import { Box, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};
const ImgViewModal = ({ open, onClose, imageUrl }) => {

  return (
    <Modal
      open={open}
    >
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
        <div style={{ height: "60%", width: "40%", background: "white" }}>
          <div style={{ padding: "20px" }}>
            <div style={{ display: 'flex', justifyContent: "space-between", paddingBottom: "15px", borderBottom: "0.5px solid ##222" }}>
              <p style={{ fontSize: "1rem", color: "#000000" }}>uploaded Image</p>
              <div >
                <i class="fa-solid fa-xmark cursorPointer" onClick={onClose}></i>
              </div>
            </div>
            <div style={{ display: 'flex', alignContent: "center", justifyContent: "center", marginTop: "15px" }}>
              <img
                src={imageUrl}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ImgViewModal;
