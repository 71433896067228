import RuleFolderOutlinedIcon from "@mui/icons-material/RuleFolderOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import light from "../../../img/LightReflection.svg";
import login from "../../../img/Login.png";

export const studentPerformanceData = [
  {
    id: 1,
    student: "Pradeep",
    concept: "Light",
    subConcepts: [
      {
        id: 1,
        title: "Type of Light",
      },
      {
        id: 2,
        title: "Light Sources",
      },
      {
        id: 3,
        title: "Application of Light",
      },
    ],
    rootConcepts: [
      {
        id: 1,
        title: "Root Concepts - 1",
      },
      {
        id: 2,
        title: "Root Concepts - 2",
      },
      {
        id: 3,
        title: "Root Concepts - 3",
      },
    ],
    marks: [
      {
        id: 1,
        mark: "3/10",
      },
      {
        id: 2,
        mark: "5/10",
      },
      {
        id: 3,
        mark: "8/10",
      },
    ],
    percentage: "87",
    timeToken: [{ id: "1", min: "8 min", second: "3 second" }],
    timePerQuestion: [{ id: "1", min: "5 min", second: "3 second" }],
    attempt: "06-jul-22",
    pq1: "4",
    pq2: "1",
    highestScore: "95",
  },
  {
    id: 2,
    student: "Jagadeeshu",
    concept: "Electricity",
    subConcepts: "Electricity",
    rootConcepts: "Electricity",
    marks: "33",
    percentage: "67",
    timeToken: [{ id: "1", min: "1 min", second: "3 second" }],
    timePerQuestion: [{ id: "1", min: "1 min", second: "3 second" }],
    attempt: "06-jul-22",
    pq1: "4",
    pq2: "1",
    highestScore: "95",
  },
  {
    id: 3,
    student: "Sri Krian",
    concept: "Electricity",
    subConcepts: "Electricity",
    rootConcepts: "Electricity",
    marks: "33",
    percentage: "45",
    timeToken: [{ id: "1", min: "1 min", second: "3 second" }],
    timePerQuestion: [{ id: "1", min: "1 min", second: "3 second" }],
    attempt: "06-jul-22",
    pq1: "4",
    pq2: "1",
    highestScore: "95",
  },
  {
    id: 4,
    student: "Hema",
    concept: "Electricity",
    subConcepts: "Electricity",
    rootConcepts: "Electricity",
    marks: "33",
    percentage: "37",
    timeToken: [{ id: "1", min: "1 min", second: "3 second" }],
    timePerQuestion: [{ id: "1", min: "1 min", second: "3 second" }],
    attempt: "06-jul-22",
    pq1: "4",
    pq2: "1",
    highestScore: "95",
  },
  {
    id: 5,
    student: "Raju",
    concept: "Electricity",
    subConcepts: "Electricity",
    rootConcepts: "Electricity",
    marks: "33",
    percentage: "37",
    timeToken: [{ id: "1", min: "1 min", second: "3 second" }],
    timePerQuestion: [{ id: "1", min: "1 min", second: "3 second" }],
    attempt: "06-jul-22",
    pq1: "4",
    pq2: "1",
    highestScore: "95",
  },
];

export const studentPerformanceDataNew = {
  "studentWisePerformanceListResponseDtoList": [
      {
          "name": "kum k",
          "conceptResponseDtoList": [
              {
                  "concept_name": "CONCEPT 1 MATTER",
                  "subConceptReportResponseDtoList": [
                      {
                          "sub_concept_name": "OXYGEN",
                          "rootConceptReportResponseDtoList": [
                              {
                                  "root_concept_name": "O2",
                                  "score": "5/10"
                              }
                          ]
                      }
                  ]
              },
              {
                  "concept_name": "AEROBIC",
                  "subConceptReportResponseDtoList": [
                      {
                          "sub_concept_name": "OXYGEN",
                          "rootConceptReportResponseDtoList": [
                              {
                                  "root_concept_name": "O2",
                                  "score": "5/10"
                              }
                          ]
                      }
                  ]
              }
          ],
          "mark": "5/10",
          "percentage": "60%",
          "timeToken": "00Hrs 04Mins 11Sec",
          "timePerQuestion": "00Hrs 03Mins 10Sec",
          "attemptOn": "2023-07-12",
          "pq1": 4,
          "pq2": 2,
          "highestScore": 45
      },
      {
          "name": "ANAND POTTY",
          "conceptResponseDtoList": [
              {
                  "concept_name": "CONCEPT 1 MATTER",
                  "subConceptReportResponseDtoList": [
                      {
                          "sub_concept_name": "OXYGEN",
                          "rootConceptReportResponseDtoList": [
                              {
                                  "root_concept_name": "O2",
                                  "score": "5/10"
                              }
                          ]
                      }
                  ]
              },
              {
                  "concept_name": "AEROBIC",
                  "subConceptReportResponseDtoList": [
                      {
                          "sub_concept_name": "OXYGEN",
                          "rootConceptReportResponseDtoList": [
                              {
                                  "root_concept_name": "O2",
                                  "score": "5/10"
                              }
                          ]
                      }
                  ]
              }
          ],
          "mark": "5/10",
          "percentage": "69%",
          "timeToken": "00Hrs 04Mins 11Sec",
          "timePerQuestion": "00Hrs 03Mins 10Sec",
          "attemptOn": "2023-07-12",
          "pq1": 4,
          "pq2": 2,
          "highestScore": 45
      }
  ]
};

export const accordionData = [
  {
    id: 1,
    title: "Reasons for different in space",
    percentage: "71.8%",
    background: "rgb(52 134 208 / 90%)",
    questionTitle:
      "Read the given question carefully and choose the correct answers:",
    questions: [
      {
        id: 1,
        question: "Question 2 - Multiple Choice",
        options: [
          {
            id: 1,
            option: "Sometimes",
            type: "text",
          },
          {
            id: 2,
            option: "Always",
            type: "button",
          },
          {
            id: 3,
            option: "Never",
            type: "text",
          },
          {
            id: 4,
            option: "Under special conditions",
            type: "text",
          },
        ],
        name: "Map Name",
        src: light,
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 50,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: 2,
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 80,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 80,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 3,
          },
        ],
      },
      {
        id: 2,
        question: "Question  - Multy-Select",
        options: [
          {
            id: 1,
            option: "Sometimes",
            type: "text",
          },
          {
            id: 2,
            option: "Always",
            type: "button",
          },
          {
            id: 3,
            option: "Never",
            type: "text",
          },
          {
            id: 4,
            option: "Under special conditions",
            type: "text",
          },
        ],
        name: "",
        src: "",
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 50,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: "2,3",
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 40,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 20,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 2,
          },
        ],
      },
      {
        id: 3,
        question: "Question 3 - Fill In the Blank",
        options: [
          {
            id: 1,
            option: "Correct Answer",
            type: "button",
          },
        ],
        name: "",
        src: "",
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 70,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: "Hydrogen,Oxygen",
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 30,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 10,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 1,
          },
        ],
      },
      {
        id: 4,
        question: "Question 4 - Match the following ",
        options: [
          {
            id: 1,
            option: "Correct Answer",
            type: "button",
          },
        ],
        name: "",
        src: light,
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 80,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: "Refer Image",
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 60,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 30,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 2,
          },
        ],
      },
    ],
    questionHeading: "",
    questionContent: "",
    questionImage: "",
  },
  {
    id: 2,
    title: "Preparation for travelling into space",
    percentage: "61%",
    background: "rgb(52 134 208 / 90%)",
    questions: [
      {
        id: 1,
        question: "",
        options: [],
        name: "",
        src: "",
      },
    ],
    questionTitle: "",
    questionHeading: "",
    questionContent: "",
    questionImage: "",
  },
  {
    id: 3,
    title: "Life in space",
    percentage: "67%",
    background: "rgb(52 134 208 / 90%)",
    questions: [
      {
        id: 1,
        question: "",
        options: [],
        name: "",
        src: "",
      },
    ],
    questionTitle: "",
    questionHeading: "",
    questionContent: "",
    questionImage: "",
  },
  {
    id: 4,
    title: "Case Study",
    percentage: "50%",
    background: "rgb(5 74 134)",
    questions: [
      {
        id: 1,
        question: "Question 2 - Multiple Choice",
        options: [
          {
            id: 1,
            option: "Sometimes",
            type: "text",
          },
          {
            id: 2,
            option: "Always",
            type: "button",
          },
          {
            id: 3,
            option: "Never",
            type: "text",
          },
          {
            id: 4,
            option: "Under special conditions",
            type: "text",
          },
        ],
        name: "Map Name",
        src: light,
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 50,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: 2,
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 80,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 80,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 3,
          },
        ],
      },
      {
        id: 2,
        question: "Question  - Multy-Select",
        options: [
          {
            id: 1,
            option: "Sometimes",
            type: "text",
          },
          {
            id: 2,
            option: "Always",
            type: "button",
          },
          {
            id: 3,
            option: "Never",
            type: "text",
          },
          {
            id: 4,
            option: "Under special conditions",
            type: "text",
          },
        ],
        name: "",
        src: "",
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 50,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: "2,3",
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 40,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 20,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 2,
          },
        ],
      },
      {
        id: 3,
        question: "Question 3 - Fill In the Blank",
        options: [
          {
            id: 1,
            option: "Correct Answer",
            type: "button",
          },
        ],
        name: "",
        src: "",
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 70,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: "Hydrogen,Oxygen",
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 30,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 10,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 1,
          },
        ],
      },
      {
        id: 4,
        question: "Question 4 - Match the following ",
        options: [
          {
            id: 1,
            option: "Correct Answer",
            type: "button",
          },
        ],
        name: "",
        src: login,
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 80,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: "Refer Image",
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 60,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 30,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 2,
          },
        ],
      },
    ],
    questionTitle:
      "Read the given question carefully and choose the correct answers:",
    questionHeading:
      "Read passage given below and answer the question that following:",
    questionContent:
      "The Human eye is one of the most important valuable sense organs, which the nature has given us. " +
      "It consists of a lens, which is made up of living tissues. The Human eye is roughly spherical in shape." +
      "It consists of a convex lens made up of living tissues. Hence, human lenses are living organs contrary to the simple optical lenses.",
    questionImage: login,
  },
];

// export const graphData = {
//   section_avg: {
//     concepts: [
//       {
//         concept_name: "Reflections",
//         score: 88,
//         sub_concepts: [
//           {
//             sub_concept_name: "Properties of Reflection",
//             score: 40,
//             root_concepts: [
//               { root_concept_name: "root concept reflection", score: 22 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 22 },
//               { root_concept_name: "root concept reflection3", score: 25 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 22 },
//               { root_concept_name: "root concept reflection3", score: 25 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//         ],
//       },
//       {
//         concept_name: "Reflections",
//         score: 90,
//         sub_concepts: [
//           {
//             sub_concept_name: "Properties of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection", score: 26 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 56,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 30 },
//               { root_concept_name: "root concept reflection3", score: 40 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 22 },
//               { root_concept_name: "root concept reflection3", score: 25 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   global_avg: {
//     concepts: [
//       {
//         concept_name: "Reflections",
//         score: 90,
//         sub_concepts: [
//           {
//             sub_concept_name: "Properties of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection", score: 26 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 56,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 30 },
//               { root_concept_name: "root concept reflection3", score: 40 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 22 },
//               { root_concept_name: "root concept reflection3", score: 25 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//         ],
//       },
//       {
//         concept_name: "Reflections",
//         score: 90,
//         sub_concepts: [
//           {
//             sub_concept_name: "Properties of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection", score: 26 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 56,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 30 },
//               { root_concept_name: "root concept reflection3", score: 40 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 22 },
//               { root_concept_name: "root concept reflection3", score: 25 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//         ],
//       },
//     ],
//   },
// };

export const graphData = {
  "section_avg": null,
  "sectionAverageResponseDto": {
      "concepts": null,
      "conceptResponseDtoList": [
          {
              "concept_name": "AEROBIC",
              "score": 9,
              "subConceptReportResponseDtoList": [
                  {
                      "sub_concept_name": "OXYGEN",
                      "score": 9,
                      "rootConceptReportResponseDtoList": [
                          {
                              "root_concept_name": "O2",
                              "score": 0
                          }
                      ]
                  }
              ]
          }
      ]
  },
  "global_avg": null,
  "globalAverageResponseDto": null
}

export const formal_assessment = {
                      "quizName": "Quizz-8",
                      "quizId": "402892888968a0d40189690682e60001",
                      "studentSectionCount": 11,
                      "quizCompletedStudent": 1,
                      "quizAttendance": 9,
                      "noOfTimeQuizReleased": 1,
                      "noOfQuestions": 3,
                      "noOfCaseStudies": 0,
                      "firstReleasedDate": "18/07/2023",
                      "averageTimeTakenCompletedQuiz": "00Hrs 04Mins 11Sec",
                      "averageTimeTakenByQuestion": null,
                      "sectionGlobalAverage": {
                          "section_avg": null,
                          "sectionAverageResponseDto": {
                              "concepts": null,
                              "conceptResponseDtoList": [
                                  {
                                      "concept_name": "Testconcept1",
                                      "score": 0,
                                      "subConceptReportResponseDtoList": [
                                          {
                                              "sub_concept_name": "BEST",
                                              "score": 0,
                                              "rootConceptReportResponseDtoList": [
                                                  {
                                                      "root_concept_name": "O2",
                                                      "score": 0
                                                  }
                                              ]
                                          },
                                          {
                                              "sub_concept_name": "OXYGEN",
                                              "score": 0,
                                              "rootConceptReportResponseDtoList": [
                                                  {
                                                      "root_concept_name": "O2",
                                                      "score": 0
                                                  }
                                              ]
                                          }
                                      ]
                                  },
                                  {
                                      "concept_name": "AEROBIC",
                                      "score": 0,
                                      "subConceptReportResponseDtoList": [
                                          {
                                              "sub_concept_name": "BEST",
                                              "score": 0,
                                              "rootConceptReportResponseDtoList": [
                                                  {
                                                      "root_concept_name": "O2",
                                                      "score": 0
                                                  }
                                              ]
                                          },
                                          {
                                              "sub_concept_name": "OXYGEN",
                                              "score": 0,
                                              "rootConceptReportResponseDtoList": [
                                                  {
                                                      "root_concept_name": "O2",
                                                      "score": 0
                                                  }
                                              ]
                                          }
                                      ]
                                  }
                              ]
                          },
                          "global_avg": null,
                          "globalAverageResponseDto": null
                      },
                      "topBottomAbsentStudents": {
                          "topStudents": [
                              {
                                  "name": "ANAND POTTY",
                                  "studentId": "4028928886f00aec0186fd573d860001",
                                  "mark": 0
                              }
                          ],
                          "bottomStudents": [],
                          "absents": [
                              {
                                  "name": "hardik pas",
                                  "studentId": "402892888773f06e0187740ee2510000",
                                  "mark": 0
                              },
                              {
                                  "name": "John Wick",
                                  "studentId": "402892888736b1f4018750ee1bbc0040",
                                  "mark": 0
                              },
                              {
                                  "name": "Kartik singfh",
                                  "studentId": "402892888736b1f401875b76f8950074",
                                  "mark": 0
                              },
                              {
                                  "name": "Sumit singh",
                                  "studentId": "402892888736b1f4018750eba71e003d",
                                  "mark": 0
                              },
                              {
                                  "name": "Shukr sdkf",
                                  "studentId": "40289288880fb52401880fe056a9012b",
                                  "mark": 0
                              },
                              {
                                  "name": "Kamlesh ck",
                                  "studentId": "402892888736b1f40187739636d4009d",
                                  "mark": 0
                              },
                              {
                                  "name": "eighth jj",
                                  "studentId": "40289288840fe4a7018456d80a45006a",
                                  "mark": 0
                              },
                              {
                                  "name": "kum k",
                                  "studentId": "40289288840fe4a7018455d0c3c7004d",
                                  "mark": 0
                              },
                              {
                                  "name": "Adheera Andmm",
                                  "studentId": "402892888773f06e018774125de30003",
                                  "mark": 0
                              },
                              {
                                  "name": "ds sds",
                                  "studentId": "40289288845d10b20184987a7ee4003d",
                                  "mark": 0
                              }
                          ]
                      },
                      "conceptListLMSResponse": {
                          "data": [
                              {
                                  "id": "4028928884ba54830184e0fff52f0053",
                                  "concept": "Decembertwo",
                                  "active": true,
                                  "subConcepts": [
                                      {
                                          "id": "4028928884ba54830184e1004c590055",
                                          "subConcept": "Bio2",
                                          "active": true
                                      },
                                      {
                                          "id": "4028928884ba54830184e1004c5d0056",
                                          "subConcept": "Bio1",
                                          "active": true,
                                          "rootConceptResponses": [
                                              {
                                                  "id": "4028928884ba54830184e10131c50057",
                                                  "rootConcept": "Bio1.1",
                                                  "active": true
                                              },
                                              {
                                                  "id": "4028928884ba54830184e10131ca0058",
                                                  "rootConcept": "Bio1.2",
                                                  "active": true
                                              }
                                          ]
                                      }
                                  ]
                              },
                              {
                                  "id": "4028928884ba54830184f0cc2e0e006c",
                                  "concept": "NATURE",
                                  "active": true,
                                  "subConcepts": [
                                      {
                                          "id": "4028928886facffa018704cef6e40105",
                                          "subConcept": "Search",
                                          "active": true
                                      }
                                  ]
                              },
                              {
                                  "id": "4028928884ba54830184f0cc59ff006e",
                                  "concept": "PLANTS",
                                  "active": true,
                                  "subConcepts": [
                                      {
                                          "id": "4028928884ba54830184f0d5cb32007c",
                                          "subConcept": "COCONUT",
                                          "active": true
                                      },
                                      {
                                          "id": "4028928884ba54830184f0d5cb37007d",
                                          "subConcept": "MANGO",
                                          "active": true
                                      },
                                      {
                                          "id": "4028928884ba54830184f0d5cb3b007e",
                                          "subConcept": "NEEM ",
                                          "active": true,
                                          "rootConceptResponses": [
                                              {
                                                  "id": "4028928884ba54830184f0d6d4590081",
                                                  "rootConcept": "ANTIOXIDANTS",
                                                  "active": true
                                              },
                                              {
                                                  "id": "4028928884ba54830184f0d6d45f0082",
                                                  "rootConcept": "HERBAL",
                                                  "active": true
                                              },
                                              {
                                                  "id": "4028928884ba54830184f0d6d4620083",
                                                  "rootConcept": "MEDICINE",
                                                  "active": true
                                              },
                                              {
                                                  "id": "4028928884ba54830184f0d6d4660084",
                                                  "rootConcept": "LEAF",
                                                  "active": true
                                              },
                                              {
                                                  "id": "4028928884ba54830184f0d6d4690085",
                                                  "rootConcept": "AYURVEDIC",
                                                  "active": true
                                              }
                                          ]
                                      },
                                      {
                                          "id": "4028928884ba54830184f0d5cb40007f",
                                          "subConcept": "BANANA",
                                          "active": true
                                      },
                                      {
                                          "id": "4028928884ba54830184f0d5cb430080",
                                          "subConcept": "AVOCADO",
                                          "active": true
                                      },
                                      {
                                          "id": "4028928884ba54830184f5fa6c16011c",
                                          "subConcept": "APPLE",
                                          "active": true
                                      }
                                  ]
                              },
                              {
                                  "id": "4028928884ba54830184f0cc828b0070",
                                  "concept": "ANIMALS",
                                  "active": true,
                                  "subConcepts": [
                                      {
                                          "id": "4028928884ba54830184f16533eb00dc",
                                          "subConcept": "ANIMALS",
                                          "active": true
                                      }
                                  ]
                              },
                              {
                                  "id": "4028928884ba54830184f0ccbc000072",
                                  "concept": "MICRO",
                                  "active": true,
                                  "subConcepts": []
                              },
                              {
                                  "id": "4028928884ba54830184f0cf6a2d0074",
                                  "concept": "ANAEROBIC",
                                  "active": true,
                                  "subConcepts": []
                              },
                              {
                                  "id": "4028928884ba54830184f0cf86420076",
                                  "concept": "AEROBIC",
                                  "active": true,
                                  "subConcepts": [
                                      {
                                          "id": "4028928884ba54830184f0db53040088",
                                          "subConcept": "OXYGEN",
                                          "active": true,
                                          "rootConceptResponses": [
                                              {
                                                  "id": "4028928884ba54830184f0db714d0089",
                                                  "rootConcept": "O2",
                                                  "active": true
                                              },
                                              {
                                                  "id": "4028928889430d7b018946466478000d",
                                                  "rootConcept": "WWe",
                                                  "active": true
                                              },
                                              {
                                                  "id": "402892888968a0d401896d1f29570007",
                                                  "rootConcept": "New",
                                                  "active": true
                                              }
                                          ]
                                      },
                                      {
                                          "id": "4028928889430d7b01894646327d000c",
                                          "subConcept": "ASdx",
                                          "active": true
                                      },
                                      {
                                          "id": "402892888968a0d401896d1f1a740006",
                                          "subConcept": "NEw",
                                          "active": true
                                      }
                                  ]
                              },
                              {
                                  "id": "40289288882457dc018824ea73f10007",
                                  "concept": "Test New",
                                  "active": true,
                                  "subConcepts": []
                              },
                              {
                                  "id": "40289288882457dc018824f12bac0012",
                                  "concept": "LMS 111",
                                  "active": true,
                                  "subConcepts": []
                              }
                          ],
                          "message": "Successfully retrieved Concepts, SubConcepts and RootConcepts for this chapter.",
                          "success": true,
                          "errorCode": null,
                          "path": null
                      },
                      "taxonomyList": [
                          {
                              "taxonomy": "Demrrtt",
                              "percentage": 33,
                              "taxonomyQuestionCount": 1
                          },
                          {
                              "taxonomy": "Synthesis",
                              "percentage": 33,
                              "taxonomyQuestionCount": 1
                          }
                      ],
                      "latestReleasedDate": "18/07/2023"
}