import {
  FormControl,
  IconButton,
  OutlinedInput,
  TextField,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MenuProps } from '../../Data'
import searchIcn from '../../../../img/search.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import dashboardContentStyles from '../../../css/dashboardContent.module.css'
import { isNOTNullOrUndefined } from '../../../../utils/helper'

const FiltersBar = ({
  subjectList,
  handleChangeSection,
  handleChangeGrade,
  handleChangeSubject,
  handleChangeSubTopic,
  handleChangeAcademicYear,
  gradeSection,
  selectedSubTopic,
  selectedAcademicYear,
  selectedGrade,
  searchHandler,
  academicYearList,
  isError,
  selectedSection,
  selectedSubject,
  sectionList,
  showSubTopic
}) => {
  // console.log("showSubTopic", selectedSubTopic, showSubTopic, subjectList)
  const filterStyle = {
    '& .css-10hburv-MuiTypography-root': {
      fontSize: '12px !important'
    },
    '&.MuiOutlinedInput-root': {
      '& > fieldset': {
        border: '1px solid #D6D5DF !important'
      },
      '&:hover > fieldset': {
        border: '1px solid #ffa500 !important', 
        
      },
    
    },

    // '&:hover .MuiOutlinedInput-notchedOutline': {
    //   backgroundColor: '#fcedc2',
    // },
    // '&.MuiOutlinedInput-root.Mui-focused': {
    //   '& > fieldset': {
    //     border: '1px solid #272727 !important'
    //   }
    // },

    
    fontSize: '14px !important',

    font: 'Poppins !important'
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: '10px',
        alignItems: 'center',
        columnGap:'1rem'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: '10px'
        }}      >
        {/* <FormControl
          sx={{
            width: '113px',
            height: 45
          }}
        >
          <InputLabel
            sx={{
              fontSize: '14px',
              marginBottom: 6,
              '&.Mui-focused': {
                color: 'orange'
              },
              color: 'orange'
            }}
            id="az-grade-select-label"
            style={{
              color: 'orange'
            }}
          >
            Grades
          </InputLabel>

          <Select
            labelId='demo-simple-select-label'
            id="az-grade-select"
            input={<OutlinedInput label='Grades' />}
            MenuProps={MenuProps}
            sx={filterStyle}
            style={{ height: '100%', background: 'white', borderRadius: 150 }}
            size={'small'}
            value={selectedGrade}
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            onChange={handleChangeGrade}
          >
            {gradeSection?.map(item => {
              return (
                <MenuItem
                  id={`az-grade-dropdown-${item.grade.toLowerCase().replace(/ /g, "_")}`}
                  value={item.id}
                  key={item.id}
                  className={
                    dashboardContentStyles.menu_item
                  }
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: 'orange',
                      color: "#FFFFFF"
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: '#fcedc2',
                      color: "#000000"
                    },
                    "&:not(.Mui-selected):hover": {
                      backgroundColor: '#fcedc2',
                      color: '#000000',
                    },
                  }}
                >
                  {item.grade}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl> */}

        <FormControl fullWidth>
          <InputLabel
            sx={{
              fontSize:'13px',
              color:'#e7ab3c',
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
              '&.Mui-focused':{
                color:'#e7ab3c'
              }
            }}  
            id="az-grade-select-label" className='dropDownLabel'>Grade</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id="az-grade-select"
            input={<OutlinedInput label='Grade' />}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '150px',
                  marginTop:'2px', 
                },
              },
              MenuListProps: {
                sx: {
                  padding: '0px'
                },
              },
            }}  
            value={selectedGrade}
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            onChange={handleChangeGrade}
            sx={{
              width: '150px',    
              height: '30px',    
              padding: '10px',  
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e7ab3c', 
              }
            }}
            className='dropdownSelect'
          >
            {gradeSection?.map(item => {
              return (
                <MenuItem
                  id={`az-grade-dropdown-${item.grade.toLowerCase().replace(/ /g, "_")}`}
                  value={item.id}
                  key={item.id}
                  className={
                    dashboardContentStyles.menu_item
                  }
                  sx={{
                    fontSize:'10px',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: '#f4d788',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#e7ab3c',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                    },
                  }}
                >
                  {item.grade}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        {/* <FormControl
          sx={{
            width: '113px',
            height: 45
          }}
        >
          <InputLabel
            sx={{
              fontSize: '14px',
              marginBottom: 6,
              '&.Mui-focused': {
                color: 'orange'
              }
            }}
            id="az-section-select-label"
          >
            Section
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id="az-section-select"
            input={<OutlinedInput label='Section' />}
            MenuProps={MenuProps}
            size={'small'}
            sx={filterStyle}
            style={{ height: '100%', background: 'white', borderRadius: 150 }}
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            value={selectedSection}
            // onChange={(e) => setSelectedSection(e.target.value)}
            onChange={handleChangeSection}
          >
            {sectionList?.map(sec => {
              if (sec.hasOwnProperty('section')) {
                return (
                  <MenuItem

                    id={`az-section-dropdown-${sec.section.toLowerCase().replace(/ /g, "_")}`}
                    // value={sec.id}
                    // key={sec.id}
                    // className={dashboardContentStyles.menu_item}
                    value={sec.id}
                    key={sec.id}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    {sec.section}
                  </MenuItem>
                )
              }
            })}
          </Select>
        </FormControl> */}

        <FormControl fullWidth >
          <InputLabel
           sx={{
            fontSize:'13px',
            color:'#e7ab3c',
            top: '-10px',
            '&.MuiInputLabel-shrink': {
              top: '0',
            },
            '&.Mui-focused':{
              color:'#e7ab3c'
            }
          }}  
            id="az-section-select-label" className='dropDownLabel'>Section</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id="az-section-select"
            input={<OutlinedInput label='Section' />}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '150px',
                  marginTop:'2px', 
                },
              },
              MenuListProps: {
                sx: {
                  padding: '0px'
                },
              },
            }}  
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            value={selectedSection}
            // onChange={(e) => setSelectedSection(e.target.value)}
            onChange={handleChangeSection}
            sx={{
              width: '150px',    
              height: '30px',    
              padding: '10px',  
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e7ab3c', 
              }
            }}
            className='dropdownSelect'
          >
            {sectionList?.map(sec => {
              if (sec.hasOwnProperty('section')) {
                return (
                  <MenuItem

                    id={`az-section-dropdown-${sec.section.toLowerCase().replace(/ /g, "_")}`}
                    // value={sec.id}
                    // key={sec.id}
                    // className={dashboardContentStyles.menu_item}
                    value={sec.id}
                    key={sec.id}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      fontSize:'10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    {sec.section}
                  </MenuItem>
                )
              }
            })}
          </Select>
        </FormControl>

        {/* <FormControl
          sx={{
            width: '113px',
            height: 45
          }}
        >
          <InputLabel sx={{
            fontSize: '14px',
            marginBottom: 6,
            '&.Mui-focused': {
              color: 'orange'
            }
          }}
            id="az-subject-select-label"
          >Subjects</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id="az-subject-select"
            input={<OutlinedInput label='Subjects' />}
            MenuProps={MenuProps}
            size={'small'}
            sx={filterStyle}
            style={{ height: '100%', background: 'white', borderRadius: 150 }}
            value={isNOTNullOrUndefined(selectedSubject) ? selectedSubject : ''}
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            // onChange={(e) => setSelectedSubject(e.target.value)}
            onChange={handleChangeSubject}
          >
            {subjectList?.map(item => {
              return (
                <MenuItem
                  id={`az-subject-dropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
                  // value={item.subjectId}
                  // key={item.subjectId}
                  // className={dashboardContentStyles.menu_item}
                  value={item.subjectId}
                  key={item.subjectId}
                  className={
                    dashboardContentStyles.menu_item
                  }
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: 'orange',
                      color: "#FFFFFF"
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: '#fcedc2',
                      color: "#000000"
                    },
                    "&:not(.Mui-selected):hover": {
                      backgroundColor: '#fcedc2',
                      color: '#000000',
                    },
                  }}
                >
                  {item.subject}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl> */}

        <FormControl fullWidth >
          <InputLabel  
          sx={{
            fontSize:'13px',
            color:'#e7ab3c',
            top: '-10px',
            '&.MuiInputLabel-shrink': {
              top: '0',
            },
            '&.Mui-focused':{
              color:'#e7ab3c'
            }
          }}  
            id="az-subject-select-label" className='dropDownLabel'>Subject</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id="az-subject-select"
            input={<OutlinedInput label='Subject' />}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '150px',
                  marginTop:'2px', 
                },
              },
              MenuListProps: {
                sx: {
                  padding: '0px'
                },
              },
            }}  
            value={isNOTNullOrUndefined(selectedSubject) ? selectedSubject : ''}
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            // onChange={(e) => setSelectedSubject(e.target.value)}
            onChange={handleChangeSubject}
            sx={{
              width: '150px',    
              height: '30px',    
              padding: '10px',  
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e7ab3c', 
              }
            }}
            className='dropdownSelect'
          >
            {subjectList?.map(item => {
              return (
                <MenuItem
                  id={`az-subject-dropdown-${item.subject.toLowerCase().replace(/ /g, "_")}`}
                  // value={item.subjectId}
                  // key={item.subjectId}
                  // className={dashboardContentStyles.menu_item}
                  value={item.subjectId}
                  key={item.subjectId}
                  className={
                    dashboardContentStyles.menu_item
                  }
                  sx={{
                    fontSize:'10px',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: '#f4d788',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#e7ab3c',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                    },
                  }}
                >
                  {item.subject}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        {/* Teach menu -----------changes */}
        {
          showSubTopic &&

          // <FormControl
          //   sx={{
          //     width: '113px',
          //     height: 45
          //   }}
          // >
          //   <InputLabel sx={{
          //     fontSize: '14px',
          //     marginBottom: 6,
          //     '&.Mui-focused': {
          //       color: 'orange'
          //     }
          //   }} id="az-subtopic-select-label">SubTopic</InputLabel>
          //   <Select
          //     labelId='demo-simple-select-label'
          //     id="az-subtopic-select"
          //     input={<OutlinedInput label='Tag' />}
          //     MenuProps={MenuProps}
          //     size={'small'}
          //     sx={filterStyle}
          //     style={{ height: '100%', background: 'white', borderRadius: 150 }}
          //     value={
          //       isNOTNullOrUndefined(selectedSubTopic) ? selectedSubTopic : ''
          //     }
          //     IconComponent={props => (
          //       <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
          //     )}
          //     onChange={handleChangeSubTopic}
          //   >
          //     {subjectList
          //       ?.find(i => i.subjectId === selectedSubject)
          //       ?.subTopics?.map(sub => {
          //         if (sub.hasOwnProperty('subTopic')) {
          //           return (
          //             <MenuItem
          //               id={`az-subtopic-dropdown-${sub.subTopic.toLowerCase().replace(/ /g, "_")}`}
          //               // value={sub.id}
          //               // key={sub.id}
          //               // className={dashboardContentStyles.menu_item}
          //               value={sub.id}
          //               key={sub.id}
          //               className={
          //                 dashboardContentStyles.menu_item
          //               }
          //               sx={{
          //                 "&.Mui-selected": {
          //                   backgroundColor: 'orange',
          //                   color: "#FFFFFF"
          //                 },
          //                 "&.Mui-selected:hover": {
          //                   backgroundColor: '#fcedc2',
          //                   color: "#000000"
          //                 },
          //                 "&:not(.Mui-selected):hover": {
          //                   backgroundColor: '#fcedc2',
          //                   color: '#000000',
          //                 },
          //               }}
          //             >
          //               {sub.subTopic}
          //             </MenuItem>
          //           )
          //         }
          //       })}
          //   </Select>
          // </FormControl>

          <FormControl fullWidth >
          <InputLabel 
          sx={{
              fontSize:'13px',
              color:'#e7ab3c',
              top: '-10px',
              '&.MuiInputLabel-shrink': {
               top: '0',
              },
             '&.Mui-focused':{
              color:'#e7ab3c'
             }
            }} id="az-subtopic-select-label" className='dropDownLabel'>SubTopic</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id="az-subtopic-select"
            input={<OutlinedInput label='SubTopic' />}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '150px',
                  marginTop:'2px', 
                },
              },
              MenuListProps: {
                sx: {
                  padding: '0px'
                },
              },
            }}  
            value={
              isNOTNullOrUndefined(selectedSubTopic) ? selectedSubTopic : ''
            }
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            onChange={handleChangeSubTopic}
            sx={{
              width: '150px',    
              height: '30px',    
              padding: '10px',  
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e7ab3c', 
              }
            }}
            className='dropdownSelect'
          >
            {subjectList
              ?.find(i => i.subjectId === selectedSubject)
              ?.subTopics?.map(sub => {
                if (sub.hasOwnProperty('subTopic')) {
                  return (
                    <MenuItem
                      id={`az-subtopic-dropdown-${sub.subTopic.toLowerCase().replace(/ /g, "_")}`}
                      // value={sub.id}
                      // key={sub.id}
                      // className={dashboardContentStyles.menu_item}
                      value={sub.id}
                      key={sub.id}
                      className={
                        dashboardContentStyles.menu_item
                      }
                      sx={{
                        fontSize:'10px',
                        backgroundColor: 'white',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                        '&.Mui-selected': {
                          backgroundColor: '#e7ab3c',
                          '&:hover': {
                            backgroundColor: '#f4d788',
                          },
                        },
                      }}
                    >
                      {sub.subTopic}
                    </MenuItem>
                  )
                }
              })}
          </Select>
          </FormControl>
        }

        {/* <FormControl
          sx={{
            width: '113px',
            height: 45
          }}
        >
          <InputLabel sx={{
            fontSize: '14px',
            marginBottom: 6,
            '&.Mui-focused': {
              color: 'orange'
            }
          }} id="az-acdyear-select-label">Academic Year</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id="az-acdyear-select"
            input={<OutlinedInput label='Tag' />}
            MenuProps={MenuProps}
            size={'small'}
            sx={filterStyle}
            style={{ height: '100%', background: 'white', borderRadius: 150 }}
            value={selectedAcademicYear}
            disabled
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            onChange={handleChangeAcademicYear}
          >
            {academicYearList?.map(item => {
              return (
                <MenuItem
                  id={`az-acdyear-dropdown-${item.academicYear.toLowerCase().replace(/ /g, "_")}`}
                  value={item.id}
                  key={item.id}
                  className={
                    dashboardContentStyles.menu_item
                  }
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: 'orange',
                      color: "#FFFFFF"
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: '#fcedc2',
                      color: "#000000"
                    },
                    "&:not(.Mui-selected):hover": {
                      backgroundColor: '#fcedc2',
                      color: '#000000',
                    },
                  }}

                >
                  <ListItemText primary={item.academicYear} />
                </MenuItem>
              )
            })}
          </Select>
        </FormControl> */}

        <FormControl fullWidth >
          <InputLabel 
           sx={{
            fontSize:'13px',
            color:'#e7ab3c',
            top: '-10px',
            '&.MuiInputLabel-shrink': {
             top: '0',
            },
           '&.Mui-focused':{
            color:'#e7ab3c'
           }
          }} 
          id="az-acdyear-select-label" className='dropDownLabel'>Academic Year</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id="az-acdyear-select"
            input={<OutlinedInput label='Academic Year' />}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '150px',
                  marginTop:'2px', 
                },
              },
              MenuListProps: {
                sx: {
                  padding: '0px'
                },
              },
            }}  
            value={selectedAcademicYear}
            disabled
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            onChange={handleChangeAcademicYear}
            sx={{
              width: '150px',    
              height: '30px',    
              padding: '10px',  
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e7ab3c', 
              }
            }}
            className='dropdownSelect'
          >
            {academicYearList?.map(item => {
              return (
                <MenuItem
                  id={`az-acdyear-dropdown-${item.academicYear.toLowerCase().replace(/ /g, "_")}`}
                  value={item.id}
                  key={item.id}
                  className={
                    dashboardContentStyles.menu_item
                  }
                  sx={{
                    fontSize:'10px',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: '#f4d788',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#e7ab3c',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                    },
                  }}
                >
                  <ListItemText primary={item.academicYear} />
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

      </div>
      <TextField
        type={'text'}
        id="az-search-input"
        placeholder={'Search anything....'}
        onChange={searchHandler}
        InputProps={{
          endAdornment: (
            <IconButton>
              <img
                alt='searchIcon'
                src={searchIcn}
                style={{
                  verticalAlign: 'top',
                  fontSize: 'large'
                }}
                aria-hidden='true'
              />
            </IconButton>
          )
        }}
        sx={{
          background: !isError && '#fff',
          borderRadius: '150px',
          '& .MuiInputBase-root': {
            width: '200px !important',
            height: '30px !important',
            borderRadius: '50px !important',
            background: '#fff !important',
            lineHeight: '21px !important',
            font: 'normal normal normal 15px/15px Poppins !important'
          }
        }}
        error={isError}
        helperText={isError && 'Please Enter min 3 character.'}
      />
    </div>
  )
}

export default FiltersBar
