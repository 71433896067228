import { alpha, styled } from "@mui/material/styles";
import { Switch as SWITCH } from "@mui/material";
import React from "react";
const ToggleSwitch = ({ checked, onChange, disabled }) => {
  const GreenSwitch = styled(SWITCH)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#FAA220",
      "&:hover": {
        backgroundColor: alpha("#FFA700", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-thumb": {
      border: "8px solid orange",
      width: "14px",
      height: "14px",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#D9DDFD",
      border: "1px Solid #FD8C00",
      width: "28px",
      height: "10px",
    },
    "& .MuiSwitch-track": {
      width: "28px",
      height: "10px",
    },
    "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled":
      {
        opacity: 0.5,
        color: "#ffff",
      },
  }));
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <GreenSwitch
      {...label}
      checked={checked}
      onChange={onChange}
      disabled={disabled ? true : false}
      style={{ color: disabled && "#fff",cursor: 'default' }}
      
    />
  );
};
export default ToggleSwitch;
