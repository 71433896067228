import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import studentFilesStyles from "./../css/studentFiles.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import trainingAidStyles from "./../css/TrainingAids.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import createschool from "./../css/CreateSchool.module.css";
// take this css copy in original file
import Pagination1 from "./pagination1";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import trainingaid_get from "../API_CONTROLLER/trainingaid-controller/trainingaid_get";
import dashHome from "../../../src/img/dashHome.svg";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

const TrainingAids = () => {
  const [chapters, setChapters] = useState({});
  const [trainingaidList, setTrainingaidList] = useState({});

  useEffect(() => {
    chapters_controller_getall(setChapters);
    trainingaid_get(setTrainingaidList);
  }, []);

  const AddButton = styled(Button)(() => ({
    color: "#7A7A7A",
    backgroundColor: 'white',
    borderRadius: 150 , 
    border : '1px solid #7A7A7A', 
    padding:'7px',
    minWidth : '100px',
    height : '42px',
    margin:'2px 8px',
    "&:hover": {
      color: "black",
      marginBottom:"20px",
      backgroundColor: 'white',
      border : '1px solid #7A7A7A',
      top: -5,
      boxShadow: "0px 3px 5px grey",
    },
  }));

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          {/* <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>{" "}
            <span className={dashboardContentStyles.link_text}>
              Content Admin
            </span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span className={dashboardContentStyles.link_text}>
              Upload Content
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Add Student Files</a>
          </div> */}

<div
            // className={dashboardContentStyles.dashboard_link}
            style={{ fontSize: "1vw", margin: "3px 10px" }}
          >
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            <span className={dashboardContentStyles.link_text}>Home</span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <span style={{ cursor: "pointer" }}>
              Content Admin
            </span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <span style={{ cursor: "pointer" }}>
              Upload Content
            </span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <a style={{ color: "blue" }}>Add Student Files</a>
          </div>

          
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update:
              {/* {modifiedDate.data} */}
            </p>
          </div>
        </article>

        {/* upload box start */}
        <Paper
          style={{
            marginTop: "55px",
            marginLeft: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 20px",
              marginTop: "69px",
            }}
          >
            <div className={trainingAidStyles.training_container}>
              <div className={trainingAidStyles.flex}>
                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: 300 }}
                >
                  <InputLabel
                    style={{ fontFamily: "poppins" }}
                    id="demo-multiple-checkbox-label"
                  >
                    *Chapter Name
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    //   value={gradeData}
                    //   onChange={gradeHandleChange}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {chapters.length > 0 ? (
                      chapters.map((chapters, i) => {
                        return (
                          <MenuItem key={i} value={chapters.chapter}>
                            <ListItemText primary={chapters.chapter} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <div className={createschool.upload_logo_container}>
                  <span style={{marginLeft:"10px"}}>*Upload Logo</span>
                  <span className={createschool.custom_file_container}>
                    <input
                    style={{marginTop:"3px",marginRight:"10px"}}
                      type="file"
                      name="logoUrl"
                      // id={sessionStorage.getItem("logoUrl")}
                      // filename={sessionStorage.getItem("logoUrl")}
                      //   Value={schoolInputs.logoUrl}
                      // onError={setIsError(true)}
                      //   onChange={imageChange}
                      className={createschool.custom_file_input}
                    />

                    {/* {schoolInputs.logoUrl && ( */}
                    <span>
                      {/* <img
                    //   src={schoolInputs.logoUrl}
                      alt="Thumb"
                      width="75"
                      height="75"
                    /> */}
                      <span>
                        <a>
                          {/* {schoolInputs.logoUrl.substring(
                          schoolInputs.logoUrl.lastIndexOf("_") + 1,
                          schoolInputs.logoUrl.length
                        )} */}
                        </a>
                        <br></br>
                        <br></br>
                        {/* <a 
                    //   onClick={removeSelectedImage} 
                      style={{color:'red' , textDecoration:'underline'}}
                      >Remove This Image</a> */}
                      </span>
                    </span>
                    {/* )} */}
                  </span>
                </div>
                {/* raio btn end */}

                {/* </div> */}

                {/* btn */}
                <div
                  style={{
                    display: "inline",
                    position: "absolute",
                    right: "50px",
                    marginTop: "40px",
                  }}
                >
                  <BlueButton>UPLOAD</BlueButton>
                </div>
              </div>
            </div>
          </div>
        </Paper>
        {/* upload box start */}

        <div className="trainingAidStyles.flex" style={{ margin: "20px auto" }}>
          <div>
            <p style={{ color: "blue", fontSize: "15px", marginLeft: "15px" }}>
              Added Training Aids
            </p>
          </div>
          <div style={{ position: "absolute", right: "50px" }}>
            <Link
              to="/dashboard/Global%20Training"
              style={{ textDecoration: "none" }}
            >
              <AddButton> BACK</AddButton>
            </Link>
          </div>
        </div>

        {/* content start  */}
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            marginTop: " 55px",
            marginLeft: "15px",
            paddingRight: "33px",
          }}
        >
          <div
            style={{
              // width: '1166px'
              height: "667px",
              backgroundColor: "white",
            }}
          >
            <Table
              style={
                {
                  // minWidth: '1385px'
                }
              }
            >
              <TableHead style={{ backgroundColor: "#d9dcfe", height: "55px" }}>
                <TableRow>
                  <TableCell
                    className={trainingAidStyles.table_head}
                    align="left"
                    style={{
                      margin: "8px 8px",
                      color: "blue",
                      textTransform: "uppercase",
                    }}
                  >
                    List of files
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "324px",
                      height: "600px",
                      overflowY: "scroll",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {trainingaidList.length > 0 ? (
                        trainingaidList.map((elem, i) => {
                          return (
                            <div className={trainingAidStyles.data_row}>
                              <p>{elem}</p>
                              <i className="bi bi-pencil"></i>
                            </div>
                          );
                        })
                      ) : (
                        <p>NO DATA AVAILABLE</p>
                      )}
                    </div>
                  </div>
                  <div></div>
                </div>
              </TableBody>
            </Table>
          </div>
        </Paper>
        {/* content start  */}
      </div>
    </>
  );
};

export default TrainingAids;
