import axios from 'axios'

async function share(userName, userId,persona) {
    let token = sessionStorage.getItem("token");
    let item = {  
        username:userName,
        userId: userId,
        persona: persona,
      lmsEnv: process.env.REACT_APP_LMS_ENV,
    };
    let result = await axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/share-details`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response,"res")
    })
      .catch((err) => console.log(err));
  }

  export default share