import React from "react";

// Document Used
import dummyPDF from "../../../../src/img/dummyPdf.pdf";

// Css Pages
import "../../css/Student/Student_Study_Files_Mind_Map.css";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { handleRightClick } from '../../../utils/AppUtility';
import ContentViewer from '../../../components/common/ContentViewer';

function Student_Study_Files_Mind_Map() {

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <>
      {/*-----------------------------| Mind-Map Pdf Division Starts Here |------------------------------*/}
      <div className="Mind_map_pdf">
        {/*----------| Chapter Heading |------------*/}
        <div>
          <h3 className="Chap_Name">Mathematics</h3>
        </div>

        {/*-------------| Underline |---------------*/}
        <hr className="U_Line" />

        {/*-----------------| Pdf |-----------------*/}

        <ContentViewer 
              url={dummyPDF} />

        {/* <Worker 
              workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"> */}
             {/* <div onContextMenu={handleRightClick}>
               <Viewer
                 fileUrl={dummyPDF}
                 plugins={[defaultLayoutPluginInstance]}
               />
             </div> */}
           {/* </Worker> */}
        {/* <iframe src={dummyPDF} /> */}
      </div>
      {/*-----------------------------| Mind-Map Pdf Division Starts Here |------------------------------*/}
    </>
  );
}

export default Student_Study_Files_Mind_Map;
