import axios from 'axios'

async function file_upload_controller_post(
  setSelectedImage,
  formData,
  setSchoolInputs,
  schoolInputs
) {
  let token = sessionStorage.getItem("token");

  
    let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=USER_SERVICE&subFolders=SCHOOL`,formData,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(
      (response)=>{
      setSelectedImage(response.data.data)
      sessionStorage.setItem("logoUrl", response.data.data)
      setSchoolInputs({
        ...schoolInputs,
        logoUrl: response.data.data,
      })

    }
    
    )
    .catch(err=>console.log(err))
}

export default file_upload_controller_post;
