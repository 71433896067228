import axios from "axios";

async function Branches_Changer_School_Id(setBranchList) {
  let token = sessionStorage.getItem("token");
  let role = localStorage.getItem("role")
  let userId = sessionStorage.getItem("userId");
  let schoolId = sessionStorage.getItem("schoolid");
console.log(schoolId,"ssx")

  if (role==='SCHOOL_ADMIN'){
     await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/all?schoolId=${schoolId}&userId=${userId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response)=>{
      setBranchList(response.data.data)
    })
    .catch(err=>console.log(err))
}

else{
  await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/all?schoolId=${sessionStorage.getItem(
    "schoolid"
  )}`,
  {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
  .then((response)=>{
    setBranchList(response.data.data)
  })
  .catch(err=>console.log(err))
}

}

export default Branches_Changer_School_Id;
