import React, { useState, useEffect } from "react";
import SubConceptStyles from "./../css/subConceptTree.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import { SearchOutlined } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import conceptlist_get from "../API_CONTROLLER/concept-controller/conceptlist_get";
import subConcept_controller_get from "../API_CONTROLLER/concept-controller/subConcept_controller_get";
import rootConcept_controller_get from "../API_CONTROLLER/root-concept-controller/rootConcept_controller_get";
import concept_last_modified_get from "../API_CONTROLLER/concept-controller/concept_last_modified_get";
import chapters_list_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_getall";
import concept_controller_post from "../API_CONTROLLER/concept-controller/concept_controller_post";
import concept_table_get from "../API_CONTROLLER/concept-controller/concept_table_get";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";

const Statistics = () => {
  const [board, setBoard] = useState({});
  const [showModalShare, setShowModalShare] = useState(false);
  const [subject, setSubject] = useState({});
  const [gradesName, setGradesName] = useState({});
  const [subtopic, setSubtopic] = useState({});
  const [chapters, setChapters] = useState({});
  const [chaptersList, setChaptersList] = useState({});
  const [conceptlist, setConceptlist] = useState({});
  const [conceptTable, setConceptTable] = useState({});
  const [conceptInputs, setConceptInputs] = useState({
    conceptId: "",
    concept: "",
    rootConcept: "",
    subTopicId: "",
    subjectsId: "",
  });
  const [rootConceptList, setRootConceptList] = useState({});

  const [subConceptInputs, setSubConceptInputs] = useState({
    subConceptId: "",
  });
  const [subconceptList, setSubconceptList] = useState({});

  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSubtopic, setSelectedSubtopic] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");

  const handleShareModal = () => {
    setShowModalShare(true);
  };

  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };

  const handleConceptInputs = (e) => {
    e.preventDefault();
    setConceptInputs({
      ...conceptInputs,
      [e.target.name]: e.target.value,
    });
  };

  const createConceptHandler = (e) => {
    e.preventDefault();
    concept_controller_post(conceptInputs);
  };

  const handlesubConceptInputs = (e) => {
    e.preventDefault();
    setSubConceptInputs({
      ...subConceptInputs,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    subConcept_controller_get(conceptInputs, setSubconceptList);
  }, [conceptInputs]);

  useEffect(() => {
    rootConcept_controller_get(subConceptInputs, setRootConceptList);
  }, [subConceptInputs]);

  useEffect(() => {
    chapters_controller_getall(setChapters);
    subjects_controller_getall(setSubject);
    subtopics_controller_getall(setSubtopic);
    conceptlist_get(setConceptlist);
    concept_last_modified_get(setLastUpdated);
    GradesControllerGetall(setGradesName);
    boards_controller_getall(setBoard);

    return () => {};
  }, []);

  useEffect(() => {
    chapters_controller_getall(setChapters);
    chapters_list_getall(setChaptersList);
    concept_table_get(setConceptTable);
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>
              Question Bank
            </span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Statistics</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {lastUpdated.data}</p>
          </div>
        </article>

        <Paper
          style={{
            marginTop: "55px",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "28%" }}>
              {" "}
              <p
                style={{
                  color: "orange",
                  fontSize: "1.2vw",
                  borderRight: "1px solid grey",
                  textAlign: "center",
                  borderBottom: "5px solid orange",
                  padding: "7px",
                }}
              >
                Term Papers
              </p>{" "}
            </div>
            <div style={{ width: "28%" }}>
              {" "}
              <p
                style={{
                  color: "orange",
                  fontSize: "1.2vw",
                  borderRight: "1px solid grey",
                  textAlign: "center",
                  padding: "7px",
                }}
              >
                Practice Papers
              </p>{" "}
            </div>
            <div style={{ width: "28%" }}>
              {" "}
              <p
                style={{
                  color: "orange",
                  fontSize: "1.2vw",
                  borderRight: "1px solid grey",
                  textAlign: "center",
                  padding: "7px",
                }}
              >
                Periodic test
              </p>{" "}
            </div>
          </div>
        </Paper>

        <Paper
          style={{
            marginTop: "55px",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <div
              style={{ height: "120px" }}
              className={SubConceptStyles.training_container}
            >
              <div className={SubConceptStyles.flex}>
                {/* <Box style={{ margin: 'auto 8px' }} sx={{ minWidth: 150 }}> */}
                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: "16vw" }}
                >
                  <InputLabel id="demo-multiple-checkbox-label">
                    Grade
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    label="Grade"
                    // value={gradeInput}
                    // onChange={(e) => setGradeInput(e.target.value)}
                    style={{ background: "#fff" }}
                    //   MenuProps={MenuProps}
                  >
                    <MenuItem value="">All</MenuItem>
                    {/* {optionsRender(gradesName)} */}
                    {gradesName.length > 0 ? (
                      gradesName?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <ListItemText primary={item.grade} />
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
                {/* </Box> */}

                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: "16vw" }}
                >
                  <InputLabel
                    style={{ fontFamily: "poppins" }}
                    id="demo-multiple-checkbox-label"
                  >
                    *Subject
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name="subTopicId"
                    value={conceptInputs.subTopicId}
                    onChange={handleConceptInputs}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {subject.length > 0 ? (
                      subject.map((subject, i) => {
                        return (
                          <MenuItem key={i} value={subject.id}>
                            <ListItemText primary={subject.subject} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: "16vw" }}
                >
                  <InputLabel
                    style={{ fontFamily: "poppins" }}
                    id="demo-multiple-checkbox-label"
                  >
                    *Board
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name="chapterId"
                    value={conceptInputs.chapterId}
                    onChange={handleConceptInputs}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {console.log(board, "xxxx")}
                    {board.length > 0 ? (
                      board.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}>
                            <ListItemText primary={elem.board} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>

              <div
                className={SubConceptStyles.flex}
                style={{
                  marginTop: "40px",
                  // minWidth: '1280px'
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    right: "61px",
                    maxWidth: "120px",
                    minWidth: "95px",
                  }}
                >
                  <BlueButton onClick={createConceptHandler}>SUBMIT</BlueButton>
                </div>
              </div>
              {/* Table */}
            </div>
          </div>
        </Paper>
        {/* search row start  */}
      </div>
    </>
  );
};

export default Statistics;
