import React from 'react'
import './Principaldashboard.css'
import { truncateString } from '../../../../utils/helper'

const TaskItem = ({ task,onClick }) => {
  let color
  switch (task?.priority) {
    case 'high':
      color = '#E1333A'
      break
    case 'medium':
      color = '#F9C62B'
      break
    case 'low':
      color = '#3383E1'
      break

    default:
      color = 'white'
      break
  }


  return (
    <div
    onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom:'17px',
        cursor:"pointer",
        width:"100%"
      }}
    >
      <div className='task-indicator' style={{background:color,marginRight:"17px"}} />
      <div style={{marginRight:"6px",width:"80%"}}>
        <p  className='task-heading'> {truncateString(task?.heading,43)}</p>
        <p  className='task-subheading'>
         {truncateString(task?.subheading,43)}
        </p>
      </div>
      <div style={{display:"flex",width:'18%'}}>
        <div className='task-priority' style={{background:color,marginRight:"7px"}}/>
        <p className='task-priority-text'>{task?.priority}</p>
      </div>
    </div>
  )
}

export default TaskItem
