import React from "react";
import { Box, Button, Divider, Modal, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  rowGap: "10px",
};
const ViewPlanModal = ({ open, close, data }) => {
  const SaveButton = styled(Button)(() => ({
    color: "#fff",
    width: 100,
    backgroundColor: "#ed9b00",
    borderRadius: 150,
    "&:hover": {
      color: "#fff",
      backgroundColor: "#ed9b00",
      border: 0,
      top: -2,
      boxShadow: "0px 3px 5px #FFA700",
    },
  }));
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            rowGap: "7px",
            overflow: "hidden",
          }}
        >
          <div style={{ color: "#ed9b00" }}>Add Learning Plan</div>
          <div
            style={{
              border: "1px solid",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              rowGap: "1rem",
              overflow: "auto",
              height: 200,
              borderColor: "rgba(0, 0, 0, 0.87)",
            }}
          >
            {data?.map((item) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                  key={item.id}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <div>{item?.plan} :</div>
                    <div>{item?.date},</div>
                    <div>{item?.time}</div>
                  </div>
                  <div>{item?.value}</div>
                </div>
              );
            })}
          </div>
        </div>
        <Divider />
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
          }}
        >
          <TextField
            label="Add Plan"
            id="standard-textarea"
            multiline
            rows={3}
            variant="outlined"
          />
          <div
            style={{
              padding: "10px",
              textAlign: "end",
            }}
          >
            <SaveButton onClick={close}>Add</SaveButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default ViewPlanModal;
