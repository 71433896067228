import { Box, Modal, Typography } from "@mui/material";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import YellowBorderHover from "../../components/common/YellowBorderHover";
import YellowButton from "../../components/common/yellowButton";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};
const ApprovalModal = ({
  open,

  message,
  close,
}) => {

  const isSuccess = message === "students chapter content update successfully." || message === "Teachers chapter content review status updated successfully." || message === "Concept successfully reviewed.";



  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <ReportGmailerrorredOutlinedIcon
              style={{ color: "#FFA700", fontSize: 120 }}
            /> */}
            {isSuccess ? (
              <CheckCircleOutlinedIcon style={{ color: "FFA700", fontSize: 120 }} />
            ) : (
              <ReportGmailerrorredOutlinedIcon style={{ color: "#FFA700", fontSize: 120 }} />
            )}

          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {/* {message == "Please at least add one sub-concept and root-concepts then try either approval or reject." ?  (<p>Please Add a sub-concept</p> ):
            message == "students chapter content update successfully." || "Teachers chapter content review status updated successfully." ?(<p>Content approved successfully</p>) : (<p>{message}</p>)
            } */}

              {message === "Please at least add one sub-concept and root-concepts then try either approval or reject." ? (
                <p>Please Add a sub-concept</p>
              ) : isSuccess ? (
                <p>Content approved successfully</p>
              ) : (
                <p>{message}</p>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  columnGap: "1rem",
                }}
              >

                <YellowButton onClick={() => close()}>
                  OK
                </YellowButton>
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default ApprovalModal;
