import axios from 'axios'

function student_contentType_getall(setContenttype){
    let token = sessionStorage.getItem("token");

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/misc/content-types`,
    {
        headers : {
            "Content-Type":"application/json",
            Authorization :`Bearer ${token}`
        }
    })
    .then((res)=>{
        setContenttype(res.data.data)
    })
}

export default student_contentType_getall