import axios from 'axios'

async function training_subtraining_table_get(
  setTrainingtableData,
  rowsInput,
  page,
  setPageDetail,
  search, sortOrder, sortBy) {
  let token = sessionStorage.getItem("token");
  if (sortOrder && sortBy) {

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training-category?search=${search.search}&pageNumber=${page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy} `,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTrainingtableData(res.data.data.data)
        setPageDetail(res.data.data)
      })
      .catch(err => console.log(err))
  }
}

export default training_subtraining_table_get;
