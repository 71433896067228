import axios from 'axios'

function quiz_last_modified(setLastModifiedDate){
    let token = sessionStorage.getItem('token');

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/last-modified-at` ,
    {
        headers : {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setLastModifiedDate(res.data)
    })
    .catch(err => console.log(err))
}

export default quiz_last_modified ;