import React from "react";

//Css
import "../../../css/Student/ChapterQuizKey/ChapterQuizKey_Cs.css";

// Images Used
import tickIcon from "../../../../img/tick-circle.svg";
import cancelIcon from "../../../../img/cancel.svg";
import circleIcon from "../../../../img/circle-thin.svg";
import checkboxPlain from "../../../../img/checkbox-plain.svg";
import checkboxGreen from "../../../../img/checkbox-green-tick.svg";
import checkboxCross from "../../../../img/checkbox-cross.svg";

//Mui
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";

function ChapterQuizKey_Cs({ list, currentQuestion }) {
  const listData = list?.questionAnswerKeys[currentQuestion];
  return (
    <>
      <div>
        <div className="cqk_CS_div">
          {/*-------| Quiz Case-Study Para & Imge Division Starts Here |-------*/}
          <div className="cqk_Para_and_img">
            <div className="cqk_head_1">
              {/* <h6>CASE Study</h6> */}
            </div>
            <div className="cqk_Para_div_1">
              <p
                className="cqk_Para"
                dangerouslySetInnerHTML={{
                  __html: listData?.question,
                }}
              ></p>
              <p
                className="cqk_Cs_Explanation"
                dangerouslySetInnerHTML={{
                  __html: listData?.explanation,
                }}
              ></p>

              {/* <div>
                <img className="cqk_Cs_img" src="" alt="Case-Study-Img" />
              </div> */}
            </div>
          </div>
          {/*-------| Quiz Case-Study Para & Imge Division Ends Here |------*/}

          {/*-------| Quiz Case-Study Sub-Question & Answer Division Starts Here |-------*/}
          <div className="cqk_SubQuestion_Section">
            <div className="cqk_Qstns_and_Ans">
              {/*-------|Sub-Question & Ans Division |-------*/}
              <div>
                {listData?.subQuestions?.map((itm, index) => {
                  return (
                    <>
                      <div className="cqk_Img_and_Para">
                        <p
                          className="cqk_Q_para"
                          dangerouslySetInnerHTML={{
                            __html: `Q${index + 1})${itm?.question}`,
                          }}
                        ></p>

                        {/* <img className="cqk_sub_Qustn_img" src="" /> */}
                      </div>

                      {/*-------| Sub-Question Explanation |-------*/}
                      <div className="SubQstn_Explanation">
                        {itm?.explanation && <h5>Explanation</h5>}
                        <p
                          className="cqk_Q_para"
                          dangerouslySetInnerHTML={{
                            __html: itm?.explanation,
                          }}
                        ></p>
                      </div>

                      {/*-------| Sub-Question Answer Fields (MSQ, MCQ, FIB) Starts Here  |-------*/}
                      <div className="cqk_Cs_Ans_Fields">
                        <div>
                          <h6 className="cqk_title_">Your Answer</h6>
                        </div>

                        {itm.questionTypeCode === "FILL" ? (
                          <div>
                            <div>
                              {/*---------------------------| FIB Answer |---------------------------*/}
                              {itm?.fillInTheBlanks?.map((itm1, index) => {
                                const name = itm?.ftbAnswerByStudent
                                  ? itm?.ftbAnswerByStudent.some(
                                    (item) => item?.order === itm1?.order
                                  )
                                  : false;

                                const ansCheck = itm?.ftbAnswerByStudent
                                  ? itm?.ftbAnswerByStudent.some(
                                    (item) =>
                                      item?.answerKey == itm1?.answerKey[0]
                                  )
                                  : "";

                                const answerFib = itm?.ftbAnswerByStudent?.filter(abs => abs?.order === itm1?.order)

                                return (
                                  <>
                                    <div className="cqk_CsFib_Option_and_Ans">
                                      <div className="cqk_CsFib_Option">
                                        {String.fromCharCode(64 + itm?.order)}
                                      </div>
                                      {/*-------| Displaying the FIB Answer |-------*/}
                                      <div
                                        className="cqk_CsFib_Ans_div"
                                        style={{
                                          background:
                                            ansCheck === true
                                              ? "#E7FFE8" //green
                                              : name === false
                                                ? "white"
                                                : "#FCD3D3", //red
                                        }}
                                      >
                                        <div className="cqk_CsFib_tick_And_Cancel_Icon">
                                          {ansCheck === true ? (
                                            <img
                                              src={tickIcon}
                                              width="22px"
                                              height="22px"
                                            />
                                          ) : name === false ? (
                                            ""
                                          ) : (
                                            <img
                                              src={cancelIcon}
                                              width="25px"
                                              height="25px"
                                            />
                                          )}
                                        </div>

                                        <div>
                                          <p>
                                            {/* {itm?.attended === false
                                              ? "Answer"
                                              : itm?.ftbAnswerByStudent &&
                                                itm?.ftbAnswerByStudent[index]
                                              ? itm?.ftbAnswerByStudent &&
                                                itm?.ftbAnswerByStudent[index]
                                                  .answerKey
                                              : "Answer"} */}
                                            {
                                              name === false
                                                ? itm1?.answerKey[0]
                                                : answerFib && answerFib[0].answerKey
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    {ansCheck === false && name === true && (
                                      <div className="cqk_CsFib_CorrectAnsLabel">
                                        Correct Answer: {itm1?.answerKey}
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        ) : itm?.questionTypeCode === "MCQ" ? (
                          <>
                            <div className="cqk_Ans_MCQ_cs">
                              {/*-------| Displaying the MCQ Ans |-------*/}
                              <div>
                                {itm?.multiChoiceOrSelect.map((itm1, index) => {
                                  const name = itm?.mcsqAnswerByStudent
                                    ? itm?.mcsqAnswerByStudent.some(
                                      (item) => item?.option === itm1?.option
                                    )
                                    : false;

                                  return (
                                    <>
                                      <div
                                        className="Ans1"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          background:
                                            itm1?.correctAnswer === true
                                              ? "#E7FFE8" //green
                                              : name === false
                                                ? "white"
                                                : "#FCD3D3", //red
                                        }}
                                      >
                                        <span>
                                          {itm1?.correctAnswer === true ? (
                                            <CheckCircleIcon
                                              style={{ color: "#4CAF50" }}
                                            />
                                          ) : name === false ? (
                                            <img
                                              src={circleIcon}
                                              width="25px"
                                              height="auto"
                                            />
                                          ) : (
                                            <CloseIcon style={{ color: "red" }} />
                                          )}
                                        </span>
                                        {itm1?.htmlTag === null && itm1?.answerKey}
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: itm1?.htmlTag,
                                          }}
                                        ></p>
                                      </div>
                                      {name === true &&
                                        (
                                          <div
                                            style={{
                                              color: "#1B1B1B",
                                              fontSize: "15px",
                                              fontWeight: "bold",
                                              margin: "5px 0 0 25px",
                                            }}
                                          >
                                            Your Answer
                                          </div>
                                        )}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="cqk_Ans1_MSQ_cs">
                              <div>
                                {/*---------------------------| Displaying MSQ Answer |---------------------------*/}
                                {itm?.multiChoiceOrSelect?.map((itm1, index) => {
                                  const name = itm?.mcsqAnswerByStudent
                                    ? itm?.mcsqAnswerByStudent.some(
                                      (item) => item?.option === itm1?.option
                                    )
                                    : false;
                                  return (
                                    <>
                                      <div
                                        className="Ans1_MSQ"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          background:
                                            itm1?.correctAnswer === true
                                              ? "#E7FFE8" //green
                                              : name === false
                                                ? "white"
                                                : "#FCD3D3", //red
                                        }}
                                      >
                                        <span>
                                          {itm1?.correctAnswer === true ? (
                                            <img
                                              src={checkboxGreen}
                                              width="20px"
                                              height="20px"
                                            />
                                          ) : name === false ? (
                                            <img
                                              src={checkboxPlain}
                                              width="20px"
                                              height="20px"
                                            />
                                          ) : (
                                            <img
                                              src={checkboxCross}
                                              width="20px"
                                              height="20px"
                                            />
                                          )}
                                        </span>
                                        {itm1?.htmlTag === null && itm1?.answerKey}
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: itm1?.htmlTag,
                                          }}
                                        ></p>
                                      </div>
                                      {name === true && (
                                        <div
                                          style={{
                                            color: "#1B1B1B",
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                            margin: "5px 0 0 25px",
                                          }}
                                        >
                                          Your Answer
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {/*-------| Sub-Question Answer Fields (MSQ, MCQ, FIB) Ends Here  |-------*/}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          {/*-------| Quiz Case-Study Sub-Question & Answer Division Ends Here |-------*/}
        </div>
      </div>
    </>
  );
}

export default ChapterQuizKey_Cs;
