export const SELECT_QUESTION = "SELECT_QUESTION";
export const UNSELECT_QUESTION = "UNSELECT_QUESTION";
export const ORDER_SELECTED_QUESTION = "ORDER_SELECTED_QUESTION";
export const SET_QUIZ_TYPE = "SET_QUIZ_TYPE";
export const QUIZ_CREATION_STEP = "QUIZ_CREATION_STEP";
export const QUIZ_CREATION_RESET = "QUIZ_CREATION_RESET";
export const RESET_SELECTED_QUESTIONS = "RESET_SELECTED_QUESTIONS";
export const EDIT_UNSELECT_QUESTION = "EDIT_UNSELECT_QUESTION";


