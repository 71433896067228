import React, { useState } from "react";
import { Link , useNavigate} from "react-router-dom";

// Css Pages
import "../../../../src/pages/css/Student/Student_SideNavbar.css";

// Images & Icons Used
import dashHome from "../../../../src/img/dashHome.svg";
import admin from "../../../../src/img/Adminuser.svg";
import enrichIcn from "../../../../src/img/teach.svg";
import reportIc from "../../../../src/img/Reports.svg";
import studyIcn from "../../../../src/img/Masters.svg";
import {
  ListItemText,
  Menu,
  MenuItem,
  ListSubheader
} from "@mui/material";
import Paper from '@material-ui/core/Paper';

const Student_SideNavbar = ({
  elem,
  i,
  schoolSublinkVisible,
  navBarVis,
  handleLinkToggle,
  Navmenu,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [subMenuNameValue, setSubMenuNameValue] = useState("");

  let menuName = sessionStorage.getItem("sideNavMainMenuNames");
  let subNavMenuName = sessionStorage.getItem("sideNavSubMenuNames");
   
  // console.log('student side menu >> ',menuName,subNavMenuName);
  // console.log(">>", navBarVis, schoolSublinkVisible);

  const handleHighlight = (clicked) => {
    if (clicked === 'Reports' && !navBarVis) {
      console.log(">>side R.menu>>", clicked);
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
    sessionStorage.setItem("sideNavMainMenuNames", clicked);
    sessionStorage.removeItem("sideNavSubMenuNames");
  };

  const handleHighlightSubMenu = (clicked) => {
    setShowPopup(false);
    console.log(">>side sub-menu>>");
    sessionStorage.setItem("sideNavSubMenuNames", clicked);
    sessionStorage.setItem("sideNavMainMenuNames", "Reports");
  };

  const handleClose = (reportValue) => {
    console.log(">>>>>>>>>>>>>>>",reportValue);
    setSubMenuNameValue(reportValue);
    setShowPopup(false);
    if(reportValue !== "Overall Performance"){
      navigate(`/dashboard/${encodeURIComponent(reportValue)}`);
    }else{
      navigate(`/dashboard/${encodeURIComponent("Student%20Overall%Performance")}`);
    }
   
  };

  // Rendering the Menu Icons
  const menuIcons = {
    Dashboard: dashHome,
    Study: studyIcn,
    Enrich: enrichIcn,
    Reports: reportIc,
    FormalAssessmentQuiz: reportIc, // Import and Change the Icon Here
  };
  const defaultIcon = admin;
  const selectedIcon = menuIcons[elem.menu.replace(/ /g, "")] || defaultIcon;

  return (
    <>
      <div    style={{ paddingTop: "0px" }}>
        {/*-------| Side Navbar Menu Names & Navigation Starts |-------*/}
        {elem.menu === "Reports" ? (
          <div
          id={`az-student-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
            key={elem.menuId}
            className="sidenav_parent"
            onClick={elem.subMenus && handleLinkToggle}
          >
            <div
              className={`icon_and_text cursorPointer ${menuName === elem.menu ? "" : "icon_and_text_active" }`}
              onClick={() => handleHighlight(elem.menu)}
            >
               
              {/*-------| Side Navbar Icons|-------*/}
              <div
                className={`sidenav_icons ${menuName === elem.menu ? "sidenav_icons_active" : ""
                  }`}
              >
                <img src={selectedIcon} alt="no img" />

              </div>

              {menuName === elem.menu ? (
                <div className="sidenav_text_selected">{elem.menu}</div>
              ) : (
                <div>
                  <div className="sidenav_text">{elem.menu}</div>
                </div>
              )}

              {/*-------| Side Navbar "Reports" Dropdown Arrow Icon|-------*/}
              <div className="report_arrow_icon">
                <i
                  className={
                    schoolSublinkVisible && navBarVis
                      ? "fa-solid fa-angle-down"
                      : "fa-solid fa-angle-right"
                  }
                  style={
                    menuName === elem.menu ? { color: "#ffffff" } : { color: "#1b1b1b" }
                  }
                  id="toggle_btn_icon"
                ></i>
              </div>
            </div>
          </div>
        ) : (
          <Link
          id={`az-student-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
            style={{ textDecoration: "none" }}
            to={`/dashboard/${elem.menu.toLowerCase()}`}
          >
            <div
              key={elem.menuId}
              className="sidenav_parent"
              onClick={elem.subMenus && handleLinkToggle}
            >
              <div
                className={`icon_and_text ${menuName === elem.menu ? "" : "icon_and_text_active"
                  }`}
                onClick={() => handleHighlight(elem.menu)}
              >
                {/*-------| Side Navbar Icons|-------*/}
                <div
                  className={`sidenav_icons ${menuName === elem.menu ? "sidenav_icons_active" : ""
                    }`}
                >
                  <img src={selectedIcon} alt="no img" />
                </div>
                {elem.subMenus ? (
                  <div className={
                    menuName === elem.menu ? "sidenav_text_selected" : "sidenav_text"
                  }>{elem.menu}</div>
                ) : (
                  <div>
                    <div className={
                      menuName === elem.menu ? "sidenav_text_selected" : "sidenav_text"
                    }>{elem.menu}</div>
                  </div>
                )}
              </div>
            </div>
          </Link>
        )}
        {/*-------| Side Navbar Menu Names & Navigation Ends |-------*/}

        {/*-------| Side Navbar "Reports" Dropdown Menu Names & Navigation Starts |-------*/}
        <div
          key={i}
          style={
            schoolSublinkVisible ? { display: "block" } : { display: "none" }
          }
        >
          <div>
            {elem.subMenus &&
              elem.subMenus.map((item) => {
               
                return (
                  <div
                  id={`az-student-sidemenu-${item.subMenuName.toLowerCase().replace(/ /g, "_")}`}
                  //  id="registered_schools_link"
                    key={item.subMenuId}
                    style={
                      navBarVis ? { display: "block" } : { display: "none" }
                    }
                  >
                    <div>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={
                          item.subMenuName === "Chapter Reports"
                            ? "Chapter%20Reports"
                            : item.subMenuName === "Overall Performance"
                              ? "Student%20Overall%Performance"
                              : item.subMenuName
                        }
                      >
                        <div
                          key={item.subMenuId}
                          // className={`reports ${subNavMenuName === item.subMenuName
                          //     ? "" && "activeSubMenu"
                          //     : ""
                          //   }`}
                          className={`reports ${subNavMenuName === item.subMenuName ? "activeSubMenu" : ""}`}

                          onClick={() => handleHighlightSubMenu(item.subMenuName)}
                        >
                          {/*-------| "Reports" Icons Starts |-------*/}

                          <div className="reports_icons">
                            {
                              item.subMenuName === "Chapter Reports" ? (
                                <span
                                  className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                      ? "subMenuIcon_active"
                                      : ""
                                    }`}
                                ></span>
                              ) :
                                item.subMenuName === "Overall Performance" ? (
                                  <span
                                    className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                        ? "subMenuIcon_active"
                                        : ""
                                      }`}
                                  ></span>
                                ) : item.subMenuName === "Formal Report" ? (
                                  <span
                                    className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                        ? "subMenuIcon_active"
                                        : ""
                                      }`}
                                  ></span>
                                ) : (
                                  ""
                                )}
                          </div>
                          <div className={
                            subNavMenuName === item.subMenuName
                              ? "reports_text_selected"
                              : "reports_text"
                          }>{item.subMenuName === 'Formal Report' ? 'Formal Assessment Report' : item.subMenuName}</div>
                          {/*-------| "Reports" Icons Ends |-------*/}
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {/*-------| Side Navbar "Reports" Dropdown Menu Names & Navigation Ends |-------*/}
        {showPopup && (
         
          <Menu
            id="basic-menu"
            open={showPopup}
            onClose={() => setShowPopup(false)}
            transformOrigin={{ horizontal: "left", vertical: "center" }}
            anchorOrigin={{ horizontal: "left", vertical: "center" }}
            sx={{paddingTop : "0px" }}          
            PaperProps={{
              style: {
                borderRadius: "20px",
                //padding: 0 !important
              },
            }}
          >
             <ListSubheader
             sx={{ typography: 'h8',
             paddingTop: "0px",
             color: "#1b1b1b" ,
             background : "#ffa500"}}>Report</ListSubheader>

            {elem.subMenus &&
              elem.subMenus.map((item) => (
                <MenuItem
                  key={item.subMenuId}
                  onClick={() => handleClose(item.subMenuName)}    
                  PaperProps={{
                    style: {
                   
                      paddingTop: "0"
                    },
                  }}  
                >
                  <ListItemText
                    sx={{
                     
                      typography: 'h7',
                      paddingTop: "0px",
                     // color : "#ff2309"
                      color: subMenuNameValue === item.subMenuName ? "#ffa500" : "#000000",
                    }}
                   >
                    {item.subMenuName === 'Formal Report'
                      ? 'Formal Assessment Report'
                      : item.subMenuName}
                  </ListItemText>
                </MenuItem>
              ))}
          </Menu>
         
        )}
      </div>
    </>
  );
};

export default Student_SideNavbar;