import axios from 'axios'

function assessment_post(asssessmentInputs,setSuccess,setShowError){
    let token = sessionStorage.getItem('token');
    
    var item = {
        "academicYearId": asssessmentInputs.academicYearId,
        "assessmentUrl": "file://content.png",
        "boardId": asssessmentInputs.boardId,
        "gradeId": asssessmentInputs.gradeId,
        "paperTitle": asssessmentInputs.paperTitle,
        "reason": "Reject reason: incorrect data",
        "reviewStatus": "PENDING",
        "subjectId": asssessmentInputs.subjectId
      }

    let result = axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper`,item ,
    {
        headers : {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`    
        }
    })
    .then((res)=> {
        // console.log(res.data.data)
        setShowError(res.message)
        setSuccess(res.data)
    })
    .catch(err=> console.log(err))
}

export default assessment_post