import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import ForumTwoToneIcon from "@mui/icons-material/ForumTwoTone";
import Pagination from "../General/Pagination";
import ViewQuizTable from "./ViewQuizTable";

function ViewquizTableComponent({
	quizes,
	pageSize,
	setPageSize,
	currentPage,
	setCurrentPage,
	totalPages,
	setSelectedQuiz,
	setPreview,
	handleSort,
	startIndexfrom
}) {

	useEffect(() => {
		setCurrentPage(0);
	}, [pageSize]);

	return (
		<Conatiner>
			<ViewQuizTable
				quizes={quizes}
				setSelectedQuiz={setSelectedQuiz}
				setPreview={setPreview}
				handleSort={handleSort}
				startIndexfrom={startIndexfrom}
			/>
			<Pagination
				totalData={quizes && quizes.length}
				totalPages={totalPages}
				pageSize={pageSize}
				setPageSize={setPageSize}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
		</Conatiner>
	);
}

export default ViewquizTableComponent;
const Conatiner = styled.div`
	position: relative;
	height: calc(100vh - 200px);
	background-color: #fff;
	min-width: calc(100vw - 240px) !important;
	width: calc(100vw - 240px) !important;
`;
