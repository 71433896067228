import React from "react";

export default function BluePrintTab({ Selected, setSelected }) {
  const handleSelectTab = (value) => {
    setSelected(value);
  };

  return (
    <div>
      <section>
        <div className="blue-print-tab-wrapper">
          <div
            style={{
              position: "relative",
              color: Selected == "Pending" ? "#FAA220" : "",
            }}
            className="blue-print-tab"
            onClick={(e) => handleSelectTab("Pending")}
          >
            Pending
            <div
              style={{
                position: "absolute",
                height: "50px",
                width: "4px",
                backgroundColor: "#DAEBFF",
                top: "0px",
                right: "0px",
              }}
            />
          </div>
          <div
            style={{
              position: "relative",
              color: Selected == "Rejected" ? "#FAA220" : "",
            }}
            className="blue-print-tab"
            onClick={(e) => handleSelectTab("Rejected")}
          >
            Rejected
            <div
              style={{
                position: "absolute",
                height: "50px",
                width: "4px",
                backgroundColor: "#DAEBFF",
                top: "0px",
                right: "0px",
              }}
            />
          </div>
          <div
            className="blue-print-tab"
            onClick={(e) => handleSelectTab("Approved")}
            style={{ color: Selected == "Approved" ? "#FAA220" : "" }}
          >
            Approved
          </div>
        </div>
      </section>
    </div>
  );
}
