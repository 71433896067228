import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  customColor: {
    color: "#EE5D45",
  },
  customColor2: {
    color: "#D9D9D9",
  },
}));

export default function CircularDeterminate({ progressValue, size = size }) {
  const classes = useStyles();

  return (
    <CircularProgress
      thickness={10}
      size={size}
      variant="determinate"
      value={progressValue == 0 ? 100 : progressValue}
      className={
        progressValue == 0 ? classes.customColor2 : classes.customColor
      }
    />
  );
}
