import React from "react";
import "./../css/BluePrint.css";
import { useState } from "react";
import {
  Box,
  MenuItem,
  TextField,
  Button,
  FormControl,
  Select,
  InputLabel,
  Switch,
  Paper,
  IconButton,
} from "@mui/material";
import sample_bluePrint from './../../img/sample_bluePrint.png'
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { DataGrid, } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import ButtonMenu from "./ButtonMenu.js";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import GetAppIcon from "@mui/icons-material/GetApp";
import ButtonGrade from "./ButtonGrade.js";
import ButtonSubject from "./ButtonSubject";


const BluePrint = () => {
  const [Expanded, setExpanded] = useState(false);
  const cols  = [
    { field: "id",headerClassName: 'super-app-theme--header', headerName: "ID", width: 90 },
    {
      field: "paperTitle",
      headerClassName: 'super-app-theme--header',
      headerName: "Paper Title",
      width: 150,
      editable: false,
    },
    {
      field: "paperType",
      headerClassName: 'super-app-theme--header',

      headerName: "Paper Type",
      width: 100,
      editable: false,
    },
    {
      field: "grade",
      headerClassName: 'super-app-theme--header',
      headerName: "Grade",
      type: "number",
      width: 80,
      editable: false,
    },
    {
      field: "subject",
      headerClassName: 'super-app-theme--header',
      headerName: "Subject",
      sortable: false,
      width: 160,
    },
    {
      field: "subTopics",
      headerClassName: 'super-app-theme--header',
      headerName: "SubTopics",
      sortable: false,
      width: 160,
    },
    {
      field: "totalMarks",
      headerClassName: 'super-app-theme--header',
      headerName: "TotalMarks",
      sortable: false,
      width: 90,
    },
    {
      field: "totalQuestions",
      headerClassName: 'super-app-theme--header',
      headerName: "TotalQuestions",
      sortable: true,
      width: 100,
    },
    {
      field: "time",
      headerClassName: 'super-app-theme--header',
      headerName: "Time",
      sortable: true,
      width: 80,
    },
    {
      field: "active",
      headerClassName: 'super-app-theme--header',
      headerName: "Active",
      sortable: false,
      width: 80,
      renderCell: (param) => (
        <strong>
          <Switch variant="contained" size="small"></Switch>
        </strong>
      ),
    },
    {
      field: "action",
      headerClassName: 'super-app-theme--header',
      headerName: "Action",
      sortable: false,
      width: 169,
      renderCell: (param) => (
        <strong>
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>

          <IconButton aria-label="delete">
            <ModeEditIcon />
          </IconButton>
        </strong>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      paperTitle: "CBSE-SCI-PRAC-5",
      paperType: "Term",
      grade: 35,
      subject:"Chemistry",
      subTopics: "Physics",
      totalMarks: "12",
      totalQuestions: "12",
      time: "40min",
    },
    {
      id: 2,
      paperTitle: "CBSE-SCI-PRAC-5",
      paperType: "Term",
      grade: 42,
      subject:"Chemistry",
      subTopics: "Physics",
      totalMarks: "12",
      totalQuestions: "12",
      time: "40min",
    },
    {
      id: 3,
      paperTitle: "CBSE-SCI-PRAC-5",
      paperType: "Term",
      grade: 45,
      subject:"Chemistry",
      subTopics: "Physics",
      totalMarks: "12",
      totalQuestions: "12",
      time: "40min",
    },
    {
      id: 4,
      paperTitle: "CBSE-SCI-PRAC-5",
      paperType: "Term",
      grade: 16,
      subject:"Chemistry",
      subTopics: "Physics",
      totalMarks: "12",
      totalQuestions: "12",
      time: "40min",
    },
    {
      id: 5,
      paperTitle: "CBSE-SCI-PRAC-5",
      paperType: "Practice",
      grade: "20",
      subject:"Chemistry",
      subTopics: "Physics",
      totalMarks: "12",
      totalQuestions: "12",
      time: "40min",
    },
    {
      id: 6,
      paperTitle: "CBSE-SCI-PRAC-5",
      paperType: "practice",
      grade: 150,
      subject:"Chemistry",
      subTopics: "Physics",
      totalMarks: "12",
      totalQuestions: "12",
      time: "40min",
    },
    {
      id: 7,
      paperTitle: "CBSE-SCI-PRAC-5",
      paperType: "Term",
      grade: 44,
      subject:"Chemistry",
      subTopics: "Physics",
      totalMarks: "12",
      totalQuestions: "12",
      time: "40min",
    },
    {
      id: 8,
      paperTitle: "CBSE-SCI-PRAC-5",
      paperType: "Term",
      grade: 36,
      subject:"Chemistry",
      subTopics: "Physics",
      totalMarks: "12",
      totalQuestions: "12",
      time: "40min",
    },
    {
      id: 9,
      paperTitle: "CBSE-SCI-PRAC-5",
      paperType: "Term",
      grade: 65,
      subject:"Chemistry",
      subTopics: "Physics",
      totalMarks: "12",
      totalQuestions: "12",
      time: "40min",
    },
  ];

  const handleBluePrint = () => {
    setExpanded(true);
  };

  const handleCancel = () => {
    setExpanded(false);
  };

  const paperType = ["term", "practice"];

  return (
    <div className="container">
      
      <div
        style={{
          display:"flex",
          fontSize: "10px",
          padding: "7px",
          backgroundColor: " #f3f2ff",
        }}
      >
        <i className="fa-solid fa-house" style={{marginRight:"3px"}} ></i>
        {" "} Home {" "}
        <Box sx={{marginLeft:"4px", marginRight:"4px"}}>
        <KeyboardDoubleArrowRightIcon fontSize="10px"></KeyboardDoubleArrowRightIcon>{" "}
        </Box>
        <div style={{color:"#0e26f4"}}>
        Blue Print
        </div> 

      </div>
      {!Expanded ? (
        <div className="blue_print_list">
          <div style={{ paddingTop: 10, display:"flex", width:"100%", justifyContent:"space-between"}}>
            <div style={{display:"flex"}}>
              <div style={{marginTop:"5px"}}>
            <i className="fab fa-fort-awesome"></i> Blue Print List
             </div>
           
           <div style={{display:"flex", }}>
            <Box sx={{marginRight:"10px",  marginLeft:"10px"}}>
          <ButtonMenu >  </ButtonMenu>
          </Box>
          
          <ButtonGrade></ButtonGrade>
          <Box sx={{marginLeft:"10px"}}>
          <ButtonSubject></ButtonSubject>
          </Box>
          </div>
         </div>
            {/* <Box sx={{display:"flex",  justifyContent:"flex-end",}}>
          <SearchBar></SearchBar>
          </Box> */}
            <Button
              variant="contained"
              size="small"
              sx={{ borderRadius: "16px", backgroundColor: "#63b966" }}
              onClick={handleBluePrint}
            >
              Add Blue Print{" "}
            </Button>

            </div>
        </div>
      ) : (
        <div>
          <div style={{ margin: "10px", marginLeft: "15px" }}>
            <i className="fab fa-fort-awesome"></i>
            {""} Add Blue Print
          </div>

          
          <form className="form-content">
            <Paper sx={{ padding: 2, margin: 2, minwidth: 800 }} elevation={4}>
              <FormControl
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "row",
                }}
                fullWidth
              >
                <Grid
                  container
                  spacing={4}
                  margin="10px"
                  justifyContent="space-between"
                >
                  <Grid item xs>
                    <TextField label={"PaperTitle"} sx={{ width: "300px" }} />
                    {/* <InputLabel sx={{left:'auto'}}>PaperType</InputLabel> */}
                  </Grid>

                  <Grid item xs>
                    <Select
                      sx={{ width: "300px" }}
                      label={"PaperType"}
                      MenuProps={{
                        PaperProps: { sx: { maxHeight: 200 } },
                      }}
                    >
                      {paperType.map((paperTypeValue) => {
                        return (
                          <MenuItem value={paperTypeValue}>
                            {paperTypeValue}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs>
                    <Select
                      sx={{ width: "300px" }}
                      label={"Board"}
                      MenuProps={{
                        PaperProps: { sx: { maxHeight: 200 } },
                      }}
                    >
                      {paperType.map((paperTypeValue) => {
                        return (
                          <MenuItem value={paperTypeValue}>
                            {paperTypeValue}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>

                  <Grid item xs>
                    <Select
                      sx={{ width: "300px" }}
                      label={"Grade"}
                      MenuProps={{
                        PaperProps: { sx: { maxHeight: 200 } },
                      }}
                    >
                      {paperType.map((paperTypeValue) => {
                        return (
                          <MenuItem value={paperTypeValue}>
                            {paperTypeValue}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>

                  <Grid item xs>
                    <Select
                      sx={{ width: "300px" }}
                      label={"Subject"}
                      MenuProps={{
                        PaperProps: { sx: { maxHeight: 200 } },
                      }}
                    >
                      {paperType.map((paperTypeValue) => {
                        return (
                          <MenuItem value={paperTypeValue}>
                            {paperTypeValue}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>

                  <Grid item xs>
                    <TextField label={"Sub Topics"} sx={{ width: "300px" }} />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      label={"Total Number of Questions"}
                      sx={{ width: "300px" }}
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField label={"Total Marks"} sx={{ width: "300px" }} />
                  </Grid>

                  <Grid item xs>
                    <TextField label={"Total Time"} sx={{ width: "300px" }} />
                  </Grid>

                  <Box sx={{ marginLeft: "50px" }}>
                    {" "}
                    Upload file <a href={sample_bluePrint} download><GetAppIcon/></a>
                    <Button
                      variant="contained"
                      sx={{ borderRadius: "16px", m: "30px" }}
                    >
                      {" "}
                      Choose File
                  </Button>
                  </Box>

                  <Grid container item justifyContent={"flex-end"}>
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{ borderRadius: "16px", mr: "20px", width: "220px" }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ borderRadius: "16px", width: "220px" }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            </Paper>
            <div></div>
          </form>
        </div>
      )}


        
      <Box
       sx={{
        height: 500, width: "100%",
         
        '& .super-app-theme--header': {
          backgroundColor: '#d9dcfe',color:"#0e26f4" }, 
        
      }}
      
      >
        <DataGrid
        //  sx={{[`& .${gridClasses.row}`] : {bgcolor:"purple"} }} 
         rows={rows} columns={cols}   
        />
      </Box>
    </div>
  );
};

export default BluePrint;
