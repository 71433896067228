import axios from 'axios'

function rootConcept_controller_get(subConceptInputs, setRootConceptList) {
    let token = sessionStorage.getItem("token")

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/root-concepts/all?subConceptId=${typeof subConceptInputs === 'object' ? subConceptInputs?.subConceptId : subConceptInputs}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        .then((res) => {
            setRootConceptList(res.data.data.data)
        })
        .catch(err => console.log(err))
}

export default rootConcept_controller_get