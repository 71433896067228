import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// import YellowButton from './yellowButton';
import crossIcon from "../../img/cross icon.svg";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import {
  Table,
  Paper,
  TableBody,
  TableCell,
  Backdrop,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import sortIcon from "../img/sort.png"
import commonsort from "../js/CommonSort"
const BoardModal = ({ open, close, boards }) => {

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(true);
  const [details, setDetails] = useState([])

  
  useEffect(() => {
    // console.log(boards.schoolDetails, "==============")
    setDetails(boards.schoolDetails)
  },[])

  const onSort = async (sortOrder, field) => {
    console.log(field, boards.schoolDetails)
    setSortOrder(sortOrder)
    var sorted = await commonsort(boards?.schoolDetails || [], field, sortOrder);
    console.log(sorted)
    setDetails(sorted) //coordinator this should come
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    // height:500,
    backgroundColor: "white",
    // border: '2px solid #000',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };
  // console.log(boards, "boardsssss");
  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableBackdropClick={true}
        closeAfterTransition

      >
        <div style={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 style={{ padding: "10px", marginLeft: "50px" }}>
              {boards.board}
            </h3>
            <img style={{ padding: "15px" }} src={crossIcon} onClick={close} />
          </div>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, marginLeft: "40px" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{ color: "#5563FF", borderBottom: "0" }}
                  >
                    School Name
                    <img
                      style={{ position: "absolute" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("name");
                        await onSort(!sortOrder, "name")
                        //await initData(!sortOrder, "grade");
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#5563FF", borderBottom: "0" }}
                  >
                    Branch
                    <img
                      style={{ position: "absolute" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("branchCount");
                        await onSort(!sortOrder, "branchCount")
                        //await initData(!sortOrder, "grade");
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#5563FF", borderBottom: "0" }}
                  >
                    Location
                    <img
                      style={{ position:"absolute"}}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("locality");
                        await onSort(!sortOrder, "locality")
                        //await initData(!sortOrder, "grade");
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#5563FF", borderBottom: "0" }}
                  >
                    Grades
                    <img
                      style={{ position: "absolute" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("totalGrades");
                        await onSort(!sortOrder, "totalGrades")
                        //await initData(!sortOrder, "grade");
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#5563FF", borderBottom: "0", display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    Total Students
                    <img
                      style={{ position: "absolute" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("totalStudents");
                        await onSort(!sortOrder, "totalStudents")
                        //await initData(!sortOrder, "grade");
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(details||boards.schoolDetails)?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{ borderBottom: "0" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "0" }}>
                      {row.branchCount}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "0" }}>
                      {row.locality}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "0" }}>
                      {row.totalGrades}
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "0" }}>
                      {row.totalStudents}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
    </>
  );
};

export default BoardModal;
