import dashboardContentStyles from "../css/dashboardContent.module.css";
import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { MenuProps } from "./Data";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TabList from "@mui/lab/TabList";
import { TabContext } from "@mui/lab";
import TabPanel from "@mui/lab/TabPanel";
import { HorizontalBar } from "react-chartjs-3";
import "chartjs-plugin-datalabels";
import DarkYellowButton from "../../components/common/DarkYellowButton";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import PlanModal from "./PlanModal";
import ViewStudentProfile from "./ViewStudentProfile";
import QuizPerformance from "./QuizPerformance";
import CustomThermometer from "../../components/common/CustomThermometer";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import axios from "axios";
import TopBar from "../../components/common/TopBar";
import { param } from "jquery";
import GenericChart from "./GenericChart";
const options = {
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        offset: true,
        ticks: {
          display: true,
          borderLeft: '1px solid #333',
          fontSize: 18,
          fontColor: "#115d8c",
        },
        datasets: {
          barThickness: 40,
          barPercentage: 0.7,
        },
        // barThickness: 50,  // number (pixels) or 'flex'
        // maxBarThickness: 50 // number (pixels)
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        display: false,
        scaleLabel: {
          display: true,
        },
        offset: false,
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 100,
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        let datasets = ctx.chart.data.datasets;
        if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
          // let sum = datasets[0].data.reduce((a, b) => a + b, 0);
          // let percentage = Math.round((value / sum) * 100) + "%";
          let percentage = Math.round(value) + "%";
          return percentage;
        }
      },
      display: true,
      color: "#115d8c",
      align: "end",
      anchor: "end",
      font: { size: "23" },
    },
  },
  legend: {
    display: false,
  },
};
const planData = [
  {
    id: 1,
    label: "Number System",
    description: "Learning Plan Description",
    planData: [
      {
        id: 1,
        subject: "Physics",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 1",
        date: "12-08-2022",
        time: "11:40 am",
      },
      {
        id: 2,
        subject: "Chemistry",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 2",
        date: "16-04-2022",
        time: "1:40 pm",
      },
      {
        id: 3,
        subject: "Biology",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 3",
        date: "23-07-2022",
        time: "5:40 pm",
      },
    ],
  },
  {
    id: 2,
    label: "Algebra",
    description: "Learning Plan Description",
    planData: [
      {
        id: 1,
        subject: "Physics",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 1",
        date: "12-08-2022",
        time: "11:40 am",
      },
      {
        id: 2,
        subject: "Chemistry",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 2",
        date: "16-04-2022",
        time: "1:40 pm",
      },
      {
        id: 3,
        subject: "Biology",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 3",
        date: "23-07-2022",
        time: "5:40 pm",
      },
    ],
  },
  {
    id: 3,
    label: "Mensuration",
    description: "Learning Plan Description",
    planData: [
      {
        id: 1,
        subject: "Physics",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 1",
        date: "12-08-2022",
        time: "11:40 am",
      },
      {
        id: 2,
        subject: "Chemistry",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 2",
        date: "16-04-2022",
        time: "1:40 pm",
      },
      {
        id: 3,
        subject: "Biology",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 3",
        date: "23-07-2022",
        time: "5:40 pm",
      },
    ],
  },
];
const practiceStaticQuizData = {
  practiceQuizOverAllResponseList: [
    {
      name: 'Physics',
      id: '56458634566546456',
      chapterDetails: [
        {
          chapterId: '5654u678768456436',
          chapterName: 'Source of Enargy',
          scoreLessThanFortyFive: 32,
          pq1: 1,
          pq2: 2,
          highestPQScore: 77
        },
        {
          chapterId: '987654345678',
          chapterName: 'Nanotek',
          scoreLessThanFortyFive: 33,
          pq1: 1,
          pq2: 2,
          highestPQScore: 75
        }
      ]
    }
  ]
}
const PracticeQuizData = [
  {
    id: 1,
    subtopic: "Physics",
    chapterName: "Source of Enargy",
    unitQuiz: "35%",
    Pq1Attempt: "1",
    Pq2Attempt: "1",
    PqScore: "50%",
  },
  {
    id: 2,
    subtopic: "Chemistry",
    chapterName: "Metals & Non-metals",
    unitQuiz: "30%",
    Pq1Attempt: "2",
    Pq2Attempt: "-",
    PqScore: "80%",
  },
  {
    id: 3,
    subtopic: "Physics",
    chapterName: "Source of Enargy",
    unitQuiz: "35%",
    Pq1Attempt: "1",
    Pq2Attempt: "1",
    PqScore: "50%",
  },
  {
    id: 4,
    subtopic: "Chemistry",
    chapterName: "Metals & Non-metals",
    unitQuiz: "30%",
    Pq1Attempt: "2",
    Pq2Attempt: "-",
    PqScore: "80%",
  },
  {
    id: 5,
    subtopic: "Physics",
    chapterName: "Source of Enargy",
    unitQuiz: "35%",
    Pq1Attempt: "1",
    Pq2Attempt: "1",
    PqScore: "50%",
  },
  {
    id: 6,
    subtopic: "Chemistry",
    chapterName: "Metals & Non-metals",
    unitQuiz: "30%",
    Pq1Attempt: "2",
    Pq2Attempt: "-",
    PqScore: "80%",
  },
  {
    id: 7,
    subtopic: "Physics",
    chapterName: "Source of Enargy",
    unitQuiz: "35%",
    Pq1Attempt: "1",
    Pq2Attempt: "1",
    PqScore: "50%",
  },
  {
    id: 8,
    subtopic: "Chemistry",
    chapterName: "Metals & Non-metals",
    unitQuiz: "30%",
    Pq1Attempt: "2",
    Pq2Attempt: "-",
    PqScore: "80%",
  },
];
const quizStaticData = {
  "data": {
    "subTopicSubConceptDetails": [
      {
        "id": "40289288836b282f018379ece03f001a",
        "name": "HUMAN ORGANS",
        "score": 80,
        "chapterDetails": [
          {
            "chapterId": "402892888801bbe301880a7e197c0055",
            "score": "80",
            "chapterName": "CHAPTER FOR NURSERY"
          }
        ]
      },
      {
        "id": "40289288836b282f018379ece043001b",
        "name": "INSECT STUDY",
        "score": 0,
        "chapterDetails": []
      }
    ]
  },
  "message": "Unit quiz overall performance success",
  "success": true,
  "errorCode": null,
  "path": null
}
const diagnosticQuizStaticData = {
  "data": {
    "subTopicSubConceptDetails": [
      {
        "id": "40289288836b282f018379ece03f001a",
        "name": "HUMAN ORGANS",
        "score": 80,
      },
      {
        "id": "40289288836b282f018379ece043001b",
        "name": "INSECT STUDY",
        "score": 60,
      },
    ],
    "overAllQuizScore": 70
  },
  "message": "Diagnostic quiz overall performance success",
  "success": true,
  "errorCode": null,
  "path": null
}

const StudentReport = () => {
  const token = sessionStorage.getItem("token");
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");

  const [tabsValue, setTabsValue] = useState("1");
  const [showPlan, setShowPlan] = useState(false);
  const [showStudentProfile, setShowStudentProfile] = useState(false);
  const [planDetailData, setPlanDetailData] = useState([]);
  const [modifiedDate, setModifiedDate] = useState({});
  const [subjectList, setSubjectList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [pageSize, setPageSize] = useState(100);
  const [studentList, setStudentList] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [selectedStudentData, setSelectedStudentData] = useState({});
  const [selectedSubjectName, setSelectedSubjectName] = useState("");
  const [selectedQuizName, setSelectedQuizName] = useState("");
  const [academicYearList, setAcademicYearList] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  // const [scoreRange, setScoreRange] = useState([
  //   {
  //     id: 1,
  //     name: "Ember"
  //   },
  //   {
  //     id: 2,
  //     name: "Kindle"
  //   },
  //   {
  //     id: 3,
  //     name: "Glow"
  //   },
  //   {
  //     id: 4,
  //     name: "Sparkle"
  //   },
  // ]);
  const [scoreRange, setScoreRange] = useState([]);
  const [selectedScoreRange, setSelectedScoreRange] = useState('');
  const [quizData, setQuizData] = useState([]);
  const [diagnosticQuizData, setDiagnosticQuizData] = useState([]);
  const [practiceQuizData, setPracticeQuizData] = useState([]);
  const [quizList, setQuizList] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState("");
  const [studentMark, setStudentMark] = useState();

  const [isPageLoading, setIsPageLoading] = useState(true)
  const [gradeSection, setGradeSection] = useState([]);
  const [changedFilter, setChangedFilter] = useState('nochange');

  const [practiceLoader, setPracticeLoader] = useState(false);
  const [unitLoader, setUnitLoader] = useState(false);
  const [diagnosticLoader, setDiagnosticLoader] = useState(false);

  const genericDiagnosticData = diagnosticQuizData.map(item => ({
    name: item?.name,
    score: item?.score === null ? 0 : item?.score
  }));

  const genericUnitData = quizData?.map(item => {
    return item?.chapterDetails.map(item2 => ({
      name: item2?.chapterName,
      score: item2?.score === null ? 0 : +item2?.score
    }))
  });

  useEffect(async () => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);

  const fetchSubjectData = async (params) => {
    const token = sessionStorage.getItem("token");
    const response = await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/teachers/subjects?teacherId=${params?.teacherId ? params.teacherId : teacherId
        }&gradeId=${params?.gradeId}&sectionId=${params?.sectionId}&menuName=${params?.menuName}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => console.log(err));
    return response?.data?.data || [];
  };

  const fetchGradeData = async (tId) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${tId}&menuName=TEACH`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchSectionData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sections/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchStudentDataUsingId = async (studentId) => {
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students/${studentId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedStudentData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setSelectedStudentData({});
      });
  };

  const fetchStudentData = async (params) => {
    if (params.menu) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/students-without-score-range?teacherId=${params.teacherId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${params.gradeId}&sectionId=${params.sectionId}&subjectId=${params.subjectId}&menu=${params.menu}&boardId=${boardId}&academicYearId=${params.academicYearId}`,
        {
          headers: {
            "Content-Type": "application/json", Authorization: `Bearer ${token}`,
          },
        }
      );
      return response?.data?.data || [];
    } else if (!params.menu) {
      let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/students-by-score-range?subjectId=${params.subjectId}&gradeId=${params.gradeId}&sectionId=${params.sectionId}&quizId=${params.quizId}&rangeId=${params.rangeId}&teacherId=${params.teacherId}&schoolId=${schoolId}&branchId=${branchId}&totalMarks=${params.studentMark}&boardId=${boardId}&academicYearId=${params.academicYearId}`
      const response = await axios.get(
        url,
        // `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/students-by-score-range?subjectId=${params.subjectId}&gradeId=${params.gradeId}&sectionId=${params.sectionId}&quizId=${params.quizId}&rangeId=${params.rangeId}&teacherId=${params.teacherId}&schoolId=${schoolId}&branchId=${branchId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response?.data?.data || [];
    }
  };

  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem("schoolId", res.data.data.teacher.school);
    sessionStorage.setItem("teacherId", res?.data.data.teacher?.id);
    sessionStorage.setItem("boardId", res?.data?.data?.teacher?.boardId); sessionStorage.setItem("branchId", res?.data?.data?.teacher?.branch);
    return res?.data.data.teacher || [];
  };

  const fetchQuizList = async (params) => {
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/diagnostic-quiz-list?gradeId=${params.gradeId}&subjectId=${params.subjectId}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&sectionId=${selectedSection}`
    const response = await axios.get(
      url,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data.data || [];
  }

  const fetchUnitQuizData = async (params) => {
    /* Dynamic Unit Quiz Data */
    // /*
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/report/get-unit-quiz-details?subjectId=${params.subjectId}&studentId=${params.studentId}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${params?.gradeId}&sectionId=${params?.sectionId}&academicYearId=${params?.academicYearId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    );
    return response?.data?.data?.subTopicSubConceptDetailDtos || [];
    // */

    /* Static Unit Quiz Data */
    // const response = quizStaticData.data.subTopicSubConceptDetails.map(
    //   item => {
    //     return {
    //         id: item.id,
    //         name: item.name,
    //         score: item.score,
    //         chapterDetails: item.chapterDetails
    //     }
    //   }
    // );
    return response;
  }

  const fetchDiagnosticQuizData = async (params) => {
    const response = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/student/report/get-diagnostic-quiz-detail?subjectId=${params.subjectId}&studentId=${params.studentId}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${params.gradeId}&sectionId=${params.sectionId}&quizId=${params.quizId}&academicYearId=${params.academicYearId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    );
    const overAllQuizScore = response?.data?.data?.overAllQuizScore;
    const hasSubTopic = response?.data?.data?.hasSubTopic;
    const responseData = response?.data?.data?.subTopicSubConceptDetails.map(item => {
      return {
        id: item.id,
        name: item.name,
        score: item.score
      }
    });
    if (responseData) {
      responseData[0].overAllQuizScore = overAllQuizScore;
      responseData[0].hasSubTopic = hasSubTopic;
    }
    return responseData || [];

    // const overAllQuizScore = diagnosticQuizStaticData.data.overAllQuizScore;
    // const response = diagnosticQuizStaticData.data.subTopicSubConceptDetails.map(item => {
    //   return {
    //     id: item.id,
    //     name: item.name,
    //     score: item.score,
    //   }
    // })
    // response[0].overAllQuizScore = overAllQuizScore;
    // return response;
  }

  const fetchPracticeQuizData = async (params) => {
    const response = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/student/report/get-practice-quiz-details?subjectId=${params.subjectId}&studentId=${params.studentId}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${params.gradeId}&sectionId=${params.sectionId}&academicYearId=${params.academicYearId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    );
    const responseData = response?.data?.data?.practiceQuizOverAllResponseList;
    const practiceData = [];
    if (responseData) {
      const hasSubTopic = response?.data?.data?.hasSubTopic;
      practiceData.push(hasSubTopic);
      practiceData.push(responseData);
    }
    return practiceData || [];
    // const response = practiceStaticQuizData.practiceQuizOverAllResponseList.map(item => {
    //   return {
    //     name: item.name,
    //     chapterDetails: item.chapterDetails
    //   }
    // })

    return response;
  }

  const fetchScoreRange = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/score-range/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    );
    return response?.data?.data || [];
  }

  const fetchAllAcademicYear = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const teacherId = sessionStorage.getItem('teacherId');
  const boardId = sessionStorage.getItem("boardId");
  const branchId = sessionStorage.getItem("branchId");
  const schoolId = sessionStorage.getItem("schoolId");

  useEffect(() => {
    firstRender();
    return () => {
      cleanupOnUnmount()
    }
  }, [token]);

  const cleanupOnUnmount = () => {
    setSelectedGrade('')
    setSelectedSection('')
    setSelectedSubject('')
  }

  const firstRender = async () => {
    setIsPageLoading(true)
    const academicYears = await fetchAllAcademicYear();
    setAcademicYearList(academicYears);
    setSelectedAcademicYear(academicYears?.length ? academicYears[0]?.id : "");
    const teacherData = await fetchTeacherData(userName, role)

    await GetLastModifiedAt(setModifiedDate)
    const gradeSectionList = await fetchGradeData(teacherData?.id)
    setGradeSection(gradeSectionList)

    const fetchScoreData = await fetchScoreRange();
    setScoreRange(fetchScoreData);
    setSelectedScoreRange(fetchScoreData[0].id);
    //auto population
    await autoPopulate(gradeSectionList, teacherData.id);
  }

  const autoPopulate = async (gradeSectionList, teacherId) => {
    const gradeSectionFirstElement = gradeSectionList[0]
    setGradeList(gradeSectionList);
    setSectionList(gradeSectionFirstElement?.sections);
    const sectionfirstElement = gradeSectionFirstElement?.sections[0]

    let subjectParams = {
      gradeId: gradeSectionFirstElement?.id,
      sectionId: sectionfirstElement?.id,
      teacherId: teacherId,
      menuName: "TEACH"
    }

    const fetchSubjectReport = async () => {
      try {
        const subjectListFetched = await fetchSubjectData(subjectParams);
        if (subjectListFetched?.length > 0) {
          setSubjectList(subjectListFetched);
          setSelectedSubject(subjectListFetched[0]?.subjectId);
          setSelectedSubjectName(subjectListFetched[0]?.subject);
        } else {
          setSubjectList([]);
          setSelectedSubject('');
          setSelectedSubjectName('');
        }
      } catch (error) {
        setSubjectList([]);
        setSelectedSubject('');
        setSelectedSubjectName('');
      }
    }
    fetchSubjectReport();

    setSelectedGrade(gradeSectionFirstElement?.id)
    setSelectedSection(sectionfirstElement?.id)

    setIsPageLoading(false);
  }

  {/*
  useEffect(() => {
    if(selectedGrade && selectedSection) {
      const fetchSubjectReport = async () => {
        const selectedGradeId = selectedGrade;
        let subjectParams ={
          gradeId: selectedGradeId,
          sectionId: selectedSection,
          teacherId: teacherId,
          menuName: "TEACH"
        }
        try {
          const subjectListFetched = await fetchSubjectData(subjectParams);
          if(subjectListFetched?.length > 0) {
            setSubjectList(subjectListFetched);
            setSelectedSubject(subjectListFetched[0]?.subjectId);
            setSelectedSubjectName(subjectListFetched[0]?.subject);
          } else {
            setSubjectList([]);
            setSelectedSubject('');
            setSelectedSubjectName('');
          }
        } catch (error) {
          setSubjectList([]);
          setSelectedSubject('');
          setSelectedSubjectName('');
        }
      }
      fetchSubjectReport();
    }
  }, [selectedGrade, selectedSection]);
  */}

  useEffect(async () => {
    const quizListParams = {
      gradeId: selectedGrade,
      subjectId: selectedSubject
    }
    if (selectedGrade && selectedSection && selectedSubject && tabsValue === '1') {
      const fetchQuizListReport = async () => {
        try {
          const quizListFetched = await fetchQuizList(quizListParams);
          if (quizListFetched?.length > 0) {
            setQuizList(quizListFetched);
            setSelectedQuiz(quizListFetched?.length ? quizListFetched[0]?.quizId : '');
            setStudentMark(quizListFetched?.length ? quizListFetched[0]?.totalMarks : '');
            setSelectedQuizName((quizListFetched[0]?.quizName)?.toUpperCase());
          } else {
            setQuizList([]);
            setSelectedQuiz('');
            setStudentMark('');
            setSelectedQuizName('');
          }
        } catch (error) {
          setQuizList([]);
          setSelectedQuiz('');
          setStudentMark('');
          setSelectedQuizName('');
        }
      }
      fetchQuizListReport();
    }
  }, [selectedGrade, selectedSection, selectedSubject, tabsValue]);

  useEffect(() => {
    const quizParams = {
      subjectId: selectedSubject,
      gradeId: selectedGrade,
      sectionId: selectedSection,
      studentId: selectedStudentId,
      quizId: selectedQuiz,
      academicYearId: selectedAcademicYear
    }
    if (selectedGrade && selectedSection && selectedSubject &&
      selectedStudentId && selectedAcademicYear && tabsValue === '1') {
      const fetchDiagnosticReport = async () => {
        try {
          setDiagnosticLoader(true);
          const diagnosticQuizDataFetched = await fetchDiagnosticQuizData(quizParams);
          setDiagnosticQuizData(diagnosticQuizDataFetched);
          setDiagnosticLoader(false);
        } catch (err) {
          setDiagnosticLoader(false);
          setDiagnosticQuizData([]);
          console.log("not able to fetch diagnostic data !")
        }
      }
      fetchDiagnosticReport();
    } else {
      setDiagnosticQuizData([]);
    }
  }, [selectedSubject, selectedSection, selectedGrade, selectedStudentId, selectedQuiz, selectedAcademicYear, tabsValue]);

  useEffect(() => {
    const quizParams = {
      subjectId: selectedSubject,
      gradeId: selectedGrade,
      sectionId: selectedSection,
      studentId: selectedStudentId,
      quizId: selectedQuiz,
      academicYearId: selectedAcademicYear
    };
    if (selectedGrade && selectedSection && selectedSubject && selectedStudentId && selectedAcademicYear && tabsValue === '2') {
      const fetchUnitReport = async () => {
        try {
          setUnitLoader(true);
          const quizDataFetched = await fetchUnitQuizData(quizParams);
          setQuizData(quizDataFetched);
          setUnitLoader(false);
        } catch (error) {
          setUnitLoader(false);
          setQuizData([]);
          console.log("not able to fetch unit data !")
        }
      }
      fetchUnitReport();
    } else {
      setQuizData([]);
    }
  }, [selectedSubject, selectedGrade, selectedStudentId, selectedAcademicYear, tabsValue]);

  useEffect(async () => {
    const quizParams = {
      subjectId: selectedSubject,
      gradeId: selectedGrade,
      sectionId: selectedSection,
      studentId: selectedStudentId,
      academicYearId: selectedAcademicYear
    }
    if (selectedGrade && selectedSection && selectedSubject && selectedStudentId && selectedAcademicYear && tabsValue === '3') {
      const fetchPracticeReport = async () => {
        try {
          setPracticeLoader(true);
          const quizDataFetched = await fetchPracticeQuizData(quizParams);
          setPracticeQuizData(quizDataFetched);
          setPracticeLoader(false);
        } catch (error) {
          setPracticeLoader(false);
          setPracticeQuizData([]);
          console.log("not able to fetch practice data !")
        }
      }
      fetchPracticeReport();
    } else {
      setPracticeQuizData([]);
    }
  }, [selectedSubject, selectedSection, selectedGrade, selectedStudentId, selectedAcademicYear, tabsValue]);

  useEffect(() => {
    const quizParams1 = {
      subjectId: selectedSubject,
      gradeId: selectedGrade,
      sectionId: selectedSection,
      quizId: selectedQuiz,
      rangeId: selectedScoreRange,
      academicYearId: selectedAcademicYear,
      teacherId,
      studentMark
    };
    const quizParams2 = {
      subjectId: selectedSubject,
      gradeId: selectedGrade,
      sectionId: selectedSection,
      academicYearId: selectedAcademicYear,
      teacherId,
      menu: +(tabsValue)
    };

    if (tabsValue === "1" && selectedGrade && selectedSection && selectedSubject && selectedQuiz && selectedScoreRange && studentMark && selectedAcademicYear) {
      const fetchStudentReport = async () => {
        try {
          const fetchedStudents = await fetchStudentData(quizParams1);
          if (fetchedStudents?.length === 0) {
            setStudentList([]);
            setSelectedStudentId('');
            setSelectedStudentData({});
          } else {
            setStudentList(fetchedStudents);
            setSelectedStudentId(fetchedStudents?.length ? fetchedStudents[0]?.id : '');
          }
        } catch (error) {
          setStudentList([]);
          setSelectedStudentId('');
          setSelectedStudentData({});
        }
      }
      fetchStudentReport();
    } else if (tabsValue === "2" && selectedGrade && selectedSection && selectedSubject && selectedAcademicYear) {
      const fetchStudentReport = async () => {
        try {
          const fetchedStudents = await fetchStudentData(quizParams2);
          if (fetchedStudents?.length === 0) {
            setStudentList([]);
            setSelectedStudentId('');
            setSelectedStudentData({});
          } else {
            setStudentList(fetchedStudents);
            setSelectedStudentId(fetchedStudents?.length ? fetchedStudents[0]?.id : '');
          }
        } catch (error) {
          setStudentList([]);
          setSelectedStudentId('');
          setSelectedStudentData({});
        }
      }
      fetchStudentReport();
    } else if (tabsValue === "3" && selectedGrade && selectedSection && selectedSubject && selectedAcademicYear) {
      const fetchStudentReport = async () => {
        try {
          const fetchedStudents = await fetchStudentData(quizParams2);
          if (fetchedStudents?.length === 0) {
            setStudentList([]);
            setSelectedStudentId('');
            setSelectedStudentData({});
          } else {
            setStudentList(fetchedStudents);
            setSelectedStudentId(fetchedStudents?.length ? fetchedStudents[0]?.id : '');
          }
        } catch (error) {
          setStudentList([]);
          setSelectedStudentId('');
          setSelectedStudentData({});
        }
      }
      fetchStudentReport();
    }
  }, [selectedSubject, selectedSection, selectedGrade, selectedScoreRange, teacherId, selectedQuiz, tabsValue, studentMark, selectedAcademicYear]);

  useEffect(async () => {
    if (selectedStudentId) {
      await fetchStudentDataUsingId(selectedStudentId);
    }
  }, [selectedStudentId]);

  {/*
  useEffect(async () => {
    if (pageSize) {
      const student = await fetchStudentData(pageSize);
      setStudentList(student);
      setSelectedStudentId(student?.length ? student[0]?.id : '');
    }
  }, [pageSize]);
  */}

  const handleChangeGrade = async (value) => {
    setSelectedGrade('');
    setSelectedSection('');
    setSelectedSubject('');
    setSelectedQuiz('');
    setStudentMark('');
    setSelectedStudentId('');
    setChangedFilter("grade");
    const selectedId = value;
    setSelectedGrade(selectedId);
    const sections = gradeSection.find(i => i.id === selectedId)?.sections
    setSectionList(sections)
    setSelectedSection(sections?.length ? sections[0].id : "");

    const fetchSubjectReport = async () => {
      let subjectParams = {
        gradeId: value,
        sectionId: sections[0].id,
        teacherId: teacherId,
        menuName: "TEACH"
      }
      try {
        const subjectListFetched = await fetchSubjectData(subjectParams);
        if (subjectListFetched?.length > 0) {
          setSubjectList(subjectListFetched);
          setSelectedSubject(subjectListFetched[0]?.subjectId);
          setSelectedSubjectName(subjectListFetched[0]?.subject);
        } else {
          setSubjectList([]);
          setSelectedSubject('');
          setSelectedSubjectName('');
        }
      } catch (error) {
        setSubjectList([]);
        setSelectedSubject('');
        setSelectedSubjectName('');
      }
    }
    fetchSubjectReport();
  }

  const handleChangeSection = async value => {
    setSelectedSection('');
    setSelectedSubject('');
    setSelectedQuiz('');
    setStudentMark('');
    setSelectedStudentId('');
    setChangedFilter("section");
    const secId = value;
    setSelectedSection(secId);

    const fetchSubjectReport = async () => {
      let subjectParams = {
        gradeId: selectedGrade,
        sectionId: value,
        teacherId: teacherId,
        menuName: "TEACH"
      }
      try {
        const subjectListFetched = await fetchSubjectData(subjectParams);
        if (subjectListFetched?.length > 0) {
          setSubjectList(subjectListFetched);
          setSelectedSubject(subjectListFetched[0]?.subjectId);
          setSelectedSubjectName(subjectListFetched[0]?.subject);
        } else {
          setSubjectList([]);
          setSelectedSubject('');
          setSelectedSubjectName('');
        }
      } catch (error) {
        setSubjectList([]);
        setSelectedSubject('');
        setSelectedSubjectName('');
      }
    }
    fetchSubjectReport();
  }

  const selectSubjectHandler = (id) => {
    setSelectedSubject('');
    setSelectedQuiz('');
    setStudentMark('');
    setSelectedStudentId('');
    const findSubject = subjectList.find(item => item.subjectId === id);
    setSelectedSubject(id);
    setSelectedSubjectName(findSubject?.subject);
  };

  const tabsChangeHandle = (event, newValue) => {
    setSelectedStudentId('');
    setTabsValue(newValue);
  };

  const handleChangeAcademicYear = value => {
    setChangedFilter("year");
    setSelectedAcademicYear(value)
    sessionStorage.setItem('academicYearId', value)
  };

  const handleChangeQuiz = value => {
    setSelectedQuiz(value);
    const mark = quizList.find(item => item?.quizId === value);
    setStudentMark(mark?.totalMarks);
    setSelectedQuizName(mark?.quizName);
  }

  const colors = [
    "rgba(48,210,214,1)",
    "rgb(20,160,192)",
    "rgb(102,46,155)",
    "rgb(247 204 61)",
    "rgb(77 252 174)",
    "rgb(31 209 213)",
    "rgb(16 158 189)",
    "rgb(108 35 151)",
    "rgb(250 106 52)",
  ];

  return (
    <div style={{ width: "100%" }}>
      <TopBar
        title={"Home"}
        role={role}
        nav1={
          role === "TEACHER"
            ? "TeacherDashboard"
              :"principal/dashboard"
        }
        subTitle={"Reports"}
        subSubTitle={"Student Report"}
        modifiedDate={modifiedDate?.data}
      />
      <div
        style={{
          marginTop: "15px",
          paddingRight: "33px",
          position: "absolute",
          left: "23px",
          width: "98%",
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}
        >
          <FormControl
            sx={{
              minWidth: "8.8vw",
              background: "white",
              borderRadius: 150,
              width: '150px',
              height: '32px'
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Select Grade
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              MenuProps={MenuProps}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                height: "32px",
                color: selectedGrade ? '#FD8C00' : "#000000"
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedGrade}
              onChange={(e) => { handleChangeGrade(e.target.value) }}
            >
              {gradeList?.map((item) => {
                return (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item.grade}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              minWidth: "8.8vw",
              background: "white",
              borderRadius: 150,
              width: '150px',
              height: '32px'
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Select Section
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              MenuProps={MenuProps}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                height: "32px",
                color: selectedSection ? '#FD8C00' : "#000000"
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedSection}
              onChange={(e) => { handleChangeSection(e.target.value) }}
            >
              {sectionList?.map((item) => {
                return (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item.section}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              minWidth: "8.8vw",
              background: "white",
              borderRadius: 150,
              width: '150px',
              height: '32px'
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Select Subject
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              MenuProps={MenuProps}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                height: "32px",
                color: selectedSubject ? '#FD8C00' : "#000000"
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedSubject}
              onChange={(e) => selectSubjectHandler(e.target.value)}
              disabled={selectedSubject === '' ? true : false}
            >
              {subjectList?.map((item) => {
                return (
                  <MenuItem
                    value={item.subjectId}
                    key={item.subjectId}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item.subject}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {tabsValue == "1" && <FormControl
            sx={{
              minWidth: "8.8vw",
              background: "white",
              borderRadius: 150,
              width: '150px',
              height: '32px'
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Score Quiz
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              MenuProps={MenuProps}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                height: "32px",
                color: selectedQuiz ? '#FD8C00' : "#000000"
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedQuiz}
              onChange={(e) => handleChangeQuiz(e.target.value)}
              disabled={selectedQuiz === '' ? true : false}
            >
              {quizList?.map((item) => {
                return (
                  <MenuItem
                    value={item.quizId}
                    key={item.quizId}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item.quizName}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>}
          {tabsValue == "1" && <FormControl
            sx={{
              minWidth: "8.8vw",
              background: "white",
              borderRadius: 150,
              width: '150px',
              height: '32px'
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Score Range
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              MenuProps={MenuProps}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                height: "32px",
                color: selectedScoreRange ? '#FD8C00' : "#000000"
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedScoreRange}
              onChange={e => setSelectedScoreRange(e.target.value)}
            >
              {scoreRange?.map(item => {
                return (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item.rangeName}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>}
          <FormControl
            sx={{
              minWidth: "8.8vw",
              background: "white",
              borderRadius: 150,
              width: '150px',
              height: '32px'
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Select Student
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              MenuProps={MenuProps}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                height: "32px",
                color: selectedStudentId ? '#FD8C00' : "#000000"
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedStudentId}
              onChange={(e) => setSelectedStudentId(e.target.value)}
              disabled={selectedStudentId === '' ? true : false}
            >
              {studentList?.map((item) => {
                return (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={`${item.firstName} ${item.lastName}`}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              minWidth: "8.8vw",
              background: "white",
              borderRadius: 150,
              width: '150px',
              height: '32px'
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Academic Year
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                // background: "white",
                height: "32px",
                color: '#FD8C00'
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{
                  fontSize: "1.5vw"
                }} />
              )}
              value={selectedAcademicYear}
              onChange={e => handleChangeAcademicYear(e.target.value)}
            >
              {academicYearList?.map((item) => {
                return (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item.academicYear}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <Box sx={{ width: "100%", typography: "body1", height: "100%" }}>
          <TabContext value={tabsValue}>
            <Box
              sx={{
                borderBottom: 2,
                borderColor: "rgb(75,70,137)",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TabList
                onChange={(e, value) => tabsChangeHandle(e, value)}
                aria-label="lab API tabs example"
                textColor="inherit"
                indicatorColor="secondary"
              >
                <Tab
                  label="Diagnostic Quiz"
                  value="1"
                  sx={{
                    background: tabsValue === "1" ? "rgb(75,70,137)" : "",
                    color: tabsValue === "1" ? "white" : "black",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                    padding: '12px 40px'
                  }}
                />
                <Tab
                  label="Unit Quiz"
                  value="2"
                  sx={{
                    background: tabsValue === "2" ? "rgb(75,70,137)" : "",
                    color: tabsValue === "2" ? "white" : "",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                    padding: '12px 40px'
                  }}
                />
                <Tab
                  label="Practice Quiz"
                  value="3"
                  sx={{
                    background: tabsValue === "3" ? "rgb(75,70,137)" : "",
                    color: tabsValue === "3" ? "white" : "",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                    padding: '12px 40px'
                  }}
                />
              </TabList>
              <DarkYellowButton
                style={{
                  marginBottom: "5px",
                  cursor: !Object.keys(selectedStudentData)?.length
                    ? "no-drop"
                    : "pointer",
                  marginRight: '25px',
                  position: 'relative',
                  top: '-5px'
                }}
                onClick={() =>
                  Object.keys(selectedStudentData)?.length &&
                  setShowStudentProfile(true)
                }
                disabled={!Object.keys(selectedStudentData)?.length}
              >
                Student Profile
              </DarkYellowButton>
            </Box>
            <Paper
              sx={{
                width: "100%",
                // minHeight: "650px",
                height: "100%",
              }}
            >
              <TabPanel value="1" sx={{ minHeight: '68vh' }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    rowGap: "1rem",
                    // minHeight: "600px",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "10px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 21,
                        color: "rgb(0 75 255)",
                      }}
                    >
                      {selectedQuizName
                        ? selectedQuizName
                        : 'No Quizzes Are Available'
                      } - {selectedSubjectName
                        ? selectedSubjectName
                        : 'No Subjects Are Available'}
                    </div>
                    <Box sx={{ flexGrow: 1 }}>
                      {/* {diagnosticLoader &&
                        <div className={dashboardContentStyles.quizErrorMsg}>
                          FETCHING...
                        </div>
                      } */}
                      {!diagnosticLoader && studentList.length === 0 &&
                        <div className={dashboardContentStyles.quizErrorMsg}>
                          NO STUDENTS ARE AVAILABLE
                        </div>
                      }
                      {!diagnosticLoader && studentList.length > 0 &&
                        (diagnosticQuizData.length === 0 ||
                          diagnosticQuizData === null) &&
                        <div className={dashboardContentStyles.quizErrorMsg}>
                          NO QUIZZES ARE AVAILABLE
                        </div>
                      }
                      {!diagnosticLoader && studentList.length > 0 &&
                        diagnosticQuizData.length > 0 &&
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={2} sm={6}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: 19,
                                  fontWeight: 600,
                                  color: "rgb(66, 61, 92)",
                                  textAlign: "center",
                                }}
                              >
                                Quiz Score
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  height: "400px",
                                  paddingTop: "0px",
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  paddingLeft: "20px",
                                }}
                              >
                                <CustomThermometer
                                  totalValue={diagnosticQuizData.map(item => Math.round(item.overAllQuizScore))[0]}
                                  color={"rgb(69,202,144)"}
                                  height="320"
                                  steps={5}
                                  minValue={0}
                                  maxValue={100}
                                  format={"%"}
                                  mode={"light"}
                                />
                              </div>
                              <div
                                style={{
                                  color: "rgb(66, 61, 92)",
                                  fontWeight: 600,
                                }}
                              >
                                Total Score
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6} sm={6}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: 19,
                                  fontWeight: 600,
                                  color: "rgb(66, 61, 92)",
                                  textAlign: "center",
                                }}
                              >
                                {diagnosticQuizData?.map(item => item?.hasSubTopic)[0] ? 'Subtopic' : 'Subconcept'}
                              </div>

                              <GenericChart
                                data={genericDiagnosticData}
                                colors={colors}
                                chapterCount={genericDiagnosticData.length}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} md={4} sm={6}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                rowGap: "10px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: 19,
                                  fontWeight: 600,
                                  color: "rgb(66, 61, 92)",
                                  textAlign: "center",
                                }}
                              >
                                Learning Plan
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: "10px",
                                  overflow: "auto",
                                  height: "240px",
                                  padding: "10px",
                                }}
                              >
                                {planData.map((data) => {
                                  return (
                                    <TextField
                                      id="outlined-basic"
                                      label={data.label}
                                      variant="outlined"
                                      value={data.description}
                                      key={data.id}
                                      InputProps={{
                                        startAdornment: (
                                          <IconButton
                                            onClick={() => {
                                              setShowPlan(true);
                                              setPlanDetailData(data.planData);
                                            }}
                                          >
                                            <CopyAllOutlinedIcon
                                              style={{ color: "#f79f02" }}
                                            />
                                          </IconButton>
                                        ),
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      }
                    </Box>
                  </div>
                  {/* <Divider /> */}
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  <div
                    style={{
                      fontSize: 21,
                      color: "rgb(0 75 255)",
                    }}
                  >
                    Unit Quiz Performance - {selectedSubjectName ? selectedSubjectName : 'No Subjects Are Available'}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "10px",
                    }}
                  >
                    {/* {unitLoader &&
                      <div className={dashboardContentStyles.quizErrorMsg}>
                        FETCHING...
                      </div>
                    } */}
                    {!unitLoader && studentList.length === 0 &&
                      <div className={dashboardContentStyles.quizErrorMsg}>
                        NO STUDENTS ARE AVAILABLE
                      </div>
                    }
                    {!unitLoader && (studentList?.length > 0 &&
                      (quizData?.length === 0 ||
                        quizData === null)) &&
                      <div
                        className={dashboardContentStyles.quizErrorMsg}
                      >
                        NO QUIZZES ARE AVAILABLE
                      </div>
                    }
                    {!unitLoader && (studentList.length > 0 &&
                      (quizData.length > 0 || quizData !== null)) &&
                      quizData?.map(item => {
                        return (
                          <QuizPerformance
                            title={item?.name}
                            titleColor={"rgba(242,68,16,0.89)"}
                            thValue={Math.round(item?.score)}
                            thColor={"rgba(242,68,16,0.89)"}
                            barColor={["rgba(242,68,16,0.89)", "rgb(83,242,173)"]}
                            data={genericUnitData}
                            colors={colors}
                          />
                        )
                      })}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: "600px",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1rem",
                  }}
                >
                  <div
                    style={{
                      fontSize: 21,
                      color: "rgb(0 75 255)",
                    }}
                  >
                    Practice Quiz Performance - {selectedSubjectName ? selectedSubjectName : 'No Subjects Are Available'}
                  </div>
                  {/* {practiceLoader &&
                    <div className={dashboardContentStyles.quizErrorMsg}>
                      FETCHING...
                    </div>
                  } */}
                  {!practiceLoader && studentList?.length === 0 &&
                    <div className={dashboardContentStyles.quizErrorMsg}>
                      NO STUDENTS ARE AVAILABLE
                    </div>
                  }
                  {!practiceLoader && studentList.length > 0 &&
                    (practiceQuizData[1]?.length === 0 ||
                      practiceQuizData[1] === null) &&
                    <div className={dashboardContentStyles.quizErrorMsg}>
                      NO QUIZZES ARE AVAILABLE
                    </div>
                  }
                  {!practiceLoader && studentList.length > 0 &&
                    (practiceQuizData[1]?.length > 0 ||
                      practiceQuizData[1] !== null) &&
                    practiceQuizData[1]?.map((item, index) => {
                      return (
                        <Paper
                          key={index}
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
                          }}
                        >
                          <TableContainer sx={{ maxHeight: 550, overflow: "auto" }}>
                            <Table sx={{ minWidth: 650 }}
                              stickyHeader={true}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontWeight: 600,
                                      background: "rgb(75,70,137)",
                                      color: "white",
                                    }}
                                  >
                                    {practiceQuizData[0] ? 'Subtopic' : 'Subconcept'}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontWeight: 600,
                                      background: "rgb(75,70,137)",
                                      color: "white",
                                    }}
                                  >
                                    Chapter Name
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontWeight: 600,
                                      background: "rgb(75,70,137)",
                                      color: "white",
                                    }}
                                  >
                                    Unit Score less then 40%
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontWeight: 600,
                                      background: "rgb(75,70,137)",
                                      color: "white",
                                    }}
                                  >
                                    PQ-1 Attempt
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontWeight: 600,
                                      background: "rgb(75,70,137)",
                                      color: "white",
                                    }}
                                  >
                                    PQ-2 Attempt
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontWeight: 600,
                                      // paddingRight: "60px",
                                      background: "rgb(75,70,137)",
                                      color: "white",
                                    }}
                                  >
                                    Highest PQ Score
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: '0 !important',
                                    },
                                    // "&:nth-child(even)": {
                                    //   backgroundColor: '#dddddd'
                                    // }
                                  }}
                                  key={item?.id}
                                  hover={false}
                                >
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      columnGap: "10px",
                                      color: "#115d8c",
                                      border: 0,
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item?.name}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      color: "#115d8c",
                                      fontWeight: 500,
                                      border: 0
                                    }}
                                  >
                                    {item?.chapterDetails?.map(item2 => {
                                      return (
                                        <div style={{ lineHeight: 2 }}>
                                          {item2?.chapterName}
                                        </div>
                                      )
                                    })}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ color: "black", fontWeight: 500, border: 0 }}
                                  >
                                    {item?.chapterDetails?.map(item2 => {
                                      return (
                                        <div style={{ lineHeight: 2 }}>
                                          {item2?.scoreLessThanFortyFive === null ? '-' : item2?.scoreLessThanFortyFive} %
                                        </div>
                                      )
                                    })}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ color: "black", fontWeight: 500, border: 0 }}
                                  >
                                    {item?.chapterDetails?.map(item2 => {
                                      return (
                                        <div style={{ lineHeight: 2 }}>
                                          {item2?.pq1 === null ? '-' : item2?.pq1}
                                        </div>
                                      )
                                    })}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ color: "black", fontWeight: 500, border: 0 }}
                                  >
                                    {item?.chapterDetails?.map(item2 => {
                                      return (
                                        <div style={{ lineHeight: 2 }}>
                                          {item2?.pq2 === null ? '-' : item2?.pq2}
                                        </div>
                                      )
                                    })}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ color: "black", fontWeight: 500, border: 0 }}
                                  >
                                    {item?.chapterDetails?.map(item2 => {
                                      return (
                                        <div style={{ lineHeight: 2 }}>
                                          {item2?.highestPQScore === null ? '-' : item2?.highestPQScore} %
                                        </div>
                                      )
                                    })}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      )
                    })
                  }
                </div>
              </TabPanel>
            </Paper>
          </TabContext>
        </Box>
      </div>
      {showPlan && (
        <PlanModal
          open={showPlan}
          close={() => setShowPlan(false)}
          data={planDetailData}
        />
      )}
      {showStudentProfile && (
        <ViewStudentProfile
          open={showStudentProfile}
          close={() => setShowStudentProfile(false)}
          studentDetail={selectedStudentData}
        />
      )}
    </div>
  );
};
export default StudentReport;
