import axios from 'axios'

function chapters_controller_post(chapterInputs,subTopicId,chapName, setSuccess , setShowError){
    let token = sessionStorage.getItem("token");
    var item = {
        // boardI : chapterInputs.boardId,
        board : chapterInputs.board,
        boardId : chapterInputs.boardId,

        grade: chapterInputs.grade,
        gradeId: chapterInputs.gradeId,

        subject : chapterInputs.subject,
        subjectId : chapterInputs.subjectId,

        chapter : chapName,
        chapterStatus : chapterInputs.chapterStatus,

        subTopicId :subTopicId ?  subTopicId  : null,
        docType : chapterInputs.docType,
        thumbnailPath:chapterInputs.thumbnailPath

    } ;
    
    const result = axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters` , item,
    {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          validateStatus() { return true } 
    })
    .then((res)=>{
        if (result.status === 200) {
            setSuccess(res.data)
            setShowError(res.data.message)
        } else {
            setSuccess(res.data)
            setShowError(res.data.message)
        }

    })
}

export default chapters_controller_post;