import React, { useState, useEffect } from "react";
import setpass from "./../css/SetPass.module.css";
import users_forgotpassword_Put from "../API/user-controller/users_forgotpassword_put";
import { useSearchParams } from "react-router-dom";
import ChangePasswordPopup from "./ChangePasswordPopup";
import { useNavigate } from "react-router-dom";
// import { Dialog, DialogContent } from "@material-ui/core";
import styled from "styled-components";
// import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { result } from "lodash";

const SetPassword = () => {
  const [loginPassword, setLoginPassword] = useState("");
  const [confirmLoginPassword, setConfirmLoginPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [open, setOpen] = useState(false)
  const navigate = useNavigate();
  const toggleLoginPasswordVisibility = (isShown) => {
    if (isShown === "show") {
      setPasswordShown(true);
    } else {
      setPasswordShown(false);
    }
  };
  const toggleconfirmLoginPasswordVisibility = (isConfirmShown) => {
    if (isConfirmShown === "Confirmshow") {
      setConfirmPasswordShown(true);
    }
    if (isConfirmShown === "ConfirmdontShow") {
      setConfirmPasswordShown(false);
    }
  };
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isPassEmpty, setIsPassEmpty] = useState(false);
  const [minCharactersLength, setMinCharactersLength] = useState(true);
  const [capitalLetter, setcapitalLetter] = useState(true);
  const [usenumber, setusenumber] = useState(true);
  const [specialCharacter, setspecialCharacter] = useState(true);
  const [successNotifi,setSuccessNotifi] = useState(false);

  const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: ${(props) => (props.isOpen ? "block" : "none")};
	z-index: 100;
`;
const ModalWrapper = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	width: 70%;
	max-width: 600px;
`;
const Icon = styled.span`
	display: block;
	color: #fc8c00;
	text-align: center;
	svg {
		font-size: 120px;
	}
`;
const Label = styled.p`
	font-size: 20px;
	text-align: center;
	font-weight: 300;
`;

  function minLengthPassword(loginPassword) {
    const minLengthRegExp = /.{6,}/;
    return minLengthRegExp.test(loginPassword);
  }
  function uppercasePassword(loginPassword) {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    return uppercaseRegExp.test(loginPassword);
  }
  function digitsPassword(loginPassword) {
    const digitsRegExp = /(?=.*?[0-9])/;
    return digitsRegExp.test(loginPassword);
  }
  function specialCharPassword(loginPassword) {
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    return specialCharRegExp.test(loginPassword);
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const [success, setSuccess] = useState({});
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");

  const resetResult = (result) => {
    console.log("result",result)
    if(result.status === 200){
      setSuccessNotifi(true)
      setTimeout(() => {
        setSuccessNotifi(false)
        navigate('/')
      }, 2000);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!loginPassword) {
    //   setIsPassEmpty(true);
    //   setPasswordMatch(true);
    //   // return;
    // }
    if (loginPassword !== confirmLoginPassword) {
      setIsPassEmpty(false);
      setPasswordMatch(false);
      return;
    }
    // if (minLengthPassword(loginPassword)) {
    //   setMinCharactersLength(false);

    // } else {
    //   setMinCharactersLength(true);
    // }
    // if (uppercasePassword(loginPassword)) {
    //   setcapitalLetter(false);
    // } else {
    //   setcapitalLetter(true);
    // }
    // if (digitsPassword(loginPassword)) {
    //   setusenumber(false);
    // } else {
    //   setusenumber(true);
    // }
    // if (specialCharPassword(loginPassword)) {
    //   setspecialCharacter(false);
    // } else {
    //   setspecialCharacter(true);
    // }

    // setPasswordMatch(true);

    users_forgotpassword_Put(
      confirmLoginPassword,
      decodeURIComponent(
        sessionStorage.getItem("selectedUserID")
          // .get("userId")
          .split(" ")
          .join("+")
      ),
      setSuccess,
      resetResult
    )
    console.log("success" , success);

    // End - fetch and decode url
  };

  const handleConfirmPasswordChange = (e) => {
    let confirmPass = e.target.value
  
    if (!loginPassword) {
      setIsPassEmpty(true);
      setPasswordMatch(true);
      // return;
    }
    if (loginPassword !== confirmPass) {
      setIsPassEmpty(false);
      setPasswordMatch(false);
      return;
    }
    if (minLengthPassword(loginPassword)) {
      setMinCharactersLength(false);

    } else {
      setMinCharactersLength(true);
    }
    if (uppercasePassword(loginPassword)) {
      setcapitalLetter(false);
    } else {
      setcapitalLetter(true);
    }
    if (digitsPassword(loginPassword)) {
      setusenumber(false);
    } else {
      setusenumber(true);
    }
    if (specialCharPassword(loginPassword)) {
      setspecialCharacter(false);
    } else {
      setspecialCharacter(true);
    }

    setPasswordMatch(true);
  }
  
  useEffect(() => {
    if (success.success === true) {
      setOpen(true);
      setIsError(true);
      setError(success.message);
      window.location = "updatePass";
    } else {
      setIsError(true);
      setError(success.message);
    }
  }, [success]);

  const Field = ({ id }) => (
    <div>
      <label htmlFor={`field${id}`}>Field {id}</label>
      <input id={`field${id}`} type="text" />
    </div>
  );

  const fields = [];
  for (let i = 1; i <= loginPassword; i++) {
    fields.push(<Field id={i} />);
  }

  const close = () => {
    setOpen(false)
  }
console.log(error,"error")

// let emailId = localStorage.getItem("email") ? localStorage.getItem("email") : sessionStorage.getItem("email")
let emailId = localStorage.getItem("selectedUserInfo")
  return (
    <>
      <div class={setpass.main_container}>
        <div class={setpass.main}>
          <div class={setpass.navbar}>
            <div class={setpass.icon}>
              <h2 class={setpass.logo}>
                <img src="/img/Azvasa_logo.png" />
              </h2>
            </div>
          </div>
          <div class={setpass.content}>
            <img src="/img/ForgotPassword.png" class={setpass.login_img} />
            <form class={setpass.setPassword_login_form}>
              <div class={setpass.login_form_title}>
                <h2>Setup New Password</h2>
                <h3>
                  For <b>{emailId}</b>
                </h3>
              </div>
              <div class={setpass.password_container}>
                <fieldset className={setpass.password_fieldset}>
                  <legend>New Password</legend>
                  <input
                    type={passwordShown ? "text" : "password"}
                    name="userPassword"
                    id={setpass.password}
                    value={loginPassword}
                    onChange={(e) => setLoginPassword(e.target.value)}
                  />
                  <i
                    className={`bi ${
                      passwordShown ? "bi-eye" : "bi-eye-slash"
                    } ${setpass.bi_eye_slash}`}
                    id={setpass.togglePassword}
                    onMouseEnter={() => {
                      toggleLoginPasswordVisibility("show");
                    }}
                    onMouseLeave={() => {
                      toggleLoginPasswordVisibility("dontShow");
                    }}

                    /*onClick={toggleLoginPasswordVisibility}*/
                  ></i>
                </fieldset>
                <span
                  className={setpass.error_span}
                  style={
                    isPassEmpty ? { display: "inline" } : { display: "none" }
                  }
                >
                  Password cannot be empty
                </span>
              </div>
              <div class={setpass.password_container}>
                <fieldset className={setpass.password_fieldset}>
                  <legend>Confirm Password</legend>
                  <input
                    type={confirmPasswordShown ? "text" : "password"}
                    name="confirmPassword"
                    id={setpass.password}
                    value={confirmLoginPassword}
                    onChange={(e) => {
                      setConfirmLoginPassword(e.target.value)
                      handleConfirmPasswordChange(e)
                    }}
                  />
                  <i
                    className={`bi ${
                      confirmPasswordShown ? "bi-eye" : "bi-eye-slash"
                    } ${setpass.bi_eye_slash}`}
                    id={setpass.togglePassword}
                    onMouseEnter={() => {
                      toggleconfirmLoginPasswordVisibility("Confirmshow");
                    }}
                    onMouseLeave={() => {
                      toggleconfirmLoginPasswordVisibility("ConfirmdontShow");
                    }}

                    //  onClick={toggleconfirmLoginPasswordVisibility}
                  ></i>
                </fieldset>
                <span
                  className={setpass.error_span}
                  style={
                    passwordMatch ? { display: "none" } : { display: "inline" }
                  }
                >
                  Passwords do not match
                </span>
                <div className={setpass.container} id={setpass.char}>
                  <p id={setpass.restrication}>
                    <span
                      onChange={(e) => setLoginPassword(e.target.value, 10)}
                      style={
                        loginPassword &&
                        minCharactersLength
                          ? { display: "inline" }
                          : { display: "none" }
                      }
                      className={setpass.text}
                    >
                      <span className={setpass.close}>
                        <i class="fa fa-close"></i>
                      </span>
                      <span 
                          className={setpass.text_gap}>
                        Minimum 6 characters
                      </span>
                    </span>
                  </p>
                  <p id={setpass.capital}>
                    <span
                      className={setpass.text}
                      onChange={(e) => setLoginPassword(e.target.value, 10)}
                      style={
                        loginPassword &&
                        capitalLetter
                          ? { display: "inline" }
                          : { display: "none" }
                      }
                    >
                      <span className={setpass.close}>
                        <i class="fa fa-close"></i>
                      </span>
                      <span className={setpass.text_gap}>
                        Atleast one capital Letter
                      </span>
                    </span>
                  </p>
                  <p id={setpass.num}>
                    <span
                      className={setpass.text}
                      onChange={(e) => setLoginPassword(e.target.value, 10)}
                      style={
                        loginPassword &&
                        usenumber ? { display: "inline" } : { display: "none" }
                      }
                    >
                      <span className={setpass.close}>
                        <i class="fa fa-close"></i>
                      </span>
                      <span className={setpass.text_gap}>
                        Atleast one number
                      </span>
                    </span>
                  </p>
                  <p>
                    <span
                      className={setpass.text}
                      onChange={(e) => setLoginPassword(e.target.value, 10)}
                      style={
                        loginPassword &&
                        specialCharacter
                          ? { display: "inline" }
                          : { display: "none" }
                      }
                    >
                      <span className={setpass.close}>
                        <i class="fa fa-close"></i>
                      </span>
                      <span className={setpass.text_gap}>
                        Atleast one Special Characters
                      </span>
                    </span>
                  </p>
                </div>
                <div className={setpass.container} id={setpass.char}>
                  <p id={setpass.restrication}>
                    <span
                      className={setpass.text_right}
                      onChange={(e) => setLoginPassword(e.target.value, 10)}
                      style={
                        minCharactersLength
                          ? { display: "none" }
                          : { display: "inline" }
                      }
                    >
                      <span className={setpass.check}>
                        <i class="fa fa-check"></i>
                      </span>
                      <span className={setpass.text_gap}>
                        Minimum 6 characters
                      </span>
                    </span>
                  </p>
                  <p id={setpass.capital}>
                    <span
                      className={setpass.text_right}
                      onChange={(e) => setLoginPassword(e.target.value, 10)}
                      style={
                        capitalLetter
                          ? { display: "none" }
                          : { display: "inline" }
                      }
                    >
                      <span className={setpass.check}>
                        <i class="fa fa-check"></i>
                      </span>
                      <span className={setpass.text_gap}>
                        Atleast one capital Letter
                      </span>
                    </span>
                  </p>
                  <p id={setpass.num}>
                    <span
                      className={setpass.text_right}
                      onChange={(e) => setLoginPassword(e.target.value, 10)}
                      style={
                        usenumber ? { display: "none" } : { display: "inline" }
                      }
                    >
                      <span className={setpass.check}>
                        <i class="fa fa-check"></i>
                      </span>
                      <span className={setpass.text_gap}>
                        Atleast one number
                      </span>
                    </span>
                  </p>
                  <p>
                    <span
                      className={setpass.text_right}
                      onChange={(e) => setLoginPassword(e.target.value, 10)}
                      style={
                        specialCharacter
                          ? { display: "none" }
                          : { display: "inline" }
                      }
                    >
                      <span className={setpass.check}>
                        <i class="fa fa-check"></i>
                      </span>
                      <span className={setpass.text_gap}>
                        Atleast one Special Characters
                      </span>
                    </span>
                  </p>
                </div>
              </div>

              <div class={setpass.login_container}>
                <button class={setpass.sp_login_btn} onClick={handleSubmit}>
                  {Field}
                  Set Password
                </button>
              </div>
              {iserror && <span>{error}</span>}
            </form>
          </div>
        </div>
        <ChangePasswordPopup
          open={open}
        
          handleOk={close}
        />
      </div>
    	<ModalOverlay isOpen={successNotifi}>
			<ModalWrapper>
				<Icon>
					<CheckCircleIcon />
				</Icon>
				<Label>Your password is updated successfully</Label>
			</ModalWrapper>
		</ModalOverlay>
    </>
  );
};

export default SetPassword;
