import { Modal } from "@mui/material";
import React from "react";
import "./DashboardGradeModal.css";
import { Close } from "@material-ui/icons";
import {
  emberMessageOne,
  emberMessageTwo,
} from "../../constants/dashboardConstants";
import GradesStudents from "../../img/gradeStudents.png";
import GradesHandling from "../../img/gradesHandling.png";
const DashboardGradeModalV2 = ({
  title,
  content,
  showMessageModal,
  onMessageModalClose,
  isEmberCategoryMessage,
  gradeDetails,
}) => {

  const data = gradeDetails?.gradeDetails

  const role = localStorage.getItem('role')
  return (
    <Modal
      sx={{ display: "grid", placeContent: "center" }}
      open={showMessageModal}
    
    >
      <div
        className="msg-modal-body"
        style={{ width: isEmberCategoryMessage ? "550px" : "420px" }}
      >
        <div className="grade-table-wrap">
          <div className="grade-modal-headv2">
            <div className="grade-modal-head-wrap">
              <div>
                <img src={GradesHandling} />
              </div>
              <div>
                <label className="label-grade-modal">No. of Grades</label>
              </div>
            </div>
            <div className="grade-modal-head-wrap">
              <div>
                <img src={GradesStudents} />
              </div>
              <div>
                <label className="label-grade-modal">Students</label>
              </div>
            </div>
          </div>
          {data?.map((data, index) => {
            return (
              <div className="grade-table-v2" key={index}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p className="label-index">{index + 1 + ". "}</p>
                  <p className="grade-table-content-v2">{data?.grade}</p>
                </div>
                <span className="student-label">{data?.totalStudentsUnderGrade}</span>
              </div>
            );
          })}
        </div>

        <div onClick={onMessageModalClose} className="msg-modal-close-btn">
          <Close style={{ fontSize: "20px", color: "#000001" }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
         
         {gradeDetails?.totalCoordinators && role != 'COORDINATOR' ? <span className="grade-table-bottom-label" style={{marginBottom:'6px'}}>No. of Coordinators: {gradeDetails?.totalCoordinators}</span>:null} 
          <span className="grade-table-bottom-label">No. of Teachers: {gradeDetails?.totalTeachers}</span>

        </div>
      </div>
    </Modal>
  );
};

export default DashboardGradeModalV2;
