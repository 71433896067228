const CityList = [
    {
      City: "Bangalore",
      PinCode: "530068",
      State: "Karnataka",
      Country: "India",
      Active: "",
      Action: "",
     
    },
  ];
  export default CityList;