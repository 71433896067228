import {
  SELECT_QUESTION,
  UNSELECT_QUESTION,
  SET_QUIZ_TYPE,
  QUIZ_CREATION_STEP,
  QUIZ_CREATION_RESET,
  RESET_SELECTED_QUESTIONS,
  EDIT_UNSELECT_QUESTION,
  ORDER_SELECTED_QUESTION
} from '../constants/quizConstants'

const initialState = {
  selected_questions: [],
  total_selected_marks:0,
  quiz_type: '',
  quiz_step: 1
}

export const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_QUESTION:
      return {
        ...state,
        selected_questions: [...state.selected_questions, action.payload], total_selected_marks: state.total_selected_marks += action.payload.marks
      }
      case ORDER_SELECTED_QUESTION:
        return {
          ...state,
          selected_questions: action.payload
        }

    case UNSELECT_QUESTION:
      return {
        ...state,
        selected_questions: state.selected_questions.filter(
          item => item.id !== action.payload.id
        ), total_selected_marks: state.total_selected_marks -= action.payload.marks
      }

    case EDIT_UNSELECT_QUESTION:
      const list = state.selected_questions;
      action.payload.forEach(q => {
        const isPresent = list.find(que => que.id === q.id)
        if (!isPresent) list.push(q)
      })
      let mark = 0;
      list.forEach(q => { mark += q.marks })
      return {
        ...state,
        selected_questions: list,
        total_selected_marks: mark
      }

    case RESET_SELECTED_QUESTIONS:
      return {
        ...state,
        selected_questions: []
      }

    case SET_QUIZ_TYPE:
      return {
        ...state,
        quiz_type: action.payload
      }

    case QUIZ_CREATION_STEP:
      return {
        ...state,
        quiz_step: action.payload
      }

    case QUIZ_CREATION_RESET:
      return {
        ...initialState
      }

    default:
      return state
  }
}
