import axios from "axios";

async function gradesWithBoard(setGrades,boardId) {
  let token = sessionStorage.getItem("token");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/from-subject-mapping?boardId=${boardId}`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response)=>{{
        setGrades(response.data.data);
      }
    })
    .catch(err=>{
      console.log(err);
      setGrades(["unable to fetch sections"]);
    })
}

export default gradesWithBoard;
