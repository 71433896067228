import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";

//Images Used
import dashHome from "../../../../src/img/dashHome.svg";
import bubble_img from "../../../../src/img/BB.svg";
import prev from "../../../../src/img/Prev.svg";
import nxt from "../../../../src/img/Nxt.svg";

//Css Pages
import "../../css/Student/Student_Enrich_Quiz_Attend.css";
import dashboardContentStyles from "../../css/dashboardContent.module.css";

//API Used
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";
import { fetch_Chapter_Quiz_Key_Api, fetch_Chapter_Quiz_Key_Api_Report } from "./Apis/Chapter_Quiz_Key_Api";

//Mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Paper, Backdrop } from "@mui/material";

// Quiz Quistion Type and Common Components
import Student_Enrich_Quiz_MCQ from "./Student_Enrich_Quiz_MCQ";
import Student_Enrich_Quiz_MSQ from "./Student_Enrich_Quiz_MSQ";
import Student_Enrich_Quiz_CS from "./Student_Enrich_Quiz_CS";
import Student_Enrich_Quiz_FIB from "./Student_Enrich_Quiz_FIB";
import Student_Enrich_Quiz_MTF from "./Student_Enrich_Quiz_MTF";
import Student_Enrich_Quiz_MTF_DrawLines from "./Student_Enrich_Quiz_MTF_DrawLines";
import Student_Enrich_Quiz_MTF_DrawLines_Resume from "./Student_Enrich_Quiz_MTF_DrawLines_Resume";

import Student_Enrich_Quiz_MTF_DD from "./Student_Enrich_Quiz_MTF_DD";
import Student_Enrich_Quiz_Submit_Modal from "./Student_Enrich_Quiz_Submit_Modal";
import Student_Enrich_Quiz_Analysis_Modal from "./Student_Enrich_Quiz_Analysis_Modal";
import ChapterQuizKey_Msq from "../Student/ChapterQuizKey/ChapterQuizKey_Msq"
import ChapterQuizKey_Fib from "../Student/ChapterQuizKey/ChapterQuizKey_Fib";
import ChapterQuizKey_Mcq from "../Student/ChapterQuizKey/ChapterQuizKey_Mcq";
import ChapterQuizKey_Mtf_Dd from "../Student/ChapterQuizKey/ChapterQuizKey_Mtf_Dd"
import ChapterQuizKeyModal from "./ChapterQuizKey/ChapterQuizKeyModal";
import { set } from "react-hook-form";
import quizJson from "../Student/Quiz_common.json"
import { Preview } from "@mui/icons-material";
import { register, unregister } from "../../../../src/Serviceworker"
import { rightClickEnable, CDNBASEURL } from "../../../../src/Serviceworker"
import $ from 'jquery';


var CDN_BASE_URL = CDNBASEURL;
console.log(rightClickEnable)
const modal = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  marginBottom: "-20px"


};

const modalStylePreview = {
  width: '90vw',
  height: '85vh',
  position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  border: "none",
  outline: "none",
  bgcolor: "white",
  // borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  rowGap: "1rem",
  overflow: "scroll",
};

const modalPreview = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  backgroundColor: "white"

};

function Student_Enrich_Quiz_Attend({
  selectedSubject,
  quizData,
  academicYearList,
  schoolId,
  branchId,
  selectedsubTopic,
  studentId,
  closeModall,
  quizExaminationId,
  resumeBtnClicked,
  setLoadQuizData,
  withTimeBtnClicked,
  withOutTimeBtnClicked,
  subjectList,
  previousPage,
  // fetchViewQuiz,
  viewQuizData,
  ispreview,
  showModalpreview,
  closeModal,
  paramsData,
  callbackforafterquizexit,
  setResumeBtnClicked,
  setQuizExaminationId
}) {
  // console.log("quiz common component loading here", quizExaminationId, "quizExaminationId", ispreview, resumeBtnClicked, quizData, viewQuizData, "currentQuestion",)
  sessionStorage.setItem("viewQuizData", JSON.stringify(viewQuizData))
  const navigate = useNavigate();
  var xxxx = sessionStorage.getItem("exid") || sessionStorage.getItem("exIdOne") || sessionStorage.getItem("exIdTwo");
  // console.log("xxxx", xxxx)
  setQuizExaminationId(xxxx)
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "550px",
    height: "550px",
    background: "#FFFFFF",
    border: "none",
  };
  window.history.pushState(null, null, window.location.href);
  const [isAuthenticatedShift, setIsAuthenticatedShift] = useState(true);
  const [quizType, setQuizType] = useState(null);
  const [modifiedDate, setModifiedDate] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const token = sessionStorage.getItem("token");
  const [remainingTime, setRemainingTime] = useState("");
  const [list, setList] = useState();
  const [questionIds, setQuestionIds] = useState([]);
  const [questions, setQuestions] = useState([]);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentQuestionId, setCurrentQuestionId] = useState();
  const [storeData, setStoreData] = useState([]);
  const [MCQ, setMCQ] = useState("");

  const [quizzData, setQuizzData] = useState({});
  const [startButtonClicked, setStartButtonClicked] = useState(false);
  const [totalQuestionss, setTotalQuestionss] = useState("");
  const [quizDetails, setQuizDetails] = useState([]);
  const [resumeData, setResumeData] = useState();
  // //console.log("quizExaminationId",quizExaminationId)
  const [attendMsq, setAttendMsq] = useState([]);
  const [attendedMsq, setAttendedMsq] = useState([]);
  const [attendMcq, setAttendMcq] = useState([]);
  const [attendedMcq, setAttendedMcq] = useState([]);
  const [attendFib, setAttendFib] = useState([]);
  const [attendedFib, setAttendedFib] = useState([]);
  const [attendMtfDd, setAttendMtfDd] = useState([]);
  const [attendMtfDl, setAttendMtfDl] = useState([]);
  const [attendMtfDlTemp, setAttendMtfDlTemp] = useState([]);
  const [attendedMtfDd, setAttendedMtfDd] = useState([]);
  const [attendedMtfDl, setAttendedMtfDl] = useState([]);
  const [tempCaseStudy, settempCaseStudy] = useState([]);
  const [attendCs, setAttendCs] = useState([]);
  const [attendedCs, setAttendedCs] = useState([]);
  const [examEndModalOpen, setExamEndModalOpen] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [openAnalysisModalTemp, setOpenAnalysisModalTemp] = useState(false);
  const [commonAttendedData, setCommonAttendedData] = useState([]);

  const [submitted, setSubmitted] = useState(false);

  const [chapterQuizKey, setChapterQuizKey] = useState(true);

  const [timeFromApi, setTimeFromApi] = useState(quizData?.totalDuration || 0);
  const [timeLeft, setTimeLeft] = useState(timeFromApi * 60);
  const [timerRunning, setTimerRunning] = useState(false);
  const [formattedTime, setFormattedTime] = useState("");

  const [elapsedTime, setElapsedTime] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const [quesstartTime, setQuesStartTime] = useState("00:00:00");
  const [quesendTime, setQuesEndTime] = useState("00:00:00");
  const [elapsedTimeArray, setElapsedTimeArray] = useState([]);
  const [quizStarted, setQuizStarted] = useState(false);
  const [exid, SetExid] = useState("")
  const [intervalIdUsed, setIntervalIdUsed] = useState("")

  sessionStorage.setItem("chapterQuizKeyBtn", chapterQuizKey);
  const subTopicId = sessionStorage.getItem("subTopicId")
  var Vquiztype = sessionStorage.getItem("quiztype")
  var question_start_time = '';
  var question_end_time = '';

  useEffect(() => {
    if (!ispreview && resumeBtnClicked) {
      setQuizStarted(true);
    }
  }, [ispreview])

  useEffect(() => {
    console.log(list, "listlistlistlistlist")
    if (!ispreview && resumeBtnClicked) {
      var answeredQuestions = list?.questionAnswerKeys?.filter(v => v?.attended === true);
      console.log("answeredQuestions", answeredQuestions)
      var looplength = list?.questionAnswerKeys?.length || 0 //(answeredQuestions?.length + 1) || 0
      console.log(list?.questionAnswerKeys, "208802208802")
      var highestAnswerOrderObj = looplength || 1
      if (answeredQuestions?.length > 0) {
        highestAnswerOrderObj = answeredQuestions?.reduce((prev, current) => {
          return (prev.answerOrder > current.answerOrder) ? prev : current;
        });
      }
      if (list) {
        console.log("if list", list)

        //working without promise
        // list?.questionAnswerKeys?.map((m, i) => {
        //   console.log("---------", m?.attended, m.answerOrder, highestAnswerOrderObj);
        //   if (m?.attended == true) {
        //     var bubbleid = "bubble_" + m?.answerOrder
        //     const bubbleidTag = document.getElementById(bubbleid);
        //     console.log("checkboxcheckboxcheckboxcheckbox bubble", bubbleidTag, bubbleid, highestAnswerOrderObj)
        //     // if (bubbleidTag) {
        //     //   console.log("mcq click")
        //     //   bubbleidTag.click();
        //     // }
        //     setTimeout(async () => {
        //       await currentQuestionHandler_forResume(m?.answerOrder)
        //     }, 100);

        //   } else {
        //     console.log("not attended", m?.attended)
        //   }
        // })

        const promises = [];

        list?.questionAnswerKeys?.forEach((m, i) => {
          console.log("---------", m.question, m?.attended, m.answerOrder);
          if (m?.attended === true) {

            // promises.push(new Promise(resolve => {
            //   setTimeout(async () => {
            //     var bubbleid = "id_bubble_" + m?.answerOrder;
            //     const bubbleidTag = document.getElementById(bubbleid);
            //     const bubbleidTagJquery = $("#" + bubbleid);
            //     console.log("checkboxcheckboxcheckboxcheckbox bubble", bubbleidTagJquery, bubbleidTag, bubbleid);
            //     // await currentQuestionHandler_forResume(m?.answerOrder);
            //     if (bubbleidTagJquery.length && bubbleidTagJquery.trigger) {
            //       console.log("mcq bubble click")
            //       // bubbleidTag.click();
            //       $("#" + bubbleid).trigger('click');
            //       const buttonid = document.getElementById("az-student-quiz-nxt-btn");
            //       const buttonidjquery = $("#" + "az-student-quiz-nxt-btn");
            //       console.log("checkboxcheckboxcheckboxcheckbox button", buttonidjquery, buttonid)
            //       if (buttonidjquery.length && buttonidjquery.trigger) {
            //         console.log("mcq button click")
            //         // buttonid.click();
            //         // buttonidjquery.trigger('click');
            //         $("#" + "az-student-quiz-nxt-btn").trigger('click')
            //       }
            //     }
            //     resolve();
            //   }, 500);
            // }));


            promises.push(new Promise((resolve, reject) => {
              setTimeout(() => {
                var bubbleid = "id_bubble_" + m?.answerOrder;
                const bubbleidTagJquery = $("#" + bubbleid);
                console.log("checkboxcheckboxcheckboxcheckbox bubble", bubbleidTagJquery, bubbleid);

                if (bubbleidTagJquery.length > 0) {
                  console.log("mcq bubble click")
                  bubbleidTagJquery.trigger('click');
                  setTimeout(() => {
                    const buttonidjquery = $("#" + "az-student-quiz-nxt-btn");
                    console.log("checkboxcheckboxcheckboxcheckbox button", buttonidjquery)

                    // Check if the button element exists
                    if (buttonidjquery.length > 0) {
                      console.log("mcq button click")
                      buttonidjquery.trigger('click');
                    } else {
                      console.log("Button element not found");
                      // reject(new Error("Button element not found"));
                    }
                    resolve(); // Resolve the promise once the button click is triggered
                  }, 200);
                } else {
                  console.log("Bubble element not found");
                  reject(new Error("Bubble element not found"));
                }
              }, 500);
            }));

          } else {
            console.log("not attended", m?.attended);
          }
        });

        // Wait for all promises to resolve using Promise.all()
        Promise.all(promises).then(() => {
          console.log("All iterations and setTimeout completed");

          var bubbleid = "id_bubble_" + highestAnswerOrderObj?.answerOrder;
          const bubbleidTag = document.getElementById(bubbleid);
          console.log("checkboxcheckboxcheckboxcheckbox bubble", bubbleidTag, bubbleid);
          currentQuestionHandler_forResume(highestAnswerOrderObj?.answerOrder - 1);

          console.log(highestAnswerOrderObj?.answerOrder == looplength - 1, looplength, highestAnswerOrderObj?.answerOrder)
          if (highestAnswerOrderObj?.answerOrder == looplength - 1) {
            console.group("yeash you have to do")
          }


          // list?.questionAnswerKeys?.forEach((m, i) => {
          //   console.log("---------", m?.attended, m.answerOrder);
          //   if (m?.attended === true) {

          //     promises.push(new Promise(resolve => {
          //       setTimeout(async () => {
          //         var bubbleid = "id_bubble_" + m?.answerOrder;
          //         const bubbleidTag = document.getElementById(bubbleid);
          //         const bubbleidTagJquery = $("#" + bubbleid);
          //         console.log("checkboxcheckboxcheckboxcheckbox bubble", bubbleidTagJquery, bubbleidTag, bubbleid);
          //         // await currentQuestionHandler_forResume(m?.answerOrder);
          //         if (bubbleidTagJquery.length && bubbleidTagJquery.trigger) {
          //           console.log("mcq click")
          //           // bubbleidTag.click();
          //           $("#" + bubbleid).trigger('click');
          //           const buttonid = document.getElementById("az-student-quiz-nxt-btn");
          //           const buttonidjquery = $("#" + "az-student-quiz-nxt-btn");
          //           console.log("checkboxcheckboxcheckboxcheckbox button", buttonidjquery, buttonid)
          //           if (buttonidjquery.length && buttonidjquery.trigger) {
          //             console.log("mcq click")
          //             // buttonid.click();
          //             // buttonidjquery.trigger('click');
          //             $("#" + "az-student-quiz-nxt-btn").trigger('click')
          //           }
          //         }
          //         resolve();
          //       }, 100);
          //     }));
          //   } else {
          //     console.log("not attended", m?.attended);
          //   }
          // });


        });

      } else {
        console.log("else list")
      }

      // const executeLoopWithDelay = (loopLength, currentIndex) => {
      //   // Base case: Stop the recursion when currentIndex equals loopLength
      //   if (currentIndex === loopLength) {
      //     return;
      //   }
      //   console.log("executed", looplength, currentIndex, answeredQuestions?.length)
      //   nextQuestionHandler();
      //   const buttonid = document.getElementById("az-student-quiz-nxt-btn");
      //   console.log("checkboxcheckboxcheckboxcheckbox", buttonid, looplength)
      //   if (buttonid) {
      //     console.log("mcq click")
      //     buttonid.click();
      //   }
      //   setTimeout(() => {
      //     executeLoopWithDelay(loopLength, currentIndex + 1);
      //   }, 1000);
      // };
      // executeLoopWithDelay(looplength, 0);
    }
  }, [list])




  useEffect(() => {
    if (!ispreview && resumeBtnClicked) {
      console.log("setlistattendedCs",)
      // setAttendedCs([])
      // setAttendedFib([])
      // setAttendMsq([])
      // setAttendedMcq([])
      // setAttendMtfDl([])
      // setAttendedMtfDd([])
    }
  }, [!ispreview, resumeBtnClicked]);
  // console.log("quizStarted", quizStarted)
  useEffect(() => {
    const fetchData = async () => {
      const exIdOne = sessionStorage.getItem("exIdOne");
      const exIdTwo = sessionStorage.getItem("exIdTwo");
      const examId = exIdTwo ? exIdTwo : exIdOne;
      // console.log(quizzData?.data?.id, examId, exid, JSON.parse(sessionStorage.getItem("exid")))
      const intervalId = setInterval(async () => {
        setQuizExaminationId(quizzData?.data?.id || examId || exid || JSON.parse(sessionStorage.getItem("exid")));
        var item = {
          examId: quizzData?.data?.id || examId || exid || JSON.parse(sessionStorage.getItem("exid")),
          status: "IN_PROGRESS",
          answerSheets: JSON.parse(sessionStorage.getItem("QUIZ_DATA")) || []
        }
        // console.log("itemitem", item, item?.answerSheets?.length, item?.examId !== "" && item?.examId !== null, item?.answerSheets !== null)
        if (item?.answerSheets?.length > 0 && (item?.examId !== "" && item?.examId !== null) && item?.answerSheets !== null) {
          console.log("service worker calling", item)

          // if (!resumeBtnClicked) {
          try {

            const res = await axios.post(
              `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/answerSaveOrUpdate`,
              item,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            console.log("service worker success", res.data);
            register()
          } catch (err) {
            console.error("service worker error", err);
          }
          // }
        } else {
          //console.log("answerSheets length ", item.answerSheets)
        }
      }, 30000);
      setIntervalIdUsed(intervalId);
      return () => clearInterval(intervalId);
    };
    if (quizStarted) {
      fetchData();
    }
  }, [quizStarted]);

  function timeToSeconds(timeString) {
    // console.log("timeString", timeString)
    // Split the time string into hours, minutes, and seconds
    let timeParts = timeString?.match(/(\d+)Hrs (\d+)Mins (\d+)Sec/);
    let totalSeconds = 0
    if (timeParts) {
      // Extract hours, minutes, and seconds
      let hours = parseInt(timeParts[1]) || 0;
      let minutes = parseInt(timeParts[2]) || 0;
      let seconds = parseInt(timeParts[3]) || 0;
      totalSeconds = hours * 3600 + minutes * 60 + seconds
    }

    return totalSeconds;
  }
  useEffect(() => {
    const fetchSub = async () => {
      let sub = [];
      if (paramsData) {
        console.log("ChapterQuizKeyModal if")
        sub = await fetch_Chapter_Quiz_Key_Api_Report(paramsData);
      } else {
        console.log("ChapterQuizKeyModal else")
        sub = await fetch_Chapter_Quiz_Key_Api();
      }
      console.log(sub.data, "sub.data", ispreview, resumeBtnClicked)

      if (sub?.data) {
        // var answeredTrue = sub.data?.questionAnswerKeys.filter(v => v.attended === true);
        // var answeredFalse = sub.data?.questionAnswerKeys.filter(v => v.attended === false);
        // console.log(answeredTrue, answeredFalse)
        // var answeredFalseshuffled = shuffleArray(answeredFalse);
        // console.log("shuffled", answeredFalseshuffled)
        // console.log([...answeredTrue, ...answeredFalse])
        // var aftershuffled = [...answeredTrue, ...answeredFalseshuffled]
        // console.log("shuffshuff aftershuffled", aftershuffled)
        console.log("1vvvvvvvvvvvvvvvvvvvvvvvvvvv", sub.data?.questionAnswerKeys)
        var arr = sub.data?.questionAnswerKeys.sort((a, b) => a.questionOrder - b.questionOrder);
        console.log("2vvvvvvvvvvvvvvvvvvvvvvvvvvv", sub.data?.questionAnswerKeys, arr)
        sub.data.questionAnswerKeys = arr
        setList(sub.data);
        if (!ispreview && resumeBtnClicked) {
          var VanswerSheets = [];
          const exIdOne = sessionStorage.getItem("exIdOne");
          const exIdTwo = sessionStorage.getItem("exIdTwo");
          const exIdThree = sessionStorage.getItem("exid");

          const examId = exIdTwo || exIdOne || exIdThree;
          // console.log("examId", examId, exIdOne, exIdTwo, exIdThree)
          // var shuff = shuffleArray(sub.data?.questionAnswerKeys)
          var shuff = sub.data?.questionAnswerKeys //aftershuffled
          // var shuff = sub.data?.questionAnswerKeys;
          shuff?.map((e, i) => {
            var item = {
              examId: examId,
              questionId: e?.questionId,
              parentQuestionId: e?.parentQuestionId,
              questionTypeCode: e?.questionTypeCode,
              answer: [],
              endDate: quizData?.endDate,//quizData.endDate
              endTime: quizData?.endTime,
              pqPausedAt: "",
              startTime: quizData?.startTime,
              totalTimeSpend: timeToSeconds(e.totalTimeSpend),
              order: i,
              isAnswered: e.attended

            };
            VanswerSheets.push(item)
          })
          // console.log(VanswerSheets, "VanswerSheets")
          sessionStorage.setItem("QUIZ_DATA", JSON.stringify(VanswerSheets));
        }
      }
    };
    fetchSub();
    setCurrentQuestion(0);
  }, [ispreview]);

  const handleChange = (event) => {
    const value = event.target.value;
    const item = questions && questions[currentQuestion];
    const id = item?.questionId;
    if (id) {
      const clone = [...storeData];
      const findIndex = clone?.findIndex((data) => data?.questionId === id);
      if (findIndex !== -1) {
        clone[findIndex].answer = value;
        setStoreData(clone);
      } else {
        const data = {
          quizId: item?.id,
          question: item?.question,
          questionId: item?.questionId,
          answer: value,
          // subQuestions: [],
        };
        setStoreData((pre) => [...pre, data]);
      }
      setMCQ(value);
    }
  };
  // const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  // const handleQuizType = (type) => {
  //   setQuizType(type);
  // };

  const handleSubmitModalOpen = () => setOpen(true);
  const handleSubmitModalClose = () => setOpen(false);

  const fetchRealTimeQuizGraph = async () => {
    const exIdOne = sessionStorage.getItem("exIdOne");
    const exIdTwo = sessionStorage.getItem("exIdTwo");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const examId = exIdTwo ? exIdTwo : exIdOne;
    //console.log("examId", examId, exIdTwo, exIdOne, sessionStorage.getItem("exid"))
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/real-time-quiz-graph?examId=${examId}&schoolId=${schoolId}&studentId=${studentId}&branchId=${branchId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${selectedSubject}&reportPage=false`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data || [];
  };
  const handleSubmitBtn = async () => {

    Attend_Exam_Post_Api();
    // Submit_Exam_Put_Api();
    setExamEndModalOpen(false);
    setChapterQuizKey(false);
    // closeModall();
    setShowModal(true);
    // setOpenAnalysisModal(true)

    // // alert("handleSubmitBtn called")
    // // debugger
    // await Attend_Exam_Post_Api();
    // // alert("true execuitng")
    // setSubmitted(true);
    // // alert("after setSubmitted")
    // setShowModal(true);
    // // alert("after showmodal")
    // handleSubmitModalOpen();
    // // alert("after handleSubmitModalOpen")
    // setLoadQuizData(false);




    // alert("after setLoadQuizData")
    // alert(quizExaminationId);
    // setQuizExaminationId()
    // const realTimeQuizGraphFetch = async () => {
    //   const realTimeQuizGraphFetchData = await fetchRealTimeQuizGraph();
    // //console.log("realTimeQuizGraphFetchData", realTimeQuizGraphFetchData)
    //   alert(JSON.stringify(realTimeQuizGraphFetchData))
    //   return realTimeQuizGraphFetchData?.data.accuracyAnalysis.some(
    //     (item) =>
    //       item.accuracyStatus === "Unattempted" && item.accuracyCount > 0
    //   );
    // };
    // realTimeQuizGraphFetch().then((attempted) => {
    // //console.log("attempted", attempted)
    //   if (attempted) {
    //     setShowModal(true);
    //     handleSubmitModalOpen();
    //     setLoadQuizData(false);
    //   } else {
    //     const sumbitQuiz = async () => {
    //       const response = await Submit_Exam_Put_Api();
    //       return response;
    //     };
    //     sumbitQuiz().then((response) => {
    //       if (response) {
    //         setLoadQuizData(false);
    //         setOpenAnalysisModal(true);
    //       }
    //     });
    //   }
    // });
    // // fetchViewQuiz();
  };
  // const handleSubmitBtn = () => {
  //   alert("handleSubmitBtn called");
  //   setSubmitted(true);
  //   setShowModal(true);
  //   handleSubmitModalOpen();
  //   setLoadQuizData(false);
  //   Attend_Exam_Post_Api();

  //   Attend_Exam_Post_Api();

  //   setShowModal(true);
  //   handleSubmitModalOpen();
  //   setLoadQuizData(false);



  //   const realTimeQuizGraphFetch = async () => {
  //     const realTimeQuizGraphFetchData = await fetchRealTimeQuizGraph();
  //     return realTimeQuizGraphFetchData?.data.accuracyAnalysis.some(
  //       (item) =>
  //         item.accuracyStatus === "Unattempted" && item.accuracyCount > 0
  //     );
  //   };
  //   realTimeQuizGraphFetch().then((attempted) => {
  //   //console.log("attempted",attempted);
  //     if (attempted) {
  //       setShowModal(true);
  //       handleSubmitModalOpen();
  //       setLoadQuizData(false);
  //     } else {
  //       // const sumbitQuiz = async () => {
  //       //   const response = await Submit_Exam_Put_Api();
  //       //   return response;
  //       // };
  //       // sumbitQuiz().then((response) => {
  //       //   if (response) {
  //       //     setLoadQuizData(false);
  //       //     setOpenAnalysisModal(true);
  //       //   }
  //       // });
  //     }
  //   });
  //   // fetchViewQuiz();
  // };

  const handleCloseExamEndModal = () => {
    // alert("handleCloseExamEndModal")
    setExamEndModalOpen(false);
    setLoadQuizData(false);
    setOpenAnalysisModal(true);
    // alert("setOpenAnalysisModal 2")


    // sessionStorage.removeItem("itemload");
    // sessionStorage.removeItem("viewQuizData");
    // setIsModalOpen(false)
    // sessionStorage.removeItem("practiceQuizAnswer");
    // sessionStorage.removeItem("drag_drop_array");
    // sessionStorage.removeItem("drag_drop_array_final");
    // sessionStorage.removeItem("shuffledQuiz");
    // sessionStorage.removeItem("alltheQuizAnswer");
    // sessionStorage.removeItem("exid");
    // sessionStorage.removeItem("exIdTwo");
    // sessionStorage.removeItem("drag_drop_array_final")
  };

  const handleAnalysisModalClose = () => {
    // alert("setOpenAnalysisModal 1")
    setOpenAnalysisModal(false);
    setLoadQuizData(true);
  };

  useEffect(() => {
    if (!ispreview) {
      if (elapsedTime) {
        setSeconds(0);
        const intervalId = setInterval(() => {
          // Update the state to increment the seconds
          setSeconds((prevSeconds) => prevSeconds + 1);
        }, 1000);
        // This function will be called when the component unmounts
        return () => {
          // Clear the interval to stop the timer
          clearInterval(intervalId);
        };
      }
      GetLastModifiedAt(setModifiedDate);
      if (rightClickEnable && isAuthenticatedShift) {
        document.addEventListener('keydown', function (event) {
          //   //console.log(event)
          if (event.key === "Enter") {
            //   //console.log("Enter key pressed");
            const isDisabledButton =
              event.target === document.getElementsByClassName('ExitBtn') ||
              event.target === document.getElementsByClassName('PrevBtn') ||
              event.target === document.getElementsByClassName('NxtBtn') ||
              event.target === document.getElementsByClassName('SubmitBtn');
            //   //console.log("isDisabledButton",isDisabledButton)
            // Prevent the default behavior only for the specified buttons
            if (isDisabledButton) {
              event.preventDefault();
            }

          } else if (event.key === " ") {
            //   //console.log("Spacebar pressed",);
            const isDisabledButton =
              event.target === document.getElementsByClassName('ExitBtn') ||
              event.target === document.getElementsByClassName('PrevBtn') ||
              event.target === document.getElementsByClassName('NxtBtn') ||
              event.target === document.getElementsByClassName('SubmitBtn');
            //   //console.log("isDisabledButton",isDisabledButton)
            // Prevent the default behavior only for the specified buttons
            if (isDisabledButton) {
              event.preventDefault();
            }

          }
        });
        //student keydown 
        document.addEventListener('keydown', function (event) {
          //   //console.log(event);
          // alert(event.getModifierState)
          const isModifierKey = event.getModifierState('Alt') ||
            event.getModifierState('Tab') ||
            event.getModifierState('Shift');

          if (event.key === 'F12' || isModifierKey) {
            //   //console.log("Modifier key pressed");
            event.preventDefault();
          } else if (event.key === "Enter" || event.key === " ") {
            //   //console.log(`${event.key} key pressed`);

            // Check if no modifier keys are pressed
            const noModifiers = !event.getModifierState('Alt') &&
              !event.getModifierState('Control') &&
              !event.getModifierState('Meta') &&
              !event.getModifierState('Shift');

            if (noModifiers) {
              //   //console.log("No modifier keys pressed");
              // Prevent the default behavior only for <button> elements
              if (event.target.tagName === 'BUTTON') {
                event.preventDefault();
                //   //console.log("Key event prevented for <button>");
              }
            }
          }
        });

        const handleBeforeUnload = (event) => {
          //   //console.log("close tab", event.type); // For reload and close tab
          const isRefresh = !event.currentTarget.performance.navigation.type;
          //   //console.log("isRefresh", isRefresh);

          if (event.type === 'beforeunload') {
            const message = "Are you sure you want to leave? Your progress may be lost.";
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
          } else {
            //   //console.log("else close tab");
            const message = "Are you sure you want to close the tab?";
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
          }
        };

        const handleContextMenu = (event) => {
          // Prevent the default right-click behavior
          //   //console.log("right click prevent");
          event.preventDefault();
        };

        const handlePopstate = (event) => {
          //   //console.log("handlepopstate", event.type, window.location.href);
          // Prevent the default behavior of navigating back
          window.history.pushState(null, document.title, window.location.href);
        };

        const handleUnload = (event) => {
          //   //console.log("handleunload", event.type);
          const message = "Are you sure you want to close the tab?";
          event.returnValue = message; // Standard for most browsers
          return message; // For some older browsers
        };

        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('copy', handleContextMenu);
        window.addEventListener('beforeunload', handleBeforeUnload);
        // window.addEventListener('popstate', handlePopstate);
        window.addEventListener('unload', handleUnload);

        // Cleanup the event listeners when the component is unmounted
        return () => {
          window.removeEventListener('unload', handleUnload);
          window.removeEventListener('beforeunload', handleBeforeUnload);
          // window.removeEventListener('popstate', handlePopstate);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
        // return () => { };
      }

    }
  }, []);

  useEffect(() => {
    if (!ispreview) {
      var currentDate = moment();
      var formattedTime = currentDate.format('HH:mm:ss');
      //   //console.log('1st start time >> ', formattedTime);
      question_start_time = formattedTime;
      setQuesStartTime(formattedTime);
      setOpenModal(true);
      setCurrentQuestion(0);

      const handleBeforeUnload = (event) => {
        //   //console.log("close tab", event.type); // For reload and close tab
        const isRefresh = !event.currentTarget.performance.navigation.type;
        //   //console.log("isRefresh", isRefresh);

        if (event.type === 'beforeunload') {
          const message = "Are you sure you want to leave? Your progress may be lost.";
          event.returnValue = message; // Standard for most browsers
          return message; // For some older browsers
        } else {
          //   //console.log("else close tab");
          const message = "Are you sure you want to close the tab?";
          event.returnValue = message; // Standard for most browsers
          return message; // For some older browsers
        }
      };

      const handleContextMenu = (e) => {
        // if (!authContext.isAuthenticated) { this is to restrict right click based on loggedin info
        e.preventDefault();
        //   //console.log("handleContextMenu called")
      };
      document.addEventListener('contextmenu', handleContextMenu);
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, []);
  function shuffleArray(array) {
    const shuffledArray = array?.map(obj => ({ ...obj, isAnswered: false }));
    for (let i = shuffledArray?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }
  function shuffleArrayBasedAnswered(array) {
    var answeredTrue = array.filter(v => v.antended == true);
    var answeredFalse = array.filter(v => v.antended == false)
    console.log(answeredTrue, answeredFalse)
    var shuffled = shuffleArray(answeredFalse);
    console.log("shuffled", shuffled)
    console.log([...answeredTrue, ...answeredFalse])

    // return shuffledArray;
  }
  const response = async () => {
    // alert("response called" + rightClickEnable)
    // // //console.log("questionIds", questionIds)
    // const promises1 = questionIds.map((questionId) => {
    //   return axios.get(
    //     `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${questionId}`,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    // });
    // var responsesv = await Promise.all(promises1);
    // var questionsidsv = responsesv.map((v, i) => {
    //   return { subQuestions: v.data.data.hasSubQuestions, Id: v.data.data.id };
    // });
    // console.log("responses---------------------", responsesv, questionsidsv)
    var questionsids = [];
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    var boardId = sessionStorage.getItem("boardId")
    // var URL = 'https://cors-anywhere.herokuapp.com/${CDNBASEURL}/quiz/' + boardId + '/' + gradeId + '/' + selectedSubject + '/' + academicYearList + '/quiz-' + quizData.quizId + '.json'
    // console.log(URL, quizData, boardId + '/' + gradeId + '/' + selectedSubject + '/' + academicYearList + '/quiz-' + quizData.quizId + '.json')
    var VURL = `${CDN_BASE_URL}` + '/quiz/' + boardId + '/' + gradeId + '/' + selectedSubject + '/' + academicYearList + '/quiz-' + quizData.quizId + '.json'
    // const response1 = await fetch('https://cors-anywhere.herokuapp.com/${CDNBASEURL}/quiz/' + boardId + '/' + gradeId + '/' + selectedSubject + '/' + academicYearList + '/quiz-' + quizData.quizId + '.json', {
    const response1 = await fetch(VURL, {
      method: 'GET',
      headers: {
        'Origin': CDN_BASE_URL,
        'Content-Type': "application/json"
      },
    });
    const data1 = await response1.json();
    //console.log("=========================1111111", data1, quizJson);

    const shuffledArray = (!ispreview && !resumeBtnClicked) ? shuffleArray(data1.quizz.questions) : data1.quizz.questions;
    //console.log(shuffledArray, "shuffledQuizshuffledQuizshuffledQuiz");
    if (shuffledArray) {
      sessionStorage.setItem("shuffledQuiz", JSON.stringify(shuffledArray));
      questionsids = shuffledArray?.map((v, i) => {
        return { subQuestions: v.hasSubQuestions, Id: v.id };
      });
      // console.log("questionsids", questionsids)
      setQuestionIds(questionsids);
      setTotalQuestionss(shuffledArray?.length);
      const questionss = shuffledArray
      let questionandSubquestions = [];
      let timeTakenArray = [];
      questionss.forEach((v, i) => {
        questionsids.forEach((value, id) => {
          // console.log("============================", questionsids, value.id, v.id)
          if (v?.id == value?.Id) {
            questionandSubquestions.push({ ...v, ...value });
            timeTakenArray.push({ ...v, ...value, timeTaken: "00:00:00" });
          } else {
            //console.log("questionsids else")
          }
        });
      });
      console.log("questionss", questionss, timeTakenArray)
      setQuestions(questionss)
      setElapsedTime(true);
      setElapsedTimeArray(timeTakenArray);
      console.log("setElapsedTimeArray")
    }
  };
  // Start The Examination (POST) API -> Integration //
  const StartExam_Post_Api = () => {
    ////console.log("StartExam_Post_Api", quizData)
    var formattedTime = moment().format('h:mm A');
    //   //console.log("formattedTime", formattedTime, Vquiztype, quizData.quizTypeName)
    if (Vquiztype === 'practice' || quizData.quizTypeName === 'Practice Quiz') {
      sessionStorage.setItem("practicequizStartTime", JSON.stringify(formattedTime));
    } else {
      sessionStorage.setItem("practicequizStartTime", JSON.stringify(""));
    }

    setLoadQuizData(true);
    var item = {
      releasedId: quizData.releasedId,
      quizId: quizData.quizId,
      quizTypeId: quizData.quizTypeId,
      startDate: quizData.startDate,
      startTime: quizData.startTime,
      status: "START",
      academicYearId: academicYearList,
      boardId: sessionStorage.getItem("boardId"),
      schoolId: schoolId,
      branchId: branchId,
      gradeId: sessionStorage.getItem("gradeId"),
      subjectId: selectedSubject,
      // subTopicId: selectedsubTopic ? selectedsubTopic : subTopicId,
      sectionId: sessionStorage.getItem("sectionId"),
      studentId: studentId,
    };
    //   //console.log("start4")
    if (subTopicId) {
      item = { ...item, subTopicId: subTopicId }
    }

    const res = axios.post(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/start`,
      item,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        //console.log("res.data", res.data)
        setQuizzData(res.data.data);
        setQuizStarted(true)
        sessionStorage.setItem("exid", JSON.stringify(res?.data?.data?.id));
        SetExid(res?.data?.data?.id)
        // setQuizExaminationId(res.data.data.id)
        setStartButtonClicked(true);
        startTimer();
        startQuesTimer();// to record ques time
        handleClose();
        sessionStorage.setItem("exIdOne", res.data.data.id);


        const exIdOne = sessionStorage.getItem("exIdOne");
        const exIdTwo = sessionStorage.getItem("exIdTwo");
        const examId = exIdTwo ? exIdTwo : exIdOne;

        var VanswerSheets = [];
        questions?.map((e, i) => {
          var item = {
            examId: examId ? examId : "",
            questionId: e.id,
            parentQuestionId: e.parentQuestionId,
            questionTypeCode: e.questionTypeCode,
            // code: questions[currentQuestion]?.matchType?.code,
            answer: [],
            endDate: moment().format('YYYY-MM-DD'),//quizData.endDate
            endTime: moment().format('hh:mm A'),
            pqPausedAt: "",
            startTime: quizData.startTime,
            totalTimeSpend: currentQuestion === 0 ? elapsedTimeArray[currentQuestion].timeTaken : elapsedTimeArray[currentQuestion - 1].timeTaken,
            order: i,
            isAnswered: false

          };
          VanswerSheets.push(item)
        })
        console.log(VanswerSheets, "VanswerSheets")
        sessionStorage.setItem("QUIZ_DATA", JSON.stringify(VanswerSheets));

      })
      .catch((err) => console.log(err));
  };

  //❕❗ Attend The Examination (POST) API -> Integration ❗❕//
  const Attend_Exam_Post_Api = async () => {

    var currentDate = moment();
    var formattedTime = currentDate.format('HH:mm:ss');
    // //console.log(formattedTime+'hh')    
    question_end_time = formattedTime;
    setQuesEndTime(formattedTime);
    setElapsedTime(false);


    if (quesstartTime !== "00:00:00" && formattedTime !== "00:00:00") {
      const difference = timeDifference(quesstartTime, formattedTime);
      if (currentQuestion === 0) {
        updateTimeDiffernce(currentQuestion, difference)
      } else {
        updateTimeDiffernce(currentQuestion - 1, difference)
      }

    }
    setAttendMtfDd([]);
    setAttendFib([]);
    setAttendCs([]);
    setAttendMcq([]);
    setAttendMsq([]);

    const sortedMtfdd = attendMtfDd.sort((a, b) =>
      a.order.localeCompare(b.order)
    );
    var casestudyArr = [];
    if (questions[currentQuestion]?.questionTypeCode === "CASE") {
      // casestudyArr = JSON.parse(sessionStorage.getItem("casestudy")) || [];
      casestudyArr = tempCaseStudy.filter(e => e.currentQuestion == currentQuestion)
      // console.log("tempCaseStudy", tempCaseStudy, casestudyArr, "48000")
      // console.log("case_help", JSON.parse(sessionStorage.getItem("case_help")), attendCs)
      var v = attendedCs[questions[currentQuestion].id]
      // console.log(attendedCs[questions[currentQuestion].id], v)
      // casestudyArr = attendedCs[questions[currentQuestion].id];
      // console.log(attendedCs["4028938b8c81d5de018d1aedb95f029a"], attendedCs, questions[currentQuestion].id, attendedCs[questions[currentQuestion].id])
      // console.log(" casestudyArr", casestudyArr, tempCaseStudy, currentQuestion)
      // console.log(questions, currentQuestion, attendedCs)
      sessionStorage.removeItem("casestudy")

    }
    console.log(attendMtfDd, "sortedMtfdd", questions[currentQuestion]?.questionTypeCode, JSON.stringify(attendedCs), attendMcq, sortedMtfdd, attendMtfDl, attendedMtfDl, attendMtfDlTemp);
    console.log("attendMsq", attendMsq, attendedMsq)
    const sortedFib = attendFib?.filter((value) => value !== undefined);
    //console.log("sortedFib", sortedFib)
    attendMsq.forEach(obj => {
      delete obj.currentQuestion;
      delete obj.index;
    });
    if (!ispreview && resumeBtnClicked) {
      attendMtfDlTemp.forEach(obj => {
        delete obj.id;
      });
      if (questions[currentQuestion]?.questionTypeCode === "CASE") {
        let mcqObject = casestudyArr?.find(obj => obj.type === "MCQ");
        // Remove the "currentQuestion" and "index" fields from the MCQ object
        if (mcqObject) {
          delete mcqObject?.currentQuestion;
          delete mcqObject?.index;
        }
        // console.log(casestudyArr, mcqObject, "8989898989898989898989");
      }

    }
    console.log(sortedMtfdd, "786678678787", attendMtfDlTemp, currentQuestion)
    var tempattendMtfDlTemp = attendMtfDlTemp.filter(f => f.currentQuestion == currentQuestion);
    console.log(tempattendMtfDlTemp, attendMtfDlTemp, currentQuestion)
    var obj =
      questions[currentQuestion]?.questionTypeCode === "MSQ"
        ? attendMsq
        : questions[currentQuestion]?.questionTypeCode === "MCQ"
          ? attendMcq
          : questions[currentQuestion]?.questionTypeCode === "FILL"
            ? sortedFib :
            questions[currentQuestion]?.questionTypeCode === "CASE" ?
              // resumeBtnClicked ? attendedCs : casestudyArr
              casestudyArr
              : questions[currentQuestion]?.questionTypeCode === "MATCH" &&
                questions[currentQuestion]?.matchType?.code === "DRAG_DROP"
                ? sortedMtfdd
                : tempattendMtfDlTemp; //attendedMtfDl;

    var studentAttendedorNot =
      questions[currentQuestion]?.questionTypeCode === "MSQ"
        ? attendMsq?.length > 0
        : questions[currentQuestion]?.questionTypeCode === "MCQ"
          ? attendMcq?.length > 0
          : questions[currentQuestion]?.questionTypeCode === "FILL"
            ? sortedFib?.length > 0 :
            questions[currentQuestion]?.questionTypeCode === "CASE" ?
              // resumeBtnClicked ? Object.keys(attendedCs)?.length > 0 : casestudyArr?.length > 0
              casestudyArr?.length > 0
              : questions[currentQuestion]?.questionTypeCode === "MATCH" &&
                questions[currentQuestion]?.matchType?.code === "DRAG_DROP"
                ? sortedMtfdd?.length > 0
                : tempattendMtfDlTemp?.length > 0; //attendedMtfDl;

    console.log(attendMsq, attendMcq, sortedFib, casestudyArr, attendedCs, attendedCs[questions[currentQuestion]?.questionId], sortedMtfdd, attendMtfDlTemp, "______________++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");

    // for (let key in attendedCs[questions[currentQuestion].questionId]) {
    //   if (attendedCs[questions[currentQuestion].questionId].hasOwnProperty(key)) { // Check if the property belongs to the object itself, not its prototype chain
    //     // console.log(`Keyssssssssssssssss: ${key}, Value: ${JSON.parse(JSON.stringify(attendedCs[questions[currentQuestion].questionId][key]))}`);
    //   }
    // }


    var currentDate = moment();
    var formattedTime = currentDate.format('HH:mm');
    var tempShuffledQuiz = JSON.parse(sessionStorage.getItem("shuffledQuiz")) || [];

    if (tempShuffledQuiz?.length > 0) {
      tempShuffledQuiz[currentQuestion].order = currentQuestion;
      tempShuffledQuiz[currentQuestion].isAnswered = studentAttendedorNot;
      tempShuffledQuiz[currentQuestion].answer = obj;
      tempShuffledQuiz[currentQuestion].examId = quizzData.id ? quizzData.id : quizExaminationId;
      tempShuffledQuiz[currentQuestion].questionId = resumeBtnClicked ? questions[currentQuestion]?.questionId : quizDetails?.questionIdList[currentQuestion];
      tempShuffledQuiz[currentQuestion].parentQuestionId = null;
      tempShuffledQuiz[currentQuestion].questionTypeCode = questions[currentQuestion]?.questionTypeCode;
      tempShuffledQuiz[currentQuestion].answer = obj;
      tempShuffledQuiz[currentQuestion].endDate = quizData.endDate;
      tempShuffledQuiz[currentQuestion].endTime = quizData.endTime;
      tempShuffledQuiz[currentQuestion].pqPausedAt = formattedTime;
      tempShuffledQuiz[currentQuestion].startTime = quizData.startTime;
      tempShuffledQuiz[currentQuestion].totalTimeSpend = currentQuestion === 0 ? elapsedTimeArray[currentQuestion]?.timeTaken : elapsedTimeArray[currentQuestion - 1]?.timeTaken;
      sessionStorage.setItem("shuffledQuiz", JSON.stringify(tempShuffledQuiz));
      // console.log("shuffled array", JSON.parse(sessionStorage.getItem("shuffledQuiz")))
    }
    //console.log("objobjobjobjobj", obj, currentQuestion, questions);
    let pracQuizAnsArr = JSON.parse(sessionStorage.getItem("practiceQuizAnswer")) || [];
    let General_QuizArray = JSON.parse(sessionStorage.getItem("QUIZ_DATA")) || [];

    var VcurrentQuestionId = resumeBtnClicked ? (questions[currentQuestion]?.questionId || questions[currentQuestion]?.id) : (questionIds[currentQuestion]?.Id || questions[currentQuestion]?.id);

    var item = {
      examId: quizzData.id ? quizzData?.id : quizExaminationId,
      questionId: VcurrentQuestionId,
      parentQuestionId: casestudyArr[0]?.parentQuestionId || null,
      questionTypeCode: questions[currentQuestion]?.questionTypeCode,
      // code: questions[currentQuestion]?.matchType?.code,
      answer: obj,
      endDate: quizData.endDate,
      endTime: quizData.endTime,
      // endDate: moment().format('DD/MM/YYYY'),
      // endTime: moment().format('hh:mm A'),
      pqPausedAt: formattedTime,
      startTime: quizData?.startTime,
      totalTimeSpend: currentQuestion === 0 ? elapsedTimeArray[currentQuestion]?.timeTaken : elapsedTimeArray[currentQuestion - 1]?.timeTaken,
      order: currentQuestion,
      isAnswered: studentAttendedorNot

    };
    console.log("General_QuizArray", General_QuizArray, pracQuizAnsArr, item)
    var allreadyanswered = JSON.parse(sessionStorage.getItem("alltheQuizAnswer")) || [];
    //console.log("allreadyanswered1", allreadyanswered)
    if (allreadyanswered.length > 0) {
      var currentindex = allreadyanswered.findIndex(obj => obj?.questionId === VcurrentQuestionId)
      //console.log("currentindex", currentindex)
      if (currentindex) {
        allreadyanswered[currentindex] = { answer: item?.answer, isAnswered: studentAttendedorNot }
      } else {
        allreadyanswered.push({ answer: item?.answer, isAnswered: studentAttendedorNot, currentQuestion })
      }
    } else {
      allreadyanswered.push({ answer: item?.answer, isAnswered: studentAttendedorNot, currentQuestion })
    }
    //console.log("after allreadyanswered", allreadyanswered)
    sessionStorage.setItem("alltheQuizAnswer", JSON.stringify(allreadyanswered))
    let existingIndex = pracQuizAnsArr.findIndex(
      (value) => value.examId === item?.examId && value?.questionId === item?.questionId
    );
    console.log(studentAttendedorNot, "studentAttendedorNot", pracQuizAnsArr, obj?.length, existingIndex, item)
    if (studentAttendedorNot) {
      if (existingIndex !== -1) {
        pracQuizAnsArr[existingIndex] = item;
      } else {
        pracQuizAnsArr.push(item);
      }
      const index = General_QuizArray.findIndex(v => v.questionId === item?.questionId);
      // console.log("index", index, item.questionId)
      if (index !== -1) {
        General_QuizArray[index] = item;
      } else {
        General_QuizArray.push(item)
      }
      console.log("final---------------------", General_QuizArray);
      sessionStorage.setItem('QUIZ_DATA', JSON.stringify(General_QuizArray));
      sessionStorage.setItem("practiceQuizAnswer", JSON.stringify(pracQuizAnsArr));
    }


    setElapsedTime(true);
    var currentDate = moment();
    var formattedTime = currentDate.format('HH:mm:ss');
    question_start_time = formattedTime;
    setQuesStartTime(formattedTime);
    //   //console.log('start time >> ', formattedTime);

    // return true;
  };

  const updateTimeDiffernce = async (index, value) => {

    console.log("elapsedTimeArray420", elapsedTimeArray, index,)

    if (!ispreview && resumeBtnClicked) {
      let timeTakenArray = [];
      if (elapsedTimeArray.length == 0 && (!ispreview && resumeBtnClicked)) {
        const data = await fetch_Chapter_Quiz_Key_Api();
        console.log(data, "datam")

        data.data.questionAnswerKeys.forEach((value, id) => {
          timeTakenArray.push({ ...value, timeTaken: "00:00:00" });
        });
        console.log("timeTakenArray", timeTakenArray)
        // elapsedTimeArray = timeTakenArray;
        setElapsedTimeArray(timeTakenArray)
      }
      console.log("elapsedTimeArray", elapsedTimeArray)
      var newElapsedTime = []//const newElapsedTime = [...elapsedTimeArray]
      if (elapsedTimeArray?.length > 0) {
        newElapsedTime = [...elapsedTimeArray]
      } else {
        newElapsedTime = [...timeTakenArray]
      }

      console.log('>> ', newElapsedTime, value)


      if (newElapsedTime[index]?.timeTaken !== "00:00:00") {
        // console.log('BEF >> ', newElapsedTime[index].timeTaken, index)
        const momentTime1 = moment(newElapsedTime[index]?.timeTaken, 'HH:mm:ss') || '00:00:ss';
        const momentTime2 = moment(value || '00:00:00', 'HH:mm:ss');

        console.log("momentTime1", momentTime1, momentTime2)

        const totalDuration = moment.duration({
          hours: momentTime1.hours() + momentTime2.hours(),
          minutes: momentTime1.minutes() + momentTime2.minutes(),
          seconds: momentTime1.seconds() + momentTime2.seconds(),
        });

        var res = moment.utc(totalDuration.asMilliseconds()).format('HH:mm:ss');
        console.log('updated time >> ', res, totalDuration)
        newElapsedTime[index].timeTaken = res;

      } else {
        newElapsedTime[index].timeTaken = value;
      }
      console.log('AFT >> ', newElapsedTime[index]?.timeTaken)
      setElapsedTimeArray(newElapsedTime);
      console.log('setElapsedTimeArray elapsedTimeArray >> ', elapsedTimeArray);

    } else {

      const newElapsedTime = [...elapsedTimeArray];
      console.log('>> ', newElapsedTime, value)
      console.log('BEF >> ', newElapsedTime[index].timeTaken)
      if (newElapsedTime[index].timeTaken !== "00:00:00") {

        const momentTime1 = moment(newElapsedTime[index].timeTaken, 'HH:mm:ss');
        const momentTime2 = moment(value, 'HH:mm:ss');

        const totalDuration = moment.duration({
          hours: momentTime1.hours() + momentTime2.hours(),
          minutes: momentTime1.minutes() + momentTime2.minutes(),
          seconds: momentTime1.seconds() + momentTime2.seconds(),
        });

        var res = moment.utc(totalDuration.asMilliseconds()).format('HH:mm:ss');
        ////console.log('updated time >> ', res)
        newElapsedTime[index].timeTaken = res;

      } else {
        newElapsedTime[index].timeTaken = value;
      }
      console.log('AFT >> ', newElapsedTime[index].timeTaken)
      setElapsedTimeArray(newElapsedTime);
      console.log('elapsedTimeArray >> ', elapsedTimeArray);

    }

  };

  function timeStringToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  function secondsToTimeString(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  }

  function timeDifference(time1, time2) {

    //   //console.log('start/end time >> ', time1, time2)

    // //console.log('start/end time >> ',time1 , time2)

    const seconds1 = timeStringToSeconds(time1);
    const seconds2 = timeStringToSeconds(time2);
    const difference = Math.abs(seconds1 - seconds2);
    return secondsToTimeString(difference);
  }


  //❕❗ Submit The Examination (PUT) API -> Integration ❗❕//
  const Submit_Exam_Put_Api = async () => {
    unregister();
    clearInterval(intervalIdUsed);
    setIntervalIdUsed(null);
    var item = {};
    if (Vquiztype === 'practice' || quizData.quizTypeName === 'Practice Quiz') {
      item = {
        examId: quizzData.id ? quizzData.id : quizExaminationId,
        status: "END",
        answerSheets: JSON.parse(sessionStorage.getItem("QUIZ_DATA")) || [],//practiceQuizAnswer
        // startTime: JSON.parse(sessionStorage.getItem("practicequizStartTime")),
      };
    } else {
      item = {
        examId: quizzData.id ? quizzData.id : quizExaminationId,
        status: "END",
        answerSheets: JSON.parse(sessionStorage.getItem("QUIZ_DATA")) || [],
        // answerSheetsNew: JSON.parse(sessionStorage.getItem("shuffledQuiz")),
      };
    }
    sessionStorage.removeItem("itemload");
    console.log(sessionStorage.getItem("itemload"), "11-4-24")
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/submit`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      var currentInterval = sessionStorage.getItem("currentInterval");
      console.log("currentInterval", currentInterval, "submit is success")
      clearInterval(currentInterval);
      sessionStorage.setItem("currentInterval", 0);

      if (res) {
        // console.log("if is happening-----------------------------")
        // alert("true" + intervalIdUsed)
        openAnalysisModalTemp(true);
        setStartButtonClicked(false);
        setResumeBtnClicked(false);
        sessionStorage.removeItem("practiceQuizAnswer");
        sessionStorage.removeItem("draw_line_array");
        sessionStorage.removeItem("drag_drop_array_final");
        sessionStorage.removeItem("itemload");
        // console.log(sessionStorage.getit("itemload"))
        sessionStorage.removeItem("viewQuizData");
        sessionStorage.removeItem("casestudy");

        // added on 10-4-24 because exit button removed
        sessionStorage.removeItem("shuffledQuiz");
        sessionStorage.removeItem("alltheQuizAnswer");
        // sessionStorage.removeItem("exid");
        // sessionStorage.removeItem("exIdTwo");
        sessionStorage.removeItem("case_help");
        // console.log("intervalIdUsed", intervalIdUsed)

        unregister();
        clearInterval(intervalIdUsed);
        setIntervalIdUsed(null);

        const realTimeQuizGraphFetch = async () => {
          const realTimeQuizGraphFetchData = await fetchRealTimeQuizGraph();
          return realTimeQuizGraphFetchData?.data.accuracyAnalysis.some(
            (item) =>
              item.accuracyStatus === "Unattempted" && item.accuracyCount > 0
          );
        };
        realTimeQuizGraphFetch().then((attempted) => {
          if (attempted) {
            setShowModal(true);
            handleSubmitModalOpen();
            setLoadQuizData(false);
          } else {
            // alert("look into this")
            // const sumbitQuiz = async () => {
            //   const response = await Submit_Exam_Put_Api();
            //   return response;
            // };
            // sumbitQuiz().then((response) => {
            //   if (response) {
            //     setLoadQuizData(false);
            //     setOpenAnalysisModal(true);
            //   }
            // });
          }
        });
        return () => clearInterval(intervalIdUsed);
      } else {
        alert("else" + intervalIdUsed)
      }
      return res;
    } catch (err) {
    }
  };

  const fetchDataUsingQuizId = async () => {
    const quizId = quizData.quizId;
    var randomizeQuestions = false;
    if (localStorage.getItem("role") == "STUDENT") {
      randomizeQuestions = true;
    }
    const response = await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/${quizId}?showCorrectAnswers=false&randomizeQuestions=${randomizeQuestions}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        ////console.log("response?.data.data", response?.data.data)
        setQuestionIds(response?.data.data.questionIdList);
        setTotalQuestionss(response?.data.data.totalQuestions);
        setQuizDetails(response?.data.data);
      });
  };
  useEffect(() => {
    //   //console.log("student_enrich_quiz_attend.js -----------");
    if (!ispreview) {
      fetchDataUsingQuizId();
    }
  }, []);

  useEffect(() => {
    if (!ispreview) {
      // setTimeout(() => {
      //   if (!resumeBtnClicked) {
      //     response();
      //   }
      // }, 1000);
      if (!resumeBtnClicked) {
        response();
      }
    }
  }, []);

  // useEffect(() => {
  //   setInterval(() => {
  //     if (!resumeBtnClicked) {
  //       response();
  //     }
  //   }, 1000);
  // }, [questionIds]);

  const subConceptsfetch = async (Id) => {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${Id}/sub-questions?showCorrectAnswer=true`;
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data?.data;
  };

  // console.log("ispreview", ispreview)
  // Total Duration Of Exam Calculation Using StartTime & EndTime
  const startTime = !ispreview ? quizData.startTime : "";
  const endTime = !ispreview ? quizData.endTime : "";
  // console.log(startTime, endTime, "start time")
  // Manual Time -> For Testing Purpose
  // const startTime = "9:15 AM";
  // const endTime = "11:55 PM";

  const start = moment(startTime, "hh:mm A");
  const end = moment(endTime, "hh:mm A");

  // Format duration string as "3hrs 45min" or "3hrs"
  let durationString = "";
  const duration = moment.duration(end.diff(start));
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  if (hours > 0) {
    durationString += `${hours}hrs`;
  }
  if (hours > 0 && minutes > 0) {
    durationString += " ";
  }
  if (minutes > 0 || durationString === "") {
    durationString += `${minutes}min`;
  }

  useEffect(() => {
    // console.log("resumeBtnClicked", resumeBtnClicked, startButtonClicked)
    if (!ispreview) {
      let quizSubmitted = false;

      if (submitted) {
        quizSubmitted = true;
      }

      const handleSubmission = () => {
        if (!quizSubmitted) {
          quizSubmitted = true;
          Attend_Exam_Post_Api();
          Submit_Exam_Put_Api();
          setExamEndModalOpen(true);
          setChapterQuizKey(false);
        }
      }

      if (resumeBtnClicked) {
        const resumeDataFetch = async () => {
          const data = await fetch_Chapter_Quiz_Key_Api();
          // console.log(data, "resumeBtnClicked quiz component")
          // alert(JSON.stringify(data))
          var arr = data.data?.questionAnswerKeys.sort((a, b) => a.questionOrder - b.questionOrder);
          console.log("2vvvvvvvvvvvvvvvvvvvvvvvvvvv", data.data?.questionAnswerKeys, arr)
          data.data.questionAnswerKeys = arr
          setQuestions(data.data.questionAnswerKeys);
          sessionStorage.setItem("shuffledQuiz", JSON.stringify(data.data.questionAnswerKeys));
          setElapsedTime(true);

          let timeTakenArray = [];
          data.data.questionAnswerKeys.forEach((value, id) => {
            timeTakenArray.push({ ...value, timeTaken: "00:00:00" });

          });
          // console.log('setElapsedTimeArray>>', timeTakenArray, list)
          setElapsedTimeArray(timeTakenArray);
        };
        resumeDataFetch();
      }
      // console.log("recent startButtonClicked", startButtonClicked, resumeBtnClicked)
      if (startButtonClicked) {
        const interval = setInterval(() => {
          // console.log("setinterval called")
          sessionStorage.setItem("currentInterval", interval);
          // console.log("started", interval)
          const currentTime = moment();
          if (currentTime.isBefore(start)) {
            // console.log("ififififififififif10-4-24")
            setRemainingTime("Not started yet");
          } else if (currentTime.isAfter(end)) {
            // console.log("elseifelseifelseif10-4-24", currentTime, end, sessionStorage.getItem("currentInterval"), JSON.parse(sessionStorage.getItem("quiz_listings")), interval, startButtonClicked, resumeBtnClicked)
            // console.log("yes it is called after quiz time ends", endTime, end)
            // cmtd on 1 april bcz evry 30 mins quiz submit then why we need this also to avoid the time over issue
            handleSubmission();
            clearInterval(interval)
            setRemainingTime("Quiz has ended");
          } else {
            // console.log("elselese10-4-24", interval)
            const remainingDuration = moment.duration(end.diff(currentTime));
            const remainingMilliseconds = remainingDuration.asMilliseconds();
            if (remainingMilliseconds <= 0) {
              handleSubmission();
              setRemainingTime("Quiz duration exceeded");
              return;
            }
            const hours = Math.floor(remainingDuration.asHours());
            const minutes = Math.floor(remainingDuration.asMinutes()) % 60;
            const seconds = Math.floor(remainingDuration.asSeconds()) % 60;
            let remainingTimeString = "";
            if (hours > 0) {
              remainingTimeString += `${hours}hrs `;
            }
            if (minutes > 0 || hours === 0) {
              remainingTimeString += `${minutes}min`;
            }
            remainingTimeString += ` ${seconds}sec`;
            setRemainingTime(remainingTimeString.trim());
          }
        }, 1000);
        return () => clearInterval(interval);
      } else if (resumeBtnClicked) {
        const interval = setInterval(() => {
          sessionStorage.setItem("currentInterval", interval);
          const currentTime = moment();
          if (currentTime.isBefore(start)) {
            // console.log("ififififififififif10-4-24 resumetnclicked")
            setRemainingTime("Not started yet");
          } else if (currentTime.isAfter(end)) {
            // console.log("elseifelseifelseif10-4-24 resumetnclicked", startButtonClicked, resumeBtnClicked)
            handleSubmission();
            clearInterval(interval)
            setRemainingTime("Quiz has ended");
          } else {
            const remainingDuration = moment.duration(end.diff(currentTime));
            const remainingMilliseconds = remainingDuration.asMilliseconds();
            // console.log("elselese10-4-24 resumetnclicked", remainingMilliseconds)
            if (remainingMilliseconds <= 0) {
              handleSubmission();
              setRemainingTime("Quiz duration exceeded");
              return;
            }
            const hours = Math.floor(remainingDuration.asHours());
            const minutes = Math.floor(remainingDuration.asMinutes()) % 60;
            const seconds = Math.floor(remainingDuration.asSeconds()) % 60;
            let remainingTimeString = "";
            if (hours > 0) {
              remainingTimeString += `${hours}hrs `;
            }
            if (minutes > 0 || hours === 0) {
              remainingTimeString += `${minutes}min`;
            }
            remainingTimeString += ` ${seconds}sec`;
            setRemainingTime(remainingTimeString.trim());
          }
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [startButtonClicked, resumeBtnClicked]);

  const nextQuestionHandler = () => {
    if (currentQuestion + 1 < questionIds?.length) {
      setCurrentQuestion(currentQuestion + 1);
    }
    Attend_Exam_Post_Api();
  };

  const preQuestionHandler = () => {
    if (currentQuestion >= 1) {
      setCurrentQuestion(currentQuestion - 1);
    }
    setElapsedTime(true);
    //   //console.log('prevous click >>', elapsedTimeArray, currentQuestion, attendedMtfDl)
  };


  const currentQuestionHandler_forResume = async (i) => {
    console.log("currentQuestionHandler_forResume", i)
    setCurrentQuestion(i);
    setElapsedTime(true);
    Attend_Exam_Post_Api();
    const buttonid = document.getElementById("az-student-quiz-nxt-btn");
    console.log("checkboxcheckboxcheckboxcheckbox button", buttonid)
    if (buttonid) {
      console.log("mcq click")
      buttonid.click();
    }

  };

  const currentQuestionHandler = (i) => {
    console.log("currentquestionhandler", i)
    setCurrentQuestion(i);
    setElapsedTime(true);
    Attend_Exam_Post_Api();
  };

  const nextQuestionHandler1 = () => {
    if (
      currentQuestion + 1 <
      list?.questionAnswerKeys[currentQuestion]?.questionId.length
    ) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const preQuestionHandler1 = () => {
    if (currentQuestion >= 1) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const currentQuestionHandler1 = (i) => {
    // alert("currentquetionhandler  ", i)
    setCurrentQuestion(i);
  };

  useEffect(() => {
    if (!ispreview) {
      if (remainingTime === "Quiz has ended") {
        setChapterQuizKey(false);
      }
    }
  }, [remainingTime]);

  // Practice Quiz Time Functionality
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const hoursPart = hours > 0 ? `${hours} hr ` : "";
    const minutesPart = minutes > 0 ? `${minutes} min ` : "";
    const secondsPart = `${remainingSeconds} sec`;

    return `${hoursPart}${minutesPart}${secondsPart}`;
  };

  useEffect(() => {
    if (!ispreview) {
      if (timerRunning) {
        const timerInterval = setInterval(() => {
          if (timeLeft > 0) {
            const newFormattedTime = formatTime(timeLeft);
            setFormattedTime(newFormattedTime);
            setTimeLeft(timeLeft - 1);
          } else {
            clearInterval(timerInterval);
          }
        }, 1000);

        return () => {
          clearInterval(timerInterval);
        };
      }
    }
  }, [startButtonClicked, timerRunning, timeLeft]);

  const startTimer = () => {
    setTimerRunning(true);
  };

  const startQuesTimer = () => {
    setElapsedTime(true);
  }

  // useEffect(() => {
  //   if (elapsedTime) {
  //  //   //console.log('>> record satrt time >>')
  //     var currentDate = moment();
  //     var formattedTime = currentDate.format('HH:mm:ss');
  //     setQuesStartTime(formattedTime);
  //   }
  // });

  // Time Left:
  const displayTime = withTimeBtnClicked ? formattedTime : remainingTime;

  const handleButtonKeyDown = (event, handlerFunction) => {
    //   //console.log("handle button keydown clicked", event.key)
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();

    }
  };


  return (
    <>
      {
        //console.log("quiz common component", ispreview, resumeBtnClicked)
      }
      {
        ispreview == true ?
          <Modal sx={modalPreview}
            open={showModalpreview}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                sx: {
                  backgroundColor: "white",
                },
              },
            }}
          >
            <Box sx={modalStylePreview} style={{ backgroundColor: "white" }}>
              <Paper>
                <div className="Cqk_Quiz_Wrapper">
                  <div className="Cqk_OutOf">
                    {currentQuestion + 1} Out of {list?.questionAnswerKeys?.length}
                  </div>
                  {list?.questionAnswerKeys[currentQuestion]?.questionTypeCode ===
                    "CASE" ? (
                    // <Student_Enrich_Quiz_CS
                    //   list={list}
                    //   currentQuestion={currentQuestion}
                    //   ispreview={ispreview}
                    // />
                    <></>
                  ) : (
                    ""
                  )}

                  {list?.questionAnswerKeys[currentQuestion]?.questionTypeCode ===
                    "FILL" ? (
                    // <ChapterQuizKey_Fib
                    //   list={list}
                    //   currentQuestion={currentQuestion}
                    // />
                    <></>
                  ) : (
                    ""
                  )}

                  {list?.questionAnswerKeys[currentQuestion]?.questionTypeCode ===
                    "MSQ" ? (
                    // <ChapterQuizKey_Msq
                    //   list={list}
                    //   currentQuestion={currentQuestion}
                    // />
                    <Student_Enrich_Quiz_MSQ
                      list={list}
                      currentQuestion={currentQuestion}
                      ispreview={ispreview}
                    />
                  ) : (
                    ""
                  )}

                  {list?.questionAnswerKeys[currentQuestion]?.questionTypeCode ===
                    "MCQ" ? (
                    // <ChapterQuizKey_Mcq
                    //   list={list}
                    //   currentQuestion={currentQuestion}
                    // />
                    <></>
                  ) : (
                    ""
                  )}
                  {
                    //console.log(list?.questionAnswerKeys[currentQuestion], questions, currentQuestion, "list", list, (list?.questionAnswerKeys[currentQuestion]?.matchType === "draw-lines" || list?.questionAnswerKeys[currentQuestion]?.matchType === "DRAW_LINE"))
                  }

                  {
                    //console.log(list?.questionAnswerKeys, list?.questionAnswerKeys[currentQuestion]?.questionTypeCode, list?.questionAnswerKeys[currentQuestion]?.matchType)
                  }

                  {list?.questionAnswerKeys[currentQuestion]?.questionTypeCode ===
                    "MATCH" &&
                    list?.questionAnswerKeys[currentQuestion]?.matchType ===
                    "DRAG_DROP" ? (
                    // <ChapterQuizKey_Mtf_Dd
                    //   list={list}
                    //   currentQuestion={currentQuestion}
                    // />
                    <></>
                  ) : list?.questionAnswerKeys &&
                    list?.questionAnswerKeys[currentQuestion]?.questionTypeCode === "MATCH" &&
                    (list?.questionAnswerKeys[currentQuestion]?.matchType === "draw-lines" || list?.questionAnswerKeys[currentQuestion]?.matchType === "DRAW_LINE") ? (
                    // <student_enrich_quiz_mtf_drawline
                    //   questions={list?.questionAnswerKeys}
                    //   currentQuestion={currentQuestion}
                    //   setAttendMtfDl={setAttendMtfDl}
                    //   attendMtfDl={attendMtfDl}
                    //   setAttendedMtfDl={setAttendedMtfDl}
                    //   attendedMtfDl={attendedMtfDl}
                    //   resumeBtnClicked={true}
                    // />
                    <></>
                  ) : (
                    ""
                  )}


                  <div className="Cqk_BtnsDiv">
                    <div className="Cqk_QstnBtns">
                      <div className="TimeTakenLabel">
                        <label>
                          Taken Time : <i>{list?.questionAnswerKeys[currentQuestion]?.totalTimeSpend ? list?.questionAnswerKeys[currentQuestion]?.totalTimeSpend : '-'}</i>
                        </label>
                      </div>
                      <button className="Cqk_ExitBtn" id="az_ExitBtn" onClick={closeModal}>
                        <h6>Exit</h6>
                      </button>

                      {currentQuestion !== 0 && (
                        <button
                          className="Cqk_PrevBtn"
                          id="az_PrevBtn"
                          onClick={preQuestionHandler1}
                        >
                          <img src={prev} />
                          <h6>Previous Question</h6>
                        </button>
                      )}

                      {currentQuestion !== list?.questionAnswerKeys?.length - 1 && (
                        <button
                          className="Cqk_NxtBtn"
                          id="az_NxtBtn"
                          onClick={nextQuestionHandler1}
                        >
                          <h6>Next Question</h6>
                          <img src={nxt} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Paper>
              <Paper
                style={{
                  // borderRadius: "10px",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Box>

                  <div>
                    <div className="Cqk_Qstn_Bubble_div">
                      <div className="Cqk_Bubble_and_img">
                        <div className="Cqk_Qstn_Bubble_img">
                          <div>
                            <img src={bubble_img} />
                          </div>
                          <div>
                            <h6>
                              Quiz <span>Key</span>
                            </h6>
                          </div>
                        </div>
                        <div className="Cqk_Bubbles">
                          {list?.questionAnswerKeys?.map((item, index) => {
                            var uniqueid = "bubble" + index;
                            return (
                              <>
                                <div
                                  className="Cqk_Bubble"
                                  id={uniqueid}
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      list?.questionAnswerKeys[index]?.attended ===
                                        true
                                        ? list?.questionAnswerKeys[index]
                                          ?.ansFromStudentCurrect === true
                                          ? "#00CE95"
                                          : "#EB3E68"
                                        : "#BABABA",
                                  }}
                                  onClick={() => currentQuestionHandler1(index)}
                                >
                                  <h6>{index + 1}</h6>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="Cqk_Info_Bubbles">
                      <div className="Cqk_bubble_1">
                        <div className="Cqk_circle_1"></div>
                        <span> - Correct</span>
                      </div>
                      <div className="Cqk_bubble_2">
                        <div className="Cqk_circle_2"></div>
                        <span> - Unattempted</span>
                      </div>
                      <div className="Cqk_bubble_3">
                        <div className="Cqk_circle_3"></div>
                        <span> - Incorrect</span>
                      </div>
                    </div>

                  </div>
                </Box>
              </Paper>
            </Box>
          </Modal>
          :
          <>
            <article >
              <div className={dashboardContentStyles.dashboard_link}>
                <span className={dashboardContentStyles.link_icon}>
                  <img
                    src={dashHome}
                    alt="no img"
                    width="15px"
                    height="20px"
                    style={{ verticalAlign: "middle" }}
                  />
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    marginLeft: "4px",
                    color: "#7A7A7A",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/dashboard/template");
                  }}
                >
                  Home ⨠
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    color: "#001AF3",
                    marginLeft: "4px",
                  }}
                >
                  Quiz
                </span>
              </div>
              <div className={dashboardContentStyles.dashboard_last_updated}>
                <p>Last Update: {modifiedDate?.data}</p>
              </div>
            </article>

            {/*---------------------------| Common Items Across The Project Ends Here |---------------------------*/}
            <Modal
              open={isModalOpen}
              onClose={isModalOpen}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >

              <div className="Quiz_Wrapper" style={{ opacity: resumeBtnClicked ? 1 : !startButtonClicked ? 0 : 1, backgroundColor: "white", height: "100%" }}>
                {/*---------------------------| Quiz Title & Time Division |---------------------------*/}
                <div className="Title_And_Time">
                  <div>
                    <h6 className="Title">{quizDetails.name}</h6>
                  </div>
                  <div className="Time_OutOf">
                    <div className="OutOf">
                      {currentQuestion + 1} Out of {totalQuestionss}
                    </div>
                    {withOutTimeBtnClicked ? (
                      <h6></h6>
                    ) : (
                      <h6 className="Time">Time Left: {displayTime}</h6>
                    )}
                  </div>
                </div>
                {/* {
                //console.log(questions[currentQuestion]?.questionTypeCode, questions[currentQuestion], "ispreview", ispreview, "resumebtnclik", resumeBtnClicked)
                } */}

                {questions.length > 0 &&
                  questions[currentQuestion]?.questionTypeCode === "CASE" ? (
                  <Student_Enrich_Quiz_CS
                    questions={questions}
                    currentQuestion={currentQuestion}
                    resumeBtnClicked={resumeBtnClicked}
                    setAttendCs={setAttendCs}
                    attendCs={attendCs}
                    setAttendedCs={setAttendedCs}
                    attendedCs={attendedCs}
                    examId={quizzData.id ? quizzData.id : quizExaminationId}
                    // parentQuestionId={questions.id}
                    endDate={quizData.endDate}
                    endTime={quizData.endTime}
                    setAttendFib={setAttendFib}
                    attendFib={attendFib}
                    setAttendedFib={setAttendedFib}
                    attendedFib={attendedFib}

                    setAttendMcq={setAttendMcq}
                    attendMcq={attendMcq}
                    setAttendedMcq={setAttendedMcq}
                    attendedMcq={attendedMcq}

                    setAttendMsq={setAttendMsq}
                    attendMsq={attendMsq}
                    setAttendedMsq={setAttendedMsq}
                    attendedMsq={attendedMsq}

                    setCommonAttendedData={setCommonAttendedData}
                    commonAttendedData={commonAttendedData}
                    ispreview={ispreview}

                    tempCaseStudy={tempCaseStudy}
                    settempCaseStudy={settempCaseStudy}
                  />
                ) : (
                  ""
                )}

                {questions?.length > 0 &&
                  questions[currentQuestion]?.questionTypeCode === "FILL" ? (
                  <Student_Enrich_Quiz_FIB
                    questions={questions}
                    resumeBtnClicked={resumeBtnClicked}
                    currentQuestion={currentQuestion}
                    Attend_Exam_Post_Api={Attend_Exam_Post_Api}
                    setAttendFib={setAttendFib}
                    attendFib={attendFib}
                    setAttendedFib={setAttendedFib}
                    attendedFib={attendedFib}
                    from={undefined}
                    attendCs={attendCs}
                    setAttendCs={setAttendCs}
                    ispreview={ispreview}

                    tempCaseStudy={tempCaseStudy}
                    settempCaseStudy={settempCaseStudy}
                    attendedCs={attendedCs}
                    setAttendedCs={setAttendedCs}
                  />
                ) : (
                  ""
                )}

                {questions?.length > 0 && questions[currentQuestion]?.questionTypeCode === "MSQ" ? (
                  <Student_Enrich_Quiz_MSQ
                    questions={questions}
                    resumeBtnClicked={resumeBtnClicked}
                    currentQuestion={currentQuestion}
                    Attend_Exam_Post_Api={Attend_Exam_Post_Api}
                    setAttendMsq={setAttendMsq}
                    attendMsq={attendMsq}
                    setAttendedMsq={setAttendedMsq}
                    attendedMsq={attendedMsq}
                    ispreview={ispreview}
                    tempCaseStudy={tempCaseStudy}
                    settempCaseStudy={settempCaseStudy}
                  />
                ) : (
                  ""
                )}

                {questions?.length > 0 && questions[currentQuestion]?.questionTypeCode === "MCQ" ? (
                  <Student_Enrich_Quiz_MCQ
                    questions={questions}
                    resumeBtnClicked={resumeBtnClicked}
                    currentQuestion={currentQuestion}
                    setAttendMcq={setAttendMcq}
                    attendMcq={attendMcq}
                    setAttendedMcq={setAttendedMcq}
                    attendedMcq={attendedMcq}
                    ispreview={ispreview}
                  />
                ) : (
                  ""
                )}
                {/* //Match The Following */}
                {questions?.length > 0 &&
                  questions[currentQuestion]?.questionTypeCode === "MATCH" &&
                  (questions[currentQuestion]?.matchType?.code === "DRAG_DROP" || questions[currentQuestion]?.matchType === "DRAG_DROP") ? (
                  <Student_Enrich_Quiz_MTF_DD
                    questions={questions}
                    // qstnId={quizDetails.questionIdList[currentQuestion]}
                    currentQuestion={currentQuestion}
                    setAttendMtfDd={setAttendMtfDd}
                    attendMtfDd={attendMtfDd}
                    setAttendedMtfDd={setAttendedMtfDd}
                    attendedMtfDd={attendedMtfDd}
                    resumeBtnClicked={resumeBtnClicked}
                    ispreview={ispreview}
                  />
                ) : questions?.length > 0 &&
                  questions[currentQuestion]?.questionTypeCode === "MATCH" &&
                  (questions[currentQuestion]?.matchType?.code == "DRAW_LINE" || questions[currentQuestion]?.matchType == "DRAW_LINE") ? (
                  <div>
                    {

                      (!ispreview && resumeBtnClicked) ? (
                        <Student_Enrich_Quiz_MTF_DrawLines_Resume
                          questions={questions}
                          currentQuestion={currentQuestion}
                          setAttendMtfDl={setAttendMtfDl}
                          attendMtfDl={attendMtfDl}
                          setAttendedMtfDl={setAttendedMtfDl}
                          attendedMtfDl={attendedMtfDl}
                          resumeBtnClicked={resumeBtnClicked}
                          attendMtfDlTemp={attendMtfDlTemp}
                          setAttendMtfDlTemp={setAttendMtfDlTemp}
                          ispreview={ispreview}
                          resume={true}
                        />
                      ) :
                        (<Student_Enrich_Quiz_MTF_DrawLines
                          questions={questions}
                          currentQuestion={currentQuestion}
                          setAttendMtfDl={setAttendMtfDl}
                          attendMtfDl={attendMtfDl}
                          setAttendedMtfDl={setAttendedMtfDl}
                          attendedMtfDl={attendedMtfDl}
                          resumeBtnClicked={resumeBtnClicked}
                          attendMtfDlTemp={attendMtfDlTemp}
                          setAttendMtfDlTemp={setAttendMtfDlTemp}
                          ispreview={ispreview}
                        />)
                    }
                  </div>
                ) : (
                  ""
                )}
                {/*---------------------------| Prev, Next & Submit Btn Division|---------------------------*/}
                <div className="BtnsDiv">
                  <div className="QstnBtns">
                    {
                      ((!ispreview && !resumeBtnClicked) || (!ispreview && resumeBtnClicked)) ? null : (
                        <button
                          id="az-student-quiz-ext-btn"
                          className="ExitBtn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            sessionStorage.removeItem("itemload");
                            sessionStorage.removeItem("viewQuizData");
                            // setIsModalOpen(false)
                            //   //console.log("exit clicked1", previousPage);
                            sessionStorage.removeItem("practiceQuizAnswer");
                            sessionStorage.removeItem("drag_drop_array");
                            sessionStorage.removeItem("drag_drop_array_final");
                            sessionStorage.removeItem("shuffledQuiz");
                            sessionStorage.removeItem("alltheQuizAnswer");
                            sessionStorage.removeItem("exid");
                            sessionStorage.removeItem("exIdTwo");
                            sessionStorage.removeItem("drag_drop_array_final")
                            sessionStorage.removeItem("case_help");
                            window.location.href = previousPage;
                            unregister();
                            clearInterval(intervalIdUsed);
                            setIntervalIdUsed(null);
                            window.location.reload()
                          }}
                          onKeyDown={(e) => handleButtonKeyDown(e, preQuestionHandler)}
                        >
                          <h6>Exit</h6>
                        </button>
                      )

                    }
                    {currentQuestion !== 0 && (
                      <button className="PrevBtn" id="az-student-quiz-prev-btn" style={{ cursor: "pointer" }} onClick={preQuestionHandler} onKeyDown={(e) => handleButtonKeyDown(e, preQuestionHandler)}
                        tabIndex="0">
                        <img src={prev} />
                        <h6>Previous Question</h6>
                      </button>
                    )}

                    {currentQuestion !== questions?.length - 1 && (
                      <button className="NxtBtn" id="az-student-quiz-nxt-btn" style={{ cursor: "pointer" }} onClick={nextQuestionHandler} onKeyDown={(e) => handleButtonKeyDown(e, nextQuestionHandler)}
                        tabIndex="0">
                        <h6>Next Question </h6>
                        <img src={nxt} />
                      </button>
                    )}

                    {currentQuestion === questions?.length - 1 && (
                      <button className="SubmitBtn" id="az-student-quiz-sub-btn" style={{ cursor: "pointer" }} onClick={handleSubmitBtn}>
                        <h6>Submit</h6>
                      </button>
                    )}
                  </div>
                </div>


                {(showModal || openAnalysisModalTemp) && (
                  <Student_Enrich_Quiz_Submit_Modal
                    showModal={showModal}
                    closeModal={handleSubmitModalClose}
                    Submit_Exam_Put_Api={Submit_Exam_Put_Api}
                    closeModall={closeModall}
                    setLoadQuizData={setLoadQuizData}
                    openAnalysisModal={openAnalysisModal}
                    questions={questions}
                    setShowModal={setShowModal}
                    // fetchViewQuiz={fetchViewQuiz}
                    callbackforafterquizexit={callbackforafterquizexit}
                  />
                )}

                {(openAnalysisModal || openAnalysisModalTemp) && (
                  <Student_Enrich_Quiz_Analysis_Modal
                    showAnalysisModal={openAnalysisModal}
                    closeModal={handleAnalysisModalClose}
                    closeModall={closeModall || false}
                    quizzData={quizzData}
                    callbackforafterquizexit={callbackforafterquizexit}
                  // chapterQuizKey={chapterQuizKey}
                  />
                )}

                {/*---------------------------| Quiz Question Bubble Division Starts Here |---------------------------*/}
                <div className="Qstn_Bubble_div">
                  <div className="Bubble_and_img">
                    <div className="Qstn_Bubble_img">
                      <div>
                        <img src={bubble_img} />
                      </div>
                      <div>
                        <h6>
                          Questions <span>Attempted</span>
                        </h6>
                      </div>
                    </div>
                    <div className="Bubbles">
                      {/* <div className="Bubble" onClick={() => handleQuizType("CS")}>
                <h6>1</h6>
              </div>
                */}
                      {
                      }
                      {questions?.map((item, index) => {
                        let isAttempted = false;
                        // console.log(item.questionTypeCode, commonAttendedData, currentQuestion, index, "questions==================", item.questionId, item.Id, attendedCs, "attendedFib", attendedFib, "attendedMsq", attendedMsq, "attendedMcq", attendedMcq, attendMtfDlTemp, "attendedMtfDl", attendedMtfDl, "attendedMtfDd", attendedMtfDd, JSON.parse(sessionStorage.getItem("practiceQuizAnswer")))
                        //console.log("item", item)
                        if (item.questionTypeCode === "CASE") {
                          //   //console.log(item.id, item.questionId, index, resumeBtnClicked ? item.questionId : item.Id || item.id, index)
                          // isAttempted = commonAttendedData?.some(obj => obj?.currentQuestion == index);
                          // console.log(tempCaseStudy, attendCs, "188666666",attendedCs)
                          var idToCheck = resumeBtnClicked ? item.questionId : item.Id || item.id
                          isAttempted = attendedCs.hasOwnProperty(idToCheck) && attendedCs[idToCheck] !== null && attendedCs[idToCheck] !== undefined;
                          // console.log(isAttempted, "******************", attendedCs, resumeBtnClicked ? item.questionId : item.Id || item.id)
                        } else if (item.questionTypeCode === "FILL") {
                          var idToCheck = resumeBtnClicked ? item.questionId : item.Id || item.id
                          isAttempted = attendedFib.hasOwnProperty(idToCheck) && attendedFib[idToCheck] !== null && attendedFib[idToCheck] !== undefined;
                          // console.log(isAttempted, "******************", attendedFib, resumeBtnClicked ? item.questionId : item.Id || item.id)
                        } else if (item.questionTypeCode === "MSQ") {
                          var idToCheck = resumeBtnClicked ? item.questionId : item.Id || item.id
                          isAttempted = attendedMsq.hasOwnProperty(idToCheck) && attendedMsq[idToCheck] !== null && attendedMsq[idToCheck] !== undefined;
                          // console.log(isAttempted, "******************", attendedMsq, resumeBtnClicked ? item.questionId : item.Id || item.id)
                        } else if (item.questionTypeCode === "MCQ") {
                          // //console.log(item,"-------------------------------------",attendedMcq,resumeBtnClicked)
                          var idToCheck = resumeBtnClicked ? item.questionId : item.Id || item.id
                          isAttempted = attendedMcq.hasOwnProperty(idToCheck) && attendedMcq[idToCheck] !== null && attendedMcq[idToCheck] !== undefined;
                          // console.log(isAttempted, "******************", attendedMcq, resumeBtnClicked ? item.questionId : item.Id || item.id)

                        } else if (item.questionTypeCode == "MATCH") {
                          if (item.matchType.code == "DRAW_LINE" || item.matchType == "DRAW_LINE") {
                            //console.log(attendedMtfDl, item.currentQuestion, index, "DRAW_LINEDRAW_LINEDRAW_LINEDRAW_LINEDRAW_LINE")
                            var id = resumeBtnClicked ? item.questionId : item.Id || item.id
                            // console.log("drawline bottom", attendedMtfDl, attendMtfDlTemp, JSON.parse(sessionStorage.getItem("QUIZ_DATA")))
                            var localArray = attendedMtfDl.length > 0 ? attendedMtfDl : attendMtfDlTemp.length > 0 ? attendMtfDlTemp : [];
                            isAttempted = localArray.some(item => item.id === id || item.currentQuestion == index);
                            if (!ispreview && resumeBtnClicked) {
                              localArray = JSON.parse(sessionStorage.getItem("QUIZ_DATA"));
                              // console.log("localArray111", localArray)
                              localArray = localArray.find(item => item.questionId === id || item.id == id);
                              // console.log("localArray2222", localArray)
                              isAttempted = localArray?.isAnswered
                            }
                            // console.log("isAttempted", isAttempted, id)
                            // console.log(isAttempted, "******************", attendedMtfDl, localArray, id)

                          } else {
                            // //console.log(JSON.parse(sessionStorage.getItem("drag_drop_array_final")), attendedMtfDd, attendMtfDd, currentQuestion);
                            // if (attendedMtfDd.length > 0) {
                            //   isAttempted = attendedMtfDd.some(items => (items.id == item.id || items.id == item.questionId));
                            //   if (isAttempted) {
                            //     // //console.log("isAttempted", isAttempted);
                            //   }
                            // } else {
                            //   isAttempted = attendMtfDd.some(items => items.currentQuestion == index);
                            //   if (isAttempted) {
                            //     // //console.log("isAttempted", isAttempted);
                            //   }
                            // }
                            var id = resumeBtnClicked ? item.questionId : item.Id || item.id
                            var sess = JSON.parse(sessionStorage.getItem("drag_drop_array_final")) || attendedMtfDd || [];
                            // console.log("sess", sess, id, attendedMtfDd, JSON.parse(sessionStorage.getItem("drag_drop_array_final")))
                            isAttempted = sess?.some(items => items.id == String(id));
                            // console.log("isAttempted", isAttempted);
                            if (!ispreview && resumeBtnClicked) {
                              localArray = JSON.parse(sessionStorage.getItem("QUIZ_DATA"));
                              // console.log("localArray111", localArray)
                              localArray = localArray.find(item => item.questionId === id || item.id == id);
                              // console.log("localArray2222", localArray)
                              isAttempted = localArray?.isAnswered
                            }
                            // console.log(isAttempted, "******************", sess, localArray, id)
                          }
                        }
                        else {
                          isAttempted = attendedMtfDd.hasOwnProperty(
                            resumeBtnClicked ? item.questionId : item.Id || item.id
                          );
                        }
                        // console.log(isAttempted, item.questionTypeCode, resumeBtnClicked)
                        return (
                          <>
                            <div
                              className="Bubble"
                              id={`id_bubble_${index}`}
                              key={index}
                              style={{
                                backgroundColor: isAttempted ? "" : "#928d8d",
                              }}
                              onClick={() => currentQuestionHandler(index)}
                            >
                              <h6>{index + 1}</h6>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {/*---------------------------| Quiz Question Bubble Division Ends Here |---------------------------*/}

                {/*---------------------------| Quiz Question Info Bubbles Division Starts Here |---------------------------*/}
                <div className="Info_Bubbles">
                  <div className="bubble_1">
                    <div className="circle_1"></div>
                    <span> - Attempted</span>
                  </div>
                  <div className="bubble_2">
                    <div className="circle_2"></div>
                    <span> - Unattempted</span>
                  </div>
                </div>
                {/*---------------------------| Quiz Question Info Bubbles Division Ends Here |---------------------------*/}
              </div>
            </Modal >
            {/*---------------------------| General Instructions Modal Starts Here |---------------------------*/}

            < div >
              {
                //console.log((quizExaminationId, "exxxx", (quizExaminationId == "" || quizExaminationId == null || quizExaminationId == undefined)), quizExaminationId, "1901")
              }

              {
                (quizExaminationId && (quizExaminationId !== "" && quizExaminationId !== null)) ? null : (
                  <Modal
                    open={openModal}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      {/*-------------------------------| General Instructions Modal Title & Close-Btn |-------------------------------*/}
                      <div className="Modal_Head">
                        <div className="Modal_title">General Instructions</div>
                        <div className="Close_btn">
                          <CloseIcon onClick={() => {
                            // alert("closemodal called")
                            closeModall();
                            sessionStorage.removeItem("alltheQuizAnswer");
                            sessionStorage.removeItem("shuffledQuiz");
                            sessionStorage.removeItem("draw_line_array");
                            sessionStorage.removeItem("itemload");
                            sessionStorage.removeItem("drag_drop_array_final");
                            sessionStorage.removeItem("chapterQuizKeyBtn");
                            sessionStorage.removeItem("quiztype");
                            sessionStorage.removeItem("exid");
                            sessionStorage.removeItem("exIdOne");
                            sessionStorage.removeItem("viewQuizData");

                          }} />
                          {/* <CloseIcon onClick={() => handleClose()} /> */}
                        </div>
                      </div>
                      {/*-------------------------------| General Instructions Modal Contents |-------------------------------*/}
                      <div className="modal_contents">
                        <h6 className="modal_content_1">
                          All questions are compulsory
                        </h6>
                        <h6 className="modal_content_2">
                          The <span>maximum marks is {quizDetails.totalMarks}</span>
                        </h6>
                        <div className="modal_content_3" style={{ marginBottom: '10px' }}>
                          The duration of the paper is: {durationString}. (15 min
                          reading time & {durationString} writing time) Read all the
                          questions carefully before attempting. A neat and legible
                          presentation will be appreciated.
                        </div>
                        <h6 className="modal_content_1">
                          Please take a few minutes to read the instructions carefully before starting the quiz:
                        </h6>
                        <div className="modal_content_3">
                          <ol>
                            <li>This assessment contains Multiple Choice Questions, with 1 correct answer for every question.</li>
                            <li>Other questions – like Multiselect and Match the following, Fill in the blanks & Case study have specific instructions given.</li>
                            <li>Read the questions carefully and choose the appropriate option.</li>
                            <li>It is mandatory to attempt all the questions.</li>
                            <li>No marks are awarded for unattempted questions.</li>
                            <li>Mark the answers for each question. If you want to attempt any questions later, you can move to the next question without marking the answer. Press the back arrow and attempt the questions later before you submit the quiz.</li>
                            <li>If you are taking the quiz through the mobile app, avoid making/receiving calls.</li>
                            <li>In case of any challenges regarding the quiz, please feel free to contact your teacher immediately.</li>
                            <li>Once quiz Time is over student will receive pop up “Time is over” click on “Ok” to submit quiz.</li>
                          </ol>
                        </div>
                      </div>
                      {/*-------------------------------| General Instructions Modal Footer Btns |-------------------------------*/}
                      <div className="Modal_Footer">
                        <hr className="U_line" />
                        <div className="Modal_Footer_Btns">
                          <div className="Cancel_Btn">
                            <button onClick={() => closeModall()}>Cancel</button>
                          </div>
                          <div className="Start_Btn">
                            {/* <button onClick={handleClose}>Start</button> */}
                            <button onClick={StartExam_Post_Api} style={{ cursor: "pointer" }}>Start</button>
                            {/* <button onClick={() => setStartButtonClicked(true)}>
                    Start
                  </button> */}
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Modal>
                )
              }
            </div >
            {/*---------------------------| General Instructions Modal Ends Here |---------------------------*/}

            {/*-----------------------| Exam Time Over Validation Modal Starts Here |-----------------------*/}

            <div>
              <Modal
                open={examEndModalOpen}
                aria-labelledby="exam-modal-title"
                aria-describedby="exam-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    border: "none",
                    borderRadius: "5px",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography id="exam-modal-title" align="center">
                    Exam Time Over
                  </Typography>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <Button
                      fullWidth
                      style={{
                        width: "20px",
                        color: "white",
                        backgroundColor: "#FD8C00",
                      }}
                      onClick={handleCloseExamEndModal}
                    >
                      OK
                    </Button>
                  </div>
                </Box>
              </Modal>
            </div>
            {/*-----------------------| Exam Time Over Validation Modal Ends Here |-----------------------*/}
          </>
      }

    </>
  );
}

export default Student_Enrich_Quiz_Attend;
