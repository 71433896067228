import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const API_ENDPOINT = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/last-modified-at`;

function useLastModifiedAt() {
	const TOKEN = sessionStorage.getItem("token");
	const config = {
		headers: { Authorization: `Bearer ${TOKEN}` },
	};

	return useQuery(
		["lastModifiedAt"],
		async () => {
			const response = await axios.get(API_ENDPOINT, config);
			return response.data;
		},
		{
			refetchOnWindowFocus: false,
		}
	);
}

export default useLastModifiedAt;
