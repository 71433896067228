import React, { useEffect,useState } from 'react'
import CardV2 from './CardV2'
import { fetchQuizzesAttemptedTotal } from '../Apis/Dashboard_Api'
import { isNOTNullOrUndefined, isNullOrUndefined } from '../../../../utils/helper'
import './dashboard.css'
import CardV1 from './CardV1'

// {
//     "subjectId": null,
//     "subject": null,
//     "totalReleasedUQ": 8,
//     "totalReleasedPQ": 0,
//     "attendedPercentageUQ": 75,
//     "attendedPercentagePQ": 0,
//     "attemptedPQ": 0,
//     "attemptedUQ": 6
//   },
const PercentProgressCards = ({ subject }) => {
    const [data, setData] = useState({})
    const fetchData = async () => {
        const response = await fetchQuizzesAttemptedTotal(subject?.subjectId)
        setData(response)
    }
    useEffect(() => {
        if(isNOTNullOrUndefined(subject?.subjectId))fetchData()
    }, [subject])

    return (
        <div className="top-section">
            <CardV1 />
            <CardV2 noData={isNullOrUndefined(data)} title={'No of Unit Quiz attempted'} subject={data?.subject} attempted={data?.attemptedUQ} total={data?.totalReleasedUQ} percent={data?.attendedPercentageUQ} color={'#FA9825'} />
            <CardV2 noData={isNullOrUndefined(data)} title={'No of Practice Quiz attempted'} subject={data?.subject} attempted={data?.attemptedPQ} total={data?.totalReleasedPQ} percent={data?.attendedPercentagePQ} color="#36DABC" />
        </div>
    )
}

export default PercentProgressCards