import React from "react";
import HorizontalScrollContainer from "../../../components/HorizontalScrollContainer/HorizontalScrollContainer";
import DashboardBarChartCard from "../../../components/DashboardBarChartCard/DashboardBarChartCard";
import HorizontalScrollContainerV1 from "../../../components/HorizontalScrollContainer/HorizontalScrollContainerV1";
import DashboardBarChartCardV2 from "../../../components/DashboardBarChartCardV2/DashboardBarChartCardV2";
import { isNOTNullOrUndefined } from "../../../utils/helper";
import HorizontallyScrollableContainerV3 from "../../../components/HorizontallyScrollableContainerV3/HorizontallyScrollableContainerV3";
import TeacherDashboardBarChartCard from "../Teacher/Dashboard/Components/TeacherDashboardBarChartCard/TeacherDashboardBarChartCard";


const ChartDetails = ({ quizPerformanceData, setSelectedGradeSectionCard, selectedGradeSectionCard, subjectWisePerformanceData, setSelectedSubjectWiseCard, selectedSubjectWiseCard, userType, chapterPerformanceData }) => {
    return <div>
        <h2 style={{ marginBottom: '20px' }} className="dashboard-heading">Quiz Performance</h2>
        <div style={{ width: "100%", marginTop: "7px", marginBottom: "45px" }}>
            {userType === "Principal" ? <HorizontalScrollContainer data={quizPerformanceData}>
                {quizPerformanceData && quizPerformanceData?.map((i) => {
                    if (i.hasOwnProperty('globalAvgAttemptPercentagePQ')) {
                        return (
                            <DashboardBarChartCard
                                key={i?.gradeSectionId}
                                title={`${i?.grade} ${i?.section}`}
                                subject={i?.subject}
                                first_label={"Average"}
                                second_label={"Global"}
                                first_percent={i?.avgScorePercentageUQ}
                                second_percent={i?.globalAvgScorePercentageUQ}
                                selected={
                                    selectedGradeSectionCard?.gradeSectionId === i?.gradeSectionId
                                }
                                onCardClick={() => setSelectedGradeSectionCard(i)}
                                labelClickDisabled
                            />
                        )
                    }
                })}
            </HorizontalScrollContainer> :
                <HorizontalScrollContainer>
                    {quizPerformanceData && quizPerformanceData?.map((item) => {
                        return (
                            <TeacherDashboardBarChartCard
                                key={item.id}
                                title={`${item.grade} ${item.section}`}
                                section={item.section}
                                subtitle={item.subject}
                                subtopic={item.subtopic}
                                first_label={"Average"}
                                second_label={"Global Score"}
                                averageMessage={item?.averageMessage || ""}
                                first_percent={item?.quizAverageScorePercentage}
                                second_percent={item?.globalQuizAverageScorePercentage}
                                selected={selectedGradeSectionCard?.id === item?.id}
                                onCardClick={() => setSelectedGradeSectionCard(item)}
                            //   onLabelClick={(e) => {
                            //     e.stopPropagation();
                            //     setShowMessageModal(true);
                            //     setMessage(item?.averageMessage);
                            //   }}
                            />
                        );
                    })}
                </HorizontalScrollContainer>}
        </div>
        {userType === "Principal" ? <div>
            <h2 className="dashboard-heading">Subjectwise Performance</h2>
            <div style={{ width: "100%", marginTop: "7px", marginBottom: "35px" }} >
                <HorizontalScrollContainerV1
                    numberOfItems={subjectWisePerformanceData?.length}>
                    {subjectWisePerformanceData && subjectWisePerformanceData?.map((i) => (
                        <DashboardBarChartCardV2
                            key={i?.id}
                            title={
                                isNOTNullOrUndefined(i?.subTopic) ? i?.subTopic : i?.subject
                            }
                            first_label={"Average"}
                            second_label={"Global"}
                            first_percent={i?.avgScorePercentageUQ}
                            second_percent={i?.globalAvgScorePercentageUQ}
                            selected={selectedSubjectWiseCard?.id === i?.id}
                            onCardClick={() => setSelectedSubjectWiseCard(i)}
                        // onLabelClick={(e) => {
                        //     e.stopPropagation();
                        //     setShowMessageModal(true);
                        // }}
                        />
                    ))}
                </HorizontalScrollContainerV1>
            </div>
        </div>
            :
            <HorizontallyScrollableContainerV3
                data={chapterPerformanceData} />}
    </div>
}

export default ChartDetails;