export const arr = [
  {
    id: 1,
    levelNumber: "type 1",
    levelName: "label 1",
    description: "U-80 | A-20 | S-0",
    isActive: false,
  },
  {
    id: 2,
    levelNumber: "type 2",
    levelName: "label 2",
    description: "U-80 | A-20 | S-0",
    isActive: false,
  },
  {
    id: 3,
    levelNumber: "type 3",
    levelName: "label 3",
    description: "U-80 | A-20 | S-0",
    isActive: true,
  },
  {
    id: 4,
    levelNumber: "type 4",
    levelName: "label 4",
    description: "U-80 | A-20 | S-0",
    isActive: false,
  },
  {
    id: 5,
    levelNumber: "type 5",
    levelName: "label 5",
    description: "U-80 | A-20 | S-0",
    isActive: true,
  },
];

export const tableColumns = [
  { id: 1, label: "Level Number", field: "levelNumber", width: 30 },
  { id: 2, label: "Level Name", field: "levelName", width: 30 },
  { id: 3, label: "Level Description", field: "description", width: 30 },
  { id: 4, label: "Action", field: "action", width: 15 },
];

export const getDataStructureFomat = (
  countValue,
  bluePrintCount,
  bluePrintList
) => {
  const values = [...bluePrintList];
  if (values) {
    for (let i = bluePrintList.length; i < countValue; i++) {
      let inputValue = {
        levelName: "",
        levelNumber: i + 1,
        description: "",
        active: true,
        status: false,
      };
      values.push(inputValue);
    }
  }
  // if (countValue < bluePrintCount) {
  //   if (values) {
  //     const array_length = values.length;
  //     const difference = array_length - countValue;
  //     values.splice(countValue, difference);
  //   }
  // }

  return values;
};

export const getDataStructureFomat2 = (
  countValue,
  bluePrintCount,
  bluePrintList
) => {
  const values = [...bluePrintList];
  if (values) {
    const array_length = values.length;
    const difference = array_length - countValue;
    values.splice(countValue, difference);
  }

  return values;
};

// export function validateFields(bluePrintList) {
//   var formValid = false;
//   bluePrintList?.map((bluePrint) => {
//     if (bluePrint.levelName == "") {
//       formValid = false;
//     } else formValid = true;
//     if (bluePrint.description == "") {
//       formValid = false;
//     } else formValid = true;
//   });
//   return formValid;
// }

export function validateFields(bluePrintList) {
  let formValid = false;
  bluePrintList?.map((bluePrint) => {
    if (bluePrint.levelName != "" && bluePrint.description != "") {
      formValid = true;
    }
    else {
      formValid = false;
    }
  });
  return formValid;
}
