async function City_Controller_get(setCityInputs) {
  let token = sessionStorage.getItem("token");
  
  fetch(
    `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities/${sessionStorage.getItem("cityid")}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        
      },
    }
  )
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
}
export default City_Controller_get;
