import axios from 'axios'
import commonSort from "../../js/CommonSort"

async function chapters_controller_getall(setChapters, rowsInput, page, setPageDetail, search, gradeInput, subjectInput, subTopicInput, sortOrder, sortBy, boardInput
) {
  let token = sessionStorage.getItem("token");
  sortOrder = sortOrder ? sortOrder : ""
  sortBy = sortBy ? sortBy : ""
  boardInput = boardInput ? boardInput : ""
  // console.log(setChapters, "11111 ", rowsInput, "2222 ", page, "3333 ", setPageDetail, "44444 ", search, "55 ", gradeInput, "66 ", subjectInput, "77 ", subTopicInput, "88 ", sortOrder, "99 ", sortBy, "1010 ", boardInput)

  if (gradeInput !== "" || subjectInput !== "" || subTopicInput !== "" || boardInput !== "") {
    if (gradeInput === "" && subjectInput === "" && subTopicInput === "" && boardInput !== "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=${rowsInput?.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&boardId=${boardInput}`;
    }

    if (gradeInput !== "" && subjectInput === "" && subTopicInput === "" && boardInput === "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=${rowsInput?.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&gradeId=${gradeInput}`;
    }
    if (gradeInput === "" && subjectInput !== "" && subTopicInput === "" && boardInput === "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=${rowsInput?.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&subjectId=${subjectInput}`;
    }
    if (gradeInput === "" && subjectInput === "" && subTopicInput !== "" && boardInput === "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=${rowsInput?.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&subTopicId=${subTopicInput}`;
    }
    if (gradeInput !== "" && subjectInput !== "" && subTopicInput !== "" && boardInput === "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=${rowsInput?.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&gradeId=${gradeInput}&subjectId=${subjectInput}&subTopicId=${subTopicInput}`;
    }
    if (gradeInput !== "" && subjectInput !== "" && boardInput !== "" && subTopicInput === "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=${rowsInput?.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&gradeId=${gradeInput}&subjectId=${subjectInput}&boardId=${boardInput}`;
    }
    if (gradeInput === "" && subjectInput !== "" && subTopicInput !== "" && boardInput !== "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=${rowsInput?.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&subjectId=${subjectInput}&subTopicId=${subTopicInput}&boardId=${boardInput}`;
    }
    if (gradeInput !== "" && subjectInput === "" && subTopicInput !== "" && boardInput !== "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=${rowsInput?.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&gradeId=${gradeInput}&subTopicId=${subTopicInput}&boardId=${boardInput}`;
    }
    if (gradeInput !== "" && subjectInput === "" && subTopicInput === "" && boardInput !== "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=${rowsInput?.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&gradeId=${gradeInput}&boardId=${boardInput}`;
    }

    if (gradeInput !== "" && subjectInput !== "" && subTopicInput !== "" && boardInput !== "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=${rowsInput?.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search?.search}&gradeId=${gradeInput}&subjectId=${subjectInput}&subTopicId=${subTopicInput}&boardId=${boardInput}`;
    }
  } else {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=${rowsInput?.rows}&sortOrder=${sortOrder !== undefined ? sortOrder : ""}&sortBy=${sortBy || ""}&search=${search?.search || ""}`
  }
  let result = await axios.get(URL,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (json) => {
      if (json.data.data) {
        console.log(json.data.data.data)
        // var Sdata = await commonSort(json.data.data.data, sortBy, sortOrder);
        // console.log("data", Sdata, sortBy, sortOrder)
        // json.data.data.data = Sdata;
        setChapters(json.data.data.data)
        setPageDetail(json.data.data)
      }
    })
    .catch(err => console.log(err))
}

export default chapters_controller_getall;
