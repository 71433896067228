import React, { useState } from "react";
import { Box, Button, Modal, TextField } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import { styled } from "@mui/material/styles";
// import { Editor } from "@tinymce/tinymce-react";
import Green100Button from "../../components/common/Green100Button";
import Yellow100Button from "../../components/common/Yellow100Button";
import TextEditor from '../../pages/js/QuestionBank/CommonComponents/TextEditor';
import axios from "axios";
import create_question_post from "../API_CONTROLLER/create-questions/question_post";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Alert,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 900,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // p: 4,
  maxHeight: '70vh',
  borderRadius: "10px",
  overflowX: "hidden",
  overflowY: "auto"
};
const MarkButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  // minWidth: 100,
  backgroundColor: "rgb(76 72 133 / 81%)",
  // borderRadius: 150,
  border: "1px solid #757575",
  borderColor: "rgb(76 72 133 / 81%)",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  "&:hover": {
    color: "#fff",
    backgroundColor: "rgb(76 72 133 / 81%)",
  },
}));
const AddQuestionModal = ({ open, close, setShowMessage,
  selectedGrade,
  selectedSubject,
  selectedYear,
  selectedMonth,
  selectedQuestionData,
  selectedQuestionOptionData,
  setnewQuestion,
  getResponse
}) => {
  // console.log("0000000000000000000", selectedGrade, selectedSubject, selectedYear, selectedMonth, selectedQuestionData);
  const [markList, setMarkList] = useState([
    {
      id: Math.random(),
      step: "",
      marks: "",
    },
  ]);
  const [content, setContent] = useState({ content: "" });
  const [clearContent, setClearContent] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [questionContent, setQuestionContent] = useState({ content: "" });
  const [questionEditContent, setEditQuestionContent] = useState({ content: "" });
  const [answerKeyContent, setAnswerKeyContent] = useState({ content: "" });
  const [answerEditKeyContent, setEditAnswerKeyContent] = useState({ content: "" });
  const [imagePreview, setImagePreview] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [uploadImageName, setUploadImageName] = useState("");
  const [edit, setEdit] = useState(false);
  const [rootConcept, setrootConcept] = useState(false)
  const [selectedboard, setselectedboard] = useState(sessionStorage.getItem("boardId"));
  const [responseValue, setResponseValue] = useState();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errorModalDetails, setErrorModalDetails] = useState({ open: false, message: '' })
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrorMessage, setshowerrorMessage] = useState("");


  const addMarksHandler = () => {
    console.log(markList, "markList")
    setMarkList((prevState) => [
      ...prevState,
      { id: Math.random(), step: "", marks: "" },
    ]);
  };
  const showErrorMessage = (message) => {
    setErrorModalDetails({ open: true, message })
  }
  const closeErrorModal = () => { setErrorModalDetails({ open: false, message: '' }) }
  const removeMarkHandler = (id) => {
    const clone = [...markList];
    const newData = clone.filter((item) => item.id !== id);
    setMarkList(newData);
  };
  const contentHandler = (content) => {
    setContent({ content: content });
  };

  const callback = (res) => {
    console.log(res, res.status, ">>>>>>>>>>>>>>>>> response", res?.error == "504")
    if (res?.error == "504" || res.status == 400 || res.data?.errorCode == 500) {
      setShowerror(true);
      setshowerrorMessage(res?.data?.message || "check error");
      setTimeout(() => {
        setShowerror(false);
        setshowerrorMessage("");
      }, 5000);
    } else {
      setShowSuccessAlert(true);
      close();
      res.urlofimage = uploadImageName
      getResponse(res)
    }

  }
  const uploadHandler = (e) => {
    const file = e.target.files[0];
    console.log("file", file, e.target)
    setUploadedFile(file);

    setUploadImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
    var fileMode = new File([file], file.name, { type: file.type });
    const formData = new FormData();
    formData.append("file", fileMode);
    console.log("fileMode", fileMode)

    const file_upload_post = async () => {
      let token = sessionStorage.getItem("token");
      let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=QUESTIONS`, formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (response) => {
            console.log("response------", response.data.data)
            setUploadImageName(response.data.data)
          }
        )
        .catch(err => console.log(err))
    }
    file_upload_post();

  };
  const stepHandler = (value, id) => {
    console.log(value, id, "stepHandlerstepHandler", markList)
    if (id) {
      const clone = [...markList];
      const findIndex = markList.findIndex((item) => item.id === id);
      if (findIndex !== -1) {
        clone[findIndex].step = value;
        setMarkList(clone);
      }
    }
  };
  const markHandler = (value, id) => {
    console.log("markHandler", value, id, markList)
    if (id) {
      const clone = [...markList];
      const findIndex = markList.findIndex((item) => item.id === id);
      console.log("findIndex", findIndex)
      if (findIndex !== -1) {
        clone[findIndex].marks = value;
        console.log("clone", clone)
        setMarkList(clone);
      }
    }
  };
  const onQuestionHandler = (content) => {
    console.log("content", content)
    setQuestionContent({ content: content });
  }
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            width: "100%",
            padding: "20px",
            background: "rgb(76 72 133 / 81%)",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            color: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>Type of question for 3 marks questions</div>
          <CloseOutlinedIcon
            style={{ fontSize: "30", cursor: "pointer" }}
            onClick={close}
          />
        </div>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "600px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                columnGap: "1rem",
              }}
            >
              <div style={{ width: "100%" }}>
                {/* <Editor
                  apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
                  value={content?.content}
                  onEditorChange={contentHandler}
                  init={{
                    height: "200px",
                    menubar: false,
                    width: "100%",
                  }}
                /> */}
                <TextEditor id="uiot" onEditorContentChange={onQuestionHandler} name={"editQuestion"} initialValue={edit ? questionEditContent.content : ""} clearContent={clearContent && clearContent} />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  // alignItems: "end",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "15px",
                  }}
                >
                  <div>Upload Image</div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      type={"text"}
                      value={uploadedFile.name || ""}
                      size={"small"}
                      id="outlined-basic"
                      label="Select Image"
                      variant="outlined"
                    />
                    <MarkButton
                      variant="contained"
                      component="label"
                      sx={{ borderRadius: 0 }}
                    >
                      <DifferenceOutlinedIcon style={{ fontSize: "25px" }} />
                      Browse
                      <input
                        hidden
                        accept="image/*"
                        multiple={false}
                        type="file"
                        onChange={(e) => uploadHandler(e)}
                      />
                    </MarkButton>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "1rem",
                alignItems: "center",
              }}
            >
              <div>Marking Scheme</div>
              <MarkButton onClick={addMarksHandler}>
                <AddOutlinedIcon style={{ fontSize: "25" }} />
                Add Step Marks
              </MarkButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
                overflow: "auto",
                height: "300px",
                padding: "10px",
              }}
            >
              {markList?.map((data) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                      alignItems: "center",
                    }}
                    key={data.id}
                  >
                    <TextField
                      type={"text"}
                      size={"small"}
                      id="outlined-basic"
                      label="Mark step"
                      variant="outlined"
                      value={data.step}
                      onChange={(e) => stepHandler(e.target.value, data.id)}
                    />
                    <TextField
                      type={"text"}
                      size={"small"}
                      id="outlined-basic"
                      label="Marks"
                      variant="outlined"
                      value={data.marks}
                      onChange={(e) => markHandler(e.target.value, data.id)}
                    />
                    <DeleteOutlineOutlinedIcon
                      style={{
                        fontSize: "40",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => removeMarkHandler(data.id)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "5%" }}>
            {showerror && (
              <Alert
                action={
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowerror(false);
                    }}
                  >
                    <CancelOutlinedIcon />
                  </IconButton>
                }
                icon={false}
                sx={{
                  color: "blue",
                  fontWeight: 500,
                  fontSize: "0.93vw",
                  // background: "#bddabca1",
                  background: "red",
                  width: "300px"
                }}
              >
                {showerrorMessage}
              </Alert>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              flexDirection: "row",
              columnGap: "1rem",
            }}
          >
            <Yellow100Button onClick={close}>Cancel</Yellow100Button>
            <Green100Button
              onClick={async () => {
                console.log(sessionStorage.getItem("boardId"))

                setShowMessage(true);
                setTimeout(() => {
                  setShowMessage(false);
                }, 3000);
                console.log("marklist ", markList);
                console.log("content", content, questionContent.content);
                console.log("uploaded file", uploadedFile, selectedQuestionData)
                const payload = {
                  subjectId: selectedSubject,
                  conceptId: selectedQuestionData?.conceptId,
                  subConceptId: selectedQuestionData?.subConceptId,
                  rootConceptId: selectedQuestionData?.rootConceptId,
                  questionTypeId: selectedQuestionData.questionTypeId,
                  taxonomyCategoryId: selectedQuestionData.taxonomyCategoryId,
                  taxonomyId: selectedQuestionData.taxonomyId,
                  boardAndGrades: [{ boardId: selectedboard, gradeId: selectedGrade }],
                  question: questionContent.content,
                  explanation: selectedQuestionData.explanation,
                  // imageUrl: selectedQuestionData.imageUrl,
                  imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/" + selectedQuestionData.imageUrl?.name,
                  hasSubQuestions: selectedQuestionData.hasSubQuestions,
                  options: selectedQuestionOptionData,
                  questionPattern: selectedQuestionData.questionPattern,
                  markList: markList
                  // parentQuestionId: props.caseStudy && parentId,
                }

                console.log("payload", payload, selectedQuestionData, selectedQuestionData?.marks, markList)

                const totalMarks = markList?.reduce((accumulator, currentValue) => {
                  const marks = parseFloat(currentValue.marks);
                  return accumulator + marks;
                }, 0);
                console.log("totalMarks", totalMarks)
                if (totalMarks !== selectedQuestionData?.marks) {
                  setshowerrorMessage("Mark should be equal to the selected Question")
                  setShowerror(true)
                  setTimeout(() => {
                    setShowerror(false)
                  }, 10000);
                } else {
                  await create_question_post(payload, setShowSuccessAlert, setResponseValue, setShowErrorAlert, showErrorMessage, callback);
                }
                // console.log(responseValue, "responsevalue--------------")
              }}
            >
              Ok
            </Green100Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default AddQuestionModal;
