import React, { useEffect, useState } from 'react'
import dashboardContentStyles from '../../css/dashboardContent.module.css'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TablePagination
} from '@mui/material'
import sortIcon from '../../../../src/pages/img/sort_1.png'
import Switch from '../../../components/common/Switch'
import EditIconButton from '../../../components/common/EditIconButton'
import action__Edit from '../../../../src/img/Edit.svg'
import action__Delete from '../../../../src/img/Delete.svg'
import DeleteIconButton from '../../../components/common/DeleteIconButton'
import axios from 'axios'
import { isNOTNullOrUndefined } from '../TeachValidate'
import CommonDeleteModal from '../CommonDeleteModal'
import PlanListHeader from './PlanListHeader'
import ToggleConfirmationModal from './ToggleConfirmationModal'
import DeleteModal from './DeleteModal'

const PlanListView = ({ onEditClick, onAddPlanClick }) => {
  const token = sessionStorage.getItem('token')

  const [planPageData, setPlanPageData] = useState({
    planData: [],
    filterPlanData: [],
    boardList: [],
    gradeList: [],
    filterGradeList: [],
    subjectList: [],
    selectedGradeIds: [],
    selectedItem: null,
    selectedBoard: '',
    selectedPlan: '',
    planTemplateVisible: false,
    isEdit: false,
    visibleDeleteModal: false
  })
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [message, setMessage] = useState('')
  const [id, setId] = useState('')
  const [toggleMessage, settoggleMessage] = useState()
  const [toggleConfirmationModalOpen, setToggleConfirmationModalOpen] = useState(false)
  const [searchParam, setSearchParam] = useState('')
  const [sortOrder, setSortOrder] = useState(true)
  const [sortBy, setSortBy] = useState('id')

  const [toggleDetails, setToggleDetails] = useState({})


  const togglePlan = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/toggle-active?id=${toggleDetails?.id}&active=${toggleDetails?.value}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          data: toggleDetails.value
        }
      )
      .then(function (response) {
        fetchData(sortOrder, sortBy)
        handleConfirmationClose()
      })
      .catch(function (response) {
        setToggleDetails({})
      })
  }

  const switchHandler = async (id, e) => {
    const value = e.target.checked
    setToggleDetails({ id: id, value: value })
    setToggleConfirmationModalOpen(true)
  }

  const handleConfirmationClose = () => {
    setToggleConfirmationModalOpen(false)
    setToggleDetails({})
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage)
  }

  const openDeleteModal = async item => {
    setId(item?.id)
    setPlanPageData({
      ...planPageData,
      selectedItem: item || null,
      visibleDeleteModal: true
    })
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/confirmation-api?planId=${item.id}&operationType=DELETE`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )
    setMessage(response?.data?.data?.message)
  }

  const closeDeleteModal = () => {
    setPlanPageData({
      ...planPageData,
      selectedItem: null,
      visibleDeleteModal: false
    })

  }

  const handleRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const fetchData = async (sortOrder, sortBy) => {
    await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL
      }v1/api/master/plans/page?pageSize=${rowsPerPage}&pageNumber=${pageNumber}&sortOrder=${sortOrder}&sortBy=${sortBy}${isNOTNullOrUndefined(searchParam) ? `&search=${searchParam}` : ''
      }`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    ).then(response => setPlanPageData({
      ...planPageData,
      planData: response?.data?.data,
      visibleDeleteModal: false
    })).catch(err => console.log(err, 'err'))
  }

  useEffect(async () => {
    await fetchData(sortOrder, sortBy)
  }, [sortOrder, rowsPerPage, pageNumber, searchParam])



  const deleteHandler = async () => {
    await axios
      .delete(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then(function (response) {
        if (response?.data) {
          setPlanPageData({
            ...planPageData,
            visibleDeleteModal: false,
            selectedItem: null
          })
          fetchData(sortOrder, sortBy)
        }
      })
      .catch(function (response) {
        setPlanPageData({
          ...planPageData,
          visibleDeleteModal: false,
          selectedItem: null
        })
      })
  }

  const handleSearch = e => {
    setSearchParam(e.target.value)
  }

  const CustomTooltip = ({ title, children, ...props }) => (
    <Tooltip
      title={title}
      arrow
      {...props}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          }
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#ffa500', 
            color: 'white',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)', 
            fontSize: '10px', 
            fontWeight: 500,
            padding: '9px 20px', 
            borderRadius: '5px', 
          },
        },
        arrow: {
          sx: {
            color: '#ffa500', 
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <Paper>
      <PlanListHeader
        searchParam={searchParam}
        handleSearch={handleSearch}
        onAddPlanClick={onAddPlanClick}
      />
      <TableContainer
        style={{ marginBottom: '90px' }}
        sx={{ maxHeight: 640, overflow: 'auto', paddingBottom: 10 }}
      >
        <Table
          sx={{ minWidth: 650 }}
          stickyHeader={true}
          aria-label='customized table'
        >
          <TableHead>
            <TableRow>
              <TableCell className={dashboardContentStyles.table_head}>
                <div style={{ alignItems: 'center', display: 'flex' }}>#</div>
              </TableCell>
              <TableCell
                align='left'
                className={dashboardContentStyles.table_head}
              >
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  Plan Name{'  '}
                  <img
                    className='cursor-pointer'
                    width='10'
                    height='15'
                    style={{
                      verticalAlign: 'middle',
                      margin: '2px 0 2px 2px'
                    }}
                    src={sortIcon}
                    onClick={async () => {
                      await setSortBy('plan')
                      await setSortOrder(!sortOrder)
                    }}
                  />
                </div>
              </TableCell>
              <TableCell
                align='right'
                className={dashboardContentStyles.table_head}
              >
                Active
                <img
                  className='cursor-pointer'
                  width='10'
                  height='15'
                  style={{
                    verticalAlign: 'middle',
                    margin: '2px 0 2px 2px'
                  }}
                  src={sortIcon}
                  onClick={async () => {
                    await setSortBy('plan')
                    await setSortOrder(!sortOrder)
                  }}
                />
              </TableCell>
              <TableCell
                align='right'
                className={dashboardContentStyles.table_head}
                style={{
                  color: 'blue',
                  fontWeight: 600,
                  paddingRight: '58px',
                  background: '#d9dcfe'
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planPageData.planData?.data?.map((row, index) => (
              <TableRow
                key={row?.id}
                sx={{
                  // "&:last-child td, &:last-child th": { border: 0 },
                  
                  backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f8',
                  "&:last-child td, &:last-child th": {
                    borderBottom: "none !important", 
                  },

                  "&:hover": {

                    "& td, & th": {
                      borderTop: "1px solid #ff9702",
                      borderBottom: "1px solid #ff9702",
                    },
                    " & th:first-of-type": {
                      borderLeft: "1px solid #ff9702",
                    },

                    "& td:first-of-type": {
                      borderLeft: "0",
                      
                    },

                    "&:last-child td, &:last-child th": {
                      borderBottom: "1px solid #ff9702 !important", 
                    },

                    "& td:last-of-type": {
                      borderRight: "1px solid #ff9702",
                      
                    },

                    "& th:last-of-type": {
                      borderRight: "0",
                    },
                  },
                  
                  "& td, & th": {
                    border: "1px solid transparent",
                    borderTop: "1px solid transparent",
                    borderBottom: "1px solid transparent",
                    "&:not(:last-child)": {
                      borderRight: "1px solid transparent",
                    },
                  },
                }}

                className={dashboardContentStyles.table_rw}
              >
                <TableCell
                  className={dashboardContentStyles.table_cell}
                  component='th'
                  scope='row'
                >
                  {pageNumber * rowsPerPage + (index + 1)}
                </TableCell>
                <TableCell
                  className={dashboardContentStyles.table_cellll}
                  align='left'
                >
                  {row?.plan}
                </TableCell>
                <TableCell
                  className={dashboardContentStyles.table_cell}
                  align='right'
                >
                  <Switch
                    checked={row?.active}
                    onChange={e => switchHandler(row?.id, e)}
                  />
                </TableCell>
                <TableCell align='right'>
                  <EditIconButton
                    onClick={() => {
                      onEditClick(row)
                    }}
                  >
                    <CustomTooltip title='Edit' placement={'top'}>
                      <img
                        className={dashboardContentStyles.aRoleActionBtn}
                        src={action__Edit}
                        alt='Edit-Btn'
                      />
                    </CustomTooltip>
                  </EditIconButton>
                  <DeleteIconButton
                    onClick={() => {
                      openDeleteModal(row)
                    }}
                  >
                    <CustomTooltip title='Remove' placement={'top'}>
                      <img
                        className={dashboardContentStyles.aRoleActionBtn}
                        src={action__Delete}
                        alt='Dlt-Btn'
                      />
                    </CustomTooltip>
                  </DeleteIconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={{
          bottom: '0',
          width: '80.5%',
          position: 'fixed',
          backgroundColor: 'white'
        }}
        rowsPerPageOptions={[5, 10, 25, 50]}
        component='div'
        count={planPageData.planData?.totalElements || 0}
        page={pageNumber}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPage}
        showFirstButton={true}
        showLastButton={true}
      />
      <ToggleConfirmationModal
        open={toggleConfirmationModalOpen}
        close={handleConfirmationClose}
        updateToggle={togglePlan}
        details={toggleDetails}
      />

      <DeleteModal
        open={planPageData.visibleDeleteModal}
        close={closeDeleteModal}
        deleteTitle={'Plan Template'}
        deleteHandler={deleteHandler}
        deleteId={planPageData?.selectedItem?.id}
        deleteName={message}
      />
    </Paper>
  )
}

export default PlanListView
