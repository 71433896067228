import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import coAdminDashboardStyles from "./../css/coadminDashboard.moule.css";
import content_count_getall from "../API_CONTROLLER/content-controller/content_count_getall";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import chapters_content_get from "../API_CONTROLLER/chapters-controller/chapters_content_get";
import dashHome from "../../../src/img/dashHome.svg";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import dashboardContentStyles from "./../css/dashboardContent.module.css";

const ContentAdminDashboard = () => {
  const [contentcount, setContentcount] = useState({});
  const [gradesName, setGradesName] = useState({});
  const [board, setBoard] = useState({});
  const [subject, setSubject] = useState({});
  const [subtopic, setSubtopic] = useState({});
  const [chapters, setChapters] = useState({});
  const [chapterdetails, setChapterdetails] = useState({});
  // const [documenttype , setDocumenttype] = useState({});
  const [modifiedDate, setModifiedDate] = useState({});

  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSubtopic, setSelectedSubtopic] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  // const [selectedDocumenttype , setSelectedDocumenttype] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    GradesControllerGetall(setGradesName);
    boards_controller_getall(setBoard);
    subjects_controller_getall(setSubject);
    subtopics_controller_getall(setSubtopic);
    // chapters_controller_getall(setChapters);
    content_count_getall(setContentcount);
    chapters_content_get(setChapterdetails);

    return () => {};
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />{" "}
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <a>Dashboard</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update:
              {modifiedDate.data}
            </p>
          </div>
        </article>

        <div
          style={{
            marginTop: "35px",
            marginLeft: "15px",
            // minWidth: '1385px' ,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <div
              style={{
                marginRight: "35px",
                marginLeft: "15px",
                display: "flex",
              }}
            >
              <p
                className={coAdminDashboardStyles.first_box}
                style={{ color: "black", fontSize: "1.3vw" }}
              >
                Teacher Files
                <span style={{ color: "blue" }}>
                  ({contentcount.teacherFiles})
                </span>
              </p>
              <hr
                style={{
                  height: "38px",
                  width: "4px",
                  marginLeft: "25px",
                  backgroundColor: "black",
                }}
              />
            </div>
            <div>
              <p
                style={{ marginTop: "15px", color: "black", fontSize: "1.3vw" }}
              >
                Student Files{" "}
                <span style={{ color: "blue" }}>
                  ({contentcount.studentFiles})
                </span>
              </p>
              <div style={{ display: "flex" }}>
                <p style={{ color: "black", fontSize: "1.3vw" }}>
                  Study Files{" "}
                  <span style={{ color: "blue" }}>
                    ({contentcount.studyFiles})
                  </span>{" "}
                </p>
                <hr
                  style={{
                    width: "4px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    backgroundColor: "black",
                  }}
                />
                <p style={{ color: "black", fontSize: "1.3vw" }}>
                  {" "}
                  Revision Files
                  <span style={{ color: "blue" }}>
                    ({contentcount.revisionFiles})
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div style={{ width: "100%", marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "1rem",
                position: "absolute",
                right: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                {/* <LibraryBooksRoundedIcon style={{ fontSize: "30px" }} /> */}
              </div>
              <Box sx={{ minWidth: 150 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small">Board</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    label="Board"
                    //   onChange={selectBoardHandler}
                    style={{ background: "#fff", borderRadius: 150 }}
                    //   MenuProps={MenuProps}
                  >
                    {/* <MenuItem value={"all"}>All</MenuItem> */}
                    {board.length > 0 ? (
                      board.map((board, i) => {
                        return (
                          <MenuItem key={i} value={board.board}>
                            <ListItemText primary={board.board} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 150 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small">Grade</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    // value={board}
                    label="Grade"
                    //   onChange={selectGradeHandler}
                    style={{ background: "#fff", borderRadius: 150 }}
                    //   MenuProps={MenuProps}
                  >
                    {gradesName.length > 0 ? (
                      gradesName.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}>
                            <ListItemText primary={elem.grade} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 150 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small">Subject</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    label="Subject"
                    style={{ background: "#fff", borderRadius: 150 }}
                    //   onChange={selectSubjectHandler}
                    //   MenuProps={MenuProps}/
                  >
                    {subject.length > 0 ? (
                      subject.map((subject, i) => {
                        return (
                          <MenuItem key={i} value={subject.subject}>
                            <ListItemText primary={subject.subject} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ minWidth: 170 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small">Subtopic</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    label="Subtopic"
                    style={{ background: "#fff", borderRadius: 150 }}
                    //   MenuProps={MenuProps}
                    //   onChange={selectSubtopicHandler}
                  >
                    {subtopic.length > 0 ? (
                      subtopic.map((subtopic, i) => {
                        return (
                          <MenuItem key={i} value={subtopic.subTopic}>
                            <ListItemText primary={subtopic.subTopic} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                    {/* {subTopicData?.map((item) => (
                    <MenuItem key={item.id} value={item?.id}>
                      <ListItemText primary={item?.subTopic} />
                    </MenuItem>
                  ))} */}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            margin: "16px",
            position: "relative",
            marginLeft: "15px",
            marginTop: "70px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {chapterdetails.length > 0 ? (
            chapterdetails.map((elem, i) => {
              return (
                <div
                  key={i}
                  onClick={(e) => {
                    navigate("/dashboard/FileStatus");
                  }}
                  style={{
                    display: "block",
                    width: "300px",
                    height: "216px",
                    backgroundColor: "white",
                    margin: "11px 11px",
                    border: "1px solid lightgrey",
                    borderRadius: "6px",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <div style={{ backgroundColor: "#F3F2FF", height: "40px" }}>
                      <p style={{ padding: "5px", color: "blue" }}>
                        {elem.chapter}
                      </p>
                    </div>

                    {/* <Link to="/dashboard/FileStatus" style={{textDecoration : 'none'}}> */}
                    <div
                      style={{
                        display: "flex",
                        position: "absolute",
                        right: "7px",
                      }}
                    >
                      <p
                        style={{
                          color: "blue",
                          fontSize: "13px",
                          marginRight: "8px",
                        }}
                      >
                        Uploaded
                      </p>
                      <p
                        style={{
                          color: "blue",
                          fontSize: "13px",
                          marginRight: "8px",
                        }}
                      >
                        Pending
                      </p>
                    </div>
                    {/* </Link> */}
                    <hr style={{ marginTop: "23px" }} />
                    <div style={{ display: "flex" }}>
                      <div>
                        <p
                          style={{
                            color: "blue",
                            fontSize: "13px",
                            margin: "10px 10px",
                          }}
                        >
                          Teacher
                        </p>
                        <hr style={{ width: "72px", margin: "8px" }} />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          position: " absolute",
                          right: "10px",
                        }}
                      >
                        <div>
                          {" "}
                          <p style={{ margin: "7px 25px ", color: "black" }}>
                            {elem.count ? elem.count.teacherUploaded : "-"}
                          </p>
                          <hr style={{ width: "47px" }} />
                        </div>
                        <div>
                          {" "}
                          <p style={{ margin: "7px 25px ", color: "black" }}>
                            {elem.count ? elem.count.teacherPending : "-"}
                          </p>
                          <hr style={{ width: "47px" }} />
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <div>
                        <p
                          style={{
                            color: "blue",
                            fontSize: "13px",
                            margin: "10px 10px",
                          }}
                        >
                          Student study
                        </p>
                        <hr style={{ width: "72px", margin: "8px" }} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          position: " absolute",
                          right: "10px",
                        }}
                      >
                        <div>
                          {" "}
                          <p style={{ margin: "7px 25px ", color: "black" }}>
                            {elem.count ? elem.count.studentStudyUploaded : "-"}
                          </p>
                          <hr style={{ width: "47px" }} />
                        </div>
                        <div>
                          {" "}
                          <p style={{ margin: "7px 25px ", color: "black" }}>
                            {elem.count ? elem.count.studentStudyPending : "-"}
                          </p>
                          <hr style={{ width: "47px" }} />
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <div>
                        {" "}
                        <p
                          style={{
                            color: "blue",
                            fontSize: "13px",
                            margin: "10px 10px",
                          }}
                        >
                          Stuent Revision{" "}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          position: " absolute",
                          right: "10px",
                        }}
                      >
                        <div>
                          {" "}
                          <p style={{ margin: "7px 25px ", color: "black" }}>
                            {elem.count
                              ? elem.count.studentRevisionUploaded
                              : "-"}
                          </p>
                        </div>
                        <div>
                          {" "}
                          <p style={{ margin: "7px 25px ", color: "black" }}>
                            {elem.count
                              ? elem.count.studentRevisionPending
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p
              style={{
                height: "25rem",
                textAlign: "center",
                position: "absolute",
                left: "40%",
                top: "50%",
                transform: "translateY(50%)",
              }}
            >
              NO DATA AVAILABLE
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default ContentAdminDashboard;
