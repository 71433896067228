import React, { useEffect, useState } from "react";
import { Button, Checkbox } from "@mui/material";
import { isNOTNullOrUndefined, isNullOrUndefined } from "../TeachValidate";
import PlanFilterBar from "./PlanFilterBar";

import {
  accordionItem,
  bottomStyle,
  cancelBtn,
  listContainer,
  submitBtn,
} from "./styles";
import AddPlanNameModal from "./AddPlanNameModal";
import MessageModal from "./MessageModal";
import {
  getBoardsList,
  getSinglePlanDetails,
  postTemplateData,
} from "./Apis/PlanTemplateMAdminApi";
import { NotEverythingIsUnchecked, UpdatedButton } from "./Data/dataHelpers";
import GradePlansListView from "./SingleView/GradePlansListView";
import SinglePlanTabHead from "./SingleView/SinglePlanTabHead";
import BluePrintLevelView from "./BluePrintLevelView";
import SpinLoader from "../../../components/SpinLoader/SpinLoader";

const PlanSingleView = ({ selectedPlan, returnToPlanListview }) => {
  const [planDetails, setPlanDetails] = useState([]);
  const [bluePrintLevelsData, setBluePrintLevelsData] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [preselectedGradeItems, setPreselectedGradeItems] = useState([]);

  const [boardList, setBoardsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [planName, setPlanName] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [openNameModal, setOpenNameModal] = useState(false);
  const [selectedGradeId, setSelectedGradeId] = useState("");
  const [allFieldsSelectedInGrade, setAllSelected] = useState([]);
  const [isBluePrintTab, setIsBluePrintTab] = useState(false);
  const [selectedBlueprintLevels, setSelectedBlueprintLevels] = useState([]);

  const isEdit = isNOTNullOrUndefined(selectedPlan);

  useEffect(() => {
    //if edit store the active grades id's in anotherarray and on submit match those ids to
    if (!isEdit) {
      fetchBoardsList();
    }
  }, []);

  useEffect(() => {
    if (isNOTNullOrUndefined(selectedPlan)) {
      setSelectedBoard({ id: selectedPlan?.boardId });
      setPlanName(selectedPlan.plan);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (isNOTNullOrUndefined(selectedBoard.id)) {
      setIsLoading(true);
      setPlanDetails([]);
      fetchSinglePlanDetails();
    }
  }, [selectedBoard]);

  const fetchBoardsList = async () => {
    const responseData = await getBoardsList();
    setBoardsList(responseData);
    setSelectedBoard(responseData[0]);
  };

  const fetchSinglePlanDetails = async () => {
    const response = await getSinglePlanDetails(
      selectedBoard?.id,
      selectedPlan?.id
    );
    let planDetailsResponse = response?.planGrades;
    let blueprintData = response?.blueprintLevels;
    setPlanDetails(planDetailsResponse);
    setBluePrintLevelsData(blueprintData);
    if (isEdit) {
      let activeGradeItems = planDetailsResponse?.filter(
        (i) => i.active === true
      );
      let activeGradeItemsIds = activeGradeItems?.map((i) => i.gradeId);
      setSelectedGrades(activeGradeItemsIds);
      let arr = [];
      activeGradeItems?.forEach((gradeItem) => {
        let obj = { ...gradeItem };
        obj.planTemplates = gradeItem?.planTemplates?.filter((subjectItem) =>
          isNOTNullOrUndefined(subjectItem.id)
        );
        arr.push(obj);
      });
      setPreselectedGradeItems(arr);

      let activeLevelItems = blueprintData?.filter((i) => i.active === true);
      let activeLevelItemsIds = activeLevelItems?.map((i) => i.id);
      setSelectedBlueprintLevels(activeLevelItemsIds);
    }
    setIsLoading(false);
  };

  const selectBoardHandler = (e) => {
    const { value } = e.target;
    setSelectedBoard(boardList.find((i) => i.id === value));
    setSelectedGrades([]);
    setSearchParam("");
  };

  const handleExpand = (id) => {
    if (id === selectedGradeId) {
      setSelectedGradeId("");
    } else {
      setSelectedGradeId(id);
    }
  };

  const handlePlanTemplateData = async (data, gradeId) => {
    //find the grade object that is expanded
    const modifiedArray = planDetails.map((item) => {
      if (item.gradeId === gradeId) {
        const atleastOneSubjectIsChecked = NotEverythingIsUnchecked(data);
        if (!atleastOneSubjectIsChecked) {
          setSelectedGrades((prev) => prev?.filter((i) => i != gradeId));
        } else {
          if (!selectedGrades.includes(gradeId)) {
            setSelectedGrades((prev) => [...prev, gradeId]);
          }
        }
        item.planTemplates = data;
        return { ...item };
      } else {
        return item;
      }
    });
    //modified object is spread into planstemplate array replacing the previous datas of the grade's planstemplate
    setPlanDetails(modifiedArray);
  };

  const handleGradeCheck = async (e, gradeId) => {
    const { checked } = e.target;
    if (checked) {
      let gradeItem = planDetails.find((i) => i.gradeId === gradeId);
      const atleastOneSubjectIsSelected = await NotEverythingIsUnchecked(
        gradeItem.planTemplates
      );
      if (atleastOneSubjectIsSelected) {
        setSelectedGrades((prev) => [...prev, gradeId]);
      } else {
        setShowMessage(
          "Please do select atleast one subject to select the grade"
        );
      }
    } else {
      let gradeItem = planDetails.find((i) => i.gradeId === gradeId);
      gradeItem.planTemplates.forEach((subjectItem) => {
        let arr = Object.entries(subjectItem);
        arr.forEach(([key, value]) => {
          if (subjectItem[key] === true) {
            subjectItem[key] = false;
          }
        });
      });
      setSelectedGrades((prev) => prev?.filter((i) => i != gradeId));
    }
  };

  const addHandler = async () => {
    setIsSubmitting(true);
    let selectedGradesDetailsArray = planDetails?.filter((i) =>
      selectedGrades.includes(i.gradeId)
    );

    //adding active == true or false in subject level
    selectedGradesDetailsArray.forEach((gradeItem) => {
      delete gradeItem.id;
      delete gradeItem.grade;
      gradeItem.planTemplates.forEach((subjectItem) => {
        delete subjectItem.active;
        delete subjectItem.id;
        delete subjectItem.subject;
        const values = Object.values(subjectItem);
        const containsTrue = values.includes(true);
        subjectItem.active = containsTrue;
      });
    });

    //filtering out only the active subjects and saving to current plantemplates
    selectedGradesDetailsArray.forEach((gradeItem) => {
      gradeItem.planTemplates = gradeItem.planTemplates?.filter(
        (subject) => subject.active === true
      );
    });

    let submitionData = {
      plan: planName,
      discription: planName,
      boardId: selectedBoard?.id,
      grades: selectedGradesDetailsArray,
      active: true,
      blueprintLevels: selectedBlueprintLevels,
    };
    apiCallforPostingData(submitionData);
  };

  const editHandler = () => {
    setIsSubmitting(true);
    let selectedGradesDetailsArray = planDetails?.filter((i) =>
      selectedGrades.includes(i.gradeId)
    );

    selectedGradesDetailsArray.forEach((prevSelected) => {
      prevSelected.active = true;
    });

    let preselectedincludingLatest = [...selectedGradesDetailsArray];

    preselectedGradeItems.forEach((prevSelected) => {
      let exisitingObject = selectedGradesDetailsArray.find(
        (i) => i.gradeId === prevSelected.gradeId
      );
      //grade level
      if (isNullOrUndefined(exisitingObject)) {
        prevSelected.active = false;
        preselectedincludingLatest.push(prevSelected);
      }
    });

    preselectedincludingLatest.forEach((gradeItem) => {
      delete gradeItem.grade;
      gradeItem.planTemplates.forEach((subjectItem) => {
        delete subjectItem.active;
        delete subjectItem.subject;
        const values = Object.values(subjectItem);
        const containsTrue = values.includes(true);
        subjectItem.active = containsTrue;
      });
    });

    //include both active subject of latest and old inactive subject
    preselectedincludingLatest.forEach((gradeItem) => {
      let arr = [];
      gradeItem.planTemplates.forEach((subjectItem) => {
        if (isNOTNullOrUndefined(subjectItem.id)) {
          arr.push(subjectItem);
        } else if (subjectItem.active === true) {
          arr.push(subjectItem);
        }
      });
      gradeItem.planTemplates = arr;
    });

    let submitionData = {
      id: selectedPlan?.id,
      plan: planName,
      discription: planName,
      boardId: selectedBoard?.id,
      grades: preselectedincludingLatest,
      active: true,
      blueprintLevels: selectedBlueprintLevels,
    };

    apiCallforPostingData(submitionData);
  };

  const apiCallforPostingData = async (submitionData) => {
    const response = await postTemplateData(isEdit, submitionData);
    setIsSubmitting(false);
    setShowMessage(response);
  };

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  const submitValidation = () => {
    if (selectedGrades?.length > 0) {
      setOpenNameModal(true);
    } else {
      setShowMessage("Select atleast one grade to proceed");
    }
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "82vh" }}>
      <PlanFilterBar
        selectedBoard={selectedBoard}
        selectBoardHandler={selectBoardHandler}
        boardList={boardList}
        handleSearch={handleSearch}
        searchParam={searchParam}
        isEdit={isEdit}
        isBluePrintTab={isBluePrintTab}
      />
      <SinglePlanTabHead
        setIsBluePrintTab={setIsBluePrintTab}
        isBluePrintTab={isBluePrintTab}
      />
      {/* {isLoading ? (
        <SpinLoader />
      ) : ( */}
        <div>
          {isBluePrintTab ? (
            <BluePrintLevelView
              bluePrintLevelData={bluePrintLevelsData}
              setSelectedBlueprintLevels={setSelectedBlueprintLevels}
              selectedBlueprintLevels={selectedBlueprintLevels}
              isEdit={isEdit}
            />
          ) : (
            <GradePlansListView
              listContainer={listContainer}
              planDetails={planDetails}
              searchParam={searchParam}
              handleGradeCheck={handleGradeCheck}
              selectedGrades={selectedGrades}
              handleExpand={handleExpand}
              selectedGradeId={selectedGradeId}
              setAllSelected={setAllSelected}
              allFieldsSelectedInGrade={allFieldsSelectedInGrade}
              handlePlanTemplateData={handlePlanTemplateData}
            />
          )}
        </div>
      
      <div style={bottomStyle}>
        <Button onClick={returnToPlanListview} sx={cancelBtn}>
          Cancel
        </Button>
        {isEdit ? (
          <UpdatedButton onClick={submitValidation} disabled={isSubmitting}>
            Update
          </UpdatedButton>
        ) : (
          <UpdatedButton onClick={submitValidation} disabled={isSubmitting}>
            Submit
          </UpdatedButton>
        )}
      </div>
      <AddPlanNameModal
        isEdit={isEdit}
        editHandler={isEdit ? editHandler : addHandler}
        addHandler={addHandler}
        setPlanName={setPlanName}
        planName={planName}
        open={openNameModal}
        close={() => setOpenNameModal(false)}
      />
      <MessageModal
        close={() => {
          if (showMessage.includes("successfully")) {
            setShowMessage("");
            returnToPlanListview();
          } else {
            setShowMessage("");
          }
        }}
        open={isNOTNullOrUndefined(showMessage) ? true : false}
        message={showMessage}
      />
    </div>
  );
};

export default PlanSingleView;
