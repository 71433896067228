import React, { useEffect } from 'react'
import './Principaldashboard.css'
import { cardText, cardTop, countCardStyle, rowOne } from './styles'
import { Doughnut } from 'react-chartjs-3'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
	getCountForPricipalDashboard,
} from '../../../API/principal-controller/dashboardApis'
import { isNOTNullOrUndefined, truncateString } from '../../../../utils/helper'
import studentNumber from '../../../../img/no-of-students.png'
import sectionNumber from '../../../../img/no-of-grades.png'
import subjectsTaught from '../../../../img/no-of-coordinators.png'
import { Tooltip } from '@material-ui/core'

const RowOne = ({
	columnWidth,
	profileData,
	token
}) => {
	const teacherId = profileData?.teacher?.id
	const schoolId = profileData?.teacher?.school
	const branchId = profileData?.teacher?.branch
	const queryClient = useQueryClient()

	const {
		isLoading: isFetchingCounts,
		data: countsForDashboard
	} = useQuery(
		['countForPrincipalDashboard', teacherId, schoolId, branchId, token],
		getCountForPricipalDashboard,
		{ enabled: !!teacherId  }
	)

	return (
		<div style={rowOne}>
			<div
				className='count-card'
				style={{ ...countCardStyle, width: columnWidth }}
			>
				<div style={cardTop}>
					<p style={cardText}>No of Sections</p>
					<div className='icon-bg-1 icon-bg'>
						<img src={sectionNumber} alt='no img' width='40px' height='40px' />
					</div>
				</div>
				<p style={{ fontSize: '36px', color: '#2D3962' }}>
					{countsForDashboard?.sectionsCount || 0}
				</p>
			</div>
			<div
				className='count-card'
				style={{ ...countCardStyle, width: columnWidth }}
			>
				<div style={cardTop}>
					<p style={cardText}>No of Students</p>
					<div className='icon-bg-2 icon-bg'>
						<img src={studentNumber} alt='no img' width='40px' height='40px' />
					</div>
				</div>
				<p style={{ fontSize: '36px', color: '#2D3962' }}>
					{countsForDashboard?.studentCount || 0}
				</p>
			</div>
			<div
				className='count-card'
				style={{ ...countCardStyle, width: columnWidth }}
			>
				<div style={cardTop}>
					<p style={cardText}>No of Teachers</p>
					<div className='icon-bg-3 icon-bg'>
						<img src={subjectsTaught} alt='no img' width='40px' height='40px' />
					</div>
				</div>
				<p style={{ fontSize: '36px', color: '#2D3962' }}>
					{countsForDashboard?.teacherCount || 0}
				</p>
			</div>
			<div
				className='count-card'
				style={{ ...countCardStyle, width: columnWidth }}
			>
				<div style={cardTop}>
					<p style={cardText}>No. Of Coordinators</p>
					<div className='icon-bg-3 icon-bg'>
						<img src={subjectsTaught} alt='no img' width='40px' height='40px' />
					</div>
				</div>
				<p style={{ fontSize: '36px', color: '#2D3962' }}>
					{countsForDashboard?.coordinatorCount || 0}
				</p>
			</div>
		</div>
	)
}

export default RowOne
