import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";
import searchIcn from "../../../src/img/search.svg";
import commonsort1 from "../js/CommonSort"
import sortIcon from "../img/sort.png"
import { filter } from "lodash";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  rowGap: "1rem",
};
const setBg = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomColor}`;
};
const StudentSectionModal = ({ open, close, data }) => {
  const [studentData, setStudentData] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [filterValue1, setFilterValue1] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("firstName");
  useEffect(async () => {
    if (data?.length) {
      console.log(sortBy, sortOrder, rowsPerPage)
      data = await commonsort1(data || [], sortBy, sortOrder)
      setFilterValue1(data)
      data = data?.slice(0, rowsPerPage)
      await setFilterValue(data);

      await setStudentData(data);
    }
  }, [data]);
  const searchHandler = (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = studentData;
    if (value) {
      result = studentData?.filter((data) => {
        return (
          data?.firstName?.toLowerCase().includes(value) ||
          data?.lastName?.toLowerCase().includes(value) ||
          data?.email?.toLowerCase().includes(value) ||
          data?.admissionDate?.toLowerCase().includes(value) ||
          data?.lastLoginTime?.toLowerCase().includes(value)
        );
      });
    }
    setFilterValue(result);
    setFilterValue1(result)
  };
  const handleChangePage = async (event, newPage) => {
    var newField = sortBy
    if (sortBy === "lastUnitQuizSubmission") {
      newField = "lastUnitQuizSubmissionNew"
    } else if (sortBy === "lastLoginTime") {
      console.log("else if", "lastLoginTimeNew")
      newField = "lastLoginTimeNew"
    }
    console.log("handleChangePage", newPage, rowsPerPage, filterValue, sortBy, sortOrder)
    var vfilterValue1 = await commonsort1(filterValue1, newField, sortOrder)
    const start = ((newPage + 1) - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    var tempfilterValue = vfilterValue1?.slice(start, end);
    setFilterValue(tempfilterValue);
    setPageNumber(newPage);
  };
  const handleRowsPerPage = (event) => {
    console.log("handleRowsPerPage", event.target.value, pageNumber,parseInt(event.target.value, 10))
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };
  const lastDateAndLoginHandler = (item) => {
    if (item?.length > 0) {
      const array = item?.split(" ");
      //const date = moment(new Date(array[0])).format("MM/DD/YYYY");
      // const time = moment(array[1]).format("hh:mm");
      return `${array[0]} / ${array[1]}`;
    }
  };
  const submissionDateHandler = (item) => {
    // if (item?.length) {
    //   return moment(item).format("YYYY - MMM - DD");
    // }
    if (item?.length > 0) {
      const array = item?.split(" ");
      //const date = moment(new Date(array[0])).format("MM/DD/YYYY");
      // const time = moment(array[1]).format("hh:mm");
      return `${array[0]} / ${array[1]}`;
    }
    // if (item?.length) {
    //   return moment(item).format("YYYY - MMM - DD");
    // }
  };
  async function parseDateString(dateString) {
    console.log(dateString)
    if (dateString === null) {
      return ""
    }
    const [datePart, timePart, period] = dateString.split(/[\s:]/);

    const [day, month, year] = datePart.split('-').map(Number);

    let [hour, minute, second] = timePart.split(':').map(Number);

    if (period === 'PM' && hour !== 12) {
      hour += 12;
    } else if (period === 'AM' && hour === 12) {
      hour = 0;
    }

    return new Date(year, month - 1, day, hour, minute, second);
  }

  const commonsort = (array,
    field,
    order) => {
    console.log(array, field, order, "common sorting file");
    order = order === true ? "desc" : "asc";
    if (array?.length > 0) {
      const nullsFirst = order === "asc";
      return array.sort((a, b) => {
        if (a[field] === null && b[field] === null) return 0;
        if (a[field] === null) return nullsFirst ? -1 : 1;
        if (b[field] === null) return nullsFirst ? 1 : -1;

        if (typeof a[field] === 'boolean') {
          if (order === 'asc') {
            return a[field] === b[field] ? 0 : a[field] ? 1 : -1;
          } else {
            return a[field] === b[field] ? 0 : a[field] ? -1 : 1;
          }
        } else if (typeof a[field] === 'string') {
          if (order === 'asc') {
            return a[field].localeCompare(b[field]);
          } else {
            return b[field].localeCompare(a[field]);
          }
        } else if (typeof a[field] === 'number' || a[field] instanceof Date) {
          if (order === 'asc') {
            return new Date(a[field]) - new Date(b[field]);
          } else {
            return new Date(b[field]) - new Date(a[field]);
          }
        }
      });
    } else {
      return [];
    }
  }

  const onSort = async (sortOrder, field) => {
    setSortOrder(sortOrder)
    var newField = field
    if (field === "lastUnitQuizSubmission") {
      newField = "lastUnitQuizSubmissionNew"
    } else if (field === "lastLoginTime") {
      console.log("else if", field)
      newField = "lastLoginTimeNew"
    }
    console.log(field, newField, filterValue,filterValue1,pageNumber,rowsPerPage,sortOrder)
    var sorted = await commonsort(filterValue1 || [], newField, sortOrder);
    console.log(sorted)
    var x = sorted?.slice(pageNumber, rowsPerPage)
    setFilterValue(x)
  }

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: 20, fontWeight: 600, color: "#29313f" }}>
              Section Students
            </div>
            <CloseOutlinedIcon
              onClick={close}
              style={{ cursor: "pointer", fontSize: 20, color: "#757575" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{
                width: { sm: 200, md: 300 },
                "& .MuiInputBase-root": {
                  width: "247px !important",
                  height: "32px !important",
                  borderRadius: "50px !important",
                  background: "#fff !important",
                  lineHeight: "21px !important",
                  opacity: 1,
                  font: "normal normal normal 13px/20px Poppins !important",
                },
              }}
              type={"text"}
              placeholder={"Search anything...."}
              onChange={searchHandler}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <img
                      src={searchIcn}
                      style={{
                        verticalAlign: "top",
                        fontSize: "large",
                      }}
                      aria-hidden="true"
                    />
                  </IconButton>
                ),
              }}
              size={"small"}
            />
          </div>
        </div>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            boxShadow: "0px 0px 0px 0px",
          }}
        >
          <TableContainer sx={{ maxHeight: 500, overflow: "auto" }}>
            <Table
              sx={{ minWidth: 650, marginBottom: "90px" }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: 600,
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    S.NO
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 600,
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                    align={"left"}
                  >
                    Name
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("firstName");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "firstName")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: 600,
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    Email
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("email");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "email")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 600,
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    Last unit Quiz submission
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("lastUnitQuizSubmission");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "lastUnitQuizSubmission")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: 600,
                      paddingRight: "60px",
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    Last login date and time
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("lastLoginTime");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "lastLoginTime")
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              {
                console.log("filterValue", filterValue, sortBy, sortOrder, rowsPerPage, filterValue1)
              }
              <TableBody>
                {filterValue?.length > 0 &&
                  filterValue
                    ?.map((row, index) => {
                      const firstLetter = row?.firstName;
                      return (
                        <TableRow
                          key={row?.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {pageNumber * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#575269",
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "10px",
                            }}
                          >
                            <Avatar
                              style={{
                                marginRight: 10,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              sx={{
                                bgcolor: setBg(),
                              }}
                            >
                              {firstLetter[0]}
                            </Avatar>
                            {`${row?.firstName} ${row?.lastName}`}
                          </TableCell>
                          <TableCell align="left" style={{ color: "#575269" }}>
                            {row?.email}
                          </TableCell>
                          <TableCell align="left" style={{ color: "#575269" }}>
                            {row?.lastUnitQuizSubmission}
                          </TableCell>
                          <TableCell align="left" style={{ color: "#575269" }}>
                            {row?.lastLoginTime}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              bottom: "0",
              width: "80.5%",
              position: "fixed",
              backgroundColor: "white",
            }}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={filterValue1?.length || 0}
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          />
        </Paper>
      </Box>
    </Modal>
  );
};
export default StudentSectionModal;
