import axios from 'axios'

function student_upload_chapter_get(setUpdatedata) {
  let token = sessionStorage.getItem("token");
  let status = sessionStorage.getItem("status")
  if(status != null ){
    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/student-chapter/page?pageSize=1000&sortBy=id&chapterId=${sessionStorage.getItem("chapterId")}&reviewStatus=${status}`, 
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res)=>{
      setUpdatedata(res.data.data.data)
    })
    .catch(err=>console.log(err))
}

else{
  let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/student-chapter/page?pageSize=1000&sortBy=id&chapterId=${sessionStorage.getItem("chapterId")}`, 
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res)=>{
      setUpdatedata(res.data.data.data)
    })
    .catch(err=>console.log(err))
}
}

export default student_upload_chapter_get;
