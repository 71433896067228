import React, { useState } from "react";
import Switch from "../../../../components/common/Switch";
import Reason from "../../../../img/reason.png";
import BluePrintReasonModal from "../../../../components/BluePrintReasonModal/BluePrintReasonModal";
import { Tooltip } from "@mui/material";
import { truncateString } from "../../../../utils/helper";
import { CreateOutlined, DeleteOutline } from "@mui/icons-material";

export const BlueprintTableBody = ({
  blueprintsData,
  currentPage,
  setShowDeleteModal,
  setSelectedForDelete,
  handleToggle,
  handleShowReasonModal,
  onPreviewClick,
  setSelectedForEdit,
  pageSize,
  totalElements,
  role,
  setButtonEdit,
}) => {
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [rejectedData, setRejectedData] = useState(null);
  const startIndex = (currentPage - 1) * pageSize + 1;

  const handleEdit = (id) => {
    setSelectedForEdit(id);
    setButtonEdit(true);
  };

  return (
    <tbody>
      {blueprintsData?.map((item, index) => {
        return (
          <tr className="table-data">
            <td>{startIndex + index}</td>
            <td>
              <Tooltip placement={"top"} title={item?.paperTitle}>
                {truncateString(item?.paperTitle, 8)}
              </Tooltip>{" "}
            </td>
            <td>{item?.paperType}</td>
            <td>{item.grade}</td>
            <td>{item.subject}</td>
            <td>{item?.totalMarks}</td>
            <td>{item?.totalNumberOfQuestions}</td>
            <td>{item?.durationInMinutes} Mins</td>
            {role !== "content_reviewer" && (
              <td>
                <Switch
                id={`az-content-switch-button-${item.id}`}
                  checked={item?.active}
                  onChange={(e) => handleToggle(e, item?.id)}
                />
              </td>
            )}
            {/* {role !== "content_reviewer" && ( */}
            <>
              {item?.reviewStatus === "REJECTED" ? (
                <td>
                  <img
                    src={Reason}
                    alt="reason"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setRejectedData({
                        rejectedBy: item?.rejectedBy,
                        rejectedAt: item?.rejectedAt,
                        rejectComments: item?.rejectComments,
                      });
                      setShowReasonModal(true);
                    }}
                  />
                </td>
              ) : null}
            </>
            {/* )} */}

            <td>
              <div className="action-container">
                <div
                  style={{ cursor: "pointer" }}
                  id={`az-content-preview-button-${item.id}`}
                  onClick={() => onPreviewClick(item?.id)}
                  className="preview-container"
                >
                  Preview
                </div>
                {role !== "content_reviewer" && (
                  <>
                    <div
                      style={{ cursor: "pointer" }}
                      id={`az-content-edit-button-${item.id}`}
                      onClick={() => handleEdit(item?.id)}
                    >
                      <CreateOutlined style={{ fontSize: "18px" }} />
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      id={`az-content-delete-button-${item.id}`}
                      onClick={() => {
                        setShowDeleteModal(true);
                        setSelectedForDelete(item?.id);
                      }}
                    >
                      <DeleteOutline 
                      style={{ fontSize: "18px" }} />
                      {/* <img src={action__Delete} alt="Dlt-Btn" /> */}
                    </div>
                  </>
                )}
              </div>
            </td>
          </tr>
        );
      })}
      <BluePrintReasonModal
        title={"Reason"}
        showReasonModal={showReasonModal}
        data={rejectedData}
        onReasonModalClose={() => {
          setShowReasonModal(false);
          setRejectedData(null);
        }}
      />
    </tbody>
  );
};
