async function TokenController_post(tokenInputs) {
  let token = sessionStorage.getItem("token");
  var item = {
    branchId: sessionStorage.getItem("branchid"),
    expiaryDate: tokenInputs.expiaryDate,
    multiUser: tokenInputs.multiUser,
    numberOfTokens: tokenInputs.numberOfTokens,
    numberOfUsersPerToken: tokenInputs.numberOfTokens,
    roleId: tokenInputs.roleId,
    schoolId: sessionStorage.getItem("schoolid"),
  };

  fetch(
    `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/tokens`,

    {
      method: "POST",

      headers: {
        Accept: "application/json",
        // "Content-type": "application/json; charset=UTF-8",
        "Content-Type": "application/json",
        // Accept: "/",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(item),
    }
  )
    .then((resp) => {
      return resp.json();
    })

    .catch((err) => console.log(err));
}

export default TokenController_post;
