import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Paper,
  TextField,
  Alert,
  IconButton,

} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { styled } from "@mui/material/styles";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";
import axios from "axios";
import DoneIcon from "@mui/icons-material/Done";
import { DateTime } from "luxon";
import { isNOTNullOrUndefined, isNullOrUndefined } from "../js/TeachValidate";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "transparent",
};
const CancelButton = styled(Button)(({ }) => ({
  // color: theme.palette.getContrastText(grey[500]),
  color: "#ed9b00",
  width: "5.2vw",
  backgroundColor: "#fff",
  borderRadius: 5,
  border: "1px solid #757575",
  borderColor: "#ed9b00",
  textTransform: "none",
  fontSize: "0.78vw",
}));
const ExitButton = styled(Button)(() => ({
  color: "#fff",
  width: "5.2vw",
  backgroundColor: "rgba(5,175,116,0.89)",
  borderRadius: 5,
  textTransform: "none",
  fontSize: "0.78vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "rgba(5,175,116,0.89)",
    border: 0,
  },
}));
const NextButton = styled(Button)(() => ({
  color: "#fff",
  width: "5.2vw",
  backgroundColor: "#ed9b00",
  borderRadius: 5,
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  fontSize: "0.78vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#ed9b00",
    border: 0,
  },
}));

const Container = styled(Paper)(() => ({
  width: "100%",
  "&.disabled": {
    opacity: 0.5,
  },
}));
const ReleaseModal = ({
  open,
  close,
  previewHandler,
  fetchSection,
  selectedQuizId,
  quizDuraction,
  teacherId,
  OpenReRelease,
  setSelectedSectionReleaseData,
  setSucessModal,
  quizType,
  setSucessMessage,
  setSelectedSectionName,
  selectedSubTopic,
}) => {
  const [isAlreadyReleased, setAlreadyReleased] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [wrongTime, SetWrongTime] = useState(false)
  const [selectedSection, setSelectedSection] = useState([]);
  const [isReady, setReady] = useState(false);
  const [apiParams, setApiParams] = useState([]);
  const [errorMessage, seterrorMessage] = useState("")
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchParams = () => {
      let tempArry = [];
      let sectionName = [];

      selectedSection.forEach((data) => {
        sectionName.push(data.sectionName);
        tempArry.push({
          sectionId: data.sectionId,
          startDate: data.startDate,
          startTime: data.startTime,
        });
      });
      setSelectedSectionName(sectionName);
      return tempArry;
    };

    setApiParams(fetchParams());
    setSelectedSectionReleaseData(fetchParams());
  }, [selectedSection]);

  function convertMUIDateToDDMMYYYY(dateString) {
    var muiDate = new Date(dateString);
    var day = muiDate.getDate().toString().padStart(2, "0");
    var month = (muiDate.getMonth() + 1).toString().padStart(2, "0");
    var year = muiDate.getFullYear();

    return day + "/" + month + "/" + year;
  }
  function convertMUIDateToTime(muiDate) {
    const dateObj = new Date(muiDate);
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    let period = "AM";
    let adjustedHours = hours;

    if (hours > 12) {
      adjustedHours -= 12;
      period = "PM";
    } else if (hours === 0) {
      adjustedHours = 12;
    } else if (hours === 12) {
      period = "PM";
    }

    return `${adjustedHours}:${minutes < 10 ? "0" + minutes : minutes
      } ${period}`;
  }

  const checkParams = () => {
    if (selectedSection.length > 0) {
      let err = 0;
      selectedSection.forEach((data, index) => {
        if (
          data.startDate !== "" &&
          data.startTime !== "" &&
          data.sectionId !== ""
        ) {
          // setReady(true);
        } else {
          err = err + 1;
        }
      });
      if (err > 0) {
        setReady(false);
      } else {
        setReady(true);
      }
    } else {
      setReady(false);
    }
  };
  useEffect(() => {
    checkParams();
  }, [selectedSection]);

  // function to handle selection select
  const handleSelectSection = (e, sectionId, count, name) => {
    const isSectionExist = selectedSection.some((section) => {
      if (section.sectionId === sectionId) {
        return true;
      } else {
        return false;
      }
    });

    if (isSectionExist) {
      if (count > 0) {
        setSelectedSection([]);
        setAlreadyReleased(false);
      } else {
        const otherSection = selectedSection.filter(
          (item) => item.sectionId !== sectionId
        );
        setSelectedSection(otherSection);
      }

      // console.log(otherSection, currentSection, "other section");
    } else {
      if (count > 0) {
        setSelectedSection([]);
        setSelectedSection([
          {
            sectionName: name,
            sectionId: sectionId,
            startDate: "",
            startTime: "",
            displayTime: "",
            displayDate: "",
          },
        ]);
        setAlreadyReleased(true);
      } else {
        setSelectedSection((prev) => [
          ...prev,
          {
            sectionName: name,
            sectionId: sectionId,
            startDate: "",
            startTime: "",
            displayTime: "",
            displayDate: "",
          },
        ]);
      }
    }
  };

  // function to handle date
  const handleDateChange = (date, sectionid, count, name) => {
    const isSectionExist = selectedSection.some((section) => {
      if (section.sectionId === sectionid) {
        return true;
      } else {
        return false;
      }
    });

    if (isSectionExist) {
      const otherSection = selectedSection.filter(
        (item) => item.sectionId !== sectionid
      );
      const currentSection = selectedSection.find(
        (item) => item.sectionId === sectionid
      );
      currentSection["startDate"] = convertMUIDateToDDMMYYYY(date);
      currentSection["displayDate"] = date;
      currentSection["startTime"] = "";
      currentSection["displayTime"] = "";

      setSelectedSection([...otherSection, currentSection]);
    } else {
      if (count > 0) {
        setAlreadyReleased(true);
      }
      setSelectedSection((prev) => [
        ...prev,
        {
          sectionId: sectionid,
          startDate: convertMUIDateToDDMMYYYY(date),
          startTime: "",
          displayTime: "",
          displayDate: date,
          sectionName: name,
        },
      ]);
    }

    setSelectedDate(date);
  };

  // function to hnadle the time change
  const handleTimeChange = (time, sectionId, count, name) => {
    const isSectionExist = selectedSection.some((section) => {
      if (section.sectionId === sectionId) {
        return true;
      } else {
        return false;
      }
    });

    if (isSectionExist) {
      const otherSection = selectedSection.filter(
        (item) => item.sectionId !== sectionId
      );
      const currentSection = selectedSection.find(
        (item) => item.sectionId === sectionId
      );
      currentSection["startTime"] = convertMUIDateToTime(time);
      currentSection["displayTime"] = time;

      setSelectedSection([...otherSection, currentSection]);
    } else {
      if (count > 0) {
        setAlreadyReleased(true);
      }
      setSelectedSection((prev) => [
        ...prev,
        {
          sectionId: sectionId,
          startDate: "",
          startTime: convertMUIDateToTime(time),
          displayTime: time,
          displayDate: "",
          sectionName: name,
        },
      ]);
    }
    setSelectedTime(time);
  };

  const token = sessionStorage.getItem("token");

  const fetchRelease = async () => {
    // Retrieve session data
    const boardId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const subjectId = sessionStorage.getItem("subjectId");
    const subTopicId = sessionStorage.getItem("subTopicId");
    const quizId = sessionStorage.getItem("quizId");
    const sectionId = sessionStorage.getItem("sectionId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    const data = {
      quizId: selectedQuizId,
      boardId: boardId,
      schoolId: schoolId,
      branchId: branchId,
      gradeId: gradeId,
      subjectId: subjectId,
      academicYearId: academicYearId,
      teacherId: teacherId,
      sectionList: apiParams,
    };
    if (isNOTNullOrUndefined(selectedSubTopic)) {
      data.subTopicId = selectedSubTopic;
    }
    const givenDateString = data?.sectionList[0]?.startDate; // Assuming the date is in the format "DD/MM/YYYY"
    const givenTimeString = data?.sectionList[0]?.startTime;
    const currentTime = new Date();

    // Parsing the given date string
    const [givenDay, givenMonth, givenYear] = givenDateString.split('/').map(Number);
    const givenDate = new Date(givenYear, givenMonth - 1, givenDay);

    // Parsing the given time string
    const [time, period] = givenTimeString.split(' ');
    let [givenHours, givenMinutes] = time.split(':').map(Number);

    if (period === "PM" && givenHours !== 12) {
      givenHours += 12;
    } else if (period === "AM" && givenHours === 12) {
      givenHours = 0;
    }

    // Set the given time on the given date
    givenDate.setHours(givenHours);
    givenDate.setMinutes(givenMinutes);
    givenDate.setSeconds(0);
    givenDate.setMilliseconds(0);

    // Normalize current time to ignore seconds and milliseconds
    const normalizedCurrentTime = new Date(currentTime);
    normalizedCurrentTime.setSeconds(0);
    normalizedCurrentTime.setMilliseconds(0);

    // Compare the given date and time with the current date and time based on hour and minute
    if (normalizedCurrentTime > givenDate) {
      console.log("The current date and time is greater than the given date and time", givenTimeString, givenTimeString, normalizedCurrentTime, givenDate);
      SetWrongTime(true);
      seterrorMessage("Wrong time selected");
    } else {
      console.log("The current date and time is not greater than the given date and time", givenTimeString);
      SetWrongTime(false);
      seterrorMessage("");
      try {
        await axios
          .post(
            `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz-release`,
            data,

            {
              headers: {
                "Content-Type": "application/json",

                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setLoading(false);
            setSucessModal(true);
            setSucessMessage("Quiz released successfully");
            close();
          });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  const handleRelease = () => {
    console.log(">>>>>>>>>>>>>>>>>>>>>")
    // setLoading(true);
    fetchRelease();
  };

  function convertMinsToHours(mins) {
    var hours = Math.floor(mins / 60);
    var minutes = mins % 60;

    return ` ${hours > 0 ? hours : ""}${hours > 0 ? "hrs " : ""}${minutes > 0 ? minutes : ""
      }${minutes > 0 ? "mins" : ""}`;
  }

  let currentDate = new Date();

  const getMinTime = (id) => {
    let dateSelected = selectedSection?.find((data) => data.sectionId === id);

    if (convertMUIDateToDDMMYYYY(currentDate) === dateSelected?.startDate) {
      let currD = dayjs();
      let d = dayjs()
        .set("hour", currD.$H)
        .startOf("hour")
        .set("minute", currD.$m);

      return d;
    }
  };
  function isPastEndTimeAndDate(endTime, endDate) {
    var currentTime = new Date();

    var endHour = parseInt(endTime.split(":")[0]);
    var endMinute = parseInt(endTime.split(":")[1].split(" ")[0]);
    var endMeridian = endTime.split(" ")[1];

    var endDay = parseInt(endDate.split("/")[0]);
    var endMonth = parseInt(endDate.split("/")[1]) - 1;
    var endYear = parseInt(endDate.split("/")[2]);

    var endDateTime = new Date(
      endYear,
      endMonth,
      endDay,
      endMeridian === "PM"
        ? endHour !== 12
          ? endHour + 12
          : endHour
        : endMeridian === "AM" && endHour === 12
          ? (endHour = 1)
          : endHour,
      endMinute
    );
    return currentTime > endDateTime;
  }

  const getCurrentTime = () =>
    DateTime.now().set({ second: 0, millisecond: 0 }).plus({ minutes: 1 });
  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Paper
            sx={{
              borderRadius: "7px",
              background: "rgb(96 91 161 / 81%)",
            }}
          >
            <div
              style={{
                background: "rgb(96 91 161 / 81%)",
                width: "100%",
                padding: "20px",
                color: "white",
                fontSize: "17px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontWeight: "500",
                borderTopLeftRadius: "7px",
                borderTopRightRadius: "7px",
              }}
            >
              <div>
                Select the section, Quiz Start Time, and date to release the
                quiz
              </div>

              <CloseRoundedIcon
                style={{
                  fontSize: 20,
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer",
                }}
                onClick={close}
              />
            </div>



            <div
              style={{
                background: "#fff",
                display: "flex",
                flexDirection: "column",
                borderBottomLeftRadius: "7px",
                borderBottomRightRadius: "7px",
                // padding:"50px"
              }}
            >
              {/* <p style={{color:"red"}}>Currently, the quiz release service is unavailable. Please try again later.</p> */}
              <div
                id='az-model-parent'
                style={{
                  maxHeight: "500px",
                  overflow: "scroll",
                }}
              >
                {fetchSection?.sections?.map((item) => {
                  console.log(item, isAlreadyReleased)
                  return (
                    <Container
                      id={`az-parent-container-${item.section.toLowerCase().replace(/ /g, "_")}`}
                      className={
                        quizType !== "Diagnostic Quiz" &&
                        (!item.teachingCompletedForSection
                          ? "disabled"
                          : item.endTime &&
                            !isPastEndTimeAndDate(item.endTime, item.endDate)
                            ? "disabled"
                            : quizType === "Practice Quiz" &&
                              item.sectionCount > 0
                              ? "disabled"
                              : item.sectionAttendedByAll
                                ? "disabled"
                                : isAlreadyReleased &&
                                  item.sectionId !== selectedSection[0]?.sectionId
                                  ? "disabled"
                                  : fetchSection?.sections.find(
                                    (data) =>
                                      data.sectionId === selectedSection[0]?.sectionId
                                  )?.sectionCount === 0 && item.sectionCount > 0
                                    ? "disabled"
                                    : "")
                      }
                      style={{
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "0.8rem",
                      }}
                      key={item.sectionId}
                    >
                      <FormControlLabel
                        control={
                          quizType !== "Diagnostic Quiz" ? (
                            !item.teachingCompletedForSection ? (
                              <div
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  border: "2px solid #605ca0",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                }}
                              ></div>
                            ) : item.endTime &&
                              !isPastEndTimeAndDate(
                                item.endTime,
                                item.endDate
                              ) ? (
                              <div
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  border: "2px solid #605ca0",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                }}
                              ></div>
                            ) : quizType === "Practice Quiz" &&
                              item.sectionCount > 0 ? (
                              <div
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  border: "2px solid #605ca0",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                }}
                              ></div>
                            ) : item.sectionAttendedByAll ? (
                              <div
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  border: "2px solid #605ca0",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                }}
                              ></div>
                            ) : isAlreadyReleased &&
                              item.sectionId !==
                              selectedSection[0]?.sectionId ? (
                              <div
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  border: "2px solid #605ca0",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                }}
                              ></div>
                            ) : fetchSection?.sections.find(
                              (data) =>
                                data.sectionId ===
                                selectedSection[0]?.sectionId
                            )?.sectionCount === 0 && item.sectionCount > 0 ? (
                              <div
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  border: "2px solid #605ca0",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                }}
                              ></div>
                            ) : (
                              <div
                                id="az-section"
                                onClick={(e) => {
                                  handleSelectSection(
                                    e,
                                    item.sectionId,
                                    item.sectionCount,
                                    item.section
                                  );
                                }}
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  border: "2px solid #605ca0",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                }}
                              >
                                {selectedSection.filter(
                                  (data) => data.sectionId === item.sectionId
                                ).length > 0 && (
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#605ca0",
                                      }}
                                    >
                                      <DoneIcon
                                        sx={{
                                          color: "#fff",
                                          fontSize: 16,
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            )
                          ) : (
                            quizType == "Diagnostic Quiz" && (
                              <div
                                id="az-section1"
                                onClick={(e) => {
                                  handleSelectSection(
                                    e,
                                    item.sectionId,
                                    item.sectionCount,
                                    item.section
                                  );
                                }}
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  border: "2px solid #605ca0",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                }}
                              >
                                {selectedSection.filter(
                                  (data) => data.sectionId === item.sectionId
                                ).length > 0 && (
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#605ca0",
                                      }}
                                    >
                                      <DoneIcon
                                        sx={{
                                          color: "#fff",
                                          fontSize: 16,
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            )
                          )
                        }
                        label={item.section}
                        sx={{ fontWeight: 600 }}
                      />

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div
                          style={{
                            display: "flex",

                            flex: "row",

                            columnGap: "10px",
                          }}
                        >
                          <div>
                            <p
                              style={{
                                paddingLeft: "15px",
                              }}
                            >
                              Date
                            </p>
                            {
                              console.log((!item.teachingCompletedForSection
                                ? (console.log("Condition 1"), true)
                                : item.endTime && !isPastEndTimeAndDate(item.endTime, item.endDate)
                                  ? (console.log("Condition 2"), true)
                                  : quizType === "Practice Quiz" && item.sectionCount > 0
                                    ? (console.log("Condition 3"), true)
                                    : item.sectionAttendedByAll
                                      ? (console.log("Condition 4"), true)
                                      : isAlreadyReleased && item.sectionId !== selectedSection[0]?.sectionId
                                        ? (console.log("Condition 5"), true)
                                        : fetchSection?.sections.find(
                                          (data) => data.sectionId === selectedSection[0]?.sectionId
                                        )?.sectionCount === 0 &&
                                          item.sectionCount > 0
                                          ? (console.log("Condition 6"), true)
                                          : (console.log("Default Condition"), false)), item)
                            }
                            <DesktopDatePicker
                              disabled={
                                quizType !== "Diagnostic Quiz" &&
                                (!item.teachingCompletedForSection
                                  ? true
                                  : item.endTime &&
                                    !isPastEndTimeAndDate(
                                      item.endTime,
                                      item.endDate
                                    )
                                    ? true
                                    : quizType === "Practice Quiz" &&
                                      item.sectionCount > 0
                                      ? true
                                      : item.sectionAttendedByAll
                                        ? true
                                        : isAlreadyReleased &&
                                          item.sectionId !==
                                          selectedSection[0]?.sectionId
                                          ? true
                                          : fetchSection?.sections.find(
                                            (data) =>
                                              data.sectionId ===
                                              selectedSection[0]?.sectionId
                                          )?.sectionCount === 0 &&
                                            item.sectionCount > 0
                                            ? true
                                            : false)
                              }
                              disableTextInput={true}
                              id={item.sectionId || "az-datepicker-releasemodal"}
                              inputFormat="DD-MM-YYYY"
                              format="DD-MM-YYYY"
                              placeholder="DD-MM-YYYY"
                              minDate={new Date()}
                              value={
                                selectedSection.find(
                                  (data) => data.sectionId === item.sectionId
                                )?.displayDate
                                  ? selectedSection.find(
                                    (data) =>
                                      data.sectionId === item.sectionId
                                  )?.displayDate
                                  : ""
                              }
                              onChange={(e) =>
                                handleDateChange(
                                  e,
                                  item.sectionId,
                                  item.sectionCount,
                                  item.section
                                )
                              }
                              renderInput={(params) => (
                                <div
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <TextField
                                    id="az-date-field"
                                    disableTextInput={true}
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                  />
                                  <div className="mask"></div>
                                </div>
                              )}
                            />
                          </div>

                          <div>
                            <p
                              style={{
                                paddingLeft: "15px",
                              }}
                            >
                              Select Start Time
                            </p>

                            <TimePicker
                              disabled={
                                quizType !== "Diagnostic Quiz" &&
                                (!item.teachingCompletedForSection
                                  ? true
                                  : item.endTime &&
                                    !isPastEndTimeAndDate(
                                      item.endTime,
                                      item.endDate
                                    )
                                    ? true
                                    : quizType === "Practice Quiz" &&
                                      item.sectionCount > 0
                                      ? true
                                      : item.sectionAttendedByAll
                                        ? true
                                        : isAlreadyReleased &&
                                          item.sectionId !==
                                          selectedSection[0]?.sectionId
                                          ? true
                                          : selectedSection.length === 0
                                            ? true
                                            : selectedSection.find(
                                              (data) =>
                                                data.sectionId === item.sectionId
                                            )?.startDate === "" ||
                                              selectedSection.find(
                                                (data) =>
                                                  data.sectionId === item.sectionId
                                              )?.sectionId !== item.sectionId
                                              ? true
                                              : fetchSection?.sections.find(
                                                (data) =>
                                                  data.sectionId ===
                                                  selectedSection[0]?.sectionId
                                              )?.sectionCount === 0 &&
                                                item.sectionCount > 0
                                                ? true
                                                : false)
                              }
                              id={item.sectionId || "az-time-picker"}
                              minTime={getMinTime(item.sectionId)}
                              placeholder="hh:mm am"
                              value={
                                selectedSection.find(
                                  (data) => data.sectionId === item.sectionId
                                )?.displayTime
                                  ? selectedSection.find(
                                    (data) =>
                                      data.sectionId === item.sectionId
                                  )?.displayTime
                                  : ""
                              }
                              // minTime={getCurrentTime()}
                              onChange={(e) =>
                                handleTimeChange(
                                  e,
                                  item.sectionId,
                                  item.sectionCount,
                                  item.section
                                )
                              }
                              renderInput={(params) => (
                                <div
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <TextField
                                    id="az-time-field"
                                    disableTextInput={true}
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                  />
                                  <div className="mask"></div>
                                </div>
                              )}
                              format="h:mm"
                            />
                          </div>

                          <div>
                            <p
                              style={{
                                paddingLeft: "15px",
                              }}
                            >
                              Time
                            </p>

                            <TextField
                              disabled
                              id="az-time-field"
                              value={convertMinsToHours(quizDuraction)}
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: <React.Fragment></React.Fragment>,
                              }}
                            />
                          </div>
                        </div>
                      </LocalizationProvider>

                      <div
                        style={{
                          color: "black",
                          paddingLeft: "5px",
                        }}
                      >
                        No. of release - {item.sectionCount}
                      </div>
                    </Container>
                  );
                })}
              </div>
              <div style={{ textAlign: "end" }}>
                {wrongTime && (
                  <Alert
                    action={
                      <IconButton
                        color="inherit"
                        size="small"
                        onClick={() => {
                          SetWrongTime(false);
                        }}
                      >
                        <CancelOutlinedIcon />
                      </IconButton>
                    }
                    icon={false}
                    sx={{
                      color: "red",
                      fontWeight: 500,
                      fontSize: "0.93vw",
                      width: "80%",
                      float: "right",
                      // background: "#bddabca1",
                      background: "rgba(200,255,200,0.63)",
                    }}
                  >
                    {errorMessage}
                  </Alert>
                )}
              </div>
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  flexDirection: "row",
                  columnGap: "1rem",
                }}
              >
                <NextButton onClick={close} id="az-cancel-btn">Cancel</NextButton>
                {isReady &&
                  (isAlreadyReleased ? (
                    <ExitButton
                      disabled={loading}
                      onClick={() =>
                        OpenReRelease(selectedQuizId)
                        // {
                        //   SetWrongTime(true);
                        //   seterrorMessage("Currently, the quiz release service is unavailable. Please try again later.")
                        // }
                      } id="az-rerelease-btn">
                      Re-Release
                    </ExitButton>
                  ) : (
                    <ExitButton
                      disabled={loading}
                      onClick={handleRelease} id="az-release-btn-rls">Release</ExitButton>
                  ))}
              </div>
            </div>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};
export default ReleaseModal;
