import React, { useState } from "react";
import { HorizontalBar } from "react-chartjs-3";
import "chartjs-plugin-datalabels";
import CustomThermometer from "../../components/common/CustomThermometer";
import GenericChart from "./GenericChart";
const QuizPerformance = ({
  titleColor,
  title,
  thValue,
  thColor,
  barLabel,
  data,
  barColor,
  quizData
}) => {
  // const options = {
  //   scales: {
  //     xAxes: [
  //       {
  //         gridLines: {
  //           display: false,
  //         },
  //         display: false,
  //         scaleLabel: {
  //           display: true,
  //         },
  //         offset: false,
  //         ticks: {
  //           beginAtZero: true,
  //           min: 0,
  //           max: 100,
  //         },
  //       },
  //     ],
  //     yAxes: [
  //       {
  //         gridLines: {
  //           display: false,
  //         },
  //         offset: true,
  //         ticks: {
  //           display: true,
  //           fontSize: 18,
  //           fontColor: "#115d8c",
  //         },
  //         datasets: {
  //           barThickness: 40,
  //         },
  //         barPercentage: barData?.length === 1 ? 0.4 : 0.7,
  //       },
  //     ],
  //   },
  //   plugins: {
  //     datalabels: {
  //       formatter: (value, ctx) => {
  //         let datasets = ctx.chart.data.datasets;
  //         if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
  //           // let sum = datasets[0].data.reduce((a, b) => a + b, 0);
  //           // let percentage = Math.round((value / sum) * 100) + "%";
  //           let percentage = Math.round(value) + "%";
  //           return percentage;
  //         }
  //       },
  //       display: true,
  //       color: "#115d8c",
  //       align: "end",
  //       anchor: "end",
  //       font: { size: "23" },
  //     },
  //   },
  //   legend: {
  //     display: false,
  //   },
  // };
  return (
    <div
      style={{
        border: "1px solid #757575",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <div
        style={{
          fontSize: 20,
          fontWeight: 600,
          color: titleColor,
        }}
      >
        {title}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          columnGap: "1rem",
        }}
      >
        <div
          style={{
            width: "30%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            padding: "20px",
          }}
        >
          <CustomThermometer
            totalValue={thValue}
            color={thColor}
            height={"190"}
            steps={5}
            minValue={0}
            maxValue={100}
            format={"%"}
            mode={"light"}
          />
          <div
            style={{
              color: "rgb(66, 61, 92)",
              fontSize: 15,
              fontWeight: 600,
              textAlign: "start",
            }}
          >
            Unit Quiz Avg.
          </div>
        </div>
        <div style={{ width: "60%", height: "200px" }}>
          {/* <HorizontalBar
            data={{
              labels: barLabel,
              datasets: [
                {
                  label: "# of Votes",
                  data: barData,
                  backgroundColor: barColor,
                  borderColor: barColor,
                  borderWidth: 1,
                },
              ],
            }}
            options={options}
            width={50}
            height={10}
          /> */}
          <GenericChart
            data={data}
            colors={barColor}
          />
        </div>
      </div>
    </div>
  );
};
export default QuizPerformance;
