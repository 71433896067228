async function SchoolControllerGet(
  rowsInput,
  setSchoolListData,
  setPageDetail,
  page,
  boardId,
  search,
  sortBy,
  sortOrder
) {
  let token = sessionStorage.getItem("token");

  let result = await fetch(
    `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools?pageNumber=${page - 1}&pageSize=${rowsInput.rows
    }&sortBy=${sortBy || "id"}&order=${sortOrder || false}&search=${search}&boardId=${boardId}`,
    // "https://13.233.158.31:9001v1/api/user/schools/all",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,

      },
    }
  )
    .then((resp) => {

      return resp.json();
    })

    .then(
      (json) => (
        console.log(json.data.data, sortOrder, sortBy),
        setSchoolListData(json.data.data),
        setPageDetail(json.data)
      )
    )

    .catch((err) => console.log(err));
}

export default SchoolControllerGet;
