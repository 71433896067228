import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  height: 550,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  // rowGap: "1rem",
};

const BoardDetailsModal = ({ open, close, schoolDetails, toplabel }) => {
  const [studentData, setStudentData] = useState([]);
  const [filterValue, setFilterValue] = useState([]);

  useEffect(async () => {
    if (schoolDetails?.length) {
      await setFilterValue(schoolDetails);
      await setStudentData(schoolDetails);
    }
  }, [schoolDetails]);

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: 20, fontWeight: 600, color: "#29313f", marginLeft:"15px" }}>
              List:
            </div>
            <CloseOutlinedIcon
              onClick={close}
              style={{ cursor: "pointer", fontSize: 20, color: "#757575" }}
            />
          </div>
        </div>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            boxShadow: "0px 0px 0px 0px",
          }}
        >
          <TableContainer sx={{ maxHeight: 500, overflow: "auto" }}>
            <Table
              sx={{ minWidth: 650, marginBottom: "90px" }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: 400,
                      color: "blue",
                      borderBottom:"white"
                    }}
                  >
                    School Name
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 400,
                      color: "blue",
                      borderBottom:"white"
                    }}
                  >
                    School code
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 400,
                      borderBottom:"white",
                      color: "blue",
                    }}
                  >
                    POC mail
                  </TableCell>

                  <TableCell
                    style={{
                      fontWeight: 400,
                      color: "blue",
                      borderBottom:"white"
                    }}
                  >
                    School Locality
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 400,
                      color: "blue",
                      borderBottom:"white"
                    }}
                  >
                    Website
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterValue?.length > 0 &&
                  filterValue.map((row, index) => {
                    return (
                      <TableRow
                        key={row?.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell style={{borderBottom:"white"}} component="th" scope="row">
                          {row?.name}
                        </TableCell>
                        <TableCell  style={{borderBottom:"white"}} component="th" scope="row">
                          {row?.code}
                        </TableCell>
                        <TableCell style={{borderBottom:"white"}} component="th" scope="row">
                          {row?.pocEmail}
                        </TableCell>
                        <TableCell style={{borderBottom:"white"}} component="th" scope="row">
                          {row?.locality}
                        </TableCell>
                        <TableCell style={{borderBottom:"white"}} component="th" scope="row">
                          {row?.website}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Modal>
  );
};
export default BoardDetailsModal;
