import React from "react";
import styled from "styled-components";
import ChapterCard from "./ChapterCard";
import InfiniteScroll from "react-infinite-scroll-component";

function ChaperView({ chapters, loadMore, isNextPage, quizType }) {
	const loader = () => {
		return <div>Loading</div>;
	};
	return (
		<Cover id="scrollableDiv">
			<InfiniteScroll
				dataLength={chapters.length}
				// loader={loader}
				next={loadMore}
				hasMore={isNextPage}
				scrollableTarget="scrollableDiv"
			>
				<Container>
					{chapters.map((data, i) => (
						<ChapterCard
							key={data.id}
							data={data}
							quizType={quizType}
						/>
					))}
				</Container>
			</InfiniteScroll>
		</Cover>
	);
}

export default ChaperView;
const Cover = styled.div`
	height: calc(100vh - 260px);
	background-color: #fff;
	border-radius: 10px;
	padding: 20px;
	margin-top: 10px;
	overflow: scroll;
`;
const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
`;
