import React, { useEffect, useState } from 'react'
import CircleProgress from '../../../../components/CircleProgress/CircleProgress'
import './dashboard.css'
import { fetchQuizzesTotal } from '../Apis/Dashboard_Api'
import { extractTextAfterExclamation, extractTextIncludingExclamation } from '../../../../utils/helper'

const CardV1 = () => {

    const [data, setData] = useState()
    const fetchData = async () => {
        const response = await fetchQuizzesTotal()
        setData(response?.data)
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className="dashboard-top-section-card ">
            <div className="dcard-left-side">
                <CircleProgress height={90} width={90} value={data?.attemptedPercentage || 0} text={`${data?.attemptedPercentage || 0}%`} color={'#132CE6'} />
                <p className="dashboard-card-count">
                    {`${data?.uqAttempted || 0}/${data?.totalUqReleased || 0}`} Quiz taken
                </p>
            </div>
            <div className="dcard-right-side">
                <p className="dcard-title">
                    {extractTextIncludingExclamation(data?.message)}
                </p>
                <p className="dcard-body">
                {extractTextAfterExclamation(data?.message)}
                </p>
            </div>
        </div>
    )
}

export default CardV1