export const SELECTED_ASSIGN_QUIZ_PARAMS = "SELECTED_ASSIGN_QUIZ_PARAMS";
export const SET_SELECTED_ASSIGN_QUIZ_PARAMS = "SET_SELECTED_ASSIGN_QUIZ_PARAMS";
export const CLEAR_ASSIGN_QUIZ_PARAMS = "CLEAR_ASSIGN_QUIZ_PARAMS";


export const getSelectedAssignQuizParams = (payload) => {
    return{
        type: SELECTED_ASSIGN_QUIZ_PARAMS,
        payload: payload
    }
}

export const setSelectedAssignQuizParams  = (payload) => {
    return{
        type: SET_SELECTED_ASSIGN_QUIZ_PARAMS,
        payload: payload
    }
}

export const removeSelectedAssignQuizParams = (payload) => {
    return{
        type: CLEAR_ASSIGN_QUIZ_PARAMS,
        payload: payload
    }
}