import axios from 'axios'

    async function chapter_file_uploader_post(
        formData, 
        setSelectedImage, 
        setChapterInputs, 
        chapterInputs) {
        let token = sessionStorage.getItem("token")

        let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=STUDY_MATERIALS_SERVICE&subFolders=COMMON_CONTENT`, formData,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                setSelectedImage(res.data.data)
                sessionStorage.setItem("logoUrl", res.data.data)
                setChapterInputs({
                    ...chapterInputs,
                    logoUrl: res.data.data,
                })
            })
    }
    export default chapter_file_uploader_post