import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Css
import navStyles from "./../css/navbar.module.css";
import "../../../src/pages/css/Student/Student_SideNavbar.css";

// Images
import dashHome from "../../../src/img/dashHome.svg";
import admin from "../../../src/img/Adminuser.svg";
import add_training from "../../../src/img/add_training.svg";
import teachIc from "../../../src/img/teach.svg";
import reportIc from "../../../src/img/Reports.svg";
import learningIcn from "../../../src/img/Learning.png";
import assignIcn from "../../../src/img/Masters.png";
import upSkillsIcn from "../../../src/img/Upskill.png";
import StudentIcn from "../../../src/img/Student report.png";
import {
  Avatar,
  Badge,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  ListSubheader
} from "@mui/material";

function TeacherNew_SideNavbar({
  elem,
  i,
  schoolSublinkVisible,
  navBarVis,
  handleLinkToggle,
}) {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [subMenuNameValue, setSubMenuNameValue] = useState("");

  let menuName = sessionStorage.getItem("sideNavMainMenuNames") ;
  let subNavMenuName = sessionStorage.getItem("sideNavSubMenuNames");
  // console.log('Teacher side menu >> ', menuName, subNavMenuName);

  const handleHighlight = (clicked) => {
    if (clicked === 'Reports' && !navBarVis) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
    sessionStorage.setItem("sideNavMainMenuNames", clicked);
    sessionStorage.removeItem("sideNavSubMenuNames");
  };

  const handleHighlightSubMenu = (clicked) => {
    setShowPopup(false);
    sessionStorage.setItem("sideNavSubMenuNames", clicked);
    // sessionStorage.setItem("sideNavMainMenuNames", "Reports");
  };

  const handleClose = (reportValue) => {
    setSubMenuNameValue(reportValue);
    setShowPopup(false);
    if (reportValue == "Progress Report") {
      navigate(`/dashboard/Progress Report`);
    } else if (reportValue !== "Formal Assessment") {
      navigate(`/dashboard/${encodeURIComponent(reportValue)}`);
    } else {
      navigate(`/dashboard/${encodeURIComponent("Formative Assessment")}`);
    }

  };

  // Rendering the Menu Icons
  const menuIcons = {
    Dashboard: dashHome,
    Teach: teachIc,
    AssignQuiz: assignIcn,
    FormalAssessment: reportIc, // Import and Change the Icon Here
    StudentRevision: StudentIcn,
    LearningPlan: learningIcn,
    Upskills: upSkillsIcn,
    Reports: reportIc,
  };
  const defaultIcon = admin;
  const selectedIcon = menuIcons[elem.menu.replace(/ /g, "")] || defaultIcon;

  return (
    <>
      <div>
        {/*-------| Side Navbar Menu Names & Navigation Starts |-------*/}
        {elem.menu === "Reports" || elem.menu === "Reading Passport" ? (
          <div
            key={elem.menuId}
            className="sidenav_parent"
            onClick={elem.subMenus && handleLinkToggle}
          >
            <div
              id={`az-teacher-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
              className={`icon_and_text cursorPointer ${menuName === elem.menu ? "" : "icon_and_text_active"
                }`}
              onClick={() => handleHighlight(elem.menu)}
            >
              {/*-------| Side Navbar Icons|-------*/}
              <div
                className={`sidenav_icons ${menuName === elem.menu ? "sidenav_icons_active" : ""
                  }`}
              >
                <img src={selectedIcon} alt="no img" />
              </div>

              {elem.subMenus ? (
                <div className={menuName === elem.menu ? "sidenav_text_selected" : "sidenav_text"}>{elem.menu}</div>
              ) : (
                <div>
                  <div className={menuName === elem.menu ? "sidenav_text_selected" : "sidenav_text"}>{elem.menu}</div>
                </div>
              )}

              {/*-------| Side Navbar "Reports" Dropdown Arrow Icon|-------*/}
              <div className="report_arrow_icon">
                <i
                  className={
                    schoolSublinkVisible && navBarVis && elem.menu === sessionStorage.getItem("sideNavMainMenuNames")
                      ? "fa-solid fa-angle-down"
                      : "fa-solid fa-angle-right"
                  }
                  style={
                    menuName === elem.menu ? { color: "#ffffff" } : { color: "#1b1b1b" }
                  }
                  id="toggle_btn_icon"
                ></i>
              </div>
            </div>
          </div>
        ) : (
          <Link
            style={{ textDecoration: "none" }}
            id={`az-teacher-sidemenu-${elem.menu.toLowerCase().replace(/ /g, "_")}`}
            to={`/dashboard/${elem.menu.toLowerCase() == "dashboard"
              ? "TeacherDashboard"
              : elem.menu.toLowerCase()
              }`}
          >
            <div
              key={elem.menuId}
              className="sidenav_parent"
              onClick={elem.subMenus && handleLinkToggle}
            >
              <div
                id="side_nav_menu_items"
                className={`icon_and_text ${(menuName === elem.menu) ? "" : "icon_and_text_active"
                  }`}
                onClick={() => handleHighlight(elem.menu)}
              >
                {/*-------| Side Navbar Icons|-------*/}
                <div
                  className={`sidenav_icons ${menuName === elem.menu ? "sidenav_icons_active" : ""
                    }`}
                >
                  <img src={selectedIcon} alt="no img" />
                </div>
                {elem.subMenus ? (
                  <div className={
                    menuName === elem.menu ? "sidenav_text_selected" : "sidenav_text"
                  }>{elem.menu}</div>
                ) : (
                  <div className={
                    menuName === elem.menu ? "sidenav_text_selected" : "sidenav_text"
                  }>{elem.menu}</div>
                )}
              </div>
            </div>
          </Link>
        )}
        {/*-------| Side Navbar Menu Names & Navigation Ends |-------*/}

        {/*-------| Side Navbar "Reports" Dropdown Menu Names & Navigation Starts |-------*/}
        <div
          key={i}
          style={
            schoolSublinkVisible && elem.menu === sessionStorage.getItem("sideNavMainMenuNames") ? { display: "block" } : { display: "none" }
          }
        >
          <div>
            {elem.subMenus &&
              elem.subMenus.map((item) => {
                return (
                  <div
                    id="registered_schools_link"
                    key={item.subMenuId}
                    style={
                      navBarVis ? { display: "block" } : { display: "none" }
                    }
                  >
                    <div>
                      <Link
                        id={`az-teacher-sidemenu-${item.subMenuName.toLowerCase().replace(/ /g, "_")}`}
                        style={{ textDecoration: "none" }}
                        to={
                          item.subMenuName === "Chapter Performance"
                            ? "Chapter%20Performance"
                            : item.subMenuName === "Student Report"
                              ? "Student%20Report"
                              : item.subMenuName === "Formative Assessment"
                                ? "Formative%20Assessment"
                                : item.subMenuName === "Progress Report"
                                  ? "Progress%20Report"
                                  : item.subMenuName === "Performance Report"
                                    ? "passport%20performance"
                                    : item.subMenuName === "Monthly Avid Reader"
                                      ? "avid%20reader"
                                      : item.subMenuName === "Reading Passport List"
                                        ? "reading%20passport"
                                        : "Formative%20Assessment"
                        }
                      >
                        <div
                          key={item.subMenuId}
                          // className={`reports ${subNavMenuName === item.subMenuName
                          //   ? "" && "activeSubMenu"
                          //   : ""
                          //   }`}
                          className={`reports ${subNavMenuName === item.subMenuName ? "activeSubMenu" : ""}`}
                          onClick={() => handleHighlightSubMenu(item.subMenuName)}
                        >
                          {/*-------| "Reports" Icons Starts |-------*/}
                          <div className="reports_icons">
                            {item.subMenuName === "Chapter Performance" ? (
                              <span
                                className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                  ? "subMenuIcon_active"
                                  : ""
                                  }`}
                              ></span>
                            ) : item.subMenuName === "Student Report" ? (
                              <span
                                className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                  ? "subMenuIcon_active"
                                  : ""
                                  }`}
                              ></span>
                            ) : item.subMenuName === "Formal Assessment" ? (
                              <span
                                className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                  ? "subMenuIcon_active"
                                  : ""
                                  }`}
                              ></span>
                            ) : item.subMenuName === "Progress Report" ? (
                              <span
                                className={`subMenuIcon ${subNavMenuName === item.subMenuName
                                  ? "subMenuIcon_active"
                                  : ""
                                  }`}
                              ></span>
                            ) : (
                              ""
                            )}
                          </div>
                          {/*-------| "Reports" Icons Ends |-------*/}

                          <div className={
                            subNavMenuName === item.subMenuName
                              ? "reports_text_selected"
                              : "reports_text"
                          }>{item.subMenuName === 'Formative Assessment' ? 'Formal Assessment' : item.subMenuName}</div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {/*-------| Side Navbar "Reports" Dropdown Menu Names & Navigation Ends |-------*/}
        {showPopup && (
          <Menu
            id="basic-menu"
            open={showPopup}
            onClose={() => setShowPopup(false)}
            transformOrigin={{ horizontal: "left", vertical: "center" }}
            anchorOrigin={{ horizontal: "left", vertical: "center" }}
            PaperProps={{
              style: {
                borderRadius: "20px", // Adjust the border radius as needed
                paddingTop: "0px"
              },
            }}
          >

            <ListSubheader
              sx={{
                typography: 'h8',
                color: "#1b1b1b",
                background: "#ffa500"
              }}>Report</ListSubheader>

            {elem.subMenus &&
              elem.subMenus.map((item) => (
                <MenuItem
                  key={item.subMenuId}
                  onClick={() => handleClose(item.subMenuName)}
                >


                  <ListItemText sx={{ color: subMenuNameValue === item.subMenuName ? "#ffa500" : "#000000", }}>
                    {item.subMenuName === 'Formal Report'
                      ? 'Formal Assessment Report'
                      : item.subMenuName}
                  </ListItemText>
                </MenuItem>
              ))}
          </Menu>
        )}
      </div>
    </>
  );
}

export default TeacherNew_SideNavbar;