import { Box, Modal, Typography } from "@mui/material";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import YellowBorderHover from "../../components/common/YellowBorderHover";
import YellowButton from "../../components/common/yellowButton";
import { Link, useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};
const ProfileUpdateModal = ({ open, close, names, successMessage }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReportGmailerrorredOutlinedIcon
              style={{ color: "#FFA700", fontSize: 120 }}
            />
          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {successMessage ==
              "Added all assigned subjects for teacher successfully" ? (
                <p>Profile Updated Successfully</p>
              ) : successMessage ==
                  "section, subTopic already assign to particular teacher" ||
                "section already assign to particular teacher" ? (
                <p>Access is Already Provided for this Staff.</p>
              ) : (
                ""
              )}
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#FFA700",
                  padding: "4px",
                }}
              >
                {names}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  columnGap: "1rem",
                }}
              >
                <YellowButton onClick={close}>OK</YellowButton>
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default ProfileUpdateModal;
