import axios from "axios";
import { isNOTNullOrUndefined } from "../../TeachValidate";
import { grey } from "@mui/material/colors";

export const getBoardsList = async () => {
    const token = sessionStorage.getItem("token");
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/applicable-boards`;
    try {
        const res = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          return  res?.data?.data|| [];
    } catch (error) {
        console.log(error,'error fetching')
        return []
    }
  };

  export const getSinglePlanDetails = async (selectedBoard,selectedPlan) => {
    const token = sessionStorage.getItem("token");

    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/template-screen?boardId=${selectedBoard}${isNOTNullOrUndefined(selectedPlan) ? `&planId=${selectedPlan}`: ''}`;
    try {
        const res = await axios.get(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          return  res?.data?.data|| [];
    } catch (error) {
        alert(JSON.stringify(error.response.data?.message))
        console.log(error,'error fetching')
        return []
    }
  };

//   v1/api/master/plans/template-screen?boardId=${selectedBoard?.id}${isNOTNullOrUndefined(selectedPlan?.id) ? `&planId=${selectedPlan.id}`: ''}`

export const postTemplateData = async (isEdit,submitionData) => {
    const token = sessionStorage.getItem("token");
    let message = ""
    await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans`,
        data: submitionData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => {
          message = `Plan template ${isEdit ? 'updated': 'created'} successfully `
        
        })
        .catch(err => {
          console.log(err, 'error')
          message = "Some error occured please try again later"
        })
        return message
  };

