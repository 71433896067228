import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const YellowButton = (props) => {
    const SaveButton = styled(Button)(() => ({
        color: "#fff",
        width: "100px",
        height: "32px",
        backgroundColor: "#FFA700",
        borderRadius: 150,
        margin:'3px 8px',
        padding:'7px',
        "&:hover": {
            color: "#fff",
            backgroundColor: "#FFA700",
            border: 0,
            top: -2,
            boxShadow: "0px 3px 5px #FFA700",
        },
    }));
    return <SaveButton className={props.className} onClick={props.onClick}>{props.children}</SaveButton>;
};
export default YellowButton;
