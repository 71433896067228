import React, { useRef } from 'react';
import './HorizontalScrollContainer.css'; // Create a CSS file for styling
import scroll_btn from '../../img/scroll_btn.png'
import { useIsOverflow } from '../../hooks/quiz/useIsOveflow';
import { useShowButtonsBasedOnItems } from '../../hooks/useShowButtonsBasedOnItems';
function HorizontalScrollContainerV1({ children,numberOfItems }) {
  const containerRefV1 = useRef(null);
  // const { showPrevButton, showNextButton } = useShowScrollButtons(containerRefV1);
  // const isOverflow = useIsOverflow(containerRefV1);
  const isOverflow = useIsOverflow(containerRefV1, null, 100); // Delay of 100ms before calculating isOverflow
  const showButtons = useShowButtonsBasedOnItems(numberOfItems);


  const handleScrollLeft = () => {
    if (containerRefV1.current) {
      containerRefV1.current.scrollLeft -= 100; // Adjust the scroll distance as needed
    }
  };

  const handleScrollRight = () => {
    if (containerRefV1.current) {
      containerRefV1.current.scrollLeft += 100; // Adjust the scroll distance as needed
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {showButtons ? <button className="scroll-button scrl-btn-left" onClick={handleScrollLeft}>
        {/* &lt; */}
        <img src={scroll_btn} style={{ display: 'block', width: '100%' }} />
      </button> : null}
      {showButtons ? <button className="scroll-button scrl-btn-right" onClick={handleScrollRight}>
        {/* &gt; */}
        <img src={scroll_btn} style={{ display: 'block', width: '100%' }} />
      </button> : null}
      <div className="horizontal-scroll-container">
        <div className="scroll-content" ref={containerRefV1}>
          {children}
        </div>
      </div>
    </div>


  );
}

export default HorizontalScrollContainerV1;
