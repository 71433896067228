import React, { useEffect, useState } from 'react'
// import dashboardContentStyles from "../../css/dashboardContent.module.css";
import dashboardContentStyles from "../../pages/css/dashboardContent.module.css";
// import dashHome from "../../../../src/img/dashHome.svg";
import dashHome from "../../img/dashHome.svg";
import GetLastModifiedAt from '../../pages/API/school-controller/GetLastModifiedAt';

const DashboardLayer = ({children}) => {
    const role = localStorage.getItem("role");
    const userName = localStorage.getItem("userName");
  const [modifiedDate, setModifiedDate] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      GetLastModifiedAt(setModifiedDate);
    };

    fetchData();
  }, [userName, role]);

  return (
    <div  style={{background:'#ffffff',height:'120vh',overflowY:'auto',overflowX:'hidden', scrollbarWidth:"thin"}}>
    <article>
      <div   style={{position:'relative',margin:'14px'}}>
        <span className={dashboardContentStyles.link_icon}>
          <img
            src={dashHome}
            alt="no img"
            className={dashboardContentStyles.az_menu_icon}
            // width="24px"
            // height="24px"
            // color='#828282'
            // style={{ verticalAlign: "middle" }}
          />
        </span>
        <span
         style={{ cursor: 'pointer' }}
        className={dashboardContentStyles.az_menu_text}
        >
          Home
        </span>
        {/* <span
        className={dashboardContentStyles.az_double_arrow}
        >
         ⨠
        </span> */}
        <span>
              <i className="fa-solid fa-angle-right"
              style={{color:"#828282"}} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right" 
              style={{color:"#828282"}}/>
            </span>
        <span
        className={dashboardContentStyles.az_selected_menu_text}
        >
          Dashboard
        </span>
      </div>
      {/* <div className={dashboardContentStyles.dashboard_last_updated}>
        <p>Last Update: {modifiedDate?.data}</p>
      </div> */}
    </article>
    {children}
    </div>
  )
}

export default DashboardLayer