import React from "react";
import updatepas from "./../css/updatepass.module.css";

const UpdatePass = () => {
  const handleLogin = (e) => {
    e.preventDefault();
    window.location = "/login";
  };
  return (
    <>
      <body>
        <div class={updatepas.main_container}>
          <div class={updatepas.main}>
            <div class={updatepas.navbar}>
              <div class={updatepas.icon}>
                <h2 class={updatepas.logo}>
                  <img src="/img/Azvasa_logo.png" />
                </h2>
              </div>
            </div>
            <div class={updatepas.content}>
              <img src="/img/pass.png" class={updatepas.login_img} />
              <form class={updatepas.updatePassword_login_form}>
                <div class={updatepas.login_form_title}>
                  <h2>Password Updated</h2>
                  <h3>Your Password has been updated successfully</h3>
                  <h3>Use your new Password for log in.</h3>
                </div>
                <div class={updatepas.login_container}>
                  <button class={updatepas.up_login_btn} onClick={handleLogin}>
                    <a href="login">Login</a>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default UpdatePass;
