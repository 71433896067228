import React from 'react'

const CustomBar = ({width,percent,color,marginRight}) => {
  return (
    <div style={{width:width,height:`${percent}%` ,background:color,marginRight:marginRight? marginRight:0,position:"relative"}} >
        <p style={{position:'absolute',top:'-15px',left:0,right:0,fontSize:'10px',color:'#060606'}}>{Math.round(percent)}%</p>
    </div>
  )
}

export default CustomBar