import {styled} from "@mui/material/styles";
import {Button} from "@mui/material";

const YellowBorderHover = (props) =>{
  const CancelButton = styled(Button)(({ theme }) => ({
    // color: theme.palette.getContrastText(grey[500]),
    color: "#757575",
    width: 150,
    backgroundColor: "#fff",
    borderRadius: 150,
    border: "1px solid #757575",
    borderColor: "#757575",
    "&:hover": {
      color: "#FFA700",
      border: "1px solid #FFA700",
      borderColor: "#FFA700",
      top: -2,
    },
  }));
  return<CancelButton onClick={props.onClick}>{props.children}</CancelButton>;
}
export default YellowBorderHover;