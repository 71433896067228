import axios from 'axios'

async function SchoolControllerWithBranchesGet(setSchoolList, setBranchList) {
  let token = sessionStorage.getItem("token");
  let schoolname = "Silverleaf School";
  await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/all/withBranches`,
  {
   headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
  .then((resp)=>{
    let TempData = []
    resp.data.data.forEach((parent) => {
      let childData = []
      parent.branches.forEach((child) => {
        childData.push({
          title : child.branch,
          value: child.branchId,
          key: child.branchId,
          active: child.active
        })
      })
      TempData.push({
        title: parent.schoolName,
        value : parent.schoolId,
        key : parent.schoolId,
        children : childData
      })
    })
    setSchoolList(TempData)
    // setSchoolList(resp.data.data)
    // setBranchList(resp.data.data)
  })
  .catch(err=>console.log(err))
  
}

export default SchoolControllerWithBranchesGet;
