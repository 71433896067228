import React from "react";
import styled from "styled-components";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

function RejectConfirmModal({
	isOpen,
	setRejectModal,
	selectedQuiz,
	setSelectedQuiz,
	setCommentModal,
}) {
	const closeModal = () => {
		setRejectModal((prev) => !prev);
		setSelectedQuiz({});
	};

	const confirmReject = () => {
		setCommentModal(true);
		setRejectModal(false);
	};
	return (
		<ModalOverlay isOpen={isOpen}>
			<ModalWrapper>
				<Icon>
					<WarningAmberRoundedIcon />
				</Icon>
				<Label>You want to Reject Quiz?</Label>
				<QuizName>"{selectedQuiz.name}"</QuizName>
				<ButtonContainer>
					<Cancel onClick={closeModal}>Cancel</Cancel>
					<RejectButton onClick={confirmReject}>Reject</RejectButton>
				</ButtonContainer>
			</ModalWrapper>
		</ModalOverlay>
	);
}

export default RejectConfirmModal;
const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: ${(props) => (props.isOpen ? "block" : "none")};
	z-index: 100;
`;

const ModalWrapper = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	width: 70%;
	max-width: 600px;
`;
const Icon = styled.span`
	display: block;
	color: #fc8c00;
	text-align: center;
	svg {
		font-size: 120px;
	}
`;
const Label = styled.p`
	font-size: 20px;
	text-align: center;
	font-weight: 300;
`;
const QuizName = styled.p`
	font-size: 22px;
	color: #fc8c00;
	text-align: center;
	margin-bottom: 20px;
`;
const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	gap: 20px;
`;
const Cancel = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 40px;
	border: 1px solid #7a7a7a;
	border-radius: 20px;
	color: #7a7a7a;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
const RejectButton = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 40px;
	border: 1px solid #fc8c00;
	border-radius: 20px;
	background-color: #fc8c00;
	color: #fff;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
