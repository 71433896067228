import React from "react";
import { Box, Modal } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};
const ViewImage = ({ open, close, url }) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            position: "absolute",
            left: "360px",
            cursor: "pointer",
          }}
          onClick={close}
        >
          <CloseOutlinedIcon style={{ fontSize: 20, color: "#686464" }} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={url} alt={url} width={300} />
        </div>
      </Box>
    </Modal>
  );
};
export default ViewImage;
