// axiosConfig.js
import axios from 'axios';
import { useState, useEffect } from 'react';

const useAxiosLoader = () => {
    const [loading, setLoading] = useState(false);
    let isIgnorePath = ['v1/api/content/inbox','v1/api/master/roles/all','v1/api/content/taxonomy-categories/all','v1/api/master/subjects/all',
        'v1/api/content/blueprint-level','v1/api/file/entity/upload-entity-json-s3?entityName=contentdata','v1/api/content/quiz/page?pageNumber=0&pageSize=20&sortOrder=true&sortBy=id&active=true&reviewStatus=PENDING',/v1\/api\/file\/entity\/upload-quizById-json-s3\?quizId=.+/]

    const shouldIgnore = (url) => {
        // return isIgnorePath.some(ignorePath => url.includes(ignorePath));
        return isIgnorePath.some(ignorePath => {
            if (typeof ignorePath === 'string') {
                return url.includes(ignorePath);
            } else if (ignorePath instanceof RegExp) {
                return ignorePath.test(url);
            }
            return false;
        });
    };

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use((config) => {
            // console.log(config.url, 'config.url')
            if (shouldIgnore(config.url)) {
                setLoading(false);
            } else {
                setLoading(true);
            }
            return config;
        }, (error) => {
            setLoading(false);
            return Promise.reject(error);
        });

        const responseInterceptor = axios.interceptors.response.use((response) => {
            setLoading(false);
            return response;
        }, (error) => {
            setLoading(false);
            return Promise.reject(error);
        });

        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, []);

    return loading;
};

export default useAxiosLoader;
