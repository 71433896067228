import React from "react";
import { Box, MenuItem, Modal, TextField } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import dashboardContentStyles from "../css/dashboardContent.module.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
};
const ViewStudentProfile = ({ open, close, studentDetail }) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            background: "rgb(96 91 161 / 81%)",
            borderTopLeftRadius: "9px",
            borderTopRightRadius: "9px",
            padding: "20px",
            color: "white",
            fontSize: "17px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>Profile</div>
          <CloseRoundedIcon
            style={{
              fontSize: 30,
              position: "absolute",
              right: 10,
              top: 10,
              cursor: "pointer",
            }}
            onClick={close}
          />
        </div>
        <div
          style={{
            padding: "20px 30px",
            borderBottomRightRadius: "9px",
            borderBottomLeftRadius: "9px",
            display: "flex",
            flexDirection: "column",
            rowGap: "2rem",
          }}
        >
          <div />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              columnGap: "1rem",
              height: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                rowGap: "1.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%" }}>First Name</div>
                <TextField
                  name={"firstName"}
                  sx={{ width: "100%" }}
                  value={studentDetail?.firstName || ""}
                  disabled
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%" }}>Email Id</div>
                <TextField
                  name={"email"}
                  sx={{ width: "100%" }}
                  value={studentDetail?.email || ""}
                  disabled
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%" }}>Date of Birth</div>
                <TextField
                  type={"date"}
                  sx={{ width: "100%" }}
                  value={studentDetail.dob || ""}
                  disabled
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%" }}>Mother Tounge</div>
                <TextField
                  name={"Tounge"}
                  sx={{ width: "100%" }}
                  value={studentDetail?.firstLanguage || ""}
                  disabled
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%" }}>Admission Date</div>
                <TextField
                  type={"date"}
                  sx={{ width: "100%" }}
                  value={studentDetail?.admissionDate || ""}
                  disabled
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    height: "100%",
                    textAlign: "start",
                  }}
                >
                  Address
                </div>
                <TextField
                  name={"address"}
                  sx={{ width: "100%" }}
                  id="standard-textarea"
                  multiline
                  rows={3}
                  value={studentDetail?.address || ""}
                  disabled
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                rowGap: "1.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%" }}>Last Name</div>
                <TextField
                  name={"lastName"}
                  sx={{ width: "100%" }}
                  value={studentDetail?.lastName || ""}
                  disabled
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%" }}>Mobile No</div>
                <TextField
                  name={"phoneNo"}
                  className={dashboardContentStyles.phone}
                  sx={{ width: "100%", borderTopLeftRadius: 0 }}
                  value={studentDetail?.mobile || ""}
                  disabled
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%" }}>Gender</div>
                <TextField
                  name={"gender"}
                  sx={{ width: "100%" }}
                  value={studentDetail?.gender || ""}
                  select
                  // label="Select"
                  disabled
                >
                  <MenuItem value={"MALE"}>Male</MenuItem>
                  <MenuItem value={"FEMALE"}>Female</MenuItem>
                  <MenuItem value={"OTHER"}>Other</MenuItem>
                </TextField>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%" }}>Grade Section</div>
                <TextField
                  name={"section"}
                  className={dashboardContentStyles.phone}
                  sx={{ width: "100%", borderTopLeftRadius: 0 }}
                  value={studentDetail?.grade || ""}
                  disabled
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "50%" }}>2nd Language</div>
                <TextField
                  name={"language"}
                  className={dashboardContentStyles.phone}
                  sx={{ width: "100%", borderTopLeftRadius: 0 }}
                  value={studentDetail?.secondLanguage || ""}
                  disabled
                />
              </div>
            </div>
          </div>
          <div />
        </div>
      </Box>
    </Modal>
  );
};
export default ViewStudentProfile;
