const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const roleListData = [
  {
    id: 1,
    role: "Teacher",
  },
  { id: 2, role: "Student" },
];
export const documentCategoryData = [
  {
    id: 1,
    documentCategory: "Study Book 1",
    role: "Teacher",
    shortName: "-",
    active: true,
  },
  {
    id: 2,
    documentCategory: "Study Book 2",
    role: "Teacher",
    shortName: "-",
    active: true,
  },
  {
    id: 3,
    documentCategory: "Study Book 3",
    role: "Teacher",
    shortName: "-",
    active: true,
  },
  {
    id: 4,
    documentCategory: "Study Book 4",
    role: "Teacher",
    shortName: "-",
    active: true,
  },
  {
    id: 5,
    documentCategory: "Study Book 5",
    role: "Student",
    shortName: "-",
    active: true,
  },
  {
    id: 6,
    documentCategory: "Study Book 6",
    role: "Teacher",
    shortName: "-",
    active: true,
  },
  {
    id: 7,
    documentCategory: "Study Book 7",
    role: "Student",
    shortName: "-",
    active: true,
  },
  {
    id: 8,
    documentCategory: "Study Book 8",
    role: "Teacher",
    shortName: "-",
    active: true,
  },
];
export const planColumnData = [
  {
    id: 2,
    label: "Lesson Plan",
    minWidth: 100,
    data: "teacher",
    dataIndex: "lessonPlan",
    align: "center",
    checked:false
  },
  {
    id: 3,
    label: "Revision Module",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
    data: "teacher",
    dataIndex: "revisionModule",
 checked:false, },
  {
    id: 4,
    label: "RM lock/unlock visible",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
    data: "teacher",
    dataIndex: "rmVisible",
 checked:false, },
  {
    id: 5,
    label: "Assessment module",
    minWidth: 100,
    align: "center",
    data: "teacher",
    dataIndex: "assessmentModule",
 checked:false, },
  {
    id: 6,
    label: "Activities",
    minWidth: 100,
    align: "center",
    data: "teacher",
    dataIndex: "activities",
 checked:false, },
  {
    id: 7,
    label: "WS Download",
    minWidth: 100,
    align: "center",
    data: "teacher",
    dataIndex: "wsDownload",
 checked:false, },
  {
    id: 8,
    label: "WB Download",
    minWidth: 100,
    align: "center",
    data: "teacher",
    dataIndex: "wbDownload",
 checked:false, },
  {
    id: 9,
    label: "News & stories",
    minWidth: 100,
    align: "center",
    data: "teacher",
    dataIndex: "stories",
 checked:false, },
  {
    id: 10,
    label: "Student booklet",
    minWidth: 100,
    align: "center",
    data: "student",
    dataIndex: "studentBooklet",
 checked:false, },
  {
    id: 11,
    label: "Revision Module",
    minWidth: 100,
    align: "center",
    data: "student",
    dataIndex: "Revision",
 checked:false, },
];
export const coordinatorColumn = [
  {
    id: 1,
    dataIndex: "#",
    align: "left",
  },
  {
    id: 2,
    dataIndex: "Coordinator Type",
    align: "left",
  },
  {
    id: 3,
    dataIndex: "Grade",
    align: "left",
  },
  {
    id: 4,
    dataIndex: "Active",
    align: "right",
  },
  {
    id: 5,
    dataIndex: "Action",
    align: "right",
    padding: 60,
  },
];
export const subjectAndSubtopicColumn = [
  {
    id: 1,
    dataIndex: "#",
    align: "left",
  },
  {
    id: 2,
    dataIndex: "Subtopics",
    align: "left",
  },
  {
    id: 3,
    dataIndex: "Subject",
    align: "left",
  },
  {
    id: 4,
    dataIndex: "Skilled Subject",
    align: "right",
  },
  {
    id: 5,
    dataIndex: "Active",
    align: "right",
  },
  {
    id: 6,
    dataIndex: "Action",
    align: "right",
    padding: 60,
  }
];
export const taxonomyCategoryData = [
  {
    id: 1,
    category: "Easy",
    active: true,
  },
  {
    id: 2,
    category: "Medium",
    active: true,
  },
  {
    id: 3,
    category: "Difficult",
    active: true,
  },
  {
    id: 4,
    category: "Easy",
    active: true,
  },
  {
    id: 5,
    category: "Medium",
    active: true,
  },
  {
    id: 6,
    category: "Difficult",
    active: true,
  },
];
export const startingYearData = [
  {
    id: 1,
    year: 2000,
  },
  {
    id: 2,
    year: 2001,
  },
  {
    id: 3,
    year: 2002,
  },
  {
    id: 4,
    year: 2003,
  },
  {
    id: 5,
    year: 2004,
  },
  {
    id: 6,
    year: 2005,
  },
  {
    id: 7,
    year: 2006,
  },
  {
    id: 8,
    year: 2007,
  },
  {
    id: 9,
    year: 2008,
  },
  {
    id: 10,
    year: 2009,
  },
  {
    id: 11,
    year: 2010,
  },
  {
    id: 12,
    year: 2011,
  },
  {
    id: 13,
    year: 2012,
  },
  {
    id: 14,
    year: 2013,
  },
  {
    id: 15,
    year: 2014,
  },
  {
    id: 16,
    year: 2015,
  },
  {
    id: 17,
    year: 2016,
  },
  {
    id: 18,
    year: 2017,
  },
  {
    id: 19,
    year: 2018,
  },
  {
    id: 20,
    year: 2019,
  },
  {
    id: 21,
    year: 2020,
  },
  {
    id: 22,
    year: 2021,
  },
  {
    id: 23,
    year: 2022,
  },
  {
    id: 24,
    year: 2023,
  },
];

export const endingYearData = [
  {
    id: 1,
    year: 2000,
  },
  {
    id: 2,
    year: 2001,
  },
  {
    id: 3,
    year: 2002,
  },
  {
    id: 4,
    year: 2003,
  },
  {
    id: 5,
    year: 2004,
  },
  {
    id: 6,
    year: 2005,
  },
  {
    id: 7,
    year: 2006,
  },
  {
    id: 8,
    year: 2007,
  },
  {
    id: 9,
    year: 2008,
  },
  {
    id: 10,
    year: 2009,
  },
  {
    id: 11,
    year: 2010,
  },
  {
    id: 12,
    year: 2011,
  },
  {
    id: 13,
    year: 2012,
  },
  {
    id: 14,
    year: 2013,
  },
  {
    id: 15,
    year: 2014,
  },
  {
    id: 16,
    year: 2015,
  },
  {
    id: 17,
    year: 2016,
  },
  {
    id: 18,
    year: 2017,
  },
  {
    id: 19,
    year: 2018,
  },
  {
    id: 20,
    year: 2019,
  },
  {
    id: 21,
    year: 2020,
  },
  {
    id: 22,
    year: 2021,
  },
  {
    id: 23,
    year: 2022,
  },
  {
    id: 24,
    year: 2023,
  },
  {
    id: 25,
    year: 2024,
  },
];

export const taxonomyData = [
  {
    id: 1,
    taxonomy: "Understanding 1",
    category: "Easy",
    active: true,
  },
  {
    id: 2,
    taxonomy: "Understanding 2",
    category: "Easy",
    active: false,
  },
  {
    id: 3,
    taxonomy: "Understanding 3",
    category: "Medium",
    active: true,
  },
  {
    id: 4,
    taxonomy: "Understanding 4",
    category: "Easy",
    active: true,
  },
  {
    id: 5,
    taxonomy: "Understanding 5",
    category: "Difficult",
    active: false,
  },
  {
    id: 6,
    taxonomy: "Understanding 6",
    category: "Easy",
    active: true,
  },
  {
    id: 7,
    taxonomy: "Understanding 7",
    category: "Medium",
    active: true,
  },
];
export const categoryData = [
  {
    id: 1,
    category: "Easy",
  },
  {
    id: 2,
    category: "Medium",
  },
  {
    id: 3,
    category: "Difficult",
  },
];
export const subjectMappingColumn = [
  {
    id: 1,
    dataIndex: "#",
    align: "left",
  },
  {
    id: 2,
    dataIndex: "Subject",
    align: "left",
  },
  {
    id: 3,
    dataIndex: "Subtopic",
    align: "left",
  },
  {
    id: 4,
    dataIndex: "Board",
    align: "center",
  },
  {
    id: 5,
    dataIndex: "Grade(s)",
    align: "center",
  },
  {
    id: 6,
    dataIndex: "Skill Subject",
    align: "center",
  },
  {
    id: 7,
    dataIndex: "Active",
    align: "center",
  },
  {
    id: 8,
    dataIndex: "Action",
    align: "center",
  },
];
