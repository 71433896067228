import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  HashRouter,
} from "react-router-dom";
import ProtectedRoute from "../src/components/ProtectedRoute";
import Login from "./pages/js/Login";
import MobileOTP from "./pages/js/MobileOTP";
import SetPass from "./pages/js/SetPassword";
import ForgetPassMob from "./pages/js/ForgetPassMob";
import UpdatePass from "./pages/js/UpdatePass";
import Dashboard from "./pages/js/Dashboard";
import Template from "./pages/js/Template";
import CreateSchool from "./pages/js/createSchool9";
import RegisteredSchool from "./pages/js/registeredSchool10";
import schoolList from "./pages/js/data/SchoolList";
import branchlist15 from "./pages/js/data/branchlist15";
import Addbranch from "./pages/js/addbranch14";
import Addbranchlist from "./pages/js/addbranch15";
import BranchPlan from "./pages/js/BranchPlan";
// import BranchSectionTesting from "./pages/js/BranchSectionTesting";
import BranchSectionMapping from "./pages/js/BranchSecitonMapping/BranchSectionMapping";
import BranchSectionTesting from "./pages/js/BranchSecitonMapping/BranchSectionMappingTesting";
import TeacherId from "./pages/js/TeacherId";
import TeacherIdList from "./pages/js/TeacherIdList";
import teacherList from "./pages/js/data/teacherslist";
import Acadmicid from "./pages/js/academicStaff";
import StudentList from "./pages/js/data/StudentList";
import SuperAdminDash from "./pages/SuperAdminDash/SuperAdminDash";
import TokenOne from "./pages/js/data/TokenOne";
import Student from "./pages/js/student31";
import Studenttable from "./pages/js/student32";
import Tokenone from "./pages/js/token35";
import TokenList from "./pages/js/data/TokenOne";
import TokenSecond from "./pages/js/Token36";
import CSP from "./pages/js/csppg34";
import Logo from "./pages/js/Logo";
import ChoosePlan from "./pages/js/ChoosePlan";
import PlanList from "./pages/js/data/PlanList";
import AddCity from "./pages/js/AddCity";
import CityList from "./pages/js/data/CityList";
import AddCityList from "./pages/js/AddCityList";

import SchoolAdminList from "./pages/js/data/SchoolAdminList";
import ManagementListdata from "./pages/js/data/managementIdList";
import PrincipalProfile from "./pages/js/PrincipalProfile";
import "./App.css";
// import Home from "./pages/js/Home";
import AssociateCptCreated from "./pages/js/AssociateCptCreated";
import AssociateCptDetails from "./pages/js/AssociateCptDetails";
import AssociateConcept from "./pages/js/AssociateConcept";
import ContentAdminDash from "./pages/js/contentAdminDashboard";
import UploadContent from "./pages/js/UploadContent";
import GlobalTraining from "./pages/js/GlobalTraining";
import ConceptDetails from "./pages/js/ConceptDetails";
import TrainingCategory from "./pages/js/TrainingCategory";
import AddTeacherFiles from "./pages/js/AddTeacherFiles";
import AddStudentFiles from "./pages/js/AddStudentFiles";
import TrainingAids from "./pages/js/TrainingAids";
import SubConceptTree from "./pages/js/SubConceptTree";
import FileStatus from "./pages/js/FileStatus";
import CreateConcept from "./pages/js/CreateConcept";
import CreateDocCategory from "./pages/js/CreateDocCategory";
import CreateChapter from "./pages/js/createChapter";
import CreateTraining from "./pages/js/createTraining";
import CreateTrainingCategory from "./pages/js/CreateTrainingCategory";
import FileStatusApproved from "./pages/js/FileStatus_approved";
import FileStatusRejected from "./pages/js/FileStatus_rejected";
import ContentApprovalPending from "./pages/js/ContentApprovalPending";
import ContentApprovalRejected from "./pages/js/ContentApprovalRejected";
import ContentApprovalApproved from "./pages/js/ContentApprovalApproved";
import GlobalTrainingApprovalPending from "./pages/js/GlobalTrainingApproval_pending";
import GlobalTrainingApprovalRejected from "./pages/js/GlobalTrainingApproval_rejected";
import GlobalTrainingApprovalApproved from "./pages/js/GlobalTrainingApproval_approved";
import StudentFileApproval from "./pages/js/StudentFileApproval";
import TeacherFileApproval from "./pages/js/TeacherFileApproval";
import TrainingApprovalAid from "./pages/js/TrainingApprovalAid";
import Activities from "./pages/js/Activities";
import ConceptTreeApproval from "./pages/js/ConceptTreeApproval";
import ConceptTreeApprovalPending from "./pages/js/ConceptTreeApprovalPending";
import ConceptTreeApprovalRejected from "./pages/js/ConceptTreeApprovalRejected";
import ObjQuestionBank from "./pages/js/ObjQuestionBank";
import ObjectiveQuestion from "./pages/js/ObjectiveQuestion";
// question bank preview
import Preview_FIB from "./pages/js/QuestionBank/CommonComponents/preview";
import ViewAllQue from "./pages/js/ViewAllQue";
import Statistics from "./pages/js/Statistics";
import CreateQuiz from "./pages/js/CreateQuiz";
import ViewQuizzes from "./pages/js/ViewQuizzes";
import UploadAssessments from "./pages/js/UploadAssessments";
import ViewConceptTree from "./pages/js/viewConceptTree";
import Maps from "./pages/js/Maps";

import PracticeQuiz from "./pages/js/PracticeQuiz";
import CreateAdminUser from "./pages/js/CreateAdminUser";
import EditAdminUser from "./pages/js/EditAdminUser";
import AssignAssessPaper from "./pages/js/AssignAssessPaper";
import QbReviewPending from "./pages/js/QbReviewPending";
import QbReviewRejected from "./pages/js/QbReviewRejected";
import QbReviewApproved from "./pages/js/QbReviewApproved";
import ViewReviewerQuizzes from "./pages/js/ViewReviewerQuizzes";
import ViewAssessments from "./pages/js/ViewAssessments";
import RevisionAdminDash from "./pages/js/RevisionAdminDash";
import AddManagement from "./pages/js/AddManagement";
import CreateManagement from "./pages/js/CreateManagement";
import TeacherAccess from "./pages/js/TeacherAccess";

// -----------
import AdminRole from "./pages/js/adminRole";
import PlanTemplate from "./pages/js/planTemplate";
import PlanTemplateClone from "./pages/js/planTemplateClone";
import CoordinatorType from "./pages/js/coordinatorType";
import Board from "./pages/js/Board";
import SubjectAndSubtopic from "./pages/js/SubjectAndSubtopic";
import SubjectMapping from "./pages/js/SubjectMapping";
import Grade from "./pages/js/Grade";
import Chapter from "./pages/js/Chapter";
import City from "./pages/js/City";
import ConceptTree from "./pages/js/conceptTree";
import DocumentCategory from "./pages/js/DocumentCategory";
import TaxonomyCategory from "./pages/js/TaxonomyCategory";
import Taxonomy from "./pages/js/Taxonomy";
import AcademicYear from "./pages/js/AcademicYear";
import TeacherDashboard from "./pages/js/TeacherDashboard";
import PrincipalDashboard from "./pages/js/PrincipalDashboard";
// import TeacherDashboard2 from "./pages/js/TeacherDashBoard2";
import Teach from "./pages/js/Teach";
import AssignQuiz from "./pages/js/AssignQuiz";
import LearningPlan from "./pages/js/LearningPlan";
import AdminUsers from "./pages/js/AdminUsers";
import BluePrint from "./pages/js/BluePrint";
import ReadingPassportTeacher from './pages/js/Teacher/ReadingPassportTeacher';
import AvidReader from "./pages/js/Teacher/AvidReader";
import PassportPerformance from "./pages/js/Teacher/PassportPerformance";

import CreateEditConcept from "./pages/js/CreateEditConcept";
import SubAndRootConcepts from "./pages/js/SubAndRootConcepts";

// import Branches from "./pages/js/Branches";
import SubjectivePapers from "./pages/js/SubjectivePapers";
import CoordinatorSubjectivePapers from "./pages/js/CordinatorSubjectivePapers";
import ChapterPerformance from "./pages/js/ChapterPerformance/ChapterPerformance";
import TeacherProfile from "./pages/js/TeacherProfile";
import StudentReport from "./pages/js/StudentReport";
import StudentRevision from "./pages/js/StudentRevision";

import ConceptTreeView from "./pages/js/ConceptTreeView";
import CreateTeacher from "./pages/js/CreateTeacher";
import SchoolAdmin from "./pages/js/SchoolAdmin";
import ContentApprovalViewFile from "./pages/js/ContentApprovalViewFile";
import ContentApprovalTeacherViewFile from "./pages/js/ContentApprovalTeacherViewFile";
import ContentrejectStudentviewfile from "./pages/js/ContentrejectStudentviewfile";
import ContentrejectTeacherviewfile from "./pages/js/ContentrejectTeacherviewfile";
import AddbranchSchoolAdmin from "./pages/js/AddBranchSchoolAdmin";
import CreateQuizParent from "./pages/js/CreateQuiz/CreateQuizParent";

import Student_Study from "./pages/js/Student/Student_Study";
import Student_Enrich from "./pages/js/Student/Student_Enrich";
import ReadingPassport from "./pages/js/Student/ReadingPassport";
import Student_Study_Files from "./pages/js/Student/Student_Study_Files";
import Student_Study_Files_Video from "./pages/js/Student/Student_Study_Files_Video";
import Student_Study_Files_Mind_Map from "./pages/js/Student/Student_Study_Files_Mind_Map";
import Student_Enrich_Quiz from "./pages/js/Student/Student_Enrich_Quiz";
import Student_Enrich_Quiz_Attend from "./pages/js/Student/Student_Enrich_Quiz_Attend";
import Student_Enrich_Quiz_FIB from "./pages/js/Student/Student_Enrich_Quiz_FIB";
import SubjectiveQuestions from "./pages/js/SubjectiveQuestions";
import Student_Enrich_Quiz_MSQ from "./pages/js/Student/Student_Enrich_Quiz_MSQ";
import Student_Enrich_Quiz_MCQ from "./pages/js/Student/Student_Enrich_Quiz_MCQ";
import Student_Enrich_Quiz_CS from "./pages/js/Student/Student_Enrich_Quiz_CS";
import Student_Formal_Assessment_Quiz from "./pages/js/Student/FormalAssessmentQuiz/Student_Formal_Assessment_Quiz";
import Student_Reports_CR from "./pages/js/Student/Student_Reports_CR";
import FillInTheBlanks from "./pages/js/QuestionBank/FillIntheBlanks";
import CreateCaseStudy from "./pages/js/QuestionBank/CreateCaseStudy";
import Student_StudentProfile from "./pages/js/Student/Student_StudentProfile";
import Principal_Teach from "./pages/js/PrincipalModule/Principal_Teach";
//cordinator
import CordinatorDashboard from "./pages/js/Cordinator/dashboard";
import CordinatorTeach from "./pages/js/Cordinator/teach/CordinatorTeach";
import CordinatorAssignQuiz from "./pages/js/Cordinator/assignQuiz";
import CordinatorStudentRevision from "./pages/js/Cordinator/studentRevision/CordinatorStudentRevision";
import CordinatorReports from "./pages/js/Cordinator/reports";
import CordinatorLearningPlan from "./pages/js/Cordinator/learningPlan";
import Principal_AssignQuiz from "./pages/js/PrincipalModule/Principal_AssignQuiz";
import AddbranchnoForm from "./pages/js/AddbranchnoForm";
import ReviewerDashboard from "./pages/js/RevisionReviewer/Dashboard/ReviewerDashboard";
import StudentRevisionContent from "./pages/js/Cordinator/studentRevision/StudentRevisionContent";
import TeachContent from "./pages/js/Cordinator/teach/TeachContent";
import TeachContentOptimised from "./pages/js/Cordinator/teach/TeachContentOptimised";
import AssignQuizContentOptimised from "./pages/js/Cordinator/assignQuiz/AssignQuizContentOptimised";
import StudentRevisionContentOptimised from "./pages/js/Cordinator/studentRevision/StudentRevisionContentOptimised";
import RevisionReviewer from "./pages/js/RevisionReviewer/Dashboard/RevisionReviewer";
import ReviewDashboard from "./pages/js/RevisionReviewer/ChapterDashBoard/Dashboard";
import ViewQuiz from "./pages/js/RevisionReviewer/ViewQuiz";
import ReviewerConceptTree from "./pages/js/RevisionReviewer/ReviewerConceptTree";
import RevisionAdminDashboard from "./pages/js/RevisionAdmin/RevisionAdminDashboard";
import Teacher_New_Dashboard from "./pages/js/Teacher_New_Dashboard";
import PlanTemplateMasterAdmin from "./pages/js/PlanTemplateMasterAdmin";
import PrincipalModuleDashboard from "./pages/js/PrincipalModule/dashboard";
import FormativeAssessment from "./pages/js/FormativeAssessment";
import PrincipalFormativeAssessment from "./pages/js/PrincipalModule/PrincipalFormativeAssessment";
import CordinatorFormativeAssessment from "./pages/js/Cordinator/FormativeAssessment/CoordinatorFormativeAssessment";
import FormalAssessment from "./pages/js/FormalAssessment/FormalAssessment";
import Student_Formal_Report from "./pages/js/Student/Student_Formal_Report";
import StudentReport_Student from "./pages/js/StudentReport_Student";
import Student_Dashboard from "./pages/js/Student/Dashboard/Student_Dashboard";
import MasterAdmin_Dashboard from "./pages/js/MasterAdmin_Dashboard";
import PrincipalDashboardNew from "./pages/js/PrincipalModule/dashboardNew/PrincipalDashboardNew";
import TeacherDashboardLatest from "./pages/js/Teacher/Dashboard/TeacherDashboardLatest";
import SchoolAdminDash from "./pages/js/SchoolDashboard/schoolAdminDash";
import BluePrintConfiguration from "./pages/js/BluePrint/Configuration/BluePrintConfiguration";
import BluePrintUpload from "./pages/js/BluePrint/Uploads/BluePrintUpload";
import BluePrintApproval from "./pages/js/ContentReviewer/BluePrintApproval";
import AssessmentUpload from "./pages/js/RevisionAdmin/BluePrint/UploadAssessment/AssessmentUpload";
import AssessmentAssign from "./pages/js/RevisionAdmin/BluePrint/AssignAssessment/AssessmentAssign";

import QuestionModalPage from "./pages/js/QuestionModalPage";
import QuestionModalPagePreview from "./pages/js/QuestionModalPagePreview";

import CourseList from "./pages/js/UpSkill/CourseList";
import AddCourse from "./pages/js/UpSkill/AddCourse";
import EditCourse from "./pages/js/UpSkill/EditCourse";
import QuizNewComponent from "./pages/js/Student/QuizNewComponent";
import QuizNewResumeComponent from "./pages/js/Student/QuizNewResumeComponent";
import Student_Enrich_Quiz_Analysis_Modal from "./pages/js/Student/Student_Enrich_Quiz_Analysis_Modal";
import ProgressReport from "./pages/js/ProgressReport";
import Student_Formal_Assesment_Analysis_Modal from "./pages/js/Student/Student_Formal_Assesment_Analysis_Modal";
import PrincipalReadingPassport from "./pages/js/PrincipalModule/PrincipalReadingPassport";
import CoordinatorReadingPassport from "./pages/js/Cordinator/ReadingPassport/CoordinatorReadingPassport";
import CoordinatorAvidReader from "./pages/js/Cordinator/ReadingPassport/CoordinatorAvidReader";
import CoordinatorPerformancePassport from "./pages/js/Cordinator/ReadingPassport/CoordinatorPerformancePassport";
import Loader from "./components/Loader/Loader";
import useAxiosLoader from "./components/Loader/AxiosConfig";
import QuizOptimizedComponent from "./pages/js/Student/QuizOptimizedComponent";
import QuizResumeOptimizedComponent from "./pages/js/Student/QuizResumeOptimizedComponent";
import CreateHTMLContent2 from "./pages/js/CreactHTMLContent2";



const updateSchoolList = (e, schoolInputs) => {
  const schoolListItem = {
    schoolName: schoolInputs.schoolName,
    schoolType: schoolInputs.typeOfSchool,
    schoolType1: schoolInputs.typeOfSchool,
    students: "00",
    hods: "00",
    teachers: "00",
    branches: "00",
  };
  schoolList.push(schoolListItem);
};

const updatebranchList = (e, branchInputs) => {
  const branchListItem = {
    branchName: branchInputs.branchName,
    branchid: "00",
    branchcity: branchInputs.branchcity,
    phonenumber: branchInputs.phoneNumber,
    branchlocality: branchInputs.branchlocality,
    boards: branchInputs.boards,

    hods: "00",
    teachers: "00",
    students: "00",
    active: "",
    action: "",
  };
  branchlist15.push(branchListItem);
};

const updateteacherList = (e, teacherInputs) => {
  const teacherListItem = {
    primaryEmail: teacherInputs.primaryEmail,
    password: teacherInputs.password,
    role: teacherInputs.role,
    PrimaryPhoneNo: teacherInputs.PrimaryPhoneNo,
    firstName: teacherInputs.firstName,
    lastName: teacherInputs.lastName,
    dateOfBirth: teacherInputs.dateOfBirth,
    gender: teacherInputs.gender,
    dateOfJoin: teacherInputs.dateOfJoin,
    workExperience: teacherInputs.workExperience,
    address: teacherInputs.address,
    designation: teacherInputs.designation,
    userId: teacherInputs.userId,

    action: "",
  };
  teacherList.push(teacherListItem);
};

const updateStudentList = (e, studentInputs) => {
  const studentListItem = {
    UserName: studentInputs.UserName,
    Grade: studentInputs.Grade,
    section: studentInputs.section,
    primaryPhoneNo: studentInputs.primaryPhoneNo,
    primaryEmail: studentInputs.primaryEmail,
    UserId: studentInputs.UserId,
    FirstName: studentInputs.FirstName,
    LastName: studentInputs.LastName,
    Quiz: studentInputs.Quiz,
    LastLogin: studentInputs.LastLogin,

    DateOfBob: studentInputs.DateOfBob,
    Gender: studentInputs.Gender,
    MotherTongue: studentInputs.MotherTongue,
    SecondLanguage: studentInputs.SecondLanguage,
    admissionDate: studentInputs.admissionDate,

    StudentCategory: studentInputs.StudentCategory,
    PresentAddress: studentInputs.PresentAddress,

    Action: "",
  };
  StudentList.push(studentListItem);
};

const updateTokenList = (e, tokenInputs) => {
  const currentDate = new Date();
  let currentMonth = 1 + currentDate.getMonth();
  if (currentMonth < 10) {
    currentMonth = 0 + currentMonth.toString();
  }

  // const currentMonth = 1 + currentDate.getMonth();
  const TokenListItem = {
    TokenId: "446-409-901",
    Role: tokenInputs.Role,
    Email: "sample@gmail.com",
    MultiUser: tokenInputs.MultiUser,
    NoOfUsers: "none",
    UsedUserId: "12815",
    DateCreated:
      currentDate.getDate() +
      "-" +
      currentMonth +
      "-" +
      currentDate.getFullYear() +
      " " +
      currentDate.toLocaleTimeString(),
    ExpiryDate: tokenInputs.ExpiryDate,
    NoOfToken: tokenInputs.NoOfToken,
  };
  TokenList.push(TokenListItem);
};

const updatePlanList = (e, PlanInputs) => {
  const PlanListItem = {
    Grade: "",
    Board: "",
    LessonPlan: PlanInputs.LessonPlan,
    RevisionModuleTeacher: PlanInputs.RevisionModuleTeacher,
    RmLock: PlanInputs.RmLock,
    AssessmentModule: PlanInputs.AssessmentModule,
    Activities: PlanInputs.Activities,
    WSdownload: PlanInputs.WSdownload,
    WBdownload: PlanInputs.WBdownload,
    News: PlanInputs.News,
    StudentBooklet: PlanInputs.StudentBooklet,
    RevisionModuleStudent: PlanInputs.RevisionModuleStudent,
  };
  PlanList.push(PlanListItem);
};

const updateCityList = (e, CityInputs) => {
  const CityListItem = {
    schoolName: CityInputs.CityName,
    City: CityInputs.City,
    PinCode: CityInputs.PinCode,
    State: CityInputs.State,
    Country: CityInputs.Country,
    Active: "",
    Action: "",
  };
  CityList.push(CityListItem);
};

const updateSchoolAdminList = (e, SchoolAdminListInputs) => {
  const SchoolAdminListItem = {
    UserName: SchoolAdminListInputs.UserName,
    FirstName: SchoolAdminListInputs.FirstName,
    LastName: SchoolAdminListInputs.LastName,

    SchoolName: SchoolAdminListInputs.SchoolName,
    BranchName: SchoolAdminListInputs.BranchName,
    Active: " ",
    Action: " ",
    NoOfBranches: SchoolAdminListInputs.NoOfBranches,
    PrimaryEmail: SchoolAdminListInputs.PrimaryEmail,
    PrimaryPhoneNo: SchoolAdminListInputs.PrimaryPhoneNo,
    Gender: SchoolAdminListInputs.Gender,
  };
  SchoolAdminList.push(SchoolAdminListItem);
};

const updateManagementListdata = (e, ManagementListdataInputs) => {
  const ManagementListdataItem = {
    UserName: ManagementListdataInputs.UserName,
    FirstName: ManagementListdataInputs.FirstName,
    LastName: ManagementListdataInputs.LastName,

    SchoolName: ManagementListdataInputs.SchoolName,
    BranchName: ManagementListdataInputs.BranchName,
    Active: " ",
    Action: " ",
    NoOfBranches: ManagementListdataInputs.NoOfBranches,
    NoOfSchool: ManagementListdataInputs.NoOfSchool,
    PrimaryEmail: ManagementListdataInputs.PrimaryEmail,
    PrimaryPhoneNo: ManagementListdataInputs.PrimaryPhoneNo,
    Gender: ManagementListdataInputs.Gender,
  };
  ManagementListdata.push(ManagementListdataItem);
};

const App = () => {
  const [token, setToken] = useState("");
  const [sucess, setSucess] = useState(true);
  const [sucessText, setSucessText] = useState("");

  const [resultTokenData, setResultTokenData] = useState({});
  const loading = useAxiosLoader();

  return (
    <>
      <Loader loading={loading} />
      {/* <Router> */}
      <HashRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Login
                token={token}
                setToken={setToken}
                sucess={sucess}
                setSucess={setSucess}
                sucessText={sucessText}
                setSucessText={setSucessText}
                resultTokenData={resultTokenData}
                setResultTokenData={setResultTokenData}
              />
            }
          />
          {/* <Route exact path="/login" element={<Login />} />, */}
          <Route
            path="/mobileotp"
            element={
              <ProtectedRoute token={token}>
                <MobileOTP />
              </ProtectedRoute>
            }
          />
          <Route exact path="forgetpassmob" element={<ForgetPassMob />} />
          <Route exact path="/updatepass" element={<UpdatePass />} />
          <Route exact path="/setPass" element={<SetPass />} />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute token={token}>
                {/* {" "} */}
                <Dashboard token={token} />
                {/* {" "} */}
              </ProtectedRoute>
            }
          >
            {/* temp comment */}
            <Route
              exact
              path="template"
              element={
                localStorage.getItem("role") == "CONTENT_REVIEWER" ||
                  localStorage.getItem("role") == "CONTENT_ADMIN" ? (
                  <Template />
                ) : localStorage.getItem("role") == "STUDENT" ? (
                  <>
                    <Student_Dashboard />
                  </>
                ) : localStorage.getItem("role") == "MASTER_ADMIN" ? (
                  <>
                    <MasterAdmin_Dashboard />
                  </>
                ) : localStorage.getItem("role") == "SUPER_ADMIN" ? (
                  <>
                    <SuperAdminDash />
                  </>
                ) : localStorage.getItem("role") == "SCHOOL_ADMIN" ? (
                  <>
                    <SchoolAdminDash />
                  </>
                ) : (
                  <></>
                )
              }
            />

            {/* temp comment */}

            <Route
              exact
              path="Registered Schools"
              element={<RegisteredSchool schoolList={schoolList} />}
            />
            <Route
              exact
              path="Chapter Reports"
              element={<Student_Reports_CR />}
            />
            <Route
              exact
              path="Formal Report"
              element={<Student_Formal_Report />}
            />
            <Route exact path="Overall Performance" element={<Template />} />
            {/* <Route exact path="study" element={<Template />} /> */}
            <Route
              exact
              path="Progress Report"
              element={<ProgressReport />}
            />
            <Route exact path="study" element={<Student_Study />} />
            <Route
              exact
              path="SA_Study_Files"
              element={<Student_Study_Files />}
            />
            <Route
              exact
              path="SA_Study_Files_Video"
              element={<Student_Study_Files_Video />}
            />
            <Route
              exact
              path="SA_Study_Files_Mind_Map"
              element={<Student_Study_Files_Mind_Map />}
            />
            <Route
              exact
              path="Student_Enrich_Quiz"
              element={<Student_Enrich_Quiz />}
            />
            <Route
              exact
              path="Student_Enrich_Quiz_Attend"
              element={<Student_Enrich_Quiz_Attend />}
            />
            <Route exact path="dashboard" element={<Student_Dashboard />} />
            <Route exact path="dashboard" element={<MasterAdmin_Dashboard />} />
            <Route
              exact
              path="Student_Enrich_Quiz_FIB"
              element={<Student_Enrich_Quiz_FIB />}
            />
            <Route
              exact
              path="Student_Enrich_Quiz_MSQ"
              element={<Student_Enrich_Quiz_MSQ />}
            />
            <Route exact path="AddbranchnoForm" element={<AddbranchnoForm />} />
            <Route
              exact
              path="Student_Enrich_Quiz_MCQ"
              element={<Student_Enrich_Quiz_MCQ />}
            />
            <Route
              exact
              path="Student_Enrich_Quiz_CS"
              element={<Student_Enrich_Quiz_CS />}
            />

            <Route path="enrich" element={<Student_Enrich />} />
            {/* <Route path="enrich/quiz" element={<QuizNewComponent />} /> */}
            <Route path="enrich/quiz" element={<QuizOptimizedComponent />} />
            {/* <Route path="enrich/quiz/resume" element={<QuizNewResumeComponent />} /> */}
            <Route path="enrich/quiz/resume" element={<QuizResumeOptimizedComponent />} />
            <Route path="enrich/quiz/analysis" element={<Student_Enrich_Quiz_Analysis_Modal />} />
            <Route path="enrich/quiz/resume/analysis" element={<Student_Enrich_Quiz_Analysis_Modal />} />

            <Route path="Student_Enrich_Quiz/quiz" element={<QuizOptimizedComponent />} />
            {/* <Route path="enrich/quiz/resume" element={<QuizNewResumeComponent />} /> */}
            <Route path="Student_Enrich_Quiz/quiz/resume" element={<QuizResumeOptimizedComponent />} />
            <Route path="Student_Enrich_Quiz/quiz/analysis" element={<Student_Enrich_Quiz_Analysis_Modal />} />
            <Route path="Student_Enrich_Quiz/quiz/resume/analysis" element={<Student_Enrich_Quiz_Analysis_Modal />} />

            <Route exact path="Reading Passport" element={
              localStorage.getItem("role") === "STUDENT" ? (
                <ReadingPassport />
              ) : localStorage.getItem("role") === "TEACHER" ? (
                <ReadingPassportTeacher />
              ) : null
            } />

            <Route
              exact
              path="avid reader"
              element={<AvidReader />}
            />

            <Route
              exact
              path="passport performance"
              element={<PassportPerformance />}
            />

            <Route
              exact
              path="formal assessment quiz"
              element={<Student_Formal_Assessment_Quiz />}
            />
            {/* <Route path="formal assessment quiz/quiz" element={<QuizNewComponent />} /> */}
            <Route path="formal assessment quiz/quiz" element={<QuizOptimizedComponent />} />
            {/* <Route path="formal assessment quiz/quiz/resume" element={<QuizNewResumeComponent />} /> */}
            <Route path="formal assessment quiz/quiz/resume" element={<QuizResumeOptimizedComponent />} />
            <Route path="formal assessment quiz/quiz/analysis" element={<Student_Formal_Assesment_Analysis_Modal />} />
            <Route path="formal assessment quiz/quiz/resume/analysis" element={<Student_Formal_Assesment_Analysis_Modal />} />

            <Route
              exact
              path="enrich/:quiz_listing"
              element={<Student_Enrich />}
            />
            {/* <Route exact path="Blueprint" element={<BluePrint />} /> */}
            <Route
              exact
              path="Configuration"
              element={<BluePrintConfiguration />}
            />
            <Route exact path="Upload" element={<BluePrintUpload />} />
            <Route
              exact
              path="blueprint approval"
              element={<BluePrintApproval />}
            />

            <Route
              exact
              path="createschool"
              element={
                <CreateSchool
                  token={token}
                  schoolList={schoolList}
                  updateSchoolList={updateSchoolList}
                />
              }
            />
            <Route exact path="admin users" element={<AdminUsers />} />
            <Route exact path="Teacher Access" element={<TeacherAccess />} />
            <Route exact path="Branches" element={<AddbranchSchoolAdmin />} />

            <Route
              exact
              path="Create/Edit Concept"
              element={<CreateEditConcept />}
            />
            <Route
              exact
              path="Create/Edit Sub & Root Concepts"
              element={<SubAndRootConcepts />}
            />

            <Route
              exact
              path="addbranchlist"
              element={<Addbranchlist branchlist15={branchlist15} />}
            />

            <Route exact path="blue print" element={<BluePrint />} />

            <Route
              exact
              path="addbranch"
              element={
                <Addbranch
                  branchlist15={branchlist15}
                  updatebranchList={updatebranchList}
                />
              }
            />

            <Route
              exact
              path="branchplan"
              element={<BranchPlan PlanList={PlanList} />}
            />
            {/* <Route
              exact
              path="branchsection"
              element={<BranchSectionTesting />}
            /> */}
            {/* <Route
              exact
              path="branchsectionnew"
              element={<BranchSectionNew />}
            />  */}
            <Route
              exact
              path="branchsection"
              element={<BranchSectionMapping />}
            />
            {/* <Route exact path="BranchSectionTesting" element={<BranchSectionTesting />} /> */}

            {/* <Route
              exact
              path="teacherId"
              element={
                <TeacherId
                  teacherList={teacherList}
                  updateteacherList={updateteacherList}
                />
              }
            /> */}
            <Route
              exact
              path="teacherId"
              element={
                <TeacherIdList
                  teacherList={teacherList}
                  updateteacherList={updateteacherList}
                />
              }
            />
            {/* <Route
              exact
              path=" AddAcademicStaff"
              element={
                <TeacherIdList
                  teacherList={teacherList}
                  updateteacherList={updateteacherList}
                />
              }
            /> */}
            <Route
              exact
              path="Add Academic Staff"
              element={
                <TeacherId
                  teacherList={teacherList}
                  updateteacherList={updateteacherList}
                />
              }
            />
            <Route
              exact
              path="academicid"
              element={
                <Acadmicid
                  teacherList={teacherList}
                  updateteacherList={updateteacherList}
                />
              }
            />

            <Route
              exact
              path="student"
              element={
                <Student
                  StudentList={StudentList}
                  updateStudentList={updateStudentList}
                />
              }
            />
            <Route
              exact
              path="Add Student"
              element={<Studenttable StudentList={StudentList} />}
            />
            <Route
              exact
              path="token"
              element={
                <Tokenone
                  TokenList={TokenList}
                  updateTokenList={updateTokenList}
                />
              }
            />
            <Route
              exact
              path="tokenlist"
              element={
                <TokenSecond
                  TokenList={TokenList}
                  updateTokenList={updateTokenList}
                />
              }
            />
            <Route
              exact
              path="csp"
              element={<CSP StudentList={StudentList} />}
            />

            <Route exact path="logo" element={<Logo />} />
            <Route
              exact
              path="chooseplan"
              element={
                <ChoosePlan
                  PlanList={PlanList}
                // updatePlanList={updatePlanList}
                />
              }
            />
            <Route
              exact
              path="addcity"
              element={
                <AddCity CityList={CityList} updateCityList={updateCityList} />
              }
            />
            <Route
              exact
              path="Add City"
              element={
                <AddCityList
                  CityList={CityList}
                // updateCityList={updateCityList}
                />
              }
            />
            <Route
              exact
              path="Registered School"
              element={<RegisteredSchool />}
            />

            {/* ------- */}
            <Route
              exact
              path="CoAdminDashboard"
              element={<ContentAdminDash />}
            />
            
            <Route
              exact
              path="Create Content"
              element={<CreateHTMLContent2 />}
            />
            <Route
              exact
              path="associate concepts"
              element={<AssociateConcept />}
            />

            <Route
              exact
              path="AssociateCptDetails"
              element={<AssociateCptDetails />}
            />
            <Route
              exact
              path="AssociateCptCreated"
              element={<AssociateCptCreated />}
            />
            <Route exact path="Upload Content" element={<UploadContent />} />
            <Route exact path="Global Training" element={<GlobalTraining />} />
            <Route exact path="Concept Tree" element={<ConceptDetails />} />
            <Route
              exact
              path="Add Training Category"
              element={<TrainingCategory />}
            />
            <Route exact path="AddTeacherFiles" element={<AddTeacherFiles />} />
            <Route exact path="AddStudentFiles" element={<AddStudentFiles />} />
            <Route exact path="TrainingAids" element={<TrainingAids />} />
            <Route exact path="subCocepttree" element={<SubConceptTree />} />
            <Route exact path="FileStatus" element={<FileStatus />} />
            <Route exact path="CreateConcept" element={<CreateConcept />} />
            <Route
              exact
              path="CreateDocCategory"
              element={<CreateDocCategory />}
            />
            <Route exact path="CreateChapter" element={<CreateChapter />} />
            <Route exact path="CreateTraining" element={<CreateTraining />} />
            <Route
              exact
              path="CreateTrainingCategory"
              element={<CreateTrainingCategory />}
            />
            <Route
              exact
              path="FileStatusApproved"
              element={<FileStatusApproved />}
            />
            <Route
              path="contentapprovalviewfile"
              element={<ContentApprovalViewFile />}
            />
            <Route
              path="ContentrejectStudentviewfile"
              element={<ContentrejectStudentviewfile />}
            />
            <Route
              path="ContentrejectTeacherviewfile"
              element={<ContentrejectTeacherviewfile />}
            />
            <Route
              exact
              path="FileStatusRejected"
              element={<FileStatusRejected />}
            />
            <Route
              exact
              path="Content Approval"
              element={<ContentApprovalPending />}
            />
            <Route
              exact
              path="ContentApprovalRejected"
              element={<ContentApprovalRejected />}
            />
            <Route
              exact
              path="ContentApprovalApproved"
              element={<ContentApprovalApproved />}
            />

            <Route
              exact
              path="ConceptTreeApprovalPending"
              element={<ConceptTreeApprovalPending />}
            />
            <Route
              exact
              path="ConceptTreeApprovalRejected"
              element={<ConceptTreeApprovalRejected />}
            />
            <Route
              exact
              path="Global Training Approval"
              element={<GlobalTrainingApprovalPending />}
            />
            <Route
              exact
              path="GlobalTrainingApprovalRejected"
              element={<GlobalTrainingApprovalRejected />}
            />
            <Route
              exact
              path="GlobalTrainingApprovalApproved"
              element={<GlobalTrainingApprovalApproved />}
            />
            <Route
              path="ContentApprovalTeacherViewFile"
              element={<ContentApprovalTeacherViewFile />}
            />
            <Route
              exact
              path="StudentFileApproval"
              element={<StudentFileApproval />}
            />
            <Route
              exact
              path="TeacherFileApproval"
              element={<TeacherFileApproval />}
            />
            <Route
              exact
              path="TrainingApprovalAid"
              element={<TrainingApprovalAid />}
            />
            <Route exact path="Activities" element={<Activities />} />
            <Route
              exact
              path="Concept Tree Approval"
              element={<ConceptTreeApproval />}
            />
            <Route path="ConceptTreeView/:id" element={<ConceptTreeView />} />
            {/* <Route exact path="Question Bank" element={<ObjQuestionBank />} /> */}
            <Route exact path="QuestionBank" element={<ObjectiveQuestion />} />
            <Route
              exact
              path="QuestionBank/:id"
              element={<ObjectiveQuestion />}
            />
            <Route
              exact
              path="QuestionBank/Subjective"
              element={<SubjectiveQuestions />}
            />
            <Route
              exact
              path="QuestionBank/Subjective/:id"
              element={<SubjectiveQuestions />}
            />
            <Route exact path="fillintheblanks" element={<FillInTheBlanks />} />
            <Route exact path="casestudy" element={<CreateCaseStudy />} />
            {/* <Route exact path="QuestionBankPreview" element={<Preview_FIB />} /> */}
            <Route exact path="View All Questions" element={<ViewAllQue />} />
            <Route exact path="Statics" element={<Statistics />} />
            <Route exact path="Maps" element={<Maps />} />
            <Route exact path="CreateAdminUser" element={<CreateAdminUser />} />
            <Route exact path="EditAdminUser" element={<EditAdminUser />} />
            <Route exact path="PracticeQuiz" element={<PracticeQuiz />} />

            <Route
              exact
              path="AssignAssessPaper"
              element={<AssignAssessPaper />}
            />
            <Route exact path="View Quizzes" element={<ViewQuizzes />} />
            <Route exact path="Quiz List" element={<ReviewerDashboard />} />
            <Route
              exact
              path="Upload Assessments"
              element={<UploadAssessments />}
            />
            <Route
              exact
              path="View Concept Tree"
              element={<ViewConceptTree />}
            />
            <Route exact path="QbReviewPending" element={<QbReviewPending />} />
            <Route
              exact
              path="QbReviewRejected"
              element={<QbReviewRejected />}
            />
            <Route
              exact
              path="QbReviewApproved"
              element={<QbReviewApproved />}
            />

            {/* <Route
              exact
              path="ReviewerDashboard"
              element={<ReviewerDashboard />}
            /> */}
            <Route
              exact
              path="View Assessments"
              element={<ViewAssessments />}
            />
            <Route
              exact
              path="RevisionAdminDash"
              element={<RevisionAdminDashboard />}
            />
            <Route
              exact
              path="RevisionAdminDash"
              element={<RevisionAdminDashboard />}
            />
            <Route exact path="Add Management" element={<AddManagement />} />
            <Route
              exact
              path="Upload Assessment"
              element={<AssessmentUpload />}
            />
            <Route
              exact
              path="Assign Assessment"
              element={<AssessmentAssign />}
            />
            <Route exact path="CreateTeacher" element={<CreateTeacher />} />
            <Route exact path="School Admin" element={<SchoolAdmin />} />

            {/* <Route exact path="Concept Tree" element={<ConceptTree />} /> */}
            <Route exact path="template" element={<Template />} />
            {/* <Route exact path="template" element={<ContentAdminDash />} /> */}

            {/* ------------- */}

            <Route exact path="createSchool9" element={<CreateSchool />} />
            <Route
              exact
              path="TeacherDashboard"
              // element={<TeacherDashboard />}
              // element={<Teacher_New_Dashboard/>}
              element={<TeacherDashboardLatest />}
            // element={<TeacherDashboard2 />}
            />

            <Route
              exact
              path="/dashboard/principal/dashboard"
              element={<PrincipalDashboardNew />}
            />

            <Route
              exact
              path="/dashboard/principal/dashboard"
              element={<PrincipalModuleDashboard />}
            />
            <Route
              exact
              path="formal assessment"
              element={<FormativeAssessment />}
            />
            <Route
              exact
              path="principal/formal assessment"
              element={<PrincipalFormativeAssessment />}
            />

            <Route exact path="teach" element={<Teach />} />

            <Route exact path="/dashboard/upskills" element={<CourseList />} />
            <Route exact path="/dashboard/upskills/addcourse" element={<AddCourse />} />
            <Route exact path="/dashboard/upskills/editcourse/:id" element={<EditCourse />} />

            <Route exact path="/dashboard/upskills" element={<CourseList />} />
            <Route exact path="/dashboard/upskills/addcourse" element={<AddCourse />} />
            <Route exact path="/dashboard/upskills/editcourse/:id" element={<EditCourse />} />

            {/*---Principal Module---*/}
            <Route
              exact
              path="/dashboard/principal/teach"
              element={<TeachContentOptimised />}
            />
            <Route
              exact
              path="/dashboard/principal/assign quiz"
              element={<AssignQuizContentOptimised />}
            />
            <Route
              exact
              path="/dashboard/principal/Reading Passport"
              element={<PrincipalReadingPassport />}
            />
            <Route
              exact
              path="/dashboard/principal/student revision"
              element={<StudentRevisionContentOptimised />}
            />
            <Route
              exact
              path="/dashboard/principal/subjective papers"
              element={<SubjectivePapers />}
            />

            {/*---Principal Module---*/}
            <Route
              exact
              path="/dashboard/principal/teach"
              element={<Principal_Teach />}
            />
            <Route
              exact
              path="/dashboard/principal/assign quiz"
              element={<Principal_AssignQuiz />}
            />

            <Route exact path="TeacherProfile" element={<TeacherProfile />} />
            <Route
              exact
              path="PrincipalProfile"
              element={<PrincipalProfile />}
            />
            <Route
              exact
              path="Student_StudentProfile"
              element={<Student_StudentProfile />}
            />
            <Route
              exact
              path="student revision"
              element={<StudentRevision />}
            />
            <Route exact path="assign quiz" element={<AssignQuiz />} />
            <Route exact path="quiz" element={<QuestionModalPage />} />
            <Route exact path="quiz preview" element={<QuestionModalPagePreview />} />
            <Route exact path="learning plan" element={<LearningPlan />} />
            <Route exact path="create quiz" element={<CreateQuizParent />} />
            <Route exact path="upskill" element={<Template />} />
            <Route exact path="Student Report" element={<StudentReport />} />
            <Route
              exact
              path="Student%20Overall%Performance"
              element={<StudentReport_Student />}
            />
            <Route
              exact
              path="Chapter performance"
              element={<ChapterPerformance />}
            />
            <Route
              exact
              path="Formative Assessment"
              element={<FormalAssessment />}
            />
            <Route
              exact
              path="subjective papers"
              element={<SubjectivePapers />}
            />
            <Route exact path="adminRole" element={<AdminRole />} />
            <Route exact path="Admin Role" element={<AdminRole />} />
            {/*<Route exact path="planTemplate" element={<PlanTemplate />} />*/}
            <Route
              exact
              path="Plan Template"
              element={<PlanTemplateMasterAdmin />}
            />
            <Route
              exact
              path="Coordinator Type"
              element={<CoordinatorType />}
            />
            <Route exact path="Board" element={<Board />} />
            <Route exact path="Subject Mapping" element={<SubjectMapping />} />
            <Route exact path="Grade" element={<Grade />} />
            <Route exact path="Chapter" element={<Chapter />} />
            <Route exact path="City" element={<City />} />
            {/* temparory commented part  */}

            {/* <Route exact path="Concept Tree" element={<ConceptTree />} /> */}
            {/* temparory commented part  */}

            <Route exact path="Taxonomy" element={<Taxonomy />} />
            <Route exact path="Academic Year" element={<AcademicYear />} />
            <Route
              exact
              path="Taxonomy Category"
              element={<TaxonomyCategory />}
            />
            <Route
              exact
              path="Document Category"
              element={<DocumentCategory />}
            />
            <Route
              exact
              path="Subject And Subtopic"
              element={<SubjectAndSubtopic />}
            />
          </Route>
          {/* dasboadd ending */}

          <Route exact path="/" element={<Login />} />,
          <Route exact path="/login" element={<Login />} />,
          <Route exact path="/mobileotp" element={<MobileOTP />} />,
          {/* <Route exact path="/forgetpassmob" element={<ForgetPassMob />} />, */}
          <Route exact path="/updatepass" element={<UpdatePass />} />,
          <Route exact path="/setPass" element={<SetPass />} />,
          {/* //cordinator routes  */}
          <Route
            exact
            path="/cordinator/dashboard"
            element={<CordinatorDashboard />}
          />
          ,
          <Route exact path="/cordinator/teach" element={<CordinatorTeach />} />
          ,
          <Route
            exact
            path="/cordinator/assign-quiz"
            element={<CordinatorAssignQuiz />}
          />
          ,
          <Route
            exact
            path="/cordinator/formal-assessment"
            element={<CordinatorFormativeAssessment />}
          />
          ,
          <Route
            exact
            path="/cordinator/subjective-papers"
            element={<CoordinatorSubjectivePapers />}
          />,
          <Route
            exact
            path="/cordinator/reading-passport"
            element={<CoordinatorReadingPassport />} />,
          <Route
            exact
            path="/cordinator/avid-reader"
            element={<CoordinatorAvidReader />} />,
          <Route
            exact
            path="/cordinator/passport-performance"
            element={<CoordinatorPerformancePassport />} />,
          <Route
            exact
            path="/cordinator/student-revision"
            element={<CordinatorStudentRevision />}
          />
          ,
          <Route
            exact
            path="/cordinator/reports"
            element={<CordinatorReports />}
          />
          ,
          <Route
            exact
            path="/cordinator/learning-plan"
            element={<CordinatorLearningPlan />}
          />
          {/* <------------- revision reviewer-------------> */}
          <Route
            exact
            path="/revision-reviewer/dashboard"
            element={<ReviewDashboard />}
          />
          {/* 
          <Route
            exact
            path="revision-reviewer/quiz-approval"
            element={<QuizApprovalScreen />}
          /> */}
          <Route
            exact
            path="revision-reviewer/quiz-approval"
            element={<RevisionReviewer />}
          />
          {/* <Route
            exact
            path="revision-reviewer/quiz-approval/:id"
            element={<RevisionReviewer />}
          /> */}
          <Route
            path="/revision-reviewer/view-quizzes"
            element={<ViewQuiz />}
          />
          <Route
            path="/revision-reviewer/view-concept-tree"
            element={<ReviewerConceptTree />}
          />
          ,{/*---Principal Module---*/}
          {/* <Route exact path="/principal/teach" element={<Principal_Teach />} /> */}
        </Routes>
        {/* </Router> */}
      </HashRouter>
    </>
  );
};

export default App;