import React from "react";
import {Pagination} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiPagination-ul": {
      "& > li ": {
        "& button.MuiPaginationItem-page.Mui-selected": {
          background: "#FFA500",
          color: "white",
        },
      },
    },
  },
});

// export default useStyles;

function Pagination1({ handleChange, page, pageDetail }) {
  const classes = useStyles();
  return (
    <div style={{ display: "block" }}>
      <Pagination
        className={classes.root}
        count={pageDetail.totalPages}
        shape="rounded"
        // color="primary"
        sx={{
          color: 'white'
        }}
        page={page}
        onChange={handleChange}
      />
    </div>
  );
}

export default Pagination1;
