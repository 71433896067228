import React, { useEffect, useState } from "react";
import styled from "styled-components";
import icon from "../../../../img/Learning.png";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import axios from "axios";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import dashboardContentStyles from '../../../css/dashboardContent.module.css'

function FilterBar({
	setFilterState,
	filterState,
	setGrades,
	grades,
	setSubject,
	subjects,
	boards,
	setBoard,
	handleChange,
	selectedMenu,
}) {
	const token = sessionStorage.getItem("token");
	const fetchGrade = () => {
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setGrades(res.data.data);
			});
	};

	const fetchSubject = () => {
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/all`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setSubject(res.data.data);
			});
	};

	const fetchBoard = () => {
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/all`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				setBoard(res.data.data);
			});
	};

	useEffect(() => {
		fetchGrade();
		fetchSubject();
		fetchBoard();
	}, []);

	return (
		<Container>
			<LettSection>
				<Icon>
					<Img src={icon} alt="Icon" />
				</Icon>
				<TitleLabel>
					Quiz&nbsp;Approval&nbsp;
					{selectedMenu === "PENDING"
						? "Pending"
						: selectedMenu === "APPROVED"
						? "Approved"
						: selectedMenu === "REJECTED"
						? "Rejected"
						: null}
				</TitleLabel>
			</LettSection>
			<RightSection>
				<InputSection>
					<FormControl fullWidth>
						<InputLabel
							sx={{
								// fontSize: "14px",
								background: "#fff",
								marginBottom: 6,
								"&.Mui-focused": {
									color: "orange",
								},
								fontSize: "16px",
							}}
							id="demo-simple-select-standard-label"
							style={{
								color:'orange'
							  }}
						>
							Board
						</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							onChange={handleChange}
							style={{color:"orange" }}
							name="board"
							sx={{
								width: "100%",
								height: "100%",
								border: "none",
								outline: "none",
								borderRadius: "25px",
								overflow: "hidden",
								backgroundColor: "#fff",
								"& .css-10hburv-MuiTypography-root": {
									fontSize: "12px !important",
								},
								"&.MuiOutlinedInput-root": {
									"& > fieldset": {
										border: "1px solid #D6D5DF !important",
									},
								},
								"&.MuiOutlinedInput-root.Mui-focused": {
									"& > fieldset": {
										border: "1px solid #272727 !important",
									},
								},
								fontSize: "14px !important",
							}}
						>
							<MenuItem
								sx={{ borderBottom: "1px solid #e9e9e9" }}
								value=""
							>
								None
							</MenuItem>
							{boards?.map((data, i) => (
								<MenuItem
									// sx={{ borderBottom: "1px solid #e9e9e9" }}
									// value={data.id}
									// key={i}
									value={data.id}
									key={i}
									id={`az-revisionreviewer-boarddropdown-${data.id.toLowerCase().replace(/ /g, "_")}`}
									className={
										dashboardContentStyles.menu_item
									}
									sx={{
										"&.Mui-selected": {
											backgroundColor: 'orange',
											color: "#FFFFFF"
										},
										"&.Mui-selected:hover": {
											backgroundColor: '#fcedc2',
											color: "#000000"
										},
										"&:not(.Mui-selected):hover": {
											backgroundColor: '#fcedc2',
											color: '#000000',
										},
									}}
								>
									{data.board}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</InputSection>
				<InputSection>
					<FormControl fullWidth>
						<InputLabel
							sx={{
								background: "#fff",
								// fontSize: "14px",
								marginBottom: 6,
								"&.Mui-focused": {
									color: "orange",
								},
								fontSize: "16px",
							}}
							id="demo-simple-select-standard-label"
							style={{
								color:'orange'
							  }}
						>
							Grade
						</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							onChange={handleChange}
							name="grade"
							style={{ color:"orange" }}
							sx={{
								width: "100%",
								height: "100%",
								border: "none",
								outline: "none",
								borderRadius: "25px",
								overflow: "hidden",
								backgroundColor: "#fff",
								"& .css-10hburv-MuiTypography-root": {
									fontSize: "12px !important",
								},
								"&.MuiOutlinedInput-root": {
									"& > fieldset": {
										border: "1px solid #D6D5DF !important",
									},
								},
								"&.MuiOutlinedInput-root.Mui-focused": {
									"& > fieldset": {
										border: "1px solid #272727 !important",
									},
								},
								fontSize: "14px !important",
							}}
						>
							<MenuItem
								sx={{ borderBottom: "1px solid #e9e9e9" }}
								value=""
							>
								None
							</MenuItem>
							{grades?.map((data, i) => (
								<MenuItem
									// sx={{ borderBottom: "1px solid #e9e9e9" }}
									// key={i}
									// value={data.id}
									value={data.id}
									key={i}
									id={`az-revisionreviewer-gradedropdown-${data.id.toLowerCase().replace(/ /g, "_")}`}
									className={
										dashboardContentStyles.menu_item
									}
									sx={{
										"&.Mui-selected": {
											backgroundColor: 'orange',
											color: "#FFFFFF"
										},
										"&.Mui-selected:hover": {
											backgroundColor: '#fcedc2',
											color: "#000000"
										},
										"&:not(.Mui-selected):hover": {
											backgroundColor: '#fcedc2',
											color: '#000000',
										},
									}}
								>
									{data.grade}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</InputSection>
				<InputSection>
					
					<FormControl fullWidth>
						<InputLabel
							sx={{
								background: "#fff",
								// fontSize: "14px",
								marginBottom: 6,
								"&.Mui-focused": {
									color: "orange",
								},
								fontSize: "16px",
							}}
							id="demo-simple-select-standard-label"
							style={{
								color:'orange'
							  }}
						>
							Subject
						</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							onChange={handleChange}
							name="subject"
							style={{ color:"orange" }}
							sx={{
								width: "100%",
								height: "100%",
								border: "none",
								outline: "none",
								borderRadius: "25px",
								overflow: "hidden",
								backgroundColor: "#fff",
								"& .css-10hburv-MuiTypography-root": {
									fontSize: "12px !important",
								},
								"&.MuiOutlinedInput-root": {
									"& > fieldset": {
										border: "1px solid #D6D5DF !important",
									},
								},
								"&.MuiOutlinedInput-root.Mui-focused": {
									"& > fieldset": {
										border: "1px solid #272727 !important",
									},
								},
								fontSize: "14px !important",
							}}
						>
							<MenuItem
								sx={{ borderBottom: "1px solid #e9e9e9" }}
								value=""
							>
								None
							</MenuItem>
							{subjects?.map((data, i) => (
								<MenuItem
									// sx={{ borderBottom: "1px solid #e9e9e9" }}
									// value={data.id}
									// key={i}
									value={data.id}
									key={i}
									id={`az-revisionreviewer-subjectdropdown-${data.id.toLowerCase().replace(/ /g, "_")}`}
									className={
										dashboardContentStyles.menu_item
									}
									sx={{
										"&.Mui-selected": {
											backgroundColor: 'orange',
											color: "#FFFFFF"
										},
										"&.Mui-selected:hover": {
											backgroundColor: '#fcedc2',
											color: "#000000"
										},
										"&:not(.Mui-selected):hover": {
											backgroundColor: '#fcedc2',
											color: '#000000',
										},
									}}
								>
									{data.subject}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</InputSection>

				<SearchSection>
					<Input
						placeholder="Search..."
						onChange={handleChange}
						name="searchParams"
					/>
					<SearchOutlinedIcon
						sx={{ fontSize: "26px ", color: "#f98a03" }}
					/>
				</SearchSection>
			</RightSection>
		</Container>
	);
}

export default FilterBar;
const Container = styled.div`
	display: flex;
	padding: 0;
`;
const LettSection = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;
const RightSection = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;
const Icon = styled.span`
	display: block;
	width: 30px;
	margin-right: 10px;
`;
const Img = styled.img`
	display: block;
	width: 100%;
`;
const TitleLabel = styled.p`
	font-size: 18px;
	color: #000;
`;

const InputSection = styled.div`
	width: 180px;
	/* border: 1px solid #dcdbe4; */
	height: 40px;
	border-radius: 25px;
	/* overflow: hidden; */
	/* padding: 0 10px; */
	margin-right: 10px;
	/* background-color: #fff; */
	/* .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
		background-color: #fff;
	} */
	/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
		height: 30px;
	} */
`;
// const Select = styled.select`
// 	width: 100%;
// 	height: 100%;
// 	outline: none;
// 	border: none;
// 	color: #7a7a7a;
// 	white-space: nowrap;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// `;
// const Option = styled.option``;
const SearchSection = styled.div`
	width: 250px;
	border: 1px solid #dcdbe4;
	height: 45px;
	margin-top: 10px;
	border-radius: 25px;
	overflow: hidden;
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
`;
const Input = styled.input`
	width: 100%;
	height: 100%;
	outline: none;
	border: none;
	font-size: 16px;
`;
