import axios from 'axios'

async function change_student_DeActive(selectedItems) {
  let token = sessionStorage.getItem("token");
  let item = {
    "students": [
      ...selectedItems
    ],
    "branchId": sessionStorage.getItem("branchid"),
    "schoolId": sessionStorage.getItem("schoolid"),
    "active": true
  }
    let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students/de-activate-profile`,item,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
    })
    .catch(err=>console.log(err))
}

export default change_student_DeActive;