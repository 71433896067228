import React from 'react'
import '../planTemplate.css'

const SinglePlanTabHead = ({setIsBluePrintTab,isBluePrintTab}) => {
  return (
    <div className='sp-tabhead'>
        <div className={`sp-tab ${!isBluePrintTab ? 'sp-active-tab':''}`} onClick={()=>setIsBluePrintTab(false)} >
          <p>Plans</p> </div>
        <div className={`sp-tab ${isBluePrintTab ? 'sp-active-tab':''}`}   onClick={()=>setIsBluePrintTab(true)}>
          <p>Blueprint levels</p>
          </div>
    </div>
  )
}

export default SinglePlanTabHead