import axios from 'axios';
import CommonSort from "../../js/CommonSort"
 
async function associateChapter_get(setChapters, page , setPageDetail ,boardInput , gradeInput , subjectInput ) {
  let token = sessionStorage.getItem("token");

    if (gradeInput !== "" || subjectInput !== "" || boardInput !== ""){
      if(gradeInput !== "" && subjectInput === "" && boardInput === ""){
        var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=100000&gradeId=${gradeInput}`;
      }
      if (gradeInput === "" && subjectInput !== "" && boardInput === ""){
        var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=100000&subjectId=${subjectInput}`;
      }
      if (gradeInput === "" && subjectInput === "" && boardInput !== ""){
        var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=100000&boardId=${boardInput}`;
      }
      if(gradeInput !== "" && subjectInput !== "" && boardInput === ""){
        var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=100000&gradeId=${gradeInput}&subjectId=${subjectInput}`;
      }
      if(gradeInput === "" && subjectInput !== "" && boardInput !== ""){
        var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=100000&subjectId=${subjectInput}&boardId=${boardInput}`;
      }
      if(gradeInput !== "" && subjectInput === "" && boardInput !== ""){
        var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=100000&gradeId=${gradeInput}&boardId=${boardInput}`;
      }
      if(gradeInput !== "" && subjectInput !== "" && boardInput !== ""){
        var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=100000&gradeId=${gradeInput}&subjectId=${subjectInput}&boardId=${boardInput}`;
      }
    } else{
      var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageNumber=${page - 1}&pageSize=100000`
    }
   
    let result = await axios.get(URL, 
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async(json)=>{
      if(json.data.data){
        json.data.data.data = await CommonSort(json?.data?.data?.data || [], "chapter", false);
        setChapters(json.data.data.data)
        setPageDetail(json.data.data)
      }
    })
    .catch(err=>console.log(err))
}

export default associateChapter_get;
