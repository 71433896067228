import React from "react";
import Navbar from "../../Navbar";
import ViewQuizContent from "./ViewQuizContent";
import MainContainer from "../Dashboard/MainContainer";

function ViewQuiz() {
	return (
		<>
			<Navbar />
			<MainContainer children={<ViewQuizContent />} headerTitle={""} />
		</>
	);
}

export default ViewQuiz;
