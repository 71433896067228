import React, { useEffect, useState } from "react";
import TopBar from "../../components/common/TopBar";
import axios from "axios";
import { FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import dashboardContentStyles from "../css/dashboardContent.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const ProgressReport = () => {
    const [modifiedDate, setModifiedDate] = useState({});
    const [gradeList, setGradeList] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [subjectList, setSubjectList] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState('');
    const [onProgressData, setOnProgressData] = useState([]);
    const [onSubTopicRenderArray, setonSubTopicRenderArray] = useState([]);
    const token = sessionStorage.getItem("token");
    const userType = localStorage.getItem('role');

    const GetLastModifiedAt = async () => {
        let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/last-modified-at`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((json) => {
                setModifiedDate(json?.data?.data)
            })
            .catch(err => console.log(err))
    }

    const fetchAllGrades = async () => {
        try {
            const teacherId = sessionStorage.getItem("teacherId");

            let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${teacherId}`;

            const res = await axios.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            const grades = await res?.data?.data || [];
            setGradeList(grades);
            setSelectedGrade(grades[0]?.id);
        } catch (error) {
            console.error("Error fetching grades:", error);
            setGradeList([]);
            setSelectedGrade('');
        }
    };


    useEffect(() => {
        GetLastModifiedAt();
        fetchAllGrades();
    }, [])

    useEffect(() => {
        const fetchSubjectData = async () => {
            try {
                let boardId = sessionStorage.getItem('boardId');
                let teacherId = sessionStorage.getItem("teacherId");
                let url;
                // switch (userType) {
                //     case "TEACHER":
                //         url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${teacherId}&gradeId=${selectedGrade}`;
                //         break;
                //     case "PRINCIPAL":
                //         url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/subjects?boardId=${boardId}&gradeId=${selectedGrade}`;
                //         break;
                //     case "COORDINATOR":
                //         url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/subjects?boardId=${boardId}&gradeId=${selectedGrade}`;
                //         break;
                //     default:
                //         throw new Error("Invalid userType");
                // }

                url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${teacherId}&gradeId=${selectedGrade}`;


                const response = await axios.get(url, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
                // console.log(response.data.data, "27-6-24", userType)
                // if(userType ==="PRINCIPAL" || userType ==="COORDINATOR"){
                // response.data.data = response?.data?.data?.filter(v => v.skilledSubject === "true") || [];
                // }else if(userType ==="TEACHER"){
                // response.data.data = response?.data?.data?.filter(v => v.skilledSubject === true) || []
                // }
                // console.log("response?.data?.data", response?.data?.data)
                setSubjectList(response?.data?.data);
                setSelectedSubject(response?.data?.data[0]?.subjectId);
            } catch (error) {
                console.error("Error fetching subject data:", error);
                setSubjectList([]);
                setSelectedSubject('')
            }
        };


        if (selectedGrade) {
            fetchSubjectData();
        }
    }, [selectedGrade])

    const fetchProgressData = async () => {
        let boardId = sessionStorage.getItem("boardId");
        let branchId = sessionStorage.getItem("branchId");
        let schoolId = sessionStorage.getItem("schoolId");

        let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/chaptercount_progress?boardId=${boardId}&branchId=${branchId}&schoolId=${schoolId}&gradeId=${selectedGrade}&subjectId=${selectedSubject}`
        try {
            const response = await axios.get(url,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const data = await response?.data?.data;
            if (relatedSubName?.hideSubtopics) {
                onRenderData(data)
            } else {
                convertToRenderArray(data)
            }
        } catch (error) {
            console.error("Error fetching progress data:", error);
            setOnProgressData([]);
            setonSubTopicRenderArray([]);
        }
    };

    useEffect(() => {
        if (selectedGrade && selectedSubject)
            fetchProgressData();

    }, [selectedGrade, selectedSubject]);

    const handleSelect = (selectedOption) => {
        if (selectedOption !== null) {
            setSelectedGrade(selectedOption.target.value);
        } else {
            setSelectedGrade(null);
        }
    };

    const subjectChangeHandler = (e) => {
        setSelectedSubject(e.target.value);
    };

    const onRenderData = (data) => {

        const sectionAggregates = data?.sections?.reduce((acc, entry) => {
            const section = entry.section;
            if (!acc[section]) {
                acc[section] = { section: entry?.section, chaptersCompleted: 0, testReleased: 0, totalChapters: entry?.totalChapters };
            }
            acc[section].chaptersCompleted += entry?.chaptersCompleted;
            acc[section].testReleased += entry?.testReleased;
            return acc;
        }, {});

        const renderArray = Object?.values(sectionAggregates);
        setOnProgressData(renderArray);
        setonSubTopicRenderArray([]);
    }

    const convertToRenderArray = (data) => {

        // Initialize an empty render array
        let renderArray = [];

        // Iterate over each section in the data
        data.sections.forEach(section => {
            // Iterate over each subTopic in the section
            section.subTopics.forEach(subTopic => {
                // Check if the subTopic already exists in renderArray
                let existingSubTopic = renderArray.find(item => item.subTopic === subTopic.subTopic);

                if (existingSubTopic) {
                    // If subTopic exists, update the existing section or add a new section
                    let existingSection = existingSubTopic.section.find(item => item.section === section.section);
                    if (existingSection) {
                        // Update existing section
                        existingSection.totalChapters = subTopic.totalChapters;
                        existingSection.chaptersCompleted += subTopic.chaptersCompleted;
                        existingSection.testReleased += subTopic.testReleased;
                    } else {
                        // Add new section
                        existingSubTopic.section.push({
                            section: section.section,
                            totalChapters: subTopic.totalChapters,
                            chaptersCompleted: subTopic.chaptersCompleted,
                            testReleased: subTopic.testReleased
                        });
                    }
                } else {
                    // If subTopic does not exist, add new subTopic with the section
                    renderArray.push({
                        subTopic: subTopic.subTopic,
                        section: [{
                            section: section.section,
                            totalChapters: subTopic.totalChapters,
                            chaptersCompleted: subTopic.chaptersCompleted,
                            testReleased: subTopic.testReleased
                        }]
                    });
                }
            });
        });
        setonSubTopicRenderArray(renderArray);
        setOnProgressData([]);
    };

    let relatedSubName = subjectList?.find(sub => sub?.subjectId === selectedSubject);

    return <>
        <div style={{ width: "100%", height: "100vh" }}>
            <TopBar
                title={"Home"}
                nav1={"Progress Report"}
                subSubTitle={"Progress Report"}
                modifiedDate={modifiedDate}
            />
            <div className="progress-container">
                <div className="progress-table" style={{ width: "90%", margin: "0 auto" }}>
                    <div style={{ display: "flex", columnGap: "20px", marginBottom: "20px" }}>
                        {/* <div className="dropdown-container ">
                            <select className="dropdown cursorPointer" onChange={handleSelect} value={selectedGrade}>
                                <option className="cursorPointer" value="">select Grade</option>
                                {gradeList && gradeList?.map((o) => {
                                    return (
                                        <option className="cursorPointer" value={o?.id}>{o?.grade}</option>
                                    )
                                })}
                            </select>
                        </div> */}
                        {/* <div className="dropdown-container ">
                            <select className="dropdown cursorPointer" onChange={subjectChangeHandler} value={selectedSubject}>
                                <option className="cursorPointer" value="">Select Subject</option>
                                {subjectList && subjectList?.map((o) => {
                                    return (
                                        <option className="cursorPointer" value={o?.subjectId}>{o?.subject}</option>
                                    )
                                })}
                            </select>
                        </div> */}
                        <FormControl >
                            <InputLabel
                                sx={{
                                    fontSize: '13px',
                                    color: '#e7ab3c',
                                    top: '-10px',
                                    '&.MuiInputLabel-shrink': {
                                        top: '0',
                                    },
                                    '&.Mui-focused': {
                                        color: '#e7ab3c'
                                    }
                                }}
                                className='dropDownLabel'
                                id="demo-simple-select-label"
                            >
                                Grade
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                input={<OutlinedInput label='Grades ' />}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            width: '150px',
                                            marginTop: '2px',
                                            maxHeight: '190px',
                                            overflowY: 'auto',
                                            '&::-webkit-scrollbar': {
                                                width: '2px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                background: '#f1f1f1',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                background: '#888',
                                                borderRadius: '4px',
                                            },
                                            '&::-webkit-scrollbar-thumb:hover': {
                                                background: '#555',
                                            },
                                        },
                                    },
                                    MenuListProps: {
                                        sx: {
                                            padding: '0px',
                                            overflowY: 'auto',
                                        },
                                    },
                                }}
                                IconComponent={(props) => (
                                    <ExpandMoreIcon
                                        {...props}
                                        sx={{ fontSize: "1.5vw" }}
                                    />
                                )}
                                sx={{
                                    width: '150px',
                                    height: '30px',
                                    padding: '10px',
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#000',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#000',
                                    },
                                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#e7ab3c',
                                    }
                                }}
                                className='dropdownSelect'
                                value={selectedGrade}
                                onChange={handleSelect}
                            >
                                {gradeList && gradeList?.map((o) => {
                                    return (
                                        <MenuItem
                                            value={o?.id}
                                            key={o?.id}
                                            className={dashboardContentStyles.menu_item}
                                            sx={{
                                                fontSize: '10px',
                                                backgroundColor: 'white',
                                                '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                },
                                                '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                        backgroundColor: '#f4d788',
                                                    },
                                                },
                                            }}
                                        >
                                            <ListItemText primary={o?.grade} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl >
                            <InputLabel
                                sx={{
                                    fontSize: '13px',
                                    color: '#e7ab3c',
                                    top: '-10px',
                                    '&.MuiInputLabel-shrink': {
                                        top: '0',
                                    },
                                    '&.Mui-focused': {
                                        color: '#e7ab3c'
                                    }
                                }}
                                className='dropDownLabel'
                                id="demo-simple-select-label"
                            >
                                Subject
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                input={<OutlinedInput label='Subjects ' />}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            width: '150px',
                                            marginTop: '2px',
                                            maxHeight: '190px',
                                            overflowY: 'auto',
                                            '&::-webkit-scrollbar': {
                                                width: '2px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                background: '#f1f1f1',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                background: '#888',
                                                borderRadius: '4px',
                                            },
                                            '&::-webkit-scrollbar-thumb:hover': {
                                                background: '#555',
                                            },
                                        },
                                    },
                                    MenuListProps: {
                                        sx: {
                                            padding: '0px',
                                            overflowY: 'auto',
                                        },
                                    },
                                }}
                                IconComponent={(props) => (
                                    <ExpandMoreIcon
                                        {...props}
                                        sx={{ fontSize: "1.5vw" }}
                                    />
                                )}
                                sx={{
                                    width: '150px',
                                    height: '30px',
                                    padding: '10px',
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#000',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#000',
                                    },
                                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#e7ab3c',
                                    }
                                }}
                                className='dropdownSelect'
                                value={selectedSubject}
                                onChange={subjectChangeHandler}
                            >
                                {subjectList && subjectList?.map((o) => {
                                    return (
                                        <MenuItem
                                            value={o?.subjectId}
                                            key={o?.subjectId}
                                            className={dashboardContentStyles.menu_item}
                                            sx={{
                                                fontSize: '10px',
                                                backgroundColor: 'white',
                                                '&:hover': {
                                                    backgroundColor: '#f4d788',
                                                },
                                                '&.Mui-selected': {
                                                    backgroundColor: '#e7ab3c',
                                                    '&:hover': {
                                                        backgroundColor: '#f4d788',
                                                    },
                                                },
                                            }}
                                        >
                                            <ListItemText primary={o?.subject} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ overflowX: "auto", scrollbarWidth: "thin" }}>
                        {relatedSubName?.hideSubtopics ? <table class="dashboard-table">
                            <thead>
                                <th scope="row" style={{ textAlign: "start" }}>{relatedSubName?.subject}</th>
                                {onProgressData && onProgressData?.map(o => <th>{o?.section}</th>)}
                            </thead>

                            <tbody>

                                <tr>
                                    <td scope="row" style={{ textAlign: "start" }}>Total Chapters</td>
                                    {/* {onProgressData?.length > 0 && <td colSpan={`${onProgressData?.length}`}>{onProgressData[0]?.totalChapters}</td>} */}
                                    {onProgressData && onProgressData?.map(o => <td>{o?.totalChapters}</td>)}
                                </tr>
                                <tr>
                                    <td scope="row" style={{ textAlign: "start" }}>Chapters Completed</td>
                                    {onProgressData && onProgressData?.map(o => <td>{o?.chaptersCompleted}</td>)}
                                </tr>
                                <tr>
                                    <td scope="row" style={{ textAlign: "start" }}>Tests Released</td>
                                    {onProgressData && onProgressData?.map(o => <td>{o?.testReleased}</td>)}
                                </tr>
                            </tbody>
                        </table> : <> {onSubTopicRenderArray && onSubTopicRenderArray?.map((obj, i) => {
                            return (<table class="dashboard-table">
                                <thead>
                                    <th scope="row" style={{ textAlign: "start" }}>{obj?.subTopic}</th>
                                    {obj?.section && obj?.section?.map((o) => {
                                        return <th>{o?.section}</th>
                                    })}
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row" style={{ textAlign: "start" }}>Total Chapters</td>
                                        <td colSpan={obj?.section?.length}>{obj?.section[0]?.totalChapters}</td>
                                    </tr>
                                    <tr>
                                        <td scope="row" style={{ textAlign: "start" }}>Chapters Completed</td>
                                        {obj?.section && obj?.section?.map((o) => {
                                            return <td>{o?.chaptersCompleted}</td>
                                        })}
                                    </tr>
                                    <tr>
                                        <td scope="row" style={{ textAlign: "start" }}>Tests Released</td>
                                        {obj?.section && obj?.section?.map((o) => {
                                            return <td>{o?.testReleased}</td>
                                        })}
                                    </tr>
                                </tbody>
                            </table>)
                        })}</>}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ProgressReport;