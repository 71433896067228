import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DashboardLayer from '../../../../components/DashboardLayer/DashboardLayer'
import './dashboard.css'
import { fetchDashboardQuizPerformance, fetchDashboardChapterWisePerformance, fetchQuizzesTotal, fetchStudentData } from '../Apis/Dashboard_Api'
import CardV1 from './CardV1'
import HorizontalScrollContainer from '../../../../components/HorizontalScrollContainer/HorizontalScrollContainer'
import HorizontallyScrollableContainerV2 from '../../../../components/HorizontallyScrollableContainerV2/HorizontallyScrollableContainerV2'

import DashboardBarChartCard from '../../../../components/DashboardBarChartCard/DashboardBarChartCard'
import { extractTextAfterExclamation, extractTextIncludingExclamation, isNOTNullOrUndefined, isNullOrUndefined } from '../../../../utils/helper'
import SpinLoader from '../../../../components/SpinLoader/SpinLoader'
import DashboardMessageModal from '../../../../components/DashboardMessageModal/DashboardMessageModal'
import PercentProgressCards from './PercentProgressCards'

const Student_Dashboard = () => {
    const userName = localStorage.getItem('userName')
    const role = localStorage.getItem('role')
    const [loading, setLoading] = useState(true)
    const [studentDetailsFetched, setStudentDetailsFetched] = useState(false)
    const [selectedSubject, setSelectedSubject] = useState(null)
    const [quizPerformanceData, setQuizPerformanceData] = useState([])
    const [chapterPerformanceData, setChapterPerformanceData] = useState([])
    const [message,setMessage] = useState('')
    const [showMessageModal,setShowMessageModal]= useState(false)
    const [showEmberCategoryMessage,setShowEmberCategoryMessage] = useState(false)
    const [branchStateId, setBranchStateId] = useState("");

    const token = sessionStorage.getItem("token");

    const fetchAndSetStudentData = async () => {
        var response = await fetchStudentData(userName, role)
        sessionStorage.setItem("studentId", response?.id);
        sessionStorage.setItem("schoolId", response?.schoolId);
        sessionStorage.setItem("branchId", response?.branchId);
        sessionStorage.setItem("gradeId", response?.gradeId);
        sessionStorage.setItem("sectionId", response?.sectionId);
        sessionStorage.setItem("boardId", response?.boardId);
        sessionStorage.setItem("sectionId", response?.sectionId);
        sessionStorage.setItem("gradeName",response?.grade);
        sessionStorage.setItem("studentName",response?.firstName+response?.lastName);
        sessionStorage.setItem("sectionName",response?.section);
        // const response = await fetchDashboardQuizPerformance()

        setBranchStateId(response?.branchId);
        setStudentDetailsFetched(true);
    }

    const fetchQuizPerformanceData = async () => {
        setLoading(true)
        var response = await fetchDashboardQuizPerformance()
        response = response?.filter(v => v.skilledSubject === true) || [];
        setQuizPerformanceData(response)
        setSelectedSubject(response[0])
        setLoading(false)
    }

    const fetchChapterWiseData = async () => {
        setLoading(true)
        const response = await fetchDashboardChapterWisePerformance(selectedSubject?.subjectId)
        setChapterPerformanceData(response)
        setLoading(false)
    }

    //check whether student details are already saved in session
    //if not fetch and save student details
    useEffect(() => {
        const stdId = sessionStorage.getItem('studentId')
        if (isNOTNullOrUndefined(stdId)) {
            setStudentDetailsFetched(true)
        } else {
            fetchAndSetStudentData()
        }
    }, [])


    //fetch quiz performance data once student details are available
    useEffect(() => {
        if (studentDetailsFetched) fetchQuizPerformanceData()
    }, [studentDetailsFetched])

    //fetch chapter wise performance data once a subject is selected
    useEffect(() => {
        if (isNOTNullOrUndefined(selectedSubject)) {
            fetchChapterWiseData()
        }
    }, [selectedSubject])

    useEffect(async () => {
      if (branchStateId) {
        const planDetails = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${branchStateId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(planDetails);
        sessionStorage.setItem("planId", planDetails.data?.data?.plans[0]?.planId);
      }
    }, [branchStateId]);

    // if (loading) return <SpinLoader />

    return (
        <DashboardLayer>
            <section className="DashWrapper">
                  <PercentProgressCards subject={selectedSubject} />
                <h2 className="dashboard-heading">Quiz Performance</h2>
                <div style={{ width: '100%', marginTop: '7px',marginBottom:'35px' }}>
                    <HorizontalScrollContainer>
                        {quizPerformanceData?.map(i => <DashboardBarChartCard
                            key={i?.subjectId}
                            title={i?.subjectName}
                            first_label={'Student'}
                            second_label={'Average'}
                            first_percent={i?.studentPercentage}
                            second_percent={i?.averagePercentage}
                            selected={selectedSubject?.subjectId === i?.subjectId}
                            onCardClick={() => setSelectedSubject(i)}
                            onLabelClick={(e) => {
                                e.stopPropagation()
                                setShowMessageModal(true)
                                setMessage(i?.studentPercentageMessage)
                            }}
                        />)}

                    </HorizontalScrollContainer>
                </div>
                <HorizontallyScrollableContainerV2 displayEmberCategoryMessage={()=>{
                    setShowEmberCategoryMessage(true)
                    setShowMessageModal(true)
                }} data={chapterPerformanceData?.chapters} />

            </section>
            <DashboardMessageModal
            showMessageModal={showMessageModal}
            title={extractTextIncludingExclamation(message)}
            content={extractTextAfterExclamation(message)}
            onMessageModalClose={()=>{
                setShowMessageModal(false)
                setShowEmberCategoryMessage(false)
                setMessage('')
            }}
            isEmberCategoryMessage={showEmberCategoryMessage}
            />

        </DashboardLayer>
    )
}

export default Student_Dashboard
