import axios from 'axios'
import commonsort from "../../js/CommonSort";

async function StudentsControllerGet(
  setStudentListData,
  setPageDetail,
  page,
  rowsInput,
  search,
  gradeInput,
  sectionInput,
  sortBy,
  sortOrder
) {
  let token = sessionStorage.getItem("token");
  // console.log("sortBy==============================================", sortBy)
  if (gradeInput !== "" || sectionInput !== "") {
    if (gradeInput !== "" && sectionInput === "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/students?pageNumber=${page - 1}&pageSize=${rowsInput.rows
        }&sortBy=${sortBy || "id"}&sortOrder=${sortOrder || false}&gradeId=${gradeInput}&branchId=${sessionStorage.getItem(
          "branchid"
        )}&schoolId=${sessionStorage.getItem("schoolid")}&search=${search.search}
  `;
    }
    if (gradeInput === "" && sectionInput !== "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/students?pageNumber=${page - 1}&pageSize=${rowsInput.rows
        }&sortBy=${sortBy || "id"}&sortOrder=${sortOrder || false}&sectionId=${sectionInput}&branchId=${sessionStorage.getItem(
          "branchid"
        )}&schoolId=${sessionStorage.getItem("schoolid")}&search=${search.search}
  `;
    }
    if (gradeInput !== "" && sectionInput !== "") {
      var URL = `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/students?pageNumber=${page - 1}&pageSize=${rowsInput.rows
        }&sortBy=${sortBy || "id"}&sortOrder=${sortOrder || false}&sectionId=${sectionInput}&gradeId=${gradeInput}&branchId=${sessionStorage.getItem(
          "branchid"
        )}&schoolId=${sessionStorage.getItem("schoolid")}&search=${search.search}
  `;
    }
  } else {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students?pageNumber=${page - 1}&pageSize=${rowsInput.rows
      }&sortBy=${sortBy || "id"}&sortOrder=${sortOrder || false}&branchId=${sessionStorage.getItem(
        "branchid"
      )}&schoolId=${sessionStorage.getItem("schoolid")}&search=${search.search}
  `;
  }
  console.log("URL", URL)
  let result = await axios.get(`${URL}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (json) => {
      console.log(sortBy, sortOrder, json.data.data.data);
      // var sortedData = await commonsort(json.data.data.data || [], sortBy, sortOrder);
      // console.log("sortedData", sortedData)
      setStudentListData(json?.data?.data?.data)
      setPageDetail(json.data.data)

    })
    .catch(err => console.log(err))

}

export default StudentsControllerGet;
