import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BreadCrums from "./BreadCrums";
import QuizCard from "./QuizCard";
import SelectSection from "./SelectSection";
import axios from "axios";
import CreateQuizModal from "./Modals/CreateQuizModal";
import { isNOTNullOrUndefined } from "../../../../utils/helper";
import CommonSort from "../../../js/CommonSort"

function ListQuiz({ setModal, onNext }) {
	const [seletedOptions, setSeletedOption] = useState([]);

	const [selectedSubject, setSelectedSubject] = useState(null);
	const [selectedBoard, setSelectedBoard] = useState(null);
	const [selectedGrade, setSelectedGrade] = useState(null);
	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedSubTopic, setSelectedSubTopic] = useState(null);

	const [isModalOpen, setIsModalOpen] = useState(false);

	console.log(selectedSubTopic);

	const TOKEN = sessionStorage.getItem("token");

	const [searchParams, setSearchParams] = useState("");
	const [quizList, setQuizList] = useState([]);

	const filterChapter = (
		arr,
		subject,
		board,
		grade,
		academicYear,
		subTitle,
		searchParams
	) => {
		let filterData = arr.filter(
			(item) =>
				item.subject === subject &&
				item.board === board &&
				item.grade === grade &&
				item.academicYear === academicYear &&
				item.subTitle === subTitle &&
				item.title.includes(searchParams)
		);
		return filterData;
	};

	const BASE_URL = process.env.REACT_APP_SWAGGER_URL;
	const local_data = JSON.parse(localStorage.getItem("quiz_data"));
	useEffect(() => {
		const fetchData = () => {
			axios
				.get(
					`${BASE_URL}v1/api/master/chapters/all-chapters?${isNOTNullOrUndefined(selectedBoard?.id)
						? `boardId=${selectedBoard?.id}`
						: ""
					}		
				${isNOTNullOrUndefined(selectedSubject?.id)
						? `&subjectId=${selectedSubject?.id}`
						: ""
					}${isNOTNullOrUndefined(selectedGrade?.id)
						? `&gradeId=${selectedGrade?.id}`
						: ""
					}${isNOTNullOrUndefined(searchParams)
						? `&search=${searchParams}`
						: ""
					}${isNOTNullOrUndefined(selectedSubTopic?.id)
						? `&subTopicId=${selectedSubTopic.id}`
						: ""
					}`,
					{
						headers: {
							Authorization: `Bearer ${TOKEN}`,
						},
					}
				)
				.then(async (response) => {
					var { data } = response.data;
					// console.log(data,'data')
					data = await CommonSort(data || [], "chapter", false);
					setQuizList(data);
				})
				.catch((error) => console.error(error));
		};
		fetchData();
	}, [
		selectedSubject,
		selectedBoard,
		selectedGrade,
		selectedYear,
		selectedSubTopic,
		searchParams,
	]);

	const onChapterClick = (item) => {
		try {
			localStorage.setItem(
				"quiz_data",
				JSON.stringify({
					...local_data,
					selected_chapter: item,
				})
			);
			setIsModalOpen(true);
		} catch (error) {
			alert(error);
		}
	};

	const handleClick = (quiztype) => {
		console.log("quiz tyupe", quiztype);
	};

	return (
		<Container>
			<BreadCrums
				currentPage={"create quizz"}
				date={"23 Dec 2021"}
				time={"10:30 AM"}
			/>
			<SelectSection
				selectedSubject={selectedSubject}
				setSelectedSubject={setSelectedSubject}
				setSearchParams={setSearchParams}
				title={"Create "}
				selectedBoard={selectedBoard}
				setSelectedBoard={setSelectedBoard}
				selectedGrade={selectedGrade}
				setSelectedGrade={setSelectedGrade}
				selectedYear={selectedYear}
				setSelectedYear={setSelectedYear}
				selectedSubTopic={selectedSubTopic}
				setSelectedSubTopic={setSelectedSubTopic}
			/>
			{selectedBoard && selectedGrade && selectedSubject && selectedYear ? (
				quizList && quizList.length > 0 ? (
					<QuizList>
						{quizList?.map((data) => (
							<CardCover
								className="cursorPointer"
								onClick={() => onChapterClick(data)}
								key={data.id}
							>
								<QuizCard
									img={data.thumbnailPath}
									title={data.chapter}
								/>
							</CardCover>
						))
						}
					</QuizList>
				) : (
					<p className="message">No Data</p>
				)
			) : (
				<p className="message">Select Grade,Board , Subject and Year</p>
			)}
			<CreateQuizModal
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onSubmitPress={() => onNext()}
			/>
		</Container>
	);
}

export default ListQuiz;
const Container = styled.div`
	padding: 20px;
	width: 100%;
	.message {
		width: 100%;
		text-align: center;
		margin-top: 40px;
		color: red;
	}
`;
const QuizList = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	margin-top: 30px;
	grid-gap: 30px;
`;
const CardCover = styled.div``;
