import {
  SET_BLUE_PRINT_DATA,
  SET_INITIAL_DATA,
  SET_IS_SCROLLING_TO_TOP
} from '../constants/bluePrintConstants'

let initialData = {
  paperTitle: '',
  paperTypeId: '',
  blueprintLevelId: '',
  boardId: '',
  gradeId: '',
  subjectId: '',
  totalQuestions: '',
  totalMark: '',
  totalTime: ''
}
const initialState = {
  bluePrintData: {},
  isScrollingToTop: false
}

export const bluePrintReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_DATA:
      return {
        ...state,
        bluePrintData: initialData
      }
    case SET_BLUE_PRINT_DATA:
      return {
        ...state,
        bluePrintData: action.payload
      }
    case SET_IS_SCROLLING_TO_TOP:
      return {
        ...state,
        isScrollingToTop: action.payload
      }

    default:
      return state
  }
}
