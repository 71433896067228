import { Box, Modal, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import YellowButton from "../../components/common/yellowButton";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: 0,
  // boxShadow: 24,
  borderRadius: 2,
  p: 4,
};
const CoordinatorGradeModal = ({ open, onClose, grades, deleteHandler }) => {
  const list = grades?.grades;
  const [gradeList, setGradeList] = useState(list);
  const [idList, setIdList] = useState([]);
  useEffect(() => {
    if (list) {
      setGradeList(list);
    }
  }, [list]);
  const idHandler = (id) => {
    setIdList([...idList, id]);
    const updateValue = gradeList?.filter(
      (item) => item.coordinatorGradeId !== id
    );
    setGradeList(updateValue);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.125rem",
          }}
        >
          {grades?.coordinatorType}
          <CloseOutlinedIcon
            onClick={() => {
              onClose();
              setGradeList(list);
            }}
            style={{ cursor: "pointer", color: grey[500] }}
          />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 3 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#FFA700",
              fontWeight: 600,
            }}
          >
            <div>Grade(s)</div>
            <div>Action</div>
          </div>
          <div
            style={{
              width: "100%",
              height: 0,
              border: "1px solid #757575",
              marginTop: 6,
            }}
          />
          <>
            {gradeList?.map((item, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // fontWeight: 600,
                    padding: "10px 0px",
                  }}
                  key={item.gradeId}
                >
                  <div>{item?.grade}</div>
                  <DeleteIconButton
                    onClick={() => idHandler(item?.coordinatorGradeId)}
                  >
                    <DeleteOutlinedIcon style={{ fontSize: "25px" }} />
                  </DeleteIconButton>
                </div>
              );
            })}
          </>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <YellowButton
              onClick={() => deleteHandler(grades.id, idList, setIdList)}
            >
              Save
            </YellowButton>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default CoordinatorGradeModal;
