import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";

function Pagination({
	setPageSize,
	pageSize,
	setCurrentPage,
	currentPage,
	totalData,
	totalPages,
}) {
	const ROWS_PER_PAGE = [5,10,20, 50, 100];
	const BREAK_LABEL = "...";
	const NEXT_LABEL = " >";
	const PREVIOUS_LABEL = "< ";
	const [totalPage, setTotalPage] = useState("");

	const handlePageClick = (event) => {
		const newOffset = event.selected;
		setCurrentPage(newOffset);
	};
	// useEffect(() => {
	// 	setTotalPage(Math.floor(totalData / pageSize));
	// }, [pageSize, totalData]);
	return (
		<Container id="revPagination">
			<LeftSection>
				<p>Row per page</p>
				<Select
				className="cursorPointer"
					onChange={({ target: { value } }) =>
						setPageSize(parseInt(value, 10))
					}
					defaultValue={pageSize}
				>
					{ROWS_PER_PAGE.map((rowPerPage) => (
						<Option key={rowPerPage}>{rowPerPage}</Option>
					))}
				</Select>
			</LeftSection>
			<RightSection>
				<ReactPaginate
					breakLabel={BREAK_LABEL}
					nextLabel={NEXT_LABEL}
					onPageChange={handlePageClick}
					pageRangeDisplayed={2}
					pageCount={totalPages}
					previousLabel={PREVIOUS_LABEL}
					renderOnZeroPageCount={null}
					initialPage={0}
					forcePage={currentPage}
				/>
			</RightSection>
		</Container>
	);
}

export default Pagination;
const Container = styled.div`
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0px;
	background-color: #fff;
	height: 60px;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	// z-index: 99;
`;
const LeftSection = styled.div`
	font-size: 14px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;
const RightSection = styled.div`
	ul {
		display: flex;
		align-items: center;
		list-style: none;
		.selected {
			a {
				background: #001af3 !important;
				color: #fff;
			}
		}
		li {
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				height: 35px;
				width: 35px;
				min-width: 35px;
				background-color: #f5f7fb;
				margin-right: 10px;
				cursor: pointer;
			}
			&:last-child {
				a {
					margin-right: 0px;
				}
			}
			color: #48538d;
		}

		.next,
		.previous {
			a {
				background-color: #fff;
				font-size: 22px;
			}
		}
		.disabled {
			a {
				opacity: 0.5;
				cursor: not-allowed;
				background-color: #fff;
				font-size: 22px;
			}
		}
	}
`;
const Select = styled.select`
	padding: 3px 6px;
	margin-left: 10px;
	color: #9e9e9e;
	border-radius: 3px;
`;
const Option = styled.option``;
