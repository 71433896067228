import axios from 'axios'
async function GetLastModifiedAt(setModifiedDate) {
  let token = sessionStorage.getItem("token");
  // let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/last-modified-at`,{
  let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/last-modified-at`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((resp) => {
      setModifiedDate(resp.data);

      return resp.data;
    })
    // .then((json) =>
    //  console.log(json)
    //  )
    .catch((err) => console.log(err));

}

export default GetLastModifiedAt;
