import React from "react";
import styled from "styled-components";

function QuizCard({ title, img }) {
	return (
		<Container>
			<ImgContainer>
				<img src={img} alt="image" />
			</ImgContainer>
			<Title>{title}</Title>
		</Container>
	);
}

export default QuizCard;
const Container = styled.div`
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 2px 5px #0000006b;
	overflow: hidden;
	transition: all 0.2s;
	:hover {
		transform: translateY(-15px);
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		h3 {
			color: #fd8c00;
		}
	}
`;
const ImgContainer = styled.div`
	display: block;
	img {
		width: 100%;
		display: block;
		height: 300px;
		object-fit: cover;
	}
`;
const Title = styled.h3`
	font-size: 16px;
	padding: 10px;
`;
