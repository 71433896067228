import React, { useState } from "react";
import styled from "styled-components";
import sort from "../../../img/sort_1.png";
import ForumTwoToneIcon from "@mui/icons-material/ForumTwoTone";
import { Tooltip } from "react-tooltip";

function QuizTable({
	quizes,
	setSelectedQuiz,
	setCommentModal,
	setRejectModal,
	approveQuiz,
	setPreview,
	currentPage,
	setSortBy,
	setSortOrder,
	pageSize,
	publishCDN,
	selectedMenu
}) {
	const columns = [
		{ field: "id", headerName: "#", width: 40 },
		{
			field: "name",
			headerName: "Quiz Name",
			width: 200,
			sortable: true,
		},
		{
			field: "quizType",
			headerName: "Quiz Type",
			width: 100,
			sortable: true,
		},
		{
			field: "board",
			headerName: "Board",
			width: 90,
			sortable: true,
		},
		{
			field: "grade",
			headerName: "Grade",
			width: 90,
			sortable: true,
		},
		{
			field: "subject",
			headerName: "Subject",
			width: 100,
			sortable: true,
		},
		{
			field: "subTopic",
			headerName: "Sub Topic",
			width: 100,
			sortable: true,
		},
		{
			field: "chapterList",
			headerName: "Chapter",
			width: 200,
			sortable: true,
		},
	];

	const handleSort = (key_name) => {
		// let field = key_name.toLowerCase(); //cmted on 20-6-24 bcz quizType T is capital in realdata but we converting all to lowercase
		let field = key_name;
		setSortBy(field);
		setSortOrder((prev) => !prev);
	};
	function chapterName(item) {
		let temp = [];
		item.forEach((item) => {
			temp.push(item.chapter);
		});

		temp = temp.toString(",");
		return temp;
	}

	return (
		<Container>
			<TableHeader>
				{columns.map((data) => (
					<TableElement
						style={{ width: data.width, minWidth: data.width }}
					>
						{data.headerName}{" "}
						{!data.sortable ? (
							""
						) : (
							<SortIcon onClick={() => handleSort(data.field)}>
								<img src={sort} alt="Sort" />
							</SortIcon>
						)}
					</TableElement>
				))}
				<TableElement>Action</TableElement>
			</TableHeader>
			<TableBody>
				{quizes.map((item, i) => (
					<TableColumn style={{ backgroundColor: i % 2 === 0 ? '#F5F5F8' : 'white' }}>
						{columns.map((data) => (
							<TableItem
								id={item.id + data.field}
								style={{
									width: data.width,
									minWidth: data.width,
								}}
							>
								{data.field === "id"
									? currentPage === 0
										? i + 1
										: pageSize * currentPage + (i + 1)
									: data.field === "chapterList"
										? chapterName(item[data.field])
										: item[data.field]}
								<Tooltip
									id="my-tooltip"
									anchorId={item.id + data.field}
									place="bottom"
									content={
										data.field === "id"
											? null
											: data.field === "chapterList"
												? chapterName(item[data.field])
												: item[data.field]
									}
								/>
							</TableItem>
						))}
						<TableItem>
							<ActionContainer>
								{item.reviewStatus === "REJECTED" ? (
									<CommentSection
										onClick={() => {
											setSelectedQuiz({
												status: item.reviewStatus,
												id: item.id,
												rejectedComments:
													item.rejectedComments,
											});
											setCommentModal(true);
										}}
									>
										<Icon />
										{item.commentCount > 0
											? "-" + item.commentCount
											: ""}
									</CommentSection>
								) : null}
								<PreviewButton
									id={`az-revisionreviewer-quizpreview-button-${i}`}
									onClick={() => {
										setPreview(true);
										setSelectedQuiz({
											id: item.id,
										});
									}}
								>
									Preview
								</PreviewButton>
								{
									selectedMenu == "APPROVED" &&
									<PreviewButton
										id={`az-revisionreviewer-quizppublish-button-${i}`}
										onClick={() => publishCDN(item.id)}
										title="PUBLISH TO CDN"
									>
										Publish
									</PreviewButton>
								}

								{item.reviewStatus === "REJECTED" ||
									item.reviewStatus === "APPROVED" ? null : (
									<RejectButton
										id={`az-revisionreviewer-quizreject-button-${i}`}
										onClick={() => {
											setRejectModal(true);
											setSelectedQuiz({
												id: item.id,
												rejectedComments:
													item.rejectedComments,
												name: item.name,
												from: "reject",
											});
										}}
									>
										Reject
									</RejectButton>
								)}
								{item.reviewStatus === "APPROVED" ? null : (
									<ApproveButton
										id={`az-revisionreviewer-quizapprove-button-${i}`}
										onClick={() => approveQuiz(item.id)}
									>
										Approve
									</ApproveButton>
								)}
							</ActionContainer>
						</TableItem>
					</TableColumn>
				))}
			</TableBody>
		</Container>
	);
}

export default QuizTable;
const Container = styled.div`
	min-width: calc(100vw - 240px) !important;
	width: calc(100vw - 240px) !important;
	overflow-x: scroll;
	/* display: block; */
`;
const TableHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	padding: 10px 20px;
	padding-top: 15px;
	background-color: #403E75;
	color: #ffffff;
	width: 100%;
	min-width: 1200px;
`;
const TableElement = styled.div`
	font-size: 13px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-family: "poppins" !important;
	&:hover {
		border-color: orange; /* Change border color on hover */
	  }
`;
const SortIcon = styled.span`
	display: block;
	color: #ffffff;
	width: 12px;
	margin-left: 5px;
	cursor: pointer;
	img {
		display: block;
		width: 100%;
		color: #ffffff;
	}
	:hover {
		opacity: 0.8;
	}
`;
const TableBody = styled.div`
	max-height: calc(100vh - 300px);
	padding-bottom: 100px;
	overflow: scroll;
	width: 100%;
	min-width: 1200px;
	&:hover {
		border-color: orange; /* Change border color on hover */
	  }
`;
const TableColumn = styled.div`
	display: flex;
	gap: 10px;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 20px;
	position: relative;
	&:hover {   
		border: 1px solid #fd8c00;
	  }
	// &:hover {
	// 	border-color: orange;
	// 	// :after {
	// 	// 	display: block;
	// 	// }
	// }
	// :after {
	// 	position: absolute;
	// 	content: "";
	// 	left: 0;
	// 	height: 100%;
	// 	width: 3px;
	// 	background-color: blue;
	// 	display: none;
	// }
`;
const TableItem = styled.span`
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 12px;
	font-weight: 300;
	cursor: default;
	font-family: "poppins" !important;
	#my-tooltip {
		z-index: 99;
	}
	
`;
const ActionContainer = styled.div`
	display: flex;
	gap: 20px;
	/* margin-left: 20px; */
`;
const CommentSection = styled.span`
	display: flex;
	align-items: center;
	font-size: 16px !important;
	font-weight: 300;
	margin-right: 30px;
	cursor: pointer;
`;
const Icon = styled(ForumTwoToneIcon)`
	font-size: 30px;
	color: #fd8c00;
`;
const PreviewButton = styled.span`
	display: flex;
	font-size: 13px;
	justify-content: center;
	align-items: center;
	padding: 5px 15px;
	color: #fd8c00;
	border: 1px solid #fd8c00;
	border-radius: 20px;
	transition: all 0.3s;
	cursor: pointer;
	:hover {
		background-color: #fd8c00;
		color: #fff;
	}
`;
const RejectButton = styled.span`
	display: flex;
	font-size: 13px;
	justify-content: center;
	align-items: center;
	padding: 5px 15px;
	color: #7a7a7a;
	border: 1px solid #7a7a7a;
	border-radius: 20px;
	transition: all 0.3s;
	cursor: pointer;
	:hover {
		background-color: #7a7a7a;
		color: #fff;
	}
`;
const ApproveButton = styled.span`
	display: flex;
	font-size: 13px;
	justify-content: center;
	align-items: center;
	padding: 5px 15px;
	color: #fff;
	border: 1px solid #fd8c00;
	border-radius: 20px;
	transition: all 0.3s;
	background-color: #fd8c00;
	cursor: pointer;
	:hover {
		background-color: #fff;
		color: #fd8c00;
	}
`;
