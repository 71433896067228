import React, { useState, useEffect, useRef } from 'react'
import { Button, Divider, IconButton, TextField } from '@mui/material'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import { styled } from '@mui/material/styles'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import Rotate90DegreesCwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCwOutlined'
import Rotate90DegreesCcwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCcwOutlined'
import '../../../css/switch.css'
import { nameHandler } from '../../../../utils/helper'


const RevisionView = ({ selectedUrl }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [enterPageNo, setEnterPagNo] = useState('')
  const [numPages, setNumPages] = useState(null)

  const addScale = () => {
    setScale(pre => pre + 0.5)
  }
  const removeScale = () => {
    setScale(pre => pre - 0.5)
  }

  const pageChangeHandler = number => {
    if (number) {
      setEnterPagNo(number)
    } else {
      setEnterPagNo('')
      setPageNumber(1)
    }
  }
  const pageHandler = () => {
    setPageNumber(enterPageNo ? Number(enterPageNo) : '')
  }
  const rightRotateHandler = () => {
    setRotate(pre =>
      pre === 0
        ? 90
        : pre === 90
        ? 180
        : pre === -90
        ? 0
        : pre === -180
        ? -90
        : 0
    )
  }
  const leftRotateHandler = () => {
    setRotate(pre =>
      pre === 0
        ? -90
        : pre === -90
        ? -180
        : pre === 90
        ? 0
        : pre === 180
        ? 90
        : 0
    )
  }

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  const previousPage = () => {
    changePage(-1)
  }
  const nextPage = () => {
    changePage(1)
  }
  return (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      background: "rgb(204, 204, 204)",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <div
      style={{
        width: "100%",
        background: "rgb(241,237,237)",
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>{nameHandler}</div>
    
    </div>
    <div
      style={{
        height: "550px",
        overflow: "auto",
        padding: "10px",
        maxWidth: "1300px",
      }}
    >
      <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={[{ uri: selectedUrl }]}
        theme={{
          primary: "rgb(241,237,237)",

          disableThemeScrollbar: true,
        }}
        style={{ height: "600px" }}
      />
    </div>
    <div
      style={{
        background: "rgb(61 60 60)",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "10px",
        color: "white",
        alignItems: "center",
        columnGap: "10px",
        fontSize: "0.93vw",
      }}
    >
      <div style={{ color: "white" }}>
        {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
      </div>
      <Divider
        sx={{
          border: "0.1px solid white",
          padding: "0px",
        }}
        orientation="vertical"
        flexItem
      />
      <IconButton
        onClick={nextPage}
        disabled={pageNumber >= numPages}
      >
        <ArrowCircleUpIcon sx={{ color: "white" }} />
        {/*Next*/}
      </IconButton>
      <IconButton
        onClick={previousPage}
        disabled={pageNumber <= 1}
      >
        <ArrowCircleDownIcon sx={{ color: "white" }} />
        {/*prev*/}
      </IconButton>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          border: "0.1px solid white",
          padding: "0px",
        }}
      />
      <IconButton onClick={addScale}>
        <AddCircleOutlineIcon
          sx={{ color: "white", fontSize: "1.5vw" }}
        />
      </IconButton>
      <IconButton onClick={removeScale} disabled={scale <= 1}>
        <RemoveCircleOutlineIcon
          sx={{ color: "white", fontSize: "1.5vw" }}
        />
      </IconButton>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          border: "0.1px solid white",
          padding: "0px",
        }}
      />
      <IconButton onClick={rightRotateHandler}>
        <Rotate90DegreesCwOutlinedIcon
          sx={{ color: "white", fontSize: "1.5vw" }}
        />
      </IconButton>
      <IconButton onClick={leftRotateHandler}>
        <Rotate90DegreesCcwOutlinedIcon
          sx={{ color: "white", fontSize: "1.5vw" }}
        />
      </IconButton>
    </div>
  </div>
  )
}

export default RevisionView
