import React from "react";
import MainContainer from "./MainContainer";
import ReviewerDashboard from "./ReviewerDashboard";
import Navbar from "../../Navbar";

function RevisionReviewer() {
	return (
		<>
			<Navbar />
			<MainContainer children={<ReviewerDashboard />} headerTitle={""} />
		</>
	);
}

export default RevisionReviewer;
