import React from 'react'
import {Menu} from '@mui/material'
import { MenuItem } from '@mui/material'
import { useState } from 'react'
import {Button} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'


const ButtonSubject = () => {
    const [anchorElm, setAnchorElm] = useState(null)
    const [open, setOpen] = useState(false)
    
    const handleClick = (event) => {
        setAnchorElm(event.currentTarget);
        setOpen(true)

    };
    const handleClose = ( ) => {
        setAnchorElm(null);
        setOpen(false)
    }

    return (
        //
    <div>
        <Button variant="outlined" size='small' onClick={handleClick} sx={{borderRadius:"16px"}}  endIcon={<KeyboardArrowDown />}>
           Subject
            </Button>
        <Menu
             anchorEl={anchorElm} open={open} onClose={handleClose}
         >
        
        <MenuItem onClick={handleClose}>English</MenuItem>
        <MenuItem onClick={handleClose}>Telugu</MenuItem>
        <MenuItem onClick={handleClose}>Bridge Cource</MenuItem>
        <MenuItem onClick={handleClose}>Annual Exam</MenuItem>
        <MenuItem onClick={handleClose}>Social Science</MenuItem>
        <MenuItem onClick={handleClose}>Life Skills</MenuItem>
        <MenuItem onClick={handleClose}>Grammar</MenuItem>
        <MenuItem onClick={handleClose}>Mathematics</MenuItem>
        <MenuItem onClick={handleClose}>Computer</MenuItem>
        </Menu>
    </div>
  )
}
  


export default ButtonSubject