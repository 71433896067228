import React, { useState } from 'react'
import PlanSingleView from './PlanSingleView'
import PlanListView from './PlanListView'
import CommonHeadPortion from './CommonHeadPortion'

const PlanTemplateMasterAdmin = () => {
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [isPlanSingleView, setIsPlanSingleView] = useState(false)

  const onEditClick = item => {
    setSelectedPlan(item)
    setIsPlanSingleView(true)
  }

  const returnToPlanListview = () => {
    if (isPlanSingleView) {
      setIsPlanSingleView(false)
      setSelectedPlan(null)
    }
  }

  const onAddPlanClick = () => {
    setIsPlanSingleView(true)
  }

  return (
    <div style={{ width: '100%' }}>
      <CommonHeadPortion onClick={returnToPlanListview} />
      {isPlanSingleView ? (
        <PlanSingleView   returnToPlanListview={returnToPlanListview} selectedPlan={selectedPlan} />
      ) : (
        <PlanListView
          onAddPlanClick={onAddPlanClick}
          onEditClick={onEditClick}
        

        />
      )}
    </div>
  )
}

export default PlanTemplateMasterAdmin
