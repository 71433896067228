import React, { useState, useEffect, useRef } from 'react'
import { Paper } from '@mui/material'
import ReactPlayer from 'react-player'
import PsychologyAltOutlinedIcon from '@mui/icons-material/PsychologyAltOutlined'
import { nameHandler } from '../../../../utils/helper'
import CustomVideoPlayer from '../../CustomVideoPlayer/CustomVideoPlayer'

const VideoView = ({
  selectedUrl,
  isPlaying,
  isDownload,
  selectedMenu,
  setDuration,
  setProgressObj,
  setPlaying
}) => {
  const videoRef = useRef(null)
  const controlsListAttribute = isDownload ? undefined : 'nodownload';

  return (
    <Paper
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '10px',
          alignItems: 'center',
          padding: '10px 0px'
        }}
      >
        <div
          style={{
            fontWeight: 600,
            fontWeight: 'bold',
            paddingBottom: '10px',
            fontSize: '13px'
          }}
        >
          {nameHandler(selectedMenu)}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          background: 'rgb(241, 237, 237)',
          padding: '20px',
          borderRadius: '10px',
          height: '100%'
        }}
      >
        {/* <ReactPlayer
          controls={true}
          config={{ file: { attributes: { controlsList: controlsListAttribute } }}}
          ref={videoRef}
          url={selectedUrl}
          className='react-player'
          playing={isPlaying}
          width='100%'
          height='450px'
          progressInterval={500}
          onDuration={d => setDuration(d)}
          onProgress={o => {
            setProgressObj(o)
          }}
          onReady={r => console.log('ready', r)}
          onEnded={() => setPlaying(false)}
        /> */}
        <div style={{ marginBottom: "20px", marginTop: "20px", textAlign: "center" }}>
          <CustomVideoPlayer videoRef={videoRef}
            selectedUrl={selectedUrl} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '5px'
          }}
        ></div>
      </div>
    </Paper>
  )
}

export default VideoView
