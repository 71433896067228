import React, { useEffect, useState } from "react";
import { coordinatorColumn, MenuProps } from "./Data";
import {
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { blue } from "@mui/material/colors";
import dashHome from "../../../src/img/dashHome.svg";
import CommonDeleteModal from "./CommonDeleteModal";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import { SearchOutlined } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Switch from "../../components/common/Switch";
import axios from "axios";
import CoordinatorGradeModal from "./CoordinatorGradeModal";
import CoordinatorTypeDeleteModal from "./coordinatorTypeDeleteModal";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import EditIconButton from "../../components/common/EditIconButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
// import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import ConfirmationModal from "./ConfirmationModal";
import sortIcon from "../../../src/pages/img/sort_1.png";
import searchIcn from "../../../src/img/search.svg";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";
import UpdatedModal from "./UpdatedModal";

//Action Icon Images
import action__Edit from "../../../src/img/Edit.svg";
import action__Delete from "../../../src/img/Delete.svg";
import commonsort from "../js/CommonSort"
const CoordinatorType = (props) => {
  const token = sessionStorage.getItem("token");
  const [addCoordinatorVisible, setAddCoordinatorVisible] = useState(false);
  const [updatedData, setUpdatedData] = useState();
  const [filterValue, setFilterValue] = useState([]);
  const [coordinator, setCoordinator] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageSize, setPageSize] = useState(1000);
  const [gradeData, setGradeData] = useState([]);
  const [openGradeModal, setOpenGradeModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [gradeList, setGradeList] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [gradeListData, setGradeListData] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [selectedCoordinator, setSelectedCoordinator] = useState("");
  const [modifiedDate, setModifiedDate] = useState({});
  const [validation, setValidation] = useState(false);
  const [message, setMessage] = useState("");
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [sortOrder, setSortOrder] = useState(false);
  const [sortBy, setSortBy] = useState("id");
  const navigate = useNavigate();

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  const [selectedItem, setSelectedItem] = useState("");
  const openDeleteModalHandler = async (row) => {
    setSelectedItem(row)
    console.log(row);
    setOpenDeleteModal(true);
    setSelectedId(row?.id);
    setSelectedCoordinator(row?.coordinatorType);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/coordinator-types/confirmation-api?id=${row.id}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const closeDeleteModalHandler = () => {
    setOpenDeleteModal(false);
    setSelectedId("");
    setSelectedCoordinator("");
  };

  async function GetLastModifiedAt(ModifiedDate) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/coordinator-types/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json) => {
        ModifiedDate(json.data)
      })
      .catch(err => console.log(err))
  }

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleOpen = async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/coordinator-types/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response?.data?.data) {
      setGradeList(response?.data?.data);
    }
    setOpenGradeModal(true);
  };
  const handleClose = () => {
    setOpenGradeModal(false);
  };
  const gradeHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setGradeData(typeof value === "string" ? value.split(",") : value);
  };
  const PageHandler = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fetchData = async (pageSize, sortOrder, sortBy) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/coordinator-types?pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.data) {
      const totalElement = response?.data?.data?.totalElements;
      if (totalElement >= pageSize) {
        setPageSize(totalElement);
      }

      var sorted = await commonsort(response?.data?.data?.data || [], sortBy, sortOrder)
      setUpdatedData(sorted);
      setPage(0);
    }
  };
  const fetchGradeData = async () => {
    const gradeResponse = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setGradeListData(gradeResponse?.data?.data);
  };
  useEffect(() => {
    fetchGradeData();
  }, []);

  useEffect(() => {
    if (pageSize) {
      fetchData(pageSize, sortOrder, sortBy);
    }
  }, [pageSize]);

  useEffect(() => {
    setFilterValue(updatedData);
  }, [updatedData]);

  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/coordinator-types/toggle/${toggleDetails?.id}?active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setUpdatedData(clone);
          handleConfirmationClose();
        }
      })
      .catch(function (response) {
        // console.log(response);
      });
  };
  const [toggleDetails, setToggleDetails] = useState({});
  const switchHandler = async (id, e) => {
    setToggleDetails({});
    const value = e?.target?.checked;
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/coordinator-types/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/coordinator-types/toggle/${id}?active=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          if (response?.data) {
            const find = updatedData?.find((item) => item.id === id);
            find.active = value;
            const clone = [...updatedData];
            clone.forEach((data) => {
              data = find;
            });
            setUpdatedData(clone);
            handleConfirmationClose();
          }
        })
        .catch(function (response) {
          // console.log(response);
        });
    }
  };
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };
  const changeHandler = (e) => {
    const value = e.target.value;
    setCoordinator(value);
  };

  const [SuccessDisplayName, setSuccessDisplayName] = useState("")

  const addCoordinatorTypeHandler = (garde) => {
    if (!coordinator || garde.length == 0) {
      setValidation(true);
      return;
    }
    const gradeList = [];
    garde.forEach((gard) => {
      const Grade = gradeListData.find((q) => q.grade === gard);
      if (Grade) {
        gradeList.push(Grade.id);
      }
    });
    const updatedValue = {
      coordinatorType: coordinator,
      gradeId: gradeList,
      active: true,
    };
    setSuccessDisplayName(updatedValue.coordinatorType)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/coordinator-types`,
      data: updatedValue,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        setSuccessCreateModal(true)
        if (response.data.data) {
          setUpdatedData([...updatedData, response.data.data]);
        }
      })
      .catch(function (response) {
        // console.log("error ==> ", response);
      });
    setAddCoordinatorVisible(false);
    setCoordinator("");
    setGradeData([]);
  };
  const [updatedName, setupdatedName] = useState("")
  const editCoordinatorType = (garde, id) => {
    if (!coordinator || garde.length == 0) {
      setValidation(true);
      return;
    }
    const gradeId = [];
    garde.forEach((gard) => {
      const Grade = gradeListData?.find((q) => q?.grade === gard);
      if (Grade) {
        gradeId.push(Grade?.id);
      }
    });
    const updatedValue = {
      coordinatorType: coordinator,
      gradeId: gradeId,
      active: true,
    };
    setupdatedName(updatedValue.coordinatorType)
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/coordinator-types/${id}`,
        updatedValue,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setSuccesUpdatesModal(true)
        if (response?.data?.data) {
          const find = updatedData?.find(
            (item) => item?.id === response?.data?.data?.id
          );
          find.coordinatorType = response?.data?.data?.coordinatorType;
          find.grades = response?.data?.data?.grades;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setUpdatedData(clone);
        }
      })
      .catch(function (response) {
        console.log(response);
      });
    setAddCoordinatorVisible(false);
    setSelectedId("");
    setCoordinator("");
    setGradeData([]);
  };

  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/coordinator-types/${selectedId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.filter((data) => data.id !== selectedId);
          setFilterValue(find);
        }
      })
      .catch(function (response) {
        console.log(response);
      });
    closeDeleteModalHandler();
  };
  const deleteGradeHandler = (id, gradeIds, setIdList) => {
    const data = { id: id, mappedGradeIds: gradeIds };
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/coordinator-types/bulk-delete`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: data,
        }
      )
      .then(function (response) {
        fetchData(pageSize, sortOrder, sortBy);
        closeDeleteModalHandler();
      })
      .catch(function (response) {
        console.log(response);
      });
    setIdList([]);
    handleClose();
  };
  const coordinatorSearch = (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      result = updatedData?.filter((data) => {
        return data?.coordinatorType?.toLowerCase()?.includes(value);
      });
    }
    setFilterValue(result);
  };
  const editClickHandler = (row) => {
    const grade = row?.grades?.map((item) => item.grade);
    setSelectedId(row?.id);
    setAddCoordinatorVisible(true);
    setIsEdit(true);
    setCoordinator(row?.coordinatorType);
    setGradeData(grade);
  };
  const [succesUpdatesModal, setSuccesUpdatesModal] = useState(false)
  const [successCreateModal, setSuccessCreateModal] = useState(false)
  const closeSuccessModal = () => {
    setSuccessCreateModal(false)
  }

  const closeModal = () => {
    setSuccesUpdatesModal(false)
  }

  const CustomTooltip = ({ title, children, ...props }) => (
    <Tooltip
      title={title}
      arrow
      {...props}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          }
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#ffa500',
            color: 'white',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
            fontSize: '10px',
            fontWeight: 500,
            padding: '9px 20px',
            borderRadius: '5px',
          },
        },
        arrow: {
          sx: {
            color: '#ffa500',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span
            className={dashboardContentStyles.link_icon}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            <img
              src={dashHome}
              alt="no img"
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle" }}
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          {/* {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "} */}
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            Home
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "} */}
          <span className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'default' }}>
            Master
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "}
          <a>Coordinator Type</a> */}
          <span
            className={`${dashboardContentStyles.az_selected_menu_text} cursorDefault`}
          >
            Coordinator Type
          </span>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            marginTop: "60px",
            gap: "16px",
          }}
        >
          {addCoordinatorVisible && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#606060",
                  columnGap: "10px",
                }}
              >
                <MenuBookOutlinedIcon style={{ fontSize: "30px" }} />
                <div style={{ fontWeight: 600 }}>
                  {isEdit ? "Edit Coordinator Type" : "Add Coordinator Type"}
                </div>
              </div>
              <Paper>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px 20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      columnGap: "1rem",
                    }}
                  >
                    <FormControl>
                      <TextField
                        id="demo-helper-text-aligned-no-helper"
                        label="*Coordinator Type"
                        autoComplete="off"
                        onChange={changeHandler}
                        value={coordinator}
                        sx={{
                          fontSize: "14px !important",
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              border: "1px solid #272727 !important"
                            }
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "#ffa500 !important",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#B5B6B6 !important",
                            fontSize: "14px"
                          },
                          "& .MuiInputBase-root": {
                            height: "48px !important",
                            width: "270px !important",
                            borderRadius: "150px !important",
                          },
                          "& .MuiInputBase-input": {
                            color: "#ffa500",
                          }
                        }}

                      />
                      {validation && !coordinator && (
                        <span style={{ color: "red", padding: "5px" }}>
                          Coordinator is Required
                        </span>
                      )}
                    </FormControl>
                    <FormControl sx={{ width: 300 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        *Grade(s)
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={gradeData}
                        onChange={gradeHandleChange}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        sx={{ borderRadius: "150px" }}
                      >
                        {gradeListData?.map((name) => (
                          <MenuItem key={name.id} value={name?.grade}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: '#FFA500',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#FFA500',
                                color: "#000000"
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}>
                            <Checkbox
                              {...label}
                              defaultChecked
                              sx={{
                                color: `#111fee !important`,
                                "&.Mui-checked": {
                                  color: blue[900],
                                },

                              }}

                              checked={gradeData?.indexOf(name?.grade) > -1}
                            />
                            <ListItemText primary={name.grade} />
                          </MenuItem>
                        ))}
                      </Select>
                      {validation && gradeData.length == 0 && (
                        <span style={{ color: "red", padding: "5px" }}>
                          Grade is Required
                        </span>
                      )}
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                    }}
                  >
                    <HoverGreyCancelButton
                      variant="outlined"
                      onClick={() => {
                        setAddCoordinatorVisible(false);
                        setSelectedId("");
                        setIsEdit(false);
                        setCoordinator("");
                        setGradeData([]);
                      }}
                    >
                      Cancel
                    </HoverGreyCancelButton>
                    {isEdit ? (
                      <BlueButton
                        onClick={() =>
                          editCoordinatorType(gradeData, selectedId)
                        }
                      >
                        Update
                      </BlueButton>
                    ) : (
                      <BlueButton
                        onClick={() => addCoordinatorTypeHandler(gradeData)}
                      >
                        Create
                      </BlueButton>
                    )}
                  </div>
                </div>
              </Paper>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "10px",
              }}
            >
              <MenuBookOutlinedIcon style={{ fontSize: "2.2vw" }} />
              <div
                style={{
                  fontSize: "1.2vw",
                  fontWeight: "600",
                }}
              >
                Coordinator Type
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <TextField
                type={"text"}
                placeholder={"Search...."}
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                    opacity: 1,
                  },
                }}
                onChange={coordinatorSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              />
              {!addCoordinatorVisible && (
                <GreenAddButton onClick={() => setAddCoordinatorVisible(true)}>
                  <AddCircleOutlineOutlinedIcon />
                  Add Coordinator Type
                </GreenAddButton>
              )}
            </div>
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              style={{ marginBottom: "90px" }}
              sx={{ maxHeight: 640, overflow: "auto" }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    {coordinatorColumn?.map((item) => {
                      return (
                        <TableCell
                          className={dashboardContentStyles.table_head}
                          key={item.id}
                          align={item.align}
                        >
                          <div
                            style={
                              item.dataIndex === "Active" ||
                                item.dataIndex === "Action"
                                ? {
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }
                                : {
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }
                            }
                          >
                            {item.dataIndex}
                            {"  "}

                            {item.dataIndex === "Active" ||
                              item.dataIndex === "Action" ? null : (
                              <img
                                className="cursor-pointer"
                                width="10"
                                height="15"
                                style={{
                                  verticalAlign: "middle",
                                  margin: "2px 0 2px 3px",
                                }}
                                src={sortIcon}
                                onClick={async () => {
                                  await setSortOrder(!sortOrder);
                                  let sort =
                                    item.dataIndex === "Coordinator Type"
                                      ? "coordinatorType"
                                      : item.dataIndex === "Grade"
                                        ? "grades"
                                        : "id";
                                  await fetchData(pageSize, !sortOrder, sort);
                                }}
                              />
                            )}
                            {
                              (item.dataIndex === "Active" ||
                                item.dataIndex === "Action") ? null :
                                (<>
                                  {item.dataIndex === "Coordinator Type" ? (
                                    <img
                                      className="cursor-pointer"
                                      width="10"
                                      height="15"
                                      style={{
                                        verticalAlign: "middle",
                                        margin: "2px 0 2px 3px",
                                      }}
                                      src={sortIcon}
                                      onClick={async () => {
                                        await setSortOrder(!sortOrder);
                                        let sort = "coordinatorType";
                                        await fetchData(pageSize, !sortOrder, sort);
                                      }}
                                    />
                                  ) : null}</>)
                            }


                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterValue
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },

                          backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f8',
                          "&:last-child td, &:last-child th": {
                            borderBottom: "none !important",
                          },

                          "&:hover": {

                            "& td, & th": {
                              borderTop: "1px solid #ff9702",
                              borderBottom: "1px solid #ff9702",
                            },
                            " & th:first-of-type": {
                              borderLeft: "1px solid #ff9702",
                            },

                            "& td:first-of-type": {
                              borderLeft: "0",

                            },

                            "&:last-child td, &:last-child th": {
                              borderBottom: "1px solid #ff9702 !important",
                            },

                            "& td:last-of-type": {
                              borderRight: "1px solid #ff9702",

                            },

                            "& th:last-of-type": {
                              borderRight: "0",
                            },
                          },

                          "& td, & th": {
                            border: "1px solid transparent",
                            borderTop: "1px solid transparent",
                            borderBottom: "1px solid transparent",
                            "&:not(:last-child)": {
                              borderRight: "1px solid transparent",
                            },
                          },
                        }}

                        // hover={true}
                        // style={{
                        //   background: "#f9f8f8",
                        // }}
                        className={dashboardContentStyles.table_rw}
                      >
                        <TableCell
                          className={dashboardContentStyles.table_cell}
                          component="th"
                          scope="row"
                        >
                          {page * rowsPerPage + (index + 1)}
                        </TableCell>
                        <TableCell
                          className={dashboardContentStyles.table_cell_name}
                          align="left"
                        >
                          {row?.coordinatorType}
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() => handleOpen(row.id)}
                          className={dashboardContentStyles.table_cell_name}
                          style={{
                            cursor: "pointer",
                            display: "table-cell",
                            flexDirection: "row",
                            height: "100%",
                          }}
                        >
                          {row?.grades?.map((item, i) => {
                            return (
                              <div
                                style={{ display: "inline-block" }}
                                key={item?.gradeId}
                              >
                                {(i ? ", " : "") + item?.grade}
                              </div>
                            );
                          })}
                        </TableCell>
                        <TableCell align="right">
                          <Switch
                            checked={row?.active}
                            onChange={(e) => switchHandler(row?.id, e)}
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                        // style={{paddingRight:60}}
                        >
                          <EditIconButton
                            onClick={() => {
                              editClickHandler(row);
                            }}
                          >
                            <CustomTooltip title="Edit" placement={"top"}>
                              {/* <EditOutlinedIcon style={{ fontSize: "1.7vw" }} /> */}
                              <img
                                className={
                                  dashboardContentStyles.aRoleActionBtn
                                }
                                src={action__Edit}
                                alt="Edit-Btn"
                              />
                            </CustomTooltip>
                          </EditIconButton>
                          <DeleteIconButton
                            marginRight="10"
                            onClick={() => openDeleteModalHandler(row)}
                          >
                            <CustomTooltip title="Remove" placement={"top"}>
                              {/* <DeleteOutlinedIcon
                                style={{ fontSize: "1.7vw" }}
                              /> */}
                              <img
                                className={
                                  dashboardContentStyles.aRoleActionBtn
                                }
                                src={action__Delete}
                                alt="Dlt-Btn"
                              />
                            </CustomTooltip>
                          </DeleteIconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{
                bottom: "0",
                width: "80.5%",
                position: "fixed",
                backgroundColor: "white",
              }}
              component="div"
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={filterValue?.length || 0}
              page={page}
              onPageChange={PageHandler}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Paper>
        </div>
        <CoordinatorGradeModal
          open={openGradeModal}
          onClose={handleClose}
          grades={gradeList}
          deleteHandler={deleteGradeHandler}
        />
        {/* <CoordinatorTypeDeleteModal
          open={openDeleteModal}
          onClose={closeDeleteModalHandler}
          coordinatorName={selectedCoordinator}
          id={selectedId}
          deleteHandler={deleteHandler}
          deleteName={message}
        /> */}
        <CommonDeleteModal
          open={openDeleteModal}
          close={closeDeleteModalHandler}
          deleteTitle={"Coordinator Type"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={
            selectedItem?.coordinatorType
          }
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
        <Success_OK_Modal
          userType={"Coordinator Type"}
          userName_Modal={
            SuccessDisplayName
          }
          open={successCreateModal}
          handleOk={closeSuccessModal}
        />
        <UpdatedModal
          userType={"Coordinator Type"}
          userName_Modal={
            updatedName
          }
          open={succesUpdatesModal}
          handleOk={closeModal}
          close={closeModal}
        />
      </div>
    </>
  );
};
export default CoordinatorType;
