import React, { useState, useEffect } from "react";
import {
  Modal,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import YellowButton from "../../components/common/yellowButton";
import info_icon from "../../../src/img/pop-up-indicator1.svg";
import addstudentstyle from "./../css/NavbarAddStudentModal.module.css";
import axios from "axios";
import { Checkbox } from "@mui/material"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  p: 2,
};
const TeacherAccessModal = ({
  open,
  close,
  giveAccessSuccess,
  selectedItem,
  grades,
  planId,
  success,
}) => {
  const token = sessionStorage.getItem("token");
  const [section, setSection] = useState([]);
  const [subtopic, setSubtopic] = useState([]);
  const [subjects, setSubject] = useState([]);
  const [gradeId, setGradeId] = useState("");
  const [sectionId, setSectionId] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  const [showSubTopic, setShowSubTopic] = useState(true);
  const [subtopicId, setSubtopicId] = useState([]);

  const handlerTeacherDetailsInputs = (gradeId) => {
    const { schoolId, branchId } = selectedItem;
    setGradeId(gradeId);
    setSection([]);
    setSubject([]);
    setSubtopic([]);
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/sections?gradeId=${gradeId}&branchId=${branchId}&schoolId=${schoolId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setSection(response?.data?.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(
        // `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan?planId=${planId}&gradeId=${gradeId}`
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan/access-based?planId=${planId}&gradeId=${gradeId}`
        ,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setSubject(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const handlerSubjectDetails = (subject) => {
    console.log(subject, "teacherAccessModel", subjects)

    const selectedSubject = subjects.find(val => val.subjectId === String(subject));
    console.log(selectedSubject)
    console.log(selectedSubject?.hideSubtopics, selectedSubject.subjectId)
    if (selectedSubject && selectedSubject.hideSubtopics) {
      setShowSubTopic(false);
      console.log("false")
      // alert("pac false");
    } else {
      console.log("true")
      setShowSubTopic(true);
      // alert("pac true");
    }


    setSubjectId(subject);
    setSubtopicId([]);
    setSubtopic(selectedSubject?.subTopics);
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/all?subjectId=${subject}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        // setSubtopic(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  const [isError, setError] = useState(false);
  const GiveAccessHandler = () => {
    console.log("GiveAccessHandler", subtopic)
    if (!gradeId || sectionId.length == 0 || !subjectId) {
      setError(true);
      return;
    }
    setError(false);
    // if (subtopic.length > 0 && subtopicId.length == 0) {
    //   console.log(subtopic)
    //   setError(true);
    //   return;
    // }
    const { id } = selectedItem;
    const data = {
      gradeId: gradeId,
      sectionIds: [...sectionId],
      subjectId: subjectId,
      subtopicIds: subtopicId ? [...subtopicId] : [],
    };
    console.log("data", data, id)
    axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${id}/map-subjects`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("response", response?.data?.message)
        // giveAccessSuccess();
        setGradeId("");
        setSectionId([]);
        setSubjectId("");
        setSubtopicId([]);
        success(response?.data?.message);
      })
      .catch((error) => {
        console.log(error?.response?.data)
        success(error?.response?.data?.message);
        setGradeId("");
        setSectionId([]);
        setSubjectId("");
        setSubtopicId([]);
      });
  };
  const onclose = () => {
    setError(false);
    close();
    setSectionId([]);
    setSubtopicId([]);
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ width: "931px", height: "374px" }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div>
            <label style={{ color: "#354052" }}>Access</label>
            <i
              class="fa-solid fa-xmark"
              style={{ marginLeft: "765px" }}
              onClick={onclose}
            ></i>
          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              marginTop: "45px",
            }}
          >
            <div
              className={addstudentstyle.input_element}
              style={{ width: "31%" }}
            >
              <select
                className={addstudentstyle.form_select_element}
                name="grade"
                id="Grade"
                onChange={(event) =>
                  handlerTeacherDetailsInputs(event.target.value)
                }
                style={{
                  width: "270px",
                  height: "48px",
                }}
              >
                <option value="">Select</option>
                {grades && grades.length > 0 ? (
                  grades.map((gradesName, index) => {
                    return (
                      <option value={gradesName.id}>{gradesName.grade}</option>
                    );
                  })
                ) : (
                  <option className={addstudentstyle.option} value=""></option>
                )}
              </select>
              <label for="Grade">*Grade</label>
              {isError && !gradeId && (
                <p style={{ fontSize: "15px", color: "red", padding: "5px" }}>
                  Grade is Required
                </p>
              )}
            </div>

            <div
              className={addstudentstyle.input_element}
              style={{ width: "31%" }}
            >
              <FormControl>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  style={{ top: "0px" }}
                  sx={{
                    fontSize: "14px",
                    marginBottom: 6,
                    "&.Mui-focused": {
                      color: "orange",
                    },
                  }}
                >
                  *Section
                </InputLabel>
                <Select
                  sx={{
                    borderRadius: "10px",
                    width: "250px",
                    height: "50px",
                    "& .css-10hburv-MuiTypography-root": {
                      fontSize: "14px !important",
                    },
                    "&.MuiOutlinedInput-root": {
                      "& > fieldset": {
                        border: "1px solid #D6D5DF !important",
                      },
                    },
                    "&.MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        border: "1px solid #272727 !important",
                      },
                      ".css-fnnx9f-MuiFormLabel-root-MuiInputLabel-root": {
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        color: "#fd8c00 !important",
                        backgroundColor: "#fff",
                        fontWeight: "500 !important",
                      },
                    },
                    fontSize: "14px !important",
                  }}
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={sectionId}
                  name="first"
                  onChange={(e) => setSectionId(e?.target?.value)}
                  input={<OutlinedInput label="Subtopic" />}
                  renderValue={(selected) =>
                    selected
                      ?.map((obj) => {
                        return section?.find((item) => {
                          return item.id === obj;
                        })?.section;
                      })
                      .join(", ")
                  }
                >
                  {section?.map((subtopic) => (
                    <MenuItem key={subtopic.id} value={subtopic.id}>
                      <Checkbox
                        checked={sectionId.indexOf(subtopic.id) > -1}
                      />
                      <ListItemText primary={subtopic.section} />
                    </MenuItem>
                  ))}
                </Select>
                {isError &&
                  sectionId.length === 0 &&
                  section.length > 0 && (
                    <span style={{ color: "red", padding: "5px" }}>
                      Section is Required
                    </span>
                  )}{" "}
              </FormControl>
            </div>
            <div
              className={addstudentstyle.input_element}
              style={{ width: "31%" }}
            >
              <select
                className={addstudentstyle.form_select_element}
                name="subject"
                id="subject"
                // value={studentDetails.section}
                onChange={(event) => handlerSubjectDetails(event.target.value)}
                style={{
                  width: "270px",
                  height: "48px",
                }}
              >
                <option value="">Select</option>
                {subjects && subjects.length > 0 ? (
                  subjects.map((sectionName, index) => {
                    return (
                      <option value={sectionName.subjectId}>
                        {sectionName.subject}
                      </option>
                    );
                  })
                ) : (
                  <option className={addstudentstyle.option} value=""></option>
                )}
              </select>
              <label for="section">*Subject</label>
              {isError && !subjectId && (
                <p style={{ fontSize: "15px", color: "red", padding: "5px" }}>
                  Subject is Required
                </p>
              )}
            </div>
          </div>

          {/* {subtopic.length > 0 && ( */}
          {showSubTopic > 0 && (
            <div
              className={addstudentstyle.input_element}
              style={{ width: "31%", marginTop: "24px", marginLeft: "10px" }}
            >
              <FormControl>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  style={{ top: "0px" }}
                  sx={{
                    fontSize: "14px",
                    marginBottom: 6,
                    "&.Mui-focused": {
                      color: "orange",
                    },
                  }}
                >
                  *Subtopic
                </InputLabel>
                <Select
                  sx={{
                    borderRadius: "10px",
                    width: "250px",
                    height: "50px",
                    "& .css-10hburv-MuiTypography-root": {
                      fontSize: "14px !important",
                    },
                    "&.MuiOutlinedInput-root": {
                      "& > fieldset": {
                        border: "1px solid #D6D5DF !important",
                      },
                    },
                    "&.MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        border: "1px solid #272727 !important",
                      },
                      ".css-fnnx9f-MuiFormLabel-root-MuiInputLabel-root": {
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        color: "#fd8c00 !important",
                        backgroundColor: "#fff",
                        fontWeight: "500 !important",
                      },
                    },
                    fontSize: "14px !important",
                  }}
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={subtopicId}
                  name="first"
                  onChange={(e) => setSubtopicId(e?.target?.value)}
                  input={<OutlinedInput label="Subtopic" />}

                  renderValue={(selected) =>
                    selected
                      ?.map((obj) => {
                        return subtopic?.find((item) => {
                          return item.id === obj;
                        })?.subTopic;
                      })
                      .join(", ")
                  }
                >
                  {subtopic?.map((subtopic) => (
                    <MenuItem key={subtopic.id} value={subtopic.id}>
                      <Checkbox
                        checked={subtopicId.indexOf(subtopic.id) > -1}
                      />
                      <ListItemText primary={subtopic.subTopic} />
                    </MenuItem>
                  ))}
                </Select>
                {isError &&
                  subtopicId.length === 0 &&
                  subtopic.length > 0 && (
                    <span style={{ color: "red", padding: "5px" }}>
                      Subtopic is Required
                    </span>
                  )}{" "}
              </FormControl>
            </div>
          )}
        </Typography>
        <hr
          style={{
            position: "relative",
            top: "40%",
            width: "100%",
            color: "#9B9FA7",
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5%",
            paddingBottom: "20px",
            columnGap: "1rem",
          }}
        >
          <YellowButton onClick={GiveAccessHandler}>Give Access</YellowButton>
        </div>
      </Box>
    </Modal>
  );
};

export default TeacherAccessModal;
