import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  TextField,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { blue, orange } from "@mui/material/colors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axios from "axios";
import QuestionViewModal from "./QuestionViewModal";
import Student_Enrich_Quiz_MSQ from "./Student/Student_Enrich_Quiz_MSQ";
import Student_Enrich_Quiz_MCQ from "./Student/Student_Enrich_Quiz_MCQ";
import Student_Enrich_Quiz_FIB from "./Student/Student_Enrich_Quiz_FIB";
import Student_Enrich_Quiz_MTF_DD from "./Student/Student_Enrich_Quiz_MTF_DD";
import Student_Enrich_Quiz_MTF_DrawLines from "./Student/Student_Enrich_Quiz_MTF_DrawLines";
import Student_Image_Open_Modal from "./Student/Student_Image_Open_Modal";
const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 1000,
  bgcolor: "white",
  // borderRadius: "10px",
  padding: 0,
  display: "flex",
  flexDirection: "column",
  // rowGap: "1rem",
};
var loggedinuserrole = localStorage.getItem("role");
const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <Box
          sx={{
            minWidth: 35,
            display: "flex",
            justifyContent: "space-between",
            padding: "10",
          }}
        >
          <Typography
            fontSize={18}
            fontWeight={600}
            sx={{ padding: "10px 0px" }}
            dangerouslySetInnerHTML={{ __html: props.name }}
          ></Typography>
          <Typography
            variant="body2"
            fontWeight={600}
            fontSize={18}
            sx={{ padding: "10px 0px" }}
          >{`${Math.round(props.value)} out of ${props.totalQuestions
            }`}</Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={Math.round((100 * props.value) / props.totalQuestions)}
          sx={{ height: 10, borderRadius: "150px", color: "#FFA700" }}
          color={"inherit"}
        />
      </Box>
    </Box>
  );
};
const CustomTextField = styled(TextField)(({ }) => ({
  width: "96%",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "2px !important",
  "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
    opacity: "1 !important",
    background: "rgb(255 255 255 / 31%) !important",
    border: "1px solid #B5B6B6",
    borderRadius: "2px !important",
  },
}));
const CustomRadioIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  color: orange[600],
  boxShadow: "inset 0 0 0 3px orange, inset 0 -1px 0 orange",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto orange",
    color: orange[600],
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
  backgroundColor: orange[600],
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: orange[800],
  },
});

function CustomRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CustomRadioCheckedIcon />}
      icon={<CustomRadioIcon />}
      {...props}
    />
  );
}

const ExitButton = styled(Button)(() => ({
  color: "#fff",
  width: 80,
  backgroundColor: "rgba(5,175,116,0.89)",
  borderRadius: 5,
  textTransform: "none",
  "&:hover": {
    color: "#fff",
    backgroundColor: "rgba(5,175,116,0.89)",
    border: 0,
  },
}));
const PreviewButton = styled(Button)(() => ({
  color: "#fff",
  width: 200,
  backgroundColor: "#8589be",
  borderRadius: 5,
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#8589be",
    border: 0,
  },
}));
const NextButton = styled(Button)(() => ({
  color: "#fff",
  width: 200,
  backgroundColor: "#FFA700",
  borderRadius: 5,
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#FFA700",
    border: 0,
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgba(5,175,116,0.89)",
  ...theme.typography.body2,
  padding: "4px",
  textAlign: "center",
  color: "white",
  borderRadius: "150px",
  width: 35,
  height: 35,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
const QuestionModal = ({ open, close, data }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [storeData, setStoreData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [answerByOrder, setAnswerByOrder] = useState([]);
  const [matchData, setMatchData] = useState([]);
  const [MSQList, setMSQList] = useState([]);
  const [MCQ, setMCQ] = useState("");
  const [subMCQ, setSubMCQ] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({
    question: "",
    color: "",
  });
  const [attendMtfDl, setAttendMtfDl] = useState([]);
  const [attendMtfDlTemp, setAttendMtfDlTemp] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [selectedImgData, setSelectedImgData] = useState([]);
  const [selectedAnswerData, setSelectedAnswerData] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [questionIds, setQuestionIds] = useState([]);
  const [isViewModal, setViewModal] = useState(false);
  const [queston, setQuestion] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const token = sessionStorage.getItem("token");
  const loggedInUserRole = localStorage.getItem("role");

  const handleChange = (event) => {
    const value = event.target.value;
    const item = questions && questions[currentQuestion];
    const id = item?.questionId;
    console.log("change", id);
    if (id) {
      const clone = [...storeData];
      const findIndex = clone?.findIndex((data) => data?.questionId === id);
      if (findIndex !== -1) {
        clone[findIndex].answer = value;
        setStoreData(clone);
      } else {
        const data = {
          quizId: item?.id,
          question: item?.question,
          questionId: item?.questionId,
          answer: value,
          subQuestions: [],
        };
        setStoreData((pre) => [...pre, data]);
      }
      setMCQ(value);
    }
  };
  const MSQHandler = (event) => {
    const value = event.target.value;
    const item = questions && questions[currentQuestion];
    const id = item?.questionId;
    if (id) {
      const clone = [...MSQList];
      let filterValue = clone?.find((item) => item === value);
      if (filterValue) {
        const remove = MSQList.filter((item) => item !== value);
        setMSQList(remove);
      } else {
        const storeClone = [...storeData];
        const findIndex = storeClone?.findIndex(
          (data) => data?.questionId === id
        );
        if (findIndex !== -1) {
          storeClone[findIndex].answer = [...MSQList, value];
          setStoreData(storeClone);
        } else {
          const data = {
            quizId: item?.id,
            question: item?.question,
            questionId: item?.questionId,
            answer: [...MSQList, value],
            subQuestions: [],
          };
          setStoreData((pre) => [...pre, data]);
        }
        setMSQList((pre) => [...pre, value]);
      }
    }
  };
  const fetchDataUsingQuizId = async () => {
    const quizId = data.id;
    const randomizeQuestions = false;
    if (localStorage.getItem("role") == "STUDENT") {
      randomizeQuestions = true;
    }
    const response = await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/${quizId}?showCorrectAnswers=false&randomizeQuestions=${randomizeQuestions}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setQuestionIds(response?.data.data.questionIdList);
      });
  };
  useEffect(() => {
    var elements = document.getElementsByClassName("navbar_nav__rvSlM");
    for (let i = 0; i < elements.length; i++) {
      console.log(elements[i].textContent);
      elements[i].style.display = 'none';
    }
    var elements1 = document.getElementsByClassName("mainContainer_header__JNdBp")
    for (let i = 0; i < elements1.length; i++) {
      console.log(elements1[i].textContent);
      elements1[i].style.display = 'none';
    }
    var elements1 = document.getElementById("demo-simple-select-standard-label")
    if (elements1 !== null && Array.isArray(elements1) && elements1.length > 0) {
      for (let i = 0; i < elements1.length; i++) {
        console.log(elements1[i].textContent);
        elements1[i].style.visibility = 'hidden';
      }
    }

    fetchDataUsingQuizId();
  }, []);

  const response = async () => {
    const promises = questionIds.map((questionId) => {
      console.log("questionId", questionId);
      return axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${questionId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    });

    const responses = await Promise.all(promises);
    const questionsids = responses.map((v, i) => {
      return {
        subQuestions: v.data.data.hasSubQuestions,
        Id: v.data.data.id,
      };
    });

    for (let element of questionsids) {
      if (element.subQuestions == true) {
        element["subQuestions"] = await subConceptsfetch(element?.Id);
      } else {
        element["subQuestions"] = [];
      }
    }

    const questionss = responses?.map((response) => response.data.data);

    let questionandSubquestions = [];
    questionss.forEach((v, i) => {
      questionsids.forEach((value, id) => {
        if (v.id == value.Id) {
          questionandSubquestions.push({ ...v, ...value });
        }
      });
    });
    setQuestions(questionandSubquestions);
  };
  useEffect(() => {
    response();
  }, [questionIds]);

  const subConceptsfetch = async (Id) => {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${Id}/sub-questions?showCorrectAnswers=false`;
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data?.data;
  };

  const nextQuestionHandler = () => {
    if (currentQuestion + 1 < questionIds?.length) {
      setCurrentQuestion(currentQuestion + 1);
      // fetchDataUsingQuestionId();
    }
  };

  const preQuestionHandler = () => {
    if (currentQuestion >= 1) {
      setCurrentQuestion(currentQuestion - 1);
      // fetchDataUsingQuestionId();

      // setMatchData([]);
    }
  };
  const currentQuestionHandler = (i) => {
    setCurrentQuestion(i);
  };
  const fillHandler = (e) => {
    const value = e.target.value;
    if (value) {
      const clone = [...answerByOrder];
      const filterValue = clone?.find((item) => item === value);
      if (filterValue) {
        const remove = answerByOrder.filter((item) => item !== value);
        setAnswerByOrder(remove);
      } else {
        setAnswerByOrder((pre) => [...pre, value]);
      }
    }
  };
  const matchRadioButtonHandler = (e, question) => {
    const value = e.target.value;
    const questionData = questions && questions[currentQuestion];
    if (value) {
      const clone = [...matchData];
      const storeClone = [...storeData];
      const filterValue = clone?.find((item) => item.question === question);
      const storeIndex = storeClone.findIndex(
        (que) => que.questionId === questionData?.questionId
      );
      if (filterValue) {
        filterValue.answer = value;
        setMatchData(clone);
      } else {
        const match = {
          questionId: Math.random(),
          question,
          answer: value,
        };
        if (storeIndex !== -1) {
          storeClone[storeIndex].answer = [...matchData, match];
          setStoreData(storeClone);
          setMatchData((prev) => [...prev, match]);
        } else {
          const data = {
            quizId: questionData?.id,
            question: questionData?.question,
            questionId: questionData?.questionId,
            answer: [...matchData, match],
            subQuestions: [],
          };
          setMatchData((prev) => [...prev, match]);
          setStoreData((pre) => [...pre, data]);
        }
      }
    }
  };
  const subMCQHandler = (event, item) => {
    const value = event.target.value;
    const questionData = questions && questions[currentQuestion];
    if (value) {
      const clone = [...subMCQ];
      const storeClone = [...storeData];
      const findIndex = clone.findIndex((sub) => sub.subQuestionId === item.id);
      const storeIndex = storeClone.findIndex(
        (que) => que.questionId === questionData?.questionId
      );
      if (findIndex !== -1) {
        clone[findIndex].subAnswer = value;
        storeClone[storeIndex].subQuestions = clone;
        setStoreData(storeClone);
        setSubMCQ(clone);
      } else {
        const data = {
          subQuestionId: item?.id,
          subQuestion: item?.question,
          subAnswer: value,
        };
        if (storeIndex !== -1) {
          storeClone[storeIndex].subQuestions = [...subMCQ, data];
          setStoreData(storeClone);
        } else {
          const finalData = {
            quizId: questionData?.id,
            question: questionData?.question,
            questionId: questionData?.questionId,
            answer: "",
            subQuestions: [...subMCQ, data],
          };
          setStoreData((pre) => [...pre, finalData]);
        }
        setSubMCQ((pre) => [...pre, data]);
      }
    }
  };
  const selectQuestionHandler = (question) => {
    const color = setBg();
    const clone = [...selectedData];
    const findIndex = clone.findIndex((item) => item.question === question);
    if (findIndex !== -1) {
      const filter = clone.filter((item) => item.question !== question);
      setSelectedData(filter);
    } else {
      setSelectedQuestion({ question: question, color: color });
    }
  };
  const selectedAnswerHandler = (item, answer) => {
    const question = item?.question;
    if (question) {
      const findIndex =
        questions &&
        questions[currentQuestion]?.options.findIndex(
          (item) => item.question === question
        );
      const find =
        questions &&
        questions[currentQuestion]?.options?.find(
          (item) => item.question === question
        );
      if (findIndex !== -1) {
        const clone = [...selectedData];
        const findIndexForData = clone.findIndex(
          (item) => item.question === question
        );
        if (findIndexForData !== -1) {
        } else {
          const data = {
            question: find?.question,
            answer: answer,
            color: item.color,
          };
          setSelectedData((pre) => [...pre, data]);
          setSelectedQuestion({ question: "", color: "" });
        }
      }
    } else if (answer) {
      const findIndex = selectedData.findIndex(
        (item) => item.answer === answer
      );
      if (findIndex !== -1) {
        const remove = selectedData.filter((item) => item.answer !== answer);
        setSelectedData(remove);
      }
    }
  };
  const setBg = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor}`;
  };
  const setImageSelectHandler = (question, url) => {
    if (question) {
      const find =
        questions &&
        questions[currentQuestion]?.options.find(
          (item) => item.question === question
        );
      if (find) {
        if (url) {
          const clone = [...selectedImgData];
          const findIndex = clone.findIndex(
            (item) => item.question === question
          );
          if (findIndex !== -1) {
            clone[findIndex].img = url;
            setSelectedImgData(clone);
            setSelectedUrl("");
          } else {
            const data = {
              question: question,
              img: selectedUrl,
            };
            setSelectedImgData((pre) => [...pre, data]);
            setSelectedUrl("");
          }
        }
      }
    }
  };
  const imageHandler = (question) => {
    const remove = selectedImgData.filter((item) => item.question !== question);
    setSelectedImgData(remove);
  };
  const imageSelectHandler = (url) => {
    setSelectedUrl(url);
  };
  const answerHandler = (question) => {
    const remove = selectedAnswerData.filter(
      (item) => item.question !== question
    );
    setSelectedAnswerData(remove);
  };
  const setAnswerSelectHandler = (question, answer) => {
    if (question) {
      const find =
        questions &&
        questions[currentQuestion]?.options?.find(
          (item) => item.question === question
        );
      if (find) {
        if (answer) {
          const clone = [...selectedAnswerData];
          const findIndex = clone.findIndex(
            (item) => item.question === question
          );
          if (findIndex !== -1) {
            clone[findIndex].answer = answer;
            setSelectedAnswerData(clone);
            setSelectedAnswer("");
          } else {
            const data = {
              question: question,
              answer: selectedAnswer,
            };
            setSelectedAnswerData((pre) => [...pre, data]);
            setSelectedAnswer("");
          }
        }
      }
    }
  };
  const answerSelectHandler = (answer) => {
    setSelectedAnswer(answer);
  };

  return (<>
    {
      console.log("questin modal page")
    }
    <Modal
      open={open}
      onClose={() => {
        close();
        setCurrentQuestion(0);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      key={data?.id}
    >

      <div style={{ backgroundColor: "white" }}>
        <Box sx={style} style={{ height: "100vh", width: "100vw", backgroundColor: "white", marginBottom: "-20px", overflowY: "auto", Zindex: 9999999999999999 }}>
          {questions &&
            questions[currentQuestion]?.questionTypeCode !== "MATCH" ? (
            <Paper
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                padding: "40px",
                // borderRadius: "10px",
              }}
            >
              <div
                style={{
                  width: "45%",
                  height: "520px",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "0.7rem",
                  // overflowY: "auto",
                }}
              >
                <div
                  style={{
                    fontWeight: 600,
                    color: "rgba(5,175,116,0.89)",
                    fontSize: 21,
                  }}
                >
                  {data?.name}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                    fontSize: 18,
                    // overflow: "auto",
                    minHeight: 400,
                  }}
                >
                  <div style={{ fontWeight: 600 }}>{` ${questions && questions[currentQuestion]?.questionType
                    }`}</div>
                  <div className="Para_div_1">
                    <h4>Question:-</h4>
                    <p
                      className="para"
                      dangerouslySetInnerHTML={{
                        __html: questions[currentQuestion]?.question,
                      }}
                    ></p>
                  </div>
                  {
                    console.log("do you know",)
                  }
                  {
                    <div className="Para_div_1">
                      {questions && questions[currentQuestion]?.questionTypeCode === "CASE" && questions[currentQuestion]?.explanation && (
                        <>
                          {/* <h4>Explanation:-</h4> */}
                          {
                            // questions[currentQuestion]?.questionTypeCode === "CASE" &&
                            <p
                              className="para explain"
                              dangerouslySetInnerHTML={{
                                __html:
                                  questions &&
                                  questions[currentQuestion]?.explanation,
                              }}
                            ></p>
                          }
                        </>
                      )}
                    </div>
                  }
                  {loggedInUserRole === "REVISION_REVIEWER" &&
                    <div className="Para_div_1">
                      {questions && questions[currentQuestion]?.questionTypeCode !== "CASE" && questions[currentQuestion]?.explanation && (
                        <>
                          <h4>Explanation:-</h4>
                          {
                            // questions[currentQuestion]?.questionTypeCode === "CASE" &&
                            <p
                              className="para"
                              dangerouslySetInnerHTML={{
                                __html:
                                  questions &&
                                  questions[currentQuestion]?.explanation,
                              }}
                            ></p>
                          }
                        </>
                      )}
                    </div>
                  }

                  {(questions && questions[currentQuestion]?.imageUrl) && (
                    <div className="Para_div_1">
                      <h4>Uploaded Image</h4>
                      <img
                        className="cursorPointer"
                        src={questions && questions[currentQuestion]?.imageUrl}
                        width="50%"
                        height="240px"

                        onClick={() => {
                          setSelectedImageUrl(questions && questions[currentQuestion]?.imageUrl);
                          setModalOpen(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "55%",
                  padding: "0px 20px",
                  minHeight: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1rem",
                  }}
                >
                  <LinearProgressWithLabel
                    value={currentQuestion + 1}
                    name={"Quiz"}
                    totalQuestions={questions?.length}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "1rem",
                      // overflow: "auto",
                      height: "auto",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "1rem",
                      }}
                    >
                      {questions &&
                        !questions[currentQuestion]?.hasSubQuestions && (
                          <div style={{ color: "black" }}>Answer</div>
                        )}
                      <FormControl
                        sx={{ paddingLeft: "10px" }}
                        key={questions && !questions[currentQuestion]?.id}
                      >
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "1rem",
                          }}
                          // onChange={handleChange}
                          defaultValue="female"
                        >
                          {questions &&
                            !questions[currentQuestion]?.hasSubQuestions
                            ? questions[currentQuestion]?.questionTypeCode ===
                              "MSQ"
                              ? questions &&
                              questions[currentQuestion]?.options[0].map(
                                (value, index, array) => {
                                  console.log("item", array);

                                  return (
                                    <Student_Enrich_Quiz_MSQ ispreview={true} resumeBtnClicked={false} questions={questions} currentQuestion={currentQuestion} Previewvalue={value} />
                                    // <Grid
                                    //   item
                                    //   xs={12}
                                    //   mg={12}
                                    //   lg={12}
                                    //   style={{
                                    //     display: "flex",
                                    //   }}
                                    // >
                                    //   {/* <div
                                    //     id="modal-modal-description"
                                    //     style={{
                                    //       paddingRight: "20px",
                                    //       paddingTop: "40px",
                                    //     }}
                                    //     sx={{
                                    //       mt: 2,
                                    //       mr: 2,
                                    //       color: "#000000",
                                    //       font: "normal normal normal 20px/30px Poppins",
                                    //     }}
                                    //   >
                                    //     {String.fromCharCode(value)}
                                    //   </div> */}
                                    //   <div
                                    //     style={{
                                    //       width: "100%",
                                    //       height: "auto",
                                    //       minHeight: "60px",
                                    //       border: "1px solid #dbdee6",
                                    //       fontSize: "18px",
                                    //       color: "#1b1b1b",
                                    //       opacity: "0.9",
                                    //       marginTop: "30px",
                                    //       lineHeight: "1",
                                    //       padding: "10px",
                                    //       borderRadius: "5px",
                                    //       borderRadius: "5px",
                                    //       position: "relative",
                                    //       paddingLeft: "60px",
                                    //       paddingTop: "20px",
                                    //     }}
                                    //   >
                                    //     <Checkbox
                                    //       id="fullWidth"
                                    //       sx={{
                                    //         color: "#001AF3",
                                    //         position: "absolute",
                                    //         top: "10px",
                                    //         left: "10px",
                                    //         "&.Mui-checked": {
                                    //           color: "rgba(0, 0, 0, 0.26)",
                                    //         },
                                    //       }}
                                    //       disabled={!value?.correctAnswer}
                                    //       checked={value?.correctAnswer}
                                    //     />

                                    //     {value.answerKey}
                                    //   </div>
                                    // </Grid>
                                  );
                                }
                              )
                              : questions &&
                                questions[currentQuestion]?.questionTypeCode ===
                                "MCQ"
                                ? questions &&
                                questions[currentQuestion]?.options[0].map(
                                  (value, index, array) => {
                                    return (
                                      <Student_Enrich_Quiz_MCQ ispreview={true} resumeBtnClicked={false} questions={questions} currentQuestion={currentQuestion} Previewvalue={value} />
                                      // <Grid
                                      //   item
                                      //   xs={12}
                                      //   mg={12}
                                      //   lg={12}
                                      //   style={{ display: "flex" }}
                                      // >
                                      //   {/* <div
                                      //   id="modal-modal-description"
                                      //   style={{
                                      //     paddingRight: "20px",
                                      //     paddingTop: "40px",
                                      //   }}
                                      //   sx={{
                                      //     mt: 2,
                                      //     mr: 2,
                                      //     color: "#000000",
                                      //     font: "normal normal normal 20px/30px Poppins",
                                      //   }}
                                      // >
                                      //   {String.fromCharCode(value)}
                                      // </div> */}
                                      //   <div
                                      //     style={{
                                      //       width: "100%",
                                      //       height: "auto",
                                      //       minHeight: "60px",
                                      //       border: "1px solid #dbdee6",
                                      //       fontSize: "18px",
                                      //       color: "#1b1b1b",
                                      //       opacity: "0.9",
                                      //       marginTop: "30px",
                                      //       lineHeight: "1",
                                      //       padding: "10px",
                                      //       borderRadius: "5px",
                                      //       position: "relative",
                                      //       paddingLeft: "45px",
                                      //       paddingTop: "16px",
                                      //     }}
                                      //   >
                                      //     <CustomRadio
                                      //       id="fullWidth"
                                      //       sx={{
                                      //         color: "white",
                                      //         position: "absolute",
                                      //         top: "10px",
                                      //         left: "10px",
                                      //         "&.Mui-checked": {
                                      //           color: "rgba(0, 0, 0, 0.26)",
                                      //         },
                                      //       }}
                                      //       disabled={!value?.correctAnswer}
                                      //       checked={value?.correctAnswer}
                                      //     />
                                      //     {value?.answerKey}
                                      //   </div>
                                      // </Grid>
                                    );
                                  }
                                )
                                : questions &&
                                  questions[currentQuestion]?.questionTypeCode ===
                                  "FILL"
                                  ? questions &&
                                  questions[currentQuestion]?.options[0]?.map(
                                    (v, index) => {
                                      const findIndex =
                                        answerByOrder &&
                                        answerByOrder?.findIndex(
                                          (item) => item === v?.order
                                        );
                                      return (
                                        <Student_Enrich_Quiz_FIB ispreview={true} resumeBtnClicked={false} questions={questions} currentQuestion={currentQuestion} Previewvalue={v} />
                                        //   <Grid
                                        //     item
                                        //     xs={12}
                                        //     mg={12}
                                        //     lg={12}
                                        //     style={{ display: "flex" }}
                                        //   >
                                        //     {/* <div
                                        //   id="modal-modal-description"
                                        //   style={{
                                        //     paddingRight: "20px",
                                        //     paddingTop: "40px",
                                        //   }}
                                        //   sx={{
                                        //     mt: 2,
                                        //     mr: 2,
                                        //     color: "#000000",
                                        //     font: "normal normal normal 20px/30px Poppins",
                                        //   }}
                                        // >
                                        //   {String.fromCharCode(v)}
                                        // </div> */}
                                        //     <div
                                        //       style={{
                                        //         width: "100%",
                                        //         height: "auto",
                                        //         minHeight: "60px",
                                        //         border: "1px solid #dbdee6",
                                        //         fontSize: "18px",
                                        //         color: "#1b1b1b",
                                        //         opacity: "0.9",
                                        //         marginTop: "30px",
                                        //         lineHeight: "1",
                                        //         padding: "10px",
                                        //         borderRadius: "5px",
                                        //         position: "relative",
                                        //         paddingLeft: "45px",
                                        //         paddingTop: "16px",
                                        //       }}
                                        //     >
                                        //       {v?.answerKey}
                                        //     </div>
                                        //   </Grid>
                                      );
                                    }
                                  )
                                  : " "
                            : questions &&
                            questions[currentQuestion]?.subQuestions?.map(
                              (subItem, index) => {
                                return (
                                  <>
                                    {/* To display uploaded sub question image and question*/}
                                    {subItem.imageUrl !== null ? (
                                      <>
                                        <Grid container>
                                          {/* <Grid
                                            item
                                            xs={10}
                                            md={10}
                                            lg={10}
                                            sx={{
                                              display: "flex",
                                              justifyContent: "left",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            <Typography
                                              id="modal-modal-title"
                                              sx={{
                                                color: "#1B1B1B",
                                                font: "normal normal 400 15px/25px Poppins",
                                                textAlign: "left",
                                              }}
                                              dangerouslySetInnerHTML={{
                                                __html: `Q${index + 1})${
                                                  subItem.question
                                                }`,
                                              }}
                                            ></Typography>
                                          </Grid> */}
                                          <div className="Para_div_1">
                                            <p
                                              className="para"
                                              dangerouslySetInnerHTML={{
                                                __html: `Q${index + 1})${subItem.question
                                                  }`,
                                              }}
                                            ></p>
                                          </div>
                                          {/* <Grid
                                            item
                                            xs={2}
                                            md={2}
                                            lg={2}
                                            sx={{
                                              display: "flex",
                                              alignContent: "center",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            <img
                                              src={subItem.imageUrl}
                                              width="70%"
                                              height="240px" 
                                            />
                                          </Grid> */}

                                          <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            lg={12}
                                            sx={{
                                              display: "flex",
                                              justifyContent: "left",
                                              flexWrap: "wrap",
                                            }}
                                          >

                                            {loggedInUserRole === 'REVISION_REVIEWER' && subItem &&
                                              subItem?.explanation && (
                                                <>
                                                  <Typography
                                                    id="modal-modal-title"
                                                    variant="h6"
                                                    component="h2"
                                                    sx={{
                                                      color: "#1B1B1B",
                                                      font: "normal normal 600 16px/25px Poppins",
                                                    }}
                                                  >
                                                    Explanation:-
                                                  </Typography>
                                                  <Typography
                                                    id="modal-modal-title"
                                                    sx={{
                                                      color: "#1B1B1B",
                                                      font: "normal normal 400 15px/25px Poppins",
                                                      textAlign: "left",
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        subItem.explanation,
                                                    }}
                                                  ></Typography>
                                                </>
                                              )}
                                          </Grid>
                                          <div className="Para_div_1">
                                            <h4>Uploaded Image</h4>
                                            <img
                                              className="cursorPointer"
                                              src={subItem.imageUrl}
                                              onClick={() => {
                                                setSelectedImageUrl(subItem.imageUrl);
                                                setModalOpen(true);
                                              }}
                                              width="50%"
                                              height="240px"
                                            />
                                          </div>
                                        </Grid>
                                      </>
                                    ) : (
                                      <>
                                        <Grid
                                          item
                                          xs={12}
                                          md={12}
                                          lg={12}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "left",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          <Typography
                                            id="modal-modal-title"
                                            sx={{
                                              color: "#1B1B1B",
                                              font: "normal normal 400 15px/25px Poppins",
                                              textAlign: "left",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: `Q${index + 1})${subItem.question
                                                }`,
                                            }}
                                          ></Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          md={12}
                                          lg={12}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "left",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {loggedInUserRole === 'REVISION_REVIEWER' && subItem && subItem?.explanation && (
                                            <>
                                              <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                                sx={{
                                                  color: "#1B1B1B",
                                                  font: "normal normal 600 16px/25px Poppins",
                                                }}
                                              >
                                                Explanation:-
                                              </Typography>
                                              <Typography
                                                id="modal-modal-title"
                                                sx={{
                                                  color: "#1B1B1B",
                                                  font: "normal normal 400 15px/25px Poppins",
                                                  textAlign: "left",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: subItem.explanation,
                                                }}
                                              ></Typography>
                                            </>
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                    <Typography
                                      id="modal-modal-title"
                                      variant="h6"
                                      component="h2"
                                      sx={{
                                        color: "#1B1B1B",
                                        font: "normal normal 600 16px/25px Poppins",
                                        cursor: 'text'
                                      }}
                                    >
                                      Answer
                                    </Typography>
                                    {/* To display answer content fields */}
                                    <Grid container spacing={4} sx={{ p: 2 }}>
                                      {subItem.options[0].length > 0 &&
                                        subItem.options[0].map((value, ind) => {
                                          return subItem.questionTypeCode ===
                                            "FILL" ? (
                                            <Student_Enrich_Quiz_FIB ispreview={true} resumeBtnClicked={false} questions={subItem} currentQuestion={currentQuestion} Previewvalue={value} />
                                            // <Grid item xs={12} mg={12} lg={12} style={{ overflow: "scroll" }}>
                                            //   <Box
                                            //     sx={{
                                            //       display: "flex",
                                            //       justifyContent:
                                            //         "space-between",
                                            //     }}
                                            //   >
                                            //     <Typography
                                            //       id="modal-modal-description"
                                            //       sx={{
                                            //         mt: 2,
                                            //         mr: 2,
                                            //         color: "#000000",
                                            //         font: "normal normal normal 20px/30px Poppins",
                                            //       }}
                                            //     >
                                            //       {String.fromCharCode(
                                            //         ind + 65
                                            //       )}
                                            //     </Typography>
                                            //     <CustomTextField
                                            //       id="fullWidth"
                                            //       disabled
                                            //       value={value.answerKey[0]}
                                            //     />
                                            //   </Box>
                                            // </Grid>
                                          ) : subItem.questionTypeCode ===
                                            "TEXT" ? (
                                            <Grid item xs={12} mg={12} lg={12}>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <Typography
                                                  id="modal-modal-description"
                                                  sx={{
                                                    mt: 2,
                                                    mr: 2,
                                                    color: "#000000",
                                                    font: "normal normal normal 20px/30px Poppins",
                                                  }}
                                                >
                                                  {String.fromCharCode(
                                                    ind + 65
                                                  )}
                                                </Typography>
                                                <CustomTextField
                                                  id="fullWidth"
                                                  disabled
                                                />
                                              </Box>
                                            </Grid>
                                          ) : subItem.questionTypeCode ===
                                            "MCQ" ? (

                                            <Student_Enrich_Quiz_MCQ ispreview={true} resumeBtnClicked={false} questions={subItem} currentQuestion={currentQuestion} Previewvalue={value} />

                                            // <Grid item xs={12} mg={12} lg={12}>
                                            //   <Box
                                            //     sx={{
                                            //       display: "flex",
                                            //       justifyContent:
                                            //         "space-between",
                                            //     }}
                                            //   >
                                            //     <Typography
                                            //       id="modal-modal-description"
                                            //       sx={{
                                            //         mt: 2,
                                            //         mr: 2,
                                            //         color: "#000000",
                                            //         font: "normal normal normal 20px/30px Poppins",
                                            //       }}
                                            //     >
                                            //       {String.fromCharCode(
                                            //         ind + 65
                                            //       )}
                                            //     </Typography>
                                            //     <div
                                            //       style={{
                                            //         width: "100%",
                                            //         height: "70px",
                                            //         border: "1px solid #dbdee6",
                                            //         fontSize: "18px",
                                            //         color: "#1b1b1b",
                                            //         opacity: "0.9",
                                            //         lineHeight: "1",
                                            //         padding: "10px",
                                            //         borderRadius: "5px",
                                            //         overflow: "scroll",
                                            //       }}
                                            //     >
                                            //       <CustomRadio
                                            //         id="fullWidth"
                                            //         sx={{
                                            //           color: "white",
                                            //           "&.Mui-checked": {
                                            //             color:
                                            //               "rgba(0, 0, 0, 0.26)",
                                            //           },
                                            //         }}
                                            //         checked={
                                            //           value.correctAnswer
                                            //         }
                                            //       />
                                            //       {value.answerKey}
                                            //     </div>
                                            //   </Box>
                                            // </Grid>
                                          ) : subItem.questionTypeCode ===
                                            "MSQ" ? (
                                            <Student_Enrich_Quiz_MSQ ispreview={true} resumeBtnClicked={false} questions={subItem} currentQuestion={currentQuestion} Previewvalue={value} />
                                            // <Grid item xs={12} mg={12} lg={12}>
                                            //   <Box
                                            //     sx={{
                                            //       display: "flex",
                                            //       justifyContent:
                                            //         "space-between",
                                            //     }}
                                            //   >
                                            //     <Typography
                                            //       id="modal-modal-description"
                                            //       sx={{
                                            //         mt: 2,
                                            //         mr: 2,
                                            //         color: "#000000",
                                            //         font: "normal normal normal 20px/30px Poppins",
                                            //       }}
                                            //     >
                                            //       {String.fromCharCode(
                                            //         ind + 65
                                            //       )}
                                            //     </Typography>
                                            //     <div
                                            //       style={{
                                            //         width: "100%",
                                            //         height: "70px",
                                            //         border: "1px solid #dbdee6",
                                            //         fontSize: "18px",
                                            //         color: "#1b1b1b",
                                            //         opacity: "0.9",
                                            //         lineHeight: "1",
                                            //         padding: "10px",
                                            //         borderRadius: "5px",
                                            //         overflow: "scroll",
                                            //       }}
                                            //     >
                                            //       <Checkbox
                                            //         id="fullWidth"
                                            //         sx={{
                                            //           color: "#001AF3",
                                            //           "&.Mui-checked": {
                                            //             color:
                                            //               "rgba(0, 0, 0, 0.26)",
                                            //           },
                                            //         }}
                                            //         checked={
                                            //           value.correctAnswer
                                            //         }
                                            //       />
                                            //       {value.answerKey}
                                            //     </div>
                                            //   </Box>
                                            // </Grid>
                                          ) : (
                                            <>
                                              <p>There is no Sub Question</p>
                                            </>
                                          );
                                        })}
                                    </Grid>
                                  </>
                                );
                              }
                            )}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                    flexDirection: "row",
                    columnGap: "1rem",
                  }}
                >
                  <ExitButton
                    onClick={() => {
                      console.log("close called")
                      close();
                      setCurrentQuestion(0);
                      var elements = document.getElementsByClassName("navbar_nav__rvSlM");
                      console.log(elements)
                      for (let i = 0; i < elements.length; i++) {
                        console.log(elements[i].textContent);
                        elements[i].style.display = 'block';
                      }
                      var elements1 = document.getElementsByClassName("mainContainer_header__JNdBp")
                      for (let i = 0; i < elements1.length; i++) {
                        console.log(elements1[i].textContent);
                        elements1[i].style.display = 'block';
                      }
                      var elements1 = document.getElementById("demo-simple-select-standard-label")
                      if (elements1 !== null && Array.isArray(elements1) && elements1.length > 0) {
                        for (let i = 0; i < elements1.length; i++) {
                          console.log(elements1[i].textContent);
                          elements1[i].style.display = 'block';
                        }
                      }
                    }}
                  >
                    Exit
                  </ExitButton>
                  <PreviewButton
                    onClick={preQuestionHandler}
                    disabled={currentQuestion + 1 === 1}
                  >
                    <KeyboardArrowLeftIcon style={{ fontSize: "25px" }} />
                    Previous Question
                  </PreviewButton>

                  <NextButton
                    onClick={nextQuestionHandler}
                    disabled={currentQuestion + 1 === data?.quizQuestions?.length}
                    className={currentQuestion + 1 === questions?.length ? 'cursorDefault' : 'cursorPointer'}
                  >
                    Next Question
                    <KeyboardArrowRightIcon style={{ fontSize: "25px" }} />
                  </NextButton>
                </div>
              </div>
            </Paper>
          ) : (
            <Paper
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: "15px",
                // borderRadius: "10px",
                // rowGap: "10px",
                height: "600px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "1rem",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontWeight: 600,
                    color: "rgba(5,175,116,0.89)",
                    fontSize: 21,
                    width: "100%",
                  }}
                >
                  {data?.name}
                </div>
                <LinearProgressWithLabel
                  value={currentQuestion + 1}
                  name={"Quiz"}
                  totalQuestions={questions?.length}
                />
              </div>

              <Grid container p={2}>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "left" }}
                >
                  <Typography
                    sx={{
                      color: "#1B1B1B",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "25px",
                    }}
                  >
                    Question
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "left" }}
                >
                  {questions && questions[currentQuestion]?.imageUrl && (
                    <Typography
                      sx={{
                        color: "#1B1B1B",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "25px",
                      }}
                    >
                      Uploaded Image
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "left" }}
                  style={{ height: "120px", overflow: "scroll" }}
                >
                  <p
                    className="para"
                    dangerouslySetInnerHTML={{
                      __html: questions && questions[currentQuestion]?.question,
                    }}
                  ></p>
                </Grid>
                {
                  (loggedinuserrole === "REVISION_REVIEWER" && questions[currentQuestion]?.explanation) &&

                  <>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      lg={6}
                      sx={{ display: "flex", justifyContent: "left" }}
                    >
                      <Typography
                        sx={{
                          color: "#1B1B1B",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "25px",
                        }}
                      >
                        Explanation:-
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      lg={6}
                      sx={{ display: "flex", justifyContent: "left" }}
                      style={{ height: "120px", overflow: "scroll" }}
                    >
                      <p
                        className="para"
                        dangerouslySetInnerHTML={{
                          __html: questions && questions[currentQuestion]?.explanation,
                        }}
                      ></p>
                    </Grid>

                  </>

                }
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{ display: "flex", justifyContent: "left" }}
                  style={{ height: "120px", overflow: "scroll" }}
                >
                  <Typography>
                    {questions && questions[currentQuestion]?.imageUrl && (
                      <img
                        src={questions && questions[currentQuestion]?.imageUrl}
                        width="100%"
                        height="auto"
                      />
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "1rem",
                      height: "100%",
                      // overflow: "auto",
                    }}
                  >
                    {/* <div className="MTF_DD_Box" style={{ height: "250px" }}> */}
                    {/* <div className="MTF_DD_Div">
                        <div className="MTF_DD_Qstn_And_Field_div">
                          
                          {questions &&
                            questions[currentQuestion]?.options[0]?.length > 0 &&
                            questions[currentQuestion]?.options[0].map((v) => {
                              return (
                                <div className="MTF_DD_Fields_1">
                                  <>
                                    <div className="MTF_DD_Qstn_Field_1_B1">
                                      <p className="para">
                                        {v?.question.length > 100
                                          ? v.question.slice(0, 40)
                                          : v.question}
                                        {v.question.length > 100 && (
                                          <span style={{ color: "orange", fontSize: "small" }} onClick={() => {
                                            setViewModal(true);
                                            setQuestion(v.question);
                                          }}>...seeMore</span>
                                        )}
                                      </p>
                                    </div>
                                    <div className="MTF_DD_Qstn_Field_1_B2">
                                      <p className="para">
                                        {v.answers.length > 0 && v?.answers.map((v, i) => {
                                          return (
                                            <span>{v}</span>
                                          )
                                        })}
                                      </p>
                                    </div>
                                  </>
                                </div>
                              );
                            })}
                        </div>
                       
                        <div>
                          <div className="MTF_DD_Ans">
                            {questions &&
                              questions[currentQuestion]?.options[0]?.length >
                              0 &&
                              questions[currentQuestion]?.options[0].map((v) => {
                                return (
                                  <div className="MTF_DD_Ans_Field_1">
                                    <p>
                                      {v?.jumbledAnswer.length > 100
                                        ? v.jumbledAnswer.slice(0, 35)
                                        : v.jumbledAnswer}
                                      {v.jumbledAnswer.length > 100 && (
                                        <span style={{ color: "orange", fontSize: "small" }} onClick={() => {
                                          setViewModal(true);
                                          setQuestion(v.jumbledAnswer);
                                        }}>...seeMore</span>
                                      )}</p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div> */}

                    {
                      questions[currentQuestion].matchType == "DRAW_LINE" ?
                        <Student_Enrich_Quiz_MTF_DrawLines questions={questions} currentQuestion={currentQuestion} ispreview={true} resumeBtnClicked={false} attendedMtfDl={[]} setAttendMtfDl={setAttendMtfDl} /> :
                        <Student_Enrich_Quiz_MTF_DD questions={questions} currentQuestion={currentQuestion} ispreview={true} resumeBtnClicked={false} />
                    }
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                    flexDirection: "row",
                    columnGap: "1rem",
                  }}
                >
                  <ExitButton
                    onClick={() => {
                      close();
                      setCurrentQuestion(0);
                    }}
                  >
                    Exit
                  </ExitButton>
                  <PreviewButton
                    onClick={preQuestionHandler}
                    disabled={currentQuestion + 1 === 1}
                  >
                    <KeyboardArrowLeftIcon style={{ fontSize: "25px" }} />
                    Previous Question
                  </PreviewButton>

                  <NextButton
                    onClick={nextQuestionHandler}
                    disabled={currentQuestion + 1 === data?.quizQuestions?.length}
                  >
                    Next Question
                    <KeyboardArrowRightIcon style={{ fontSize: "25px" }} />
                  </NextButton>
                </div>
              </div>
            </Paper>
          )}
          <div
            style={{
              minHeight: "80px",
              borderRadius: "10px",
              padding: "20px",
              display: "flex",
              flexDirection: "row",
              columnGap: "1rem",
              width: "100%",
            }}
          >
            <div
              style={{
                fontSize: 15,
                fontWeight: 600,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                rowGap: "5px",
                width: "10%",
              }}
            >
              <span>Total</span>
              <span>Questions</span>
              <span>{data.totalQuestions}</span>
            </div>
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "row",
                columnGap: "1rem",
                width: "90%",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {questions?.map((item, index) => {
                    return (
                      <Grid item xs={0.6} md={0.6} key={index}>
                        <Item
                          sx={{
                            background:
                              currentQuestion === index ||
                                currentQuestion >= index
                                ? ""
                                : "#928d8d",
                            cursor: "pointer",
                          }}
                          onClick={() => currentQuestionHandler(index)}
                        >
                          {index + 1}
                        </Item>
                      </Grid>
                    );
                  })}
                </Grid>
                <QuestionViewModal open={isViewModal} deleteName={queston} close={() => { setViewModal(false) }} />
              </Box>
            </div>
          </div>
        </Box>
      </div>
    </Modal>

    <Student_Image_Open_Modal
      selectedImageUrl={selectedImageUrl}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
    />
  </>
  );
};
export default QuestionModal;
