import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "../../Navbar";
import MainContainer from "../dashboard/MainContainer";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AssignQuizContentOptimised from "./AssignQuizContentOptimised";

const CordinatorAssignQuiz = () => {
   //added by sg
  const [headerTitle,setHeaderTitle] = useState('')
  const handleHeaderTitle = (value) => {
    setHeaderTitle(value)
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Navbar handleHeaderTitle={(value)=>handleHeaderTitle(value)}/>  
      <MainContainer children={<AssignQuizContentOptimised/>} headerTitle={headerTitle} />  
    
    </LocalizationProvider>
  );
};
export default CordinatorAssignQuiz;