import React from 'react';
import { useNavigate } from "react-router-dom";

const SchoolAdminBreadcrumb = ({dashHome, sectionName, date, isSecondOne, sectionLink, secondSectionName}) => {
  const navigate = useNavigate();
  return (
    <div className="az_home_navigation_section">
        <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}>
              <div style={{ display: "flex", alignItems: "flex-start"}}>
                  <p className="az_navigation_text"><span style={{position:"relative"}}>
                      <img style={{position: "absolute", top:"1px"}}
                        src={dashHome}
                        alt="no img"
                        width="18px"
                        height="18px"
                        
                      /> </span>
                      <span onClick={() => {
                        sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                        sessionStorage.removeItem("sideNavSubMenuNames");
                        navigate("/dashboard/template")
                      }} className="az_navigation_content" style={{marginLeft:"20px"}}>Home</span>
                  </p>
              </div>
          <p>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
          </p>
          <p className={isSecondOne ? "az_navigation_text": ""} onClick={() => isSecondOne ? navigate(sectionLink) : ''}><span style={{color: isSecondOne ? "" : "#ffa500"}}>{sectionName}</span></p>
          {isSecondOne && <>
          <p>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
          </p>
          <p><span style={{color: "#ffa500"}}>{secondSectionName}</span></p>
          </>}
        </div>
        <p className="az_last_update_time">Last Update: {date}</p>
      </div>
  );
}

export default SchoolAdminBreadcrumb;