import axios from 'axios'

async function plans_controller_getall(setPlansList) {
  let token = sessionStorage.getItem("token");
   
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/page`,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setPlansList(json.data.data)
    })
    .catch(err=>console.log(err)) 
}

export default plans_controller_getall;
