import React, { useState } from "react";
import Switch from "../../../components/common/Switch";
import Reason from "../../../img/reason.png";
import BluePrintReasonModal from "../../../components/BluePrintReasonModal/BluePrintReasonModal";
import { Tooltip } from "@mui/material";
import { truncateString } from "../../../utils/helper";
import { CreateOutlined, DeleteOutline } from "@mui/icons-material";
import DisabledSwitch from "../../../components/common/DisabledSwitch";
import moment from 'moment';
export const HomeWorkListBody = ({
  homeWorkListData,
  currentPage,
  setShowDeleteModal,
  setSelectedForDelete,
  handleToggle,
  setSelectedForEdit,
  pageSize,
  totalElements,
  role,
  setButtonEdit,
  page,
  setSelectedDataForEdit,
}) => {
  const startIndex = (currentPage - 1) * pageSize + 1;

  const handleEdit = (id, item) => {
    setSelectedForEdit(id);
    setSelectedDataForEdit(item);
  };

  return (
    <tbody>
      {homeWorkListData?.map((item, index) => {
        return (
          <tr
            className="table-data"
            style={{
              textAlign: "center",
              border: "1px Solid #D9D9D9",
            }}
          >
            <td>{startIndex + index}</td>
            <td>
              <Tooltip placement={"top"} title={item?.homeworkName}>
                {truncateString(item?.homeworkName, 8)}
              </Tooltip>{" "}
            </td>
            <td>
              {" "}
              <Tooltip placement={"top"} title={item?.description}>
                {truncateString(item?.description, 8)}
              </Tooltip>{" "}
            </td>
            {/* <td>{item?.assignedDate}</td> */}
            <td>{moment(item?.assignedDate).format('DD-MM-YYYY')}</td>
            {/* <td>{item?.submittingDate}</td> */}
            <td>{moment(item?.submittingDate).format('DD-MM-YYYY')}</td>
            <td>{item?.status}</td>
            {role !== "student" ? (
              <td>
                <Switch
                  checked={item?.active}
                  onChange={(e) => handleToggle(e, item?.id)}
                />
              </td>
            ) : null}
            {role !== "student" ? (
              <td style={{ paddingLeft: "10px" }}>
                <div className="action-container">
                  <>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEdit(item?.id, item)}
                    >
                      <CreateOutlined style={{ fontSize: "18px" }} />
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowDeleteModal(true);
                        setSelectedForDelete(item?.id);
                      }}
                    >
                      <DeleteOutline style={{ fontSize: "18px" }} />
                      {/* <img src={action__Delete} alt="Dlt-Btn" /> */}
                    </div>
                  </>
                </div>
              </td>
            ) : null}
          </tr>
        );
      })}
    </tbody>
  );
};
