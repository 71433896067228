import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { red } from "@mui/material/colors";

const DarkRedButton = (props) => {
    const AddButton = styled(Button)(() => ({
        color: "#fff",
        backgroundColor: props.disabled ? "#ccc" : "rgba(255, 0, 0, 0.89)", // Red color
        borderColor: red[500], // Red color
        textTransform: "none",
        display: "flex",
        flexDirection: "row",
        columnGap: "10px",
        fontSize: "0.9vw",
        "&:hover": {
            color: "#fff",
            backgroundColor: props.disabled ? "#ccc" : red[500], // Red color
            border: 0,
            boxShadow: props.disabled ? "0px" : "0px 3px 5px #ff0000", // Red color
        },
    }));
    return (
        <AddButton id={props.id} disabled={props.disabled} onClick={props.onClick} style={props.style}>
            {props.children}
        </AddButton>
    );
};
export default DarkRedButton;