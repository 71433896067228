import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { isNOTNullOrUndefined } from "../../../../utils/helper";
import ViewquizTableComponent from "./ViewquizTableComponent";
import ViewQuizFilterBar from "./ViewQuizFilterBar";
import BreadCrumbs from "../General/BreadCrumbs";
import QuestionModal from "../../QuestionModal";
import commonsort from "../../../js/CommonSort1";

const ViewQuizContent = () => {
	const [isPreview, setPreview] = useState(false);
	// <-----------states for filter componenet-------------->
	const [grades, setGrades] = useState([]);
	const [subjects, setSubject] = useState([]);
	const [boards, setBoard] = useState([]);

	const [filterState, setFilterState] = useState({
		grade: {},
		board: {},
		subject: {},
		searchParams: "",
	});

	const [changeCount, setChangeCount] = useState(1);
	const [quizes, setQuizes] = useState([]);
	const [pageSize, setPageSize] = useState(20);
	const [currentPage, setCurrentPage] = useState(0);
	const [totalPages, setTotalPages] = useState(1);
	const [sortBy, setSortBy] = useState('id');
	const [sortAscending, setSortAscending] = useState(true);

	const handleChange = (e) => {
		setCurrentPage(0)
		setChangeCount((prev) => prev + 1);
		let currentData = filterState;
		let value = e.target.value;
		let name = e.target.name;
		if (name === "grade") {
			currentData[name] = grades.find((item) => item.id === value);
		} else if (name === "board") {
			currentData[name] = boards.find((item) => item.id === value);
		} else if (name === "subject") {
			currentData[name] = subjects.find((item) => item.id === value);
		} else {
			currentData[name] = value;
		}
	};

	const getQuizData = async () => {
		const token = sessionStorage.getItem("token");
		await axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL
				}v1/api/content/quiz/page?pageNumber=${currentPage}&pageSize=${pageSize}
				&sortOrder=${sortAscending}&active=true&sortBy=${sortBy}
				${isNOTNullOrUndefined(filterState.searchParams)
					? "&search=" + filterState.searchParams
					: ""
				}${isNOTNullOrUndefined(filterState.board?.id)
					? "&boardId=" + filterState.board?.id
					: ""
				}${isNOTNullOrUndefined(filterState.grade?.id)
					? "&gradeId=" + filterState.grade?.id
					: ""
				}${isNOTNullOrUndefined(filterState.subject?.id)
					? "&subjectId=" + filterState.subject?.id
					: ""
				}&reviewStatus=APPROVED
				`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(async (res) => {
				console.log(res, "response");
				if (res.status === 200) {
					// var sorted = await commonsort(res.data.data.data, sortBy, sortAscending);
					// console.log(sortAscending, sorted, res.data.data.data)
					setQuizes(res.data.data.data);
					setTotalPages(res.data.data.totalPages);
					//
					const currPageNUm = res.data.data.pageNumber
					const sizePerPage = res.data.data.pageSize
					let startingIndex = 0
					if (currPageNUm === 1) {
						startingIndex = 0
					} else {
						startingIndex = (currPageNUm - 1) * sizePerPage
					}
					setStartIndexfrom(startingIndex)
				} else {
					setQuizes([]);
					setTotalPages(0);
				}
			});
	};

	const [selectedQuiz, setSelectedQuiz] = useState("");
	useEffect(() => {
		getQuizData();
	}, [changeCount, pageSize, currentPage, sortAscending]);

	const handleSort = (field) => {
		console.log("handle sort", field)
		// setSortBy(field.toLowerCase())
		setSortBy(field)
		setSortAscending(prev => !prev)
	}

	const [startIndexfrom, setStartIndexfrom] = useState(0)
	return (
		<MainContainer>
			<BreadCrumbs currentPage={"View Quizzes"} />
			<ViewQuizFilterBar
				filterState={filterState}
				setFilterState={setFilterState}
				setGrades={setGrades}
				grades={grades}
				setSubject={setSubject}
				subjects={subjects}
				boards={boards}
				setBoard={setBoard}
				handleChange={handleChange}
			/>
			<ViewquizTableComponent
				quizes={quizes}
				setQuizes={setQuizes}
				pageSize={pageSize}
				setPageSize={setPageSize}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				totalPages={totalPages}
				setPreview={setPreview}
				setSelectedQuiz={setSelectedQuiz}
				handleSort={handleSort}
				startIndexfrom={startIndexfrom}
			/>
			{isPreview && (
				<QuestionModal
					open={isPreview}
					data={selectedQuiz}
					close={() => {
						setPreview(false);
						setSelectedQuiz({});
					}}
				/>
			)}
		</MainContainer>
	);
};

export default ViewQuizContent;

const MainContainer = styled.div`
	padding: 10px 20px;
	width: 100% !important;
	background-color: #f3f2ff;
`;
