const PlanList = [
    {
      Grade: "Sr. KG",
      Board: "CBSE",
      LessonPlan: true,
      RevisionModuleTeacher: false,
      RmLock: false,
      AssessmentModule: false,
      Activities: false,
      WSdownload: false,
      WBdownload: false,
      News: false,
      StudentBooklet: false,
      RevisionModuleStudent: false,
    },
    {
      Grade: "Grade 1",
      Board: "CBSE",
      LessonPlan: true,
      RevisionModuleTeacher: false,
      RmLock: false,
      AssessmentModule: false,
      Activities: false,
      WSdownload: false,
      WBdownload: false,
      News: false,
      StudentBooklet: false,
      RevisionModuleStudent: false,
    },
    {
      Grade: "Grade 2",
      Board: "CBSE",
      LessonPlan: true,
      RevisionModuleTeacher: false,
      RmLock: false,
      AssessmentModule: false,
      Activities: false,
      WSdownload: false,
      WBdownload: false,
      News: false,
      StudentBooklet: false,
      RevisionModuleStudent: false,
    },
    {
      Grade: "Grade 3",
      Board: "CBSE",
      LessonPlan: true,
      RevisionModuleTeacher: false,
      RmLock: false,
      AssessmentModule: false,
      Activities: false,
      WSdownload: false,
      WBdownload: false,
      News: false,
      StudentBooklet: false,
      RevisionModuleStudent: false,
    },
    {
      Grade: "Grade 4",
      Board: "CBSE",
      LessonPlan: true,
      RevisionModuleTeacher: false,
      RmLock: false,
      AssessmentModule: false,
      Activities: true,
      WSdownload: false,
      WBdownload: false,
      News: false,
      StudentBooklet: false,
      RevisionModuleStudent: false,
    },
    {
      Grade: "Grade 5",
      Board: "CBSE",
      LessonPlan: true,
      RevisionModuleTeacher: false,
      RmLock: false,
      AssessmentModule: false,
      Activities: false,
      WSdownload: false,
      WBdownload: false,
      News: false,
      StudentBooklet: false,
      RevisionModuleStudent: false,
    },
    {
      Grade: "Grade 6",
      Board: "CBSE",
      LessonPlan: true,
      RevisionModuleTeacher: false,
      RmLock: false,
      AssessmentModule: false,
      Activities: false,
      WSdownload: false,
      WBdownload: false,
      News: false,
      StudentBooklet: false,
      RevisionModuleStudent: false,
    },
    {
      Grade: "Grade 7",
      Board: "CBSE",
      LessonPlan: true,
      RevisionModuleTeacher: false,
      RmLock: false,
      AssessmentModule: false,
      Activities: false,
      WSdownload: false,
      WBdownload: false,
      News: false,
      StudentBooklet: false,
      RevisionModuleStudent: false,
    },
    {
      Grade: "Grade 8",
      Board: "CBSE",
      LessonPlan: true,
      RevisionModuleTeacher: false,
      RmLock: false,
      AssessmentModule: false,
      Activities: false,
      WSdownload: false,
      WBdownload: false,
      News: false,
      StudentBooklet: false,
      RevisionModuleStudent: false,
    },
    
  ];
  export default PlanList;