import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	Paper,
	Radio,
	RadioGroup,
	TextField,
	styled,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React from "react";

function CaseStudyPreviewModal({
	currentQuestion,
	questions,
	data,
	subquestions,
	MSQHandler,
	MSQList,
	handleChange,
	answerByOrder,
	MCQ,
	fillHandler,
	matchRadioButtonHandler,
	matchData,
	subMCQ,
	subMCQHandler,
	selectedAnswerData,
	answerHandler,
	close,
	setAnswerSelectHandler,
	selectedAnswer,
	answerSelectHandler,
	setCurrentQuestion,
	preQuestionHandler,
	onSubmitClick,
	nextQuestionHandler,
}) {
	return (
		<Paper style={{ padding: 20 }}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div>
					<h2 style={{ fontSize: 20 }}>{data?.name}</h2>
					<h2 style={{ fontSize: 20 }}>Case study </h2>
					<h2 style={{ fontSize: 18 }}>
						{currentQuestion + 1}.{" "}
						{questions[currentQuestion]?.question}
					</h2>
				</div>

				<p style={{ fontSize: 20, color: "#093EB5" }}>
					Total Questions : {currentQuestion + 1} of{" "}
					{questions.length}
				</p>
			</div>
			<div
				style={{
					margin: "20px 0 20px 0",
					maxHeight: "50vh",
					overflow: "scroll",
				}}
			>
				{subquestions?.map((subQuestion, index) => (
					<div key={data.id}>
						{subQuestion?.questionTypeCode !== "MATCH" ? (
							<div
								style={{
									width: "100%",
									display: "flex",
									flexDirection: "row",
									padding: "20px",
									borderRadius: "10px",
								}}
							>
								<div
									style={{
										width: "45%",
										display: "flex",
										flexDirection: "column",
										rowGap: "0.7rem",
										overflow: "hidden",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: "10px",
											fontSize: 18,
											overflow: "auto",
										}}
									>
										<div
											style={{
												fontWeight: 600,
											}}
										>{` ${subQuestion?.questionType}`}</div>
										<div
											style={{
												paddingLeft: "10px",
												fontSize: 17,
											}}
										>
											{`${index + 1} .${
												subQuestion.question
											}`}
										</div>
										{subQuestion?.imageUrl && (
											<div
												style={{
													fontWeight: 600,
													fontSize: 18,
												}}
											>
												<img
													src={subQuestion?.imageUrl}
													alt={""}
													width={200}
												/>
											</div>
										)}
									</div>
								</div>
								<div
									style={{
										width: "55%",
										padding: "0px 20px",

										display: "flex",
										flexDirection: "column",
										justifyContent: "space-between",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: "1rem",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												rowGap: "1rem",
												overflow: "auto",
												padding: "10px",
											}}
										>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													rowGap: "1rem",
												}}
											>
												<div
													style={{
														color: "black",
													}}
												>
													Answer
												</div>

												<FormControl
													sx={{
														paddingLeft: "10px",
													}}
													key={subQuestion?.id}
												>
													<RadioGroup
														aria-labelledby="demo-controlled-radio-buttons-group"
														name="controlled-radio-buttons-group"
														sx={{
															display: "flex",
															flexDirection:
																"column",
															rowGap: "1rem",
														}}
														// onChange={handleChange}
														defaultValue="female"
													>
														{subQuestion
															? subQuestion?.questionTypeCode ===
															  "MSQ"
																? subQuestion?.options[0]?.map(
																		(
																			data,
																			index
																		) => {
																			return (
																				<FormControlLabel
																					value={
																						data.option
																					}
																					control={
																						<Checkbox
																							onChange={(
																								e
																							) =>
																								MSQHandler(
																									e
																								)
																							}
																							checked={
																								MSQList.indexOf(
																									data.option
																								) >
																								-1
																							}
																						/>
																					}
																					label={
																						data.option
																					}
																					sx={{
																						border: "1px solid #827d7d",
																						borderRadius:
																							"4px",
																					}}
																					key={
																						index
																					}
																					// onChange={MSQHandler}
																				/>
																			);
																		}
																  )
																: subQuestion?.questionTypeCode ===
																  "MCQ"
																? subQuestion?.options[0]?.map(
																		(
																			data,
																			index
																		) => {
																			return (
																				<RadioGroup
																					aria-labelledby="demo-controlled-radio-buttons-group"
																					name="controlled-radio-buttons-group"
																					sx={{
																						display:
																							"flex",
																						flexDirection:
																							"column",
																						rowGap: "1rem",
																					}}
																					onChange={(
																						e
																					) =>
																						handleChange(
																							e
																						)
																					}
																					value={
																						MCQ
																					}
																					key={
																						index
																					}
																				>
																					<FormControlLabel
																						value={
																							data.option
																						}
																						control={
																							<Radio />
																						}
																						label={
																							data.option
																						}
																						sx={{
																							border: "1px solid #827d7d",
																							borderRadius:
																								"4px",
																						}}
																						key={
																							index
																						}
																					/>
																				</RadioGroup>
																			);
																		}
																  )
																: subQuestion?.questionTypeCode ===
																  "FILL"
																? subQuestion?.options?.map(
																		(
																			data,
																			index
																		) => {
																			const findIndex =
																				answerByOrder &&
																				answerByOrder?.findIndex(
																					(
																						item
																					) =>
																						item ===
																						data?.order
																				);

																			return (
																				<div
																					key={
																						index
																					}
																					style={{
																						display:
																							"flex",
																						flexDirection:
																							"row",
																						columnGap:
																							"2rem",
																						alignItems:
																							"center",
																					}}
																				>
																					{findIndex !==
																						-1 && (
																						<div
																							style={{
																								display:
																									"flex",
																								flexDirection:
																									"row",
																								columnGap:
																									"5px",
																							}}
																						>
																							<div>
																								Answer
																							</div>
																							{findIndex +
																								1}
																						</div>
																					)}
																					<FormControlLabel
																						value={
																							data.order
																						}
																						control={
																							<TextField
																								disabled
																								value={
																									data[0]
																										?.answerKey[0]
																								}
																								sx={{
																									border: "1px solid #827d7d",
																									borderRadius:
																										"4px",
																									width: "100%",
																								}}
																							/>
																						}
																						// label={
																						// 	data.answer
																						// }
																						sx={{
																							// border: "1px solid #827d7d",
																							borderRadius:
																								"4px",
																							width: "100%",
																						}}
																						key={
																							data.order
																						}
																						onChange={(
																							e
																						) => {
																							fillHandler(
																								e
																							);
																						}}
																					/>
																				</div>
																			);
																		}
																  )
																: questions &&
																  questions[
																		currentQuestion
																  ]
																		?.questionTypeCode ===
																		"MATCH"
																? questions &&
																  questions[
																		currentQuestion
																  ]?.options?.map(
																		(
																			matchItem,
																			index
																		) => {
																			const subOptions =
																				questions[
																					currentQuestion
																				]?.options
																					.map(
																						(
																							que
																						) =>
																							que.answer
																					)
																					.sort();
																			return (
																				<div
																					key={
																						index
																					}
																					style={{
																						display:
																							"flex",
																						flexDirection:
																							"row",
																						columnGap:
																							"1rem",
																					}}
																				>
																					<FormControl
																						key={
																							index
																						}
																					>
																						<FormLabel
																							id="demo-customized-radios"
																							sx={{
																								fontWeight: 600,
																								color: "black",
																							}}
																							key={
																								index
																							}
																						>
																							{
																								matchItem.question
																							}
																						</FormLabel>
																						<RadioGroup
																							defaultValue="female"
																							aria-labelledby="demo-customized-radios"
																							name="customized-radios"
																							row
																							onChange={(
																								e
																							) =>
																								matchRadioButtonHandler(
																									e,
																									matchItem.question
																								)
																							}
																						>
																							{subOptions?.map(
																								(
																									subSubItem,
																									index
																								) => {
																									const find =
																										matchData &&
																										matchData?.filter(
																											(
																												item
																											) =>
																												item?.answer ===
																												subSubItem
																										);
																									return (
																										<FormControlLabel
																											value={
																												subSubItem
																											}
																											control={
																												<Radio />
																											}
																											label={
																												subSubItem
																											}
																											key={
																												subSubItem
																											}
																											disabled={
																												!!find?.length
																											}
																										/>
																									);
																								}
																							)}
																						</RadioGroup>
																					</FormControl>
																				</div>
																			);
																		}
																  )
																: ""
															: questions &&
															  questions[
																	currentQuestion
															  ]?.subQuestions?.map(
																	(
																		subItem,
																		index
																	) => {
																		return (
																			<div
																				key={
																					index
																				}
																				style={{
																					display:
																						"flex",
																					flexDirection:
																						"column",
																					rowGap: "1rem",
																				}}
																			>
																				<div
																					style={{
																						fontSize: 17,
																						display:
																							"flex",
																						flexDirection:
																							"row",
																						columnGap:
																							"10px",
																						justifyContent:
																							"space-between",
																					}}
																				>
																					{`Q${
																						index +
																						1
																					} ) ${
																						subItem.question
																					}`}
																					{subItem?.imageUrl && (
																						<img
																							src={
																								subItem?.imageUrl
																							}
																							width={
																								110
																							}
																							alt={
																								""
																							}
																						/>
																					)}
																				</div>
																				<div
																					style={{
																						color: "black",
																					}}
																				>
																					Answer
																				</div>
																				<FormControl
																					sx={{
																						paddingLeft:
																							"10px",
																					}}
																					key={
																						index
																					}
																				>
																					<RadioGroup
																						aria-labelledby="demo-controlled-radio-buttons-group"
																						name="controlled-radio-buttons-group"
																						sx={{
																							display:
																								"flex",
																							flexDirection:
																								"column",
																							rowGap: "1rem",
																						}}
																					>
																						{subItem.options.map(
																							(
																								subOption,
																								index
																							) => {
																								const findIndex =
																									subMCQ?.findIndex(
																										(
																											sub
																										) =>
																											sub?.subQuestionId ===
																											subItem?.id
																									);
																								return (
																									<FormControlLabel
																										value={
																											subOption.option
																										}
																										control={
																											<Radio
																												checked={
																													subMCQ[
																														findIndex
																													]
																														?.subAnswer ===
																													subOption.option
																												}
																												onChange={(
																													e
																												) =>
																													subMCQHandler(
																														e,
																														subItem
																													)
																												}
																											/>
																										}
																										label={
																											subOption.option
																										}
																										sx={{
																											border: "1px solid #827d7d",
																											borderRadius:
																												"4px",
																										}}
																										key={
																											index
																										}
																									/>
																								);
																							}
																						)}
																					</RadioGroup>
																				</FormControl>
																			</div>
																		);
																	}
															  )}
													</RadioGroup>
												</FormControl>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : subQuestion?.questionTypeCode === "MATCH" ? (
							<div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										rowGap: "5px",
									}}
								>
									<div
										style={{
											paddingLeft: "10px",
											fontSize: 17,
										}}
									>
										{`${currentQuestion + 1} .${
											subQuestion?.question
										}`}
									</div>
								</div>

								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "space-between",
										height: "100%",
										marginTop: "10px",
									}}
								>
									<div
										style={{
											width: "100%",
											display: "flex",
											flexDirection: "row",
											columnGap: "10px",
										}}
									>
										<div
											style={{
												width: "65%",
												display: "flex",
												flexDirection: "column",
												rowGap: "1rem",
												height: "100%",
												overflow: "auto",
											}}
										>
											{subQuestion?.options[0]?.map(
												(item, index) => {
													const find =
														selectedAnswerData?.find(
															(data) =>
																data.question ===
																item.question
														);
													return (
														<div
															style={{
																display: "flex",
																flexDirection:
																	"row",
																alignItems:
																	"center",
															}}
															key={item.question}
														>
															<div
																style={{
																	width: "100%",
																	padding:
																		"10px",
																	border: "1px solid black",
																	borderStyle:
																		"solid",
																	borderRadius:
																		"5px",
																	cursor: "pointer",
																}}
															>
																{item.question}
															</div>
															<div
																style={{
																	width: "20%",
																	border: "3px dashed #6f6f6f",
																	borderStyle:
																		"dashed",
																	borderBottom: 0,
																}}
															/>
															{find ? (
																<div
																	style={{
																		width: "100%",
																		borderStyle:
																			"dashed",
																		border: "3px dashed #6f6f6f",
																		padding:
																			"10px",
																		borderRadius:
																			"5px",
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		answerHandler(
																			item.question
																		)
																	}
																>
																	{
																		find?.answer
																	}
																</div>
															) : (
																<div
																	style={{
																		width: "100%",
																		borderStyle:
																			"dashed",
																		border: "3px dashed #6f6f6f",
																		padding:
																			"10px",
																		borderRadius:
																			"5px",
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		setAnswerSelectHandler(
																			item.question,
																			selectedAnswer
																		)
																	}
																>
																	Drag & Drop
																	Here
																</div>
															)}
														</div>
													);
												}
											)}
										</div>
										<div
											style={{
												width: "35%",
												display: "flex",
												flexDirection: "column",
												rowGap: "1rem",
												height: "100%",
												overflow: "auto",
											}}
										>
											{subQuestion?.options[0]?.map(
												(item) => {
													const find =
														selectedAnswerData.find(
															(data) =>
																data.jumbledAnswer ===
																item.jumbledAnswer
														);
													return (
														<div
															style={{
																width: "100%",
																padding: "12px",
																border: "1px solid black",
																borderStyle:
																	"solid",
																borderRadius:
																	"5px",
																cursor:
																	find?.jumbledAnswer ===
																	item?.jumbledAnswer
																		? "not-allowed"
																		: "pointer",
															}}
															key={
																item.jumbledAnswer
															}
															onClick={() =>
																find?.jumbledAnswer !==
																	item?.jumbledAnswer &&
																answerSelectHandler(
																	item?.jumbledAnswer
																)
															}
														>
															{item.jumbledAnswer}
														</div>
													);
												}
											)}
										</div>
									</div>
								</div>
							</div>
						) : (
							""
						)}
					</div>
				))}{" "}
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					width: "100%",
					flexDirection: "row",
					columnGap: "1rem",
				}}
			>
				<ExitButton
					onClick={() => {
						close();
						setCurrentQuestion(0);
					}}
				>
					Exit
				</ExitButton>
				<PreviewButton
					onClick={preQuestionHandler}
					disabled={currentQuestion + 1 === 1}
				>
					<KeyboardArrowLeftIcon style={{ fontSize: "25px" }} />
					Previous Question
				</PreviewButton>
				{currentQuestion + 1 === data?.quizQuestions?.length ? (
					<NextButton onClick={onSubmitClick}>Submit</NextButton>
				) : (
					<NextButton
						onClick={nextQuestionHandler}
						disabled={
							currentQuestion + 1 === data?.quizQuestions?.length
						}
					>
						Next Question
						<KeyboardArrowRightIcon style={{ fontSize: "25px" }} />
					</NextButton>
				)}
			</div>
		</Paper>
	);
}

export default CaseStudyPreviewModal;
const PreviewButton = styled(Button)(() => ({
	color: "#fff",
	width: 200,
	backgroundColor: "#8589be",
	borderRadius: 5,
	textTransform: "none",
	display: "flex",
	flexDirection: "row",
	columnGap: "10px",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#8589be",
		border: 0,
	},
}));
const NextButton = styled(Button)(() => ({
	color: "#fff",
	width: 200,
	backgroundColor: "#FFA700",
	borderRadius: 5,
	textTransform: "none",
	display: "flex",
	flexDirection: "row",
	columnGap: "10px",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#FFA700",
		border: 0,
	},
}));
const ExitButton = styled(Button)(() => ({
	color: "#fff",
	width: 80,
	backgroundColor: "rgba(5,175,116,0.89)",
	borderRadius: 5,
	textTransform: "none",
	"&:hover": {
		color: "#fff",
		backgroundColor: "rgba(5,175,116,0.89)",
		border: 0,
	},
}));
