import React from "react";
import { Navigate, Route } from "react-router-dom";

function ProtectedRoute({ children }) {
  
  return sessionStorage.getItem("token") !== null ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
}

export default ProtectedRoute;
