import { FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useTaxonomy } from '../../../../hooks/quiz/useTaxonomy'

const TaxonomyDropDown = ({taxonomySelected,setTaxonomySelected,questiontypeId}) => {
    const taxInfo = useTaxonomy()
  return (
   
            <Box sx={{ minWidth: 160, marginLeft: '16px' }}>
            <FormControl fullWidth size='small'>
              <InputLabel
                //    shrink={false}
                id='demo-select-small'
                style={{ fontSize: 14 }}
              >
                Taxonomy
              </InputLabel>
              <Select
                labelId='demo-select-small'
                id='demo-select-small'
                label='Board'
                value={taxonomySelected.taxonomyName}
                disabled={questiontypeId?.code === 'CASE' ? true : false}
                sx={{ fontSize: 12, color: 'red' }}
                onChange={setTaxonomySelected}
                style={{
                  background: '#fff',
                  borderRadius: 150,
                  height: 32,
                  fontSize: 10,
                  color: '#FD8C00'
                }}
              >
                {taxInfo.data?.length > 0 ? (
                  taxInfo.data?.map(taxonom => {
                    return (
                      <MenuItem
                        style={{ fontSize: 10 }}
                        key={taxonom.id}
                        value={taxonom}
                      >
                        <ListItemText
                          sx={{ fontSize: 10 }}
                          primary={taxonom.taxonomyName}
                        />
                      </MenuItem>
                    )
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box> 
  )
}

export default TaxonomyDropDown