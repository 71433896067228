import React from 'react'
import { nameHandler } from '../../../../utils/helper'
import { Paper } from '@mui/material'
import PsychologyAltOutlinedIcon from '@mui/icons-material/PsychologyAltOutlined'
// import PDFViewer from "pdf-viewer-reactjs";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin, ToolbarSlot, TransformToolbarSlot } from "@react-pdf-viewer/default-layout";


import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import ContentViewer from '../../../../components/common/ContentViewer';


const ImageView = ({ selectedMenu, selectedUrl, handleFullscreen }) => {

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default right-click behavior
  };
  console.log("selected file type >>> ", selectedMenu);
  return (
    <div
      style={{
        display: 'flex',
        // justifyContent: "center",
        background: 'white',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '20px'
        // width: "100%",
        // height: "550px",
      }}
    >
      {!selectedMenu.includes('pdf') ? (
        <>
          <div
            style={{
              fontWeight: 'bold',
              paddingBottom: '10px',
              fontSize: '13px'
            }}
          >
            {nameHandler(selectedMenu)}
          </div>
          <iframe
            title={nameHandler(selectedUrl)}
            src={selectedUrl}
            style={{ height: '600px', width: '100%' }}
          />
          <button onClick={handleFullscreen} className='FullScrnBtn hvr-bubble-top'>
            Fullscreen
          </button>
        </>
      ) : (
        <>
          <div
            style={{
              fontWeight: 'bold',
              paddingBottom: '10px',
              fontSize: '13px'
            }}
          >
            {nameHandler(selectedMenu)}
          </div>
          <ContentViewer contentstyle={{ height: "750px", width: "750px" }}
            url={selectedUrl} />

        </>
      )}
    </div>
  )
}

export default ImageView