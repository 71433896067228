import React from "react";
import styled from "styled-components";

function TabMenu({ selectedMenu }) {
	return (
		<Container>

			<Menu
				className={selectedMenu === "REJECTED" && "active"}
			>
				Rejected
			</Menu>
		</Container>
	);
}

export default TabMenu;
const Container = styled.div`
	margin-top: 16px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 5px;
	font-weight: 300;
`;
const Menu = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: #fff;
	height: 50px;
	font-size: 14px;
	cursor: default;
	&.active {
		color: #fd8c00;
		border-bottom: 2px solid #fd8c00;
	}
`;
