import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "../../Navbar";
import MainContainer from "../dashboard/MainContainer";
import LearningPlanContent from "./LearningPlanContent";

const CordinatorLearningPlan = () => {
	//added by sg
	const [headerTitle, setHeaderTitle] = useState("");
	const handleHeaderTitle = (value) => {
		setHeaderTitle(value);
	};
	return (
		<>
			<Navbar handleHeaderTitle={(value)=>handleHeaderTitle(value)}/>  
			<MainContainer
					children={<div></div>}
					// children={<DashboardContent />}
				headerTitle={headerTitle}
			/>
		</>
	);
};
export default CordinatorLearningPlan;
