import { v4 as uuidv4 } from "uuid";

export const DataStructureWithSubtopic = (item, subtopic, data, elem) => {
  let arr = {
    id: uuidv4(),
    grade: data.grade,
    gradeId: data.gradeId,
    section: item.section,
    sectionId: item.sectionId,
    subject: elem.subject,
    subjectId: elem.subjectId,
    averageMessage: subtopic.averageMessage,
    practiceAttemptRateMessage: subtopic.practiceAttemptRateMessage,
    unitAttemptRateMessage: subtopic.unitAttemptRateMessage,
    unitGlobalQuizAttemptRate: subtopic.unitGlobalQuizAttemptRate,
    unitQuizAttemptRate: subtopic.unitQuizAttemptRate,
    practiceGlobalQuizAttemptRate: subtopic.practiceGlobalQuizAttemptRate,
    practiceQuizAttemptRate: subtopic.practiceQuizAttemptRate,
    globalQuizAverageScorePercentage: subtopic.globalQuizAverageScorePercentage,
    quizAverageScorePercentage: subtopic.quizAverageScorePercentage,
    subtopic: subtopic.subtopic,
    subtopicId: subtopic.subtopicId,
    skilledSubject: elem?.skilledSubject
  };

  return arr;
};

export const DataStructureWithoutSubtopic = (item, data, elem) => {
  let arr = {
    id: uuidv4(),
    grade: data.grade,
    gradeId: data.gradeId,
    section: item.section,
    sectionId: item.sectionId,
    subject: elem.subject,
    subjectId: elem.subjectId,
    averageMessage: elem.averageMessage || "",
    practiceAttemptRateMessage: elem.practiceAttemptRateMessage || "",
    unitAttemptRateMessage: elem.unitAttemptRateMessage || "",
    unitGlobalQuizAttemptRate: elem.unitGlobalQuizAttemptRate || 0,
    unitQuizAttemptRate: elem.unitQuizAttemptRate || 0,
    practiceGlobalQuizAttemptRate: elem.practiceGlobalQuizAttemptRate || 0,
    practiceQuizAttemptRate: elem.practiceQuizAttemptRate || 0,
    globalQuizAverageScorePercentage:
      elem.globalQuizAverageScorePercentage || 0,
    quizAverageScorePercentage: elem.quizAverageScorePercentage || 0,
    subtopic: "",
    subtopicId: "",
    skilledSubject: elem?.skilledSubject
  };

  return arr;
};

export const DataStructureSubtopicWithoutSection = (data, elem, subtopic) => {
  let arr = {
    id: uuidv4(),
    grade: data.grade,
    gradeId: data.gradeId,
    section: "",
    sectionId: "",
    subject: elem.subject,
    subjectId: elem.subjectId,
    averageMessage: subtopic.averageMessage,
    practiceAttemptRateMessage: subtopic.practiceAttemptRateMessage,
    unitAttemptRateMessage: subtopic.unitAttemptRateMessage,
    unitGlobalQuizAttemptRate: subtopic.unitGlobalQuizAttemptRate,
    unitQuizAttemptRate: subtopic.unitQuizAttemptRate,
    practiceGlobalQuizAttemptRate: subtopic.practiceGlobalQuizAttemptRate,
    practiceQuizAttemptRate: subtopic.practiceQuizAttemptRate,
    globalQuizAverageScorePercentage: subtopic.globalQuizAverageScorePercentage,
    quizAverageScorePercentage: subtopic.quizAverageScorePercentage,
    subtopic: subtopic.subtopic,
    subtopicId: subtopic.subtopicId,
  };

  return arr;
};

export const DataStructureWithoutSubtopicWithoutSection = (data, elem) => {
  let arr = {
    id: uuidv4(),
    grade: data.grade,
    gradeId: data.gradeId,
    section: "",
    sectionId: "",
    subject: elem.subject,
    subjectId: elem.subjectId,
    averageMessage: elem.averageMessage || "",
    practiceAttemptRateMessage: elem.practiceAttemptRateMessage || "",
    unitAttemptRateMessage: elem.unitAttemptRateMessage || "",
    unitGlobalQuizAttemptRate: elem.unitGlobalQuizAttemptRate || 0,
    unitQuizAttemptRate: elem.unitQuizAttemptRate || 0,
    practiceGlobalQuizAttemptRate: elem.practiceGlobalQuizAttemptRate || 0,
    practiceQuizAttemptRate: elem.practiceQuizAttemptRate || 0,
    globalQuizAverageScorePercentage:
      elem.globalQuizAverageScorePercentage || 0,
    quizAverageScorePercentage: elem.quizAverageScorePercentage || 0,
    subtopic: "",
    subtopicId: "",
  };
  return arr;
};

export const getDataStructureFormatted = (cdata) => {
  let formatted = [];
  cdata?.grades?.forEach((data) => {
    if (data?.sections) {
      data?.sections?.forEach((item) => {
        item?.subjects?.forEach((elem) => {
          if (elem?.subtopics) {
            elem?.subtopics?.forEach((subtopic) => {
              let arr = DataStructureWithSubtopic(item, subtopic, data, elem);
              formatted.push(arr);
            });
          } else {
            let arr = DataStructureWithoutSubtopic(item, data, elem);
            formatted.push(arr);
          }
        });
      });
    } else {
      data?.subjects?.forEach((elem) => {
        if (elem?.subtopics) {
          elem?.subtopics?.forEach((subtopic) => {
            let arr = DataStructureSubtopicWithoutSection(data, elem, subtopic);
            formatted.push(arr);
          });
        } else {
          let arr = DataStructureWithoutSubtopicWithoutSection(data, elem);
          formatted.push(arr);
        }
      });
    }
  });
  return formatted;
};

export const updatedGetDataStructureFormattedTeacher = (cdata) => {
  let formatted = [];
  cdata?.forEach(obj => {
    obj?.grades?.forEach((data) => {
      if (data?.sections) {
        data?.sections?.forEach((item) => {
          item?.subjects.forEach((elem) => {
            if (elem?.subtopics) {
              elem?.subtopics?.forEach((subtopic) => {
                let arr = DataStructureWithSubtopic(item, subtopic, data, elem);
                formatted.push(arr);
              });
            } else {
              let arr = DataStructureWithoutSubtopic(item, data, elem);
              formatted.push(arr);
            }
          });
        });
      } else {
        data?.subjects?.forEach((elem) => {
          if (elem?.subtopics) {
            elem?.subtopics?.forEach((subtopic) => {
              let arr = DataStructureSubtopicWithoutSection(data, elem, subtopic);
              formatted.push(arr);
            });
          } else {
            let arr = DataStructureWithoutSubtopicWithoutSection(data, elem);
            formatted.push(arr);
          }
        });
      }
    })
  });
  return formatted;
};
