import React from "react";
import styled from "styled-components";

function TabMenu({ selectedMenu, setSelectedMenu, setCurrentPage }) {
	return (
		<Container>
			<Menu
				className={selectedMenu === "PENDING" && "active"}
				onClick={() => {
					setSelectedMenu("PENDING");
					setCurrentPage(0);
				}}
			>
				Pending
			</Menu>
			<Menu
				className={selectedMenu === "REJECTED" && "active"}
				onClick={() => {
					setSelectedMenu("REJECTED");
					setCurrentPage(0);
				}}
			>
				Rejected
			</Menu>
			<Menu
				className={selectedMenu === "APPROVED" && "active"}
				onClick={() => {
					setSelectedMenu("APPROVED");
					setCurrentPage(0);
				}}
			>
				Approved
			</Menu>
		</Container>
	);
}

export default TabMenu;
const Container = styled.div`
	margin-top: 16px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 5px;
	font-weight: 300;
`;
const Menu = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: #fff;
	height: 50px;
	font-size: 14px;
	cursor: pointer;
	&.active {
		color: #fd8c00;
		border-bottom: 2px solid #fd8c00;
	}
`;
