export default function TopRightpopup() {
    return (
      <>
        <div
          style={{
            display: "flex",
            padding: "15px",
            justifyContent: "center",
            width: "226px",
            borderRadius:'16px',
            backgroundColor: "green",
            color: "white"
          }}
        >
          Uploaded succesfully
        </div>
      </>
    );
  }
  