import axios from "axios";

async function Gender_Controller_Get(setGenderType) {
  let token = sessionStorage.getItem("token");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/misc/gender`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp)=>{
      setGenderType(resp.data.data)
    })
    .catch(err=>console.log(err))
}

export default Gender_Controller_Get;
