import axios from "axios";
import { isNOTNullOrUndefined } from "../../../../../utils/helper";
import { rightClickEnable, CDNBASEURL } from "../../../../../../src/Serviceworker"
import CommonSort from "../../../../js/CommonSort"

export const fetchTeacherData = async (userName, role) => {
	const token = sessionStorage.getItem("token");

	let result = {};
	await axios
		.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then((res) => {
			if (res.status === 200) {
				result = res?.data?.data?.teacher;
				sessionStorage.setItem("boardId", res.data.data.teacher.boardId);
				sessionStorage.setItem("schoolId", res.data.data.teacher.school);
				sessionStorage.setItem("branchId", res.data.data.teacher.branch);
				// var result = res?.data?.data?.teacher || {};
				sessionStorage.setItem("Basic_info", JSON.stringify(result))

			} else {
				result = {};
			}
		})
		.catch((err) => console.log(err, "errr"));
	return result;
};

export const fetchGradeSectionList = async (teacherIdd) => {
	const token = sessionStorage.getItem("token");
	const result = await axios.get(
		`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${teacherIdd}&menuName=ASSIGN`,
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	);
	return result?.data?.data || [];
};

export const fetchAcademicYearData = async () => {
	const token = sessionStorage.getItem("token");

	const result = await axios.get(
		`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	);
	return result?.data?.data || [];
};

export const fetchSubjectList = async (params) => {
	const token = sessionStorage.getItem("token");

	const teacherId = sessionStorage.getItem("teacherId");
	const boardId = sessionStorage.getItem("boardId");
	const schoolId = sessionStorage.getItem("schoolId");
	const branchId = sessionStorage.getItem("branchId");

	console.log(params, "teacherId");
	console.log("params?.gradeId >> ", params, sessionStorage.getItem("planId"));

	/// CDN CALL ///
	// const cdn_response = await fetch('https://cors-anywhere.herokuapp.com/${CDNBASEURL}/grades/' + params?.gradeId + '/subjects-' + params?.gradeId + '.json');
	// if (!cdn_response.ok) {
	// 	throw new Error('Network response was not ok');
	// }
	// const jsonData = await cdn_response.json();
	// const grade = jsonData.data.grades.find(grade => grade.gradeId === params?.gradeId);

	// // If the grade is found, extract its subjects
	// let subjects = [];
	// if (grade) {
	// 	subjects = grade.subjects;
	// }
	// console.log("res subjects >>> ", subjects);
	// return subjects || [];
	/////


	const response = await axios
		.get(
			`${process.env.REACT_APP_SWAGGER_URL
			}v1/api/user/teachers/subjects?teacherId=${params?.teacherId ? params.teacherId : teacherId
			}&gradeId=${params?.gradeId}&sectionId=${params?.sectionId}&menuName=${params?.menuName}`
			// `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan/access-based?planId=${params?.planId || sessionStorage.getItem("planId")}&gradeId=${params?.gradeId }`
			,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.catch((err) => console.log(err));

	return response?.data?.data || [];
};

export const fetchChapterListData = async (params) => {
	// alert("params1212121" + JSON.stringify(params))
	/// CDN CALL ///
	// const cdn_response = await fetch('https://cors-anywhere.herokuapp.com/${CDNBASEURL}/grades/' + params?.gradeId + '/subjects-' + params?.gradeId + '.json');
	// if (!cdn_response.ok) {
	// 	throw new Error('Network response was not ok');
	// }
	// const jsonData = await cdn_response.json();
	// const grade = jsonData.data.grades.find(grade => grade.gradeId === params?.gradeId);

	// // If the grade is found, extract its subjects
	// let subjects = [];
	// if (grade) {
	// 	subjects = grade.subjects;
	// }
	// const chapterList = subjects.find(subject => subject.subjectId === params?.subjectId);
	// console.log("res chapterList >>> ", chapterList?.chapters);
	// return chapterList?.chapters;

	const token = sessionStorage.getItem("token");
	console.log(params)
	const teacherId = sessionStorage.getItem("teacherId");
	const boardId = sessionStorage.getItem("boardId");
	const schoolId = sessionStorage.getItem("schoolId");
	const branchId = sessionStorage.getItem("branchId");
	let result = [];


	await axios
		.get(
			`${process.env.REACT_APP_SWAGGER_URL
			}v1/api/master/chapters/all-chapters?subjectId=${params?.subjectId
			}&gradeId=${params?.gradeId}&boardId=${boardId}${isNOTNullOrUndefined(params?.subTopicId)
				? `&subTopicId=${params?.subTopicId}`
				: ""
			}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then(async (response) => {
			if (response.status === 200) {
				result = response?.data?.data;
				// console.log(result)
				result = await CommonSort(result || [], "chapter", false);
				// console.log(result)
				// result = result.filter(obj => obj.startDate !== null && obj.startDate !== '')
			} else {
				result = [];
			}
		})
		.catch((err) =>
			console.log(err, "error on fetching chapter list on preselection")
		);
	return result;
};

export const fetchQuizList = async (params) => {
	const token = sessionStorage.getItem("token");
	console.log("***********************************")
	const teacherId = sessionStorage.getItem("teacherId");
	const boardId = sessionStorage.getItem("boardId");
	const schoolId = sessionStorage.getItem("schoolId");
	const branchId = sessionStorage.getItem("branchId");
	let result = [];

	await axios
		.get(
			`${process.env.REACT_APP_SWAGGER_URL
			}v1/api/content/quiz-release/list-to-teacher?reviewStatus=APPROVED&schoolId=${schoolId}&branchId=${branchId}&boardId=${boardId}&sectionId=${params?.sectionId
			}&gradeId=${params?.gradeId}&subjectId=${params?.subjectId
			}${`&chapterId=${params?.chapterId}`}&academicYearId=${params?.academicYearId
			}${isNOTNullOrUndefined(params?.subTopicId)
				? "&subTopicId=" + params?.subTopicId
				: ""
			}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then((response) => {
			if (response.status === 200) {
				result = response?.data?.data;
			} else {
				console.log(response.data.message)
				result = [];
			}
		})
		.catch((err) => {
			// console.log(err.message, "error fetching quiz list");
			if (err.response && err.response.data && err.response.data.message) {
				console.log(err.response.data.message);
			} else {
				console.log(err.message, "error fetching quiz list");
			}
		});
	return result;
};

export const fetchChapterDetails = async (params) => {
	const token = sessionStorage.getItem("token");

	const teacherId = sessionStorage.getItem("teacherId");
	const boardId = sessionStorage.getItem("boardId");
	const schoolId = sessionStorage.getItem("schoolId");
	const branchId = sessionStorage.getItem("branchId");
	let result;
	await axios
		.get(
			`${process.env.REACT_APP_SWAGGER_URL
			}v1/api/teacher/teach/chapter/min/details?gradeId=${params?.gradeId
			}&sectionId=${params?.sectionId
			}&schoolId=${schoolId}&branchId=${branchId}&subjectId=${params?.subjectId
			}&boardId=${boardId}&academicYearId=${params?.academicYearId}${isNOTNullOrUndefined(params?.subTopicId)
				? "&subTopicId=" + params?.subTopicId
				: ""
			}&chapterId=${params?.chapterId}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then((res) => {
			if (res.status === 200) {
				result = res?.data?.data?.endDate;
			} else {
				result = null;
			}
		});
	return result;
};

export const fetchQuizSectionsData = async (params) => {
	const token = sessionStorage.getItem("token");

	const teacherId = sessionStorage.getItem("teacherId");
	const boardId = sessionStorage.getItem("boardId");
	const schoolId = sessionStorage.getItem("schoolId");
	const branchId = sessionStorage.getItem("branchId");
	const subTopicId = sessionStorage.getItem("subTopicId");
	const response = await axios.get(
		`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz-release/list-of-section?quizId=${params?.quizid}&teacherId=${teacherId}&gradeId=${params?.gradeId}&sectionId=${params?.sectionId}&schoolId=${schoolId}&branchId=${branchId}&subjectId=${params?.subjectId}&boardId=${boardId}&academicYearId=${params?.academicYearId}
		${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ''} `,
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	);
	return response?.data?.data || [];
};

let planIdd

//-------| Plan Api |-------//
export const fetchPlanDetails = async () => {
	const branchId = sessionStorage.getItem("branchId")
	const token = sessionStorage.getItem("token");

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${branchId}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		sessionStorage.setItem("planId", res?.data?.data?.plans[0]?.planId)
		planIdd = res?.data?.data?.plans[0]?.planId
		return res.data || [];
	} catch (error) {
		console.error("Error fetching plan Data:", error);
		return [];
	}
};


//-------| Plan Template rm-lock/unlock Api |-------//
export const fetchRmlockUnlockData = async (selectedGrade) => {
	const planId = sessionStorage.getItem("planId");
	const token = sessionStorage.getItem("token");
	const gradee = sessionStorage.getItem("gradeId");

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/subjects-by-template-feature?planId=${planId !== null ? planId : planIdd}&gradeId=${selectedGrade ? selectedGrade : gradee}&columnName=RM_LOCK`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		// console.log(res.data.data)
		return res.data.data || [];
	} catch (error) {
		console.error("Error fetching RmLock/Unlock Data:", error);
		return [];
	}
};

//-------| Enabling and Disabling the "Assign Quiz" Button Api |-------//
export const fetchEnableAssignBtn = async (selectedGrade) => {
	const planId = sessionStorage.getItem("planId");
	const token = sessionStorage.getItem("token");

	try {
		const res = await axios.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/subjects-by-template-feature?planId=${planId}&gradeId=${selectedGrade}&columnName=TEACHER_REVISION_MODULE`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return res.data.data || [];
	} catch (error) {
		console.error("Error fetching Data:", error);
		return [];
	}
};