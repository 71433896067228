import axios from 'axios'

async function City_Controller_Put(CityInputs, setSuccess) {
  let token = sessionStorage.getItem("token");
  var item = {
    countryId: CityInputs.countryId,
    stateId: CityInputs.stateId,
    // districtId: ,
    pincodeId: CityInputs.pincodeId,
    city: CityInputs.city,
  };

    let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities/${sessionStorage.getItem("cityid")}`,item,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp)=>{
      setSuccess(resp)
    })
    .catch(err=>console.log(err)) 
}

export default City_Controller_Put;
