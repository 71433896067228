import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BreadCrumbs from "../General/BreadCrumbs";
import FilterBar from "./FilterBar";
import ChapterView from "./ChapterView.js";
import { isNOTNullOrUndefined } from "../../../../utils/helper";
import axios from "axios";

function ChapterListScreen() {
	const [grades, setGrades] = useState([]);
	const [subjects, setSubject] = useState([]);
	const [showSubTopic, setShowSubTopic] = useState(true);
	const [subTopics, setSubTopics] = useState([]);
	const [boards, setBoard] = useState([]);
	const [quizTypes, setQuizTypes] = useState([]);
	const [chapters, setChapters] = useState([]);
	const [changeCount, setChangeCount] = useState(1);
	const [totalItems, setTotalItems] = useState(0);
	const [selectedData, setSelectedData] = useState();
	const [isNextPage, setNextpage] = useState(true);
	const [pageNumber, setPageNumber] = useState(0);
	const [totalPage, setTotalPages] = useState(null);
	const [quizCount, setQuizCount] = useState(0);

	const [filterState, setFilterState] = useState({
		grade: {},
		board: {},
		subject: {},
		searchParams: "",
		quizType: {},
		subTopic: {},
	});
	console.log(filterState);
	const [selectedMenu, setSelectedMenu] = useState("PENDING");

	const handleChange = (e) => {
		setPageNumber(0);
		setChapters([]);
		setNextpage(true);
		setChangeCount((prev) => prev + 1);
		let currentDate = filterState;
		let value = e.target.value;
		let name = e.target.name;
		if (name === "grade") {
			currentDate[name] = grades.find((item) => item.id === value);
		} else if (name === "board") {
			currentDate[name] = boards.find((item) => item.id === value);
		} else if (name === "subject") {
			currentDate[name] = subjects.find((item) => item.id === value);
			setShowSubTopic(currentDate[name].hideSubtopics == true ? false : true);
			setSubTopics(
				currentDate[name]?.subTopics && currentDate[name]?.subTopics
			);
			currentDate["subTopic"] = {};
		} else if (name === "quizType") {
			currentDate[name] = quizTypes.find((item) => item.id === value);
		} else if (name === "subTopic") {
			// value &&
			currentDate[name] = subTopics?.find((item) => item.id === value);
		} else {
			currentDate[name] = value;
		}
	};
	const getChapterData = () => {
		const token = sessionStorage.getItem("token");
		axios
			.get(
				`${process.env.REACT_APP_SWAGGER_URL
				}v1/api/master/chapters/revision-reviewer/dashboard?pageNumber=${pageNumber}&pageSize=100
				&sortOrder=true&sortBy=id&active=true
				${isNOTNullOrUndefined(filterState.searchParams)
					? "&search=" + filterState.searchParams
					: ""
				}${isNOTNullOrUndefined(filterState.board?.id)
					? "&boardId=" + filterState.board?.id
					: ""
				}${isNOTNullOrUndefined(filterState.grade?.id)
					? "&gradeId=" + filterState.grade?.id
					: ""
				}${isNOTNullOrUndefined(filterState.subject?.id)
					? "&subjectId=" + filterState.subject?.id
					: ""
				}${isNOTNullOrUndefined(filterState.subTopic?.id)
					? "&subTopicIds=" + filterState.subTopic?.id
					: ""
				}${isNOTNullOrUndefined(filterState.quizType?.id)
					? "&quizTypeId=" + filterState.quizType?.id
					: ""
				}
				
				`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				if (res.data.success) {
					const data = res.data.data.data;
					setChapters([...chapters, ...data]);
					setTotalItems(res.data.data.totalElements);
					setTotalPages(res.data.data.totalPages);
					// setTotalPages(res.data.data.totalPages);
				} else {
					alert("Something went wrong");
				}
			})
			.catch((err) => {
				console.log(err);
				// alert(err);
			});
	};
	const getQuizCount = () => {
		const token = sessionStorage.getItem("token");
		if (filterState.board?.id && filterState.grade?.id) {
			axios
				.get(
					`${process.env.REACT_APP_SWAGGER_URL
					}v1/api/master/chapters/revision-reviewer/quizzes-count?
				${isNOTNullOrUndefined(filterState.searchParams)
						? "&search=" + filterState.searchParams
						: ""
					}${isNOTNullOrUndefined(filterState.board?.id)
						? "&boardId=" + filterState.board?.id
						: ""
					}${isNOTNullOrUndefined(filterState.grade?.id)
						? "&gradeId=" + filterState.grade?.id
						: ""
					}${isNOTNullOrUndefined(filterState.subject?.id)
						? "&subjectId=" + filterState.subject?.id
						: ""
					}${isNOTNullOrUndefined(filterState.subTopic?.id)
						? "&subTopicIds=" + filterState.subTopic?.id
						: ""
					}${isNOTNullOrUndefined(filterState.quizType?.id)
						? "&quizTypeId=" + filterState.quizType?.id
						: ""
					}
				
				`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then((res) => {
					if (res.data.success) {
						setQuizCount(res.data.data);
					} else {
						// alert("Something went wrong");
					}
				})
				.catch((err) => {
					console.log(err);
					alert(err);
				});
		}
	};
	useEffect(() => {
		getQuizCount();
		getChapterData();
	}, [changeCount, pageNumber]);

	function loadMore() {
		if (totalPage - 1 > pageNumber) {
			setPageNumber((prev) => prev + 1);
		} else {
			setNextpage(false);
		}
	}
	return (
		<Container>
			<BreadCrumbs currentPage={"Dashboard"} />
			<FilterBar
				selectedMenu={selectedMenu}
				filterState={filterState}
				setFilterState={setFilterState}
				setGrades={setGrades}
				grades={grades}
				setSubject={setSubject}
				subjects={subjects}
				boards={boards}
				setBoard={setBoard}
				quizTypes={quizTypes}
				setQuizTypes={setQuizTypes}
				handleChange={handleChange}
				totalItems={quizCount}
				subTopics={subTopics}
				showSubTopic={showSubTopic}
			/>
			<ChapterView
				chapters={chapters}
				loadMore={loadMore}
				isNextPage={isNextPage}
				quizType={
					filterState?.quizType?.quizType
						? filterState?.quizType?.quizType
						: null
				}
			/>
		</Container>
	);
}

export default ChapterListScreen;
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
