import axios from "axios";

const fetch_Chapter_Quiz_Key_Api = async () => {
  const token = sessionStorage.getItem("token");
  const schoolId = sessionStorage.getItem("schoolId");
  const branchId = sessionStorage.getItem("branchId");
  const gradeId = sessionStorage.getItem("gradeId");
  const sectionId = sessionStorage.getItem("sectionId");
  const selectedSubject = sessionStorage.getItem("selectedSub") || sessionStorage.getItem("selectedSubject");
  const studentId = sessionStorage.getItem("studentId");
  const exIdOne = sessionStorage.getItem("exIdOne");
  const exIdTwo = sessionStorage.getItem("exIdTwo");
  const examID = JSON.parse(sessionStorage.getItem("examId"));
  console.log(exIdTwo ? exIdTwo : exIdOne, examID)
  // const examId="4028928889b274a20189bfd921ac00bf"
  const examId = exIdTwo ? exIdTwo : exIdOne;
  // alert("examId" + examId)
  // console.log(token, schoolId, branchId, gradeId, sectionId, selectedSubject, studentId, exIdOne, exIdTwo, examId)
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/chapter-quiz-key?examId=${examId || examID}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${selectedSubject}&studentId=${studentId}&reportPage=false`

  try {
    const res = await axios.get(
      url,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("res", res)
    return res?.data || [];
  } catch (error) {
    // console.log("error", error)
    return []
  }
};

const fetch_Chapter_Quiz_Key_Api_Report = async ({ subjectId, chapterId, formal_assessment, quizType, quizId }) => {
  const token = sessionStorage.getItem("token");
  const schoolId = sessionStorage.getItem("schoolId");
  const branchId = sessionStorage.getItem("branchId");
  const gradeId = sessionStorage.getItem("gradeId");
  const studentId = sessionStorage.getItem("studentId");

  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/chapter-quiz-key?schoolId=${schoolId}&branchId=${branchId}&gradeId=${gradeId}&subjectId=${subjectId}&studentId=${studentId}&chapterId=${chapterId}&reportPage=true`

  if (formal_assessment) {
    url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/chapter-quiz-key?schoolId=${schoolId}&branchId=${branchId}&gradeId=${gradeId}&subjectId=${subjectId}&studentId=${studentId}&quizTypeId=${quizType}&quizId=${quizId}&reportPage=true`
  }

  const res = await axios.get(
    url,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res?.data || [];
};

export { fetch_Chapter_Quiz_Key_Api, fetch_Chapter_Quiz_Key_Api_Report };
