import axios from 'axios'

function student_content_post(studentInputs,cb){
    let token = sessionStorage.getItem("token");

    var item = {
        "active": true,
        "chapterId":sessionStorage.getItem("chapterId"),
        "contentUrl": studentInputs.contentUrl,
        "documentCategoryId": studentInputs.documentCategoryId,
        "reason": "Reject reason: incorrect data",
        "reviewStatus": "PENDING",
        "contentType": studentInputs.contentType,
      }

    let result = axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/student-chapter` , item , 
    {
        headers: {
            "Content-Type" : "application/json" ,
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=> {
        // console.log(res.data)
        cb()
    })
    .catch(err => console.log(err))
}

export default student_content_post;