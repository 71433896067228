import React, { useEffect, useState } from "react";
import mobileotp from "./../css/mobileOTP.module.css";

const MobileOTP = () => {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  // const [otpValue, setOtpValue] = useState({
  //   otpValue1: "",
  //   otpValue2: "",
  //   otpValue3: "",
  //   otpValue4: "",
  // });
  const [otpTimeout, setOtpTimeout] = useState("sending otp");
  const [error, setError] = useState(false);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  function countdown(minutes, seconds) {
    // set time for the particular countdown
    let time = minutes * 60 + seconds;
    let interval = setInterval(function() {
      // if the time is 0 then end the counter
      if (time == 0) {
        setTimeout(function() {
          setOtpTimeout("Resending otp");
        }, 10);
        clearInterval(interval);
        setTimeout(function() {
          countdown(0, 30);
        }, 2000);
      }
      let minutes = Math.floor(time / 60);
      if (minutes < 10) minutes = "0" + minutes;
      let seconds = time % 60;
      if (seconds < 10) seconds = "0" + seconds;
      let text = minutes + ":" + seconds;
      setOtpTimeout(text);
      time--;
    }, 1000);
  }

  const submitOtp = (e) => {
    e.preventDefault();
    if (!otp[0] || !otp[1] || !otp[2] || !otp[3]) {
      setError(true);
    } else {
      setError(false);
      window.location = "/setPass";
    }
  };

  useEffect(() => {
    countdown(0, 80);
  }, []);

  return (
    <>
      <body>
        <div class={mobileotp.main_container}>
          <div class={mobileotp.main}>
            <div class={mobileotp.navbar}>
              <div class={mobileotp.icon}>
                <h2 class={mobileotp.logo}>
                  <img src="./img/Azvasa_logo.png" />
                </h2>
              </div>
            </div>
            <div class={mobileotp.content}>
              <img src="./img/ForgotPassword.png" class={mobileotp.login_img} />
              <form action="POST" class={mobileotp.mobileOTP_login_form}>
                <div class={mobileotp.login_form_title}>
                  <h2>Enter your OTP</h2>
                  <h3>OTP will send to your registered mobile number</h3>
                </div>
                <div class={mobileotp.digit_group}>
                  {otp.map((data, index) => {
                    return (
                      <input
                        type="password"
                        id="digit-1"
                        name="otp"
                        // data-next="digit-2"
                        maxlength="1"
                        key={index}
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        onFocus={(e) => e.target.select()}
                      />
                    );
                  })}
                  {/* <input
                    type="password"
                    id="digit-2"
                    name="digit-2"
                    data-next="digit-3"
                    data-previous="digit-1"
                    maxlength="1"
                    value={otpValue.otpValue2}
                    onChange={(e) =>
                      setOtpValue({ ...otpValue, otpValue2: e.target.value })
                    }
                  />
                  <input
                    type="password"
                    id="digit-3"
                    name="digit-3"
                    data-next="digit-4"
                    data-previous="digit-2"
                    maxlength="1"
                    value={otpValue.otpValue3}
                    onChange={(e) =>
                      setOtpValue({ ...otpValue, otpValue3: e.target.value })
                    }
                  />
                  <input
                    type="password"
                    id="digit-4"
                    name="digit-4"
                    data-previous="digit-3"
                    maxlength="1"
                    value={otpValue.otpValue4}
                    onChange={(e) =>
                      setOtpValue({ ...otpValue, otpValue4: e.target.value })
                    }
                  /> */}
                </div>
                <div class={mobileotp.resend_otp}>
                  <span>{otpTimeout}</span>
                  {/* <a
                    
                    onClick={() => {
                      // clearInterval(interval);
                      countdown(0, 30);
                    }}
                  >
                    Resend otp
                  </a> */}
                  <span
                    style={
                      error
                        ? { color: "red", display: "inline" }
                        : { display: "none" }
                    }
                  >
                    Please enter a valid otp
                  </span>
                </div>
                <div class={mobileotp.login_container}>
                  <button class={mobileotp.mo_login_btn} onClick={submitOtp}>
                    <a href="setPass">Submit</a>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default MobileOTP;
