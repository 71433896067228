import React, { useRef, useState, useEffect } from 'react';
import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { handleRightClick } from '../../utils/AppUtility';
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { pdfjs } from 'react-pdf';
import findLinksPlugin from '../../../src/findLinksPlugin';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ContentViewer = ({ contentstyle, url }) => {
    const viewerRef = useRef(null); // Ref to the Viewer component

    const findLinksPluginInstance = findLinksPlugin();
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar } = toolbarPluginInstance;

    const transform = (slot) => ({
        ...slot,
        Download: () => <></>,
        Open: () => <></>,
        Print: () => <></>,
    });

    const renderToolbar = (Toolbar) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [
            defaultTabs[0], // Thumbnails tab      
        ],
        renderToolbar,
    });

    return (
        url && (
            url.includes(".pdf") ? (
                <div onContextMenu={handleRightClick} style={{ ...contentstyle, userSelect: 'none' }}>
                    <Viewer
                        fileUrl={url}
                        plugins={[defaultLayoutPluginInstance,findLinksPluginInstance]}
                        defaultScale={SpecialZoomLevel.PageWidth}
                    />
                </div>
            ) : (
                <iframe
                    title="external-content"
                    src={url}
                    style={contentstyle}
                ></iframe>
            )
        )
    );
};

export default ContentViewer;
