import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { green } from "@mui/material/colors";
import "./Loader.css";
const GreenAddButton = (props) => {
  const AddButton = styled(Button)(() => ({
    color: "#fff",
    backgroundColor: green[500],
    borderRadius: 150,
    borderColor: green[500],
    // fontSize : '1vw',
    fontSize : '13px',
    opacity: 1,
    fontWeight:"normal",
    fontFamily:"Poppins, Medium",
    textTransform: "capitalize",
    // padding: "8px 21px",
    padding: "4px 16px",
    "&:hover": {
      color: "#fff",
      backgroundColor: green[500],
      border: 0,
      top: -2,
      boxShadow: "0px 3px 5px #78be20",
    },
  }));
  return <AddButton onClick={props.onClick}>{props.children}</AddButton>;
};
export default GreenAddButton;
