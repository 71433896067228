import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  NativeSelect,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';

function CreateQuizModal({ open, onClose, onSubmitPress }) {
  console.log(open, onClose, onSubmitPress)
  const local_data = JSON.parse(localStorage.getItem('quiz_data'));

  const { handleSubmit, control } = useForm();

  const [quizData, setQuizData] = useState({
    quiz_name: '',
    total_score: 0,
    duration: 0,
    academic_year: '2022-2023',
  });
  const [isError, setError] = useState(false);

  const handleOverlayClick = (e) => {
    // if (e.target === e.currentTarget) {
    // 	onClose();
    // }
  };
  const handleSubmittion = () => {
    setError(false);
    if (
      quizData.quiz_name &&
      quizData.total_score > 0 &&
      quizData.duration > 0
    ) {
      console.log('====================================');
      console.log(quizData);
      console.log('====================================');
    } else {
      setError(true);
      console.log('====================================');
      console.log('error', isError);
      console.log('====================================');
    }
  };

  const handleChange = (e) => {
    // console.log("====================================");
    // console.log(e);
    // console.log("====================================");
    if (e.target.id === 'name') {
      setQuizData({ ...quizData, quiz_name: e.target.value });
    } else if (e.target.id === 'total_mark') {
      setQuizData({ ...quizData, total_score: e.target.value });
    } else if (e.target.id === 'duration') {
      setQuizData({ ...quizData, duration: e.target.value });
    } else if (e.target.id === 'year') {
    } else return;
  };
  const handleChangeYear = (e) => {
    setQuizData({ ...quizData, academic_year: e.target.value });
    console.log('====================================');
    console.log(quizData);
    console.log('====================================');
  };

  const onSubmit = (data) => {
    localStorage.setItem(
      'quiz_data',
      JSON.stringify({
        ...local_data,
        quiz_name: data.name,
        duration: data.duration,
        score: data.total_mark,
      })
    );
    onSubmitPress();
  };


  
	return (
		<ModalOverlay isOpen={open} onClick={handleOverlayClick}>
			<ModalWrapper>
				<TopSection>
					<Breadcrumbs style={{ color: "#FFA700",textTransform:'capitalize' }}>
						{local_data?.quiz_type} - {local_data?.selected_board?.board}\
						{local_data?.selected_grade?.grade} \
						{local_data?.seleted_subject?.subject}
					</Breadcrumbs>
					<Close onClick={onClose}>
						<CloseIcon />
					</Close>
				</TopSection>
				<form onSubmit={handleSubmit(onSubmit)}>
					<InputSection>
						<Controller
							name="name"
							control={control}
							defaultValue=""
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<TextField
									error={error}
									helperText={error && error.message}
									id="name"
									label="Quiz name"
									variant="outlined"
									value={value}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={onChange}
								/>
							)}
							rules={{ required: "*Quiz name required" }}
						/>
						<Controller
							name="total_mark"
							control={control}
							defaultValue=""
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<TextField
									error={error}
									helperText={error && error.message}
									id="total_mark"
									label="Total Marks"
									type="number"
									variant="outlined"
									onChange={onChange}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							)}
							rules={{
								required: "*Total marks required",
								min: 0,
								validate: (value) =>
									parseInt(value, 10) >= 1 ||
									"Number should be greater than  0",
							}}
						/>{" "}
						<Controller
							name="duration"
							control={control}
							defaultValue=""
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<TextField
									id="duration"
									label="Duration"
									type="number"
									error={error}
									helperText={error && error.message}
									InputProps={{
										endAdornment: (
											<InputAdornment position="start">
												Minutes
											</InputAdornment>
										),
									}}
									onChange={onChange}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							)}
							rules={{
								required: "*Total duration required",
								min: 0,
								validate: (value) =>
									parseInt(value, 10) >= 1 ||
									"Number should be greater than  0",
							}}
						/>
						<Controller
							name="academic_year"
							control={control}
							defaultValue="2019-2020"
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<FormControl>
									<InputLabel
										id="select-academic-year"
										htmlFor="uncontrolled-native"
									>
									{	local_data?.selected_year
												?.academicYear}
									</InputLabel>
									<Select
										labelId="demo-simple-select-label"
                    disabled
										id="academic_year"
										value={
											local_data?.selected_year
												?.academicYear
										}
										label="Academic Year *"
										onChange={onChange}
										inputProps={{
											name: "academic_year",
											id: "uncontrolled-native year",
										}}
									>
										<MenuItem
											value={
												local_data?.selected_year
													?.academicYear
											}
										>
											{
												local_data?.selected_year
													?.academicYear
											}
										</MenuItem>
										{/* <MenuItem value={"2021-2022"}>
											2021-2022
										</MenuItem>
										<MenuItem value={"2022-2023"}>
											2022-2023
										</MenuItem> */}
                  </Select>
                </FormControl>
              )}
            />
          </InputSection>
          <ButtonSection>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <SubmitButton type='submit' value='Submit' />
          </ButtonSection>
        </form>
      </ModalWrapper>
    </ModalOverlay>
  );
}

export default CreateQuizModal;
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  z-index: 99;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 70%;
  max-width: 600px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #bebebe;
`;
const Breadcrumbs = styled.p`
  color: '#ffa700' !important;
  font-size: 16px;
`;
const Close = styled.span`
  cursor: pointer;
  display: block;
  width: 30px;
  color: #848888;
`;
const InputSection = styled.div`
  padding: 30px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  grid-row-gap: 35px;
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 3px !important;
  }
  .css-1pnmrwp-MuiTypography-root {
    font-size: 14px;
  }
  .css-1wc848c-MuiFormHelperText-root.Mui-error {
    position: absolute;
    left: 0;
    bottom: -25px;
    display: block;
    font-size: 13px;
  }
`;
const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;
const CancelButton = styled.span`
  color: #848888;
  width: 100px;
  height: 40px;
  border-radius: 25px;
  border: 1px solid #848888;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px;
  :hover {
    opacity: 0.8;
  }
`;
const SubmitButton = styled.input`
  color: #fff;
  width: 100px;
  height: 40px;
  border-radius: 25px;
  border: 1px solid #001af3;
  background-color: #001af3;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 4px #001af380;
  font-size: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
