import axios from 'axios'

 function conceptTree_count_get(setConceptTreeCount){
    let token = sessionStorage.getItem("token");

    let result =  axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/dash-board/concepts-count` ,
    {
        headers : {
            "Content-Type" : 'application/json',
            Authorization : `Bearer${token}`
        },
    })
    .then((res)=>{
        setConceptTreeCount(res.data.data)
    })
    .catch(err => console.log(err))
}

export default conceptTree_count_get;