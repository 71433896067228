import React, { useEffect, useState } from "react";
import SemiCircleProgressBar from "../../../../components/CircleProgress/SemiCircleProgress";
import SemiCircleProgressBar2 from "./Components/CircleProgressTeacher";
import "./teacher_dashboard.css";

import {
  extractTextAfterExclamation,
  extractTextIncludingExclamation,
  getMessageColorCodeV3,
} from "../../../../utils/helper";
import ApexSemiCircleProgress from "./Components/ApexSemiCircleProgress";

const TeacherardV2 = ({
  color,
  heading,
  boxClass,
  progressValue,
  message,
  title,
}) => {
  return (
    <div>
      <label className="card-heading">{heading}</label>
      {message !== "" && (
        <>
          <div className="progress-wrapper">
            <div className="circle-wrapper">
              <ApexSemiCircleProgress
                bgcolor={color}
                progressValue={progressValue}
              />
              <div className="text-style">
                <div className={boxClass}></div>
                Global %
              </div>
            </div>
            <div>
              <div>
                <p
                  style={{ color: getMessageColorCodeV3(title) }}
                  className="card-sub-head"
                >
                  {title}
                </p>
              </div>
              <div>
                <p className="card-text">{message}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TeacherardV2;
