import React, { useEffect, useState } from 'react'
import dashboardContentStyles from '../../css/dashboardContent.module.css'
// import GetLastModifiedAt from '../../API/teacher-controller/GetLastModifiedAt'
import axios from 'axios'
import dashHome from '../../../../src/img/dashHome.svg'
import { Link, useNavigate, useLocation } from "react-router-dom";

const CommonHeadPortion = ({ goBackToListView }) => {
  const [modifiedDate, setModifiedDate] = useState({})
  const navigate = useNavigate();

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate)
    return () => { }
  }, [])

  async function GetLastModifiedAt(ModifiedDate) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json) => {
        ModifiedDate(json.data)
      })
      .catch(err => console.log(err))
  }

  return (
    <article
      style={{ marginBottom: "60px" }}
    >
      <div className={dashboardContentStyles.dashboard_link}>
        <span
          className={dashboardContentStyles.link_icon}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
            sessionStorage.removeItem("sideNavSubMenuNames");
            navigate("/dashboard/template");
          }}
        >
          <img
            src={dashHome}
            alt="no img"
            // width="15px"
            // height="20px"
            // style={{ verticalAlign: "middle" }}
            className={dashboardContentStyles.az_menu_icon}
          />{" "}
        </span>
        {/* {"  "}
        <span className={dashboardContentStyles.link_text}>Home</span>
        {"  "} */}
        <span
          className={dashboardContentStyles.az_menu_text}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
            sessionStorage.removeItem("sideNavSubMenuNames");
            navigate("/dashboard/template");
          }}
        >
          Home
        </span>
        <span>
          <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
        </span>
        <span>
          <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
        </span>
        {/* {"  "}

        {"  "} */}
        <span className={dashboardContentStyles.az_menu_text}
          style={{ cursor: 'default' }}>
          Master
        </span>
        <span>
          <i className='fa-solid fa-angle-right' style={{ color: "#828282" }}></i>
        </span>
        <span>
          <i className='fa-solid fa-angle-right' style={{ color: "#828282" }}></i>
        </span>
        {/* {'  '} */}
        {/* <a style={{ cursor: "pointer" }} onClick={goBackToListView}>Plan Template</a> */}
        <span
          className={`${dashboardContentStyles.az_selected_menu_text} cursorDefault`}
          onClick={goBackToListView}
        >
          Plan Template
        </span>
      </div>
      <div className={dashboardContentStyles.dashboard_last_updated}>
        <p>
          Last Update: {modifiedDate.data}
        </p>
      </div>
    </article>


  )
}

export default CommonHeadPortion
