import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteModal from "./DeleteModal";
import Switch from "../../components/common/Switch";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import LockIconButton from "../../components/common/LockIconButton";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import ShareIconButton from "../../components/common/ShareIconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import axios from "axios";
import GreenAddButton from "../../components/common/GreenAddButton";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import CommonDeleteModal from "./CommonDeleteModal";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";

const CreateAdminUsers = () => {
  const [adminUsers, setAdminUsers] = useState([]);
  const token = sessionStorage.getItem("token");
  const [isEdit, setIsEdit] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const [updatedData, setUpdatedData] = useState();
  const [selectedItem, setSelectedItem] = useState("");
  const [userlist, setUserlist] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addAdminVisible, setAddAdminVisible] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [mail, setMail] = useState("");
  const [num, setNum] = useState("");
  const [userinputs, setUserinputs] = useState("");
  const [isAddUser, setIsAddUser] = useState(true);

  useEffect(() => {
    initData();
  }, []);

  const navigate = useNavigate();
  const prevLocation = useLocation();

  if (prevLocation.state === "edit") {
    setIsAddUser(false);
    setIsEdit(true);
    prevLocation.state = null;
  }

  const onSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      result = updatedData?.filter((data) => {
        return data?.userName?.toLowerCase().includes(value);
      });
    }
    setFilterValue(result);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const showDeleteModal = (row) => {
    setSelectedItem(row);
    setOpenDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };

  useEffect(() => {
    setFilterValue(adminUsers);
  }, [adminUsers]);

  const getAllUser = (setUserlist) => {
    let token = sessionStorage.getItem("token");

    let result = axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/roles/admin-roles`,
        // let result = axios.get(`http://3.111.249.182:9004v1/api/master/roles/admin-roles` ,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUserlist(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const addAdmin = () => {
    let token = sessionStorage.getItem("token");

    var item = {
      // "id": "ff80818180f65df90180f67e409a0172",
      firstName: fname,
      lastName: lname,
      email: mail.toLowerCase(),
      phoneNumber: num,
      userRoles: [
        {
          roleId: userRole,
          active: true,
        },
      ],
      lmsEnv: process.env.REACT_APP_LMS_ENV,
    };

    let result = axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log("success",res)
      })
      .catch((err) => console.log(err));

    setAddAdminVisible(false);
  };

  const deleteHandler = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.filter((data) => data.id !== id);
          setFilterValue(find);
        }
      })
      .catch(function (response) { });
    hideDeleteModal();
    navigate("/dashboard/admin%20users");
  };

  const initData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/admin-users/all?pageSize=500&sortBy=id`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (data.data.data.length > 0) {
      setAdminUsers(data.data.data);
    } else {
      setAdminUsers([]);
    }
  };

  // function get_userBy_id() {}

  useEffect(() => {
    // get_userBy_id()
    let token = sessionStorage.getItem("token");

    let result = axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/admin-users/${sessionStorage.getItem("userId")}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUserinputs(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [sessionStorage.getItem("userId")]);

  useEffect(() => {
    setFilterValue(updatedData);
  }, [updatedData]);

  useEffect(() => {
    getAllUser(setUserlist);
    setFilterValue(adminUsers);
  }, []);

  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "}

          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a>Admin User</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          {/* <p>Last Update: {modifiedDate.data}</p> */}
        </div>
      </article>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            marginTop: "20px",
            gap: "16px",
          }}
        >
          {addAdminVisible && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#606060",
                  columnGap: "10px",
                }}
              >
                <ManageAccountsOutlinedIcon style={{ fontSize: "30px" }} />
                <div style={{ fontWeight: 600 }}>
                  {isEdit ? "Edit Admin Role" : "Add Admin Role"}
                </div>
              </div>
              <TableContainer component={Paper}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    padding: "20px 20px",
                  }}
                >
                  <div>
                    <Box style={{ margin: "auto 8px", width: "260px" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Admin Role
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Admin role"
                          value={userRole}
                          onChange={(e) => setUserRole(e.target.value)}
                          style={{ background: "#fff", borderRadius: 150 }}
                        //   MenuProps={MenuProps}
                        >
                          <MenuItem value="">All</MenuItem>
                          {userlist.length > 0 ? (
                            userlist?.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                <ListItemText primary={item.role} />
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>Select</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      width: "90%",
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      id="demo-helper-text-aligned-no-helper"
                      label="First Name"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                    />
                    <TextField
                      id="demo-helper-text-aligned-no-helper"
                      label="Last Name"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                    />
                    <TextField
                      id="demo-helper-text-aligned-no-helper"
                      label="Primary Email"
                      value={mail}
                      onChange={(e) => setMail(e.target.value)}
                    />
                    <TextField
                      id="demo-helper-text-aligned-no-helper"
                      label="Primary Phone no"
                      value={num}
                      onChange={(e) => setNum(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "auto",
                      columnGap: "1rem",
                    }}
                  >
                    <HoverGreyCancelButton
                      variant="outlined"
                      onClick={() => {
                        // setAddAdminVisible(false);
                        // setIsEdit(false);
                        navigate("/dashboard/admin%20users");
                      }}
                    >
                      Cancel
                    </HoverGreyCancelButton>
                    {isEdit ? (
                      <BlueButton>Update</BlueButton>
                    ) : (
                      <BlueButton
                        onClick={(e) => {
                          addAdmin();
                          setTimeout(() => {
                            navigate("/dashboard/admin%20users");
                            // Do some stuff here
                          }, 1000);

                          // setAddAdminVisible(false);
                        }}
                      // disabled={roleDetail.hasRoleError}
                      >
                        Create
                      </BlueButton>
                    )}
                  </div>
                </div>
              </TableContainer>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "10px",
              }}
            >
              <ManageAccountsOutlinedIcon style={{ fontSize: "30px" }} />
              <span>Admin User List</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <TextField
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                    opacity: 1,
                  },
                }}
                type={"text"}
                placeholder={"Search...."}
                onChange={onSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              />

              <GreenAddButton
                onClick={() => {
                  setAddAdminVisible(true);
                  // navigate('/dashboard/CreateAdminUser')
                }}
              >
                <AddCircleOutlineOutlinedIcon style={{ marginRight: "10px" }} />
                Add Admin Role
              </GreenAddButton>
            </div>
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 500, overflow: "auto" }}>
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        color: "blue",
                        fontWeight: 600,
                        background: "#d9dcfe",
                      }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "blue",
                        fontWeight: 600,
                        background: "#d9dcfe",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "blue",
                        fontWeight: 600,
                        background: "#d9dcfe",
                      }}
                    >
                      Role
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "blue",
                        fontWeight: 600,
                        background: "#d9dcfe",
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "blue",
                        fontWeight: 600,
                        background: "#d9dcfe",
                      }}
                    >
                      Mobile
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: "blue",
                        fontWeight: 600,
                        background: "#d9dcfe",
                      }}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: "blue",
                        fontWeight: 600,
                        paddingRight: "60px",
                        background: "#d9dcfe",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterValue
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ fontSize: "15px" }}
                        >
                          {page * rowsPerPage + (index + 1)}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: "15px" }}>
                          {row?.firstName + row?.lastName}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: "15px" }}>
                          Role
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: "15px" }}>
                          {row?.email}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: "15px" }}>
                          {row?.phoneNumber}
                        </TableCell>
                        <TableCell align="right">
                          <div>
                            <Switch
                              checked={row?.active}
                            // onChange={(e) => switchHandler(row?.id, e)}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <EditIconButton
                            onClick={(e) => {
                              e.preventDefault();
                              var userId = row.id;
                              // navigate('/dashboard/CreateAdminuser', { state: "edit" })
                              sessionStorage.setItem("userId", userId);
                            }}
                          >
                            <Tooltip title="Edit" placement={"top"}>
                              <EditOutlinedIcon style={{ fontSize: "25px" }} />
                            </Tooltip>
                          </EditIconButton>
                          <DeleteIconButton
                            onClick={() => {
                              showDeleteModal(row);
                            }}
                          >
                            <Tooltip title="Remove" placement={"top"}>
                              <DeleteOutlinedIcon
                                style={{ fontSize: "25px" }}
                              />
                            </Tooltip>
                          </DeleteIconButton>
                          <LockIconButton>
                            <Tooltip title="Change Password" placement={"top"}>
                              <LockOutlinedIcon style={{ fontSize: "25px" }} />
                            </Tooltip>
                          </LockIconButton>
                          <ShareIconButton>
                            <Tooltip title="Share " placement={"top"}>
                              <ShareOutlinedIcon style={{ fontSize: "25px" }} />
                            </Tooltip>
                          </ShareIconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filterValue?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Paper>
        </div>
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Admin User"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={selectedItem?.firstName}
        />
      </div>
    </>
  );
};

export default CreateAdminUsers;
