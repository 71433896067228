import React, { useEffect, useState } from "react";
import dashHome from "../../../img/dashHome.svg";
import faEdit from "../../../img/faEdit.svg";
import alertIcon from "../../../img/alertIcon.png";
import "./approval.css";
import { compareBluePrintTableColumn } from "../BluePrint/Apis/BluePrintApis";
import ContentReviewerRejectModal from "./ContentReviewerRejectModal";
import {
  approveBluePrint,
  rejectBluePrintWithComment,
} from "./Apis/ContentreviewerApis";
import ConfigurationSuccessModal from "../../../components/common/ConfigurationSuccessModal";

export default function ContentReviewerSheetTable({
  data,
  handleSave,
  isPending,
  onCancelPreview,
  bluePrintID,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [inputData, setinputData] = useState(data);
  const [columnNameValidation, setColumnNameValidation] = useState([]);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [comment, setComment] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    compareTableColumn();
  }, []);

  const tableHead = [
    { name: "Print", fieldName: "print", width: 10 },
    { name: "What to print", fieldName: "what_to_print", width: 20 },
    { name: "Qstn No.", fieldName: "qstn_no", width: 5 },
    { name: "Qstn- Sub No.", fieldName: "qstn_sub_no", width: 6 },
    { name: "Marks", fieldName: "marks", width: 5 },
    { name: "Type", fieldName: "type", width: 15 },
    { name: "Sub Type", fieldName: "sub_type", width: 10 },
    { name: "Taxonomy Category", fieldName: "taxonomy_category", width: 10 },
    { name: "Taxonomy", fieldName: "taxonomy", width: 12 },
    { name: "Internal Choice", fieldName: "internal_choice", width: 7 },
  ];

  const handleClick = (event) => {
    setIsEdit(true);
  };

  const handleInputs = (e, index) => {
    const values = [...inputData];
    let inputValue = e.target.value;
    values[index][e.target.name] = inputValue;
    setinputData(values);
  };

  const compareTableColumn = async () => {
    const response = await compareBluePrintTableColumn();
    setColumnNameValidation(response?.data);
  };

  const columnValidation = (print) => {
    if (columnNameValidation.includes(print)) {
      return false;
    } else return true;
  };

  const checkValidation = (e) => {
    let arr = [];
    inputData?.forEach((item) => {
      arr.push(columnValidation(item.print));
    });
    if (arr.includes(true)) {
      alert("please check the column values");
      // setValidationStatus(false);
    } else {
      handleSave(inputData);
      // setValidationStatus(true);
    }
  };

  const handleRejectConfirm = async () => {
    const response = await rejectBluePrintWithComment(comment, bluePrintID);
    if (response?.success) {
      setComment("");
      setShowRejectModal(false);
      onCancelPreview();
    } else {
      alert("Something happend ! Try again later");
    }
  };

  const handleApproval = async () => {
    const response = await approveBluePrint(bluePrintID);
    if (response?.success) {
      setShowSuccessModal(true);
    } else {
      alert("Something happend ! Try again later");
    }
  };

  return (
    <div style={{ marginTop: "5px", marginBottom: "30px" }}>
      <div className="sheet-table-img-wrap"></div>
      <div className="sheet-table-wrapper">
        <div className="sheet-table-content" style={{ marginTop: "10px" }}>
          {tableHead.map((item, index) => {
            return (
              <div
                className="sheet-table-head"
                style={{ width: `${item.width}%` }}
                key={index}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        {inputData.map((item, index) => {
          return (
            <div
              className="sheet-table-content"
              style={{ marginTop: "5px" }}
              key={index}
            >
              <div
                className="sheet-table-body"
                style={{
                  width: "10%",
                  border: isEdit
                    ? "1px Solid #0161D2"
                    : !isEdit && columnValidation(item?.print)
                    ? "1px Solid red"
                    : "",
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  display: "flex",
                }}
              >
                {/* <div style={{ display: "flex" }}> */}
                <input
                  type="text"
                  name="print"
                  value={item.print}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
                {columnValidation(item?.print) && (
                  <>
                    <div className="hover-text">
                      <img
                        src={alertIcon}
                        width="18px"
                        height="18px"
                        style={{ paddingRight: "5px", cursor: "pointer" }}
                      />
                      <span className="tooltip-text">Value not defined</span>
                    </div>
                  </>
                )}
                {/* </div> */}
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "20%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="whatToPrint"
                  value={item.whatToPrint}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "5%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="questionNumber"
                  value={item.questionNumber}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "6%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="questionSubNumber"
                  value={item.questionSubNumber}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "5%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="marks"
                  value={item.marks}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "15%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="type"
                  value={item.type}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "10%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="subType"
                  value={item.subType}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "10%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="taxonomyCategory"
                  value={item.taxonomyCategory}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "12%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="taxonomy"
                  value={item.taxonomy}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "7%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="internalChoice"
                  value={item.internalChoice}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
            </div>
          );
        })}

        <div className="btn-wrapper">
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "5px",
                marginBottom: "30px",
                marginTop: "20px",
              }}
            >
              <div style={{ marginRight: "20px" }}>
                <button
                  type="button"
                  className="cancel-button-blue-print"
                  onClick={onCancelPreview}
                >
                  Cancel
                </button>
              </div>
              {isPending ? (
                <div style={{ marginRight: "20px" }}>
                  <button
                    type="button"
                    className="reject-button-blue-print"
                    onClick={() => setShowRejectModal(true)}
                  >
                    Reject
                  </button>
                </div>
              ) : null}
              {isPending ? (
                <div>
                  <button
                    type="button"
                    className="approve-button-blue-print"
                    onClick={() => handleApproval()}
                  >
                    Approve
                  </button>
                </div>
              ) : null}
            </div>
          </>
        </div>
      </div>
      <ContentReviewerRejectModal
        handleCancel={() => {
          setComment("");
          setShowRejectModal(false);
        }}
        handleConfirm={() => handleRejectConfirm()}
        open={showRejectModal}
        setComment={setComment}
        comment={comment}
      />
      <ConfigurationSuccessModal
        userType={"Successfully approved the blueprint."}
        open={showSuccessModal}
        handleOk={() => {
          setShowSuccessModal(false);
          onCancelPreview();
        }}
      />
    </div>
  );
}
