import React from "react";
import styled from "styled-components";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import UpdatedAt from "../UpdatedAt";
import dashboardContentStyles from "../../../css/dashboardContent.module.css";
import dashHome from "../../../../../src/img/dashHome.svg";
import { useNavigate } from "react-router-dom";

function BreadCrums({ currentPage, date, time }) {

	const navigate = useNavigate();

	return (
		<Container>
			<LeftSection>
				<span className={dashboardContentStyles.link_icon}>
					<img
						src={dashHome}
						alt="no img"
						width="15px"
						height="20px"
						style={{ verticalAlign: "middle" }}
					/>
				</span>
				<span className={dashboardContentStyles.az_menu_text}
					style={{ cursor: "pointer" }}
					onClick={() => {
						sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
						sessionStorage.removeItem("sideNavSubMenuNames");
						navigate("/dashboard/RevisionAdminDash");
					}}>Home</span>
				<span>
					<i className="fa-solid fa-angle-right"
						style={{ color: "#828282" }} />
				</span>
				<span>
					<i className="fa-solid fa-angle-right"
						style={{ color: "#828282" }} />
				</span>
				<span
					className={dashboardContentStyles.az_selected_menu_text}
					style={{ cursor: "default" }}>
					{currentPage}
				</span>
			</LeftSection>
			<UpdatedAt />
		</Container>
	);
}

export default BreadCrums;
const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #707070;
	margin-bottom: 10px;
`;
const LeftSection = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	p {
		font-size: 13px !important;
	}
`;
const RightSection = styled.div`
	p {
		font-size: 13px !important;
	}
`;
const Icon = styled.span`
	display: block;
	width: 20px;
	svg {
		color: #7a7a7a;
		width: 100%;
	}
`;
const CurrentPage = styled.p`
	font-size: 13px;
	color: #001af3;
`;
