import React, { useEffect, useState, useRef } from "react";
import dashboardContentStyles from "../../../../../pages/css/dashboardContent.module.css";
import dashHome from "../../../../../img/dashHome.svg";
import { GetLastModifiedAt } from "../Apis/RevisionAdminBluePrintApis";
import { useDispatch, useSelector } from "react-redux";
import { set_is_scrolling_to_top } from "../../../../../actions/bluePrintActions";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";


const RevisionAdminBlueprintLayer = ({ children }) => {
  const role = localStorage.getItem("role");
  const userName = localStorage.getItem("userName");
  const [modifiedDate, setModifiedDate] = useState({});
  const targetRef = useRef(null);
  const { isScrollingToTop } = useSelector((state) => state.bluePrint);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      GetLastModifiedAt(setModifiedDate);
    };

    fetchData();
  }, [userName, role]);

  const scrollUp = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (isScrollingToTop) {
      scrollUp();
      setTimeout(() => {
        dispatch(set_is_scrolling_to_top(false));
      }, 300);
    }
  }, [isScrollingToTop]);

  return (
    <div
      className="WholePagee"
      style={{
        background: "#eaeaf5",
        height: "120vh",
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarWidth: "thin"
      }}
    >
      <article>
        <div
          // className={dashboardContentStyles.dashboard_link}
          style={{ fontSize: "1vw", margin: "3px 10px" }}
        >
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          <span className={dashboardContentStyles.az_menu_text}
            style={{ cursor: "pointer" }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate(`/dashboard/RevisionAdminDash`);
            }}
          >
            Home
          </span>
          <span>
            <i className="fa-solid fa-angle-right" />
          </span>
          <span>
            <i className="fa-solid fa-angle-right" />
          </span>
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: "default" }}>
            Revision Admin
          </span>
          <span>
            <i className="fa-solid fa-angle-right" />
          </span>
          <span>
            <i className="fa-solid fa-angle-right" />
          </span>
          <a className={dashboardContentStyles.az_selected_menu_text}>Upload Assessment Paper</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>
            Last Update: {modifiedDate?.data}
          </p>
        </div>
        {/* <div
          className={dashboardContentStyles.dashboard_link}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
            marginLeft: "15px",
            fontSize: "1vw",
            position: "initial",
          }}
          ref={targetRef}
        >
          <div>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            <span
              style={{ fontSize: "12px", marginLeft: "4px", color: "#7A7A7A" }}
            >
              Home ⨠
            </span>
            <span
              style={{ fontSize: "12px", color: "#001AF3", marginLeft: "4px" }}
            >
              Revision Admin ⨠
            </span>
            <span
              style={{ fontSize: "12px", color: "#001AF3", marginLeft: "4px" }}
            >
              Upload Assessment Paper
            </span>
          </div>
          <div
            className={dashboardContentStyles.dashboard_last_updated}
            style={{ position: "initial" }}
          >
            <p>Last Update: {modifiedDate?.data}</p>
          </div>
        </div> */}
      </article>
      {children}
    </div>
  );
};

export default RevisionAdminBlueprintLayer;

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #707070;
	margin-bottom: 10px;
`;
const LeftSection = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	p {
		font-size: 13px !important;
	}
`;
const RightSection = styled.div`
	p {
		font-size: 13px !important;
	}
`;
const Icon = styled.span`
	display: block;
	width: 20px;
	svg {
		color: #7a7a7a;
		width: 100%;
	}
`;
const CurrentPage = styled.p`
	font-size: 13px;
	color: #001af3;
	cursor: pointer;
`;