import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import searchIcn from "../../../src/img/search.svg";
import commonsort from "../js/CommonSort"
import sortIcon from "../img/sort.png"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  height: 650,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  rowGap: "1rem",
};
const setBg = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomColor}`;
};
const RoleDetailsModal = ({ open, close, data, toplabel }) => {
  const [studentData, setStudentData] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [showClear, setShowClear] = useState(false);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("name");
  const [query, setQuery] = useState("");
  useEffect(async () => {
    if (data?.length) {
      await setFilterValue(data);
      await setStudentData(data);
    }
  }, [data]);
  const onSort = async (sortOrder, field) => {
    // console.log(field, filterValue)
    setSortOrder(sortOrder)
    var sorted = await commonsort(filterValue || [], field, sortOrder);
    // console.log(sorted)
    setFilterValue(sorted)
  }
  const searchHandler = (event) => {
    let value = event?.target?.value?.toLowerCase();
    setQuery(value)
    let result = [...studentData];
    if (value) {
      result = studentData?.filter((data) => {
        return (
          data?.name?.toLowerCase().includes(value) ||

          data?.email?.toLowerCase().includes(value)

        );
      });
    } else {

    }
    setShowClear(value !== "")
    setFilterValue(result);
  };
  const handleClear = () => {
    setQuery("");
    setShowClear(false);
    setFilterValue(studentData);

  };
  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };
  const lastDateAndLoginHandler = (item) => {
    if (item?.length > 0) {
      const array = item?.split(" ");
      //const date = moment(new Date(array[0])).format("MM/DD/YYYY");
      // const time = moment(array[1]).format("hh:mm");
      return `${array[0]} / ${array[1]}`;
    }
  };
  const submissionDateHandler = (item) => {
    if (item?.length) {
      return moment(item).format("YYYY - MMM - DD");
    }
  };
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: 20, fontWeight: 600, color: "#29313f" }}>
              {toplabel}
            </div>
            <CloseOutlinedIcon
              onClick={close}
              style={{ cursor: "pointer", fontSize: 20, color: "#757575" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{
                width: { sm: 200, md: 300 },
                "& .MuiInputBase-root": {
                  width: "247px !important",
                  height: "32px !important",
                  borderRadius: "50px !important",
                  background: "#fff !important",
                  lineHeight: "21px !important",
                  opacity: 1,
                  font: "normal normal normal 13px/20px Poppins !important",
                },
              }}
              type={"text"}
              value={query}
              placeholder={"Search anything...."}
              onChange={searchHandler}
              InputProps={{
                endAdornment: (
                  <>
                    {showClear && (
                      <IconButton onClick={handleClear}>
                        <ClearIcon
                          style={{ color: "#F05262", fontSize: "1vw" }}
                        />
                      </IconButton>
                    )}
                    {!showClear && (
                      <IconButton>
                        <img
                          src={searchIcn}
                          style={{
                            verticalAlign: "top",
                            fontSize: "large",
                          }}
                          aria-hidden="true"
                        />
                      </IconButton>
                    )}
                  </>
                ),
              }}
              size={"small"}
            />

          </div>
        </div>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            boxShadow: "0px 0px 0px 0px",
          }}
        >
          <TableContainer sx={{ maxHeight: 500, overflow: "auto" }}>
            <Table
              sx={{ minWidth: 650, marginBottom: "90px" }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: 600,
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    S.NO
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 600,
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                    align={"left"}
                  >
                    Name
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("name");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "name")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: 600,
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    Email
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("email");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "email")
                      }}
                    />
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{
                      fontWeight: 600,
                      paddingRight: "60px",
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    Registered Time
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("lastLoginTime");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "lastLoginTime")
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterValue?.length > 0 &&
                  filterValue
                    ?.slice(
                      pageNumber * rowsPerPage,
                      pageNumber * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      const firstLetter = row?.firstName;
                      return (
                        <TableRow
                          key={row?.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {pageNumber * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#575269",
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "10px",
                            }}
                          >
                            {row?.name}
                          </TableCell>
                          <TableCell align="left" style={{ color: "#575269" }}>
                            {row?.email}
                          </TableCell>

                          <TableCell align="left" style={{ color: "#575269" }}>
                            {row?.lastLoginTime !== null
                              ? moment(row?.lastLoginTime).format("DD-MM-YYYY | hh:mm A")
                              : ''}
                            {/* {row?.lastLoginTime} */}
                            {/* {(moment(row?.lastLoginTime).format("DD-MM-YYYY | hh:mm A") || '')} */}
                          </TableCell>


                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              bottom: "0",
              width: "80.5%",
              position: "fixed",
              backgroundColor: "white",
            }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={filterValue?.length || 0}
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          />
        </Paper>
      </Box>
    </Modal>
  );
};
export default RoleDetailsModal;
