import React, { useState } from "react";
import Switch from "../../../../../components/common/Switch";
import Reason from "../../../../../img/reason.png";
import BluePrintReasonModal from "../../../../../components/BluePrintReasonModal/BluePrintReasonModal";
import { Tooltip } from "@mui/material";
import { truncateString } from "../../../../../utils/helper";
import { CreateOutlined, DeleteOutline } from "@mui/icons-material";
import DisabledSwitch from "../../../../../components/common/DisabledSwitch";

export const UploadAssessmentTableBody = ({
  blueprintsData,
  currentPage,
  setShowDeleteModal,
  setSelectedForDelete,
  handleToggle,
  handleShowReasonModal,
  onPreviewClick,
  setSelectedForEdit,
  pageSize,
  totalElements,
  role,
  setButtonEdit,
  page,
}) => {
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [rejectedData, setRejectedData] = useState(null);
  const startIndex = (currentPage - 1) * pageSize + 1;

  const handleEdit = (id) => {
    setSelectedForEdit(id);
  };

  return (
    <tbody>
      {blueprintsData?.map((item, index) => {
        return (
          <tr className="table-data" style={{ textAlign: "start" }}>
            <td style={{ paddingLeft: "10px" }}>{startIndex + index}</td>
            <td style={{ paddingLeft: "10px", cursor: 'default' }}>
              <Tooltip placement={"top"} title={item?.paperTitle}>
                {truncateString(item?.paperTitle, 8)}
              </Tooltip>{" "}
            </td>
            <td style={{ paddingLeft: "10px" }}>{item?.board}</td>
            <td style={{ paddingLeft: "10px" }}>{item?.grade}</td>
            <td style={{ paddingLeft: "10px" }}>{item?.subject}</td>
            <td style={{ paddingLeft: "10px" }}>{item?.levelName}</td>
            <td style={{ paddingLeft: "10px" }}>{item?.academicYear}</td>

            <td>
              {page == "assign_assessment" ? (
                <DisabledSwitch checked={item?.active} />
              ) : (
                <Switch
                  checked={item?.active}
                  onChange={(e) =>
                    page == "assign_assessment" ? "" : handleToggle(e, item?.id)
                  }
                />
              )}
            </td>
            {page == "assign_assessment" ? null : (
              <td style={{ paddingLeft: "10px" }}>
                <div className="action-container">
                  <>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEdit(item?.id)}
                    >
                      <CreateOutlined style={{ fontSize: "18px" }} />
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowDeleteModal(true);
                        setSelectedForDelete(item?.id);
                      }}
                    >
                      <DeleteOutline style={{ fontSize: "18px" }} />
                      {/* <img src={action__Delete} alt="Dlt-Btn" /> */}
                    </div>
                  </>
                </div>
              </td>
            )}
          </tr>
        );
      })}
    </tbody>
  );
};
