import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "../../Navbar";
import MainContainer from "./MainContainer";
import DashboardContent from "./DashboardContent";
import PrincipalDashboardNew from "../../PrincipalModule/dashboardNew/PrincipalDashboardNew";
import axios from "axios";

const CordinatorDashboard = () => {
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const token = sessionStorage.getItem("token");
  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Basic_info", res?.data?.data?.teacher)
    return res?.data?.data?.teacher || {}
  };

  useEffect(async () => {
    const teacherData = await fetchTeacherData(userName, role);
    sessionStorage.setItem('teacherId', teacherData?.id)
    sessionStorage.setItem('boardId', teacherData?.boardId)
    sessionStorage.setItem('schoolId', teacherData?.school)
    sessionStorage.setItem('branchId', teacherData?.branch)
  }, [])
  //added by sg
  const [headerTitle, setHeaderTitle] = useState("");
  const handleHeaderTitle = (value) => {
    setHeaderTitle(value);
  };
  return (
    <>
      <Navbar handleHeaderTitle={(value) => handleHeaderTitle(value)} />
      {/* //header 10vh  */}
      <MainContainer
        children={<PrincipalDashboardNew />}
        // children={<DashboardContent />}
        headerTitle={headerTitle}
      />
    </>
  );
};
export default CordinatorDashboard;
