const teacherList = [
  {
    firstName: "John",
    lastName: "Smith",
    primaryEmail: "teacher@gmail.com",
    password: "446409901",
    userName: "-",
    active: "",
    action: "",
    userId: "12815",
    loginTime: "-",
    PrimaryPhoneNo: "1234567890",
    dateOfBirth: "15 Mar 1982",
    gender: "Male",
    dateOfJoin: "02 APR 2021",
    workExperience: "15 Years",
    address: "Flat no. 205, apartment",
    designation: "Math Teacher",
    role: "Teacher",
  },
  {
    firstName: "Coordinater",
    lastName: "",
    primaryEmail: "coordinater@gmail.com",
    password: "446409901",
    userName: "-",
    active: "",
    action: "",
    userId: "12816",
    PrimaryPhoneNo: "1234567890",
    dateOfBirth: "15 Mar 1982",
    gender: "Male",
    dateOfJoin: "02 APR 2021",
    workExperience: "15 Years",
    address: "Flat no. 205, apartment",
    designation: "Math Teacher",
    role: "Coordinator",
    loginTime: "-",
  },
  {
    firstName: "principal",
    lastName: "",
    primaryEmail: "principal@gmail.com",
    password: "446409901",
    userName: "-",
    active: "",
    action: "",
    userId: "12816",
    PrimaryPhoneNo: "1234567890",
    dateOfBirth: "15 Mar 1982",
    gender: "Male",
    dateOfJoin: "02 APR 2021",
    workExperience: "15 Years",
    address: "Flat no. 205, apartment",
    designation: "Math Teacher",
    role: "Principal",
    loginTime: "-",
  },
];

export default teacherList;
