const StudentList = [
    {
        UserName:"Rohan Singh",
        Grade:"Grade 3",
        section:"A",
        UserId:"122234",
        students:"",
        FirstName:"Rohan",
        LastName:" Singh ",
        primaryEmail:"RohanSingh@gmail.com",
        primaryPhoneNo:" 1234567890",
        DateOfBob:"12-07-1999",
        Gender:"Male",
        MotherTongue:"Hindi",
        SecondLanguage:"English",
        admissionDate:"17-08-2015",
        Quiz : "0",
        LastLogin: "12:00",
        StudentCategory:"-",
        PresentAddress:"-",
        Active:"",
        Action:"",
            }
];

export default StudentList;
