import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";

const HoverGreyCancelButton = (props) => {
  const CancelButton = styled(Button)(({ theme }) => ({
    // color: theme.palette.getContrastText(grey[500]),
    color: "#757575",
    width: 150,
    fontSize:"14px",
    backgroundColor: "#fff",
    borderRadius: 150,
    borderColor: grey[500],
    border: "1px solid",
    "&:hover": {
      color: "#fff",
      backgroundColor: grey[500],
      border: 0,
    },
  }));
  return <CancelButton onClick={props.onClick}>{props.children}</CancelButton>;
};
export default HoverGreyCancelButton;