import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  height: 600,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  // rowGap: "1rem",
};

const PlanDetailsModal = ({ open, close, planDetails, planlable }) => {
  // const [studentData, setStudentData] = useState([]);
  const [filterValue, setFilterValue] = useState([]);

  useEffect(async () => {
    if (planDetails?.length) {
      await setFilterValue(planDetails);
      //await setStudentData(schoolDetails);
    }
  }, [planDetails]);

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize:"small", fontWeight: 600, color: "#29313f" }}>
              {planlable}
            </div>
            <CloseOutlinedIcon
              onClick={close}
              style={{ cursor: "pointer", fontSize: 20, color: "#757575" }}
            />
          </div>
        </div>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            boxShadow: "0px 0px 0px 0px",
          }}
        >
          <TableContainer sx={{ maxHeight: 500, overflow: "auto" }}>
            <Table
              sx={{ minWidth: 430, marginBottom: "90px" }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: 400,
                      color: "blue",
                      borderBottom: "white",
                    }}
                  >
                    Grades
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 400,
                      color: "blue",
                      borderBottom: "white",
                    }}
                  >
                    Features
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterValue?.length > 0 &&
                  filterValue.map((item, index) => {
                    return (
                      <TableRow
                        key={item?.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{ borderBottom: "white" }}
                          component="th"
                          scope="row"
                        >
                          {item?.grade}
                        </TableCell>
                        <TableCell
                          style={{ borderBottom: "white" }}
                          component="th"
                          scope="row"
                        >
                          {item?.status === true ? (
                            <span
                              style={{
                                height: "25px",
                                width: "25px",
                                borderRadius: "50%",
                                backgroundColor: "rgb(56 224 13)",
                                
                                fontSize:"small"
                              }}
                            >
                            <i class="fa fa-check"  style={{color:"white"}}></i>
                            </span>
                          ) : item?.status === false ? (
                            <span
                              style={{
                                height: "25px",
                                width: "25px",
                                borderRadius: "50%",
                                backgroundColor: "#d8d806",
                                
                                fontSize:"small"
                              }}
                            >
                              <i class="fa fa-check"  style={{color:"white"}}></i>
                            </span>
                          ) : (
                            <span
                              style={{
                                height: "25px",
                                width: "25px",
                                borderRadius: "50%",
                                backgroundColor: "#cfcbc8",
                               
                                fontSize:"small"
                              }}
                            >
                              <i class="fa fa-check"  style={{color:"white"}}></i>
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div style={{display:"flex" ,fontSize:"xx-small" ,fontWeight: "500",marginTop:"20px",justifyContent:"space-evenly"}}>
          <div>
            <span
              style={{
                height: "15px",
                width: "15px",
                borderRadius: "50%",
                backgroundColor: "#3eda3e",
                padding: "0px 2px",
                marginRight:"5PX",
              }}
            >
              <i class="fa fa-check" aria-hidden="true" style={{color:"white"}}></i>
            </span>
            All Feature
          </div>
          <div>
            <span
              style={{
                height: "20px",
                width: "20px",
                borderRadius: "50%",
                backgroundColor: "yellow",
                padding: "0px 2px",
                marginRight:"5PX",
              }}
            >
              <i class="fa fa-check" aria-hidden="true" style={{color:"white"}}></i>
            </span>
            Few Feature
          </div>
          <div>
            <span
              style={{
                height: "20px",
                width: "20px",
                borderRadius: "50%",
                backgroundColor: "#cfcbc8",
                padding: "0px 2px",
                marginRight:"5PX",
              }}
            >
              <i class="fa fa-check" aria-hidden="true" style={{color:"white"}}></i>
            </span>
            No Feature
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default PlanDetailsModal;
