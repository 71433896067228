import axios from 'axios'
import { isNOTNullOrUndefined } from '../../../utils/helper'

let token = sessionStorage.getItem('token')

const dashboardApi = axios.create({
  baseURL: process.env.REACT_APP_SWAGGER_URL,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const getProfileDetails = async ({ queryKey }) => {
  const [_, userName, role, token] = queryKey
  dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${token}`
  const response = await dashboardApi.get(
    `v1/api/user/users/profile?username=${userName}&roles=${role}`
  )
  console.log("response?.data?.data", response?.data?.data)
  var result = response?.data?.data?.teacher || {};
  console.log("Basic_info", response?.data?.data?.teacher)
  sessionStorage.setItem("Basic_info", JSON.stringify(result))
  return response?.data?.data
}

//Coordinator

export const getCountForDashboard = async ({ queryKey }) => {
  const [_, teacherId, schoolId, branchId, gradeId, subjectId, subTopicId, token] = queryKey
  dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${token}`
  const response = await dashboardApi.get(
    `v1/api/user/dashboard/count-for-dashboard?teacherId=${teacherId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${gradeId}&subjectId=${subjectId}${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ''}`
  )
  console.log(response?.data?.data, 'count response', subTopicId)
  return response?.data?.data
}


export const getVsData = async ({ queryKey }) => {
  const [_, teacherId, schoolId, branchId, gradeId, subjectId, boardId, subTopicId, token] = queryKey
  dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${token}`
  const response = await dashboardApi.get(
    `v1/api/user/dashboard/count-for-chapter-quizzes?teacherId=${teacherId}&schoolId=${schoolId}&boardId=${boardId}&branchId=${branchId}&gradeId=${gradeId}&subjectId=${subjectId}${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ''}`
  )
  return response?.data?.data
}

export const getStudentLeveldata = async ({ queryKey }) => {
  const [_, teacherId, schoolId, branchId, gradeId, subjectId, boardId, subTopicId, token] =
    queryKey
  dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${token}`

  const response = await dashboardApi.get(
    `v1/api/user/dashboard/student-level?teacherId=${teacherId}&schoolId=${schoolId}&boardId=${boardId}&branchId=${branchId}&gradeId=${gradeId}&subjectId=${subjectId}${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ''}`
  )
  return response?.data?.data
}

export const getGradesList = async ({ queryKey }) => {
  const [_, teacherId, token] = queryKey
  dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${token}`

  const response = await dashboardApi.get(
    `v1/api/user/teachers/assigned-grades?teacherId=${teacherId}`
  )
  if (response.status === 200) {
    return response?.data?.data
  } else {
    return []
  }
}

// export const getGradesList = async ({ queryKey }) => {
//   console.log('grades call')
//   const [_, teacherId] = queryKey
//   const response = await dashboardApi.get(`v1/api/master/grades/all`)
//   if (response.status === 200) {
//     return response?.data?.data
//   } else {
//     return []
//   }
// }

export const getSubjectsList = async ({ queryKey }) => {
  const [_, teacherId, gradeId, token] = queryKey
  dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${token}`

  const response = await dashboardApi.get(
    `v1/api/user/teachers/subjects?teacherId=${teacherId}&gradeId=${gradeId}`
  )
  if (response.status === 200) {
    return response?.data?.data
  } else {
    return []
  }
}



//Principal
// v1/api/user/dashboard/syllbus-percentage?boardId=boardId&schoolId=schoolId&branchId=branchId&gradeId=gradeId&subjectId=subjectId&subTopicId=subTopicId

export const getCountForPricipalDashboard = async ({ queryKey }) => {
  const [_, teacherId, schoolId, branchId, token] = queryKey
  dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${token}`
  const response = await dashboardApi.get(
    `v1/api/user/dashboard/count-for-dashboard?teacherId=${teacherId}&schoolId=${schoolId}&branchId=${branchId}`
  )
  return response?.data?.data
}


export const getAllGradesList = async ({ queryKey }) => {
  const [_, teacherId, token] = queryKey
  dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${token}`

  const response = await dashboardApi.get(
    `v1/api/master/grades/all?teacherId=${teacherId}`
  )
  if (response.status === 200) {
    return response?.data?.data
  } else {
    return []
  }
}

export const getAllSubjectsList = async ({ queryKey }) => {
  const [_, teacherId, gradeId, token] = queryKey
  dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${token}`

  const response = await dashboardApi.get(
    `v1/api/master/subjects/all?teacherId=${teacherId}&gradeId=${gradeId}`
  )
  if (response.status === 200) {
    return response?.data?.data
  } else {
    return []
  }
}

export const getSyllabusCompletionDetails = async ({ queryKey }) => {
  const [_, schoolId, branchId, gradeId, subjectId, boardId, subTopicId, token] =
    queryKey
  dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${token}`

  const response = await dashboardApi.get(
    `v1/api/teacher/teach/chapter/syllabus-completion/details?schoolId=${schoolId}&boardId=${boardId}&branchId=${branchId}&gradeId=${gradeId}&subjectId=${subjectId}${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ''}`
  )
  console.log(response?.data)
  return response?.data?.data
}

export const getSyllabusPercentage = async ({ queryKey }) => {
  const [_, schoolId, branchId, gradeId, subjectId, boardId, subTopicId, token] =
    queryKey
  dashboardApi.defaults.headers.common['Authorization'] = `Bearer ${token}`

  const response = await dashboardApi.get(
    `v1/api/user/dashboard/syllbus-percentage?schoolId=${schoolId}&boardId=${boardId}&branchId=${branchId}&gradeId=${gradeId}&subjectId=${subjectId}${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ''}`
  )
  console.log(response?.data)
  return response?.data?.data
}

// v1/api/user/dashboard/syllbus-percentage?boardId=asd&schoolId=asd&branchId=asd&gradeId=asd&subjectId=ad