import axios from 'axios'

async function SchoolControllerIdGet(setSchoolIdData, setSchoolInputs) {
  let token = sessionStorage.getItem("token");
  let schoolid = sessionStorage.getItem("schoolid")
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/${schoolid}`,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp)=>{
      if(resp.data.data){
        setSchoolIdData(resp.data.data)
        setSchoolInputs(resp.data.data)
      }
      
    })
    .catch((err)=>{
      //console.log(err)
     })
}

export default SchoolControllerIdGet;
