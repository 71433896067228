import { alpha, styled } from "@mui/material/styles";
import { Switch as SWITCH } from "@mui/material";
import React from "react";
const Switch = ({ checked, onChange, disabled }) => {
  const GreenSwitch = styled(SWITCH)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#ffff",
      "&:hover": {
        backgroundColor: alpha("#FFA700", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-thumb" : {
      border: "3px solid orange"
    } ,
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#FD8C00",
    },
    '& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled': 
    {
      opacity: 0.5,
      color: "#ffff",
    },
  }));
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return <GreenSwitch {...label} checked={checked} onChange={onChange} disabled = {disabled ? true : false} style = {{color:disabled && "#fff"}} />;
};
export default Switch;
