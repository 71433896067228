import { FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useQuestionType } from '../../../../hooks/quiz/useQuestionType'

const QuestionTypeDropDown = ({setquestiontypeId,questiontypeId}) => {

  const { data, isLoading, error } = useQuestionType()

  return (
   
           <Box sx={{ minWidth: 160, marginLeft: '16px' }}>
            <FormControl fullWidth size='small'>
              <InputLabel
                id='demo-select-small'
                style={{ fontSize: 14 }}
              >
                Type
              </InputLabel>
              <Select
                labelId='demo-select-small'
                id='demo-select-small'
                label='Board'
                value={questiontypeId}
                sx={{ fontSize: 12, color: 'red' }}
                onChange={setquestiontypeId}
                style={{
                  background: '#fff',
                  borderRadius: 150,
                  height: 32,
                  fontSize: 10,
                  color: '#FD8C00'
                }}
              >
                {data?.length > 0 ? (
                  data?.map(type => {
                    return (
                      <MenuItem
                        style={{ fontSize: 10 }}
                        key={type.id}
                        value={type}
                      >
                        <ListItemText
                          sx={{ fontSize: 10 }}
                          primary={type.questionType}
                        />
                      </MenuItem>
                    )
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box> 
  )
}

export default QuestionTypeDropDown