import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { Box } from "@mui/material";
import { getPercentLabelPositionV2 } from "../../../../../utils/helper";

export default function ApexSemiCircleProgress({ bgcolor, progressValue }) {
  const series = progressValue;

  const options = {
    chart: {
      height: 300,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: "30%",
        },
        dataLabels: {
          show: false,
        },

        startAngle: -110,
        endAngle: 110,
      },
    },
    fill: {
      type: "solid",
      colors: bgcolor,
    },

    stroke: {
      lineCap: "round",
    },
  };

  return (
    <Box
      sx={{
        height: 100,
        width: 100,
        position: "relative",
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={170}
      />
      <div
        style={{
          position: "absolute",
          top: "54%",
          left: "40%",
          right: 0,
          fontSize: "18px",
          color: "#2D3962",
          fontWeight: "bold",
        }}
      >
        {series[1]}
      </div>
      <div
        style={{
          position: "absolute",
          top: "75%",
          left: "47%",
          right: 0,
          fontSize: "14px",
          color: "#2D3962",
        }}
      >
        {"%"}
      </div>
      <div style={getPercentLabelPositionV2(series[0])}>{series[0] + "%"}</div>
    </Box>
  );
}
