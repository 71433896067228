import React from 'react';
import { Box, Modal, Grid, Typography, Button, styled } from "@mui/material";
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import dashboardContentStyles from "../../../css/dashboardContent.module.css";
import YellowButton from '../../BranchSecitonMapping/CommonComponents/YellowButton';
// import YellowBorderHover from "../../../components/common/yellowButton";
// import { DoneAllRounded } from "@mui/icons-material";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import action__Success from "../../../../../src/img/Successfully_added.svg";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
};

const DeleteBlanksModal = ({ open, close, alert, caseStudy, yesClick, okClick }) => {
    return (
        <>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {caseStudy ?
                                <img
                                    className={
                                        dashboardContentStyles.aRoleActionBtn
                                    }
                                    src={action__Success}
                                    alt="Success-Btn"
                                    style={{ width: "18%", height: "auto" }}
                                />
                                :
                                <ReportGmailerrorredOutlinedIcon
                                    style={{ color: "#FFA700", fontSize: 120 }}
                                />
                            }
                        </div>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <center>{alert}</center>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        columnGap: "1rem",
                                        paddingTop: '20px'
                                    }}
                                >
                                    <YellowButton onClick={close}>Ok</YellowButton>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}

export default DeleteBlanksModal;