import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Questionperformance from "./Questionperformance.css";
import { AccountCircle as AccountCircleIcon, CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import FlakyOutlinedIcon from '@mui/icons-material/FlakyOutlined';
import reflectionImage from './img/Reflection.png';
import slide1Image from './img/slide1.jpg';
import StudentPercentageSvg from "../../../../img/StudentPercentage.svg";
import CorrectOptionSvg from "../../../../img/CorrectOption.svg";
import AttemptRateSvg from "../../../../img/AttemptRate.svg";
import StudentsAttemptedSvg from "../../../../img/StudentAttempted.svg";
import OptionMostStudentChose from "../../../../img/MostStudentChose.svg";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    background: '#ffffff', // Background color
    borderRadius: '3px', // Border radius
    padding: theme.spacing(1), // Add some padding if needed
    marginTop: '15px',
  },
  gridItem: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    padding: '5px',
  },
}));

const dummyCaseStudy = [
  {
    "parentQuestionId": "402892888978420d01898ccb13ec0063",
    "percentageInQuiz": 66,
    "caseStudyQuestion": "CASE STUDY ",
    "caseStudyExplanation": "<p>SIMPLE - 25, MEDIUM -50 , HARD-25</p>",
    "taxonomyPercentageDto": [
      {
        "taxonomyId": "402892888978420d01898ca8cf70003e",
        "taxonomyName": "geo",
        "percentage": 50.0
      },
      {
        "taxonomyId": "402892888978420d01898ca91840003f",
        "taxonomyName": "ion",
        "percentage": 25.0
      },
      {
        "taxonomyId": "402892888978420d01898ca8840b003d",
        "taxonomyName": "major",
        "percentage": 25.0
      }
    ],
    "subQuestion": [
      {
        "questionId": "402892888978420d01898ccbf4140066",
        "questionTypeId": "402892888697da25018697dd5fc70001",
        "questionType": "Fill in the blanks",
        "question": "<p>2 INTERFERENCE</p>",
        "explanation": "<p>EXPLANA</p>",
        "listOfAnswers": [
          [
            {
              "order": 1,
              "answerKey": [
                "SD"
              ],
              "resourceUrl": null,
              "htmlTag": [
                "<p>SD</p>"
              ]
            }
          ]
        ],
        "report": null
      },
      {
        "questionId": "402892888978420d01898ccc6a5b0068",
        "questionTypeId": "402892888697da25018697dd5fc70001",
        "questionType": "Fill in the blanks",
        "question": "<p>2 INTRFERENCE</p>",
        "explanation": "<p>SA</p>",
        "listOfAnswers": [
          [
            {
              "order": 1,
              "answerKey": [
                "INTERFERENCE"
              ],
              "resourceUrl": null,
              "htmlTag": [
                "<p>INTERFERENCE</p>"
              ]
            }
          ]
        ],
        "report": null
      },
      {
        "questionId": "402892888978420d01898ccd22d9006a",
        "questionTypeId": "402892888697da25018697de0a730003",
        "questionType": "Muti Choice Question",
        "question": "<p>1 NEWSS</p>",
        "explanation": null,
        "listOfAnswers": [
          [
            {
              "option": "A",
              "order": 65,
              "answerKey": "NEW",
              "resourceUrl": "",
              "htmlTag": "<p>NEW</p>",
              "correctAnswer": false
            },
            {
              "option": "B",
              "order": 66,
              "answerKey": "LOAD",
              "resourceUrl": "",
              "htmlTag": "<p>LOAD</p>",
              "correctAnswer": true
            },
            {
              "option": "C",
              "order": 67,
              "answerKey": "AS",
              "resourceUrl": "",
              "htmlTag": "<p>AS</p>",
              "correctAnswer": false
            }
          ]
        ],
        "report": null
      },
      {
        "questionId": "402892888978420d01898cce35fe006c",
        "questionTypeId": "402892888697da25018697ddca200002",
        "questionType": "Muti Select Question",
        "question": "<p>GLOBAL 1 QUESTION</p>",
        "explanation": null,
        "listOfAnswers": [
          [
            {
              "option": "A",
              "order": 65,
              "answerKey": "GLOBAL",
              "resourceUrl": "",
              "htmlTag": "<p>GLOBAL</p>",
              "correctAnswer": true
            },
            {
              "option": "B",
              "order": 66,
              "answerKey": "NEWS",
              "resourceUrl": "",
              "htmlTag": "<p>NEWS</p>",
              "correctAnswer": true
            },
            {
              "option": "C",
              "order": 67,
              "answerKey": "CD",
              "resourceUrl": "",
              "htmlTag": "<p>CD</p>",
              "correctAnswer": false
            }
          ]
        ],
        "report": {
          "correctQuestionAnswerStudentPercentage": 0,
          "correctQuestionAnswerOption": {
            "A": {
              "option": "A",
              "order": 65,
              "answerKey": "GLOBAL"
            },
            "B": {
              "option": "B",
              "order": 66,
              "answerKey": "NEWS"
            }
          },
          "quizAttemptRate": 0,
          "studentQuestionAttemptedCount": 1,
          "mostOptionChoseStudentCount": "A"
        }
      }
    ]
  },
  {
    "parentQuestionId": "402892888978420d01898ccefd57006e",
    "percentageInQuiz": 50,
    "caseStudyQuestion": "2ND ONE",
    "caseStudyExplanation": "<p>THINGS ARE OUT OF CONTROL</p>",
    "taxonomyPercentageDto": [
      {
        "taxonomyId": "402892888968a0d4018968c3104f0000",
        "taxonomyName": "Application",
        "percentage": 33.0
      },
      {
        "taxonomyId": "402892888978420d01898ca8cf70003e",
        "taxonomyName": "geo",
        "percentage": 33.0
      },
      {
        "taxonomyId": "402892888978420d01898ca8840b003d",
        "taxonomyName": "major",
        "percentage": 33.0
      }
    ],
    "subQuestion": [
      {
        "questionId": "402892888978420d01898ccf7ef70071",
        "questionTypeId": "402892888697da25018697dd5fc70001",
        "questionType": "Fill in the blanks",
        "question": "<p>QUESTION</p>",
        "explanation": "<p>EXPLANATION</p>",
        "listOfAnswers": [
          [
            {
              "order": 1,
              "answerKey": [
                "BLANK"
              ],
              "resourceUrl": null,
              "htmlTag": [
                "<p>BLANK</p>"
              ]
            }
          ]
        ],
        "report": null
      },
      {
        "questionId": "402892888978420d01898cd03a920073",
        "questionTypeId": "402892888697da25018697de0a730003",
        "questionType": "Muti Choice Question",
        "question": "<p>INTERFERENCE</p>",
        "explanation": null,
        "listOfAnswers": [
          [
            {
              "option": "A",
              "order": 65,
              "answerKey": "CHOIC A",
              "resourceUrl": "",
              "htmlTag": "<p>CHOIC A</p>",
              "correctAnswer": false
            },
            {
              "option": "B",
              "order": 66,
              "answerKey": "CHOICE B",
              "resourceUrl": "",
              "htmlTag": "<p>CHOICE B</p>",
              "correctAnswer": true
            },
            {
              "option": "C",
              "order": 67,
              "answerKey": "CHOIC E C",
              "resourceUrl": "",
              "htmlTag": "<p>CHOIC E C</p>",
              "correctAnswer": false
            }
          ]
        ],
        "report": null
      },
      {
        "questionId": "402892888978420d01898cd1a1a90076",
        "questionTypeId": "402892888697da25018697ddca200002",
        "questionType": "Muti Select Question",
        "question": "<p>1 CATEGORY FOR THIS CASE STUDY</p>",
        "explanation": null,
        "listOfAnswers": [
          [
            {
              "option": "A",
              "order": 65,
              "answerKey": "CHOICE A",
              "resourceUrl": "",
              "htmlTag": "<p>CHOICE A</p>",
              "correctAnswer": true
            },
            {
              "option": "B",
              "order": 66,
              "answerKey": "CHOICE B",
              "resourceUrl": "",
              "htmlTag": "<p>CHOICE B</p>",
              "correctAnswer": true
            },
            {
              "option": "C",
              "order": 67,
              "answerKey": "CHOICE C",
              "resourceUrl": "",
              "htmlTag": "<p>CHOICE C</p>",
              "correctAnswer": false
            },
            {
              "option": "D",
              "order": 68,
              "answerKey": "CHOICE D",
              "resourceUrl": "",
              "htmlTag": "<p>CHOICE D</p>",
              "correctAnswer": false
            }
          ]
        ],
        "report": {
          "correctQuestionAnswerStudentPercentage": 0,
          "correctQuestionAnswerOption": {},
          "quizAttemptRate": 0,
          "studentQuestionAttemptedCount": 1,
          "mostOptionChoseStudentCount": "B"
        }
      }
    ]
  }
]

function indexToAlphabet(i) {
  return String.fromCharCode(65 + i); // 65 is the ASCII code for 'A'
}

const QuestionWisePerformance = ({
  accordionData,
  accordionChangeHandler,
  intIndex,
  expanded,
  setSelectedImageUrl,
  setOpenImageModal,
  loading,
  tabsValue,
  errorMsg
}) => {

  const [showModal, setShowModal] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [caseStudyAccordion, setCaseStudyAccordion] = useState(false);
  const [imageToShow, setImageToShow] = useState('');

  const handleImageClick = (imageSrc) => {
    setImageToShow(imageSrc);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setImageToShow('');
    setShowModal(false);
  };

  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? index : null);
  };

  const handleCaseStudyAccordion = () => {
    setCaseStudyAccordion(!caseStudyAccordion);
  }

  const classes = useStyles();
  return (
    <>
      {/* {loading &&
        <div
          style={{
            position: 'relative',
            fontSize: '18px',
            fontWeight: 500,
            color: '#9b9fa7',
            opacity: 0.8,
          }}
        >
          FETCHING...
        </div>
      } */}
      {!loading && (Object.keys(accordionData)?.length === 0 ||
        accordionData?.concepts?.length === 0 ||
        accordionData?.concepts === null) && (accordionData?.caseStudy?.length === 0 || accordionData?.caseStudy === null) &&
        <div
          style={{
            position: 'relative',
            fontSize: '18px',
            fontWeight: 500,
            color: '#9b9fa7',
            opacity: 0.8,
          }}
        >
          NO QUESTIONS AVAILABLE
        </div>
      }
      {!loading &&
        (errorMsg?.length > 0 &&
          accordionData?.concepts?.length !== 0 || accordionData?.caseStudy?.length !== 0) &&
        <div
          style={{
            position: 'relative',
            fontSize: '18px',
            fontWeight: 500,
            color: '#9b9fa7',
            opacity: 0.8,
          }}
        >
          {errorMsg}
        </div>
      }
      <div className="questionwise">
        {(!loading && !errorMsg) && (accordionData?.concepts?.length > 0 || accordionData?.caseStudy?.length > 0) &&
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ paddingTop: "0" }}>
                <p style={{ textAlign: "left", fontSize: "13px", color: "#000000", marginLeft: 8 }}>
                  Class Average - {accordionData?.classAverage !== null ? `${Math.round(accordionData?.classAverage)}%` : ''}
                </p>
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: '10px',
                background: '#fff',
                maxHeight: '67vh',
                overflow: 'auto',
                scrollbarWidth: "thin"
              }}
            >
              {accordionData && accordionData?.concepts?.map((item, index) => {
                let questions = item?.questions;
                questions.sort((a, b) => {
                  const percentageA = a.report.correctQuestionAnswerStudentPercentage || 0;
                  const percentageB = b.report.correctQuestionAnswerStudentPercentage || 0;
                  return percentageA - percentageB;
                });
                return (
                  <Accordion
                    key={index}
                    style={{
                      background: '#5F98D9 0% 0% no-repeat padding-box',
                      borderRadius: '5px 5px 0px 0px',
                      marginBottom: index === accordionData?.concepts?.length - 1 ? 0 : 7
                    }}
                    expanded={expandedAccordion === index}
                    onChange={handleAccordionChange(index)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            style={{
                              color: '#fff',
                              textAlign: 'left',
                              fontSize: '16px',
                              textTransform: 'capitalize'
                            }}
                          >
                            {item?.concept}
                          </Typography>
                        </Grid>
                        <Grid
                          item xs={6}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography
                            style={{
                              color: '#fff',
                              textAlign: 'left',
                              fontSize: '16px'
                            }}
                          >
                            {Math.round(item?.percentageInQuiz)}%{/*-----roundoff----  */}
                          </Typography>
                          <Typography
                            style={{
                              color: '#fff',
                              fontSize: '16px'
                            }}
                          >
                            {expandedAccordion === index ? '' : 'Expand'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails style={{ backgroundColor: '#fff' }}>
                      {questions?.map((que, queInd) => {
                        return (
                          <Box
                            key={index + queInd}
                            style={{
                              backgroundColor: `${tabsValue === '3'
                                ? '#E6EBFF'
                                : '#eff1f9'
                                }`,
                              boxShadow: `${tabsValue === '4'
                                ? 'rgba(0, 0, 0, 0.15) 1.95px 3.95px 2.6px'
                                : '0 0 0 0'
                                }`,
                              marginTop: `${tabsValue === '3' ? '20px' : '25px'}`,
                              padding: '15px',
                              borderRadius: '5px'
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={8}>
                                <Typography
                                  style={{
                                    textAlign: 'left',
                                    color: '#222',
                                    fontSize: '14px',
                                    fontWeight: '600'
                                  }}
                                >
                                  {que?.question && (
                                    <div
                                      id="quest1"
                                      style={{
                                        fontWeight: 600,
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: `<div style="display: flex;"><p style="color:black";">${queInd + 1}.</p> &nbsp; <div>${que?.question}</div></div>`
                                      }}
                                    >
                                    </div>
                                  )}
                                </Typography>
                                {(que?.questionType).toLowerCase() != "fill in the blanks" &&
                                  (que?.questionType).toLowerCase() != "match the following" &&
                                  <>
                                    <Typography
                                      style={{
                                        textAlign: 'left',
                                        color: '#222',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        margin: '10px 0'
                                      }}
                                    >
                                      List Of Answers
                                    </Typography>
                                    <ul className="custom-list">
                                      {
                                        que?.listOfAnswers[0]?.map((opt, i) => {
                                          return (
                                            // opt?.answerKey !== undefined ?
                                            //   <li
                                            //     key={`${i + 1}.${opt?.answerKey}`}
                                            //     className={opt?.correctAnswer ? "active" : ""}
                                            //   >
                                            //     {`${i + 1}.${opt?.answerKey}`}
                                            //   </li>
                                            //   : opt?.answers !== undefined ?
                                            //   <li
                                            //     key={`${i + 1}.${opt?.answers}`}
                                            //     className={opt?.correctAnswer ? "active" : ""}
                                            //   >
                                            //   {`${i + 1}.${opt?.answers}`}
                                            //   </li>
                                            //   : ''
                                            <li
                                              key={`${i + 1}.${opt?.answerKey}`}
                                              className={opt?.correctAnswer ? "active" : ""}
                                              dangerouslySetInnerHTML={{
                                                // __html: `<div style="display: flex; align-items: baseline;">${i + 1}.&nbsp; ${opt?.htmlTag}</div>`
                                                __html: `<div style="display: flex; align-items: baseline;">${indexToAlphabet(i)}.&nbsp; ${opt?.htmlTag}</div>`
                                              }}
                                            >
                                            </li>
                                          )
                                        }
                                        )}
                                    </ul>
                                  </>
                                }
                                {((que?.questionType).toLowerCase() == "fill in the blanks") &&
                                  <>
                                    <Typography
                                      style={{
                                        textAlign: 'left',
                                        color: '#222',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        margin: '10px 0'
                                      }}
                                    >
                                      Answers
                                    </Typography>
                                    <ul className="custom-list mtf-list">
                                      {que?.listOfAnswers[0]?.map((opt, i) => (
                                        // opt?.answerKey !== undefined ? (
                                        //   <li
                                        //     key={`${i + 1}.${opt?.answerKey}`}
                                        //     className={"active"}
                                        //   >
                                        //     {opt?.answerKey}
                                        //   </li>
                                        // ) : opt?.answers !== undefined ? (
                                        //   <li
                                        //     key={`${i + 1}.${opt?.answers}`}
                                        //     className={"active"}
                                        //   >
                                        //     {opt?.answers}
                                        //   </li>
                                        // ) : null
                                        opt?.htmlTag ? (
                                          <li
                                            key={`${i + 1}.${opt?.answerKey}`}
                                            className={"active"}
                                            dangerouslySetInnerHTML={{
                                              __html: `<div style="display: flex; align-items: flex-end;">${opt?.htmlTag}</div>`
                                            }}
                                          >
                                          </li>
                                        ) : (
                                          <li
                                            key={`${i + 1}.${opt?.answerKey}`}
                                            className={"active"}
                                            dangerouslySetInnerHTML={{
                                              // __html: `<div style="display: flex; align-items: flex-end;">${i + 1}.&nbsp; ${opt?.htmlTagJumbled}</div>`
                                              __html: `<div style="display: flex; align-items: flex-end;">">${indexToAlphabet(i)}.&nbsp; ${opt?.htmlTagJumbled}</div>`
                                            }}
                                          >
                                          </li>
                                        )
                                      ))}
                                    </ul>
                                  </>
                                }
                              </Grid>
                              <Grid item xs={4}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item xs={12}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'end',
                                      alignItems: 'end',
                                      gap: '10px'
                                    }}
                                  >
                                    {/* <Typography style={{fontSize: '14px', color: '#222', fontWeight: '600', textAlign: 'left'}}>{que?.mapName}</Typography> */}
                                    {que?.map &&
                                      <div>
                                        <img
                                          src={que?.map}
                                          alt="Reflection Image"
                                          style={{ width: '160px', height: '80px', objectFit: 'contain', cursor: 'pointer' }}
                                          onClick={() => handleImageClick(que?.map)}
                                        />
                                        {showModal && (
                                          <div
                                            className="modal-overlay"
                                            onClick={handleCloseModal}
                                          >
                                            <div className="modal-content">
                                              <img src={imageToShow} alt="Reflection Image" />
                                              <span
                                                className="close-icon"
                                                onClick={handleCloseModal}
                                              >
                                                &times;
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            {que?.report &&
                              <div className={classes.gridContainer}>
                                <Grid container spacing={0}>
                                  <Grid item xs={12 / 5} className={classes.gridItem}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '5px'
                                      }}
                                    >
                                      <img
                                        src={StudentPercentageSvg}
                                        alt="Stduent Percentage"
                                      />
                                    </div>
                                    <Typography
                                      style={{
                                        fontSize: '12px',
                                        color: '#222',
                                        textAlign: 'center'
                                      }}
                                    >
                                      Percentage of students who gave correct answers
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: '16px',
                                        color: '#222',
                                        textAlign: 'center',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      {Math.round(que?.report?.correctQuestionAnswerStudentPercentage) || que?.report?.correctQuestionAnswerStudentPercentage === 0 ? `${Math.round(que?.report?.correctQuestionAnswerStudentPercentage)} %` : "N/A"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12 / 5} className={classes.gridItem}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '5px'
                                      }}
                                    >
                                      <img
                                        src={CorrectOptionSvg}
                                        alt="Correct Option"
                                      />
                                    </div>
                                    <Typography
                                      style={{
                                        fontSize: '12px',
                                        color: '#222',
                                        textAlign: 'center'
                                      }}
                                    >
                                      Correct Option
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: '16px',
                                        color: '#222',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        marginTop: '15px',
                                      }}
                                    >
                                      <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        flexWrap: 'wrap'
                                      }}>
                                        {(que?.questionType).toLowerCase() != "fill in the blanks" &&
                                          (que?.questionType).toLowerCase() != "match the following" &&
                                          que?.listOfAnswers?.flat()?.filter(item => item?.correctAnswer === true)?.map((ans, i, arr) => {
                                            console.log(item.questions, que?.listOfAnswers, ans)
                                            return (
                                              <div
                                                key={i}
                                                style={{ padding: '0 5px' }}
                                              >
                                                {ans?.option === 'A'
                                                  ? "A"
                                                  : ans?.option === 'B'
                                                    ? 'B'
                                                    : ans?.option === 'C'
                                                      ? 'C'
                                                      : ans?.option === 'D'
                                                        ? 'D'
                                                        : ans?.option === 'E'
                                                          ? 'E'
                                                          : "N/A"
                                                }{i < arr.length - 1 ? ', ' : ''}
                                              </div>
                                            )
                                          })}
                                        {(que?.questionType).toLowerCase() == "fill in the blanks" &&
                                          que?.listOfAnswers?.flat()?.map((ans, i, arr) => {
                                            return (
                                              <div
                                                key={i}
                                                style={{ padding: '0 5px' }}
                                              >
                                                {ans?.answerKey}{i < arr.length - 1 ? ', ' : ''}
                                              </div>
                                            )
                                          })}
                                        {(que?.questionType).toLowerCase() == "match the following" &&
                                          <button
                                            style={{
                                              textTransform: 'capitalize',
                                              color: '#222222',
                                              fontFamily: `"Poppins",sans-serif`,
                                              fontWeight: 600,
                                              padding: '5px 10px',
                                              background: 'transparent',
                                              border: 'none',
                                              cursor: 'pointer'
                                            }}
                                          >
                                            Refer Image
                                          </button>
                                        }
                                      </div>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12 / 5} className={classes.gridItem}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '5px'
                                      }}
                                    >
                                      <img
                                        src={AttemptRateSvg}
                                        alt="Attempt Rate"
                                      />
                                    </div>
                                    <Typography
                                      style={{
                                        fontSize: '12px',
                                        color: '#222',
                                        textAlign: 'center'
                                      }}
                                    >
                                      Attempt rate
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: '16px',
                                        color: '#222',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        marginTop: '15px'
                                      }}
                                    >
                                      {que?.report?.quizAttemptRate ||
                                        que?.report?.quizAttemptRate === 0 ? `${que?.report?.quizAttemptRate} %` : "N/A"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12 / 5} className={classes.gridItem}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '5px'
                                      }}
                                    >
                                      <img
                                        src={StudentsAttemptedSvg}
                                        alt="Students Attempted"
                                      />
                                    </div>
                                    <Typography
                                      style={{
                                        fontSize: '12px',
                                        color: '#222',
                                        textAlign: 'center'
                                      }}
                                    >
                                      No. of Students attempted
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: '16px',
                                        color: '#222',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        marginTop: '15px'
                                      }}
                                    >
                                      {que?.report?.studentQuestionAttemptedCount ||
                                        que?.report?.studentQuestionAttemptedCount === 0 ? que?.report?.studentQuestionAttemptedCount : "N/A"}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item xs={12 / 5}
                                    className={classes.gridItem}
                                    style={{ borderRight: 'none' }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '5px'
                                      }}
                                    >
                                      <img
                                        src={OptionMostStudentChose}
                                        alt="Option Most Student Chose"
                                      />
                                    </div>
                                    <Typography
                                      style={{
                                        fontSize: '12px',
                                        color: '#222',
                                        textAlign: 'center'
                                      }}
                                    >
                                      {tabsValue === '3'
                                        ? 'Wrong answer most frequently given'
                                        : 'Wrong answer most frequently given'
                                      }
                                    </Typography>
                                    {tabsValue === '3'
                                      && ((que?.questionType).toLowerCase() !== 'muti select question' && (que?.questionType).toLowerCase() !== 'muti choice question') &&
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          color: '#222',
                                          textAlign: 'center',
                                          fontWeight: 'bold',
                                          marginTop: '15px'
                                        }}
                                      >
                                        {que?.report?.mostWrongOptionChoseStudentCount ? que?.report?.mostWrongOptionChoseStudentCount : 'N/A'}
                                      </Typography>
                                    }
                                    {tabsValue === '3'
                                      && ((que?.questionType).toLowerCase() === 'muti select question' || (que?.questionType).toLowerCase() === 'muti choice question') &&
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          color: '#222',
                                          textAlign: 'center',
                                          fontWeight: 'bold',
                                          marginTop: '15px'
                                        }}
                                      >
                                        {que?.report?.mostOptionChoseStudentCount !== null
                                          ? que?.report?.mostOptionChoseStudentCount === 'A'
                                            ? 1
                                            : que?.report?.mostOptionChoseStudentCount === 'B'
                                              ? 2
                                              : que?.report?.mostOptionChoseStudentCount === 'C'
                                                ? 3
                                                : que?.report?.mostOptionChoseStudentCount === 'D'
                                                  ? 4
                                                  : que?.report?.mostOptionChoseStudentCount === 'E'
                                                    ? 5
                                                    : que?.report?.mostWrongOptionChoseStudentCount === 'F'
                                                      ? 6
                                                      : 'N/A'
                                          : 'N/A'}
                                      </Typography>
                                    }
                                    {tabsValue === '4'
                                      && ((que?.questionType).toLowerCase() !== 'muti select question' && (que?.questionType).toLowerCase() !== 'muti choice question') &&
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          color: '#222',
                                          textAlign: 'center',
                                          fontWeight: 'bold',
                                          marginTop: '15px'
                                        }}
                                      >
                                        {que?.report?.mostWrongOptionChoseStudentCount ? que?.report?.mostWrongOptionChoseStudentCount : 'N/A'}
                                      </Typography>
                                    }
                                    {tabsValue === '4'
                                      && ((que?.questionType).toLowerCase() === 'muti select question' || (que?.questionType).toLowerCase() === 'muti choice question') &&
                                      <Typography
                                        style={{
                                          fontSize: '16px',
                                          color: '#222',
                                          textAlign: 'center',
                                          fontWeight: 'bold',
                                          marginTop: '15px'
                                        }}
                                      >
                                        {que?.report?.mostWrongOptionChoseStudentCount !== null
                                          ? que?.report?.mostWrongOptionChoseStudentCount === 'A'
                                            ? 1
                                            : que?.report?.mostWrongOptionChoseStudentCount === 'B'
                                              ? 2
                                              : que?.report?.mostWrongOptionChoseStudentCount === 'C'
                                                ? 3
                                                : que?.report?.mostWrongOptionChoseStudentCount === 'D'
                                                  ? 4
                                                  : que?.report?.mostWrongOptionChoseStudentCount === 'E'
                                                    ? 5
                                                    : que?.report?.mostWrongOptionChoseStudentCount === 'F'
                                                      ? 6
                                                      : 'N/A'
                                          : 'N/A'}
                                      </Typography>
                                    }
                                  </Grid>
                                </Grid>
                              </div>
                            }
                            {que?.explanation &&
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Typography style={{ color: '#FD8C00', fontSize: '13px', textAlign: 'left', marginTop: '10px', fontWeight: 600 }}>Explanation</Typography>
                                  <Typography style={{ color: '#222', fontSize: '13px', textAlign: 'left', marginTop: '5px' }} dangerouslySetInnerHTML={{ __html: `${que?.explanation}` }}></Typography>
                                </Grid>
                              </Grid>
                            }
                          </Box>
                        )
                      })}
                    </AccordionDetails>
                  </Accordion>
                )
              })}
              {accordionData?.caseStudy?.length > 0 &&
                <Accordion
                  style={{
                    background: '#173c83 0% 0% no-repeat padding-box',
                    borderRadius: '5px 5px 0px 0px',
                    marginTop: accordionData?.concepts?.length > 0 ? 7 : 0
                  }}
                  onChange={handleCaseStudyAccordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          style={{
                            color: '#fff',
                            textAlign: 'left',
                            fontSize: '16px'
                          }}
                        >
                          {"CASE STUDY"}
                        </Typography>
                      </Grid>
                      <Grid
                        item xs={6}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography
                          style={{
                            color: '#fff',
                            textAlign: 'left',
                            fontSize: '16px'
                          }}
                        >
                          {Math.round(accordionData?.percentageInQuizForCaseStudy)}%
                        </Typography>
                        <Typography
                          style={{
                            color: '#fff',
                            fontSize: '16px'
                          }}
                        >
                          {caseStudyAccordion ? '' : 'Expand'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: '#fff' }}>
                    {accordionData?.caseStudy?.map((item, itemInd) => {
                      return (
                        <React.Fragment key={itemInd}>
                          <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                            <Grid item xs={12} sx={{ padding: '0px !important', marginLeft: '16px !important' }}>
                              <div className="casestudy_class_average">
                                <div className="class_average">
                                  <Typography
                                    style={{
                                      fontWeight: 600,
                                      color: '#333',
                                      fontSize: '16px',
                                      fontFamily: 'Poppins, sans-serif'
                                    }}
                                  >
                                    Class Average : {item?.parentClassAverage}%
                                  </Typography>
                                  {item?.taxonomyPercentageDto?.length > 0 && item?.taxonomyPercentageDto?.map(taxonomy => {
                                    return (<Typography
                                      style={{
                                        fontWeight: 600,
                                        color: '#333',
                                        fontSize: '16px',
                                        fontFamily: 'Poppins, sans-serif'
                                      }}
                                    >
                                      {taxonomy?.taxonomyName} : {taxonomy?.percentage} %
                                    </Typography>)
                                  })}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={8}>
                              <Typography
                                style={{
                                  textAlign: 'left',
                                  color: '#222',
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  marginTop: 20
                                }}
                              >
                                {item?.caseStudyQuestion && (
                                  <div
                                    style={{ fontWeight: 600 }}
                                    dangerouslySetInnerHTML={{ __html: `<div style="display: flex; align-items: flex-end">${itemInd + 1}. &nbsp; ${item?.caseStudyQuestion}</div>` }}
                                  >
                                  </div>
                                )}
                              </Typography>
                              <Grid item xs={12} sx={{ marginLeft: '20px' }}>
                                <Typography
                                  style={{
                                    color: '#222',
                                    fontSize: '13px',
                                    textAlign: 'left',
                                    marginTop: '5px'
                                  }}
                                  dangerouslySetInnerHTML={{ __html: `${item?.caseStudyExplanation}` }}
                                >
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid container spacing={2}>
                                <Grid
                                  item xs={12}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'end',
                                    gap: '10px'
                                  }}
                                >
                                  {item?.map && <div style={{ marginTop: 20 }}>
                                    <img
                                      src={item?.map}
                                      alt="Reflection Image"
                                      style={{ width: '160px', height: '80px', objectFit: 'contain', cursor: 'pointer' }}
                                      onClick={() => handleImageClick(item?.map)}
                                    />
                                    {showModal && (
                                      <div className="modal-overlay" onClick={handleCloseModal}>
                                        <div className="modal-content">
                                          <img src={imageToShow} alt="Reflection Image" />
                                          <span className="close-icon" onClick={handleCloseModal}>&times;</span>
                                        </div>
                                      </div>
                                    )}
                                  </div>}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {item?.subQuestion?.map((que, queInd) => {
                            return (
                              <Box
                                key={"case" + queInd}
                                style={{
                                  backgroundColor: '#E6EBFF',
                                  marginTop: '20px',
                                  padding: '15px',
                                  borderRadius: '5px'
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={8}>
                                    <Typography
                                      style={{
                                        textAlign: 'left',
                                        color: '#222',
                                        fontSize: '14px',
                                        fontWeight: '600'
                                      }}
                                    >
                                      {que?.question && (
                                        // <div
                                        //   id="quest22"
                                        //   style={{ fontWeight: 600 }}
                                        //   dangerouslySetInnerHTML={{ __html: `<div style="display: flex; align-items: flex-end">${queInd + 1}. &nbsp; ${que?.question}</div>` }}
                                        // >
                                        // </div>
                                        <div
                                          id="quest22"
                                          style={{ fontWeight: 600, display: 'flex' }}
                                        >
                                          <span style={{ display: 'inline-block' }}>{queInd + 1}.&nbsp;</span>
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: que?.question,
                                            }}
                                            style={{ display: 'inline-block' }}
                                          />
                                          </div>
                                      )}
                                    </Typography>
                                    {(que?.questionType).toLowerCase() != "fill in the blanks" &&
                                      (que?.questionType).toLowerCase() != "match the following" &&
                                      <>
                                        <Typography
                                          style={{
                                            textAlign: 'left',
                                            color: '#222',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            marginTop: '10px'
                                          }}
                                        >
                                          List Of Answers
                                        </Typography>
                                        <ul className="custom-list">
                                          {que?.listOfAnswers?.flat()?.map((opt, i) => {
                                            return (
                                              // opt?.answerKey !== undefined ?
                                              // <li
                                              //   key={`${i + 1}.${opt?.answerKey}`}
                                              //   className={opt?.correctAnswer ? "active" : ""}
                                              // >
                                              //   {`${i + 1}.${opt?.answerKey}`}
                                              // </li>
                                              // : opt?.answers !== undefined ?
                                              // <li
                                              //   key={`${i + 1}.${opt?.answers}`}
                                              //   className={opt?.correctAnswer ? "active" : ""}
                                              // >
                                              // {`${i + 1}.${opt?.answers}`}
                                              // </li>
                                              // : ''
                                              <li
                                                key={`${i + 1}.${opt?.answerKey}`}
                                                className={opt?.correctAnswer ? "active" : ""}
                                                dangerouslySetInnerHTML={{
                                                  // __html: `<div style="display: flex; align-items: baseline;">${i + 1}.&nbsp; ${opt?.htmlTag}</div>`
                                                  __html: `<div style="display: flex; align-items: baseline;">">${indexToAlphabet(i)}.&nbsp; ${opt?.htmlTag}</div>`
                                                }}
                                              >
                                              </li>
                                            )
                                          }
                                          )}
                                        </ul>
                                      </>
                                    }
                                    {((que?.questionType).toLowerCase() == "fill in the blanks") &&
                                      <>
                                        <Typography
                                          style={{
                                            textAlign: 'left',
                                            color: '#222',
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            margin: '10px 0'
                                          }}
                                        >
                                          Answers
                                        </Typography>
                                        <ul className="custom-list mtf-list">
                                          {que?.listOfAnswers[0]?.map((opt, i) => (
                                            // opt?.answerKey !== undefined ? (
                                            //   <li
                                            //     key={`${i + 1}.${opt?.answerKey}`}
                                            //     className={"active"}
                                            //   >
                                            //     {opt?.answerKey}
                                            //   </li>
                                            // ) : opt?.answers !== undefined ? (
                                            //   <li
                                            //     key={`${i + 1}.${opt?.answers}`}
                                            //     className={"active"}
                                            //   >
                                            //     {opt?.answers}
                                            //   </li>
                                            // ) : null
                                            opt?.htmlTag ? (
                                              <li
                                                key={`${i + 1}.${opt?.answerKey}`}
                                                className={"active"}
                                                dangerouslySetInnerHTML={{
                                                  __html: `<div style="display: flex; align-items: flex-end;">${opt?.htmlTag}</div>`
                                                }}
                                              >
                                              </li>
                                            ) : (
                                              <li
                                                key={`${i + 1}.${opt?.answerKey}`}
                                                className={"active"}
                                                dangerouslySetInnerHTML={{
                                                  // __html: `<div style="display: flex; align-items: flex-end;">${i + 1}.&nbsp; ${opt?.htmlTagJumbled}</div>`
                                                  __html: `<div style="display: flex; align-items: flex-end;">">${indexToAlphabet(i)}.&nbsp; ${opt?.htmlTagJumbled}</div>`
                                                }}
                                              >
                                              </li>
                                            )
                                          ))}
                                        </ul>
                                      </>
                                    }
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Grid container spacing={2}>
                                      <Grid
                                        item xs={12}
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'end',
                                          alignItems: 'end',
                                          gap: '10px'
                                        }}
                                      >
                                        {que?.map && <div>
                                          <img
                                            src={que?.map}
                                            alt="Reflection Image"
                                            style={{ width: '160px', height: '80px', objectFit: 'contain', cursor: 'pointer' }}
                                            onClick={() => handleImageClick(que?.map)}
                                          />
                                          {showModal && (
                                            <div className="modal-overlay" onClick={handleCloseModal}>
                                              <div className="modal-content">
                                                <img src={imageToShow} alt="Reflection Image" />
                                                <span className="close-icon" onClick={handleCloseModal}>&times;</span>
                                              </div>
                                            </div>
                                          )}
                                        </div>}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {que?.report &&
                                  <div className={classes.gridContainer}>
                                    <Grid container spacing={0}>
                                      <Grid
                                        item xs={12 / 5}
                                        className={classes.gridItem}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: '5px'
                                          }}
                                        >
                                          <img
                                            src={StudentPercentageSvg}
                                            alt="Stduent Percentage"
                                          />
                                        </div>
                                        <Typography
                                          style={{
                                            fontSize: '12px',
                                            color: '#222',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Percentage of students who gave correct answers
                                        </Typography>
                                        <Typography
                                          style={{
                                            fontSize: '16px',
                                            color: '#222',
                                            textAlign: 'center',
                                            fontWeight: 'bold'
                                          }}
                                        >
                                          {Math.round(que?.report?.correctQuestionAnswerStudentPercentage) || que?.report?.correctQuestionAnswerStudentPercentage === 0 ? `${Math.round(que?.report?.correctQuestionAnswerStudentPercentage)} %` : "N/A"}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item xs={12 / 5}
                                        className={classes.gridItem}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: '5px'
                                          }}
                                        >
                                          <img
                                            src={CorrectOptionSvg}
                                            alt="Correct Option"
                                          />
                                        </div>
                                        <Typography
                                          style={{
                                            fontSize: '12px',
                                            color: '#222',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Correct Option
                                        </Typography>
                                        <Typography
                                          style={{
                                            fontSize: '16px',
                                            color: '#222',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            marginTop: '15px'
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              justifyContent: 'center',
                                              flexWrap: 'wrap'
                                            }}
                                          >
                                            {(que?.questionType).toLowerCase() != "fill in the blanks" &&
                                              (que?.questionType).toLowerCase() != "match the following" &&
                                              que?.listOfAnswers?.flat()?.filter(item => item?.correctAnswer === true)?.map((ans, i, arr) => {
                                                return (
                                                  <div
                                                    key={i}
                                                    style={{ padding: '0 5px' }}
                                                  >
                                                    {ans?.option === 'A'
                                                      ? "A"
                                                      : ans?.option === 'B'
                                                        ? 'B'
                                                        : ans?.option === 'C'
                                                          ? 'C'
                                                          : ans?.option === 'D'
                                                            ? 'D'
                                                            : ans?.option === 'E'
                                                              ? 'E'
                                                              : "N/A"
                                                    }{i < arr.length - 1 ? ', ' : ''}
                                                  </div>
                                                )
                                              })}
                                            {(que?.questionType).toLowerCase() == "fill in the blanks" &&
                                              que?.listOfAnswers?.flat()?.map((ans, i, arr) => {
                                                return (
                                                  <div
                                                    key={i}
                                                    style={{ padding: '0 5px' }}
                                                  >
                                                    {ans?.answerKey}{i < arr.length - 1 ? ', ' : ''}
                                                  </div>
                                                )
                                              })}
                                            {(que?.questionType).toLowerCase() == "match the following" &&
                                              <button
                                                style={{
                                                  textTransform: 'capitalize',
                                                  color: '#222222',
                                                  fontFamily: `"Poppins",sans-serif`,
                                                  fontWeight: 600,
                                                  padding: '5px 10px',
                                                  background: 'transparent',
                                                  border: 'none',
                                                  cursor: 'pointer'
                                                }}
                                              >
                                                Refer Image
                                              </button>
                                            }
                                          </div>
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item xs={12 / 5}
                                        className={classes.gridItem}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: '5px'
                                          }}
                                        >
                                          <img
                                            src={AttemptRateSvg}
                                            alt="Attempt Rate"
                                          />
                                        </div>
                                        <Typography
                                          style={{
                                            fontSize: '12px',
                                            color: '#222',
                                            textAlign: 'center'
                                          }}
                                        >
                                          Attempt rate
                                        </Typography>
                                        <Typography
                                          style={{
                                            fontSize: '16px',
                                            color: '#222',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            marginTop: '15px'
                                          }}
                                        >
                                          {que?.report?.quizAttemptRate ||
                                            que?.report?.quizAttemptRate === 0 ? `${que?.report?.quizAttemptRate} %` : "N/A"}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item xs={12 / 5}
                                        className={classes.gridItem}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: '5px'
                                          }}
                                        >
                                          <img
                                            src={StudentsAttemptedSvg}
                                            alt="Students Attempted"
                                          />
                                        </div>
                                        <Typography
                                          style={{
                                            fontSize: '12px',
                                            color: '#222',
                                            textAlign: 'center'
                                          }}
                                        >
                                          No. of Students attempted
                                        </Typography>
                                        <Typography
                                          style={{
                                            fontSize: '16px',
                                            color: '#222',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            marginTop: '15px'
                                          }}
                                        >
                                          {que?.report?.studentQuestionAttemptedCount ||
                                            que?.report?.studentQuestionAttemptedCount === 0 ? que?.report?.studentQuestionAttemptedCount : "N/A"}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item xs={12 / 5}
                                        className={classes.gridItem}
                                        style={{ borderRight: 'none' }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: '5px'
                                          }}
                                        >
                                          <img
                                            src={OptionMostStudentChose}
                                            alt="Option Most Student Chose"
                                          />
                                        </div>
                                        <Typography
                                          style={{
                                            fontSize: '12px',
                                            color: '#222',
                                            textAlign: 'center'
                                          }}
                                        >
                                          {tabsValue === '3'
                                            ? 'Wrong answer most frequently given'
                                            : 'Wrong answer most frequently given'}
                                        </Typography>
                                        {tabsValue === '3'
                                          && ((que?.questionType).toLowerCase() !== 'muti select question' && (que?.questionType).toLowerCase() !== 'muti choice question') &&
                                          <Typography
                                            style={{
                                              fontSize: '16px',
                                              color: '#222',
                                              textAlign: 'center',
                                              fontWeight: 'bold',
                                              marginTop: '15px'
                                            }}
                                          >
                                            {que?.report?.mostWrongOptionChoseStudentCount ? que?.report?.mostWrongOptionChoseStudentCount : 'N/A'}
                                          </Typography>
                                        }
                                        {tabsValue === '3'
                                          && ((que?.questionType).toLowerCase() === 'muti select question' || (que?.questionType).toLowerCase() === 'muti choice question') &&
                                          <Typography
                                            style={{
                                              fontSize: '16px',
                                              color: '#222',
                                              textAlign: 'center',
                                              fontWeight: 'bold',
                                              marginTop: '15px'
                                            }}
                                          >
                                            {que?.report?.mostOptionChoseStudentCount !== null
                                              ? que?.report?.mostOptionChoseStudentCount === 'A'
                                                ? 1
                                                : que?.report?.mostOptionChoseStudentCount === 'B'
                                                  ? 2
                                                  : que?.report?.mostOptionChoseStudentCount === 'C'
                                                    ? 3
                                                    : que?.report?.mostOptionChoseStudentCount === 'D'
                                                      ? 4
                                                      : que?.report?.mostOptionChoseStudentCount === 'E'
                                                        ? 5
                                                        : que?.report?.mostWrongOptionChoseStudentCount === 'F'
                                                          ? 6
                                                          : 'N/A'
                                              : 'N/A'}
                                          </Typography>
                                        }
                                        {tabsValue === '4'
                                          && ((que?.questionType).toLowerCase() !== 'muti select question' && (que?.questionType).toLowerCase() !== 'muti choice question') &&
                                          <Typography
                                            style={{
                                              fontSize: '16px',
                                              color: '#222',
                                              textAlign: 'center',
                                              fontWeight: 'bold',
                                              marginTop: '15px'
                                            }}
                                          >
                                            {que?.report?.mostWrongOptionChoseStudentCount ? que?.report?.mostWrongOptionChoseStudentCount : 'N/A'}
                                          </Typography>
                                        }
                                        {tabsValue === '4'
                                          && ((que?.questionType).toLowerCase() === 'muti select question' || (que?.questionType).toLowerCase() === 'muti choice question') &&
                                          <Typography
                                            style={{
                                              fontSize: '16px',
                                              color: '#222',
                                              textAlign: 'center',
                                              fontWeight: 'bold',
                                              marginTop: '15px'
                                            }}
                                          >
                                            {que?.report?.mostWrongOptionChoseStudentCount !== null
                                              ? que?.report?.mostWrongOptionChoseStudentCount === 'A'
                                                ? 1
                                                : que?.report?.mostWrongOptionChoseStudentCount === 'B'
                                                  ? 2
                                                  : que?.report?.mostWrongOptionChoseStudentCount === 'C'
                                                    ? 3
                                                    : que?.report?.mostWrongOptionChoseStudentCount === 'D'
                                                      ? 4
                                                      : que?.report?.mostWrongOptionChoseStudentCount === 'E'
                                                        ? 5
                                                        : que?.report?.mostWrongOptionChoseStudentCount === 'F'
                                                          ? 6
                                                          : 'N/A'
                                              : 'N/A'}
                                          </Typography>
                                        }
                                      </Grid>
                                    </Grid>
                                  </div>
                                }
                                {que?.explanation &&
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <Typography
                                        style={{
                                          color: '#FD8C00',
                                          fontSize: '13px',
                                          textAlign: 'left',
                                          marginTop: '10px',
                                          fontWeight: 600
                                        }}
                                      >
                                        Explanation
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: '#222',
                                          fontSize: '13px',
                                          textAlign: 'left',
                                          marginTop: '5px'
                                        }}
                                        dangerouslySetInnerHTML={{ __html: `${que?.explanation}` }}
                                      >
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                }
                              </Box>
                            )
                          })}
                        </React.Fragment>
                      )
                    })
                    }
                  </AccordionDetails>
                </Accordion>
              }
            </div>
          </>
        }
      </div>
    </>
  );
};

export default QuestionWisePerformance;
