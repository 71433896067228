import React, { useState } from "react";
import { Box, Button, Modal, TextField } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import { styled } from "@mui/material/styles";
// import { Editor } from "@tinymce/tinymce-react";
import Green100Button from "../../components/common/Green100Button";
import Yellow100Button from "../../components/common/Yellow100Button";
import TextEditor from '../../pages/js/QuestionBank/CommonComponents/TextEditor';
import axios from "axios";
import create_question_post from "../API_CONTROLLER/create-questions/question_post";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Alert,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import McqCreationQBSubjective from "../js/Subjective/McqCreationQBSubjective"
import FillInTheBlanks from "../js/Subjective/FillIntheBlanks"
import CreateCaseStudy from "../js/Subjective/CreateCaseStudy"
import MultiSelectQB from "../js/Subjective/MultiSelectQB"
import MatchTheFollowing from "../js/Subjective/MatchTheFollowing"
import SubjectiveText from "../js/Subjective/SubjectiveText"
import SubjectivePoliticalMap from "../js/Subjective/SubjectivePoliticalMap"
import SubjectivePhysicalMap from "../js/Subjective/SubjectivePhysicalMap"
import SubjectiveCompound from "../js/Subjective/SubjectiveCompound"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 900,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // p: 4,
  maxHeight: '70vh',
  borderRadius: "10px",
  overflowX: "hidden",
  overflowY: "auto"
};
const MarkButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  // minWidth: 100,
  backgroundColor: "rgb(76 72 133 / 81%)",
  // borderRadius: 150,
  border: "1px solid #757575",
  borderColor: "rgb(76 72 133 / 81%)",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  "&:hover": {
    color: "#fff",
    backgroundColor: "rgb(76 72 133 / 81%)",
  },
}));
const AddQuestionModal = ({ open, close, setShowMessage,
  selectedGrade,
  selectedSubject,
  selectedYear,
  selectedMonth,
  selectedQuestionData,
  selectedQuestionOptionData,
  setnewQuestion,
  getResponse,
  qstnpaperData,
  QstnPaperFormData,
  setShowErroMessage
}) => {
  console.log("0000000000000000000", selectedGrade, "!11", selectedSubject, selectedYear, selectedMonth, selectedQuestionData, qstnpaperData,
    QstnPaperFormData);
  console.log("1111111111111111111111", selectedQuestionData?.subjectiveType)
  const [markList, setMarkList] = useState([
    {
      id: Math.random(),
      step: "",
      marks: "",
    },
  ]);
  const [content, setContent] = useState({ content: "" });
  const [clearContent, setClearContent] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [questionContent, setQuestionContent] = useState({ content: "" });
  const [questionEditContent, setEditQuestionContent] = useState({ content: "" });
  const [answerKeyContent, setAnswerKeyContent] = useState({ content: "" });
  const [answerEditKeyContent, setEditAnswerKeyContent] = useState({ content: "" });
  const [imagePreview, setImagePreview] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [uploadImageName, setUploadImageName] = useState("");
  const [edit, setEdit] = useState(false);
  const [rootConcept, setrootConcept] = useState(false)
  const [selectedboard, setselectedboard] = useState(sessionStorage.getItem("boardId"));
  const [responseValue, setResponseValue] = useState();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errorModalDetails, setErrorModalDetails] = useState({ open: false, message: '' })
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrorMessage, setshowerrorMessage] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [newConcept, setNewConcept] = useState(false);
  const [goToQuestionBank, setGoToQuestionBank] = useState(false);
  const [disableTopFields, setDisableTopFields] = useState(false);
  const { id } = useParams();
  const addMarksHandler = () => {
    console.log(markList, "markList")
    setMarkList((prevState) => [
      ...prevState,
      { id: Math.random(), step: "", marks: "" },
    ]);
  };
  const showErrorMessage = (message) => {
    setErrorModalDetails({ open: true, message })
  }
  const closeErrorModal = () => { setErrorModalDetails({ open: false, message: '' }) }
  const removeMarkHandler = (id) => {
    const clone = [...markList];
    const newData = clone.filter((item) => item.id !== id);
    setMarkList(newData);
  };
  const contentHandler = (content) => {
    setContent({ content: content });
  };

  const callback = (res) => {
    console.log(res, res.status, ">>>>>>>>>>>>>>>>> response", res?.error == "504");
    console.log("iwillprovideownCallback in qddQuestionmodalObjective", res);
    if (res?.error == "504" || res.status == 400 || res.data?.errorCode == 500) {
      setShowerror(true);
      setshowerrorMessage(res?.data?.message || "check error");
      setTimeout(() => {
        setShowerror(false);
        setshowerrorMessage("");
      }, 5000);
    } else {
      setShowSuccessAlert(true);
      close();
      res.urlofimage = uploadImageName
      getResponse(res)
    }

  }


  const uploadHandler = (e) => {
    const file = e.target.files[0];
    console.log("file", file, e.target)
    setUploadedFile(file);

    setUploadImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
    var fileMode = new File([file], file.name, { type: file.type });
    const formData = new FormData();
    formData.append("file", fileMode);
    console.log("fileMode", fileMode)

    const file_upload_post = async () => {
      let token = sessionStorage.getItem("token");
      let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=QUESTIONS`, formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (response) => {
            console.log("response------", response.data.data)
            setUploadImageName(response.data.data)
          }
        )
        .catch(err => console.log(err))
    }
    file_upload_post();

  };
  const stepHandler = (value, id) => {
    console.log(value, id, "stepHandlerstepHandler", markList)
    if (id) {
      const clone = [...markList];
      const findIndex = markList.findIndex((item) => item.id === id);
      if (findIndex !== -1) {
        clone[findIndex].step = value;
        setMarkList(clone);
      }
    }
  };
  const markHandler = (value, id) => {
    console.log("markHandler", value, id, markList)
    if (id) {
      const clone = [...markList];
      const findIndex = markList.findIndex((item) => item.id === id);
      console.log("findIndex", findIndex)
      if (findIndex !== -1) {
        clone[findIndex].marks = value;
        console.log("clone", clone)
        setMarkList(clone);
      }
    }
  };
  const onQuestionHandler = (content) => {
    console.log("content", content)
    setQuestionContent({ content: content });
  }
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="Subjective-box">
        <div
          style={{
            width: "100%",
            padding: "20px",
            background: "rgb(76 72 133 / 81%)",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            color: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>Type of question for {selectedQuestionData?.marks} marks questions</div>
          <CloseOutlinedIcon
            style={{ fontSize: "30", cursor: "pointer" }}
            onClick={close}
          />
        </div>
        {
          console.log(selectedQuestionData?.questionType, "**************************",selectedQuestionData?.subjectiveType)
        }


        {selectedQuestionData?.questionType === "FILL" || selectedQuestionData?.questionTypeCode === "FILL" ? (
          <FillInTheBlanks
            setNewConcept={setNewConcept}
            setGoToQuestionBank={setGoToQuestionBank}
            setDisableTopFields={setDisableTopFields}
            questionData={responseValue}
            fromViewAllQuestion={id}
            selectedGrade={selectedGrade}
            getResponse={getResponse}
            selectedQuestionData={selectedQuestionData}
          />
        ) : selectedQuestionData?.questionType === "Multi Choice Question" || selectedQuestionData?.questionTypeCode === "MCQ" ? (
          <McqCreationQBSubjective
            setNewConcept={setNewConcept}
            setGoToQuestionBank={setGoToQuestionBank}
            setDisableTopFields={setDisableTopFields}
            questionData={responseValue}
            fromViewAllQuestion={id}
            selectedGrade={selectedGrade}
            getResponse={getResponse}
            selectedQuestionData={selectedQuestionData}
          />
        ) : selectedQuestionData?.questionType === "CASE" || selectedQuestionData?.questionTypeCode === "CASE" ? (
          <CreateCaseStudy
            setNewConcept={setNewConcept}
            setGoToQuestionBank={setGoToQuestionBank}
            setDisableTopFields={setDisableTopFields}
            questionData={responseValue}
            fromViewAllQuestion={id}
            selectedGrade={selectedGrade}
            getResponse={getResponse}
            selectedQuestionData={selectedQuestionData}
          />
        ) : selectedQuestionData?.questionType === "MSQ" || selectedQuestionData?.questionTypeCode === "MSQ" ? (
          <MultiSelectQB
            setNewConcept={setNewConcept}
            setGoToQuestionBank={setGoToQuestionBank}
            setDisableTopFields={setDisableTopFields}
            questionData={responseValue}
            fromViewAllQuestion={id}
            selectedGrade={selectedGrade}
            getResponse={getResponse}
            selectedQuestionData={selectedQuestionData}
          />
        ) : selectedQuestionData?.questionType === "MATCH" || selectedQuestionData?.questionTypeCode === "MATCH" ? (
          <MatchTheFollowing
            setNewConcept={setNewConcept}
            setGoToQuestionBank={setGoToQuestionBank}
            setDisableTopFields={setDisableTopFields}
            questionData={responseValue}
            fromViewAllQuestion={id}
            selectedGrade={selectedGrade}
            getResponse={getResponse}
            selectedQuestionData={selectedQuestionData}
          />
        ) : selectedQuestionData?.subjectiveType === "Simple" && (selectedQuestionData?.questionType === "TEXT" || selectedQuestionData?.questionTypeCode === "TEXT") ? (
          <SubjectiveText
            setNewConcept={setNewConcept}
            setGoToQuestionBank={setGoToQuestionBank}
            setDisableTopFields={setDisableTopFields}
            questionData={responseValue}
            fromViewAllQuestion={id}
            selectedGrade={selectedGrade}
            getResponse={getResponse}
            selectedQuestionData={selectedQuestionData}
            setShowErroMessage={setShowErroMessage}
          />
        ) : selectedQuestionData?.subjectiveType === "Simple" && (selectedQuestionData?.questionType === "MAP_POLITICAL" || selectedQuestionData?.questionTypeCode === "MAP_POLITICAL") ? (
          <SubjectivePoliticalMap
            setNewConcept={setNewConcept}
            setGoToQuestionBank={setGoToQuestionBank}
            setDisableTopFields={setDisableTopFields}
            questionData={responseValue}
            fromViewAllQuestion={id}
            selectedGrade={selectedGrade}
            getResponse={getResponse}
            selectedQuestionData={selectedQuestionData}
          />
        ) : (selectedQuestionData?.subjectiveType === "Simple" && (selectedQuestionData?.questionType === "MAP_PHYSICAL" || selectedQuestionData?.questionTypeCode === "MAP_PHYSICAL")) ? (
          <SubjectivePhysicalMap
            setNewConcept={setNewConcept}
            setGoToQuestionBank={setGoToQuestionBank}
            setDisableTopFields={setDisableTopFields}
            questionData={responseValue}
            fromViewAllQuestion={id}
            selectedGrade={selectedGrade}
            getResponse={getResponse}
            selectedQuestionData={selectedQuestionData}
          />
        ) :
          selectedQuestionData?.subjectiveType === "Compound" || selectedQuestionData?.subjectiveType === "COMPOUND" ?
            (
              <SubjectiveCompound
                setNewConcept={setNewConcept}
                setGoToQuestionBank={setGoToQuestionBank}
                setDisableTopFields={setDisableTopFields}
                questionData={responseValue}
                fromViewAllQuestion={id}
                selectedGrade={selectedGrade}
                getResponse={getResponse}
                selectedQuestionData={selectedQuestionData}
              />
            ) : (
              <>Nothing matches</>
            )}

      </Box>
    </Modal>
  );
};
export default AddQuestionModal;
