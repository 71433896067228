import * as yup from "yup";

export const validationSchema = yup.object().shape({
  homeworkName: yup.string().required("Homework Name is required"),
  description: yup.string().required("Description is required"),
});

export const tableHead = [
  { title: "#" },
  { title: "Homework Name" },
  { title: "Description" },
  { title: "Assign Date" },
  { title: "Last Submission Date" },
  { title: "Status" },
  { title: "Active" },
  { title: "Action" },
];

export const tableHeadStudent = [
  { title: "#" },
  { title: "Homework Name" },
  { title: "Description" },
  { title: "Assign Date" },
  { title: "Last Submission Date" },
  { title: "Status" },
];

export const AssignDateStructure = (value) => {
  const assignDates = value.split("/");
  const newAssignDate =
    assignDates[2] + "-" + assignDates[1] + "-" + assignDates[0];
  return newAssignDate;
};

export const AssignSubmitStructure = (value1) => {
  const submissionDates = value1.split("/");
  const newSubmissionDate =
    submissionDates[2] + "-" + submissionDates[1] + "-" + submissionDates[0];
  return newSubmissionDate;
};
