import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
// import icon from "../../../../img/successpopup.png";

function TeacherSuccessModal({ open, onClose,successMessage }) {
  const handleOverlayClick = (e) => {};
  return (
    <ModalOverlay isOpen={open} onClick={handleOverlayClick}>
      <ModalWrapper>
        <SucessIcon>{/* <img src={icon} alt="sucess" /> */}</SucessIcon>
        {/* <Title>{sectionNames.map((v,i)=>{
          return(
            <span>{v}{sectionNames.length - 1 != i && ","}</span>
          )
        })}</Title> */}
        <QuizName>{successMessage == "Added all assigned subjects for teacher successfully" ? (<p>Profile Updated Successfully</p>) :
        successMessage == "section, subTopic already assign to particular teacher" || "section already assign to particular teacher" ?
        (<p>Access is Already Provided for this Staff.</p>) :(
          ""
        )
      }
        </QuizName>
        <TotalQuestion></TotalQuestion>
        <Button onClick={onClose}>Ok</Button>
      </ModalWrapper>
    </ModalOverlay>
  );
}

export default TeacherSuccessModal;
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.isOpen ? "block" : "none")};
  z-index: 100;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 70%;
  max-width: 400px;
`;
const SucessIcon = styled.span`
  width: 70px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  img {
    display: block;
    width: 100%;
  }
`;

const Title = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
`;
const QuizName = styled.p`
  font-size: 16px;
  text-align: center;
  color: #fd8c00;
`;
const TotalQuestion = styled.p`
  font-size: 16px;
  text-align: center;
  color: #fd8c00;
`;
const Button = styled.span`
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fd8c00;
  color: #fff;
  font-size: 16px;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 20px;
  box-shadow: 0px 5px 6px #fd8c0080;
  cursor: pointer;
`;
