import React from 'react'
import {Menu} from '@mui/material'
import { MenuItem } from '@mui/material'
import { useState } from 'react'
import {Button} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
const ButtonMenu = () => {
    const [anchorElm, setAnchorElm] = useState(null)
    const [open, setOpen] = useState(false)
    const handleClick = (event) => {
        setAnchorElm(event.currentTarget);
        setOpen(true)

    };
    const handleClose = ( ) => {
        setAnchorElm(null);
        setOpen(false)
    }

    return (
        //
    <div>
        <Button variant="outlined" size='small' onClick={handleClick} sx={{borderRadius:"16px"}}  endIcon={<KeyboardArrowDown />}>
            Board
            </Button>
        <Menu
             anchorEl={anchorElm} open={open} onClose={handleClose}
         >
        
        <MenuItem onClick={handleClose}>CBSE</MenuItem>
        <MenuItem onClick={handleClose}>ICSE</MenuItem>
        <MenuItem onClick={handleClose}>None</MenuItem>
        </Menu>
    </div>
  )
}

export default ButtonMenu