import axios from "axios";

async function BranchControllerIdGet(setbranchInputs,setplanid,setNum) {
  let token = sessionStorage.getItem("token");
  let branchid = sessionStorage.getItem("branchid");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${branchid}`,
    {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response)=>{
      if(response.data.data){
        setplanid(response.data.data.plans[0].planId)
        sessionStorage.setItem("planid", response.data.data.plans[0].planId);
        sessionStorage.setItem("boardid", response.data.data.boardId);
        sessionStorage.setItem("boardName", response.data.data.board);

        setbranchInputs(response.data.data);
        setNum(response.data.data.phoneNumber)
      }


    })
    .catch((err)=>{
      //console.log(err)
     })
}

export default BranchControllerIdGet;
