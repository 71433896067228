export const tableHead = [
  { title: "#" },
  { title: "Paper Title" },
  { title: "Paper Type" },
  { title: "Grade" },
  { title: "Subject" },
  { title: "Total Marks" },
  { title: "Total Questions" },
  { title: "Time" },
  // { title: "Active" },
  { title: "Action" },
];

export const tableHeadRejected = [
  { title: "#" },
  { title: "Paper Title" },
  { title: "Paper Type" },
  { title: "Grade" },
  { title: "Subject" },
  { title: "Total Marks" },
  { title: "Total Questions" },
  { title: "Time" },
  // { title: "Active" },
  { title: "Reason" },
  { title: "Action" },
];
