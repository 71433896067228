import React, { useEffect, useState } from "react";
import CircleProgress from "../../../../components/CircleProgress/CircleProgress";
import "./teacher_dashboard.css";
import { fetchQuizzesTotal } from "../Apis/TeacherDashboard";
import {
  extractTextAfterExclamation,
  extractTextIncludingExclamation,
} from "../../../../utils/helper";

const TeacherCardV1 = ({ img, label, count, onLabelClick }) => {
  const styles = {
    cursor: "pointer",
  };

  return (
    <div className="teacher-top-section-card">
      <div>
        <img src={img}></img>
      </div>
      <div>
        <div>
          <p className="teacher-grade-count-label">
            <a className="cursorPointer" onClick={onLabelClick} style={onLabelClick ? styles : null}>
              {label}
            </a>
          </p>
        </div>
        <div>
          <p className="teacher-grade-count">{count}</p>
        </div>
      </div>
    </div>
  );
};

export default TeacherCardV1;
