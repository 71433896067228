import React from "react";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import useLastModifiedAt from "../../../hooks/quiz/useLastModifiedAt";

const UpdatedAt = () => {
	const { data } = useLastModifiedAt();
	return (
		<div className={dashboardContentStyles.dashboard_last_updated}>
			<p>Last Update: {data?.data}</p>
		</div>
	);
};

export default UpdatedAt;
