import React, { useRef, useState, useEffect } from "react";
import "./HorizontallyScrollableContainerV3.css";
import { Tooltip } from "@mui/material";
import { isNullOrUndefined, truncateString } from "../../utils/helper";
import useShowScrollButtons from "../../hooks/quiz/useShowScrollButtons";
import SemiCircleProgressBarTeacher from "../../pages/js/Teacher/Dashboard/Components/CircleProgressTeacher";
import DashboardEmpherModal from "../DashboardEmpherCategory/DashboardEmpherModal";
const HorizontallyScrollableContainerV2 = ({
  data,
  displayEmberCategoryMessage,
  onLabelClick,
}) => {
  //To do : Mkae it Reusable
  const containerRef = useRef(null);
  const { showPrevButton, showNextButton } = useShowScrollButtons(containerRef);

  const [showEmberModal, setShowEmberModal] = useState(false);
  const [showEmberGradeMessage, setShowEmberGradeMessage] = useState(false);
  const [emberModalData, setEmberModalData] = useState([]);
  const [emberTotalProgress, setEmberemberTotalProgress] = useState("");

  const scroll = (scrollOffset) => {
    containerRef.current.scrollLeft += scrollOffset;
  };

  const clickableLabelStyle = {
    color: "#EE5D45",
    textDecoration: "underline",
    cursor: "pointer",
  };

  const onLabelClickModal = (e, item) => {
    if (item?.studentPercentageInEmber !== 0) {
      setShowEmberModal(true);
      setEmberModalData(item?.emberStudents);
      setEmberemberTotalProgress(item?.studentPercentageInEmber);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "296px",
        marginTop: "10px",
        background: "#fff",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      <div className="scrl-container-top">
        <p className="scrl-container-sub-heading">Chapterwise Performance</p>
        <div className="scrl-container-legend">
          <div className="scrl-container-indicator">
            <div className="clr-box" style={{ background: "#5CA2F2" }} />
            <p className="indicator-label"> Average %</p>
          </div>
          <div className="scrl-container-indicator">
            <div className="clr-box" style={{ background: "#64C2A6" }} />
            <p className="indicator-label">Global %</p>
          </div>
        </div>
      </div>
      <div className="bar-chart-container">
        {showPrevButton ? (
          <div className="round-btn" onClick={() => scroll(-150)}>
            {"<"}
          </div>
        ) : null}

        <div ref={containerRef} className="bar-chart">
          {data && data?.map((item, index) => (
            <>
              <div className="cs-chart-bar-pair">
                <div className="cs-chart-bar-pair-inner">
                  {/* <div
                    className="donut"
                    onClick={(event) => onLabelClickModal(event, item)}
                  >
                    <SemiCircleProgressBarTeacher
                      progressValue={item?.studentPercentageInEmber}
                      size={10}
                    />
                  </div> */}

                  <div
                    className="bar"
                    style={{
                      background: "#5CA2F2",
                      marginRight: "7px",
                      height: `${
                        item?.scorePercentage > 100
                          ? 100
                          : item?.scorePercentage
                      }%`,
                    }}
                  >
                    <p className="bar-label" style={{ left: "-5px" }}>
                      {item?.scorePercentage.toFixed(1)}%
                    </p>
                  </div>
                  <div
                    className="bar"
                    style={{
                      background: "#64C2A6",
                      height: `${
                        item?.averagePercentage > 100
                          ? 100
                          : item?.averagePercentage
                      }%`,
                    }}
                  >
                    <p className="bar-label" style={{ left: "5px" }}>
                      {item?.averagePercentage.toFixed(1)}%
                    </p>
                  </div>
                  <div
                    style={{
                      background: "#000",
                      height: "1px",
                      width: "90px",
                      position: "absolute",
                      bottom: "-2px",
                    }}
                  />
                  <Tooltip title={item?.chapterName}>
                    <span
                      className="cs-chart-pair-label"
                      // onClick={() => {
                      //   if (item?.scorePercentage < 40) {
                      //     displayEmberCategoryMessage();
                      //   }
                      // }}
                      style={item?.scorePercentage < 40 ? null : null}
                    >
                      {truncateString(item?.chapterName, 10)}{" "}
                    </span>
                  </Tooltip>
                </div>
              </div>
            </>
          ))}
          <DashboardEmpherModal
            showMessageModal={showEmberModal}
            data={emberModalData}
            progressData={emberTotalProgress}
            onMessageModalClose={() => {
              setShowEmberModal(false);
            }}
            isEmberCategoryMessage={showEmberGradeMessage}
          />
        </div>
        {showNextButton ? (
          <div className="round-btn" onClick={() => scroll(150)}>
            {">"}
          </div>
        ) : null}
      </div>
      <div>
        <h5>Ember Category</h5>
      </div>
      <div className="ember-container">
        <div className="bar-chart">
          {data && data?.map((obj) => {
            return <div className="ember-box"> {obj?.emberStudents.length > 0 ? obj?.emberStudents?.map((elem, index) => {
              return <div className="ember-card-container">
                <div className="table">
                  <span style={{ paddingRight: index === 0 ? "9px" : "6px" }}>{index + 1}</span>
                  <Tooltip title={elem?.studentName}>
                    <span style={{ width: "75px" }}>{truncateString(elem?.studentName, 8)}</span>
                  </Tooltip>
                  <span>{elem?.emberPercentage + "%"}</span>
                </div>
              </div>
            }) : <div className="ember-card-container">
            <div className="table">
                <span style={{ width: "75px" }}>No Ember Category</span>
            </div>
          </div>}</div>
          })}
        </div>
      </div>
    </div>
  );
};

export default HorizontallyScrollableContainerV2;
