import React,{useState} from 'react'
import studentmodal from "./../../pages/css/StudentModal.module.css"
import { Box, Modal, Typography,  Button } from "@mui/material";
import YellowBorderHover from "../../components/common/YellowBorderHover";
import YellowButton from "../../components/common/yellowButton";
import success from "../../../src/img/successpopup.png"
import { styled } from "@mui/material/styles";



const SuccessModal = ({
    open,
    close,
    userType,
    handleOk,
    // addedItem,
  }) => {
    const [closeModal , setCloseModal] = useState(false);
    const CancelButton = styled(Button)(() => ({
      background: "#ffffff 0% 0% no-repeat padding-box",
border: "1px solid #7a7a7a",
background: "#FFFFFF 0% 0% no-repeat padding-box",
border: "1px solid #7A7A7A",
borderRadius: "21px",
opacity: 1,
width: "178px",
height: "40px",
marginRight: "15px",
color: "#7A7A7A",
"&:hover": {
  boxShadow: "1px 2px 3px 0px #FD8C00",
  borderColor: "#FD8C00",
    color: "#FD8C00"
},

    }));
   
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        // border: "2px solid #000",
        boxShadow: 24,
        borderRadius: 1,
        padding: '15px 30px',
      };
  return (
    // <div
    //     className={studentmodal.flex_container}
    //     id={studentmodal.modal}
    //     // style={showModal ? { display: "flex" } : { display: "none" }}
    //   >
    //     <div
    //       className={studentmodal.main_content}
    //       id={studentmodal.modal_content}
    //     >
    //       <span style={{ textAlign: "center" }}>
    //         <i style={{ color: 'orange', fontSize: '50px' }} class="fa-regular fa-circle-check"></i>
    //         {/* <img src={tickImage} alt="red checkmark" /> */}
    //       </span>

    //       <h5>{props.userType} added successfully</h5>
    //       <h6>
    //         {props.name}
    //       </h6>
    //       <div className={studentmodal.modal_buttons}>
    //         <button
    //           className={studentmodal.choosefile_button_1}
    //           id={studentmodal.choose_button_id_1}
    //           onClick={handleOk}
    //         >
    //           OK
    //         </button>
    //       </div>
    //     </div>
    //   </div>

    //   
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      
    >
      <Box sx={style}
      style={{
        padding:"35px 120px 35px 120px",
        
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div style={{ display: "flex", justifyContent: "center", paddingBottom: "10px"}}>
          <img src={success} alt="success" />
          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign:"center",
              }}
            >
              <p style={{ color:"#FD8C00"}}>{userType} Successfully added</p><br/>
              <p> Do you want to create {userType} Branch?</p><br/>
              {/* <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#FFA700",
                  padding: "4px",
                }}
              >
                {`"${addedItem}"`}
              </p> */}
              <div
                style={{
                  justifyContent: "flex-start",
                  left: "10px"
                }}
              >
                <CancelButton  onClick={() => close()}>
                  NOT NOW
                </CancelButton>

                <YellowButton onClick={() => handleOk()}>
                 <p style={{color: "white"}}>CREATE BRANCH</p> 
                </YellowButton>
                
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  )
}

export default SuccessModal