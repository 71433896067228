import React, { useEffect, useState } from 'react'
import { Box, Divider, Grid } from '@mui/material'
import axios from 'axios'
import GetLastModifiedAt from '../../../API/token-controller/GetLastModifiedAt'
import TopBar from '../../../../components/common/TopBar'
import FiltersBar from './FiltersBar'
import ContentDisplay from '../common/ContentDisplay'
import ChapterCard from './ChapterCard'
import { isNOTNullOrUndefined } from '../../../../utils/helper'
import { fetchAcademicYearData, fetchChapterListData, fetchGradeSectionList, fetchSubjectList, fetchTeacherData, fetchPlanDetails, fetchEnableAssignBtn, } from '../assignQuiz/api/fetchCalls'
import FullPageLoader from '../common/FullPageLoader'
import dashboardContentStyles from "./../../../css/dashboardContent.module.css"
import cdn_response_validator from "../../../../utils/CdnResponseValidator";

import { rightClickEnable, CDNBASEURL } from "../../../../../src/Serviceworker"

const TeachContentOptimised = () => {
  const [showDetail, setShowDetail] = useState([])
  const [updatedData, setUpdatedData] = useState([])
  const [subjectList, setSubjectList] = useState([])
  const [academicYearList, setAcademicYearList] = useState([])
  const [selectedAcademicYear, setSelectedAcademicYear] = useState('')
  const [selectedSubTopic, setSelectedSubTopic] = useState('')
  const [selectedGrade, setSelectedGrade] = useState('')
  const [selectedSubject, setSelectedSubject] = useState('')
  const [showSubTopic, setShowSubTopic] = useState(false);
  const token = sessionStorage.getItem('token')
  const [modifiedDate, setModifiedDate] = useState({})
  const [showContentModal, setShowContentModal] = useState(false)
  const [selectedChapterId, setSelectedChapterId] = useState('')
  const [selectedSection, setSelectedSection] = useState('')
  const [sectionList, setSectionList] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const userName = localStorage.getItem('userName')
  const role = localStorage.getItem('role')

  const [teacherId, setTeacherId] = useState('')
  const [gradeSection, setGradeSection] = useState([])
  const [apiData, setApiData] = useState([]);
  const [planData, setPlanData] = useState([]);

  const CDN_URL = `${CDNBASEURL}/`;
  



  useEffect(() => {
    firstRender()
    return () => {
      cleanupOnUnmount()
    }
  }, [token])

  const cleanupOnUnmount = () => {
    setSelectedGrade('')
    setSelectedSection('')
    setSelectedSubject('')
    sessionStorage.removeItem('sectionId')
    sessionStorage.removeItem('gradeId')
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const firstRender = async () => {
    setIsPageLoading(true)
    const teacherData = await fetchTeacherData(userName, role)

    sessionStorage.setItem('teacherId', teacherData.id)
    sessionStorage.setItem('boardId', teacherData.boardId)
    sessionStorage.setItem('schoolId', teacherData.school)
    sessionStorage.setItem('branchId', teacherData.branch)
    setTeacherId(teacherData)
    await GetLastModifiedAt(setModifiedDate)
    const gradeSectionList = await fetchGradeSectionList(teacherData.id)
    setGradeSection(gradeSectionList)
    const academic = await fetchAcademicYearData()
    setAcademicYearList(academic)
    const storedAcademicYearId = sessionStorage.getItem('academicYearId')
    if (storedAcademicYearId) {
      setSelectedAcademicYear(storedAcademicYearId)
    } else if (academic?.length > 0) {
      setSelectedAcademicYear(academic[0].id)
      sessionStorage.setItem('academicYearId', academic[0].id)
    }
    //auto population
    await autoPopulate(gradeSectionList, teacherData.id)
  }

  const autoPopulate = async (gradeSectionList, teacherId) => {
    fetchPlanDetails()
    fetchEnableAssignBtn()
    const gradeSectionFirstElement = gradeSectionList[0]
    const sectionfirstElement = gradeSectionFirstElement?.sections[0]
    let subjectParams = {
      gradeId: gradeSectionFirstElement?.id,
      sectionId: sectionfirstElement?.id,
      teacherId: teacherId,
      menuName: "TEACH",
      planId: sessionStorage.getItem("planId")
    }
    const subjectListFetched = await fetchSubjectList(subjectParams);
    // console.log(subjectListFetched)
    setSubjectList(subjectListFetched)
    setSectionList(gradeSectionFirstElement?.sections)
    setShowSubTopic(subjectListFetched.length > 0 ? subjectListFetched[0].hideSubtopics == true ? false : true : false)
    // console.log("initialload")
    setSelectedGrade(gradeSectionFirstElement?.id)
    setSelectedSection(sectionfirstElement?.id)
    setSelectedSubject(subjectListFetched[0]?.subjectId);
    console.log("subjectListFetched", subjectListFetched)
    // console.log("setSelectedSubTopic", subjectListFetched[0]?.subTopics[0]?.id)
    // setSelectedSubTopic(subjectListFetched[0]?.subTopics[0]?.id)

    let params = {
      subjectId: subjectListFetched[0]?.subjectId,
      gradeId: gradeSectionFirstElement?.id,
      subTopicId: subjectListFetched[0]?.subTopics[0]?.id
    };
    console.log("params", params, "subjectListFetched", subjectListFetched)
    const chaptersListFetched = await fetchChapterListData(params)
    setUpdatedData(chaptersListFetched)
    sessionStorage.setItem('gradeId', gradeSectionFirstElement?.id)
    sessionStorage.setItem('subjectId', subjectListFetched[0]?.subjectId)
    sessionStorage.setItem('sectionId', sectionfirstElement?.id)
    if (subjectListFetched[0]?.hideSubtopics) {

    } else {
      console.log("setting subtopic in session here")
      sessionStorage.setItem('subTopicId', subjectListFetched[0]?.subTopics[0]?.id)//reg maths hide
    }

    setIsPageLoading(false)

    const menuData = await fetchDataUsingChapterId(selectedChapterId)
    // const menuData =  await fetchDataFromCDN();
    // console.log("menu data >>> ", menuData)
    const contentPlanDetails = await fetchContentPlanDetails();


  }


  useEffect(async () => {
    if (!isPageLoading) {
      if (
        isNOTNullOrUndefined(selectedGrade) &&
        isNOTNullOrUndefined(selectedSection) &&
        isNOTNullOrUndefined(selectedSubject)
      ) {
        var Vsublist = subjectList.find(subject => subject.subjectId === selectedSubject);
        console.log("Vsublist", Vsublist);
        let params = {};
        if (Vsublist && Vsublist.hideSubtopics) {
          params = {
            subjectId: selectedSubject,
            gradeId: selectedGrade,
            // subTopicId: selectedSubTopic
          };
        } else {
          params = {
            subjectId: selectedSubject,
            gradeId: selectedGrade,
            subTopicId: selectedSubTopic
          };
        }

        console.log("params", params, "selectedSubject", selectedSubject, subjectList)
        const chaptersfetched = await fetchChapterListData(params)
        setUpdatedData(chaptersfetched)

        const contentPlanDetails = await fetchContentPlanDetails();

      }
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedSubTopic])

  //////////

  useEffect(() => {
    const fethPlanData = async () => {
      const branchId = sessionStorage.getItem("branchId")
      if (branchId) {
        const planDatas = await fetchPlanDetails();
        setPlanData(planDatas);
      }
    };

    fethPlanData();
  }, []);

  useEffect(() => {
    const fetchTheData = async () => {
      var list = await fetchEnableAssignBtn(selectedGrade);
      // console.log(list, "27-6-24 required");
      // list = list?.filter(v => v.skilledSubject === true) || [];
      // console.log(list)
      setApiData(list);
    };

    fetchTheData();
  }, [planData, selectedGrade, selectedSubject]);

  const subjectID = selectedSubject;
  const idArray = apiData.map((item) => item.subjectId)
  const activeStatusArray = apiData.map((item) => item.active);
  const foundIndex = idArray.indexOf(subjectID);
  // console.log(foundIndex,idArray,subjectID);
  // console.log(activeStatusArray)

  let isActive = false;
  if (foundIndex !== -1) {
    // console.log("activeStatusArray[foundIndex] " + "_" + activeStatusArray[foundIndex])
    isActive = activeStatusArray[foundIndex];
    sessionStorage.setItem("isActiveBoo", isActive)
  } else {
    // console.log(isActive)
  }

  const fetchDataUsingChapterId = async chapterId => {
    // console.log("api/content/teacher-chapter/page?pageSize")
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/page?pageSize=1000&sortOrder=true&reviewStatus=APPROVED&chapterId=${chapterId}&includeStudentFile=true`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )
    return response?.data?.data?.data || []
  }

  const fetchDataFromCDN = async () => {

    ///new CDN call
    const response_newcontentdata = await fetch(`${CDN_URL}/teacher-CDN/teacher_content_chapter_${selectedChapterId}.json`);
    if (!response_newcontentdata.ok) {
      throw new Error('Network response was not ok');
    }
    let contentDataResponseNew = await response_newcontentdata.json();

    console.log("contentDataResponseNew >>> ", contentDataResponseNew)

    var response_iterator = cdn_response_validator(contentDataResponseNew);

    console.log("response_iterator >>> ", response_iterator)
    console.log("response_iterator >>> ", response_iterator.data)

    // console.log("documentTypesTeacherContent >>> ", response_iterator.documentTypesTeacherContent, response_iterator.data.documentTypesTeacherContent)

    return response_iterator.data.documentTypesTeacherContent;
  };

  const fetchContentPlanDetails = async () => {
    const planId = sessionStorage.getItem("planId");
    const bordId = sessionStorage.getItem("boardId");
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/template-screen?planId=${planId}&boardId=${bordId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const contentPlanDetails = response?.data?.data || [];
    console.log("planDetails >>> ", contentPlanDetails)

    const grade = contentPlanDetails.planGrades.find(grade => grade.gradeId === selectedGrade);

    const planTemplate = grade ? grade.planTemplates.find(template => template.subjectId === selectedSubject) : null;

    const wsDownload = planTemplate ? planTemplate.wsDownload : null;
    const wbDownload = planTemplate ? planTemplate.wbDownload : null;
    localStorage.setItem("wsDownload", wsDownload);
    localStorage.setItem("wbDownload", wbDownload);
    console.log("wsDownload,wbDownload >>> ", wsDownload, wbDownload)

    return response?.data?.data || [];
  };


  useEffect(async () => {
    if (selectedChapterId) {
      const menuData = await fetchDataUsingChapterId(selectedChapterId)
      // const menuData =  await fetchDataFromCDN();
      console.log("menu data >>> ", menuData)
      const contentPlanDetails = await fetchContentPlanDetails();


      //// CDN /////
      // if (menuData[0]?.length !== 0) {
      //    console.log("11111111111111",menuData)
      //   setShowDetail(menuData);
      //   setShowContentModal(true);
      // } else {
      //   console.log("222222222")
      //   setShowDetail([]);
      //   setShowContentModal(false);
      // }


      /// API call
      if (menuData?.length !== 0) {
        setShowDetail(menuData[0]?.documentTypes)
        setShowContentModal(true)
      } else {
        setShowDetail([])
      }
    }
  }, [selectedChapterId])

  const handleChangeGrade = e => {
    const selectedId = e.target.value
    setSelectedGrade(selectedId)
    const sections = gradeSection.find(i => i.id === selectedId)?.sections
    sessionStorage.setItem('gradeId', selectedId)
    setSelectedSubject('')
    setSelectedSection('')
    setSelectedSubTopic('')
    setSectionList(sections)
    sessionStorage.removeItem('sectionId')
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const handleChangeSection = async event => {
    const secId = event.target.value
    setSelectedSection(secId)
    setSelectedSubject('')
    setSelectedSubTopic("")
    let subjectParams = {
      gradeId: selectedGrade,
      sectionId: secId,
      menuName: "TEACH"
    }
    var subjectsFetchList = await fetchSubjectList(subjectParams);
    console.log("subjectsFetchList",subjectsFetchList)
    // subjectsFetchList = subjectsFetchList?.filter(v => v.skilledSubject === true) || [];
    // console.log("subjectsFetchList",subjectsFetchList)
    setSubjectList(subjectsFetchList)
    sessionStorage.setItem('sectionId', event.target.value)
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const handleChangeSubject = event => {
    console.log("handleChangeSubject", event)
    console.log("subjectList", subjectList)
    const subject = subjectList?.find(
      item => item.subjectId === event.target.value
    )
    console.log(subject)
    setSelectedSubject(event.target.value)
    setShowSubTopic(subject.hideSubtopics == true ? false : true);
    // console.log(subject.hideSubtopics == true ? false : true)
    // alert("tco")

    if (subject?.subTopics?.length > 0) {
      setSelectedSubTopic(subject?.subTopics[0]?.id)
      if (subject?.hideSubtopics) {

      } else {
        console.log("setting subtopic in session here", subject)
        sessionStorage.setItem('subTopicId', subject?.subTopics[0]?.id)
      }

    } else {
      setSelectedSubTopic("")
      sessionStorage.removeItem('subTopicId')
    }
    sessionStorage.setItem('subjectId', event.target.value)
  }

  const handleChangeSubTopic = event => {
    setSelectedSubTopic(event.target.value)
    sessionStorage.setItem('subTopicId', event.target.value)
  }

  const handleChangeAcademicYear = event => {
    setSelectedAcademicYear(event.target.value)
    sessionStorage.setItem('academicYearId', event.target.value)
  }

  const [query, setQuery] = useState('')
  const [isfetchingList, setisfetchingList] = useState(false)

  // if (isPageLoading) {
  //   return <FullPageLoader />
  // }
  return (
    <>
      {!showContentModal ? (
        <div style={{ width: '80%' }}>
          <TopBar
            title={'Home'}
            subSubTitle={'Teach'}
            modifiedDate={modifiedDate}
            nav1={'principal/dashboard'}
            role={role}
          />
          <div
            style={{
              marginTop: '10px',
              paddingRight: '33px',
              position: 'absolute',
              right: '15px',
              left: '15px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <FiltersBar
              handleChangeSection={handleChangeSection}
              subjectList={subjectList}
              gradeSection={gradeSection}
              selectedSubTopic={selectedSubTopic}
              selectedAcademicYear={selectedAcademicYear}
              selectedGrade={selectedGrade}
              handleChangeGrade={handleChangeGrade}
              handleChangeSubject={handleChangeSubject}
              handleChangeSubTopic={handleChangeSubTopic}
              handleChangeAcademicYear={handleChangeAcademicYear}
              academicYearList={academicYearList}
              searchHandler={e => setQuery(e.target.value.toLowerCase())}
              isError={isError}
              selectedSection={selectedSection}
              selectedSubject={selectedSubject}
              sectionList={sectionList}
              showSubTopic={showSubTopic}
            />
            <Divider />
            {isfetchingList ? (
              <div>Fetching ....</div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: 700,
                  width: '100%',
                  padding: '25px 20px',
                  overflow: 'auto',
                  scrollbarWidth:"thin"
                }}
              >
                {selectedGrade && selectedSection && selectedSubject ? (
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      {updatedData?.length > 0 ? (
                        updatedData
                          ?.filter(itm =>
                            itm.chapter.toLowerCase().includes(query)
                          )
                          .map((item, i) => (
                            <div
                              key={item.id}
                              onClick={() => setSelectedChapterId(item?.id)}
                              className={dashboardContentStyles.card}
                            >
                              <img
                                src={item?.thumbnailPath}
                                width={110}
                                alt={""}
                                // style={{ maxHeight: "138px" }}
                                className={dashboardContentStyles.az_quiz_card_img} />
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  color: "#423d5c",
                                }}
                              >
                                {item?.chapter}
                              </div>
                            </div>
                            // <ChapterCard
                            //   key={i}
                            //   item={item}
                            //   onChapterClick={() => {
                            //     setSelectedChapterId(item?.chapterId)
                            //     fetchEnableAssignBtn(selectedGrade)
                            //   }}
                            // />
                          ))
                      ) : (
                        <div>No data for the selected filters</div>
                      )}
                    </Grid>
                  </Box>
                ) : (
                  <div>Please Select Grade , Section and Subject</div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <ContentDisplay
          studyData={showDetail}
          // studyData={dummy2}
          menuType={'teach'}
          closeModal={() => {
            setShowContentModal(false)
            setSelectedChapterId('')
            setShowDetail([])
          }}
          // selectedChapterId={chapterIdDummy}
          selectedChapterId={selectedChapterId}
          pageName={'Teach'}
          showSubTopic={showSubTopic}
        />
      )}
    </>
  )
}
export default TeachContentOptimised
