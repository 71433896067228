import axios from 'axios'

async function StudentsControllerIdGet(setStudentIdData, setStudentInputs) {
  let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students/${sessionStorage.getItem("studentid")}`,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setStudentIdData(json.data.data)
        setStudentInputs(json.data.data)
    })
    .catch(err=>console.log(err))
}

export default StudentsControllerIdGet;
