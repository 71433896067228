import React, { useEffect, useState } from "react";
import axios from "axios";
import dashboardContentStyles from "./../../css/dashboardContent.module.css";
import chapterStyles from "./../../css/createChapter.module.css";
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";
import { Link, useNavigate, useLocation } from "react-router-dom";
import sortIcon from "../../../../src/pages/img/sort_1.png";
import add_icon from "../../../../src/img/add-circle-outline.svg";
import dashHome from "../../../../src/img/dashHome.svg";
import { Box, Modal, Typography } from '@material-ui/core';
import YellowButton from "../../../components/common/yellowButton";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";



const Passport_3 = (props) => {

    const [modifiedDate, setModifiedDate] = useState({});
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');


    const navigate = useNavigate();
    console.log("@@@@ passport 3  @@@@@@")
    console.log("grade >>> ", sessionStorage.getItem("gradeName"));

    useEffect(() => {
        //fetchReadingBookData();
        GetLastModifiedAt(setModifiedDate);
    }, [])

    const [formData, setFormData] = useState({

        title: '',
        author: '',
        maincharacters: '',
        otherCharacters: '',
        favcharacters: '',
        // rating: '',
        rating: 0,
        storyFeedback: '',
        characterFeedback: '',
        changes: '',
    });

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value
    //     });
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleClose = () => {
        setOpen(false);
        props.onClosePage();
        // navigate('/dashboard/reading%20passport');
        // navigate(0);
    };

    const handleRating = (rate) => {
        setFormData(prevState => ({
            ...prevState,
            rating: rate
        }));
        setError('');
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        console.log("values >>> ", formData);

        if (formData.rating === 0) {
            setError('Please select a star rating.');
            return;
        }

        const payload =
        {
            "studentId": sessionStorage.getItem("studentId"),
            "studentName": sessionStorage.getItem("studentName"),
            "schoolId": sessionStorage.getItem("schoolId"),
            "gradeId": sessionStorage.getItem("gradeId"),
            "grade": sessionStorage.getItem("gradeName"),
            "sectionId": sessionStorage.getItem("sectionId"),
            "section": sessionStorage.getItem("sectionName"),
            "titleOfBook": formData.title,
            "author": formData.author,
            "bookDetails": {
                "book": {
                    // "characters": formData.otherCharacters,
                    "favcharacters": formData.favcharacters,
                    "maincharacters": formData.maincharacters,
                    "storyFeedback": formData.storyFeedback,
                    "otherCharacters": formData.otherCharacters,
                    "characterFeedback": formData.characterFeedback,
                    "changes": formData.changes,


                }
            },
            "reviewRating": {
                "bookRating": {
                    "rating": formData.rating
                }
            },
            "imageUploaded": "",

        };

        console.log("payload >>> ", payload);
        let token = sessionStorage.getItem("token");
        axios
            .post(
                `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/reading_submissions/add`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {

                console.log("res >>> ", res.data);
                setOpen(true)

                // setFormData({
                //     title: '',
                //     author: '',
                //     characters: '',
                //     favcharacters: '',
                //     maincharacters: '',

                // });

            })
            .catch((error) => {
                console.log(error.response.data.message);

            });
    };

    const renderStars = () => {
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <span
                    key={i}
                    onClick={() => handleRating(i)}
                    style={{
                        cursor: 'pointer',
                        color: i <= formData.rating ? '#ffc107' : '#c0c2c5',
                        fontSize: '24px',
                        marginRight: '5px'
                    }}

                >
                    &#9733;
                </span>
            );
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        padding: '5px',
                        marginRight: '10px'
                    }}
                >
                    {stars}
                </div>
            </div>
        );
    };



    return (

        <div style={{ width: "100%" }}>
            <article>

                <div style={{ position: 'relative', margin: '14px' }}>
                    <span className={dashboardContentStyles.link_icon}>
                        <img
                            src={dashHome}
                            alt="no img"
                            className={dashboardContentStyles.az_menu_icon}
                        />
                    </span>
                    <span
                        id={`az-breadscrum-home`}
                        className={dashboardContentStyles.az_menu_text}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                            sessionStorage.removeItem("sideNavSubMenuNames");
                            navigate("/dashboard/template");
                        }}
                    >
                        Home
                    </span>
                    <span>
                        <i className="fa-solid fa-angle-right"
                            style={{ color: "#828282" }} />
                    </span>
                    <span>
                        <i className="fa-solid fa-angle-right"
                            style={{ color: "#828282" }} />
                    </span>
                    <span
                        className={dashboardContentStyles.az_menu_text}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            props.onClosePage();
                        }}
                    >
                        Passport
                    </span>
                    <span>
                        <i className="fa-solid fa-angle-right"
                            style={{ color: "#828282" }} />
                    </span>
                    <span>
                        <i className="fa-solid fa-angle-right"
                            style={{ color: "#828282" }} />
                    </span>
                    <span
                        className={dashboardContentStyles.az_selected_menu_text}
                    >
                        Review - Online
                    </span>
                </div>

                <div className={dashboardContentStyles.dashboard_last_updated}>
                    <p>Last Update: {modifiedDate.data}</p>
                </div>
            </article>

            <div style={{ margin: 'start', marginTop: '20px', marginLeft: '20px', marginRight: '50px', marginBottom: '20px' }}>
                <form onSubmit={handleSubmit}>

                    <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <label className="cursorText" htmlFor="title" style={{ flex: '1', marginRight: '10px' }}>Title*:</label>
                        <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} maxLength="50" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} required />
                        <p style={{ paddingLeft: '10px' }}>{formData.title.length}/50</p>
                    </div>

                    <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <label className="cursorText" htmlFor="maincharacters" style={{ flex: '1', marginRight: '10px' }}>Main Character*:</label>
                        <input type="text" id="maincharacters" name="maincharacters" value={formData.maincharacters} onChange={handleChange} maxLength="40" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} required />
                        <p style={{ paddingLeft: '10px' }}>{formData.maincharacters.length}/40</p>
                    </div>

                    {/* <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="maincharacters" style={{ display: 'block' }}>Main Character*:</label>
                        <textarea type="text" id="maincharacters" name="maincharacters" value={formData.maincharacters} onChange={handleChange} maxLength="40" style={{ width: '100%', height: '150px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }}  required />
                        <p style={{paddingLeft: '10px'}}>{formData.maincharacters.length}/40</p>
                    </div> */}

                    {/* <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <label htmlFor="storyFeedback" style={{ flex: '1', marginRight: '10px' }}>What I like/dislike about the story*:</label>
                        <input type="text" id="storyFeedback" name="storyFeedback" value={formData.storyFeedback} onChange={handleChange} maxLength="80" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} required />
                        <p style={{ paddingLeft: '10px' }}>{formData.storyFeedback.length}/80</p>
                    </div> */}



                    <div style={{ marginBottom: '15px', }}>
                        <label className="cursorText" htmlFor="storyFeedback" style={{ display: 'block' }}>What I like/dislike about the story*:</label>
                        <textarea id="storyFeedback" name="storyFeedback" value={formData.storyFeedback} onChange={handleChange} maxLength="80" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.storyFeedback.length}/80</p>
                    </div>



                    <div style={{ marginBottom: '15px', }}>
                        <label className="cursorText" htmlFor="otherCharacters" style={{ display: 'block' }}>Other Characters*:</label>
                        <textarea id="otherCharacters" name="otherCharacters" value={formData.otherCharacters} onChange={handleChange} maxLength="120" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.otherCharacters.length}/120</p>
                    </div>


                    <div style={{ marginBottom: '15px', }}>
                        <label className="cursorText" htmlFor="characterFeedback" style={{ display: 'block' }}>What I like/dislike about the Main Character*:</label>
                        <textarea id="characterFeedback" name="characterFeedback" value={formData.characterFeedback} onChange={handleChange} maxLength="400" style={{ width: '100%', height: '100px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.characterFeedback.length}/400</p>
                    </div>


                    <div style={{ marginBottom: '15px', }}>
                        <label className="cursorText" htmlFor="changes" style={{ display: 'block' }}>What changes I want to make to the story*:</label>
                        <textarea id="changes" name="changes" value={formData.changes} onChange={handleChange} maxLength="100" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.changes.length}/100</p>
                    </div>

                    {/* <div style={{ marginBottom: '15px', }}>
                         <label htmlFor="favcharacters" style={{ display: 'block' }}>Favorite Characters*:</label>
                         <textarea id="favcharacters" name="favcharacters" value={formData.favcharacters} onChange={handleChange} maxLength="25" style={{ width: '100%', height: '150px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} required></textarea>
                         <p style={{paddingLeft: '10px'}}>{formData.favcharacters.length}/25</p>
                    </div> */}
                    {/* 
                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="rating" style={{ display: 'block' }}>Star Value*:</label>
                        <input type="number" id="rating" name="rating" value={formData.rating} onChange={handleChange} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} required />
                    </div> */}

                    <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <label className="cursorText" htmlFor="rating" style={{ marginRight: '10px' }}>Star Value*:</label>
                        {renderStars()}
                    </div>

                    {error && <div style={{ color: 'red' }}>{error}</div>}

                    <button type="submit" style={{ background: '#403e75', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Submit</button>
                </form>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    bgcolor: "background.paper",
                    // border: "2px solid #000",
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 4
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div style={{ display: "flex", justifyContent: "center" }}>


                            <CheckCircleOutlinedIcon style={{ color: "#FFA700", fontSize: 130 }} />

                        </div>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                // flexDirection: "column",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    rowGap: "2rem",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        rowGap: "1.25rem",
                                    }}
                                >
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        Review submitted Successfully

                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        columnGap: "1rem",
                                    }}
                                >

                                    <YellowButton
                                        onClick={handleClose}
                                    >
                                        Ok
                                    </YellowButton>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </Box>
            </Modal>

        </div>



    );
};

export default Passport_3;
