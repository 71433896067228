import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import { SearchOutlined } from "@mui/icons-material";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
// import GradesControllerGetall from '../API_CONTROLLER/grades-controller/grades_controller_getall';
import gradeListGet from "../API/grade-section-mapping-controller/grade_get";
import associateChapter_get from "../API_CONTROLLER/associate-chapters-controller/associateChapters_get";
import { MenuProps } from "./Data";
import chapterStyles from "../css/createChapter.module.css";
import { makeStyles, Tooltip } from "@material-ui/core";

import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Grid,
  TextField,
  Paper,
  OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import dashHome from "../../../src/img/dashHome.svg";
import GetLastModifiedAt from "../API/administration-controller/GetLastModifiedAt";
import searchIcn from "../../../src/img/search.svg";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: '190px',
    fontSize: '10px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));

const AssociateConcept = () => {
  const [board, setBoard] = useState([]);
  const [subject, setSubject] = useState([]);
  const [gradesName, setGrades] = useState([]);
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const [chapters, setChapters] = useState([]);
  const [gradeInput, setGradeInput] = useState("");
  const [boardInput, setBoardInput] = useState("");
  const [subjectInput, setSubjectInput] = useState("");
  const [search, setSearch] = useState({
    search: "",
  });
  const classes = useStyles();


  const navigate = useNavigate();
  const handleSearch = (e) => {
    e.preventDefault();
    const search = {
      search: e.target.value,
    };
    associateChapter_get(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      boardInput
    );
  };

  useEffect(() => {
    if (subjectInput && gradeInput && boardInput) {
      associateChapter_get(
        setChapters,
        page,
        setPageDetail,
        // rowsInput,
        boardInput,
        gradeInput,
        subjectInput
      );
    }
  }, [subjectInput, gradeInput, boardInput]);
  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  // last update time ends
  // useEffect(() => {
  //   associateChapter_get(
  //     setChapters,
  //     page,
  //     setPageDetail,
  //     // rowsInput,
  //     boardInput,
  //     gradeInput,
  //     subjectInput
  //   );
  // }, [rowsInput, page])

  useEffect(() => {
    boards_controller_getall(setBoard);
    subjects_controller_getall(setSubject);
    gradeListGet(setGrades);
  }, []);

  const handleBoardChange = (e) => {
    setBoardInput(e.target.value);
    setGradeInput("");
    setSubjectInput("");
    setChapters([]);
  };

  const handleGradeChange = (e) => {
    setGradeInput(e.target.value);
    setSubjectInput("");
    setChapters([]);
  };
  const labelStyle = {
    fontSize: "14px",
    "&.Mui-focused": {
      color: "orange",
      fontWeight: 600,
      fontFamily: "Poppins",
      fontSize: "13px",
    },
  };

  //-------| Truncate the Chapter Name |-------//
  const maxLength = 20;

  return (
    <div style={{ width: "100%" }}>
      <div className="az_home_navigation_section" style={{ top: "67px" }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <p className="az_navigation_text"><span style={{ position: "relative" }}>
              <img style={{ position: "absolute", top: "1px" }}
                src={dashHome}
                alt="no img"
                width="18px"
                height="18px"

              /> </span>
              <span
                onClick={() => {
                  sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                  sessionStorage.removeItem("sideNavSubMenuNames");
                  navigate("/dashboard/template")
                }}
                className="az_navigation_content" style={{ marginLeft: "20px" }}>Home</span>
            </p>
          </div>
          <p>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
          </p>


          <p className="az_navigation_text cursorDefault"><span style={{ color: "#FFA500" }}>Associate concepts</span></p>

        </div>
        <p className="az_last_update_time">Last Update: {modifiedDate.data}</p>
      </div>
      {/* <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a>Associate concepts</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>
            Last Update: {modifiedDate.data}
          </p>
        </div>
      </article> */}
      <hr
        style={{
          width: "100%",
          position: "absolute",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <hr
          style={{
            top: "50px",
            width: "97%",
            position: "absolute",
            backgroundColor: "##dcdbe7",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#606060",
              columnGap: "0.5vw",
              marginTop: "15px",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "5px",
                marginRight: "8px",
              }}
            >
              <LibraryBooksRoundedIcon style={{ fontSize: "2.2vw" }} />
              <div
                style={{
                  fontSize: "1.1vw",
                  fontWeight: "600",
                }}
              >
                Associate Concepts
              </div>
            </div>
            <Box style={{ marginRight: "8px" }}>
              <FormControl sx={{ width: "113px", height: "36px" }} size="small">
                <InputLabel
                  className={chapterStyles.select_input}
                  sx={labelStyle}
                  id="board-select"
                >
                  *Boards
                </InputLabel>
                <Select
                  MenuProps={MenuProps}
                  style={{
                    borderRadius: 150,
                    background: "#fff",
                    fontSize: "14px !important",
                    height: "100%",
                  }}
                  value={boardInput}
                  input={
                    <OutlinedInput
                      label="Board"
                    />
                  }
                  labelId="demo-select-small"
                  id="demo-select-small"
                  onChange={(e) => handleBoardChange(e)}
                >
                  {board.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      className={dashboardContentStyles.menu_item}
                      id={`az-content-boarddropdown-${item.id}`}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: '#FFA500',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#FFA500',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}
                    >
                      <ListItemText sx={{
                        overflow: 'hidden !important',
                      }} className={classes.ellipsis} primary={item.board} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box style={{ marginRight: "8px" }}>
              <FormControl sx={{ width: "113px", height: "36px" }} size="small">
                <InputLabel
                  className={chapterStyles.select_input}
                  sx={labelStyle}
                  id="grade-select"
                >
                  Grade
                </InputLabel>
                <Select
                  disabled={!boardInput.length > 0}
                  value={boardInput ? gradeInput : ""}
                  style={{
                    borderRadius: 150,
                    background: "#fff",
                    fontSize: "14px !important",
                    height: "100%",
                  }}
                  input={
                    <OutlinedInput
                      label="Grade"
                    />
                  }
                  onChange={(e) => handleGradeChange(e)}
                >
                  {gradesName?.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      className={dashboardContentStyles.menu_item}
                      id={`az-content-gradedropdown-${item.id}`}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: '#FFA500',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#FFA500',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}
                    >
                      <ListItemText sx={{
                        overflow: 'hidden !important',
                      }} className={classes.ellipsis} primary={item.grade} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={{ width: "156px", height: "36px" }} size="small">
                <InputLabel
                  className={chapterStyles.select_input}
                  sx={labelStyle}
                  id="subject-select"
                >
                  Subject
                </InputLabel>
                <Select
                  disabled={!gradeInput.length > 0}
                  value={gradeInput ? subjectInput : ""}
                  style={{
                    borderRadius: 150,
                    background: "#fff",
                    fontSize: "14px !important",
                    height: "100%",
                  }}
                  input={
                    <OutlinedInput
                      label="Subject"
                    />
                  }
                  onChange={(e) => {
                    setSubjectInput(e.target.value);
                  }}
                >
                  {subject?.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      className={dashboardContentStyles.menu_item}
                      id={`az-contentadmin-subjectdropdown-${item.id}`}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: '#FFA500',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#FFA500',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}
                    >
                      <ListItemText sx={{
                        overflow: 'hidden !important',
                      }} className={classes.ellipsis} primary={item.subject} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "auto",
            width: "100%",
            padding: "25px 20px",
            overflow: "auto"
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}
            >
              {chapters.length > 0 ? (
                chapters
                  ?.filter(itm =>
                    itm.chapter.toLowerCase()
                  )
                  .map((item, i) => (
                    <div
                      key={item.chapterId}
                      // onClick={() => setSelectedChapterId(item?.id)}
                      className={dashboardContentStyles.card}
                      onClick={() => {
                        sessionStorage.setItem("board", item.board);
                        sessionStorage.setItem("boardId", item.boardId);
                        sessionStorage.setItem("grade", item.grade);
                        sessionStorage.setItem("gradeId", item.gradeId);
                        sessionStorage.setItem("subject", item.subject);
                        sessionStorage.setItem("subjectId", item.subjectId);
                        sessionStorage.setItem("chapter", item.chapter);
                        sessionStorage.setItem("chapterId", item.id);
                        navigate("/dashboard/AssociateCptDetails");
                      }}
                    >
                      <img
                        src={item?.thumbnailPath}
                        width={110}
                        alt={""}
                        // style={{ maxHeight: "138px" }}
                        className={dashboardContentStyles.az_quiz_card_img} />
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: "#423d5c",
                        }}
                      >
                        {item?.chapter}
                      </div>
                    </div>

                  ))
              ) : (
                <div>No data for the selected filters </div>
              )}
            </Grid>
          </Box>

        </div>
      </div>
    </div>
  );
};

export default AssociateConcept;
