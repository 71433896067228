import axios from 'axios'

function training_controller_put(trainingInputs){
    let token = sessionStorage.getItem("token");
    var item = {
        categoryId : trainingInputs.categoryId ,
        name: trainingInputs.name,
        reason: "Reject reason: incorrect data",
        reviewStatus : 'PENDING',
        subCategoryId : trainingInputs.subCategoryId,
        trainingType : "SELF" ,
    };

    let result = axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training/${sessionStorage.getItem("trainingId")}`,item, 
    {
        headers:{
            // "Content-Type" : "appliation/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
    })
    .catch(err => console.log(err))
}

export default training_controller_put ;