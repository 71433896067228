import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import TrainingStyles from "./../css/createTraining.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import Pagination1 from "./pagination1";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import training_category_controller_getall from "../API_CONTROLLER/training-category-controller/training_category_controller_getall";
import training_subcategory_controller_getall from "../API_CONTROLLER/training-category-controller/training_subcategory_controller_getall";
import training_subtraining_table_get from "../API_CONTROLLER/training-category-controller/training_subtraining_table_get";
import training_subtraining_table_post from "../API_CONTROLLER/training-category-controller/training_subtraining_table_post";
import training_table_post from "../API_CONTROLLER/training-controller/training_table_post";
import training_table_get from "../API_CONTROLLER/training-controller/training_table_get";
import training_get_by_id from "../API_CONTROLLER/training-controller/training_get_by_id";
import training_controller_put from "../API_CONTROLLER/training-controller/training_controller_put";
import training_get_modifieddate from "../API_CONTROLLER/training-controller/training_modifiedDate_get";
import dashHome from "../../../src/img/dashHome.svg";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";

import { SearchOutlined } from "@mui/icons-material";
import searchIcn from "../../../src/img/search.svg";

const CreateTraining = () => {
  const [modifiedDate, setModifiedDate] = useState({});

  const [traininglist, setTraininglist] = useState({});
  const [subtraininglist, setSubtraininglist] = useState({});
  const [trainingtableData, setTrainingtableData] = useState({});
  const [trainingnameField, setTrainingnameField] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();
  const [selectedSubCat, setSelectedsubcat] = useState({});
  const [name, setName] = useState("");
  const [showErr, setShowErr] = useState("");
  const [isErr, setIsErr] = useState(false);
  const [success, setSuccess] = useState({});
  const [isAddTraining, setIsAddTraining] = useState(true);

  const [trainingInputs, setTrainingInputs] = useState({
    categoryId: "",
    subCategoryId: "",
    reviewStatus: "PENDING",
    name: "",
  });

  const navigate = useNavigate();
  const prevLocation = useLocation();

  if (prevLocation.state === "edit") {
    setIsAddTraining(false);
    prevLocation.state = null;
  }
  // search section start
  const [search, setSearch] = useState({
    search: "",
  });

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };

  // pagination ends

  const handleSearch = (e) => {
    e.preventDefault();
    const search = {
      search: e.target.value,
    };
    training_table_get(
      setTrainingtableData,
      rowsInput,
      page,
      setPageDetail,
      search
    );
  };

  const handleTrainingInputs = (e) => {
    e.preventDefault();
    setTrainingInputs({
      ...trainingInputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateTraining = (e) => {
    e.preventDefault();
    for (const input in trainingInputs) {
      if (trainingInputs[input] == "") {
        setIsErr(true);
        setShowErr("Please enter all required values");
        return;
      }
    }
    training_table_post(trainingInputs);
    navigate("/dashboard/global%20training");
  };

  useEffect(() => {
    if (success.success === false) {
      setIsErr(true);
      setShowErr(success.message);
      return () => {};
    }
    if (success.success === true) {
      setIsErr(false);
    }
  }, [success]);

  useEffect(() => {
    if (search.search === "") {
      training_table_get(
        setTrainingtableData,
        rowsInput,
        page,
        setPageDetail,
        search
      );
      return () => {};
    }
  }, [rowsInput, search]);

  useEffect(() => {
    training_table_get(
      setTrainingtableData,
      rowsInput,
      page,
      setPageDetail,
      search
    );
  }, [rowsInput, page]);

  useEffect(() => {
    if (isAddTraining == false) {
      training_get_by_id(setTrainingInputs);
      return () => {};
    }
  }, []);

  useEffect(() => {
    training_category_controller_getall(setTraininglist);
    training_subcategory_controller_getall(setSubtraininglist);
    GetLastModifiedAt(setModifiedDate);
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
      <div className="az_home_navigation_section" style={{top:"75px"}}>
        <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}>
              <div style={{ display: "flex", alignItems: "flex-start"}}>
                  <p className="az_navigation_text"><span style={{position:"relative"}}>
                      <img style={{position: "absolute", top:"1px"}}
                        src={dashHome}
                        alt="no img"
                        width="18px"
                        height="18px"
                        
                      /> </span>
                      <span onClick={() => navigate("/dashboard/template")} className="az_navigation_content" style={{marginLeft:"20px"}}>Home</span>
                  </p>
              </div>
          <p>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
          </p>
          <p className="az_navigation_text"><span>Content Admin</span></p>
          <p>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#7A7A7A" }}
              ></i>
            </span>
          </p>
          <p><span style={{color: "#ffa500"}}>Create Training</span></p>
        </div>
        <p className="az_last_update_time">Last Update: {modifiedDate.data}</p>
      </div>

        <Paper
          style={{
            marginTop: "55px",
            marginLeft: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 20px",
            }}
          >
            <div className={TrainingStyles.training_container}>
              <div className={TrainingStyles.flex}>
                <FormControl
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px !important",
                      width: "270px !important",
                      borderRadius: "5px !important",
                    },
                  }}
                >
                  <InputLabel
                    className={TrainingStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Category Name
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name="categoryId"
                    value={trainingInputs.categoryId}
                    onChange={handleTrainingInputs}
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: "300px",
                      },
                    }}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {traininglist.length > 0 ? (
                      traininglist.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}>
                            <ListItemText primary={elem.category} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <FormControl
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px !important",
                      width: "270px !important",
                      borderRadius: "5px !important",
                    },
                  }} // sx={{ width: 300 }}
                >
                  <InputLabel
                    className={TrainingStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Sub Category Name
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name="subCategoryId"
                    value={trainingInputs.subCategoryId}
                    onChange={handleTrainingInputs}
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: "300px",
                      },
                    }}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {subtraininglist.length > 0 ? (
                      subtraininglist.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}>
                            <ListItemText primary={elem.subCategory} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <FormControl
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px !important",
                      width: "270px !important",
                      borderRadius: "5px !important",
                    },
                  }}
                  // sx={{ width: 300 }}
                >
                  <div>
                    <TextField
                      id="demo-helper-text-aligned-no-helper"
                      label="*Training Name"
                      name="name"
                      value={trainingInputs.name}
                      type="text"
                      onChange={handleTrainingInputs}
                    />
                  </div>
                </FormControl>
              </div>

              <div
                className={TrainingStyles.flex}
                style={{ marginTop: "40px" }}
              >
                <FormControl
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px !important",
                      width: "270px !important",
                      borderRadius: "5px !important",
                    },
                  }}
                  // sx={{ width: 300 }}
                >
                  <InputLabel
                    className={TrainingStyles.select_input}
                    id="demo-multiple-checkbox-label"
                  >
                    *Select Dependent
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      style: {
                        maxHeight: "300px",
                      },
                    }}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  ></Select>
                </FormControl>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "22px",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <strong>Training Type</strong>
                  </div>
                  <div style={{ display: "flex ", marginTop: "10px" }}>
                    <div style={{ display: "flex", margin: "auto 10px" }}>
                      <span className={TrainingStyles.radio_funcionality}>
                        <input
                          type="radio"
                          className={TrainingStyles.radio_active}
                          //  checked="checked"
                          defaultChecked="checked"
                        />
                      </span>
                      <p>Self Learning</p>
                    </div>
                    <div style={{ display: "flex ", margin: "auto 10px" }}>
                      <span className={TrainingStyles.radio_funcionality}>
                        <input
                          type="radio"
                          className={TrainingStyles.radio_active}
                        />
                      </span>
                      <p>Trainer Led Learning</p>
                    </div>
                  </div>
                </div>

                <div
                  className={TrainingStyles.err_box}
                  style={
                    isErr ? { visibility: "visible" } : { visibility: "hidden" }
                  }
                >
                  <p>{showErr}</p>
                </div>

                <div style={{ position: "absolute", right: "44px" }}>
                  <button
                  className="az_cancel_btn"
                    onClick={() => {
                      navigate("/dashboard/global%20training");
                    }}
                    style={{ marginRight: "12px" }}
                  >
                    BACK
                  </button>
                  {isAddTraining ? (
                    <button
                      className={"az_confirm_btn"}
                      onClick={handleCreateTraining}
                    >
                      CREATE
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        training_controller_put(trainingInputs);
                        navigate("/dashboard/global%20training");
                      }}
                      className={"az_confirm_btn"}
                    >
                      UPDATE
                    </button>
                  )}
                </div>
              </div>
              {/* Table */}
            </div>
          </div>
        </Paper>
        <div>
          <div className={TrainingStyles.flex} style={{ minWidth: "1280px" }}>
            <TextField
              sx={{
                width: { sm: 200, md: 300 },
                "& .MuiInputBase-root": {
                  width: "247px !important",
                  height: "32px !important",
                  borderRadius: "50px !important",
                  background: "#fff !important",
                  lineHeight: "21px !important",
                  font: "normal normal normal 13px/20px Poppins !important",
                  opacity: 1,
                },
              }}
              style={{ position: "absolute", right: "10px", marginTop: "46px" }}
              type={"text"}
              placeholder={"Search...."}
              name="search"
              Value={search.search}
              onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <img
                      src={searchIcn}
                      alt="no img"
                      style={{
                        verticalAlign: "top",
                        fontSize: "large",
                      }}
                      aria-hidden="true"
                    />
                  </IconButton>
                ),
              }}
            />
          </div>
        </div>
        {/* table */}
        <div style={{ paddingRight: "33px" }}>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              marginTop: "126px",
              marginLeft: "15px",
            }}
          >
            <TableContainer
              sx={{
                overflow: "auto",
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={TrainingStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={TrainingStyles.table_head}
                    >
                      Category Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className={TrainingStyles.table_head}
                    >
                      Sub-category Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className={TrainingStyles.table_head}
                    >
                      Training Name
                    </TableCell>

                    <TableCell
                      align="center"
                      className={TrainingStyles.table_head}
                    >
                      Review-status
                    </TableCell>
                    <TableCell
                      align="left"
                      className={TrainingStyles.table_head}
                      style={{ paddingRight: "57px", textAlign: "right" }}
                    >
                      Operation
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trainingtableData.length > 0 ? (
                    trainingtableData.map((elem, i) => {
                      return (
                        <TableRow>
                          <TableCell className={TrainingStyles.table_cell}>
                            {i + 1}
                          </TableCell>
                          <TableCell className={TrainingStyles.table_cell}>
                            {elem.categoryName}
                          </TableCell>
                          <TableCell
                            className={TrainingStyles.table_cell}
                            align="center"
                          >
                            {elem.subCategoryName}
                          </TableCell>

                          <TableCell
                            className={TrainingStyles.table_cell}
                            align="center"
                          >
                            {elem.name}
                          </TableCell>
                          {/* active btn */}
                          <TableCell
                            className={TrainingStyles.table_cell}
                            align="center"
                          >
                            {elem.reviewStatus == "PENDING" ? (
                              <button className={TrainingStyles.red_btn}>
                                PENDING
                              </button>
                            ) : (
                              <button className={TrainingStyles.green_btn}>
                                ACTIVE
                              </button>
                            )}
                            {/* <i className="bi bi-pencil" style={{ color: 'white', backgroundColor: 'orange',padding: '6px',borderRadius: '50%',marginLeft: '7px'}}></i> */}
                          </TableCell>
                          {/* index */}
                          <TableCell
                            align="right"
                            className={TrainingStyles.table_cell}
                            style={{
                              display: "tableCell",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Link
                              style={{ textDecoration: "none" }}
                              to="/dashboard/TrainingAids"
                            >
                              <button
                                style={{
                                  backgroundColor: "transparent",
                                  color: "black",
                                  border: "1px solid",
                                }}
                                className={TrainingStyles.white_btn}
                              >
                                <AddCircleOutlineOutlinedIcon
                                  style={{
                                    marginRight: "6px",
                                    height: "16px",
                                    width: "16px",
                                  }}
                                />{" "}
                                Training Aids
                              </button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <td
                      colSpan="10"
                      style={{
                        height: "25rem",
                        textAlign: "center",
                        color: "grey",
                      }}
                    >
                      {" "}
                      NO DATA AVAILABLE{" "}
                    </td>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        {/* pagination container - Start */}
        <div
          className={TrainingStyles.pagination}
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            width: "100%",
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={TrainingStyles.rows}>
              <label for="rows">Rows per page</label>
              <select
              className="cursorPointer"
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        {/* pagination container - End */}
      </div>
    </>
  );
};

export default CreateTraining;
