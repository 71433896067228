import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { MenuProps } from "./Data";
import { SearchOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import GetLastModifiedAt from "../API/token-controller/GetLastModifiedAt";
import ViewContentPage from "./ViewContentPage";
import TopBar from "../../components/common/TopBar";
import searchIcn from "../../../src/img/search.svg";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import { isNOTNullOrUndefined, isNullOrUndefined } from "../js/TeachValidate";
import { CircularProgress } from "@material-ui/core";
import cdn_response_validator from "../../utils/CdnResponseValidator";
import { fetchPlanDetails, } from '../js/Cordinator/assignQuiz/api/fetchCalls'
import CommonSort from "../js/CommonSort"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
const StudentRevision = () => {
  const [showDetail, setShowDetail] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [showSubTopic, setShowSubTopic] = useState(true);
  const [gradeList, setGradeList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const [subTopicList, setSubTopicList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const token = sessionStorage.getItem("token");
  const [modifiedDate, setModifiedDate] = useState({});
  const [showContentModal, setShowContentModal] = useState(false);
  const [selectedChapterId, setSelectedChapterId] = useState("");
  const [sectionList, setSectionList] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [pageSize, setPageSize] = useState(1000);
  const [boardPageSize, setBoardPageSize] = useState(1000);
  const [isError, setIsError] = useState(false);
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const [teacherDetail, setTeacherDetail] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    joinDate: "",
    designation: "",
    email: "",
    mobile: "",
    id: "",
  });
  const [teacherId, setTeacherId] = useState("");
  const [gradeSection, setGradeSection] = useState([]);

  useEffect(() => {
    fetchTeacherData(userName, role);
    const teacherData = fetchTeacherData(userName, role);
    setTeacherDetail(teacherData);
    GetLastModifiedAt(setModifiedDate);

    return () => {
      setSelectedGrade("");
      setSelectedSection("");
      setSelectedSubject("");
      sessionStorage.removeItem("gradeId");
      sessionStorage.removeItem("sectionId");
      sessionStorage.removeItem("subjectId");
      sessionStorage.removeItem("subTopicId");
    };
  }, []);

  //auto populate filters start scripts
  const [isAutoPopulating, setIsAutoPopulating] = useState(false);
  useEffect(() => {
    if (isNOTNullOrUndefined(gradeSection) && gradeSection?.length) {
      startAutoPopulate();
    }
  }, [gradeSection]);

  const fetchSubjectForAutoPopulate = async (gradId, secId) => {
    const id = teacherId.id;
    const response = await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/subjects?teacherId=${id}&gradeId=${gradId}&sectionId=${secId}&menuName=STUDENT_REVISION`,

        // `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan/access-based?planId=${sessionStorage.getItem("planId")}&gradeId=${gradId || sessionStorage.getItem("gradeId")}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => console.log(err));
    // setSubjectList(response?.data?.data)
    return response?.data?.data;
  };

  const startAutoPopulate = async () => {
    setIsAutoPopulating(true);
    setSelectedGrade(gradeSection[0]?.id);
    setSelectedSection(gradeSection[0]?.sections[0].id);
    var subList = await fetchSubjectForAutoPopulate(
      gradeSection[0]?.id,
      gradeSection[0]?.sections[0].id
    );

    // console.log(subList, "4-7-24")
    subList = subList?.filter(v => v.skilledSubject === true) || []
    // console.log(subList)

    setSubjectList(subList);
    setSelectedSubject(subList[0]?.subjectId);
    setShowSubTopic(subList[0]?.hideSubtopics ? false : true);
    console.log(subList[0]?.hideSubtopics ? false : true)
    // alert("1111" + "----1")
    sessionStorage.setItem("gradeId", gradeSection[0]?.id);
    sessionStorage.setItem("sectionId", gradeSection[0]?.sections[0].id);
    sessionStorage.setItem("subjectId", subList[0]?.subjectId);

    setIsAutoPopulating(false);
    const planDatas = await fetchPlanDetails();
    const contentPlanDetails = await fetchContentPlanDetails();

    // setTimeout(() => {
    //   setIsAutoPopulating(false);
    // }, 2000);
  };

  const RenderLoader = () => {
    return (
      <div
        style={{
          // background: 'rgba(0,0,0,0.6)',
          height: "100%",
          width: "100%",
          zindex: 99,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  };

  //Auto populate ends

  // Student, teacher,only have access

  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    sessionStorage.setItem("boardId", res.data.data.teacher.boardId);
    sessionStorage.setItem("schoolId", res.data.data.teacher.school);
    sessionStorage.setItem("branchId", res.data.data.teacher.branch);

    setTeacherId(res.data.data.teacher);
  };

  // grade - section API

  const fetchGrade = async () => {
    // const id = '4028928886f00aec0186fda2e0a70004'
    const id = teacherId.id;

    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=TEACH`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setGradeSection(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  // subject - subtopic API

  const fetchSubject = async (selectedSection) => {
    const id = teacherId.id;
    // const id = '4028928886f00aec0186fda2e0a70004'

    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/teachers/subjects?teacherId=${id}&gradeId=${selectedGrade}${selectedSection ? `&sectionId=${selectedSection}` : ""
        }&menuName=STUDENT_REVISION`,
        // `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan/access-based?planId=${sessionStorage.getItem("planId")}&gradeId=${selectedGrade || sessionStorage.getItem("gradeId")}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {

        console.log(response?.data?.data, "4-7-24")
        response.data.data = response?.data?.data?.filter(v => v.skilledSubject === true) || []
        console.log(response?.data?.data)

        setSubjectList(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  // Academic year api

  const fetchAcademicYearData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data.data || [];
  };

  const [isfetchingList, setIsFetchingList] = useState(false);
  const fetchChapterData = async (subjectId, gradeId) => {
    console.log(subjectId, gradeId)
    setUpdatedData([]);
    const bordId = sessionStorage.getItem("boardId");
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/master/chapters/all-chapters?subjectId=${subjectId}&gradeId=${gradeId}&boardId=${bordId}${isNOTNullOrUndefined(selectedSubTopic)
          ? `&subTopicId=${selectedSubTopic}`
          : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (response) => {
        if (response.status === 200) {
          response.data.data = await CommonSort(response?.data?.data || [], "chapter", false);
          setUpdatedData(response?.data?.data);
          setIsFetchingList(false);

          // setFilterValue(response?.data?.data)
        } else {
          setUpdatedData([]);
          setIsFetchingList(false);
        }
      });
  };
  const fetchChapterBySearch = async (value) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageSize=${pageSize}&sortOrder=true&search=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data?.data || [];
  };

  const fetchBoardData = async (pageSize) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/page?pageSize=1000&sortOrder=true`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response?.data?.data?.totalElements > pageSize) {
      setBoardPageSize(response?.data?.data?.totalElements);
    }
    return response?.data?.data?.data || [];
  };

  const fetchDataUsingChapterId = async (chapterId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/student-chapter/page?pageSize=1000&reviewStatus=APPROVED&sortOrder=true&sortBy=createdAt&chapterId=${chapterId}&contentType=REVISION`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data?.data || [];
  };

  const fetchContentPlanDetails = async () => {
    const planId = sessionStorage.getItem("planId");
    const bordId = sessionStorage.getItem("boardId");

    if (planId !== null) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/template-screen?planId=${planId}&boardId=${bordId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const contentPlanDetails = response?.data?.data || [];
      console.log("planDetails >>> ", contentPlanDetails)

      const grade = contentPlanDetails.planGrades.find(grade => grade.gradeId === selectedGrade);

      const planTemplate = grade ? grade.planTemplates.find(template => template.subjectId === selectedSubject) : null;

      const wsDownload = planTemplate ? planTemplate.wsDownload : null;
      const wbDownload = planTemplate ? planTemplate.wbDownload : null;
      localStorage.setItem("wsDownload", wsDownload);
      localStorage.setItem("wbDownload", wbDownload);
      console.log("wsDownload,wbDownload >>> ", wsDownload, wbDownload)

      return response?.data?.data || [];
    }

  };


  useEffect(async () => {
    if (selectedChapterId) {
      const menuData = await fetchDataUsingChapterId(selectedChapterId);
      if (menuData?.length !== 0) {
        setShowDetail(menuData[0]?.revisionDocMaterials);
        setShowContentModal(true);
      } else {
        setShowDetail([]);
      }
    }
  }, [selectedChapterId]);

  useEffect(async () => {
    if (
      isNOTNullOrUndefined(selectedSubject) &&
      isNOTNullOrUndefined(selectedGrade) &&
      isNOTNullOrUndefined(selectedSection)
    ) {
      const subtopicsInSelectedSubject = subjectList?.find(
        (i) => i.subjectId === selectedSubject
      )?.subTopics;
      if (
        isNOTNullOrUndefined(subtopicsInSelectedSubject) &&
        subtopicsInSelectedSubject.length > 0
      ) {
        if (isNOTNullOrUndefined(selectedSubTopic)) {
          setIsFetchingList(true);
          await fetchChapterData(selectedSubject, selectedGrade);
        }
      } else {
        setIsFetchingList(true);
        await fetchChapterData(selectedSubject, selectedGrade);
      }
      const contentPlanDetails = await fetchContentPlanDetails();
    }
  }, [
    selectedSubject,
    selectedSubTopic,
    selectedGrade,
    selectedSection,
    selectedBoard,
  ]);

  useEffect(async () => {
    const grade = await fetchGrade();
    const board = await fetchBoardData(boardPageSize);
    const section = await fetchGrade();
    const academic = await fetchAcademicYearData();
    setAcademicYearList(academic);
    setSectionList(section);
    setBoardList(board);
    setGradeList(grade);
    // Set the initial value of the filter
    const storedAcademicYearId = sessionStorage.getItem("academicYearId");
    if (storedAcademicYearId) {
      setSelectedAcademicYear(storedAcademicYearId);
    } else if (academic.length > 0) {
      setSelectedAcademicYear(academic[0].id);
      sessionStorage.setItem("academicYearId", academic[0].id);
    }
    // setSubjectList(subject);
  }, [teacherId.id]);

  useEffect(() => {
    const subjectObj = subjectList.filter(
      (i) => i.subjectId === selectedSubject
    )[0];
    if (subjectObj?.subTopics) {
      setSelectedSubTopic(subjectObj?.subTopics[0]?.id);
      sessionStorage.setItem("subTopicId", subjectObj?.subTopics[0]?.id);
    } else {
      setSelectedSubTopic("");
    }
  }, [selectedSubject]);

  const handleChangeGrade = (e) => {
    setSelectedGrade(e.target.value);
    sessionStorage.setItem("gradeId", e.target.value);
    setSelectedSubject("");
    setSelectedSection("");
    setSelectedSubTopic("");
    sessionStorage.removeItem("sectionId");
    sessionStorage.removeItem("subjectId");
    sessionStorage.removeItem("subTopicId");
    fetchSubject(selectedSection);
  };

  const handleChangeSection = (event) => {
    setSelectedSection(event.target.value);
    setSelectedSubject("");
    fetchSubject(event.target.value);
    sessionStorage.setItem("sectionId", event.target.value);
  };

  const handleChangeSubject = (event) => {
    setSelectedSubject(event.target.value);
    sessionStorage.setItem("subjectId", event.target.value);
    const selectedSubject = subjectList.find(val => val.subjectId === String(event.target.value));
    if (selectedSubject && selectedSubject.hideSubtopics) {
      setShowSubTopic(false);
    } else {
      setShowSubTopic(true);
    }
  };

  const handleChangeSubTopic = (event) => {
    setSelectedSubTopic(event.target.value);
    sessionStorage.setItem("subTopicId", event.target.value);
  };

  const handleChangeAcademicYear = (event) => {
    setSelectedAcademicYear(event.target.value);
    sessionStorage.setItem("academicYearId", event.target.value);
  };
  const [sectionFilteredList, setSectionFilteredList] = useState([]);

  useEffect(() => {
    if (isNOTNullOrUndefined(selectedGrade)) {
      const filtered = gradeSection?.find(
        (i) => i.id === selectedGrade
      )?.sections;
      setSectionFilteredList(filtered);
    }
  }, [selectedGrade]);

  const [query, setQuery] = useState("");
  // if (isAutoPopulating) {
  //   return <RenderLoader />;
  // }
  return (
    <>
      {!showContentModal ? (
        <div style={{ width: "100%" }}>
          <TopBar
            title={"Home"}
            nav1={"TeacherDashboard"}
            subSubTitle={"Student Revision"}
            modifiedDate={modifiedDate}
          />
          <div
            style={{
              marginTop: "10px",
              paddingRight: "33px",
              position: "absolute",
              right: "15px",
              left: "15px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBottom: "10px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "1rem",
                }}
              >
                {/* <FormControl
                  sx={{
                    minWidth: "7.8vw",
                    background: "white",
                    borderRadius: 150,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Grades</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={MenuProps}
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                      color: selectedGrade ? "orange" : "black",
                    }}
                    sx={{
                      "& .css-10hburv-MuiTypography-root": {
                        font: "normal normal normal 15px/15px Poppins !important",
                      },
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                    size={"small"}
                    value={selectedGrade}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    onChange={handleChangeGrade}
                  >
                    {gradeSection?.map((item) => {
                      return (
                        <MenuItem
                          value={item?.id}
                          key={item.id}
                          className={dashboardContentStyles.menu_item}
                          id={`az-teacher-gradedropdown-${item.id}`}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: 'orange',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#fcedc2',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}
                        >
                          <ListItemText primary={item.grade} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <InputLabel sx={{
                    fontSize: '13px',
                    color: '#e7ab3c',
                    top: '-10px',
                    '&.MuiInputLabel-shrink': {
                      top: '0',
                    },
                    '&.Mui-focused': {
                      color: '#e7ab3c'
                    }
                  }} id="demo-simple-select-label" className='dropDownLabel'>Grade</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    input={<OutlinedInput label="Grade" />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '150px',
                          marginTop: '2px',
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: '0px'
                        },
                      },
                    }}
                    value={selectedGrade}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    onChange={handleChangeGrade}
                    sx={{
                      width: '150px',
                      height: '30px',
                      padding: '10px',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e7ab3c',
                      }
                    }}
                    className='dropdownSelect'
                  >
                    {gradeSection?.map((item) => {
                      return (
                        <MenuItem
                          value={item?.id}
                          key={item.id}
                          className={dashboardContentStyles.menu_item}
                          id={`az-teacher-gradedropdown-${item.id}`}
                          sx={{
                            fontSize: '10px',
                            backgroundColor: 'white',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                            '&.Mui-selected': {
                              backgroundColor: '#e7ab3c',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                            },
                          }}
                        >
                          <ListItemText primary={item.grade} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {/* <FormControl
                  sx={{
                    minWidth: "7.8vw",
                    background: "white",
                    borderRadius: 150,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">Section</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={MenuProps}
                    sx={{
                      "& .css-10hburv-MuiTypography-root": {
                        font: "normal normal normal 15px/15px Poppins !important",
                      },
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                    style={{
                      borderRadius: "150px",
                      padding: "4px", color: selectedSection ? "orange" : "black",
                    }}
                    size={"small"}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    value={selectedSection}
                    // onChange={(e) => setSelectedSection(e.target.value)}
                    onChange={handleChangeSection}
                  >
                    {sectionFilteredList?.map((sec) => {
                      if (sec.hasOwnProperty("section")) {
                        return (
                          <MenuItem
                            value={sec.id}
                            key={sec.id}
                            className={dashboardContentStyles.menu_item}
                            id={`az-teacher-sectiondropdown-${sec.id}`}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: 'orange',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#fcedc2',
                                color: "#000000"
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}
                          >
                            <ListItemText primary={sec.section} />
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <InputLabel sx={{
                    fontSize: '13px',
                    color: '#e7ab3c',
                    top: '-10px',
                    '&.MuiInputLabel-shrink': {
                      top: '0',
                    },
                    '&.Mui-focused': {
                      color: '#e7ab3c'
                    }
                  }} id="demo-simple-select-label" className='dropDownLabel'>Section</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    input={<OutlinedInput label="Section" />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '150px',
                          marginTop: '2px',
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: '0px'
                        },
                      },
                    }}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    value={selectedSection}
                    // onChange={(e) => setSelectedSection(e.target.value)}
                    onChange={handleChangeSection}
                    sx={{
                      width: '150px',
                      height: '30px',
                      padding: '10px',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e7ab3c',
                      }
                    }}
                    className='dropdownSelect'
                  >
                    {sectionFilteredList?.map((sec) => {
                      if (sec.hasOwnProperty("section")) {
                        return (
                          <MenuItem
                            value={sec.id}
                            key={sec.id}
                            className={dashboardContentStyles.menu_item}
                            id={`az-teacher-sectiondropdown-${sec.id}`}
                            sx={{
                              fontSize: '10px',
                              backgroundColor: 'white',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#e7ab3c',
                                '&:hover': {
                                  backgroundColor: '#f4d788',
                                },
                              },
                            }}
                          >
                            <ListItemText primary={sec.section} />
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>

                {/* <FormControl
                  sx={{
                    minWidth: "7.8vw",
                    background: "white",
                    borderRadius: 150,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Subjects
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={MenuProps}
                    sx={{
                      "& .css-10hburv-MuiTypography-root": {
                        font: "normal normal normal 15px/15px Poppins !important",
                      },
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                      color: selectedSubject ? "orange" : "black",
                    }}
                    size={"small"}
                    value={
                      isNOTNullOrUndefined(selectedSubject)
                        ? selectedSubject
                        : ""
                    }
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    onChange={handleChangeSubject}
                  >
                    {subjectList?.map((item) => {
                      return (
                        <MenuItem
                          value={item.subjectId}
                          key={item.subjectId}
                          className={dashboardContentStyles.menu_item}
                          id={`az-teacher-subjectdropdown-${item.subjectId}`}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: 'orange',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#fcedc2',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}
                        >
                          <ListItemText primary={item.subject} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <InputLabel sx={{
                    fontSize: '13px',
                    color: '#e7ab3c',
                    top: '-10px',
                    '&.MuiInputLabel-shrink': {
                      top: '0',
                    },
                    '&.Mui-focused': {
                      color: '#e7ab3c'
                    }
                  }} id="demo-simple-select-label" className='dropDownLabel'>Subject</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    input={<OutlinedInput label="Subject" />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '150px',
                          marginTop: '2px',
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: '0px'
                        },
                      },
                    }}
                    value={
                      isNOTNullOrUndefined(selectedSubject)
                        ? selectedSubject
                        : ""
                    }
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    onChange={handleChangeSubject}
                    sx={{
                      width: '150px',
                      height: '30px',
                      padding: '10px',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e7ab3c',
                      }
                    }}
                    className='dropdownSelect'
                  >
                    {subjectList?.map((item) => {
                      return (
                        <MenuItem
                          value={item.subjectId}
                          key={item.subjectId}
                          className={dashboardContentStyles.menu_item}
                          id={`az-teacher-subjectdropdown-${item.subjectId}`}
                          sx={{
                            fontSize: '10px',
                            backgroundColor: 'white',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                            '&.Mui-selected': {
                              backgroundColor: '#e7ab3c',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                            },
                          }}
                        >
                          <ListItemText primary={item.subject} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {/* {
                  console.log("showSubTopic", showSubTopic)
                } */}
                {
                  showSubTopic &&
                  // <FormControl
                  //   sx={{
                  //     minWidth: "7.8vw",
                  //     background: "white",
                  //     borderRadius: 150,
                  //   }}
                  // >
                  //   <InputLabel id="demo-simple-select-label">
                  //     SubTopic
                  //   </InputLabel>
                  //   <Select
                  //     labelId="demo-simple-select-label"
                  //     id="demo-simple-select"
                  //     input={<OutlinedInput label="Tag" />}
                  //     MenuProps={MenuProps}
                  //     sx={{
                  //       "& .css-10hburv-MuiTypography-root": {
                  //         font: "normal normal normal 15px/15px Poppins !important",
                  //       },
                  //       "&.Mui-selected": {
                  //         backgroundColor: 'orange',
                  //         color: "#FFFFFF"
                  //       },
                  //       "&.Mui-selected:hover": {
                  //         backgroundColor: '#fcedc2',
                  //         color: "#000000"
                  //       },
                  //       "&:not(.Mui-selected):hover": {
                  //         backgroundColor: '#fcedc2',
                  //         color: '#000000',
                  //       },
                  //     }}
                  //     style={{
                  //       borderRadius: "150px",
                  //       padding: "4px",
                  //       color: selectedSubTopic ? "orange" : "black",
                  //     }}
                  //     size={"small"}
                  //     value={
                  //       isNOTNullOrUndefined(selectedSubTopic)
                  //         ? selectedSubTopic
                  //         : ""
                  //     }
                  //     IconComponent={(props) => (
                  //       <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                  //     )}
                  //     onChange={handleChangeSubTopic}
                  //   >
                  //     {subjectList
                  //       ?.find((i) => i.subjectId === selectedSubject)
                  //       ?.subTopics?.map((sub) => {
                  //         if (sub.hasOwnProperty("subTopic")) {
                  //           return (
                  //             <MenuItem
                  //               value={sub.id}
                  //               key={sub.id}
                  //               className={dashboardContentStyles.menu_item}
                  //               id={`az-teacher-subjectdropdown-${sub.id}`}
                  //               sx={{
                  //                 "&.Mui-selected": {
                  //                   backgroundColor: 'orange',
                  //                   color: "#FFFFFF"
                  //                 },
                  //                 "&.Mui-selected:hover": {
                  //                   backgroundColor: '#fcedc2',
                  //                   color: "#000000"
                  //                 },
                  //                 "&:not(.Mui-selected):hover": {
                  //                   backgroundColor: '#fcedc2',
                  //                   color: '#000000',
                  //                 },
                  //               }}
                  //             // disabled={!sub.active}
                  //             >
                  //               <ListItemText primary={sub.subTopic} />
                  //             </MenuItem>
                  //           );
                  //         }
                  //       })}
                  //   </Select>
                  // </FormControl>

                  <FormControl fullWidth>
                    <InputLabel sx={{
                      fontSize: '13px',
                      color: '#e7ab3c',
                      top: '-10px',
                      '&.MuiInputLabel-shrink': {
                        top: '0',
                      },
                      '&.Mui-focused': {
                        color: '#e7ab3c'
                      }
                    }} id="demo-simple-select-label" className='dropDownLabel'>SubTopic</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      input={<OutlinedInput label="Sub Topic" />}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            width: '150px',
                            marginTop: '2px',
                          },
                        },
                        MenuListProps: {
                          sx: {
                            padding: '0px'
                          },
                        },
                      }}
                      value={
                        isNOTNullOrUndefined(selectedSubTopic)
                          ? selectedSubTopic
                          : ""
                      }
                      IconComponent={(props) => (
                        <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                      )}
                      onChange={handleChangeSubTopic}
                      sx={{
                        width: '150px',
                        height: '30px',
                        padding: '10px',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#000',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#000',
                        },
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#e7ab3c',
                        }
                      }}
                      className='dropdownSelect'
                    >
                      {subjectList
                        ?.find((i) => i.subjectId === selectedSubject)
                        ?.subTopics?.map((sub) => {
                          if (sub.hasOwnProperty("subTopic")) {
                            return (
                              <MenuItem
                                value={sub.id}
                                key={sub.id}
                                className={dashboardContentStyles.menu_item}
                                id={`az-teacher-subjectdropdown-${sub.id}`}
                                sx={{
                                  fontSize: '10px',
                                  backgroundColor: 'white',
                                  '&:hover': {
                                    backgroundColor: '#f4d788',
                                  },
                                  '&.Mui-selected': {
                                    backgroundColor: '#e7ab3c',
                                    '&:hover': {
                                      backgroundColor: '#f4d788',
                                    },
                                  },
                                }}
                              // disabled={!sub.active}
                              >
                                <ListItemText primary={sub.subTopic} />
                              </MenuItem>
                            );
                          }
                        })}
                    </Select>
                  </FormControl>
                }
                {/* <FormControl
                  sx={{
                    minWidth: "7.8vw",
                    background: "white",
                    borderRadius: 150,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Academic Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={MenuProps}
                    sx={{
                      "& .css-10hburv-MuiTypography-root": {
                        font: "normal normal normal 15px/15px Poppins !important",
                      },
                    }}
                    style={{
                      borderRadius: "150px",
                      padding: "4px",
                    }}
                    size={"small"}
                    value={selectedAcademicYear}
                    disabled
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    onChange={handleChangeAcademicYear}
                  >
                    {academicYearList?.map((item) => {
                      return (
                        <MenuItem
                          value={item.id}
                          key={item.id}
                          className={dashboardContentStyles.menu_item}
                          id={`az-teacher-academicdropdown-${item.id}`}
                        >
                          <ListItemText primary={item.academicYear} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}

                <FormControl fullWidth>
                  <InputLabel sx={{
                    fontSize: '13px',
                    color: '#e7ab3c',
                    top: '-10px',
                    '&.MuiInputLabel-shrink': {
                      top: '0',
                    },
                    '&.Mui-focused': {
                      color: '#e7ab3c'
                    }
                  }} className='dropDownLabel' id="demo-simple-select-label">Academic Year</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    input={<OutlinedInput label="Academic Year" />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '150px',
                          marginTop: '2px',
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: '0px'
                        },
                      },
                    }}
                    value={selectedAcademicYear}
                    disabled
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                    )}
                    onChange={handleChangeAcademicYear}
                    sx={{
                      width: '150px',
                      height: '30px',
                      padding: '10px',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e7ab3c',
                      }
                    }}
                    className='dropdownSelect'
                  >
                    {academicYearList?.map((item) => {
                      return (
                        <MenuItem
                          value={item.id}
                          key={item.id}
                          className={dashboardContentStyles.menu_item}
                          id={`az-teacher-academicdropdown-${item.id}`}
                          sx={{
                            fontSize: '10px',
                            backgroundColor: 'white',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                            '&.Mui-selected': {
                              backgroundColor: '#e7ab3c',
                              '&:hover': {
                                backgroundColor: '#f4d788',
                              },
                            },
                          }}
                        >
                          <ListItemText primary={item.academicYear} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <TextField
                type={"text"}
                placeholder={"Search anything...."}
                id={`az-teacher-search`}
                onChange={(e) => setQuery(e.target.value.toLowerCase())}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                        alt=""
                      />
                    </IconButton>
                  ),
                }}
                size={"small"}
                sx={{
                  background: !isError && "#fff",
                  borderRadius: "150px",
                  '& .MuiInputBase-root': {
                    width: '200px !important',
                    height: '30px !important',
                    borderRadius: '50px !important',
                    background: '#fff !important',
                    lineHeight: '21px !important',
                    font: 'normal normal normal 15px/15px Poppins !important'
                  }
                }}
                error={isError}
                helperText={isError && "Please Enter min 3 character."}
              />
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // height: "100%",
                width: "100%",
                padding: "25px 20px",
                overflow: "auto",
                height: 700,
                scrollbarWidth: "thin"
              }}
            >
              {selectedGrade && selectedSection && selectedSubject ? (
                <Box sx={{ flexGrow: 1 }}>
                  <div className={dashboardContentStyles.cardContainer}>
                    {updatedData.length > 0 ? (
                      updatedData
                        ?.filter((itm) =>
                          itm.chapter.toLowerCase().includes(query)
                        )
                        .map((item, i) => (
                          <div
                            key={item.id}
                            id={`az-teacher-chapter-card-${item.id}`}
                            onClick={
                              () => {
                                console.log("chapter id >>>", item.id)
                                setSelectedChapterId(item?.id)
                              }
                            }
                            className={dashboardContentStyles.card}
                          >
                            <img
                              src={item?.thumbnailPath}
                              width={110}
                              alt={""}
                              // style={{ maxHeight: "138px" }}
                              className={dashboardContentStyles.az_quiz_card_img} />
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: 600,
                                color: "#423d5c",
                              }}
                            >
                              {item?.chapter}
                            </div>
                          </div>
                        ))
                    ) : (
                      <div>No data for the selected filters</div>
                    )}
                  </div>

                  {/* <Grid container spacing={2}>
                    {updatedData.length > 0 ? (
                      updatedData
                        ?.filter((itm) =>
                          itm.chapter.toLowerCase().includes(query)
                        )
                        .map((item, i) => (
                          <Grid
                            item
                            xs={12}
                            md={2.4}
                            sm={4}
                            key={item.id}
                            onClick={() => setSelectedChapterId(item?.id)}
                          >
                            <div key={item?.id} id="az_card_style" className={dashboardContentStyles.az_card_style} >
                              <div
                                // style={{
                                //   display: "flex",
                                //   flexDirection: "column",
                                //   justifyContent: "center",
                                //   alignItems: "center",
                                //   minHeight: "166px",
                                // }}
                                key={item?.id}
                              >
                                <img
                                  src={item?.thumbnailPath}
                                  width={110}
                                  alt={""}
                                  style={{ maxHeight: "138px" }}
                                />
                                <div
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    color: "#423d5c",
                                  }}
                                >
                                  {item?.chapter}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        ))
                    ) : (
                      <div>No data for the selected filters</div>
                    )}
                  </Grid> */}
                </Box>
              ) : (
                <div>Please Select Grade , Section and Subject</div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <ViewContentPage
            studyData={showDetail}
            menuType={"student"}
            closeModal={() => {
              setShowContentModal(false);
              setSelectedChapterId("");
              setShowDetail([]);
            }}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            setSelectedAcademicYear={setSelectedAcademicYear}
            selectedAcademicYear={selectedAcademicYear}
            selectedChapterId={selectedChapterId}
            pageName={"Student Revision"}
          />
        </>
      )}
    </>
  );
};
export default StudentRevision;
