import { Box, FormControl, Grid, InputLabel } from '@mui/material'
import React from 'react'
import { useEffect, useState } from "react";
import QuizCard from './QuizCard'
import { ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import { MenuProps } from '../../Data'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import dashboardContentStyles from '../../../css/dashboardContent.module.css'
import { isNOTNullOrUndefined } from '../../../../utils/helper'

function QuizCardViewOptimised({
  selectedGrade,
  gradeSection,
  handleChangeGrade,
  selectedSection,
  handleChangeSection,
  selectedSubject,
  subjectList,
  selectedSubTopic,
  onPreviewClick,
  onReleaseClick,
  chapterList,
  handleChangeChapter,
  selectedChapter,
  selectedAcademicYear,
  allQuizData,
  academicYearList,
  handleChangeSubject,
  handleChangeSubtopic,
  OpenReRelease,
  setQuizType,
  isLoadingList,
  sectionList,
  isActive,
  showSubTopic
}) {
  console.log("quizcardviewoptimised.js", showSubTopic, selectedSubject)
  const filterStyle = {
    '& .css-10hburv-MuiTypography-root': {
      fontSize: '12px !important'
    },
    '&.MuiOutlinedInput-root': {
      '& > fieldset': {
        border: '1px solid #D6D5DF !important'
      }
    },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: '1px solid #272727 !important'
      }
    },
    fontSize: '14px !important',
    font: 'Poppins !important'
  }

  const labelStyle = {
    fontSize: '14px',
    marginBottom: 6,
    '&.Mui-focused': {
      color: 'orange'
    }
  }

  const callbacktochangeExitStatus = () => {
    console.log("callbacktochangeExitStatus")
  }

  useEffect(() => {
    console.log("allQuizData ", allQuizData)
  })

  return (
    <div
      style={{
        marginTop: '10px',
        paddingRight: '33px',
        position: 'absolute',
        right: '15px',
        left: '15px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1.2rem'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '10px',
          alignItems: 'center',
          columnGap: '1rem'
        }}
      >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '10px'
        }}
      >
        {/* <FormControl
          sx={{
            width: '113px',
            height: 45
          }}
        >
          <InputLabel sx={labelStyle} id='demo-simple-select-label'>
            Select Grade
          </InputLabel>
          <Select
            value={isNOTNullOrUndefined(selectedGrade) ? selectedGrade : ''}
            onChange={handleChangeGrade}
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            input={<OutlinedInput label='Select Grade' />}
            MenuProps={MenuProps}
            style={{ height: '100%', background: 'white', borderRadius: 150 }}
            size={'small'}
            sx={filterStyle}
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
          >
            {gradeSection?.map(item => (
              <MenuItem
                // key={item.id}
                // value={item.id}
                // className={dashboardContentStyles.menu_item}
                key={item.id}
                value={item.id}
                className={
                  dashboardContentStyles.menu_item
                }
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: 'orange',
                    color: "#FFFFFF"
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: '#fcedc2',
                    color: "#000000"
                  },
                  "&:not(.Mui-selected):hover": {
                    backgroundColor: '#fcedc2',
                    color: '#000000',
                  },
                }}
              >
                {item?.grade}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <FormControl fullWidth>
          <InputLabel
          sx={{
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
            }}  
          id="demo-simple-select-label" className='dropDownLabel'>Grade</InputLabel>
            <Select  labelId='demo-simple-select-label'
              id='demo-simple-select'
              input={<OutlinedInput label='Grades' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop:'2px', 
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}  
              value={isNOTNullOrUndefined(selectedGrade) ? selectedGrade : ''}
              IconComponent={props => (
                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
              )}
              onChange={handleChangeGrade}
              sx={{
                width: '150px',    
                height: '30px',    
                padding: '10px',  
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c', 
                }
              }}
              className='dropdownSelect'
            >
              {gradeSection?.map(item => {
                return (
                  <MenuItem
                    // value={item?.id}
                    // key={item.id}
                    // className={dashboardContentStyles.menu_item}
                    value={item?.id}
                    key={item.id}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      fontSize:'10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}>
                    {item.grade}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

        <FormControl fullWidth >
            <InputLabel 
            sx={{
                  top: '-10px',
                  '&.MuiInputLabel-shrink': {
                    top: '0',
                  },
                }}  
            id="demo-simple-select-label" className='dropDownLabel'>Section</InputLabel>
            <Select labelId='demo-simple-select-label'
              id='demo-simple-select'
              input={<OutlinedInput label='Section' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop:'2px', 
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}  
              IconComponent={props => (
                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
              )}
              value={
                isNOTNullOrUndefined(selectedSection) ? selectedSection : ''
              }
              onChange={handleChangeSection}
              sx={{
                width: '150px',    
                height: '30px',    
                padding: '10px', 
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c', 
                }
              }}
              className='dropdownSelect'
            >
              {sectionList?.map(sec => {
                return (
                  <MenuItem
                    // value={sec.id}
                    // key={sec.id}
                    // className={dashboardContentStyles.menu_item}
                    value={sec?.id}
                    key={sec.id}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      fontSize:'10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    {sec.section}
                  </MenuItem>
                )
              })}
            </Select>
        </FormControl>

        <FormControl fullWidth>
            <InputLabel
            sx={{
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
            }} 
           id="demo-simple-select-label" className='dropDownLabel'>Subjects</InputLabel>
            <Select  labelId='demo-simple-select-label'
              id='demo-simple-select'
              input={<OutlinedInput label='Subjects' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop:'2px', 
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}  
              value={
                isNOTNullOrUndefined(selectedSubject?.subjectId)
                  ? selectedSubject.subjectId
                  : ''
              }
              IconComponent={props => (
                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
              )}
              onChange={handleChangeSubject}
              sx={{
                width: '150px',    
                height: '30px',    
                padding: '10px', 
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c', 
                }
              }}
              className='dropdownSelect'
            >
              {subjectList?.map(item => {
                return (
                  <MenuItem
                    // value={item.subjectId}
                    // key={item.subjectId}
                    // className={dashboardContentStyles.menu_item}
                    value={item?.subjectId}
                    key={item.subjectId}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      fontSize:'10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    {item.subject}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

        {/* <FormControl
          sx={{
            width: '113px',
            height: 45
          }}
        >
          <InputLabel sx={labelStyle} id='demo-simple-select-label'>
            Section
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            input={<OutlinedInput label='Section' />}
            MenuProps={MenuProps}
            style={{ height: '100%', background: 'white', borderRadius: 150 }}
            size={'small'}
            sx={filterStyle}
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            value={isNOTNullOrUndefined(selectedSection) ? selectedSection : ''}
            onChange={handleChangeSection}
          >
            {sectionList?.map(sec => {
              return (
                <MenuItem
                  // value={sec.id}
                  // key={sec.id}
                  // className={dashboardContentStyles.menu_item}
                  key={sec.id}
                  value={sec.id}
                  className={
                    dashboardContentStyles.menu_item
                  }
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: 'orange',
                      color: "#FFFFFF"
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: '#fcedc2',
                      color: "#000000"
                    },
                    "&:not(.Mui-selected):hover": {
                      backgroundColor: '#fcedc2',
                      color: '#000000',
                    },
                  }}
                >
                  {sec.section}
                </MenuItem>
              )
              // }
            })}
          </Select>
        </FormControl> */}


        
        {/* <FormControl
          sx={{
            width: '113px',
            height: 45
          }}
        >
          <InputLabel sx={labelStyle} id='demo-simple-select-label'>
            Subject
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            input={<OutlinedInput label='Subject' />}
            MenuProps={MenuProps}
            style={{ height: '100%', background: 'white', borderRadius: 150 }}
            size={'small'}
            sx={filterStyle}
            onChange={handleChangeSubject}
            value={
              isNOTNullOrUndefined(selectedSubject?.subjectId)
                ? selectedSubject.subjectId
                : ''
            }
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
          >
            {subjectList?.map(item => (
              <MenuItem
                // value={item.subjectId}
                // key={item.subjectId}
                // className={dashboardContentStyles.menu_item}
                key={item.subjectId}
                value={item.subjectId}
                className={
                  dashboardContentStyles.menu_item
                }
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: 'orange',
                    color: "#FFFFFF"
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: '#fcedc2',
                    color: "#000000"
                  },
                  "&:not(.Mui-selected):hover": {
                    backgroundColor: '#fcedc2',
                    color: '#000000',
                  },
                }}
              >
                {item?.subject}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        {
          showSubTopic &&
          // <FormControl
          //   sx={{
          //     width: '113px',
          //     height: 45
          //   }}
          // >
          //   <InputLabel sx={labelStyle} id='demo-simple-select-label'>
          //     SubTopic
          //   </InputLabel>
          //   <Select
          //     labelId='demo-simple-select-label'
          //     id='demo-simple-select'
          //     input={<OutlinedInput label='SubTopic' />}
          //     MenuProps={MenuProps}
          //     style={{ height: '100%', background: 'white', borderRadius: 150 }}
          //     size={'small'}
          //     sx={filterStyle}
          //     onChange={handleChangeSubtopic}
          //     value={
          //       isNOTNullOrUndefined(selectedSubTopic) ? selectedSubTopic : ''
          //     }
          //     IconComponent={props => (
          //       <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
          //     )}
          //   >
          //     {selectedSubject?.subTopics?.map(item => (
          //       <MenuItem
          //         // value={item.id}
          //         // key={item.id}
          //         // className={dashboardContentStyles.menu_item}
          //         key={item.id}
          //         value={item.id}
          //         className={
          //           dashboardContentStyles.menu_item
          //         }
          //         sx={{
          //           "&.Mui-selected": {
          //             backgroundColor: 'orange',
          //             color: "#FFFFFF"
          //           },
          //           "&.Mui-selected:hover": {
          //             backgroundColor: '#fcedc2',
          //             color: "#000000"
          //           },
          //           "&:not(.Mui-selected):hover": {
          //             backgroundColor: '#fcedc2',
          //             color: '#000000',
          //           },
          //         }}
          //       >
          //         {item?.subTopic}
          //       </MenuItem>
          //     ))}
          //   </Select>
          // </FormControl>

          <FormControl fullWidth>
            <InputLabel 
              sx={{
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
              }} 
            id="demo-simple-select-label" className='dropDownLabel'>SubTopic</InputLabel>
            <Select 
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                input={<OutlinedInput label='SubTopic' />}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      width: '150px',
                      marginTop:'2px', 
                    },
                  },
                  MenuListProps: {
                    sx: {
                      padding: '0px'
                    },
                  },
                }}  
                value={
                  isNOTNullOrUndefined(selectedSubTopic) ? selectedSubTopic : ''
                }
                IconComponent={props => (
                  <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
                )}
                onChange={handleChangeSubtopic}
                sx={{
                  width: '150px',    
                  height: '30px',    
                  padding: '10px', 
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },
                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#e7ab3c', 
                  }
                }}
                className='dropdownSelect'
              >
                {selectedSubject?.subTopics?.map(item => (
                  <MenuItem
                    // value={item.id}
                    // key={item.id}
                    // className={dashboardContentStyles.menu_item}
                    value={item?.id}
                    key={item.id}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      fontSize:'10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    {item?.subTopic}
                  </MenuItem>
                ))}
              </Select>
          </FormControl>
        }


        {/* <FormControl
          sx={{
            width: '113px',
            height: 45
          }}
        >
          <InputLabel sx={labelStyle} id='demo-simple-select-label'>
            Chapter
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            input={<OutlinedInput label='Chapter' />}
            MenuProps={MenuProps}
            style={{ height: '100%', background: 'white', borderRadius: 150 }}
            size={'small'}
            sx={filterStyle}
            onChange={handleChangeChapter}
            value={
              isNOTNullOrUndefined(selectedChapter?.id)
                ? selectedChapter?.id
                : ''
            }
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
          >
            {chapterList?.map(item => (
              <MenuItem
                // value={item.id}
                // key={item.id}
                // className={dashboardContentStyles.menu_item}
                key={item.id}
                value={item.id}
                className={
                  dashboardContentStyles.menu_item
                }
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: 'orange',
                    color: "#FFFFFF"
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: '#fcedc2',
                    color: "#000000"
                  },
                  "&:not(.Mui-selected):hover": {
                    backgroundColor: '#fcedc2',
                    color: '#000000',
                  },
                }}
              >
                {item?.chapter}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <FormControl fullWidth>
          <InputLabel  sx={{
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
            }}  id='demo-simple-select-label' className='dropDownLabel'>
            Chapter
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            input={<OutlinedInput label='Chapter' />}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '150px',
                  marginTop:'2px', 
                  maxHeight:'190px',
                  overflowY: 'auto',   // Enable scrolling if content exceeds maxHeight
              '&::-webkit-scrollbar': {
                width: '2px', // Width of the scrollbar
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1', // Background color of the scrollbar track
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888', // Color of the scrollbar thumb
                borderRadius: '4px', // Rounded corners for the scrollbar thumb
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555', // Color of the scrollbar thumb when hovered
              },
                },
              },
              MenuListProps: {
                sx: {
                  padding: '0px',
                  overflowY: 'auto',
                },
              },
            }}  
            onChange={handleChangeChapter}
            value={
              isNOTNullOrUndefined(selectedChapter?.id)
                ? selectedChapter?.id
                : ''
            }
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            sx={{
              width: '150px',    
              height: '30px',    
              padding: '10px', 
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e7ab3c', 
              }
            }}
            className='dropdownSelect'
          >
            {chapterList?.map(item => (
              <MenuItem
                // value={item.id}
                // key={item.id}
                // className={dashboardContentStyles.menu_item}
                key={item.id}
                value={item.id}
                className={
                  dashboardContentStyles.menu_item
                }
                sx={{
                  fontSize:'10px',
                  backgroundColor: 'white',
                  '&:hover': {
                    backgroundColor: '#f4d788',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#e7ab3c',
                    '&:hover': {
                      backgroundColor: '#f4d788',
                    },
                  },
                }}
              >
                {item?.chapter}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <FormControl
          sx={{
            width: '113px',
            height: 45
          }}
        >
          <InputLabel sx={labelStyle} id='demo-simple-select-label'>
            Selected Year
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            input={<OutlinedInput label='Selected year' />}
            MenuProps={MenuProps}
            style={{ height: '100%', background: 'white', borderRadius: 150 }}
            size={'small'}
            value={selectedAcademicYear}
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            sx={filterStyle}
            disabled
          >
            <MenuItem
              // value={academicYearList[0]?.id}
              // key={academicYearList[0]?.id}
              // className={dashboardContentStyles.menu_item}
              value={academicYearList[0]?.id}
              key={academicYearList[0]?.id}
              className={
                dashboardContentStyles.menu_item
              }
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
            >
              <ListItemText primary={academicYearList[0]?.academicYear} />
            </MenuItem>
          </Select>
        </FormControl> */}

        <FormControl fullWidth>
          <InputLabel  sx={{
              top: '-10px',
              '&.MuiInputLabel-shrink': {
                top: '0',
              },
            }} 
            id="demo-simple-select-label" className='dropDownLabel'>
            Academic Year
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            input={<OutlinedInput label='Academic Year' />}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: '150px',
                  marginTop:'2px', 
                },
              },
              MenuListProps: {
                sx: {
                  padding: '0px'
                },
              },
            }}  
            value={selectedAcademicYear}
            IconComponent={props => (
              <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
            )}
            disabled
            sx={{
              width: '150px',    
              height: '30px',    
              padding: '10px', 
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e7ab3c', 
              },
            }}
            className='dropdownSelect'
          >
            <MenuItem
              // value={academicYearList[0]?.id}
              // key={academicYearList[0]?.id}
              // className={dashboardContentStyles.menu_item}
              value={academicYearList[0]?.id}
              key={academicYearList[0]?.id}
              className={
                dashboardContentStyles.menu_item
              }
              sx={{
                fontSize:'10px',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: '#f4d788',
                },
                '&.Mui-selected': {
                  backgroundColor: '#e7ab3c',
                  '&:hover': {
                    backgroundColor: '#f4d788',
                  },
                },
              }}
            >
              <ListItemText primary={academicYearList[0]?.academicYear} />
            </MenuItem>
          </Select>
        </FormControl>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          height: '100%'
        }}
      >
        <div style={{ fontWeight: '600', fontSize: 18 }}>
          All Quiz For this Chapter {allQuizData?.length}
        </div>
        {isLoadingList ? (
          <div>Fetching....</div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '1rem',
              padding: '30px'
            }}
          >
            {isNOTNullOrUndefined(selectedGrade) &&
              isNOTNullOrUndefined(selectedSubject) &&
              isNOTNullOrUndefined(selectedSection) &&
              isNOTNullOrUndefined(selectedChapter?.id) ? (
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  {allQuizData?.length > 0 ? (
                    allQuizData
                    .sort((a, b) => {
													
                      if (a.quizType === b.quizType) {
                    
                        return a.name.localeCompare(b.name);
                      }
                      
                      return a.quizType === "Unit Quiz" ? -1 : 1;
                      })
                    .map(item => (
                      <QuizCard
                        key={item.id}
                        setQuizType={setQuizType}
                        item={item}
                        onPreviewClick={onPreviewClick}
                        onReleaseClick={() => onReleaseClick(item)}
                        OnReRelease={() => OpenReRelease(item)}
                        isActive={isActive}
                        from={"quizcardviewoptimised"}
                        callbacktochangeExitStatus={callbacktochangeExitStatus}
                      />
                    ))
                  ) : (
                    <div style={{ color: 'red' }}>
                      No Quiz for selected Filters
                    </div>
                  )}
                </Grid>
              </Box>
            ) : (
              <div>Please do select Grade , Section , Subject and Chapter</div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default QuizCardViewOptimised
