import React from "react";
import ImageUploading from "react-images-uploading";
import createschool from "./../css/CreateSchool.module.css";

function UploadImages() {
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    
    setImages(imageList);
  };

  return (
    <>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <>
            <button
              className={createschool.choosefile_button}
              id={createschool.choose_button_id}
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            >
              Choose File
            </button>
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            {imageList.map((image, index) => (
              <div key={index} className={createschool.image_item}>
                <img src={image.data_url} alt="school logo" width="60" />
                <div className={createschool.image_item__btn_wrapper}>
                  <p>{image.file.name}</p>
                  {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                  <button onClick={() => onImageRemove(index)}>Remove</button>
                </div>
              </div>
            ))}
          </>
        )}
      </ImageUploading>
    </>
  );
}

export default UploadImages;
