export const columns = [
    { field: 'subject', headerName: 'Subject', width: '15%' },
    {
      field: 'lessonPlan',
      headerName: 'Lesson Plan',
      width: '8.5%',
      sortable: true,
      category: 'teacher'
    },
    {
      field: 'teacherRevisionModule',
      headerName: 'Revision Module',
      width: '8.5%',
      sortable: true,
      category: 'teacher'
    },
    {
      field: 'rmLock',
      headerName: 'Rm Lock',
      width: '8.5%',
      sortable: true,
      category: 'teacher'
    },
    {
      field: 'assessmentModule',
      headerName: 'Assessment Module',
      width: '8.5%',
      sortable: true,
      category: 'teacher'
    },
    {
      field: 'activities',
      headerName: 'Activities',
      width: '8.5%',
      sortable: true,
      category: 'teacher'
    },
    {
      field: 'wsDownload',
      headerName: 'WS Download',
      width: '8.5%',
      sortable: true,
      category: 'teacher'
    },
    {
      field: 'wbDownload',
      headerName: 'WB Download',
      width: '8.5%',
      sortable: true,
      category: 'teacher'
    },
    {
      field: 'newsStories',
      headerName: 'News & Stories',
      width: '8.5%',
      sortable: true,
      category: 'teacher'
    },
    {
      field: 'studentBooklet',
      headerName: 'Student Booklet',
      width: '8.5%',
      sortable: true,
      category: 'student'
    },
    {
      field: 'studentRevisionModule',
      headerName: 'Revision Module',
      width: '8.5%',
      sortable: true,
      category: 'student'
    }
  ]