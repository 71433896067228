import React, { useRef ,useEffect} from 'react';
import './HorizontalScrollContainer.css'; // Create a CSS file for styling
import useShowScrollButtons from '../../hooks/quiz/useShowScrollButtons';
import scroll_btn from '../../img/scroll_btn.png'
import { useIsOverflow } from '../../hooks/quiz/useIsOveflow';
function HorizontalScrollContainer({ children ,data}) {
  const containerRef = useRef(null);
  // const { showPrevButton, showNextButton } = useShowScrollButtons(containerRef);
  const isOverflow = useIsOverflow(containerRef);



  const handleScrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 100; // Adjust the scroll distance as needed
    }
  };

  const handleScrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 100; // Adjust the scroll distance as needed
    }
  };


  useEffect(()=>{
    if (containerRef.current) {
      containerRef.current.scrollLeft = 0; // Adjust the scroll distance as needed
    }
  },[data])

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {isOverflow ? <button className="scroll-button scrl-btn-left" onClick={handleScrollLeft}>
        {/* &lt; */}
        <img src={scroll_btn} style={{ display: 'block', width: '100%' }} />
      </button> : null}
      {isOverflow ? <button className="scroll-button scrl-btn-right" onClick={handleScrollRight}>
        {/* &gt; */}
        <img src={scroll_btn} style={{ display: 'block', width: '100%' }} />
      </button> : null}
      <div className="horizontal-scroll-container">
        <div className="scroll-content" ref={containerRef}>
          {children}
        </div>
      </div>
    </div>


  );
}

export default HorizontalScrollContainer;
