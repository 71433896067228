import axios from 'axios'

async function concept_table_get(setConceptTable,search,subjectId, rowsInput, page, setPageDetail){
    let token = sessionStorage.getItem("token")
    if(subjectId){
        var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/all?search=${search.search}&subjectId=${subjectId}&pageNumber=${page - 1}&pageSize=${rowsInput?.rows}`
    } else {
        var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/all?search=${search.search}&pageNumber=${page - 1}&pageSize=${rowsInput?.rows}`
    }
    let result =  await axios.get(URL ,
    {
        headers : {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        if(res.data){
            setConceptTable(res.data.data.data);
            setPageDetail(res.data.data)
        }
    })
    .catch(err => console.log(err))
}

export default concept_table_get;