import axios from 'axios'

function document_category_student_get(setDocCategory) {
  let token = sessionStorage.getItem("token");
  
    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/document-category/all?role=Student`, 
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      console.log(json.data,"9999")
      setDocCategory(json.data.data)
    })
    .catch(err=>console.log(err))
}

export default document_category_student_get;
