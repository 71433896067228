import axios from 'axios'

async function User_Schools_Addget(
  rowsInput,
  setSchoolListData,
  setPageDetail,
  page,

  search
) {
  let token = sessionStorage.getItem("token");
    let url = `${
        process.env.REACT_APP_SWAGGER_URL
       }v1/api/user/schools?pageNumber=${page - 1}&pageSize=${
         rowsInput.rows
       }&sortBy=id&order=true&search=${search.search}`
    let result = await axios(url,{
      method: 'GET',
      mode: 'cors',
       credentials: "include",
      headers: {
        // Access-Control-Request-Headers: 'Content-Type, Authorization',
        Accept: "application/json",
        // Accept: '/',
        Authorization: `Bearer ${token}`,
        // "Content-Type": "multipart/form-data",
        // 'Origin': 'https://localhost:5502'
      },
    })
    .then((resp)=>{
      if(resp.data.data){
        setSchoolListData(resp.data.data.data)
          setPageDetail(resp.data.data)
      }

    })
    .catch(err=>console.log(err))
}

export default User_Schools_Addget;
