import axios from 'axios'

async function Pincodes_Controller_getall(setPinCode, CityInputs) {
  let token = sessionStorage.getItem("token");
  let Stateid = CityInputs.stateId;


    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/pincodes/all?stateId=${Stateid}`,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setPinCode(json.data.data)
    })
    .catch(err=>console.log(err))
}

export default Pincodes_Controller_getall;
