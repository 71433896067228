import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import dashboardContentStyles from "../../../css/dashboardContent.module.css";
import queSelectionStyles from "../../../css/queSelectionStyles.module.css";
import quebankStyle from "../../../css/questionbank.module.css";
import GradesControllerGetall from "../../../API_CONTROLLER/grades-controller/grades_controller_getall";
import boards_controller_getall from "../../../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../../../API_CONTROLLER/subject_controller/subjects_controller_getall";
import dashHome from "../../../../../src/img/dashHome.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIconButton from "../../../../components/common/DeleteIconButton";
import { previewData } from "./dummydata";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import styled from "styled-components";

import {
	Box,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	Tooltip,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	TextField,
} from "@mui/material";
import QuestionsHead from "./QuestionHead";
import SearchBox from "./SearchBox";
import { Cancel } from "@mui/icons-material";
import CreateQuizModal from "./Modals/CreateQuizModal";
import PreviewModal from "./Modals/PreviewModal";
import { useQuestionType } from "../../../../hooks/quiz/useQuestionType";
import { useTaxonomy } from "../../../../hooks/quiz/useTaxonomy";
import { useConceptList } from "../../../../hooks/quiz/useConceptList";
import SubConcept from "./SubConcept";
import { useSelector, useDispatch } from "react-redux";
import {
	order_selected_question,
	reset_quiz_creation,
	reset_selected_questions,
	unselect_question,
} from "../../../../actions/quizAction";
import useFetch from "../../../../hooks/quiz/useFetch";
import { isNOTNullOrUndefined, truncateString } from "../../../../utils/helper";
import QuizSucessModal from "./QuizSucessModal";
import debounce from "lodash.debounce";
import QuestionTypeDropDown from "./QuestionTypeDropDown";
import TaxonomyDropDown from "./TaxonomyDropDown";
import Concept from "./Concept";
import UpdatedAt from "../UpdatedAt";
import MessageModal from "./MessageModal";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const DiagnosticQuizCreation = () => {
	const [questiontypeId, setquestiontypeId] = useState("");
	const [taxonomySelected, setTaxonomySelected] = useState("");
	const [searchQuery, setSearchQuery] = useState("");
	const [showPreviewModal, setShowPreviewModal] = useState(false);
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const [openSuccessModal, setOpenSuccessModal] = useState(false);
	const [showMessageModal, setShowMessageModal] = useState(false);

	const { selected_questions, total_selected_marks, quiz_type } = useSelector(
		(state) => state.quiz
	);

	const [searchParams] = useSearchParams();
	const isEditFlow = searchParams.get("isEdit");
	const isEditedScore = searchParams.get("score");

	const local_data = JSON.parse(localStorage.getItem("quiz_data"));

	if (local_data && isEditFlow) {
		local_data.score = isEditedScore;
		localStorage.setItem("quiz_data", JSON.stringify(local_data));
	}

	const { selected_chapters = [] } = local_data || [];

	const boardId = local_data?.selected_board?.id;
	const gradeId = local_data?.selected_grade?.id;
	const subjectId = local_data?.selected_subject?.id;
	const chapterId = local_data?.selected_chapters[0]?.id;
	const subTopicId = local_data?.selected_sub_topic?.id;

	const dispatch = useDispatch();
	let ViewQuizData = sessionStorage.getItem("questionIdView");

	const btnStyle = {
		height: 40,
		width: 176,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "40px",
	};

	const cancelBtnStyle = {
		height: 40,
		width: 176,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "40px",
		borderWidth: 1,
		background: "#fff",
		borderColor: "#848888",
		cursor: "pointer",
	};

	const unSelectTopic = (selected) => {
		dispatch(unselect_question(selected));
	};

	const handleOpen = () => {
		setOpenCreateModal(true);
	};
	const handleClose = () => {
		setOpenCreateModal(false);
	};

	const onSubmitSuccess = () => {
		setOpenCreateModal(false);
		setOpenSuccessModal(true);
	};

	const handleSuccessModalClose = () => {
		setOpenSuccessModal(false);
		dispatch(reset_quiz_creation());
	};

	const prev = {
		totalQuestions: selected_questions.length,
		name: local_data?.quiz_name,
		quizQuestions: selected_questions.map((question) => {
			return {
				id: question?.id,
				questionType: question?.questionType,
				questionTypeCode: question?.questionTypeCode,
				question: question?.question,
				imageUrl: question?.imageUrl,
				hasSubQuestions: question?.hasSubQuestions,
				options: question?.options,
				explanation: question?.explanation,
			};
		}),
	};

	const inputRef = useRef();

	const debouncedSearch = React.useRef(
		debounce(async (criteria) => {
			setSearchQuery(criteria);
		}, 300)
	).current;

	React.useEffect(() => {
		return () => {
			debouncedSearch.cancel();
		};
	}, [debouncedSearch]);
	const [search, setSearch] = useState("");
	async function handleChange(e) {
		debouncedSearch(e.target.value);
		setSearch(e.target.value);
	}

	const filteredSearch = selected_chapters?.filter((item) =>
		item.chapter.toLowerCase().includes(searchQuery.toLowerCase())
	);

	useEffect(() => {
		if (questiontypeId?.code === "CASE") {
			setTaxonomySelected("");
		}
	}, [questiontypeId]);

	const onPreviewSubmitClick = () => {
		setShowPreviewModal(false);

		if (total_selected_marks === parseInt(local_data?.score)) {
			setOpenCreateModal(true);
		} else {
			setShowMessageModal(true);
		}
	};

	const storedData = localStorage.getItem("editedData");
	const editedQuizData = JSON.parse(storedData);

	const handleCreateQuizClick = () => {
		// if (total_selected_marks === parseInt(editedQuizData?.totalMarks)) {
		// 	setOpenCreateModal(true);
		// } else if (total_selected_marks === parseInt(local_data?.score)) {
		// 	setOpenCreateModal(true);
		// } else {
		// 	setShowMessageModal(true);
		// }
		if (editedQuizData?.length > 0) {
			if (total_selected_marks === parseInt(editedQuizData?.totalMarks)) {
				setOpenCreateModal(true);
			} else {
				setShowMessageModal(true);
			}
		} else if (local_data) {
			console.log(total_selected_marks, "total");
			console.log(local_data?.score, "local");
			if (total_selected_marks === parseInt(local_data?.score)) {
				setOpenCreateModal(true);
			} else {
				setShowMessageModal(true);
			}
		} else {
			setShowMessageModal(true);
		}
	};

	const [isExpanded, setIsExpanded] = useState(true);

	const [items, setItems] = useState([]);

	useEffect(() => {
		setItems(selected_questions)
	}, [selected_questions])

	const onDragEnd = (result) => {
		if (!result.destination) return;
		const newItems = [...items];
		const [removed] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, removed);
		setItems(newItems);
		dispatch(order_selected_question(newItems.flat()))
	};

	return (
		<>
			<div style={{ width: "100%", backgroundColor: "#f3f2ff" }}>
				{/* article start  */}
				<article>
					<div
						className={dashboardContentStyles.dashboard_link}
						style={{ position: "relative" }}
					>
						<span className={dashboardContentStyles.link_icon}>
							<img
								src={dashHome}
								alt="no img"
								width="15px"
								height="20px"
								style={{ verticalAlign: "middle" }}
							/>
						</span>
						{"  "}
						<span className={dashboardContentStyles.link_text}>Home</span>
						{"  "}
						<span>
							<i className="fa-solid fa-angle-right"></i>
						</span>
						<span>
							<i className="fa-solid fa-angle-right"></i>
						</span>
						{"  "}
						<a>{ViewQuizData ? "Update Quiz" : "Create quiz"}</a>
						<UpdatedAt />
					</div>
				</article>
				{/* article end  */}
				{/* drop down search row start  */}
				<div
					style={{
						marginTop: "55px",
						marginInline: "15px",
						display: "flex",
						alignItems: "center",
						position: "relative",
					}}
				>
					<div className={queSelectionStyles.breadcrumbs}>
						{quiz_type} -
						<Tooltip title={local_data?.selected_board?.board}>
							<p> {truncateString(local_data?.selected_board?.board, 8)} </p>
						</Tooltip>
						\
						<Tooltip title={local_data?.selected_grade?.grade}>
							<p> {truncateString(local_data?.selected_grade?.grade, 8)} </p>
						</Tooltip>
						\
						<Tooltip title={local_data?.selected_subject?.subject}>
							<p>
								{" "}
								{truncateString(local_data?.selected_subject?.subject, 8)}{" "}
							</p>
						</Tooltip>
					</div>
					<TaxonomyDropDown
						taxonomySelected={taxonomySelected}
						questiontypeId={questiontypeId}
						setTaxonomySelected={(event) =>
							setTaxonomySelected(event.target.value)
						}
					/>
					<QuestionTypeDropDown
						questiontypeId={questiontypeId}
						setquestiontypeId={(event) => setquestiontypeId(event.target.value)}
					/>
					<div className={queSelectionStyles.totalScore}>
						Total Quiz Marks: {total_selected_marks}{" "}
					</div>
				</div>

				<Paper
					sx={{ width: "100%", display: "flex", height: "70vh" }}
					style={{
						paddingTop: "15px",
						arginInline: "15px",
						backgroundColor: "#f3f2ff",
					}}
				>
					<div
						className={quebankStyle.shadow_container}
						style={{
							overflow: "hidden",
							height: "100%",
							backgroundColor: "#fff",
						}}
					>
						<div
							style={{
								height: "50px",
								width: "100%",
								background: "#d9dcfe",
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								paddingLeft: "20px",
								paddingRight: "20px",
							}}
						>
							<Box sx={{ minWidth: 150, width: "50%" }}>
								<FormControl
									variant="filled"
									fullWidth
									size="small"
									sx={{
										borderBottomWidth: 0,
										background: "#d9dcfe",
									}}
								>
									<InputLabel
										id="demo-select-small"
										sx={{ fontSize: 14, color: "#0d26f4" }}
									>
										Selected Chapters
									</InputLabel>
								</FormControl>
							</Box>
							<InputCover>
								<Input
									type="search"
									placeholder="Enter your search"
									onChange={handleChange}
								/>

								<SearchIcon>
									<SearchOutlinedIcon />
								</SearchIcon>
							</InputCover>
						</div>
						<div style={{ overflowY: "auto", height: "90%" }}>
							{selected_chapters && selected_chapters.length > 0 ? (
								selected_chapters.map((chapter) => {
									return (
										<li
											className={queSelectionStyles.accordian_main}
											key={chapter.id}
										>
											<Accordion
												sx={{
													background: "#EDFDEE",
												}}
												onChange={(e, expanded) => setIsExpanded(expanded)}
												expanded={setIsExpanded}
											>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls="panel1a-content"
													id="panel1a-header"
												>
													<a
														className={quebankStyle.plan_container_link}
														style={{
															color: "#0A693D",
														}}
													>
														<span
															className={queSelectionStyles.sublink_text}
															style={{
																color: "#0A693D",
															}}
														>
															{chapter.chapter}
														</span>
													</a>
												</AccordionSummary>
												<Concept
													questiontypeId={questiontypeId}
													taxonomySelected={taxonomySelected}
													chapterId={chapter.id}
													isExpanded={isExpanded}
													search={search}
												/>
											</Accordion>
										</li>
									);
								})
							) : (
								<div style={{ color: "red" }}>No data for selected filters</div>
							)}
						</div>
					</div>
					<div
						className={quebankStyle.shadow_container}
						style={{
							overflowY: "hidden",
							height: "100%",
							position: "relative",
							background: "#fff",
							paddingBottom: 200,
						}}
					>
						<div id="PrevCreate"
							style={{
								display: "flex",
								alignIems: "center",
								justifyContent: "space-between",
								position: "absolute",
								bottom: 0,
								width: "100%",
								background: "#fff",
								padding: 20,
								zIndex: 1,
							}}
						>
							<button
								onClick={() => dispatch(reset_quiz_creation())}
								style={cancelBtnStyle}
							>
								<div
									style={{
										fontSize: 14,
										color: "#848888",
										textTransform: "capitalize",
									}}
								>
									Cancel
								</div>
							</button>
							<Button
								sx={{ borderRadius: 100 }}
								disabled={selected_questions?.length > 0 ? false : true}
								style={
									(btnStyle,
									{
										backgroundColor:
											selected_questions?.length > 0 ? "#FD8C00" : "#ccc",
									})
								}
								onClick={() => setShowPreviewModal(true)}
							>
								<div
									style={{
										fontSize: 14,
										color: "#fff",
										textTransform: "capitalize",
									}}
								>
									Preview
								</div>
							</Button>
							<Button
								sx={{ borderRadius: 100 }}
								onClick={() => handleCreateQuizClick()}
								disabled={selected_questions?.length > 0 ? false : true}
								style={
									(btnStyle,
									{
										backgroundColor:
											selected_questions?.length > 0 ? "#001AF3" : "#ccc",
									})
								}
							>
								<div
									style={{
										fontSize: 14,
										color: "#fff",
										textTransform: "capitalize",
									}}
								>
									{ViewQuizData ? "Update Quiz" : "Create Quiz"}
								</div>
							</Button>
						</div>
						<div>
							<QuestionsHead totalQues={selected_questions?.length} />
							<div
								style={{
									overflowY: "auto",
									height: 440,
									paddingBottom: 200,
								}}
							>
								{selected_questions?.length > 0 ? <DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId="droppable">
										{(provided) => (
											<ul {...provided.droppableProps} ref={provided.innerRef}>
												{items.map((item, index) => (
													<Draggable key={item.id} draggableId={item.id} index={index}>
														{(provided) => (
															<li
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																ref={provided.innerRef}
																className={quebankStyle.plan_links_items}
																key={item.id}
															>
																<a className={quebankStyle.plan_container_link}>
																	<span className={quebankStyle.plan_link_text}>
																		{/* {item.question.replace(
																			/<[^>]+>|(&nbsp;|&ldquo;|&rdquo;|&ndash;)/gi,
																			""
																		)} */}
																		<div style={{display:'flex', gap:'10px'}}><p
																			className="head_3"
																			dangerouslySetInnerHTML={{ __html: item?.question }}
																		></p><span>{`(${item?.marks})`}</span>
																		</div>
																	</span>
																	<span
																		style={{
																			marginRight: "-34px",
																		}}
																	>
																		<DeleteIconButton
																			onClick={() => {
																				unSelectTopic(item);
																			}}
																		>
																			<Tooltip title="Remove" placement={"top"}>
																				<Cancel color={"#DB3705"} />
																			</Tooltip>
																		</DeleteIconButton>
																	</span>
																</a>
															</li>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</ul>
										)}
									</Droppable>
								</DragDropContext> : ""}
							</div>
						</div>
					</div>
				</Paper>
				{selected_questions?.length > 0 && openCreateModal ? (
					<CreateQuizModal
						open={openCreateModal}
						handleClose={handleClose}
						onSubmitSuccess={onSubmitSuccess}
					/>
				) : null}

				{selected_questions?.length > 0
					? showPreviewModal && (
						<PreviewModal
							data={prev}
							open={showPreviewModal}
							close={() => setShowPreviewModal(false)}
							onSubmitClick={() => onPreviewSubmitClick()}
						/>
					)
					: null}

				<QuizSucessModal
					open={openSuccessModal}
					onClose={() => handleSuccessModalClose()}
					title={"You have successfully created a quiz"}
					quiz_name={local_data?.quiz_name}
					total_question={selected_questions?.length}
				/>

				<MessageModal
					message={
						"The marks of the selected questions should be equal to the total marks."
					}
					open={showMessageModal}
					onClose={() => setShowMessageModal(false)}
				/>
			</div>
		</>
	);
};

export default DiagnosticQuizCreation;
const InputCover = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 40%;
  padding: 0 20px;
  border: 1px solid #9b9fa7;
  border-radius: 20px;
`;
const Input = styled.input`
  border: none;
  border: none;
  padding: 10px 0;
  flex: 1;
  outline: none;
  :focus,
  :hover {
    border: none;
  }
`;

const SearchIcon = styled.span`
  display: block;
  width: 20px;
  svg {
    width: 100%;
    display: block;
  }
`;