import React, { useEffect, useState } from "react";
import { Button, Grid, Paper } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuProps } from "../../Data";
import { styled } from "@mui/material/styles";
import "../../../css/Student/Student_Enrich_Quiz.css";

const CancelButton = styled(Button)(({ theme }) => ({
	color: "#ed9b00",
	width: "5.2vw",
	backgroundColor: "#fff",
	borderRadius: 150,
	border: "1px solid #757575",
	borderColor: "#ed9b00",
	textTransform: "none",
	fontSize: "0.78vw",
}));

const SaveButton = styled(Button)(() => ({
	color: "#fff",
	width: "5.2vw",
	backgroundColor: "#ed9b00",
	borderRadius: 150,
	fontSize: "0.78vw",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#ed9b00",
		border: 0,
		top: -2,
		boxShadow: "0px 3px 5px #FFA700",
	},
}));

const Item = styled(Paper)(({ theme, quizType }) => ({
	backgroundColor: "#fff",
	...theme.typography.body2,
	color: theme.palette.text.secondary,
	// boxShadow:
	// 	"0px 2px 1px -1px rgba(138,203,249,0.77), 0px 1px 1px 0px rgba(138,203,249,0.77), 0px 1px 3px 0px rgba(138,203,249,0.77)",
	boxShadow:
		quizType === "Practice Quiz"
			? "0px 2px 1px -1px  #8ed4d3, 0px 1px 1px 0px  #8ed4d3, 0px 1px 3px 0px  #8ed4d3"
			: "0px 2px 1px -1px rgba(138,203,249,0.77), 0px 1px 1px 0px rgba(138,203,249,0.77), 0px 1px 3px 0px rgba(138,203,249,0.77)",
	display: "flex",
	flexDirection: "column",
	alignItems: "start",
}));

const QuizCard = ({
	item,
	onPreviewClick,
	onReleaseClick,
	OnReRelease,
	setQuizType,
	isActive,
	from,
	callbacktochangeExitStatus
}) => {
	console.log(from)
	const [taxanomyKey, setTaxonamyKey] = useState([]);

	useEffect(() => {
		const arry = Object.keys(item?.taxonomyPercentage);
		setTaxonamyKey(arry);
	}, [item]);

	function isPastEndTimeAndDate(endTime, endDate) {
		var currentTime = new Date();

		var endHour = parseInt(endTime.split(":")[0]);
		var endMinute = parseInt(endTime.split(":")[1].split(" ")[0]);
		var endMeridian = endTime.split(" ")[1];

		var endDay = parseInt(endDate.split("/")[0]);
		var endMonth = parseInt(endDate.split("/")[1]) - 1;
		var endYear = parseInt(endDate.split("/")[2]);

		var endDateTime = new Date(
			endYear,
			endMonth,
			endDay,
			endMeridian === "PM"
				? endHour !== 12
					? endHour + 12
					: endHour
				: endMeridian === "AM" && endHour === 12
					? (endHour = 1)
					: endHour,
			endMinute
		);
		return currentTime > endDateTime;
	}

	const purchasedFeature = localStorage.getItem("purchasedFeatures");
	const purchasedFeatures = JSON.parse(purchasedFeature);

	const rm_Lock = purchasedFeatures.find(item => item.name === 'rm_lock')

	let toggle_status = rm_Lock.toggleStatus;

	return (
		<Grid item xs={12} md={4} sm={6} key={item.id}>
			<Item quizType={item.quizType}>
				<div
					style={{
						padding: "15px 10px",
						color: "#f19e00",
						fontWeight: 600,
						fontSize: "0.93vw",
					}}
				>
					{item.quizType} | {item.name}
				</div>
				<div
					style={{
						// background: "rgba(138,203,249,0.77)",
						background: item.quizType === "Practice Quiz" ? "#8ed4d3" : "rgba(138,203,249,0.77)",
						width: "100%",
						padding: "20px",
						fontSize: "0.93vw",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							padding: "5px",
						}}
					>
						<div>Questions</div>
						<div style={{ minWidth: "80px" }}>
							{item.totalQuestions}
						</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							padding: "5px",
						}}
					>
						<div>Marks</div>
						<div style={{ minWidth: "80px" }}>
							{item.totalMarks}
						</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							padding: "5px",
						}}
					>
						<div>Duration</div>
						<div style={{ minWidth: "80px" }}>
							{`${item.duration} minutes`}
						</div>
					</div>
				</div>
				<div
					style={{
						// background: "rgba(4,59,189,0.91)",
						background: item.quizType === "Practice Quiz" ? "#3ba5a3" : "rgba(4,59,189,0.91)",
						width: "100%",
						padding: "20px",
						color: "white",
						fontSize: "0.93vw",
					}}
				>
					{taxanomyKey?.map((data) => (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								padding: "5px",
							}}
						>
							<div>{data}</div>
							<div>{item?.taxonomyPercentage[data]}%</div>
						</div>
					))}
				</div>
				{item.containsCaseStudy === true && (
					<div
						style={{
							// background: "rgb(3,38,120)",
							background: item.quizType === "Practice Quiz" ? " #23706f" : "rgb(3,38,120)",
							width: "100%",
							padding: "20px",
							color: "white",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						Contains CaseStudy
					</div>
				)}
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						padding: "10px",
						alignItems: "center",
						width: "100%",
						columnGap: "1rem",
					}}
				>
					<CancelButton onClick={() => onPreviewClick(item)}>
						Preview
					</CancelButton>
					
					{/* {item.quizType === "Practice Quiz"
						? !item.released && toggle_status && isActive && (
							<SaveButton
								onClick={() => {
									onReleaseClick(item);
									setQuizType(item.quizType);
								}}
								style={{ visibility: item.quizType == "Practice Quiz" ? 'hidden' : 'visible' }}
							>
								Release1111
							</SaveButton>
						)
						: !item.allAttended && toggle_status && isActive &&
						(item.endTime !== null ? (
							isPastEndTimeAndDate(
								item.endTime,
								item.endDate
							) &&
							(item.released ? (
								<SaveButton
									onClick={() => {
										onReleaseClick(item);
										setQuizType(item.quizType);
									}}
								>
									ReRelease2222
								</SaveButton>
							) : (
								<SaveButton
									onClick={() => {
										onReleaseClick(item);
										setQuizType(item.quizType);
									}}
								>
									Release3333
								</SaveButton>
							))
						) : item.released ? (
							<SaveButton
								onClick={() => {
									onReleaseClick(item);
									setQuizType(item.quizType);
								}}
							>
								ReRelease4444
							</SaveButton>
						) : (
							<SaveButton
								onClick={() => {
									onReleaseClick(item);
									setQuizType(item.quizType);
								}}
							>
								Release5555
							</SaveButton>
						))}
					{
						<></>
					} */}
					{/* refacored on 19-7-24 */}
					{item.quizType === "Practice Quiz" ? (
						!item.released && toggle_status && isActive && (
							<SaveButton
								onClick={() => {
									onReleaseClick(item);
									setQuizType(item.quizType);
								}}
								style={{ visibility: item.quizType === "Practice Quiz" ? 'hidden' : 'visible' }}
							>
								Release
							</SaveButton>
						)
					) : (
						!item.allAttended && toggle_status && isActive && (
							item.endTime !== null ? (
								isPastEndTimeAndDate(item.endTime, item.endDate) ? (
									item.released ? (
										<SaveButton
											onClick={() => {
												onReleaseClick(item);
												setQuizType(item.quizType);
											}}
										>
											ReRelease
										</SaveButton>
									) : (
										<SaveButton
											onClick={() => {
												onReleaseClick(item);
												setQuizType(item.quizType);
											}}
										>
											Release
										</SaveButton>
									)
								) : (
									item.released ? (
										<SaveButton
											onClick={() => {
												onReleaseClick(item);
												setQuizType(item.quizType);
											}}
										>
											ReRelease
										</SaveButton>
									) : (
										<SaveButton
											onClick={() => {
												onReleaseClick(item);
												setQuizType(item.quizType);
											}}
										>
											Release
										</SaveButton>
									)
								)
							) : (
								item.released ? (
									<SaveButton
										onClick={() => {
											onReleaseClick(item);
											setQuizType(item.quizType);
										}}
									>
										ReRelease
									</SaveButton>
								) : (
									<SaveButton
										onClick={() => {
											onReleaseClick(item);
											setQuizType(item.quizType);
										}}
									>
										Release
									</SaveButton>
								)
							)
						)
					)}
					<></>

				</div>
			</Item>
		</Grid>
	);
};

export default QuizCard;
