import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PlayLessonOutlinedIcon from "@mui/icons-material/PlayLessonOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import NotStartedOutlinedIcon from "@mui/icons-material/NotStartedOutlined";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import CategoryIcon from "@mui/icons-material/Category";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import { makeStyles } from "@material-ui/core/styles";
import mindMap from "../../../src/img/mind.png";
import videoIcn from "../../../src/img/lms_video.png";
import workBook from "../../../src/img/lms_book.png";
import presentation from "../../../src/img/Presentation.png";
import { useEffect } from "react";

const SideMenu = ({
  props,
  menu,
  menuType,
  menuClickHandler,
  subMenuHandler,
  collapseId,
  startIndex,
  selectedUrl,
  menuRevisionClickHandler,
  subMenuHandlerRev,
}) => {
  const [showIcon, setShowIcon] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: showIcon ? 80 : 250,
      backgroundColor: "#1a1588",
      color: "white",
      height: "100%",
      minHeight: "100%",
      whiteSpace: "nowrap",
      overflowY: "auto",
      overflowX: "hidden",
    },
    nested: {
      paddingLeft: theme.spacing(4),
      "&:hover": {
        color: "white",
        backgroundColor: "#3833d5",
        borderTopRightRadius: "150px",
        borderBottomRightRadius: "150px",
      },
    },
    nestedSecondLevel: {
      paddingLeft: theme.spacing(8),
    },
  }));
  const classes = useStyles();
  const trimStringHandler = (value) => {
    return value?.replace(/ /g, "");
  };
  useEffect(() => {
    if (menu.length > 0) {
      menuClickHandler(menu[0].documentTypeId, menu[0].documentType, menu[0], 0)
    }
  }, [menu])
  const IconHandler = (type, index, url) => {
    const filter = trimStringHandler(type);
    return filter?.includes(trimStringHandler("TextBook")) ||
      filter?.includes(trimStringHandler("Book"))
      ? ""
      : filter?.includes(trimStringHandler("MindMap"))
        ? ""
        : filter?.includes(trimStringHandler("video")) ||
          filter?.includes(trimStringHandler("mpeg")) ||
          filter?.includes(trimStringHandler("avi")) ||
          filter?.includes(trimStringHandler("mpg")) ||
          filter?.includes(trimStringHandler("webm")) ||
          filter?.includes(trimStringHandler("mp4"))
          ? ""
          : filter?.includes(trimStringHandler("Workbook"))
            ? ""
            : filter?.includes(trimStringHandler("PRESENTATION")) ||
              filter?.includes(trimStringHandler("xlsx")) ||
              filter?.includes(trimStringHandler("docx")) ||
              filter?.includes(trimStringHandler("doc")) ||
              filter?.includes(trimStringHandler("pdf")) ||
              filter?.includes(trimStringHandler("xls")) ||
              filter?.includes(trimStringHandler("ppt")) ||
              filter?.includes(trimStringHandler("pptx"))
              ? ""
              : filter?.includes(trimStringHandler("lessonPlan"))
                ? ""
                : filter?.includes(trimStringHandler("jpg")) ||
                  filter?.includes(trimStringHandler("png")) ||
                  filter?.includes(trimStringHandler("jpeg")) ||
                  filter?.includes(trimStringHandler("svg")) ||
                  filter?.includes(trimStringHandler("bmp")) ||
                  filter?.includes(trimStringHandler("tif")) ||
                  filter?.includes(trimStringHandler("tiff")) ||
                  filter?.includes(trimStringHandler("raw")) ||
                  filter?.includes(trimStringHandler("image"))
                  ? ""
                  : filter?.includes(trimStringHandler("pdf")) ||
                    filter?.includes(trimStringHandler(".pdf"))
                    ? ""
                    : filter?.includes(trimStringHandler("doc"))
                      ? ""
                      : filter?.includes(trimStringHandler("1"))
                        ? ""
                        : filter?.includes(trimStringHandler("xlsx"))
                          ? ""
                          : filter?.includes(trimStringHandler("zip"))
                            ? ""
                            : filter?.includes(trimStringHandler("audio")) ||
                              filter?.includes(trimStringHandler("wav")) ||
                              filter?.includes(trimStringHandler("wma")) ||
                              filter?.includes(trimStringHandler("aac")) ||
                              filter?.includes(trimStringHandler("ogg")) ||
                              filter?.includes(trimStringHandler("mp3"))
                              ? ""
                              : "";
  };
  const nameHandler = (name) => {
    const data = name.split("/")?.[name.split("/").length - 1]?.split(".");
    const first = data;
    var str = first[0];
    return str.split(' ').map(word => word.split('-').map(segment => segment.charAt(0).toUpperCase() + segment.slice(1)).join('-')).join(' ');
  };

  const purchasedFeature = localStorage.getItem("purchasedFeatures");
  const purchasedFeatures = JSON.parse(purchasedFeature);

  const activities = purchasedFeatures.find(item => item.name === "activities")

  const toggle_status = activities.toggleStatus;

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          sx={{
            backgroundColor: "#061ca5",
            color: "white",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => setShowIcon(!showIcon)}
        >
          <MenuOutlinedIcon />
        </ListSubheader>
      }
      className={classes.root}
    >
      {menuType === "student"
        ? menu?.map((item, index) => {
          return (
            <div key={item?.documentCategoryId}>
              <ListItem
                button
                key={item?.documentCategoryId}
                id={`az-teacher-documentCategoryId-${index}`}
                onClick={() =>
                  menuClickHandler(item?.documentCategoryId, "", item, index)
                }
              >
                <ListItemIcon>
                  {/* {IconHandler(item?.documentType, index, "")} */}
                  <img src={item.documentUrl} width="20px" height="20px" />
                </ListItemIcon>
                {!showIcon && (
                  <ListItemText
                    primary={item.documentType}
                    sx={{ color: startIndex === index ? "yellow" : "white" }}
                  />
                )}
              </ListItem>

              {item?.studentRevisionContent?.map((sub) => {
                return (
                  <Collapse
                    in={
                      startIndex === index
                        ? true
                        : collapseId === item.documentCategoryId
                    }
                    timeout="auto"
                    unmountOnExit
                    key={sub.id}
                    onClick={() =>
                      subMenuHandlerRev(
                        sub?.contentWithIndux,
                        sub?.contentUrl
                      )
                    }
                  >
                    <List component="div" disablePadding>
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          {IconHandler(
                            sub?.contentWithIndux,
                            "",
                            sub?.contentUrl
                          )}
                        </ListItemIcon>
                        {!showIcon && (
                          <ListItemText
                            id="sub-item1"
                            // primary={nameHandler(sub?.contentWithIndux)}
                            sx={{
                              // color:
                              //   selectedUrl === sub?.contentUrl
                              //     ? "#ffcf09"
                              //     : "white",
                              color: "white",
                            }}
                            key={index}
                            primary={
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  color: "white",
                                  textTransform: "capitalize",
                                  whiteSpace: 'normal',
                                  overflowWrap: 'break-word',
                                }}
                              >
                                {nameHandler(sub?.contentWithIndux)}
                              </Typography>
                            }
                          />
                        )}
                      </ListItem>
                    </List>
                  </Collapse>
                );
              })}
            </div>
          );
        })
        : menu?.map((item, index) => {
          return (
            <div key={item?.documentTypeId}>
              {(item.documentType === "In Class Activities" || item.documentType === "During Class Activities") ? toggle_status ?
                <div
                  className={`studyHeading ${startIndex === index ? "borderLeft" : ""
                    }`}
                  id={`az-teacher-teach1-${item?.documentType}`}
                >
                  <ListItem
                    button
                    key={item?.documentTypeId}
                    id={`az-teacher-documentCategoryId-${index}`}
                    onClick={() =>
                      menuClickHandler(item?.documentTypeId, item?.documentType, item, index)
                    }
                  >
                    <ListItemIcon>
                      {/* {IconHandler(item?.documentType, index, "")} */}
                      <img src={item.documentUrl} width="20px" height="20px" />
                    </ListItemIcon>
                    {!showIcon && (
                      <ListItemText
                        // primary={item.documentType}
                        // sx={{
                        //   color: startIndex === index ? "#ffcf09" : "white",
                        // }}
                        key={index}
                        primary={
                          <Typography
                            style={{
                              fontSize: "13px",
                              color: startIndex === index ? "#ffcf09" : "white",
                              // textTransform: "capitalize",
                              whiteSpace: 'normal',
                              overflowWrap: 'break-word',
                            }}
                          >
                            {item.documentType}
                          </Typography>
                        }
                      />
                    )}
                  </ListItem>
                </div>
                :
                null
                :
                <div
                  className={`studyHeading ${startIndex === index ? "borderLeft" : ""
                    }`}
                  id={`az-teacher-teach2-${item?.documentType}`}
                >
                  
                  <ListItem
                    button
                    key={item?.documentTypeId}
                    id="idddddddddddddd"
                    onClick={() =>
                      menuClickHandler(item?.documentTypeId, item?.documentType, item, index)
                    }
                  >
                    <ListItemIcon>
                      {/* {IconHandler(item?.documentType, index, "")} */}
                      <img src={item.documentUrl} width="20px" height="20px" />
                    </ListItemIcon>
                    {!showIcon && (
                      <ListItemText
                        // primary={item.documentType}
                        // sx={{
                        //   color: startIndex === index ? "#ffcf09" : "white",
                        // }}
                        key={index}
                        primary={
                          <Typography
                            style={{
                              fontSize: "13px",
                              color: startIndex === index ? "#ffcf09" : "white",
                              // textTransform: "capitalize",
                              whiteSpace: 'normal',
                              overflowWrap: 'break-word',
                            }}
                          >
                            {item.documentType}
                          </Typography>
                        }
                      />
                    )}
                  </ListItem>
                </div>}
              {item?.uploadContents?.map((sub) => {
                return (
                  <Collapse
                    in={
                      startIndex === index
                        ? true
                        : collapseId === item.documentTypeId
                    }
                    timeout="auto"
                    unmountOnExit
                    key={sub.id}
                    id={`az-teacher-uploadContents-${sub?.contentWithIndux}`}
                    onClick={() => {
                      subMenuHandler(sub?.contentWithIndux, sub?.contentUrl, { details: sub })
                    }
                    }
                  >
                    <List component="div" disablePadding>
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          {IconHandler(
                            sub?.contentWithIndux,
                            "",
                            sub?.contentUrl
                          )}
                        </ListItemIcon>
                        {!showIcon && (
                          <ListItemText
                            id="sub-item2"
                            // primary={nameHandler(sub?.contentWithIndux)}
                            sx={{
                              // color:
                              //   selectedUrl === sub?.contentUrl
                              //     ? "#ffcf09"
                              //     : "white",
                              color: "white",
                            }}
                            key={index}
                            primary={
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  color: "white",
                                  textTransform: "capitalize",
                                  whiteSpace: 'normal',
                                  overflowWrap: 'break-word',
                                }}
                              >
                                {nameHandler(sub?.contentWithIndux)}
                              </Typography>
                            }
                          />
                        )}
                      </ListItem>
                    </List>
                  </Collapse>
                );
              })}
            </div>
          )
        })}
    </List>
  );
};
export default SideMenu;
