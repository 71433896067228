import React, { useEffect, useState } from 'react'
import CircleProgress from '../../../../components/CircleProgress/CircleProgress'
import './dashboard.css'
import { fetchQuizzesTotal } from '../Apis/Dashboard_Api'
import SemiCircleProgressBar from '../../../../components/CircleProgress/SemiCircleProgress'
import ApexSemiCircleProgress from '../../Teacher/Dashboard/Components/ApexSemiCircleProgress'
import SemiCircleApexChart from '../../../../components/CircleProgress/SemiCircleApexChart'
import { Tooltip } from '@mui/material'
import { truncateString } from '../../../../utils/helper'

const CardV2 = ({
    title,
    subject,
    percent,
    color,
    attempted,
    total,
    noData
}) => {

    if (noData) return <div className='dashboard-top-section-card ' />

    return (
        <div className="dashboard-top-section-card " style={{ flexDirection: "row-reverse", paddingRight: '20px' }}>
            <div className="dcard-left-side">
                <SemiCircleApexChart
                    bgcolor={color}
                    progressValue={percent || 0}
                    height={90} width={125}
                />
                {/* <SemiCircleProgressBar height={90} width={119} value={percent} text={`${percent || 0}%`} color={color} /> */}
                <p className="dashboard-card-count" style={{ marginTop: '0', textAlign: 'center' }}>
                    {`${attempted || 0}/${total || 0}`} 
                </p>
            </div>
            <div className="dcard-right-side">
                <p className="dcard-title">
                    {title}
                </p>
                <Tooltip title={subject}>
                    <p className="dcard-body">
                        Subject : {truncateString(subject, 12)}
                    </p>
                </Tooltip>

            </div>
        </div>
    )
}

export default CardV2