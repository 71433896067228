export const dummy =[
    {
      sectionName: 'Section A',
      startDate: '2023-01-01',
      endDate: '2023-01-30',
      subjects: [
        { subject: 'Painting', percent: 85 },
        { subject: 'Biology', percent: 92 },
        { subject: 'Shakespeare', percent: 71 }
      ]
    },
    {
      sectionName: 'Section B',
      startDate: '2023-01-01',
      endDate: '2023-01-30',
      subjects: [
        { subject: 'Algebra', percent: 67 },
        { subject: 'World War II', percent: 79 }
      ]
    },
    {
      sectionName: 'Section C',
      startDate: '2023-01-01',
      endDate: '2023-01-30',
      subjects: [
        { subject: 'Biology', percent: 58 }
      ]
    },
    {
      sectionName: 'Section A',
      startDate: '2023-01-01',
      endDate: '2023-01-30',
      subjects: [
        { subject: 'Shakespeare', percent: 91 },
        { subject: 'Painting', percent: 76 }
      ]
    },
    {
      sectionName: 'Section B',
      startDate: '2023-01-01',
      endDate: '2023-01-30',
      subjects: [
        { subject: 'Algebra', percent: 55 },
        { subject: 'Biology', percent: 83 },
        { subject: 'World War II', percent: 70 }
      ]
    },
    {
      sectionName: 'Section C',
      startDate: '2023-01-01',
      endDate: '2023-01-30',
      subjects: [
        { subject: 'Shakespeare', percent: 67 }
      ]
    },
    {
      sectionName: 'Section A',
      startDate: '2023-01-01',
      endDate: '2023-01-30',
      subjects: [
        { subject: 'Painting', percent: 79 },
        { subject: 'Biology', percent: 88 },
        { subject: 'Shakespeare', percent: 72 }
      ]
    },
    {
      sectionName: 'Section B',
      startDate: '2023-01-01',
      endDate: '2023-01-30',
      subjects: [
        { subject: 'Algebra', percent: 63 },
        { subject: 'World War II', percent: 77 }
      ]
    },
    {
      sectionName: 'Section C',
      startDate: '2023-01-01',
      endDate: '2023-01-30',
      subjects: [
        { subject: 'Biology', percent: 54 }
      ]
    },
    {
      sectionName: 'Section A',
      startDate: '2023-01-01',
      endDate: '2023-01-30',
      subjects: [
        { subject: 'Shakespeare', percent: 89 },
        { subject: 'Painting', percent: 72 }
      ]
    }
  ]

  export const dummyTasks  = [
    {
      id: 1,
      heading: 'Complete Project Proposal',
      subheading: 'Prepare and finalize the project proposal document',
      priority: 'high',
    },
    {
      id: 2,
      heading: 'Review Code Pull Requests',
      subheading: 'Go through and provide feedback on code pull requests',
      priority: 'medium',
    },
    {
      id: 3,
      heading: 'Schedule Team Meeting',
      subheading: 'Arrange and set up a team meeting for project discussion',
      priority: 'low',
    },
    {
      id: 4,
      heading: 'Write User Documentation',
      subheading: 'Create comprehensive user documentation for the software',
      priority: 'high',
    },
    {
      id: 5,
      heading: 'Test Application on Multiple Devices',
      subheading: 'Perform testing of the application on various devices and platforms',
      priority: 'medium',
    },
    {
      id: 6,
      heading: 'Optimize Website Performance',
      subheading: 'Identify and implement performance optimizations for the website',
      priority: 'low',
    },
    {
      id: 7,
      heading: 'Prepare Presentation Slides',
      subheading: 'Create visually appealing slides for the upcoming presentation',
      priority: 'high',
    },
    {
      id: 8,
      heading: 'Debug Application Issues',
      subheading: 'Investigate and resolve bugs reported in the application',
      priority: 'medium',
    },
    {
      id: 9,
      heading: 'Research New Technologies',
      subheading: 'Explore and evaluate emerging technologies for future projects',
      priority: 'low',
    },
    {
      id: 10,
      heading: 'Create Test Cases',
      subheading: 'Design and document test cases for comprehensive testing',
      priority: 'high',
    },
  ];
  

  export const schoolVsGlobal = [
    {
      school:80,
      global:50
    },
    {
      school:20,
      global:90
    },
    {
      school:80,
      global:100
    },
    {
      school:20,
      global:40
    },
    {
      school:100,
      global:20
    },
    {
      school:20,
      global:30
    },
    {
      school:20,
      global:30
    },
    {
      school:20,
      global:30
    },
    {
      school:20,
      global:30
    },
    {
      school:20,
      global:30
    },
    {
      school:20,
      global:30
    },
    {
      school:20,
      global:30
    },
     {
      school:20,
      global:30
    },
  ]