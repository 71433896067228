import React, { useEffect, useRef, useState } from 'react'
import { fetch_Chapter_Quiz_Key_Api } from './Apis/Chapter_Quiz_Key_Api';
import axios from 'axios';
import moment from 'moment';
import Student_Formal_Assesment_Analysis_Modal from './Student_Formal_Assesment_Analysis_Modal';
import { Link, useNavigate } from 'react-router-dom';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import Student_Enrich_Quiz_Analysis_Modal from './Student_Enrich_Quiz_Analysis_Modal';
import Student_Image_Open_Modal from './Student_Image_Open_Modal';
import { Box, Checkbox, Grid, Radio, Typography } from '@mui/material';
import { register } from '../../../Serviceworker';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import LogoutIcon from '@mui/icons-material/Logout';

const QuizResumeOptimizedComponent = () => {

    const navigate = useNavigate();
    const token = sessionStorage.getItem('token');
    const quizCardData = JSON.parse(sessionStorage.getItem('quizData'));
    const quizId = quizCardData[0]?.quizId;
    const examId = quizCardData[0]?.examinationId;
    const startTime = quizCardData[0]?.startTime;
    const endDate = quizCardData[0]?.endDate;
    const endTime = quizCardData[0]?.endTime;
    const currentDate = moment();
    const formattedTime = currentDate.format('HH:mm');

    const [quizTitle, setQuizTitle] = useState();
    const [quizType, setQuizType] = useState();
    const [quizDuration, setQuizDuration] = useState();
    const [quizQuestions, setQuizQuestions] = useState();
    const [answerSheets, setAnswerSheets] = useState([]);

    const [countdown, setCountdown] = useState('00:00:00');
    const [currentPage, setCurrentPage] = useState(0);
    const questionsPerPage = 1;
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageUrl, setselectedImageUrl] = useState(false);

    const [timeUpPopUp, setTimeUpPopUp] = useState(null);
    const [submitPopUp, setSubmitPopUp] = useState(false);
    const [openAnalysisModel, setOpenAnalysisModal] = useState(false);
    const [loadQuizData, setLoadQuizData] = useState(false);

    const [timeSpentOnQuestions, setTimeSpentOnQuestions] = useState({});
    const [timer, setTimer] = useState(null);
    const [questionStartTime, setQuestionStartTime] = useState(0);

    const [submitQuizMessage, SetsubmitQuizMessage] = useState("Are you sure want to submit ?");
    const [submitSuccess, setSubmitSuccess] = useState(null);
    const [submitFailed, setSubmitFailed] = useState(null);

    const [fullScreenAlert, setFullScreenAlert] = useState(false);
    const [warningPopUp, setWarningPopUp] = useState(false);

    const [questionLoaded, setQuestionLoaded] = useState(false);

    const [ examOver, setExamOver ] = useState(false);

    let interval;


    // format timings functions --------------------------------------
    const formatTimeString = (timeString) => {
        if (timeString) {
            const [hours, minutes, seconds] = timeString?.match(/\d+/g);
            return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
        }
    };

    // Setting last answered question on resume ---------
    const findHighestAnswerOrderPage = (sortedQuestions) => {
        if (sortedQuestions.length === 0) return 0;
        const highestAnswerOrderQuestion = sortedQuestions.reduce((max, question) =>
            question.answerOrder > max.answerOrder ? question : max,
            sortedQuestions[0]
        );
        const highestAnswerOrderIndex = sortedQuestions.indexOf(highestAnswerOrderQuestion);
        return Math.floor(highestAnswerOrderIndex / questionsPerPage);
    };

    // resume data fetch from api ------------------------------------
    useEffect(() => {
        let timeLeft;
        
        const resumeDataFetch = async () => {
            try {

                const serverDateResponse = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/configurations/server-datetime`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    })
                const serverTime = serverDateResponse.data
                const quizEndTime = moment(endTime, 'hh:mm A')
                const remainingTime = quizEndTime.diff(serverTime, 'minutes') + 1;

                let totalSeconds = remainingTime * 60;
                const hours = Math.floor(totalSeconds / 3600);
                const minutes = Math.floor((totalSeconds % 3600) / 60);
                const seconds = totalSeconds % 60;
                const formattedTime = `${hours.toString().padStart(2, '0')} hrs : ${minutes.toString().padStart(2, '0')} mins : ${seconds.toString().padStart(2, '0')} secs`;
                if (totalSeconds < 0) {
                    setCountdown('00 hrs : 00 mins : 00 secs')
                } else {
                    setCountdown(formattedTime)
                }

                const data = await fetch_Chapter_Quiz_Key_Api();

                // quiz resume time calculation----------------------------- 
                // const serverDateResponse = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/configurations/server-datetime`,
                //     {
                //         headers: {
                //             "Content-Type": "application/json",
                //             Authorization: `Bearer ${token}`,
                //         },
                //     })
                // const serverTime = serverDateResponse.data
                // const quizEndTime = moment(endTime, 'hh:mm A')
                // const remainingTime = quizEndTime.diff(serverTime, 'minutes') + 1;

                const resumeQuestions = data?.data?.questionAnswerKeys;
                const sortedQuestions = resumeQuestions.sort((a, b) => a.questionOrder - b.questionOrder)

                // console.log('resume', resumeQuestions)
                // console.log('sorted', sortedQuestions)

                setQuizQuestions(sortedQuestions);
                setQuizType(data?.data?.quizTypeName);
                setQuizTitle(data?.data?.quizName);
                setQuizDuration(remainingTime);
                setQuestionLoaded(true);

                setCurrentPage(findHighestAnswerOrderPage(sortedQuestions))

                sessionStorage.setItem('examiD', examId)

                sortedQuestions?.map((question, index) => {
                    let item = {
                        examId: examId,
                        questionId: question?.questionId,
                        parentQuestionId: question?.parentQuestionId || null,
                        questionTypeCode: question?.questionTypeCode,
                        answer: [],
                        endDate: endDate,
                        endTime: endTime,
                        pqPausedAt: formattedTime || '',
                        startTime: startTime,
                        totalTimeSpend: formatTimeString(question?.totalTimeSpend) || '00:00:00',
                        order: question?.questionOrder,
                        isAnswered: question?.attended
                    }
                    if (question?.questionTypeCode === 'MCQ') {
                        const mcqAnswer = question?.mcsqAnswerByStudent || [];
                        item.answer = mcqAnswer?.map((mcq) => ({
                            order: mcq.order,
                            answerKey: mcq.answerKey,
                            option: mcq.option,
                        }));
                    } else if (question?.questionTypeCode === 'MSQ') {
                        const msqAnswer = question?.mcsqAnswerByStudent || [];
                        item.answer = msqAnswer?.map((msq) => ({
                            order: msq.order,
                            answerKey: msq.answerKey,
                            option: msq.option,
                        }));
                    } else if (question?.questionTypeCode === 'FILL') {
                        const fillAnswer = question?.ftbAnswerByStudent || [];
                        item.answer = fillAnswer
                    } else if (question?.questionTypeCode === 'CASE') {
                        const subQuestions = question?.subQuestions || [];
                        item.answer = subQuestions.map((sub) => {
                            let subAnswer = {};
                            if (sub?.questionTypeCode === 'MCQ' || sub?.questionTypeCode === 'MSQ') {
                                const mcqAnswer = sub?.mcsqAnswerByStudent || [];
                                subAnswer[sub.questionId] = mcqAnswer.map((ans) => ({
                                    order: ans.order,
                                    answerKey: ans.answerKey,
                                    option: ans.option,
                                }));
                            } else if (sub?.questionTypeCode === 'FILL') {
                                const fillAnswer = sub?.ftbAnswerByStudent || [];
                                subAnswer[sub.questionId] = fillAnswer.map((ans) => ({
                                    order: ans.order,
                                    answerKey: ans.answerKey,
                                }));
                            }
                            subAnswer.type = sub?.questionTypeCode;
                            subAnswer.currentQuestion = sub?.questionId;
                            subAnswer.parentQuestionId = sub?.parentQuestionId;
                            return subAnswer;
                        });
                    }
                    answerSheets.push(item);
                    setAnswerSheets(answerSheets);
                });

                startTimer(remainingTime)

            } catch (error) {
                console.error('Error while fetching quiz questions:', error);
            }

        }
        
         const checkExamTime =async() =>{
            const serverDateResponse = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/configurations/server-datetime`,
                {
                    headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            const serverTime = serverDateResponse?.data
            const quizEndTime = moment(endTime, 'hh:mm A');
            timeLeft = quizEndTime.diff(serverTime, 'minutes')+1
            console.log('timeleft',timeLeft);
            if(timeLeft > 0){
                resumeDataFetch();
            }else{
                setExamOver(true);
            }
         }
    checkExamTime();
    }, [])
    // console.log('answersheets', answerSheets);

    // // Fullscreen and copy, paste and event preventions ----------------------------------------
    useEffect(() => {
        const enterFullscreen = () => {
            const elem = document.documentElement;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { // Firefox
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { // IE/Edge
                elem.msRequestFullscreen();
            }
        };

        const exitFullscreen = () => {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
        };
        let tokenRemoved = false;
        const handleBeforeUnload = (event) => {
            const message = "Are you sure you want to leave? Your progress may be lost.";
            event.preventDefault();
            event.returnValue = message;
            return message;
        };

        const handleUnload = () => {
            if (!tokenRemoved) {
                sessionStorage.removeItem('token');
                sessionStorage.clear();
                tokenRemoved = true;
            }
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                setWarningPopUp(true);
                // if (warningsLeft >=0 ) {
                //   setWarningsLeft(prev => prev - 1);
                // }
            }
        };

        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        const handlePopstate = (event) => {
            window.history.pushState(null, document.title, window.location.href);
        };

        const handleKeydown = (event) => {
            if (event.key === 'Escape' || event.key === 'F11' && event.key === 'Fn' || (event.key === 'F11' && event.getModifierState('Fn'))) {
                event.preventDefault();
            } else if (event.key === "Enter" || event.key === " ") {
                const isDisabledButton =
                    event.target.classList.contains('prevBtn') ||
                    event.target.classList.contains('nextBtn') ||
                    event.target.classList.contains('submitBtn');
                if (isDisabledButton) {
                    event.preventDefault();
                }
            } else if (event.ctrlKey && event.key === 'c') {
                event.preventDefault();
            }
        };

        const handleKeydownModifiers = (event) => {
            const isInputField = event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA';
            const isModifierKey1 = event.getModifierState('Control');
            const isModifierKey2 = event.getModifierState('Fn')
            if (event.key === 'F12' || isModifierKey1) {
                event.preventDefault();
            }
            if (event.key === 'F11' || isModifierKey2) {
                event.preventDefault();
            }
            if (!isInputField) {
                const isModifierKey = event.getModifierState('Alt') ||
                    event.getModifierState('Tab') ||
                    event.getModifierState('Shift') || event.getModifierState('Control');

                if (event.key === 'F12' || isModifierKey) {
                    event.preventDefault();
                } else if ((event.key === "Enter" || event.key === " ") && !isModifierKey) {
                    if (event.target.tagName === 'BUTTON') {
                        event.preventDefault();
                    }
                }
            }
        };

        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                // alert('You have exited fullscreen mode.');
                setFullScreenAlert(true);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleUnload);
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('popstate', handlePopstate);
        document.addEventListener('contextmenu', handleContextMenu);
        window.addEventListener('keydown', handleKeydown);
        window.addEventListener('keydown', handleKeydownModifiers);
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        window.history.pushState(null, document.title, window.location.href);

        enterFullscreen();

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('popstate', handlePopstate);
            document.removeEventListener('contextmenu', handleContextMenu);
            window.removeEventListener('keydown', handleKeydown);
            window.removeEventListener('keydown', handleKeydownModifiers);
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            exitFullscreen();
            clearInterval(interval);
        };
    }, [])

    // go fullscreen button handler -------------
    const handleGoFullscreen = () => {
        const enterFullscreen = () => {
            const elem = document.documentElement;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { // Firefox
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { // IE/Edge
                elem.msRequestFullscreen();
            }
        };
        enterFullscreen();
        setFullScreenAlert(false);
    }

    // warning for switching tab ------------
    const handleContinue = () => {
        setWarningPopUp(false);
    }

    // Start timer of the entire quiz --------
    const startTimer = (remainingTime) => {
        let totalSeconds = remainingTime * 60;
        interval = setInterval(() => {
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;
            const formattedTime = `${hours.toString().padStart(2, '0')} hrs : ${minutes.toString().padStart(2, '0')} mins : ${seconds.toString().padStart(2, '0')} secs`;
            setCountdown(formattedTime);
            totalSeconds--;
            if (totalSeconds < 0) {
                clearInterval(interval);
                setTimeUpPopUp(true)
                submitQuiz()
            }
        }, 1000);
    };

    // api call for answersaveorupdate 15 secs --------------------
    const intervalRef = useRef(null);
    const fetchData = async () => {
        if (quizType === "Unit Quiz" || quizType === "Annual/Term Quiz" || quizType === "Diagnostic Quiz") {
            intervalRef.current = setInterval(async () => {
                var item = {
                    examId: examId || answerSheets[0]?.examId || JSON.parse(sessionStorage.getItem('examId')),
                    status: "IN_PROGRESS",
                    answerSheets: answerSheets || []
                }
                if (item?.answerSheets?.length > 0 && (item?.examId !== "" && item?.examId !== null) && item?.answerSheets !== null) {
                    try {
                        const res = await axios.post(
                            `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/saveOrUpdateAnswerV2`,
                            item,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );
                        register()
                    } catch (err) {
                        console.error("service worker error", err);
                    }
                } else {
                }
            }, 30000);
        }
    };
    useEffect(() => {
        fetchData();
        // Cleanup function to clear the interval when component unmounts
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [answerSheets, examId, token]);

    // // each question timespent calculation -----------------------
    useEffect(() => {
        if (currentPage >= 0 && quizQuestions && quizQuestions.length > currentPage) {
            const questionId = quizQuestions[currentPage].questionId;
            if (timer) {
                clearInterval(timer);
            }

            const storedTime = timeSpentOnQuestions[questionId] || 0;
            const startTimeForQuestion = Date.now() - storedTime;
            setQuestionStartTime(startTimeForQuestion);
            const newTimer = setInterval(() => {
                setTimeSpentOnQuestions((prevTimeSpent) => ({
                    ...prevTimeSpent,
                    [questionId]: (prevTimeSpent[questionId] || 0) + 1000,
                }));
            }, 1000);
            setTimer(newTimer);
            return () => clearInterval(newTimer);
        }
    }, [currentPage, quizQuestions]);

    // HandleChange function for all -------------------
    const handleChange = (input, question, questionId, subQuestionId = null) => {
        const questionType = question?.questionTypeCode;
        let updatedAnswerSheets = [...answerSheets];

        switch (questionType) {
            case 'MCQ':
                handleMCQChange(input, question, questionId, updatedAnswerSheets);
                break;
            case 'MSQ':
                handleMSQChange(input, question, questionId, updatedAnswerSheets);
                break;
            case 'FILL':
                handleFillChange(input, question, questionId, updatedAnswerSheets);
                break;
            case 'CASE':
                handleCaseChange(input, question, questionId, updatedAnswerSheets, subQuestionId);
                break;
            default:
                break;
        }
        setAnswerSheets(updatedAnswerSheets);
    }

    const handleMCQChange = (item, question, questionId, updatedAnswerSheets) => {
        const questionIndex = updatedAnswerSheets.findIndex((q) => q.questionId === questionId);

        const totalTimeSpentOnCurrentQuestion =
            timeSpentOnQuestions[questionId] || 0;

        if (questionIndex !== -1) {
            const answerItem = {
                option: item?.option,
                order: item?.order,
                answerKey: item?.answerKey.replace(/\s/g, '') || null,
            }
            updatedAnswerSheets[questionIndex].answer = [answerItem];
            updatedAnswerSheets[questionIndex].isAnswered = true
            updatedAnswerSheets[questionIndex].totalTimeSpend = addTimeDurations(
                updatedAnswerSheets[questionIndex].totalTimeSpend,
                totalTimeSpentOnCurrentQuestion
            );

        }
    }

    const handleMSQChange = (item, question, questionId, updatedAnswerSheets) => {
        const questionIndex = updatedAnswerSheets.findIndex((q) => q.questionId === questionId);

        const totalTimeSpentOnCurrentQuestion =
            timeSpentOnQuestions[questionId] || 0;

        if (questionIndex !== -1) {
            const existingAnswers = updatedAnswerSheets[questionIndex].answer || [];
            const correctAnswerCount = question?.multiChoiceOrSelect.filter(option => option.correctAnswer === true).length;
            const answerItem = {
                option: item?.option,
                order: item?.order,
                answerKey: item?.answerKey.replace(/\s/g, '') || null,
            }

            const isSelected = existingAnswers.some((ans) => ans.answerKey === answerItem.answerKey);
            let updatedAnswers;
            if (isSelected) {
                updatedAnswers = existingAnswers.filter((ans) => ans.answerKey !== answerItem.answerKey);
            } else {
                if (existingAnswers.length < correctAnswerCount) {
                    updatedAnswers = [...existingAnswers, answerItem]
                } else {
                    updatedAnswers = existingAnswers;

                }
            }

            updatedAnswerSheets[questionIndex].answer = updatedAnswers;
            updatedAnswerSheets[questionIndex].isAnswered = updatedAnswers.length > 0;
            updatedAnswerSheets[questionIndex].totalTimeSpend = addTimeDurations(
                updatedAnswerSheets[questionIndex].totalTimeSpend,
                totalTimeSpentOnCurrentQuestion
            );
        }
    }

    const handleFillChange = (input, question, questionId, updatedAnswerSheets) => {
        const { value, groupIndex, optionIndex } = input;
        const questionIndex = updatedAnswerSheets.findIndex((q) => q.questionId === questionId);

        const totalTimeSpentOnCurrentQuestion =
            timeSpentOnQuestions[questionId] || 0;

        if (questionIndex !== -1) {
            const existingAnswers = updatedAnswerSheets[questionIndex].answer;

            existingAnswers[optionIndex] = {
                order: optionIndex + 1,
                answerKey: value.trim() || '',
            }
            updatedAnswerSheets[questionIndex].answer = existingAnswers;

            const isFilled = existingAnswers.some((ans) => ans && ans.answerKey !== null);

            updatedAnswerSheets[questionIndex].isAnswered = isFilled;
            updatedAnswerSheets[questionIndex].totalTimeSpend = addTimeDurations(
                updatedAnswerSheets[questionIndex].totalTimeSpend,
                totalTimeSpentOnCurrentQuestion
            );
        }
    }

    const handleCaseChange = (input, question, questionId, updatedAnswerSheets, subQuestionId) => {
        const questionIndex = updatedAnswerSheets.findIndex((q) => q.questionId === questionId);

        const totalTimeSpentOnCurrentQuestion =
            timeSpentOnQuestions[questionId] || 0;

        if (questionIndex !== -1 && subQuestionId !== null) {
            const subAnswerSheetIndex = updatedAnswerSheets[questionIndex].answer.findIndex((subAns) => subAns.currentQuestion === subQuestionId);

            const subQuestion = question.subQuestions.find((sub) => sub.questionId === subQuestionId);

            const subQuestionType = subQuestion?.questionTypeCode;

            switch (subQuestionType) {
                case "MCQ":
                    const mcqOption = {
                        option: input?.option,
                        order: input?.order,
                        answerKey: input?.answerKey.replace(/\s/g, "") || null,
                    };
                    updatedAnswerSheets[questionIndex].answer[subAnswerSheetIndex][subQuestionId] = [mcqOption];
                    break;
                case "MSQ":
                    const existingAnswers = updatedAnswerSheets[questionIndex].answer[subAnswerSheetIndex][subQuestionId] || [];
                    const correctAnswerCount = subQuestion?.multiChoiceOrSelect.filter(option => option.correctAnswer === true).length;
                    const msqOption = {
                        option: input?.option,
                        order: input?.order,
                        answerKey: input?.answerKey.replace(/\s/g, "") || null,
                    };
                    const isSelected = existingAnswers.some((ans) => ans.answerKey === msqOption.answerKey);
                    let updatedAnswers;
                    if (isSelected) {
                        updatedAnswers = existingAnswers.filter(
                            (ans) => ans.answerKey !== msqOption.answerKey
                        );
                    } else {
                        if (existingAnswers.length < correctAnswerCount) {
                            updatedAnswers = [...existingAnswers, msqOption];
                        } else {
                            updatedAnswers = existingAnswers;
                        }
                    }

                    updatedAnswerSheets[questionIndex].answer[subAnswerSheetIndex][subQuestionId] = updatedAnswers;
                    break;
                case "FILL":
                    const { value, groupIndex, optionIndex } = input;
                    const fillAnswers = updatedAnswerSheets[questionIndex].answer[subAnswerSheetIndex][subQuestionId] || [];

                    fillAnswers[optionIndex] = {
                        order: optionIndex + 1,
                        answerKey: value.trim() || "",
                    };

                    updatedAnswerSheets[questionIndex].answer[subAnswerSheetIndex][subQuestionId] = fillAnswers;
                    break;
                default:
                    break;
            }

            const isSubQuestionAnswered = updatedAnswerSheets[questionIndex].answer.some((sub) => sub[subQuestionId]?.length > 0);

            updatedAnswerSheets[questionIndex].isAnswered = isSubQuestionAnswered;
            updatedAnswerSheets[questionIndex].totalTimeSpend = addTimeDurations(
                updatedAnswerSheets[questionIndex].totalTimeSpend,
                totalTimeSpentOnCurrentQuestion
            );
        }
    }

    // addition logic for each question timespent from api and current ----------------------------
    const addTimeDurations = (time1, time2) => {
        const timeStringToSeconds = (timeString) => {
            const [hours, minutes, seconds] = timeString.split(':').map(Number);
            return hours * 3600 + minutes * 60 + seconds;
        };

        const secondsToTimeString = (seconds) => {
            const duration = moment.duration(seconds, 'seconds');
            return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
        };

        const time1InSeconds = timeStringToSeconds(time1); // Convert initial time to seconds
        const time2InSeconds = time2 / 1000; // Convert current time in milliseconds to seconds
        const totalSeconds = time1InSeconds + time2InSeconds; // Add both times

        return secondsToTimeString(totalSeconds); // Convert total seconds back to formatted string
    };

    //getting pagenumbers for questions ----------
    const getPageQuestions = () => {
        const startIndex = currentPage * questionsPerPage
        const endIndex = startIndex + questionsPerPage
        return quizQuestions?.slice(startIndex, endIndex)
    }

    const handleAddTimeSpend = () => {

        if (timer) {
            clearInterval(timer);
        }

        const currentQuestionId = quizQuestions[currentPage]?.questionId;

        const totalTimeSpentOnCurrentQuestion =
            timeSpentOnQuestions[currentQuestionId] || 0;

        // Adding and updating the answers in answerSheets by questionId
        const answeredQuestionIndex = answerSheets.findIndex(
            (answer) => answer.questionId === currentQuestionId
        );

        if (answeredQuestionIndex !== -1) {
            const currentAnswer = { ...answerSheets[answeredQuestionIndex] };

            // Add the new time spent to the existing totalTimeSpend
            currentAnswer.totalTimeSpend = addTimeDurations(
                currentAnswer.totalTimeSpend,
                totalTimeSpentOnCurrentQuestion
            );

            // Update answerSheets with new time spent
            setAnswerSheets((prevSheets) => [
                ...prevSheets.slice(0, answeredQuestionIndex),
                currentAnswer,
                ...prevSheets.slice(answeredQuestionIndex + 1),
            ]);
        }
    }

    //nextpage handling (next button) and timespent calculation ------------
    const handleNextPage = () => {
        if (currentPage < quizQuestions.length - 1) {
            handlePageChange(currentPage + 1);
        }
    };

    //prevpage handling (prev button) and timespent calculation ------------
    const handlePrevPage = () => {
        if (currentPage > 0) {
            handlePageChange(currentPage - 1);
        }
    };

    //pagenumber handling (page number button) and timespent calculation ------------
    const handlePageChange = (pageIndex) => {
        handleAddTimeSpend();
        // Set new page index
        setCurrentPage(pageIndex);
    };

    //submit button handling with question not answer -----------
    const handleSubmission = () => {
        clearInterval(interval);
        if (currentPage === quizQuestions?.length - 1) {
            setSubmitPopUp(true)

            var i = 0;
            answerSheets?.map((v) => {
                var ans = v?.answer?.length;
                if (v.questionTypeCode === "CASE") {
                    if (ans == 0) {
                        i = i + 1
                    } else {
                        var lengths = []
                        v?.answer?.forEach(item => {
                            const knownKeys = ['type', 'currentQuestion', 'parentQuestionId'];
                            const dynamicKey = Object.keys(item).find(key => !knownKeys.includes(key));
                            if (dynamicKey && Array.isArray(item[dynamicKey])) {
                                if (item[dynamicKey].length == 0) {
                                    i = i + 1
                                }
                                lengths.push(item[dynamicKey].length > 0);
                            }
                        });
                        var acheived = lengths.filter(item => item === true).length;
                        if (ans?.answer?.length > acheived && acheived !== 0) {
                            i = i + 1;
                        }
                    }
                } else if (v.questionTypeCode === "FILL") {
                    if (v?.answer?.length == 0) {
                        i = i + 1
                    }
                } else {
                    if (v?.answer?.length == 0) {
                        i = i + 1
                    }
                }

            })
            console.log("=========", i)
            if (i === 0) {
                SetsubmitQuizMessage("Are you sure want to submit ?");
            } else {
                SetsubmitQuizMessage("Are you sure you want to submit the quiz? Few questions are unattempted")
            }

            // const lengthReached = answerSheets?.filter(ans => ans.isAnswered == true);
            // if (quizQuestions?.length !== lengthReached?.length) {
            //     SetsubmitQuizMessage("Are you sure you want to submit quiz few questions are unattempted ?")
            // } else {
            //     SetsubmitQuizMessage("Are you sure want to submit ?")
            // }
        }
        else {
            setSubmitPopUp(false)
        }
    };

    //quiz submit api call function -----------
    const submitQuiz = async () => {
        clearInterval(interval);
        try {
            const item = {
                examId: examId || JSON.parse(sessionStorage.getItem('examId')),
                status: "END",
                answerSheets: answerSheets || [],
            };
            const res = await axios.put(
                `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/submitAnswerV2`,
                item,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res?.data?.success) {
                setOpenAnalysisModal(true)
                setSubmitSuccess(true);
            }
        } catch (error) {
            setSubmitFailed(true);
            console.error('Error while submitting quiz:', error);
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    // cancel button in popup -------------
    const handleCancelSubmit = () => {
        setSubmitPopUp(false)
    }

    //confirm submit handler ---------------
    const handleConfirmSubmit = () => {
        //checking the no. of questions = no.of answersheets ---------
        if (quizQuestions?.length !== answerSheets?.length) {
            console.log('questions error', quizQuestions);
            console.log('answersheets error', answerSheets);
            alert('questions and answersheets are not matched retake the test')
        }
        submitQuiz();
        setSubmitPopUp(false);
        setTimeUpPopUp(false);
    }

    // timeup handler ----------
    const handleOk = () => {
        setTimeUpPopUp(false);
        //checking the no. of questions = no.of answersheets in auto submit ----------
        if (quizQuestions?.length !== answerSheets?.length) {
            console.log('questions error', quizQuestions);
            console.log('answersheets error', answerSheets);
            alert('questions and answersheets are not matched retake the test')
        }
    }

    const handleOk2 = () => {
        setSubmitSuccess(false);
    }

    const handleOk3 = () => {
        setSubmitFailed(false);
    }

    const handleExit = () => {
        navigate('/dashboard/enrich')
    }

    //real time quiz graph page --------------
    const handleAnalysisModalClose = () => {
        setOpenAnalysisModal(false);
        setLoadQuizData(true);
    };

    //closing the real time quiz graph --------
    const closemodall = () => {
        window.location.reload();
    }

    const isPartiallyAnswered = (question, answerSheets) => {
        var ans = answerSheets?.find((v) => v.questionId === question.questionId)
        var i = 0, lengths = [];
        ans?.answer?.forEach(item => {
            // Filter out known keys
            const knownKeys = ['type', 'currentQuestion', 'parentQuestionId'];
            const dynamicKey = Object.keys(item).find(key => !knownKeys.includes(key));

            if (dynamicKey && Array.isArray(item[dynamicKey])) {
                lengths.push(item[dynamicKey].length > 0);
            }
        });
        var acheived = lengths.filter(item => item === true).length;
        if (ans?.answer?.length > acheived && acheived !== 0) {
            return true
        } else {
            return false
        }


    }

    // disable the overall selection 
    const disableUserActionsStyle = {
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        WebkitUserDrag: 'none',
        userDrag: 'none',
    };

    return (
        <div className='quizz quiz' style={disableUserActionsStyle}>
           
             {examOver ? 
            <>
             {fullScreenAlert &&
                <div className='alertOverlay'>
                    <div className='alertPopUp'>
                        <div>
                            You must be in FullScreen Mode
                        </div>
                        <button onClick={() => handleGoFullscreen()}>Go Fullscreen</button>

                    </div>
                </div>
            }
            <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
                <Box className='examOverContainer'>
                    <h1 className='examOverHead'>
                        Quiz has already Ended
                    </h1>
                    <div className='overIcon'>
                        <MoodBadIcon />
                    </div>
                    <Typography variant='h6' className='examOverText'>
                        Sorry, you have arrived late. The exam has already ended, and no further submissions can be accepted. Please contact your teacher for further assistance.
                    </Typography>
                    <button className='examOverExit' onClick={()=>handleExit()}> <LogoutIcon /> Exit</button>
                </Box>
            </Box>
            </>:
            <>
             {fullScreenAlert &&
                <div className='alertOverlay'>
                    <div className='alertPopUp'>
                        <div>
                            You must attend the Quiz Only in FullScreen
                        </div>
                        <button onClick={() => handleGoFullscreen()}>Go Fullscreen</button>

                    </div>
                </div>
            }
            {warningPopUp &&
                <div className='alertOverlay'>
                    <div className='alertPopUp'>
                        <div>
                            Leaving this tab will pause the test and could negatively affect your score.
                            {/* ({warningsLeft}) */}
                        </div>
                        <button onClick={() => handleContinue()}>Continue Quiz</button>

                    </div>
                </div>
            }
            <div className='quizHeader'>
                <Grid container spacing={2}>
                    <Grid className='gridQuizHead' item md={4} xs={12}>
                        <h1>{quizType}</h1>
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <h1>{quizTitle}</h1>
                    </Grid>
                    <Grid className='gridQuizHead1' item md={4} xs={6}>
                        <span className='timerClass'> Time Left  {countdown} </span>
                    </Grid>
                </Grid>
                {/* <h1>{quizType}</h1>
        <h1>{quizTitle}</h1>
         <span className='timerClass'> {countdown} Time Left </span> */}
            </div>
            <div className='OutOff'>
                {currentPage + 1} Out of {quizQuestions?.length}
            </div>
            {getPageQuestions()?.map((question, index) => {
                const questionAnswer = answerSheets?.find(q => q.questionId === question.questionId);
                if (question.questionTypeCode === "MCQ") {
                    return (
                        <div className="quizQuestion" id={`question_${question.questionId}`} key={question.questionId}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <div className="questionGrid">
                                            <p style={{ display: "flex" }}>
                                                <p style={{ marginRight: "5px" }}>{currentPage +
                                                    1}.</p>
                                                <p style={{
                                                    margin: 0, flex: "1", whiteSpace:
                                                        "pre-wrap"
                                                }} dangerouslySetInnerHTML={{
                                                    __html: question?.question,
                                                }} />
                                            </p>
                                            {question.imageUrl &&
                                                <div className='questionImage'>
                                                    <img src={question.imageUrl} alt="" onClick={() => { setModalOpen(true); setselectedImageUrl(question.imageUrl) }} />
                                                </div>}
                                        </div>
                                    </Grid>
                                    <Grid item md={6} xs={12}>

                                        <div className='optionGrid'>
                                            {question.multiChoiceOrSelect?.map((item, optionIndex) => (
                                                <label style={{ display: "flex", alignItems: "center" }} id={`option_${item.option}`} key={optionIndex}
                                                    className={questionAnswer?.answer.some(
                                                        ans => ans.option === item.option) ? 'selected' : ''}>
                                                    <Radio
                                                        className="customRadio"
                                                        checked={questionAnswer?.answer.some(
                                                            ans => ans.option === item.option)}
                                                        onChange={() => handleChange(item, question, question.questionId)}
                                                    />
                                                    <span
                                                        dangerouslySetInnerHTML={{ __html: item?.htmlTag }}
                                                    ></span>
                                                </label>
                                            ))}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    )
                }
                else if (question.questionTypeCode === "MSQ") {
                    return (
                        <div className="quizQuestion" id={`question_${question.questionId}`}>
                            <Box sx={{ flexGrow: 1 }} key={question.questionId}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <div className="questionGrid">
                                            <p style={{ display: "flex" }}>
                                                <p style={{ marginRight: "5px" }}>{currentPage +
                                                    1}.</p>
                                                <p style={{
                                                    margin: 0, flex: "1", whiteSpace:
                                                        "pre-wrap"
                                                }} dangerouslySetInnerHTML={{
                                                    __html: question?.question,
                                                }} />
                                            </p>
                                            {question.imageUrl &&
                                                <div className='questionImage'>
                                                    <img src={question.imageUrl} alt="" onClick={() => { setModalOpen(true); setselectedImageUrl(question.imageUrl) }} />
                                                </div>}
                                        </div>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <div className='optionGrid'>
                                            {question.multiChoiceOrSelect?.map((item, optionIndex) => (
                                                <label id={`option_${item.option}`} key={optionIndex} className={questionAnswer?.answer.some(
                                                    ans => ans.option === item.option) ? 'selected' : ''}>
                                                    <Checkbox
                                                        className="customCheckbox"
                                                        checked={questionAnswer?.answer.some(
                                                            ans => ans.option === item.option)}
                                                        onChange={() => handleChange(item, question, question.questionId)} />
                                                    {item.answerKey}
                                                </label>
                                            ))}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    )
                }
                else if (question.questionTypeCode === "FILL") {
                    return (
                        <div className="quizQuestion" id={`question_${question.questionId}`}>
                            <Box sx={{ flexGrow: 1 }} key={question.questionId}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <div className="questionGrid">
                                            <p style={{ display: "flex" }}>
                                                <p style={{ marginRight: "5px" }}>{currentPage +
                                                    1}.</p>
                                                <p style={{
                                                    margin: 0, flex: "1", whiteSpace:
                                                        "pre-wrap"
                                                }} dangerouslySetInnerHTML={{
                                                    __html: question?.question,
                                                }} />
                                            </p>
                                            {question.imageUrl &&
                                                <div className='questionImage'>
                                                    <img src={question.imageUrl} alt="" onClick={() => { setModalOpen(true); setselectedImageUrl(question.imageUrl) }} />
                                                </div>}
                                        </div>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <div className='optionGridfill'>
                                            {question.fillInTheBlanks?.map((item, optionIndex) => (
                                                <div key={optionIndex} className="inputWithLabel">
                                                    <label htmlFor={`fillAnswer_${question.questionId}_${optionIndex}`} className='answerLabel'>{`Answer ${optionIndex + 1}`}</label>
                                                    <input
                                                        type="text"
                                                        id={`fillAnswer_${question.questionId}_${optionIndex}`}
                                                        value={questionAnswer?.answer[optionIndex]?.answerKey || ''}
                                                        onChange={(e) => handleChange({
                                                            value: e.target.value,
                                                            optionIndex
                                                        },
                                                            question,
                                                            question.questionId)}
                                                        placeholder={`Enter Answer ${optionIndex + 1}`}
                                                        autocomplete="off" />
                                                </div>
                                            ))}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    )
                }
                else if (question.questionTypeCode === "CASE") {
                    return (
                        <div className="quizQuestion" id={`question_${question.questionId}`}>
                            <Box sx={{ flexGrow: 1 }} key={question.questionId}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <div className="questionGrid">
                                            <p style={{ display: "flex" }}>
                                                <p style={{ marginRight: "5px" }}>{currentPage +
                                                    1}.</p>
                                                <p style={{
                                                    margin: 0, flex: "1", whiteSpace:
                                                        "pre-wrap"
                                                }} dangerouslySetInnerHTML={{
                                                    __html: question?.question,
                                                }} />
                                            </p>
                                            {question?.explanation &&
                                                <p className='smallP' style={{
                                                    margin: "20px", flex: "1", whiteSpace:
                                                        "pre-wrap"
                                                }} dangerouslySetInnerHTML={{
                                                    __html: question?.explanation,
                                                }} />
                                            }
                                            {question.imageUrl &&
                                                <div className='questionImage'>
                                                    <img src={question.imageUrl} alt="" onClick={() => { setModalOpen(true); setselectedImageUrl(question.imageUrl) }} />
                                                </div>}
                                        </div>
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <div className='questionContainerCase'>
                                            <h2>Questions:</h2>
                                            {question.subQuestions?.map((subQuestion, subIndex) => {
                                                const subQuestionAnswer = questionAnswer?.answer.find((ans) => ans.currentQuestion === subQuestion.questionId);
                                                if (subQuestion.questionTypeCode === "MCQ") {
                                                    return (
                                                        <div key={subQuestion.questionId} id={`subquestion_${subQuestion.questionId}`} className='optionnn'>
                                                            {/* <p>{subIndex + 1}. {subQuestion.question}</p> */}
                                                            <p style={{ display: "flex" }}>
                                                                <p style={{
                                                                    marginRight: "5px"
                                                                }}>{subIndex + 1}.</p>
                                                                <p style={{
                                                                    margin: 0, flex: "1",
                                                                    whiteSpace: "pre-wrap"
                                                                }} dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        subQuestion?.question,
                                                                }} />
                                                            </p>
                                                            {subQuestion.imageUrl &&
                                                                <div className='questionImage'>
                                                                    <img src={subQuestion.imageUrl} alt="" onClick={() => { setModalOpen(true); setselectedImageUrl(subQuestion.imageUrl) }} />
                                                                </div>}
                                                            <div className='optionGridCase'>
                                                                {subQuestion.multiChoiceOrSelect?.map((item, optionIndex) => (
                                                                    <label id={`option_${item.option}`} key={optionIndex}
                                                                        className={subQuestionAnswer?.[subQuestion.questionId]?.some(
                                                                            ans => ans.option === item.option) ? 'selected' : ''}>
                                                                        <Radio
                                                                            className="customRadio"
                                                                            checked={subQuestionAnswer?.[subQuestion.questionId]?.some(
                                                                                ans => ans.option === item.option)}
                                                                            onChange={() => handleChange(item, question, question.questionId, subQuestion.questionId)} />
                                                                        {/* <span>{optionIndex + 1}.</span>  */}
                                                                        {item.answerKey}
                                                                    </label>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                else if (subQuestion.questionTypeCode === "MSQ") {
                                                    return (
                                                        <div key={subQuestion.questionId} id={`subquestion_${subQuestion.questionId}`} className='optionnn'>
                                                            {/* <p>{subIndex + 1}. {subQuestion.question}</p> */}
                                                            <p style={{ display: "flex" }}>
                                                                <p style={{
                                                                    marginRight: "5px"
                                                                }}>{subIndex + 1}.</p>
                                                                <p style={{
                                                                    margin: 0, flex: "1",
                                                                    whiteSpace: "pre-wrap"
                                                                }} dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        subQuestion?.question,
                                                                }} />
                                                            </p>
                                                            {subQuestion.imageUrl &&
                                                                <div className='questionImage'>
                                                                    <img src={subQuestion.imageUrl} alt="" onClick={() => { setModalOpen(true); setselectedImageUrl(subQuestion.imageUrl) }} />
                                                                </div>}
                                                            <div className='optionGridCase'>
                                                                {subQuestion.multiChoiceOrSelect?.map((item, optionIndex) => (
                                                                    <label id={`option_${item.option}`} key={optionIndex} className={subQuestionAnswer?.[subQuestion.questionId]?.some(
                                                                        ans => ans.option === item.option)
                                                                        ? 'selected' : ''}>
                                                                        <Checkbox
                                                                            className="customCheckbox"
                                                                            // style={{display:"none"}}
                                                                            checked={subQuestionAnswer?.[subQuestion.questionId]?.some(
                                                                                ans => ans.option === item.option)}
                                                                            onChange={() => handleChange(item, question, question.questionId, subQuestion.questionId)} />
                                                                        {/* <span>{optionIndex+1}.</span>  */}
                                                                        {item.answerKey}
                                                                    </label>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                else if (subQuestion.questionTypeCode === "FILL") {
                                                    return (
                                                        <div key={subQuestion.questionId} id={`subquestion_${subQuestion.questionId}`} className='optionnn'>
                                                            {/* <p>{subIndex+1}. {subQuestion.question}</p> */}
                                                            <p style={{ display: "flex" }}>
                                                                <p style={{
                                                                    marginRight: "5px"
                                                                }}>{subIndex + 1}.</p>
                                                                <p style={{
                                                                    margin: 0, flex: "1",
                                                                    whiteSpace: "pre-wrap"
                                                                }} dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        subQuestion?.question,
                                                                }} />
                                                            </p>
                                                            {subQuestion.imageUrl &&
                                                                <div className='questionImage'>
                                                                    <img src={subQuestion.imageUrl} alt="" onClick={() => { setModalOpen(true); setselectedImageUrl(subQuestion.imageUrl) }} />
                                                                </div>}
                                                            <div className='optionGridCasefill'>
                                                                {subQuestion.fillInTheBlanks?.map((item, optionIndex) => (
                                                                    <div key={optionIndex} className='inputWithLabel'>
                                                                        <label htmlFor={`fillCaseAnswer_${subQuestion.questionId}_${optionIndex}`} className='answerLabel'>{`Answer ${optionIndex + 1}`}</label>
                                                                        <input type="text" id={`fillCaseAnswer_${subQuestion.questionId}_${optionIndex}`}
                                                                            value={subQuestionAnswer?.[subQuestion.questionId]?.[optionIndex]?.answerKey || ''}
                                                                            onChange={(e) => handleChange({
                                                                                value: e.target.value,
                                                                                optionIndex,
                                                                            },
                                                                                question,
                                                                                question.questionId,
                                                                                subQuestion.questionId)}
                                                                            placeholder={`Enter Answer ${optionIndex + 1}`}
                                                                            autocomplete="off" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    )
                }
            })}

            <Student_Image_Open_Modal
                selectedImageUrl={selectedImageUrl}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
            />
            <div className='quizBottom'>
                <div className='attemDiv'>
                    <div className='diffAttem'>
                        <h3>Questions Attempted </h3><ArrowRightIcon />
                        <span className='attem'><CircleIcon />&nbsp;Attempted</span> |
                        <span className='unattem'><CircleIcon />&nbsp;Unattempted</span>
                        <span className='partialattend'><CircleIcon />&nbsp;Partially Attempted</span>
                    </div>
                </div>
                <div className="questionPagination">
                    {questionLoaded &&
                        <button id={`Previous_${quizCardData[0]?.quizId}`} className={` ${currentPage === 0 ? 'disabled' : 'prevBtn'}`} disabled={currentPage === 0} onClick={() => handlePrevPage()}>
                            <KeyboardDoubleArrowLeftIcon /> Previous Question
                        </button>
                    }

                    <div className='numberBtnGrp'>
                        {Array.from({ length: Math.ceil(quizQuestions?.length / questionsPerPage) }, (_, i) => (
                            <button id={`CurrentQuestion_${quizQuestions[i * questionsPerPage].questionId}`} key={i} onClick={() => handlePageChange(i)}
                                className={`${i === currentPage ? 'active' : ''} 
                                 ${quizQuestions[i * questionsPerPage].questionTypeCode === "CASE"
                                        ? (isPartiallyAnswered(quizQuestions[i * questionsPerPage], answerSheets) ? 'partiallyAnswered' : (answerSheets?.find(answer => answer.questionId === quizQuestions[i * questionsPerPage]?.questionId)?.isAnswered ? 'answeredPage' : ''))
                                        : (answerSheets?.find(answer => answer.questionId === quizQuestions[i * questionsPerPage]?.questionId)?.isAnswered ? 'answeredPage' : '')
                                    }
                              `}>
                                {i + 1}
                            </button>

                            // //     <button id={`CurrentQuestion_${quizQuestions[i * questionsPerPage].id}`} key={i} onClick={() => handlePageChange(i)}
                            // //     className={`
                            // //     ${i === currentPage ? 'active' : ''}
                            // //     ${quizQuestions[i * questionsPerPage].questionTypeCode === "CASE"
                            // //             ? (isPartiallyAnswered(quizQuestions[i * questionsPerPage], answerSheets) ? 'partiallyAnswered' : (answerSheets?.find(answer => answer.questionId === quizQuestions[i * questionsPerPage]?.id)?.isAnswered ? 'answeredPage' : ''))
                            // //             : (answerSheets?.find(answer => answer.questionId === quizQuestions[i * questionsPerPage]?.id)?.isAnswered ? 'answeredPage' : '')
                            // //         }
                            // //   `}>
                            //     {i + 1}
                            // </button>
                        ))}
                    </div>
                    {questionLoaded && <>
                        {currentPage === quizQuestions?.length - 1 ?
                            <button id={`Submit_${quizCardData[0]?.quizId}`} className='submitBtn' onClick={() => handleSubmission()}>Submit Quiz <ArrowCircleRightOutlinedIcon /></button>
                            : <button id={`Next_${quizCardData[0]?.quizId}`} className="nextBtn" onClick={() => currentPage === quizQuestions.length - 1 ? handleSubmission() : handleNextPage()}>
                                Next Question <KeyboardDoubleArrowRightIcon />
                            </button>
                        }
                    </>}
                </div>

            </div>
            {submitPopUp &&
                <div className="submitPopUpBackground">
                    <div className='submitPopUp'>
                        <div>
                            <h3>{submitQuizMessage}</h3>
                        </div>
                        <div className='buttonDiv'>
                            <button onClick={() => handleCancelSubmit()} className='cancel'>Go to quiz</button>
                            <button onClick={() => handleConfirmSubmit()}
                                className='confirm'>Submit</button>

                            {/* {(quizType === "Unit Quiz") &&
                                <Link
                                    to={"analysis"}
                                    element={<Student_Enrich_Quiz_Analysis_Modal quizzData={quizCardData[0]}
                                        examId={examId}
                                        showAnalysisModal={openAnalysisModel}
                                        closeModal={handleAnalysisModalClose}
                                        // examId = {quizCardData[0]?.examinationId}
                                        closeModall={closemodall || false} />}
                                >
                                    <button onClick={() => handleConfirmSubmit()}
                                        className='confirm'>Submit</button></Link>
                            }
                            {(quizType === "Annual/Term Quiz" || quizType === "Diagnostic Quiz") &&
                                <Link
                                    to={"analysis"}
                                    element={<Student_Formal_Assesment_Analysis_Modal quizzData={quizCardData[0]}
                                        examId={examId}
                                        showAnalysisModal={openAnalysisModel}
                                        closeModal={handleAnalysisModalClose}
                                        // examId = {quizCardData[0]?.examinationId}
                                        closeModall={closemodall || false} />}
                                >
                                    <button onClick={() => handleConfirmSubmit()}
                                        className='confirm'>Submit</button></Link>
                            } */}
                        </div>
                    </div>
                </div>
            }
            {submitSuccess &&
                <div className="submitPopUpBackground">
                    <div className='submitPopUp'>
                        <div>
                            <h3>Quiz Submitted</h3>
                        </div>
                        <div className='buttonDiv'>
                            {(quizType === "Unit Quiz") &&
                                <Link
                                    to={"analysis"}
                                    element={<Student_Enrich_Quiz_Analysis_Modal quizzData={quizCardData[0]}
                                        examId={examId}
                                        showAnalysisModal={openAnalysisModel}
                                        closeModal={handleAnalysisModalClose}
                                        // examId = {quizCardData[0]?.examinationId}
                                        closeModall={closemodall || false} />}
                                >
                                    <button onClick={() => handleOk2()}
                                        className='confirm'>Ok</button></Link>
                            }
                            {(quizType === "Annual/Term Quiz" || quizType === "Diagnostic Quiz") &&
                                <Link
                                    to={"analysis"}
                                    element={<Student_Formal_Assesment_Analysis_Modal quizzData={quizCardData[0]}
                                        examId={examId}
                                        showAnalysisModal={openAnalysisModel}
                                        closeModal={handleAnalysisModalClose}
                                        // examId = {quizCardData[0]?.examinationId}
                                        closeModall={closemodall || false} />}
                                >
                                    <button onClick={() => handleOk2()}
                                        className='confirm'>Ok</button></Link>
                            }
                        </div>
                    </div>
                </div>
            }
            {submitFailed &&
                <div className="submitPopUpBackground">
                    <div className='submitPopUp'>
                        <div>
                            <h3>Failed to submit exam Please contact admin with examId : {examId}</h3>
                        </div>
                        <div className='buttonDiv'>
                            <button onClick={() => handleExit()}
                                className='confirm'>Exit</button>

                        </div>
                    </div>
                </div>
            }
            {timeUpPopUp &&
                <div className="submitPopUpBackground">
                    <div className='submitPopUp'>
                        <div>
                            <h3>Time Is Up</h3>
                            {/* <p>Quiz Submitted</p> */}
                        </div>
                        <div className='buttonDiv'>
                            <button onClick={() => handleOk()} className='confirm'>Ok</button>
                            {/* {(quizType === "Unit Quiz") &&
                                <Link
                                    to={"analysis"}
                                    element={<Student_Enrich_Quiz_Analysis_Modal quizzData={quizCardData[0]}
                                        examId={examId}
                                        showAnalysisModal={openAnalysisModel}
                                        closeModal={handleAnalysisModalClose}
                                        // examId = {quizCardData[0]?.examinationId}
                                        closeModall={closemodall || false}
                                    />}>
                                    <button onClick={() => handleOk()} className='confirm'>Ok</button></Link>
                            }
                            {(quizType === "Annual/Term Quiz" || quizType === "Diagnostic Quiz") &&
                                <Link
                                    to={"analysis"}
                                    element={<Student_Formal_Assesment_Analysis_Modal quizzData={quizCardData[0]}
                                        examId={examId}
                                        showAnalysisModal={openAnalysisModel}
                                        closeModal={handleAnalysisModalClose}
                                        // examId = {quizCardData[0]?.examinationId}
                                        closeModall={closemodall || false}
                                    />}>
                                    <button onClick={() => handleOk()} className='confirm'>Ok</button></Link>
                            } */}
                        </div>
                    </div>
                </div>
            }
            </>}
        </div>
    )
}

export default QuizResumeOptimizedComponent