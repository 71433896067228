import { Box, Modal, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import YellowButton from "../../../components/common/yellowButton";
import YellowBorderHover from "../../../components/common/yellowButton";
import { DoneAllRounded } from "@mui/icons-material";
import WhiteAddButton from "../../../components/common/WhiteButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};
const SuccessModal = ({ open, close, title,subtitle, createHandler }) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CheckCircleOutlineIcon style={{ color: "#FFA700", fontSize: 120 }} />
          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <center>Successfully Created</center>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#FFA700",
                }}
              >
                {subtitle}
              </p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#FFA700",
                }}
              >
                {title}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  columnGap: "1rem",
                  paddingTop:'20px'
                }}
              >
                {/* <WhiteAddButton onClick={close}>NO</WhiteAddButton> */}
                {/* <YellowBorderHover onClick={close}>No</YellowBorderHover> */}
                <YellowButton onClick={() => createHandler()}>OK</YellowButton>
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default SuccessModal;
