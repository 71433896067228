import { Box, Modal, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { grey } from "@mui/material/colors";
import YellowButton from "../../components/common/yellowButton";
import YellowBorderHover from "../../components/common/YellowBorderHover";
import React, { useState } from "react";
import Switch from "../../components/common/Switch";
import axios from "axios";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};
const ViewSubjectMappingModal = ({ open, onClose, subject, onChange }) => {
  const token = sessionStorage.getItem("token");
  const [isDelete, setDelete] = useState(true);
  const [message, setMessage] = useState("");
  const [id, setId] = useState("");
  const [subtopiclist, setSubtopiclist] = useState(subject?.subTopics);
  const [deletpopup, setdeletepopup] = useState(false);
  const [selectedSubject, setSelectedSubjectDetails] = useState("");

  React.useEffect(() => {
    if (subject?.subTopics) {
      console.log(subject);
      setSelectedSubjectDetails(subject);
      setSubtopiclist(subject?.subTopics);
    }
  }, [subject?.subTopics]);

  const deletHandler = async (item) => {
    setId(item?.subtopicId);
    setDelete(false);
    setdeletepopup(true);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/confirmation-api?id=${item.subtopicId}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMessage(response?.data?.data?.message);
  };
  const deleteItem = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/bulk-delete?ids=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        const updateValue = subtopiclist?.filter(
          (item) => item.subtopicId != id
        );
        setSubtopiclist(updateValue);
        setDelete(true);
      })
      .catch(function (response) {});
  };

  const no = () => {
    setDelete(true);
  };
  const [toggleId, settoggleid] = useState({});
  const switchHandler = (id, e) => {
    const value = e.target.checked;
    if (!value) {
      const { subjectId, boardId, gradeId } = selectedSubject;
      settoggleid({ id, value });
      setDelete(false);
      setdeletepopup(false);
      const data = {
        subjectId,
        boardId,
        gradeId,
        subtopicId: id,
        active: value,
        operationType: "TOGGLE",
      };
      axios
        .put(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/confirmation-api`,
          { ...data },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setMessage(response.data.data.message);
        });
    } else {
      const { subjectId, boardId, gradeId } = selectedSubject;
      axios
        .put(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/toggle-active`,
          { subjectId, boardId, gradeId, active: value, subtopicId: id },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: value,
          }
        )
        .then(function (response) {
          const ele = subtopiclist.filter((v) => v.subtopicId != id);
          const uptele = subtopiclist.find((v) => v.subtopicId == id);
          uptele.active = value;
          setSubtopiclist([...ele, uptele]);
          setDelete(true);
        })
        .catch(function (response) {
          // console.log(response);
        });
    }
  };

  const updateToggle = () => {
    const { value, id } = toggleId;
    const { subjectId, boardId, gradeId } = selectedSubject;
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/toggle-active`,
        { subjectId, boardId, gradeId, active: value, subtopicId: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: value,
        }
      )
      .then(function (response) {
        const ele = subtopiclist.filter((v) => v.subtopicId != id);
        const uptele = subtopiclist.find((v) => v.subtopicId == id);
        uptele.active = value;
        setSubtopiclist([...ele, uptele]);
        setDelete(true);
      })
      .catch(function (response) {
        // console.log(response);
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {isDelete ? (
          <>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "1.125rem",
              }}
            >
              {subject?.subject}
              <CloseOutlinedIcon
                onClick={onClose}
                style={{ cursor: "pointer", color: grey[500] }}
              />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 3 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#FFA700",
                  fontWeight: 600,
                }}
              >
                <div>Subtopics</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "130px",
                  }}
                >
                  <div>Active</div>
                  <div>Delete</div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: 0,
                  border: "1px solid ",
                  borderColor: grey[500],
                  marginTop: 6,
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.875rem",
                }}
              >
                {subtopiclist?.map((item) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      key={item?.subtopicId}
                    >
                      <div>{item?.subtopic}</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "130px",
                        }}
                      >
                        <Switch
                          checked={item?.active}
                          onChange={(e) => switchHandler(item?.subtopicId, e)}
                        />
                        <DeleteIconButton onClick={() => deletHandler(item)}>
                          <DeleteOutlinedIcon style={{ fontSize: "25px" }} />
                        </DeleteIconButton>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <YellowButton onClick={onClose}>Save</YellowButton>
              </div> */}
            </Typography>
          </>
        ) : (
          <div style={{ padding: "40px" }}>
            {deletpopup && (
              <>
                <p>{message}</p>
                {message ==
                "No Permission to delete or deactivate, as it has existing mapping in the workflow..!" ? (
                  <div>
                    {" "}
                    <YellowButton onClick={no}>Ok</YellowButton>{" "}
                  </div>
                ) : (
                  <div style={{ marginTop: "40px" }}>
                    <YellowBorderHover onClick={deleteItem}>
                      Yes
                    </YellowBorderHover>
                    <YellowButton onClick={no}>No</YellowButton>
                  </div>
                )}
              </>
            )}
            {!deletpopup && (
              <>
                <p>{message}</p>
                {message ==
                "No Permission to delete or deactivate, as it has existing mapping in the workflow..!" ? (
                  <div>
                    {" "}
                    <YellowButton onClick={no}>Ok</YellowButton>{" "}
                  </div>
                ) : (
                  <div style={{ marginTop: "40px" }}>
                    <YellowBorderHover onClick={updateToggle}>
                      Yes
                    </YellowBorderHover>
                    <YellowButton onClick={no}>No</YellowButton>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </Box>
    </Modal>
  );
};
export default ViewSubjectMappingModal;
