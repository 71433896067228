import React from "react";
import { MenuProps } from "../Data";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import { filterBarContainer, filterBarContainerLeft } from "./styles";
import searchIcn from "../../../../src/img/search.svg";
import { isNOTNullOrUndefined } from "../TeachValidate";

const PlanFilterBar = ({
  boardList,
  selectedBoard,
  selectBoardHandler,
  handleSearch,
  searchParam,
  isEdit,
  isBluePrintTab,
}) => {
  const filterStyle = {
    "&.MuiOutlinedInput-root": {
      "& > fieldset": {
        border: "1px solid #D6D5DF !important",
        fontSize: "13px !important",
      },
    },
    "&.MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        border: "1px solid #272727 !important",
      },
    },
    "& .css-10hburv-MuiTypography-root": {
      fontSize: "15px !important",
    },
    fontSize: "14px !important",
    fontFamily: "Poppins",
  };

  const labelStyle = {
    fontSize: "14px",
    "&.Mui-focused": {
      color: "orange",
      fontWeight: 600,
      fontFamily: "Poppins",
      fontSize: "13px",
    },
  };

  return (
    <div style={filterBarContainer}>
      <div style={filterBarContainerLeft}>
        <MenuBookOutlinedIcon
          style={{ fontSize: "25px", marginRight: "7px" }}
        />
        <div
          style={{
            fontSize: "15px",
            fontWeight: "500",
            fontFamily: "Poppins",
            color: "#354052",
            marginRight: "23px",
          }}
        >
          {isEdit ? "Edit" : "Add"} Plan Template
        </div>
        {!isEdit ? (
          <FormControl sx={{ width: "113px", height: "36px" }} size="small">
            <InputLabel sx={labelStyle} id="board-select">
              Board
            </InputLabel>
            <Select
              labelId="board-select"
              id="board-select"
              sx={filterStyle}
              label="Board"
              input={<OutlinedInput label="Board" />}
              onChange={selectBoardHandler}
              value={
                isNOTNullOrUndefined(selectedBoard?.id) ? selectedBoard.id : ""
              }
              defaultValue={""}
              style={{
                background: "#fff",
                borderRadius: 150,
                height: "100%",
              }}
              MenuProps={MenuProps}
            >
              {boardList?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <ListItemText primary={item.board} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </div>
      {!isBluePrintTab ? (
        <TextField
          placeholder="Search"
          type="search"
          variant="outlined"
          fullWidth
          size="small"
          onChange={handleSearch}
          value={searchParam}
          sx={{
            width: { sm: 200, md: 300 },
            "& .MuiInputBase-root": {
              width: "247px !important",
              height: "32px !important",
              borderRadius: "50px !important",
              background: "#fff !important",
              lineHeight: "21px !important",
              font: "normal normal normal 13px/20px Poppins !important",
              opacity: 1,
            },
          }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <img
                  src={searchIcn}
                  style={{
                    verticalAlign: "top",
                    fontSize: "large",
                  }}
                  aria-hidden="true"
                />
              </IconButton>
            ),
          }}
        />
      ) : null}
    </div>
  );
};

export default PlanFilterBar;
