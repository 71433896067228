import { useEffect, useState } from 'react';

function useShowScrollButtons(containerRef) {
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () => {
      const contentWidth = container.scrollWidth;
      const viewportWidth = container.clientWidth;

      // Check if content width exceeds viewport width
      if (contentWidth > viewportWidth) {
        setShowPrevButton(true);
        setShowNextButton(true);
      } else {
        setShowPrevButton(false);
        setShowNextButton(false);
      }
    };

    // Add scroll event listener to the container
    container.addEventListener('scroll', handleScroll);

    // Call the handleScroll function immediately to determine initial state
    handleScroll();

    // Cleanup the event listener when the component unmounts
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef]);

  return { showPrevButton, showNextButton };
}

export default useShowScrollButtons;
