import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuProps } from "../Data";
import { styled } from "@mui/material/styles";
import QuestionModal from "../QuestionModal";

import ReleaseModal from "../ReleaseModal";
import axios from "axios";

import GetLastModifiedAt from "../../API/token-controller/GetLastModifiedAt";
// import { SearchOutlined } from "@mui/icons-material";
import ReReleaseModal from "../ReReleaseModal";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../../../components/common/TopBar";
import searchIcn from "../../../../src/img/search.svg";
import dashboardContentStyles from ".././../css/dashboardContent.module.css";
import "../../css/Student/Student_Enrich_Quiz.css";
import {
  isNOTNullOrUndefined,
  isNullOrUndefined,
} from "../../js/TeachValidate";
import TeacherMessage from "../TeacherMessage";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  boxShadow:
    "0px 2px 1px -1px rgba(138,203,249,0.77), 0px 1px 1px 0px rgba(138,203,249,0.77), 0px 1px 3px 0px rgba(138,203,249,0.77)",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
}));
const ChapterItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
const CancelButton = styled(Button)(({ theme }) => ({
  color: "#ed9b00",
  width: "5.2vw",
  backgroundColor: "#fff",
  borderRadius: 150,
  border: "1px solid #757575",
  borderColor: "#ed9b00",
  textTransform: "none",
  fontSize: "0.78vw",
}));
const SaveButton = styled(Button)(() => ({
  color: "#fff",
  width: "5.2vw",
  backgroundColor: "#ed9b00",
  borderRadius: 150,
  fontSize: "0.78vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#ed9b00",
    border: 0,
    top: -2,
    boxShadow: "0px 3px 5px #FFA700",
  },
}));
const reReleaseButton = styled(Button)(() => ({
  color: "#fff",
  width: "7.8vw",
  backgroundColor: "#ed9b00",
  borderRadius: 150,
  fontSize: "0.78vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#ed9b00",
    border: 0,
    top: -2,
    boxShadow: "0px 3px 5px #FFA700",
  },
}));
const Principal_AssignQuiz = (props) => {
  const navigate = useNavigate();
  const [preSelectedData, setPreSelectedData] = useState({});
  // console.log(preSelectedData, "state throught router");
  const [questionModalData, setQuestionModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showReleaseModal, setShowReleaseModal] = useState(false);
  const [showReReleaseModal, setShowReReleaseModal] = useState(false);
  const token = sessionStorage.getItem("token");
  const [modifiedDate, setModifiedDate] = useState({});
  const [subjectList, setSubjectList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState("");
  const [preSelectedSubTopic, setPreSelectedSubTopic] = useState("");
  const [academicYearList, setAcademicYearList] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [allQuizData, setAllQuizData] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [selectedChapterId, setSelectedChapterId] = useState("");
  const [chapterList, setChapterList] = useState([]);
  const [chapterPageSize, setChapterPageSize] = useState(100);
  const [selectedQuizId, setSelectedQuizId] = useState("");
  const [selectedPreviewId, setSelectedPreviewId] = useState("");
  const [isError, setIsError] = useState(false);
  const [quizIds, setQuizIds] = useState([]);
  const [quizDuraction, setQuizDuration] = useState("");
  const [selectedSectionReleaseData, setSelectedSectionReleaseData] = useState(
    []
  );
  const [query, setQuery] = useState("");

  const [isCheckingWhetherChapterIsEnded, setisCheckingWhetherChapterIsEnded] =
    useState(false);

  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");

  const [teacherId, setTeacherId] = useState("");
  const [gradeSection, setGradeSection] = useState([]);

  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      console.log("section setting preselected in locaton.state");
      // setSelectedChapterId(location?.state?.id);
      setPreSelectedData(location?.state);
    }
  }, [location?.state]);

  const quizRelease = async (item) => {
    console.log("principal_assignquiz.js ------------")
    const bordId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const subTopicId = selectedSubTopic;
    const academicYearId = selectedAcademicYear
      ? selectedAcademicYear
      : academicYearList[0]?.id;

    const chapterId = item.id;

    const data = {
      bordId,
      gradeId,
      schoolId,
      branchId,
      subjectId,
      subTopicId,
      sectionId,
      chapterId,
      academicYearId,
    };
    console.log("section setting preselected in quiz release");

    setPreSelectedData(data);
  };

  useEffect(() => {
    // console.log(preSelectedData.chapterId, "pre selected data");
    if (preSelectedData) {
      setSelectedGrade(
        gradeSection?.filter((item) => item.id === preSelectedData.gradeId)[0]
      );
      setSelectedSubject(
        subjectList?.find(
          (item) => item.subjectId === preSelectedData.subjectId
        )
      );

      setSelectedChapterId(
        chapterList?.filter((item) => item.id === preSelectedData.chapterId)[0]
      );

      const storedAcademicYearId = sessionStorage.getItem("academicYearId");
      setSelectedAcademicYear(storedAcademicYearId);
    }
  }, [preSelectedData, gradeSection, academicYearList]);

  useEffect(() => {
    console.log("section preselected data change");
    setSelectedSection(preSelectedData.sectionId);
  }, [preSelectedData?.gradeId]);

  useEffect(() => {
    setSelectedChapterId(
      chapterList?.filter((item) => item.id === preSelectedData.chapterId)[0]
    );
  }, [chapterList]);

  useEffect(() => {
    console.log("subject ====> changed");
    if (preSelectedData?.subTopicId) {
      console.log(preSelectedData?.subTopicId, "subtopic preselected");
      if (preSelectedData?.subTopicId?.id) {
        setSelectedSubTopic(
          selectedSubject?.subTopics?.find(
            (item) => item.id === preSelectedData?.subTopicId?.id
          )
        );
      } else {
        setSelectedSubTopic(
          selectedSubject?.subTopics?.find(
            (item) => item.id === preSelectedData?.subTopicId
          )
        );
      }
    } else if (selectedSubject?.subTopics) {
      console.log("subtopic fetch from subjects list");
      setSelectedSubTopic(selectedSubject?.subTopics[0]);
    } else {
      console.log(selectedSubject, "subtopic empty");

      setSelectedSubTopic("");
    }
  }, [selectedSubject]);

  useEffect(async () => {
    fetchTeacherData(userName, role);
    const teacherData = fetchTeacherData(userName, role);
    const academic = await fetchAcademicYearData();

    GetLastModifiedAt(setModifiedDate);
    // Set the initial value of the filter
    const storedAcademicYearId = sessionStorage.getItem("academicYearId");
    if (storedAcademicYearId) {
      setSelectedAcademicYear(storedAcademicYearId);
    } else if (academic.length > 0) {
      setSelectedAcademicYear(academic[0].id);
      sessionStorage.setItem("academicYearId", academic[0].id);
    }
    return () => { };
  }, []);

  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    sessionStorage.setItem("boardId", res.data.data.teacher.boardId);
    sessionStorage.setItem("schoolId", res.data.data.teacher.school);
    sessionStorage.setItem("branchId", res.data.data.teacher.branch);
    setTeacherId(res.data.data.teacher);
  };

  // grade - section API

  const fetchGrade = async () => {
    // const id = "4028928886f00aec0186fda2e0a70004";
    const id = teacherId.id;
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=ASSIGN`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setGradeSection(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  // subject - subtopic API

  const fetchSubject = async () => {
    // const id = "4028928886f00aec0186fda2e0a70004";
    const id = teacherId?.id;
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/teachers/subjects?teacherId=${id}&gradeId=${selectedGrade.id ? selectedGrade.id : selectedGrade
        }&sectionId=${selectedSection.id ? selectedSection.id : selectedSection
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSubjectList(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchSubject();
  }, [selectedGrade, selectedSection]);

  // Academic year api

  const fetchAcademicYearData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data.data || [];
  };

  const fetchChapterData = async (subjectId, gradeId) => {
    console.log(selectedSubTopic, "subtopic in chapters api");
    const bordId = sessionStorage.getItem("boardId");
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL
      }v1/api/master/chapters/all-chapters?subjectId=${selectedSubject?.subjectId ? selectedSubject.subjectId : selectedSubject
      }&gradeId=${selectedGrade.id ? selectedGrade.id : selectedGrade
      }&boardId=${bordId}${isNOTNullOrUndefined(selectedSubTopic)
        ? `&subTopicId=${selectedSubTopic.id}`
        : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setChapterList(response.data?.data);

    return response?.data?.data || [];
  };

  const searchHandler = async (event) => {
    let value = await event?.target?.value?.toLowerCase();
    if (value?.length !== 0) {
      if (value?.length >= 3) {
        const newChapter = await fetchChapterBySearch(value);
        setIsError(false);
        setFilterValue(newChapter);
      } else {
        setIsError(true);
      }
    } else {
      setIsError(false);
      setFilterValue(updatedData);
    }
  };
  const fetchChapterBySearch = async (value) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters?pageSize=${chapterPageSize}&sortOrder=true&search=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data?.data || [];
  };

  const showQuiz = async () => {
    const bordId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    // const chapterId = id;
    console.log("***************")
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL
      }v1/api/content/quiz-release/list-to-teacher?reviewStatus=APPROVED&schoolId=${schoolId}&branchId=${branchId}&boardId=${bordId}&sectionId=${selectedSection}&gradeId=${selectedGrade?.id ? selectedGrade?.id : selectedGrade
      }&subjectId=${selectedSubject?.subjectId ? selectedSubject.subjectId : selectedSubject
      }${isNOTNullOrUndefined(selectedChapterId)
        ? `&chapterId=${selectedChapterId?.id}`
        : ""
      }&academicYearId=${selectedAcademicYear?.id
        ? selectedAcademicYear.id
        : selectedAcademicYear
      }${selectedSubTopic?.id ? "&subTopicId=" + selectedSubTopic?.id : ""}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data || [];
  };

  useEffect(async () => {
    const quiz = await showQuiz();
    setAllQuizData(quiz);
  }, [
    selectedChapterId,
    preSelectedData,
    selectedGrade,
    selectedSubject,
    selectedSection,
    selectedBoard,
    selectedChapterId?.id,
    selectedSubTopic,
  ]);

  useEffect(async () => {
    if (selectedSubject && selectedGrade) {
      const chapterData = await fetchChapterData(
        selectedSubject,
        selectedGrade,
        selectedBoard,
        selectedSubTopic
      );
      setUpdatedData(chapterData);
      setFilterValue(chapterData);
    } else if (!selectedSubject && !selectedBoard && !selectedGrade) {
    }
  }, [selectedSubject, selectedSubTopic, selectedBoard, selectedBoard]);

  useEffect(async () => {
    // const subject = await fetchSubject();
    const grade = await fetchGrade();
    const section = await fetchGrade();
    const academic = await fetchAcademicYearData();
    const chapters = await fetchChapterData();

    setAcademicYearList(academic);
    // setSubjectList(subject);
    setGradeList(grade);
    setSectionList(section);
    setChapterList(chapters);
  }, [teacherId.id]);

  const handleChangeGrade = (e) => {
    // setSelectedGrade(e.target.value);
    setSelectedGrade(
      gradeSection?.filter((grade) => grade.id === e.target.value)[0]
    );
    setSelectedSection("");

    sessionStorage.setItem("gradeId", e.target.value);
  };

  const handleChangeSection = (event) => {
    console.log("handle section changes");
    setSelectedSection(event.target.value);

    // console.log(event.target.value,'section in change handler');
    setSelectedSubject("");
    sessionStorage.setItem("sectionId", event.target.value);
  };

  const handleChangeSubject = (event) => {
    setSelectedSubject(
      subjectList?.find((item) => item.subjectId === event.target.value)
    );
    sessionStorage.setItem("subjectId", event.target.value);
  };

  const handleChangeChapter = (event) => {
    setSelectedChapterId(
      chapterList?.filter((item) => item.id === event.target.value)[0]
    );
    sessionStorage.setItem("chapterId", event.target.value);
  };

  const handleChangeSubtopic = (event) => {
    setSelectedSubTopic(
      selectedSubject?.subTopics?.find((item) => item.id === event.target.value)
    );
    sessionStorage.setItem("subTopicId", event.target.value);
  };

  const onPreviewClick = async (item) => {
    console.log("preview clicked")
    // alert("here")
    await setQuestionModalData(item);
    // await setShowModal(true);
    sessionStorage.setItem("selectedquestionModal",JSON.stringify(item));
    navigate('../principal/quiz');
    sessionStorage.setItem("redirectUrl", 'principal/assign quiz');
  };

  const onReleaseClick = (item) => {
    checkIfChapterEnded(item);
  };

  // function to handle Re-release
  const OpenReRelease = (item) => {
    setSelectedQuizId(item);
    setShowReReleaseModal(true);
    setShowReleaseModal(false);
  };

  const previewHandler = async () => {
    setShowReleaseModal(false);
    setSelectedQuizId(selectedPreviewId);
    setShowModal(true);
  };

  const releaseQuiz = async (quizid) => {
    const id = teacherId.id;
    const bordId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");

    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz-release/list-of-section?quizId=${quizid}&teacherId=${id}&gradeId=${selectedGrade.id}&sectionId=${selectedSection}&schoolId=${schoolId}&branchId=${branchId}&subjectId=${selectedSubject.subjectId}&boardId=${bordId}&academicYearId=${selectedAcademicYear}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setQuizIds(response.data.data);
  };

  const checkIfChapterEnded = async (item) => {
    setisCheckingWhetherChapterIsEnded(true);
    const bordId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    // v1/api/teacher/teach/chapter/min/details?schoolId=4028928885cb7f970185ce97c2b80002&branchId=4028928885cb7f970185ce9a3ab00003&boardId=4028928883193d8701831d237c030004&gradeId=4028928883193d8701831d2d8b850010&sectionId=40289288840fe4440184142921600011&subjectId=40289288836b282f018379ece0370019&subTopicId=40289288836b282f018379ece03f001a&chapterId=402892888705a8a00187120bb9ef0007&academicYearId=40289288840fe4440184141016c70010
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/teacher/teach/chapter/min/details?gradeId=${selectedGrade.id
        }&sectionId=${selectedSection}&schoolId=${schoolId}&branchId=${branchId}&subjectId=${selectedSubject.subjectId
        }&boardId=${bordId}&academicYearId=${selectedAcademicYear}${selectedSubTopic?.id ? "&subTopicId=" + selectedSubTopic?.id : ""
        }&chapterId=${selectedChapterId?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const endDateFetched = res?.data?.data?.endDate;
        if (isNOTNullOrUndefined(endDateFetched)) {
          setisCheckingWhetherChapterIsEnded(false);
          setShowReleaseModal(true);
          setSelectedPreviewId(item.id);
          setSelectedQuizId(item.id);
          setQuizDuration(item.duration);
          releaseQuiz(item.id);
        } else {
          setChapterStatusMessage("Study of Chapter not Completed");
        }
      })
      .catch((err) => {
        // setisCheckingWhetherChapterIsEnded(false)
        setChapterStatusMessage("Some Error Occured try again");
        console.log(err);
      });
  };

  const onChapterStatusModalClose = () => {
    setisCheckingWhetherChapterIsEnded(false);
    setChapterStatusMessage(null);
  };
  function isPastEndTimeAndDate(endTime, endDate) {
    var currentTime = new Date();

    var endHour = parseInt(endTime.split(":")[0]);
    var endMinute = parseInt(endTime.split(":")[1].split(" ")[0]);
    var endMeridian = endTime.split(" ")[1];

    var endDay = parseInt(endDate.split("/")[0]);
    var endMonth = parseInt(endDate.split("/")[1]) - 1;
    var endYear = parseInt(endDate.split("/")[2]);

    var endDateTime = new Date(
      endYear,
      endMonth,
      endDay,
      endMeridian === "PM"
        ? endHour !== 12
          ? endHour + 12
          : endHour
        : endMeridian === "AM" && endHour === 12
          ? (endHour = 1)
          : endHour,
      endMinute
    );
    return currentTime > endDateTime;
  }
  // console.log(selectedSubject, "selected subject ..........................");
  const [chapterStatusMessage, setChapterStatusMessage] = useState(null);
  return (
    <div style={{ width: "100%" }}>
      <TopBar
        title={"Home"}
        subSubTitle={"Assign Quiz"}
        modifiedDate={modifiedDate}
      />
      {preSelectedData?.chapterId ? (
        <div
          style={{
            marginTop: "10px",
            paddingRight: "33px",
            position: "absolute",
            right: "15px",
            left: "15px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: "1.2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "10px",
            }}
          >
            <span style={{ position: "relative" }}>
              <p
                style={{
                  position: "absolute",
                  left: "18px",
                  top: "-12px",
                  fontSize: "12px",
                  backgroundColor: "#FFFF",
                }}
              >
                Select Grade
              </p>
              <select
                value={selectedGrade?.id ? selectedGrade?.id : selectedGrade}
                style={{
                  width: "180px",
                  padding: "10px",
                  borderRadius: "20px",
                  textOverflow: "ellipsis",
                }}
                onChange={handleChangeGrade}
              >
                {gradeSection?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item?.grade}
                  </option>
                ))}
              </select>
            </span>
            <FormControl
              sx={{
                minWidth: "180px",
                background: "white",
                borderRadius: 150,
                height: 40,
              }}
            >
              <InputLabel id="demo-simple-select-label">Section</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                input={<OutlinedInput label="Tag" />}
                MenuProps={MenuProps}
                style={{
                  borderRadius: "150px",
                  minWidth: "180px",
                  maxWidth: "180px",
                  textOverflow: "ellipsis",
                  fontSize: 8,
                  // padding: "4px",
                }}
                sx={{
                  fontSize: 8,
                  height: "100%",
                }}
                size={"small"}
                IconComponent={(props) => (
                  <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                )}
                value={
                  isNOTNullOrUndefined(selectedSection) ? selectedSection : ""
                }
                // onChange={(e) => setSelectedSection(e.target.value)}
                onChange={handleChangeSection}
              >
                {selectedGrade &&
                  selectedGrade.sections.map((sec) => {
                    if (sec.hasOwnProperty("section")) {
                      return (
                        <MenuItem
                          
                          value={sec.id}
                          key={sec.id}
                          className={
                            dashboardContentStyles.menu_item
                          }
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: 'orange',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#fcedc2',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}
                        >
                          <ListItemText primary={sec.section} />
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                minWidth: "180px",
                background: "white",
                borderRadius: 150,
                height: 40,
              }}
            >
              <InputLabel id="demo-simple-select-label">Subject</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                input={<OutlinedInput label="Tag" />}
                MenuProps={MenuProps}
                style={{
                  borderRadius: "150px",
                  minWidth: "180px",
                  maxWidth: "180px",
                  textOverflow: "ellipsis",
                  fontSize: 8,
                  // padding: "4px",
                }}
                sx={{
                  fontSize: 8,
                  height: "100%",
                }}
                onChange={handleChangeSubject}
                size={"small"}
                value={
                  isNOTNullOrUndefined(selectedSubject)
                    ? selectedSubject?.subjectId
                    : ""
                }
                IconComponent={(props) => (
                  <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                )}
              >
                {subjectList?.map((item) => (
                  <MenuItem
                    // value={item.subjectId}
                    // key={item.subjectId}
                    // className={dashboardContentStyles.menu_item}
                    value={item.subjectId}
                    key={item.subjectId}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText primary={item?.subject} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                minWidth: "180px",
                background: "white",
                borderRadius: 150,
                height: 40,
              }}
            >
              <InputLabel id="demo-simple-select-label">SubTopic</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                input={<OutlinedInput label="Tag" />}
                MenuProps={MenuProps}
                style={{
                  borderRadius: "150px",
                  minWidth: "180px",
                  maxWidth: "180px",
                  textOverflow: "ellipsis",
                  fontSize: 8,
                  // padding: "4px",
                }}
                sx={{
                  fontSize: 8,
                  height: "100%",
                }}
                onChange={handleChangeSubtopic}
                size={"small"}
                value={
                  isNOTNullOrUndefined(selectedSubTopic?.id)
                    ? selectedSubTopic?.id
                    : ""
                }
                IconComponent={(props) => (
                  <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                )}
              >
                {selectedSubject?.subTopics?.map((item) => (
                  <MenuItem
                    // value={item.id}
                    // key={item.id}
                    // className={dashboardContentStyles.menu_item}
                    value={item.id}
                    key={item.id}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText primary={item?.subTopic} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <span style={{ position: "relative" }}>
              <p
                style={{
                  position: "absolute",
                  left: "18px",
                  top: "-12px",
                  fontSize: "12px",
                  backgroundColor: "#FFFF",
                }}
              >
                Select Chapter
              </p>
              <select
                value={
                  selectedChapterId?.id
                    ? selectedChapterId?.id
                    : selectedChapterId
                }
                style={{
                  width: "180px",
                  padding: "10px",
                  borderRadius: "20px",
                  textOverflow: "ellipsis",
                }}
                onChange={handleChangeChapter}
              >
                <option value={null}>None</option>
                {chapterList?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item?.chapter}
                  </option>
                ))}
              </select>
            </span>
            <span style={{ position: "relative" }}>
              <p
                style={{
                  position: "absolute",
                  left: "18px",
                  top: "-12px",
                  fontSize: "12px",
                  backgroundColor: "#FFFF",
                }}
              >
                Selected year
              </p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                input={<OutlinedInput label="Tag" />}
                MenuProps={MenuProps}
                style={{
                  borderRadius: "150px",
                  // padding: "4px",
                }}
                size={"small"}
                value={selectedAcademicYear}
                IconComponent={(props) => (
                  <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                )}
                disabled
              >
                <MenuItem
                  // value={academicYearList[0]?.id}
                  // key={academicYearList[0]?.id}
                  // className={dashboardContentStyles.menu_item}
                  value={academicYearList[0]?.id}
                  key={academicYearList[0]?.id}
                  className={
                    dashboardContentStyles.menu_item
                  }
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: 'orange',
                      color: "#FFFFFF"
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: '#fcedc2',
                      color: "#000000"
                    },
                    "&:not(.Mui-selected):hover": {
                      backgroundColor: '#fcedc2',
                      color: '#000000',
                    },
                  }}
                >
                  <ListItemText primary={academicYearList[0]?.academicYear} />
                </MenuItem>
              </Select>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "1rem",
              height: "100%",
            }}
          >
            <div style={{ fontWeight: "600", fontSize: 18 }}>
              All Quiz For this Chapter
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "1rem",
                padding: "30px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  {selectedGrade &&
                    isNOTNullOrUndefined(selectedSubject) &&
                    selectedSection ? (
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={3}>
                        {allQuizData?.length > 0 ? (
                          allQuizData.map((item) => (
                            <Grid item xs={12} md={4} sm={6} key={item.id}>
                              <Item>
                                <div
                                  style={{
                                    padding: "15px 10px",
                                    color: "#f19e00",
                                    fontWeight: 600,
                                    fontSize: "0.93vw",
                                    wordWrap: "break-word",

                                    maxWidth: "300px",
                                  }}
                                >
                                  {item.quizType} | {item.name}
                                </div>
                                <div
                                  style={{
                                    background: "rgba(138,203,249,0.77)",
                                    width: "100%",
                                    padding: "20px",
                                    fontSize: "0.93vw",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      padding: "5px",
                                    }}
                                  >
                                    <div>Questions</div>
                                    <div style={{ minWidth: "80px" }}>
                                      {item.totalQuestions}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      padding: "5px",
                                    }}
                                  >
                                    <div>Marks</div>
                                    <div style={{ minWidth: "80px" }}>
                                      {item.totalMarks}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      padding: "5px",
                                    }}
                                  >
                                    <div>Duration</div>
                                    <div style={{ minWidth: "80px" }}>
                                      {`${item.duration} mins`}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    background: "rgba(4,59,189,0.91)",
                                    width: "100%",
                                    padding: "20px",
                                    color: "white",
                                    fontSize: "0.93vw",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      padding: "5px",
                                    }}
                                  >
                                    <div style={{ minWidth: "280px" }}>
                                      {Object.keys(
                                        item?.taxonomyPercentage
                                      ).map((v, i) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <p style={{ color: "white" }}>
                                              {v}
                                            </p>
                                            <p style={{ color: "white" }}>
                                              {item?.taxonomyPercentage[v]}%
                                            </p>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                                {item.containsCaseStudy === true && (
                                  <div
                                    style={{
                                      background: "rgb(3,38,120)",
                                      width: "100%",
                                      padding: "20px",
                                      color: "white",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    Contains CaseStudy
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "10px",
                                    alignItems: "center",
                                    width: "100%",
                                    columnGap: "1rem",
                                  }}
                                >
                                  <CancelButton
                                    onClick={() => onPreviewClick(item)}
                                  >
                                    Preview
                                  </CancelButton>
                                  {!item.allAttended &&
                                    (item.endTime !== null ? (
                                      isPastEndTimeAndDate(
                                        item.endTime,
                                        item.endDate
                                      ) && (
                                        <SaveButton
                                          onClick={() => onReleaseClick(item)}
                                        >
                                          Release
                                        </SaveButton>
                                      )
                                    ) : (
                                      <SaveButton
                                        onClick={() => onReleaseClick(item)}
                                      >
                                        Release
                                      </SaveButton>
                                    ))}
                                </div>
                              </Item>
                            </Grid>
                          ))
                        ) : (
                          <div style={{ color: "red" }}>
                            No Quiz for selected Filters
                          </div>
                        )}
                      </Grid>
                    </Box>
                  ) : (
                    <div>Please do select Grade , Section and Subject</div>
                  )}
                </Grid>
              </Box>
            </div>
          </div>
          {questionModalData && showModal && (
            <QuestionModal
              open={showModal}
              close={() => {
                setShowModal(false);
                setQuestionModalData(null);
                setSelectedQuizId("");
              }}
              data={questionModalData}
            />
          )}
          {showReleaseModal && (
            <ReleaseModal
              open={showReleaseModal}
              close={() => {
                setShowReleaseModal(false);
                setSelectedQuizId("");
                setSelectedPreviewId("");
              }}
              previewHandler={previewHandler}
              fetchSection={quizIds}
              selectedQuizId={selectedQuizId}
              quizDuraction={quizDuraction}
              teacherId={teacherId.id}
              OpenReRelease={OpenReRelease}
              setSelectedSectionReleaseData={setSelectedSectionReleaseData}
            />
          )}
          {showReReleaseModal && (
            <ReReleaseModal
              open={showReReleaseModal}
              close={() => setShowReReleaseModal(false)}
              selectedQuizId={selectedQuizId}
              teacherId={teacherId.id}
              selectedSectionReleaseData={selectedSectionReleaseData}
            />
          )}
          {isCheckingWhetherChapterIsEnded ? (
            <TeacherMessage
              message={chapterStatusMessage}
              open={isCheckingWhetherChapterIsEnded}
              close={onChapterStatusModalClose}
            />
          ) : null}
        </div>
      ) : (
        <div
          style={{
            marginTop: "55px",
            paddingRight: "33px",
            position: "absolute",
            right: "15px",
            left: "15px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: "10px",
              alignItems: "center",
              columnGap: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <FormControl
                sx={{
                  minWidth: "7.8vw",
                  background: "white",
                  borderRadius: 150,
                }}
              >
                <InputLabel id="demo-simple-select-label">Grades</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  input={<OutlinedInput label="Tag" />}
                  MenuProps={MenuProps}
                  style={{
                    borderRadius: "150px",
                    padding: "4px",
                  }}
                  size={"small"}
                  // value={selectedGrade}
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                  )}
                  onChange={handleChangeGrade}
                >
                  {gradeSection?.map((item) => {
                    return (
                      <MenuItem
                        // value={item?.id}
                        // key={item.id}
                        // className={dashboardContentStyles.menu_item}
                        value={item?.id}
                        key={item.id}
                        className={
                          dashboardContentStyles.menu_item
                        }
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}
                      >
                        <ListItemText primary={item.grade} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  minWidth: "7.8vw",
                  background: "white",
                  borderRadius: 150,
                }}
              >
                <InputLabel id="demo-simple-select-label">Section</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  input={<OutlinedInput label="Tag" />}
                  MenuProps={MenuProps}
                  style={{
                    borderRadius: "150px",
                    padding: "4px",
                  }}
                  size={"small"}
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                  )}
                  value={selectedSection}
                  // onChange={(e) => setSelectedSection(e.target.value)}
                  onChange={handleChangeSection}
                >
                  {selectedGrade &&
                    selectedGrade.sections.map((sec) => {
                      if (sec.hasOwnProperty("section")) {
                        return (
                          <MenuItem
                            // value={sec.id}
                            // key={sec.id}
                            // className={dashboardContentStyles.menu_item}
                            value={sec.id}
                            key={sec.id}
                            className={
                              dashboardContentStyles.menu_item
                            }
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: 'orange',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#fcedc2',
                                color: "#000000"
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}
                          >
                            <ListItemText primary={sec.section} />
                          </MenuItem>
                        );
                      }
                    })}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  minWidth: "7.8vw",
                  background: "white",
                  borderRadius: 150,
                }}
              >
                <InputLabel id="demo-simple-select-label">Subjects</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  input={<OutlinedInput label="Tag" />}
                  MenuProps={MenuProps}
                  style={{
                    borderRadius: "150px",
                    padding: "4px",
                  }}
                  size={"small"}
                  value={
                    isNOTNullOrUndefined(selectedSubject)
                      ? selectedSubject?.subjectId
                      : ""
                  }
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                  )}
                  // onChange={(e) => setSelectedSubject(e.target.value)}
                  onChange={handleChangeSubject}
                >
                  {subjectList?.map((item) => {
                    return (
                      <MenuItem
                        // value={item.subjectId}
                        // key={item.subjectId}
                        // className={dashboardContentStyles.menu_item}
                        value={item.subjectId}
                        key={item.subjectId}
                        className={
                          dashboardContentStyles.menu_item
                        }
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}
                      >
                        <ListItemText primary={item.subject} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  minWidth: "7.8vw",
                  background: "white",
                  borderRadius: 150,
                }}
              >
                <InputLabel id="demo-simple-select-label">SubTopic</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  input={<OutlinedInput label="Tag" />}
                  MenuProps={MenuProps}
                  style={{
                    borderRadius: "150px",
                    padding: "4px",
                  }}
                  size={"small"}
                  value={
                    isNOTNullOrUndefined(selectedSubTopic?.id)
                      ? selectedSubTopic?.id
                      : ""
                  }
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                  )}
                  onChange={handleChangeSubtopic}
                >
                  {subjectList.length > 0
                    ? subjectList?.map((item) => {
                      return item?.subTopics?.map((sub) => {
                        if (sub.hasOwnProperty("subTopic")) {
                          return (
                            <MenuItem
                              // value={sub.id}
                              // key={sub.id}
                              // className={dashboardContentStyles.menu_item}
                              value={sub.id}
                              key={sub.id}
                              className={
                                dashboardContentStyles.menu_item
                              }
                              sx={{
                                "&.Mui-selected": {
                                  backgroundColor: 'orange',
                                  color: "#FFFFFF"
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: '#fcedc2',
                                  color: "#000000"
                                },
                                "&:not(.Mui-selected):hover": {
                                  backgroundColor: '#fcedc2',
                                  color: '#000000',
                                },
                              }}
                            >
                              <ListItemText primary={sub.subTopic} />
                            </MenuItem>
                          );
                        }
                      });
                    })
                    : null}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  minWidth: "7.8vw",
                  background: "white",
                  borderRadius: 150,
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Academic Years
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  input={<OutlinedInput label="Tag" />}
                  MenuProps={MenuProps}
                  style={{
                    borderRadius: "150px",
                    padding: "4px",
                  }}
                  size={"small"}
                  value={selectedAcademicYear}
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                  )}
                  onChange={(e) => setSelectedAcademicYear(e.target.value)}
                  disabled
                >
                  <MenuItem
                    // value={academicYearList[0]?.id}
                    // key={academicYearList[0]?.id}
                    // className={dashboardContentStyles.menu_item}
                    value={academicYearList[0]?.id}
                    key={academicYearList[0]?.id}
                    className={
                      dashboardContentStyles.menu_item
                    }
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText primary={academicYearList[0]?.academicYear} />
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <TextField
              type={"text"}
              placeholder={"Search anything...."}
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <img
                      src={searchIcn}
                      style={{
                        verticalAlign: "top",
                        fontSize: "large",
                      }}
                      aria-hidden="true"
                      alt=""
                    />
                  </IconButton>
                ),
              }}
              size={"small"}
              sx={{
                background: !isError && "#fff",
                borderRadius: "150px",
                "& .MuiInputBase-root": {
                  width: "247px !important",
                  height: "32px !important",
                  borderRadius: "50px !important",
                  background: "#fff !important",
                  lineHeight: "21px !important",
                  font: "normal normal normal 13px/20px Poppins !important",
                },
              }}
              error={isError}
              helperText={isError && "Please Enter min 3 character."}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: 700,
              width: "100%",
              padding: "25px 20px",
              overflow: "auto",
              scrollbarWidth:"thin"
            }}
          >
            {selectedGrade && selectedSection && selectedSubject ? (
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {updatedData.length > 0 ? (
                    updatedData
                      ?.filter((itm) =>
                        itm.chapter.toLowerCase().includes(query)
                      )
                      .map((item) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            md={2.4}
                            sm={4}
                            key={item.id}
                            onClick={() => {
                              quizRelease(item);
                            }}
                          >
                            <ChapterItem
                              key={item?.id}
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  minHeight: "166px",
                                  textAlign: "center",
                                }}
                                key={item?.id}
                              >
                                <img
                                  src={item?.thumbnailPath}
                                  width={110}
                                  alt={""}
                                  style={{
                                    maxHeight: "138px",
                                  }}
                                />
                                <div
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    color: "#423d5c",
                                  }}
                                >
                                  {item?.chapter}
                                </div>
                              </div>
                            </ChapterItem>
                          </Grid>
                        );
                      })
                  ) : (
                    <div>No data for the selected filters</div>
                  )}
                </Grid>
              </Box>
            ) : (
              <div>Please select Grade , Section & Subject</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Principal_AssignQuiz;
