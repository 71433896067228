import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import ChapterStyles from "../../css/createChapter.module.css";
import axios from "axios";
import boards_controller_getall from "../../API_CONTROLLER/boards-controller/boards_controller_getall";
import gradeListGet from "../../API/grade-section-mapping-controller/grade_get";
import { orange } from "@mui/material/colors";
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    Box,
    TextField,
    Modal,
    styled,
    Alert,
    IconButton
} from "@mui/material";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Checkbox from "@mui/material/Checkbox";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import action__Add from "../../../../src/img/AddDefault.svg";
import action__Delete from "../../../../src/img/Delete.svg";
import SelectBoardAndGrade from "../../js/QuestionBank/CommonComponents/SelectBoardAndGrade";
import TextEditor from "../../js/QuestionBank/CommonComponents/TextEditor";
import DeleteConfirmationAlert from "../../js/QuestionBank/CommonComponents/DeleteConfirmation";
import create_question_post from "../../API_CONTROLLER/create-questions/question_post";
import ImageListModal from "../../js/QuestionBank/CommonComponents/ImageListModal";
import Preview from "../../js/QuestionBank/CommonComponents/preview";
import get_question from "../../API_CONTROLLER/create-questions/get_questionbyid";
import update_question_post from "../../API_CONTROLLER/create-questions/question_put";

const CustomHead = styled(Typography)(({ theme }) => ({
    '&': {
        color: "#FD8C00",
        fontFamily: "Poppins",
        fontSize: "1.3vw",
        fontWeight: 400,
        lineHeight: "27px",
    },
}))

const CustomTextField = styled(TextField)(({ theme }) => ({
    width: "96%",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "2px !important",
    "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
        opacity: "1 !important",
        background: "rgb(255 255 255 / 31%) !important",
        border: "1px solid #B5B6B6",
        borderRadius: "2px !important",
    }
}))

const CustomRadioIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    color: orange[600],
    boxShadow: 'inset 0 0 0 3px orange, inset 0 -1px 0 orange',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto orange',
        color: orange[600],
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
    backgroundColor: orange[600],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: orange[800],
    },
});

const CustomButton = styled(Button)(({ theme }) => ({
    color: "#FFFFFF",
    backgroundColor: '#FD8C00',
    fontFamily: ['normal', 'normal', 'medium', 'Poppins'].join(','),
    fontSize: '10px',
    borderRadius: '30px',
    width: 'auto',
    padding: "0.6vw 1vw",
    '&:hover': {
        backgroundColor: '#FD8C00',
    },
}));

const CustomAddButton = styled(Button)(({ theme }) => ({
    color: "#9B9FA7",
    backgroundColor: '#F8F8F8',
    border: '1px dashed #B5B6B6',
    borderRadius: '10px',
}))

function CustomRadio(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<CustomRadioCheckedIcon />}
            icon={<CustomRadioIcon />}
            {...props}
        />
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '58%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const MultiSelectQB = (props) => {

    const [clearContent, setClearContent] = useState(false);

    const [boardsList, setBoardsList] = useState();
    const [boardSelect, setBoardSelect] = useState('');

    const [gradeList, setGradeList] = useState();
    const [gradeSelect, setGradeSelect] = useState([]);

    const [boardAndGrade, setBoardAndGrade] = useState([]);

    const [boardAndGradeCombo, setBoardAndGradeCombo] = useState([]);

    const [questionContent, setQuestionContent] = useState({ content: "" });
    const [questionEditContent, setEditQuestionContent] = useState({ content: "" });
    const [answerContent, setAnswerContent] = useState([]);

    const [radioButtonValue, setRadioButtonValue] = useState("machine");
    const [radioButtonEnable, setRadioButtonEnable] = useState(false);

    const [uploadImage, setUploadImage] = useState();
    const [selectedImageName, setSelectedImageName] = useState("");
    const [selectedImageUrl, setSelectedImageUrl] = useState();

    const [addQuestionCount, setAddQuestionCount] = useState([65, 66, 67, 68]);

    const [showAlert, setShowAlert] = useState(false);
    const [deleteBlankAlert, setDeleteBlankAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const [errorField, setErrorField] = useState({});
    const [errorFieldCombo, setErrorFieldCombo] = useState({});

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState();

    const [imagePreview, setImagePreview] = useState();
    const [openPreviewModal, setOpenPreviewModal] = React.useState(false);
    const handlePreviewModalOpen = () => setOpenPreviewModal(true);
    const handlePreviewModalClose = () => setOpenPreviewModal(false);

    const [openImageListModal, setOpenImageListModal] = React.useState(false);
    const handleImageListModalOpen = () => setOpenImageListModal(true);
    const handleImageListModalClose = () => setOpenImageListModal(false);

    const subjectId = sessionStorage.getItem("subjectId");
    const conceptId = sessionStorage.getItem("conceptId");
    const subQuestionConceptId = sessionStorage.getItem("subQuestionConceptId");
    const subConceptId = sessionStorage.getItem("subConceptId");
    const subQuestionSubConceptId = sessionStorage.getItem("subQuestionSubConceptId");
    const rootConcept = sessionStorage.getItem("rootConceptId");
    const questionTypeId = sessionStorage.getItem("questionType");
    const subQuestionTypeId = sessionStorage.getItem("subQuestionType");
    const taxonomyCategory = sessionStorage.getItem("taxonomyCategory");
    const taxonomy = sessionStorage.getItem("taxonomy");
    const parentId = sessionStorage.getItem("parentId");

    const [responseValue, setResponseValue] = useState();
    const [edit, setEdit] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showerrorMessage, setShowErroMessage] = useState();
    const [questionValue, setQuestionValue] = useState();
    const [optionsEdit, setOptionsEdit] = useState();
    const [previewFromModal, setPreviewFromModal] = useState(false);
    const [editButton, setEditButton] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        var basic_info = JSON.parse(sessionStorage.getItem("Basic_info"));
        console.log("basic information", basic_info?.boardId, props.selectedGrade)
        // setBoardSelect(basic_info?.boardId);
        // setGradeSelect(props.selectedGrade);
        handleBoardChange(basic_info?.boardId);
        handleGradeChange(props.selectedGrade);
        setTimeout(() => {
            handleBoardAndGradeChange()
        }, 3000);

    }, [])

    useEffect(() => {
        boards_controller_getall(setBoardsList);
        gradeListGet(setGradeList);
        setClearContent(false);
        sessionStorage.removeItem("questioncontent");
        sessionStorage.removeItem("answerkeycontent");
        sessionStorage.removeItem("options");
        sessionStorage.removeItem("images");
    }, [])

    // const handleBoardChange = (e) => {
    //     setBoardSelect(e.target.value);
    //     setErrorFieldCombo(prevState => {
    //         delete prevState['board'];
    //         return prevState;
    //     })
    // }

    // const handleGradeChange = (e) => {
    //     const value = e.target.value;
    //     setGradeSelect(typeof value === "string" ? value.split(",") : value);
    //     setErrorFieldCombo(prevState => {
    //         delete prevState['grade'];
    //         return prevState;
    //     })
    // }

    const handleBoardChange = (e) => {
        console.log(e, "handleBoardChange")
        // setBoardSelect(e.target.value)
        setBoardSelect(e)
        setErrorFieldCombo(prevState => {
            delete prevState['board'];
            return prevState;
        })
    }

    const handleGradeChange = (e) => {
        console.log("handleGradeChange", e)
        // const value = e.target.value;
        const value = e;
        setGradeSelect(typeof value === "string" ? value.split(",") : value);
        setErrorFieldCombo(prevState => {
            delete prevState['grade'];
            return prevState;
        })
    }

    const handleCreateQuestion = (type) => {
        let isError = false;

        let errorObj = {};
        let answerContentFoundEmpty = answerContent.some(ansContent => ansContent.answerKey === '' && ansContent.htmlTag === '');
        let checkContentFoundEmpty = answerContent.filter(ckContent => ckContent.correctAnswer === true).length < 2;

        let answerKeyFoundEmpty = answerContent.find(ansContent => ansContent.answerKey === '');
        if (answerKeyFoundEmpty) {
            let optionFind = answerKeyFoundEmpty.order;
            answerKeyFoundEmpty.answerKey = `option ${String.fromCharCode(optionFind)}`;
        }
        // (boardAndGradeCombo.length === 0 || Object.keys(boardAndGradeCombo).length === 0)
        if (questionContent.content === "" || answerContent.length === 0 || props.caseStudy !== true && answerContent.length !== addQuestionCount.length || answerContentFoundEmpty === true || checkContentFoundEmpty === true) {
            isError = true;
            // if (boardAndGradeCombo.length === 0) {
            //     errorObj['combo'] = "Please provide the combination of board and grade";
            // }
            // if (Object.keys(boardAndGradeCombo).length === 0) {
            //     errorObj['combo'] = "Please provide the combination of board and grad";
            // }
            if (questionContent.content === "") {
                errorObj['question'] = "Please provide the question";
            }
            if (answerContentFoundEmpty === true) {
                errorObj['foundEmpty'] = "Answer content found empty";
            }
            if (checkContentFoundEmpty === true) {
                errorObj['foundEmptyCheck'] = "Please Select The Correct Answer";
            }
        }

        setErrorField(errorObj);

        if (!isError) {
            // setErrorField({ id: "", message: "" });
            setClearContent(false);
            if (type === "update") {
                setEdit(false);
                const payload1 = {
                    id: questionValue.id,
                    subjectId: subjectId,
                    conceptId: props.caseStudy ? subQuestionConceptId : conceptId,
                    subConceptId: props.caseStudy ? subQuestionSubConceptId : subConceptId,
                    rootConceptId: props.caseStudy ? null : rootConcept !== "undefined" ? rootConcept : null,
                    questionTypeId: props.caseStudy ? subQuestionTypeId : questionTypeId,
                    taxonomyCategoryId: taxonomyCategory,
                    taxonomyId: taxonomy,
                    boardAndGrades: boardAndGrade,
                    question: questionContent.content,
                    explanation: null,
                    imageUrl: selectedImageUrl,
                    // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
                    hasSubQuestions: false,
                    options: answerContent,
                    questionPattern: "OBJECTIVE",
                    parentQuestionId: props.caseStudy && parentId,
                }
                const payload = removeNewlines(payload1);
                console.log(">>>", payload);
                update_question_post(payload, setShowSuccessAlert, setResponseValue, setShowErrorAlert, setShowErroMessage, props.getResponse);
            }
            else {
                const payload1 = {
                    subjectId: subjectId,
                    conceptId: props.caseStudy ? subQuestionConceptId : conceptId,
                    subConceptId: props.caseStudy ? subQuestionSubConceptId : subConceptId,
                    rootConceptId: props.caseStudy ? null : rootConcept !== "undefined" ? rootConcept : null,
                    questionTypeId: props.caseStudy ? subQuestionTypeId : questionTypeId,
                    taxonomyCategoryId: taxonomyCategory,
                    taxonomyId: taxonomy,
                    boardAndGrades: boardAndGrade.length > 0 ? boardAndGrade : [{ boardId: boardSelect, gradeId: gradeSelect[0] }],
                    question: questionContent.content,
                    explanation: null,
                    imageUrl: selectedImageUrl,
                    // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
                    hasSubQuestions: false,
                    options: answerContent,
                    questionPattern: "OBJECTIVE",
                    parentQuestionId: props.caseStudy && parentId,
                }
                const payload = removeNewlines(payload1);
                console.log(">>>", payload);
                // create_question_post(payload, setShowSuccessAlert, setResponseValue, setShowErrorAlert, setShowErroMessage, callBackForQstn);
                Save_question(payload, setShowSuccessAlert, setResponseValue, setShowErrorAlert, setShowErroMessage)
            }
            sessionStorage.setItem("questioncontent", questionContent.content);
            sessionStorage.setItem("options", JSON.stringify(answerContent));
            sessionStorage.setItem("images", imagePreview ? imagePreview : selectedImageUrl);
        }
    }
    const Save_question = async (payload, setShowSuccessAlert, setResponseValue, setShowErrorAlert, setShowErroMessage) => {
        console.log(payload, setShowSuccessAlert, setResponseValue, setShowErrorAlert, setShowErroMessage, "Save_question called", props?.selectedQuestionData?.marks, payload)
        if (payload.question !== "") {
            var enteredOptions = payload?.options?.filter(v => v.correctAnswer === true) || []
            console.log(props?.selectedQuestionData?.marks, enteredOptions, enteredOptions?.length)
            if (props?.selectedQuestionData?.marks !== enteredOptions?.length) {
                setErrorField({ "message": "Mark does not match the selected question." })
                setTimeout(() => {
                    setErrorField("")
                }, 5000);
            } else {
                try {

                    let token = sessionStorage.getItem("token");
                    console.log("payload", payload)
                    let response = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question`, payload,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                            },
                        });
                    console.log("response data", response.data.data);
                    // sessionStorage.setItem("newQuestion", JSON.stringify(response.data.data));
                    // setShowSuccessAlert(true);
                    // setResponseValue(response.data.data);
                    // response.data.data.payload = payload;
                    props.getResponse(response.data.data)
                } catch (error) {
                    console.log(error, "errrpppppp");
                    // setShowErrorAlert(true);
                    // setShowErroMessage(error?.response?.data?.message || "error");
                    // setResponseValue({ error });
                    // setTimeout(() => {
                    //     setShowErrorAlert(false);
                    //     setShowErroMessage("");
                    //     setResponseValue({});
                    // }, 10000);
                    setErrorField(error.response.data)
                    setTimeout(() => {
                        setErrorField("")
                    }, 5000);
                }
            }
        } else {
            // callback({ error: "504", errorMessage: "Please fill the Required fields" });
            // alert({ error: "504", errorMessage: "Please fill the Required fields" });
            setErrorField("Please fill the Required fields")
            setTimeout(() => {
                setErrorField("")
            }, 5000);

        }
    }
    const removeNewlines = (obj) => {
        const newObj = {};
        // Recursively traverse the object
        for (const key in obj) {
            if (typeof obj[key] === 'string') {
                obj[key] = obj[key].replace(/\n/g, '');
            } else if (typeof obj[key] === 'object') {
                removeNewlines(obj[key]);
            }
        }
        return obj;
    };

    // useEffect(() => {
    //     if (!edit) {
    //         if (showSuccessAlert) {
    //             if (props.caseStudy) {
    //                 props.setOpenQuestionModal(false);
    //                 setTimeout(() => {
    //                     props.setSuccessMessage("You have successfully Added subquestion “Fill in the blanks” for Case Study")
    //                 }, 5000);
    //                 props.setGetSubQuestion(true);
    //             }
    //             else {
    //                 setSuccessMessage(`You have successfully created MSQ question`);
    //             }
    //             setBoardSelect('');
    //             setGradeSelect([]);
    //             setBoardAndGradeCombo([]);
    //             setClearContent(true);
    //             const defaultQues = ['65', '66', '67', '68']
    //             setAnswerContent(defaultQues.map(id => ({
    //                 "option": String.fromCharCode(id),
    //                 "order": id,
    //                 "answerKey": '',
    //                 "resourceUrl": "",
    //                 "correctAnswer": ''
    //             })));
    //             setAddQuestionCount(defaultQues);
    //             setClearContent(true);
    //             // setPreview(true);
    //             setUploadImage();
    //             setImagePreview();
    //             setSelectedImageName("");
    //             setSelectedImageUrl();
    //             setErrorField({});
    //             setErrorFieldCombo({});
    //             // props.setDisableTopFields(false);
    //             setEdit(false);
    //             setShowEditor(false);
    //         }
    //     }
    //     else {
    //         if (showSuccessAlert) {
    //             if (props.caseStudy) {
    //                 props.setOpenQuestionModal(false);
    //                 setTimeout(() => {
    //                     props.setSuccessMessage("You have successfully Added subquestion “Multi Select Question” for Case Study")
    //                 }, 5000);
    //                 props.setGetSubQuestion(true);
    //             }
    //             else {
    //                 setSuccessMessage(`You have successfully updated MSQ question`);
    //             }
    //             setBoardSelect('');
    //             setGradeSelect([]);
    //             setBoardAndGradeCombo([]);
    //             setClearContent(true);
    //             const defaultQues = ['65', '66', '67', '68']
    //             setAnswerContent(defaultQues.map(id => ({
    //                 "option": String.fromCharCode(id),
    //                 "order": id,
    //                 "answerKey": '',
    //                 "resourceUrl": "",
    //                 "correctAnswer": ''
    //             })));
    //             setAddQuestionCount(defaultQues);
    //             setClearContent(true);
    //             // setPreview(true);
    //             setUploadImage();
    //             setImagePreview();
    //             setSelectedImageName("");
    //             setSelectedImageUrl();
    //             setErrorField({});
    //             setErrorFieldCombo({});
    //             // props.setDisableTopFields(false);
    //             // setEdit(false);
    //             setShowEditor(false);
    //         }
    //     }
    // }, [showSuccessAlert]);

    const onHideSuccessModal = () => {
        setShowSuccessAlert(false);
    }

    const handleBoardAndGradeChange = () => {
        let errorObj = {};
        let error = false;

        if (boardSelect === "") {
            errorObj['board'] = "Please choose the Board";
            error = true;
            // setErrorField({ message: " Board field is required", id: "board" });
        }
        if (gradeSelect.length === 0) {
            errorObj['grade'] = "Please choose atleast one Grade";
            error = true;
        }
        setErrorFieldCombo(errorObj);
        if (!error) {
            setErrorField(prevState => {
                delete prevState['combo'];
                return prevState;
            })
            if (Object.keys(boardAndGradeCombo).length > 0) {
                setErrorField({ message: "", id: "" });
                const keys = Object.keys(boardAndGradeCombo)
                const foundKey = Object.hasOwn(boardAndGradeCombo, boardSelect);
                if (foundKey) {
                    let found;
                    found = gradeSelect.filter(item => !boardAndGradeCombo[boardSelect].includes(item))
                    const concatArray = boardAndGradeCombo[boardSelect].concat(found);
                    setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: concatArray } });
                    setBoardSelect('');
                    setGradeSelect([]);
                }
                else {
                    setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: gradeSelect } });
                    setBoardSelect('');
                    setGradeSelect([]);
                }
            }
            else {
                setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: gradeSelect } });
                setBoardSelect('');
                setGradeSelect([]);
            }
            gradeSelect.map((grade, ind) => {
                let obj = {
                    "boardId": boardSelect,
                    "gradeId": grade
                };
                setBoardAndGrade(oldArray => [...oldArray, obj]);
            })
        }
    }

    // useEffect(() => {
    //     setGradeSelect([]);
    // }, [boardSelect])

    const onRemoveCombo = (boardId, gradeId) => {
        boardAndGrade.map((key, index) => {
            if (key.boardId === boardId && key.gradeId === gradeId) {
                boardAndGrade.splice(index, 1);
            };
        })
        Object.keys(boardAndGradeCombo).map(key => {
            if (key === boardId) {
                boardAndGradeCombo[key].map((value, i) => {
                    if (value === gradeId) {
                        boardAndGradeCombo[key].splice(i, 1);
                        if (boardAndGradeCombo[key].length === 0) {
                            delete boardAndGradeCombo[key];
                            setBoardSelect('');
                            setGradeSelect([]);
                        }
                        else {
                            setBoardSelect(key);
                            setGradeSelect([...boardAndGradeCombo[key]]);
                        }
                    }
                })
            }
        })
    }

    useEffect(() => {
        if (boardsList !== undefined) {
            const boardName = boardsList.map((board, index) => {
                const found = boardAndGrade.some(boardAndGrade => boardAndGrade.boardId === board.id)
                if (found) {
                    return board.board;
                }
            }).filter(Boolean);
            const gradeName = gradeList.map((grade, index) => {
                const found = boardAndGrade.some(boardAndGrade => boardAndGrade.gradeId === grade.id)
                if (found) {
                    return grade.grade;
                }
            }).filter(Boolean);
        }
    }, [boardAndGrade]);

    const onQuestionHandler = (content) => {
        setQuestionContent({ content: content });
        setErrorField(prevState => {
            delete prevState['question'];
            return prevState;
        })
    }

    // const onAnswerHandler = (content, id) => {
    //     console.log(content, id, answerContent)
    //     var lengthReached = answerContent.filter(item => item.correctAnswer == true) || [];
    //     console.log(lengthReached.length)

    //     if (content !== "") {
    //         function convertHtmlToText(html) {
    //             const element = document.createElement('div');
    //             element.innerHTML = html;
    //             return element.textContent || element.innerText || '';
    //         }
    //         var quesWithAns = addQuestionCount.map((queNo) => {
    //             let preAns = answerContent.find(ans => ans.order === queNo && ans.correctAnswer == true);
    //             if (typeof content === 'boolean') {
    //                 if (preAns && preAns.order === id) {
    //                     console.log("1111111111ifffffffffffffff", preAns, id, lengthReached, answerContent.length, content)
    //                     preAns.correctAnswer = content
    //                 }
    //                 else if (!preAns) {
    //                     console.log("2222222222222222ifffffffffffffff", preAns, id, lengthReached.length, answerContent.length)
    //                     preAns = {
    //                         "option": String.fromCharCode(id),
    //                         "order": id,
    //                         "answerKey": '',
    //                         "resourceUrl": "",
    //                         "correctAnswer": content,
    //                         "htmlTag": ''
    //                     }
    //                 }
    //             } else {
    //                 if (preAns && preAns.order === id) {
    //                     console.log("33333333333333ifffffffffffffff", preAns, id, lengthReached.length, answerContent.length)
    //                     preAns.answerKey = convertHtmlToText(content)
    //                     preAns.htmlTag = content
    //                 }
    //                 else if (!preAns) {
    //                     console.log("44444444444ifffffffffffffff", preAns, id, lengthReached.length, answerContent.length)
    //                     preAns = {
    //                         "option": String.fromCharCode(id),
    //                         "order": id,
    //                         "answerKey": convertHtmlToText(content),
    //                         "resourceUrl": "",
    //                         "correctAnswer": false,
    //                         "htmlTag": content
    //                     }
    //                 }
    //             }
    //             console.log("preAns", preAns)
    //             return preAns;
    //         })
    //         var cnt = quesWithAns.filter(item => item.correctAnswer == true);
    //         console.log("quesWithAns", quesWithAns, cnt)
    //         cnt = cnt.filter((obj, index, self) => {
    //             const uniqueKey = obj.order;
    //             console.log(index === self.findIndex((o) => o.order === uniqueKey))
    //             return index === self.findIndex((o) => o.order === uniqueKey);
    //         })
    //         var temp = []
    //         quesWithAns.filter((v) => {
    //             if (v.order !== id && content == true) {
    //                 temp.push(v)
    //             } else {
    //                 v.correctAnswer = false
    //                 temp.push(v)
    //             }
    //         })
    //         console.log("cnt", cnt, quesWithAns, lengthReached.length, answerContent.length - 1)
    //         setAnswerContent(temp);

    //     }
    // }


    const onAnswerHandler = (content, id) => {
        if (content !== "") {
            function convertHtmlToText(html) {
                const element = document.createElement('div');
                element.innerHTML = html;
                return element.textContent || element.innerText || '';
            }
            const quesWithAns = addQuestionCount.map((queNo) => {
                let preAns = answerContent.find(ans => ans.order === queNo);
                if (typeof content === 'boolean') {
                    if (preAns && preAns.order === id) {
                        preAns.correctAnswer = content
                    }
                    else if (!preAns) {
                        preAns = {
                            "option": String.fromCharCode(id),
                            "order": id,
                            "answerKey": '',
                            "resourceUrl": "",
                            "correctAnswer": content,
                            "htmlTag": ''
                        }
                    }
                } else {
                    if (preAns && preAns.order === id) {
                        preAns.answerKey = convertHtmlToText(content)
                        preAns.htmlTag = content
                    }
                    else if (!preAns) {
                        preAns = {
                            "option": String.fromCharCode(id),
                            "order": id,
                            "answerKey": convertHtmlToText(content),
                            "resourceUrl": "",
                            "correctAnswer": false,
                            "htmlTag": content
                        }
                    }
                }
                return preAns;
            })
            var cnt = quesWithAns.filter(item => item.correctAnswer == true);
            console.log("quesWithAns1", quesWithAns, cnt)
            cnt = cnt.filter((obj, index, self) => {
                const uniqueKey = obj.order;
                console.log(index === self.findIndex((o) => o.order === uniqueKey))
                return index === self.findIndex((o) => o.order === uniqueKey);
            })
            console.log("quesWithAns2", quesWithAns, cnt)
            var temp = []
            quesWithAns.filter((v) => {
                if (cnt.length == quesWithAns.length - 1) {
                    console.log("---------", content, v.order, id)
                    if (content == true && v.order == id) {
                        v.correctAnswer = false
                    }
                    temp.push(v)
                }
                else {
                    temp.push(v)
                }
            });
            console.log("quesWithAns after", quesWithAns, temp);
            setAnswerContent(temp);
        }
    }

    const imageUploadHandler = (e) => {
        setRadioButtonValue(e.target.value)
        if (e.target.value === "repository") {
            handleImageListModalOpen();
        }

    }

    const onFileChange = (e) => {
        const file = e.target.files[0];
        setUploadImage(e.target.files[0]);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
        var fileMode = new File([file], file.name, { type: file.type });
        const formData = new FormData();
        // formData.append("fileCategory", "CONTENT_SERVICE");
        // formData.append("subFolders", "QUESTIONS");
        formData.append("file", fileMode);

        const file_upload_post = async () => {
            let token = sessionStorage.getItem("token");
            let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=QUESTIONS`, formData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(
                    (response) => {
                        setSelectedImageUrl(response.data.data)
                        sessionStorage.setItem("logoUrl", response.data.data)
                    }

                )
                .catch(err => console.log(err))
        }
        file_upload_post();
    }

    const onRemoveImage = (type) => {
        if (type === "upload") {
            setUploadImage();
            setSelectedImageUrl()
            setUploadImage()
        }
        else {
            setImagePreview();
            setSelectedImageUrl();
            setSelectedImageName("");
        }
    }

    useEffect(() => {
        if (uploadImage || selectedImageName) {
            setRadioButtonEnable(true);
        }
        else {
            setRadioButtonEnable(false);
        }
    }, [uploadImage, selectedImageName]);

    const addQuestionHandler = () => {
        const count = addQuestionCount[addQuestionCount.length - 1];
        setAddQuestionCount(oldArray => [...oldArray, parseInt(count) + 1]);
    }

    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    const fileInput = React.useRef();

    const handleCloseClick = () => {
        setBoardSelect(null);
    }

    const onDeleteBlank = (order, index) => {

        // Use slice instead of splice to keep the original array intact
        const newQuesList = addQuestionCount.slice();
        newQuesList.splice(index, 1);

        console.log("newQuesList", newQuesList, answerContent);
        console.log("optionsEdit", optionsEdit);


        // const newQuesList = addQuestionCount.filter(que => que !== order).map((que, i) => (i + 65).toString())
        const newAnsList = answerContent.filter(ans => ans.order !== order).map((ans, i) => ({ ...ans, order: (i + 65).toString() }))
        let newAnsListEdit;
        if (edit) {
            newAnsListEdit = optionsEdit.filter(ans => ans.order !== order).map((ans, i) => ({ ...ans, order: (i + 65).toString() }));
        }
        if (newQuesList.length >= 3) {
            setAddQuestionCount(newQuesList);
            setAnswerContent(newAnsList);
            setOptionsEdit(newAnsListEdit);
            answerContent.splice(index, 1);
            setAnswerContent(answerContent)
        }
        if (newQuesList.length < 3) {
            setDeleteBlankAlert(true);
            setAlertMessage("For Deleting Choices At least Three Choices Are Required");
        }
    }

    const handleEditQuestion = () => {
        get_question(responseValue.id, setQuestionValue);
        setEditButton(true);
        // let myObj = [];
        // setEdit(true);
        // let answerContent = sessionStorage.getItem("options");
        // let parsedContent = JSON.parse(answerContent);
        // setOptionsEdit(parsedContent);
        // let count = [65];
        // parsedContent.length > 0 && parsedContent.map((ans, index) => {
        //     if (index > 0) {
        //         const updateCount = count[count.length - 1];
        //         count = [...count, updateCount + 1];
        //     }
        // })
        // setAddQuestionCount(count);
        // responseValue.boardAndGrades.map((boardGrade, ind) => {
        //     let obj = {
        //         "boardId": boardGrade.boardId,
        //         "gradeId": boardGrade.gradeId
        //     };
        //     setBoardAndGrade(oldArray => [...oldArray, obj]);
        //     if (ind !== 0) {
        //         setErrorField(prevState => {
        //             delete prevState['combo'];
        //             return prevState;
        //         })
        //         const foundKey = Object.hasOwn(myObj, boardGrade.boardId);
        //         if (foundKey) {
        //             myObj[boardGrade.boardId].push(boardGrade.gradeId);
        //             setBoardAndGradeCombo(myObj);
        //             setBoardSelect('');
        //             setGradeSelect([]);
        //         }
        //         else {
        //             myObj[boardGrade.boardId] = [boardGrade.gradeId];
        //             setBoardAndGradeCombo(myObj);
        //             setBoardSelect('');
        //             setGradeSelect([]);
        //         }
        //     }
        //     else {
        //         myObj = { [boardGrade.boardId]: [] };
        //         myObj[boardGrade.boardId].push(boardGrade.gradeId);
        //         setBoardAndGradeCombo(myObj);
        //         setBoardSelect('');
        //         setGradeSelect([]);
        //     }
        // })
        // responseValue.options(opt => {
        //     const count = addQuestionCount[addQuestionCount.length - 1];
        //     setAddQuestionCount(oldArray => [...oldArray, (count) + 1]);
        // });
    }

    useEffect(() => {
        if (props.questionData) {
            if (!editButton) {
                setQuestionValue(props.questionData);
            }
        }
        if (questionValue) {
            if (!props.caseStudy) {
                props.setDisableTopFields(true);
            }
            setEditQuestionContent({ content: questionValue.question });
            const quesWithAns = questionValue.options[0].map((queNo) => {
                let preAns = answerContent.find(ans => ans.order === queNo.order);
                if (preAns && preAns.order === queNo.order) {
                    preAns.correctAnswer = queNo.correctAnswer
                }
                else if (!preAns) {
                    preAns = {
                        "option": String.fromCharCode(queNo.order),
                        "order": queNo.order,
                        "answerKey": queNo.answerKey,
                        "resourceUrl": "",
                        "correctAnswer": queNo.correctAnswer,
                        "htmlTag": queNo.htmlTag
                    }
                }
                return preAns;
            })
            setAnswerContent(quesWithAns);
            let myObj = [];
            setEdit(true);
            setOptionsEdit(questionValue.options[0]);
            setSelectedImageUrl(questionValue.imageUrl);
            setSelectedImageName(questionValue.imageUrl);
            if (questionValue.imageUrl) {
                setRadioButtonValue("repository");
            }
            let count = [65];
            questionValue.options[0].length > 0 && questionValue.options[0].map((ans, index) => {
                if (index > 0) {
                    const updateCount = count[count.length - 1];
                    count = [...count, updateCount + 1];
                }
            })
            setAddQuestionCount(count);
            if (!props.caseStudy) {
                questionValue.boardAndGrades.map((boardGrade, ind) => {
                    let obj = {
                        "boardId": boardGrade.boardId,
                        "gradeId": boardGrade.gradeId
                    };
                    setBoardAndGrade(oldArray => [...oldArray, obj]);
                    if (ind !== 0) {
                        setErrorField(prevState => {
                            delete prevState['combo'];
                            return prevState;
                        })
                        const foundKey = Object.hasOwn(myObj, boardGrade.boardId);
                        if (foundKey) {
                            myObj[boardGrade.boardId].push(boardGrade.gradeId);
                            setBoardAndGradeCombo(myObj);
                            setBoardSelect('');
                            setGradeSelect([]);
                        }
                        else {
                            myObj[boardGrade.boardId] = [boardGrade.gradeId];
                            setBoardAndGradeCombo(myObj);
                            setBoardSelect('');
                            setGradeSelect([]);
                        }
                    }
                    else {
                        myObj = { [boardGrade.boardId]: [] };
                        myObj[boardGrade.boardId].push(boardGrade.gradeId);
                        setBoardAndGradeCombo(myObj);
                        setBoardSelect('');
                        setGradeSelect([]);
                    }
                })
            }
        }
    }, [props.questionData, questionValue, editButton]);

    const onHideModal = () => {
        setDeleteBlankAlert(false);
    }

    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => {
        if (questionEditContent.content !== "") {
            setShowEditor(true);
        }
    }, [questionEditContent]);

    const handleCancelUpdate = () => {
        if (props.fromViewAllQuestion !== undefined) {
            navigate("/dashboard/View All Questions");
        }
        setBoardSelect('');
        setGradeSelect([]);
        setBoardAndGradeCombo([]);
        setClearContent(true);
        const defaultQues = ['65', '66', '67', '68']
        setAnswerContent(defaultQues.map(id => ({
            "option": String.fromCharCode(id),
            "order": id,
            "answerKey": '',
            "resourceUrl": "",
            "correctAnswer": ''
        })));
        setAddQuestionCount(defaultQues);
        setClearContent(true);
        // setPreview(true);
        setUploadImage();
        setImagePreview();
        setSelectedImageName("");
        setSelectedImageUrl();
        setErrorField({});
        setErrorFieldCombo({});
        if (!props.caseStudy) {
            props.setDisableTopFields(false);
        }
        setEdit(false);
        setShowEditor(false);
    }

    return (
        <>
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} md={12} lg={12}>
                    <CustomHead >Multi-Select Question</CustomHead>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography>Question</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        {showEditor && <TextEditor onEditorContentChange={onQuestionHandler} name={"editQuestion"} initialValue={edit ? questionEditContent.content : ""} clearContent={clearContent && clearContent} />}
                        {!edit && <TextEditor onEditorContentChange={onQuestionHandler} name={"question"} clearContent={clearContent && clearContent} />}
                        {/* {errorField.id === "option" && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField.message}</Typography>} */}
                        {!!errorField['question'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['question']}</Typography>}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={radioButtonValue}
                                    onChange={imageUploadHandler}
                                    sx={{ display: 'flex', flexDirection: 'row' }}
                                >
                                    <FormControlLabel disabled={radioButtonEnable} value="machine" control={<CustomRadio />} label="Upload from my machine" sx={{ fontSize: '1.5vw' }} />
                                    <FormControlLabel disabled={radioButtonEnable} value="repository" control={<CustomRadio />} label="Use from repository" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {radioButtonValue === "machine" &&
                            <>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography sx={{ color: '#272727', fontSize: '1vw', fontWeight: '500', fontFamily: 'normal, normal, medium, Poppins' }}>Upload Image</Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography>
                                        <input ref={fileInput} onChange={onFileChange} type="file" accept="image/*" style={{ display: 'none' }} disable={radioButtonEnable} /><CustomButton onClick={() => fileInput.current.click()}><ImageOutlinedIcon fontSize="small" />Choose File</CustomButton>
                                    </Typography>
                                    {uploadImage &&
                                        <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                                            {uploadImage && uploadImage.name}
                                            <CloseOutlinedIcon onClick={() => onRemoveImage("upload")} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                                        </Typography>
                                    }
                                </Grid>
                            </>
                        }
                        {radioButtonValue === "repository" &&
                            <Grid item xs={12} md={12} lg={12}>
                                {selectedImageName &&
                                    <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                                        {selectedImageName && selectedImageName.split('/').pop()}
                                        <CloseOutlinedIcon onClick={() => onRemoveImage("repository")} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                                    </Typography>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} md={12} lg={12}>
                    <hr />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ p: 2 }}>
                {/* <Grid item xs={12} md={12} lg={12}> */}
                <Grid item xs={6} md={6} lg={6}>
                    <Typography>Answer Structure : <span style={{ color: "orange" }}>Select an option</span></Typography>
                </Grid>
                <Grid item xs={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomAddButton onClick={addQuestionHandler}><img
                        className={
                            dashboardContentStyles.aRoleActionBtn
                        }
                        src={action__Add}
                        alt="Add-Btn"
                        style={{ width: "18%", height: "auto" }}
                    />Add Choice</CustomAddButton>
                </Grid>
                {/* </Grid> */}
                <Grid container spacing={2} sx={{ p: 2 }}>
                    {addQuestionCount.map((value, index) => {
                        return (
                            <Grid item xs={12} md={4} lg={4} key={value}>
                                <Grid container>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <CustomHead>Choice - {String.fromCharCode(index + 65)}</CustomHead>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <img
                                            className={
                                                dashboardContentStyles.aRoleActionBtn
                                            }
                                            src={action__Delete}
                                            alt="Dlt-Btn"
                                            onClick={() => onDeleteBlank(value, index)}
                                        />
                                    </Grid>
                                </Grid>
                                {showEditor && <TextEditor onEditorContentChange={(content) => onAnswerHandler(content, value)} name={`edit ${String.fromCharCode(value)}`} initialValue={edit ? optionsEdit[index] && optionsEdit[index].htmlTag : ""} clearContent={clearContent && clearContent} />}
                                {!edit && <TextEditor onEditorContentChange={(content) => onAnswerHandler(content, value)} name={String.fromCharCode(value)} clearContent={clearContent && clearContent} />}
                                {/* <TextEditor onEditorContentChange={(content) => onAnswerHandler(content, value)}  id={String.fromCharCode(value)} initialValue={edit ? answerContent[index] && answerContent[index].answerKey : ""} format='text' clearContent={clearContent && clearContent} /> */}
                                {/* value={answerContent.find(ans => ans.order === value)?.answerKey} */}
                                {/* {errorField.id === "option" && <Typography sx={{ color: "orange", fontSize: '13px' }}>{errorField.message}</Typography>} */}
                                {!!errorField['foundEmpty'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["foundEmpty"]}</Typography>}
                                <FormControlLabel control={<Checkbox
                                    value={String.fromCharCode(value)}
                                    checked={Boolean(answerContent.find(ans => ans.order === value)?.correctAnswer)}
                                    onChange={(e, checked) => onAnswerHandler(checked, value)}
                                    sx={{
                                        "&.Mui-checked": {
                                            "&, & + .MuiFormControlLabel-label": {
                                                color: "blue",
                                                fontWeight: "600"
                                            }
                                        }
                                    }}

                                />} label="Correct Answer" />
                                {!!errorField['foundEmptyCheck'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["foundEmptyCheck"]}</Typography>}
                            </Grid>
                        )
                    }
                    )
                    }
                    {
                        showErrorAlert &&
                        <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Alert
                                action={
                                    <IconButton
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setShowErrorAlert(false);
                                            setShowErroMessage("")
                                        }}
                                    >
                                        <CancelOutlinedIcon />
                                    </IconButton>
                                }
                                icon={false}
                                sx={{
                                    color: "#FFFEFA",
                                    fontWeight: 500,
                                    fontSize: "0.93vw",
                                    marginRight: "10%",
                                    // background: "#bddabca1",
                                    background: "red",
                                }}
                            >
                                {showerrorMessage}
                            </Alert>
                        </Grid>
                    }
                    <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {errorField.message && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField.message}</Typography>}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <FormControl sx={{ ml: 1 }}>
                            <button
                                style={{ maxHeight: "50px" }}
                                onClick={handlePreviewModalOpen}
                                className={ChapterStyles.blueWhite_btn}
                            >
                                {"PREVIEW"}
                            </button>
                        </FormControl>
                        {edit ?
                            <>
                                <FormControl sx={{ ml: 1 }}>
                                    <button
                                        style={{ maxHeight: "50px" }}
                                        onClick={handleCancelUpdate}
                                        className={ChapterStyles.blue_btn}
                                    >
                                        CANCEL
                                    </button>
                                </FormControl>
                                <FormControl sx={{ ml: 1 }}>
                                    <button
                                        style={{ maxHeight: "50px" }}
                                        onClick={() => handleCreateQuestion("update")}
                                        className={ChapterStyles.blue_btn}
                                    >
                                        UPDATE
                                    </button>
                                </FormControl>
                            </>
                            :
                            <FormControl sx={{ ml: 1 }}>


                                <button
                                    style={{ maxHeight: "50px" }}
                                    onClick={() => handleCreateQuestion("create")}
                                    className={ChapterStyles.blue_btn}
                                >
                                    CREATE
                                </button>
                            </FormControl>
                        }
                    </Grid>

                </Grid>
                <ImageListModal
                    openImageModal={openImageListModal}
                    closeImageModal={handleImageListModalClose}
                    selectedImage={setSelectedImageUrl}
                    selectedImageName={setSelectedImageName}
                />
                {/* <Modal
                    open={openPreviewModal}
                    onClose={handlePreviewModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} style={{overflow: "hidden"}}>
                        <Grid container spacing={10}>
                            <Grid item xs={12} md={6} lg={6} sx={{ height: "39vw", overflow: "auto" }}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                            Multi-Select Question
                                        </Typography>
                                        <Typography id="modal-modal-description" sx={{ mt: 2, color: "#354052", font: "normal normal normal 14px/21px Poppins" }}
                                            dangerouslySetInnerHTML={{ __html: questionContent.content }}
                                        >
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                            Uploaded Image
                                        </Typography>
                                        <Typography>
                                            <img src={imagePreview ? imagePreview : selectedImageUrl} width="30%" height="200px" />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} sx={{ height: "39vw", overflow: "auto", "&::-webkit-scrollbar": { width: 20 }, "&::-webkit-scrollbar-track": { backgroundColor: "orange" } }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                    Answer
                                </Typography>
                                <Grid container spacing={4} sx={{ p: 2 }}>
                                    {addQuestionCount.map(value => {
                                        return (
                                            <Grid item xs={12} mg={12} lg={12} style={{ display: "flex" }}>
                                                <div id="modal-modal-description" style={{ paddingRight: "20px", paddingTop: "40px" }} sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}>
                                                    {String.fromCharCode(value)}
                                                </div>
                                                <div style={{
                                                    width: "100%",
                                                    height: "auto",
                                                    minHeight: "60px",
                                                    border: "1px solid #dbdee6",
                                                    fontSize: "18px",
                                                    color: "#1b1b1b",
                                                    opacity: "0.9",
                                                    marginTop: "30px",
                                                    lineHeight: "1",
                                                    padding: "10px",
                                                    borderRadius: "5px",
                                                    borderRadius: "5px",
                                                    position: "relative",
                                                    paddingLeft: "60px",
                                                    paddingTop: "20px",
                                                }}>
                                                    <Checkbox
                                                        id="fullWidth"
                                                        sx={{
                                                            color: "#001AF3",
                                                            position: "absolute",
                                                            top: "10px",
                                                            left: "10px",
                                                            "&.Mui-checked": {
                                                                color: "rgba(0, 0, 0, 0.26)",
                                                            },
                                                        }}
                                                        checked={Boolean(answerContent.find(ans => ans.order === value)?.correctAnswer)}
                                                        value={answerContent.find(ans => ans.order === value)?.answerKey} disabled
                                                    />
                                                    {answerContent.find(ans => ans.order === value)?.answerKey}
                                                </div>
                                            </Grid>
                                        )
                                    })
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <FormControl sx={{ ml: 1 }}>
                                    <button
                                        style={{ maxHeight: "50px" }}
                                        onClick={handlePreviewModalClose}
                                        className={ChapterStyles.blue_btn}
                                    >
                                        CLOSE
                                    </button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal > */}
                {/* <Preview
                    questionContent={questionContent.content}
                    addQuestionCount={addQuestionCount}
                    answerContent={answerContent}
                    questionCode={"MSQ"}
                    openPreviewModal={openPreviewModal}
                    responseValue={responseValue}
                    handlePreviewModalClose={handlePreviewModalClose}
                    imagePreview={imagePreview}
                    selectedImageUrl={selectedImageUrl}
                    previewFromModal={previewFromModal}
                    setPreviewFromModal={setPreviewFromModal}
                />
                <DeleteConfirmationAlert
                    open={deleteBlankAlert}
                    close={onHideModal}
                    alert={alertMessage}
                    onBackdropClickable={false}
                />
                <DeleteConfirmationAlert
                    open={showSuccessAlert}
                    close={onHideSuccessModal}
                    alert={successMessage}
                    caseStudy={true}
                    openPreview={handlePreviewModalOpen}
                    // closePreview={handlePreviewModalClose}
                    isEditQuestion={handleEditQuestion}
                    setNewConcept={props.setNewConcept}
                    setGoToQuestionBank={props.setGoToQuestionBank}
                    setPreviewFromModal={setPreviewFromModal}
                    setDisableTopFields={props.setDisableTopFields}
                    onBackdropClickable={true}
                /> */}
            </Grid >
        </>
    )
}

export default MultiSelectQB