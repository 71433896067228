import axios from 'axios'

async function training_approved_table_get(setTrainingtableData , rowsInput , page , setPageDetail , search) {
  // console.log(rowsInput , page , "yyyy")
  let token = sessionStorage.getItem("token");
   

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training?reviewStatus=APPROVED&search=${search.search}&pageNumber=${page - 1}&pageSize=${rowsInput.rows}`, 
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res)=>{
      if(res.data.data){
        setTrainingtableData(res.data.data.data)
        setPageDetail(res.data.data)
      }
    })
    .catch(err=>console.log(err))
}

export default training_approved_table_get;
