import React, { useEffect, useState } from "react";

//Css Pages
import "../../../css/QuestionBank/Matchthefollowing_DL_Preview.css";

//Images Used
// import prev from "../../../../src/img/Prev.svg";
// import nxt from "../../../../src/img/Nxt.svg";

import {
    Box,
    Modal,
    Grid,
    Button,
    styled,
    Typography
} from "@mui/material";
import get_question from "../../../API_CONTROLLER/create-questions/get_questionbyid";

const style = {
    position: 'absolute',
    top: '50%',
    left: '58%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "45vw",
    // overflow: "scroll",
    overflowX: "scroll",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "4px",
    p: 4,
};

const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: "3px",
    color: "#FFFFFF",
    fontFamily: ['normal', 'normal', 'medium', 'Poppins'].join(','),
    backgroundColor: "#2AA571",
    fontSize: '10px',
    width: 'auto',
    padding: "0.6vw 1vw",
    '&:hover': {
        backgroundColor: '#056039',
    },
}));

function Preview_MTF_DL(props) {

    const [responseValue, setResponseValue] = useState();

    let questionContent;
    let options;
    let images;
    if (responseValue) {
        questionContent = responseValue.question;
        options = responseValue.options[0];
        images = responseValue.imageUrl;
    }

    useEffect(() => {
        if (props.responseValue) {
            get_question(props.responseValue.id, setResponseValue);
        }
    }, [props.previewFromModal]);

    const onExitButton = () => {
        props.close();
        setResponseValue();
        props.setPreviewFromModal(false);
    }

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container p={2}>
                        <Grid item xs={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "left" }}>
                            <Typography sx={{ color: "#1B1B1B", fontFamily: "Poppins", fontWeight: "600", fontSize: "16px", lineHeight: "25px", }}>Match the following</Typography>
                        </Grid>
                    </Grid>
                    <Grid container p={2}>
                        <Grid item xs={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "left" }}>
                            <Typography sx={{ color: "#1B1B1B", fontFamily: "Poppins", fontWeight: "600", fontSize: "16px", lineHeight: "25px", }}>Question</Typography>
                        </Grid>

                        <Grid item xs={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "left" }}>
                            {props.imagePreview || props.selectedImageUrl ?
                                <Typography sx={{ color: "#1B1B1B", fontFamily: "Poppins", fontWeight: "600", fontSize: "16px", lineHeight: "25px", }}>Uploaded Image</Typography>
                                :
                                <></>
                            }
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "left" }}>
                            <Typography dangerouslySetInnerHTML={{ __html: props.previewFromModal ? questionContent : props.temporaryQuestionText }}></Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "left" }}>
                            <Typography>
                                <img src={props.previewFromModal ? images : props.selectedImageUrl} width="100%" height="auto" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className="MTF_DL_Div">

                        <div className="MTF_DL_Cards">
                            <div className="Qstns_Cards">
                                {props.previewFromModal ?
                                    options && options.map(opt => {
                                        return (
                                            <div className="QstnCarD">
                                                <p dangerouslySetInnerHTML={{ __html: `${opt.question}` }}></p>
                                                {/* <p>{opt.question}</p> */}
                                            </div>
                                        )
                                    })
                                    :
                                    props.options.length > 0 && props.options.map(opt => {
                                        return (
                                            <div className="QstnCarD">
                                                <p dangerouslySetInnerHTML={{ __html: `${opt.question}` }}></p>
                                                {/* <p>{opt.question}</p> */}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="Ans_Cards">
                                {props.previewFromModal ?
                                    options && options.map(opt => {
                                        return (
                                            <div className="AnsCard">
                                                <p dangerouslySetInnerHTML={{ __html: `${opt.jumbledAnswer}` }}></p>
                                                {/* <p>{opt.jumbledAnswer}</p> */}
                                            </div>
                                        )
                                    })
                                    :
                                    props.options.length > 0 && props.options.map(opt => {
                                        return (
                                            <div className="AnsCard">
                                                <p dangerouslySetInnerHTML={{ __html: `${opt.jumbledAnswer}` }}></p>
                                                {/* <p>{opt.jumbledAnswer}</p> */}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <Grid container>
                        < Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <CustomButton onClick={onExitButton}>Exit</CustomButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}

export default Preview_MTF_DL;
