import axios from 'axios'

async function users_forgotpassword_Put(
  confirmLoginPassword,
  userId,
  setSuccess,
  resetResult
) {
  var item = {
    password: confirmLoginPassword,
    userId: userId,
  };
  

    let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/reset-password`,item,
    {
     headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      console.log(json)
      setSuccess(json)
      resetResult(json)
      sessionStorage.removeItem("selectedUserID")
      localStorage.removeItem("selectedUserInfo")
    })
    .catch(err=>console.log(err))
}
export default users_forgotpassword_Put;
