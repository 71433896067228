import axios from 'axios'

function training_get_by_id(setTrainingInputs){
    let token = sessionStorage.getItem('token');

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training/${sessionStorage.getItem("trainingId")}`,
    {
        headers: {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setTrainingInputs(res.data.data)
    })
}

export default training_get_by_id