import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import {
FormControl,
InputLabel,
Select,
MenuItem,
Checkbox,
ListItemText,
} from "@material-ui/core";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
formControl: {
margin: theme.spacing(1),
minWidth: 120,
maxWidth: 300,
},
selectEmpty: {
marginTop: theme.spacing(2),
},
}));

const schools = [
{
id: 1,
name: "School 1",
branches: [
{ id: 1, name: "Branch 1" },
{ id: 2, name: "Branch 2" },
{ id: 3, name: "Branch 3" },
],
},
{
id: 2,
name: "School 2",
branches: [
{ id: 4, name: "Branch 4" },
{ id: 5, name: "Branch 5" },
],
},
{
id: 3,
name: "School 3",
branches: [
{ id: 6, name: "Branch 6" },
{ id: 7, name: "Branch 7" },
],
},
];

const NestedMultiSelect = () => {
const classes = useStyles();

const [dhrSchool, setdhrSchool] = useState([]);

useEffect(() => {
setdhrSchool(
schools.map((item) => {
const tempSchool = { ...item };
tempSchool.checked = false;
if (item.branches.length) {
tempSchool.branches = [
...item.branches.map((branch) => {
return { ...branch, checked: false };
}),
];
}
return tempSchool;
})
);
}, []);

const selectHandler = (school, branch) => {
if (!branch) {
setdhrSchool(
dhrSchool.map((sch) => {
const tempSchool = { ...sch };
if (school.id === tempSchool.id) {
tempSchool.checked = !tempSchool.checked;
if (tempSchool.branches.length) {
const tempBranch = [
...tempSchool.branches.map((branch) => ({
...branch,
checked: tempSchool.checked,
})),
];
tempSchool.branches = [...tempBranch];
}
}
return tempSchool;
})
);
} else {
setdhrSchool([
...dhrSchool.map((sch) => {
const tempSchool = { ...sch };
if (tempSchool.id === school.id) {
tempSchool.branches = [
...tempSchool.branches.map((brh) => ({
...brh,
checked: brh.id === branch.id ? !brh.checked : brh.checked,
})),
];
tempSchool.checked =
tempSchool.branches.filter((brh) => brh.checked).length > 0;
}
return tempSchool;
}),
]);
}
};
const [data, setData] = useState()
async function schoolAndBranchGet() {
let token = sessionStorage.getItem("token");
// management role
// let roleId = "2c9180858104272401812308c507000d";
await axios
.get(
`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/all/withBranches`,
{
headers: {
"Content-Type": "application/json",
Authorization: `Bearer ${token}`,
},
}
)
.then((response) => {
console.log(response, "ttt");
setData(response.data.data);
// setBranchIdGet(response.data.data.branchId);
})
.catch((err) => console.log(err));
}

useEffect(() => {
schoolAndBranchGet();
}, []);

console.log("Schools Data =>", dhrSchool);

console.log(
"Selected Schools=>",
dhrSchool.filter((school) => school.checked).map((item) => item.id)
);
console.log(
"Selected Branches=>",
dhrSchool
.filter((school) => school.checked)
.map((school) => {
return [
...school.branches.filter((item) => item.checked).map((i) => i.id),
];
})
);
console.log(
"Selected School and Branches =>",
dhrSchool
.filter((school) => school.checked)
.map((school) => {
const tempSchool = { schoolID: school.id };
tempSchool.branches = [
...school.branches
.filter((item) => item.checked)
.map((brh) => brh.id),
];
return tempSchool;
})
);
return (
<FormControl className={classes.formControl}>
<InputLabel id="school-label">School</InputLabel>

<Select
labelId="school-label"
id="school-select"
multiple
value={dhrSchool}
renderValue={(selected) => selected.map((item) => item["schoolName"])}
>
{dhrSchool.map((school) => (
<MenuItem key={school.id} value={school}>
<Checkbox
checked={school.checked}
onChange={() => selectHandler(school, null)}
/>

<ListItemText primary={school.name} />
<Select
labelId={`branch-label-${school.id}`}
id={`branch-select-${school.id}`}
multiple
value={school.branches || []}
renderValue={(selected) => selected.join(", ")}
>
{school.branches.map((branch) => (
<MenuItem key={branch.id} value={branch.name}>
<Checkbox
checked={branch.checked}
onChange={() => selectHandler(school, branch)}
/>

<ListItemText primary={branch.name} disabled />
</MenuItem>
))}
</Select>
</MenuItem>
))}
</Select>
</FormControl>
);
};

export default NestedMultiSelect;