import axios from 'axios'

async function get_question(
    id,
   setResponseValue
) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${id}?showCorrectAnswer=true`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
        .then(
            (response) => {
                setResponseValue(response.data.data);
            }
        )
        .catch(err => {
            console.log(err)
        });
}

export default get_question;
