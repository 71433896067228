import axios from 'axios'

async function StudentsControllerPost(studentInputs,num, setSuccess,cb,) {
  let token = sessionStorage.getItem("token");
  let env = process.env.REACT_APP_LMS_ENV
  var item = {
    active: true,
    address: studentInputs.address,
    admissionDate: studentInputs.admissionDate,
    // branch: "4028818380665dd20180667ee7ee0003",
    branch: sessionStorage.getItem("branchid"),
    dob: studentInputs.dob,
    email: studentInputs.email.toLowerCase(),
    firstLanguageId: studentInputs.firstLanguageId,
    firstName: studentInputs.firstName,
    gender: studentInputs.gender,
    gradeId: studentInputs.gradeId,
    isPromoted: true,
    lastName: studentInputs.lastName,
    mobile: num,
    school: sessionStorage.getItem("schoolid"),
    secondLanguageId: studentInputs.secondLanguageId,
    sectionId: studentInputs.sectionId,
    studentCategoryId: studentInputs.studentCategoryId,
    // token: "770-1798-MMARS",
    yearEndProcess: true,
    //  lmsEnv: "LOCAL"
   lmsEnv: env

  };
    let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students`,item,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setSuccess(json);
      cb()
    })
    .catch(err=>console.log(err))
}

export default StudentsControllerPost;
