import React, { useState, useEffect } from 'react'
import './Principaldashboard.css'
import { cardText, cardTop, countCardStyle, rowOne, rowTwo, taskheaderContainer } from './styles'
import { Doughnut } from 'react-chartjs-3'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getCountForDashboard,
  getGradesList,
  getStudentLeveldata,
  getSubjectsList,
  getSyllabusCompletionDetails,
  getSyllabusPercentage,
  getVsData
} from '../../../API/principal-controller/dashboardApis'
import { isNOTNullOrUndefined } from '../../../../utils/helper'
import { filterStyle, labelStyle, formStyle, selectStyle } from './styles'
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from '@mui/material'
import { MenuProps } from '../../Data'

import { cardsData, dummyTasks } from './dummyData'
import CompletionCards from './CompletionCards'
import CompletionDetails from './CompletionDetails'
import SyllabusCompletionGraph from './SyllabusCompletionGraph'
const RowTwo = ({
  profileData,
  token,
  selectedSubject, setSelectedSubject,
  selectedGrade, setSelectedGrade,
  selectedSubtopic, setSelectedSubtopic
}) => {
  const teacherId = profileData?.teacher?.id
  const schoolId = profileData?.teacher?.school
  const branchId = profileData?.teacher?.branch
  const boardId = profileData?.teacher?.boardId
  const [subjectQueryParams,setSubjectQueryParams] = useState({teacherId,selectedGrade,token})

  const { data: gradeList } = useQuery(['gradesList', teacherId, token], getGradesList, { enabled: !!teacherId })
  const { data: subjectsList } = useQuery(['subjectsList', subjectQueryParams.teacherId, subjectQueryParams.selectedGrade, subjectQueryParams.token], getSubjectsList, { enabled: !!subjectQueryParams.selectedGrade })

  const { data: syllabusPercentageData } = useQuery(
    [
      'getSyllabusCompletionDetails',
      schoolId,
      branchId,
      selectedGrade,
      selectedSubject,
      boardId,
      selectedSubtopic,
      token
    ],
    getSyllabusCompletionDetails,
    {
      enabled: isNOTNullOrUndefined(selectedGrade) && isNOTNullOrUndefined(selectedSubject)
    }
  )

  useEffect(()=>{
    if(isNOTNullOrUndefined(selectedGrade)){
      setSubjectQueryParams({teacherId,selectedGrade,token})
    }
  },[selectedGrade])

  useEffect(() => {
    if (gradeList?.length) {
      setSelectedGrade(gradeList[0]?.id)
    }
  }, [gradeList])


  useEffect(() => {
    if (subjectsList?.length && subjectsList?.length > 0) {
      let firstitem = subjectsList[0]
      let subtopicsInFirstItem = firstitem?.subTopics
      setSelectedSubject(firstitem?.subjectId)

      if (isNOTNullOrUndefined(subtopicsInFirstItem) && subtopicsInFirstItem?.length > 0) {
        setSelectedSubtopic(subtopicsInFirstItem[0]?.id)
      } else {
        setSelectedSubtopic("")
      }
    }
  }, [subjectsList])

  const subtopicsList = subjectsList?.find(i => i.subjectId === selectedSubject)?.subTopics

  return (
    <div style={rowTwo}>
      <div className="row-two-top">
        <p className="sub-heading" >Syllabus completion percentage</p>
        <div style={{ display: "flex", alignItems: "center" }}>

          <FormControl sx={formStyle} size='small'>
            <InputLabel sx={labelStyle} id='grade-select'>
              Grade
            </InputLabel>
            <Select
              labelId='grade-select'
              id='grade-select'
              sx={filterStyle}
              label='grade'
              input={<OutlinedInput label='Grade' />}
              onChange={e => {
                setSelectedGrade(e.target.value)
                setSelectedSubject("")
              }}
              value={isNOTNullOrUndefined(selectedGrade) ? selectedGrade : ""}
              style={selectStyle}
              MenuProps={MenuProps}
            >
              {gradeList?.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  <ListItemText primary={item.grade} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={formStyle} size='small'>
            <InputLabel sx={labelStyle} id='subject-select'>
              Subject
            </InputLabel>
            <Select
              labelId='subject-select'
              id='subject-select'
              sx={filterStyle}
              label='subject'
              input={<OutlinedInput label='subject' />}
              onChange={e => {
                let subId = e.target.value
                let subtopics = subjectsList?.find(i => i.subjectId === subId)?.subTopics
                setSelectedSubject(subId)
                if (isNOTNullOrUndefined(subtopics) && subtopics?.length > 0) {
                  setSelectedSubtopic(subtopics[0].id)
                } else {
                  setSelectedSubtopic("")
                }
              }}
              value={isNOTNullOrUndefined(selectedSubject) ? selectedSubject : ""}
              style={selectStyle}
              MenuProps={MenuProps}
            >
              {subjectsList?.map(item => (
                <MenuItem key={item.subjectId} value={item.subjectId}>
                  <ListItemText primary={item.subject} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={formStyle} size='small'>
            <InputLabel sx={labelStyle} id='subtopic-select'>
              Subtopic
            </InputLabel>
            <Select
              labelId='subtopic-select'
              id='subtopic-select'
              sx={filterStyle}
              label='subtopic'
              input={<OutlinedInput label='subtopic' />}
              onChange={e => setSelectedSubtopic(e.target.value)}
              value={isNOTNullOrUndefined(selectedSubtopic) ? selectedSubtopic : ""}
              style={selectStyle}
              MenuProps={MenuProps}
            >
              {subtopicsList?.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  <ListItemText primary={item.subTopic} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      
      {/* <SyllabusCompletionGraph token={token} profileData={profileData} selectedGrade={selectedGrade} selectedSubtopic={selectedSubtopic} selectedSubject={selectedSubject} /> */}
      <>
      <CompletionCards data={syllabusPercentageData} />
      <CompletionDetails data={syllabusPercentageData} />
      </>
     
    </div>
  )
}

export default RowTwo
