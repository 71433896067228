import { Modal } from "@mui/material";
import React from "react";
import "./DashboardEmpherModal.css";
import { Close } from "@material-ui/icons";
import BookEdit from "../../img/book-edit--pencil.png";
import ProfImage from "../../img/EmberProf.png";
import SemiCircleProgressBarTeacher from "../../pages/js/Teacher/Dashboard/Components/CircleProgressTeacher";

const DashboardGradeModal = ({
  data,
  showMessageModal,
  onMessageModalClose,
  isEmberCategoryMessage,
  progressData,
}) => {
  return (
    <Modal
      sx={{ display: "grid", placeContent: "center" }}
      open={showMessageModal}
      onClose={onMessageModalClose}
    >
      <div
        className="msg-modal-body"
        style={{ width: isEmberCategoryMessage ? "600px" : "600px" }}
      >
        <p className="ember">
          <img
            src={BookEdit}
            width="14"
            height="14"
            style={{ marginRight: "5px" }}
          />
          Ember Category
        </p>
        <div className="container">
          <div className="left">
            <div className="sentence">
              Please priortise the remedial classes for these students for this
              particular chapter.
            </div>
            <div className="table-wrap">
              {data?.map((elem, index) => {
                return (
                  <>
                    <div className="table">
                      <span>{index + 1}</span>
                      <span>{elem?.studentName}</span>
                      <span>
                        <img
                          src={ProfImage}
                          width="14"
                          height="14"
                          style={{ marginRight: "5px" }}
                        />
                      </span>
                      <span>{elem?.emberPercentage + "%"}</span>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="right">
            <div className="wrap-circles">
              <div className="circle">
                <SemiCircleProgressBarTeacher
                  progressValue={progressData}
                  size={80}
                />
                <div className="inner">{progressData}</div>
                <div className="per">%</div>
              </div>
              <div className="students">
                % of students
                <br /> in Ember
              </div>
            </div>
          </div>
        </div>

        {/* <p className="msg-modal-title" style={{marginBottom:isEmberCategoryMessage ? '30px':'15px'}}>{isEmberCategoryMessage ? 'Attention!': title}</p>
            <p className="msg-modal-content" style={{marginBottom:isEmberCategoryMessage ? '15px':'0px'}}>{isEmberCategoryMessage ? emberMessageOne :content}</p>
           
            {isEmberCategoryMessage ?  <p className="msg-modal-content">{emberMessageTwo}</p> :null} */}
        <div onClick={onMessageModalClose} className="msg-modal-close-btn">
          <Close style={{ fontSize: "16px", color: "#ccc" }} />
        </div>
      </div>
    </Modal>
  );
};

export default DashboardGradeModal;
