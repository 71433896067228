import React, { useState } from "react";
import { Button, Checkbox } from "@mui/material";
import "./planTemplate.css";
import { listContainer } from "./styles";

export default function BluePrintLevelView({
  bluePrintLevelData,
  setSelectedBlueprintLevels,
  selectedBlueprintLevels,
  isEdit,
}) {
  const handleLevelCheck = (e, id) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedBlueprintLevels((prev) => [...prev, id]);
    } else {
      const values = [...selectedBlueprintLevels];
      var index = values.indexOf(id);
      if (index !== -1) {
        values.splice(index, 1);
        setSelectedBlueprintLevels(values);
      }
    }
  };

  return (
    <div style={listContainer}>
      {bluePrintLevelData?.map((item) => {
        return (
          <>
            <div className="bp-level-container" key={item?.id}>
              <div>{item?.levelName}</div>
              <div>
                <Checkbox
                  sx={{
                    color: "#001af3",
                    "&.Mui-checked": {
                      color: "#001af3",
                    },
                  }}
                  onClick={(e) => handleLevelCheck(e, item.id)}
                  checked={selectedBlueprintLevels?.includes(item.id)}
                />
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}
