import React, { useState } from "react";
import { Box, Modal, Typography, Button, TextField } from "@mui/material";
import YellowButton from "../../../components/common/yellowButton";
import { styled } from "@mui/material/styles";
import './approval.css'
import { isNullOrUndefined } from "../TeachValidate";

const ContentReviewerRejectModal = ({
  open,
  handleCancel,
  handleConfirm,
  onClose,
  setrejectReason,
  rejectReason
}) => {
  const CancelButton = styled(Button)(() => ({
    background: "#ffffff 0% 0% no-repeat padding-box",
    border: "1px solid #7a7a7a",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #7A7A7A",
    borderRadius: "21px",
    opacity: 1,
    width: "178px",
    height: "40px",
    marginRight: "15px",
    color: "#7A7A7A",
    "&:hover": {
      boxShadow: "1px 2px 3px 0px #FD8C00",
      borderColor: "#FD8C00",
      color: "#FD8C00",
    },
  }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 1,
    padding: "15px 30px",
    height:'412px'
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ padding: "30px 50px 30px 50px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <TextField
            value={rejectReason}
            onChange={e => setrejectReason(e.target.value)}
            variant="outlined"
            label={'Reason'}
            sx={{ height: '150px', color: 'black' }}
            className="modal-text"
            multiline
            inputProps={{
              style: {
                height: "207px",
                width: '607px',
                marginBottom: '20px',
                fontSize: 16,
                color: 'black',
                fontFamily: 'Poppins',
                fontWeight: 300,

              },
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginTop: '140px'
            }}
          >
            <CancelButton onClick={() => handleCancel()} >Cancel</CancelButton>
            <YellowButton disabled={isNullOrUndefined(rejectReason)} onClick={() => handleConfirm()}>
              <p style={{ color: "white" }}>Confirm</p>
            </YellowButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ContentReviewerRejectModal;
