import * as yup from "yup";

export const validationSchema = yup.object().shape({
  paperTitle: yup.string().required("Paper Title is required"),
  boardId: yup.string().required("Board is required"),
  gradeId: yup.string().required("Grade is required"),
  subjectId: yup.string().required("Subject is required"),
  blueprintLevelId: yup.string().required("Level is required"),
  academicYearId: yup.string().required("Academic Year is required"),
});

export const validationSchemaAssign = yup.object().shape({
  boardId: yup.string().required("Board is required"),
  gradeId: yup.string().required("Grade is required"),
  subjectId: yup.string().required("Subject is required"),
  blueprintLevelId: yup.string().required("Level is required"),
  academicYearId: yup.string().required("Academic Year is required"),
  selectedSchools: yup.array().min(1, "School is required"),
});

export const tableHead = [
  { title: "#" },
  { title: "Paper Title" },
  { title: "Board" },
  { title: "Grade" },
  { title: "Subject" },
  { title: "Level" },
  { title: "Academic year" },
  { title: "Active" },
  { title: "Action" },
];

export const tableHeadAssign = [
  { title: "#" },
  { title: "Paper Title" },
  { title: "Board" },
  { title: "Grade" },
  { title: "Subject" },
  { title: "Level" },
  { title: "Academic year" },
  { title: "Active" },
];
