import axios from "axios";

async function BranchControllerGet1(array, field, order) {
    console.log(array, field, order, "common sorting file");

    // Determine the sorting order
    const sortOrder = order === true ? "desc" : "asc";

    // Nulls come first in ascending order, last in descending order
    const nullsFirst = sortOrder === "asc";

    // Perform sorting
    return array?.length > 0 ? array.sort((a, b) => {
        // Handle null values
        if (a[field] === null && b[field] === null) return 0;
        if (a[field] === null) return nullsFirst ? -1 : 1;
        if (b[field] === null) return nullsFirst ? 1 : -1;

        // Handle empty strings (if needed)
        if (a[field] === '' && b[field] === '') return 0;
        if (a[field] === '') return nullsFirst ? -1 : 1;
        if (b[field] === '') return nullsFirst ? 1 : -1;

        // Handle booleans
        if (typeof a[field] === 'boolean') {
            if (sortOrder === 'asc') {
                return a[field] === b[field] ? 0 : a[field] ? 1 : -1;
            } else {
                return a[field] === b[field] ? 0 : a[field] ? -1 : 1;
            }
        }

        // Handle strings (case-insensitive)
        if (typeof a[field] === 'string') {
            const comparison = a[field].localeCompare(b[field], undefined, { sensitivity: 'base' });
            return sortOrder === 'asc' ? comparison : -comparison;
        }

        // Handle numbers and dates
        if (typeof a[field] === 'number' || a[field] instanceof Date) {
            const comparison = a[field] - b[field];
            return sortOrder === 'asc' ? comparison : -comparison;
        }

        // Fallback in case of unexpected types
        return 0;
    }) : [];
}

export default BranchControllerGet1;
