import RuleFolderOutlinedIcon from "@mui/icons-material/RuleFolderOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import light from "../../../img/LightReflection.svg";
import login from "../../../img/Login.png";

export const studentPerformanceData = [
  {
    id: 1,
    student: "Pradeep",
    concept: "Light",
    subConcepts: [
      {
        id: 1,
        title: "Type of Light",
      },
      {
        id: 2,
        title: "Light Sources",
      },
      {
        id: 3,
        title: "Application of Light",
      },
    ],
    rootConcepts: [
      {
        id: 1,
        title: "Root Concepts - 1",
      },
      {
        id: 2,
        title: "Root Concepts - 2",
      },
      {
        id: 3,
        title: "Root Concepts - 3",
      },
    ],
    marks: [
      {
        id: 1,
        mark: "3/10",
      },
      {
        id: 2,
        mark: "5/10",
      },
      {
        id: 3,
        mark: "8/10",
      },
    ],
    percentage: "87",
    timeToken: [{ id: "1", min: "8 min", second: "3 second" }],
    timePerQuestion: [{ id: "1", min: "5 min", second: "3 second" }],
    attempt: "06-jul-22",
    pq1: "4",
    pq2: "1",
    highestScore: "95",
  },
  {
    id: 2,
    student: "Jagadeeshu",
    concept: "Electricity",
    subConcepts: "Electricity",
    rootConcepts: "Electricity",
    marks: "33",
    percentage: "67",
    timeToken: [{ id: "1", min: "1 min", second: "3 second" }],
    timePerQuestion: [{ id: "1", min: "1 min", second: "3 second" }],
    attempt: "06-jul-22",
    pq1: "4",
    pq2: "1",
    highestScore: "95",
  },
  {
    id: 3,
    student: "Sri Krian",
    concept: "Electricity",
    subConcepts: "Electricity",
    rootConcepts: "Electricity",
    marks: "33",
    percentage: "45",
    timeToken: [{ id: "1", min: "1 min", second: "3 second" }],
    timePerQuestion: [{ id: "1", min: "1 min", second: "3 second" }],
    attempt: "06-jul-22",
    pq1: "4",
    pq2: "1",
    highestScore: "95",
  },
  {
    id: 4,
    student: "Hema",
    concept: "Electricity",
    subConcepts: "Electricity",
    rootConcepts: "Electricity",
    marks: "33",
    percentage: "37",
    timeToken: [{ id: "1", min: "1 min", second: "3 second" }],
    timePerQuestion: [{ id: "1", min: "1 min", second: "3 second" }],
    attempt: "06-jul-22",
    pq1: "4",
    pq2: "1",
    highestScore: "95",
  },
  {
    id: 5,
    student: "Raju",
    concept: "Electricity",
    subConcepts: "Electricity",
    rootConcepts: "Electricity",
    marks: "33",
    percentage: "37",
    timeToken: [{ id: "1", min: "1 min", second: "3 second" }],
    timePerQuestion: [{ id: "1", min: "1 min", second: "3 second" }],
    attempt: "06-jul-22",
    pq1: "4",
    pq2: "1",
    highestScore: "95",
  },
];

export const studentPerformanceDataNew = {
  "studentWisePerformanceListResponseDtoList": [
      {
          "name": "kum k",
          "conceptResponseDtoList": [
              {
                  "concept_name": "CONCEPT 1 MATTER",
                  "subConceptReportResponseDtoList": [
                      {
                          "sub_concept_name": "OXYGEN",
                          "rootConceptReportResponseDtoList": [
                              {
                                  "root_concept_	": "O2",
                                  "score": "5/10"
                              }
                          ]
                      }
                  ]
              },
              {
                  "concept_name": "AEROBIC",
                  "subConceptReportResponseDtoList": [
                      {
                          "sub_concept_name": "OXYGEN",
                          "rootConceptReportResponseDtoList": [
                              {
                                  "root_concept_name": "O2",
                                  "score": "5/10"
                              }
                          ]
                      }
                  ]
              }
          ],
          "mark": "5/10",
          "percentage": "60%",
          "timeToken": "00Hrs 04Mins 11Sec",
          "timePerQuestion": "00Hrs 03Mins 10Sec",
          "attemptOn": "2023-07-12",
          "pq1": 4,
          "pq2": 2,
          "highestScore": 45
      },
      {
          "name": "ANAND POTTY",
          "conceptResponseDtoList": [
              {
                  "concept_name": "CONCEPT 1 MATTER",
                  "subConceptReportResponseDtoList": [
                      {
                          "sub_concept_name": "OXYGEN",
                          "rootConceptReportResponseDtoList": [
                              {
                                  "root_concept_name": "O2",
                                  "score": "5/10"
                              }
                          ]
                      }
                  ]
              },
              {
                  "concept_name": "AEROBIC",
                  "subConceptReportResponseDtoList": [
                      {
                          "sub_concept_name": "OXYGEN",
                          "rootConceptReportResponseDtoList": [
                              {
                                  "root_concept_name": "O2",
                                  "score": "5/10"
                              }
                          ]
                      }
                  ]
              }
          ],
          "mark": "5/10",
          "percentage": "69%",
          "timeToken": "00Hrs 04Mins 11Sec",
          "timePerQuestion": "00Hrs 03Mins 10Sec",
          "attemptOn": "2023-07-12",
          "pq1": 4,
          "pq2": 2,
          "highestScore": 45
      }
  ]
};

export const formalStudentPerformance = {
  "data": {
      "formalStudentWiseListResponseDtos": [
          {
              "name": "kum k",
              "subTopicResponseListDto": {
                  "subTopicsResponseDtos": [
                      {
                          "id": "40289288836b282f018379ece03f001a",
                          "subTopic": "HUMAN ORGANS",
                          "active": true,
                          "mark": "2",
                          "timeTaken": "0Hrs 7Mins 49Sec",
                          "timePerQuestion": null,
                          "attemptOn": "2023-07-17"
                      },
                      {
                          "id": "40289288836b282f018379ece043001b",
                          "subTopic": "INSECT STUDY",
                          "active": true,
                          "mark": "2",
                          "timeTaken": "0Hrs 7Mins 49Sec",
                          "timePerQuestion": null,
                          "attemptOn": "2023-07-17"
                      }
                  ]
              },
              "conceptResponseDtoList": null,
              "percentage": "3%"
          },
          {
              "name": "ANAND POTTY",
              "subTopicResponseListDto": {
                  "subTopicsResponseDtos": [
                      {
                          "id": "40289288836b282f018379ece03f001a",
                          "subTopic": "HUMAN ORGANS",
                          "active": true,
                          "mark": "0",
                          "timeTaken": "",
                          "timePerQuestion": null,
                          "attemptOn": null
                      },
                      {
                          "id": "40289288836b282f018379ece043001b",
                          "subTopic": "INSECT STUDY",
                          "active": true,
                          "mark": "0",
                          "timeTaken": "",
                          "timePerQuestion": null,
                          "attemptOn": null
                      }
                  ]
              },
              "conceptResponseDtoList": null,
              "percentage": "4%"
          }
      ]
  },
  "message": "Successfully fetched Formal Assessment Student wise performance.",
  "success": true,
  "errorCode": null,
  "path": null
}


export const accordionData = [
  {
    id: 1,
    title: "Reasons for different in space",
    percentage: "71.8%",
    background: "rgb(52 134 208 / 90%)",
    questionTitle:
      "Read the given question carefully and choose the correct answers:",
    questions: [
      {
        id: 1,
        question: "Question 2 - Multiple Choice",
        options: [
          {
            id: 1,
            option: "Sometimes",
            type: "text",
          },
          {
            id: 2,
            option: "Always",
            type: "button",
          },
          {
            id: 3,
            option: "Never",
            type: "text",
          },
          {
            id: 4,
            option: "Under special conditions",
            type: "text",
          },
        ],
        name: "Map Name",
        src: light,
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 50,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: 2,
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 80,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 80,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 3,
          },
        ],
      },
      {
        id: 2,
        question: "Question  - Multy-Select",
        options: [
          {
            id: 1,
            option: "Sometimes",
            type: "text",
          },
          {
            id: 2,
            option: "Always",
            type: "button",
          },
          {
            id: 3,
            option: "Never",
            type: "text",
          },
          {
            id: 4,
            option: "Under special conditions",
            type: "text",
          },
        ],
        name: "",
        src: "",
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 50,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: "2,3",
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 40,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 20,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 2,
          },
        ],
      },
      {
        id: 3,
        question: "Question 3 - Fill In the Blank",
        options: [
          {
            id: 1,
            option: "Correct Answer",
            type: "button",
          },
        ],
        name: "",
        src: "",
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 70,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: "Hydrogen,Oxygen",
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 30,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 10,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 1,
          },
        ],
      },
      {
        id: 4,
        question: "Question 4 - Match the following ",
        options: [
          {
            id: 1,
            option: "Correct Answer",
            type: "button",
          },
        ],
        name: "",
        src: light,
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 80,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: "Refer Image",
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 60,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 30,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 2,
          },
        ],
      },
    ],
    questionHeading: "",
    questionContent: "",
    questionImage: "",
  },
  {
    id: 2,
    title: "Preparation for travelling into space",
    percentage: "61%",
    background: "rgb(52 134 208 / 90%)",
    questions: [
      {
        id: 1,
        question: "",
        options: [],
        name: "",
        src: "",
      },
    ],
    questionTitle: "",
    questionHeading: "",
    questionContent: "",
    questionImage: "",
  },
  {
    id: 3,
    title: "Life in space",
    percentage: "67%",
    background: "rgb(52 134 208 / 90%)",
    questions: [
      {
        id: 1,
        question: "",
        options: [],
        name: "",
        src: "",
      },
    ],
    questionTitle: "",
    questionHeading: "",
    questionContent: "",
    questionImage: "",
  },
  {
    id: 4,
    title: "Case Study",
    percentage: "50%",
    background: "rgb(5 74 134)",
    questions: [
      {
        id: 1,
        question: "Question 2 - Multiple Choice",
        options: [
          {
            id: 1,
            option: "Sometimes",
            type: "text",
          },
          {
            id: 2,
            option: "Always",
            type: "button",
          },
          {
            id: 3,
            option: "Never",
            type: "text",
          },
          {
            id: 4,
            option: "Under special conditions",
            type: "text",
          },
        ],
        name: "Map Name",
        src: light,
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 50,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: 2,
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 80,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 80,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 3,
          },
        ],
      },
      {
        id: 2,
        question: "Question  - Multy-Select",
        options: [
          {
            id: 1,
            option: "Sometimes",
            type: "text",
          },
          {
            id: 2,
            option: "Always",
            type: "button",
          },
          {
            id: 3,
            option: "Never",
            type: "text",
          },
          {
            id: 4,
            option: "Under special conditions",
            type: "text",
          },
        ],
        name: "",
        src: "",
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 50,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: "2,3",
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 40,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 20,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 2,
          },
        ],
      },
      {
        id: 3,
        question: "Question 3 - Fill In the Blank",
        options: [
          {
            id: 1,
            option: "Correct Answer",
            type: "button",
          },
        ],
        name: "",
        src: "",
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 70,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: "Hydrogen,Oxygen",
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 30,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 10,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 1,
          },
        ],
      },
      {
        id: 4,
        question: "Question 4 - Match the following ",
        options: [
          {
            id: 1,
            option: "Correct Answer",
            type: "button",
          },
        ],
        name: "",
        src: login,
        data: [
          {
            id: 1,
            icon: (
              <AutoStoriesOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#6e67d4",
                }}
              />
            ),
            name: "Percentage of student who gave correct answers",
            value: 80,
          },
          {
            id: 2,
            icon: (
              <CheckCircleOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#22ea00",
                }}
              />
            ),
            name: "Correct Option",
            value: "Refer Image",
          },
          {
            id: 3,
            icon: (
              <StarHalfOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#57a8ed",
                }}
              />
            ),
            name: "Quiz attempt rate",
            value: 60,
          },
          {
            id: 4,
            icon: (
              <ListAltOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "#403b97",
                }}
              />
            ),
            name: "No.of Students attempted",
            value: 30,
          },
          {
            id: 5,
            icon: (
              <RuleFolderOutlinedIcon
                style={{
                  fontSize: "30px",
                  color: "orange",
                }}
              />
            ),
            name: "Option most students chose",
            value: 2,
          },
        ],
      },
    ],
    questionTitle:
      "Read the given question carefully and choose the correct answers:",
    questionHeading:
      "Read passage given below and answer the question that following:",
    questionContent:
      "The Human eye is one of the most important valuable sense organs, which the nature has given us. " +
      "It consists of a lens, which is made up of living tissues. The Human eye is roughly spherical in shape." +
      "It consists of a convex lens made up of living tissues. Hence, human lenses are living organs contrary to the simple optical lenses.",
    questionImage: login,
  },
];

// export const graphData = {
//   section_avg: {
//     concepts: [
//       {
//         concept_name: "Reflections",
//         score: 88,
//         sub_concepts: [
//           {
//             sub_concept_name: "Properties of Reflection",
//             score: 40,
//             root_concepts: [
//               { root_concept_name: "root concept reflection", score: 22 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 22 },
//               { root_concept_name: "root concept reflection3", score: 25 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 22 },
//               { root_concept_name: "root concept reflection3", score: 25 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//         ],
//       },
//       {
//         concept_name: "Reflections",
//         score: 90,
//         sub_concepts: [
//           {
//             sub_concept_name: "Properties of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection", score: 26 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 56,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 30 },
//               { root_concept_name: "root concept reflection3", score: 40 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 22 },
//               { root_concept_name: "root concept reflection3", score: 25 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   global_avg: {
//     concepts: [
//       {
//         concept_name: "Reflections",
//         score: 90,
//         sub_concepts: [
//           {
//             sub_concept_name: "Properties of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection", score: 26 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 56,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 30 },
//               { root_concept_name: "root concept reflection3", score: 40 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 22 },
//               { root_concept_name: "root concept reflection3", score: 25 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//         ],
//       },
//       {
//         concept_name: "Reflections",
//         score: 90,
//         sub_concepts: [
//           {
//             sub_concept_name: "Properties of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection", score: 26 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 56,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 30 },
//               { root_concept_name: "root concept reflection3", score: 40 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection",
//             score: 50,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 22 },
//               { root_concept_name: "root concept reflection3", score: 25 },
//             ],
//           },
//           {
//             sub_concept_name: "Laws of Reflection2",
//             score: 57,
//             root_concepts: [
//               { root_concept_name: "root concept reflection2", score: 40 },
//               { root_concept_name: "root concept reflection3", score: 50 },
//             ],
//           },
//         ],
//       },
//     ],
//   },
// };

export const graphData = {
  "section_avg": null,
  "sectionAverageResponseDto": {
      "concepts": null,
      "conceptResponseDtoList": [
          {
              "concept_name": "AEROBIC",
              "score": 9,
              "subConceptReportResponseDtoList": [
                  {
                      "sub_concept_name": "OXYGEN",
                      "score": 9,
                      "rootConceptReportResponseDtoList": [
                          {
                              "root_concept_name": "O2",
                              "score": 0
                          }
                      ]
                  }
              ]
          }
      ]
  },
  "global_avg": null,
  "globalAverageResponseDto": null
}

export const formal_assessment = {
  "quizOverview": {
"quizName": "Quizz-8",
"quizId": "402892888968a0d40189690682e60001",
    "studentCountInSection": 11,
    "studentCompletedQuiz": 1,
    "quizAttendance": 9,
    "noOfTimesQuizReleased": 1,
    "noOfQuestions": 3,
    "noOfCaseStudies": 0,
    "firstReleaseDate": "18/07/2023",
    "latestReleaseDate": "18/07/2023",
    "averageTimeTaken": "00Hrs 04Mins 11Sec",
    "averageTimeTakenByQuestion": "00Hrs 01Mins 11Sec"
},
  "sectionGlobalAverage": {
      "section_avg": null,
      "sectionAverageResponseDto": {
          "concepts": null,
          "conceptResponseDtoList": [
              {
                  "concept_name": "Testconcept1",
                  "score": 0,
                  "subConceptReportResponseDtoList": [
                      {
                          "sub_concept_name": "BEST",
                          "score": 0,
                          "rootConceptReportResponseDtoList": [
                              {
                                  "root_concept_name": "O2",
                                  "score": 0
                              }
                          ]
                      },
                      {
                          "sub_concept_name": "OXYGEN",
                          "score": 0,
                          "rootConceptReportResponseDtoList": [
                              {
                                  "root_concept_name": "O2",
                                  "score": 0
                              }
                          ]
                      }
                  ]
              },
              {
                  "concept_name": "AEROBIC",
                  "score": 0,
                  "subConceptReportResponseDtoList": [
                      {
                          "sub_concept_name": "BEST",
                          "score": 0,
                          "rootConceptReportResponseDtoList": [
                              {
                                  "root_concept_name": "O2",
                                  "score": 0
                              }
                          ]
                      },
                      {
                          "sub_concept_name": "OXYGEN",
                          "score": 0,
                          "rootConceptReportResponseDtoList": [
                              {
                                  "root_concept_name": "O2",
                                  "score": 0
                              }
                          ]
                      }
                  ]
              }
          ]
      },
      "global_avg": null,
      "globalAverageResponseDto": null
  },
  "topBottomAbsentStudents": {
      "topStudents": [
          {
              "studentName": "ANAND POTTY",
              "studentId": "4028928886f00aec0186fd573d860001",
              "percentage": 90
          }
      ],
      "bottomStudents": [],
      "absentStudents": [
          {
              "studentName": "hardik pas",
              "studentId": "402892888773f06e0187740ee2510000",
              "percentage": 0
          },
          {
              "studentName": "John Wick",
              "studentId": "402892888736b1f4018750ee1bbc0040",
              "percentage": 0
          },
          {
              "studentName": "Kartik singfh",
              "studentId": "402892888736b1f401875b76f8950074",
              "percentage": 0
          },
          {
              "studentName": "Sumit singh",
              "studentId": "402892888736b1f4018750eba71e003d",
              "percentage": 0
          },
          {
              "studentName": "Shukr sdkf",
              "studentId": "40289288880fb52401880fe056a9012b",
              "percentage": 0
          },
          {
              "studentName": "Kamlesh ck",
              "studentId": "402892888736b1f40187739636d4009d",
              "percentage": 0
          },
          {
              "studentName": "eighth jj",
              "studentId": "40289288840fe4a7018456d80a45006a",
              "percentage": 0
          },
          {
              "studentName": "kum k",
              "studentId": "40289288840fe4a7018455d0c3c7004d",
              "percentage": 0
          },
          {
              "studentName": "Adheera Andmm",
              "studentId": "402892888773f06e018774125de30003",
              "percentage": 0
          },
          {
              "studentName": "ds sds",
              "studentId": "40289288845d10b20184987a7ee4003d",
              "percentage": 0
          }
      ]
  },
  "conceptListLMSResponse": {
      "data": [
          {
              "id": "4028928884ba54830184e0fff52f0053",
              "concept": "Decembertwo",
              "active": true,
              "subConcepts": [
                  {
                      "id": "4028928884ba54830184e1004c590055",
                      "subConcept": "Bio2",
                      "active": true
                  },
                  {
                      "id": "4028928884ba54830184e1004c5d0056",
                      "subConcept": "Bio1",
                      "active": true,
                      "rootConceptResponses": [
                          {
                              "id": "4028928884ba54830184e10131c50057",
                              "rootConcept": "Bio1.1",
                              "active": true
                          },
                          {
                              "id": "4028928884ba54830184e10131ca0058",
                              "rootConcept": "Bio1.2",
                              "active": true
                          }
                      ]
                  }
              ]
          },
          {
              "id": "4028928884ba54830184f0cc2e0e006c",
              "concept": "NATURE",
              "active": true,
              "subConcepts": [
                  {
                      "id": "4028928886facffa018704cef6e40105",
                      "subConcept": "Search",
                      "active": true
                  }
              ]
          },
          {
              "id": "4028928884ba54830184f0cc59ff006e",
              "concept": "PLANTS",
              "active": true,
              "subConcepts": [
                  {
                      "id": "4028928884ba54830184f0d5cb32007c",
                      "subConcept": "COCONUT",
                      "active": true
                  },
                  {
                      "id": "4028928884ba54830184f0d5cb37007d",
                      "subConcept": "MANGO",
                      "active": true
                  },
                  {
                      "id": "4028928884ba54830184f0d5cb3b007e",
                      "subConcept": "NEEM ",
                      "active": true,
                      "rootConceptResponses": [
                          {
                              "id": "4028928884ba54830184f0d6d4590081",
                              "rootConcept": "ANTIOXIDANTS",
                              "active": true
                          },
                          {
                              "id": "4028928884ba54830184f0d6d45f0082",
                              "rootConcept": "HERBAL",
                              "active": true
                          },
                          {
                              "id": "4028928884ba54830184f0d6d4620083",
                              "rootConcept": "MEDICINE",
                              "active": true
                          },
                          {
                              "id": "4028928884ba54830184f0d6d4660084",
                              "rootConcept": "LEAF",
                              "active": true
                          },
                          {
                              "id": "4028928884ba54830184f0d6d4690085",
                              "rootConcept": "AYURVEDIC",
                              "active": true
                          }
                      ]
                  },
                  {
                      "id": "4028928884ba54830184f0d5cb40007f",
                      "subConcept": "BANANA",
                      "active": true
                  },
                  {
                      "id": "4028928884ba54830184f0d5cb430080",
                      "subConcept": "AVOCADO",
                      "active": true
                  },
                  {
                      "id": "4028928884ba54830184f5fa6c16011c",
                      "subConcept": "APPLE",
                      "active": true
                  }
              ]
          },
          {
              "id": "4028928884ba54830184f0cc828b0070",
              "concept": "ANIMALS",
              "active": true,
              "subConcepts": [
                  {
                      "id": "4028928884ba54830184f16533eb00dc",
                      "subConcept": "ANIMALS",
                      "active": true
                  }
              ]
          },
          {
              "id": "4028928884ba54830184f0ccbc000072",
              "concept": "MICRO",
              "active": true,
              "subConcepts": []
          },
          {
              "id": "4028928884ba54830184f0cf6a2d0074",
              "concept": "ANAEROBIC",
              "active": true,
              "subConcepts": []
          },
          {
              "id": "4028928884ba54830184f0cf86420076",
              "concept": "AEROBIC",
              "active": true,
              "subConcepts": [
                  {
                      "id": "4028928884ba54830184f0db53040088",
                      "subConcept": "OXYGEN",
                      "active": true,
                      "rootConceptResponses": [
                          {
                              "id": "4028928884ba54830184f0db714d0089",
                              "rootConcept": "O2",
                              "active": true
                          },
                          {
                              "id": "4028928889430d7b018946466478000d",
                              "rootConcept": "WWe",
                              "active": true
                          },
                          {
                              "id": "402892888968a0d401896d1f29570007",
                              "rootConcept": "New",
                              "active": true
                          }
                      ]
                  },
                  {
                      "id": "4028928889430d7b01894646327d000c",
                      "subConcept": "ASdx",
                      "active": true
                  },
                  {
                      "id": "402892888968a0d401896d1f1a740006",
                      "subConcept": "NEw",
                      "active": true
                  }
              ]
          },
          {
              "id": "40289288882457dc018824ea73f10007",
              "concept": "Test New",
              "active": true,
              "subConcepts": []
          },
          {
              "id": "40289288882457dc018824f12bac0012",
              "concept": "LMS 111",
              "active": true,
              "subConcepts": []
          }
      ],
      "message": "Successfully retrieved Concepts, SubConcepts and RootConcepts for this chapter.",
      "success": true,
      "errorCode": null,
      "path": null
  },
  "taxonomyList": [
      {
          "taxonomy": "Demrrtt",
          "percentage": 33,
          "taxonomyQuestionCount": 1
      },
      {
          "taxonomy": "Synthesis",
          "percentage": 33,
          "taxonomyQuestionCount": 1
      }
  ]
}

export const question_wise_data = {
  "data": {
      "classAverage": null,
      "concepts": [
          {
              "conceptName": "AEROBIC",
              "conceptId": "4028928884ba54830184f0cf86420076",
              "percentageInQuiz": "1%",
              "questions": [
                  {
                      "questionId": "4028928887188108018718e300e40045",
                      "questionTypeId": "402892888697da25018697de5e1e0004",
                      "questionType": "Case Study",
                      "question": "as",
                      "explanation": "<p>as</p>",
                      "map_name": "C:\\fakepath\\Screenshot (3).png",
                      "map": "https://s3.ap-south-1.amazonaws.com/lmscontentupload/Content-Service/Chapters/1148bc0e-40cf-487b-a5e7-bc623974ffe7_img11.jpg",
                      "listOfAnswers": {
                          "4028928887188108018718e300e40045": []
                      },
                      "report": {
                          "correctQuestionAnswerStudentPercentage": 0,
                          "correctQuestionAnswerOption": null,
                          "quizAttemptRate": null,
                          "studentQuestionAttemptedCount": 0,
                          "mostOptionChoseStudentCount": null
                      },
                      "wrong_answer_count": 0,
                      "conceptName": "AEROBIC"
                  },
                  {
                      "questionId": "402892888718810801872298d78e0067",
                      "questionTypeId": "402892888697da25018697ddca200002",
                      "questionType": "Muti Select Question",
                      "question": "<p>Other names of&nbsp; &nbsp;india</p>",
                      "explanation": null,
                      "map_name": null,
                      "map": null,
                      "listOfAnswers": {
                          "A": {
                              "option": "A",
                              "order": 65,
                              "answerKey": "bharat",
                              "resourceUrl": "",
                              "correctAnswer": true
                          },
                          "B": {
                              "option": "B",
                              "order": 66,
                              "answerKey": "chola",
                              "resourceUrl": "",
                              "correctAnswer": true
                          },
                          "C": {
                              "option": "C",
                              "order": 67,
                              "answerKey": "bengal",
                              "resourceUrl": "",
                              "correctAnswer": false
                          },
                          "D": {
                              "option": "D",
                              "order": 68,
                              "answerKey": "pak",
                              "resourceUrl": "",
                              "correctAnswer": false
                          }
                      },
                      "report": {
                          "correctQuestionAnswerStudentPercentage": 25,
                          "correctQuestionAnswerOption": null,
                          "quizAttemptRate": 14,
                          "studentQuestionAttemptedCount": 2,
                          "mostOptionChoseStudentCount": null
                      },
                      "wrong_answer_count": 6,
                      "conceptName": "AEROBIC"
                  }
              ]
          },
          {
              "conceptName": "COMPUNDS",
              "conceptId": "4028928884ba54830184f0b990700062",
              "percentageInQuiz": "1%",
              "questions": [
                  {
                      "questionId": "4028928887376b2501874feb86e000ea",
                      "questionTypeId": "402892888697da25018697dd5fc70001",
                      "questionType": "Fill in the blanks",
                      "question": "<p>THE UNIT OF RESISTANCE IS ____ AND THE UNIT OF CURRENT IS ____</p>",
                      "explanation": "<p>RESISTANCE</p>",
                      "map_name": null,
                      "map": null,
                      "listOfAnswers": {
                          "Order_2": {
                              "order": 2,
                              "answerKey": [
                                  "AMPERE"
                              ],
                              "resourceUrl": null
                          },
                          "Order_1": {
                              "order": 1,
                              "answerKey": [
                                  "OHMS"
                              ],
                              "resourceUrl": null
                          }
                      },
                      "report": {
                          "correctQuestionAnswerStudentPercentage": 7,
                          "correctQuestionAnswerOption": {
                              "A": {
                                  "option": "A",
                                  "order": 65,
                                  "answerKey": "bharat"
                              },
                              "B": {
                                  "option": "B",
                                  "order": 66,
                                  "answerKey": "chola"
                              },
                              "C": {
                                  "option": "C",
                                  "order": 67,
                                  "answerKey": "bengal"
                              },
                              "D": {
                                  "option": "D",
                                  "order": 68,
                                  "answerKey": "pak"
                              }
                          },
                          "quizAttemptRate": 21,
                          "studentQuestionAttemptedCount": 3,
                          "mostOptionChoseStudentCount": null
                      },
                      "wrong_answer_count": 12,
                      "conceptName": "COMPUNDS"
                  }
              ]
          }
      ],
      "caseStudy": [
          {
              "conceptName": "COMPUNDS",
              "conceptId": "4028928884ba54830184f0b990700062",
              "percentageInQuiz": "1%",
              "questions": [
                  {
                      "questionId": "402892888718810801872297a0500064",
                      "questionTypeId": "402892888697da25018697dd5fc70001",
                      "questionType": "Fill in the blanks",
                      "question": "<p>The unit of current is ____</p>",
                      "explanation": null,
                      "map_name": null,
                      "map": null,
                      "listOfAnswers": {
                          "Order_1": {
                              "order": 1,
                              "answerKey": [
                                  "amperes"
                              ],
                              "resourceUrl": null
                          }
                      },
                      "report": {
                          "correctQuestionAnswerStudentPercentage": 0,
                          "correctQuestionAnswerOption": null,
                          "quizAttemptRate": 7,
                          "studentQuestionAttemptedCount": 1,
                          "mostOptionChoseStudentCount": null
                      },
                      "wrong_answer_count": 16,
                      "conceptName": "COMPUNDS"
                  }
              ]
          }
      ]
  },
  "message": "Question wise performance in formative assessment success",
  "success": true,
  "errorCode": null,
  "path": null
}

export const top5_questions = {
  "data": {
      "conceptQuestions": [
          {
              "conceptName": "AEROBIC",
              "conceptId": "4028928884ba54830184f0cf86420076",
              "percentageInQuiz": "1%",
              "questions": [
                  {
                      "questionId": "402892888718810801872298d78e0067",
                      "questionTypeId": "402892888697da25018697ddca200002",
                      "questionType": "Muti Select Question",
                      "question": "<p>Other names of&nbsp; &nbsp;india</p>",
                      "explanation": null,
                      "map_name": null,
                      "map": null,
                      "listOfAnswers": {
                          "A": {
                              "option": "A",
                              "order": 65,
                              "answerKey": "bharat",
                              "resourceUrl": "",
                              "correctAnswer": true
                          },
                          "B": {
                              "option": "B",
                              "order": 66,
                              "answerKey": "chola",
                              "resourceUrl": "",
                              "correctAnswer": true
                          },
                          "C": {
                              "option": "C",
                              "order": 67,
                              "answerKey": "bengal",
                              "resourceUrl": "",
                              "correctAnswer": false
                          },
                          "D": {
                              "option": "D",
                              "order": 68,
                              "answerKey": "pak",
                              "resourceUrl": "",
                              "correctAnswer": false
                          }
                      },
                      "report": {
                          "correctQuestionAnswerStudentPercentage": 25,
                          "correctQuestionAnswerOption": null,
                          "quizAttemptRate": 14,
                          "studentQuestionAttemptedCount": 2,
                          "mostOptionChoseStudentCount": null
                      },
                      "wrong_answer_count": 6,
                      "conceptName": "AEROBIC"
                  },
                  {
                      "questionId": "4028928887188108018718e300e40045",
                      "questionTypeId": "402892888697da25018697de5e1e0004",
                      "questionType": "Case Study",
                      "question": "as",
                      "explanation": "<p>as</p>",
                      "map_name": "C:\\fakepath\\Screenshot (3).png",
                      "map": "https://s3.ap-south-1.amazonaws.com/lmscontentupload/Content-Service/Chapters/1148bc0e-40cf-487b-a5e7-bc623974ffe7_img11.jpg",
                      "listOfAnswers": {
                          "4028928887188108018718e300e40045": []
                      },
                      "report": {
                          "correctQuestionAnswerStudentPercentage": 0,
                          "correctQuestionAnswerOption": null,
                          "quizAttemptRate": null,
                          "studentQuestionAttemptedCount": 0,
                          "mostOptionChoseStudentCount": null
                      },
                      "wrong_answer_count": 0,
                      "conceptName": "AEROBIC"
                  }
              ]
          },
          {
              "conceptName": "COMPUNDS",
              "conceptId": "4028928884ba54830184f0b990700062",
              "percentageInQuiz": "1%",
              "questions": [
                  {
                      "questionId": "402892888718810801872297a0500064",
                      "questionTypeId": "402892888697da25018697dd5fc70001",
                      "questionType": "Fill in the blanks",
                      "question": "<p>The unit of current is ____</p>",
                      "explanation": null,
                      "map_name": null,
                      "map": null,
                      "listOfAnswers": {
                          "Order_1": {
                              "order": 1,
                              "answerKey": [
                                  "amperes"
                              ],
                              "resourceUrl": null
                          }
                      },
                      "report": {
                          "correctQuestionAnswerStudentPercentage": 0,
                          "correctQuestionAnswerOption": null,
                          "quizAttemptRate": 7,
                          "studentQuestionAttemptedCount": 1,
                          "mostOptionChoseStudentCount": null
                      },
                      "wrong_answer_count": 16,
                      "conceptName": "COMPUNDS"
                  },
                  {
                      "questionId": "4028928887376b2501874feb86e000ea",
                      "questionTypeId": "402892888697da25018697dd5fc70001",
                      "questionType": "Fill in the blanks",
                      "question": "<p>THE UNIT OF RESISTANCE IS ____ AND THE UNIT OF CURRENT IS ____</p>",
                      "explanation": "<p>RESISTANCE</p>",
                      "map_name": null,
                      "map": null,
                      "listOfAnswers": {
                          "Order_2": {
                              "order": 2,
                              "answerKey": [
                                  "AMPERE"
                              ],
                              "resourceUrl": null
                          },
                          "Order_1": {
                              "order": 1,
                              "answerKey": [
                                  "OHMS"
                              ],
                              "resourceUrl": null
                          }
                      },
                      "report": {
                          "correctQuestionAnswerStudentPercentage": 7,
                          "correctQuestionAnswerOption": {
                              "A": {
                                  "option": "A",
                                  "order": 65,
                                  "answerKey": "bharat"
                              },
                              "B": {
                                  "option": "B",
                                  "order": 66,
                                  "answerKey": "chola"
                              },
                              "C": {
                                  "option": "C",
                                  "order": 67,
                                  "answerKey": "bengal"
                              },
                              "D": {
                                  "option": "D",
                                  "order": 68,
                                  "answerKey": "pak"
                              }
                          },
                          "quizAttemptRate": 21,
                          "studentQuestionAttemptedCount": 3,
                          "mostOptionChoseStudentCount": null
                      },
                      "wrong_answer_count": 12,
                      "conceptName": "COMPUNDS"
                  }
              ]
          }
      ]
  },
  "message": "Top five difficult question formative assessment success",
  "success": true,
  "errorCode": null,
  "path": null
}

export const top5bottom5 = {
  "data": {
      "topStudents": [
          {
              "studentId": "4028928886f00aec0186fd573d860001",
              "studentName": "ANAND POTTY",
              "percentage": 100
          }
      ],
      "bottomStudents": [
          {
              "studentId": "4028928886f00aec0186fd573d860001",
              "studentName": "ANAND POTTY",
              "percentage": 100
          }
      ],
      "absentStudents": []
  },
  "message": "Successfully fetched the top 5 students, Bottom 5 students and absent students.",
  "success": true,
  "errorCode": null,
  "path": null
}

export const conceptTreeFormal = {
  "data": [
      {
          "id": "4028928884ba54830184e0fff52f0053",
          "concept": "Decembertwo",
          "active": true,
          "subConcepts": [
              {
                  "id": "4028928884ba54830184e1004c590055",
                  "subConcept": "Bio2",
                  "active": true
              },
              {
                  "id": "4028928884ba54830184e1004c5d0056",
                  "subConcept": "Bio1",
                  "active": true,
                  "rootConceptResponses": [
                      {
                          "id": "4028928884ba54830184e10131c50057",
                          "rootConcept": "Bio1.1",
                          "active": true
                      },
                      {
                          "id": "4028928884ba54830184e10131ca0058",
                          "rootConcept": "Bio1.2",
                          "active": true
                      }
                  ]
              }
          ]
      },
      {
          "id": "4028928884ba54830184f0cc2e0e006c",
          "concept": "NATURE",
          "active": true,
          "subConcepts": [
              {
                  "id": "4028928886facffa018704cef6e40105",
                  "subConcept": "Search",
                  "active": true
              }
          ]
      },
      {
          "id": "4028928884ba54830184f0cc59ff006e",
          "concept": "PLANTS",
          "active": true,
          "subConcepts": [
              {
                  "id": "4028928884ba54830184f0d5cb32007c",
                  "subConcept": "COCONUT",
                  "active": true
              },
              {
                  "id": "4028928884ba54830184f0d5cb37007d",
                  "subConcept": "MANGO",
                  "active": true
              },
              {
                  "id": "4028928884ba54830184f0d5cb3b007e",
                  "subConcept": "NEEM ",
                  "active": true,
                  "rootConceptResponses": [
                      {
                          "id": "4028928884ba54830184f0d6d4590081",
                          "rootConcept": "ANTIOXIDANTS",
                          "active": true
                      },
                      {
                          "id": "4028928884ba54830184f0d6d45f0082",
                          "rootConcept": "HERBAL",
                          "active": true
                      },
                      {
                          "id": "4028928884ba54830184f0d6d4620083",
                          "rootConcept": "MEDICINE",
                          "active": true
                      },
                      {
                          "id": "4028928884ba54830184f0d6d4660084",
                          "rootConcept": "LEAF",
                          "active": true
                      },
                      {
                          "id": "4028928884ba54830184f0d6d4690085",
                          "rootConcept": "AYURVEDIC",
                          "active": true
                      }
                  ]
              },
              {
                  "id": "4028928884ba54830184f0d5cb40007f",
                  "subConcept": "BANANA",
                  "active": true
              },
              {
                  "id": "4028928884ba54830184f0d5cb430080",
                  "subConcept": "AVOCADO",
                  "active": true
              },
              {
                  "id": "4028928884ba54830184f5fa6c16011c",
                  "subConcept": "APPLE",
                  "active": true
              }
          ]
      },
      {
          "id": "4028928884ba54830184f0cc828b0070",
          "concept": "ANIMALS",
          "active": true,
          "subConcepts": [
              {
                  "id": "4028928884ba54830184f16533eb00dc",
                  "subConcept": "ANIMALS",
                  "active": true
              }
          ]
      },
      {
          "id": "4028928884ba54830184f0ccbc000072",
          "concept": "MICRO",
          "active": true,
          "subConcepts": []
      },
      {
          "id": "4028928884ba54830184f0cf6a2d0074",
          "concept": "ANAEROBIC",
          "active": true,
          "subConcepts": []
      },
      {
          "id": "4028928884ba54830184f0cf86420076",
          "concept": "AEROBIC",
          "active": true,
          "subConcepts": [
              {
                  "id": "4028928884ba54830184f0db53040088",
                  "subConcept": "OXYGEN",
                  "active": true,
                  "rootConceptResponses": [
                      {
                          "id": "4028928884ba54830184f0db714d0089",
                          "rootConcept": "O2",
                          "active": true
                      },
                      {
                          "id": "4028928889430d7b018946466478000d",
                          "rootConcept": "WWe",
                          "active": true
                      },
                      {
                          "id": "402892888968a0d401896d1f29570007",
                          "rootConcept": "New",
                          "active": true
                      }
                  ]
              },
              {
                  "id": "4028928889430d7b01894646327d000c",
                  "subConcept": "ASdx",
                  "active": true
              },
              {
                  "id": "402892888968a0d401896d1f1a740006",
                  "subConcept": "NEw",
                  "active": true
              }
          ]
      },
      {
          "id": "40289288882457dc018824ea73f10007",
          "concept": "Test New",
          "active": true,
          "subConcepts": []
      },
      {
          "id": "40289288882457dc018824f12bac0012",
          "concept": "LMS 111",
          "active": true,
          "subConcepts": []
      }
  ],
  "message": "Successfully retrieved the concept, subConcepts and rootConcept for the given chapter.",
  "success": true,
  "errorCode": null,
  "path": null
}


export const formalOverview = {
  "data": {
      "quizId": "402892888968a0d40189690682e60001",
      "quizName": "Quizz-8",
      "studentCountInSection": 1,
      "studentCompletedQuiz": 1,
      "quizAttendance": 100.0,
      "noOfTimesQuizReleased": 1,
      "noOfQuestions": null,
      "noOfCaseStudies": 0,
      "firstReleaseDate": "18/07/2023",
      "latestReleaseDate": "18/07/2023",
      "averageTimeTaken": "00Hrs 04Mins 11Sec",
      "averageTimeTakenByQuestion": null,
      "taxonomyCalculations": [
          {
              "taxonomy": "Demrrtt",
              "percentage": 33,
              "taxonomyQuestionCount": 1
          },
          {
              "taxonomy": "Synthesis",
              "percentage": 33,
              "taxonomyQuestionCount": 1
          }
      ]
  },
  "message": "Success to fetch the teacher format quiz overview report.",
  "success": true,
  "errorCode": null,
  "path": null
}

export const sectionGlobalData = {
  "data": {
      "section_avg": null,
      "sectionAverageResponseDto": {
          "concepts": null,
          "conceptResponseDtoList": [
              {
                  "concept_name": "AEROBIC",
                  "score": 0,
                  "subConceptReportResponseDtoList": [
                      {
                          "sub_concept_name": "OXYGEN",
                          "score": 0,
                          "rootConceptReportResponseDtoList": []
                      }
                  ]
              }
          ]
      },
      "global_avg": null,
      "globalAverageResponseDto": null
  },
  "message": "Successfully fetched Section Global Average.",
  "success": true,
  "errorCode": null,
  "path": null
}