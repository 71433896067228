import React, { useState, useEffect, useCallback, useRef } from "react";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import SelectBoardAndGrade from "./CommonComponents/SelectBoardAndGrade";
import { useNavigate, useLocation } from "react-router-dom";
import TextEditor from "./CommonComponents/TextEditor";
import axios from "axios";
import { orange } from "@mui/material/colors";
import create_caseStudy_question_post from "../../API_CONTROLLER/create-questions/caseStudy_questionPost";
import DeleteConfirmationAlert from "./CommonComponents/DeleteConfirmation";
import chapterStyles from "../../css/createChapter.module.css";
import {
    Button,
    FormControl,
    Grid,
    Box,
    TextField,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    styled,
    Modal,
} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import conceptlist_get from "../../API_CONTROLLER/concept-controller/conceptlist_get";
import rootConcept_controller_get from "../../API_CONTROLLER/root-concept-controller/rootConcept_controller_get";
import subConcept_controller_get from "../../API_CONTROLLER/concept-controller/subConcept_controller_get";
import taxonomy_categorylist_get from "../../API_CONTROLLER/taxonomy-controller/taxonomy_categorylist_get";
import taxonomy_get from "../../API_CONTROLLER/taxonomy-controller/taxonomy_get";
import queType_get from "../../API_CONTROLLER/question-controller/queType_get";
import getSubQuestions from "../../API_CONTROLLER/create-questions/subQuestions_get";

import boards_controller_getall from "../../API_CONTROLLER/boards-controller/boards_controller_getall";
import gradeListGet from "../../API/grade-section-mapping-controller/grade_get";
import action__Add from "../../../../src/img/AddDefault.svg";

import MultiSelectQB from "../MultiSelectQB";
import McqCreationQB from "../McqCreationQB";
import FillInTheBlanks from "./FillIntheBlanks";
import SubQuestionPreview from "./CommonComponents/SubQuestionPreview";
import ImageListModal from "./CommonComponents/ImageListModal";
import SubQuestionPreviewScroll from "./CommonComponents/SubQuestionPreviewScroll";
import DeleteBlanksModal from "./CommonComponents/DeleteBlanksModal";
import get_question from "../../API_CONTROLLER/create-questions/get_questionbyid";
import get_subQuestion from "../../API_CONTROLLER/create-questions/get_subQuestionbyid";
import update_question_post from "../../API_CONTROLLER/create-questions/question_put";
import McqCreationQBCase from "../McqCreationQBCase";
import MultiSelectQBCase from "../MultiSelectQBCase";
// import DeleteConfirmationAlert from "./CommonComponents/DeleteConfirmation";

const CustomHead = styled(Typography)(({ theme }) => ({
    '&': {
        color: orange[600],
    },
}))

const CustomRadioIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    color: orange[600],
    boxShadow: 'inset 0 0 0 3px orange, inset 0 -1px 0 orange',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto orange',
        color: orange[600],
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
    backgroundColor: orange[600],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: orange[800],
    },
});

const CustomButton = styled(Button)(({ theme }) => ({
    color: "#FFFFFF",
    backgroundColor: '#FD8C00',
    fontFamily: ['normal', 'normal', 'medium', 'Poppins'].join(','),
    fontSize: '10px',
    borderRadius: '30px',
    width: 'auto',
    padding: "0.6vw 1vw",
    '&:hover': {
        backgroundColor: '#FD8C00',
    },
}));

const CustomAddButton = styled(Button)(({ theme }) => ({
    color: "#9B9FA7",
    backgroundColor: '#F8F8F8',
    border: '1px dashed #B5B6B6',
    borderRadius: '10px',
}))

function CustomRadio(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<CustomRadioCheckedIcon />}
            icon={<CustomRadioIcon />}
            {...props}
        />
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '58%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "45vw",
    overflow: "scroll",
    bgcolor: 'background.paper',
    borderRadius: "4px",
    boxShadow: 24,
};

const addSubQuestionStyle = {
    position: 'absolute',
    top: '50%',
    left: '58%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "auto",
    overflow: "scroll",
    bgcolor: 'background.paper',
    borderRadius: "4px",
    boxShadow: 24,
}

const CustomTextField = styled(TextField)(({ }) => ({
    width: "96%",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "2px !important",
    "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
        opacity: "1 !important",
        background: "rgb(255 255 255 / 31%) !important",
        border: "1px solid #B5B6B6",
        borderRadius: "2px !important",
    }
}))

export default function CreateCaseStudy(props) {
    const [boardsList, setBoardsList] = useState();
    const [boardSelect, setBoardSelect] = useState('');

    const [gradeList, setGradeList] = useState();
    const [gradeSelect, setGradeSelect] = useState([]);

    const [boardAndGrade, setBoardAndGrade] = useState([]);

    const [boardAndGradeCombo, setBoardAndGradeCombo] = useState([]);

    const [title, setTitle] = useState("");
    const [explanation, setExplanation] = useState({ content: "" });
    const [editExplanation, setEditExplanation] = useState({ content: "" });

    const [radioButtonValue, setRadioButtonValue] = useState("machine");
    const [radioButtonEnable, setRadioButtonEnable] = useState(false);

    const [uploadImage, setUploadImage] = useState();
    const [selectedImageName, setSelectedImageName] = useState("");
    const [selectedImageUrl, setSelectedImageUrl] = useState();
    const [imagePreview, setImagePreview] = useState();

    const [responseValueSubQuestion, setResponseValueSubQuestion] = useState();
    const [responseValue, setResponseValue] = useState();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState();

    const [disable, setDisable] = useState(true);

    const [clearContent, setClearContent] = useState(false);

    const subjectId = sessionStorage.getItem("subjectId");
    const conceptId = sessionStorage.getItem("conceptId");
    const subConceptId = sessionStorage.getItem("subConceptId");
    const rootConcept = sessionStorage.getItem("rootConceptId");
    const questionTypeId = sessionStorage.getItem("questionType");
    const taxonomyCategory = sessionStorage.getItem("taxonomyCategory");
    const taxonomy = sessionStorage.getItem("taxonomy");
    const caseStudyContent = sessionStorage.getItem("case");
    const caseStudyTitle = sessionStorage.getItem("caseTitle");

    const [conceptlist, setConceptlist] = useState({});
    const [conceptInputs, setConceptInputs] = useState("");
    const [subconceptList, setSubconceptList] = useState({});
    const [subConceptInputs, setSubConceptInputs] = useState("");
    const [rootConceptList, setRootConceptList] = useState({});
    const [queType, setQueType] = useState({});
    const [queTypeList, setQueTypeList] = useState([]);
    const [chapterInputs, setChapterInputs] = useState();
    const [taxonomyCategoryList, setTaxonomyCategoryList] = useState({});
    const [taxonomyList, setTaxonomyList] = useState({});
    const [selectedTaxonomyCategory, setSelectedTaxonomyCategory] = useState("");
    const [selectedTaxonomy, setSelectedTaxonomy] = useState("");
    const [isAddChapter, setIsAddChapter] = useState(true);

    const [errorField, setErrorField] = useState({});
    const [errorFieldCombo, setErrorFieldCombo] = useState({});
    const [disabledFields, setDisabledFields] = useState(false);

    const [questionType, setQuestionType] = useState("");
    const [questionValue, setQuestionValue] = useState();

    const [subQuestionTypeChange, setSubQuestionTypeChange] = useState("");

    const [subQuestion, setSubQuestion] = useState([]);
    const [showSubQuestion, setShowSubQuestion] = useState(false);

    const [openAddSubQuestionModal, setOpenAddSubQuestionModal] = React.useState(false);
    const handleAddSubQuestionModalOpen = () => setOpenAddSubQuestionModal(true);
    const handleAddSubQuestionModalClose = () => setOpenAddSubQuestionModal(false);

    const [openQuestionModal, setOpenQuestionModal] = React.useState(false);
    const handleQuestionModalOpen = () => setOpenQuestionModal(true);
    const handleQuestionModalClose = () => setOpenQuestionModal(false);

    const [openSubQuestionPreviewModal, setOpenSubQuestionPreviewModal] = React.useState(false);
    const handlePreviewModalOpen = useCallback(() => setOpenSubQuestionPreviewModal(true), []);
    const handlePreviewModalClose = () => setOpenSubQuestionPreviewModal(false);

    const [openImageListModal, setOpenImageListModal] = React.useState(false);
    const handleImageListModalOpen = () => setOpenImageListModal(true);
    const handleImageListModalClose = () => setOpenImageListModal(false);

    const [edit, setEdit] = useState(false);
    const [editButton, setEditButton] = useState(false);

    const [subQuestionValue, setSubQuestionValue] = useState();

    const [previewFromModal, setPreviewFromModal] = useState(false);

    const [questionTypeDisabled, setQuestionTypeDisabled] = useState(false);

    const token = sessionStorage.getItem("token");



    const navigate = useNavigate();
    const questionModalRef = useRef(null);
    const addSubQuestionModalRef = useRef(null);

    useEffect(() => {
        if (openQuestionModal) {
            // Scroll to question modal
            questionModalRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        if (openAddSubQuestionModal) {
            // Scroll to add sub question modal
            addSubQuestionModalRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [openQuestionModal, openAddSubQuestionModal]);

    useEffect(() => {
        boards_controller_getall(setBoardsList);
        gradeListGet(setGradeList);
    }, [])

    const handleBoardChange = (e) => {
        const value = e.target.value;
        setBoardSelect(value);
        setErrorFieldCombo(prevState => {
            delete prevState['board'];
            return prevState;
        })
    }

    const handleGradeChange = (e) => {
        const value = e.target.value;
        setGradeSelect(typeof value === "string" ? value.split(",") : value);
        setErrorFieldCombo(prevState => {
            delete prevState['grade'];
            return prevState;
        })
    }

    useEffect(() => {
        if (conceptInputs !== "") {
            setSubConceptInputs({ subConceptId: "" });
            subConcept_controller_get(conceptInputs, setSubconceptList);
        }
        if (subQuestionValue) {
            setSubConceptInputs(subQuestionValue.subConceptId);
        }
    }, [conceptInputs]);

    useEffect(() => {
        if (subConceptInputs !== "") {
            rootConcept_controller_get(subConceptInputs, setRootConceptList);
        }
    }, [subConceptInputs]);

    useEffect(() => {
        if (selectedTaxonomyCategory !== "") {
            setSelectedTaxonomy("");
            taxonomy_get(setTaxonomyList, selectedTaxonomyCategory, questionType === "402881858369cb30018369d7dfd70000" ? true : false);
        }
        if (subQuestionValue) {
            setSelectedTaxonomy(subQuestionValue.taxonomyId);
        }
    }, [selectedTaxonomyCategory]);

    useEffect(() => {
        taxonomy_categorylist_get(setTaxonomyCategoryList);
        conceptlist_get(setConceptlist, subjectId);
        queType_get(setQueType);
        // sessionStorage.removeItem("parentId");
        return () => { };
    }, []);

    useEffect(() => {
        if (queType.length > 0) {
            setQueTypeList(queType.filter(que => {
                return que.code == "FILL" || que.code === "MCQ" || que.code === "MSQ"
            }))
        }
    }, [queType]);

    const handleConceptInputs = (e) => {
        setConceptInputs(e.target.value);
        setErrorField(prevState => {
            delete prevState["concept"];
            return prevState;
        })
    };

    const handlesubConceptInputs = (e) => {
        e.preventDefault();
        setSubConceptInputs(e.target.value);
        setErrorField(prevState => {
            delete prevState["subconcept"];
            return prevState;
        })
    };

    const handleQuestionType = (e) => {
        setQuestionType(e.target.value);
        setErrorField(prevState => {
            delete prevState["questiontype"];
            return prevState;
        })
    };

    const handlerTaxonomyInputs = (e) => {
        setSelectedTaxonomy(e.target.value);
        setErrorField(prevState => {
            delete prevState["taxonomy"];
            return prevState;
        })
    }

    const handlerTaxonomyCategory = (e) => {
        setSelectedTaxonomyCategory(e.target.value);
        setErrorField(prevState => {
            delete prevState["taxonomycategory"];
            return prevState;
        })
    }

    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    const fileInput = React.useRef();

    const handleBoardAndGradeChange = () => {
        let errorObj = {};
        let error = false;
        if (boardSelect === "") {
            errorObj['board'] = "Please choose the Board";
            error = true;
        }
        if (gradeSelect.length === 0) {
            errorObj['grade'] = "Please choose atleast one Grade";
            error = true;
        }
        setErrorFieldCombo(errorObj);
        if (!error) {
            setErrorField(prevState => {
                delete prevState['combo'];
                return prevState;
            })
            if (Object.keys(boardAndGradeCombo).length > 0) {
                const foundKey = Object.hasOwn(boardAndGradeCombo, boardSelect);
                if (foundKey) {
                    let found;
                    found = gradeSelect.filter(item => !boardAndGradeCombo[boardSelect].includes(item))
                    const concatArray = boardAndGradeCombo[boardSelect].concat(found);
                    setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: concatArray } });
                    setBoardSelect('');
                    setGradeSelect([]);
                }
                else {
                    setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: gradeSelect } });
                    setBoardSelect('');
                    setGradeSelect([]);
                }
            }
            else {
                setBoardAndGradeCombo(boardAndGradeCombo => { return { ...boardAndGradeCombo, [boardSelect]: gradeSelect } });
                setBoardSelect('');
                setGradeSelect([]);
            }
            gradeSelect.map((grade, ind) => {
                let obj = {
                    "boardId": boardSelect,
                    "gradeId": grade
                };
                setBoardAndGrade(oldArray => [...oldArray, obj]);
            })

        }
    }

    const onRemoveCombo = (boardId, gradeId) => {
        if (disabledFields) {
            return false;
        }
        else {
            boardAndGrade.map((key, index) => {
                if (key.boardId === boardId && key.gradeId === gradeId) {
                    boardAndGrade.splice(index, 1);
                };
            })
            Object.keys(boardAndGradeCombo).map(key => {
                if (key === boardId) {
                    boardAndGradeCombo[key].map((value, i) => {
                        if (value === gradeId) {
                            boardAndGradeCombo[key].splice(i, 1);
                            if (boardAndGradeCombo[key].length === 0) {
                                delete boardAndGradeCombo[key];
                                setBoardSelect('');
                                setGradeSelect([]);
                            }
                            else {
                                setBoardSelect(key);
                                setGradeSelect([...boardAndGradeCombo[key]]);
                            }
                        }
                    })
                }
            })
        }
    }

    const onTitleChange = (e) => {
        setTitle(e.target.value)
        setErrorField(prevState => {
            delete prevState['title'];
            return prevState;
        })
    }

    const onExplanationHandler = (content) => {
        setExplanation({ content: content });
        setClearContent(false);
        setErrorField(prevState => {
            delete prevState['case'];
            return prevState;
        })
    }

    const imageUploadHandler = (e) => {
        setRadioButtonValue(e.target.value);
        if (e.target.value === "repository") {
            handleImageListModalOpen();
        }
    }

    const onFileChange = (e) => {
        const file = e.target.files[0];
        setUploadImage(e.target.files[0]);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
        var fileMode = new File([file], file.name, { type: file.type });
        const formData = new FormData();
        formData.append("file", fileMode);

        const file_upload_post = async () => {
            let token = sessionStorage.getItem("token");
            let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=QUESTIONS`, formData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(
                    (response) => {
                        setSelectedImageUrl(response.data.data)
                        sessionStorage.setItem("logoUrl", response.data.data)
                    }

                )
                .catch(err => console.log(err))
        }
        file_upload_post();
    }

    const onRemoveImage = (type) => {
        if (type === "upload") {
            setUploadImage();
            setSelectedImageUrl()
            setUploadImage()
        }
        else {
            setImagePreview();
            setSelectedImageUrl();
            setSelectedImageName("");
        }
    }

    useEffect(() => {
        if (uploadImage || selectedImageName) {
            setRadioButtonEnable(true);
        }
        else {
            setRadioButtonEnable(false);
        }
    }, [uploadImage, selectedImageName]);

    const handleCaseStudy = (type) => {
        let errorObj = {}
        let isError = false;

        if (title === "" || explanation.content === "" || boardAndGradeCombo.length === 0) {
            isError = true;

            if (boardAndGradeCombo.length === 0) {
                errorObj['combo'] = "Please provide the combination of board and grade";
            }
            if (title === "") {
                errorObj['title'] = "Please provide the title";
            }
            if (explanation.content === "") {
                errorObj['case'] = "Please provide the Case";
            }
        }

        setErrorField(errorObj);

        if (!isError) {
            sessionStorage.setItem("case", explanation.content);
            sessionStorage.setItem("caseTitle", title);
            setClearContent(false);
            setDisabledFields(true);
            if (type === "update") {
                const payload1 = {
                    id: questionValue.id,
                    subjectId: subjectId,
                    conceptId: conceptId,
                    subConceptId: subConceptId,
                    rootConceptId: rootConcept !== "undefined" ? rootConcept : null,
                    questionTypeId: questionTypeId,
                    boardAndGrades: boardAndGrade,
                    question: title,
                    explanation: explanation.content,
                    imageUrl: selectedImageUrl,
                    questionPattern: "OBJECTIVE",
                    // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
                    hasSubQuestions: true
                }
                const payload = removeNewlines(payload1);
                console.log(">>>", payload);
                update_question_post(payload, setShowAlert, setResponseValue);
            }
            else {
                const payload1 = {
                    subjectId: subjectId,
                    conceptId: conceptId,
                    subConceptId: subConceptId,
                    rootConceptId: rootConcept !== "undefined" ? rootConcept : null,
                    questionTypeId: questionTypeId,
                    boardAndGrades: boardAndGrade,
                    question: title,
                    explanation: explanation.content,
                    imageUrl: selectedImageUrl,
                    questionPattern: "OBJECTIVE",
                    // imageUrl: "https://s3.ap-south-1.amazonaws.com/azvasa-lms-test-s3-app-backup/User-Service/Common-Content/09eae5db-61f1-4540-b111-0069d461f084_bg-logo.png",
                    hasSubQuestions: true
                }
                const payload = removeNewlines(payload1);
                console.log(">>>", payload);
                create_caseStudy_question_post(payload, setShowAlert, setResponseValue);
            }
        }
    }


    const removeNewlines = (obj) => {
        const newObj = {};
        // Recursively traverse the object
        for (const key in obj) {
            if (typeof obj[key] === 'string') {
                obj[key] = obj[key].replace(/\n/g, '');
            } else if (typeof obj[key] === 'object') {
                removeNewlines(obj[key]);
            }
        }
        return obj;
    };


    useEffect(() => {
        if (!edit) {
            if (showAlert) {
                setAlertMessage(`You have succefully created case study ${title}`);
                setDisable(false);
                // setEdit(false);
                setErrorField(prevState => {
                    delete prevState["createButton"];
                    return prevState
                })
            }
        }
        else {
            if (showAlert) {
                setAlertMessage(`You have succefully updated case study ${title}`);
                setDisable(false);
                // setEdit(false);
                setErrorField(prevState => {
                    delete prevState["createButton"];
                    return prevState
                })
            }
        }
    }, [showAlert === true]);

    useEffect(() => {
        getSubQuestions(setSubQuestion);
        setShowSubQuestion(false);
    }, [showSubQuestion]);

    const onHideSuccessModal = () => {
        setShowSuccessAlert(false);
    }

    const addSubQuestionHandler = () => {
        handleAddSubQuestionModalOpen();
        setSelectedTaxonomyCategory();
        setSelectedTaxonomy();
        setConceptInputs();
        setSubConceptInputs();
        setQuestionType();
        setSubQuestionValue();
        setQuestionTypeDisabled(false);
    }

    const handleCreateQuestion = (type) => {
        if (type = "create") {
            setExplanation({ content: "" });
            setTitle("");
            setBoardAndGradeCombo([]);
            setBoardAndGrade([]);
            setClearContent(true);
            setSubQuestion([]);
            // sessionStorage.removeItem("parentId");
            setShowSuccessAlert(true);
            setUploadImage()
            setSuccessMessage(`You have successfully created Case Study with Sub Questions`);
            setDisabledFields(false);
            setSelectedImageName("");
            setSelectedImageUrl();
            props.setDisableTopFields(false);
        }
        else {
            setEdit(false);
            setExplanation({ content: "" });
            setTitle("");
            setBoardAndGradeCombo([]);
            setBoardAndGrade([]);
            setClearContent(true);
            setSubQuestion([]);
            // sessionStorage.removeItem("parentId");
            setShowSuccessAlert(true);
            setSuccessMessage(`You have successfully updated Case Study with Sub Questions`);
            setDisabledFields(false);
            setSelectedImageName("");
            setSelectedImageUrl();
            props.setDisableTopFields(false);
        }
    }

    const handleCreateSubQuestion = (e, type) => {
        e.preventDefault();
        let isError = false;
        // setEdit(false);
        let errorObj = {};
        if (disable) {
            isError = true;
            errorObj["createButton"] = "Please create the case study";
        }

        if (conceptInputs === "" || subConceptInputs === "" || questionType === "" || selectedTaxonomyCategory === "" || selectedTaxonomy === "") {
            isError = true;
            if (selectedTaxonomyCategory === "") {
                errorObj["taxonomycategory"] = "Taxonomy category field is required"
            }
            if (selectedTaxonomy === "") {
                errorObj["taxonomy"] = "Taxonomy field is required"
            }
            if (conceptInputs === "") {
                errorObj["concept"] = "Concept field is required"
            }
            if (subConceptInputs === "") {
                errorObj["subconcept"] = "Subconcept field is required"
            }
            if (questionType === "") {
                errorObj["questiontype"] = "Question type field is required"
            }
            // if (!(selectedSubjectiveType !== "Compound" || questionCode !== "CASE") && selectedTaxonomy === "") {
            //     // if (selectedTaxonomy === "") {
            //     errorObj["taxonomy"] = "Taxonomy field is required"
            // }
        }
        console.log("errorObj", errorObj)
        setErrorField(errorObj);

        if (!isError) {
            setErrorField({ id: "", message: "" });

            handleAddSubQuestionModalClose();

            queType.map(que => {
                if (que.id === questionType) {
                    setSubQuestionTypeChange(que.code);
                }
            })

            sessionStorage.setItem("subQuestionConceptId", conceptInputs);
            sessionStorage.setItem("subQuestionSubConceptId", subConceptInputs);
            sessionStorage.setItem("subQuestionType", questionType);
            sessionStorage.setItem("taxonomyCategory", selectedTaxonomyCategory);
            sessionStorage.setItem("taxonomy", selectedTaxonomy);

            setConceptInputs("");
            setSubConceptInputs("");
            setQuestionType("");
            setSelectedTaxonomyCategory("");
            setSelectedTaxonomy("");
            handleQuestionModalOpen();
        }
    };

    const handleUpdateSubQuestion = (id) => {
        subQuestion.map((subQues, index) => {
            if (subQues.id === id) {
                setSelectedTaxonomyCategory(subQues.taxonomyCategoryId);
                setSelectedTaxonomy(subQues.taxonomyId);
                setConceptInputs(subQues.conceptId);
                setSubConceptInputs(subQues.subConceptId);
                setQuestionType(subQues.questionTypeId);
                setSubQuestionValue(subQues);
                sessionStorage.setItem("parentId", subQues.parentQuestionId);
                setQuestionTypeDisabled(true);
            }
        })
        handleAddSubQuestionModalOpen();
        setEdit(true);
    }

    const handleEditQuestion = () => {
        get_question(responseValue.id, setQuestionValue);
        setEditButton(true);
        // let myObj = [];
        // setTitle(responseValue.question);
        // setExplanation(responseValue.explanation);
        // setDisable(false);
        // get_subQuestion(responseValue.id, setSubQuestion);
        // sessionStorage.setItem("parentId", responseValue.id);
        // setEdit(true);
        // responseValue.boardAndGrades.map((boardGrade, ind) => {
        //     let obj = {
        //         "boardId": boardGrade.boardId,
        //         "gradeId": boardGrade.gradeId
        //     };
        //     setBoardAndGrade(oldArray => [...oldArray, obj]);
        //     if (ind !== 0) {
        //         setErrorField(prevState => {
        //             delete prevState['combo'];
        //             return prevState;
        //         })
        //         const foundKey = Object.hasOwn(myObj, boardGrade.boardId);
        //         if (foundKey) {
        //             myObj[boardGrade.boardId].push(boardGrade.gradeId);
        //             setBoardAndGradeCombo(myObj);
        //             setBoardSelect('');
        //             setGradeSelect([]);
        //         }
        //         else {
        //             myObj[boardGrade.boardId] = [boardGrade.gradeId];
        //             setBoardAndGradeCombo(myObj);
        //             setBoardSelect('');
        //             setGradeSelect([]);
        //         }
        //     }
        //     else {
        //         myObj = { [boardGrade.boardId]: [] };
        //         myObj[boardGrade.boardId].push(boardGrade.gradeId);
        //         setBoardAndGradeCombo(myObj);
        //         setBoardSelect('');
        //         setGradeSelect([]);
        //     }
        // })
        // responseValue.options(opt => {
        //     const count = addQuestionCount[addQuestionCount.length - 1];
        //     setAddQuestionCount(oldArray => [...oldArray, (count) + 1]);
        // });
    }

    useEffect(() => {
        if (props.questionData) {
            setQuestionValue(props.questionData);
            // setResponseValue(props.questionData);
            // setEdit(false);
        }
        if (questionValue) {
            props.setDisableTopFields(true);
            let myObj = [];
            setEdit(true);
            setTitle(questionValue.question)
            setEditExplanation({ content: questionValue.explanation });
            setDisable(false);
            get_subQuestion(questionValue.id, setSubQuestion);
            setSelectedImageUrl(questionValue.imageUrl);
            setSelectedImageName(questionValue.imageUrl);
            setRadioButtonValue("repository");
            sessionStorage.setItem("case", questionValue.explanation);
            sessionStorage.setItem("caseTitle", questionValue.question);
            sessionStorage.setItem("parentId", questionValue.id);
            questionValue.boardAndGrades.map((boardGrade, ind) => {
                let obj = {
                    "boardId": boardGrade.boardId,
                    "gradeId": boardGrade.gradeId
                };
                setBoardAndGrade(oldArray => [...oldArray, obj]);
                if (ind !== 0) {
                    setErrorField(prevState => {
                        delete prevState['combo'];
                        return prevState;
                    })
                    const foundKey = Object.hasOwn(myObj, boardGrade.boardId);
                    if (foundKey) {
                        myObj[boardGrade.boardId].push(boardGrade.gradeId);
                        setBoardAndGradeCombo(myObj);
                        setBoardSelect('');
                        setGradeSelect([]);
                    }
                    else {
                        myObj[boardGrade.boardId] = [boardGrade.gradeId];
                        setBoardAndGradeCombo(myObj);
                        setBoardSelect('');
                        setGradeSelect([]);
                    }
                }
                else {
                    myObj = { [boardGrade.boardId]: [] };
                    myObj[boardGrade.boardId].push(boardGrade.gradeId);
                    setBoardAndGradeCombo(myObj);
                    setBoardSelect('');
                    setGradeSelect([]);
                }
            })
            // }
        }
    }, [props.questionData, questionValue, editButton]);

    const onHideModal = () => {
        setShowAlert(false);
    }

    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => {
        if (editExplanation.content !== "") {
            setShowEditor(true);
        }
    }, [editExplanation.content]);

    const handleCancelUpdate = () => {
        if (props.fromViewAllQuestion !== undefined) {
            navigate("/dashboard/View All Questions");
        }
        setExplanation({ content: "" });
        setTitle("");
        setBoardAndGradeCombo([]);
        setBoardAndGrade([]);
        setClearContent(true);
        setSubQuestion([]);
        sessionStorage.removeItem("parentId");
        setDisabledFields(false);
        setSelectedImageName("");
        setSelectedImageUrl();
        props.setDisableTopFields(false);
        setEdit(false);
        setShowEditor(false);
    }

    return (
        <>
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} md={12} lg={12}>
                    <CustomHead>Case Study</CustomHead>
                </Grid>
                <SelectBoardAndGrade
                    boardsList={boardsList}
                    gradeList={gradeList}
                    boardValue={boardSelect}
                    handleBoardChange={handleBoardChange}
                    gradeValue={gradeSelect}
                    handleGradeChange={handleGradeChange}
                    isError={errorFieldCombo}
                    isDisabled={disabledFields}
                />
                <Grid item xs={3} md={3} lg={3}>
                    <img
                        className={
                            dashboardContentStyles.aRoleActionBtn
                        }
                        src={action__Add}
                        onClick={handleBoardAndGradeChange}
                        alt="Add-Btn"
                        style={{ width: "18%", height: "auto", cursor: 'pointer' }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    {!!errorField['combo'] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['combo']}</Typography>}
                    {Object.keys(boardAndGradeCombo).length > 0 &&
                        <Grid container sx={{ border: "1px solid #B5B6B6", borderRadius: "4px", boxShadow: "0px 3px 6px #00000029" }}>
                            {Object.keys(boardAndGradeCombo).length > 0 ?
                                Object.keys(boardAndGradeCombo).map(key => {
                                    return (
                                        boardsList && boardsList.map(board => {
                                            return (
                                                board.id === key &&
                                                gradeList && gradeList.map(grade => {
                                                    return (
                                                        boardAndGradeCombo[key].map((value, i) => {
                                                            return (
                                                                value === grade.id &&
                                                                <Grid item xs={6} md={4} lg={4} key={key} sx={{ p: 1 }}>
                                                                    <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                                                                        {board.board}/{grade.grade}
                                                                        <CloseOutlinedIcon onClick={() => onRemoveCombo(board.id, grade.id)} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                                                                    </Typography>
                                                                </Grid>
                                                            )
                                                        })

                                                    )
                                                })
                                            )
                                        })
                                    )
                                })
                                :
                                null
                            }
                        </Grid>
                    }
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            boxShadow: 3,
                            borderRadius: 2,
                        }}
                    >
                        <input type="text" className={dashboardContentStyles.title} onChange={onTitleChange} placeholder="Title Name" value={title} disabled={disabledFields} />
                    </Box>
                    {!!errorField["title"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["title"]}</Typography>}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            boxShadow: 3,
                            borderRadius: 2,
                        }}
                    >
                        {showEditor && <TextEditor onEditorContentChange={(content) => onExplanationHandler(content)} name={"editExplanation"} initialValue={edit ? editExplanation.content : ""} clearContent={clearContent && clearContent} isDisabled={disabledFields} />}
                        {!edit && <TextEditor onEditorContentChange={(content) => onExplanationHandler(content)} name={"explanation"} clearContent={clearContent && clearContent} isDisabled={disabledFields} />}
                    </Box>
                    {!!errorField["case"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField['case']}</Typography>}
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} md={12} lg={12}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={radioButtonValue}
                            onChange={imageUploadHandler}
                            sx={{ display: 'flex', flexDirection: 'row' }}
                        >
                            <FormControlLabel disabled={radioButtonEnable} value="machine" control={<CustomRadio />} label="Upload from my machine" sx={{ fontSize: '1.5vw' }} />
                            <FormControlLabel disabled={radioButtonEnable} value="repository" control={<CustomRadio disabled={disabledFields} />} label="Use from repository" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {radioButtonValue === "machine" &&
                    <>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography sx={{ color: '#272727', fontSize: '1vw', fontWeight: '500', fontFamily: 'normal, normal, medium, Poppins' }}>Upload Image</Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography>
                                <input ref={fileInput} onChange={onFileChange} type="file" accept="image/*" style={{ display: 'none' }} disabled={radioButtonEnable} /><CustomButton onClick={() => fileInput.current.click()} disabled={disabledFields}><ImageOutlinedIcon fontSize="small" />Choose File</CustomButton>
                            </Typography>
                            {uploadImage &&
                                <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                                    {uploadImage && uploadImage.name}
                                    <CloseOutlinedIcon onClick={() => onRemoveImage("upload")} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                                </Typography>
                            }
                        </Grid>
                    </>
                }
                {radioButtonValue === "repository" &&
                    <Grid item xs={12} md={12} lg={12}>
                        {selectedImageName &&
                            <Typography sx={{ padding: '8px', display: 'flex', textAlign: 'left', color: '#000000', font: `normal normal normal 14px/21px Poppins`, fontSize: '0.7vw', background: '#A6C7FC', borderRadius: '20px', }}>
                                {selectedImageName && selectedImageName.split('/').pop()}
                                <CloseOutlinedIcon onClick={() => onRemoveImage("repository")} sx={{ fontSize: "1.3vw", color: '#FFFFFF', marginLeft: 'auto', cursor: 'pointer' }} />
                            </Typography>
                        }
                    </Grid>
                }
                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {edit ?
                        <FormControl sx={{ m: 1 }}>
                            <CustomButton onClick={() => handleCaseStudy("update")} disabled={disabledFields ? true : false}>Update Case Study</CustomButton>
                        </FormControl>
                        :
                        <FormControl sx={{ m: 1 }}>
                            <CustomButton onClick={() => handleCaseStudy("create")} disabled={disabledFields ? true : false}>Save Case Study</CustomButton>
                        </FormControl>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <hr />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomAddButton onClick={addSubQuestionHandler} disabled={edit ? disabledFields : !disabledFields}><img
                        className={
                            dashboardContentStyles.aRoleActionBtn
                        }
                        src={action__Add}
                        alt="Add-Btn"
                        style={{ width: "14%", height: "auto" }}
                    />Add Sub Question</CustomAddButton>
                </Grid>
                {subQuestion.length > 0 &&
                    <Grid item xs={12} md={12} lg={12}>
                        <CustomHead>Case Study Sub Questions</CustomHead>
                    </Grid>
                }
                {subQuestion.length > 0 && subQuestion.map((category, i) => {
                    return (
                        <>
                            <Grid container spacing={2} sx={{ p: 2 }}>
                                <Grid item xs={12} md={6} lg={3}>
                                    <FormControl sx={{ width: "19vw" }}>
                                        <CustomTextField label="Taxonomy Category" disabled value={category.taxonomyCategory} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <FormControl sx={{ width: "19vw" }}>
                                        <CustomTextField label="Taxonomy" disabled value={category.taxonomy} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <FormControl sx={{ width: "19vw" }}>
                                        <CustomTextField label="Concept" disabled value={category.concept} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <FormControl sx={{ width: "19vw" }}>
                                        <CustomTextField label="Sub-Concept" disabled value={category.subConcept} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <FormControl sx={{ width: "19vw" }}>
                                        <CustomTextField label="Question Type" disabled value={category.questionType} />
                                    </FormControl>
                                </Grid>
                                {edit &&
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl sx={{ ml: 1 }}>
                                            <button
                                                style={{ maxHeight: "50px" }}
                                                onClick={() => handleUpdateSubQuestion(category.id)}
                                                className={chapterStyles.blue_btn}
                                            >
                                                UPDATE
                                            </button>
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                        </>
                    )
                })
                }
                {subQuestion.length > 0 &&
                    <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <FormControl sx={{ ml: 1 }}>
                            <button
                                style={{ maxHeight: "50px" }}
                                onClick={handlePreviewModalOpen}
                                className={chapterStyles.blueWhite_btn}
                            >
                                {"PREVIEW"}
                            </button>
                        </FormControl>
                        {edit ?
                            <>
                                <FormControl sx={{ ml: 1 }}>
                                    <button
                                        style={{ maxHeight: "50px" }}
                                        onClick={handleCancelUpdate}
                                        className={chapterStyles.blue_btn}
                                    >
                                        CANCEL
                                    </button>
                                </FormControl>
                                <FormControl sx={{ ml: 1 }}>
                                    <button
                                        style={{ maxHeight: "50px" }}
                                        onClick={() => handleCreateQuestion("update")}
                                        className={chapterStyles.blue_btn}
                                    >
                                        UPDATE
                                    </button>
                                </FormControl>
                            </>
                            :
                            <FormControl sx={{ ml: 1 }}>
                                <button
                                    style={{ maxHeight: "50px" }}
                                    onClick={() => handleCreateQuestion("create")}
                                    className={chapterStyles.blue_btn}
                                >
                                    CREATE
                                </button>
                            </FormControl>
                        }
                    </Grid>
                }
            </Grid>
            {/* <Modal
                open={openQuestionModal}
                onClose={handleQuestionModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="boxModal">
                    <Grid container>

                        <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "space-between", m: 3 }}>
                            <CustomHead>Add Question</CustomHead>
                            <CloseOutlinedIcon onClick={handleQuestionModalClose} sx={{ fontSize: "2vw", color: '#707070', marginLeft: 'auto', cursor: 'pointer' }} />
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <hr />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} sx={{ m: 3 }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#354052", font: "normal normal 600 16px/25px Poppins" }}>
                                {caseStudyTitle}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2, color: "#354052", font: "normal normal normal 14px/21px Poppins" }}
                                dangerouslySetInnerHTML={{ __html: caseStudyContent }}
                            >
                            </Typography>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <hr />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                {subQuestionTypeChange == "FILL" ?
                                    <FillInTheBlanks caseStudy={true} questionData={edit ? subQuestionValue : ""} setOpenQuestionModal={setOpenQuestionModal} setGetSubQuestion={setShowSubQuestion} setSuccessMessage={setSuccessMessage} />
                                    : subQuestionTypeChange === "MCQ" ? <McqCreationQB caseStudy={true} questionData={edit ? subQuestionValue : ""} setOpenQuestionModal={setOpenQuestionModal} setGetSubQuestion={setShowSubQuestion} setSuccessMessage={setSuccessMessage} /> :
                                        // questionType === "CASE" ? <CreateCaseStudy /> :
                                        subQuestionTypeChange === "MSQ" ? <MultiSelectQB caseStudy={true} questionData={edit ? subQuestionValue : ""} setOpenQuestionModal={setOpenQuestionModal} setGetSubQuestion={setShowSubQuestion} setSuccessMessage={setSuccessMessage} /> :
                                            <>
                                            </>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal > */}
            {openQuestionModal &&
                <Box className="boxModal" ref={questionModalRef}>
                    <Grid container>

                        <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "space-between", m: 3 }}>
                            <CustomHead>Add Question</CustomHead>
                            <CloseOutlinedIcon onClick={handleQuestionModalClose} sx={{ fontSize: "2vw", color: '#707070', marginLeft: 'auto', cursor: 'pointer' }} />
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <hr />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} sx={{ m: 3 }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#354052", font: "normal normal 600 16px/25px Poppins" }}>
                                {caseStudyTitle}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2, color: "#354052", font: "normal normal normal 14px/21px Poppins" }}
                                dangerouslySetInnerHTML={{ __html: caseStudyContent }}
                            >
                            </Typography>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <hr />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                {subQuestionTypeChange == "FILL" ?
                                    <FillInTheBlanks caseStudy={true} questionData={edit ? subQuestionValue : ""} setOpenQuestionModal={setOpenQuestionModal} setGetSubQuestion={setShowSubQuestion} setSuccessMessage={setSuccessMessage} />
                                    : subQuestionTypeChange === "MCQ" ? <McqCreationQBCase caseStudy={true} questionData={edit ? subQuestionValue : ""} setOpenQuestionModal={setOpenQuestionModal} setGetSubQuestion={setShowSubQuestion} setSuccessMessage={setSuccessMessage} /> :
                                        // questionType === "CASE" ? <CreateCaseStudy /> :
                                        subQuestionTypeChange === "MSQ" ? <MultiSelectQBCase caseStudy={true} questionData={edit ? subQuestionValue : ""} setOpenQuestionModal={setOpenQuestionModal} setGetSubQuestion={setShowSubQuestion} setSuccessMessage={setSuccessMessage} /> :
                                            <>
                                            </>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            }

            <ImageListModal
                openImageModal={openImageListModal}
                closeImageModal={handleImageListModalClose}
                selectedImage={setSelectedImageUrl}
                selectedImageName={setSelectedImageName}
            />
            {/* <Modal
                open={openAddSubQuestionModal}
                onClose={handleAddSubQuestionModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={addSubQuestionStyle} className="boxModal">
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "space-between", m: 3 }}>
                            {edit ?
                                <CustomHead>Update Sub Question</CustomHead>
                                :
                                <CustomHead>Add Sub Question</CustomHead>
                            }
                            <CloseOutlinedIcon onClick={handleAddSubQuestionModalClose} sx={{ fontSize: "2vw", color: '#707070', marginLeft: 'auto', cursor: 'pointer' }} />
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <hr />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2} sx={{ p: 2 }}>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl sx={{ width: "19vw" }}>
                                            <InputLabel
                                                className={chapterStyles.select_input}
                                                id="demo-simple-select-required-label"
                                            >
                                                *Taxonomy Category
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="taxonomyCategory"
                                                value={selectedTaxonomyCategory}
                                                onChange={handlerTaxonomyCategory}
                                                input={<OutlinedInput label="Tag" />}
                                            >
                                                {taxonomyCategoryList.length > 0 ? (
                                                    taxonomyCategoryList.map((elem, i) => {
                                                        return (
                                                            <MenuItem key={i} value={elem.id}>
                                                                {elem.taxonomyCategory}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem>Select</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {!!errorField["taxonomycategory"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["taxonomycategory"]}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl sx={{ width: "19vw" }}>
                                            <InputLabel
                                                className={chapterStyles.select_input}
                                                id="demo-simple-select-required-label"
                                            >
                                                *Taxonomy
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="taxonomy"
                                                value={selectedTaxonomy}
                                                onChange={handlerTaxonomyInputs}
                                                input={<OutlinedInput label="Tag" />}
                                            >
                                                {taxonomyList.length > 0 ? (
                                                    taxonomyList.map((elem, i) => {
                                                        return (
                                                            <MenuItem key={i} value={elem.id}>
                                                                {elem.taxonomyName}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem>Select</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {!!errorField["taxonomy"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["taxonomy"]}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl sx={{ width: "19vw" }}>
                                            <InputLabel
                                                className={chapterStyles.select_input}
                                                id="demo-simple-select-required-label"
                                            >
                                                *Concept
                                            </InputLabel>
                                            <Select
                                                name="conceptId"
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                value={conceptInputs}
                                                onChange={handleConceptInputs}
                                                input={<OutlinedInput label="Tag" />}
                                            >
                                                {conceptlist && conceptlist.length > 0 ? (
                                                    conceptlist.map((elem, i) => {
                                                        return (
                                                            <MenuItem key={i} value={elem.id}>
                                                                {elem.concept}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem>Select</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {!!errorField["concept"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["concept"]}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl sx={{ width: "19vw" }}>
                                            <InputLabel
                                                className={chapterStyles.select_input}
                                                id="demo-simple-select-required-label"
                                            >
                                                *Sub-Concept
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="subConceptId"
                                                value={subConceptInputs}
                                                onChange={handlesubConceptInputs}
                                                input={<OutlinedInput label="Tag" />}
                                            >
                                                {subconceptList && subconceptList.length > 0 ? (
                                                    subconceptList.map((elem, i) => {
                                                        return (
                                                            <MenuItem key={i} value={elem.id}>
                                                                {elem.subConcept}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem>Select</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {!!errorField["subconcept"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["subconcept"]}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl sx={{ width: "19vw" }}>
                                            <InputLabel
                                                className={chapterStyles.select_input}
                                                id="demo-simple-select-required-label"
                                            >
                                                *Question Type
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="questionType"
                                                value={questionType}
                                                onChange={handleQuestionType}
                                                input={<OutlinedInput label="Tag" />}
                                                disabled={edit && questionTypeDisabled}
                                            >
                                                {queTypeList.length > 0 ? (
                                                    queTypeList.map((elem, i) => {
                                                        return (
                                                            <MenuItem key={i} value={elem.id}>
                                                                {elem.questionType}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem>SELECT</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {!!errorField["questiontype"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["questiontype"]}</Typography>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <hr />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} display="flex" justifyContent="center" m={4}>
                            {edit ?
                                <FormControl sx={{ width: "19vw", alignItems: "centre" }}>
                                    <button
                                        style={{ maxHeight: "50px" }}
                                        onClick={(e) => handleCreateSubQuestion(e, "update")}
                                        className={chapterStyles.blue_btn}
                                    // disabled={disable ? true : false}
                                    >
                                        Update Sub Question
                                    </button>
                                    {!!errorField["createButton"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["createButton"]}</Typography>}
                                </FormControl>
                                :
                                <FormControl sx={{ width: "19vw", alignItems: "centre" }}>
                                    <button
                                        style={{ maxHeight: "50px" }}
                                        onClick={(e) => handleCreateSubQuestion(e, "create")}
                                        className={chapterStyles.blue_btn}
                                    // disabled={disable ? true : false}
                                    >
                                        Add Sub Question
                                    </button>
                                    {!!errorField["createButton"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["createButton"]}</Typography>}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Modal > */}

            {openAddSubQuestionModal &&
                <Box className="boxModal" ref={addSubQuestionModalRef}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "space-between", m: 3 }}>
                            {edit ?
                                <CustomHead>Update Sub Question</CustomHead>
                                :
                                <CustomHead>Add Sub Question</CustomHead>
                            }
                            <CloseOutlinedIcon onClick={handleAddSubQuestionModalClose} sx={{ fontSize: "2vw", color: '#707070', marginLeft: 'auto', cursor: 'pointer' }} />
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <hr />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2} sx={{ p: 2 }}>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl sx={{ width: "19vw" }}>
                                            <InputLabel
                                                className={chapterStyles.select_input}
                                                id="demo-simple-select-required-label"
                                            >
                                                *Taxonomy Category
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="taxonomyCategory"
                                                value={selectedTaxonomyCategory}
                                                onChange={handlerTaxonomyCategory}
                                                input={<OutlinedInput label="Tag" />}
                                            >
                                                {taxonomyCategoryList.length > 0 ? (
                                                    taxonomyCategoryList.map((elem, i) => {
                                                        return (
                                                            <MenuItem key={i} value={elem.id}>
                                                                {elem.taxonomyCategory}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem>Select</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {!!errorField["taxonomycategory"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["taxonomycategory"]}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl sx={{ width: "19vw" }}>
                                            <InputLabel
                                                className={chapterStyles.select_input}
                                                id="demo-simple-select-required-label"
                                            >
                                                *Taxonomy
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="taxonomy"
                                                value={selectedTaxonomy}
                                                onChange={handlerTaxonomyInputs}
                                                input={<OutlinedInput label="Tag" />}
                                            >
                                                {taxonomyList.length > 0 ? (
                                                    taxonomyList.map((elem, i) => {
                                                        return (
                                                            <MenuItem key={i} value={elem.id}>
                                                                {elem.taxonomyName}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem>Select</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {!!errorField["taxonomy"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["taxonomy"]}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl sx={{ width: "19vw" }}>
                                            <InputLabel
                                                className={chapterStyles.select_input}
                                                id="demo-simple-select-required-label"
                                            >
                                                *Concept
                                            </InputLabel>
                                            <Select
                                                name="conceptId"
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                value={conceptInputs}
                                                onChange={handleConceptInputs}
                                                input={<OutlinedInput label="Tag" />}
                                            // renderValue={(selected) => selected.join(", ")}
                                            >
                                                {/* {optionsRender(board)} */}
                                                {conceptlist && conceptlist.length > 0 ? (
                                                    conceptlist.map((elem, i) => {
                                                        return (
                                                            <MenuItem key={i} value={elem.id}>
                                                                {elem.concept}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem>Select</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {!!errorField["concept"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["concept"]}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl sx={{ width: "19vw" }}>
                                            <InputLabel
                                                className={chapterStyles.select_input}
                                                id="demo-simple-select-required-label"
                                            >
                                                *Sub-Concept
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="subConceptId"
                                                value={subConceptInputs}
                                                onChange={handlesubConceptInputs}
                                                input={<OutlinedInput label="Tag" />}
                                            >
                                                {subconceptList && subconceptList.length > 0 ? (
                                                    subconceptList.map((elem, i) => {
                                                        return (
                                                            <MenuItem key={i} value={elem.id}>
                                                                {elem.subConcept}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem>Select</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {!!errorField["subconcept"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["subconcept"]}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <FormControl sx={{ width: "19vw" }}>
                                            <InputLabel
                                                className={chapterStyles.select_input}
                                                id="demo-simple-select-required-label"
                                            >
                                                *Question Type
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                name="questionType"
                                                value={questionType}
                                                onChange={handleQuestionType}
                                                input={<OutlinedInput label="Tag" />}
                                                disabled={edit && questionTypeDisabled}
                                            >
                                                {queTypeList.length > 0 ? (
                                                    queTypeList.map((elem, i) => {
                                                        return (
                                                            <MenuItem key={i} value={elem.id}>
                                                                {elem.questionType}
                                                            </MenuItem>
                                                        );
                                                    })
                                                ) : (
                                                    <MenuItem>SELECT</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                        {!!errorField["questiontype"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["questiontype"]}</Typography>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={12}>
                                <hr />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} display="flex" justifyContent="center" m={4}>
                            {edit ?
                                <FormControl sx={{ width: "19vw", alignItems: "centre" }}>
                                    <button
                                        style={{ maxHeight: "50px" }}
                                        onClick={(e) => handleCreateSubQuestion(e, "update")}
                                        className={chapterStyles.blue_btn}
                                    // disabled={disable ? true : false}
                                    >
                                        Update Sub Question
                                    </button>
                                    {!!errorField["createButton"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["createButton"]}</Typography>}
                                </FormControl>
                                :
                                <FormControl sx={{ width: "19vw", alignItems: "centre" }}>
                                    <button
                                        style={{ maxHeight: "50px" }}
                                        onClick={(e) => { handleCreateSubQuestion(e, "create"); setOpenAddSubQuestionModal(false); }}
                                        className={chapterStyles.blue_btn}
                                    // disabled={disable ? true : false}
                                    >
                                        Add Sub Question
                                    </button>
                                    {!!errorField["createButton"] && <Typography sx={{ color: "orange", fontSize: '15px' }}>{errorField["createButton"]}</Typography>}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                </Box>
            }
            <SubQuestionPreviewScroll
                openSubQuestionPreview={openSubQuestionPreviewModal}
                closeSubQuestionPreview={handlePreviewModalClose}
                imagePreview={imagePreview}
                selectedImageUrl={selectedImageUrl}
                isCaseStudyReset={clearContent}
                previewFromModal={previewFromModal}
                setPreviewFromModal={setPreviewFromModal}
            />
            <DeleteBlanksModal
                open={showAlert}
                close={onHideModal}
                alert={alertMessage}
                caseStudy={true}
            />
            <DeleteConfirmationAlert
                open={showSuccessAlert}
                close={onHideSuccessModal}
                alert={successMessage}
                caseStudy={true}
                openPreview={handlePreviewModalOpen}
                isEditQuestion={handleEditQuestion}
                setPreviewFromModal={setPreviewFromModal}
                setNewConcept={props.setNewConcept}
                setGoToQuestionBank={props.setGoToQuestionBank}
                setDisableTopFields={props.setDisableTopFields}
                onBackdropClickable={true}
            />
        </>
    )
}