import axios from "axios";

function student_file_post(
  setSelectedImage,
  formData,
  setStudentInputs,
  studentInputs,
  setImageUploadError
) {
  let token = sessionStorage.getItem("token");

  let result = axios
    .post(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=STUDENT`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      // console.log(res.data,"888");
      setSelectedImage(res.data.data);
      sessionStorage.setItem("contentUrl", res.data.data);
      setStudentInputs({
        ...studentInputs,
        contentUrl: res.data.data,
      });
      setImageUploadError(null);
    })
    .catch((error) => {
      console.log(error.response.data.message);
      console.log(error.response.data.errorCode);
      if (error?.response?.data?.errorCode == 409) {
        setStudentInputs({
          ...studentInputs,
          contentUrl: null,
        });
        setImageUploadError(error.response.data.message+"Please delete and try again");
      } else {
        setStudentInputs({
          ...studentInputs,
          contentUrl: null,
        });
        setImageUploadError("Unable To Upload File");
      }
    });
}

export default student_file_post;
