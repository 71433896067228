import React, { useState, useEffect } from "react";
import axios from "axios";
import "./../../App.css";
import TopRightPopup from "../../components/common/TopRightPopup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import studentFilesStyles from "./../css/studentFiles.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import createschool from "./../css/CreateSchool.module.css";
// take this css copy in original file
import Pagination1 from "./pagination1";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import document_category_get from "../API_CONTROLLER/document-category-controller/document_category_get";
import student_upload_chapter_get from "../API_CONTROLLER/student-upload-content-controller/student_upload_chapter_get";
import student_content_post from "../API_CONTROLLER/student-upload-content-controller/student_content_post";
import student_file_post from "../API_CONTROLLER/student-upload-content-controller/student_file_post";
import chapters_list_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_getall";
import student_contentType_getall from "../API_CONTROLLER/student-upload-content-controller/student_contentType_getall";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CommonDeleteModal from "./CommonDeleteModal";
import RejectedModal from "./RejectedModal";
import ApprovalModal from "./ApprovalModal";
import ContentRejectedModal from "./ContentRejectedModal";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import dashHome from "../../../src/img/dashHome.svg";
import action__Delete from "../../../src/img/Delete.svg";
import teacher_paginated_content_get from "../API_CONTROLLER/teacher-upload-content-controller/teacher_paginated_content_get";
import {
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import fileApprovalStyles from "../css/fileApproval.module.css";
import { styled } from '@mui/material/styles';
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { handleRightClick } from '../../utils/AppUtility';
import ContentViewer from '../../components/common/ContentViewer';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  border: "2px solid blue",
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'white',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto blue',
    outlineOffset: 2,
  },


}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'blue',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },

});
const StudentFileApproval = (props) => {
  const [chapters, setChapters] = useState({});
  const [show, setShow] = useState(false);
  const token = sessionStorage.getItem("token");
  const [docCategory, setDocCategory] = useState({});
  const [selectedItem, setSelectedItem] = useState("");
  const [updatedata, setUpdatedata] = useState({});
  const [filterval, setFilterval] = useState({});
  const [chaptersList, setChaptersList] = useState({});
  const [contenttype, setContenttype] = useState({});
  const [modifiedDate, setModifiedDate] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [approveModel, setapproveModal] = useState(false);
  const [rejectmsdModal, setrejectmsgModal] = useState(false);
  const [rejecteModel, setrejecteModal] = useState(false);
  const [rejectComment, setrejecetComment] = useState("");
  const [message, setMessage] = useState("");
  const [mess, setMess] = useState("");

  const role = localStorage.getItem("role");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isLaptopScreen = windowWidth >= 1024 && windowWidth < 1440;

  const headingStyles = {
    width: isLaptopScreen ? '200%' : '249%',
    height: isLaptopScreen ? '630px' : '630px',
  };
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const rejecteModal = () => {
    setMess("Content Rejected");
    setrejecteModal(true);
  };
  const closerejectmodel = () => {
    setrejecteModal(false);
    setrejecetComment("");
    setError(false);
  };
  const [error, setError] = useState(false);
  const rejecte = () => {
    if (rejectComment.length == 0) {
      setError(true);
      return;
    }
    const value = {
      chapterId: filterval[0]?.chapterId,
      reason: rejectComment,
      reviewStatus: "REJECTED",
      uploadContents: ids,
    };
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/student-chapter/review-status`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        closerejectmodel();
        setMessage(response.data.message);
        setrejectmsgModal(true);
        setIds([]);
      })
      .catch((err) => console.log(err));
  };
  const closeModal = () => {
    setrejectmsgModal(false);
    student_upload_chapter_get(setUpdatedata);
  };

  const [ids, setIds] = useState([]);
  const handleChapterlist = (event, elem) => {
    if (event.target.checked) {
      setIds([...ids, elem?.id]);
    } else {
      const temp_id = ids.filter((v) => v != elem?.id);
      setIds(temp_id);
    }
  };
  const handleallChapterlist = (event) => {
    if (event.target.checked) {
      var temp = filterval[0]?.studyDocMaterials?.reduce((current, next) => {
        var t = next.studentStudyContent.map((v) => v?.id);
        return [...current, ...t];
      }, []);
      var temptwo = filterval[0]?.revisionDocMaterials?.reduce(
        (current, next) => {
          var t = next.studentRevisionContent.map((v) => v?.id);
          return [...current, ...t];
        },
        []
      );
      temp = temp ?? [];
      temptwo = temptwo ?? [];
      setIds([...temp, ...temptwo]);
    } else {
      setIds([]);
    }
  };

  const approvefiles = () => {
    const value = {
      chapterId: filterval[0]?.chapterId,
      reviewStatus: "APPROVED",
      uploadContents: ids,
    };
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/student-chapter/review-status`,
        value,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        //student_upload_chapter_get(setUpdatedata);
        setMessage(response.data.message);
        publishContentCDN();
        setTimeout(() => {
          setapproveModal(true);
        }, 700);
        setIds([]);
      })
      .catch((err) => console.log(err));
  };

  /// CDN publish call
  const publishContentCDN = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/file/entity/upload-entity-json-s3?entityName=contentdata`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //alert(response);
    console.log("publishContentCDN >>> ", response);
  }

  const closeapproval = () => {
    setapproveModal(false);
    student_upload_chapter_get(setUpdatedata);
  };
  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/student-chapter/${selectedItem?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setUrl(null);
        student_upload_chapter_get(setUpdatedata);
      })
      .catch((err) => console.log(err));

    hideDeleteModal();
  };

  const showDeleteModal = (row) => {
    setSelectedItem(row);
    setOpenDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };
  const [chapterName, setChapterName] = useState("");
  useEffect(() => {
    console.log("updatedata", updatedata)
    setFilterval(updatedata);
    setChapterName(updatedata[0]?.chapterName);
  }, [updatedata]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 1500);
  }, [show]);
  const [url, setUrl] = useState();
  const openIframe = (contentUrl) => {
    if (
      contentUrl.includes(".xlsx") ||
      contentUrl.includes(".pptx") ||
      contentUrl.includes(".doc") ||
      contentUrl.includes(".ppt") ||
      contentUrl.includes(".docx")
    ) {
      setUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${contentUrl}`
      );
    } else {
      setUrl(contentUrl);
    }
  };
  const [uptdata, setUptDta] = useState([]);
  const [isDisabled, setDisabled] = useState();
  useEffect(() => {
    teacher_paginated_content_get(setUptDta);
    return () => { };
  }, [sessionStorage.getItem("chapterId")]);

  useEffect(() => {
    if (uptdata.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [uptdata]);

  useEffect(() => {
    // chapters_controller_getall(setChapters);
    document_category_get(setDocCategory);
    student_upload_chapter_get(setUpdatedata);
    chapters_list_getall(setChaptersList);
    student_contentType_getall(setContenttype);
  }, []);

  return (
    <>
      <div style={{ width: "100%" }} onContextMenu={handleRightClick}>
        <article>
          {/* <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>{" "}
            <span className={dashboardContentStyles.link_text}>
              Content Approval
            </span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <a>Student Files</a>
            {"  "}
          </div> */}

          <div className={dashboardContentStyles.dashboard_link}>

            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={`${dashboardContentStyles.az_menu_icon} cursorPointer`}
              />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate("/dashboard/template")}
            >
              Home
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span className={dashboardContentStyles.az_menu_text}>
              Content Approval
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_selected_menu_text}
            >
              Student Files
            </span>
          </div>

          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {modifiedDate.data}</p>
          </div>
        </article>
        <div
          style={{ marginTop: "65px", position: "relative" }}
          className={fileApprovalStyles.flex}
        >
          <div
            id={`az-content-backarrow`}
            className={fileApprovalStyles.left_row}>
            <i
              onClick={(e) => {
                if (
                  ((filterval[0]?.studyDocMaterials || []).length > 0 ||
                    (filterval[0]?.revisionDocMaterials || []).length > 0) &&
                  sessionStorage.getItem("isStatus") == "true"
                ) {
                  navigate(
                    `/dashboard/Content%20Approval?chapterId=${updatedata[0].chapterId}`
                  );
                } else if (searchParams.get("chapterId")) {
                  navigate(
                    `/dashboard/Content%20Approval?chapterId=${searchParams.get(
                      "chapterId"
                    )}`
                  );
                } else {
                  navigate("/dashboard/Content%20Approval");
                }
              }}
              class="fa-solid fa-arrow-left cursorPointer"
            ></i>
            <i
              style={{ color: "grey", fontSize: "24px", margin: "10px" }}
              class="fa-solid fa-user-gear"
            ></i>
            <p>Content Pending</p>
          </div>
          <div style={{ position: "absolute", right: "15px" }}>
            <button
              id={`az-content-student-button`}
              style={{
                color: "white",
                backgroundColor: "#FD8C00",
                cursor: "pointer",
              }}
              className={fileApprovalStyles.orange_btn}
            >
              Student
            </button>
            <button
              id={`az-content-teacher-button`}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                navigate("/dashboard/TeacherFileApproval");
                sessionStorage.setItem("status", "PENDING");
              }}
              className={
                !isDisabled
                  ? fileApprovalStyles.orange_btn
                  : fileApprovalStyles.orange_btn_disabled_grey
              }
              disabled={isDisabled}
            >
              Teacher
            </button>
          </div>
        </div>

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            marginTop: "75px",
            marginLeft: "15px",
          }}
          //style={{ display: "flex" }}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            // style={{
            //   // minWidth: '1166px',
            //   // width: "70%",
            //   height: "667px",
            //   backgroundColor: "white",
            // }}
            style={{ flexGrow: "1", backgroundColor: "white" }}
          >
            <Table
              style={{
                width: "32%",
              }}
            >
              <TableHead style={{ backgroundColor: "#d9dcfe", height: "55px" }}>
                <TableRow>
                  <TableCell
                    className={studentFilesStyles.table_head}
                    align="left"
                    style={{ margin: "8px 8px" }}
                  >
                    {updatedata?.length > 0 && role !== "CONTENT_ADMIN" ? (
                      <Checkbox
                        id={`az-content-checkbox-button`}
                        sx={{
                          '&:hover': { bgcolor: 'transparent' },
                          right: "10px",
                          marginBottom: "20px"
                        }}
                        disableRipple
                        color="default"
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        inputProps={{ 'aria-label': 'Checkbox demo' }}
                        {...props}
                        onChange={handleallChapterlist}
                      />

                    ) : null}
                    <Tooltip title={chapterName} arrow>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '300px',
                          display: 'inline-block',
                          cursor: 'pointer',
                          marginLeft: '-13px', // Adjust the margin as needed
                        }}
                      >
                        {chapterName}
                      </span>
                    </Tooltip>

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* <div style={{ display: "flex" }}> */}
                <div style={{ display: "flex", flexDirection: "column", height: "667px" }}>
                  <div
                    style={{
                      //width: "100%",
                      //overflowY: "scroll",
                      //height: "560px",
                      borderRight: "1px solid lightgrey",
                      //position: "relative",
                      overflowY: "auto",
                      flexGrow: 1,
                    }}
                  >
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ backgroundColor: "#92A2E8" }}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{ color: "white" }}>
                          STUDY MATERIAL -{" "}
                          {filterval[0]?.studyDocWithCount
                            ?.match(/\d/g)
                            .toString()
                            .replaceAll(",", "")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                      >
                        {filterval[0]?.studyDocMaterials &&
                          filterval[0]?.studyDocMaterials.length > 0 ? (
                          filterval[0]?.studyDocMaterials.map((item) => {
                            return (
                              <Accordion>
                                <AccordionSummary
                                  style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                  }}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {item.documentType} -{" "}
                                    {item.studentStudyContent?.length}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                >
                                  {item.studentStudyContent &&
                                    item.studentStudyContent.length > 0 ? (
                                    item.studentStudyContent.map((elem) => {
                                      return (
                                        <Accordion>
                                          <AccordionSummary
                                            style={{
                                              width: "100%",
                                              position: "relative",
                                            }}
                                          // expandIcon={<ExpandMoreIcon />}
                                          >
                                            <div
                                              style={{
                                                marginTop: "-13px",
                                                width: "332px",
                                                whiteSpace: "nowrap",
                                                overflow: "auto",
                                              }}
                                            >
                                              {role !== "CONTENT_ADMIN" ? (
                                                <Checkbox
                                                  id={`az-content-checkbox-button-${elem?.id}`}
                                                  style={{
                                                    color: "#fb9d2a",
                                                    width: "10px",
                                                    position: "absolute",
                                                    left: "8px",
                                                    top: "2px",
                                                  }}
                                                  onChange={(event) =>
                                                    handleChapterlist(event, elem)
                                                  }
                                                  onClick={(event) => event.stopPropagation()}
                                                  checked={
                                                    ids.includes(elem?.id)
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              ) : null}
                                              <Tooltip
                                                title={elem.contentWithIndux.length > 10 ? elem.contentWithIndux : null}
                                              >
                                                <a
                                                  // href={elem.contentUrl}
                                                  // download={elem.contentWithIndux}
                                                  onClick={(event) => {
                                                    event.stopPropagation(); {/* Stop event propagation */ }
                                                    openIframe(elem?.contentUrl);
                                                  }}
                                                  style={{
                                                    textDecoration: "none",
                                                    fontSize: "1vw",
                                                    color: "grey",
                                                    left: "80px",
                                                    position: "absolute",
                                                    width: "332px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "auto",
                                                    textOverflow: "ellipsis",
                                                    // word wrap style (...)
                                                    inlineSize: "200px",
                                                    overflowWrap: "break-word",
                                                    hyphens: "manual",
                                                  }}
                                                  data-toggle="tooltip"
                                                >
                                                  {elem.contentWithIndux.length > 20
                                                    ? elem.contentWithIndux.slice(
                                                      0,
                                                      15
                                                    ) + "...."
                                                    : elem.contentWithIndux}
                                                </a>
                                              </Tooltip>
                                              <span
                                                style={{
                                                  position: "absolute",
                                                  left: "334px",
                                                  marginTop: "-7px",
                                                }}
                                              >
                                                <DeleteIconButton
                                                  onClick={() => {
                                                    showDeleteModal(true);
                                                    setSelectedItem(elem);
                                                  }}
                                                >
                                                  <Tooltip
                                                    title="Remove"
                                                    placement={"top"}
                                                  >
                                                    <img
                                                      className={
                                                        dashboardContentStyles.aRoleActionBtn
                                                      }
                                                      src={action__Delete}
                                                      alt="Dlt-Btn"
                                                    />
                                                  </Tooltip>
                                                </DeleteIconButton>
                                              </span>
                                              {/* <i style={{position:"absolute",right:"10px"}} class="bi bi-download"></i> */}
                                            </div>
                                          </AccordionSummary>
                                        </Accordion>
                                      );
                                    })
                                  ) : (
                                    <p>-</p>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            );
                          })
                        ) : (
                          <p>-</p>
                        )}
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        style={{ backgroundColor: "#92A2E8" }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{ color: "white" }}>
                          REVISION MATERIAL -{" "}
                          {filterval[0]?.revisionDocWithCount
                            ?.match(/\d/g)
                            .toString()
                            .replaceAll(",", "")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {filterval[0]?.revisionDocMaterials &&
                          filterval[0]?.revisionDocMaterials.length > 0 ? (
                          filterval[0]?.revisionDocMaterials.map((item) => {
                            return (
                              <Accordion>
                                <AccordionSummary
                                  style={{
                                    backgroundColor: "white",
                                    width: "100%",
                                  }}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography
                                    style={{
                                      color: "black",
                                      fontWeight: "600",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {item.documentType} -{" "}
                                    {item.studentRevisionContent?.length}{" "}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                >
                                  {item.studentRevisionContent &&
                                    item.studentRevisionContent.length > 0 ? (
                                    item.studentRevisionContent.map((elem) => {
                                      return (
                                        <Accordion>
                                          <AccordionSummary
                                            style={{
                                              width: "100%",
                                              position: "relative",
                                            }}
                                          // expandIcon={<ExpandMoreIcon />}
                                          >
                                            <div
                                              style={{
                                                marginTop: "-13px",
                                                width: "332px",
                                                whiteSpace: "nowrap",
                                                overflow: "auto",
                                              }}
                                            >
                                              {role !== "CONTENT_ADMIN" ? (
                                                <Checkbox
                                                  id={`az-content-checkbox-button-${elem?.id}`}
                                                  style={{
                                                    color: "#fb9d2a",
                                                    width: "10px",
                                                    position: "absolute",
                                                    left: "8px",
                                                    top: "2px",
                                                  }}
                                                  onChange={(event) =>
                                                    handleChapterlist(event, elem)
                                                  }
                                                  onClick={(event) => event.stopPropagation()}
                                                  checked={
                                                    ids.includes(elem?.id)
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              ) : null}
                                              <Tooltip
                                                title={elem.contentWithIndux.length > 20 ? elem.contentWithIndux : null}
                                              >
                                                <a
                                                  // href={elem.contentUrl}
                                                  // download={elem.contentWithIndux}
                                                  onClick={(event) => {
                                                    event.stopPropagation(); {/* Stop event propagation */ }
                                                    openIframe(elem?.contentUrl);
                                                  }}
                                                  style={{
                                                    textDecoration: "none",
                                                    fontSize: "1vw",
                                                    color: "grey",
                                                    left: "80px",
                                                    position: "absolute",
                                                    width: "332px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "auto",
                                                    textOverflow: "ellipsis",
                                                    // word wrap style (...)
                                                    inlineSize: "200px",
                                                    overflowWrap: "break-word",
                                                    hyphens: "manual",
                                                  }}
                                                  data-toggle="tooltip"
                                                >
                                                  {elem.contentWithIndux.length > 20
                                                    ? elem.contentWithIndux.slice(
                                                      0,
                                                      15
                                                    ) + "...."
                                                    : elem.contentWithIndux}
                                                </a>
                                              </Tooltip>
                                              <span
                                                style={{
                                                  position: "absolute",
                                                  left: "334px",
                                                  marginTop: "-7px",
                                                }}
                                              >
                                                <DeleteIconButton
                                                  onClick={() => {
                                                    showDeleteModal(true);
                                                    setSelectedItem(elem);
                                                  }}
                                                >
                                                  <Tooltip
                                                    title="Remove"
                                                    placement={"top"}
                                                  >
                                                    <DeleteOutlinedIcon
                                                      style={{
                                                        fontSize: "1.7vw",
                                                      }}
                                                    />
                                                  </Tooltip>
                                                </DeleteIconButton>
                                              </span>
                                              {/* <i style={{position:"absolute",right:"10px"}} class="bi bi-download"></i> */}
                                            </div>
                                          </AccordionSummary>
                                        </Accordion>
                                      );
                                    })
                                  ) : (
                                    <p>-</p>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            );
                          })
                        ) : (
                          <p>-</p>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {role !== "CONTENT_ADMIN" ? (
                    <div
                      // style={{
                      //   position: "absolute",
                      //   left: "60px",
                      //   bottom: "-120px",
                      // }}

                      style={{
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      {updatedata?.length > 0 ? (
                        <>
                          <button
                            id={`az-content-reject-button`}
                            className={fileApprovalStyles.orange_btn}
                            onClick={(e) => {
                              rejecteModal();
                            }}
                          >
                            REJECT
                          </button>
                          <button
                            id={`az-content-approve-button`}
                            style={{
                              color: "white",
                              backgroundColor: "#FD8C00",
                            }}
                            className={fileApprovalStyles.orange_btn}
                            onClick={(e) => {
                              approvefiles();
                            }}
                          >
                            APPROVE
                          </button>
                        </>
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </TableBody>
            </Table>
          </div>
          <div onContextMenu={handleRightClick}>
            {url && (
              <ContentViewer contentstyle={{ height: "700px", width: "750px" }}
                url={url} />)}

            {/* {url && (
              // <Worker 
              //   workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <div
                  style={{ height: "700px", width: "770px" }}>
                  <Viewer
                    fileUrl={'https://cors-anywhere.herokuapp.com/' + url}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </div>
              // </Worker>


              // <iframe
              //   src={url}
              //  style={headingStyles}
              // ></iframe>
            )} */}
          </div>
        </Paper>

        {/* content end */}
        <ApprovalModal
          open={approveModel}
          message={message}
          close={closeapproval}
        />
        <ContentRejectedModal
          open={rejectmsdModal}
          message={message}
          close={closeModal}
        />
        <RejectedModal
          open={rejecteModel}
          close={closerejectmodel}
          rejecte={rejecte}
          error={error}
          message={mess}
        >
          <TextField
            label="Reason"
            rows={5}
            multiline
            style={{ width: 450 }}
            value={rejectComment}
            onChange={(event) => setrejecetComment(event.target.value)}
          />
        </RejectedModal>
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          // deleteTitle={"this"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={selectedItem?.contentWithIndux}
        />
        <div
          className="box animate fadeInUp one"
          style={
            show
              ? {
                color: "red",
                position: "absolute",
                right: "10px",
                top: "10px",
              }
              : { display: "none" }
          }
        >
          <TopRightPopup
            style={{ color: "red", position: "absolute", right: "10px" }}
          />
        </div>
      </div>
    </>
  );
};

export default StudentFileApproval;