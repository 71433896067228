import axios from 'axios'
async function Student_update_Id_put(studentInputs,num, setSuccess) {
  let token = sessionStorage.getItem("token");
  let item = {
    lmsEnv: process.env.REACT_APP_LMS_ENV,
    active: true,
    address: studentInputs.address,
    admissionDate: studentInputs.admissionDate,
    branch: studentInputs.branchId,
    dob: studentInputs.dob,
    email: studentInputs?.email ? studentInputs?.email?.toLowerCase() : "",
    firstLanguageId: studentInputs.firstLanguageId,
    firstName: studentInputs.firstName,
    gender: studentInputs.gender,
    gradeId: studentInputs.gradeId,
    isPromoted: studentInputs.isPromoted,
    lastName: studentInputs.lastName,
    mobile: num,
    school: studentInputs.schoolId,
    secondLanguageId: studentInputs.secondLanguageId,
    sectionId: studentInputs.sectionId,
    studentCategoryId: studentInputs.studentCategoryId,
    // token: studentInputs.token,
    yearEndProcess: true,
  };
    let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students/${sessionStorage.getItem("studentid")}`,item,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setSuccess(json)
    })
    .catch(err=>console.log(err))
}

export default Student_update_Id_put;
