import axios from 'axios'

async function City_Controller_get(
  setCityData,
  rowsInput,
  page,
  setPageDetail,
  search
) {
  let token = sessionStorage.getItem("token");
  
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities?search=${
      search.searchName
    }&pageNumber=${page - 1}&pageSize=${rowsInput.rows}&sortBy=id`,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setCityData(json.data.data.data)
       setPageDetail(json.data.data)
    })
    .catch(err=>console.log(err))
}

export default City_Controller_get;
