import axios from 'axios'

async function trainingaid_get(setTrainingaidList) {
  let token = sessionStorage.getItem("token");
   

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training-aids`, 
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      if(json.data.data){
        setTrainingaidList(json.data.data)
      }
    })
    .catch(err=>console.log(err))
}

export default trainingaid_get;
