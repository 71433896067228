import axios from "axios";

async function Coordinator_Type_Get(setCoordinatorType) {
  let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/misc/coordinator-type`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp)=>{
      // console.log(resp)
       setCoordinatorType(resp?.data?.data);
    })
    .catch((err) => console.log(err));
}

export default Coordinator_Type_Get;
