import React, { useState } from 'react'
// import MainContainer from '../dashboard/MainContainer'
// import Navbar from '../../Navbar'
import SubjectivePapers from '../js/SubjectivePapers'
import MainContainer from '../js/MainContainer'
import Navbar from '../js/Navbar'

const CordinatorSubjectivePapers = () => {
  const [headerTitle, setHeaderTitle] = useState('')
  const handleHeaderTitle = value => {
    setHeaderTitle(value)
  }

  return (
    <>
      <Navbar handleHeaderTitle={value => handleHeaderTitle(value)} />

      <MainContainer
        // children={<div></div>} 
        children={<SubjectivePapers />}
        headerTitle={headerTitle}
      />
    </>
  )
}

export default CordinatorSubjectivePapers