import { isNOTNullOrUndefined } from "../../../../../utils/helper";

export const listItemStyle = {
  "& .MuiListItem-container": {
    color: "white",
    background: "red !important",
  },
  height: "56px",
  border: "1px solid #FFE2B8",
  marginTop: "8px",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "15px",
  color: "#354052",
  background: "#FFF6EA",
  boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
};

export const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "12px",
  width: "80vw",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

export const getFormattedObjectForSubmition = (
  selectedSchoolBranches,
  selectedPapers
) => {
  let obj = {
    institutions: [],
    assessmentPapers: [],
  };

  let institutionArr = [];
  selectedSchoolBranches.forEach((schoolItem) => {
    schoolItem?.branches?.forEach((i) => {
      let obj = {
        schoolId: schoolItem.schoolId,
        assigned: true,
        branchId: i,
      };
      institutionArr.push(obj);
    });
  });

  obj.institutions = institutionArr;
  obj.assessmentPapers = selectedPapers.map((i) => {
    return {
      assessmentPaperId: i,
      assigned: true,
    };
  });
  return obj;
};

export const getFormattedBranchSchoolData = (
  branchId,
  schoolId,
  selectedSchoolBranches
) => {
  let selections = [...selectedSchoolBranches];

  let schoolSelected = selections?.find((i) => i.schoolId === schoolId);

  //school exist uppend and remove from its branches
  if (isNOTNullOrUndefined(schoolSelected)) {
    let indexOfSchoolInSelections = selections.findIndex(
      (i) => i.schoolId === schoolId
    );
    let branchIsSelected = schoolSelected?.branches?.includes(branchId);
    if (branchIsSelected) {
      //if branch is already selected , REMOVE Branch
      selections[indexOfSchoolInSelections].branches =
        schoolSelected?.branches?.filter((id) => id != branchId);
      if (selections[indexOfSchoolInSelections].branches.length > 0) {
        // do nothing
      } else {
        // all branches removed , REMOVE School
        selections = selections.filter((item) => item.schoolId != schoolId);
      }
    } else {
      //if branch is not selected , ADD Branch
      selections[indexOfSchoolInSelections].branches.push(branchId);
    }
  } else {
    //if school is not already selected : ADD school and Branch
    let obj = {
      schoolId: schoolId,
      branches: [branchId],
    };
    selections.push(obj);
  }

  return selections;
};
