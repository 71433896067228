import React from "react";
import styled from "styled-components";
// import icon from "../../../../img/successpopup.png";

function MessageModal({ open, onClose, message  }) {

	const handleOverlayClick = (e) => {
		// if (e.target === e.currentTarget) {
		// 	onClose();
		// }
	};
	return (
		<ModalOverlay isOpen={open} onClick={handleOverlayClick}>
			<ModalWrapper>
				<SucessIcon>
				</SucessIcon>
				<Title>{message}</Title>
				<Button onClick={onClose}>Ok</Button>
			</ModalWrapper>
		</ModalOverlay>
	);
}

export default MessageModal;
const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: ${(props) => (props.isOpen ? "block" : "none")};
	z-index: 100;
`;

const ModalWrapper = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	width: 70%;
	max-width: 400px;
`;
const SucessIcon = styled.span`
	width: 70px;
	display: block;
	margin: 0 auto;
	margin-bottom: 10px;
	img {
		display: block;
		width: 100%;
	}
`;

const Title = styled.p`
	font-size: 16px;
	text-align: center;
	margin-bottom: 10px;
`;
const QuizName = styled.p`
	font-size: 16px;
	text-align: center;
	color: #fd8c00;
`;
const TotalQuestion = styled.p`
	font-size: 16px;
	text-align: center;
	color: #fd8c00;
`;
const Button = styled.span`
	width: 100px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fd8c00;
	color: #fff;
	font-size: 16px;
	border-radius: 20px;
	margin: 0 auto;
	margin-top: 20px;
	box-shadow: 0px 5px 6px #fd8c0080;
	cursor: pointer;
`;
