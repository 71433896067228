import axios from "axios";

async function subtopics_controller_getall(setSubtopic, subjectId) {
  let token = sessionStorage.getItem("token");

  let result = await axios
    .get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sub-topics/all?subjectId=${typeof subjectId === 'object' ? subjectId?.subjectsId:subjectId||""}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((json) => {
      if (json.data.data) {
        setSubtopic(json.data.data);
      }
    })
    .catch((err) => console.log(err));
}

export default subtopics_controller_getall;
