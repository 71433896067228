import React, { useEffect, useState } from "react";
import dashHome from "../../../../img/dashHome.svg";
import upArrow from "../../../../img/upArrow.svg";
import {
  FormControl,
  TextField,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
} from "@mui/material";
import { validationSchema } from "../Schema/schema";
import {
  createUpdateBluePrintContent,
  getBluePriintSingleContent,
  getSampleTemplate,
} from "../Apis/BluePrintApis";
import ConfigurationSuccessModal from "../../../../components/common/ConfigurationSuccessModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  set_blue_print_data,
  set_initial_data,
  set_is_scrolling_to_top,
} from "../../../../actions/bluePrintActions";
import { isNOTNullOrUndefined } from "../../../../utils/helper";
import BluePrintErrorModal from "../../../../components/common/BluePrintErrorModal";

export default function AddBluePrint({
  subjectList,
  paperList,
  boardList,
  gradeList,
  levelList,
  handleFileSelect,
  isUploaded,
  totalMarks,
  totalQuestions,
  excelDataUpload,
  setIsUploaded,
  selectedForEdit,
  setSelectedForEdit,
  setExcelDataUpload,
  setCreated,
  formSelectedBoard,
  setFormSelectedBoard,
  formSelectedGrade,
  setFormSelectedGrade,
  buttonEdit,
  setButtonEdit,
}) {
  let initialData = {
    paperTitle: "",
    paperTypeId: "",
    blueprintLevelId: "",
    boardId: "",
    gradeId: "",
    subjectId: "",
    totalNumberOfQuestions: "",
    totalMark: "",
    totalTime: "",
    questionFrequency: "",
  };

  const dispatch = useDispatch();
  const { bluePrintData } = useSelector((state) => state.bluePrint);
  const [formData, setFormData] = useState(bluePrintData);
  const [uploadedExcelData, setUploadedExcelData] = useState([]);
  const [sampleTemplateLink, setSampleTemplateLink] = useState("");
  const [uploadFileError, setUploadFileError] = useState("");

  // const [frequencyCount, setFrequencyCount] = useState("");
  useEffect(() => {
    dispatch(set_initial_data());
    saveTemplateNameAndSetSample();
  }, []);

  useEffect(() => {
    if (selectedForEdit) {
      dispatch(set_is_scrolling_to_top(true));
      editBluePrintContent(selectedForEdit);
    }
  }, [selectedForEdit]);

  useEffect(() => {
    dispatch(set_blue_print_data(formData));
  }, [formData]);

  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [errorCreateModal, setErrorCreateModal] = useState(false);
  const [modalMessageError, setModalMessageError] = useState("");

  const [showCancelButton, setShowCancelButton] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const editBluePrintContent = async () => {
    const response = await getBluePriintSingleContent(selectedForEdit);
    if (response) {
      setFormData({
        id: response?.id,
        paperTitle: response?.paperTitle,
        paperTypeId: response?.paperTypeId,
        blueprintLevelId: response?.blueprintLevelId,
        boardId: response?.boardId,
        gradeId: response?.gradeId,
        subjectId: response?.subjectId,
        questionFrequency: response?.questionFrequency,
        totalNumberOfQuestions: response?.totalNumberOfQuestions,
        totalMark: response?.totalMarks,
        totalTime: response?.durationInMinutes,
      });
      setUploadedExcelData(response?.blueprintTemplate);
      setExcelDataUpload(response?.blueprintTemplate || []);
      setFormSelectedBoard(response?.boardId);
      setFormSelectedGrade(response?.gradeId);
    }
  };

  const saveTemplateNameAndSetSample = async () => {
    // getSampleTemplatename()
    const response = await getSampleTemplate();
    // console.log(response, "sample");
    setSampleTemplateLink(response);
  };

  const onSubmit = async (data) => {
    console.log("onSubmit called", data)
    let objData = {
      id: data?.id || "",
      paperTitle: data?.paperTitle,
      paperTypeId: data?.paperTypeId,
      blueprintLevelId: data?.blueprintLevelId,
      boardId: data?.boardId,
      gradeId: data?.gradeId,
      subjectId: data?.subjectId,
      totalNumberOfQuestions: data?.totalNumberOfQuestions,
      totalMarks: data?.totalMark,
      durationInMinutes: data?.totalTime,
      questionFrequency: data?.questionFrequency,
      blueprintTemplate: excelDataUpload,
    };
    // Handle form submission here
    const response = await createUpdateBluePrintContent(objData);
    if (response?.success == true) {
      if (!buttonEdit) {
        setFormData(initialData);
        setIsUploaded(false);
        setSelectedForEdit(null);

        setCreated(true);
        setFormSelectedBoard("");
        setFormSelectedGrade("");
        setButtonEdit(false);
        setShowCancelButton(false);
        setTimeout(() => {
          setSuccessCreateModal(true);
          setModalMessage("Blueprint created successfully");
        }, 1000);
      } else {
        setTimeout(() => {
          setModalMessage("Blueprint updated successfully");
        }, 1500);
        setSuccessCreateModal(true);
        setFormData(initialData);
        setIsUploaded(false);
        setSelectedForEdit(null);
        setCreated(true);
        setFormSelectedBoard("");
        setFormSelectedGrade("");
        setButtonEdit(false);
        setShowCancelButton(false);
      }
    } else {
      setModalMessageError(response.message);
      setTimeout(() => {
        setErrorCreateModal(true);
      }, 1500);
      // alert(JSON.stringify(response.message));
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      ["totalMark"]: totalMarks,
      ["totalNumberOfQuestions"]: totalQuestions,
    });
  }, [totalMarks, totalQuestions]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (e.target.name == "boardId") {
      setFormSelectedBoard(e.target.value);
    }
    if (e.target.name == "gradeId") {
      setFormSelectedGrade(e.target.value);
    }
  };

  const resetForm = () => {
    // dispatch(set_initial_data());
    setFormData(initialData);
    setFormErrors({});
    setIsUploaded(false);
    setSelectedForEdit(null);
    setFormSelectedBoard("");
    setFormSelectedGrade("");
    setButtonEdit(false);
    setExcelDataUpload([]);
    setUploadFileError("")
  };
  const handleSubmit = async () => {
    if (excelDataUpload.length == 0) {
      setUploadFileError('Please upload a file.');
    } else {
      setUploadFileError('');
    }

    try {
      // Validate the form data using Yup
      await validationSchema.validate(formData, { abortEarly: false });
      onSubmit(formData);

      // Form data is valid, you can handle the submission here
      // console.log(formData, "valuesxxx");

      // Clear form errors
      setFormErrors({});
    } catch (error) {
      // Handle validation errors
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setFormErrors(errors);
    }
  };

  const closeSuccessModal = () => {
    setSuccessCreateModal(false);
  };
  const closeErrorModal = () => {
    setErrorCreateModal(false);
  };

  useEffect(() => {
    if (
      formData.paperTitle ||
      formData?.paperTypeId ||
      formData?.blueprintLevelId ||
      formData?.boardId ||
      formData?.gradeId ||
      formData?.subjectId ||
      formData?.totalTime ||
      formData?.questionFrequency ||
      excelDataUpload.length > 0
    ) {
      setShowCancelButton(true);
    } else {
      setShowCancelButton(false);
    }
  }, [formData]);

  // const handleQuestionFrequency = (e) => {
  //   if (e.target.value >= 1) {
  //     setFrequencyCount(e.target.value);
  //   }
  // };

  return (
    <div className="blue-print-wrap">
      <div style={{ marginLeft: "5px" }}>
        <img
          src={dashHome}
          alt="no img"
          width="20px"
          height="20px"
          style={{ verticalAlign: "middle" }}
        />
        <span className="sheet-table-page-head">Add Blue Print</span>
      </div>
      <div className="blue-print-form-container">
        <div className="form-wrapper">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "20ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="outlined-password-input"
                label="Paper Title"
                required
                name="paperTitle"
                value={
                  isNOTNullOrUndefined(formData?.paperTitle)
                    ? formData.paperTitle
                    : ""
                }
                onChange={handleChange}
                error={!!formErrors.paperTitle}
                helperText={formErrors.paperTitle}
              />

              <FormControl sx={{ width: "20ch", marginTop: "8px", m: 1 }}>
                <InputLabel id="demo-simple-select-label" required>
                  Paper Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.paperTypeId)
                      ? formData.paperTypeId
                      : ""
                  }
                  label="Paper Type"
                  name="paperTypeId"
                  onChange={handleChange}
                  error={!!formErrors.paperTypeId}
                  helperText={formErrors.paperTypeId}
                >
                  {paperList?.map((item) => {
                    return (
                      <MenuItem value={item?.id}
                        id={`az-content-paperlistdropdown-${item.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: '#FFA500',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#FFA500',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}
                      >
                        {item?.paperType}
                      </MenuItem>
                    );
                  })}
                </Select>
                {formErrors.paperTypeId && (
                  <>
                    <p className="error-style">{formErrors.paperTypeId}</p>
                  </>
                )}
              </FormControl>
              <FormControl sx={{ width: "20ch", marginTop: "8px", m: 1 }}>
                <InputLabel id="demo-simple-select-label" required>
                  Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.blueprintLevelId)
                      ? formData.blueprintLevelId
                      : ""
                  }
                  label="Level"
                  name="blueprintLevelId"
                  onChange={handleChange}
                  error={!!formErrors.blueprintLevelId}
                  helperText={formErrors.blueprintLevelId}
                >
                  {levelList?.map((item) => {
                    return (
                      <MenuItem value={item?.id}
                        id={`az-content-leveldropdown-${item.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: '#FFA500',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#FFA500',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <Tooltip title={item?.description} placement="top">
                          {item?.levelName}
                        </Tooltip>
                      </MenuItem>
                    );
                  })}
                </Select>
                {formErrors.blueprintLevelId && (
                  <>
                    <p className="error-style">{formErrors.blueprintLevelId}</p>
                  </>
                )}
              </FormControl>
              <FormControl sx={{ width: "20ch", marginTop: "8px", m: 1 }}>
                <InputLabel id="demo-simple-select-label" required>
                  Board
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.boardId)
                      ? formData.boardId
                      : ""
                  }
                  label="Board"
                  name="boardId"
                  onChange={handleChange}
                  error={!!formErrors.boardId}
                  helperText={formErrors.boardId}
                >
                  {boardList?.map((item) => {
                    return <MenuItem value={item?.id}
                      id={`az-content-boarddropdown-${item.id}`}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: '#FFA500',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#FFA500',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}>{item?.board}</MenuItem>;
                  })}
                </Select>
                {formErrors.boardId && (
                  <>
                    <p className="error-style">{formErrors.boardId}</p>
                  </>
                )}
              </FormControl>
              <FormControl sx={{ width: "20ch", marginTop: "8px", m: 1 }}>
                <InputLabel id="demo-simple-select-label" required>
                  Grade
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.gradeId)
                      ? formData.gradeId
                      : ""
                  }
                  label="Grade"
                  name="gradeId"
                  onChange={handleChange}
                  error={!!formErrors.gradeId}
                  helperText={formErrors.gradeId}
                >
                  {gradeList?.map((item) => {
                    return <MenuItem value={item?.id}
                      id={`az-content-gradedropdown-${item.id}`}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: '#FFA500',
                          color: "#FFFFFF"
                        },
                        "&.Mui-selected:hover": {
                          backgroundColor: '#FFA500',
                          color: "#000000"
                        },
                        "&:not(.Mui-selected):hover": {
                          backgroundColor: '#fcedc2',
                          color: '#000000',
                        },
                      }}>{item?.grade}</MenuItem>;
                  })}
                </Select>
                {formErrors.gradeId && (
                  <>
                    <p className="error-style">{formErrors.gradeId}</p>
                  </>
                )}
              </FormControl>
              <FormControl sx={{ width: "20ch", marginTop: "8px", m: 1 }}>
                <InputLabel id="demo-simple-select-label" required>
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    isNOTNullOrUndefined(formData?.subjectId)
                      ? formData.subjectId
                      : ""
                  }
                  label="Subject"
                  name="subjectId"
                  onChange={handleChange}
                  error={!!formErrors.subjectId}
                  helperText={formErrors.subjectId}
                >
                  {subjectList?.map((item) => {
                    return (
                      <MenuItem value={item?.subjectId}
                        id={`az-content-subjectdropdown-${item.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: '#FFA500',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#FFA500',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        {item?.subject}
                      </MenuItem>
                    );
                  })}
                </Select>
                {formErrors.subjectId && (
                  <>
                    <p className="error-style">{formErrors.subjectId}</p>
                  </>
                )}
              </FormControl>

              <FormControl>
                <TextField
                  id="outlined-basic"
                  label="Question Frequency"
                  variant="outlined"
                  type="number"
                  name="questionFrequency"
                  required
                  value={
                    isNOTNullOrUndefined(formData?.questionFrequency)
                      ? formData.questionFrequency
                      : ""
                  }
                  onChange={handleChange}
                  error={!!formErrors.questionFrequency}
                  helperText={formErrors.questionFrequency}
                />
              </FormControl>

              <TextField
                id="outlined-password-input"
                label="Total no of Questions"
                value={
                  isNOTNullOrUndefined(formData?.totalNumberOfQuestions)
                    ? formData.totalNumberOfQuestions
                    : ""
                }
                disabled
              />
              <TextField
                id="outlined-password-input"
                label="Total Mark"
                value={
                  isNOTNullOrUndefined(formData?.totalMark)
                    ? formData.totalMark
                    : ""
                }
                disabled
              />
              <TextField
                id="outlined-password-inputs"
                label="Total Time"
                value={
                  isNOTNullOrUndefined(formData?.totalTime)
                    ? formData.totalTime
                    : ""
                }
                name="totalTime"
                onChange={handleChange}
                error={!!formErrors.totalTime}
                helperText={formErrors.totalTime}
              />
              {selectedForEdit && formData.id && (
                <label
                  className="image-button"
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    position: "relative",
                  }}
                  onClick={(e) => handleFileSelect(e, uploadedExcelData)}
                >
                  {/* <input
                    type="file"
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                  /> */}
                  <span className="btn-text">View File</span>
                  <img src={upArrow} width="23px" height="23px" />{" "}
                </label>
              )}
              {!isUploaded && !selectedForEdit && (
                <>
                  <label
                    className="image-button"
                    style={{
                      marginTop: "10px",
                      marginLeft: "10px",
                      position: "relative",
                      backgroundColor: "#403e75"
                    }}
                  >
                    <input
                      type="file"
                      id={`az-content-file-upload-button1`}
                      onChange={handleFileSelect}
                      style={{ display: "none" }}
                    />
                    <span className="btn-text">Upload File</span>
                    <img src={upArrow} width="23px" height="2px" />{" "}
                    <a
                      href={sampleTemplateLink}
                      className="link-style"
                      style={{
                        display: "block",
                        position: "absolute",
                        bottom: "-25px",
                      }}
                    >
                      Download the sample template (.xls)
                    </a>
                  </label>
                </>
              )}
              {isUploaded && !selectedForEdit && (
                <label
                  className="file-upload-text"
                  style={{
                    marginTop: "20px",
                    marginLeft: "10px",
                    display: "inline-block",
                    cursor: "pointer",
                    backgroundColor: "#403e75"
                  }}
                >
                  <input
                    type="file"
                    id={`az-content-file-upload-button2`}
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                  />
                  <span className="btn-text">File Uploaded</span>
                </label>
              )}
            </div>
            <div style={{ width: "100%" }}>
              {uploadFileError && <p className="error-style" style={{ marginLeft: "85%" }}>{uploadFileError}</p>}
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "5px",
                  paddingRight: "20px",
                  position: "absolute",
                  bottom: "12px",
                  right: "12px",
                }}
              >
                <button
                  onClick={resetForm}
                  type="button"
                  id={`az-content-cancel-button`}
                  className="button-cancel"
                  style={{
                    backgroundColor: showCancelButton ? "red" : "",
                    color: showCancelButton ? "#ffff" : "",
                    cursor: showCancelButton ? "pointer" : "default",
                  }}
                >
                  <span className="btn-text">Cancel</span>
                </button>

                <button
                  onClick={handleSubmit}
                  id={`az-content-submit-button`}
                  type="button"
                  className="button-submit"
                  style={{
                    backgroundColor:
                      excelDataUpload.length > 0 ? "#cccccc" : "#403e75",
                    cursor: showCancelButton ? "pointer" : "default",
                  }}
                // disabled={excelDataUpload.length > 0 ? false : true}
                >
                  <span className="btn-text">
                    {buttonEdit ? "Update" : "Create"}
                  </span>
                </button>
              </div>
            </div>
          </Box>
        </div>
      </div>
      <ConfigurationSuccessModal
        userType={modalMessage}
        open={successCreateModal}
        handleOk={closeSuccessModal}
      />
      <BluePrintErrorModal
        userType={modalMessageError}
        open={errorCreateModal}
        handleOk={closeErrorModal}
      />
    </div>
  );
}
