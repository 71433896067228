import axios from 'axios'

function training_category_id_delete(trainingCategoryId){
    let token = sessionStorage.getItem("token")

    let result = axios.delete(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training-category/${trainingCategoryId}` ,
    {
        headers: {
            "Contetn-Type" : "application/json" ,
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{

    })
}

export default training_category_id_delete