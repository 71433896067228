import axios from "axios";

async function sectionListGet(setSections) {
  let token = sessionStorage.getItem("token");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sections/all`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp)=>{
      // console.log(resp);
      if (resp.data.length !== 0) {
        setSections(resp.data.data);
      }
    })
    .catch((err)=>{
      console.log(err);
      setSections(["unable to fetch sections"]);
    })
}

export default sectionListGet;
