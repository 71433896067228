import axios from "axios";
import { isNOTNullOrUndefined } from "../../../../utils/helper";

export const fetchGradesStudentDetails = async () => {
  const token = sessionStorage.getItem("token");
  // const gradeId = sessionStorage.getItem("gradeId");
  const teacherId = sessionStorage.getItem("teacherId");

  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/dashboard/grades-students/details?teacherId=${teacherId}`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const fetchAllGrades = async () => {
  const token = sessionStorage.getItem("token");
  const teacherId = sessionStorage.getItem("teacherId");

  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${teacherId}`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  var val = res?.data?.data || [];
  sessionStorage.setItem("grade_info", JSON.stringify(val))
  return val
};

export const fetchTeacherData = async (userName, role) => {
  const token = sessionStorage.getItem("token");

  let result = {};
  await axios
    .get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        result = res?.data?.data?.teacher || [];
        sessionStorage.setItem("Basic_info", JSON.stringify(result))
      } else {
        result = {};
      }
    })
    .catch((err) => console.log(err, "errr"));
  return result;
};

export const fetchQuizPerformance = async (selectedGrade) => {
  const token = sessionStorage.getItem("token");
  const teacherId = sessionStorage.getItem("teacherId");

  let url = `${process.env.REACT_APP_SWAGGER_URL
    }v1/api/user/dashboard/grade-wise-quiz-performance?teacherId=${teacherId}${isNOTNullOrUndefined(selectedGrade) ? `&gradeId=${selectedGrade}` : ""
    }`;
  try {
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data?.data || [];
  } catch (error) {
    return [];
  }
};

export const fetchDashboardChapterWisePerformance = async (
  selectedGrade,
  selectedSubject,
  selectedSection,
  selectedSubTopic
) => {
  const token = sessionStorage.getItem("token");
  const teacherId = sessionStorage.getItem("teacherId");

  let url = `${process.env.REACT_APP_SWAGGER_URL
    }v1/api/user/dashboard/chapter-wise-quiz-performance?teacherId=${teacherId}${isNOTNullOrUndefined(selectedGrade) ? `&gradeId=${selectedGrade}` : ""
    }&subjectId=${selectedSubject}${isNOTNullOrUndefined(selectedSection) ? `&sectionId=${selectedSection}` : ""
    }${isNOTNullOrUndefined(selectedSubTopic)
      ? `&subTopicId=${selectedSubTopic}`
      : ""
    }`;

  try {
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data?.data || [];
  } catch (error) {
    return [];
  }
};
