import React from 'react'
import ViewConceptTree from '../../viewConceptTree'
import Navbar from '../../Navbar'
import MainContainer from '../Dashboard/MainContainer'

const ReviewerConceptTree = () => {
  return (
    <>
    <Navbar />
    <MainContainer children={<ViewConceptTree />} />
    </>
  )
}

export default ReviewerConceptTree