import { BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Bar, LabelList, Cell } from "recharts";

const tooltipContentStyle = {
  width: 'auto',
  height: '60px',
  background: '#f0f0f0',
  border: '1px solid #ccc',
  fontSize: '14px',
  textAlign: 'center',
  padding: '5px',
};

const formatXTick = (tick) => `${tick}%`;

const GenericChart = ({ data, colors, chapterCount }) => {

  const CustomizedLabel = ({x, y, width, value}) => {
    return (
      <text
        x={ x + width + 30 }
        y={ y + 2 }
        fontSize={'12'}
        fontWeight={500}
        fontFamily="sans-serif"
        fill="#5e93ad"
        textAnchor="middle"
        dy={20}
      >
        {Math.round(value)}%
      </text>
    )
  };
  if(Array.isArray(data[0])) {
    const firstElement = data[0];
    data.shift();
      return (
        <div
          style={{
            width: '90%',
            height: '300px',
            overflowY: 'scroll'
          }}
        >
          <ResponsiveContainer width={'100%'} height={'100%'}>
            <BarChart
              width={'100%'}
              height={'100%'}
              data={firstElement}
              layout="vertical"
              barCategoryGap={20}
            >
              <XAxis
                type="number"
                domain={[0, 100]}
                tickFormatter={formatXTick}
                ticks={[0, 100]}
                tick={{fill: '#5e93ad'}}
                tickLine={{ display: 'none' }}
                axisLine={{
                  stroke: '#9ac6d6',
                  strokeWidth: 3
                }}
              />
              <YAxis
                type="category"
                width={120}
                padding={{left: 20}}
                dataKey={'name'}
                tickLine={{ display: 'none' }}
                tick={{fill: '#5e93ad', fontSize: 12}}
                axisLine={{
                  stroke: '#9ac6d6',
                  strokeWidth: 2
                }}
              />
              <Tooltip
                contentStyle={tooltipContentStyle}
                formatter={(value) => [`${Math.round(value)} %`]}
              />
              <Bar
                dataKey={'score'}
                label={<CustomizedLabel />}
                background={{ fill: '#eee' }}
                barSize={40}
              >
                {firstElement.map((entry, index) => (
                  <>
                    <Cell key={`bar-${index}`} dataKey="value" fill={colors[index % colors.length]} />
                  </>
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      )
  } else {
    return (
      <div
        style={{
          width: '90%',
          height: data?.length <= 7 ? '40vh' : '50vh',
        }}
      >
        <ResponsiveContainer width={'100%'} height={'100%'}>
          <BarChart
            width={'100%'}
            height={'100%'}
            data={data}
            layout="vertical"
            barCategoryGap={20}
          >
            <XAxis
              type="number"
              domain={[0, 100]}
              tickFormatter={formatXTick}
              ticks={[0, 100]}
              tick={{fill: '#5e93ad', fontSize: 16}}
              tickLine={{ display: 'none' }}
              axisLine={{
                stroke: '#9ac6d6',
                strokeWidth: 3
              }}
            />
            <YAxis
              type="category"
              width={120}
              padding={{left: 20}}
              dataKey={'name'}
              tickLine={{ display: 'none' }}
              tick={{fill: '#5e93ad', fontSize: '12px'}}
              axisLine={{
                stroke: '#9ac6d6',
                strokeWidth: 2
              }}
            />
            <Tooltip
              contentStyle={tooltipContentStyle}
              formatter={(value) => [`${Math.round(value)} %`]}
            />
            <Bar
              dataKey={'score'}
              label={<CustomizedLabel />}
              background={{ fill: '#eee' }}
              barSize={40}
            >
              {data.map((entry, index) => (
                <>
                  <Cell key={`bar-${index}`} dataKey="value" fill={colors[index % colors.length]} barCategoryGap={30} />
                </>
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

export default GenericChart;
