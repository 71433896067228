import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Divider,
  IconButton,
  TextField,
  Paper,
  Box,
} from "@mui/material";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import "../../../css/switch.css";
import number from "../../../img/Numbers.svg";

const GrayButton = styled(Button)(() => ({
    color: "#fff",
    backgroundColor: "gray",
    borderColor: "gray",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    fontSize: "0.9vw",
    "&:hover": {
      color: "#fff",
      backgroundColor: "gray",
      border: 0,
      boxShadow: "0px 3px 5px gray",
    },
  }));

const YButton = styled(Button)(() => ({
    color: "#fff",
    backgroundColor: "#ffcc00",
    borderColor: "#ffcc00",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    fontSize: "0.9vw",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#ffcc00",
      border: 0,
      boxShadow: "0px 3px 5px #ffcc00",
    },
  }));
  
  
  const LightWhiteButton = styled(Button)(() => ({
    color: "#3b3939",
    backgroundColor: "rgb(200,237,251)",
    textTransform: "none",
    border: "1px solid rgb(200,237,251)",
    borderColor: "#757575",
    fontWeight: "600",
    fontSize: "0.9vw",
    boxShadow: "1px -0px -0px 0px rgb(200,237,251)",
    "&:hover": {
      color: "#3b3939",
      backgroundColor: "rgb(200,237,251)",
    },
  }));
  const BlueButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#061ca5",
    textTransform: "none",
    border: "1px solid #061ca5",
    borderColor: "#061ca5",
    fontWeight: "600",
    fontSize: "0.9vw",
    boxShadow: "1px -0px -0px 0px #061ca5",
    "&:hover": {
      color: "white",
      backgroundColor: "#061ca5",
    },
  }));
  

const WorkbookView = () => {
    const [tabsValue, setTabsValue] = useState("1");


  const mql = window.matchMedia("(max-width: 2000px)");
  const smallScreen = mql.matches;

    
  const tabsChangeHandle = (event, newValue) => {
    setTabsValue(newValue);
  };
 
  return (
    <Paper
      sx={{
        padding: '10px'
      }}
    >
      <TabContext value={tabsValue}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'rgba(221,218,218,0.67)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
            // columnGap: "1rem",
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              columnGap: '10px'
            }}
          >
            <div
              style={{
                background: '#ffcc00',
                borderRadius: '150px',
                width: '1.5vw',
                height: '1.5vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <PsychologyAltOutlinedIcon
                style={{ color: 'white', fontSize: '1.3vw' }}
              />
            </div>
            <div
              style={{
                fontSize: '1.09vw',
                fontWeight: 600,
                color: 'black'
              }}
            >
              Mathematics
            </div>
          </div>
          <TabList
            onChange={(e, value) => tabsChangeHandle(e, value)}
            aria-label='lab API tabs example'
            textColor='inherit'
            // indicatorColor="secondary"
            TabIndicatorProps={{
              style: {
                background: '#061ca5',
                height: '10px',
                top: '50px'
              }
            }}
            variant={smallScreen ? 'scrollable' : 'standard'}
          >
            <Tab
              label='All'
              value='1'
              sx={{
                background: tabsValue === '1' ? '#061ca5' : '',
                color: tabsValue === '1' ? 'white' : 'black',
                borderRadius: '150px',
                marginBottom: '5px',
                textTransform: 'none',
                fontWeight: 600
              }}
            />
            <Tab
              label='Real Number'
              value='2'
              sx={{
                background: tabsValue === '2' ? '#061ca5' : '',
                color: tabsValue === '2' ? 'white' : 'black',
                borderRadius: '150px',
                marginBottom: '5px',
                textTransform: 'none',
                fontWeight: 600
              }}
            />
            <Tab
              label='Chapter 2'
              value='3'
              sx={{
                background: tabsValue === '3' ? '#061ca5' : '',
                color: tabsValue === '3' ? 'white' : 'black',
                borderRadius: '150px',
                marginBottom: '5px',
                textTransform: 'none',
                fontWeight: 600
              }}
            />
            <Tab
              label='Chapter 4'
              value='4'
              sx={{
                background: tabsValue === '4' ? '#061ca5' : '',
                color: tabsValue === '4' ? 'white' : 'black',
                borderRadius: '150px',
                marginBottom: '5px',
                textTransform: 'none',
                fontWeight: 600
              }}
            />
            <Tab
              label='Chapter 5'
              value='5'
              sx={{
                background: tabsValue === '5' ? '#061ca5' : '',
                color: tabsValue === '5' ? 'white' : 'black',
                borderRadius: '150px',
                marginBottom: '5px',
                textTransform: 'none',
                fontWeight: 600
              }}
            />
          </TabList>
          <TextField
            size={'small'}
            placeholder={'Search Content'}
            // onChange={coordinatorSearch}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <PsychologyAltOutlinedIcon style={{ color: '#152738' }} />
                </IconButton>
              )
            }}
            sx={{
              background: 'rgba(221,218,218,0.67)',
              borderRadius: '7px',
              marginBottom: '10px'
            }}
          />
        </Box>
        <TabPanel value='1'>All</TabPanel>
        <TabPanel value='2'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '2rem'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '1rem'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '10px'
                  }}
                >
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                      color: 'black'
                    }}
                  >
                    Real Number
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      columnGap: '10px'
                    }}
                  >
                    <YButton sx={{ borderRadius: '150px' }}>
                      11.Sessions
                    </YButton>
                    <GrayButton sx={{ borderRadius: '150px' }}>
                      Chapter 1
                    </GrayButton>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '10px'
                    }}
                  >
                    <div
                      style={{
                        color: 'rgb(66, 61, 92)',
                        fontSize: 18,
                        fontWeight: 600
                      }}
                    >
                      Learning Objective
                    </div>
                    <div>
                      <span style={{ color: 'black', fontWeight: 600 }}>
                        Knowledge:
                      </span>
                      The student is able to :
                    </div>
                    <div
                      style={{
                        width: '100%',
                        paddingLeft: '20px'
                      }}
                    >
                      <ul>
                        <li>State and understand Euclid's Division Lemma.</li>
                        <li>State and understand Euclid's Division Lemma.</li>
                        <li>
                          State and understand Fundamental Theorem of
                          Arithmetic.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '10px'
                    }}
                  >
                    <div>
                      <span style={{ color: 'black', fontWeight: 600 }}>
                        Value/Behaviour
                      </span>
                      The student is able to :
                    </div>
                    <div
                      style={{
                        width: '100%',
                        paddingLeft: '20px'
                      }}
                    >
                      <ul>
                        <li>
                          Understand the importance of L.C.M and H.C.F in real
                          life situation.
                        </li>
                        <li>State and understand Euclid's Division Lemma.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '10px'
                }}
              >
                <div>
                  <span style={{ color: 'black', fontWeight: 600 }}>
                    Skills:
                  </span>
                  The student is able to :
                </div>
                <div
                  style={{
                    width: '100%',
                    paddingLeft: '20px'
                  }}
                >
                  <ul>
                    <li>
                      Solve divisibility problem using Euclid Division Lemma.
                    </li>
                    <li>Solve problem based on L.C.M and H.C.F.</li>
                    <li>Prove irrationality of 2,3,5 etc.</li>
                    <li>
                      Differentiate between rational and irrational numbers.
                    </li>
                    <li>
                      Comprehend and solve word problem based on L.C.M and
                      H.C.F.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Divider
                orientation='horizontal'
                flexItem
                sx={{
                  border: '1px solid rgba(221,218,218,0.67)',
                  padding: '0px'
                }}
              />
              <div style={{ padding: '15px' }}>
                <LightWhiteButton
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '10px'
                  }}
                >
                  Session 1
                  <PsychologyAltOutlinedIcon />
                </LightWhiteButton>
              </div>
              <Divider
                orientation='horizontal'
                flexItem
                sx={{
                  border: '1px solid rgba(221,218,218,0.67)',
                  padding: '0px'
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    // columnGap: "1px",
                    color: 'black',
                    alignItems: 'center'
                  }}
                >
                  <PsychologyAltOutlinedIcon sx={{ fontSize: 20 }} />
                  <div style={{ fontSize: '22px', fontWeight: 'bolder' }}>
                    Session 1
                  </div>
                </div>
                <div
                  style={{
                    border: '1px solid rgba(221,218,218,0.67)',
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'end'
                  }}
                >
                  <PsychologyAltOutlinedIcon
                    sx={{ color: 'rgb(151,151,151)' }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  columnGap: '1rem'
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '1rem'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '10px'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '10px',
                        alignItems: 'center'
                      }}
                    >
                      <div style={{ fontWeight: 600, color: 'black' }}>
                        Mindful Practice:
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          columnGap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <PsychologyAltOutlinedIcon
                          sx={{ color: 'rgb(151,151,151)' }}
                        />
                        <span style={{ color: '#00b85d', fontWeight: 600 }}>
                          5 Min
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '10px',
                        alignItems: 'center'
                      }}
                    >
                      <div style={{ fontWeight: 600, color: 'black' }}>
                        Introduction:
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          columnGap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <PsychologyAltOutlinedIcon
                          sx={{ color: 'rgb(151,151,151)' }}
                        />
                        <span style={{ color: '#00b85d', fontWeight: 600 }}>
                          10 Min
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    The teacher revises varius types of numbers like Natural
                    Numbers, Whole NUmbers, Integers, Rational Numbers,
                    Irrational Numbers and Real Numbers with the help of a Venn
                    Diagram
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      The teacher also revises Division Algorithm of Integers by
                      asking follwing question:
                    </div>
                    <div style={{ paddingLeft: '20px' }}>
                      <ol type={'a'}>
                        <li>
                          What is dividend, divisor, quotient and remainder?
                        </li>
                        <li>What is Division Algorithm?</li>
                      </ol>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    The teacher explains division algorithm using suitable
                    example Dividend = Divisor * Quotient + Remainder
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '10px'
                    }}
                  >
                    <div
                      style={{
                        fontSize: 22,
                        fontWeight: 600,
                        color: 'blue'
                      }}
                    >
                      Guided inquiry
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '10px',
                        alignItems: 'center'
                      }}
                    >
                      <div style={{ fontWeight: 600, color: 'black' }}>
                        Introduction:
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          columnGap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <PsychologyAltOutlinedIcon
                          sx={{ color: 'rgb(151,151,151)' }}
                        />
                        <span style={{ color: '#00b85d', fontWeight: 600 }}>
                          15 Min
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '3px'
                    }}
                  >
                    <div>
                      The teacher states and explains Euclid's Division Lemma
                      with the help of PPT, for reach pair of positive integers,
                      a and b, there exists unique integers q and r , satisfying
                      the relations:
                    </div>
                    <div>{`a = bq + r, 0 < r < b`} </div>
                    <div>Debriefing : [10min]</div>
                    <div>
                      The teacher asks the students to find integers 'q' and 'r'
                      for the following pair of positive integers 'a' and 'b',
                    </div>
                    <div style={{ paddingLeft: '20px' }}>
                      <ol
                        type={'1'}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          columnGap: '1.2rem'
                        }}
                      >
                        <li>10,3</li>
                        <li>4,19</li>
                        <li>81,3</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <img src={number} width={'100%'} alt={''} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center'
                    }}
                  >
                    <BlueButton
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        columnGap: '10px'
                      }}
                    >
                      <PsychologyAltOutlinedIcon />
                      Home Work Assainment
                    </BlueButton>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px'
              }}
            >
              <Divider
                orientation='horizontal'
                flexItem
                sx={{
                  border: '1px solid rgba(221,218,218,0.67)',
                  padding: '0px'
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    // columnGap: "1px",
                    color: 'black',
                    alignItems: 'center'
                  }}
                >
                  <PsychologyAltOutlinedIcon sx={{ fontSize: 20 }} />
                  <div style={{ fontSize: '22px', fontWeight: 'bolder' }}>
                    Session 2
                  </div>
                </div>
                <div
                  style={{
                    border: '1px solid rgba(221,218,218,0.67)',
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'end'
                  }}
                >
                  <PsychologyAltOutlinedIcon
                    sx={{ color: 'rgb(151,151,151)' }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '1em',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <img src={number} alt={''} width={'100%'} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '10px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '10px',
                        alignItems: 'center'
                      }}
                    >
                      <div style={{ fontWeight: 600, color: 'black' }}>
                        Mindful Practice:
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          columnGap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <PsychologyAltOutlinedIcon
                          sx={{ color: 'rgb(151,151,151)' }}
                        />
                        <span style={{ color: '#00b85d', fontWeight: 600 }}>
                          5 Min
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '10px',
                        alignItems: 'center'
                      }}
                    >
                      <div style={{ fontWeight: 600, color: 'black' }}>
                        Guided Inquiry:
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          columnGap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <PsychologyAltOutlinedIcon
                          sx={{ color: 'rgb(151,151,151)' }}
                        />
                        <span style={{ color: '#00b85d', fontWeight: 600 }}>
                          25 Min
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '10px'
                    }}
                  >
                    <div
                      style={{
                        fontSize: 22,
                        fontWeight: 600,
                        color: 'blue'
                      }}
                    >
                      Euclid's division Algorithm
                    </div>
                    <div>
                      The teacher explain how to find HCF of two given number
                      using euclid's division Algorithm.
                    </div>
                    <div>
                      An algorithm is series of well-definded steps which gives
                      a proceure of solving a type of problem.
                    </div>
                    <div>what is euclid's division Algorithm?</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '10px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '10px',
                    alignItems: 'center'
                  }}
                >
                  <div style={{ fontWeight: 600, color: 'black' }}>
                    Recapitulation:
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      columnGap: '5px',
                      alignItems: 'center'
                    }}
                  >
                    <PsychologyAltOutlinedIcon
                      sx={{ color: 'rgb(151,151,151)' }}
                    />
                    <span style={{ color: '#00b85d', fontWeight: 600 }}>
                      10 Min
                    </span>
                  </div>
                </div>
                <div>
                  Students are asked to make a flowchart to euclid's division
                  Algorithm
                </div>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: 'blue'
                  }}
                >
                  Home Work
                </div>
                <div>Complete section 2 Q1 & Q2.</div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value='3'>Chapter 2</TabPanel>
        <TabPanel value='4'>Chapter 4</TabPanel>
        <TabPanel value='5'>Chapter 5</TabPanel>
      </TabContext>
    </Paper>
  )
}

export default WorkbookView
