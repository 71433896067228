import React from "react";
import dashboardContentStyles from "../../pages/css/dashboardContent.module.css";
import dashHome from "../../../src/img/dashHome.svg";
import { useNavigate } from "react-router-dom";
import commonStyling from "../../pages/css/commonStyling.module.css"

const TopBar = ({ title, nav1, role, subTitle, subSubTitle, modifiedDate }) => {
  const navigate = useNavigate();
  const classes = [
    dashboardContentStyles.small_font,
  ];
  return (
    <article>
      <div id={`az-breadscrum-parent`} style={{ display: 'flex', position: 'relative', justifyContent: "space-between" }}>
        <div style={{ display: 'flex', position: 'relative', margin: '14px' }}  >
          <div >
            <span
              className={dashboardContentStyles.link_icon}
              style={{ cursor: 'pointer' }}
              id={`az-breadscrum-home`}
              onClick={() => {
                sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                sessionStorage.removeItem("sideNavSubMenuNames");
                console.log("role >>> ", role)
                // navigate(`/dashboard/${nav1}`)
                // role
                //   ? navigate(`/cordinator/dashboard`)
                //   : navigate(`/dashboard/${nav1}`)
                if (role === "COORDINATOR") {
                  navigate(`/cordinator/dashboard`);
                } else {
                  navigate(`/dashboard/${nav1}`);
                }
              }}
            >
              <img
                src={dashHome}
                alt="no img"
                className={dashboardContentStyles.az_menu_icon}
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle", marginRight: '5px' }}
              />
            </span>
          </div>
          <div>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              id={`az-breadscrum-back`}
              onClick={() => {
                sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                sessionStorage.removeItem("sideNavSubMenuNames");
                // role
                //   ? navigate(`/cordinator/dashboard`)
                //   : navigate(`/dashboard/${nav1}`)
                if (role === "COORDINATOR") {
                  navigate(`/cordinator/dashboard`);
                } else {
                  navigate(`/dashboard/${nav1}`);
                }
              }}
            >
              {title}
            </span>
            {subTitle && (
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
            )}
            {subTitle && (
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
            )}

            {subTitle && <span  className={`${dashboardContentStyles.az_menu_text} cursorDefault`}>{subTitle}</span>}
            {subSubTitle && (
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
            )}
            {subSubTitle && (
              <span>
                <i className="fa-solid fa-angle-right"
                  style={{ color: "#828282" }} />
              </span>
            )}
            {subSubTitle && (
              <span
                // id="az-Subtitle"
                className={dashboardContentStyles.az_selected_menu_text} //classes.join(' ')
              // style={{ color: "orange", fontSize: "15px" }}
              >
                {subSubTitle}
              </span>
            )}
          </div>
        </div>
        {/* <div className={classes.join(' ')} style={{ display: "flex" }}>
          <p>
            Last Update:&nbsp;
            {modifiedDate?.length && modifiedDate}
          </p>
        </div> */}
      </div>
    </article >
  );
};
export default TopBar;
