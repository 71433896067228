import React from "react";
import styled from "styled-components";
import MainContainer from "../Dashboard/MainContainer";
import ReviewerDashboard from "../Dashboard/ReviewerDashboard";
import Navbar from "../../Navbar";
import ChapterListScreen from "../ChapterDashBoard/ChapterListScreen";

function Dashboard() {
	return (
		<>
			<Navbar />
			<MainContainer children={<ChapterListScreen />} headerTitle={""} />
		</>
	);
}

export default Dashboard;
