import {
  SET_BLUE_PRINT_DATA,
  SET_INITIAL_DATA,
  SET_IS_SCROLLING_TO_TOP
} from "../constants/bluePrintConstants";

export const set_blue_print_data = (item) => (dispatch) => {
  dispatch({
    type: SET_BLUE_PRINT_DATA,
    payload: item,
  });
};

export const set_initial_data = () => (dispatch) => {
  dispatch({
    type: SET_INITIAL_DATA,
  });
};

export const set_is_scrolling_to_top = (item) => (dispatch) => {
  dispatch({
    type: SET_IS_SCROLLING_TO_TOP,
    payload: item,
  });
};
