import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { grey } from "@mui/material/colors";
import React from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};
const ViewSubjectAndSubtopicModal = ({ open, close, chapterData }) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>{chapterData?.chapter}</div>
            <CloseIcon onClick={close} style={{ cursor: "pointer" }} />
          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                color: "#FFA700",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span>Subject</span>
              <span>Subtopic</span>
            </div>
            <div
              style={{
                width: "100%",
                height: 0,
                border: "1px solid ",
                borderColor: grey[500],
                marginTop: 6,
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>{chapterData?.subject}</div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {chapterData?.subTopics?.map((item) => {
                  return (
                    <div key={item?.subTopicId} style={{ padding: "2px" }}>
                      {item?.subTopic}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default ViewSubjectAndSubtopicModal;
