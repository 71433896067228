import {
    SET_NAVBAR_IS_EXPANDED,
  } from "../constants/navbarConstants";
  
  
  export const set_navbar_is_expanded = item => dispatch => {
    dispatch({
        type:SET_NAVBAR_IS_EXPANDED,
        payload:item
    })
}

