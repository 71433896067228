import React, { useEffect, useState } from "react";
import axios from "axios";
import dashboardContentStyles from "./../../css/dashboardContent.module.css";
import chapterStyles from "./../../css/createChapter.module.css";
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";
import { Link, useNavigate, useLocation } from "react-router-dom";
import sortIcon from "../../../../src/pages/img/sort_1.png";
import add_icon from "../../../../src/img/add-circle-outline.svg";
import dashHome from "../../../../src/img/dashHome.svg";
import { Box, Modal, Typography } from '@material-ui/core';
import YellowButton from "../../../components/common/yellowButton";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";


const Passport_4 = (props) => {

    const [modifiedDate, setModifiedDate] = useState({});
    // const [popUp, setPopUp] = useState(false);
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();
    console.log("@@@@ passport 4  @@@@@@")
    console.log("grade >>> ", sessionStorage.getItem("gradeName"));

    useEffect(() => {
        //fetchReadingBookData();
        GetLastModifiedAt(setModifiedDate);
    }, [])

    const [formData, setFormData] = useState({
        title: '',
        author: '',
        description: '',
        summary: '',
        protagonist: '',
        antagonist: '',
        majorconflicts: '',
        minorconflicts: '',
        turningpoint: '',
        quote: '',
        motive: '',
        theme: '',
        storyend: '',
        recommendation: '',
        comments: '',
        recommendChoice: "",
    });

    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     setFormData({
    //         ...formData,
    //         [name]: value
    //     });
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleClose = () => {
        setOpen(false);
        props.onClosePage();
        // navigate('/dashboard/reading%20passport');
        // navigate(0);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        const fullRecommendation = `${formData.recommendChoice},  ${formData.recommendation}`;
        console.log(formData);
        const payload =
        {
            "studentId": sessionStorage.getItem("studentId"),
            "studentName": sessionStorage.getItem("studentName"),
            "schoolId": sessionStorage.getItem("schoolId"),
            "gradeId": sessionStorage.getItem("gradeId"),
            "grade": sessionStorage.getItem("gradeName"),
            "sectionId": sessionStorage.getItem("sectionId"),
            "section": sessionStorage.getItem("sectionName"),
            "branchId": sessionStorage.getItem("branchId"),
            "titleOfBook": formData.title,
            "author": formData.author,
            "bookDetails": {
                "book": {
                    "description": formData.description,
                    "summary": formData.summary,
                    "protagonist": formData.protagonist,
                    "antagonist": formData.antagonist,
                    "majorconflicts": formData.majorconflicts,
                    "minorconflicts": formData.minorconflicts,
                    "turningpoint": formData.turningpoint,
                    "quote": formData.quote,
                    "motive": formData.motive,
                    "theme": formData.theme,
                    "storyend": formData.storyend,
                    // "recommendation": formData.recommendation,
                    "recommendation": fullRecommendation,
                    "comments": formData.comments
                }
            },
            "reviewRating": {
                "bookRating": {
                    "bookRating": ""
                }
            },
            "imageUploaded": "",

        };

        console.log("payload >>> ", payload);
        let token = sessionStorage.getItem("token");
        axios
            .post(
                `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/reading_submissions/add`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {

                console.log("res >>> ", res.data);
                // setPopUp(true);
                setOpen(true)
                // alert('Review Submitted Successfully.')
                // setFormData({
                //     title: '',
                //     author: '',
                //     description: '',
                //     summary: '',
                //     protagonist: '',
                //     antagonist: '',
                //     majorconflicts: '',
                //     minorconflicts: '',
                //     turningpoint: '',
                //     quote: '',
                //     motive: '',
                //     theme: '',
                //     storyend: '',
                //     recommendation: '',
                //     comments: ''
                // });

            })
            .catch((error) => {
                console.log(error.response.data.message);

            });
    };

    return (

        <div style={{ width: "100%", height: "100%", marginBottom: '20px' }}>
            <article>

                <div style={{ position: 'relative', margin: '14px' }}>
                    <span className={dashboardContentStyles.link_icon}>
                        <img
                            src={dashHome}
                            alt="no img"
                            className={dashboardContentStyles.az_menu_icon}
                        />
                    </span>
                    <span
                        id={`az-breadscrum-home`}
                        className={dashboardContentStyles.az_menu_text}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                            sessionStorage.removeItem("sideNavSubMenuNames");
                            navigate("/dashboard/template");
                        }}
                    >
                        Home
                    </span>
                    <span>
                        <i className="fa-solid fa-angle-right"
                            style={{ color: "#828282" }} />
                    </span>
                    <span>
                        <i className="fa-solid fa-angle-right"
                            style={{ color: "#828282" }} />
                    </span>
                    <span
                        className={dashboardContentStyles.az_menu_text}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            props.onClosePage();
                            // props.pageRefresh();
                            // props.useEffect();
                        }}
                    >
                        Passport
                    </span>
                    <span>
                        <i className="fa-solid fa-angle-right"
                            style={{ color: "#828282" }} />
                    </span>
                    <span>
                        <i className="fa-solid fa-angle-right"
                            style={{ color: "#828282" }} />
                    </span>
                    <span
                        className={dashboardContentStyles.az_selected_menu_text}
                    >
                        Review - Online
                    </span>
                </div>

                {/* <div className={dashboardContentStyles.dashboard_last_updated}>
                    <p>Last Update: {modifiedDate.data}</p>
                </div> */}
            </article>

            {/* <div style={{ margin: 'start', marginTop: '20px', marginLeft: '20px', marginRight: '50px', marginBottom: '20px' }}>
                <form onSubmit={handleSubmit}>

                    <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <label htmlFor="title" style={{ flex: '1', marginRight: '10px' }}>Title*:</label>
                        <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} maxLength="50" style={{ flex: '2', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} required />
                        <p style={{ paddingLeft: '10px' }}>{formData.title.length}/50</p>

                    </div>

                    <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <label htmlFor="author" style={{ flex: '1', marginRight: '10px' }}>Author*:</label>
                        <input type="text" id="author" name="author" value={formData.author} onChange={handleChange} maxLength="20" style={{ flex: '2', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} required />
                        <p style={{ paddingLeft: '10px' }}>{formData.author.length}/20</p>
                    </div>

                    <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="description" style={{ display: 'block' }}>Describe the setting of the story.*</label>
                        <textarea id="description" name="description" value={formData.description} onChange={handleChange} maxLength="80" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.description.length}/80</p>
                    </div>

                    <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="summary" style={{ display: 'block' }}>Summary of the Plot.*</label>
                        <textarea id="summary" name="summary" value={formData.summary} onChange={handleChange} maxLength="200" style={{ width: '100%', height: '100px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.summary.length}/200</p>
                    </div>

                    <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="protagonist" style={{ display: 'block' }}>Who is the protagonist? Describe the character.*</label>
                        <textarea id="protagonist" name="protagonist" value={formData.protagonist} onChange={handleChange} maxLength="150" style={{ width: '100%', height: '100px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.protagonist.length}/150</p>
                    </div>

                    <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="antagonist" style={{ display: 'block' }}>Who is the antagonist? Describe the character.*</label>
                        <textarea id="antagonist" name="antagonist" value={formData.antagonist} onChange={handleChange} maxLength="150" style={{ width: '100%', height: '100px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.antagonist.length}/150</p>
                    </div>

                    <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="majorconflicts" style={{ display: 'block' }}>What are the major conflicts within the story?*</label>
                        <textarea id="majorconflicts" name="majorconflicts" value={formData.majorconflicts} onChange={handleChange} maxLength="100" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.majorconflicts.length}/100</p>
                    </div>

                    <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="minorconflicts" style={{ display: 'block' }}>What are the minor conflicts within the story?*</label>
                        <textarea id="minorconflicts" name="minorconflicts" value={formData.minorconflicts} onChange={handleChange} maxLength="100" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none'}} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.minorconflicts.length}/100</p>
                    </div>

                    <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="turningpoint" style={{ display: 'block' }}>What is the turning point or climax of the story?*</label>
                        <textarea id="turningpoint" name="turningpoint" value={formData.turningpoint} onChange={handleChange} maxLength="100" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.turningpoint.length}/100</p>
                    </div>

                    <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="quote" style={{ display: 'block' }}>Write a memorable quote/line/citation from the story and caption it.*</label>
                        <textarea id="quote" name="quote" value={formData.quote} onChange={handleChange} maxLength="150" style={{ width: '100%', height: '100px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.quote.length}/150</p>
                    </div>

                    <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="motive" style={{ display: 'block' }}>What is the motive that runs throughout the story?*</label>
                        <textarea id="motive" name="motive" value={formData.motive} onChange={handleChange} maxLength="75" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.motive.length}/75</p>
                    </div>

                    <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="theme" style={{ display: 'block' }}>What is the theme that runs throughout the story?*</label>
                        <textarea id="theme" name="theme" value={formData.theme} onChange={handleChange} maxLength="25" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.theme.length}/25</p>
                    </div>

                    <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="storyend" style={{ display: 'block' }}>How does the story end?*</label>
                        <textarea id="storyend" name="storyend" value={formData.storyend} onChange={handleChange} maxLength="125" style={{ width: '100%', height: '100px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.storyend.length}/125</p>
                    </div>

                    <div style={{ marginBottom: '15px' }}>
                        <label htmlFor="recommendation" style={{ display: 'block', marginBottom: '10px' }}>
                            Would you recommend the book to somebody? Why or why not?*
                        </label>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <label style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="radio"
                                    name="recommendChoice"
                                    value="Yes"
                                    checked={formData.recommendChoice === "Yes"}
                                    required
                                    onChange={handleChange}
                                    style={{ marginRight: '5px' }}
                                />
                                Yes
                            </label>
                            <label style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="radio"
                                    name="recommendChoice"
                                    value="No"
                                    checked={formData.recommendChoice === "No"}
                                    onChange={handleChange}
                                    required
                                    style={{ marginRight: '5px' }}
                                />
                                No
                            </label>
                        </div>
                        <textarea
                            id="recommendation"
                            name="recommendation"
                            value={formData.recommendation}
                            onChange={handleChange}
                            maxLength="100"
                            style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }}
                            required
                        />
                        <p style={{ paddingLeft: '10px' }}>{formData.recommendation.length}/100</p>
                    </div>



                    <div style={{ marginBottom: '15px', }}>
                        <label htmlFor="comments" style={{ display: 'block' }}>List five different words from the book and define them.*</label>
                        <textarea id="comments" name="comments" value={formData.comments} onChange={handleChange} maxLength="125" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                        <p style={{ paddingLeft: '10px' }}>{formData.comments.length}/125</p>
                    </div>


                    <button type="submit" style={{ background: '#403e75', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}> Submit</button>
                </form>
            </div> */}

            <div></div>

            <div style={{ display: 'flex', flexDirection: 'row', margin: '20px', justifyContent: 'space-between' }}>
                <div style={{ width: '48%' }}>
                    {/* Left Column Form Items */}
                    <form onSubmit={handleSubmit}>

                        <div style={{ marginBottom: '15px' }}>
                            <label className="cursorText" htmlFor="title" style={{ display: 'block', marginBottom: '5px' }}>Title*:</label>
                            <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} maxLength="50" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} required />
                            <p style={{ paddingLeft: '10px' }}>{formData.title.length}/50</p>
                        </div>

                        <div style={{ marginBottom: '15px' }}>
                            <label className="cursorText" htmlFor="author" style={{ display: 'block', marginBottom: '5px' }}>Author*:</label>
                            <input type="text" id="author" name="author" value={formData.author} onChange={handleChange} maxLength="20" style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} required />
                            <p style={{ paddingLeft: '10px' }}>{formData.author.length}/20</p>
                        </div>

                        <div style={{ marginBottom: '15px', }}>
                            <label className="cursorText" htmlFor="description" style={{ display: 'block' }}>Describe the setting of the story.*</label>
                            <textarea id="description" name="description" value={formData.description} onChange={handleChange} maxLength="80" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                            <p style={{ paddingLeft: '10px' }}>{formData.description.length}/80</p>
                        </div>

                        <div style={{ marginBottom: '15px', }}>
                            <label className="cursorText" htmlFor="summary" style={{ display: 'block' }}>Summary of the Plot.*</label>
                            <textarea id="summary" name="summary" value={formData.summary} onChange={handleChange} maxLength="200" style={{ width: '100%', height: '100px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                            <p style={{ paddingLeft: '10px' }}>{formData.summary.length}/200</p>
                        </div>

                        <div style={{ marginBottom: '15px', }}>
                            <label className="cursorText" htmlFor="protagonist" style={{ display: 'block' }}>Who is the protagonist? Describe the character.*</label>
                            <textarea id="protagonist" name="protagonist" value={formData.protagonist} onChange={handleChange} maxLength="150" style={{ width: '100%', height: '100px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                            <p style={{ paddingLeft: '10px' }}>{formData.protagonist.length}/150</p>
                        </div>

                        <div style={{ marginBottom: '15px', }}>
                            <label className="cursorText" htmlFor="antagonist" style={{ display: 'block' }}>Who is the antagonist? Describe the character.*</label>
                            <textarea id="antagonist" name="antagonist" value={formData.antagonist} onChange={handleChange} maxLength="150" style={{ width: '100%', height: '100px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                            <p style={{ paddingLeft: '10px' }}>{formData.antagonist.length}/150</p>
                        </div>

                        <div style={{ marginBottom: '15px', }}>
                            <label className="cursorText" htmlFor="majorconflicts" style={{ display: 'block' }}>What are the major conflicts within the story?*</label>
                            <textarea id="majorconflicts" name="majorconflicts" value={formData.majorconflicts} onChange={handleChange} maxLength="100" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                            <p style={{ paddingLeft: '10px' }}>{formData.majorconflicts.length}/100</p>
                        </div>

                        <div style={{ marginBottom: '15px', }}>
                            <label className="cursorText" htmlFor="minorconflicts" style={{ display: 'block' }}>What are the minor conflicts within the story?*</label>
                            <textarea id="minorconflicts" name="minorconflicts" value={formData.minorconflicts} onChange={handleChange} maxLength="100" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                            <p style={{ paddingLeft: '10px' }}>{formData.minorconflicts.length}/100</p>
                        </div>



                    </form>
                </div>
                <div style={{ width: '48%' }}>
                    {/* Right Column Form Items */}
                    <form onSubmit={handleSubmit}>

                        <div style={{ marginBottom: '15px', }}>
                            <label className="cursorText" htmlFor="turningpoint" style={{ display: 'block' }}>What is the turning point or climax of the story?*</label>
                            <textarea id="turningpoint" name="turningpoint" value={formData.turningpoint} onChange={handleChange} maxLength="100" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                            <p style={{ paddingLeft: '10px' }}>{formData.turningpoint.length}/100</p>
                        </div>

                        <div style={{ marginBottom: '15px', }}>
                            <label className="cursorText" htmlFor="quote" style={{ display: 'block' }}>Write a memorable quote/line/citation from the story and caption it.*</label>
                            <textarea id="quote" name="quote" value={formData.quote} onChange={handleChange} maxLength="150" style={{ width: '100%', height: '100px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                            <p style={{ paddingLeft: '10px' }}>{formData.quote.length}/150</p>
                        </div>

                        <div style={{ marginBottom: '15px', }}>
                            <label className="cursorText" htmlFor="motive" style={{ display: 'block' }}>What is the motive that runs throughout the story?*</label>
                            <textarea id="motive" name="motive" value={formData.motive} onChange={handleChange} maxLength="75" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                            <p style={{ paddingLeft: '10px' }}>{formData.motive.length}/75</p>
                        </div>

                        <div style={{ marginBottom: '15px', }}>
                            <label className="cursorText" htmlFor="theme" style={{ display: 'block' }}>What is the theme that runs throughout the story?*</label>
                            <textarea id="theme" name="theme" value={formData.theme} onChange={handleChange} maxLength="25" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                            <p style={{ paddingLeft: '10px' }}>{formData.theme.length}/25</p>
                        </div>

                        <div style={{ marginBottom: '15px', }}>
                            <label className="cursorText" htmlFor="storyend" style={{ display: 'block' }}>How does the story end?*</label>
                            <textarea id="storyend" name="storyend" value={formData.storyend} onChange={handleChange} maxLength="125" style={{ width: '100%', height: '100px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                            <p style={{ paddingLeft: '10px' }}>{formData.storyend.length}/125</p>
                        </div>

                        <div style={{ marginBottom: '15px' }}>
                            <label className="cursorText" htmlFor="recommendation" style={{ display: 'block', marginBottom: '10px' }}>
                                Would you recommend the book to somebody? Why or why not?*
                            </label>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <label style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="radio"
                                        name="recommendChoice"
                                        value="Yes"
                                        checked={formData.recommendChoice === "Yes"}
                                        required
                                        onChange={handleChange}
                                        className="cursorPointer"
                                        style={{ marginRight: '5px' }}
                                    />
                                    Yes
                                </label>
                                <label style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="radio"
                                        name="recommendChoice"
                                        value="No"
                                        checked={formData.recommendChoice === "No"}
                                        onChange={handleChange}
                                        required
                                        className="cursorPointer"
                                        style={{ marginRight: '5px' }}
                                    />
                                    No
                                </label>
                            </div>
                            <textarea
                                id="recommendation"
                                name="recommendation"
                                value={formData.recommendation}
                                onChange={handleChange}
                                maxLength="100"
                                style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }}
                                required
                            />
                            <p style={{ paddingLeft: '10px' }}>{formData.recommendation.length}/100</p>
                        </div>



                        <div style={{ marginBottom: '15px', }}>
                            <label className="cursorText" htmlFor="comments" style={{ display: 'block' }}>List five different words from the book and define them.*</label>
                            <textarea id="comments" name="comments" value={formData.comments} onChange={handleChange} maxLength="125" style={{ width: '100%', height: '50px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', resize: 'none' }} required></textarea>
                            <p style={{ paddingLeft: '10px' }}>{formData.comments.length}/125</p>
                        </div>

                        <button type="submit" style={{ background: '#403e75', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}> Submit</button>



                    </form>
                </div>
            </div>




            {/* <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Review Status</DialogTitle>
                <DialogContent>
                    <h1>Submitted Successfully</h1>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        OK
                    </Button>

                </DialogActions>
            </Dialog> */}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    bgcolor: "background.paper",
                    // border: "2px solid #000",
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 4
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div style={{ display: "flex", justifyContent: "center" }}>


                            <CheckCircleOutlinedIcon style={{ color: "#FFA700", fontSize: 130 }} />

                        </div>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                // flexDirection: "column",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    rowGap: "2rem",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        rowGap: "1.25rem",
                                    }}
                                >
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        Review submitted Successfully

                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        columnGap: "1rem",
                                    }}
                                >

                                    <YellowButton
                                        onClick={handleClose}
                                    >
                                        Ok
                                    </YellowButton>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </Box>
            </Modal>




        </div >



    );
};

export default Passport_4;
