import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // default storage is local storage
import { userReducer } from "./reducers/userReducer";
import { quizReducer } from "./reducers/quizReducer";
import { navbarReducer } from "./reducers/navbarReducer";
import { bluePrintReducer } from "./reducers/bluePrintReducer";
import { dashboardTableReducer } from "./reducers/dashboardTableReducer";
import { userDetailReducer } from "./reducers/userDetailReducer";
import { assignQuizReducer } from "./reducers/assignQuizReducer";

// Combine all reducers
const rootReducer = combineReducers({
  user: userReducer,
  quiz: quizReducer,
  navbar: navbarReducer,
  bluePrint: bluePrintReducer,
  dashboard: dashboardTableReducer,
  userDetails: userDetailReducer,
  assignQuizParams: assignQuizReducer,
});

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userDetails'] // only persist user reducer
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Initial state
let initialState = {
  user: {
    firstName: localStorage.getItem("firstName") || "",
    lastName: localStorage.getItem("lastName") || ""
  },
};

const middleware = [thunk];

// Create store with persisted reducer
const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

// Create a persistor
const persistor = persistStore(store);

export { store, persistor };
