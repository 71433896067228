import axios from 'axios'

function queType_get(setQuetype){
    let token = sessionStorage.getItem("token");
    let pattern = sessionStorage.getItem("questionPattern");

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question-type/pattern?pattern=${pattern}`,
    {
        headers : {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=> {
        setQuetype(res.data.data)
    })
    .catch(err => console.log(err))
}

export default queType_get