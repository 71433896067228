import React, { useEffect, useState } from "react";
import DashboardLayer from "../../../../components/DashboardLayer/DashboardLayer";
import HorizontalScrollContainer from "../../../../components/HorizontalScrollContainer/HorizontalScrollContainer";
import HorizontallyScrollableContainerV2 from "../../../../components/HorizontallyScrollableContainerV2/HorizontallyScrollableContainerV2";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getProfileDetails } from "../../../API/principal-controller/dashboardApis";
import DashboardBarChartCard from "../../../../components/DashboardBarChartCard/DashboardBarChartCard";
import "./PrincipalDashboardNew.css";
import {
  fetchDashboardChapterwisePerformance,
  fetchDashboardSubjectWisePerformance,
  fetchGradesDetails,
  fetchPrincipalDashboardQuizPerformance,
  fetchPrincipalDashboardQuizPerformanceForTeacher,
  fetchTeachersOptions,
} from "./api/apis";
import DashboardBarChartCardV2 from "../../../../components/DashboardBarChartCardV2/DashboardBarChartCardV2";
import PrincipalCardV1 from "./PrincipalCardV1";
import TeacherGrades from "../../../img/teachergrades.png";
import TotalStudents from "../../../img/totalstudents.png";
import PrincipalCardV2 from "./PrincipalCardV2";
import DashboardGradeModalV2 from "../../../../components/DashboardGradeModal/DashboardGradeModalV2";
import TeacherFilterDropDown from "../../../../components/TeacherDropdown/TeacherDropdown";
import {
  isNOTNullOrUndefined,
  isNullOrUndefined,
} from "../../../../utils/helper";
import SelectDropDownV2 from "./SelectDropDownV2";
import {
  DataStructureSubtopicWithoutSection,
  DataStructureWithSubtopic,
  DataStructureWithoutSubtopic,
  DataStructureWithoutSubtopicWithoutSection,
  getDataStructureFormatted,
} from "./DataHelper";
import HorizontallyScrollableContainerV4 from "../../../../components/HorizontallyScrollableContainerV4/HorizontallyScrollableContainerV4";
import { useSelector } from "react-redux";
import HorizontalScrollContainerV1 from "../../../../components/HorizontalScrollContainer/HorizontalScrollContainerV1";
import axios from "axios";
import DashboardTableData from "../../../../components/DashboardTableData";
import DashboardProgressData from "../../../../components/DashboardProgressData";
import DashboardTabs from "../../DashboardTabs/DashboardTabs";

const PrincipalDashboardNew = () => {
  const [loading, setLoading] = useState(true);

  const [quizPerformanceData, setQuizPerformanceData] = useState([]);
  const [chapterPerformanceData, setChapterPerformanceData] = useState([]);
  const [message, setMessage] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showEmberCategoryMessage, setShowEmberCategoryMessage] =
    useState(false);

  const [gradeOptions, setGradeOptions] = useState([]);

  const [gradeStudentCount, setgradeStudentCount] = useState([]);
  const [showGradeModal, setShowGradeModal] = useState(false);
  const [gradeDetails, setGradeDetails] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [teachersList, setTeachersList] = useState([]);
  const [subjectWisePerformanceData, setSubjectWisePerformanceData] = useState(
    []
  );

  const [subId, setSubId] = useState('');
  const [subList, setSubList] = useState([]);
  const [selectedGradeSectionCard, setSelectedGradeSectionCard] =
    useState(null);
  const [selectedSubjectWiseCard, setSelectedSubjectWiseCard] = useState(null);
  const [isTriggerProgressApi, setIsTriggerProgressApi] = useState(false);

  //if selected  subject wise card is present display the card data from that
  //else display it from selected grade card

  const selectedCardDataForAttemptsDisplay = isNOTNullOrUndefined(
    selectedSubjectWiseCard?.id
  )
    ? selectedSubjectWiseCard
    : selectedGradeSectionCard;

  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const token = sessionStorage.getItem("token");

  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //  return res?.data?.data?.teacher || []
    var result = res?.data?.data?.teacher || {};
    console.log("Basic_info", res?.data?.data?.teacher)
    sessionStorage.setItem("Basic_info", JSON.stringify(result))
    return result
  };

  const isOnTriggerProgressData = () => {
    setIsTriggerProgressApi(!isTriggerProgressApi)
  }

  useEffect(async () => {
    const teacherData = await fetchTeacherData(userName, role);
    sessionStorage.setItem('teacherId', teacherData?.id)
    sessionStorage.setItem('boardId', teacherData?.boardId)
    sessionStorage.setItem('schoolId', teacherData?.school)
    sessionStorage.setItem('branchId', teacherData?.branch)
  }, [])

  const color1 = ["#FA9825", "#EC5683"];
  const color2 = ["#4FD8BF", "#384CE0"];

  const { data: profileData } = useQuery(
    ["profileData", userName, role, token],
    getProfileDetails,
    {
      enabled: !!token,
      refetchOnWindowFocus: false,
    }
  );
  const staffId = profileData?.teacher?.id;

  const fetchGradeDetails = async () => {
    setLoading(true);
    const response = await fetchGradesDetails();
    setGradeDetails(response);
    setLoading(false);
  };

  const fetchGradeOptions = async () => {
    const token = sessionStorage.getItem('token')

    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${staffId}`

    const res = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    return res?.data?.data || []
  }

  const fetchGradeOptionsList = async () => {
    const response = await fetchGradeOptions();
    const list = response?.map((i) => {
      return {
        label: i?.grade,
        value: i?.id,
      };
    });
    setGradeOptions(list);
    setSelectedGrade(list[0]?.value);
  };

  // const fetchTeachersOptionsList = async () => {
  //   const gradeId = selectedGrade?.value || null;
  //   const response = await fetchTeachersOptions(staffId, gradeId);
  //   const list = response?.map((i) => {
  //     return {
  //       label: i?.name,
  //       value: i?.id,
  //     };
  //   });
  //   setTeachersList(list);
  //   setSelectedTeacher(list[0])
  // };

  // const fetchQuizPerformanceTeacher = async () => {
  //   setLoading(true);
  //   const response = await fetchPrincipalDashboardQuizPerformanceForTeacher(
  //     // selectedTeacher?.value,
  //     selectedGrade,
  //     subId
  //   );
  //   saveQuizPerformanceDataTeacher(response);
  // };

  // useEffect(() => {
  //   if (selectedGrade && subId)
  //     fetchQuizPerformanceTeacher();

  // }, [isTriggerProgressApi]);

  // const fetchQuizPerformancePricipal = async () => {
  //   setLoading(true);
  //   const response = await fetchPrincipalDashboardQuizPerformance(
  //     selectedTeacher?.value,
  //     selectedGrade?.value
  //   );
  //   saveQuizPerformanceData(response);
  // };

  // const saveQuizPerformanceData = async (response) => {
  //   let formattedDataWithId = response?.gradeDetails?.map((i) => {
  //     return { ...i, gradeSectionId: `${i?.gradeId}-${i?.sectionId}` };
  //   });
  //   // console.log("test11111111",formattedDataWithId,selectedTeacher);
  //   // setQuizPerformanceData(formattedDataWithId);
  //   setSelectedGradeSectionCard(formattedDataWithId[0]);
  //   setLoading(false);
  // };

  // const saveQuizPerformanceDataTeacher = async (response) => {
  //   setQuizPerformanceData([]);
  //   const cdata = response;
  //   var formatted = getDataStructureFormatted(cdata);
  //   console.log("formatted 4-7-24", formatted);
  //   formatted = formatted?.filter(v => v.skilledSubject === true) || [];
  //   console.log("aftr", formatted)
  //   setQuizPerformanceData(formatted);

  //   // let formattedDataWithId = response?.gradeDetails?.map((i) => {
  //   //     return { ...i, gradeSectionId: `${i?.gradeId}-${i?.sectionId}` }
  //   // })

  //   setSelectedGradeSectionCard(formatted[0]);
  //   setLoading(false);
  // };

  // const fetchSubjectWiseData = async () => {
  //   setSelectedSubjectWiseCard(null);

  //   const response = await fetchDashboardSubjectWisePerformance(
  //     selectedGradeSectionCard?.gradeId,
  //     selectedTeacher?.id,
  //     selectedGradeSectionCard?.sectionId
  //   );
  //   //to do format the data to showcase subtopics and subjects linear
  //   let arr = [];
  //   response?.subjectDetails?.forEach((obj) => {
  //     if (obj?.subTopicDetails?.length > 0) {
  //       obj?.subTopicDetails?.forEach((item) => {
  //         if (item.hasOwnProperty("avgScorePercentageUQ")) {
  //           let objItem = { ...item };
  //           objItem.id = item?.subTopicId;
  //           arr.push(objItem);
  //         }
  //       });
  //     } else if (obj.hasOwnProperty("avgScorePercentageUQ")) {
  //       let objItem = { ...obj };
  //       objItem.id = obj?.subjectId;
  //       arr.push(objItem);
  //     }
  //   });

  //   console.log(arr, "formatted array");
  //   setSubjectWisePerformanceData(arr);

  //   // setSelectedGradeSectionCard(response[0])
  // };

  // const fetchChapterWiseDataForTeacher = async () => {
  //   setLoading(true);
  //   setChapterPerformanceData([]);
  //   const response = await fetchDashboardChapterwisePerformance(
  //     selectedGradeSectionCard?.gradeId,
  //     selectedGradeSectionCard?.subjectId,
  //     selectedTeacher?.value,
  //     selectedGradeSectionCard?.sectionId,
  //     selectedGradeSectionCard?.subtopicId
  //   );
  //   let arr = response?.chapters?.map((item) => {
  //     return {
  //       id: item?.chapterId,
  //       scorePercentage: item?.quizAverage,
  //       averagePercentage: item?.globalQuizAverage,
  //       chapterName: item?.chapter,
  //       emberStudents: item?.emberStudents,
  //       studentPercentageInEmber: item?.studentPercentageInEmber,
  //     };
  //   });

  //   // console.log(arr, 'res chapter')

  //   setChapterPerformanceData(arr);
  // };

  useEffect(() => {
    fetchGradeDetails();
  }, []);

  // useEffect(() => {
  //   fetchTeachersOptionsList();
  // }, [selectedGrade]);

  // useEffect(() => {
  //     if (isNOTNullOrUndefined(selectedTeacher) && isNOTNullOrUndefined(gradeOptions)) setSelectedGrade(gradeOptions[0])
  // }, [gradeOptions, selectedTeacher])

  useEffect(() => {
    fetchGradeOptionsList();
  }, [staffId]);

  // useEffect(() => {
  //   if (isNOTNullOrUndefined(selectedTeacher)) {
  //     fetchQuizPerformanceTeacher();
  //   } else {
  //     fetchQuizPerformancePricipal();
  //   }
  // }, [selectedTeacher, selectedGrade]);

  // useEffect(() => {
  //   if (
  //     isNOTNullOrUndefined(selectedGradeSectionCard) &&
  //     isNullOrUndefined(selectedTeacher)
  //   )
  //     fetchSubjectWiseData();
  //   if (
  //     // isNOTNullOrUndefined(selectedGradeSectionCard) &&
  //     isNOTNullOrUndefined(selectedTeacher)
  //   )
  //     fetchChapterWiseDataForTeacher();
  // }, [selectedGradeSectionCard]);

  const { is_expanded } = useSelector((state) => state.navbar);

  const handleSelectGrade = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedGrade(selectedOption.target.value);
    } else {
      setSelectedGrade(null);
    }
  };

  const isTableSubList = (data) => {
    setSubList(data)
  }

  const isTableSubId = (data) => {
    setSubId(data)
  }

  const isSelectedGradeSectionCard = (data) => {
    setSelectedGradeSectionCard(data)
  }

  return (
    <DashboardLayer>
      <section
        className="prncpl-dash-wrapper"
        style={{ width: is_expanded ? "80vw" : "90vw" }}
      >

        <div className="principal-card-section">
          <div className="principal-count-card">
            <div className="count-card">
              <PrincipalCardV1
                img={TeacherGrades}
                label={"Number of grades in school"}
                count={gradeDetails?.totalGradesPL}
                onLabelClick={(e) => {
                  e.stopPropagation();
                  setShowGradeModal(true);
                }}
              />
            </div>
            <div className="count-card">
              <PrincipalCardV1
                img={TotalStudents}
                label={"Total number of students"}
                count={gradeDetails?.totalStudentsPL || 0}
              />
            </div>
          </div>

          <PrincipalCardV2
            teacher={selectedTeacher?.label}
            color={color1}
            heading={"Unit Quiz Attempts"}
            boxClass={"box orange"}
            message={selectedCardDataForAttemptsDisplay?.unitAttemptRateMessage}
            progressValue={[
              selectedCardDataForAttemptsDisplay?.globalAvgAttemptPercentageUQ ||
              0,
              selectedCardDataForAttemptsDisplay?.avgAttemptPercentageUQ || 0,
            ]}
          />
          <PrincipalCardV2
            teacher={selectedTeacher?.label}
            color={color2}
            heading={"Practice Quiz Attempts"}
            boxClass={"box blue"}
            message={
              selectedCardDataForAttemptsDisplay?.practiceAttemptRateMessage
            }
            progressValue={[
              selectedCardDataForAttemptsDisplay?.globalAvgAttemptPercentagePQ ||
              0,
              selectedCardDataForAttemptsDisplay?.avgAttemptPercentagePQ || 0,
            ]}
          />
        </div>
        {/* <div style={{ paddingBottom: "30px" }}>
          <DashboardTableData
            userType={"Principal"}
            handleSelect={handleSelectGrade}
            gradeList={gradeOptions}
            selectedGrade={selectedGrade}
            isTableSubList={isTableSubList}
            isTableSubId={isTableSubId}
            isOnTriggerProgressData={isOnTriggerProgressData}
            selectedTeacher={selectedTeacher}
          />
        </div> */}
        {/* <div className="progress-section" style={{ marginBottom: "40px" }}>
          <h2 className="dashboard-heading" style={{ marginBottom: "40px" }}>Progress</h2>
          <DashboardProgressData
            gradeList={gradeOptions}
            selectedGrade={selectedGrade}
            subList={subList}
            subId={subId}
            isTriggerProgressApi={isTriggerProgressApi}
          />
        </div> */}
        {/* <div style={{ display: "flex", gap: "20px", alignItems: "center", marginBottom: "20px" }}>
          <h2 className="dashboard-heading">
            Quiz Performance
          </h2>
          <div>
            <TeacherFilterDropDown
              handleSelect={(obj) => {
                if (isNullOrUndefined(obj)) {
                  setSelectedGrade(obj);
                  setSelectedTeacher(obj);
                } else {
                  setSelectedTeacher(obj);
                }
              }}
              selectedOption={
                isNOTNullOrUndefined(selectedTeacher) ? selectedTeacher : ""
              }
              data={teachersList}
            />
          </div>
        </div> */}

        {/* <div style={{ width: "100%", marginTop: "7px", marginBottom: "35px" }}>
          <HorizontalScrollContainer data={quizPerformanceData}>
            {
              console.log("quizPerformanceData", quizPerformanceData)
            }
            {quizPerformanceData?.map((i) => {
              if (i.hasOwnProperty('globalAvgAttemptPercentagePQ')) {
                return (
                  <DashboardBarChartCard
                    key={i?.gradeSectionId}
                    title={`${i?.grade} ${i?.section}`}
                    subject={i?.subject}
                    first_label={"Average"}
                    second_label={"Global"}
                    first_percent={i?.avgScorePercentageUQ}
                    second_percent={i?.globalAvgScorePercentageUQ}
                    selected={
                      selectedGradeSectionCard?.gradeSectionId === i?.gradeSectionId
                    }
                    onCardClick={() => setSelectedGradeSectionCard(i)}
                    labelClickDisabled
                  />
                )
              }
            })}
          </HorizontalScrollContainer>
        </div> */}
        {/* {isNullOrUndefined(selectedTeacher?.value) ? (
          <h2 className="dashboard-heading">Subjectwise Performance</h2>
        ) : null}
        {isNullOrUndefined(selectedTeacher?.value) ? (
          <div
            style={{ width: "100%", marginTop: "7px", marginBottom: "35px" }}
          >
            <HorizontalScrollContainerV1
              numberOfItems={subjectWisePerformanceData?.length}
            >
              {subjectWisePerformanceData?.map((i) => (
                <DashboardBarChartCardV2
                  key={i?.id}
                  title={
                    isNOTNullOrUndefined(i?.subTopic) ? i?.subTopic : i?.subject
                  }
                  first_label={"Average"}
                  second_label={"Global"}
                  first_percent={i?.avgScorePercentageUQ}
                  second_percent={i?.globalAvgScorePercentageUQ}
                  selected={selectedSubjectWiseCard?.id === i?.id}
                  onCardClick={() => setSelectedSubjectWiseCard(i)}
                  onLabelClick={(e) => {
                    e.stopPropagation();
                    setShowMessageModal(true);
                  }}
                />
              ))}
            </HorizontalScrollContainerV1>
          </div>
        ) : (
          <HorizontallyScrollableContainerV4
            displayEmberCategoryMessage={() => {
              setShowEmberCategoryMessage(true);
              setShowMessageModal(true);
            }}
            data={chapterPerformanceData}
          />
        )} */}
        <DashboardTabs
          userType={'Principal'}
          gradeList={gradeOptions}
          selectedGrade={selectedGrade}
          handleSelectGrade={handleSelectGrade}
          isSelectedGradeSectionCard={isSelectedGradeSectionCard} />
      </section>

      <DashboardGradeModalV2
        showMessageModal={showGradeModal}
        title={"Keep Going!"}
        content={
          "You're making progress. Review your answers and try to improve your scores."
        }
        gradeDetails={gradeDetails}
        onMessageModalClose={() => {
          setShowGradeModal(false);
          //   setShowEmberGradeMessage(false);
          setMessage("");
        }}
        isEmberCategoryMessage={false}
      />
    </DashboardLayer>
  );
};

export default PrincipalDashboardNew;
