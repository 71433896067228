import axios from 'axios'

async function City_Controller_Post(CityInputs) {
  let token = sessionStorage.getItem("token");
  var item = {
    countryId: CityInputs.countryId,
    stateId: CityInputs.stateId,
    // districtId: ,
    pincodeId: CityInputs.pincodeId,
    city: CityInputs.city,
  };

    let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/cities`,item,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      // console.log(json)
    })
    .catch(err=>console.log(err))
}

export default City_Controller_Post;
