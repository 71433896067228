import axios from 'axios'

function chapters_last_modified_get(setLastModifiedDate){
    let token = sessionStorage.getItem("token")

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/last-modified-at` ,
    {
        headers: {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setLastModifiedDate(res.data)
    })
    .catch(err => console.log(err))
}
export default chapters_last_modified_get