import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Modal,
	Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { isNOTNullOrUndefined } from "../../../../utils/helper";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	// border: "2px solid #000",
	boxShadow: 24,
	p: 2,
	borderRadius: "10px",
	display: "flex",
	flexDirection: "column",
	rowGap: "1rem",
};
const ReReleaseButton = styled(Button)(() => ({
	color: "#fff",
	width: "7.8vw",
	backgroundColor: "#ed9b00",
	borderRadius: 150,
	fontSize: "0.78vw",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#ed9b00",
		border: 0,
		top: -2,
		boxShadow: "0px 3px 5px #FFA700",
	},
}));


const MessageModal = ({
	open,
	close,
	message
}) => {

	return (
		<Modal
			open={open}
			onClose={close}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{isNOTNullOrUndefined(message) ? <h3 style={{ color: 'tomato', fontSize: 18, textAlign: 'center' }}>{message}</h3> :
						// <CircularProgress />

						<></>}
				</div>
				<Paper
					sx={{
						width: "100%",
						overflow: "hidden",
						boxShadow: "0px 0px 0px 0px",
						display: "flex",
						flexDirection: "column",
						rowGap: "10px",
					}}
				>

				</Paper>
				{isNOTNullOrUndefined(message) ? <div style={{ textAlign: "end" }}>
					<ReReleaseButton onClick={close}>
						Close
					</ReReleaseButton>
				</div> : null}

			</Box>
		</Modal>
	);
};
export default MessageModal;
