import axios from 'axios'

async function content_count_getall(setContentcount) {
    let token = sessionStorage.getItem("token");

  let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/total/count` ,
  {
    headers : {
        "Content-Type" : "appllication/json",
        Authorization : `Bearer ${token}`

    }
  })
  .then((res)=> {
    setContentcount(res.data.data)
  })
  .catch(err=> console.log(err))
}

export default content_count_getall;
