import { LayerRenderStatus, PluginOnAnnotationLayerRender, RenderViewer } from "@react-pdf-viewer/core";
import linkifyElement from "linkify-element";
import { forEach } from "lodash";

const findLinksPlugin = () => {

  console.log('inside findlinker');

  const handleClick = (e) => {
    e.preventDefault();
    // const href = (e.target as HTMLLinkElement).href;
    // store.update('clickedTarget', href);
    // alert('sdfsd')
    // console.log(e)

    const target = e.target;
    if (target.tagName.toLowerCase() === 'a' && (target.href.startsWith('http') || target.href.startsWith('https'))) {
        window.open(target.href, '_blank');

    }
};

  const findLinks = (e) => {
   // console.log(">>>>>>>", e, e.status, LayerRenderStatus.DidRender)
    // if (e.status !== LayerRenderStatus.DidRender) {
    //   return;
    // }


    e?.annotations?.forEach(link => {
    //  console.log(link)
      
    });


    e.container.querySelectorAll('a[data-target="external"]').forEach((link) => {

     // console.log(link)
      link.addEventListener('click', handleClick);
  });

    
    // e?.annotations?.forEach(link => {
    //   console.log(link)
      
    // });

    // `e.ele` represents the element containing all text elements in each page
    // Find all text elements

      // `rpv-core__text-layer-text` is the CSS class of each text element
    // e?.ele
    //   .querySelectorAll(".rpv-core__annotation-layer")
    //   .forEach((textEle) => {
    //     console.log(">>>>>>>>>>>>>",textEle)
    //     linkifyElement(textEle, {
    //       attributes: {
    //         // Custom styles
    //         style: "color: red; text-decoration: none;",
    //         // Open link in new tab
    //         target: "_blank"
    //       }
    //     });
    //   });
  };

  return {
  //  onTextLayerRender: findLinks
    onAnnotationLayerRender: findLinks
   // renderViewer,
  };
};

export default findLinksPlugin;
