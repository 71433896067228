import axios from "axios";

export const rejectBluePrintWithComment = async (comment, id) => {
  const token = sessionStorage.getItem("token");
  let data = { status: "REJECTED" };
  data.id = id;
  data.comment = comment;
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/blueprint/review`;
  const res = await axios.put(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data || [];
};

export const approveBluePrint = async (id) => {
  const token = sessionStorage.getItem("token");
  let data = { status: "APPROVED", comment: "" };
  data.id = id;
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/blueprint/review`;
  const res = await axios.put(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data || [];
};
