import axios from 'axios'

function training_subtraining_table_post(categoryInputs){
    let token = sessionStorage.getItem("token");
    var item = {
        category : categoryInputs.category ,
        subCategories : [categoryInputs.subCategories],
    };

    const result = axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training-category`, item ,
    {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          validateStatus() { return true } 
    })
    .then((res)=>{
    })
}

export default training_subtraining_table_post;