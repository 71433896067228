import React, { useEffect, useState } from 'react'
import axios from 'axios'
import GetLastModifiedAt from '../../../API/token-controller/GetLastModifiedAt'
import { useLocation, useNavigate } from 'react-router-dom'
import TopBar from '../../../../components/common/TopBar'
import '../../../css/Student/Student_Enrich_Quiz.css'
import CordinatorReleaseModal from './CordinatorReleaseModal'
import AssignQuizReReleaseModal from './Modal/AssignQuizReReleaseModal'
import QuestionModal from '../../QuestionModal'
import {
  isNOTNullOrUndefined,
  isNullOrUndefined
} from '../../../../utils/helper'
import MessageModal from '../common/MessageModal'
import SucessModal from './Modal/SucessModal'
import {
  fetchAcademicYearData,
  fetchChapterDetails,
  fetchChapterListData,
  fetchGradeSectionList,
  fetchQuizList,
  fetchQuizSectionsData,
  fetchSubjectList,
  fetchTeacherData,
  fetchPlanDetails,
  fetchRmlockUnlockData
} from './api/fetchCalls'
import FullPageLoader from '../common/FullPageLoader'
import QuizCardViewOptimised from './QuizCardViewOptimised'
import ChapterViewOptimised from './ChapterViewOptimised'
import { useDispatch, useSelector } from 'react-redux'
import { removeSelectedAssignQuizParams, setSelectedAssignQuizParams } from '../../../../actions/assignQuizAction'

const AssignQuizContentOptimised = () => {
  const navigate = useNavigate();
  const [questionModalData, setQuestionModalData] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showReleaseModal, setShowReleaseModal] = useState(false)
  const [showReReleaseModal, setShowReReleaseModal] = useState(false)
  const [modifiedDate, setModifiedDate] = useState({})
  const [subjectList, setSubjectList] = useState([])
  const [showSubTopic, setShowSubTopic] = useState(false)
  const [selectedGrade, setSelectedGrade] = useState('')
  const [selectedSubject, setSelectedSubject] = useState('')
  const [selectedSection, setSelectedSection] = useState('')
  const [selectedSubTopic, setSelectedSubTopic] = useState('')
  const [academicYearList, setAcademicYearList] = useState([])
  const [selectedAcademicYear, setSelectedAcademicYear] = useState('')
  const [allQuizData, setAllQuizData] = useState([])
  const [selectedChapter, setSelectedChapter] = useState('')
  const [chapterList, setChapterList] = useState([])
  const [selectedQuizId, setSelectedQuizId] = useState('')
  const [selectedPreviewId, setSelectedPreviewId] = useState('')
  const [isError, setIsError] = useState(false)
  const [quizIds, setQuizIds] = useState([])
  const [quizDuraction, setQuizDuration] = useState('')
  const [selectedSectionReleaseData, setSelectedSectionReleaseData] = useState(
    []
  )
  const [isSucessModal, setSucessModal] = useState(false)
  const [quizType, setQuizType] = useState('')
  const [SucessMessage, setSucessMessage] = useState(
    'Quiz Released Sucessfully'
  )
  const [selectedSectionName, setSelectedSectionName] = useState('')

  const [isCheckingWhetherChapterIsEnded, setisCheckingWhetherChapterIsEnded] =
    useState(false)

  const userName = localStorage.getItem('userName')
  const role = localStorage.getItem('role')

  const [teacherId, setTeacherId] = useState('')
  const [gradeSection, setGradeSection] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isQuizListView, setIsQuizListView] = useState(false)
  const [sectionList, setSectionList] = useState([])
  const location = useLocation()
  const token = sessionStorage.getItem('token')

  const [rmLockUnlockDatas, setRmLockUnlockDatas] = useState([]);
  const [planData, setPlanData] = useState([]);

  const dispatch = useDispatch();
  const exitBtnParams = useSelector(state => state?.assignQuizParams?.assignQuizParams);

  //first call
  useEffect(async () => {
    firstRender()
    return () => {
      cleanupOnUnmount()
    }
  }, [token])

  const cleanupOnUnmount = () => {
    setSelectedGrade('')
    setSelectedSection('')
    setSelectedSubject('')
    sessionStorage.removeItem('sectionId')
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
    dispatch(removeSelectedAssignQuizParams);
  }

  useEffect(() => {
    console.log("assignquizcontentoptimised.js --------------")
    const fethPlanData = async () => {
      const branchId = sessionStorage.getItem("branchId")
      if (branchId) {
        const planDatas = await fetchPlanDetails();
        setPlanData(planDatas);
      }
    };

    fethPlanData();
  }, []);

  useEffect(() => {
    const fetchRmData = async () => {
      const rmDataList = await fetchRmlockUnlockData(selectedGrade);
      // console.log("rmDataList", rmDataList)
      setRmLockUnlockDatas(rmDataList);
    };

    fetchRmData();
  }, [planData, selectedGrade, selectedSubject]);

  const subjectID = sessionStorage.getItem("subjectId");
  const idArray = rmLockUnlockDatas.map((item) => item.subjectId)
  const activeStatusArray = rmLockUnlockDatas.map((item) => item.active);
  const foundIndex = idArray.indexOf(subjectID);

  let isActive = false;
  if (foundIndex !== -1) {
    isActive = activeStatusArray[foundIndex];
  }

  const isEmpty = (obj) => Object.keys(obj).length === 0;

  const firstRender = async () => {
    setIsPageLoading(true)
    const teacherData = await fetchTeacherData(userName, role)
    sessionStorage.setItem('teacherId', teacherData.id)
    sessionStorage.setItem('boardId', teacherData.boardId)
    sessionStorage.setItem('schoolId', teacherData.school)
    sessionStorage.setItem('branchId', teacherData.branch)
    fetchPlanDetails()
    fetchRmlockUnlockData()
    setTeacherId(teacherData)
    await GetLastModifiedAt(setModifiedDate)
    const gradeSectionList = await fetchGradeSectionList(teacherData.id)
    setGradeSection(gradeSectionList)
    const academic = await fetchAcademicYearData()
    setAcademicYearList(academic)
    const storedAcademicYearId = sessionStorage.getItem('academicYearId')
    if (storedAcademicYearId) {
      setSelectedAcademicYear(storedAcademicYearId)
    } else if (academic?.length > 0) {
      setSelectedAcademicYear(academic[0].id)
      sessionStorage.setItem('academicYearId', academic[0].id)
    }
    // formal assessment quiz safety purpose
    // console.log("assign quiz back")
    sessionStorage.setItem("isBack", false);
    sessionStorage.setItem("isBackValue", null)

    // checking for data from teach page
    if (location?.state && location?.state !== null) {
      // console.log("location state", location?.state)
      setIsQuizListView(true)
      const preFillData = location?.state
      await populateFilters(preFillData, gradeSectionList, teacherData.id)
    } else if (!isEmpty(exitBtnParams) && exitBtnParams?.gradeId) {
      // console.log("location state", exitBtnParams)
      setIsQuizListView(true)
      const preFillData = exitBtnParams
      await populateFilters(preFillData, gradeSectionList, teacherData.id)
    }
    else {
      //auto population
      await autoPopulate(gradeSectionList, teacherData.id)
    }
    setIsPageLoading(false)
  }

  //population in first render
  const autoPopulate = async (gradeSectionList, teacherId) => {
    const gradeSectionFirstElement = gradeSectionList[0]
    const sectionfirstElement = gradeSectionFirstElement?.sections[0]
    let subjectParams = {
      gradeId: gradeSectionFirstElement?.id,
      sectionId: sectionfirstElement?.id,
      teacherId: teacherId,
      menuName: "ASSIGN_QUIZ"
    }
    var subjectListFetched = await fetchSubjectList(subjectParams)
    console.log("subjectListFetched", subjectListFetched)
    subjectListFetched = subjectListFetched?.filter(v => v.skilledSubject === true) || [];
    // console.log("aftr", subjectListFetched)
    setSubjectList(subjectListFetched)
    setSectionList(gradeSectionFirstElement?.sections)

    setSelectedGrade(gradeSectionFirstElement?.id)
    setSelectedSection(sectionfirstElement?.id)
    setSelectedSubject(subjectListFetched[0])
    setSelectedSubTopic(subjectListFetched[0]?.subTopics[0]?.id)

    let params = {
      subjectId: subjectListFetched[0]?.subjectId,
      gradeId: gradeSectionFirstElement?.id,
      subTopicId: subjectListFetched[0]?.subTopics[0]?.id
    }
    // console.log("params", params)
    const chaptersListFetched = await fetchChapterListData(params)
    setChapterList(chaptersListFetched)
    sessionStorage.setItem('gradeId', gradeSectionFirstElement?.id)
    sessionStorage.setItem('sectionId', sectionfirstElement?.id)
    sessionStorage.setItem('subjectId', subjectListFetched[0]?.subjectId)
    sessionStorage.setItem(
      'subTopicId',
      subjectListFetched[0]?.subTopics[0]?.id
    )
  }

  //Manual population
  const populateFilters = async (preFillData, gradeSectionList, teacherId) => {
    const preFillGradeSection = gradeSectionList?.find(
      item => item.id === preFillData.gradeId
    )
    const preFilledSection = preFillGradeSection?.sections?.find(
      item => item.id === preFillData.sectionId
    )
    let subjectParams = {
      gradeId: preFillGradeSection?.id,
      sectionId: preFilledSection?.id,
      teacherId: teacherId,
      menuName: "ASSIGN_QUIZ"
    }
    var subjectListFetched = await fetchSubjectList(subjectParams);
    // console.log("4-7-24", subjectListFetched)
    subjectListFetched = subjectListFetched?.filter(v => v.skilledSubject === true) || [];
    // console.log("aftr", subjectListFetched)
    const subjectPreSelected = subjectListFetched.find(
      i => i.subjectId === preFillData.subjectId
    )
    //check whether the section
    const selectedSubjectContainsSelectedSubtopic =
      subjectPreSelected?.subTopics?.find(
        item => item.id === preFillData?.subTopicId
      )
    // console.log("subjectListFetched", subjectListFetched)
    setSubjectList(subjectListFetched)
    setSectionList(preFillGradeSection?.sections)

    setSelectedGrade(preFillGradeSection?.id)
    setSelectedSection(preFilledSection?.id)
    setSelectedSubject(subjectPreSelected)

    if (isNOTNullOrUndefined(selectedSubjectContainsSelectedSubtopic)) {
      setSelectedSubTopic(selectedSubjectContainsSelectedSubtopic?.id)
      sessionStorage.setItem(
        'subTopicId',
        selectedSubjectContainsSelectedSubtopic?.id
      )
    }

    let params = {
      subjectId: subjectPreSelected?.subjectId,
      gradeId: preFillGradeSection?.id,
      subTopicId: selectedSubjectContainsSelectedSubtopic?.id
    };
    // console.log("params", params)
    const chaptersListFetched = await fetchChapterListData(params)
    setChapterList(chaptersListFetched)
    const chapterObject = chaptersListFetched?.filter(
      item => item.id === preFillData.chapterId
    )[0]
    setSelectedChapter(chapterObject)
    sessionStorage.setItem('chapterId', chapterObject?.id)
    sessionStorage.setItem('gradeId', preFillGradeSection?.id)
    sessionStorage.setItem('sectionId', preFilledSection?.id)
    sessionStorage.setItem('subjectId', subjectPreSelected?.subjectId)
    dispatch(removeSelectedAssignQuizParams({}));
  }

  //fetching chapter list
  useEffect(async () => {
    if (!isPageLoading) {
      if (
        isNOTNullOrUndefined(selectedGrade) &&
        isNOTNullOrUndefined(selectedSection) &&
        isNOTNullOrUndefined(selectedSubject)
      ) {
        let params = {};
        params = {
          subjectId: selectedSubject.subjectId,
          gradeId: selectedGrade,
          subTopicId: selectedSubTopic
        }
        console.log("params", params, subjectList)
        const chaptersfetched = await fetchChapterListData(params)
        setChapterList(chaptersfetched)
      }
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedSubTopic])

  //fetching quiz list
  useEffect(async () => {
    if (isQuizListView && !isSucessModal) {
      if (
        isNOTNullOrUndefined(selectedGrade) &&
        isNOTNullOrUndefined(selectedSection) &&
        isNOTNullOrUndefined(selectedSubject?.subjectId) &&
        isNOTNullOrUndefined(selectedChapter?.id)
      ) {
        setAllQuizData([])
        let params = {
          gradeId: selectedGrade,
          sectionId: selectedSection,
          chapterId: selectedChapter?.id,
          academicYearId: selectedAcademicYear,
          subTopicId: selectedSubTopic,
          subjectId: selectedSubject?.subjectId
        }
        const quizListfetched = await fetchQuizList(params)
        // console.log("setAllQuizData", quizListfetched, selectedSubject)
        setAllQuizData(quizListfetched)
      }
    }
  }, [
    selectedGrade,
    selectedSection,
    selectedSubject,
    selectedSubTopic,
    isQuizListView,
    selectedChapter,
    isSucessModal
  ])

  const onChapterClick = async item => {
    //add quiz release data here to release the  quiz
    const gradeId = selectedGrade
    const sectionId = selectedSection
    const subjectId = selectedSubject?.subjectId
    const subTopicId = selectedSubTopic
    const academicYearId = selectedAcademicYear
    const chapterId = item.id
    const preFillData = {
      gradeId,
      sectionId,
      subjectId,
      subTopicId,
      academicYearId,
      chapterId
    }
    await populateFilters(preFillData, gradeSection)
    setIsQuizListView(true)
  }

  const handleChangeGrade = e => {
    const selectedId = e.target.value
    setSelectedGrade(selectedId)
    sessionStorage.setItem('gradeId', selectedId)
    const sections = gradeSection.find(i => i.id === selectedId)?.sections
    setSelectedSubject('')
    setSelectedSection('')
    setSelectedSubTopic('')
    setSectionList(sections)
    sessionStorage.removeItem('sectionId')
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const handleChangeSection = async event => {
    const secId = event.target.value
    setSelectedSection(secId)
    setSelectedChapter('')
    setSelectedSubject('')
    setSelectedSubTopic("")
    setAllQuizData([])
    let subjectParams = {
      gradeId: selectedGrade,
      sectionId: secId,
      menuName: "ASSIGN_QUIZ"
    }
    var subjectsFetchList = await fetchSubjectList(subjectParams);
    console.log("subjectsFetchList", subjectsFetchList)
    subjectsFetchList = subjectsFetchList?.filter(v => v.skilledSubject === true) || [];
    // console.log("aftr", subjectsFetchList)
    setSubjectList(subjectsFetchList)
    sessionStorage.removeItem('chapterId')
    sessionStorage.setItem('sectionId', event.target.value)
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const handleChangeSubject = event => {
    console.log("handleChangeSubject caled", event.target.value, subjectList)
    const subject = subjectList?.find(
      item => item.subjectId === event.target.value
    );
    console.log("subject", subject)
    console.log(subject?.hideSubtopics, subject.subjectId)
    if (subject && subject?.hideSubtopics) {
      setShowSubTopic(false);
      console.log("false")
      // alert("pac false");
    } else {
      console.log("true")
      setShowSubTopic(true);
      // alert("pac true");
    }
    console.log(subject);
    // alert(subject)
    setSelectedSubject(subject)
    setSelectedChapter('')
    setAllQuizData([])
    if (subject?.subTopics?.length > 0) {
      if (subject && subject?.hideSubtopics) {
        setShowSubTopic(false);
        console.log("false")
        setSelectedSubTopic("")
        console.log("setting subtopic in session not here")
      } else {
        console.log("true")
        console.log("setting subtopic in session here")
        setSelectedSubTopic(subject?.subTopics[0]?.id)
        sessionStorage.setItem('subTopicId', subject?.subTopics[0]?.id)
      }

    } else {
      setSelectedSubTopic("")
      sessionStorage.removeItem('subTopicId')
    }
    sessionStorage.setItem('subjectId', event.target.value)
    sessionStorage.removeItem('chapterId')
  }

  const handleChangeChapter = event => {
    setSelectedChapter(
      chapterList?.find(item => item.id === event.target.value)
    )
    sessionStorage.setItem('chapterId', event.target.value)
  }

  const handleChangeSubtopic = event => {
    setSelectedSubTopic(event.target.value)
    setSelectedChapter('')
    sessionStorage.setItem('subTopicId', event.target.value)
  }

  /////////////////////////////////////////
  const onPreviewClick = async item => {
    console.log("preview clicked")
    await setQuestionModalData(item)
    // await setShowModal(true)
    sessionStorage.setItem("selectedquestionModal", JSON.stringify(item));
    console.log(localStorage.getItem("role"))
    //for coordinator it is dashboard/
    setShowModal(false)
    setQuestionModalData(null)
    setSelectedQuizId('')

    let params = {
      academicYearId: selectedAcademicYear,
      chapterId: selectedChapter?.id,
      gradeId: selectedGrade,
      sectionId: selectedSection,
      subTopicId: selectedSubTopic,
      subjectId: selectedSubject?.subjectId,
    }
    dispatch(setSelectedAssignQuizParams(params));
    console.log(localStorage.getItem("role"), location?.state?.from, location?.state?.from === "Teach")
     // no condition here for teacher bcz that is seperate file
    if (localStorage.getItem("role") == "PRINCIPAL") {
      navigate(`../quiz`);
      sessionStorage.setItem("redirectUrl", 'principal/assign quiz');
    } else {
      if (location?.state?.from === "Teach") {
        location.state.from = null
        navigate(`../quiz`);
        sessionStorage.setItem("redirectUrl", '/cordinator/assign-quiz');
      } else {
        navigate(`../dashboard/quiz`);
        sessionStorage.setItem("redirectUrl", '/cordinator/assign-quiz');
      }
    }

  }

  const onReleaseClick = item => {
    checkIfChapterEnded(item)
  }

  // function to handle Re-release
  const OpenReRelease = item => {
    setSelectedQuizId(item)
    setShowReReleaseModal(true)
    setShowReleaseModal(false)
  }

  const previewHandler = async () => {
    setShowReleaseModal(false)
    setSelectedQuizId(selectedPreviewId)
    setShowModal(true)
  }

  const releaseQuiz = async quizid => {
    let params = {
      gradeId: selectedGrade,
      quizid: quizid,
      academicYearId: selectedAcademicYear,
      subjectId: selectedSubject?.subjectId,
      sectionId: selectedSection
    }
    const quizIdsData = await fetchQuizSectionsData(params)
    setQuizIds(quizIdsData)
  }

  const checkIfChapterEnded = async item => {
    setChapterStatusMessage(null)
    setisCheckingWhetherChapterIsEnded(true)
    let params = {
      gradeId: selectedGrade,
      sectionId: selectedSection,
      subjectId: selectedSubject?.subjectId,
      academicYearId: selectedAcademicYear,
      subTopicId: selectedSubTopic,
      chapterId: selectedChapter?.id
    }
    const endDateFetched = await fetchChapterDetails(params)
    if (isNOTNullOrUndefined(endDateFetched)) {
      setisCheckingWhetherChapterIsEnded(false)
      setShowReleaseModal(true)
      setSelectedPreviewId(item?.id)
      setSelectedQuizId(item?.id)
      setQuizDuration(item?.duration)
      releaseQuiz(item?.id)
    } else {
      setChapterStatusMessage('Study of Chapter not Completed')
    }
  }

  const onChapterStatusModalClose = () => {
    setisCheckingWhetherChapterIsEnded(false)
    setChapterStatusMessage(null)
  }

  const [chapterStatusMessage, setChapterStatusMessage] = useState(null)
  const [isLoadingList, setIsLoadingList] = useState(false)
  // if (isPageLoading) {
  //   return <FullPageLoader />
  // }

  return (
    <div style={{ width: '100%' }}>
      <SucessModal
        open={isSucessModal}
        onClose={() => {
          setSucessModal(false)
        }}
        title={SucessMessage}
        selectedSectionName={selectedSectionName}
      />
      <TopBar
        title={'Home'}
        subSubTitle={'Assign Quiz '}
        modifiedDate={modifiedDate}
        nav1={'principal/dashboard'}
        role={role}
      />

      {isQuizListView ? (
        <QuizCardViewOptimised
          selectedGrade={selectedGrade}
          gradeSection={gradeSection}
          sectionList={sectionList}
          handleChangeGrade={handleChangeGrade}
          selectedSection={selectedSection}
          handleChangeSection={handleChangeSection}
          selectedSubject={selectedSubject}
          subjectList={subjectList}
          selectedSubTopic={selectedSubTopic}
          onPreviewClick={onPreviewClick}
          onReleaseClick={onReleaseClick}
          chapterList={chapterList}
          handleChangeChapter={handleChangeChapter}
          selectedChapter={selectedChapter}
          academicYearList={academicYearList}
          selectedAcademicYear={selectedAcademicYear}
          allQuizData={allQuizData}
          handleChangeSubject={handleChangeSubject}
          handleChangeSubtopic={handleChangeSubtopic}
          OpenReRelease={OpenReRelease}
          setQuizType={setQuizType}
          isLoadingList={isLoadingList}
          // Rm Lock/Unlock Plan template Logic
          isActive={isActive}
          showSubTopic={showSubTopic}
        />
      ) : (
        <ChapterViewOptimised
          selectedGrade={selectedGrade}
          sectionList={sectionList}
          handleChangeGrade={handleChangeGrade}
          gradeSection={gradeSection}
          selectedSection={selectedSection}
          handleChangeSection={handleChangeSection}
          selectedSubject={selectedSubject}
          handleChangeSubject={handleChangeSubject}
          subjectList={subjectList}
          selectedSubTopic={selectedSubTopic}
          selectedAcademicYear={selectedAcademicYear}
          setSelectedAcademicYear={setSelectedAcademicYear}
          academicYearList={academicYearList}
          isError={isError}
          updatedData={chapterList}
          onChapterClick={onChapterClick}
          handleChangeSubtopic={handleChangeSubtopic}
          showSubTopic={showSubTopic}
        />
      )}
      {/* {questionModalData && showModal && (
        <QuestionModal
          open={showModal}
          close={() => {
            setShowModal(false)
            setQuestionModalData(null)
            setSelectedQuizId('')
          }}
          data={questionModalData}
        />
      )} */}
     
      {showReleaseModal && (
        <CordinatorReleaseModal
          open={showReleaseModal}
          close={() => {
            setShowReleaseModal(false)
            setSelectedQuizId('')
            setSelectedPreviewId('')
          }}
          previewHandler={previewHandler}
          fetchSection={quizIds}
          selectedQuizId={selectedQuizId}
          quizDuraction={quizDuraction}
          teacherId={teacherId.id}
          OpenReRelease={OpenReRelease}
          setSucessModal={setSucessModal}
          setSelectedSectionReleaseData={setSelectedSectionReleaseData}
          quizType={quizType}
          setSucessMessage={setSucessMessage}
          setSelectedSectionName={setSelectedSectionName}
        />
      )}
      {showReReleaseModal && (
        <AssignQuizReReleaseModal
          open={showReReleaseModal}
          close={() => setShowReReleaseModal(false)}
          selectedQuizId={selectedQuizId}
          teacherId={teacherId?.id}
          selectedSectionReleaseData={selectedSectionReleaseData}
          setSucessModal={setSucessModal}
          setSucessMessage={setSucessMessage}
          chapterId={selectedChapter}
        />
      )}{' '}
      {isCheckingWhetherChapterIsEnded ? (
        <MessageModal
          message={chapterStatusMessage}
          open={isCheckingWhetherChapterIsEnded}
          close={onChapterStatusModalClose}
        />
      ) : null}
    </div>
  )
}
export default AssignQuizContentOptimised
