import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import "./Loader.css";
const whiteAddButton = (props) => {
  const AddButton = styled(Button)(() => ({
    color: "#7A7A7A",
    backgroundColor: 'white',
    borderRadius: 150 , 
    border : '1px solid #7A7A7A', 
    padding:'7px',
    minWidth : '100px',
    height : '32px',
    margin:'3px 8px',
    "&:hover": {
      color: "black",
      backgroundColor: 'white',
      border : '1px solid #7A7A7A',
      top: -2,
      boxShadow: "0px 3px 5px grey",
    },
  }));
  return <AddButton onClick={props.onClick}>{props.children}</AddButton>;
};
export default whiteAddButton;
