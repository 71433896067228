import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import icon from '../../../img/Learning.png'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import axios from 'axios'
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import dashboardContentStyles from '../../css/dashboardContent.module.css'

function FilterBar({
  setGrades,
  grades,
  setSubject,
  subjects,
  boards,
  setBoard,
  handleChange,
  title,
  setQuizType,
  quizTypes,
  subTopics,
  showSubTopic
}) {
  const token = sessionStorage.getItem('token')
  const fetchGrade = () => {
    axios
      .get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        setGrades(res.data.data)
      })
  }

  const fetchSubject = () => {
    axios
      .get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/all`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        setSubject(res.data.data)
      })
  }

  const fetchBoard = () => {
    axios
      .get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/all`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        setBoard(res.data.data)
      })
  }

  const fetchQuizType = () => {
    axios
      .get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz-types`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setQuizType(res.data.data);
      });
  };

  useEffect(() => {
    fetchGrade()
    fetchSubject()
    fetchBoard()
    fetchQuizType()
  }, [])

  const filterStyle = {
    "&.MuiOutlinedInput-root": {
      "& > fieldset": {
        border: "1px solid #D6D5DF !important",
        fontSize: "13px !important",
      }
    },
    "&.MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        border: "1px solid #272727 !important",
      }
    },
    fontSize: "14px !important",
    fontFamily: "Poppins",
    borderRadius: 150,
    background: "#fff",
    height: '100%'
  }


  const labelStyle = {
    fontSize: "14px",
    "&.Mui-focused": {
      color: "orange",
      fontWeight: 600,
      fontFamily: "Poppins",
      fontSize: "13px",

    }
  }



  return (
    <Container>
      <LettSection>
        <Icon>
          <Img src={icon} alt='Icon' />
        </Icon>
        <TitleLabel>{title}</TitleLabel>
      </LettSection>
      <RightSection>
        <InputSection>
          <FormControl fullWidth>
            <InputLabel
              sx={labelStyle}
              id='demo-simple-select-standard-label'
              style={{
								color:'orange'
							  }}
            >
              Board
            </InputLabel>
            <Select
              style={{ width: "113px" }}
              labelId='demo-simple-select-standard-label'
              onChange={handleChange}
              name='board'
              sx={filterStyle}
              input={<OutlinedInput label="Board" />}

            >
              <MenuItem sx={{ borderBottom: '1px solid #e9e9e9' }} value=''>
                None
              </MenuItem>
              {boards?.map((data, i) => (
                <MenuItem
                value={data.id}
                key={i}
                id={`az-revisionadmin-boarddropdown-${data.id.toLowerCase().replace(/ /g, "_")}`}
                className={
                  dashboardContentStyles.menu_item
                }
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: 'orange',
                    color: "#FFFFFF"
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: '#fcedc2',
                    color: "#000000"
                  },
                  "&:not(.Mui-selected):hover": {
                    backgroundColor: '#fcedc2',
                    color: '#000000',
                  },
                }}
                >
                  {data.board}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputSection>
        <InputSection>
          <FormControl fullWidth>
            <InputLabel
              sx={labelStyle}
              id='demo-simple-select-standard-label'
              style={{
								color:'orange'
							  }}
            >
              Grade
            </InputLabel>
            <Select
              labelId='demo-simple-select-standard-label'
              onChange={handleChange}
              name='grade'
              sx={filterStyle}
              style={{ width: "170px" }}
              input={<OutlinedInput label="Grade" />}

            >
              <MenuItem sx={{ borderBottom: '1px solid #e9e9e9' }} value=''>
                None
              </MenuItem>
              {grades?.map((data, i) => (
                <MenuItem
                value={data.id}
                key={i}
                id={`az-revisionadmin-gradedropdown-${data.id.toLowerCase().replace(/ /g, "_")}`}
                className={
                  dashboardContentStyles.menu_item
                }
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: 'orange',
                    color: "#FFFFFF"
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: '#fcedc2',
                    color: "#000000"
                  },
                  "&:not(.Mui-selected):hover": {
                    backgroundColor: '#fcedc2',
                    color: '#000000',
                  },
                }}
                >
                  {data.grade}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputSection>
        <InputSection>
          {' '}
          <FormControl fullWidth>
            <InputLabel
              sx={labelStyle}
              id='demo-simple-select-standard-label'
              style={{
								color:'orange'
							  }}
            >
              Subject
            </InputLabel>
            <Select
              labelId='demo-simple-select-standard-label'
              onChange={handleChange}
              name='subject'
              sx={filterStyle}
              style={{ width: "156px" }}
              input={<OutlinedInput label="Subject" />}

            >
              <MenuItem sx={{ borderBottom: '1px solid #e9e9e9' }} value=''>
                None
              </MenuItem>
              {subjects?.map((data, i) => (
                <MenuItem
                value={data.id}
                key={i}
                id={`az-revisionadmin-subjectdropdown-${data.id.toLowerCase().replace(/ /g, "_")}`}
                className={
                  dashboardContentStyles.menu_item
                }
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: 'orange',
                    color: "#FFFFFF"
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: '#fcedc2',
                    color: "#000000"
                  },
                  "&:not(.Mui-selected):hover": {
                    backgroundColor: '#fcedc2',
                    color: '#000000',
                  },
                }}
                >
                  {data.subject}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputSection>
        {/* <InputSection>
					{" "}
					<FormControl fullWidth>
						<InputLabel
							sx={labelStyle}
							id="demo-simple-select-standard-label"
						>
							Subtopic
						</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							onChange={handleChange}
							name="subTopic"
							sx={filterStyle}
            style={{width:"170px"}}
            input={<OutlinedInput  label="Subtopic" />}

						>
							<MenuItem
								sx={{ borderBottom: "1px solid #e9e9e9" }}
								value=""
							>
								None
							</MenuItem>
							{subTopics?.map((data, i) => (
								<MenuItem
									sx={{ borderBottom: "1px solid #e9e9e9" }}
									value={data.id}
									key={i}
								>
									{data.subTopic}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</InputSection> */}
        {showSubTopic && (
          <InputSection>

            <FormControl fullWidth>
              <InputLabel sx={labelStyle} id="demo-simple-select-standard-label"
              style={{
								color:'orange'
							  }}>
                Subtopic
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                onChange={handleChange}
                name="subTopic"
                sx={filterStyle}
                style={{ width: "170px" }}
                input={<OutlinedInput label="Subtopic" />}
              >
                <MenuItem sx={{ borderBottom: "1px solid #e9e9e9" }} value="">
                  None
                </MenuItem>
                {subTopics?.map((data, i) => (
                  <MenuItem
                  value={data.id}
									key={i}
									id={`az-revisionadmin-subtopicdropdown-${data.id.toLowerCase().replace(/ /g, "_")}`}
									className={
										dashboardContentStyles.menu_item
									}
									sx={{
										"&.Mui-selected": {
											backgroundColor: 'orange',
											color: "#FFFFFF"
										},
										"&.Mui-selected:hover": {
											backgroundColor: '#fcedc2',
											color: "#000000"
										},
										"&:not(.Mui-selected):hover": {
											backgroundColor: '#fcedc2',
											color: '#000000',
										},
									}}
                  >
                    {data.subTopic}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </InputSection>
        )}
        <InputSection>
          {' '}
          <FormControl fullWidth>
            <InputLabel
              sx={labelStyle}
              id='demo-simple-select-standard-label'
              style={{
								color:'orange'
							  }}
            >
              Quiz Type
            </InputLabel>
            <Select
              labelId='demo-simple-select-standard-label'
              onChange={handleChange}
              name='quiz_type'
              sx={filterStyle}
              style={{ width: "170px" }}
              input={<OutlinedInput label="Quiz Type" />}

            >
              <MenuItem sx={{ borderBottom: '1px solid #e9e9e9' }} value=''>
                None
              </MenuItem>
              {quizTypes?.map((data, i) => (
                <MenuItem
                value={data.id}
                key={i}
                id={`az-revisionadmin-quiztypedropdown-${data.id.toLowerCase().replace(/ /g, "_")}`}
                className={
                  dashboardContentStyles.menu_item
                }
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: 'orange',
                    color: "#FFFFFF"
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: '#fcedc2',
                    color: "#000000"
                  },
                  "&:not(.Mui-selected):hover": {
                    backgroundColor: '#fcedc2',
                    color: '#000000',
                  },
                }}
                >
                  {data.quizType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </InputSection>
      </RightSection>
    </Container>
  )
}

export default FilterBar
const Container = styled.div`
  display: flex;
  padding: 0;
`
const LettSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
`
const RightSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Icon = styled.span`
  display: block;
  width: 30px;
  margin-right: 10px;
  
`
const Img = styled.img`
  display: block;
  width: 100%;
`
const TitleLabel = styled.p`
  font-size: 18px;
  color: #000;
`

const InputSection = styled.div`
  min-width: 113px;
  height:32px;
  border-radius: 25px;
  margin-right: 10px;
  margin-bottom:10px
 
`
// const Select = styled.select`
// 	width: 100%;
// 	height: 100%;
// 	outline: none;
// 	border: none;
// 	color: #7a7a7a;
// 	white-space: nowrap;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// `;
// const Option = styled.option``;
const SearchSection = styled.div`
  width: 250px;
  border: 1px solid #dcdbe4;
  height: 45px;
  margin-top: 10px;
  border-radius: 25px;
  overflow: hidden;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
`
const Input = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  font-size: 16px;
`
