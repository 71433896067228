import React, { useEffect, useState } from "react";

//Css Pages
import "../../css/Student/Student_Enrich_Quiz_MCQ.css";

//Mui
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Student_Image_Open_Modal from "./Student_Image_Open_Modal";
import { current } from "@reduxjs/toolkit";
// import CancelIcon from "@mui/icons-material/Cancel";
// import CloseIcon from "@mui/icons-material/Close";

import CloseIcon from "@mui/icons-material/Close";

//Images used
import circleIcon from "../../../../src/img/circle-thin.svg";
import { Preview } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { blue, orange } from "@mui/material/colors";
import { rightClickEnable } from "../../../../src/Serviceworker"
import { Box, Button, FormControl, TextField, FormControlLabel, FormLabel, Grid, LinearProgress, Modal, Paper, Radio, RadioGroup, Typography, } from "@mui/material";
const CustomRadioIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  color: orange[600],
  boxShadow: "inset 0 0 0 3px orange, inset 0 -1px 0 orange",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto orange",
    color: orange[600],
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
  backgroundColor: orange[600],
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: orange[800],
  },
});
function CustomRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CustomRadioCheckedIcon />}
      icon={<CustomRadioIcon />}
      {...props}
    />
  );
}
var loggedinuserrole = localStorage.getItem("role");
function Student_Enrich_Quiz_MCQ({ questions, currentQuestion, setAttendMcq, attendMcq, setAttendedMcq, attendedMcq, resumeBtnClicked, from, subItem, Vvalue, setCommonAttendedData,
  commonAttendedData, ispreview, list, Previewvalue, showExplanation }) {

  const listData = (ispreview && resumeBtnClicked) ? (list?.questionAnswerKeys[currentQuestion] || questions[currentQuestion]) : {};
  // console.log("mcqqqqqqqqqqqqqqqqqq", ispreview, resumeBtnClicked, questions, currentQuestion, from)
  const [selectedOption, setSelectedOption] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [isAuthenticatedShift, setIsAuthenticatedShift] = useState(true);
  const currentQuestionData = (!ispreview || !resumeBtnClicked) ? questions[currentQuestion] : list?.questionAnswerKeys[currentQuestion] || questions[currentQuestion];
  // console.log("currentQuestionData", currentQuestionData)
  const [isChecked, setIsChecked] = useState(false);
  const [hoverColor, setHoverColor] = useState('orange');

  const handleOptionChange = (optionIndex) => {
    if (selectedOption === optionIndex) {
      setSelectedOption(null);
    } else {
      setSelectedOption(optionIndex);
    }
  };

  useEffect(() => {
    console.log(ispreview, resumeBtnClicked, "====================useeffect")
    if (!ispreview && resumeBtnClicked) {
      console.log("attendedMcq", attendedMcq, currentQuestionData)
      const transformedObject = {
        [currentQuestionData?.questionId]: currentQuestionData?.mcsqAnswerByStudent?.map(item => ({
          option: item.option,
          order: item.order,
          answerKey: item.answerKey,
          currentQuestion: currentQuestion,
          index: currentQuestion
        }))
      };

      // setAttendMcq(transformedObject[currentQuestionData?.questionId])
      console.log("demo transformedObject", transformedObject);

      const keyToCheck = currentQuestionData?.questionId;
      const existingData = attendedMcq;

      for (const key in transformedObject) {
        if (transformedObject.hasOwnProperty(key)) {
          // console.log("transformedObject[key]", transformedObject[key]);
          // console.log("-------------", transformedObject, key);
          if (existingData.hasOwnProperty(key)) {
            // console.log("111111111111111", existingData);
            delete existingData[key];
            // console.log("delete happening", existingData[key]);
          }
          existingData[key] = transformedObject[key];
        }
      }


      console.log(existingData, "existingData");
      // console.log("transformedObject", transformedObject, attendedMcq, attendMcq)
      setAttendedMcq(existingData)
    } else {
    }


  }, [currentQuestion])


  useEffect(() => {
    if (!ispreview && resumeBtnClicked) {

      var isalreadyPreviewdArray = JSON.parse(sessionStorage.getItem("MSQ_det")) || [];
      console.log(isalreadyPreviewdArray, "sessionwork", currentQuestionData.questionId, questions[currentQuestion])
      var isalreadypreviewed = isalreadyPreviewdArray.filter(v => v.questionId == questions[currentQuestion].questionId);
      console.log("isalreadypreviewed", isalreadypreviewed)

      if ((isalreadypreviewed.length == 0 || !isalreadypreviewed[0]?.preview)) {

        currentQuestionData?.multiChoiceOrSelect?.forEach((value, ind) => {
          const dynamicId = `az-answer-text-mcq-${ind}`;
          const isChecked = Object.keys(attendedMcq).length > 0 && attendedMcq[currentQuestionData.questionId]
            ? attendedMcq[currentQuestionData.questionId].some((item) => item.option === value.option)
            : false;

          if (isChecked) {
            const checkbox = document.getElementById(dynamicId);
            // console.log("checkboxcheckboxcheckboxcheckbox", checkbox)
            if (checkbox) {
              console.log("mcq click")
              checkbox.click();
            }
          }
          let MSQ_det = JSON.parse(sessionStorage.getItem("MSQ_det")) || [];
          const questionId = questions[currentQuestion].questionId;
          const isQuestionIdPresent = MSQ_det.some(item => item.questionId === questionId);
          if (!isQuestionIdPresent) {
            // alert("mcq msq_det set")
            MSQ_det.push({ questionId: questionId, preview: true });
            sessionStorage.setItem("MSQ_det", JSON.stringify(MSQ_det));
          } else {
            console.log("QuestionId already present in MSQ_det array");
          }
        });
      }
    }
  }, [currentQuestionData, attendedMcq]); // Add dependencies here

  useEffect(() => {
    //console.log("useeffectt valled.")
    const handleContextMenu = (e) => {
      // alert("context menu mcq")
      // sessionStorage.removeItem("itemload");
      sessionStorage.removeItem("viewQuizData");
      sessionStorage.removeItem("practiceQuizAnswer");
      sessionStorage.removeItem("drag_drop_array");
      sessionStorage.removeItem("drag_drop_array_final");
      sessionStorage.removeItem("shuffledQuiz");
      sessionStorage.removeItem("alltheQuizAnswer");
      sessionStorage.removeItem("exid");
      sessionStorage.removeItem("exIdTwo");
      sessionStorage.removeItem("drag_drop_array_final")
      sessionStorage.removeItem("case_help");
      // if (!authContext.isAuthenticated) { this is to restrict right click based on loggedin info

      e.preventDefault();
      //console.log("handleContextMenu called")
    };
    if (rightClickEnable && isAuthenticatedShift) {
      document.addEventListener('contextmenu', handleContextMenu);
      document.addEventListener('keydown', function (event) {
        //console.log(event);
        // alert(event.getModifierState)
        const isModifierKey = event.getModifierState('Alt') ||
          event.getModifierState('Tab') ||
          event.getModifierState('Shift');

        if (event.key === 'F12' || isModifierKey) {
          //console.log("Modifier key pressed");
          event.preventDefault();
        } else if (event.key === "Enter" || event.key === " ") {
          //console.log(`${event.key} key pressed`);

          // Check if no modifier keys are pressed
          const noModifiers = !event.getModifierState('Alt') &&
            !event.getModifierState('Control') &&
            !event.getModifierState('Meta') &&
            !event.getModifierState('Shift');

          if (noModifiers) {
            //console.log("No modifier keys pressed");
            // Prevent the default behavior only for <button> elements
            if (event.target.tagName === 'BUTTON') {
              event.preventDefault();
              //console.log("Key event prevented for <button>");
            }
          }
        }
      });
      const handleBeforeUnload = (event) => {
        //console.log("close tab", event.type); // For reload and close tab
        const isRefresh = !event.currentTarget.performance.navigation.type;
        //console.log("isRefresh", isRefresh);

        if (event.type === 'beforeunload') {
          const message = "Are you sure you want to leave? Your progress may be lost.";
          event.returnValue = message; // Standard for most browsers
          return message; // For some older browsers
        } else {
          //console.log("else close tab");
          const message = "Are you sure you want to close the tab?";
          event.returnValue = message; // Standard for most browsers
          return message; // For some older browsers
        }
      }
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [])
  useEffect(() => {
    setSelectedOption(null);
  }, [currentQuestion]);

  useEffect(() => {
    if (!Preview) {
      if (currentQuestionData && Object.keys(currentQuestionData).length !== 0) {
        if (attendedMcq[currentQuestionData.id]) {
          attendedMcq[currentQuestionData.id]?.forEach((ques) => {
            attendMcq.push(ques);
            attendMcq.sort((a, b) => a.option - b.option);
          })
        }
        if (resumeBtnClicked) {
          if (attendedMcq[currentQuestionData.questionId]) {
            attendedMcq[currentQuestionData.questionId]?.forEach((ques) => {
              attendMcq.push(ques);
              attendMcq.sort((a, b) => a.option - b.option);
            })
          }
          if (!attendedMcq[currentQuestionData.questionId]) {
            currentQuestionData.mcsqAnswerByStudent?.forEach((itm) => {
              attendMcq.push(itm);
              setAttendedMcq((prevAttendedQuestions) => {
                const updatedQuestions = { ...prevAttendedQuestions };
                if (!updatedQuestions[currentQuestionData.questionId]) {
                  updatedQuestions[currentQuestionData.questionId] = [];
                }
                updatedQuestions[currentQuestionData.questionId]?.splice(0, 1);
                updatedQuestions[currentQuestionData.questionId]?.push({
                  "option": itm.option,
                  "order": itm.order,
                  "answerKey": itm.answerKey,
                });
                return updatedQuestions;
              });
            })
          }
        }
      }
    }
  }, [currentQuestion])

  const onHandleChange = (event, option, order, answerKey, ind) => {
    // console.log("mcq attend is happening", attendMcq)
    var arr = [];
    var obj = {
      "option": option,
      "order": order,
      "answerKey": answerKey,
    };
    console.log(obj, "***************************************************", from, attendedMcq, attendMcq)
    if (from == "CASE") {
      var Data = JSON.parse(sessionStorage.getItem("casestudy")) || [{}, {}, {}]
      Data[1] = { [resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]: [obj], type: "MCQ", currentQuestion };
      //console.log("Data", Data)
      sessionStorage.setItem("casestudy", JSON.stringify(Data));
      commonAttendedData.push(Data[1])
      setCommonAttendedData(commonAttendedData)
    }
    arr.push(obj);
    setAttendMcq(arr);
    handleOptionChange(ind, event);

    setAttendedMcq((prevAttendedQuestions) => {
      const updatedQuestions = { ...prevAttendedQuestions };
      if (!updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id]) {
        updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id] = [];
      }
      updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].splice(0, 1);
      updatedQuestions[resumeBtnClicked ? currentQuestionData.questionId : currentQuestionData.id].push({
        "option": option,
        "order": order,
        "answerKey": answerKey,
      });
      return updatedQuestions;
    });
  }

  return (
    <>
      {/* {
    //console.log("mcqmcqmcqmcqmcq", ispreview, from, resumeBtnClicked)
    } */}
      {
        ispreview ?

          !resumeBtnClicked ?
            <>
              <Grid
                item
                xs={12}
                mg={12}
                lg={12}
                style={{ display: "flex" }}
              >

                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    border: "1px solid #dbdee6",
                    fontSize: "13px",
                    color: "#1b1b1b",
                    opacity: "0.9",
                    marginTop: "10px",
                    lineHeight: "1",
                    padding: "10px",
                    borderRadius: "5px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CustomRadio
                    id="fullWidth"
                    sx={{
                      color: "white",
                      position: "absolute",
                      top: "50%", // Center the checkbox vertically
                      transform: "translateY(-50%)", // Center the checkbox vertically
                      left: "10px",
                      "&.Mui-checked": {
                        color: "rgba(0, 0, 0, 0.26)",
                      },
                      cursor: 'default'
                    }}
                  // disabled={!Previewvalue?.correctAnswer}
                  // checked={Previewvalue?.correctAnswer}
                  />
                  <div
                    style={{
                      marginLeft: "40px", // Adjust the spacing between checkbox and text
                      overflow: "hidden", // Hide overflow if the text is too long
                    }}
                  >
                    <p
                      className="head_3"
                      dangerouslySetInnerHTML={{ __html: Previewvalue?.htmlTag }}
                    ></p>
                    {/* {Previewvalue?.answerKey} */}
                  </div>
                </div>
              </Grid>

            </> :
            <>
              {
                //console.log("condition", ispreview, resumeBtnClicked, listData)
              }
              <div>
                {/*---------------------------| Quiz Question & Answer Division Starts Here |---------------------------*/}
                <div className="cqk__Quiz_Main_div_MCQ">
                  {/*---------------------------| Quiz Question Division |---------------------------*/}
                  <div className="cqk__Qstn_and_Ans">
                    <div className="cqk__Qstn_div">
                      <h5 className="Paragraph_title" id="az-mcq-qstn" >Question:-</h5>
                      <p
                        className="Paragraph"
                        dangerouslySetInnerHTML={{
                          __html: listData?.question,
                        }}
                      ></p>
                      {
                        console.log("listData", listData)
                      }
                      {
                        showExplanation == true &&
                        <>
                          {/* <div>
                            <img className="cqk__MCQ_Qstn_Img" src="" alt="Image" />
                          </div> */}

                          <div style={{ marginTop: '20px' }}>
                            {listData?.explanation && <h3 id="az-mcq-expl" className="Paragraph_title" style={{ marginTop: "10px" }}>Explanation:-</h3>}
                            <p
                              className="cqk__Qstn_content__1 Paragraph"
                              style={{ marginTop: '5px' }}
                              dangerouslySetInnerHTML={{
                                __html: listData?.explanation,
                              }}
                            ></p>
                          </div>
                          {listData?.imageUrl && (
                            <div style={{ marginTop: '20px' }}>
                              <img
                                className="MCQ_Qstn_Img"
                                src={listData?.imageUrl}
                                alt="Image"
                                onClick={() => {
                                  setSelectedImageUrl(listData?.imageUrl);
                                  setModalOpen(true);
                                }}
                              />
                            </div>
                          )} </>
                      }
                    </div>
                    {/*---------------------------| Quiz Answer Division |---------------------------*/}
                    <div className="cqk__Ans_div">
                      {/* <div className="cqk__Ans_Head_and_Count">
                        <div>
                          <h6 className="cqk__Head">Answer</h6>
                        </div>
                      </div> */}
                      <div>
                        {/*---------------------------| Ans |---------------------------*/}
                        {listData?.multiChoiceOrSelect.map((itm, index) => {
                          const name = listData?.mcsqAnswerByStudent
                            ? listData?.mcsqAnswerByStudent.some(
                              (item) => item.option === itm.option
                            )
                            : false;
                          //console.log("name", name)
                          const dynamicId = `az-answer-preview-mcq-${index}`;
                          var vv = name ? "Your Answer" : itm.correctAnswer === true ? "Correct Answer" : "";
                          return (
                            <>
                              <div
                                className="cqk__Ans1"
                                id={dynamicId}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  background:
                                    itm.correctAnswer === true
                                      ? "#E7FFE8" //green
                                      : name === false
                                        ? "white"
                                        : "#FCD3D3", //red
                                }}
                              >
                                <span>
                                  {itm.correctAnswer === true ? (
                                    <CheckCircleIcon style={{ color: "#4CAF50" }} />
                                  ) : name === false ? (
                                    <img src={circleIcon} width="25px" height="auto" />
                                  ) : (
                                    <CloseIcon style={{ color: "red" }} />
                                  )}
                                </span>
                                {itm.htmlTag === null && itm.answerKey}
                                <p className="cursorDefault"
                                  dangerouslySetInnerHTML={{
                                    __html: itm.htmlTag,
                                  }}
                                ></p>
                              </div>
                              <div
                                style={{
                                  color: "#1B1B1B",
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  margin: "5px 0 0 25px",
                                  cursor: 'default'
                                }}
                              >
                                {vv}
                              </div>
                              {/* {name === true &&
                                (
                                  <div
                                    style={{
                                      color: "#1B1B1B",
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                      margin: "5px 0 0 25px",
                                    }}
                                  >
                                    Your Answer
                                  </div>
                                )} */}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/*---------------------------| Quiz Question & Answer Division Ends Here |---------------------------*/}
              </div>
            </>
          :
          <>

            {
              (from !== undefined && from === "CASE") ? (
                <>
                  <div>

                    {/*---------------------------| Ans |---------------------------*/}
                    {!resumeBtnClicked ? [Vvalue]?.map((value, ind) => {
                      // console.log(value,"=========================")
                      const isChecked = Object.keys(attendedMcq).length > 0 && attendedMcq[currentQuestionData.id] ? attendedMcq[currentQuestionData.id].some((item) => {
                        return item.option === value.option
                      }
                      )
                        :
                        false;
                      const dynamicId = `az-answer1-text-mcq-${ind}`;
                      return (
                        <div className="Ans1" key={value.option} style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            id={dynamicId}
                            sx={{
                              color: isChecked ? 'orange' : 'grey', // Color when checked
                              '&:hover': {
                                color: hoverColor, // Color on hover
                              },
                            }}
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={
                              <CheckCircleIcon
                                style={{
                                  color: "orange",
                                }}
                              />
                            }
                            checked={isChecked}
                            onChange={(event) => {
                              onHandleChange(event, value.option, value.order, value.answerKey, ind)
                            }}
                          />
                          {value.htmlTag === null && value.answerKey}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: value.htmlTag,
                            }}
                          ></p>
                        </div>
                      );
                    })
                      :
                      currentQuestionData?.multiChoiceOrSelect?.map((value, ind) => {
                        const isChecked = Object.keys(attendedMcq).length > 0 && attendedMcq[currentQuestionData.questionId] ? attendedMcq[currentQuestionData.questionId].some((item) => {
                          return item.option === value.option
                        }
                        )
                          : false;
                        const dynamicId = `az-answer2-text-mcq-${ind}`;
                        return (
                          <div className="Ans1" key={ind} style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              // sx={{
                              //   color: "#EEF0F9",
                              // }}
                              id={dynamicId}
                              sx={{
                                color: isChecked ? 'orange' : 'grey', // Color when checked
                                '&:hover': {
                                  color: hoverColor, // Color on hover
                                },
                              }}
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={
                                <CheckCircleIcon
                                  style={{
                                    color: "orange", //"#4CAF50",
                                  }}
                                />
                              }
                              checked={isChecked}
                              onChange={(event) => {
                                onHandleChange(event, value.option, value.order, value.answerKey, ind)
                              }}
                            />
                            {value.htmlTag === null && value.answerKey}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: value.htmlTag,
                              }}
                            ></p>
                          </div>
                        );
                      })}
                  </div>
                  <Student_Image_Open_Modal
                    selectedImageUrl={selectedImageUrl}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                  />
                </>
              ) : (
                <>
                  {
                    //console.log(questions[currentQuestion], currentQuestion, "else part executing----------------------")
                  }
                  <div>
                    {/*---------------------------| Quiz Question & Answer Division Starts Here |---------------------------*/}
                    <div className="Quiz_Main_div_MCQ">
                      {/*---------------------------| Quiz Question Division |---------------------------*/}
                      <div className="Qstn_and_Ans">
                        <div className="Qstn_div">
                          {/* <h6 className="Mcq_Head">MCQ</h6> */}
                          {/* <p
                            className="Qstn_content_1"
                            dangerouslySetInnerHTML={{
                              __html: questions[currentQuestion]?.question,
                            }}
                          ></p> */}
                          <div className="Para_div_1">
                            <h3 className="Paragraph_title" id="az-mcq-qstn">Question:-</h3>
                            <p
                              className="Paragraph"
                              dangerouslySetInnerHTML={{
                                __html: questions[currentQuestion]?.question,
                              }}
                            ></p>
                          </div>
                          {/* <div className="Paragraph_title">
                            {questions && questions[currentQuestion]?.explanation && (loggedinuserrole !== "STUDENT") && (
                              <>
                                <h4 id="az-mcq-expl">Explanation:-</h4>
                                <p
                                  className="Paragraph"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      questions &&
                                      questions[currentQuestion]?.explanation,
                                  }}
                                ></p>
                              </>
                            )}
                          </div>*/}
                          {questions && questions[currentQuestion]?.imageUrl && (
                            <div>
                              <img
                                className="MCQ_Qstn_Img"
                                src={questions[currentQuestion]?.imageUrl}
                                alt="Image"
                                onClick={() => {
                                  setSelectedImageUrl(questions[currentQuestion]?.imageUrl);
                                  setModalOpen(true);
                                }}
                              />
                            </div>
                          )}
                        </div>
                        {/*---------------------------| Quiz Answer Division |---------------------------*/}
                        <div className="Ans_div">
                          <div className="Ans_Head_and_Count">
                            <div>
                              <h6 className="Head">Answer</h6>
                            </div>
                          </div>
                          <div>
                            {/* {
                            console.log(attendedMcq, "attendedMcq----------", attendMcq)
                          } */}
                            {/*---------------------------| Ans |---------------------------*/}
                            {!resumeBtnClicked ? questions[currentQuestion]?.options[0]?.map((value, ind) => {
                              // console.log(value.option, value)
                              const dynamicId = `az-answer1-text-mcq-${ind}`;
                              const isChecked = Object.keys(attendedMcq).length > 0 && attendedMcq[currentQuestionData.id] ? attendedMcq[currentQuestionData.id].some((item) => {
                                return item.option === value.option
                              }
                              ) : false;
                              console.log(isChecked)

                              return (
                                <div className="Ans1" key={value.option} style={{ display: 'flex', alignItems: 'center' }}>
                                  <Checkbox
                                    // sx={{
                                    //   color: "#EEF0F9",
                                    // }}
                                    id={dynamicId}
                                    sx={{
                                      color: isChecked ? 'orange' : 'grey', // Color when checked
                                      '&:hover': {
                                        color: hoverColor, // Color on hover
                                      },
                                    }}
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={
                                      <CheckCircleIcon
                                        style={{
                                          color: 'orange',//"#4CAF50",
                                        }}
                                      />
                                    }
                                    checked={isChecked}
                                    onChange={(event) => {
                                      onHandleChange(event, value.option, value.order, value.answerKey, ind)
                                    }}
                                  />
                                  {value.htmlTag === null && value.answerKey}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: value.htmlTag,
                                    }}
                                  ></p>
                                </div>
                              );
                            })
                              :
                              currentQuestionData?.multiChoiceOrSelect?.map((value, ind) => {

                                const dynamicId = `az-answer-text-mcq-${ind}`;
                                const isChecked = Object.keys(attendedMcq).length > 0 && attendedMcq[currentQuestionData.questionId] ? attendedMcq[currentQuestionData.questionId].some((item) => {
                                  return item.option === value.option
                                }) : false;

                                // console.log("isChecked", isChecked)
                                // if (isChecked) {
                                //   document.getElementById(dynamicId).click();
                                //   console.log("clicked---------------")
                                // }
                                return (
                                  <div className="Ans1" key={ind} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                      // sx={{
                                      //   color: "#EEF0F9",
                                      // }}
                                      id={dynamicId}
                                      sx={{
                                        color: isChecked ? 'orange' : 'grey', // Color when checked
                                        '&:hover': {
                                          color: hoverColor, // Color on hover
                                        },
                                      }}
                                      icon={<RadioButtonUncheckedIcon />}
                                      checkedIcon={
                                        <CheckCircleIcon
                                          style={{
                                            color: 'orange',//"#4CAF50",
                                          }}
                                        />
                                      }
                                      checked={isChecked}
                                      onChange={(event) => {
                                        onHandleChange(event, value.option, value.order, value.answerKey, ind)
                                      }}
                                    />
                                    {value.htmlTag === null && value.answerKey}
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: value.htmlTag,
                                      }}
                                    ></p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*---------------------------| Quiz Question & Answer Division Ends Here |---------------------------*/}
                  </div>
                  <Student_Image_Open_Modal
                    selectedImageUrl={selectedImageUrl}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                  /></>
              )
            }
          </>
      }
    </>
  );
}

export default Student_Enrich_Quiz_MCQ;