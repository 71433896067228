import axios from 'axios'
async function GetLastModifiedAt(ModifiedDate) {
  let token = sessionStorage.getItem("token");
  // let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/last-modified-at`,
  let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/last-modified-at`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json) => {
      ModifiedDate(json.data)
    })
    .catch(err => console.log(err))
}

export default GetLastModifiedAt;
