import React from "react";
import { Box, Modal } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Yellow100Button from "../../components/common/Yellow100Button";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "7px",
  display: "flex",
  flexDirection: "column",
  rowGap: "1rem",
  // p: 4,
};
const SuccessModal = ({ open, close, secondClose }) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            padding: "30px 20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "10px",
          }}
        >
          <CheckCircleOutlineOutlinedIcon
            style={{ fontSize: "60px", color: "green" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                // padding: "0px 20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              You New Number has been
            </div>
            <div
              style={{
                // padding: "0px 20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Changed Successfully
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Yellow100Button
            onClick={async () => {
              await close();
              await secondClose();
            }}
          >
            Ok
          </Yellow100Button>
        </div>
      </Box>
    </Modal>
  );
};
export default SuccessModal;
