const schoolList = [
  {
    schoolName: "AZVASA Education",
    schoolType: "PRODUCTION",
    schoolType1: "CBSE",
    branches: "02",
    hods: "65",
    teachers: "93",
    students: "156",
  },
  {
    schoolName: "AZVASA Education",
    schoolType: "TEST",
    schoolType1: "CBSE",
    branches: "02",
    hods: "65",
    teachers: "93",
    students: "156",
  },
  {
    schoolName: "AZVASA Education",
    schoolType: "TEST",
    schoolType1: "ICSE",
    branches: "02",
    hods: "65",
    teachers: "93",
    students: "156",
  },
  {
    schoolName: "AZVASA Education",
    schoolType: "PRODUCTION",
    schoolType1: "CBSE",
    branches: "02",
    hods: "65",
    teachers: "93",
    students: "156",
  },
  {
    schoolName: "AZVASA Education",
    schoolType: "TEST",
    schoolType1: "CBSE",
    branches: "02",
    hods: "65",
    teachers: "93",
    students: "156",
  },
  {
    schoolName: "AZVASA Education",
    schoolType: "PRODUCTION",
    schoolType1: "ICSE",
    branches: "02",
    hods: "65",
    teachers: "93",
    students: "156",
  },
  {
    schoolName: "AZVASA Education",
    schoolType: "TEST",
    schoolType1: "CBSE",
    branches: "02",
    hods: "65",
    teachers: "93",
    students: "156",
  },
  {
    schoolName: "AZVASA Education",
    schoolType: "PRODUCTION",
    schoolType1: "CBSE",
    branches: "02",
    hods: "65",
    teachers: "93",
    students: "156",
  },
  {
    schoolName: "AZVASA Education",
    schoolType: "PRODUCTION",
    schoolType1: "ICSE",
    branches: "02",
    hods: "65",
    teachers: "93",
    students: "156",
  },
  {
    schoolName: "AZVASA Education",
    schoolType: "TEST",
    schoolType1: "CBSE",
    branches: "02",
    hods: "65",
    teachers: "93",
    students: "156",
  },
];

export default schoolList;
