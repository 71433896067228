import React from "react";

// Css Pages
import "../../css/Student/Student_Study_Files_Video.css";

// Video Used
import dummyVdo from "../../../../src/img/dummy-vdo.mp4";

function Student_Study_Files_Video() {
  return (
    <div>
      {/*----------------------------| Side-Menu Video Division Starts Here |--------------------------*/}
      <div className="SideMenu_video">
        <video controls>
          <source src={dummyVdo} type="video/mp4" />
        </video>
      </div>
      {/*----------------------------| Side-Menu Video Division Ends Here |--------------------------*/}
    </div>
  );
}

export default Student_Study_Files_Video;
