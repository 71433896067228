import * as yup from "yup";

export const validationSchema = yup.object().shape({
  paperTitle: yup.string().required("Paper Title is required"),
  paperTypeId: yup.string().required("Paper Type is required"),
  blueprintLevelId: yup.string().required("Level is required"),
  boardId: yup.string().required("Board is required"),
  gradeId: yup.string().required("Grade is required"),
  subjectId: yup.string().required("Subject is required"),
  questionFrequency: yup.string().required("Question Frequency is required"),
  totalNumberOfQuestions: yup
    .number()
    .typeError("Total no of Questions must be a number")
    .required("Total no of Questions is required"),
  totalMark: yup
    .number()
    .typeError("Total Mark must be a number")
    .required("Total Mark is required"),
  totalTime: yup
    .number()
    .typeError("Total Time must be a number")
    .required("Total Time is required"),
});

export const tableHead = [
  { title: "#" },
  { title: "Paper Title" },
  { title: "Paper Type" },
  { title: "Grade" },
  { title: "Subject" },
  { title: "Total Marks" },
  { title: "Total Questions" },
  { title: "Time" },
  { title: "Active" },
  { title: "Action" },
];

export const tableHeadRejected = [
  { title: "#" },
  { title: "Paper Title" },
  { title: "Paper Type" },
  { title: "Grade" },
  { title: "Subject" },
  { title: "Total Marks" },
  { title: "Total Questions" },
  { title: "Time" },
  { title: "Active" },
  { title: "Reason" },
  { title: "Action" },
];

export const tableData = [
  {
    no: 1,
    paper_title: "CBSE-SCI-PRAC",
    paper_type: "Term",
    grade: "Grade 5",
    subject: "Science",
    total_marks: 12,
    total_questions: "12",
    time: "40 min",
    active: true,
  },
  {
    no: 2,
    paper_title: "CBSE-SCI-PRAC",
    paper_type: "Term",
    grade: "Grade 5",
    subject: "Science",
    total_marks: 12,
    total_questions: "12",
    time: "40 min",
    active: false,
  },
];
