import React, { useState } from 'react'
import MainContainer from '../dashboard/MainContainer'
import Navbar from '../../Navbar'
import PrincipalReadingPassport from '../../PrincipalModule/PrincipalReadingPassport';

const CoordinatorReadingPassport = () => {
  const [headerTitle, setHeaderTitle] = useState('')
  const handleHeaderTitle = value => {
    setHeaderTitle(value)
  }
  return (
    <>
      <Navbar handleHeaderTitle={value => handleHeaderTitle(value)} />
      <MainContainer
        		// children={<div></div>} 
            children={<PrincipalReadingPassport />}
        headerTitle={headerTitle}
      />
    </>
  )
}

export default CoordinatorReadingPassport
