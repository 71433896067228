import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Studentprofile from "../../css/Studentpg31.module.css";
import sortIcon from "../../../../src/pages/img/sort_1.png";
import Studentdynamic from "../../css/student32.module.css";
import { Category } from "@material-ui/icons";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";
import add_icon from "../../../../src/img/add-circle-outline.svg";
import dashHome from "../../../../src/img/dashHome.svg";
import AddCourse from "./AddCourse";
import { FaEye } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import axios from "axios";
import bg from './viewBg.png'
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



const CourseList = (props) => {

    const token = sessionStorage.getItem("token");
    const [upskilData, setUpskilData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const totalPages = Math.ceil(upskilData.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = upskilData.slice(indexOfFirstItem, indexOfLastItem);
    const [modifiedDate, setModifiedDate] = useState({});
    const navigate = useNavigate();
    const [toolTipContent, setToolTipContent] = useState('')
    const [showToolTip, setShowToolTip] = useState(false)
    const [hoveredItem, setHoveredItem] = useState(null)

    useEffect(() => {

        GetLastModifiedAt(setModifiedDate);
    }, [])
    useEffect(async () => {
        // v1/api/content/paper-type/all
        const response = await axios.get(
            `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/courses/getCourses/pages`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log("get course", response?.data?.data?.content);
        console.log("paper type", response.data || [])
        setUpskilData(response?.data?.data?.content)
    }, [])
    const handleViewDetails = (item) => {
        setSelectedItem(item)
        setShowModal(true)
    }
    const handleCloseModal = () => {
        setShowModal(false)
    }
    const handleMouseEnter = (content) => {
        if (content.length > 13) {
            setToolTipContent(content)
            setShowToolTip(true)
        }

    }
    const handleMouseLeave = () => {
        setShowToolTip(false)
    }
    return (
        <>
            <article className={Studentprofile.article_31}
                style={{ background: 'transparent', position: 'relative' }}>
                <article>

                    <div style={{ position: 'relative', margin: '14px' }}>
                        <span className={dashboardContentStyles.link_icon}>
                            <img
                                src={dashHome}
                                alt="no img"
                                className={dashboardContentStyles.az_menu_icon}
                            />
                        </span>
                        <span
                            id={`az-breadscrum-home`}
                            className={dashboardContentStyles.az_menu_text}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                                sessionStorage.removeItem("sideNavSubMenuNames");
                                navigate("/dashboard/template");
                            }}
                        >
                            Home
                        </span>
                        <span>
                            <i className="fa-solid fa-angle-right"
                                style={{ color: "#828282" }} />
                        </span>
                        <span>
                            <i className="fa-solid fa-angle-right"
                                style={{ color: "#828282" }} />
                        </span>
                        <span
                            className={dashboardContentStyles.az_selected_menu_text}
                        >
                            Upskills
                        </span>
                    </div>

                    <div className={dashboardContentStyles.dashboard_last_updated}>
                        <p>Last Update: {modifiedDate.data}</p>
                    </div>
                </article>
                <div className="upskillHeader ">
                    <h1>
                        Course List
                    </h1>
                    <Link to='/dashboard/upskills/addcourse/' >
                        <button className="addCourseBtn">Add Course</button>
                    </Link>

                </div>
                <div className="tableData">
                    <table>
                        <div className="tableheaddiv">
                            <thead>
                                <tr>
                                    <th width="10%" className="truncate">Course</th>
                                    <th width="10%">Type</th>
                                    <th width="10%">Category</th>
                                    <th width="10%">Code</th>
                                    <th width="10%">Mode</th>
                                    <th width="10%">Status</th>
                                    <th width="10%">Created By</th>
                                    <th width="10%">Created Date</th>
                                    <th width="10%">Actions</th>
                                </tr>
                            </thead>
                        </div>
                        <div className="tableBodyDiv">
                            {currentItems.length > 0 ?
                                <tbody >
                                    {currentItems.map((item, index) => (
                                        <tr key={index}>
                                            <td
                                                width="10%"
                                                className="truncate"
                                                onMouseEnter={() => setHoveredItem(item.id)}
                                                onMouseLeave={() => setHoveredItem(null)}
                                                style={{ position: 'relative' }}
                                            >
                                                <span className="truncate">
                                                    {item?.courseName.length > 13 ? (
                                                        <>
                                                            {item?.courseName}
                                                            {hoveredItem === item?.id && (
                                                                <div className="toolTip">
                                                                    {item.name}
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        item.courseName
                                                    )}
                                                </span>
                                            </td>
                                            {/* {showToolTip &&
                                            <div className="toolTip">
                                                {toolTipContent}
                                            </div>} */}

                                            <td width="10%">{item.type}</td>
                                            <td width="10%">{item.category}</td>
                                            <td width="10%">{item.code}</td>
                                            <td width="10%">{item.courseMode}</td>
                                            <td width="10%">{item.courseStatus}</td>
                                            <td width="10%">{item.createdUserName}</td>
                                            <td width="10%" className="dateSpan">{item.createdDate}</td>
                                            <td width="10%" className="buttonDiv">
                                                <div className="buttonContainer">
                                                    <button onClick={() => handleViewDetails(item)} className="viewButton"><FaEye /><span className="viewLabel">View</span></button>
                                                </div>
                                                <div className="buttonContainer">
                                                    <Link to={`/dashboard/upskills/editcourse/${item.id}`}>
                                                        <button className="editButton"> <FaRegEdit /><span className="editLabel">Edit</span></button>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                : <div className="noCourse"> No courses found</div>
                            }
                        </div>
                    </table>

                </div>
                {currentItems?.length > 0 &&
                    <div className="pagination">
                        <button className={currentPage === 1 ? "prevNext disabled" : "prevNext"} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}><ArrowBackIosOutlinedIcon />Previous</button>
                        {Array.from({ length: Math.ceil(upskilData.length / itemsPerPage) }, (_, i) => {
                            if (
                                (i === 0) ||
                                (i === currentPage - 1 || i === currentPage || i === currentPage + 1) ||
                                (i === Math.ceil(upskilData.length / itemsPerPage) - 1)
                            ) {
                                return (
                                    <button
                                        key={i + 1}
                                        onClick={() => setCurrentPage(i + 1)}
                                        className={currentPage === i + 1 ? "activePage" : "inactivePage"}
                                    >
                                        {i + 1}
                                    </button>
                                );
                            } else if (
                                ((currentPage > 3 && i === 1) || (currentPage > 4 && i === 2)) &&
                                (currentPage !== Math.ceil(upskilData.length / itemsPerPage) - 1)
                            ) {
                                return <span key={i}>...</span>;
                            }
                            return null;
                        })}
                        <button className={currentPage === totalPages ? "prevNext disabled" : "prevNext"} onClick={() => setCurrentPage(currentPage + 1)} disabled={currentItems.length < itemsPerPage}>Next <ArrowForwardIosIcon /></button>
                    </div>}
                {showModal && (
                    <div className="modalOverlay">
                        <div className="viewModal">
                            <div className="modalContent">
                                <span onClick={() => handleCloseModal()}> &times;</span>
                                {selectedItem && (
                                    <>
                                        <div className="viewHeader">
                                            <h2>{selectedItem.name}</h2>
                                            <p>{selectedItem.status}</p>
                                        </div>
                                        <div className="viewImage">
                                            <img src={bg} alt="" />
                                        </div>
                                        <div className="viewBody">
                                            <p>{selectedItem.category}</p>
                                            <p>{selectedItem.location}</p>
                                            <p>{selectedItem.mode}</p>
                                        </div>
                                        <div className="viewFooter">
                                            <p>Author : {selectedItem.createdBy}</p>
                                            <p>{selectedItem.createdDate}</p>
                                        </div>
                                    </>)}
                            </div>

                        </div>
                    </div>
                )}
            </article>
        </>
    );
}
export default CourseList;