import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Pagination from "../General/Pagination";
import QuizTable from "./QuizTable";

function TableComponent({
	quizes,
	setQuizes,
	pageSize,
	setPageSize,
	currentPage,
	setCurrentPage,
	totalPages,
	setSelectedQuiz,
	setCommentModal,
	setRejectModal,
	approveQuiz,
	setPreview,
	setSortBy,
	setSortOrder,
	publishCDN,
	selectedMenu
}) {
	useEffect(() => {
		setCurrentPage(0);
	}, [pageSize]);

	return (
		<Conatiner>
			<QuizTable
				quizes={quizes}
				setSelectedQuiz={setSelectedQuiz}
				setCommentModal={setCommentModal}
				setRejectModal={setRejectModal}
				approveQuiz={approveQuiz}
				setPreview={setPreview}
				currentPage={currentPage}
				setSortBy={setSortBy}
				setSortOrder={setSortOrder}
				pageSize={pageSize}
				publishCDN={publishCDN}
				selectedMenu={selectedMenu}
			/>
			<Pagination
				totalData={quizes && quizes.length}
				totalPages={totalPages}
				pageSize={pageSize}
				setPageSize={setPageSize}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
		</Conatiner>
	);
}

export default TableComponent;
const Conatiner = styled.div`
	position: relative;
	height: calc(100vh - 290px);
	background-color: #fff;
	padding-bottom: 60px;
	overflow: hidden;
`;
