export const isNOTNullOrUndefined = (value) => {
  return !isNullOrUndefined(value);
};

export const isNullOrUndefined = (value) => {
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    value === "null" ||
    value === "undefined"
  );
};

export const truncateString = (str, limit) => {
  if (isNullOrUndefined(str)) {
    return str;
  }

  if (limit === null) {
    limit = 10;
  }
  if (str.length > limit) {
    return str.slice(0, limit) + "...";
  } else {
    return str;
  }
};

export const nameHandler = (name) => {
  console.log("namehandler", name)
  const data = name.split("/")?.[name.split("/").length - 1]?.split(".");
  // return `${data[0]}.${data?.[data.length - 1]}`;
  // return `${data[0]}`;

  var str = data[0];
  console.log(str.split(' ').map(word => word.split('-').map(segment => segment.charAt(0).toUpperCase() + segment.slice(1)).join('-')).join(' '))
  return str.split(' ').map(word => word.split('-').map(segment => segment.charAt(0).toUpperCase() + segment.slice(1)).join('-')).join(' ');

};

export const extractTextIncludingExclamation = (inputString) => {
  if (!inputString) return "";
  const exclamationIndex = inputString.indexOf("!");

  if (exclamationIndex !== -1) {
    return inputString.slice(0, exclamationIndex + 1); // Include "!" in the result
  } else {
    // If there is no "!" in the string, return the original string.
    return "";
  }
};

export const extractTextAfterExclamation = (inputString) => {
  if (!inputString) return "";
  const exclamationIndex = inputString.indexOf("!");

  if (exclamationIndex !== -1) {
    return inputString.slice(exclamationIndex + 1); // Extract text after "!"
  } else {
    // If there is no "!" in the string, return an empty string.
    return "";
  }
};

export const getMessageColorCode = (message) => {
  if (isNullOrUndefined(message)) return "#EC6B56";
  let lowercaseMsg = message.toLowerCase();
  if (lowercaseMsg === "great effort!") {
    return "#519DF4";
  } else if (lowercaseMsg === "keep going!") {
    return "#EC6B56";
  } else if (lowercaseMsg === "congratulations!") {
    return "#51BF9F";
  }
};

export const getMessageColorCodeV2 = (message) => {
  if (isNullOrUndefined(message)) return "#EC6B56";
  let lowercaseMsg = message.toLowerCase();
  if (lowercaseMsg.includes("let")) {
    return "#ED634C";
  } else if (lowercaseMsg === "keep going!") {
    return "#4FCDA8";
  }
};

export const getMessageColorCodeV3 = (message) => {
  if (isNullOrUndefined(message)) return "#EC6B56";
  let lowercaseMsg = message.toLowerCase();
  if (lowercaseMsg.includes("let")) {
    return "#EE5F48";
  } else if (lowercaseMsg === "good work!") {
    return "#55C3A2";
  }
};

export const getPercentLabelPosition = (percent) => {
  let styleObj = {
    position: "absolute",
    right: 0,
    fontSize: "10px",
    color: "#2D3962",
    top: "10%",
    left: "35%",
  };
  if (isNullOrUndefined(percent)) return styleObj;

  if (percent >= 0 && percent <= 30) {
    styleObj.top = "48%";
    styleObj.left = "-4%";
  } else if (percent > 30 && percent <= 60) {
    styleObj.top = "10%";
    styleObj.left = "50%";
  } else if (percent > 60 && percent <= 100) {
    styleObj.top = "40%";
    styleObj.left = "85%";
  }

  return styleObj;
};

export const getPercentLabelPositionV2 = (percent) => {
  let styleObj = {
    position: "absolute",
    right: 0,
    fontSize: "10px",
    color: "#2D3962",
    top: "10%",
    left: "35%",
  };
  if (isNullOrUndefined(percent)) return styleObj;

  if (percent >= 0 && percent <= 30) {
    styleObj.top = "48%";
    styleObj.left = "-15%";
  } else if (percent > 30 && percent <= 60) {
    styleObj.top = "10%";
    styleObj.left = "50%";
  } else if (percent > 60 && percent <= 100) {
    styleObj.top = "40%";
    styleObj.left = "95%";
  }

  return styleObj;
};
