import React, { useState, useEffect } from "react";

//Css Pages
import "../../../css/QuestionBank/Matchthefollowing_DD_Preview.css";

// Images Used
import prev from "../../../../../src/img/Prev.svg";
import nxt from "../../../../../src/img/Nxt.svg";

import {
    Box,
    Modal,
    Grid,
    Button,
    styled,
    Typography
} from "@mui/material";
import get_question from "../../../API_CONTROLLER/create-questions/get_questionbyid";

const style = {
    position: 'absolute',
    top: '50%',
    left: '58%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "45vw",
    // overflow: "scroll",
    overflowX: "scroll",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "4px",
    p: 4,
};

const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: "3px",
    color: "#FFFFFF",
    fontFamily: ['normal', 'normal', 'medium', 'Poppins'].join(','),
    backgroundColor: "#2AA571",
    fontSize: '10px',
    width: 'auto',
    padding: "0.6vw 1vw",
    '&:hover': {
        backgroundColor: '#056039',
    },
}));

function Preview_MTF_DD(props) {

    const [responseValue, setResponseValue] = useState();

    let questionContent;
    let answerKeyContent;
    let options;
    let images;
    if (responseValue) {
        questionContent = responseValue.question;
        answerKeyContent = responseValue.explanation;
        options = responseValue.options[0];
        images = responseValue.imageUrl;
    }

    useEffect(() => {
        if (props.responseValue) {
            get_question(props.responseValue.id, setResponseValue);
        }
    }, [props.previewFromModal]);

    const onExitButton = () => {
        // setCloseModal(true);
        props.handlePreviewModalClose();
        setResponseValue();
        props.setPreviewFromModal(false);
    }

    return (
        <Modal
            open={props.open}
            onClose={props.close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Grid container p={2}>
                    <Grid item xs={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "left" }}>
                        <Typography sx={{ color: "#1B1B1B", fontFamily: "Poppins", fontWeight: "600", fontSize: "16px", lineHeight: "25px", }}>Match the following</Typography>
                    </Grid>
                </Grid>
                <Grid container p={2}>
                    <Grid item xs={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "left" }}>
                        <Typography sx={{ color: "#1B1B1B", fontFamily: "Poppins", fontWeight: "600", fontSize: "16px", lineHeight: "25px", }}>Question</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "left" }}>
                        {props.imagePreview || props.selectedImageUrl ?
                            <Typography sx={{ color: "#1B1B1B", fontFamily: "Poppins", fontWeight: "600", fontSize: "16px", lineHeight: "25px", }}>Uploaded Image</Typography>
                            :
                            <></>
                        }
                    </Grid>

                    <Grid item xs={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "left", fontSize: "0.9vw" }}>
                        <Typography dangerouslySetInnerHTML={{ __html: props.previewFromModal ? questionContent : props.temporaryQuestionText }}></Typography>
                        <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "left" }}>
                        <Typography>
                            <img src={props.previewFromModal ? images : props.selectedImageUrl} width="100%" height="auto" />
                        </Typography>
                    </Grid>
                </Grid>
                <div className="MTF_DD_Box">
                    <div className="MTF_DD_Div">
                        <div className="MTF_DD_Qstn_And_Field_div">
                            {/*------------------| Qstn And Field 1 |-----------------*/}
                            {props.previewFromModal ?
                                options && options.map(opt => {
                                    return (
                                        <div className="MTF_DD_Fields_1">

                                            <>
                                                <div className="MTF_DD_Qstn_Field_1_B1">
                                                    <p dangerouslySetInnerHTML={{ __html:opt.htmlTagQuestion}}></p>
                                                    <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                                </div>
                                                <div className="MTF_DD_Qstn_Field_1_B2">
                                                    <p>{opt.answers}</p>
                                                </div>
                                            </>

                                        </div>
                                    )
                                })
                                :
                                props.options.length > 0 && props.options.map(opt => {
                                    return (
                                        <div className="MTF_DD_Fields_1">

                                            <>
                                                <div className="MTF_DD_Qstn_Field_1_B1">
                                                    <p dangerouslySetInnerHTML={{ __html: opt.htmlTagQuestion}}></p>
                                                    <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                                </div>
                                                <div className="MTF_DD_Qstn_Field_1_B2">
                                                    <p>{opt.answers}</p>
                                                </div>
                                            </>

                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/*------------------| MTF DD Ans IMages Div |-----------------*/}
                        <div>
                            <div className="MTF_DD_Ans">
                                {props.previewFromModal ?
                                    options && options.map(opt => {
                                        return (
                                            <div className="MTF_DD_Ans_Field_1">
                                                <p dangerouslySetInnerHTML={{ __html:opt.htmlTagJumbled}}></p>
                                                <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                            </div>
                                        )
                                    })
                                    :
                                    props.options.length > 0 && props.options.map(opt => {
                                        return (
                                            <div className="MTF_DD_Ans_Field_1">
                                                <p dangerouslySetInnerHTML={{ __html:opt.htmlTagJumbled }}></p>
                                                <style>{`
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
      `}</style>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/*---------------------------| Prev & Next Btn Division|---------------------------*/}
                {/* <div className="btn-DIV">
                    <div className="Qstn-Btns">
                        <div className="Exit-btn">
                            <h6 onClick={props.close}>Exit</h6>
                        </div>
                    </div>
                </div> */}
                <Grid container>
                    < Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <CustomButton onClick={onExitButton}>Exit</CustomButton>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

export default Preview_MTF_DD;
