import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Divider,
  Paper,
  Box,
} from "@mui/material";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { styled } from "@mui/material/styles";
import "../../../css/switch.css";
import number from "../../../img/Numbers.svg";

const GrayButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: "gray",
  borderColor: "gray",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  fontSize: "0.9vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "gray",
    border: 0,
    boxShadow: "0px 3px 5px gray",
  },
}));

const data = [
  { id: "1", label: "Practice Question" },
  { id: "2", label: "K-W-L Chart" },
  { id: "3", label: "Concepts / Sub-concepts" },
  { id: "4", label: "The Fundamental Theorem of Arithmetic" },
  { id: "5", label: "Decimal Expansions of Real Numbers" },
  { id: "6", label: "Progression of the Chpater" },
  { id: "7", label: "Worksheet" },
];

const StudentWorkbookView = () => {
  const [tab, setTab] = useState("Practice Question");


  const mql = window.matchMedia("(max-width: 2000px)");
  const smallScreen = mql.matches;

  const tabHandle = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <Paper
    sx={{
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      rowGap: "1rem",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "10px",
        alignItems: "center",
        padding: "10px 0px",
      }}
    >
      <div
        style={{
          background: "#ffcc00",
          borderRadius: "150px",
          width: "1.5vw",
          height: "1.5vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PsychologyAltOutlinedIcon
          style={{ color: "white", fontSize: "1.3vw" }}
        />
      </div>
      <div style={{ fontSize: 20, color: "black", fontWeight: 600 }}>
        WoorkBook key
      </div>
    </div>
    <Divider
      orientation="horizontal"
      flexItem
      sx={{
        border: "1px solid rgba(221,218,218,0.67)",
        padding: "0px",
      }}
    />
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <div
        style={{ fontSize: 21, color: "black", fontWeight: "bold" }}
      >
        Number System
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <GrayButton sx={{ borderRadius: 150 }}>Chapter 6</GrayButton>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={number} alt={""} width={"100%"} height={300} />
      </div>
      <div style={{ color: "rgb(66, 61, 92)", fontWeight: "600" }}>
        Overview:
      </div>
      <div>
        Rational number are the numbers that can be written in the
        form p/q, where p and q integer and q 0. irrational number are
        the numbers that cannot be written in the form p/q, where p
        and q are integer and q = 0. A collection of rational and
        irrational numbers and real numbers.
      </div>
      <div>
        This chapter deal Euclid's dividion algorithm and fundamental
        thorems of arithmertic Euclids division algorithm state the
        divisibility of integer. We can find HCF of given.
      </div>
      <div>Number with the help of algorithm.</div>
      <Divider
        orientation="horizontal"
        flexItem
        sx={{
          border: "1px solid rgba(221,218,218,0.67)",
          padding: "0px",
        }}
      />
      <Box sx={{ flexGrow: 1 }}>
        <TabContext value={tab}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
            }}
          >
            <TabList
              onChange={(e, value) => tabHandle(e, value)}
              aria-label="lab API tabs example"
              textColor="inherit"
              TabIndicatorProps={{
                style: {
                  background: "#061ca5",
                  height: "10px",
                  top: "50px",
                },
              }}
              // indicatorColor={false}
              variant={smallScreen ? "scrollable" : "standard"}
            >
              {data?.map((item) => {
                return (
                  <Tab
                    label={item?.label}
                    value={item?.label}
                    sx={{
                      background:
                        tab === item?.label
                          ? "#1a1588"
                          : "rgb(220,219,219)",
                      color:
                        tab === item?.label ? "white" : "#1a1588",
                      borderRadius: "10px",
                      marginBottom: "5px",
                      textTransform: "none",
                      fontWeight: 600,
                      opacity: "1",
                      marginRight: "10px",
                      // width: "100%",
                    }}
                    key={item.id}
                  />
                );
              })}
            </TabList>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                border: "1px solid rgba(221,218,218,0.67)",
                padding: "0px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  padding: "10px",
                }}
              >
                <PsychologyAltOutlinedIcon
                  sx={{ color: "#1a1588" }}
                />
                <div
                  style={{
                    fontSize: 21,
                    fontWeight: "bolder",
                    color: "rgb(253 47 78)",
                  }}
                >
                  {tab}
                </div>
              </div>
              <Divider orientation="horizontal" flexItem />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                  }}
                >
                  <div style={{ fontWeight: 600, color: "blue" }}>
                    Euclid's Division Lemma
                  </div>
                  <div>
                    Given positive integers a and b, there exist
                    unique integer q and r satisfying a = bq + r ,{" "}
                    {`where 0 < r <b,`}
                  </div>
                  <div>Dividend = Divisor * Quotient + Remainder</div>
                </div>
                <div style={{ fontWeight: 600, color: "blue" }}>
                  Euclid's Division Algoithm
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <ul>
                    <li>It is based onEuclid's division lemma.</li>
                    <li>
                      The HCF of two positive integer can be obtained
                      as follows:
                    </li>
                  </ul>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "2px",
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>Step 1:</span>
                    <div>
                      Apply the division lemma to find q and r where a
                      = bq + 1, {`0 < 1 < b`}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "2px",
                    }}
                  >
                    <div style={{ fontWeight: 600 }}>Step 2:</div>
                    <div>
                      If r = 0, the HCF is b. if r % 0, apply Euclid's
                      lemma to b and r.
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "2px",
                    }}
                  >
                    <div style={{ fontWeight: 600 }}>Step 3:</div>
                    <div>
                      Continus the process till the remainder is zero.
                      The divisor at this stage will be HCF (a,b).
                      Also, HCF (a,b) = HCF (b,r).
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "5px",
                  }}
                >
                  <div style={{ fontWeight: 600, color: "blue" }}>
                    Solved Example 1
                  </div>
                  <div style={{ fontWeight: 600 }}>
                    :Use Euclid's algorithm to find the HCF of 225 and
                    135
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: 600,
                    color: "rgb(0, 184, 93)",
                  }}
                >
                  Solution:
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "2px",
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>Step 1:</span>
                    <div>
                      Since 225 135, We apply the division lemma to
                      225 and 135, to get 225 = 135 * 1 + 90.
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "2px",
                    }}
                  >
                    <div style={{ fontWeight: 600 }}>Step 2:</div>
                    <div>
                      Since the remainder (90) 0, We apply the
                      division lemma to 135 and 90, to get 135 = 90 *
                      1 + 45
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "4px",
                    }}
                  >
                    <div style={{ fontWeight: 600 }}>Step 3:</div>
                    <div>
                      We consider the new divisor (90) and the new
                      remainder (45), and apply the division lemma to
                      get 90 = 45 * 2 +0
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabContext>
      </Box>
    </div>
  </Paper>
  )
}

export default StudentWorkbookView
