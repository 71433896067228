import React, { useRef, useState, useEffect } from "react";

// Css
import "../../css/CustomVideoPlayer/CustomVideoPlayer.css";

// Icons & Images Used
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PictureInPictureAltIcon from "@mui/icons-material/PictureInPictureAlt";
import Forward10Icon from "@mui/icons-material/Forward10";
import Replay10Icon from "@mui/icons-material/Replay10";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const CustomVideoPlayer = ({ selectedUrl }) => {
  const videoRef = useRef(null);
  const playBtnRef = useRef(null);
  const speakerIconRef = useRef(null);
  const volInputRef = useRef(null);
  const playbarRef = useRef(null);

  const [muted, setMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [totalTime, setTotalTime] = useState(0);
  const [playedTime, setPlayedTime] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isPiP, setIsPiP] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1); // Initial playback speed is normal (1x)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

  function togglePlay() {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  }

  function handleRangeUpdate(event) {
    const video = videoRef.current;
    if (video) {
      video[event.target.name] = event.target.value;
      setVolume(event.target.value);
    }
  }

  function mute() {
    const video = videoRef.current;
    if (video) {
      video.muted = !video.muted;
      setMuted(video.muted);
    }
  }

  function toggleFullScreen() {
    const video = videoRef.current;
    if (video) {
      if (!isFullScreen && !document.fullscreenElement) {
        video.requestFullscreen();
        setIsFullScreen(true);
      } else {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  }

  function togglePiP() {
    const video = videoRef.current;
    if (video) {
      if (!isPiP && document.pictureInPictureEnabled) {
        video.requestPictureInPicture();
        setIsPiP(true);
      } else {
        document.exitPictureInPicture();
        setIsPiP(false);
      }
    }
  }

  function handlePlaybarChange(event) {
    const video = videoRef.current;
    const playbar = playbarRef.current;
    if (video && playbar) {
      video.currentTime = (event.target.value / 100) * video.duration;
      playbar.style.backgroundSize = `${event.target.value}% 100%`;
    }
  }

  function handlePlaybackSpeedChange(speed) {
    const video = videoRef.current;
    if (video) {
      video.playbackRate = speed;
      setPlaybackSpeed(speed);
    }
  }

  useEffect(() => {
    const video = videoRef.current;
    const playBtn = playBtnRef.current;
    const volInput = volInputRef.current;

    if (video && playBtn && volInput) {
      video.addEventListener("loadedmetadata", () => {
        setTotalTime(video.duration);
      });

      video.addEventListener("timeupdate", () => {
        setPlayedTime(video.currentTime);
        const playbar = playbarRef.current;
        if (playbar) {
          playbar.value = (video.currentTime / video.duration) * 100;
          playbar.style.backgroundSize = `${playbar.value}% 100%`;
        }
      });
    }

    return () => {
      if (video) {
        video.removeEventListener("loadedmetadata", () => {
          setTotalTime(video.duration);
        });
        video.removeEventListener("timeupdate", () => {
          setPlayedTime(video.currentTime);
        });
      }
    };
  }, [videoRef]);

  function displayTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    return formattedTime;
  }

  return (
    <div className="video-box" onContextMenu={(e) => e.preventDefault()}>
      <div className={`player ${isFullScreen ? "fullscreen" : ""}`}>
        <video 
       className="player_video viewer"
       src={selectedUrl}
       ref={videoRef}
       controls
       preload={"auto"}
       data-setup="{}"
       playsInline
       data-vjs-player
       controlsList="nodownload"/>

      
        
      </div>
    </div>
  );
};

export default CustomVideoPlayer;
