import React from "react";
import { Box, Modal } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // p: 2,
  borderRadius: "10px",
};

const PlanModal = ({ open, close, data }) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            rowGap: "7px",
            overflow: "hidden",
          }}
        >
          <div style={{ color: "#ed9b00" }}>Learning Plan</div>
          <div
            style={{
              border: "1px solid",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              rowGap: "1rem",
              overflow: "auto",
              height: 200,
              borderColor: "rgba(0, 0, 0, 0.87)",
            }}
          >
            {data?.map((item) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                  key={item.id}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <div>{item?.plan} :</div>
                    <div>{item?.date},</div>
                    <div>{item?.time}</div>
                  </div>
                  <div>{item?.value}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default PlanModal;
