import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import chapterStyles from "./../css/createChapter.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import createschool from "./../css/CreateSchool.module.css";
import Pagination1 from "./pagination1";
// get create school css in this css file
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIconButton from "../../components/common/EditIconButton";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import { SearchOutlined } from "@mui/icons-material";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
// import documentType_controller_getall from '../API_CONTROLLER/document-category-controller/documentType_controller_getall';
import chapters_controller_post from "../API_CONTROLLER/chapters-controller/chapters_controller_post";
import chapters_file_uploader_post from "../API_CONTROLLER/chapters-controller/chapters_file_uploader_post";
import chapters_last_modified_get from "../API_CONTROLLER/chapters-controller/chapters_last_modified_get";
import assessment_table_get from "../API_CONTROLLER/accessment-controller/assessment_table_get";
import Switch from "../../components/common/Switch";
import CommonDeleteModal from "./CommonDeleteModal";
import chapters_edit_getby_id from "../API_CONTROLLER/chapters-controller/chapters_edit_getby_id";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";

const UploadAssessments = () => {
  const token = sessionStorage.getItem("token");
  const [gradesName, setGradesName] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [board, setBoard] = useState({});
  const [subject, setSubject] = useState({});
  const [subtopic, setSubtopic] = useState({});
  const [chapters, setChapters] = useState({});
  const [chapter, setChapter] = useState("");
  const [lastModifiedDate, setLastModifiedDate] = useState("");
  const [documenttype, setDocumenttype] = useState({});
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSubtopic, setSelectedSubtopic] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [selectedDocumenttype, setSelectedDocumenttype] = useState("");
  const [docType, setDoctype] = useState({});
  const [gradeInput, setGradeInput] = useState("");
  const [subjectInput, setSubjectInput] = useState("");
  const [subTopicInput, setSubTopicInput] = useState("");
  const [assessTable, setAssessTable] = useState("");

  const navigate = useNavigate();

  const [chapterInputs, setChapterInputs] = useState({
    // selectedBoard : "",
    // selectedGrade : "",
    // selectedSubject: "",
    // selectedSubtopic:"",
    // selectedChapter:"",
    boardId: "",
    gradeId: "",
    subjectId: "",
    chapter: "",
    docType: "",
    logoUrl: "",
    chapterStatus: "PENDING",
  });

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();
  const [selectedItem, setSelectedItem] = useState("");

  const showDeleteModal = (elem) => {
    setOpenDeleteModal(true);
    setSelectedItem(elem);
  };

  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteHandler = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        assessment_table_get(setAssessTable);
        hideDeleteModal();
      })
      .catch((err) => console.log(err));
  };

  const handleCreateChapter = (e) => {
    e.preventDefault();
    chapters_controller_post(chapterInputs);
    // setChapterInputs("")
    navigate("/dashboard/coadminDashboard");
  };

  const handlerChapterInputs = (e) => {
    e.preventDefault();
    setChapterInputs({
      ...chapterInputs,
      [e.target.name]: e.target.value,
    });
  };

  const switchHandler = (id, e) => {
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper/${id}/active?active=${e.target.checked}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        assessment_table_get(setAssessTable);
      })
      .catch((err) => console.log(err));
  };

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    e.preventDefault();
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };

  // pagination ends

  const [search, setSearch] = useState({
    search: "",
  });
  const handleSearch = (e) => {
    e.preventDefault();
    const search = {
      search: e.target.value,
    };
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput
    );
  };

  const addChapterHandler = (
    chapter,
    boardId,
    gradeId,
    subjectId,
    subTopicName,
    file
  ) => {};

  const imageChange = (e) => {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);
    chapters_file_uploader_post(
      setSelectedImage,
      setChapterInputs,
      chapterInputs,
      formData
    );
    window.alert("iiiiii");
  };

  const removeSelectedImage = () => {
    setChapterInputs({
      ...chapterInputs,
      logoUrl: null,
    });
  };

  useEffect(() => {
    setChapterInputs({
      ...chapterInputs,
      logourl: sessionStorage.getItem("logourl"),
    });
    return () => {};
  }, [sessionStorage.getItem("logoUrl")]);

  useEffect(() => {
    if (search.search === "") {
      chapters_controller_getall(
        setChapters,
        rowsInput,
        page,
        setPageDetail,
        search,
        gradeInput,
        subjectInput,
        subTopicInput
      );
    }
    return () => {};
  }, [rowsInput, search]);

  useEffect(() => {
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput
    );
  }, [rowsInput, page]);

  useEffect(() => {
    chapters_controller_getall(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput
    );
    return () => {};
  }, [search, gradeInput, subjectInput, subTopicInput]);

  useEffect(() => {
    GradesControllerGetall(setGradesName);
    boards_controller_getall(setBoard);
    subjects_controller_getall(setSubject);
    subtopics_controller_getall(setSubtopic);
    // chapters_controller_getall(setChapters);
    chapters_last_modified_get(setLastModifiedDate);
    assessment_table_get(setAssessTable);
    // documentType_controller_getall(setDocumenttype);

    return () => {};
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <a>View Quizes</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update:
              {lastModifiedDate.data}
            </p>
          </div>
        </article>
        {/* create chapter */}
        <Paper
          style={{
            marginTop: "55px",
            paddingRight: "33px",
            position: "absolute",
            right: "15px",
            left: "15px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 20px",
            }}
          >
            <div className={chapterStyles.chapter_container}>
              {/* search btn row */}
              <div
                className={chapterStyles.flex}
                style={{ marginTop: "15px", position: "relative" }}
              >
                <p
                  style={{
                    marginTop: "8px",
                    marginRight: "10px",
                    color: "#354052",
                    fontSize: "1.3vw",
                  }}
                >
                  <i class="fa-solid fa-chart-line"></i>Assessment Papers
                </p>

                <Box sx={{ minWidth: 115 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel
                      className={chapterStyles.select_input}
                      id="demo-select-small"
                    >
                      Grade
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="Grade"
                      value={gradeInput}
                      onChange={(e) => setGradeInput(e.target.value)}
                      style={{ background: "#fff", borderRadius: 150 }}
                      //   MenuProps={MenuProps}
                    >
                      <MenuItem value="">All</MenuItem>
                      {/* {optionsRender(gradesName)} */}
                      {gradesName.length > 0 ? (
                        gradesName?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            <ListItemText primary={item.grade} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>Select</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
                <Box style={{ margin: "auto 8px" }}>
                  <FormControl fullWidth size="small" style={{ width: "12vw" }}>
                    <InputLabel
                      className={chapterStyles.select_input}
                      id="demo-select-small"
                    >
                      Select subject
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={subjectInput}
                      label="subject"
                      onChange={(e) => setSubjectInput(e.target.value)}
                      style={{ background: "#fff", borderRadius: 150 }}
                      //   MenuProps={MenuProps}
                    >
                      <MenuItem value="">All</MenuItem>

                      {subject.length > 0 ? (
                        subject.map((subject, i) => {
                          return (
                            <MenuItem key={i} value={subject.id}>
                              <ListItemText primary={subject.subject} />
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem>Select</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 115 }}>
                  <FormControl fullWidth size="small" style={{ width: "9vw" }}>
                    <InputLabel
                      className={chapterStyles.select_input}
                      id="demo-select-small"
                    >
                      Sub Topic
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      label="subTopic"
                      style={{ background: "#fff", borderRadius: 150 }}
                      value={subTopicInput}
                      onChange={(e) => setSubTopicInput(e.target.value)}
                      //   MenuProps={MenuProps}/
                    >
                      <MenuItem value="">All</MenuItem>
                      {subtopic.length > 0 ? (
                        subtopic.map((subtopic, i) => {
                          return (
                            <MenuItem key={i} value={subtopic.id}>
                              <ListItemText primary={subtopic.subTopic} />
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem>Select</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>

                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    right: "-23px",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <button
                      style={{ height: "31px", fontSize: "0.9vw" }}
                      className={chapterStyles.green_btn}
                    >
                      <i class="bi bi-plus-circle"></i>Upload Assessment Papers
                    </button>
                    <button
                      style={{
                        height: "31px",
                        marginLeft: "8px",
                        fontSize: "0.9vw",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/dashboard/AssignAssessPaper");
                      }}
                      className={chapterStyles.green_btn}
                    >
                      <i class="bi bi-plus-circle"></i>Assign Assessment Papersr
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* table */}
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              sx={{
                overflow: "auto",
              }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={chapterStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={chapterStyles.table_head}
                    >
                      Paper Name
                    </TableCell>
                    <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    >
                      Board
                    </TableCell>
                    <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    >
                      Grade
                    </TableCell>
                    <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    >
                      Subject
                    </TableCell>
                    <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    >
                      Academic Year
                    </TableCell>
                    <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="center"
                      className={chapterStyles.table_head}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assessTable.length > 0 ? (
                    assessTable.map((elem, i) => (
                      <TableRow>
                        {/* index */}
                        <TableCell className={chapterStyles.table_cell}>
                          {i + 1}
                        </TableCell>
                        {/* index */}
                        <TableCell className={chapterStyles.table_cell}>
                          {elem.paperTitle}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.board}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.grade}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.subject}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={chapterStyles.table_cell}
                          align="center"
                        >
                          {elem.academicYear}
                        </TableCell>
                        {/* active btn */}
                        <TableCell className={chapterStyles.table_cell}>
                          <Switch
                            checked={elem?.active}
                            onChange={(e) => switchHandler(elem?.id, e)}
                          />
                        </TableCell>
                        {/* index */}
                        <TableCell
                          align="right"
                          className={chapterStyles.table_cell}
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "tableCell" }}>
                            <EditIconButton
                              onClick={() => {
                                // openAddAcademic();
                                // setIsEdit(true);
                                // setStartingYear(item?.startingYear);
                                // setEndingYear(item?.endingYear);
                                // setSelectedItem(item);
                              }}
                            >
                              <Tooltip title="Edit" placement={"top"}>
                                <EditOutlinedIcon
                                  style={{ fontSize: "25px" }}
                                />
                              </Tooltip>
                            </EditIconButton>
                            <DeleteIconButton
                              onClick={() => {
                                showDeleteModal(elem);
                              }}
                            >
                              <Tooltip title="Remove" placement={"top"}>
                                <DeleteOutlinedIcon
                                  style={{ fontSize: "25px" }}
                                />
                              </Tooltip>
                            </DeleteIconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <td
                      colSpan="10"
                      style={{
                        height: "25rem",
                        textAlign: "center",
                        color: "grey",
                      }}
                    >
                      {" "}
                      NO DATA AVAILABLE{" "}
                    </td>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* pagination container - Start */}
            <div className={chapterStyles.pagination} style={{ width: "100%" }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <span className={chapterStyles.rows}>
                  <label for="rows">Rows per page</label>
                  <select
                    name="rows"
                    id="rows"
                    value={rowsInput.rows}
                    onChange={handlerRowsInputs}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </span>
              </form>
              <span>
                <Pagination1
                  pageDetail={pageDetail}
                  handleChange={handleChange}
                />
              </span>
            </div>
            {/* pagination container - End */}
          </Paper>
        </Paper>
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Assessment"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem.id}
          deleteName={selectedItem.paperTitle}
        />
      </div>
    </>
  );
};

export default UploadAssessments;
