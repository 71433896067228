import React, { useState, useEffect } from "react";
import RevisionAdminBlueprintLayer from "../RevisionAdminBlueprintLayer/RevisionAdminBlueprintLayer";
import CreateUpload from "./CreateUpload";
import "./uploadAssessment.css";
import BluePrintFilter from "../../../../../components/BluePrintCommonTable/BluePrintFilter";
import UploadAssessmentTableHead from "./UploadAssessmentTableHead";
import { UploadAssessmentTableBody } from "./UploadAssessmentTableBody";
import { tableHead } from "../Schema/schema";
import {
  bluePrintsAllGrades,
  bluePrintsAllSubjects,
  bluePrintsBoards,
  bluePrintsGrades,
  bluePrintsSubjects,
  fetchBluePrintLevels,
} from "../../../BluePrint/Apis/BluePrintApis";
import {
  bluePrintAcademicYear,
  deleteAssessment,
  fetchUploadAssessmentForListView,
  toggleActiveStatus,
} from "../Apis/RevisionAdminBluePrintApis";
import { TablePagination } from "@mui/material";
import DeleteModal from "../../../../../components/DeleteModal/DeleteModal";
import BluePrintDeleteModal from "../../../../../components/common/BluePrintDeleteModal/BluePrintDeleteModal";

export default function AssessmentUpload() {
  const [boardList, setBoardList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [selectedForDelete, setSelectedForDelete] = useState(null);

  const [selectedForEdit, setSelectedForEdit] = useState(null);

  const [uploadData, setUploadData] = useState([]);

  const [allGradeList, setAllGradeList] = useState([]);
  const [allSubjectList, setAllSubjectList] = useState([]);

  const [formSelectedGrade, setFormSelectedGrade] = useState("");
  const [formSelectedBoard, setFormSelectedBoard] = useState("");

  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");

  const [pageSize, setpageSize] = useState(5);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [isCreated, setCreated] = useState(false);
  const [totalPage, setTotalPage] = useState(0);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    fetchBoardList();
    fetchLevelList();
    fetchAcademicYearList();
    fetchAllGradeList();
    fetchAllSubjectList();
  }, []);

  useEffect(() => {
    if (formSelectedBoard) fetchGradeList();
  }, [formSelectedBoard]);

  useEffect(() => {
    if (formSelectedBoard && formSelectedGrade) {
      fetchSubjectList();
    }
  }, [formSelectedBoard, formSelectedGrade]);

  const fetchBoardList = async () => {
    const response = await bluePrintsBoards();
    setBoardList(response);
  };

  const fetchGradeList = async () => {
    const response = await bluePrintsGrades(formSelectedBoard);
    setGradeList(response);
  };

  const fetchSubjectList = async () => {
    const response = await bluePrintsSubjects(
      formSelectedBoard,
      formSelectedGrade
    );
    setSubjectList(response);
  };
  const fetchLevelList = async () => {
    const response = await fetchBluePrintLevels();
    setLevelList(response);
  };

  const fetchAcademicYearList = async () => {
    const response = await bluePrintAcademicYear();
    setAcademicYearList(response);
  };

  const fetchAllGradeList = async () => {
    const response = await bluePrintsAllGrades();
    setAllGradeList(response);
  };

  const fetchAllSubjectList = async () => {
    const response = await bluePrintsAllSubjects();
    setAllSubjectList(response);
  };

  const fetchUploadAssessments = async () => {
    setCreated(false);
    let pageNumber = page;
    const response = await fetchUploadAssessmentForListView(
      pageNumber,
      pageSize,
      selectedBoard,
      selectedGrade,
      selectedSubject,
      search
    );
    setUploadData(response?.data);
    setTotalPage(response?.totalPages);
    setTotalElements(response?.totalElements);
    setCurrentPage(response?.pageNumber);
  };

  useEffect(() => {
    fetchUploadAssessments();
  }, [
    selectedBoard,
    selectedGrade,
    selectedSubject,
    search,
    pageSize,
    page,
    isCreated,
  ]);

  const handleToggle = async (e, id) => {
    const response = await toggleActiveStatus(e.target.checked, id);
    fetchUploadAssessments();
  };

  const onDeleteConfirm = async () => {
    const response = await deleteAssessment(selectedForDelete);
    setSelectedForDelete(null);
    setShowDeleteModal(false);
    fetchUploadAssessments();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setpageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <RevisionAdminBlueprintLayer>
      <div
        className="filter-wrapper"
        style={{ marginTop: "30px", marginBottom: "20px" }}
      >
        {" "}
        <CreateUpload
          boardList={boardList}
          gradeList={gradeList}
          subjectList={subjectList}
          levelList={levelList}
          academicYearList={academicYearList}
          formSelectedBoard={formSelectedBoard}
          setFormSelectedBoard={setFormSelectedBoard}
          formSelectedGrade={formSelectedGrade}
          setFormSelectedGrade={setFormSelectedGrade}
          setCreated={setCreated}
          setSelectedForEdit={setSelectedForEdit}
          selectedForEdit={selectedForEdit}
        />
        <BluePrintFilter
          title="Assessment paper"
          selectedBoard={selectedBoard}
          setSelectedBoard={(i) => {
            setPage(0);
            setSelectedBoard(i);
          }}
          selectedGrade={selectedGrade}
          setSelectedGrade={(i) => {
            setPage(0);
            setSelectedGrade(i);
          }}
          selectedSubject={selectedSubject}
          setSelectedSubject={(i) => {
            setPage(0);
            setSelectedSubject(i);
          }}
          search={search}
          setSearch={(i) => {
            setPage(0);
            setSearch(i);
          }}
          gradeList={allGradeList}
          subjectList={allSubjectList}
          boardList={boardList}
        />
        <table className="table-wrapper" style={{ marginTop: "15px" }}>
          <UploadAssessmentTableHead tableHeadData={tableHead} />
          <UploadAssessmentTableBody
            blueprintsData={uploadData}
            pageSize={pageSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handleToggle={handleToggle}
            setShowDeleteModal={setShowDeleteModal}
            setSelectedForDelete={setSelectedForDelete}
            setSelectedForEdit={setSelectedForEdit}
          />
        </table>
        {uploadData?.length > 0 && (
          <div style={{ marginTop: "5px" }}>
            <span>
              <TablePagination
                style={{
                  bottom: "0",
                  width: "100%",
                  backgroundColor: "white",
                }}
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={totalElements}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
              />
            </span>
          </div>
        )}
      </div>
      {showDeleteModal ? (
        <BluePrintDeleteModal
          userType={" Are you sure you want to delete ?"}
          open={showDeleteModal}
          handleOk={closeDeleteModal}
          onConfirm={onDeleteConfirm}
        />
      ) : // <DeleteModal
      //   open={setShowDeleteModal}
      //   handleClose={() => {
      //     setShowDeleteModal(false);
      //     setSelectedForDelete(null);
      //   }}
      //   onConfirm={onDeleteConfirm}
      // />
      null}
    </RevisionAdminBlueprintLayer>
  );
}
