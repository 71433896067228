import axios from 'axios'

async function update_question_post(
    payload,
    setShowSuccessAlert,
    setResponseValue,
    setShowErrorAlert,
    setShowErroMessage,
) {
    let token = sessionStorage.getItem("token");
    let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question`, payload,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
        .then(
            (response) => {
                setShowSuccessAlert(true);
                setResponseValue(response.data.data);
            }
        )
        .catch(err => {
            setShowErrorAlert(true);
            setShowErroMessage(err.response.data.message);
            console.log(err)
        });
}

export default update_question_post;
