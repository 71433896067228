import React, { useRef } from 'react'
import './Principaldashboard.css'
import { lastRowContainer } from './styles'
import { dummy } from './dummyData'
import { schoolVsGlobal } from '../../Cordinator/dashboard/dummyData'

const RowThree = ({token,selectedGrade,
  selectedSubject,
  selectedSubtopic}) => {

  const containerRef= useRef(null)
  const scroll = (scrollOffset) => {
    containerRef.current.scrollLeft += scrollOffset;
  };
  return (
    <div style={{ width: '100%', height: '296px',marginTop:'10px', background: '#fff',padding:'10px'}}>
    <p className="sub-heading">Unit Quiz Performance - School avg vs Global avg</p>
    <div className="bar-chart-container">
      <div className="round-btn" onClick={()=>scroll(-150)}>{'<'}</div>
    <div ref={containerRef} className="bar-chart">
    {schoolVsGlobal.map((item,index)=> <div className='bar-pair'>
      <div className='bar' style={{background:"#13DEE6",marginRight:'14px',height:`${item.school}%`}} >
        <p className='bar-label'>{item.school}%</p>
      </div>
      <div className='bar' style={{background:"#2AE31B",height:`${item.global}%`}} >
      <p className='bar-label'>{item.global}%</p>
      </div>
      <p className="pair-label">
        {`Section ${index}`}
      </p>
      <span style={{background:'#C9C9C9',width:'101px',height:'1px',position:'absolute',bottom:0,left:'-15px'}} />

    </div>)}
    </div>
    <div className="round-btn" onClick={()=>scroll(150)}>{'>'}</div>

    </div>
  </div>
  )
}

export default RowThree