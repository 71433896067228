import React, { useState, useEffect } from "react";
import SchoolControllerIdGet from "../API/school-controller/User_School_Id";

function School_name() {
  const [schoolIdData, setSchoolIdData] = useState({});
  useEffect(() => {
    SchoolControllerIdGet(setSchoolIdData);
    return () => {};
  }, []);
  return (
    <>
      <i class="fa-solid fa-house-user" aria-hidden="true"></i>{" "}
      {schoolIdData.name}
    </>
  );
}

export default School_name;
