import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { grey, red } from "@mui/material/colors";

const ShareIconButton = (props) => {
  const ShareButton = styled(Button)(() => ({
    color: grey[500],
    borderRadius: 150,
    "&:hover": {
      color: red[600],
    },
  }));
  return <ShareButton sx={{minHeight: 0, minWidth: 0, padding: 0 }} onClick={props.onClick}>{props.children}</ShareButton>;
};
export default ShareIconButton;
