import axios from 'axios'

function map_img_post(formData,mapInputs,setMapInputs,setSelectedImage){
     let token = sessionStorage.getItem("token");
    let result = axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/file/upload?fileCategory=CONTENT_SERVICE&subFolders=INTERACTIVE_CONTENT`,formData,{
        headers:{
            "Content-Type":"application/json",
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        setSelectedImage(res.data.data)
        sessionStorage.setItem("mapUrl",res.data.data)
        setMapInputs({
            ...mapInputs,
            mapUrl:res.data.data
        })
    })
    .catch(err => console.log(err))
}

export default map_img_post