import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import chapterStyles from "../../../css/createChapter.module.css";
import Typography from '@mui/material/Typography';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import conceptlist_get from "../../../API_CONTROLLER/concept-controller/conceptlist_get";
import subjects_controller_getall from "../../../API_CONTROLLER/subject_controller/subjects_controller_getall";
import chapters_edit_getby_id from "../../../API_CONTROLLER/chapters-controller/chapters_edit_getby_id";
import chapters_controller_put from "../../../API_CONTROLLER/chapters-controller/chapters_controller_put";
import rootConcept_controller_get from "../../../API_CONTROLLER/root-concept-controller/rootConcept_controller_get";
import subConcept_controller_get from "../../../API_CONTROLLER/concept-controller/subConcept_controller_get";
import taxonomy_categorylist_get from "../../../API_CONTROLLER/taxonomy-controller/taxonomy_categorylist_get";
import taxonomy_get from "../../../API_CONTROLLER/taxonomy-controller/taxonomy_get";
import queType_get from "../../../API_CONTROLLER/question-controller/queType_get";
import subjective_get from "../../../API_CONTROLLER/subjective_get/subjective_get";

const SelectQuestions = (props) => {
  const [subject, setSubject] = useState({});
  const [selectedSubject, setSelectedSubject] = useState("");
  const [conceptlist, setConceptlist] = useState({});
  const [conceptInputs, setConceptInputs] = useState("");
  const [subconceptList, setSubconceptList] = useState({});
  const [subConceptInputs, setSubConceptInputs] = useState("");
  const [rootConceptList, setRootConceptList] = useState({});
  const [rootConcept, setRootConcept] = useState("");
  const [queType, setQueType] = useState({});
  const [chapterInputs, setChapterInputs] = useState({
    boardId: "",
    gradeId: "",
    subjectId: "",
    chapter: "",
    // docType:"",
    // logoUrl:"",
    chapterStatus: "PENDING",
  });
  const [taxonomyCategoryList, setTaxonomyCategoryList] = useState({});
  const [taxonomyList, setTaxonomyList] = useState({});
  const [selectedTaxonomyCategory, setSelectedTaxonomyCategory] = useState("");
  const [selectedTaxonomy, setSelectedTaxonomy] = useState("");
  const [matchTypeList, setMatchTypeList] = useState(["DRAG_DROP", "DRAW_LINE"]);
  const [selectedMatchType, setSelectedMatchType] = useState("");
  const [isAddChapter, setIsAddChapter] = useState(true);

  const [errorField, setErrorField] = useState({});

  const [questionType, setQuestionType] = useState("");
  const [questionCode, setQuestionCode] = useState("");

  const [subjectiveTypeList, setSubjectiveTypeList] = useState({});
  const [selectedSubjectiveType, setSelectedSubjectiveType] = useState("");
  // const [newConcept, setNewConcept] = useState(false);
  const navigate = useNavigate();
  const prevLocation = useLocation();

  const questionData = props.questionData;

  useEffect(() => {
    if (props.newConcept === true) {
      setConceptInputs("");
      setSubConceptInputs("");
      setRootConcept("");
    }
  }, [props.newConcept]);

  useEffect(() => {
    if (props.goToQuestionBank === true) {
      setSelectedSubject("");
      setConceptInputs("");
      setSubConceptInputs("");
      setRootConcept("");
      setQuestionType("");
      setSelectedTaxonomyCategory("");
      setSelectedTaxonomy("");
      setSelectedMatchType("");
      setSelectedSubjectiveType("")
      navigate(`/dashboard/QuestionBank`);
    }
  }, [props.goToQuestionBank])

  useEffect(() => {
    if (conceptInputs !== "") {
      setSubConceptInputs("");
      subConcept_controller_get(conceptInputs, setSubconceptList);
    }
    if (questionData) {
      if (!props.goToQuestionBank && !props.newConcept) {
        setSubConceptInputs(questionData.subConceptId);
      }
    }
  }, [conceptInputs]);

  useEffect(() => {
    if (subConceptInputs !== "") {
      setRootConcept("");
      rootConcept_controller_get(subConceptInputs, setRootConceptList);
    }
    if (questionData) {
      if (!props.goToQuestionBank && !props.newConcept) {
        setRootConcept(questionData.rootConceptId)
      }
    }
  }, [subConceptInputs]);

  useEffect(() => {
    if (selectedTaxonomyCategory !== "") {
      setSelectedTaxonomy("");
      taxonomy_get(setTaxonomyList, selectedTaxonomyCategory, questionType === "402881858369cb30018369d7dfd70000" ? true : false);
    }
    if (questionData) {
      if (!props.goToQuestionBank) {
        setSelectedTaxonomy(questionData.taxonomyId);
      }
    }
  }, [selectedTaxonomyCategory]);

  useEffect(() => {
    if (props.isSubjective) {
      subjective_get(setSubjectiveTypeList);
    }
  }, [props.isSubjective]);

  useEffect(() => {
    if (isAddChapter == false) {
      chapters_edit_getby_id(setChapterInputs);
      return () => { };
    }
  }, []);

  useEffect(() => {
    if (selectedSubject !== "") {
      setConceptInputs("");
      conceptlist_get(setConceptlist, selectedSubject);
    }
    if (questionData) {
      if (!props.goToQuestionBank) {
        setConceptInputs(questionData.conceptId);
      }
    }
  }, [selectedSubject]);

  useEffect(() => {
    subjects_controller_getall(setSubject);
    taxonomy_categorylist_get(setTaxonomyCategoryList);
    queType_get(setQueType);
    return () => { };
  }, []);

  useEffect(() => {
    if (questionType !== "") {
      queType && queType?.map(que => {
        if (que?.id === questionType) {
          setQuestionCode(que.code);
        }
      })
    }
  }, [questionType, queType])

  const onSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
    setErrorField(prevState => {
      delete prevState['subject'];
      return prevState;
    })
  };

  const handleConceptInputs = (e) => {
    setConceptInputs(e.target.value);
    setErrorField(prevState => {
      delete prevState['concept'];
      return prevState;
    })
  };

  const handlesubConceptInputs = (e) => {
    e.preventDefault();
    setSubConceptInputs(e.target.value);
    setErrorField(prevState => {
      delete prevState['subconcept'];
      return prevState;
    })
  };

  const handleRootConceptInputs = (e) => {
    setRootConcept(e.target.value);
    setErrorField(prevState => {
      delete prevState['rootconcept'];
      return prevState;
    })
  }

  const handleQuestionType = (e) => {
    setQuestionType(e.target.value);
    queType.map(que => {
      if (que.id === e.target.value) {
        setQuestionCode(que.code);
      }
    })
    setErrorField(prevState => {
      delete prevState['questiontype'];
      return prevState;
    })
    sessionStorage.removeItem("mapImage");
    sessionStorage.removeItem("mapUrl");
    sessionStorage.removeItem("parentId");
  };

  const handlerSubjectiveType = (e) => {
    setSelectedSubjectiveType(e.target.value);
    sessionStorage.setItem("subjectiveType", e.target.value);
    subjectiveTypeList.map(que => {
      if (que.name === e.target.value) {
        setSelectedSubjectiveType(que.name);
        setQuestionCode("")
        setQuestionType("");
      }
    })
    setErrorField(prevState => {
      delete prevState['subjectivetype'];
      delete prevState['questiontype'];
      return prevState;
    })
    sessionStorage.removeItem("parentId");
    sessionStorage.removeItem("mapImage");
    sessionStorage.removeItem("mapUrl");
  };

  const handlerTaxonomyInputs = (e) => {
    setSelectedTaxonomy(e.target.value);
    setErrorField(prevState => {
      delete prevState['taxonomy'];
      return prevState;
    })
  }

  const handlerTaxonomyCategory = (e) => {
    setSelectedTaxonomyCategory(e.target.value);
    setErrorField(prevState => {
      delete prevState['taxonomycategory'];
      return prevState;
    })
  }

  const handlerMatchType = (e) => {
    setSelectedMatchType(e.target.value);
    setErrorField(prevState => {
      delete prevState['matchType'];
      return prevState;
    })
  }

  if (prevLocation.state === "edit") {
    setIsAddChapter(false);
    prevLocation.state = null;
  }

  const handleCreateChapter = (e) => {
    console.log("questionType", questionType)
    props.setNewConcept(false);
    props.setGoToQuestionBank(false);
    e.preventDefault();
    let errorObj = {}
    if (questionCode === "MATCH" && (selectedMatchType === "") || props.isSubjective === true && (selectedSubjectiveType === "") || selectedSubject === "" || conceptInputs === "" || subConceptInputs === "" || questionType === "" || questionCode !== "CASE" && (selectedTaxonomyCategory === "" || selectedTaxonomy === "") || selectedSubjectiveType !== "Compound" && (selectedTaxonomyCategory === "" || selectedTaxonomy === "")) {
      if (selectedSubject === "") {
        errorObj["subject"] = "Subject field is required"
      }
      if (conceptInputs === "") {
        errorObj["concept"] = "Concept field is required"
      }
      if (subConceptInputs === "") {
        errorObj["subconcept"] = "Subconcept field is required"
      }
      if (questionType === "") {
        errorObj["questiontype"] = "Question type field is required"
      }
      if (questionType == "4028938b8697cf27018697d061240000") {
        if (!(selectedSubjectiveType !== "Compound" || questionCode !== "CASE") && selectedTaxonomyCategory === "") {
          // if (selectedTaxonomyCategory === "") {
          errorObj["taxonomycategory"] = "Taxonomy category field is required"
        }
      } else {
        if (selectedSubjectiveType !== "Compound" && selectedTaxonomyCategory === "") {
          errorObj["taxonomycategory"] = "Taxonomy category field is required"
        }
      }
      if (questionType == "4028938b8697cf27018697d061240000") {
        if (!(selectedSubjectiveType !== "Compound" || questionCode !== "CASE") && selectedTaxonomy === "") {
          // if (selectedTaxonomy === "") {
          errorObj["taxonomy"] = "Taxonomy field is required"
        }
      } else {
        if (selectedSubjectiveType !== "Compound" && selectedTaxonomy === "") {
          errorObj["taxonomy"] = "Taxonomy field is required"
        }
      }
      if (questionCode === "MATCH" && selectedMatchType === "") {
        errorObj["matchType"] = "Match Type field is required"
      }
      if (questionCode !== "CASE" && selectedSubjectiveType === "") {
        errorObj["subjectivetype"] = "Subjective Type field is required"
      }
    }
    console.log("errorObj", errorObj)
    setErrorField(errorObj);
    if (!Object.keys(errorObj).length) {
      setErrorField({ id: "", message: "" });

      props.objQuestionTypeChange(questionCode);
      props.objSubjectiveQuestionTypeChange(selectedSubjectiveType);

      sessionStorage.setItem("subjectId", selectedSubject);
      sessionStorage.setItem("conceptId", conceptInputs);
      sessionStorage.setItem("subConceptId", subConceptInputs);
      sessionStorage.setItem("rootConceptId", rootConcept || "");
      sessionStorage.setItem("questionType", questionType);
      sessionStorage.setItem("taxonomyCategory", selectedTaxonomyCategory);
      sessionStorage.setItem("taxonomy", selectedTaxonomy);
      sessionStorage.setItem("matchType", selectedMatchType);
      sessionStorage.setItem("subjectiveType", selectedSubjectiveType);
    }
  };

  const handleUpdateChapter = (e) => {
    e.preventDefault();
    chapters_controller_put(chapterInputs);
    navigate("/dashboard/Upload%20Content");
  };

  useEffect(() => {
    if (questionData) {
      sessionStorage.setItem("subjectId", questionData.subjectId);
      sessionStorage.setItem("conceptId", questionData.conceptId);
      sessionStorage.setItem("subConceptId", questionData.subConceptId);
      sessionStorage.setItem("rootConceptId", questionData.rootConceptId || "");
      sessionStorage.setItem("questionType", questionData.questionTypeId);
      sessionStorage.setItem("taxonomyCategory", questionData.taxonomyCategoryId);
      sessionStorage.setItem("taxonomy", questionData.taxonomyId);
      sessionStorage.setItem("matchType", questionData.matchType);
      setSelectedSubject(questionData.subjectId);
      setQuestionType(questionData.questionTypeId);
      setSelectedTaxonomyCategory(questionData.taxonomyCategoryId);
      setSelectedMatchType(questionData.matchType);
      let SType = ''
      if (questionData.subjectiveType) SType = questionData.subjectiveType.charAt(0) + questionData.subjectiveType.slice(1).toLowerCase()
      if (SType) {
        props.objSubjectiveQuestionTypeChange(SType);
        setSelectedSubjectiveType(SType)
      }
      props.objQuestionTypeChange(questionData.questionType?.toUpperCase())
    }
  }, [questionData]);

  const labelStyle = {
    fontSize: "14px",
    root: {
      color: "#fd8c00",
    },
    "&.MuiInputLabel-shrink": {
      color: "#ff9900" /* Set the desired color */
    },
    "&.Mui-focused": {
      color: "orange !important",
      fontWeight: "bold",
      fontFamily: "Poppins",
      fontSize: "13px",
    },
  };

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12} md={6} lg={3}>
        <FormControl sx={{ width: '19vw' }}>
          <InputLabel
            className={chapterStyles.select_input}
            id="demo-simple-select-required-label"
            sx={labelStyle}
          >
            *Subject
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            // name="subject"
            className={chapterStyles.select_selected}
            id="demo-simple-select-required"
            value={selectedSubject}
            onChange={onSubjectChange}
            input={<OutlinedInput label="Subject" />}
            disabled={props.disableTopFields}
          // renderValue={(selected) => selected.join(", ")}
          //   MenuProps={MenuProps}
          >
            {subject.length > 0 ? (
              subject.map((subject, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={subject.id}
                    className={chapterStyles.select_menu}
                    id={`az-revisionadmin-subjectdropdown-${subject.id}`}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    {subject.subject}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem>Select</MenuItem>
            )}
          </Select>
        </FormControl>
        {!!errorField["subject"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["subject"]}</Typography>}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormControl sx={{ width: "19vw" }}>
          <InputLabel
            className={chapterStyles.select_input}
            id="demo-simple-select-required-label"
            sx={labelStyle}
          >
            *Concept
          </InputLabel>
          <Select
            name="conceptId"
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            className={chapterStyles.select_selected}
            value={conceptInputs}
            onChange={handleConceptInputs}
            input={<OutlinedInput label="Concept" />}
            disabled={props.disableTopFields}
          // renderValue={(selected) => selected.join(", ")}
          >
            {/* {optionsRender(board)} */}
            {conceptlist && conceptlist.length > 0 ? (
              conceptlist.map((elem, i) => {
                return (
                  <MenuItem key={i} value={elem.id} className={chapterStyles.select_menu}
                    id={`az-revisionadmin-conceptdropdown-${elem.id}`}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}>
                    {elem.concept}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem>Select</MenuItem>
            )}
          </Select>
        </FormControl>
        {!!errorField["concept"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["concept"]}</Typography>}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormControl sx={{ width: "19vw" }}>
          <InputLabel
            className={chapterStyles.select_input}
            id="demo-simple-select-required-label"
            sx={labelStyle}
          >
            *Sub-Concept
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            className={chapterStyles.select_selected}
            name="subConceptId"
            value={subConceptInputs}
            onChange={handlesubConceptInputs}
            input={<OutlinedInput label="Sub-Concept" />}
            disabled={props.disableTopFields}
          >
            {subconceptList && subconceptList.length > 0 ? (
              subconceptList.map((elem, i) => {
                return (
                  <MenuItem key={i} value={elem.id} className={chapterStyles.select_menu}
                    id={`az-revisionadmin-subconceptdropdown-${elem.id}`}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}>
                    {elem.subConcept}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem>Select</MenuItem>
            )}
          </Select>
        </FormControl>
        {!!errorField["subconcept"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["subconcept"]}</Typography>}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormControl sx={{ width: "19vw" }}>
          <InputLabel
            className={chapterStyles.select_input}
            id="demo-simple-select-required-label"
            sx={labelStyle}
          >
            Root Concept
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            className={chapterStyles.select_selected}
            name="rootConcept"
            value={rootConcept}
            onChange={handleRootConceptInputs}
            input={<OutlinedInput label="Root-Concept" />}
            disabled={props.disableTopFields}
          >
            {rootConceptList && rootConceptList.length > 0 ? (
              rootConceptList.map((elem, i) => {
                return (
                  <MenuItem key={i} value={elem.id} className={chapterStyles.select_menu}
                    id={`az-revisionadmin-rootconceptdropdown-${elem.id}`}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}>
                    {elem.rootConcept}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem>Select</MenuItem>
            )}
          </Select>
        </FormControl>
        {!!errorField["rootconcept"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["rootconcept"]}</Typography>}
      </Grid>
      {props.isSubjective &&
        <>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl sx={{ width: "19vw" }}>
              <InputLabel
                className={chapterStyles.select_input}
                id="demo-simple-select-required-label"
                sx={labelStyle}
              >
                *S/C Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                className={chapterStyles.select_selected}
                name="S/C Type"
                value={selectedSubjectiveType}
                onChange={handlerSubjectiveType}
                input={<OutlinedInput label="S/C Type" />}
                disabled={props.disableTopFields}
              >
                {subjectiveTypeList.length > 0 ? (
                  subjectiveTypeList.map((elem, i) => {
                    return (
                      <MenuItem key={i} value={elem.name} className={chapterStyles.select_menu}
                        id={`az-revisionadmin-subjectdropdown-${elem.name}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        {elem.name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
            {!!errorField["subjectivetype"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["subjectivetype"]}</Typography>}
          </Grid>
        </>
      }
      <Grid item xs={12} md={6} lg={3}>
        <FormControl sx={{ width: "19vw" }}>
          <InputLabel
            className={chapterStyles.select_input}
            id="demo-simple-select-required-label"
            sx={labelStyle}
          >
            *Question Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            className={chapterStyles.select_selected}
            name="questionType"
            value={questionType}
            onChange={handleQuestionType}
            input={<OutlinedInput label="Question Type" />}
            disabled={props.disableTopFields}
          // renderValue={(selected) => selected.join(", ")}
          //   MenuProps={MenuProps}
          >
            {queType?.length > 0 ? (
              queType.map((elem, i) => {
                return (
                  <MenuItem key={i} value={elem.id} className={chapterStyles.select_menu}
                    id={`az-revisionadmin-questiontypedropdown-${elem.id}`}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}>
                    {elem.questionType}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem>SELECT</MenuItem>
            )}
          </Select>
        </FormControl>
        {!!errorField["questiontype"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["questiontype"]}</Typography>}
      </Grid>
      {questionCode !== "" && questionCode === "CASE" || selectedSubjectiveType === "Compound" ?
        <Grid item xs={12} md={6} lg={9} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControl sx={{ width: "19vw", alignItems: "flex-end" }}>
            {isAddChapter ? (
              <button
                style={{ maxHeight: "50px" }}
                onClick={handleCreateChapter}
                className={chapterStyles.blue_btn}
                disabled={props.disableTopFields}
              >
                SUBMIT
              </button>
            ) : (
              <button
                style={{ maxHeight: "50px" }}
                onClick={handleUpdateChapter}
                className={chapterStyles.blue_btn}
              >
                UPDATE
              </button>
            )}
          </FormControl>
        </Grid>
        :
        <>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl sx={{ width: "19vw" }}>
              <InputLabel
                className={chapterStyles.select_input}
                id="demo-simple-select-required-label"
                sx={labelStyle}
              >
                *Taxonomy Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                className={chapterStyles.select_selected}
                name="taxonomyCategory"
                value={selectedTaxonomyCategory}
                onChange={handlerTaxonomyCategory}
                input={<OutlinedInput label="Taxonomy Category" />}
                disabled={props.disableTopFields}
              >
                {taxonomyCategoryList?.length > 0 ? (
                  taxonomyCategoryList.map((elem, i) => {
                    return (
                      <MenuItem key={i} value={elem.id} className={chapterStyles.select_menu}
                        id={`az-revisionadmin-taxonomydropdown-${elem.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        {elem.taxonomyCategory}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
            {!!errorField["taxonomycategory"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["taxonomycategory"]}</Typography>}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl sx={{ width: "19vw" }}>
              <InputLabel
                className={chapterStyles.select_input}
                id="demo-simple-select-required-label"
                sx={labelStyle}
              >
                *Taxonomy
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                className={chapterStyles.select_selected}
                name="taxonomy"
                value={selectedTaxonomy}
                onChange={handlerTaxonomyInputs}
                input={<OutlinedInput label="Taxonomy" />}
                disabled={props.disableTopFields}
              >
                {taxonomyList.length > 0 ? (
                  taxonomyList.map((elem, i) => {
                    return (
                      <MenuItem key={i} value={elem.id} className={chapterStyles.select_menu}
                        id={`az-revisionadmin-taxonomynamedropdown-${elem.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        {elem.taxonomyName}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
            {!!errorField["taxonomy"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["taxonomy"]}</Typography>}
          </Grid>
        </>
      }
      {questionCode !== "" && questionCode === "MATCH" ?
        <>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl sx={{ width: "19vw" }}>
              <InputLabel
                className={chapterStyles.select_input}
                id="demo-simple-select-required-label"
                sx={labelStyle}
              >
                *Match Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                className={chapterStyles.select_selected}
                name="taxonomy"
                value={selectedMatchType}
                onChange={handlerMatchType}
                input={<OutlinedInput label="Match Type" />}
                disabled={props.disableTopFields}
              >
                {matchTypeList.length > 0 ? (
                  matchTypeList.map((elem, i) => {
                    return (
                      <MenuItem key={i} value={elem} className={chapterStyles.select_menu}
                        id={`az-revisionadmin-matchtypedropdown-${elem}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: 'orange',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#fcedc2',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        {elem}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
            {!!errorField["matchType"] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{errorField["matchType"]}</Typography>}
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", width: "19vw", justifyContent: "flex-end" }}>
            <FormControl sx={{ width: "19vw", alignItems: "flex-end" }}>
              {isAddChapter ? (
                <button
                  style={{ maxHeight: "50px" }}
                  onClick={handleCreateChapter}
                  className={chapterStyles.blue_btn}
                >
                  SUBMIT
                </button>
              ) : (
                <button
                  style={{ maxHeight: "50px" }}
                  onClick={handleUpdateChapter}
                  className={chapterStyles.blue_btn}
                >
                  UPDATE
                </button>
              )}
            </FormControl>
          </Grid>
        </>
        :
        questionCode !== '' && questionCode === "CASE" || selectedSubjectiveType === "Compound" ?
          <>
          </>
          :
          <>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl sx={{ width: "19vw", alignItems: "flex-end" }}>
                {isAddChapter ? (
                  <button
                    style={{ maxHeight: "50px" }}
                    onClick={handleCreateChapter}
                    className={chapterStyles.blue_btn}
                  >
                    SUBMIT
                  </button>
                ) : (
                  <button
                    style={{ maxHeight: "50px" }}
                    onClick={handleUpdateChapter}
                    className={chapterStyles.blue_btn}
                  >
                    UPDATE
                  </button>
                )}
              </FormControl>
            </Grid>
          </>
      }
    </Grid>
  );
};

export default SelectQuestions;
