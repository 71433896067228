import React, { useState } from 'react'
import createConceptStyles from './../css/CreateConcept.module.css'
import { blue } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

const CreateConcept = () => {
  const [showModalShare, setShowModalShare] = useState(true);

  const handleShareModal = () => {
    setShowModalShare(true);
  }

  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <div
          className={createConceptStyles.flex_container}
          id={createConceptStyles.modal_id}
          style={showModalShare ? { display: "flex", zIndex: '10' } : { display: "none" }}>

          <div
            className={createConceptStyles.main_container}
            id={createConceptStyles.modal_content}
            style={{ mariginTop: '255px', borderRadius: '6px' }}
          >
            <div style={{ padding: '20px 23px 25px', position: 'relative' }}>

              <div style={{ display: 'flex' }}>
                <h4>Root Concept Name</h4>
                <p style={{ fontSize: '36px', marginLeft: 'auto' }}>X</p>
              </div>

              <hr />

              <div style={{ padding: '20px 23px 25px', position: 'relative' }}>

                {/* one box */}
                <div>
                  <div style={{ display: 'flex', flexDirection: ' column' }}>
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                      <p style={{ color: 'black', fontSize: '14px' }}>Question-1</p>
                      <p style={{ marginLeft: 'auto', color: 'black', fontSize: '14px' }}>Posted on 31-09-2021</p>
                    </div>

                    <div><p style={{ fontSize: '13px' }}>Geetika is learning about China in her Geography classes. She has to give a short talk to her class about Shanghai. Read the notes she has made and then help her complete the sentence given above.</p></div>
                    <div><p style={{ fontSize: '13px', color: 'black' }}>Shanghai largest Chinese port city handles 50% Chinese exports and imports most heavy population in the world industrial-number of industries present 1842-important after Treaty of Nanking Shangai</p></div>

                    {/* opt */}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex' }}>
                        <Checkbox
                          //  {...label}
                          defaultChecked
                          sx={{
                            color: `#111fee !important`,
                            '&.Mui-checked': {
                              color: blue[900],
                            }
                          }}
                        // checked={gradeData?.indexOf(name?.grade) > -1}
                        />
                        <p style={{ fontSize: '13px', alignItems: 'center', color: 'black' }}>is the largest port city of China</p>

                      </div>

                      <div style={{ display: 'flex' }}>
                        <Checkbox
                          //  {...label}
                          // defaultChecked
                          sx={{
                            color: `#111fee !important`,
                            '&.Mui-checked': {
                              color: blue[900],
                            }
                          }}
                        // checked={gradeData?.indexOf(name?.grade) > -1}
                        />
                        <p style={{ fontSize: '13px', alignItems: 'center', color: 'black' }}>is the largest port city of China</p>

                      </div>
                      <div style={{ display: 'flex' }}>
                        <Checkbox
                          //  {...label}
                          // defaultChecked
                          sx={{
                            color: `#111fee !important`,
                            '&.Mui-checked': {
                              color: blue[900],
                            }
                          }}
                        // checked={gradeData?.indexOf(name?.grade) > -1}
                        />
                        <p style={{ fontSize: '13px', alignItems: 'center', color: 'black' }}>is the largest port city of China</p>

                      </div>

                      <div style={{ display: 'flex' }}>
                        <Checkbox
                          //  {...label}
                          // defaultChecked
                          sx={{
                            color: `#111fee !important`,
                            '&.Mui-checked': {
                              color: blue[900],
                            }
                          }}
                        // checked={gradeData?.indexOf(name?.grade) > -1}
                        />
                        <p style={{ fontSize: '13px', alignItems: 'center', color: 'black' }}>is the largest port city of China</p>

                      </div>
                    </div>
                  </div>
                </div>
                <hr />
            {/* one box */}

                {/* questions start */}
                <div>
                  <div style={{ display: 'flex', flexDirection: ' column' }}>
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                      <p style={{ color: 'black', fontSize: '14px' }}>Question-2</p>
                      <p style={{ marginLeft: 'auto', color: 'black', fontSize: '14px' }}>Posted on 31-09-2022</p>
                    </div>

                    <div><p style={{ fontSize: '13px' }}>Maya is learning about China in her Geography classes. She has to give a short talk to her class about Shanghai. Read the notes she has made and then help her complete the sentence given above.</p></div>
                    <div><p style={{ fontSize: '13px', color: 'black' }}>Shanghai largest Chinese port city handles 50% Chinese exports and imports most heavy population in the world industrial-number of industries present 1842-important after Treaty of Nanking Shangai</p></div>

                    {/* opt */}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex' }}>
                        <Checkbox
                          //  {...label}
                          defaultChecked
                          sx={{
                            color: `#111fee !important`,
                            '&.Mui-checked': {
                              color: blue[900],
                            }
                          }}
                        // checked={gradeData?.indexOf(name?.grade) > -1}
                        />
                        <p style={{ fontSize: '13px', alignItems: 'center', color: 'black' }}>is the largest port city of China</p>

                      </div>

                      <div style={{ display: 'flex' }}>
                        <Checkbox
                          //  {...label}
                          // defaultChecked
                          sx={{
                            color: `#111fee !important`,
                            '&.Mui-checked': {
                              color: blue[900],
                            }
                          }}
                        // checked={gradeData?.indexOf(name?.grade) > -1}
                        />
                        <p style={{ fontSize: '13px', alignItems: 'center', color: 'black' }}>is the largest port city of China</p>

                      </div>
                      <div style={{ display: 'flex' }}>
                        <Checkbox
                          //  {...label}
                          // defaultChecked
                          sx={{
                            color: `#111fee !important`,
                            '&.Mui-checked': {
                              color: blue[900],
                            }
                          }}
                        // checked={gradeData?.indexOf(name?.grade) > -1}
                        />
                        <p style={{ fontSize: '13px', alignItems: 'center', color: 'black' }}>is the largest port city of China</p>

                      </div>

                      <div style={{ display: 'flex' }}>
                        <Checkbox
                          //  {...label}
                          // defaultChecked
                          sx={{
                            color: `#111fee !important`,
                            '&.Mui-checked': {
                              color: blue[900],
                            }
                          }}
                        // checked={gradeData?.indexOf(name?.grade) > -1}
                        />
                        <p style={{ fontSize: '13px', alignItems: 'center', color: 'black' }}>is the largest port city of China</p>

                      </div>

                    </div>

                  </div>

                </div>
                <hr />
                {/* questions start */}
              </div>
            </div>
          </div>
        </div>
        {/* own modal end  */}
      </div>
    </>
  )
}

export default CreateConcept