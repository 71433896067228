import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";

const EditIconButton = (props) => {
  const EditButton = styled(Button)(() => ({
    marginRight:3,
    color: grey[500],
    borderRadius: 150,
    "&:hover": {
      color: "#FFA700",
    },
  }));
  return <EditButton sx={{minHeight: 0, minWidth: 0, padding: 0 }} onClick={props.onClick}>{props.children}</EditButton>;
};
export default EditIconButton;
