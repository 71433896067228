import React from 'react'
import './Principaldashboard.css'
import { isNOTNullOrUndefined } from '../../../../utils/helper'

const keys =[{title:"Chapters",key:'completedChaptersByGrade'}]
const CompletionCards = ({ data }) => {

    let cardData= Array(4).fill()

     cardData = isNOTNullOrUndefined(data) ? cardData.map((element,index)=>  {
        let keyValue = keys[index]?.key 
        return {...keys[index],count:data[keyValue]} 
     }) : [...cardData]

    return (
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" ,marginBottom:'9px'}}>
            {cardData.map((item, index) => <div className={`vss-card vss-card-${index === 3 ? '1' : '2'}`}>
                <p className='cardheading'>{keys[index]?.title || '---'}</p>
                <p className="cardSubheading">{item?.count || 0}</p>
            </div>)}
        </div>
    )
}

export default CompletionCards