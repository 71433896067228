import axios from 'axios'

async function TeacherIDControllerGet(setTeacherIDData, teacherIDData) {
  let token = sessionStorage.getItem("token");
  let teacherid = sessionStorage.getItem("teacherid");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${teacherid}`,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setTeacherIDData(json.data.data)
    })
    .catch(err=>console.log(err))
}

export default TeacherIDControllerGet;
