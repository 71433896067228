import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { MenuProps } from "./Data";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Green100Button from "../../components/common/Green100Button";
import Yellow100Button from "../../components/common/Yellow100Button";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "7px",
  display: "flex",
  flexDirection: "column",
};
const SelectSectionModal = ({
  open,
  close,
  sectionList,
  selectedSection,
  setSelectedSection,
}) => {
  const [personName, setPersonName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  useEffect(() => {
    if (selectedSection) {
      const find = sectionList?.find((item) => item.id === selectedSection);
      if (find) {
        setPersonName(find?.section);
      }
    }
  }, [selectedSection]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const find = sectionList?.find((item) => item?.id === value);
    setPersonName(find?.section);
    setSelectedId(value);
  };
  const startHandler = async (id) => {
    await setSelectedSection(id);
    await close();
  };
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            background: "rgba(96, 91, 161, 0.81)",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            padding: "15px",
            color: "white",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 17 }}>
            Select section to start the chapter
          </div>
          <CloseOutlinedIcon
            sx={{ fontSize: "25px", cursor: "pointer" }}
            onClick={close}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "2rem",
          }}
        >
          <div />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-checkbox-label">Section</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                // multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Section" />}
                renderValue={(selected) => selected}
                MenuProps={MenuProps}
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
              >
                {sectionList?.map((item) => {
                  return item.sections.map((sec) => {
                    if (sec.hasOwnProperty("section")) {
                      return (
                        <MenuItem value={sec.id} key={sec.id}>
                          <ListItemText primary={sec.section} />
                        </MenuItem>
                      );
                    }
                  });
                })}
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
              flexDirection: "row",
              columnGap: "10px",
            }}
          >
            <Yellow100Button onClick={close}>Cancel</Yellow100Button>
            <Green100Button onClick={() => startHandler(selectedId)}>
              Start
            </Green100Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default SelectSectionModal;
