// import axios from "axios";

// async function Branch_update_Id_put(branchInputs,num, setSuccess) {
//   let token = sessionStorage.getItem("token");
//   let item = {
//     boardId: branchInputs.boardId,
//     cityId: branchInputs.cityId,
//     locality: branchInputs.locality,
//     logoUrl: sessionStorage.getItem("logoUrl"),
//     name: branchInputs.name,
//     phoneNumber: num,
//     schoolCode: branchInputs.schoolCode,
//     plans: branchInputs.plans,
//     pocEmail: branchInputs.pocEmail,
//     schoolId: sessionStorage.getItem("schoolid"),
//   };

//     let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${sessionStorage.getItem("branchid")}`,item,{
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((response)=>{
//       // console.log("-----uPDATE DATAD",response.data)
//       setSuccess(response.data.data)
//     })
//     .catch(err=>console.log(err))
// }

// export default Branch_update_Id_put;


import axios from "axios";

async function Branch_update_Id_put(branchInputs, num, setSuccess) {
  let token = sessionStorage.getItem("token");
  let item = {
    boardId: branchInputs.boardId,
    cityId: branchInputs.cityId,
    locality: branchInputs.locality,
    logoUrl: sessionStorage.getItem("logoUrl"),
    name: branchInputs.name,
    phoneNumber: num,
    schoolCode: branchInputs.schoolCode,
    plans: branchInputs.plans,
    pocEmail: branchInputs.pocEmail,
    schoolId: sessionStorage.getItem("schoolid"),
    branchCode:sessionStorage.getItem("branchid"),
    testBranch:true
  };

  const config = {
    method: 'put',
    url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/${sessionStorage.getItem("branchid")}`, // Replace with your actual endpoint
    data: item,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      // Add any other headers if necessary
    }
  };

  axios(config)
    .then((response) => {
      console.log("-----uPDATE DATAD",response.data,sessionStorage.getItem("branchid"))
      setSuccess(response.data.data)
    })
    .catch(err => console.log(err,sessionStorage.getItem("branchid")))
}

export default Branch_update_Id_put;
