import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import addcitystyles from "./../css/AddCity.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import City_Controller_getall from "../API_CONTROLLER/City_Controller/City_Controller_getall";
import Pincodes_Controller_getall from "../API_CONTROLLER/pincode_controller/pincode_controller_getall";
import Countries_Controller_getall from "../API_CONTROLLER/countries_controller/Countries_Controller_getall";
import States_Controller_getall from "../API_CONTROLLER/states-controller/states_Controller_getall";
import Pagination1 from "./pagination1";
import City_Controller_get from "../API_CONTROLLER/City_Controller/City_Controller_get";
import City_Controller_get_Id from "../API_CONTROLLER/City_Controller/City_Controller_get_Id";
import City_Controller_Post from "../API_CONTROLLER/City_Controller/City_Controller_Post";
import City_Controller_Put from "../API_CONTROLLER/City_Controller/City_Controller_Put";
import GetLastModifiedAt from "../API/school-controller/GetLastModifiedAt";
import dashHome from "../../../src/img/dashHome.svg"
import searchIcn from "../../../src/img/search.svg";


const AddCity = (props) => {
 
  const [modifiedDate , setModifiedDate] = useState({});

  useEffect(()=> {
    GetLastModifiedAt(setModifiedDate);
    return () => {};
  }, [])
 

  const navigate = useNavigate();
  const prevLocation = useLocation();
  // console.log(prevLocation);
  const [CityInputs, setCityInputs] = useState({
    city: "",
    pincodeId: "",
    stateId: "",
    countryId: "",
  });

  const [isError, setIsError] = useState(false);
  const [showError, setShowError] = useState("");
  const [isAddCity, setIsAddCity] = useState(true);

  if (prevLocation.state === "edit") {
    setIsAddCity(false);
    prevLocation.state = null;
  }

  useEffect(() => {
    if (isAddCity === false) {
      City_Controller_get_Id(setCityInputs);
      return () => {};
    }
  }, []);

  const handleEditCity = () => {
    setIsAddCity(false);
  };

  const handlerCityInputs = (e) => {
    setCityInputs({
      ...CityInputs,
      [e.target.name]: e.target.value,
    });
  };
  // search starts
  const [search, setSearch] = useState({
    searchName: "",
  });
  const handleSearch = (e) => {
    e.preventDefault()
    const search = {
      searchName : e.target.value
    }
    City_Controller_get(
      setCityData,
       rowsInput,
        page,
         setPageDetail,
          search);
    // setSearch({ ...search, [e.target.name]: e.target.value });
    // console.log(search.searchName);
  };
  const handleSearchCity = (e) => {
    e.preventDefault();
    City_Controller_get(setCityData, rowsInput, page, setPageDetail, search);
  };

  // search ends

  const handleAddCityBtn = (e) => {
    e.preventDefault();
    for (const input in CityInputs) {
      if (CityInputs[input] === "") {
        setIsError(true);
        setShowError("Please enter all the required values");
        return;
      }
    }
    setIsError(false);
    City_Controller_Post(CityInputs);

    navigate("/dashboard/Add%20City");
  };

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 5,
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };
  // pagination ends

  const [pageDetail, setPageDetail] = useState({});
  const [cityName, setCityName] = useState({});
  const [countriesName, setCountriesName] = useState({});
  const [statesName, setStatesName] = useState({});
  const [cityData, setCityData] = useState({});
  const [pinCode, setPinCode] = useState({});
  const [success, setSuccess] = useState({});
  useEffect(() => {
    if (success.success === true) {
      navigate("/dashboard/addcitylist");
    } else {
      setIsError(true);
      setShowError(success.message);
    }
  }, [success]);

  useEffect(() => {
    City_Controller_get(setCityData, rowsInput, page, setPageDetail, search);

    return () => {};
  }, [rowsInput, page]);

  useEffect(() => {
    Countries_Controller_getall(setCountriesName);
    return () => {};
  }, []);
  useEffect(() => {
    City_Controller_getall(setCityName, CityInputs);
    States_Controller_getall(setStatesName, CityInputs);
    Pincodes_Controller_getall(setPinCode, CityInputs);
    return () => {};
  }, [CityInputs]);

  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
          <img src={dashHome} alt= "no img"
                      width="15px" 
                      height="20px"
                      style={{verticalAlign: 'middle'}}
                      />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>{" "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>City List</span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          {isAddCity ? <a > Add City</a> : <a > Edit City</a>}
        </div>
       
      </article>
      {/* <h1 className={addcitystyles.h1}> hello</h1> */}
      <article className={addcitystyles.article}>
        <div className={addcitystyles.city_head}>
          {isAddCity ? (
            <div>
              <i class="fa-solid fa-city"></i> Add City
            </div>
          ) : (
            <div>
              <i class="fa-solid fa-city"></i> Edit City
            </div>
          )}
        </div>

        <form
          className={addcitystyles.input_form}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className={addcitystyles.input_grid}>
            <div
              className={addcitystyles.input_element}
              id={addcitystyles.input_element}
            >
              <select
                className={addcitystyles.form_select_element}
                name="countryId"
                id="countryId"
                value={CityInputs.countryId}
                onChange={handlerCityInputs}
                placeholder=" "
              >
                <option className={addcitystyles.option} value="">
                  Select
                </option>
                {countriesName.length > 0 ? (
                  countriesName.map((countriesName) => {
                    return (
                      <option
                        className={addcitystyles.option}
                        value={countriesName.id}
                      >
                        {countriesName.country}
                      </option>
                    );
                  })
                ) : (
                  <option className={addcitystyles.option} value="">
                    Select
                  </option>
                )}
              </select>
              <label
                for="Country"
                className={addcitystyles.label_name}
                // id={addcitystyles.city_name_id}
              >
                *Country
              </label>
            </div>
            
            <div className={addcitystyles.input_element}>
              <select
                className={addcitystyles.form_select_element}
                name="stateId"
                id="stateId"
                value={CityInputs.stateId}
                onChange={handlerCityInputs}
                placeholder=" "
              >
                <option className={addcitystyles.option} value="">
                  Select
                </option>
                {statesName.length > 0 ? (
                  statesName.map((statesName) => {
                    return (
                      <option
                        className={addcitystyles.option}
                        value={statesName.id}
                      >
                        {statesName.state}
                      </option>
                    );
                  })
                ) : (
                  <option className={addcitystyles.option} value=""></option>
                )}
              </select>
              <label
                for="State"
                className={addcitystyles.label_name}
                // id={addcitystyles.city_name_id}
              >
                *State
              </label>
            </div>
            <div className={addcitystyles.input_element}>
              <input
                className={addcitystyles.form_select_element}
                name="city"
                id="city"
                autoComplete="none"
                value={CityInputs.city}
                onChange={handlerCityInputs}
                placeholder=" "
              />
 
  {/* cityadon */}
               
              {/* <option value="">Select</option>

                {cityName.length > 0 ? (
                  cityName.map((cityName, index) => {
                    return (
                      <option
                        className={addcitystyles.option}
                        value={cityName.city}
                        id={cityName.id}
                      >
                        {cityName.city}
                      </option>
                    );
                  })
                ) : (
                  <option className={addcitystyles.option} value="">
                    Select
                  </option>
                )}
              </select> */}
              <label for="City" className={addcitystyles.label_name}>
                *City
              </label>
            </div>
            {/* <div className={addcitystyles.input_element}>
              <input list="" name="City" id="City" />
              <datalist
                id="Cities"
                className={addcitystyles.form_select_element}
              >
                {cityName.length > 0 ? (
                  cityName.map((cityName, index) => {
                    return (
                      <option
                        // className={addcitystyles.option}
                        className={addcitystyles.form_select_element}
                        value={cityName.city}
                        id={cityName.id}
                      >
                        {cityName.city}
                      </option>
                    );
                  })
                ) : (
                  <option className={addcitystyles.option} value="">
                    Select
                  </option>
                )}
              </datalist>

              <label for="City" className={addcitystyles.label_name}>
                *City
              </label>
            </div> */}

            <div
              className={addcitystyles.input_element}
              id={addcitystyles.input_element}
            >
              <select
                className={addcitystyles.form_select_element}
                name="pincodeId"
                id="pincodeId"
                value={CityInputs.pincodeId}
                onChange={handlerCityInputs}
                placeholder=" "
              >
                <option value="">Select</option>
                {pinCode.length > 0 ? (
                  pinCode.map((pinCode, index) => {
                    return (
                      <option
                        className={addcitystyles.option}
                        value={pinCode.id}
                        id={pinCode.id}
                      >
                        {pinCode.pinPlacePostOffice}
                      </option>
                    );
                  })
                ) : (
                  <option className={addcitystyles.option} value="">
                    Select
                  </option>
                )}
              </select>

              <label for="PinCode" className={addcitystyles.label_name}>
                *PinCode
              </label>
            </div>
            {/* <div
              className={addcitystyles.input_element}
              id={addcitystyles.input_element}
            >
              <input
                className={addcitystyles.form_input_element}
                type="text"
                name="PinCode"
                id="PinCode"
                value={CityInputs.PinCode}
                placeholder=" "
                onChange={handlerCityInputs}
              />

              <label for="PinCode">*PinCode</label>
            </div> */}

            <div className={addcitystyles.artical_button_container}>
              <button
                className={addcitystyles.artical_button_cancel}
                id={addcitystyles.button_id_cancel}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dashboard/Add%20City");
                }}
              >
                Cancel
              </button>
              {isAddCity ? (
                <button
                  className={addcitystyles.artical_button}
                  id={addcitystyles.button_id}
                  onClick={handleAddCityBtn}
                >
                  ADD
                </button>
              ) : (
                <button
                  className={addcitystyles.artical_button}
                  id={addcitystyles.button_id}
                  onClick={(e) => {
                    e.preventDefault();
                    City_Controller_Put(CityInputs, setSuccess);
                    navigate("/dashboard/Add%20City")
                  }}
                >
                  UPDATE
                </button>
              )}
            </div>
            <div
              className={addcitystyles.error_box}
              style={
                isError
                  ? {
                      display: "block",
                      color: "red",
                    }
                  : { display: "none" }
              }
            >
              {showError}
            </div>
          </div>
        </form>
        <div className={addcitystyles.city_head}>
          <div>
            <i class="fa-solid fa-city"></i>City List
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className={addcitystyles.school_list_search_container}>
              <div>
                <input
                  type="search"
                  name="searchName"
                  id="searchName"
                  placeholder="Name"
                  Value={search.searchName}
                  onChange={handleSearch}
                />
                <span>
                <img
                              src={searchIcn}
                              style={{
                                verticalAlign: "top",
                                fontSize: "large",
                              }}
                              onClick={handleSearchCity}
                            />
                  
                </span>
              </div>
            </div>
          </form>
        </div>

        <div className={addcitystyles.section_list_table}>
          <table className={addcitystyles.table_section}>
            <thead className={addcitystyles.thead_35}>
              <tr className={addcitystyles.table_head_section}>
                {/* <th className={addcitystyles.cbse_checkbox_thead}><input type="checkbox" id="test2" />
                                            <label for="test2"></label></th> */}
                <th className={addcitystyles.cbse_table_head}>
                  #<i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th className={addcitystyles.city_name}>
                  City
                  <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th className={addcitystyles.cbse_table_head}>
                  {" "}
                  PinCode
                  <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th className={addcitystyles.cbse_table_head1}>
                  State
                  <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th className={addcitystyles.cbse_table_head2}>
                  Country
                  <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th className={addcitystyles.cbse_table_head3}>
                  Active
                  <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th className={addcitystyles.cbse_table_head3}>
                  Action
                  <i class="fa-solid fa-arrows-up-down"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              {cityData.length > 0 ? (
                cityData.map((cityData, index) => {
                  return (
                    <tr className={addcitystyles.data_row} key={index}>
                      <td>{index + 1}</td>
                      <td className={addcitystyles.city_name}>
                        {cityData.city}
                      </td>
                      <td>{cityData.pincode}</td>
                      <td>{cityData.state}</td>
                      <td>{cityData.country}</td>
                      <td>
                        <label className={addcitystyles.active_switch}>
                          <input
                            type="checkbox"
                            id=""
                            name=""
                            value=""
                            checked={cityData.active}
                          />
                          <span className={addcitystyles.active_slider}></span>
                        </label>
                      </td>
                      <td>
                        <div className={addcitystyles.wrapper}>
                          <div className={addcitystyles.icon}>
                            <div className={addcitystyles.tooltip}>Edit</div>
                            <span>
                              <i
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsAddCity(false);
                                  var cityid = cityData.id;
                                  sessionStorage.setItem("cityid", cityid);
                                  City_Controller_get_Id(setCityInputs);
                                }}
                                class="fa-solid fa-pencil"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5">NO DATA AVAILABLE</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* pagination container - Start */}
        <div className={addcitystyles.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={addcitystyles.rows}>
              <label for="rows">Rows per page</label>
              <select
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        {/* pagination container - End */}
      </article>
    </>
  );
};

export default AddCity;
