const branchlist15 = [
  {
    branchName: "AZVASA ",
    branchid: "0114",
    branchcity: "mumbai",
    branchlocality: "virar",
    boards: "CBSE",
    hods: "00",
    teachers: "00",
    students: "00",
    // active: "00",
    // action: "",
  },
];
export default branchlist15;
