import axios from 'axios'

function training_category_getby_id(setCategoryInputs){
    let token = sessionStorage.getItem("token")

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training-category/${sessionStorage.getItem("trainingCatId")}` ,
    {
        headers : {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setCategoryInputs(res.data.data)
    })
    .catch(err => console.log(err))
}

export default training_category_getby_id