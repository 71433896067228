import axios from 'axios';

function chapters_list_getall(setChaptersList) {
    let token = sessionStorage.getItem("token")
    
    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/all-chapters` , 
    {
        headers : {
            "Content-type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setChaptersList(res.data.data)
    })
}

export default chapters_list_getall

