import {
  SET_NAVBAR_IS_EXPANDED,
} from "../constants/navbarConstants";


const initialState = {
  is_expanded: true,
 
}

export const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAVBAR_IS_EXPANDED:
      return {
        ...state,
        is_expanded: action.payload
      }

  
    default:
      return state
  }
}
