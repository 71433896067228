import React from "react";
import "./blueprint.css";
import dashHome from "../../img/dashHome.svg";
import searchIcon from "../../img/search.svg";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function BluePrintFilter({
  selectedBoard,
  setSelectedBoard,
  selectedGrade,
  setSelectedGrade,
  selectedSubject,
  setSelectedSubject,
  search,
  setSearch,
  gradeList,
  subjectList,
  boardList,
  title,
}) {
  // console.log(selectedSubject,'selectedBoard')
  // console.log(subjectList,'boardList')
  return (
    <div className="filter-wrap-blueprint">
      <div className="filter-dropdown">
        <div
          style={{ marginRight: "30px", display: "flex", alignItems: "center" }}
        >
          <img
            src={dashHome}
            alt="no img"
            width="20px"
            height="20px"
            style={{ verticalAlign: "middle" }}
          />
          <span className="sheet-table-page-head">
            {title ? title : "Blue Print List"}
          </span>
        </div>
        <Box
          sx={{ minWidth: 130, minHeight: 36 }}
          style={{ marginRight: "10px" }}
        >
          <FormControl fullWidth size="small">
            <InputLabel
              style={{
                fontSize: "16px",
                color: "#1B1B1B",
                fontWeight: "400",
                fontStyle: "normal",
              }}
              id="demo-simple-select-label"
            >
              Board
            </InputLabel>
            <Select
              value={selectedBoard}
              label="board"
              className="form-select-blue-print"
              style={{
                borderRadius: "18px",
                // width: "100%",
                height: "39px",
              }}
              onChange={(e) => setSelectedBoard(e.target.value)}
            >
              {boardList?.map((item) => {
                return <MenuItem value={item?.id}
                  id={`az-revisionadmin-boarddropdown-${item.id}`}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: 'orange',
                      color: "#FFFFFF"
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: '#fcedc2',
                      color: "#000000"
                    },
                    "&:not(.Mui-selected):hover": {
                      backgroundColor: '#fcedc2',
                      color: '#000000',
                    },
                  }}>{item?.board}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{ minWidth: 130, minHeight: 36 }}
          style={{ marginRight: "10px" }}
        >
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-simple-select-label"
              style={{
                fontSize: "16px",
                color: "#1B1B1B",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Grade
            </InputLabel>
            <Select
              value={selectedGrade}
              label="grade"
              className="form-select-blue-print"
              size="small"
              style={{
                borderRadius: "18px",
                // width: "100%",
                height: "39px",
              }}
              onChange={(e) => setSelectedGrade(e.target.value)}
            >
              {gradeList?.map((item) => {
                return <MenuItem value={item?.id}
                  id={`az-revisionadmin-gradedropdown-${item.id}`}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: 'orange',
                      color: "#FFFFFF"
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: '#fcedc2',
                      color: "#000000"
                    },
                    "&:not(.Mui-selected):hover": {
                      backgroundColor: '#fcedc2',
                      color: '#000000',
                    },
                  }}>{item?.grade}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 130, minHeight: 36 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-simple-select-label"
              style={{
                fontSize: "16px",
                color: "#1B1B1B",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Subject
            </InputLabel>
            <Select
              value={selectedSubject}
              label="subject"
              className="form-select-blue-print"
              style={{
                borderRadius: "18px",
                // width: "100%",
                height: "39px",
              }}
              onChange={(e) => setSelectedSubject(e.target.value)}
            >
              {subjectList?.map((item) => {
                return <MenuItem value={item?.id}
                  id={`az-revisionadmin-subjectdropdown-${item.id}`}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: 'orange',
                      color: "#FFFFFF"
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: '#fcedc2',
                      color: "#000000"
                    },
                    "&:not(.Mui-selected):hover": {
                      backgroundColor: '#fcedc2',
                      color: '#000000',
                    },
                  }}>{item?.subject}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Box>
      </div>

      <div className="filter-search">
        <input
          className="filter-input"
          type="search"
          name="search"
          id="search"
          placeholder="Search..."
          style={{
            width: "247px",
            height: "44px",
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <span>
          <img
            src={searchIcon}
            style={{
              verticalAlign: "top",
              fontSize: "large",
              marginTop: "13px",
              marginRight: "10px",
              marginLeft: "5px",
            }}
            aria-hidden="true"
          />
        </span>
      </div>
    </div>
  );
}
