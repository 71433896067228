import React, { useState, useEffect } from "react";
import axios from "axios";
import SubConceptStyles from "./../css/subConceptTree.module.css";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import chapterStyles from "./../css/createChapter.module.css";
import { ControlPoint } from "@mui/icons-material";
import WhiteAddButton from "../../components/common/WhiteButton";
import BlueButton from "../../components/common/BlueButton";
import EditIconButton from "../../components/common/EditIconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TrainingCategoryStyles from "./../css/TrainingCategory.module.css";
import CommonDeleteModal from "./CommonDeleteModal";
import Pagination1 from "./pagination1";
import dashHome from "../../../src/img/dashHome.svg";
import commonsort from "../js/CommonSort"
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  Paper,
  Select,
  Table,
  Stack,
  Chip,
  Autocomplete,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import SuccessModal from "./data/SuccessModal";
import { blue } from "@mui/material/colors";

// sort
import sortIcon from "../../../src/pages/img/sort_1.png";
import searchIcn from "../../../src/img/search.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ConfigurationSuccessModal from "../../components/common/ConfigurationSuccessModal";

const SubAndRootConcepts = () => {
  const token = sessionStorage.getItem("token");
  const [isErr, setIsErr] = useState(false);
  const [modifiedDate, setModifiedDate] = useState({});
  const [addConcept, setConcept] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [subjectData, setSubjectData] = useState([]);
  const [conceptList, setConceptList] = useState([]);
  const [item, setItem] = useState({});
  const [values, setValues] = useState([]);
  const [currValue, setCurrValue] = useState([]);
  const [id, setId] = useState();
  const [subConcept, setSubConcept] = useState([]);
  const [concept, setConceptName] = useState("");
  const [data, setData] = useState([]);
  const [subConceptdropdown, setsubConceptdropdown] = useState([]);
  const [subConceptId, setConceptId] = useState("");
  const [rootConcepts, setRootConcepts] = useState([]);
  const [listrootConcepts, setlistrootConcepts] = useState([]);
  const [subConceptmodal, setSubconceptModal] = useState(false);
  const [title, setTitle] = useState("");
  const [rootConceptmodal, setRootconceptModal] = useState(false);
  const [titleroot, setrootTitle] = useState("");
  const [conceptData, setConceptData] = useState([]);
  const [listsubconcepts, setlistsubconcepts] = useState([]);
  const [listrootconceptstable, setlistrootconceptsintable] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState();
  const [row, setRow] = useState({});
  const [rootConceptdeleteModal, setrootConceptDeleteModel] = useState(false);
  const [rootConceptMessage, setrootConceptMessage] = useState();
  const [listsubconceptedit, setlistsubconceptedit] = useState("");
  const [listrootconceptedit, setlistrootconceptedit] = useState("");
  const [subConceptErrMsg, setSubConceptErrMsg] = useState("");
  const [rootConceptErrMsg, setRootConceptErrMsg] = useState("");
  const [isUpdatedModal, setIsUpdateModal] = useState(false);
  const [conceptUpdateMsg, setConceptUpdateMsg] = useState("");

  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  const [subConceptsEditid, setSubConceptsEditid] = useState();
  const [rootConceptsEditid, setRootConceptEditid] = useState();

  // sort
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("id");

  const navigate = useNavigate();

  const onCloseUpdateModal = () => {
    setIsUpdateModal(false)
  }

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    initData(sortOrder, sortBy);
    return () => { };
  }, []);

  const initData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/sub-concepts/page?pageSize=1000&sortOrder=${sortOrder}&sortBy=${sortBy}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setData(res.data.data.data);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setSubjectData(response.data.data);


    axios({
      method: "get",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/all?pageSize=1000&sortOrder=${sortOrder}&sortBy=${sortBy}&subjectId=${selectedSubject}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async function (response) {
        response.data.data.data = await commonsort(response.data.data.data || [], sortBy, sortOrder);
        setConceptList(response.data.data.data);
        setConceptData(response.data.data.data);
        setlistsubconcepts([]);
        setlistrootconceptsintable([]);
      })
      .catch(function (response) {
        console.log(response);
      });

    // axios({
    //   method: "get",
    //   url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/all?pageSize=10`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // })
    //   .then(function (response) {
    //     setConceptData(response.data.data.data);
    //   })
    //   .catch(function (response) {
    //     console.log(response);
    //   });
  };
  const [key, setKey] = useState(Math.random() * 1000);
  const handleSubject = (value) => {
    setKey(Math.random() * 1000);
    setrootkey(Math.random() * 1000);
    setsubConceptdropdown([]);
    setSelectedSubject(value);
    setselectedcps(null);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/all?pageSize=1000&sortOrder=${sortOrder}&sortBy=${sortBy}&subjectId=${value}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async function (response) {
        response.data.data.data = await commonsort(response.data.data.data || [], sortBy, sortOrder);
        setConceptList(response.data.data.data);
        setConceptData(response.data.data.data);
        setlistsubconcepts([]);
        setlistrootconceptsintable([]);
      })
      .catch(function (response) {
        console.log(response);
      });
  };
  const [subConceptCreated, setsubConceptCreated] = useState(false);
  const createSubconcept = () => {
    if (!selectedSubject || !selcps || values.length == 0) {
      setIsErr(true);
      return null;
    }
    setSubconceptModal(true);
    setTitle("SubConcepts with Concepts");
  };

  const createHandler = () => {
    setSubconceptModal(false);
    const newValue = {
      id: concept,
      conceptNames: [...values],
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/sub-concepts/create`,
      data: newValue,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        setsubConceptdropdown([...subConceptdropdown, ...response.data.data]);
        setValues([]);
        setIsErr(false);
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  const onClose = () => {
    setSubconceptModal(false);
  };
  const [rootError, setRootError] = useState(false);
  const createRootconcept = () => {
    if (!subConceptId || listrootConcepts.length == 0) {
      setRootError(true);
      return null;
    }
    setRootconceptModal(true);
    setrootTitle("RootConcepts with SubConcepts");
  };

  const createrootHandler = () => {
    setRootconceptModal(false);
    const newValue = {
      id: subConceptId,
      conceptNames: [...listrootConcepts],
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/root-concepts/create`,
      data: newValue,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        setlistrootConcepts([]);
        setRootError(false);
      })
      .catch(function (response) {
        console.log(response);
      });
  };
  const onrootClose = () => {
    setRootconceptModal(false);
  };

  const handlesubConcepts = () => {
    if (currValue && currValue != "") {
      setValues((oldState) => [...oldState, currValue]);
      setCurrValue("");
      setIsErr(false);
    } else {
      setIsErr(true);
    }
  };
  const handlerootConcepts = () => {
    if (rootConcepts && rootConcepts != "") {
      setRootConcepts("");
      setlistrootConcepts((oldState) => [...oldState, rootConcepts]);
      setCurrValue("");
      setRootError(false);
    } else {
      setRootError(true);
    }
  };

  const handleDelete = (val, index) => {
    const newValues = values.filter((v) => v != val);
    setValues([...newValues]);
  };

  const handlerootDelete = (val, index) => {
    const newValues = listrootConcepts.filter((v) => v != val);
    setlistrootConcepts([...newValues]);
  };

  const handleKeyUp = (e) => {
    setValues((oldState) => [...oldState, currValue]);
    setCurrValue("");
  };
  const [selcps, setselectedcps] = useState("");
  const [rootkey, setrootkey] = useState(Math.random() * 880);
  const handleConcept = async (value) => {
    setsubConceptdropdown([]);
    setrootkey(Math.random() * 1000);
    setselectedcps(value);
    const resp = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/sub-concepts/all?pageSize=1000&sortOrder=${sortOrder}&sortBy=${sortBy}&conceptId=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (resp.data.data.data.length > 0) {
      setsubConceptdropdown([...resp.data.data.data, subConceptdropdown]);
    }
    setConceptName(value);
  };

  const handleValChange = (e) => {
    setCurrValue(e.target.value);
  };
  // const ValChanges = (e) => {
  //   setCurrValue(e.target.value);
  // };
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsErr(false);
  //   }, 2000);
  // }, [isErr]);
  const [selectedConcept, setSelectedConcept] = useState();
  const getSubConcepts = async (v) => {
    setlistsubconcepts([]);
    setlistrootconceptsintable([]);

    const { id } = v;
    setSelectedConcept(id);
    const resp = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/sub-concepts/all?pageSize=1000&sortOrder=${sortOrder}&sortBy=${sortBy}&conceptId=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (resp.data.data.data.length > 0) {
      resp.data.data.data = await commonsort(resp.data.data.data || [], sortBy, sortOrder);
      setlistsubconcepts([...resp.data.data.data]);
    }
  };
  const [selecetedsubConcept, setSelectedSubConcept] = useState();
  const getRootConcepts = async (v) => {
    setlistrootconceptsintable([]);
    const { id } = v;
    setSelectedSubConcept(id);
    const resp = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/root-concepts/all?pageSize=1000&sortOrder=${sortOrder}&sortBy=${sortBy}&subConceptId=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (resp.data.data.data.length > 0) {
      resp.data.data.data = await commonsort(resp.data.data.data || [], sortBy, sortOrder);
      setlistrootconceptsintable([...resp.data.data.data]);
    }
  };
  const editClickHandler = (row) => {
    setSubConceptsEditid(row?.id);
    setlistsubconceptedit(row?.subConcept);
  };

  const editHandler = (row) => {
    setRootConceptEditid(row?.id);
    setlistrootconceptedit(row?.rootConcept);
  };

  const handleOpen = async (item) => {
    setOpenDeleteModal(true);
    setRow(item);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/sub-concepts/confirmation-api?id=${item?.id}&operationType=DELETE`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setMessage(response?.data?.data?.message);
  };

  const deleteHandler = async () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/sub-concepts/${row.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        var temp = listsubconcepts.filter((v) => v.id != row.id);
        setlistsubconcepts([...temp]);
        setOpenDeleteModal(false);
      })
      .catch((error) => {
        setOpenDeleteModal(false);
      });
  };

  const rootConceptdeleteConfirm = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/root-concepts/${row.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        var temp = listrootconceptstable.filter((v) => v.id != row.id);
        setlistrootconceptsintable([...temp]);
        setrootConceptDeleteModel(false);
      })
      .catch((error) => {
        setrootConceptDeleteModel(false);
      });
  };

  const handleDeleteOpen = async (item) => {
    setrootConceptDeleteModel(true);
    setRow(item);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/root-concepts/confirmation-api?id=${item?.id}&operationType=DELETE`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setrootConceptMessage(response.data?.data?.message);
  };

  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const updatelistsubConcepts = (row) => {
    if (!listsubconceptedit) {
      setSubConceptErrMsg("Enter Sub Concept")
      return true
    } else {
      setSubConceptErrMsg("")
    }
    const data = {
      id: row.id,
      subConcept: listsubconceptedit,
    };
    axios({
      method: "put",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/sub-concepts/update`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const temp = listsubconcepts.filter((v) => v.id != row.id);
      setlistsubconcepts([...temp, { ...row, subConcept: listsubconceptedit }]);
      setSubConceptsEditid(null);
      setlistsubconceptedit("");
      setSubConceptErrMsg("")
      setIsUpdateModal(true);
      setConceptUpdateMsg("SubConcept Successfully Updated");
    })
      .catch((err) => {
        console.log(err, "err")
        setSubConceptErrMsg(err.response.data.message)
      })
  };

  const updatelistrootConcepts = (row) => {
    if (!listrootconceptedit) {
      setRootConceptErrMsg("Enter Root Concept")
      return true
    } else {
      setRootConceptErrMsg("")
    }
    const temp = listrootconceptstable.filter((v) => v.id != row.id);
    const data = {
      id: row.id,
      rootConcept: listrootconceptedit,
    };
    axios({
      method: "put",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/sub-root/root-concepts/update`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setlistrootconceptsintable([
        ...temp,
        { ...row, rootConcept: listrootconceptedit },
      ]);
      setRootConceptEditid(null);
      setlistrootconceptedit("");
      setRootConceptErrMsg('')
      setIsUpdateModal(true)
      setConceptUpdateMsg("RootConcept Successfully Updated")
    })
      .catch((err) => {
        console.log(err, "err")
        setRootConceptErrMsg(err.response.data.message)
      })
  };

  const hiderootConceptDeleteModal = () => {
    setrootConceptDeleteModel(false);
  };

  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };

  const [subjectkey, setSubjectkey] = useState();

  const clear = () => {
    setSelectedSubject("");
    setselectedcps("");
    setValues([]);
    setKey("");
    setSubjectkey(Math.random() * 1000);
  };
  const clearroot = () => {
    setConceptId("");
    setrootkey(Math.random() * 1000);
    setRootConcepts([]);
    setlistrootConcepts([]);
    setRootError(false);
  };

  const onSort = async (sortOrder, sortby) => {
    console.log(sortOrder, sortby, listsubconcepts)

    var sorted = await commonsort(listsubconcepts || [], sortby, sortOrder);
    setlistsubconcepts(sorted)
  }
  const onSortRoot = async (sortOrder, sortby) => {
    console.log(sortOrder, sortby, listrootconceptstable)

    var sorted = await commonsort(listrootconceptstable || [], sortby, sortOrder);
    setlistrootconceptsintable(sorted)
  }


  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="az_home_navigation_section" style={{ top: "67px" }}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <p className="az_navigation_text"><span style={{ position: "relative" }}>
                <img style={{ position: "absolute", top: "1px" }}
                  src={dashHome}
                  alt="no img"
                  width="18px"
                  height="18px"

                /> </span>
                <span
                  onClick={() => {
                    sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                    sessionStorage.removeItem("sideNavSubMenuNames");
                    navigate("/dashboard/template")
                  }}
                  className="az_navigation_content" style={{ marginLeft: "20px" }}>Home</span>
              </p>
            </div>
            <p>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
            </p>
            <p onClick={() => {
              navigate("/dashboard/Add Training Category")
              sessionStorage.setItem("sideNavMainMenuNames", "Master");
              sessionStorage.removeItem("sideNavSubMenuNames");
            }} className="az_navigation_text"><span>Master</span>
            </p>
            <p>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
            </p>
            <p className="az_navigation_text"><span style={{ color: "#FFA500" }}>Concept</span></p>

          </div>
          <p className="az_last_update_time">Last Update: {modifiedDate.data}</p>
        </div>
        {/* <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <span>Master</span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <a>Concept</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {modifiedDate.data}</p>
          </div>
        </article> */}
        <Paper
          style={{
            marginTop: "55px",
            paddingRight: "33px",
            position: "absolute",
            right: "15px",
            left: "15px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 20px",
            }}
          >
            {addConcept && (
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <FormControl
                    style={{ marginRight: "8px", marginLeft: "8px" }}
                    sx={{ width: 300 }}
                  >
                    <Autocomplete
                      key={subjectkey}
                      disablePortal
                      id="demo-multiple-checkbox"
                      options={subjectData}
                      getOptionLabel={(option) =>
                        option ? option.subject : ""
                      }
                      onChange={(e, value) => handleSubject(value?.id)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Subject"
                          id={`az-content-subjectdropdown`}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: '#FFA500',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#FFA500',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}
                        />
                      )}
                    />
                    {isErr && !selectedSubject && <span style={{ color: "red" }}>Subject is Required</span>}
                  </FormControl>
                  <FormControl
                    style={{ marginRight: "8px", marginLeft: "8px" }}
                    sx={{ width: 300 }}
                  >
                    <Autocomplete
                      disablePortal
                      key={key}
                      id="demo-multiple-checkbox"
                      style={{cursor:"pointer"}}
                      options={conceptList}
                      getOptionLabel={(option) =>
                        option ? option.concept : ""
                      }
                      onChange={(event, value) => {
                        handleConcept(value?.id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Concept"
                          id={`az-content-subjectdropdown`}
                          className="cursorPointer"
                          sx={{
                            cursor: "pointer",
                            "&.Mui-selected": {
                              backgroundColor: '#FFA500',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#FFA500',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}
                        />
                      )}
                    />
                    {isErr && !selcps && <span style={{ color: "red" }}>Concept is Required</span>}
                  </FormControl>
                </div>
                <div
                  style={{
                    marginTop: "18px",
                    display: "flex",
                    flexDirection: "row",
                    width: "80vw",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <FormControl
                      style={{ marginRight: "8px", marginLeft: "8px" }}
                    // sx={{ width: "16vw" }}
                    >
                      <TextField
                        id={`az-content-subtopicdropdown`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: '#FFA500',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#FFA500',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}
                        // id="demo-helper-text-aligned-no-helper"
                        label="*Sub-Concept"
                        autoComplete="none"
                        name="subConcepts"
                        disabled={!selectedSubject || !selcps}
                        value={currValue}
                        onChange={handleValChange}
                        onBlur={(e) => setCurrValue(e.target.value.trim())}
                        InputProps={{
                          endAdornment: (
                            <ControlPoint
                              style={{
                                color: "white",
                                backgroundColor: "orange",
                                borderRadius: "50%",
                                marginRight: "10px",
                                cursor: 'pointer'
                              }}
                              onClick={handlesubConcepts}
                            />
                          ),
                        }}
                      />
                      {isErr && currValue && currValue.length == 0 && <span style={{ color: "red" }}>SubConcept is Required</span>}
                    </FormControl>
                    {values.length > 0 &&
                      values.map((item, index) => (
                        <Chip
                          style={{
                            backgroundColor: "#D9DCFE",
                            color: "#354052",
                            padding: "10px",
                            margin: "5px",
                          }}
                          size="small"
                          onDelete={() => handleDelete(item, index)}
                          label={item}
                        />
                      ))}
                  </div>
                  <div style={{ display: "flex" }}>
                    {/* <p
                      style={
                        isErr
                          ? { display: "block", color: "red" }
                          : { display: "none" }
                      }
                    >
                      Please enter all required values
                    </p> */}
                    <WhiteAddButton
                      id={`az-content-clearbutton`}
                      onClick={clear}
                      style={{ marginRight: "12px" }}
                    >
                      Clear
                    </WhiteAddButton>

                    <div>
                      <button
                        id={`az-content-createsubconcept-button`}
                        onClick={createSubconcept}
                        style={{
                          fontWeight: "400",
                          border: "none",
                          color: "white",
                          backgroundColor: "#403e75",
                          padding: "5px",
                          borderRadius: "16px",
                        }}
                      >
                        Create Sub Concept
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    flexDirection: "row",

                    width: "80vw",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <FormControl
                      style={{ marginRight: "8px", marginLeft: "8px" }}
                      sx={{ width: 300 }}
                    >
                      <Autocomplete
                        disablePortal
                        key={rootkey}
                        id="demo-multiple-checkbox"
                        options={subConceptdropdown}
                        getOptionLabel={(option) =>
                          option ? option.subConcept : ""
                        }
                        onChange={(event, value) => {
                          setConceptId(value?.id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sub Concept"
                            id={`az-content-subtopicdropdown`}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: '#FFA500',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#FFA500',
                                color: "#000000"
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}
                          />
                        )}
                        disabled={!selectedSubject || !selcps}
                      />
                      {rootError && !subConceptId && <span style={{ color: "red" }}>SubConcept is Required</span>}
                    </FormControl>

                    <FormControl
                      style={{ marginRight: "8px", marginLeft: "8px" }}
                      sx={{ width: "16vw" }}
                    >
                      <TextField
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "48px !important",
                            width: "270px !important",
                            borderRadius: "5px !important",
                          },
                        }}
                        id="demo-helper-text-aligned-no-helper"
                        label="*Root-Concepts"
                        name="subCategories"
                        autoComplete="none"
                        disabled={!subConceptId}
                        value={rootConcepts}
                        onChange={(event) => {
                          setRootConcepts(event.target.value);
                        }}
                        onBlur={(e) => setRootConcepts(e.target.value.trim())}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <ControlPoint
                                style={{
                                  color: "white",
                                  backgroundColor: "orange",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                  cursor: 'pointer'
                                }}
                                onClick={handlerootConcepts}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {rootError && rootConcepts && rootConcepts.length == 0 && <span style={{ color: "red" }} >RootConcept is required</span>}
                    </FormControl>
                    {listrootConcepts.length > 0 &&
                      listrootConcepts.map((item, index) => (
                        <Chip
                          style={{
                            backgroundColor: "#D9DCFE",
                            color: "#354052",
                            padding: "10px",
                            margin: "5px",
                          }}
                          size="small"
                          onDelete={() => handlerootDelete(item, index)}
                          label={item}
                        />
                      ))}
                  </div>
                  <div>
                    <WhiteAddButton
                      id={`az-content-clearbutton`}
                      onClick={clearroot}
                      style={{ marginRight: "12px" }}
                    >
                      Clear
                    </WhiteAddButton>
                    <button
                      onClick={createRootconcept}
                      id={`az-content-createrootconcept-button`}
                      style={{
                        fontWeight: "400",
                        border: "none",
                        color: "white",
                        background: "#403e75",
                        padding: "5px",
                        borderRadius: "16px",
                      }}
                    >
                      Create Root Concept
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className={chapterStyles.chapter_container}>
              <div
                className={chapterStyles.flex}
                style={{ marginTop: "15px", position: "relative" }}
              >
                {!addConcept && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      right: "30px",
                    }}
                  >
                    <TextField
                      type={"search"}
                      name="search"
                      // type='search'
                      sx={{
                        width: { sm: 200, md: 300 },
                        "& .MuiInputBase-root": {
                          width: "247px !important",
                          height: "32px !important",
                          borderRadius: "50px !important",
                          background: "#fff !important",
                          lineHeight: "21px !important",
                          opacity: 1,
                          font: "normal normal normal 13px/20px Poppins !important",
                        },
                      }}
                      // style={{width:'14vw' , height : '12vh'}}
                      placeholder={"Search...."}
                      // onChange={handleSearch}
                      // Value={search.search}
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <img
                              src={searchIcn}
                              alt="no img"
                              style={{
                                verticalAlign: "top",
                                fontSize: "large",
                              }}
                              aria-hidden="true"
                            />
                          </IconButton>
                        ),
                      }}
                    />
                    <div style={{ marginLeft: "18px" }}>
                      <button
                        id={`az-content-createsubconcept-button`}
                        style={{ height: "31px" }}
                        className={chapterStyles.green_btn}
                        onClick={() => {
                          setConcept(true);
                        }}
                      >
                        Create Sub-Concept
                      </button>
                      {/* green_btn */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "25px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  width: "33.3%",
                  display: "flex",
                  flexDirection: "column",
                  borderRight: "solid 2px #d9dcfe",
                }}
              >
                <div
                  style={{
                    height: "50px",
                    backgroundColor: "#403e75",
                    color: "white",
                    padding: "10px",
                  }}
                >
                  Concept
                  <img
                    style={{
                      verticalAlign: "middle",
                      marginLeft: "25px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="12"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("concept");
                      await initData(!sortOrder, "concept");
                    }}
                  />
                </div>
                <ol
                  start="1"
                  style={{ overflow: "scroll" }}
                  className={chapterStyles.concept_list_itm}
                >
                  {conceptData.length > 0 &&
                    conceptData.map((v, i) => {
                      return (
                        <li
                          onClick={() => getSubConcepts(v)}
                          style={{
                            // color: selectedConcept == v.id ? "green" : "blue",
                            color:
                              selectedConcept == v.id ? "#001AF3" : "#888888",
                            listStyle: "none",
                            padding: "10px",
                          }}
                        >
                          {i + 1}.{v.concept}
                        </li>
                      );
                    })}
                </ol>
              </div>

              <div
                style={{
                  width: "33.3%",
                  display: "flex",
                  borderRight: "solid 2px #d9dcfe",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    height: "50px",
                    backgroundColor: "#403e75",
                    color: "white",
                    padding: "10px",
                  }}
                >
                  Sub Concept
                  <img
                    style={{
                      verticalAlign: "middle",
                      marginLeft: "25px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="12"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("subConcept");
                      // await initData(!sortOrder, "subConcept");
                      await onSort(!sortOrder, "subConcept")

                    }}
                  />
                </div>
                <ol
                  start="1"
                  style={{ overflow: "scroll" }}
                  className={chapterStyles.SubConcept_list_itm}
                >
                  {listsubconcepts.length > 0 &&
                    listsubconcepts.map((v, i) => {
                      return (
                        <li
                          onClick={() => getRootConcepts(v)}
                          style={{
                            color:
                              // selecetedsubConcept == v.id ? "green" : "blue",
                              selecetedsubConcept == v.id
                                ? "#001AF3"
                                : "#888888",
                            listStyle: "none",
                            padding: "10px",
                          }}
                        >
                          <>
                            {subConceptsEditid == v.id ? (
                              <>
                                <input
                                  type="text"
                                  style={{
                                    border: "0px",
                                    outline: "0px",
                                    borderBottom: "2px solid #1890ff",
                                    height: "30px",
                                  }}
                                  value={listsubconceptedit}
                                  onChange={(event) =>
                                    setlistsubconceptedit(event.target.value)
                                  }
                                ></input>
                                {subConceptErrMsg && <p style={{ fontSize: "10px", color: "red", marginTop: "2px", marginBottom: "2px" }}>{subConceptErrMsg}</p>}
                                <button
                                  className={chapterStyles.green_btn}
                                  onClick={() => updatelistsubConcepts(v)}
                                  style={{
                                    fontSize: "0.6vw",
                                    padding: "10px 25px",
                                  }}
                                >
                                  update
                                </button>

                                <button
                                  className={chapterStyles.green_btn}
                                  style={{
                                    fontSize: "0.6vw",
                                    padding: "10px 25px",
                                    marginLeft: "10px",
                                  }}
                                  onClick={() => {
                                    setSubConceptsEditid(null);
                                  }}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>
                                {i + 1}.{v.subConcept}
                                <EditIconButton
                                  id={`az-content-editicon`}
                                  onClick={() => {
                                    editClickHandler(v);
                                  }}
                                >
                                  <Tooltip title="Edit" placement={"top"}>
                                    <EditOutlinedIcon
                                      style={{
                                        fontSize: "1.2vw",
                                        color: "orange",
                                      }}
                                    />
                                  </Tooltip>
                                </EditIconButton>
                                <span style={{ float: "right" }}>
                                  <DeleteIconButton
                                    id={`az-content-deleteicon`}
                                    onClick={() => {
                                      handleOpen(v);
                                    }}
                                  >
                                    <Tooltip title="Remove" placement={"top"}>
                                      <DeleteOutlinedIcon
                                        style={{ fontSize: "1.2vw" }}
                                      />
                                    </Tooltip>
                                  </DeleteIconButton>
                                </span>
                              </>
                            )}
                          </>
                        </li>
                      );
                    })}
                </ol>
              </div>
              <div
                style={{
                  width: "33.3%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    height: "50px",
                    backgroundColor: "#403e75",
                    color: "white",
                    padding: "10px",
                  }}
                >
                  Root Concept
                  <img
                    style={{
                      verticalAlign: "middle",
                      marginLeft: "25px",
                      marginBottom: "2px",
                    }}
                    className="cursor-pointer"
                    width="12"
                    height="15"
                    src={sortIcon}
                    onClick={async () => {
                      await setSortOrder(!sortOrder);
                      await setSortBy("rootConcept");
                      // await initData(!sortOrder, "rootConcept");
                      await onSortRoot(!sortOrder, "rootConcept")
                    }}
                  />
                </div>
                <ol
                  style={{ overflow: "scroll" }}
                  className={chapterStyles.RootConcept_list_itm}
                >
                  {listrootconceptstable.length > 0 &&
                    listrootconceptstable.map((v, i) => {
                      return (
                        <li
                          style={{
                            color: "blue",
                            listStyle: "none",
                            padding: "10px",
                          }}
                        >
                          <>
                            {rootConceptsEditid == v.id ? (
                              <>
                                <input
                                  type="text"
                                  style={{
                                    border: "0px",
                                    outline: "0px",
                                    borderBottom: "2px solid #1890ff",
                                    height: "30px",
                                  }}
                                  value={listrootconceptedit}
                                  onChange={(event) =>
                                    setlistrootconceptedit(event.target.value)
                                  }
                                ></input>
                                {rootConceptErrMsg && <p style={{ fontSize: "10px", color: "red", marginTop: "2px", marginBottom: "2px" }}>{rootConceptErrMsg}</p>}
                                <button
                                  className={chapterStyles.green_btn}
                                  onClick={() => updatelistrootConcepts(v)}
                                  style={{
                                    fontSize: "0.6vw",
                                    padding: "10px 25px",
                                  }}
                                >
                                  update
                                </button>
                                <button
                                  className={chapterStyles.green_btn}
                                  style={{
                                    fontSize: "0.6vw",
                                    padding: "10px 25px",
                                    marginLeft: "10px",
                                  }}
                                  onClick={() => {
                                    setRootConceptEditid(null);
                                  }}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>
                                {i + 1}.{v.rootConcept}
                                <EditIconButton
                                  id={`az-content-editicon`}
                                  onClick={() => {
                                    editHandler(v);
                                  }}
                                >
                                  <Tooltip title="Edit" placement={"top"}>
                                    <EditOutlinedIcon
                                      style={{
                                        fontSize: "1.2vw",
                                        color: "orange",
                                      }}
                                    />
                                  </Tooltip>
                                </EditIconButton>
                                <span style={{ float: "right" }}>
                                  <DeleteIconButton
                                    id={`az-content-deleteicon`}
                                    onClick={() => {
                                      handleDeleteOpen(v);
                                    }}
                                  >
                                    <Tooltip title="Remove" placement={"top"}>
                                      <DeleteOutlinedIcon
                                        style={{ fontSize: "1.2vw" }}
                                      />
                                    </Tooltip>
                                  </DeleteIconButton>
                                </span>
                              </>
                            )}
                          </>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          </Paper>
        </Paper>
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Concept"}
          deleteHandler={deleteHandler}
          //deleteId={selectedItem?.id}
          deleteName={message}
        />
        <CommonDeleteModal
          open={rootConceptdeleteModal}
          close={hiderootConceptDeleteModal}
          deleteTitle={"Concept"}
          deleteHandler={rootConceptdeleteConfirm}
          //deleteId={selectedItem?.id}
          deleteName={rootConceptMessage}
        />

        <SuccessModal
          open={subConceptmodal}
          title={title}
          createHandler={createHandler}
          close={onClose}
        ></SuccessModal>
        <SuccessModal
          open={rootConceptmodal}
          title={titleroot}
          createHandler={createrootHandler}
          close={onrootClose}
        ></SuccessModal>
        <ConfigurationSuccessModal
          userType={conceptUpdateMsg}
          open={isUpdatedModal}
          handleOk={onCloseUpdateModal}
        />
      </div>
    </>
  );
};
export default SubAndRootConcepts;
