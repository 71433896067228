import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const YellowButton = (props) => {
  const SaveButton = styled(Button)(() => ({
    color: "#fff",
    width: "178px",
    height: "40px",
    backgroundColor:props.disabled ? "#ccc":"#FFA700",
    borderRadius: 150,
    "&:hover": {
      color: "#fff",
      backgroundColor: "#FFA700",
      border: 0,
      top: -2,
      boxShadow: "0px 3px 5px #FFA700",
    },
  }));
  return (
    <SaveButton disabled={props?.disabled} className={props.className} onClick={props.onClick}>
      {props.children}
    </SaveButton>
  );
};
export default YellowButton;
