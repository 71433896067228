import React, { useState } from "react";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import MainContainer from "./MainContainer";
import DashboardContent from "./DashboardContent";
import { useEffect } from "react";

const Dashboard = () => {
  const navigate = useNavigate();
  //added by sg
  const [headerTitle, setHeaderTitle] = useState('');
  const [isnavbarHide, setIsNavBarHide] = useState(false);
  const handleHeaderTitle = (value) => {
    setHeaderTitle(value)
  }
  useEffect(() => {
    var checknavbarstatus = JSON.parse(sessionStorage.getItem("navbarHide"))
  
    console.log("dahsboard loading", checknavbarstatus)
    var currentLocation = window.location.href
    console.log("dahsboard loading", currentLocation)

  }, [])

  return (
    <>
      {/* navbar loading here */}
      <Navbar handleHeaderTitle={(value) => handleHeaderTitle(value)} /> {/* added by sg */}
      <MainContainer children={<DashboardContent />} headerTitle={headerTitle} />  {/* added by sg
       */}
    </>
  );
};
export default Dashboard;
