import { List, Typography } from "antd";
import { useContext, useState } from "react";
import { NotificationContext } from "../../context/notification/NotificationContext";
import $, { map } from 'jquery';
import axios from "axios";
import { useNavigate } from "react-router";

function convertDateFormat(dateString) {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date)) {
        throw new Error('Invalid date');
    }

    // Extract date components
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Extract time components
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format hours to 12-hour format and determine AM/PM
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight

    // Combine into the desired format
    const formattedDate = `${month}-${day}-${year} ${String(hours12).padStart(2, '0')}:${minutes}:${seconds} ${period}`;

    return formattedDate;
}


function isFormatedData(data) {
    let pairs = data && data?.split('#');

    let kvObject = {};

    pairs?.forEach(pair => {
        let [key, value] = pair?.split(':');
        kvObject[key] = value;
    });

    return kvObject;
}



const NotificationList = () => {
    const [listOfNotifications, setListOfNotifications] = useState([]);

    const navigation = useNavigate();

    const notificationList = useContext(NotificationContext)
    const { fetchNotifications, notifications } = notificationList || {};

    const TOKEN = sessionStorage?.getItem('token');

    const BASE_URL = process.env.REACT_APP_SWAGGER_URL;

    const navigateNotifications = (id, navigateUrl) => {
        fetchReadNotifications(id)

        if (navigateUrl == 'enrich') {
            navigation("/dashboard/enrich")
            sessionStorage.setItem("sideNavMainMenuNames", "Enrich")
        } else if (navigateUrl == 'formal assessment quiz') {
            navigation("/dashboard/formal%20assessment%20quiz")
            sessionStorage.setItem("sideNavMainMenuNames", "Formal Assessment Quiz")
        } else if (navigateUrl == 'Reading Passport') {
            navigation("/dashboard/reading%20passport")
            sessionStorage.setItem("sideNavMainMenuNames", "Reading Passport")
        }

    }

    const fetchReadNotifications = (id) => {
        axios
            .post(`${BASE_URL}v1/api/content/inbox/read/${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            })
            .then((response) => {
                setListOfNotifications(response.data)
                fetchNotifications()
            })
            .catch((error) => console.error(error));
    };

    const renderInboxUiList = (item) => {

        const { customFields } = item || {};
        let customData = (customFields != null && customFields != '') ? isFormatedData(customFields) : {};

        if (item?.messagetypeid === "QUIZ") {
            switch (customData?.quizType) {
                case 'Unit Quiz':
                    return <div className="inbox-list-li">
                        <p>{item?.message}</p>
                        <p style={{ color: "black", fontSize: "14px" }}>Subject &nbsp;: <span style={{ fontWeight: "bold" }}>{customData?.subject}</span></p>
                        <p style={{ color: "black", fontSize: "14px" }}>Chapter : <span style={{ fontWeight: "bold" }}>{customData?.chapter}</span></p>
                        <p>Please Click <span className="cursorPointer" style={{ textDecoration: "underline", color: "blue" }} onClick={() => {
                            navigateNotifications(item?.id, item?.messageHandlerRoute);
                            sessionStorage.setItem('InboxChapter', customData?.chapterId);
                            sessionStorage.setItem('InboxSubject1', customData?.subjectId);
                            sessionStorage.setItem('InboxSubjectName1', customData?.subject);
                            sessionStorage.setItem('InboxChapterName', customData?.chapter);
                        }}
                        >here</span> to open the test</p>
                        <p style={{ textAlign: "end" }}><span>{convertDateFormat(item?.sent_Datetime)}</span></p>
                    </div>
                case 'Formal assessment Quiz':
                    return <div className="inbox-list-li">
                        <p>{item?.message}</p>
                        <p style={{ color: "black", fontSize: "14px" }}>Subject &nbsp;&nbsp;&nbsp; : <span style={{ fontWeight: "bold" }}>{customData?.subject}</span></p>
                        <p style={{ color: "black", fontSize: "14px" }}>Quiz Type : <span style={{ fontWeight: "bold" }}>{customData?.quizType}</span></p>
                        <p>Please Click <span className="cursorPointer" style={{ textDecoration: "underline", color: "blue" }} onClick={() => {
                            navigateNotifications(item?.id, item?.messageHandlerRoute);
                            sessionStorage.setItem('InboxQuizType', customData?.quizType);
                            sessionStorage.setItem('InboxSubject2', customData?.subjectId);
                            sessionStorage.setItem('InboxSubjectName2', customData?.subject);
                        }}>here</span> to open the test</p>
                        <p style={{ textAlign: "end" }}><span>{convertDateFormat(item?.sent_Datetime)}</span></p>
                    </div>
                case 'Diagnostic Quiz':
                    return <div className="inbox-list-li">
                        <p>{item?.message}</p>
                        <p style={{ color: "black", fontSize: "14px" }}>Subject &nbsp;&nbsp;&nbsp; : <span style={{ fontWeight: "bold" }}>{customData?.subject}</span></p>
                        <p style={{ color: "black", fontSize: "14px" }}>Quiz Type : <span style={{ fontWeight: "bold" }}>{customData?.quizType}</span></p>
                        <p>Please Click <span className="cursorPointer" style={{ textDecoration: "underline", color: "blue" }} onClick={() => {
                            navigateNotifications(item?.id, item?.messageHandlerRoute);
                            sessionStorage.setItem('InboxQuizType', customData?.quizType);
                            sessionStorage.setItem('InboxSubject2', customData?.subjectId);
                            sessionStorage.setItem('InboxSubjectName2', customData?.subject);
                        }}>here</span> to open the test</p>
                        <p style={{ textAlign: "end" }}><span>{convertDateFormat(item?.sent_Datetime)}</span></p>
                    </div>
                case 'Annual/Term Quiz':
                    return <div className="inbox-list-li">
                        <p>{item?.message}</p>
                        <p style={{ color: "black", fontSize: "14px" }}>Subject &nbsp;&nbsp;&nbsp; : <span style={{ fontWeight: "bold" }}>{customData?.subject}</span></p>
                        <p style={{ color: "black", fontSize: "14px" }}>Quiz Type : <span style={{ fontWeight: "bold" }}>{customData?.quizType}</span></p>
                        <p>Please Click <span className="cursorPointer" style={{ textDecoration: "underline", color: "blue" }} onClick={() => {
                            navigateNotifications(item?.id, item?.messageHandlerRoute);
                            sessionStorage.setItem('InboxQuizType', customData?.quizType);
                            sessionStorage.setItem('InboxSubject2', customData?.subjectId);
                            sessionStorage.setItem('InboxSubjectName2', customData?.subject);
                        }}>here</span> to open the test</p>
                        <p style={{ textAlign: "end" }}><span>{convertDateFormat(item?.sent_Datetime)}</span></p>
                    </div>
                default:
                    return <div className="inbox-list-li">
                        <p>{item?.message}</p>
                        <p style={{ color: "black", fontSize: "14px" }}>Subject &nbsp;&nbsp;&nbsp; : <span style={{ fontWeight: "bold" }}></span></p>
                        <p style={{ color: "black", fontSize: "14px" }}>Quiz Type : <span style={{ fontWeight: "bold" }}></span></p>
                        <p>Please Click <span className="cursorPointer" style={{ textDecoration: "underline", color: "blue" }} onClick={() => {
                            navigateNotifications(item?.id, item?.messageHandlerRoute);
                            sessionStorage.setItem('InboxQuizType', customData?.quizType);
                            sessionStorage.setItem('InboxSubject', customData?.subjectId);
                            sessionStorage.setItem('InboxSubjectName', customData?.subject);
                        }}>here</span> to open the test</p>
                        <p style={{ textAlign: "end" }}><span>{convertDateFormat(item?.sent_Datetime)}</span></p>
                    </div>
            }
        } else {
            return <div onClick={() => navigateNotifications(item?.id, item?.messageHandlerRoute)}>
                <p style={{ color: "black", fontSize: "16px", paddingBottom: "5px" }}>{item?.message}</p>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p style={{ fontSize: "13px" }}>{customData?.student}</p>
                    <p style={{ fontSize: "13px" }}>{convertDateFormat(item?.sent_Datetime)}</p>
                </div>
            </div>
        }
    }

    const sortedData = notifications && notifications?.messages?.sort((a, b) => new Date(b.sent_Datetime) - new Date(a.sent_Datetime))

    return (
        <>
            {/* <h1>Notification List</h1> */}
            <div style={{ width: "570px" }}>

                <List
                    // header={<div>Header</div>}
                    // footer={<div>Footer</div>}
                    bordered
                    dataSource={sortedData}
                    renderItem={(item) => (
                        <List.Item style={{ display: "block", cursor: 'pointer' }}>
                            {renderInboxUiList(item)}
                        </List.Item>
                    )}
                />
            </div>
        </>
    )
}
export default NotificationList;