import axios from 'axios'

function concept_controller_post(conceptInputs){
    let token = sessionStorage.getItem("token") ;
    var item = {
        "chapterId": conceptInputs.chapterId,
        "concept": conceptInputs.conceptId,
        "description": "Interactive Concept",
        "id": conceptInputs.conceptId,
        "subConcepts": [
          {
            "id": conceptInputs.subConceptId,
            "rootConcepts": [
              {
                "rootConcept": "Biology"
              }
            ],
            "subConcept": "Biology"
          }
        ],
        "subTopicId": conceptInputs.subTopicId,
        "subjects": [
            conceptInputs.subjectsId
        ],
        "subjectsId": conceptInputs.subjectsId
      }

    let result = axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concept` , item ,{
        headers : {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`

        }
    })
    .then((res)=> {
        // console.log(res.data)
    })
    .catch(err => console.log(err))
}

export default concept_controller_post