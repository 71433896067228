import React from 'react'
import './Principaldashboard.css'

const CompletionDetails = ({ data }) => {

  const sectionsData = data?.sections?.length > 0 ? data?.sections : []
  return (
    <div style={{ width: '100%' }}>
      <p className='sub-heading'>Completion Details</p>
      <div className='sections-scroll-box'>
        <div className='sections-scroll-box-head'>
          {sectionsData?.map(item => (
            <div
              style={{
                background: '#62E3D4',
              }}
            >
              <p key={item?.sectionId} className='table-label'>
                {item?.section}
                <p className='table-value'>{`${item?.completedPercentage || 0}/${item?.totalPercentage || 0}`}</p>
              </p>
            </div>
          ))}
           <div
              style={{
                background: '#62E3D4',
              }}
            >
              <p className='table-label'>
                Total
                <p className='table-value' >{data?.total || 0}</p>
              </p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default CompletionDetails
