import React, { useEffect, useState } from "react";
import axios from "axios";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import dashHome from "../../../../src/img/dashHome.svg";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const EditCourse = (props) => {
    const formatDateTime = (dateTime) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        const formattedDateTime =  new Intl.DateTimeFormat('en-US', options).format(dateTime);

        return formattedDateTime.replace(/\//g, '-');
    };

    const {id} = useParams();
    const [updateData, setUpdateData] = useState({
        name:'',
        code:'',
        type:'',
        category:'',
        location:'',
        mode:'',
        status:'',
        createdBy:'',
        description:'',
        createdDate:formatDateTime(new Date())
    })
    const [modifiedDate, setModifiedDate] = useState({});


    const navigate = useNavigate();
   
    useEffect(() => {
        GetLastModifiedAt(setModifiedDate);
    }, [])

    useEffect(async()=>{
        if(id){
            try{
                const response = await axios.get(
                    `http://localhost:4000/items/${id}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );
                console.log("updateSinglePost", response.data)
                setUpdateData(response?.data)
            }catch(err){
                console.error('error fetching post detail',err)
            }
        }
    },[id])
    console.log('single ', updateData)

    const handleUpdate=async(e)=>{
        e.preventDefault()
        try{
            const updatedData = { ...updateData, createdDate: formatDateTime(new Date()) };
            const response = await axios.patch(`http://localhost:4000/items/${id}`,updatedData,
            
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if(response.status===200){
            
            navigate('/dashboard/upskills')
            return response.data; 
          }else{
            console.log('failed to update ')
          }
        }catch(err){
            console.log('error updating ',err)
        }
        navigate('/dashboard/upskills')

    }
    const handleCancel =()=>{
        navigate('/dashboard/upskills')
    }
    
    return (

        <div style={{ width: "100%" }}>
        <article>

            <div style={{ position: 'relative', margin: '14px' }}>
                <span className={dashboardContentStyles.link_icon}>
                    <img
                        src={dashHome}
                        alt="no img"
                        className={dashboardContentStyles.az_menu_icon}
                    />
                </span>
                <span
                    id={`az-breadscrum-home`}
                    className={dashboardContentStyles.az_menu_text}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                        sessionStorage.removeItem("sideNavSubMenuNames");
                        navigate("/dashboard/template");
                    }}
                >
                    Home
                </span>
                <span>
                    <i className="fa-solid fa-angle-right"
                        style={{ color: "#828282" }} />
                </span>
                <span>
                    <i className="fa-solid fa-angle-right"
                        style={{ color: "#828282" }} />
                </span>
                <span
                    className={dashboardContentStyles.az_menu_text}
                    style={{ cursor: 'pointer' }}
                        onClick={() => {                            
                            navigate('/dashboard/upskills')
                        }}
                >
                    Upskills
                </span>
                <span>
                    <i className="fa-solid fa-angle-right"
                        style={{ color: "#828282" }} />
                </span>
                <span>
                    <i className="fa-solid fa-angle-right"
                        style={{ color: "#828282" }} />
                </span>
                <span
                    className={dashboardContentStyles.az_selected_menu_text}
                >
                    EditCourse
                </span>
            </div>

            <div className={dashboardContentStyles.dashboard_last_updated}>
                <p>Last Update: {modifiedDate.data}</p>
            </div>
        </article>
        <div className="addCourseForm">
            <form onSubmit={(e)=>handleUpdate(e)}>
            <Box >
                <Grid container spacing={2}>
                    <Grid item md={3}  xs={12}>
                        <div className="inputDiv">
                            <label className="inputLabel" htmlFor="name">Course Name </label>
                            <input type="text" id="name" name="name"  required placeholder="Enter course name" value={updateData.name} onChange={(e)=>setUpdateData({...updateData,name:e.target.value})} />
                            
                        </div>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <div className="inputDiv">
                            <label className="inputLabel" htmlFor="code">Course Code </label>
                            <input type="text" id="code" name="code"  required placeholder="Enter course code" value={updateData.code} onChange={(e)=>setUpdateData({...updateData,code:e.target.value})} />
                        </div>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <div className="inputDiv">
                            <label className="inputLabel" htmlFor="type">Type </label>
                            <input type="text" id="type" name="type"  required placeholder="Enter course code" value={updateData.type} onChange={(e)=>setUpdateData({...updateData,type:e.target.value})}/>
                        </div>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <div className="inputDiv">
                            <label className="inputLabel" htmlFor="category">Category </label>
                            <input type="text" id="category" name="category"  required placeholder="Enter course code" value={updateData.category} onChange={(e)=>setUpdateData({...updateData,category:e.target.value})}/>
                        </div>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <div className="inputDiv">
                            <label className="inputLabel" htmlFor="location">Location </label>
                            <input type="text" id="location" name="location"  required placeholder="Enter course code"value={updateData.location}onChange={(e)=>setUpdateData({...updateData,location:e.target.value})} />
                        </div>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <div className="inputDiv">
                            <label className="inputLabel" htmlFor="mode">Mode </label>
                            <input type="text" id="mode" name="mode"  required placeholder="Enter course code"value={updateData.mode} onChange={(e)=>setUpdateData({...updateData,mode:e.target.value})} />
                        </div>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <div className="inputDiv">
                            <label className="inputLabel" htmlFor="author">Author </label>
                            <input type="text" id="createdBy" name="createdBy"  required placeholder="Enter author name"value={updateData.createdBy} onChange={(e)=>setUpdateData({...updateData,createdBy:e.target.value})} />
                        </div>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <div className="inputDiv">
                            <label className="inputLabel" htmlFor="status">Status </label>
                            <input type="text" id="status" name="status"  required placeholder="Status" value={updateData.status} onChange={(e)=>setUpdateData({...updateData,status:e.target.value})}/>
                        </div>
                    </Grid>
                    <Grid item md={3}  xs={12}>
                        <div className="inputDiv">
                            <label className="inputLabel" htmlFor="description">Description </label>
                            <textarea type="text" id="description" name="description"  required placeholder="Enter description" value={updateData.description} onChange={(e)=>setUpdateData({...updateData,description:e.target.value})}></textarea>
                        </div>
                    </Grid>
        </Grid>
        </Box>
        <div className="btnGrid">
        <button className="cancelCBtn" onClick={()=>handleCancel()}>Cancel</button>
        <button  className="addCBtn" type="submit">Update</button>
        </div>
        
            </form>
        </div>
             

    </div>


       
    );
};

export default EditCourse;
