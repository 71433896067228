import axios from 'axios'

function training_get_modifieddate(setLastModifiedDate){
    let token = sessionStorage.getItem("token");

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training/last-modified-at`,
    {
        headers: {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setLastModifiedDate(res.data)
    })
}

export default training_get_modifieddate