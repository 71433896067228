import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const useEducationData = () => {
  const TOKEN = sessionStorage.getItem('token');
  const BASE_URL = process.env.REACT_APP_SWAGGER_URL;

  const getBoards = async () => {
    const response = await axios.get(`${BASE_URL}v1/api/master/boards/all`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    });
    return response.data;
  };

  const getGrades = async () => {
    const response = await axios.get(`${BASE_URL}v1/api/master/grades/all`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    });
    return response.data;
  };

  const getSubjects = async () => {
    const response = await axios.get(`${BASE_URL}v1/api/master/subjects/all`, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    });
    return response.data;
  };

  const getAcademicYears = async () => {
    const response = await axios.get(
      `${BASE_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    );
    return response.data;
  };

  const {
    data: boards,
    isLoading: isBoardsLoading,
    error: boardsError,
  } = useQuery('boards', getBoards);
  const {
    data: grades,
    isLoading: isGradesLoading,
    error: gradesError,
  } = useQuery('grades', getGrades);
  const {
    data: subjects,
    isLoading: isSubjectsLoading,
    error: subjectsError,
  } = useQuery('subjects', getSubjects);
  const {
    data: academicYears,
    isLoading: isAcademicYearsLoading,
    error: academicYearsError,
  } = useQuery('academicYears', getAcademicYears);

  const isLoading =
    isBoardsLoading ||
    isGradesLoading ||
    isSubjectsLoading ||
    isAcademicYearsLoading;
  const error =
    boardsError || gradesError || subjectsError || academicYearsError;

  return {
    isLoading,
    error,
    boards,
    grades,
    subjects,
    academicYears,
  };
};

export default useEducationData;
