import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { orange } from "@material-ui/core/colors";

const LockIconButton = (props) => {
  const LockButton = styled(Button)(() => ({
    color: grey[500],
    borderRadius: 150,
    "&:hover": {
      color: orange[600],
    },
  }));
  return (
    <LockButton
      sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
      onClick={props.onClick}
    >
      {props.children}
    </LockButton>
  );
};
export default LockIconButton;
