import { alpha, styled } from "@mui/material/styles";
import { Switch as SWITCH } from "@mui/material";
import React from "react";
const DisabledSwitch = ({ checked, onChange, disabled }) => {
  const GreenSwitch = styled(SWITCH)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#ffff",
      cursor: 'default',
      "&:hover": {
        backgroundColor: alpha("#474441", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-thumb": {
      border: "3px solid #888888",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#474441",
    },
    "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled":
    {
      opacity: 0.5,
      color: "#ffff",
    },
  }));
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <GreenSwitch
      {...label}
      checked={checked}
      onChange={onChange}
      disabled={disabled ? true : false}
      style={{ color: disabled && "#fff" }}
    />
  );
};
export default DisabledSwitch;
