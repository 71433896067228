import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import axiosToken from '../axiosToken';

export const EducationContext = createContext();

const EducationContextProvider = ({ children }) => {
  const [boards, setBoards] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  // const [subTopics, setSubTopics] = useState([]);

  const TOKEN = sessionStorage.getItem('token');

  const BASE_URL = process.env.REACT_APP_SWAGGER_URL;

  useEffect(() => {
    // BOARDS
    const fetchBoards = () => {
      axios
        .get(`${BASE_URL}v1/api/master/boards/all`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        })
        .then((response) => setBoards(response.data))
        .catch((error) => console.error(error));
    };

    // GRADES
    const fetchGrades = () => {
      axios
        .get(`${BASE_URL}v1/api/master/grades/all`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        })
        .then((response) => setGrades(response.data))
        .catch((error) => console.error(error));
    };

    // SUBJECTS
    const fetchSubjects = () => {
      axios
        .get(`${BASE_URL}v1/api/master/subjects/all`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        })
        .then((response) => setSubjects(response.data))
        .catch((error) => console.error(error));
    };

    // ACADEMIC-YEARS
    const fetchAcademicYears = () => {
      axios
        .get(`${BASE_URL}v1/api/master/academic-years/all`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        })
        .then((response) => setAcademicYears(response.data))
        .catch((error) => console.error(error));
    };

    fetchBoards();
    fetchGrades();
    fetchSubjects();
    fetchAcademicYears();
  }, [BASE_URL, TOKEN]);

  // const fetchSubTopics = (id) => {
  //   axios
  //     .get(`${BASE_URL}v1/api/master/sub-topics/all?subjectId=${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${TOKEN}`,
  //       },
  //     })
  //     .then((response) => setSubTopics(response.data))
  //     .catch((error) => console.error(error));
  // };

  return (
    <EducationContext.Provider
      value={{
        boards,
        grades,
        subjects,
        academicYears,
      }}
    >
      {children}
    </EducationContext.Provider>
  );
};

export default EducationContextProvider;
