import { v4 as uuidv4 } from "uuid";
//avgAttemptPercentagePQ??

export const getDataStructureFormatted = (cdata) => {
  let formatted = [];
  cdata?.forEach((obj) => {
    obj?.grades?.forEach((data) => {
      if (data?.sections) {
        data?.sections?.forEach((item) => {
          item?.subjects.forEach((elem) => {
            if (elem?.subtopics) {
              elem?.subtopics?.forEach((subtopic) => {
                let arr = DataStructureWithSubtopic(item, subtopic, data, elem);
                formatted.push(arr);
              });
            } else {
              let arr = DataStructureWithoutSubtopic(item, data, elem);
              formatted.push(arr);
            }
          });
        });
      } else {
        data?.subjects?.forEach((elem) => {
          if (elem?.subtopics) {
            elem?.subtopics?.forEach((subtopic) => {
              let arr = DataStructureSubtopicWithoutSection(data, elem, subtopic);
              formatted.push(arr);
            });
          } else {
            let arr = DataStructureWithoutSubtopicWithoutSection(data, elem);
            formatted.push(arr);
          }
        });
      }
    });
  });
  return formatted;
};

export const updatedGetDataStructureFormatted = (cdata) => {
  let formatted = [];
  cdata?.forEach((obj) => {
    obj?.grades?.forEach((data) => {
      if (data?.sections) {
        data?.sections?.forEach((item) => {
          item?.subjects.forEach((elem) => {
            if (elem?.subtopics) {
              elem?.subtopics?.forEach((subtopic) => {
                let arr = DataStructureWithSubtopic(item, subtopic, data, elem);
                formatted.push(arr);
              });
            } else {
              let arr = DataStructureWithoutSubtopic(item, data, elem);
              formatted.push(arr);
            }
          });
        });
      } else {
        data?.subjects?.forEach((elem) => {
          if (elem?.subtopics) {
            elem?.subtopics?.forEach((subtopic) => {
              let arr = DataStructureSubtopicWithoutSection(data, elem, subtopic);
              formatted.push(arr);
            });
          } else {
            let arr = DataStructureWithoutSubtopicWithoutSection(data, elem);
            formatted.push(arr);
          }
        });
      }
    });
  });
  return formatted;
};

export const DataStructureWithSubtopic = (item, subtopic, data, elem) => {
  let arr = {
    gradeSectionId: uuidv4(),
    grade: data.grade,
    gradeId: data.gradeId,
    // gradeSectionId: `${data?.gradeId}-${item?.sectionId}`,

    section: item.section,
    sectionId: item.sectionId,
    subject: subtopic.subtopic,
    subjectId: elem.subjectId,
    averageMessage: subtopic.averageMessage,
    practiceAttemptRateMessage: subtopic.practiceAttemptRateMessage,
    unitAttemptRateMessage: subtopic.unitAttemptRateMessage,
    globalAvgAttemptPercentageUQ: subtopic.unitGlobalQuizAttemptRate,
    avgAttemptPercentageUQ: subtopic.unitQuizAttemptRate,
    globalAvgAttemptPercentagePQ: subtopic.practiceGlobalQuizAttemptRate,
    avgAttemptPercentagePQ: subtopic.practiceQuizAttemptRate,
    globalAvgScorePercentageUQ: subtopic.globalQuizAverageScorePercentage,
    avgScorePercentageUQ: subtopic.quizAverageScorePercentage,
    subtopic: subtopic.subtopic,
    subtopicId: subtopic.subtopicId,
  };

  return arr;
};

export const DataStructureWithoutSubtopic = (item, data, elem) => {
  let arr = {
    gradeSectionId: uuidv4(),
    // gradeSectionId: `${data?.gradeId}-${item?.sectionId}-${elem?.subjectId}`,

    grade: data.grade,
    gradeId: data.gradeId,
    section: item.section,
    sectionId: item.sectionId,
    subject: elem.subject,
    subjectId: elem.subjectId,
    averageMessage: elem.averageMessage || "",
    practiceAttemptRateMessage: elem.practiceAttemptRateMessage || "",
    unitAttemptRateMessage: elem.unitAttemptRateMessage || "",
    globalAvgAttemptPercentageUQ: elem.unitGlobalQuizAttemptRate || 0,
    avgAttemptPercentageUQ: elem.unitQuizAttemptRate || 0,
    globalAvgAttemptPercentagePQ: elem.practiceGlobalQuizAttemptRate || 0,
    avgAttemptPercentagePQ: elem?.practiceQuizAttemptRate || 0,
    globalAvgScorePercentageUQ: elem.globalQuizAverageScorePercentage || 0,
    avgScorePercentageUQ: elem?.quizAverageScorePercentage || 0,
    subtopic: "",
    subtopicId: "",
  };

  return arr;
};

export const DataStructureSubtopicWithoutSection = (data, elem, subtopic) => {
  let arr = {
    gradeSectionId: uuidv4(),
    // gradeSectionId: `${data?.gradeId}-${elem?.subjectId}`,

    grade: data.grade,
    gradeId: data.gradeId,
    section: "",
    sectionId: "",
    subject: elem.subject,
    subjectId: elem.subjectId,
    averageMessage: subtopic.averageMessage,
    practiceAttemptRateMessage: subtopic.practiceAttemptRateMessage,
    unitAttemptRateMessage: subtopic.unitAttemptRateMessage,
    globalAvgAttemptPercentageUQ: subtopic.unitGlobalQuizAttemptRate,
    avgAttemptPercentageUQ: subtopic.unitQuizAttemptRate,
    globalAvgAttemptPercentagePQ: subtopic.practiceGlobalQuizAttemptRate,
    avgAttemptPercentagePQ: subtopic.practiceQuizAttemptRate,
    globalAvgScorePercentageUQ: subtopic.globalQuizAverageScorePercentage,
    avgScorePercentageUQ: subtopic.quizAverageScorePercentage,
    subtopic: subtopic.subtopic,
    subtopicId: subtopic.subtopicId,
  };

  return arr;
};

export const DataStructureWithoutSubtopicWithoutSection = (data, elem) => {
  console.log(elem, "elem mm");
  let arr = {
    gradeSectionId: uuidv4(),
    // gradeSectionId: `${data?.gradeId}`,

    grade: data.grade,
    gradeId: data.gradeId,
    section: "",
    sectionId: "",
    subject: elem.subject,
    subjectId: elem.subjectId,
    averageMessage: elem.averageMessage || "",
    practiceAttemptRateMessage: elem.practiceAttemptRateMessage || "",
    unitAttemptRateMessage: elem.unitAttemptRateMessage || "",
    globalAvgAttemptPercentageUQ: elem.unitGlobalQuizAttemptRate || 0,
    avgAttemptPercentageUQ: elem.unitQuizAttemptRate || 0,
    globalAvgAttemptPercentagePQ: elem.practiceGlobalQuizAttemptRate || 0,
    avgAttemptPercentagePQ: elem?.practiceQuizAttemptRate || 0,
    globalAvgScorePercentageUQ: elem.globalQuizAverageScorePercentage || 0,
    avgScorePercentageUQ: elem?.quizAverageScorePercentage || 0,
    subtopic: "",
    subtopicId: "",
  };
  return arr;
};
