import React from 'react';

const DocumentViewer = ({ selectedUrl }) => {
    const filename = selectedUrl.substring(selectedUrl.lastIndexOf('/') + 1);
    const extensionMatch = filename.match(/\.([^.]+)$/);

    if (extensionMatch) {
        return (
            <iframe
                src={selectedUrl}
                title="Document Viewer"
                style={{ width: '100%', height: '100vh', border: 'none' }}
            />
        );
    } else {
        // Render a message or fallback content if the extension cannot be determined
        return <p>Unable to determine file extension</p>;
    }
};

export default DocumentViewer;
