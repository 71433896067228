import React from "react";
import styled from "styled-components";
import moment from 'moment';
function RejectCommentModal({
	isOpen,
	setCommentModal,
	selectedQuiz,
	setComment,
	comment,
	rejectQuiz,
	addComment,
	isCommentError,
	setCommentError,
}) {
	const closeModal = () => {
		setCommentModal((prev) => !prev);
	};

	const handleChange = (e) => {
		setCommentError(false);
		setComment(e.target.value);
	};

	const user = localStorage.getItem("userName");

	return (
		<ModalOverlay isOpen={isOpen}>
			<ModalWrapper>
				<Title>Comments</Title>
				<CommentContainer>
					{selectedQuiz?.rejectedComments?.map((data, id) => (
						<Comment>
							<Content
								className={data.replyFrom === user && "user"}
							>
								{data.comment}
							</Content>
							<From className={data.replyFrom === user && "user"}>
								Replay from {data.replyFrom},{data.createdAt}
								{/* {moment(data.createdAt).format('DD-MM-YYYY')} */}
							</From>
						</Comment>
					))}
				</CommentContainer>
				<ReplaySection>
					<Input
						placeholder="Reply..."
						onChange={handleChange}
						value={comment}
					/>
				</ReplaySection>
				{isCommentError && <Error>Please add a comment</Error>}
				<ButtonContainer>
					<Cancel onClick={closeModal}>Cancel</Cancel>
					<Okay
						onClick={
							selectedQuiz.from === "reject"
								? rejectQuiz
								: addComment
						}
					>
						Ok
					</Okay>
				</ButtonContainer>
			</ModalWrapper>
		</ModalOverlay>
	);
}

export default RejectCommentModal;

const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: ${(props) => (props.isOpen ? "block" : "none")};
	z-index: 100;
`;

const ModalWrapper = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	width: 70%;
	max-width: 600px;
`;
const Title = styled.h3`
	font-size: 16px;
	color: #fe930f;
	padding-bottom: 10px;
	border-bottom: 10px solid #d8dcff;
	margin: 0;
`;
const CommentContainer = styled.div`
	max-height: 250px;
	overflow-y: scroll;
`;
const Comment = styled.div`
	padding: 16px 0;
`;
const Content = styled.p`
	font-size: 13px;
	font-weight: 300;
	&.user {
		text-align: right;
	}
`;
const From = styled.p`
	font-size: 13px;
	font-weight: 300;
	color: #3072e2;
	margin-top: 5px;
	&.user {
		text-align: right;
	}
`;
const ReplaySection = styled.div`
	width: 100%;
	margin-top: 20px;
`;
const Input = styled.input`
	width: 100%;
	outline: none;
	box-shadow: none;
	min-height: 40px;
	border: 1px solid rgb(240 240 240);
	padding: 0 10px;
	::placeholder {
		color: #2f74e2;
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	gap: 20px;
`;
const Cancel = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 40px;
	border: 1px solid #fc8c00;
	border-radius: 20px;
	color: #fc8c00;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
const Okay = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 40px;
	border: 1px solid #fc8c00;
	border-radius: 20px;
	background-color: #fc8c00;
	color: #fff;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
const Error = styled.span`
	display: block;
	color: red;
	font-size: 13px;
	font-weight: 300;
`;
