import React, { useEffect, useState } from "react";
// import dashboardContentStyles from "../../css/dashboardContent.module.css";
import dashboardContentStyles from "../../../../pages/css/dashboardContent.module.css";
// import dashHome from "../../../../src/img/dashHome.svg";
import dashHome from "../../../../img/dashHome.svg";
import GetLastModifiedAt from "../../../../pages/API/school-controller/GetLastModifiedAt";
import { Link, useNavigate } from "react-router-dom";

const DashboardBluePrintLayer = ({ children }) => {
  const role = localStorage.getItem("role");
  const userName = localStorage.getItem("userName");
  const [modifiedDate, setModifiedDate] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      GetLastModifiedAt(setModifiedDate);
    };

    fetchData();
  }, [userName, role]);

  return (
    <div
      className="WholePagee"
      style={{
        background: "#eaeaf5",
        height: "120vh",
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarWidth:'thin'
      }}
    >
      <article>
        <div
          className={dashboardContentStyles.dashboard_link}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
            marginLeft: "15px",
            fontSize: "1vw",
            position: "initial",
          }}
        >
          {/* <div>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            <span
              style={{ fontSize: "12px", marginLeft: "4px", color: "#7A7A7A" }}
            >
              Home ⨠
            </span>
            <span
              style={{ fontSize: "12px", color: "#001AF3", marginLeft: "4px" }}
            >
              Content Reviewer ⨠
            </span>
            <span
              style={{ fontSize: "12px", color: "#001AF3", marginLeft: "4px" }}
            >
              Blue Print
            </span>
          </div> */}

          <div className={dashboardContentStyles.dashboard_link}>

            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={dashboardContentStyles.az_menu_icon}
              />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate("/dashboard/template")}
            >
              Home
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
            >
              Content Reviewer
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_selected_menu_text}
            >
              Blue Print
            </span>
          </div>


          <div
            className={dashboardContentStyles.dashboard_last_updated}
            // style={{ position: "initial" }}
          >
            <p>Last Update: {modifiedDate?.data}</p>
          </div>
        </div>
      </article>
      {children}
    </div>
  );
};

export default DashboardBluePrintLayer;
