import React, { memo, useRef, useLayoutEffect, useEffect } from "react";
export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
export function SingleOTPInputComponent(props) {
  const { focus, autoFocus, ...rest } = props;
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <input
      ref={inputRef}
      {...rest}
      style={{
        width: 50,
        padding: "10px",
        fontSize: "18px",
        textAlign: "center",
        background: inputRef?.current?.value ? "#f79f02" : "",
        color: inputRef?.current?.value ? "white" : "",
        border: inputRef?.current?.value ? "white" : "",
        borderRadius: "7px",
      }}
    />
  );
}
const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;
