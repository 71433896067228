import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DeleteModal({ open, handleClose, onConfirm }) {

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Are you sure you want to delete ?
      </DialogTitle>

      <DialogActions>
        <Button
          id={`az-contentadmin-cancel-button`}
          onClick={handleClose}>
          Cancel
        </Button>
        <Button
          id={`az-contentadmin-delete-button`}
          onClick={onConfirm} style={{ color: 'tomato' }}  >
          Delete
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}