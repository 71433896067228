import React from 'react'
import './DashboardBarChartCard.css'
import CustomBar from '../CustomBar/CustomBar';
import { truncateString } from '../../utils/helper';
import { Tooltip } from '@mui/material';

const DashboardBarChartCard = ({ onCardClick, subject, labelClickDisabled, title, subtitle, v2, selected, first_percent, first_label, second_percent, second_label, onLabelClick }) => {

    return (
        <div onClick={onCardClick} className='chart-card' style={{ border: selected ? '2px solid #3268E7' : 'none' }}>
            <div className="chart-card-inner">

                <div style={{ width: '100%', marginBottom: '30px' }}>
                    <Tooltip title={title}>
                        <p className='chart-card-title'>{truncateString(title, 20)}</p>
                    </Tooltip>

                    <p className='chart-card-subtitle'>{subject}</p>
                </div>
                <div style={{ height: '160px', display: 'flex', alignItems: 'flex-end', position: 'relative' }}>
                    <CustomBar
                        color={'#636EB7'}
                        percent={first_percent > 100 ? 100 : Math.round(first_percent)}
                        width={'35px'}
                        marginRight={'20px'}
                    />
                    <CustomBar
                        color={'#9BC0E4'}
                        percent={second_percent > 100 ? 100 : Math.round(second_percent)}
                        width={'35px'}
                    />
                </div>
                <div style={{ height: 1, width: '80%', background: '#000', marginTop: '5px', marginBottom: '5px' }} />
                <div style={{ display: 'flex' }}>
                    {labelClickDisabled ?
                        <p className='chart-label' style={{ marginRight: 10 }} >{first_label}  {'\n'} %</p> :
                        <p className='chart-label' style={{ marginRight: 10, textDecoration: 'underline', cursor: 'pointer' }} onClick={onLabelClick} >{first_label}  {'\n'} %</p>
                    }

                    <p className='chart-label'>{second_label} {'\n'} %</p>
                </div>
            </div>

        </div>
    )
}

export default DashboardBarChartCard