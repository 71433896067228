import axios from "axios";
import { isNOTNullOrUndefined } from "../../../../utils/helper";

export const fetchQuizzesTotal = async () => {
    const token = sessionStorage.getItem("token");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const studentId = sessionStorage.getItem("studentId");
    const boardId = sessionStorage.getItem("boardId");
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/dashboard/all-uq/attempted/so-far`
    const res = await axios.get(
      url,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data || [];
  };

  export const fetchQuizzesAttemptedTotal = async (subjectId) => {
    const token = sessionStorage.getItem("token");
    
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/dashboard/no-of-quizzes/total?subjectId=${subjectId}`
    try {
      const res = await axios.get(
        url,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res?.data?.data || [];
    } catch (error) {
      return []
    }
   
  };



  


  export const fetchDashboardQuizPerformance = async () => {
    const token = sessionStorage.getItem("token");
    // const schoolId = sessionStorage.getItem("schoolId");
    // const branchId = sessionStorage.getItem("branchId");
    // const gradeId = sessionStorage.getItem("gradeId");
    // const sectionId = sessionStorage.getItem("sectionId");
    // const studentId = sessionStorage.getItem("studentId");
    // const boardId = sessionStorage.getItem("boardId");
  
    
    // let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/dashboard/quiz-performance?studentId=${studentId}&sectionId=${sectionId}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${gradeId}`
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/dashboard/quiz-performance`
    
    try {
      const res = await axios.get(
        url,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res?.data?.data || [];
    } catch (error) {
      return []
    }
   
  };



  export const fetchDashboardChapterWisePerformance = async (subjectId,subTopicId) => {
    const token = sessionStorage.getItem("token");
  
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/dashboard/chapterwise-quiz-performance?subjectId=${subjectId}${isNOTNullOrUndefined(subTopicId)? `&subTopicId=${subTopicId}`:''}`
    try {
      const res = await axios.get(
        url,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res?.data?.data || [];
    } catch (error) {
      return []
    }

  };

  export const fetchStudentData = async (userName, role) => {
	const token = sessionStorage.getItem("token");

	let result = {};
	await axios
		.get(
			`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then((res) => {
			if (res.status === 200) {
				result = res?.data?.data?.student;
			} else {
				result = {};
			}
		})
		.catch((err) => console.log(err, "errr"));
	return result;
};


