import React from "react";

const UploadAssessmentTableHead = ({ tableHeadData }) => {
  return (
    <thead>
      <tr className="table-data-head">
        {tableHeadData?.map((item) => {
          return (
            <>
              <th
                style={{
                  textAlign: item?.title == "Action" ? "start" : "",
                  borderRight: "1px Solid #0000001F",
                  paddingLeft: item?.title == "Action" ? "10px" : "",
                }}
              >
                {item?.title}
              </th>
            </>
          );
        })}
      </tr>
    </thead>
  );
};

export default UploadAssessmentTableHead;
