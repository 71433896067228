import React from "react";
import "./DashboardBarChartCardV2.css";
import CustomBarV2 from "../CustomBar/CustomBarV2";
import { truncateString } from "../../utils/helper";
import { Tooltip } from "@mui/material";

const DashboardBarChartCardV2 = ({
  onCardClick,
  title,
  subtitle,
  v2,
  selected,
  first_percent,
  first_label,
  second_percent,
  second_label,
  onLabelClick,
}) => {
  return (
    <div
      onClick={onCardClick}
      className="chart-card"
      style={{ border: selected ? "2px solid #3268E7" : "none" }}
    >
      <div className="chart-card-inner">
        <div style={{ width: "100%", marginBottom: "30px" }}>
          <Tooltip title={title}>
            <p className="chart-cardv2-title">{truncateString(title, 20)}</p>
          </Tooltip>
        </div>
        <div
          style={{
            height: "160px",
            display: "flex",
            alignItems: "flex-end",
            position: "relative",
          }}
        >
          <CustomBarV2
            color={"#5CA2F2"}
            percent={first_percent > 100 ? 100 : first_percent}
            width={"25px"}
            marginRight={10}
          />
          <CustomBarV2
            color={"#64C2A6"}
            percent={second_percent > 100 ? 100 : second_percent}
            width={"25px"}
          />
        </div>
        <div
          style={{
            height: 1,
            width: "80%",
            background: "#000",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        />
        <div className="chart-cardv2-container-legend">
          <div className="chart-cardv2-container-indicator">
            <div className="clr-box" style={{ background: "#5CA2F2" }} />
            <p className="indicator-label" style={{ fontSize: "10px" }}>
              {first_label} {"\n"} %
            </p>
          </div>
          <div className="chart-cardv2-container-indicator">
            <div className="clr-box" style={{ background: "#64C2A6" }} />
            <p className="indicator-label" style={{ fontSize: "10px" }}>
              {second_label} {"\n"} %
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBarChartCardV2;
