import React, { useState, useEffect, useRef } from 'react';
// import CKEditor from 'ckeditor4-react';
import CkEditor from './ckEditor5/CkEditor';

export default function TextEditor(props) {
  const [content, setContent] = useState('');
  const editorRef = useRef(null);

  // CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js';
  console.log("updated ckeditor full-all")
  useEffect(() => {
    setContent('');
  }, [props.clearContent]);

  useEffect(() => {
    if (props.initialValue !== content) {
      setContent(props.initialValue);
    }
  }, [props.initialValue]);

  useEffect(() => {
    if (editorRef.current) {
      const editorInstance = editorRef.current;

      // Adding paste event listener to handle image paste
      editorInstance.on('paste', (event) => {
        const clipboardData = event.data.dataTransfer;
        const items = clipboardData.items || clipboardData.files;

        for (let i = 0; i < items?.length; i++) {
          if (items[i].kind === 'file' && items[i].type.startsWith('image/')) {
            const file = items[i].getAsFile();
            const reader = new FileReader();

            reader.onload = (e) => {
              const imageUrl = e.target.result;
              const imageTag = `<img src="${imageUrl}" alt="Pasted Image" />`;
              editorInstance.insertHtml(imageTag);
            };

            reader.readAsDataURL(file);
            event.cancel(); // Prevent default paste behavior
            break; // Exit loop after processing the image
          }
        }
      });

      // Drag and drop event listener for images
      editorInstance.on('drop', (event) => {
        const dataTransfer = event.data.dataTransfer;
        const files = dataTransfer.$.files;

        for (let i = 0; i < files?.length; i++) {
          if (files[i].type.startsWith('image/')) {
            const reader = new FileReader();

            reader.onload = (e) => {
              const imageUrl = e.target.result;
              const imageTag = `<img src="${imageUrl}" alt="Dropped Image" />`;
              editorInstance.insertHtml(imageTag);
            };

            reader.readAsDataURL(files[i]);
            event.cancel(); // Prevent default drop behavior
            break;
          }
        }
      });
    }
  }, [editorRef.current]);

  return (
    <div className="App">
      <CkEditor
      setContent={setContent}
      props={props}
      content={content}
      />
      {/* <CKEditor
        id={props.id}
        name={props.name}
        activeClass="p10"
        data={content}
        initialValue={props.initialValue || ''}
        onChange={(e) => {
          const newContent = e.editor.getData();
          setContent(newContent);
          props.onEditorContentChange(newContent);
        }}
        onBeforeLoad={(CKEDITOR) => {
          // Adding the WIRIS plugin before CKEditor loads
          CKEDITOR.plugins.addExternal(
            'ckeditor_wiris',
            '/mathtype-ckeditor4/', // Adjust the path to where the WIRIS plugin is hosted in your project
            'plugin.js'
          );
        }}
        config={{
          extraPlugins: 'ckeditor_wiris,clipboard,uploadimage',
          removePlugins: 'easyimage,filetools',
          allowedContent: true,
          pasteFromWordRemoveFontStyles: false,
          pasteFromWordRemoveStyles: false,
          on: {
            instanceReady: function (event) {
              editorRef.current = event.editor;
            },
          },
        }}
        outputFormat={props.format || 'html'}
        disabled={!!props.isDisabled}
      /> */}
    </div>
  );
}


// import React, { useState, useEffect, useRef } from "react";
// import CKEditor from "ckeditor4-react";

// export default function TextEditor(props) {

//   const [content, setContent] = useState("");
//   const [clearTheContent, setClearTheContent] = useState(false);
//   const editorRef = useRef(null);


//     CKEditor.editorUrl =
//       "https://cdn.ckeditor.com/4.16.0/standard-all/ckeditor.js";


//   // useEffect(() => {
//   //   setContent("");
   
//   // }, [props.clearContent === true]);

//   // if (props.clearContent === true) {
//   //   setContent({ content: "" });
//   // }

//   // useEffect(() => {
//   //   if (props.initialValue !== content) {
//   //     setContent(props.initialValue)
//   //   };
//   // }, [props.initialValue]);

//   useEffect(() => {
//     setContent("");
//   }, [props.clearContent === true]);

//   useEffect(() => {
//     if (props.initialValue !== content) {
//       setContent(props.initialValue);
//     }
//   }, [props.initialValue]);


 
//   useEffect(() => {
//     // Code to run after each render (componentDidUpdate)
//     // You can perform data fetching or side effects here if needed
//   }, [content]); // Ensure to include dependencies if necessary  


//   // onEditorChange = (e) => {
//   //   console.log("onChange fired with event info: ", e);
//   //   var newContent = e.editor.getData();
//   //   setContent(newContent);
//   //   console.log('new value >> ', newContent);
//   //   // this.setState({
//   //   //   data: e.editor.getData(),
//   //   // });
//   // };

//   //   const onTextChange = (evt) => {
//   //   console.log("onChange fired with event info: ", evt);
//   //   var newContent = evt.editor.getData();
//   //   setContent(newContent);
//   //   console.log('new value >> ', newContent);
//   //   props.onEditorContentChange(newContent);
//   // };


//   return (

//     <div className="App">

//       <CKEditor
//           id={props.id}
//           name={props.name}
//           activeClass="p10"
//           data={content}
//           initialValue = {props.initialValue? props.initialValue : ""}
//           onChange={(e) => {
//                 console.log("onChange fired with event info: ", e);
//                 var newContent = e.editor.getData();
//                 setContent(newContent);
//                 console.log('new value >> ', newContent);
//                 props.onEditorContentChange(newContent);
//           }}
//           config={{
//             extraPlugins: "ckeditor_wiris",
//             removePlugins:
//               "filetools,uploadimage,uploadwidget,uploadfile,filebrowser,easyimage",
//             allowedContent: true,
//           }}
//           onBeforeLoad={(CKEDITOR) => {
//             CKEDITOR.plugins.addExternal(
//               "ckeditor_wiris",
//               "/mathtype-ckeditor4/",
//               "plugin.js"
//             );
//           }}
//           outputFormat={props.format ? props.format : 'html'}
//           disabled={props.isDisabled ? true : false}
//       />
//     </div>
//   )
// }

// divya code
// import React, { useState, useEffect, useRef } from "react";
// import CKEditor from "ckeditor4-react";

// export default function TextEditor(props) {

//   const [content, setContent] = useState("");
//   const [clearTheContent, setClearTheContent] = useState(false);
//   const editorRef = useRef(null);


//     CKEditor.editorUrl =
//       "https://cdn.ckeditor.com/4.16.0/standard-all/ckeditor.js";


//   // useEffect(() => {
//   //   setContent("");
   
//   // }, [props.clearContent === true]);

//   // if (props.clearContent === true) {
//   //   setContent({ content: "" });
//   // }

//   // useEffect(() => {
//   //   if (props.initialValue !== content) {
//   //     setContent(props.initialValue)
//   //   };
//   // }, [props.initialValue]);

//   useEffect(() => {
//     setContent("");
//   }, [props.clearContent === true]);

//   useEffect(() => {
//     if (props.initialValue !== content) {
//       setContent(props.initialValue);
//     }
//   }, [props.initialValue]);


 
//   useEffect(() => {
//     // Code to run after each render (componentDidUpdate)
//     // You can perform data fetching or side effects here if needed
//   }, [content]); // Ensure to include dependencies if necessary  


//   // onEditorChange = (e) => {
//   //   console.log("onChange fired with event info: ", e);
//   //   var newContent = e.editor.getData();
//   //   setContent(newContent);
//   //   console.log('new value >> ', newContent);
//   //   // this.setState({
//   //   //   data: e.editor.getData(),
//   //   // });
//   // };

//   //   const onTextChange = (evt) => {
//   //   console.log("onChange fired with event info: ", evt);
//   //   var newContent = evt.editor.getData();
//   //   setContent(newContent);
//   //   console.log('new value >> ', newContent);
//   //   props.onEditorContentChange(newContent);
//   // };


//   return (

//     <div className="App">

//       <CKEditor
//           id={props.id}
//           name={props.name}
//           activeClass="p10"
//           data={content}
//           initialValue = {props.initialValue? props.initialValue : ""}
//           onChange={(e) => {
//                 console.log("onChange fired with event info: ", e);
//                 var newContent = e.editor.getData();
//                 setContent(newContent);
//                 console.log('new value >> ', newContent);
//                 props.onEditorContentChange(newContent);
//           }}
//           config={{
//             extraPlugins: "ckeditor_wiris",
//             removePlugins:
//               "filetools,uploadimage,uploadwidget,uploadfile,filebrowser,easyimage",
//             allowedContent: true,
//           }}
//           onBeforeLoad={(CKEDITOR) => {
//             CKEDITOR.plugins.addExternal(
//               "ckeditor_wiris",
//               "/mathtype-ckeditor4/",
//               "plugin.js"
//             );
//           }}
//           outputFormat={props.format ? props.format : 'html'}
//           disabled={props.isDisabled ? true : false}
//       />
//     </div>
//   )
// }


// import React, { useState, useEffect, useRef } from "react";
// import { Editor } from "@tinymce/tinymce-react";
// import CKEditor from 'react-ckeditor-component';

// export default function TextEditor(props) {
//   const [content, setContent] = useState("");
//   const [clearTheContent, setClearTheContent] = useState(false);

//   const editorRef = useRef(null);

//   useEffect(() => {
//     setContent("");
//   }, [props.clearContent === true]);

//   if (props.clearContent === true) {
//     setContent({ content: "" });
//   }

//   useEffect(() => {
//     if (props.initialValue !== content) {
//       setContent(props.initialValue)
//     };
//   }, [props.initialValue]);

//   const onChange = (evt) => {
//     console.log("onChange fired with event info: ", evt);
//     var newContent = evt.editor.getData();
//     setContent(newContent);
//     console.log('new value >> ', newContent);
//     props.onEditorContentChange(newContent);
//   };

//   const onBlur = (evt) => {
//     console.log("onBlur event called with event info: ", evt);
//   };

//   const afterPaste = (evt) => {
//     console.log("afterPaste event called with event info: ", evt);
//   };

//   useEffect(() => {
//     // Code to run after each render (componentDidUpdate)
//     // You can perform data fetching or side effects here if needed
//   }, [content]); // Ensure to include dependencies if necessary

//   const handleMathTypeEvent = (event) => {
//     // Handle WIRIS/MathType event
//   };

//   return (

//     <div className="App">

//       <CKEditor
//         id={props.id}
//         name={props.name}
//         activeClass="p10"
//         content={content}
//         events={{
//           blur: onBlur,
//           afterPaste: afterPaste,
//           change: onChange
//         }}
//         config={{
//           extraPlugins: 'mathjax',
//           mathJaxLib: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js',
//           mathJaxClass: 'equation',
//         }}
//       />
//     </div>
//   )
// }