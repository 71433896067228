import React from 'react'
import ReactApexChart from "react-apexcharts";
import { Box } from "@mui/material";

export default function SemiCircleApexChart({ bgcolor, progressValue,height,width }) {
  const series = [progressValue];

  const options = {
    grid: {
        padding: {
            top: -20,
            bottom: -15
        }
    },
    chart: {
      height: 300,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: "50%",
        },
        dataLabels: {
          show: false,
        },

        startAngle: -110,
        endAngle: 110,
      },
    },
    fill: {
      type: "solid",
      colors: bgcolor,
    },

    stroke: {
      lineCap: "round",
    },
  };

  return (
    <Box
      sx={{
        height: height,
        width: width,
        position:'relative',
        paddingTop:'-30px'
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={170}
      />
      <div
        style={{
          position: "absolute",
          top: "30%",
          left: "0%",
          right: 0,
          fontSize: "14px",
          color: "#2D3962",
          whiteSpace:'pre-wrap',
          width:'100%',
          textAlign:'center'
        }}
      >
       <p style={{fontSize:'18px'}}>{series[0]}</p> 
       <p>{"%"}</p>
      </div>
     
    </Box>
  );
}
