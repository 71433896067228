import React, { useEffect } from "react";
import { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import "../../css/Student/Student_Enrich_Quiz_MTF_DD.css";
import "../../css/Student/ChapterQuizKey/ChapterQuizKey_Mtf_Dd.css";
import Student_QuestionView_Modal from "./Student_QuestionView_Modal";
import Student_Image_Open_Modal from "./Student_Image_Open_Modal";
import { Preview } from "@mui/icons-material";
import { Tooltip } from '@mui/material';
import { rightClickEnable } from "../../../../src/Serviceworker"
var loggedinuserrole = localStorage.getItem("role");

function Student_Enrich_Quiz_MTF_DD({
  currentQuestion,
  questions,
  setAttendMtfDd,
  attendMtfDd,
  setAttendedMtfDd,
  attendedMtfDd,
  resumeBtnClicked,
  list,
  ispreview,
  showExplanation
}) {
  const [droppedAnswer, setDroppedAnswer] = useState("");
  const [answerKey, setAnswerKey] = useState("");
  const [htmlanswerKey, setHtmlAnswerKey] = useState("");
  const [isViewModal, setViewModal] = useState(false);
  const [queston, setQuestion] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isAuthenticatedShift, setIsAuthenticatedShift] = useState(true);

  //console.log(questions[currentQuestion],ispreview,resumeBtnClicked, JSON.parse(sessionStorage.getItem("drag_drop_array")), JSON.parse(sessionStorage.getItem("drag_drop_array_final")),)

  const listData = (ispreview && resumeBtnClicked) ? list?.questionAnswerKeys[currentQuestion] : {};
  // console.log("listData", listData,);
  const handleDragStart = (e, answer, val, html) => {
    // ////console.log("handleDragStart", e, answer, val)
    e.dataTransfer.setData("text/plain", answer);
    setAnswerKey(String.fromCharCode(val));
    setHtmlAnswerKey(html)
  };

  const handleDragEnd = (e) => {
    // Do something after the drag is completed
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };


  // useEffect(() => {
  //   if (!ispreview && resumeBtnClicked) {
  //     console.log("resume functionality", droppedAnswer, "1111", attendedMtfDd)

  //     var formattedAnswers = {};
  //     questions[currentQuestion].mtfAnswerByStudent.forEach(item => {
  //       item.answers.forEach(answer => {
  //         // const match = data.matchTheFollowing.find(mtfItem => mtfItem.order === item.order && mtfItem.answers.includes(answer));
  //         // const match = questions[currentQuestion].matchTheFollowing.find(mtfItem => mtfItem.order === answer);
  //         // if (match) {
  //         //   const key = `${currentQuestion}-${item.order}`;
  //         //   formattedAnswers[key] = [match.jumbledAnswer];
  //         // }

  //         const match = questions[currentQuestion].matchTheFollowing.find(mtfItem => mtfItem.order === answer);
  //         if (match) {
  //           const key = `${currentQuestion}-${item.order}`;
  //           if (!formattedAnswers[key]) {
  //             formattedAnswers[key] = [];
  //           }
  //           formattedAnswers[key].push(match.jumbledAnswer);
  //         }
  //       });
  //     });

  //     console.log("formattedAnswers", formattedAnswers, questions[currentQuestion].mtfAnswerByStudent)

  //     setDroppedAnswer(formattedAnswers)

  //     var formattedAnswers = [];
  //     questions[currentQuestion].mtfAnswerByStudent.forEach(item => {
  //       const attendanceObj = {
  //         order: item.order,
  //         answers: item.answers,
  //         answersText: [],
  //         currentQuestion: currentQuestion,
  //         id: item.questionId
  //       };
  //       item.answers.forEach(answer => {
  //         const match = questions[currentQuestion].matchTheFollowing.find(mtfItem => mtfItem.order === answer);
  //         if (match) {
  //           attendanceObj.answersText.push(answer);
  //           attendanceObj.answersText.push(match.htmlTagQuestion);
  //           // attendanceObj.answersText.push(match.jumbledAnswer);
  //           // attendanceObj.answersText.push(match.htmlTagJumbled);
  //         }
  //       });
  //       formattedAnswers.push(attendanceObj);
  //     });
  //     console.log("formattedAnswers22222222222", formattedAnswers)

  //     // setDroppedAnswer({
  //     //   "2-B": [
  //     //     "bbbbbbbbbbbbbbbbbbb",
  //     //     "cccccccccccccccccccccccccc",
  //     //     "aaaaaaaaaaaaaaaaaaaaaaaa"
  //     //   ],
  //     //   "currentQuestion": 2,
  //     //   "id": "4028938b8c81d5de018cb4f5b3b900b8",
  //     //   "2-A": [
  //     //     "aaaaaaaaaaaaaaaaaaaaaaaa",
  //     //     "bbbbbbbbbbbbbbbbbbb",
  //     //     "cccccccccccccccccccccccccc"
  //     //   ],
  //     //   "2-C": [
  //     //     "cccccccccccccccccccccccccc",
  //     //     "aaaaaaaaaaaaaaaaaaaaaaaa"
  //     //   ]
  //     // })
  //   }
  // }, [ispreview])

  //====================================================================
  const handleDrop = (e, questionId, val) => {
    //console.log("e, questionId, val", e, questionId, val);
    e.preventDefault();


    const answer = e.dataTransfer.getData("text/plain");
    //console.log("attendMtfDd", attendMtfDd, "answer", answer, answerKey)
    if (questions[currentQuestion]?.clueType?.code === "EXACT_MATCH") {
      const existingIndex = attendMtfDd.findIndex(
        (itm) => String.fromCharCode(val) === itm.order
      );

      if (existingIndex !== -1) {
        // Update existing option
        attendMtfDd[existingIndex].answers[0] = answerKey;
        attendMtfDd[existingIndex].answersText[0] = answerKey;
        attendMtfDd[existingIndex].answersText[1] = htmlanswerKey;
        attendMtfDd[existingIndex].currentQuestion = currentQuestion;
        attendMtfDd[existingIndex].id = questions[currentQuestion].id || questions[currentQuestion].questionId
        attendedMtfDd.push(attendedMtfDd);
        //console.log(existingIndex, "existingindex", attendedMtfDd)
        setAttendedMtfDd(attendedMtfDd)
        //console.log("session storage drag_drop_array", attendMtfDd, [...attendedMtfDd, attendMtfDd])
        var getDD = JSON.parse(sessionStorage.getItem("drag_drop_array")) || [];
        //console.log("getDD", getDD)
        sessionStorage.setItem("drag_drop_array", JSON.stringify([...getDD, attendMtfDd]))
      } else {
        // Add new option
        const objOne = {
          order: String.fromCharCode(val),
          answers: [answerKey],
          answersText: [answerKey, htmlanswerKey],
          currentQuestion,
          id: questions[currentQuestion].id || questions[currentQuestion].questionId
        };
        setAttendMtfDd([...attendMtfDd, objOne]);
        //console.log("sessionstorage set drag_drop_array", [...attendMtfDd, objOne])
        var getDD = JSON.parse(sessionStorage.getItem("drag_drop_array")) || [];
        //console.log("getDD", getDD)
        sessionStorage.setItem("drag_drop_array", JSON.stringify([...getDD, objOne]))

      }
      //console.log("droppedAnswer", droppedAnswer)
      if (!droppedAnswer[questionId]) {
        //console.log("answerKey", answerKey)

        setDroppedAnswer((prevAnswers) => {
          const newAnswers = { ...prevAnswers };
          const objOne = {
            order: String.fromCharCode(val),
            answers: [answerKey],
            answersText: [answerKey, htmlanswerKey],
            answer: answer,
          };
          //console.log(objOne)
          newAnswers[questionId] = objOne;
          //console.log("newAnswers", newAnswers)
          var newAnswersV = newAnswers;
          newAnswersV.currentQuestion = currentQuestion;
          newAnswersV.id = questions[currentQuestion].id || questions[currentQuestion].questionId;
          var drag_drop_array_final = JSON.parse(sessionStorage.getItem("drag_drop_array_final")) || [];
          console.log("drag_drop_array_final", drag_drop_array_final)
          var ExIndex = drag_drop_array_final.findIndex(
            (e) => e.currentQuestion === currentQuestion
          )
          //console.log("ExIndex", ExIndex)
          if (ExIndex == -1) {
            drag_drop_array_final.push(newAnswersV)
          } else {
            drag_drop_array_final[ExIndex] = newAnswersV;
          }

          console.log("drag_drop_array_final", drag_drop_array_final, ExIndex)
          sessionStorage.setItem("drag_drop_array_final", JSON.stringify(drag_drop_array_final))

          console.log("setDroppedAnswer111111111", droppedAnswer, newAnswers)
          return newAnswers;
        });
      }
    } else {
      // Drag and Drop MULTI_ANS
      const existingIndex = attendMtfDd.findIndex(
        (itm) => String.fromCharCode(val) === itm.order
      );
      // //console.log("existingIndex", existingIndex)

      if (existingIndex !== -1) {
        // Update existing option
        attendMtfDd[existingIndex].answers.push(answerKey);
        attendMtfDd[existingIndex].answersText.push(answerKey);
        attendMtfDd[existingIndex].answersText.push(htmlanswerKey);
        attendMtfDd[existingIndex].answers.sort((a, b) => a - b);
        attendMtfDd[existingIndex].currentQuestion = currentQuestion;
        attendMtfDd[existingIndex].id = questions[currentQuestion].id || questions[currentQuestion].questionId
        //console.log("drag and drop session", [...attendedMtfDd, attendMtfDd]);
        var getDD = JSON.parse(sessionStorage.getItem("drag_drop_array")) || [];
        //console.log("getDD", getDD)
        sessionStorage.setItem("drag_drop_array", JSON.stringify([...getDD, attendMtfDd]))
      } else {
        const objOne = {
          order: String.fromCharCode(val),
          answers: [answerKey],
          answersText: [answerKey, htmlanswerKey],
          currentQuestion,
          id: questions[currentQuestion].id || questions[currentQuestion].questionId
        };
        setAttendMtfDd([...attendMtfDd, objOne]);
        //console.log("session storage drag and drop set", [...attendMtfDd, objOne]);
        var getDD = JSON.parse(sessionStorage.getItem("drag_drop_array")) || [];
        //console.log("getDD", getDD)
        sessionStorage.setItem("drag_drop_array", JSON.stringify([...getDD, objOne]))
      }
      //console.log("droppedAnswer", droppedAnswer,)
      console.log("setDroppedAnswer2222222", droppedAnswer, ispreview, resumeBtnClicked)
      setDroppedAnswer((prevAnswers) => {
        // //console.log("prevAnswers", prevAnswers)
        var newAnswers = { ...prevAnswers };
        newAnswers[questionId] = [...(prevAnswers[questionId] || [])];
        newAnswers[questionId] = newAnswers[questionId].concat(answer);
        //console.log("mst111 ", newAnswers);
        for (let key in newAnswers) {
          if (newAnswers.hasOwnProperty(key) && Array.isArray(newAnswers[key])) {
            let uniqueSet = new Set(newAnswers[key]);
            newAnswers[key] = Array.from(uniqueSet);
          }
        }
        var newAnswersV = newAnswers;
        newAnswersV.currentQuestion = currentQuestion;
        newAnswersV.id = questions[currentQuestion].id || questions[currentQuestion].questionId
        //console.log("mst ", newAnswers);
        var drag_drop_array_final = JSON.parse(sessionStorage.getItem("drag_drop_array_final")) || [];
        //console.log("drag_drop_array_final", drag_drop_array_final)
        var ExIndex = drag_drop_array_final.findIndex(
          (e) => e.currentQuestion === currentQuestion
        )
        //console.log("ExIndex", ExIndex)
        if (ExIndex == -1) {
          drag_drop_array_final.push(newAnswersV)
        } else {
          drag_drop_array_final[ExIndex] = newAnswersV;
        }

        console.log("drag_drop_array_final", drag_drop_array_final, ExIndex)
        sessionStorage.setItem("drag_drop_array_final", JSON.stringify(drag_drop_array_final))
        return newAnswers;
      });
    }
  };

  // function will call the ClassName Based on the Ans length
  function determineAnswerLengthClass(answer) {
    if (!answer) {
      return "";
    }

    const length = answer.length;
    if (length < 1) {
      return "AnsWithBtn--short";
    } else if (length < 2) {
      return "AnsWithBtn--medium";
    } else {
      return "AnsWithBtn--long";
    }
  }
  useEffect(() => {
    if (!Preview) {
      //console.log("useeffectt valled.")
      const handleContextMenu = (e) => {
        // if (!authContext.isAuthenticated) { this is to restrict right click based on loggedin info

        e.preventDefault();
        //console.log("handleContextMenu called")
      };
      if (rightClickEnable && isAuthenticatedShift) {
        document.addEventListener('contextmenu', handleContextMenu);

        document.addEventListener('keydown', function (event) {
          //console.log(event);
          // alert(event.getModifierState)
          const isModifierKey = event.getModifierState('Alt') ||
            event.getModifierState('Tab') ||
            event.getModifierState('Shift');

          if (event.key === 'F12' || isModifierKey) {
            //console.log("Modifier key pressed");
            event.preventDefault();
          } else if (event.key === "Enter" || event.key === " ") {
            //console.log(`${event.key} key pressed`);

            // Check if no modifier keys are pressed
            const noModifiers = !event.getModifierState('Alt') &&
              !event.getModifierState('Control') &&
              !event.getModifierState('Meta') &&
              !event.getModifierState('Shift');

            if (noModifiers) {
              //console.log("No modifier keys pressed");
              // Prevent the default behavior only for <button> elements
              if (event.target.tagName === 'BUTTON') {
                event.preventDefault();
                //console.log("Key event prevented for <button>");
              }
            }
          }
        });

        const handleBeforeUnload = (event) => {
          //console.log("close tab", event.type); // For reload and close tab
          const isRefresh = !event.currentTarget.performance.navigation.type;
          //console.log("isRefresh", isRefresh);

          if (event.type === 'beforeunload') {
            sessionStorage.removeItem("itemload");
            sessionStorage.removeItem("viewQuizData");
            sessionStorage.removeItem("practiceQuizAnswer");
            sessionStorage.removeItem("drag_drop_array");
            sessionStorage.removeItem("drag_drop_array_final");
            sessionStorage.removeItem("shuffledQuiz");
            sessionStorage.removeItem("alltheQuizAnswer");
            sessionStorage.removeItem("exid");
            sessionStorage.removeItem("exIdTwo");
            sessionStorage.removeItem("drag_drop_array_final")
            sessionStorage.removeItem("case_help");
            const message = "Are you sure you want to leave? Your progress may be lost.";
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
          } else {
            //console.log("else close tab");
            const message = "Are you sure you want to close the tab?";
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
          }
        }
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }
  }, [])
  useEffect(() => {
    if (!ispreview && !resumeBtnClicked) {
      questions[currentQuestion]?.matchTheFollowing?.length > 0 &&
        questions[currentQuestion]?.matchTheFollowing.map((v, index) => {
          const questionId = `${currentQuestion}-${v.order}`;
          if (droppedAnswer[questionId]) {
            Object.keys(droppedAnswer).forEach((questionId) => {
              const ques = droppedAnswer[questionId];
              const { answer, ...rest } = ques; // Destructure and exclude the "answer" property
              //console.log("rest", rest)
              attendMtfDd.push(rest);
            });
            // Sort the array based on the "order" property
            attendMtfDd.sort((a, b) => a.order.localeCompare(b.order));
          }
        });
      if (resumeBtnClicked) {
        console.log("setDroppedAnswer333333", droppedAnswer)
        setDroppedAnswer([])
        //commented on 16-march-2024 bcz resume no data need to repopulate
        ////console.log("attendMtfDd", attendMtfDd)
        questions[currentQuestion]?.mtfAnswerByStudent?.forEach((element, index) => {
          const questionId = `${currentQuestion}-${element.order}`;
          if (!droppedAnswer[questionId]) {
            attendMtfDd.push(element);
            const matchingMtfQuestion = questions[currentQuestion].matchTheFollowing.find(mtfQuestion => mtfQuestion.order === element.order);

            if (matchingMtfQuestion) {
              const matchedAnswer = matchingMtfQuestion.jumbledAnswer;
              // ////console.log(matchingMtfQuestion);
              setDroppedAnswer((prevAnswers) => {
                const newAnswers = { ...prevAnswers };
                const objOne = {
                  order: matchingMtfQuestion.order,
                  answers: matchingMtfQuestion.answers,
                  answer: matchedAnswer
                };
                newAnswers[questionId] = objOne;
                ////console.log("newAnswers", newAnswers)
                return newAnswers;
              });
            }
          }
        });
      }
    } else {
      if (resumeBtnClicked) {
        console.log("setDroppedAnswer333333", droppedAnswer)
        setDroppedAnswer([])
        if (!ispreview) {
          //commented on 16-march-2024 bcz resume no data need to repopulate
          ////console.log("attendMtfDd", attendMtfDd)
          questions[currentQuestion]?.mtfAnswerByStudent?.forEach((element, index) => {
            const questionId = `${currentQuestion}-${element.order}`;
            if (!droppedAnswer[questionId]) {
              attendMtfDd.push(element);
              const matchingMtfQuestion = questions[currentQuestion].matchTheFollowing.find(mtfQuestion => mtfQuestion.order === element.order);

              if (matchingMtfQuestion) {
                const matchedAnswer = matchingMtfQuestion.jumbledAnswer;
                // ////console.log(matchingMtfQuestion);
                setDroppedAnswer((prevAnswers) => {
                  const newAnswers = { ...prevAnswers };
                  const objOne = {
                    order: matchingMtfQuestion.order,
                    answers: matchingMtfQuestion.answers,
                    answer: matchedAnswer
                  };
                  newAnswers[questionId] = objOne;
                  ////console.log("newAnswers", newAnswers)
                  return newAnswers;
                });
              }
            }
          });
        }
      }
    }
  }, [currentQuestion]);
  // ////console.log(droppedAnswer, attendMtfDd);
  useEffect(() => {
    if (!ispreview) {
      const sessionDataFinal = JSON.parse(sessionStorage.getItem("drag_drop_array_final")) || [];
      const currentRecord = sessionDataFinal.find(x => x.currentQuestion === currentQuestion);
      console.log("sessionDataFinal", sessionDataFinal, currentRecord)
      const questionId = `${currentQuestion}-${"currentQuestion" + "A"}`;
      if (currentRecord) {
        //console.log(sessionDataFinal, currentRecord, attendMtfDd, droppedAnswer, "======",)
        setDroppedAnswer(currentRecord)
        console.log("setDroppedAnswer444444444", droppedAnswer, currentRecord)
      }
    }
  }, [currentQuestion])
  return (
    <>
      {
        ispreview ?
          (!resumeBtnClicked) ?
            <div className="MTF_DD_Div">
              <div className="MTF_DD_Qstn_And_Field_div">
                {/*------------------| Qstn And Field 1 |-----------------*/}
                {questions &&
                  questions[currentQuestion]?.options[0]?.length > 0 &&
                  questions[currentQuestion]?.options[0].map((v) => {
                    return (
                      <div className="MTF_DD_Fields_1">
                        <>
                          <div className="MTF_DD_Qstn_Field_1_B1">
                            <p className="para">
                              {v?.question.length > 100
                                ? v.question.slice(0, 40)
                                : v.question}
                              {v.question.length > 100 && (
                                <span style={{ color: "orange", fontSize: "small" }} onClick={() => {
                                  setViewModal(true);
                                  setQuestion(v.question);
                                }}>...seeMore</span>
                              )}
                            </p>
                          </div>
                          <div className="MTF_DD_Qstn_Field_1_B2">
                            {/* commented for teach not see */}
                            {/* <p className="para">
                              {v.answers.length > 0 && v?.answers.map((v, i) => {
                                return (
                                  <span>{v}</span>
                                )
                              })}
                            </p> */}
                          </div>
                        </>
                      </div>
                    );
                  })}
              </div>
              {/*------------------| MTF DD Ans IMages Div |-----------------*/}
              <div>
                <div className="MTF_DD_Ans">
                  {questions &&
                    questions[currentQuestion]?.options[0]?.length >
                    0 &&
                    questions[currentQuestion]?.options[0].map((v) => {
                      return (
                        <div className="MTF_DD_Ans_Field_1">
                          <p>
                            {v?.jumbledAnswer.length > 100
                              ? v.jumbledAnswer.slice(0, 35)
                              : v.jumbledAnswer}
                            {v.jumbledAnswer.length > 100 && (
                              <span style={{ color: "orange", fontSize: "small" }} onClick={() => {
                                setViewModal(true);
                                setQuestion(v.jumbledAnswer);
                              }}>...seeMore</span>
                            )}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div > :
            <>
              {/* <div>
                <h6 className="cqk_Fib_Head">DRAG & DROP : {list?.questionAnswerKeys[currentQuestion]?.clueType}</h6>
              </div> */}
              {
                console.log(list?.questionAnswerKeys[currentQuestion], "==================")
              }
              <div className="cqk_Fib_Main_Qstn">
                {list?.questionAnswerKeys[currentQuestion]?.question && <h3 id="az-qstn-dd" className="Paragraph_title">Question:-</h3>}
                <p
                  className="Paragraph"
                  dangerouslySetInnerHTML={{
                    __html: list?.questionAnswerKeys[currentQuestion]?.question,
                  }}
                ></p>
              </div>
              {
                console.log("list?.questionAnswerKeys[currentQuestion]", list?.questionAnswerKeys[currentQuestion])
              }
              {
                showExplanation &&
                <div className="cqk_Fib_Main_Qstn">
                  {list?.questionAnswerKeys[currentQuestion]?.explanation && <h3 id="az-qstn-dd" className="Paragraph_title">Explanation:-</h3>}
                  <p
                    className="Paragraph"
                    dangerouslySetInnerHTML={{
                      __html: list?.questionAnswerKeys[currentQuestion]?.explanation,
                    }}
                  ></p>
                </div>
              }
              <div className="cqk_MTF_dd_Box">
                {/* <p className="cqk_main_qstn">Question:-</p>
                <div className="cqk_qstn_img">
                  <p
                    className="cqk_main_qstn"
                    dangerouslySetInnerHTML={{
                      __html: list?.questionAnswerKeys[currentQuestion]?.question,
                    }}
                  ></p> */}

                {/* </div> */}
                <div className="cqk_MTF_dd_Div">
                  {listData.clueType === "EXACT_MATCH" ?
                    <div className="cqk_MTF_dd_Qstn_And_Field_div">
                      {/*------------------| Qstn And Field 1 |-----------------*/}
                      {
                        console.log("listData?.mtfAnswerByStudent", listData?.mtfAnswerByStudent)
                      }
                      {listData?.matchTheFollowing.map((itm, index) => {
                        const name = listData?.mtfAnswerByStudent
                          ? listData?.mtfAnswerByStudent?.some(
                            (item) => item?.order === itm?.order && item?.answers[0] === itm?.answers[0]
                          )
                          : false;
                        const dynamicId = `az-answer-preview-dd-${index}`;
                        return (
                          <div className="cqk_MTF_dd_Fields_1">
                            <div className="cqk_MTF_dd_Qstn_Field_1_B1">
                              <span>
                                {itm.question !== "" ? (
                                  itm.question
                                ) : (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: itm.htmlTagQuestion,
                                    }}
                                  ></p>
                                )}
                              </span>
                            </div>
                            <div className="cqk_MTF_dd_Qstn_Field_1_B2" id={dynamicId}>
                              {listData?.mtfAnswerByStudent &&
                                listData?.mtfAnswerByStudent[index] ? (
                                <p
                                  style={{
                                    backgroundColor: name === false ? "red" : "green",
                                    padding: "5px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {listData?.mtfAnswerByStudent[index].answers}
                                </p>
                              ) : (
                                <p className="cqk_dd_placeHolder">Drag &amp; Drop Here</p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    :

                    <div className="cqk_MTF_dd_Qstn_And_Field_div">
                      {/*------------------| Qstn And Field 1 |-----------------*/}
                      {listData?.matchTheFollowing.map((itm, index) => {
                        const name = listData?.mtfAnswerByStudent
                          ? listData?.mtfAnswerByStudent?.some(
                            (item) => item?.order === itm?.order && item?.answers[0] === itm?.answers[0]
                          )
                          : false;
                        const dynamicId = `az-answer-preview1-dd-${index}`;
                        return (
                          <div className="cqk_MTF_dd_Fields_1">
                            <div className="cqk_MTF_dd_Qstn_Field_1_B1">
                              <span>
                                {itm.question !== "" ? (
                                  itm.question
                                ) : (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: itm.htmlTagQuestion,
                                    }}
                                  ></p>
                                )}
                              </span>
                            </div>
                            <div className="cqk_MTF_dd_Qstn_Field_1_B2" id={dynamicId}>
                              {listData?.mtfAnswerByStudent &&
                                listData?.mtfAnswerByStudent[index] ? (
                                // <p
                                //   style={{
                                //     backgroundColor: name === false ? "red" : "green",
                                //     padding: "5px",
                                //     borderRadius: "5px",
                                //   }}
                                // >
                                //   {listData?.mtfAnswerByStudent[index].answers}
                                // </p>
                                <>
                                  {listData?.mtfAnswerByStudent[index]?.answers?.map((answer, i) => (
                                    // console.log(itm.answers, answer, "--------------low level", isHovered),
                                    <p
                                      key={i}
                                      style={{
                                        backgroundColor: itm?.answers?.includes(answer) ? "green" : "red",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        marginLeft: "5px"
                                      }}

                                    >
                                      {answer}
                                    </p>
                                  ))}
                                </>
                              ) : (
                                <p className="cqk_dd_placeHolder">Drag &amp; Drop Here</p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  }

                  {/*------------------| MTF DD Ans Div |-----------------*/}
                  {listData.clueType === "EXACT_MATCH" ?
                    <div className="cqk_MTF_dd_Ans">
                      {listData?.matchTheFollowing.map((itm, index) => {
                        const dynamicId = `az-answer-preview1-dd-${index}`;
                        return (
                          <div className="cqk_MTF_dd_Ans_Field_1" id={dynamicId}>
                            <span>
                              {itm.jumbledAnswer !== "" ? (
                                itm.jumbledAnswer
                              ) : (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: itm.htmlTagJumbled,
                                  }}
                                ></p>

                              )}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                    :
                    <div className="cqk_MTF_dd_Ans">
                      {listData?.matchTheFollowing.map((itm, index) => {
                        const dynamicId = `az-answer-preview2-dd-${index}`;
                        return (
                          <div className="cqk_MTF_dd_Ans_Field_1" id={dynamicId}>
                            <span>
                              {itm.jumbledAnswer !== "" ? (
                                itm.jumbledAnswer
                              ) : (
                                // <p
                                //   dangerouslySetInnerHTML={{
                                //     __html: itm.htmlTagJumbled,
                                //   }}
                                // ></p>
                                <div className="container">
                                  {/* <div className="content" title={itm.htmlTagJumbled}>
                                    {`${itm.htmlTagJumbled.slice(0, 30)}...`}
                                  </div> */}
                                  <div className="content" title={itm?.jumbledAnswer}
                                    dangerouslySetInnerHTML={{ __html: `${itm?.htmlTagJumbled.slice(0, 30)}...` }}
                                  />
                                </div>
                              )}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  }
                </div>
              </div>
            </> :
          <>
            <div className="MTF_dd_Box">
              {questions[currentQuestion]?.question && <h3 id="az-dd-qstn-text" className="Paragraph_title">Question:-</h3>}
              <div className="qstn_img">
                <p
                  id="az-dd-qstn"
                  className="Paragraph"
                  dangerouslySetInnerHTML={{
                    __html: questions[currentQuestion]?.question,
                  }}
                ></p>
                {/* {
                  console.log(attendedMtfDd, "attendedMtfDd", attendMtfDd, droppedAnswer, questions[currentQuestion])
                } */}
                {questions && questions[currentQuestion]?.imageUrl && (loggedinuserrole !== "STUDENT") && (
                  <div>
                    <img
                      src={questions[currentQuestion]?.imageUrl}
                      className="MTF_dd_img"
                      alt="Image"
                      onClick={() => {
                        setSelectedImageUrl(questions[currentQuestion]?.imageUrl);
                        setModalOpen(true);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="MTF_dd_Div">
                {
                  //console.log("questions", questions, droppedAnswer)
                }
                <div className="MTF_dd_Qstn_And_Field_div">
                  {/*------------------| Qstn And Field 1 |-----------------*/}
                  {!resumeBtnClicked ? questions &&
                    questions[currentQuestion]?.options[0]?.length > 0 &&
                    questions[currentQuestion]?.options[0]?.map((v, index) => {
                      const questionId = `${currentQuestion}-${v.order}`;
                      const val = 65 + index;

                      const handleCancel = (clueType, ind) => {
                        //console.log("handleCancel called", clueType, ind)
                        // Remove the dropped answer
                        //console.log(JSON.parse(sessionStorage.getItem("drag_drop_array_final")), currentQuestion)
                        if (clueType === "Exact") {
                          const updatedDroppedAnswer = { ...droppedAnswer };
                          //console.log(updatedDroppedAnswer[questionId])
                          delete updatedDroppedAnswer[questionId];
                          //console.log(updatedDroppedAnswer)
                          console.log("setDroppedAnswer5555555", droppedAnswer, updatedDroppedAnswer)
                          setDroppedAnswer(updatedDroppedAnswer);
                          var drag_drop_array_final = JSON.parse(sessionStorage.getItem("drag_drop_array_final")) || [];
                          //console.log("drag_drop_array_final", drag_drop_array_final)
                          var ExIndex = drag_drop_array_final.findIndex(
                            (e) => e.currentQuestion === currentQuestion
                          );
                          //console.log("ExIndex", ExIndex, updatedDroppedAnswer);
                          drag_drop_array_final[ExIndex] = updatedDroppedAnswer;
                          console.log("drag_drop_array_final", drag_drop_array_final, ExIndex)
                          sessionStorage.setItem("drag_drop_array_final", JSON.stringify(drag_drop_array_final))
                          const updatedAttendMtfDd = attendMtfDd.filter((item) => item.order !== String.fromCharCode(val));
                          //console.log("updatedAttendMtfDd", updatedAttendMtfDd)
                          setAttendMtfDd(updatedAttendMtfDd);
                        } else {
                          const updatedDroppedAnswer = { ...droppedAnswer };
                          //console.log("updatedDroppedAnswer", updatedDroppedAnswer, ind)
                          if (updatedDroppedAnswer[questionId] && Array.isArray(updatedDroppedAnswer[questionId])) {
                            updatedDroppedAnswer[questionId].splice(ind, 1);
                          } else {
                            ////console.log("else executed");
                            updatedDroppedAnswer[questionId].answers.splice(ind, 1);
                          }
                          //console.log("updatedDroppedAnswer[questionId]", updatedDroppedAnswer, currentQuestion)
                          var drag_drop_array_final = JSON.parse(sessionStorage.getItem("drag_drop_array_final")) || [];
                          //console.log("drag_drop_array_final", drag_drop_array_final)
                          var ExIndex = drag_drop_array_final.findIndex(
                            (e) => e.currentQuestion === currentQuestion
                          )
                          //console.log("ExIndex", ExIndex)
                          drag_drop_array_final[ExIndex] = updatedDroppedAnswer;
                          //console.log("drag_drop_array_final", drag_drop_array_final, ExIndex)
                          sessionStorage.setItem("drag_drop_array_final", JSON.stringify(drag_drop_array_final))
                          //console.log("updatedDroppedAnswer", updatedDroppedAnswer)
                          setDroppedAnswer(updatedDroppedAnswer);
                          console.log("setDroppedAnswer66666666", droppedAnswer, updatedDroppedAnswer)

                          const updatedAttendMtfDd = attendMtfDd.map((item) => {
                            if (item.order === String.fromCharCode(val)) {
                              item.answers = item.answers.filter((ans) => ans !== answerKey);
                            }
                            return item;
                          });
                          //console.log("updatedAttendMtfDd", updatedAttendMtfDd)
                          setAttendMtfDd(updatedAttendMtfDd);
                        }
                      };

                      console.log(droppedAnswer, "======3333",)
                      const answer = droppedAnswer[questionId];
                      const answerLengthClass = determineAnswerLengthClass(answer);
                      const dynamicId = `az-answer-dd1-${index}`;
                      return (
                        <div className="MTF_dd_Fields_1" key={questionId} id={dynamicId}>
                          <div className="MTF_dd_Qstn_Field_1_B1">
                            <p>
                              {v?.question.length > 100
                                ? v.question.slice(0, 40)
                                : v.question}
                              {v.question.length > 100 && (
                                <span
                                  style={{
                                    color: "orange",
                                    fontSize: "1vw",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setViewModal(true);
                                    setQuestion(v.question && v.htmlTagQuestion);
                                  }}
                                >
                                  ...seeMore
                                </span>
                              )}
                            </p>
                          </div>
                          <div
                            // key={index}
                            className="MTF_dd_Qstn_Field_1_B2"
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, questionId, val)}
                          >
                            {/* {droppedAnswer[questionId] ? (
                         <div className="AnsWithBtn">
                           <p>{droppedAnswer[questionId]}</p>
                           <IconButton
                             onClick={handleCancel}
                             style={{
                               color: "#FD8C00",
                             }}
                           >
                             <CancelIcon
                               style={{
                                 width: "20px",
                                 height: "20px",
                               }}
                             />
                           </IconButton>
                         </div>
                       ) : (
                         <p>Drag &amp; Drop Here</p>
                       )} */}
                            {/* {console.log(" here")} */}

                            {droppedAnswer[questionId] ? (
                              questions[currentQuestion]?.clueType.code ===
                                "EXACT_MATCH" ? (<Tooltip title={droppedAnswer[questionId]?.answer} placement={"bottom"}>
                                  <div className={`AnsWithBtn ${answerLengthClass}`} id="az-dd-answer">
                                    {/* <p>{droppedAnswer[questionId].answer}3333333</p> */}
                                    {droppedAnswer[questionId]?.answersText[1] ? (
                                      <div style={{
                                        height: '55px', overflow: 'hidden', cursor: "pointer", display: "flex", alignItems: "center",
                                        justifyContent: "center", paddingLeft: "10px"
                                      }} dangerouslySetInnerHTML={{ __html: droppedAnswer[questionId]?.answersText[1] }} />
                                    ) : (
                                      <p style={{ height: "55px", overflow: "hidden" }}>{droppedAnswer[questionId]?.answer}</p>
                                    )}
                                    <IconButton
                                      onClick={() => {
                                        handleCancel("Exact");
                                      }}
                                      style={{ color: "#FD8C00" }}
                                    >
                                      <CancelIcon className="ans_Cancel_Btn" />
                                    </IconButton>
                                  </div></Tooltip>
                              ) : (
                                droppedAnswer[questionId] &&
                                droppedAnswer[questionId]?.map((dropAnswer, index) => (
                                  // console.log("dropAnswer herer", dropAnswer),
                                  <div
                                    className={`AnsWithBtn ${answerLengthClass}`}
                                    key={index}
                                    id={"az-answer-dd2" + index}
                                  >

                                    {/* <p>{dropAnswer}</p>
                                */}
                                    {questions[currentQuestion].matchTheFollowing && questions[currentQuestion].matchTheFollowing.length > 0 ?
                                      questions[currentQuestion].matchTheFollowing.filter(person => person.jumbledAnswer === dropAnswer)
                                        .map(filteredPerson => (
                                          // <div dangerouslySetInnerHTML={{ __html: filteredPerson.htmlTagJumbled }} />
                                          <div className="container">
                                            <div className="content az-content-mtf" title={filteredPerson?.jumbledAnswer}
                                              dangerouslySetInnerHTML={{ __html: `${filteredPerson?.htmlTagJumbled.slice(0, 30)}` }}
                                            />
                                          </div>
                                        ))
                                      :
                                      // <p>{dropAnswer}</p>
                                      <>
                                        {questions[currentQuestion].options[0].filter(person => person.jumbledAnswer === dropAnswer)
                                          .map(filteredPerson => (
                                            // <div dangerouslySetInnerHTML={{ __html: filteredPerson.htmlTagJumbled.slice(0, 30) }} />
                                            // <div className="container">
                                            //   <div className="content" title={filteredPerson.htmlTagJumbled}>
                                            //     {`${filteredPerson.htmlTagJumbled.slice(0, 30)}...`}
                                            //   </div>
                                            // </div>

                                            <div className="container">
                                              <div className="content az-content-mtf" title={filteredPerson?.jumbledAnswer}
                                                dangerouslySetInnerHTML={{ __html: `${filteredPerson?.htmlTagJumbled.slice(0, 30)}` }}
                                              />
                                            </div>
                                          ))
                                        }</>
                                    }

                                    <IconButton
                                      onClick={() => {
                                        handleCancel("Multi", index);
                                      }}
                                      style={{ color: "#FD8C00" }}
                                    >
                                      <CancelIcon className="ans_Cancel_Btn" />
                                    </IconButton>
                                  </div>
                                ))
                              )
                            ) : (
                              <p className="dd_placeHolder">Drag &amp; Drop Here</p>
                            )}
                          </div>
                        </div>
                      );
                    })
                    :
                    questions[currentQuestion]?.matchTheFollowing?.length > 0 &&
                    questions[currentQuestion]?.matchTheFollowing.map((v, index) => {
                      const questionId = `${currentQuestion}-${v.order}`;
                      const val = 65 + index;

                      const handleCancel = (clueType, ind) => {
                        //console.log("handlecancel called", clueType, ind)
                        // Remove the dropped answer
                        if (clueType === "Exact") {
                          ////console.log({ ...droppedAnswer }, "exact")
                          const updatedDroppedAnswer = { ...droppedAnswer };
                          delete updatedDroppedAnswer[questionId];
                          //console.log("updatedDroppedAnswer", updatedDroppedAnswer)
                          setDroppedAnswer(updatedDroppedAnswer);
                          // console.log("setDroppedAnswer77777777", droppedAnswer, updatedDroppedAnswer)
                          const findInd = attendMtfDd.findIndex(itm => itm.order === v.order)
                          attendMtfDd.splice(findInd, 1);
                          //console.log("attendMtfDd", attendMtfDd)
                        } else {
                          attendMtfDd.map((val, i) => {
                            if (index === i) {
                              if (
                                Array.isArray(attendMtfDd[i].answers) &&
                                attendMtfDd[i].answers.length === 1
                              ) {
                                attendMtfDd.splice(i, 1);
                              } else {
                                attendMtfDd[i].answers.splice(ind, 1);
                              }
                            }
                          });
                          const updatedDroppedAnswer = { ...droppedAnswer };
                          updatedDroppedAnswer[questionId].splice(ind, 1);
                          //console.log("updatedDroppedAnswer", updatedDroppedAnswer, attendMtfDd)
                          setDroppedAnswer(updatedDroppedAnswer);
                          // console.log("setDroppedAnswer888888888", droppedAnswer, updatedDroppedAnswer)
                        }
                      };
                      const dynamicId = `az-answer-dd3-${index}`;
                      const answer = droppedAnswer[questionId];
                      const answerLengthClass = determineAnswerLengthClass(answer);

                      return (
                        <div className="MTF_dd_Fields_1" key={questionId}>
                          <div className="MTF_dd_Qstn_Field_1_B1">
                            <p id={dynamicId}>
                              {v?.question.length > 100
                                ? v.question.slice(0, 40)
                                : v.question}
                              {v.question.length > 100 && (
                                <span
                                  style={{
                                    color: "orange",
                                    fontSize: "1vw",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setViewModal(true);
                                    setQuestion(v.question && v.htmlTagQuestion);
                                  }}
                                >
                                  ...seeMore
                                </span>
                              )}
                            </p>
                          </div>
                          <div
                            // key={index}
                            className="MTF_dd_Qstn_Field_1_B2"
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, questionId, val)}
                          >
                            {/* {droppedAnswer[questionId] ? (
                         <div className="AnsWithBtn">
                           <p>{droppedAnswer[questionId]}</p>
                           <IconButton
                             onClick={handleCancel}
                             style={{
                               color: "#FD8C00",
                             }}
                           >
                             <CancelIcon
                               style={{
                                 width: "20px",
                                 height: "20px",
                               }}
                             />
                           </IconButton>
                         </div>
                       ) : (
                         <p>Drag &amp; Drop Here</p>
                       )} */}
                            {
                              console.log("questions[currentQuestion]", droppedAnswer, questionId, questions, currentQuestion)
                            }
                            {droppedAnswer[questionId] ? (
                              questions[currentQuestion]?.clueType ===
                                "EXACT_MATCH" ? (
                                <div className={`AnsWithBtn ${answerLengthClass}`} id="az-answer-dd4">
                                  {
                                    console.log(droppedAnswer[questionId], questions[currentQuestion], "swetha123",)
                                  }
                                  <p>{droppedAnswer[questionId].answer}</p>
                                  {questions[currentQuestion].matchTheFollowing.filter(person => person.jumbledAnswer === droppedAnswer[questionId][0])//dropAnswer
                                    .map(filteredPerson => (
                                      // <div dangerouslySetInnerHTML={{ __html: filteredPerson.htmlTagJumbled }} />
                                      // <div className="content" title={filteredPerson?.jumbledAnswer}
                                      //       dangerouslySetInnerHTML={{ __html: `${filteredPerson.htmlTagJumbled.slice(0, 30)}...` }}
                                      //     />
                                      <div className="container">
                                        <div className="content" title={filteredPerson?.jumbledAnswer}>
                                          {`${filteredPerson?.htmlTagJumbled.slice(0, 30)}...`}
                                        </div>
                                      </div>
                                    ))
                                  }
                                  <IconButton
                                    onClick={() => {
                                      handleCancel("Exact");
                                    }}
                                    style={{ color: "#FD8C00" }}
                                  >
                                    <CancelIcon className="ans_Cancel_Btn" />
                                  </IconButton>
                                </div>
                              ) : (
                                droppedAnswer[questionId] &&
                                // droppedAnswer[questionId].map((dropAnswer, index) => (/i comment bcz of error
                                // ////console.log("dropanswer  ", dropAnswer),
                                <div
                                  className={`AnsWithBtn ${answerLengthClass}`}
                                  id={`az-answer-dd5 ${answerLengthClass}`}

                                  key={index}
                                >
                                  {/* {
                                    console.log("droppedAnswer[questionId]", droppedAnswer[questionId], questions[currentQuestion])
                                  } */}
                                  {/* <p>{droppedAnswer[questionId].answer}</p> */}
                                  {questions[currentQuestion].matchTheFollowing.filter(person => person.jumbledAnswer === droppedAnswer[questionId].answer)//dropAnswer
                                    .map(filteredPerson => (
                                      // <div dangerouslySetInnerHTML={{ __html: filteredPerson.htmlTagJumbled }} />



                                      < div className="container" >
                                        {/* <div className="content" title={filteredPerson?.jumbledAnswer}>
                                          {`${filteredPerson.htmlTagJumbled.slice(0, 30)}...`}
                                        </div> */}
                                        < div className="content" title={filteredPerson?.jumbledAnswer}
                                          dangerouslySetInnerHTML={{ __html: `${filteredPerson?.htmlTagJumbled.slice(0, 30)}...` }}
                                        />
                                        {/* <p>swe2</p> */}
                                      </div>
                                    ))
                                  }
                                  {/* {
                               ////console.log("************",questions[currentQuestion].matchTheFollowing,droppedAnswer)
                             } */}
                                  <IconButton
                                    onClick={() => {
                                      handleCancel("Multi", index);
                                    }}
                                    style={{ color: "#FD8C00" }}
                                  >
                                    <CancelIcon className="ans_Cancel_Btn" />
                                  </IconButton>
                                </div>
                                // ))
                              )
                            ) : (
                              <p className="dd_placeHolder">Drag &amp; Drop Here</p>
                            )}
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
                {/*------------------| MTF DD Ans IMages Div |-----------------*/}
                <div className="MTF_dd_Ans">
                  {!resumeBtnClicked
                    ? questions &&
                    questions[currentQuestion]?.options[0]?.length > 0 &&
                    questions[currentQuestion]?.options[0]?.map((v, index) => {
                      const questionId = `${currentQuestion}-${v.order}`;
                      const val = 65 + index;
                      console.log("droppedAnswer", droppedAnswer)
                      const isDragged = Object.values(droppedAnswer).some(
                        item => (item.answerUrl ? item.answerUrl === v.jumbledAnswerUrl : item.answer === v.jumbledAnswer)
                      );
                      const dynamicId = `az-dd-${index}`;
                      return (
                        <div
                          className={`MTF_dd_Ans_Field_1 ${isDragged ? "AnswerSelected" : ""
                            }`}
                          id={dynamicId}
                          draggable={!isDragged}
                          onDragStart={(e) =>
                            handleDragStart(e, v.jumbledAnswer, val, v.htmlTagJumbled)
                          }
                          onDragEnd={handleDragEnd}
                          key={questionId}
                        >
                          {/* <p>{v.jumbledAnswer}</p> */}
                          <div dangerouslySetInnerHTML={{ __html: v.htmlTagJumbled }} />
                          {/* <p>swe1</p> */}
                          {/* <div className="container">
                            <div className="content" title={v?.jumbledAnswer} dangerouslySetInnerHTML={{ __html: `${v?.htmlTagJumbled.slice(0, 30)}...` }} />
                          </div> */}
                        </div>
                      );
                    })
                    : questions[currentQuestion]?.matchTheFollowing?.length > 0 &&
                    questions[currentQuestion]?.matchTheFollowing.map(
                      (v, index) => {
                        // console.log(v, "-==========================================")
                        const questionId = `${currentQuestion}-${v.order}`;
                        const val = 65 + index;
                        // console.log("droppedAnswer", droppedAnswer)
                        const isDragged = Object.values(droppedAnswer).some(
                          item => (item.answerUrl ? item.answerUrl === v.jumbledAnswerUrl : item.answer === v.jumbledAnswer)
                        );
                        const dynamicId = `az-jumbled-answer-dd-${index}`;
                        return (
                          <div
                            className={`MTF_dd_Ans_Field_1 ${isDragged ? "AnswerSelected" : ""
                              }`}
                            id={dynamicId}
                            draggable={!isDragged}
                            onDragStart={(e) =>
                              handleDragStart(e, v.jumbledAnswer, val, v.htmlTagJumbled)
                            }
                            onDragEnd={handleDragEnd}
                            key={questionId}
                          >
                            {/* <p>{v.jumbledAnswer}</p> */}
                            <div dangerouslySetInnerHTML={{ __html: v.htmlTagJumbled }} />
                            {/* <p>swe</p> */}
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
            <Student_QuestionView_Modal
              open={isViewModal}
              deleteName={queston}
              close={() => {
                setViewModal(false);
              }}
            />
            <Student_Image_Open_Modal
              selectedImageUrl={selectedImageUrl}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
            />
          </>
      }
    </>
  );
}
export default Student_Enrich_Quiz_MTF_DD;
