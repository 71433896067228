import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "../../Navbar";
import MainContainer from "./MainContainer";
import DashboardContent from "./DashboardContent";

const PrincipalModuleDashboard = () => {
	//added by sg
	const [headerTitle, setHeaderTitle] = useState("");
	const handleHeaderTitle = (value) => {
		setHeaderTitle(value);
	};
	return (
	<DashboardContent />
	);
};
export default PrincipalModuleDashboard;
