import axios from "axios";
import { isNOTNullOrUndefined } from "../../../../utils/helper";

export const createHomeWorkAssignment = async (data) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/home-assignment`;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(url, data, options);
    return res?.data || [];
  } catch (error) {
    return error.response.data;
  }
};

export const fetchHomeWorkAssignmentListView = async (
  pageNumber,
  pageSize,
  boardId,
  gradeId,
  schoolId,
  branchId,
  subjectId,
  sectionId,
  subTopicId,
  sortBy,
  sortOrder,
  chapterId
) => {
  const token = sessionStorage.getItem("token");
  let url = `${
    process.env.REACT_APP_SWAGGER_URL
  }v1/api/teacher/home-assignment?pageNumber=${pageNumber}&pageSize=${pageSize}&order=${sortOrder}&sortBy=${sortBy}${
    isNOTNullOrUndefined(boardId) ? `&boardId=${boardId}` : ""
  }${isNOTNullOrUndefined(schoolId) ? `&schoolId=${schoolId}` : ""}${
    isNOTNullOrUndefined(gradeId) ? `&gradeId=${gradeId}` : ""
  }${isNOTNullOrUndefined(branchId) ? `&branchId=${branchId}` : ""}${
    isNOTNullOrUndefined(subjectId) ? `&subjectId=${subjectId}` : ""
  }${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ""}${
    isNOTNullOrUndefined(sectionId) ? `&sectionId=${sectionId}` : ""
  }${isNOTNullOrUndefined(chapterId) ? `&chapterId=${chapterId}` : ""}`;
  try {
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data?.data || [];
  } catch {
    return [];
  }
};

export const toggleActiveStatus = async (isActive, id) => {
  const token = sessionStorage.getItem("token");

  let data = {
    id: id,
    active: isActive,
  };

  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/home-assignment/toggle`;
  const res = await axios.put(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const deleteHomeWork = async (id) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/home-assignment/${id}`;

  const res = await axios.delete(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};
