import React, { useEffect, useState } from "react";
import "./PrincipalDashboardNew.css";

import {
  extractTextAfterExclamation,
  extractTextIncludingExclamation,
  isNOTNullOrUndefined,
  truncateString,
} from "../../../../utils/helper";
import ApexSemiCircleProgress from "../../Teacher/Dashboard/Components/ApexSemiCircleProgress";
import ApexSemiCircleProgressV2 from "./ApexSemiCircleProgressv2";
import { Tooltip } from "@mui/material";

const PrincipalCardV2 = ({ color, teacher, heading, boxClass, progressValue, message }) => {
  console.log("progressValue123",progressValue);
  return (
    <div className="principal-card" style={{ position: 'relative' }}>
      {isNOTNullOrUndefined(teacher) ?
        <Tooltip title={teacher}>
          <p className="card-text-teacher">{`(Teacher- ${truncateString(teacher,8)})`}</p>
        </Tooltip>
        : null}
      <div>
        <label className="card-heading">{heading}</label>
        <div className="progress-wrapper">
          <div className="circle-wrapper">
            <ApexSemiCircleProgressV2
              bgcolor={color}
              progressValue={progressValue}
            />
            {progressValue[0] && progressValue[1] !== 0 ? (
            <div className="text-style">
              <div className={boxClass}></div>
              Global %
            </div>
            ):(
              ""
            )}
          </div>
          <div >

            <div>
              <p className="card-sub-head-prncpl">{extractTextIncludingExclamation(message)}</p>
            </div>
            <div >
              <p className="card-text-prncpl">
                {extractTextAfterExclamation(message)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrincipalCardV2;
