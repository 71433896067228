
import { SELECTED_ASSIGN_QUIZ_PARAMS, CLEAR_ASSIGN_QUIZ_PARAMS, SET_SELECTED_ASSIGN_QUIZ_PARAMS } from "../actions/assignQuizAction";


const initialState = {
    assignQuizParams: {},
    selectedData: {}
}

export const assignQuizReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECTED_ASSIGN_QUIZ_PARAMS:
            return {
                ...state,
                assignQuizParams: action.payload,
            }
        case SET_SELECTED_ASSIGN_QUIZ_PARAMS:
            return {
                ...state,
                selectedData: action.payload,
            }
        case CLEAR_ASSIGN_QUIZ_PARAMS:
            return {
                ...state,
                assignQuizParams: action.payload,
                selectedData: action.payload,
            }
        default:
            return state
    }
}
