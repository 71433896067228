import { Box, Modal, TextField, Typography } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import React from "react";
import YellowButton from "../../../components/common/yellowButton";
import HoverGreyCancelButton from "../../../components/common/HoverGreyCancelButton";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};
const AddPlanNameModal = ({
  open,
  close,
  addHandler,
  planName,
  setPlanName,
  isEdit,
  editHandler,
}) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CheckCircleOutlinedIcon
              style={{ color: "#FFA700", fontSize: 130 }}
            />
          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                rowGap: "2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  rowGap: "1.25rem",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {`You have successfully ${ isEdit ? 'updated' : 'added'} plan`}
                </div>
                <TextField
                  id="demo-helper-text-aligned-no-helper"
                  label="*Plan Template Name"
                  value={planName}
                  onChange={(e) => setPlanName(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  columnGap: "1rem",
                }}
              >
                <HoverGreyCancelButton onClick={close}>
                  Cancel
                </HoverGreyCancelButton>
                <YellowButton
                disabled={planName.length > 0 ? false: true}
                  onClick={() => (isEdit ? editHandler() : addHandler())}
                >
                  Save
                </YellowButton>
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default AddPlanNameModal;
