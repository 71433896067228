import axios from 'axios'
import commonsort from "../../js/CommonSort"

async function TeacherControllerGet(
  setTeacherData,
  setPageDetail,
  page,
  rowsInput,
  search,
  sortBy, sortOrder,
) {
  let token = sessionStorage.getItem("token");
  let schoolid = sessionStorage.getItem("schoolid");
  let branchid = sessionStorage.getItem("branchid");

  let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers?pageNumber=${page - 1}&pageSize=${rowsInput.rows
    }&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search.search
    }&schoolId=${schoolid}&branchId=${branchid}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (json) => {
      // var sortedData = await commonsort(json.data.data.data || [], sortBy, sortOrder);
      // json.data.data.data = sortedData
      setTeacherData(json.data.data.data);
      setPageDetail(json.data.data)
    })
    .catch(err => console.log(err))
}

export default TeacherControllerGet;
