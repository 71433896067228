import {
    SET_INITIAL_SELECTED_VALUE,
  } from "../actions/dashboardTableAction";
  
  
  const initialState = {
    is_selected_data: {},
   
  }
  
  export const dashboardTableReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_INITIAL_SELECTED_VALUE:
        console.log(action.payload,"action.payload")
        return {
          ...state,
          is_selected_data: {...action.payload}
        }
  
    
      default:
        return state
    }
  }
  