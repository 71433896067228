import React, { useState, useEffect, memo } from "react";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import getSubQuestions from "../../../API_CONTROLLER/create-questions/subQuestions_get";
import bubble_img from "../../../../../src/img/BB.svg";
import { blue, orange } from "@mui/material/colors";
import Radio from '@mui/material/Radio';
import Checkbox from "@mui/material/Checkbox";

import {
    FormControl,
    Grid,
    Box,
    Modal,
    styled,
    Button,
    Typography,
    TextField
} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '58%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "40vw",
    overflow: "scroll",
    bgcolor: '#f8f4fc',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const CustomTextField = styled(TextField)(({ }) => ({
    width: "96%",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "2px !important",
    "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
        opacity: "1 !important",
        background: "rgb(255 255 255 / 31%) !important",
        border: "1px solid #B5B6B6",
        borderRadius: "2px !important",
    }
}))

const CustomPagination = styled(Pagination)(({ }) => ({
    width: '100%',
    ".MuiPaginationItem-root": {
        backgroundColor: "#00ce95",
        color: "#fff",
        fontFamily: "Poppins, sans-serif",
    },
    ".MuiPaginationItem-root.Mui-selected ": {
        backgroundColor: "#0da87e",
        color: "#fff"
    }
}))

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const CustomRadioIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    color: orange[600],
    boxShadow: 'inset 0 0 0 3px orange, inset 0 -1px 0 orange',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto orange',
        color: orange[600],
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
    backgroundColor: orange[600],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: orange[800],
    },
});

function CustomRadio(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<CustomRadioCheckedIcon />}
            icon={<CustomRadioIcon />}
            {...props}
        />
    );
}

function SubQuestionPreview(props) {
    console.log("SubQuestionPreview")
    const [subQuestions, setSubQuestions] = useState([]);
    const [addQuestionCount, setAddQuestionCount] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    const title = sessionStorage.getItem("caseTitle");

    useEffect(() => {
        getSubQuestions(setSubQuestions);
        setPageNumber(0);
    }, [props.openSubQuestionPreview]);

    const onPageChange = (event, page) => {
        setPageNumber(page - 1);
    }

    useEffect(() => {
        setSubQuestions([]);
    }, [props.isCaseStudyReset]);

    useEffect(() => {
        if (subQuestions.length > 0) {
            let value = [];
            if (subQuestions[pageNumber].options[0].length > 1) {
                subQuestions[pageNumber].options[0].map((opt, i) => {
                    if (i === 0) {
                        value = [...value, 65];
                    }
                    else {
                        value = [...value, value[i - 1] + 1];
                    }
                })
                setAddQuestionCount([...value]);
            }
        }
    }, [props.openSubQuestionPreview, pageNumber, subQuestions]);

    return (
        <>
            <Modal
                open={props.openSubQuestionPreview}
                onClose={props.closeSubQuestionPreview}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {subQuestions.length > 0 &&
                        <>
                            <Stack spacing={2}>
                                <Item>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Grid container spacing={2} p={2}>
                                                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "left" }}>
                                                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                                        {title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "left", flexWrap: "wrap" }}>
                                                    <Typography id="modal-modal-title" sx={{ color: "#1B1B1B", font: "normal normal 400 13px/25px Poppins", textAlign: "left" }}
                                                        dangerouslySetInnerHTML={{ __html: subQuestions[pageNumber].question }}
                                                    >
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "left", flexWrap: "wrap" }}>
                                                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                                        Explanation
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={12} >
                                                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 400 13px/25px Poppins", textAlign: "left" }}
                                                        dangerouslySetInnerHTML={{ __html: subQuestions[pageNumber].explanation }}
                                                    >
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1B1B1B", font: "normal normal 600 16px/25px Poppins" }}>
                                                Answer
                                            </Typography>
                                            <Grid container spacing={4} sx={{ p: 2 }}>
                                                {addQuestionCount.map((value, index) => {
                                                    return (
                                                        subQuestions[index] && subQuestions.questionTypeCode === "FILL" ?
                                                            <Grid item xs={12} mg={12} lg={12}>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <Typography id="modal-modal-description" sx={{ mt: 2, mr: 2, color: "#000000", font: "normal normal normal 20px/30px Poppins" }}>
                                                                        {String.fromCharCode(value)}
                                                                    </Typography>
                                                                    <CustomTextField id="fullWidth" disabled />
                                                                </Box>
                                                            </Grid>
                                                            :
                                                            subQuestions[index] && subQuestions.questionTypeCode === "MCQ" ?
                                                                <>
                                                                    <p>This is MCQ</p>
                                                                </>
                                                                :
                                                                subQuestions[index] && subQuestions.questionTypeCode === "MSQ" ?
                                                                    <>
                                                                        <p>This is MCQ</p>
                                                                    </>
                                                                    :
                                                                    <>
                                                                    </>
                                                    )
                                                })
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                            <div className="btn_diV">
                                                <div className="Exit__btn" onClick={props.closeSubQuestionPreview}>
                                                    <h6>Exit</h6>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Item>
                                <Item>
                                    <Grid container>
                                        <Grid item xs={1} md={1} lg={1}>
                                            <img src={bubble_img} />
                                        </Grid>
                                        <Grid item xs={3} md={3} lg={3} sx={{ alignContent: "center", flexWrap: "wrap", display: "flex", justifyContent: "center" }}>
                                            <Typography component={"h4"} sx={{ color: "#1b1b1b", fontWeight: "600", marginLeft: "15px" }}>Questions - {subQuestions.length}</Typography>
                                        </Grid>
                                        <Grid item xs={8} md={8} lg={8} sx={{ alignContent: "center", flexWrap: "wrap", display: "flex" }}>
                                            <Stack spacing={2} >
                                                <CustomPagination count={subQuestions.length} onChange={onPageChange} defaultPage={1} boundaryCount={subQuestions.length} size='large' hideNextButton={true} hidePrevButton={true} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Item>
                            </Stack>
                        </>
                    }
                </Box>
            </Modal >

        </>
    )
}

export default memo(SubQuestionPreview);