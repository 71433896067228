import React, { useEffect, useState } from 'react'
import { Grid, styled, Paper, Box } from '@mui/material'
import { makeStyles, Tooltip } from "@material-ui/core";
import dashboardContentStyles from '../../../css/dashboardContent.module.css'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: '190px',
    fontSize: '10px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

const ChapterCard = ({ item, onChapterClick }) => {

  const classes = useStyles();

  //-------| Truncate the Chapter Name |-------//
  const maxLength = 20;

  return (

  //   <div
  //   style={{
  //     display: "flex",
  //     flexDirection: "row",
  //     height: 700,
  //     width: "100%",
  //     padding: "25px 20px",
  //     overflowX: "auto", // Change to overflowX to allow horizontal scrolling only
  //     overflowY: "hidden",
  //   }}
  // >
  //   <Item key={item?.chapterId} style={{ cursor: 'pointer', height: "139px" }}>
  //     <Box sx={{ flexGrow: 1 }}>
  //       <div className={dashboardContentStyles.cardContainer}>
  //         <div
  //           key={item.chapterId}
  //           // onClick={() => setSelectedChapterId(item?.chapterId)}
  //           className={dashboardContentStyles.card}
  //         >
  //           <img
  //             src={item?.thumbnailPath}
  //             width={110}
  //             alt={""}
  //             // style={{ maxHeight: "138px" }}
  //             className={dashboardContentStyles.az_quiz_card_img} 
  //           />
  //           <div
  //             style={{
  //               fontSize: "14px",
  //               fontWeight: 600,
  //               color: "#423d5c",
  //             }}
  //           >
  //             {item?.chapter}
  //           </div>
  //         </div>
  //       </div>
  //     </Box>
  //   </Item>
  // </div>

    <Grid
    id='az-chapter-card-parent'
     item xs={12} md={2.4} sm={4} key={item.id} onClick={onChapterClick}>
      <Item key={item?.chapterId} style={{ cursor: 'pointer', height: "139px" }}>
        <div
          className={dashboardContentStyles.img_Chapter_Parent_div}
          key={item?.chapterId}
        >
          <img
            src={item?.thumbnailPath}
            alt={''}
          />
          {/*-------| Truncated Chapter Name |-------*/}
          <div
            className={dashboardContentStyles.chapterName}
          >
            {item?.chapter.length > maxLength ? (
              <Tooltip arrow title={item?.chapter} classes={{ tooltip: classes.tooltip }}>
                <span>{`${item?.chapter.substring(0, maxLength)}...`}</span>
              </Tooltip>
            ) : (
              item?.chapter
            )}
          </div>
        </div>
      </Item>
    </Grid>
  )
}

export default ChapterCard
