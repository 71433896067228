
export const listContainer = {
  width: '98%',
  paddingBottom: '100px',
  height: '100%',
  overflowY: 'auto',
  margin:"0 auto",
  borderRadius:"6px",
  paddingTop:"10px",

}

export const accordionItem ={
    "& .MuiListItem-root": {
        color: "white",
        background:"red !important"
      },
    height:'56px',
    border:"1px solid #7070706B",
    marginBottom:"8px",
    borderRadius:"6px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",


    fontFamily:"Poppins",
    fontWeight:500,
    fontSize:"15px",
    color:"#354052"
}

export const bottomStyle = {
  position: 'fixed',
  bottom: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  width: '98%',
  background: 'white',
  justifyContent: 'flex-end',
  padding: '10px 20px'
}

export const cancelBtn = {
    color:"#7A7A7A",
  background: 'white',
  marginRight: '20px',
  border:"1px solid #7A7A7A",
  borderRadius:"21px",
  width:"176px",
  height:"40px",
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  fontFamily:"Poppins",
  fontWeight:600,
  fontSize:"14px"
}

export const submitBtn = {
    color:"#fff",
  background: '#001AF3',
  borderRadius:"21px",
  width:"177px",
  height:"41px",
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  fontFamily:"Poppins",
  fontWeight:600,
  fontSize:"14px"
}


export const filterBarContainer ={
    display:"flex",
    alignItems:"center",
    padding:"9px ",
    justifyContent:"space-between",
}
export const filterBarContainerLeft ={
    display:"flex",
    alignItems:"center",
}