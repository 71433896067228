import React from "react";
import { Box, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};
const Student_QuestionView_Modal = ({ open, close, deleteName }) => {
  // console.log(deleteName);
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          <div>
            <div>
              <p
                style={{
                  color: "#333333",
                  opacity: "0.8",
                  fontSize: "1vw",
                  textAlign: "justify",
                }}
              >
                {`"${deleteName}"`}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <button
                  style={{
                    padding: "0.3vw 0.6vw",
                    fontSize: "0.8vw",
                  }}
                  onClick={close}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default Student_QuestionView_Modal;
