import React, { useEffect, useState } from "react";
import dashHome from "../../../../img/dashHome.svg";
import faEdit from "../../../../img/faEdit.svg";
import alertIcon from "../../../../img/alertIcon.png";
import "./uploads.css";
import { compareBluePrintTableColumn } from "../Apis/BluePrintApis";

export default function SheetTable({
  data,
  handleSave,
  isPreview,
  onCancelPreview,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [inputData, setinputData] = useState(data);
  const [columnNameValidation, setColumnNameValidation] = useState([]);
  const [validationStatus, setValidationStatus] = useState(false);

  useEffect(() => {
    compareTableColumn();
  }, []);

  const tableHead = [
    { name: "Print", fieldName: "print", width: 10 },
    { name: "What to print", fieldName: "what_to_print", width: 20 },
    { name: "Qstn No.", fieldName: "qstn_no", width: 5 },
    { name: "Qstn- Sub No.", fieldName: "qstn_sub_no", width: 6 },
    { name: "Marks", fieldName: "marks", width: 5 },
    { name: "Type", fieldName: "type", width: 15 },
    { name: "Sub Type", fieldName: "sub_type", width: 10 },
    { name: "Taxonomy Category", fieldName: "taxonomy_category", width: 10 },
    { name: "Taxonomy", fieldName: "taxonomy", width: 12 },
    { name: "Internal Choice", fieldName: "internal_choice", width: 7 },
  ];

  const handleClick = (event) => {
    setIsEdit(true);
  };

  const handleInputs = (e, index) => {
    const values = [...inputData];
    let inputValue = e.target.value;
    values[index][e.target.name] =
      e.target.name == "marks" ? parseInt(inputValue) : inputValue;
    setinputData(values);
  };

  const compareTableColumn = async () => {
    const response = await compareBluePrintTableColumn();
    setColumnNameValidation(response?.data);
  };

  const columnValidation = (print) => {
    if (columnNameValidation.includes(print)) {
      return false;
    } else return true;
  };

  const checkValidation = (e) => {
    let arr = [];
    inputData?.forEach((item) => {
      arr.push(columnValidation(item.print));
    });
    if (arr.includes(true)) {
      alert("please check the column values");
      // setValidationStatus(false);
    } else {
      handleSave(inputData);
      // setValidationStatus(true);
    }
  };

  return (
    <div style={{ marginTop: "5px", marginBottom: "30px" }}>
      <div className="sheet-table-img-wrap">
        <div>
          <img
            src={dashHome}
            alt="no img"
            width="20px"
            height="20px"
            style={{ verticalAlign: "middle" }}
          />
          <span className="sheet-table-page-head">Add Blue Print</span>
        </div>
        {!isPreview && (
          <div
          id={`az-content-edit-button`}
           onClick={handleClick}>
            <img
              src={faEdit}
              alt="no img"
              width="20px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
            <span className="sheet-table-page-head">Edit</span>
          </div>
        )}
      </div>
      <div className="sheet-table-wrapper">
        <div className="sheet-table-content" style={{ marginTop: "10px" }}>
          {tableHead.map((item, index) => {
            return (
              <div
                className="sheet-table-head"
                style={{ width: `${item.width}%` }}
                key={index}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        {inputData.map((item, index) => {
          return (
            <div
              className="sheet-table-content"
              style={{ marginTop: "5px" }}
              key={index}
            >
              <div
                className="sheet-table-body"
                style={{
                  width: "10%",
                  border: isEdit
                    ? "1px Solid #0161D2"
                    : !isEdit && columnValidation(item?.print)
                    ? "1px Solid red"
                    : "",
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  display: "flex",
                }}
              >
                {/* <div style={{ display: "flex" }}> */}
                <input
                  type="text"
                  name="print"
                  value={item.print}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  id={`az-content-print-button-${index}`}
                  onChange={(e) => handleInputs(e, index)}
                />
                {columnValidation(item?.print) && (
                  <>
                    <div className="hover-text">
                      <img
                        src={alertIcon}
                        width="18px"
                        height="18px"
                        style={{ paddingRight: "5px", cursor: "pointer" }}
                      />
                      <span className="tooltip-text">Value not defined</span>
                    </div>
                  </>
                )}
                {/* </div> */}
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "20%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="whatToPrint"
                  value={item.whatToPrint}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                  id={`az-content-print-button-${index}`}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "5%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="number"
                  min="1"
                  name="questionNumber"
                  value={item.questionNumber}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "6%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="questionSubNumber"
                  value={item.questionSubNumber}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "5%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="number"
                  min="0"
                  name="marks"
                  value={item.marks}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "15%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="type"
                  value={item.type}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "10%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="subType"
                  value={item.subType}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "10%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="taxonomyCategory"
                  value={item.taxonomyCategory}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "12%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="taxonomy"
                  value={item.taxonomy}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
              <div
                className="sheet-table-body"
                style={{
                  width: "7%",
                  border: isEdit ? "1px Solid #0161D2" : null,
                  backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                }}
              >
                <input
                  type="text"
                  name="internalChoice"
                  value={item.internalChoice}
                  className="input-text"
                  disabled={isEdit ? false : true}
                  style={{
                    color: "#1A1A1A",
                    backgroundColor: item.print == "Section" ? "#FFEAD1" : "",
                  }}
                  onChange={(e) => handleInputs(e, index)}
                />
              </div>
            </div>
          );
        })}

        <div className="btn-wrapper">
          {!isPreview && (
            <button
              type="button"
              className="sheet-button"
              // onClick={(e) => handleSave(e, inputData)}
              onClick={() => checkValidation()}
            >
              Save & Upload
            </button>
          )}
          {isPreview && (
            <button
              type="button"
              className="button-cancel"
              onClick={() => onCancelPreview()}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
