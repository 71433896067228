import axios from 'axios';

const TOKEN = sessionStorage.getItem('token');
const axiosToken = axios.create({
  baseURL: 'https://prd-api.azvasa.online/',
  // baseURL: 'https://dev-api.azvasa.online/',
  // baseURL: 'https://test-be-lms.azvasa.online/',
  headers: {
    Authorization: TOKEN,
  },
});

export default axiosToken;
