// import axios from 'axios'

// async function users_forgotpassword_Mobile(mobile,data) {
//   let token = sessionStorage.getItem("token");
//     let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/forgotpasswordMobile?phoneNumber=${mobile}&userName=${data}`,
//     {
//      headers: {
//         "Content-Type": "application/json",
//         // Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((json)=>{
//       // console.log(json)
//     })
//     .catch(err=>console.log(err))
// }

// export default users_forgotpassword_Mobile;




import { Phone } from '@mui/icons-material';
import axios from 'axios'

async function users_forgotpassword_Mobile(mobile, data) {
  let token = sessionStorage.getItem("token");
  // "userName": "g.sekaran",
  //   "mobileNumber": "12345678",
  //   "mailId": "sekaran@azvasa.in"
  var item = {
    "userName": data,
    "phoneNumber": mobile
  }
  let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/forgot-password/generate-otp`, item,
    {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    })
    .then((json) => {
    })
    .catch(err => {
      console.log(err)
      throw err

    })
}

export default users_forgotpassword_Mobile;
