import { Box, Modal, Typography } from "@mui/material";
import { dummy, dummyTasks } from "./dummyData";
import TaskItem from "./TaskItem";
import { Close } from "@material-ui/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 1,
  p: 2,
};
const TasksModal = ({
  open,
  close,
}) => {

    const onTaskClick =()=>{}
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
        <div className="task-modal-container">
                <div className="task-modal-header">
                    <p className="tasks-title">Upcoming Tasks</p>
                    <Close onClick={close} />
                </div>
                <div style={{height:'90%',overflowY:"auto"}}>
                    {dummyTasks?.map(item => <TaskItem key={item.id} onClick={onTaskClick} task={item}/>)}
                </div>
        </div>
      
    </Modal>
  );
};
export default TasksModal;
