//
// --- ❗❕❗ MTF-> MATCH THE FOLLOWING ❗❕❗ --- //
//
import React from "react";
import { useState } from "react";

// CSS Pages Used
import "../../css/Student/Student_Enrich_Quiz_MTF.css";

// Images Used
import MTF_img from "../../../../src/img/MTF-img.jpg";
import prev from "../../../../src/img/Prev.svg";
import nxt from "../../../../src/img/Nxt.svg";

function Student_Enrich_Quiz_MTF() {
  const [droppedImageUrl, setDroppedImageUrl] = useState(null);

  // -----Image Drag To The Ans Field-----
  // const handleDragStart = (e) => {
  //   setIsDragging(true);
  //   e.dataTransfer.setData("text/plain", e.target.id);
  // };

  // const handleDragEnd = () => {
  //   setIsDragging(false);
  // };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   const droppedImageId = e.dataTransfer.getData("text/plain");
  //   const droppedImage = document.getElementById(droppedImageId);
  //   e.target.appendChild(droppedImage);
  // };

  // const handleDragOver = (e) => {
  //   e.preventDefault();
  // };
  // ----------------------------------------------------------------

  function dragStart(event) {
    event.dataTransfer.setData("image", event.target.src);
  }

  function dragOver(event) {
    event.preventDefault();
  }

  function drop(event) {
    event.preventDefault();
    const imageUrl = event.dataTransfer.getData("image");
    setDroppedImageUrl(imageUrl);
  }

  return (
    <>
      <div className="MTF_Box">
        <div>
          <p>
            Drag the boxes from right Column to the empty boxes in the Middle
            Column and match the clues given on the Right Column
          </p>
        </div>
        <div className="MTF_Div">
          <div className="MTF_Qstn_And_Field_div">
            {/*------------------| Qstn And Field 1 |-----------------*/}
            <div className="MTF_Fields_1">
              <div className="MTF_Qstn_Field_1_B1">
                <p>Dog</p>
              </div>
              <div
                id="box"
                className="MTF_Qstn_Field_1_B2"
                // onDrop={handleDrop}
                // onDragOver={handleDragOver}
                onDragOver={dragOver}
                onDrop={drop}
              >
                {droppedImageUrl && (
                  <img
                    src={droppedImageUrl}
                    alt="dropped-image"
                    style={{
                      maxWidth: "276px",
                      maxHeight: "149px",
                      objectFit: "contain",
                    }}
                  />
                )}
              </div>
            </div>

            {/*------------------| Qstn And Field 2 |-----------------*/}
            <div className="MTF_Fields_2">
              <div className="MTF_Qstn_Field_2_B1">
                <p>Dog</p>
              </div>
              <div className="MTF_Qstn_Field_2_B2">
                <p></p>
              </div>
            </div>
            {/*------------------| Qstn And Field 3 |-----------------*/}
            <div className="MTF_Fields_3">
              <div className="MTF_Qstn_Field_3_B1">
                <p>Dog</p>
              </div>
              <div className="MTF_Qstn_Field_3_B2">
                <p></p>
              </div>
            </div>
            {/*------------------| Qstn And Field 4 |-----------------*/}
            <div className="MTF_Fields_4">
              <div className="MTF_Qstn_Field_4_B1">
                <p>Dog</p>
              </div>
              <div className="MTF_Qstn_Field_4_B2">
                <p></p>
              </div>
            </div>
          </div>
          {/*------------------| MTF Ans IMages Div |-----------------*/}
          <div className="MTF_Ans_Imgs">
            <div>
              <img
                id="image"
                src={MTF_img}
                draggable="true"
                // onDragStart={handleDragStart}
                // onDragEnd={handleDragEnd}
                onDragStart={dragStart}
              />
            </div>
            <div>
              <img src={MTF_img} />
            </div>
            <div>
              <img src={MTF_img} />
            </div>
            <div>
              <img src={MTF_img} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Student_Enrich_Quiz_MTF;
