import axios from 'axios';

 function GradesControllerGetall(setGradesName) {
  
  let token = sessionStorage.getItem("token");
   axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setGradesName(json.data.data)
    })
    .catch(err=>console.log(err))
}

export default GradesControllerGetall;
