// question wise performance
export const accordionParentDiv = (gap) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: gap !== undefined ? `${gap}rem` : "10px",
})

export const accordionSummaryStyles = (bgColor, borderTLRadius, borderTRRadius) => ({
    background: bgColor
        ? "rgb(5 74 134)"
        : "rgb(52 134 208 / 90%)",
    color: "white",
    borderTopLeftRadius: borderTLRadius ? borderTLRadius : "10px",
    borderTopRightRadius: borderTRRadius ? borderTRRadius : "10px",
    fontSize: "20px",
})

export const accordionSummaryBarLeft = {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
}

export const accordionSummaryBarRight = {
    width: "50%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
}

export const accordionDetailsPaper = {
    background: "#c3c5ee6e",
    boxShadow: "0px 0px 0px 0px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
}

export const accordionDetailsUpperPart = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "end",
}

export const accordionDetailsQuestion = {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    justifyContent: "start",
}

export const accordionDisplayAnswers = {
    display: "flex",
    flexDirection: "row",
    columnGap: "1rem",
    alignItems: "center",
    fontSize: "15px",
}

export const accordionQuestionImage = {
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    alignItems: "end",
}

export const accordionDetailsBelowPart = {
    background: "white",
    padding: "15px",
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    width: "100%",
    justifyContent: "space-between",
}

export const accordionQuestionOverallDetails = {
    display: "flex",
    flexDirection: "column",
    rowGap: "7px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    maxWidth: "200px",
    padding: "10px",
}

// quiz overview
export const quizOverviewParentDiv = (gap, color, marginLeft) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: gap !== undefined ? `${gap}px` : "1rem",
    color: color !== undefined ? `rgb(${color.join(",")})` : '',
    marginLeft: `${marginLeft}px`
})

export const absenteesPaper = {
    width: "100%",
    background: "rgba(245,85,127,0.89)",
    padding: "15px",
    maxHeight: "255px",
    color: "white",
}

export const absenteesStyles = {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    height: "185px",
    overflow: "scroll",
}
