import axios from 'axios'

async function StudentControllerIdPut(studentIdData, changeStudentProfile,optionSel,isYearAssesment,selectedItems) {
  let token = sessionStorage.getItem("token");
  let item = {
    "students": [
      ...selectedItems
    ],
    "fromSectionId": studentIdData.sectionId,
    "fromGradeId":studentIdData.gradeId,
    "toSectionId": changeStudentProfile.sectionId,
    "toGradeId": changeStudentProfile.gradeId,
    "branchId": sessionStorage.getItem("branchid"),
    "schoolId": sessionStorage.getItem("schoolid"),
    "sameYear": true,
    "yearEndProcess": isYearAssesment
  }
    let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students/change-profile`,item,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
    })
    .catch(err=>console.log(err))
}

export default StudentControllerIdPut;
