import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

function ChapterCard({ data, quizType }) {
	const [unitquiz, setUnitquiz] = useState({});
	const [practiceQuiz, setPracticeQuiz] = useState({});
	const [annualQuiz, setAnnualQuiz] = useState({});
	const [DiagnosticQuiz, setDiagnosticQuiz] = useState({});
	const navigate = useNavigate();
	useEffect(() => {
		setUnitquiz(() =>
			data.quizCountDetails?.find(
				(item) => item?.quizType === "Unit Quiz"
			)
		);
		setPracticeQuiz(() =>
			data.quizCountDetails?.find(
				(item) => item?.quizType === "Practice Quiz"
			)
		);
		setAnnualQuiz(() =>
			data.quizCountDetails?.find(
				(item) => item?.quizType === "Annual/Term Quiz"
			)
		);
		setDiagnosticQuiz(() =>
			data.quizCountDetails?.find(
				(item) => item?.quizType === "Diagnostic Quiz"
			)
		);
	}, [data]);

	const handleNavigation = (item, status) => {
		navigate(`/revision-reviewer/quiz-approval/`, {
			state: {
				chapter: data.id,
				quizStatus: status,
				quizTypeId: item.quizTypeId,
			},
		});
	};
	return (
		<Container>
			<Label>{data.chapter}</Label>
			<TitleSection>
				<LeftSection></LeftSection>
				<RightSection>
					<StatuslabelCover>
						<Statuslabelpending>Pending</Statuslabelpending>
						<Statuslabelrejected>Rejected</Statuslabelrejected>
						<Statuslabelapproved>Approved</Statuslabelapproved>
					</StatuslabelCover>
				</RightSection>
			</TitleSection>

			{unitquiz ? (
				<QuizDetailCard
					className={quizType === "Unit Quiz" && "active"}
				>
					<QuizName>Unit</QuizName>
					<QuizCount>
						<Count
							onClick={() =>
								handleNavigation(unitquiz, "PENDING")
							}
						>
							{unitquiz.pending}
						</Count>
						<Count
							onClick={() =>
								handleNavigation(unitquiz, "REJECTED")
							}
						>
							{unitquiz.rejected}
						</Count>
						<Count
							onClick={() =>
								handleNavigation(unitquiz, "APPROVED")
							}
						>
							{unitquiz.approved}
						</Count>
					</QuizCount>
				</QuizDetailCard>
			) : quizType !== "Unit Quiz" && quizType !== null ? (
				""
			) : (
				<QuizDetailCard
					className={quizType === "Unit Quiz" && "active"}
				>
					<QuizName>Unit Quiz</QuizName>
					<QuizCount>
						<Count>-</Count>
						<Count>-</Count>
						<Count>-</Count>
					</QuizCount>
				</QuizDetailCard>
			)}
			{practiceQuiz ? (
				<QuizDetailCard
					className={quizType === "Practice Quiz" && "active"}
				>
					<QuizName>Practice Quiz</QuizName>
					<QuizCount>
						<Count
							onClick={() =>
								handleNavigation(practiceQuiz, "PENDING")
							}
						>
							{practiceQuiz.pending}
						</Count>
						<Count
							onClick={() =>
								handleNavigation(practiceQuiz, "REJECTED")
							}
						>
							{practiceQuiz.rejected}
						</Count>
						<Count
							onClick={() =>
								handleNavigation(practiceQuiz, "APPROVED")
							}
						>
							{practiceQuiz.approved}
						</Count>
					</QuizCount>
				</QuizDetailCard>
			) : quizType !== "Practice Quiz" && quizType !== null ? (
				""
			) : (
				<QuizDetailCard
					className={quizType === "Practice Quiz" && "active"}
				>
					<QuizName>Practice Quiz</QuizName>
					<QuizCount>
						<Count>-</Count>
						<Count>-</Count>
						<Count>-</Count>
					</QuizCount>
				</QuizDetailCard>
			)}
			{annualQuiz ? (
				<QuizDetailCard
					className={quizType === "Annual/Term Quiz" && "active"}
				>
					<QuizName>Annual Quiz</QuizName>
					<QuizCount>
						<Count
							onClick={() =>
								handleNavigation(annualQuiz, "PENDING")
							}
						>
							{annualQuiz.pending}
						</Count>
						<Count
							onClick={() =>
								handleNavigation(annualQuiz, "REJECTED")
							}
						>
							{annualQuiz.rejected}
						</Count>
						<Count
							onClick={() =>
								handleNavigation(annualQuiz, "APPROVED")
							}
						>
							{annualQuiz.approved}
						</Count>
					</QuizCount>
				</QuizDetailCard>
			) : quizType !== "Annual/Term Quiz" && quizType !== null ? (
				""
			) : (
				<QuizDetailCard
					className={quizType === "Annual/Term Quiz" && "active"}
				>
					<QuizName>Annual Quiz</QuizName>
					<QuizCount>
						<Count>-</Count>
						<Count>-</Count>
						<Count>-</Count>
					</QuizCount>
				</QuizDetailCard>
			)}
			{DiagnosticQuiz ? (
				<QuizDetailCard className={"active"}>
					<QuizName>Diagnostic Quiz</QuizName>
					<QuizCount>
						<Count
							onClick={() =>
								handleNavigation(DiagnosticQuiz, "PENDING")
							}
						>
							{DiagnosticQuiz.pending}
						</Count>
						<Count
							onClick={() =>
								handleNavigation(DiagnosticQuiz, "REJECTED")
							}
						>
							{DiagnosticQuiz.rejected}
						</Count>
						<Count
							onClick={() =>
								handleNavigation(DiagnosticQuiz, "APPROVED")
							}
						>
							{DiagnosticQuiz.approved}
						</Count>
					</QuizCount>
				</QuizDetailCard>
			) : quizType !== "Diagnostic Quiz" && quizType !== null ? (
				""
			) : (
				<QuizDetailCard className={"active"}>
					<QuizName>Diagnostic Quiz</QuizName>
					<QuizCount>
						<Count>-</Count>
						<Count>-</Count>
						<Count>-</Count>
					</QuizCount>
				</QuizDetailCard>
			)}
		</Container>
	);
}

export default ChapterCard;
const Container = styled.div`
	border: 1px solid #ececec;
	height: auto;
	border-radius: 5px;
`;
const Label = styled.p`
	color: #4053f6;
	font-size: 14px;
	font-weight: 500;
	padding: 10px;
	background-color: #f3f2ff;
`;
const TitleSection = styled.div`
	display: grid;
	grid-template-columns: 2fr 4fr;
	border-bottom: 1px solid rgb(220 220 220);
	padding: 5px 10px;
	grid-gap: 10px;
`;
const LeftSection = styled.div``;
const RightSection = styled.div``;
const StatuslabelCover = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 10px;
`;
const Statuslabelpending = styled.p`
	font-size: 12px;
	font-weight: 300;
	color: #FD8C00;
	text-align: center;
`;
const Statuslabelrejected = styled.p`
	font-size: 12px;
	font-weight: 300;
	color: red;
	text-align: center;
`;
const Statuslabelapproved = styled.p`
	font-size: 12px;
	font-weight: 300;
	color: green;
	text-align: center;
`;
const QuizDetailCard = styled.div`
	display: grid;
	padding: 10px;
	grid-template-columns: 2fr 4fr;
	grid-gap: 10px;
	padding-bottom: 10px;
	&.active {
		p {
			border-bottom: none !important;
		}
	}
`;
const QuizName = styled.p`
	font-size: 12px;
	border-bottom: 1px solid rgb(220 220 220);
	color: black;
`;
const QuizCount = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 10px;
`;
const Count = styled.p`
	font-size: 14px;
	font-weight: 300;
	text-align: center;
	border-bottom: 1px solid rgb(220 220 220);
	cursor: pointer;
`;
