import axios from "axios";

async function GetLastModifiedAt(ModifiedDate) {
  let token = sessionStorage.getItem("token");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/branches/last-modified-at`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response)=>{
      // console.log(response)
      ModifiedDate(response.data)
    })
    .catch(err=>console.log(err))
}

export default GetLastModifiedAt;
