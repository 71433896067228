import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const DarkYellowButton = (props) => {
  const CancelButton = styled(Button)(() => ({
    color: "#fff",
    minWidth: "7.8vw",
    backgroundColor:props.disabled ? "#ccc":  "#f79f02",
    borderRadius: '50px',
    border: "1px solid #757575",
    borderColor: props.disabled ?"#ccc": "#f79f02",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    fontSize: "0.9vw",
    fontWeight: 600,
    "&:hover": {
      color: "#fff",
      backgroundColor:props.disabled ? "#ccc": "#f79f02",
    },
  }));
  return (
    <CancelButton id={props.id} disabled={props.disabled} onClick={props.onClick} style={props.style}>
      {props.children}
    </CancelButton>
  );
};
export default DarkYellowButton;
