import axios from "axios";

async function User_Branches_id_Plan(setPlanData) {
  let token = sessionStorage.getItem("token");
  let schoolid = sessionStorage.getItem("schoolid");
  let branchid = sessionStorage.getItem("branchid");
  let planid = sessionStorage.getItem("planid");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/plans/for-other-admins?id=${planid}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response)=>{
      // if(response.data[0]){
        setPlanData(response.data.data)
        // setPlanData(response.data[0].planGrades)
      // }

    })
    .catch(err=>console.log(err))
}

export default User_Branches_id_Plan;
