const TokenList= [
    {

        TokenId:"446-409-901",
        Role:"Teacher",
        Email:"sample@gmail.com",
        MultiUser:"No",
        NoOfUsers:"None",
        UsedUserId:"12815",
        DateCreated:"12-01-2022 10:12:45 PM ",
        ExpiryDate:"24-02-2022",
        NoOfToken:"-",
    },

    {

        TokenId:"446-409-901",
        Role:"Teacher",
        Email:"sample@gmail.com",
        MultiUser:"No",
        NoOfUsers:"None",
        UsedUserId:"12815",
        DateCreated:"12-01-2022 10:12:45 PM ",
        ExpiryDate:"24-02-2022",

        NoOfToken:"-",
    }

]
export default TokenList;
