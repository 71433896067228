import { Box, Modal, Typography } from "@mui/material";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import YellowBorderHover from "../../components/common/YellowBorderHover";
import YellowButton from "../../components/common/yellowButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};
const RejectCommentModal = ({ open, close, submitComment, children }) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <p style={{ color: "#FFA700" }}>Rejection Comments</p>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>{children}</div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  columnGap: "1rem",
                  marginTop: "10px",
                }}
              >
                <WhiteAddButton onClick={close}>Cancel</WhiteAddButton>
                <YellowButton onClick={() => submitComment()}>Submit</YellowButton>
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default RejectCommentModal;
