import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ExcelJS from 'exceljs';
import sortIcon from "../../../img/sort_1.png"
import commonsort from "../../../js/CommonSort"

const staticData = {
  "data": {
    "formalListOfStudentWiseResponse": [
      {
        "studentId": "402892888968a1ca018972c37b940013",
        "studentName": "student one",
        "percentage": 67.0,
        "timeTaken": "00Hrs 02Mins 23Sec",
        "avgTimeTaken": null,
        "attemptOn": "01-09-2023",
        "concepts": [
          {
            "conceptId": "402892888a4a60f4018a4ec8c668003e",
            "conceptName": "CRICKET CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecab8610049",
                "subConceptName": "CRICKET SUB-CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4ecae9a6004a",
                    "rootConceptName": "CRICKET ROOT-CONCEPT",
                    "marks": "0/2"
                  }
                ],
                "mark": null
              }
            ],
            "mark": null
          },
          {
            "conceptId": "402892888a4a60f4018a4ec9106d0040",
            "conceptName": "FOOTBALL CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecc70fb004e",
                "subConceptName": "FOOTBALL SUB-CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4eccad5f0050",
                    "rootConceptName": "FOOTBALL ROOT-CONCEPT",
                    "marks": "2/2"
                  }
                ],
                "mark": "0/1"
              }
            ],
            "mark": null
          },
          {
            "conceptId": "402892888a4a60f4018a4ec95a700042",
            "conceptName": "TENNIS CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecdf3a60051",
                "subConceptName": "TENNIS SUB-CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4ece965d0053",
                    "rootConceptName": "TENNIS ROOT-CONCEPT",
                    "marks": "3/3"
                  }
                ],
                "mark": null
              }
            ],
            "mark": null
          },
          {
            "conceptId": "402892888a4a60f4018a4ec9a3e10044",
            "conceptName": "SPORTS CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecf66000055",
                "subConceptName": "SPORTS CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4ecf9b960056",
                    "rootConceptName": "SPORTS ROOT-CONCEPT",
                    "marks": "1/1"
                  }
                ],
                "mark": null
              }
            ],
            "mark": null
          }
        ],
        "total": "6/9"
      },
      {
        "studentId": "402892888968a1ca018972c46c0f0016",
        "studentName": "student two",
        "percentage": 22.0,
        "timeTaken": "00Hrs 00Mins 33Sec",
        "avgTimeTaken": null,
        "attemptOn": "01-09-2023",
        "concepts": [
          {
            "conceptId": "402892888a4a60f4018a4ec8c668003e",
            "conceptName": "CRICKET CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecab8610049",
                "subConceptName": "CRICKET SUB-CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4ecae9a6004a",
                    "rootConceptName": "CRICKET ROOT-CONCEPT",
                    "marks": "0/2"
                  }
                ],
                "mark": null
              }
            ],
            "mark": null
          },
          {
            "conceptId": "402892888a4a60f4018a4ec9106d0040",
            "conceptName": "FOOTBALL CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecc70fb004e",
                "subConceptName": "FOOTBALL SUB-CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4eccad5f0050",
                    "rootConceptName": "FOOTBALL ROOT-CONCEPT",
                    "marks": "1/2"
                  }
                ],
                "mark": "0/1"
              }
            ],
            "mark": null
          },
          {
            "conceptId": "402892888a4a60f4018a4ec95a700042",
            "conceptName": "TENNIS CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecdf3a60051",
                "subConceptName": "TENNIS SUB-CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4ece965d0053",
                    "rootConceptName": "TENNIS ROOT-CONCEPT",
                    "marks": "0/3"
                  }
                ],
                "mark": null
              }
            ],
            "mark": null
          },
          {
            "conceptId": "402892888a4a60f4018a4ec9a3e10044",
            "conceptName": "SPORTS CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecf66000055",
                "subConceptName": "SPORTS CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4ecf9b960056",
                    "rootConceptName": "SPORTS ROOT-CONCEPT",
                    "marks": "1/1"
                  }
                ],
                "mark": null
              }
            ],
            "mark": null
          }
        ],
        "total": "2/9"
      },
      {
        "studentId": "402892888a28443f018a2c889c1b0016",
        "studentName": "student fifty",
        "percentage": 67.0,
        "timeTaken": "00Hrs 01Mins 20Sec",
        "avgTimeTaken": null,
        "attemptOn": "07-09-2023",
        "concepts": [
          {
            "conceptId": "402892888a4a60f4018a4ec8c668003e",
            "conceptName": "CRICKET CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecab8610049",
                "subConceptName": "CRICKET SUB-CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4ecae9a6004a",
                    "rootConceptName": "CRICKET ROOT-CONCEPT",
                    "marks": "0/2"
                  }
                ],
                "mark": null
              }
            ],
            "mark": null
          },
          {
            "conceptId": "402892888a4a60f4018a4ec9106d0040",
            "conceptName": "FOOTBALL CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecc70fb004e",
                "subConceptName": "FOOTBALL SUB-CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4eccad5f0050",
                    "rootConceptName": "FOOTBALL ROOT-CONCEPT",
                    "marks": "2/2"
                  }
                ],
                "mark": "0/1"
              }
            ],
            "mark": null
          },
          {
            "conceptId": "402892888a4a60f4018a4ec95a700042",
            "conceptName": "TENNIS CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecdf3a60051",
                "subConceptName": "TENNIS SUB-CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4ece965d0053",
                    "rootConceptName": "TENNIS ROOT-CONCEPT",
                    "marks": "3/3"
                  }
                ],
                "mark": null
              }
            ],
            "mark": null
          },
          {
            "conceptId": "402892888a4a60f4018a4ec9a3e10044",
            "conceptName": "SPORTS CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecf66000055",
                "subConceptName": "SPORTS CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4ecf9b960056",
                    "rootConceptName": "SPORTS ROOT-CONCEPT",
                    "marks": "1/1"
                  }
                ],
                "mark": null
              }
            ],
            "mark": null
          }
        ],
        "total": "6/9"
      },
      {
        "studentId": "402892888968a1ca018972c566720019",
        "studentName": "student three",
        "percentage": 33.0,
        "timeTaken": "00Hrs 00Mins 57Sec",
        "avgTimeTaken": null,
        "attemptOn": "01-09-2023",
        "concepts": [
          {
            "conceptId": "402892888a4a60f4018a4ec8c668003e",
            "conceptName": "CRICKET CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecab8610049",
                "subConceptName": "CRICKET SUB-CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4ecae9a6004a",
                    "rootConceptName": "CRICKET ROOT-CONCEPT",
                    "marks": "0/2"
                  }
                ],
                "mark": null
              }
            ],
            "mark": null
          },
          {
            "conceptId": "402892888a4a60f4018a4ec9106d0040",
            "conceptName": "FOOTBALL CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecc70fb004e",
                "subConceptName": "FOOTBALL SUB-CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4eccad5f0050",
                    "rootConceptName": "FOOTBALL ROOT-CONCEPT",
                    "marks": "0/2"
                  }
                ],
                "mark": "1/1"
              }
            ],
            "mark": null
          },
          {
            "conceptId": "402892888a4a60f4018a4ec95a700042",
            "conceptName": "TENNIS CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecdf3a60051",
                "subConceptName": "TENNIS SUB-CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4ece965d0053",
                    "rootConceptName": "TENNIS ROOT-CONCEPT",
                    "marks": "1/3"
                  }
                ],
                "mark": null
              }
            ],
            "mark": null
          },
          {
            "conceptId": "402892888a4a60f4018a4ec9a3e10044",
            "conceptName": "SPORTS CONCEPT",
            "subConcepts": [
              {
                "subConceptId": "402892888a4a60f4018a4ecf66000055",
                "subConceptName": "SPORTS CONCEPT 2",
                "rootConcepts": [
                  {
                    "rootConceptId": "402892888a4a60f4018a4ecf9b960056",
                    "rootConceptName": "SPORTS ROOT-CONCEPT",
                    "marks": "1/1"
                  }
                ],
                "mark": null
              }
            ],
            "mark": null
          }
        ],
        "total": "3/9"
      }
    ],
    "subjectWithSubtopic": []
  }
}

const StudentWisePerformance = ({ data, absenteeData, downloadReport, setDownloadReport, loading }) => {
  // console.log('absenteeData data >> ', absenteeData);
  // const [conceptList, setConceptList] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [studentDataTemp, setStudentDataTemp] = useState([]);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("studentName");
  const [isSubtopic, setIsSubtopic] = useState(false);
  const [loggedInRole, SetLoggedInRole] = useState(localStorage.getItem("role"));

  useEffect(() => {
    const setConceptLevelData = () => {
      console.log(data, "===========101")
      if (data?.formalListOfStudentWiseResponse?.length > 0) {
        let alteredDataTemp = [];
        data?.formalListOfStudentWiseResponseTemp?.map((m) => {
          if (m?.concepts) {
            var cons = groupAndSumMarksBySubConcept(m.concepts)
            m.concepts = cons
            alteredDataTemp.push(m)
          }
        })
        alteredDataTemp?.sort((a, b) => Math.round(b?.percentage) - Math.round(a?.percentage))
        setStudentDataTemp(alteredDataTemp);
        console.log("alteredDataTemp", alteredDataTemp)
        setIsSubtopic(false);
        let alteredData = [];
        data?.formalListOfStudentWiseResponse?.map(x => {
          let concept = [];
          x.concepts?.map(item => {
            concept.push({
              id: `${item?.conceptName}`,
              name: item?.conceptName,
              subConcept: item?.subConcepts?.map(subItem => {
                return {
                  subConceptName: subItem?.subConceptName,
                  rootConcept: subItem?.rootConcepts !== null ? subItem?.rootConcepts?.map(rootItem => rootItem?.rootConceptName ? rootItem?.rootConceptName : '') : [],
                  // rootConcept: subItem?.rootConcepts?.map(rootItem => rootItem?.rootConceptName ? rootItem?.rootConceptName : ''),
                  // marksArr: subItem?.rootConcepts !== null
                  //   ? subItem?.rootConcepts?.map(rootItem => rootItem?.marks !== null ? rootItem?.marks : subItem?.mark)
                  //   : [subItem?.mark],
                  marksArr: {
                    rootMark: subItem?.rootConcepts !== null
                      ? subItem?.rootConcepts?.map(root => root?.marks) : [],
                    subMark: subItem?.mark !== null ? subItem?.mark : ''
                  }
                }
              }),
            });
          });
          x["concepts"] = concept;
          x["timeTaken"] = x?.timeTaken;
          x["attemptOn"] = x?.attemptOn;
          x["timePerQuestion"] = x?.avgTimeTaken;
          alteredData.push(x);
        });
        alteredData?.sort((a, b) => Math.round(b?.percentage) - Math.round(a?.percentage));
        console.log("alteredData", alteredData);
        setStudentData(alteredData);
      } else if (data?.subjectWithSubtopic?.length) {
        data?.subjectWithSubtopic?.sort((a, b) => Math.round(b?.percentage) - Math.round(a?.percentage));
        setStudentData(data?.subjectWithSubtopic);
        // console.log("ssw", data?.subjectWithSubtopic)
        setIsSubtopic(true);
      } else {
        setIsSubtopic(false);
        setStudentData([]);
      }
    }
    setConceptLevelData();
  }, [data])
  const onSort = async (sortOrder, field) => {
    var ddData = []
    console.log("studentDataTemp", studentDataTemp)
    if (field == "attemptOn" || field == "avgTimeTaken") {
      studentDataTemp?.forEach(student => {
        console.log("student", student)
        let avgTimeParts = student?.avgTimeTaken?.match(/(\d+)/);
        student.avgTimeTakenNumber = avgTimeParts ? parseInt(avgTimeParts[0]) : null;
        if (student?.attemptOn) {
          let [day, month, year] = student?.attemptOn?.split("-").map(Number);
          student.attemptOnDate = new Date(year, month - 1, day);
          console.log(student.attemptOnDate, "ifffffffffff")
        } else {
          student.attemptOnDate = null
        }

        ddData.push(student)
      });

      console.log("studentDataTemp", ddData);
    } else {
      ddData = studentDataTemp
    }
    console.log(field, ddData)
    setSortOrder(sortOrder)
    var sorted = [];
    if (field === "attemptOn") {
      field = "attemptOnDate"
    } else if (field === "avgTimeTaken") {
      field = "avgTimeTakenNumber"
    }

    var sorted = await commonsort(ddData || [], field, sortOrder);
    console.log(sorted, "===========")
    setStudentDataTemp(sorted)
  }
  const onSort1 = async (sortOrder, field) => {
    var ddData = []
    // console.log("studentDataTemp", studentData)
    if (field == "attemptOn" || field == "avgTimeTaken") {
      studentData?.forEach(student => {
        // console.log("student", student)
        let avgTimeParts = student?.avgTimeTaken?.match(/(\d+)/);
        student.avgTimeTakenNumber = avgTimeParts ? parseInt(avgTimeParts[0]) : null;
        if (student?.attemptOn) {
          let [day, month, year] = student?.attemptOn?.split("-").map(Number);
          student.attemptOnDate = new Date(year, month - 1, day);
          // console.log(student.attemptOnDate, "ifffffffffff")
        } else {
          student.attemptOnDate = null
        }

        ddData.push(student)
      });

      // console.log("studentDataTemp", ddData);
    } else {
      ddData = studentData
    }
    // console.log(field, ddData)
    setSortOrder(sortOrder)
    var sorted = [];
    if (field === "attemptOn") {
      field = "attemptOnDate"
    } else if (field === "avgTimeTaken") {
      field = "avgTimeTakenNumber"
    }

    var sorted = await commonsort(ddData || [], field, sortOrder);
    // console.log(sorted, "===========")
    setStudentData(sorted)
  }

  function groupAndSumMarksBySubConcept(Conc) {
    // console.log("data", Conc)
    const result = processConcepts(Conc);
    // console.log("main",result)
    // console.log(JSON.stringify(result, null, 2));
    return result
    function groupAndSumMarks(data) {
      const groupedData = new Map();

      data?.forEach(item => {
        const { subConceptName, subConceptId, rootConcepts, mark, numerator, denominator } = item;

        if (groupedData.has(subConceptName)) {
          let existingItem = groupedData.get(subConceptName);
          existingItem.numerator += numerator;
          existingItem.denominator += denominator;
        } else {
          groupedData.set(subConceptName, { subConceptId, subConceptName, rootConcepts, numerator, denominator });
        }
      });

      return Array.from(groupedData.values()).map(item => ({
        ...item,
        mark: `${item.numerator}/${item.denominator}`
      }));
    }

    function processConcepts(concepts) {
      const allSubConcepts = concepts.flatMap(concept => concept.subConcepts);
      const groupedSubConcepts = groupAndSumMarks(allSubConcepts);

      return [{
        subConcepts: groupedSubConcepts,
        mark: null
      }];
    }
  }
  {/*
  useEffect(() => {
    const setConceptLevelData = () => {
      let alteredData = [];

      staticData.data.formalListOfStudentWiseResponse.map(x => {
        let concept = [];
        x.concepts.map(item => {
          concept.push({
            id: `${item?.conceptName}`,
            name: item?.conceptName,
            subConcept: item?.subConcepts?.map(subItem => {
              return {
                subConceptName: subItem?.subConceptName,
                rootConcept: subItem?.rootConcepts?.map(rootItem => rootItem?.rootConceptName ? rootItem?.rootConceptName : ''),
                marksArr: {
                  rootMark: subItem?.rootConcepts !== null
                    ? subItem?.rootConcepts?.map(root => root?.marks) : [],
                  subMark: subItem?.mark !== null ? subItem?.mark : ''
                }
              }
            }),
          });
        });

        x['concepts'] = concept;
        x["timeTaken"] = x?.timeTaken;
        x["attemptOn"] = x?.attemptOn;
        x["timePerQuestion"] = x?.avgTimeTaken;
        alteredData.push(x);

        alteredData?.sort((a, b) => Math.round(b?.percentage) - Math.round(a?.percentage));
        console.log(alteredData);
        setStudentData(alteredData);
      });
    }
    setConceptLevelData();
  }, [])
  */}

  const simplifiedTime = (time) => {
    const parts = time?.split(' ');

    let hours = null;
    let minutes = null;
    let seconds = null;

    if (parts?.length) {
      for (const part of parts) {
        if (part.endsWith('Sec')) {
          seconds = part;
        } else if (part.endsWith('Mins') && part !== '00Mins') {
          minutes = part;
        } else if (part.endsWith('Hrs') && part !== '00Hrs') {
          hours = part;
        }
      }
    }

    return `${hours ? hours : ''} ${minutes ? minutes : ''} ${seconds}`
  }

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report');

    // Define cell styles
    const bodyColor = {
      argb: 'CF82E9F5', // Equivalent to RGBA(130, 233, 245, 0.81)
    };

    // Create a custom color for the header
    const headerColor = {
      argb: '4080C0', // Equivalent to RGB(64, 128, 192)
    };

    // Define cell styles
    const headerCellStyle = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: headerColor,
      },
      font: { color: { argb: 'FFFFFF' }, bold: true },
      border: {
        top: { style: 'thin' }, left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      },
      alignment: { vertical: 'middle', horizontal: 'center' },
    };

    const bodyCellStyle = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: bodyColor,
      },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      },
      alignment: { vertical: 'middle', horizontal: 'center' },
      wrapText: true,
    };

    let columnNames;
    if (isSubtopic) {
      columnNames = ['S. No', 'Student', 'Subtopic', 'Marks', 'Percentage', 'Time Taken(Min)', 'Avg. time per Question', 'Attempted on'];
    } else {
      columnNames = ['S. No', 'Student', 'Sub Concept', 'Root-Concept', 'Marks', 'Percentage', 'Time Taken(Min)', 'Avg. time per Question', 'Attempted on'];
    }

    // Add headers to the worksheet
    worksheet.addRow(columnNames);
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.style = headerCellStyle;
    });
    console.log(isSubtopic, studentData)
    if (isSubtopic) {
      let id = 0;
      let lastUnmergedRow = 2;
      let lastStudentId = null;
      studentData?.forEach((student, index) => {
        const studentId = index + 1;
        const studentName = student?.studentName;
        const percentage = Math.round(student?.percentage);
        const timeTaken = student?.timeTaken ? simplifiedTime(student?.timeTaken) : '-';
        const avgTimeTaken = '-';
        const attemptOn = student?.attemptOn;

        student?.subDetails?.forEach((item, subIndex) => {
          const subTopicName = item?.subtopic;
          const marks = item?.marks;

          let rowData;
          if (studentId !== id) {
            rowData = [
              studentId,
              studentName,
              subTopicName,
              marks,
              `${percentage}%`,
              timeTaken,
              avgTimeTaken,
              attemptOn
            ];
            id = studentId;
          } else {
            rowData = [
              '',
              '',
              subTopicName,
              marks,
              '',
              '',
              '',
              ''
            ];
          }

          let backgroundColor = '';
          if (percentage >= 80) {
            backgroundColor = '9BC2E6'; // Green
          } else if (percentage >= 60) {
            backgroundColor = 'C6E0B4'; // Yellow
          } else if (percentage >= 40) {
            backgroundColor = 'FFE699'; // Yellow
          } else {
            backgroundColor = 'FF6699'; // Red
          }

          const dataRow = worksheet.addRow(rowData);

          dataRow.eachCell((cell) => {
            cell.style = {
              ...bodyCellStyle,
              fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
            };
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true, // Wrap text in cells
            };
          });

          if (lastStudentId !== studentId) {
            if (lastUnmergedRow < dataRow.number) {
              worksheet.mergeCells(`A${lastUnmergedRow}:A${dataRow.number - 1}`);
              worksheet.mergeCells(`B${lastUnmergedRow}:B${dataRow.number - 1}`);
              worksheet.mergeCells(`E${lastUnmergedRow}:E${dataRow.number - 1}`);
              worksheet.mergeCells(`F${lastUnmergedRow}:F${dataRow.number - 1}`);
              worksheet.mergeCells(`G${lastUnmergedRow}:G${dataRow.number - 1}`);
              worksheet.mergeCells(`H${lastUnmergedRow}:H${dataRow.number - 1}`);
              lastUnmergedRow = dataRow.number;
            }
            lastStudentId = studentId;
          }
        });
      })
      if (lastStudentId !== null && lastUnmergedRow < worksheet.rowCount) {
        worksheet.mergeCells(`A${lastUnmergedRow}:A${worksheet.rowCount}`);
        worksheet.mergeCells(`B${lastUnmergedRow}:B${worksheet.rowCount}`);
        worksheet.mergeCells(`E${lastUnmergedRow}:E${worksheet.rowCount}`);
        worksheet.mergeCells(`F${lastUnmergedRow}:F${worksheet.rowCount}`);
        worksheet.mergeCells(`G${lastUnmergedRow}:G${worksheet.rowCount}`);
        worksheet.mergeCells(`H${lastUnmergedRow}:H${worksheet.rowCount}`);
      }
    } else {
      let lastUnmergedRow = 2;
      let lastStudentId = null;
      let rowStart = 2;
      let isRootConceptAndNotSub;
      studentData?.forEach((student, index) => {
        const studentId = index + 1;
        const studentName = student?.studentName;
        const percentage = Math.round(student?.percentage);
        const timeTaken = student?.timeTaken ? simplifiedTime(student?.timeTaken) : '-';
        const avgTimeTaken = '-';
        const attemptOn = student?.attemptOn;

        student?.concepts?.forEach(concept => {
          concept?.subConcept?.forEach((subConcept, subIndex) => {
            const subConceptName = subConcept?.subConceptName;
            const rootConcepts = subConcept?.rootConcept ? subConcept?.rootConcept : [];
            const marks = subConcept?.marksArr?.rootMark !== null ? subConcept?.marksArr?.rootMark : [];
            const subItemMark = (subConcept?.marksArr?.subMark !== null || subConcept?.marksArr?.subMark !== '') ? subConcept?.marksArr?.subMark : '';
            // const rootConceptName = subConcept?.rootConcept ? subConcept?.rootConcept[0] : '';
            // const marks = subConcept?.marksArr[0] !== null ? subConcept?.marksArr[0] : '';
            if (rootConcepts?.length > 0 && subItemMark === '') {
              rootConcepts?.forEach((rootConcept, rootIndex) => {
                const rowData = [
                  studentId,
                  studentName,
                  subConceptName,
                  rootConcept,
                  marks[rootIndex],
                  `${percentage}%`,
                  timeTaken,
                  avgTimeTaken,
                  attemptOn,
                ];

                let backgroundColor = '';
                if (percentage >= 80) {
                  backgroundColor = '9BC2E6'; // Green
                } else if (percentage >= 60) {
                  backgroundColor = 'C6E0B4'; // Yellow
                } else if (percentage >= 40) {
                  backgroundColor = 'FFE699'; // Yellow
                } else {
                  backgroundColor = 'FF6699'; // Red
                }

                const dataRow = worksheet.addRow(rowData);
                dataRow.eachCell((cell) => {
                  cell.style = {
                    ...bodyCellStyle,
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
                  };
                  cell.alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                  };
                });

                if (rootConcepts?.length > 1 && rootIndex === 0) {
                  isRootConceptAndNotSub = true;
                  if (rowStart < dataRow.number) {
                    worksheet.mergeCells(`C${rowStart}:C${dataRow.number - 1}`);
                    rowStart = dataRow.number;
                  }
                }

                if (lastStudentId !== studentId) {
                  if (lastUnmergedRow < dataRow.number) {
                    worksheet.mergeCells(`A${lastUnmergedRow}:A${dataRow.number - 1}`);
                    worksheet.mergeCells(`B${lastUnmergedRow}:B${dataRow.number - 1}`);
                    worksheet.mergeCells(`F${lastUnmergedRow}:F${dataRow.number - 1}`);
                    worksheet.mergeCells(`G${lastUnmergedRow}:G${dataRow.number - 1}`);
                    worksheet.mergeCells(`H${lastUnmergedRow}:H${dataRow.number - 1}`);
                    worksheet.mergeCells(`I${lastUnmergedRow}:I${dataRow.number - 1}`);
                    lastUnmergedRow = dataRow.number;
                  }
                  lastStudentId = studentId;
                }
              })
            } else if (rootConcepts?.length > 0 && subItemMark !== '') {
              rootConcepts?.forEach((rootConcept, rootIndex) => {
                const rowData = [
                  studentId,
                  studentName,
                  subConceptName,
                  rootConcept,
                  marks[rootIndex],
                  `${percentage}%`,
                  timeTaken,
                  avgTimeTaken,
                  attemptOn,
                ];

                const subDataRow = [
                  '',
                  '',
                  '',
                  '',
                  subItemMark,
                  '',
                  '',
                  '',
                  '',
                ];

                let backgroundColor = '';
                if (percentage >= 80) {
                  backgroundColor = '9BC2E6'; // Green
                } else if (percentage >= 60) {
                  backgroundColor = 'C6E0B4'; // Yellow
                } else if (percentage >= 40) {
                  backgroundColor = 'FFE699'; // Yellow
                } else {
                  backgroundColor = 'FF6699'; // Red
                }

                const dataRow = worksheet.addRow(rowData);
                dataRow.eachCell((cell) => {
                  cell.style = {
                    ...bodyCellStyle,
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
                  };
                  cell.alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                  };
                });

                const subDataRowObject = worksheet.addRow(subDataRow);
                subDataRowObject.eachCell((cell) => {
                  cell.style = {
                    ...bodyCellStyle,
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
                  };
                  cell.alignment = {
                    vertical: 'middle',
                    horizontal: 'center',
                    wrapText: true,
                  };
                });

                worksheet.mergeCells(`C${dataRow.number}:C${dataRow.number + 1}`);

                if (lastStudentId !== studentId) {
                  if (lastUnmergedRow < dataRow.number) {
                    worksheet.mergeCells(`A${lastUnmergedRow}:A${dataRow.number - 1}`);
                    worksheet.mergeCells(`B${lastUnmergedRow}:B${dataRow.number - 1}`);
                    worksheet.mergeCells(`F${lastUnmergedRow}:F${dataRow.number - 1}`);
                    worksheet.mergeCells(`G${lastUnmergedRow}:G${dataRow.number - 1}`);
                    worksheet.mergeCells(`H${lastUnmergedRow}:H${dataRow.number - 1}`);
                    worksheet.mergeCells(`I${lastUnmergedRow}:I${dataRow.number - 1}`);
                    lastUnmergedRow = dataRow.number;
                  }
                  lastStudentId = studentId;
                }
              })
            } else if (rootConcepts?.length === 0 && subItemMark !== '') {
              const rowData = [
                studentId,
                studentName,
                subConceptName,
                '-',
                subItemMark,
                `${percentage}%`,
                timeTaken,
                avgTimeTaken,
                attemptOn,
              ];

              let backgroundColor = '';
              if (percentage >= 80) {
                backgroundColor = '9BC2E6'; // Green
              } else if (percentage >= 60) {
                backgroundColor = 'C6E0B4'; // Yellow
              } else if (percentage >= 40) {
                backgroundColor = 'FFE699'; // Yellow
              } else {
                backgroundColor = 'FF6699'; // Red
              }

              const dataRow = worksheet.addRow(rowData);
              dataRow.eachCell((cell) => {
                cell.style = {
                  ...bodyCellStyle,
                  fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
                };
                cell.alignment = {
                  vertical: 'middle',
                  horizontal: 'center',
                  wrapText: true,
                };
              });

              if (lastStudentId !== studentId) {
                if (lastUnmergedRow < dataRow.number) {
                  worksheet.mergeCells(`A${lastUnmergedRow}:A${dataRow.number - 1}`);
                  worksheet.mergeCells(`B${lastUnmergedRow}:B${dataRow.number - 1}`);
                  worksheet.mergeCells(`F${lastUnmergedRow}:F${dataRow.number - 1}`);
                  worksheet.mergeCells(`G${lastUnmergedRow}:G${dataRow.number - 1}`);
                  worksheet.mergeCells(`H${lastUnmergedRow}:H${dataRow.number - 1}`);
                  worksheet.mergeCells(`I${lastUnmergedRow}:I${dataRow.number - 1}`);
                  lastUnmergedRow = dataRow.number;
                }
                lastStudentId = studentId;
              }
            }
          })
        })
      });

      if (isRootConceptAndNotSub && (rowStart < worksheet.rowCount)) {
        worksheet.mergeCells(`C${rowStart}:C${worksheet.rowCount}`);
      }

      if (lastStudentId !== null && lastUnmergedRow < worksheet.rowCount) {
        worksheet.mergeCells(`A${lastUnmergedRow}:A${worksheet.rowCount}`);
        worksheet.mergeCells(`B${lastUnmergedRow}:B${worksheet.rowCount}`);
        worksheet.mergeCells(`F${lastUnmergedRow}:F${worksheet.rowCount}`);
        worksheet.mergeCells(`G${lastUnmergedRow}:G${worksheet.rowCount}`);
        worksheet.mergeCells(`H${lastUnmergedRow}:H${worksheet.rowCount}`);
        worksheet.mergeCells(`I${lastUnmergedRow}:I${worksheet.rowCount}`);
      }
    }

    // const gapRow = worksheet.addRow(['', '', '', '', '', '', '', '', '']);
    // gapRow.eachCell((cell, index) => {
    //   cell.style = {
    //     fill: {
    //       type: 'pattern',
    //       pattern: 'solid',
    //       fgColor: { argb: 'FFFFFFFF' },
    //     },
    //     border: {},
    //   };
    // })

    // const legendRow = worksheet.addRow(['', '', '>= 80 Blue', '', '60 - 79 Green', '', '40 - 59 Yellow', '', '<= 39 Red']);

    // legendRow.height = 30;
    // legendRow.eachCell((cell, index) => {
    //   if (index === 0) {
    //     cell.style = { ...bodyCellStyle, border: { top: {}, left: {}, bottom: {}, right: {} } };
    //   } else {
    //     let backgroundColor = '';
    //     switch (index) {
    //       case 2:
    //         backgroundColor = '9BC2E6';
    //         break;
    //       case 4:
    //         backgroundColor = 'C6E0B4';
    //         break;
    //       case 6:
    //         backgroundColor = 'FFE699';
    //         break;
    //       case 8:
    //         backgroundColor = 'FF6699';
    //         break;
    //       default:
    //         break;
    //     }
    //     cell.style = {
    //       ...bodyCellStyle,
    //       border: { top: {}, left: {}, bottom: {}, right: {} },
    //       fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
    //     };
    //   }
    // });
    const gapRow = worksheet.addRow(['', '', '', '', '', '', '', '', '']);
    gapRow.eachCell((cell, index) => {
      cell.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFFF' },
        },
        border: {},
      };
    })

    const legendRow1 = worksheet.addRow(['', '', '>= 80 Blue']);
    legendRow1.height = 30;
    legendRow1.eachCell((cell, index) => {
      if (index === 0) {
        cell.style = { ...bodyCellStyle, border: {} };
      } else {
        let backgroundColor = '';
        switch (index) {
          case 2:
            backgroundColor = '9BC2E6';
            break;

          default:
            break;
        }

        cell.style = {
          ...bodyCellStyle,
          border: {},
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
        };
      }
    });

    const legendRow2 = worksheet.addRow(['', '', '60 - 79 Green']);
    legendRow2.height = 30;
    legendRow2.eachCell((cell, index) => {
      if (index === 0) {
        cell.style = { ...bodyCellStyle, border: {} };
      } else {
        let backgroundColor = '';
        switch (index) {
          case 2:
            backgroundColor = 'C6E0B4';
            break;

          default:
            break;
        }
        cell.style = {
          ...bodyCellStyle,
          border: {},
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
        };
      }
    });

    const legendRow3 = worksheet.addRow(['', '', '40 - 59 Yellow']);
    legendRow3.height = 30;
    legendRow3.eachCell((cell, index) => {
      if (index === 0) {
        cell.style = { ...bodyCellStyle, border: {} };
      } else {
        let backgroundColor = '';
        switch (index) {
          case 2:
            backgroundColor = 'FFE699';
            break;

          default:
            break;
        }
        cell.style = {
          ...bodyCellStyle,
          border: {},
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
        };
      }
    });

    const legendRow4 = worksheet.addRow(['', '', '<= 39 Red']);
    legendRow4.height = 30;
    legendRow4.eachCell((cell, index) => {
      if (index === 0) {
        cell.style = { ...bodyCellStyle, border: {} };
      } else {
        let backgroundColor = '';
        switch (index) {
          case 2:
            backgroundColor = 'FF6699';
            break;

          default:
            break;
        }
        cell.style = {
          ...bodyCellStyle,
          border: {},
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
        };
      }
    });
    const gapRow2 = worksheet.addRow(['', '', '', '', '', '', '', '', '']);
    gapRow2.eachCell((cell, index) => {
      cell.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFFF' },
        },
        border: {},
      };
    });
    const headerColor1 = {
      argb: '4080C0',
    };

    const headerCellStyle1 = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: headerColor1,
      },
      font: { color: { argb: 'FFFFFF' }, bold: true },
      border: {
        top: { style: 'thin' }, left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      },
      alignment: { vertical: 'middle', horizontal: 'center' },
    };
    const columnNames1 = [
      'S. No',
      'Absentees',
    ];

    worksheet.addRow(columnNames1);
    // console.log("formal danger",studentData?.length)
    const headerRow1 = worksheet.getRow(studentData?.length + 2);
    // headerRow1.eachCell((cell) => {
    //   cell.style = headerCellStyle1;
    // });

    absenteeData?.forEach((student, index) => {
      const studentId = index + 1;
      const studentName = student?.name;

      const rowData = [
        studentId,
        studentName
      ];

      let backgroundColor = 'FFFFFF';
      const dataRow = worksheet.addRow(rowData);

      dataRow.eachCell((cell) => {
        cell.style = {
          ...bodyCellStyle,
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: backgroundColor } },
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true, // Wrap text in cells
        };
      });

    });
    worksheet.columns.forEach((column, index) => {
      if (index === 0) {
        column.width = 5; // S. No column width
      } else {
        column.width = 25; // Other columns width
      }
    });

    // Generate the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'report.xlsx';
      a.click();
      setDownloadReport(false);
    });
  }

  useEffect(() => {
    if (downloadReport) {
      downloadExcel();
    }
    setDownloadReport(false);
  }, [downloadReport]);

  const redColor = "#ff6698";
  const yellowColor = "#fde9a0";
  const greenColor = "#c3e1b7";
  const blueColor = "#9cc0e4";

  const convertToInt = stringVal => {
    const percentageValue = stringVal;
    return percentageValue <= 39
      ? redColor
      : percentageValue <= 59
        ? yellowColor
        : percentageValue <= 79
          ? greenColor
          : blueColor
  }

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        padding: "10px",
        overflow: "hidden",
        boxShadow: "0px 0px 0px 0px",
      }}
    >
      {loading &&
        <div
          style={{
            position: 'relative',
            fontSize: '18px',
            fontWeight: 500,
            color: '#9b9fa7',
            opacity: 0.8,
          }}
        >
          FETCHING...
        </div>
      }
      {!loading &&
        (data === null ||
          data?.length === 0 || studentData?.length === 0) &&
        <div
          style={{
            position: 'relative',
            fontSize: '18px',
            fontWeight: 500,
            color: '#9b9fa7',
            opacity: 0.8,
          }}
        >
          NO DATA AVAILABLE
        </div>
      }
      {!loading &&
        studentData !== null &&
        studentData?.length > 0 &&
        isSubtopic &&
        <>
          <TableContainer sx={{ maxHeight: 600, overflow: "auto", scrollbarWidth: "thin" }}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead sx={{ overflowY: "auto", minWidth: "1152px" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    S. No
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    Student
                    <img
                      // style={{ position: studentDataTemp.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("studentName");
                        //await initData(!sortOrder, "grade");
                        await onSort1(!sortOrder, "studentName")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    Subtopic
                    <img
                      // style={{ position: studentDataTemp.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("subtopic");
                        //await initData(!sortOrder, "grade");
                        await onSort1(!sortOrder, "subtopic")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    Marks <img
                      // style={{ position: studentDataTemp.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("percentage");
                        //await initData(!sortOrder, "grade");
                        await onSort1(!sortOrder, "percentage")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    Percentage
                    <img
                      // style={{ position: studentDataTemp.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("percentage");
                        //await initData(!sortOrder, "grade");
                        await onSort1(!sortOrder, "percentage")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    Time Taken(Min)
                    <img
                      // style={{ position: studentDataTemp.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("timeTaken");
                        //await initData(!sortOrder, "grade");
                        await onSort1(!sortOrder, "timeTaken")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    Avg. time per Question
                    <img
                      // style={{ position: studentDataTemp.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("avgTimeTaken");
                        //await initData(!sortOrder, "grade");
                        await onSort1(!sortOrder, "avgTimeTaken")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    Attempted on
                    <img
                      // style={{ position: studentDataTemp.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("attemptOn");
                        //await initData(!sortOrder, "grade");
                        await onSort1(!sortOrder, "attemptOn")
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  // console.log("studentData", studentData)
                }
                {studentData?.map((item, id) => {
                  return (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                        color: "black",
                      }}
                      key={id}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          textAlign: 'center',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      >
                        {id + 1}
                      </TableCell>
                      <TableCell
                        align={"left"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontWeight: "900",
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      >
                        {item?.studentName}
                      </TableCell>
                      <TableCell
                        align={"left"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          padding: 0
                        }}
                      >
                        {item?.subDetails !== item?.subDetails?.toString()
                          ? item?.subDetails?.map((sub, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  padding: '5px 10px',
                                  borderBottom: item?.subDetails?.length - 1 === index
                                    ? 0 : '1px solid #cacaca'
                                }}
                                key={sub?.subConceptId}
                              >
                                <div>{sub?.subtopic}</div>
                              </div>
                            );
                          })
                          : item?.subDetails}
                      </TableCell>
                      <TableCell
                        align={"left"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          padding: 0
                        }}
                      >
                        {item?.subDetails !== item?.subDetails?.toString()
                          ? item?.subDetails?.map((sub, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: '5px 10px',
                                  borderBottom: item?.subDetails?.length - 1 === index
                                    ? 0 : '1px solid #cacaca'
                                }}
                                key={sub?.subConceptId}
                              >
                                <div>{sub?.marks}</div>
                              </div>
                            );
                          })
                          : item?.subDetails}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontWeight: "900",
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      >
                        {`${Math.round(item?.percentage) || 0}`}%
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      >
                        {item?.timeTaken ? simplifiedTime(item?.timeTaken) : ''}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      >
                        {item?.avgTimeTaken ? simplifiedTime(item?.avgTimeTaken) : '-'}
                        {/* {'-'} */}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      >
                        {item?.attemptOn ? item?.attemptOn : ''}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "1rem",
              padding: "10px",
            }}
          >
            <div
              style={{
                background: blueColor,
                padding: "8px",
              }}
            />
            <div>{`>=`} 80 Blue</div>
            <div
              style={{
                background: greenColor,
                padding: "8px",
              }}
            />
            <div>60 - 79 Green</div>
            <div
              style={{
                background: yellowColor,
                padding: "8px",
              }}
            />
            <div>40 - 59 Yellow</div>
            <div
              style={{
                background: redColor,
                padding: "8px",
              }}
            />
            <div> {'<='} 39 Red</div>
          </div>
        </>
      }
      {!loading &&
        studentData !== null &&
        studentData?.length > 0 &&
        !isSubtopic &&
        <>
          <TableContainer sx={{ maxHeight: 600, overflow: "auto", scrollbarWidth: "thin" }}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead sx={{ overflowY: "auto", minWidth: "1152px" }}>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    S. No
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    Student
                    <img
                      // style={{ position: studentDataTemp.length > 0 ? "absolute" : '', top: studentDataTemp.length > 0 ? "" : "34px" }}
                      style={{ position: (loggedInRole === "TEACHER" && studentDataTemp.length > 0) ? "absolute" : '', top: "" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("studentName");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "studentName")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #d0cdcd',
                      borderCollapse: 'collapse',
                      lineHeight: 1.2,
                      padding: 0,
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <div
                        style={{
                          padding: 10,
                          borderRight: '2px solid #d0cdcd',
                          height: '58px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 150,
                          fontWeight: 300
                        }}
                      >
                        Sub Concept
                      </div>
                      {/* <div
                        style={{
                          padding: 10,
                          borderRight: '2px solid #d0cdcd',
                          height: '58px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 150,
                          fontWeight: 300
                        }}
                      >
                        Root Concept
                      </div> */}
                      <div
                        style={{
                          padding: 10,
                          margin: 'auto',
                          fontWeight: 300
                        }}
                      >
                        Marks
                        <img
                          // style={{ position: studentDataTemp.length > 0 ? "absolute" : '', top: studentDataTemp.length > 0 ? "" : "34px" }}
                          style={{ position: (loggedInRole === "TEACHER" && studentDataTemp.length > 0) ? "absolute" : '', top: "" }}
                          className="cursor-pointer"
                          width="12"
                          height="15"
                          src={sortIcon}
                          onClick={async () => {
                            await setSortOrder(!sortOrder);
                            await setSortBy("percentage");
                            //await initData(!sortOrder, "grade");
                            await onSort(!sortOrder, "percentage")
                          }}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    Percentage
                    <img
                      // style={{ position: studentDataTemp.length > 0 ? "absolute" : '', top: studentDataTemp.length > 0 ? "" : "34px" }}
                      style={{ position: (loggedInRole === "TEACHER" && studentDataTemp.length > 0) ? "absolute" : '', top: "" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("percentage");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "percentage")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif',
                      display: 'flex',
                      flexDirection: 'row', // This ensures content is aligned vertically in a straight line
                      alignItems: 'center', // Center align content horizontally
                      position: 'relative',
                    }}
                  >
                    Time Taken(Min)
                    <img
                      // style={{ position: studentDataTemp.length > 0 ? "absolute" : '', top: studentDataTemp.length > 0 ? "" : "34px" }}
                      style={{ position: (loggedInRole === "TEACHER" && studentDataTemp.length > 0) ? "absolute" : '', top: "" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("timeTaken");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "timeTaken")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    Avg. time per Question
                    <img
                      style={{ position: (loggedInRole === "TEACHER" && studentDataTemp.length > 0) ? "absolute" : '', top: "" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("avgTimeTaken");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "avgTimeTaken")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 300,
                      background: "#403E75",
                      color: "#fff",
                      fontSize: '14px',
                      border: '1px solid #cacaca',
                      borderCollapse: 'collapse',
                      fontFamily: 'Poppins, sans-serif'
                    }}
                  >
                    Attempted on
                    <img
                      // style={{ position: studentDataTemp.length > 0 ? "absolute" : '', top: studentDataTemp.length > 0 ? "" : "34px" }}
                      style={{ position: (loggedInRole === "TEACHER" && studentDataTemp.length > 0) ? "absolute" : '', top: "" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("attemptOn");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "attemptOn")
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentDataTemp?.map((item, id) => {
                  return (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                        color: "black",
                      }}
                      key={id}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          textAlign: 'center',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      >
                        {id + 1}
                      </TableCell>
                      <TableCell
                        align={"left"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontWeight: "900",
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          textTransform: 'capitalize',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      >
                        {item?.studentName}
                      </TableCell>
                      <TableCell
                        align={"top"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '12px',
                          border: '1px solid #d0cdcd',
                          borderCollapse: 'collapse',
                          textAlign: 'center',
                          padding: 0
                        }}
                      >
                        {item?.concepts?.map((sub, index) => {
                          return (
                            <React.Fragment key={index}>
                              {sub?.subConcepts?.map((subItem, ind) =>
                                <div
                                  key={ind}
                                  style={{ display: 'flex', minHeight: 52 }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderBottom: item?.concepts?.length - 1 === index && sub?.subConcepts?.length - 1 === ind
                                        ? 0 : '1px solid #d0cdcd',
                                      padding: '0 5px',
                                      width: 150,
                                      borderRight: '2px solid #d0cdcd'
                                    }}
                                    key={`${sub?.id}${ind}`}
                                  >
                                    {subItem?.subConceptName}
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      flexGrow: 1,
                                      borderBottom: (item?.concepts?.length - 1 === index && sub?.subConcept?.length - 1 === ind)
                                        ? 0 : '1px solid #d0cdcd',
                                    }}
                                  >
                                    {subItem?.mark &&
                                      // subItem?.marksArr?.rootMark?.map((mark, i) =>
                                      <div
                                        // key={`${sub?.id}${i}`}
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          minHeight: 60,
                                          borderBottom: `${subItem?.mark ? 0 : '1px solid #d0cdcd'}`,
                                        }}
                                      >
                                        {subItem?.mark}
                                      </div>
                                      // )
                                    }
                                    {/* {subItem?.marksArr?.subMark !== '' &&
                                      <div
                                        style={{
                                          borderTop: '1px solid #d0cdcd',
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          minHeight: 60
                                        }}
                                      >
                                        {subItem?.marksArr?.subMark}
                                      </div>
                                    } */}
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })
                        }
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontWeight: "900",
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      >
                        {`${Math.round(item?.percentage) || 0}`}%
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      >
                        {item?.timeTaken ? simplifiedTime(item?.timeTaken) : ''}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      >
                       {item?.avgTimeTaken ? simplifiedTime(item?.avgTimeTaken) : '-'} 
                        {/*{'-'} */}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        style={{
                          background: convertToInt(item?.percentage),
                          fontSize: '14px',
                          border: '1px solid #cacaca',
                          borderCollapse: 'collapse',
                          fontFamily: 'Poppins, sans-serif'
                        }}
                      >
                        {item?.attemptOn ? item?.attemptOn : ''}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "1rem",
              padding: "10px",
            }}
          >
            <div
              style={{
                background: blueColor,
                padding: "8px",
              }}
            />
            <div>{`>=`} 80 Blue</div>
            <div
              style={{
                background: greenColor,
                padding: "8px",
              }}
            />
            <div>60 - 79 Green</div>
            <div
              style={{
                background: yellowColor,
                padding: "8px",
              }}
            />
            <div>40 - 59 Yellow</div>
            <div
              style={{
                background: redColor,
                padding: "8px",
              }}
            />
            <div> {'<='} 39 Red</div>
          </div>
        </>
      }
    </Paper>
  );
};
export default StudentWisePerformance;
