import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import conceptDetStyles from "./../css/conceptDetail.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import conceptTree_count_get from "../API_CONTROLLER/concept-controller/conceptTree_count_get";
import concept_last_modified_get from "../API_CONTROLLER/concept-controller/concept_last_modified_get";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import dashHome from "../../../src/img/dashHome.svg";

const ConceptDetails = () => {
  const [gradesName, setGradesName] = useState({});
  const [subject, setSubject] = useState({});
  const [conceptTreeCount, setConceptTreeCount] = useState(null);
  const [lastUpdated, setLastUpdated] = useState("");

  useEffect(() => {
    subjects_controller_getall(setSubject);
    GradesControllerGetall(setGradesName);
    conceptTree_count_get(setConceptTreeCount);
    concept_last_modified_get(setLastUpdated);
    return () => {};
  }, []);

  return (
    <>
      <div>
        <div>
          <article>
            <div className={dashboardContentStyles.dashboard_link}>
              <span className={dashboardContentStyles.link_icon}>
                <img
                  src={dashHome}
                  alt="no img"
                  width="15px"
                  height="20px"
                  style={{ verticalAlign: "middle" }}
                />{" "}
              </span>
              {"  "}
              <span className={dashboardContentStyles.link_text}>Home</span>
              {"  "}
              <span>
                <i className="fa-solid fa-angle-right"></i>
              </span>
              <span>
                <i className="fa-solid fa-angle-right"></i>
              </span>
              {"  "}
              <a>Dashboard</a>
            </div>
            <div className={dashboardContentStyles.dashboard_last_updated}>
              <p>Last Update: {lastUpdated.data}</p>
            </div>
          </article>
          {/* total que row */}
          <div
            style={{
              display: "flex",
              marginTop: "54px",
              marginLeft: "15px",
            }}
            className={conceptDetStyles.flex}
          >
            <div>
              <div className={conceptDetStyles.flex}>
                <strong style={{ fontSize: "30px", fontWeight: "400" }}>
                  Total Questions:
                </strong>
                <h4
                  style={{
                    fontSize: "36px",
                    color: "blue",
                    marginLeft: "20px",
                  }}
                >
                  {conceptTreeCount
                    ? conceptTreeCount.allTypeConcepts[0]?.totalQuestion +
                      conceptTreeCount.allTypeConcepts[1]?.totalQuestion +
                      conceptTreeCount.allTypeConcepts[2]?.totalQuestion
                    : "-"}
                </h4>
              </div>
            </div>

            <div
              className={conceptDetStyles.flex}
              style={{ position: "absolute", right: "10px" }}
            >
              <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 150 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small">Grade</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    label="Board"
                    //   onChange={selectBoardHandler}
                    style={{ background: "#fff", borderRadius: 150 }}
                    //   MenuProps={MenuProps}
                  >
                    {gradesName.length > 0 ? (
                      gradesName.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}>
                            <ListItemText primary={elem.grade} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box style={{ margin: "auto 8px" }} sx={{ minWidth: 180 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small">Select subject</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    // value={board}
                    label="Grade"
                    //   onChange={selectGradeHandler}
                    style={{ background: "#fff", borderRadius: 150 }}
                    //   MenuProps={MenuProps}
                  >
                    {subject.length > 0 ? (
                      subject.map((subject, i) => {
                        return (
                          <MenuItem key={i} value={subject.subject}>
                            <ListItemText primary={subject.subject} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                    {/* {gradeList?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <ListItemText primary={item.grade} />
                    </MenuItem>
                  ))} */}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
          <hr
            style={{ margin: "auto 10px", width: "100%", position: "absolute" }}
          />

          {/* 2nd detail start */}
          <div style={{ margin: "15px 15px" }}>
            <div
              className={conceptDetStyles.flex}
              style={{ marginTop: "40px", display: "flex", flexWrap: "wrap" }}
            >
              {/* box */}
              <Link
                to="/dashboard/subCocepttree"
                style={{ textDecoration: "none" }}
              >
                <div
                  style={{
                    height: "120px",
                    width: "354px",
                    margin: "10px 20px",
                    backgroundColor: " yellow",
                    border: "1px solid #707070",
                    borderRadius: "6px",
                    background:
                      "transparent linear-gradient(180deg, #FFFFFF 0%, #EDEAF8 100%) 0% 0% no-repeat padding-box",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", marginBottom: "8px" }}>
                    <div>
                      {" "}
                      <p
                        style={{
                          fontSize: "22px",
                          lineHeight: "29px",
                          margin: "10px 10px",
                          color: "black",
                        }}
                      >
                        Total <br /> Concepts
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "auto",
                        marginRight: "20px",
                      }}
                    >
                      <h3 style={{ fontSize: "13px" }}>Total Questions</h3>

                      <p
                        style={{
                          color: "blue",
                          fontSize: "36px",
                          margin: "auto",
                          textAlign: "center",
                        }}
                      >
                        {conceptTreeCount?.allTypeConcepts[0]?.totalQuestion}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <p
                      style={{
                        color: "black",
                        marginLeft: "8px",
                        fontSize: "15px",
                      }}
                    >
                      {conceptTreeCount?.allTypeConcepts[0]?.lastUpdated}
                    </p>
                  </div>
                </div>
              </Link>

              <Link
                to="/dashboard/subCocepttree"
                style={{ textDecoration: "none" }}
              >
                <div
                  style={{
                    height: "120px",
                    width: "354px",
                    margin: "10px 20px",
                    backgroundColor: " yellow",
                    border: "1px solid #707070",
                    borderRadius: "6px",
                    background:
                      "transparent linear-gradient(180deg, #FFFFFF 0%, #EDEAF8 100%) 0% 0% no-repeat padding-box",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", marginBottom: "8px" }}>
                    <div>
                      {" "}
                      <p
                        style={{
                          fontSize: "22px",
                          lineHeight: "29px",
                          margin: "10px 10px",
                          color: "black",
                        }}
                      >
                        Total <br /> Sub-concepts
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "auto",
                        marginRight: "20px",
                      }}
                    >
                      <h3 style={{ fontSize: "13px" }}>Total Questions</h3>
                      <p
                        style={{
                          color: "blue",
                          fontSize: "36px",
                          margin: "auto",
                          textAlign: "center",
                        }}
                      >
                        {conceptTreeCount?.allTypeConcepts[1]?.totalQuestion}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <p
                      style={{
                        color: "black",
                        marginLeft: "8px",
                        fontSize: "15px",
                      }}
                    >
                      {conceptTreeCount?.allTypeConcepts[1]?.lastUpdated}
                    </p>
                  </div>
                </div>
              </Link>

              <Link
                to="/dashboard/subCocepttree"
                style={{ textDecoration: "none" }}
              >
                <div
                  style={{
                    height: "120px",
                    width: "354px",
                    margin: "10px 20px",
                    backgroundColor: " yellow",
                    border: "1px solid #707070",
                    borderRadius: "6px",
                    background:
                      "transparent linear-gradient(180deg, #FFFFFF 0%, #EDEAF8 100%) 0% 0% no-repeat padding-box",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", marginBottom: "8px" }}>
                    <div>
                      {" "}
                      <p
                        style={{
                          fontSize: "22px",
                          lineHeight: "29px",
                          margin: "10px 10px",
                          color: "black",
                        }}
                      >
                        Root <br /> Concepts
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "auto",
                        marginRight: "20px",
                      }}
                    >
                      <h3 style={{ fontSize: "13px" }}>Total Questions</h3>
                      <p
                        style={{
                          color: "blue",
                          fontSize: "36px",
                          margin: "auto",
                          textAlign: "center",
                        }}
                      >
                        {conceptTreeCount?.allTypeConcepts[2]?.totalQuestion}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <p
                      style={{
                        color: "black",
                        marginLeft: "8px",
                        fontSize: "15px",
                      }}
                    >
                      {conceptTreeCount?.allTypeConcepts[2]?.lastUpdated}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          {/* 2nd detail end */}
        </div>
      </div>
    </>
  );
};

export default ConceptDetails;
