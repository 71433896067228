import styled from "styled-components";
import { Button, Checkbox } from "@mui/material";
import { grey } from "@mui/material/colors";

export const listItemStyle = {
  "& .MuiListItem-container": {
    color: "white",
    background: "red !important",
  },
  height: "56px",
  border: "1px solid #7070706B",
  marginTop: "8px",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "15px",
  color: "#354052",
  background: "#fff",
};

export const NotEverythingIsUnchecked = (templates) => {
  let arrayOfChecked = [];
  templates.forEach((subjectItem) => {
    //each subject
    delete subjectItem.active;
    const values = Object.values(subjectItem);
    const containsTrue = values.includes(true);
    arrayOfChecked.push(containsTrue);
  });

  return arrayOfChecked.includes(true);
};

export const UpdatedButton = styled(Button)((props) => ({
  color: "#fff",
  width: 170,
  height: 40,
  fontSize: "14px",
  backgroundColor: props?.disabled ? "#ccc" : "blue",
  borderRadius: 150,
  borderColor: grey[500],
  "&:hover": {
    color: "#fff",
    backgroundColor: "blue",
    border: 0,
    top: -2,
    boxShadow: "0px 3px 5px blue",
  },
}));
