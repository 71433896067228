import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Box } from "@mui/material";

//import SemiCircleProgressBar from "react-progressbar-semicircle";
export default function CircleProgress({height,width,value,text,color}) {
  return (
        <Box
          sx={{
            height: height,
            width: width,
          }}
        >
          <CircularProgressbar
            value={value}
            text={text}
            styles={{
              path: { stroke: color },
              trailColor: "grey",
            }}
          />
         
        </Box>
  );
}
