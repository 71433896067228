import { useState, useEffect } from 'react';

export function useShowButtonsBasedOnItems(numberOfItems) {
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    const checkConditionsAndSetShowButtons = () => {
      // Get the window width
      const windowWidth = window.innerWidth;

      // Check if the number of items exceeds 4 and the window width is 720 or greater
      if (numberOfItems > 5 && windowWidth >= 1377) {
        // console.log('items above 5 and above should be shown',numberOfItems)
        setShowButtons(true);
      } else if(numberOfItems >= 4 && windowWidth < 1377) {
        // console.log('items  4 and above should be shown',numberOfItems)

        setShowButtons(true);
      }else {
        setShowButtons(false);
      }
    };

    // Call the function to check conditions and update showButtons
    checkConditionsAndSetShowButtons();

    // Add an event listener to handle window resize
    window.addEventListener('resize', checkConditionsAndSetShowButtons);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkConditionsAndSetShowButtons);
    };
  }, [numberOfItems]);

  return showButtons;
}
