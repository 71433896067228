import React from "react";
import styled from "styled-components";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import UpdatedAt from "../UpdatedAt";

function BreadCrumbs({ currentPage, date, time }) {
	return (
		<Container>
			<LeftSection>
				<Icon>
					<HomeOutlinedIcon />
				</Icon>
				{/* <p>{"Home >"}</p>{" "}
				<CurrentPage onClick={() => console.log("hi")}>
					{currentPage}
				</CurrentPage> */}
				<span style={{ cursor: 'pointer' }} className="az_menu_text">Home</span>
				<span>
					<i className="fa-solid fa-angle-right"
						style={{ color: "#828282" }} />
				</span>
				<span>
					<i className="fa-solid fa-angle-right"
						style={{ color: "#828282" }} />
				</span>
				<CurrentPage className="az_selected_menu_text"
					style={{ color: "orange", cursor: 'default' }}
					onClick={() => console.log("hi")}>
					{currentPage}
				</CurrentPage>
			</LeftSection>
			<UpdatedAt />
		</Container>
	);
}

export default BreadCrumbs;
const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #707070;
	margin-bottom: 10px;
`;
const LeftSection = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	p {
		font-size: 13px !important;
	}
`;
const RightSection = styled.div`
	p {
		font-size: 13px !important;
	}
`;
const Icon = styled.span`
	display: block;
	width: 20px;
	svg {
		color: #7a7a7a;
		width: 100%;
	}
`;
const CurrentPage = styled.p`
	font-size: 13px;
	color: #001af3;
	cursor: pointer;
`;
