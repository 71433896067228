import axios from 'axios'

async function SchoolControllerIdPut(schoolInputs,num, callback) {
  let token = sessionStorage.getItem("token");
  let item = {
    lmsEnv: process.env.REACT_APP_LMS_ENV,
    boardId: schoolInputs.boardId,
    cityId: schoolInputs.cityId,
    code: schoolInputs.code,
    hasBranch: true,

    logoUrl: schoolInputs.logoUrl,
    name: schoolInputs.name,
    phoneNumber: schoolInputs.phoneNumber,

    pocEmail: schoolInputs.pocEmail,
    signatoryName: schoolInputs.signatoryName,
    signatoryRole: schoolInputs.signatoryRole,
    website: schoolInputs.website,
  };

    let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/${sessionStorage.getItem("schoolid")}`,item,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp)=>{
      // console.log(resp)
      callback(resp)
    })
    .catch(err=>console.log(err))
}

export default SchoolControllerIdPut;
