import axios from 'axios'
import { isNOTNullOrUndefined } from '../../../../../utils/helper'

export const fetchPrincipalDashboardQuizPerformance = async (
  teacherId,
  gradeId
) => {
  const token = sessionStorage.getItem('token')
  console.log(teacherId, gradeId, 'ids')

  const getGradeId = () => {
    if (isNOTNullOrUndefined(gradeId) && isNOTNullOrUndefined(teacherId)) {
      return `&gradeId=${gradeId}`
    } else if (isNOTNullOrUndefined(gradeId)) {
      return `?gradeId=${gradeId}`
    } else {
      return ''
    }
  }
  let url = `${
    process.env.REACT_APP_SWAGGER_URL
  }v1/api/user/dashboard/grade-wise-quiz-performance/principal${
    isNOTNullOrUndefined(teacherId) ? `?teacherId=${teacherId}` : ''
  }${getGradeId()}`

  const res = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
  return res?.data?.data || []
}

export const fetchPrincipalDashboardQuizPerformanceForTeacher = async (
  // teacherId,
  gradeId, 
  subjectId
) => {
  const token = sessionStorage.getItem('token')

  // let url = `${
  //   process.env.REACT_APP_SWAGGER_URL
  // }v1/api/user/dashboard/grade-wise-quiz-performance?teacherId=${teacherId}${isNOTNullOrUndefined(gradeId) ? `&gradeId=${gradeId}`:''}`

  let url = `${
    process.env.REACT_APP_SWAGGER_URL
  }v1/api/user/dashboard/grade-wise-quiz-performance/without-teacherId?${isNOTNullOrUndefined(gradeId) ? `gradeId=${gradeId}`:''}${isNOTNullOrUndefined(subjectId) ? `&subjectId=${subjectId}`:''}`

 try {
  const res = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
  return res?.data?.data || []
 } catch (error) {
  return []
 }
}

export const fetchGradesDetails = async () => {
  const token = sessionStorage.getItem('token')
  // const gradeId = sessionStorage.getItem("gradeId");
  // const teacherId = sessionStorage.getItem("teacherId");

  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/dashboard/grades-students/details`

  const res = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
  return res?.data?.data || []
}

export const fetchGradeOptions = async teacherId => {
  const token = sessionStorage.getItem('token')
  // const gradeId = sessionStorage.getItem("gradeId");
  // const teacherId = sessionStorage.getItem("teacherId");

  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${teacherId}`

  const res = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
  return res?.data?.data || []
}

export const fetchTeachersOptions = async (teacherId, gradeId) => {
  const token = sessionStorage.getItem('token')
  // const gradeId = sessionStorage.getItem("gradeId");
  // const teacherId = sessionStorage.getItem("teacherId");

  let url = `${
    process.env.REACT_APP_SWAGGER_URL
  }v1/api/user/teachers/for-dashboard${
    isNOTNullOrUndefined(gradeId) ? `?gradeId=${gradeId}` : ''
  }`

  const res = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
  return res?.data?.data || []
}

export const fetchDashboardSubjectWisePerformance = async (
  gradeId,
  teacherId,
  sectionId
) => {
  const token = sessionStorage.getItem('token')

  let url = `${
    process.env.REACT_APP_SWAGGER_URL
  }v1/api/user/dashboard/grade-subject-wise-performance/principal?gradeId=${gradeId}${
    isNOTNullOrUndefined(teacherId) ? `&teacherId=${teacherId}` : ''
  }${isNOTNullOrUndefined(sectionId) ? `&sectionId=${sectionId}` : ''}`
  try {
    const res = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    return res?.data?.data || []
  } catch (error) {
    return []
  }
}

export const fetchDashboardChapterwisePerformance = async (
  gradeId,
  subjectId,
  teacherId,
  sectionId,
  subTopicId
) => {
  const token = sessionStorage.getItem('token')

  let url = `${
    process.env.REACT_APP_SWAGGER_URL
  }v1/api/user/dashboard/chapter-wise-quiz-performance?gradeId=${gradeId}&subjectId=${subjectId}${
    isNOTNullOrUndefined(teacherId) ? `&teacherId=${teacherId}` : ''
  }${isNOTNullOrUndefined(sectionId) ? `&sectionId=${sectionId}` : ''}${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ''}`
  try {
    const res = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    return res?.data?.data || []
  } catch (error) {
    return []
  }
}



//fetch dashboard pricipal v2 for teachers