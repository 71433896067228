import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";

const BlueButton = (props) => {
  const UpdatedButton = styled(Button)(() => ({
    color: "#fff",
    width: 150,
    fontSize:"14px",
    backgroundColor: "#403e75",
    borderRadius: 150,
    borderColor: grey[500],
    "&:hover": {
      color: "#fff",
      // backgroundColor: "blue",
      backgroundColor : "#2c2b51",
      
      border: 0,
      top: -2,
      // boxShadow: "0px 3px 5px blue",
      boxShadow: "0px 3px 5px #2c2b51 ",
    },
  }));
  return (
    <UpdatedButton onClick={props.onClick}>{props.children}</UpdatedButton>
  );
};
export default BlueButton;
