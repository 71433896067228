import axios from "axios";

async function SchoolController_post(schoolInputs,num) {
  let token = sessionStorage.getItem("token");
  var item = {
    lmsEnv: process.env.REACT_APP_LMS_ENV,
    cityId: schoolInputs.cityId,
    code: schoolInputs.code,
    hasBranch: schoolInputs.hasBranch,

    logoUrl: schoolInputs.logoUrl,
    name: schoolInputs.name,
    phoneNumber: num,

    pocEmail: schoolInputs.pocEmail,
    signatoryName: schoolInputs.signatoryName,
    signatoryRole: schoolInputs.signatoryRole,
    website: schoolInputs.website,
  };
  return axios.post(
    `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools`,
    item,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      validateStatus() {
        return true;
      },
    }
  );
}

export default SchoolController_post;
