import { RowingSharp } from "@mui/icons-material";
import axios from "axios";

async function fileStatus_approved_get(
  setChapters,
  rowsInput,
  page,
  setPageDetail,
  search,
  subjectInput,
  sortBy,sortOrder
 
) {
  let token = sessionStorage.getItem("token");

  var URL = `${
    process.env.REACT_APP_SWAGGER_URL
  }v1/api/content/concepts/sub-roots-mapping?pageSize=1000&sortOrder=${sortOrder}&sortBy=${sortBy}&subjectId=${subjectInput}&reviewStatus=REJECTED`;

  let result = await axios
    .get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json) => {
      if (json.data.data) {
        setChapters(json.data.data.data);
        setPageDetail(json.data.data);
      }
    })
    .catch((err) => console.log(err));
}

export default fileStatus_approved_get;
