import axios from 'axios'

async function SectionsControllerGetall(setSectionsName) {
  let token = sessionStorage.getItem("token");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sections/all`,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setSectionsName(json.data.data)
    })
    .catch(err=>console.log(err))
}

export default SectionsControllerGetall;
