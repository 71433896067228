import React from "react";
import { isNOTNullOrUndefined } from "../../TeachValidate";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import SubjectTable from "../SubjectTable";
import { listItemStyle } from "../Data/dataHelpers";
import { Collapse, ListItem } from "@mui/material";
import { Checkbox } from "@mui/material";

const GradePlansListView = ({
  listContainer,
  planDetails,
  searchParam,
  handleGradeCheck,
  selectedGrades,
  handleExpand,
  selectedGradeId,
  setAllSelected,
  allFieldsSelectedInGrade,
  handlePlanTemplateData,
}) => {
  return (
    <div style={listContainer}>
      {planDetails
        ?.filter((i) =>
          isNOTNullOrUndefined(searchParam)
            ? i.grade.toLowerCase().includes(searchParam)
            : i
        )
        .map((i) => (
          <div key={i.gradeId}>
            <ListItem
              className="cursorPointer"
              sx={listItemStyle}
              onClick={() => handleExpand(i.gradeId)}
            >
              <div>
                <Checkbox
                  sx={{
                    color: "#001af3",
                    "&.Mui-checked": {
                      color: "#001af3",
                    },
                  }}
                  onClick={(e) => handleGradeCheck(e, i.gradeId)}
                  checked={selectedGrades?.includes(i.gradeId)}
                />
                {i.grade}{" "}
              </div>
              <div>
                {i.gradeId === selectedGradeId ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </div>
            </ListItem>
            <Collapse
              in={selectedGradeId === i.gradeId}
              timeout="auto"
              unmountOnExit
            >
              <SubjectTable
                setAllSelected={setAllSelected}
                allFieldsSelectedInGrade={allFieldsSelectedInGrade}
                planDetails={i.planTemplates}
                handlePlanTemplateData={handlePlanTemplateData}
                gradeId={i.gradeId}
              />
            </Collapse>
          </div>
        ))}
    </div>
  );
};

export default GradePlansListView;
