import axios from "axios";
import { isNOTNullOrUndefined } from "../../../../utils/helper";

export const fetchAllBluePrints = async () => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/blueprint-level`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const fetchBluePrintLevels = async () => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/blueprint-level/with-or-without/ids
  `;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const createBluePrints = async (data) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/blueprint-level`;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(url, data, options);
    return res?.data || [];
  } catch (error) {
    return error.response.data;
  }
};

export const bluePrintsToggle = async (data) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/blueprint-level/toggle`;

  const res = await axios.put(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data || [];
};

export const bluePrintsPaperType = async () => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/paper-type/all`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const bluePrintsGrades = async (boardId) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/from-subject-mapping?boardId=${boardId}`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const bluePrintsAllSubjects = async () => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/all`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const bluePrintsAllGrades = async (boardId) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const bluePrintsSubjects = async (boardId, gradeId) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subject-mapping/subjects?boardId=${boardId}&gradeId=${gradeId}`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const bluePrintsBoards = async () => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/boards/all`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const fetchBluePrintsListForListView = async (
  pageNumber,
  pageSize,
  boardId,
  gradeId,
  subjectId,
  // subTopicId,
  search,
  reviewStatus
) => {
  const token = sessionStorage.getItem("token");
  let url = `${
    process.env.REACT_APP_SWAGGER_URL
  }v1/api/content/blueprint/page?pageNumber=${pageNumber}&pageSize=${pageSize}&sortOrder=true&sortBy=createdAt${
    isNOTNullOrUndefined(boardId) ? `&boardId=${boardId}` : ""
  }${isNOTNullOrUndefined(gradeId) ? `&gradeId=${gradeId}` : ""}${
    isNOTNullOrUndefined(subjectId) ? `&subjectId=${subjectId}` : ""
  }${isNOTNullOrUndefined(search) ? `&search=${search}` : ""}${
    isNOTNullOrUndefined(reviewStatus)
      ? `&reviewStatus=${reviewStatus.toUpperCase()}`
      : ""
  }`;
  // ${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` :'' }
  try {
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data?.data || [];
  } catch {
    return [];
  }
};

export const createUpdateBluePrintContent = async (data) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/blueprint`;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(url, data, options);
    return res?.data || [];
  } catch (error) {
    return error.response.data;
  }
};

export const compareBluePrintTableColumn = async () => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/misc/print-column`;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return res?.data || [];
  } catch (error) {
    return [];
  }
};

export const getSampleTemplate = async () => {
  const templateType = "BLUEPRINT_TEMPLATE";
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/misc/templates?templateType=${templateType}`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const getBluePriintSingleContent = async (id) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/blueprint/${id}`;

  const res = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const toggleActiveStatus = async (isActive, id) => {
  const token = sessionStorage.getItem("token");

  let data = { mappingData: [] };
  data.id = id;
  data.active = isActive;
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/blueprint/toggle`;
  const res = await axios.put(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};

export const deleteBluePrint = async (id) => {
  const token = sessionStorage.getItem("token");
  let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/blueprint/${id}`;

  const res = await axios.delete(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res?.data?.data || [];
};
