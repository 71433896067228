import React from 'react'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import GreenAddButton from '../../../components/common/GreenAddButton'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import searchIcn from '../../../../src/img/search.svg'

const PlanListHeader = ({ onAddPlanClick,handleSearch,searchParam }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        padding:'15px 16px  '
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: '#606060',
          columnGap: '10px'
        }}
      >
        <MenuBookOutlinedIcon style={{ fontSize: '2.2vw' }} />
        <div
          style={{
            fontSize: '1.2vw',
            fontWeight: '600'
          }}
        >
          Plan Template
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: '1rem'
        }}
      >
        <TextField
          placeholder='Search'
          type='search'
          variant='outlined'
          fullWidth
          size='small'
             onChange={handleSearch}
             value={searchParam}
          sx={{
            width: { sm: 200, md: 300 },
            '& .MuiInputBase-root': {
              width: '247px !important',
              height: '32px !important',
              borderRadius: '50px !important',
              background: '#fff !important',
              lineHeight: '21px !important',
              font: 'normal normal normal 13px/20px Poppins !important',
              opacity: 1
            }
          }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <img
                  src={searchIcn}
                  style={{
                    verticalAlign: 'top',
                    fontSize: 'large'
                  }}
                  aria-hidden='true'
                />
              </IconButton>
            )
          }}
        />
        <GreenAddButton onClick={onAddPlanClick}>
          <AddCircleOutlineOutlinedIcon style={{ marginRight: '10px' }} />
          Add Plan Template
        </GreenAddButton>
      </div>
    </div>
  )
}

export default PlanListHeader
