import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  Paper,
  Select,
  Table,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Checkbox,
  // Switch
} from "@mui/material";
import Switch from "../../components/common/Switch";
// import Switch from "../../components/common/Switch";
import { blue } from "@mui/material/colors";
import { ControlPoint } from "@mui/icons-material";
import Pagination1 from "./pagination1";
import WhiteAddButton from "../../components/common/WhiteButton";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import chapterStyles from "./../css/createChapter.module.css";
import TrainingCategoryStyles from "./../css/TrainingCategory.module.css";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import GreenAddButton from "../../components/common/GreenAddButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import { SearchOutlined } from "@mui/icons-material";
import EditIconButton from "../../components/common/EditIconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CommonDeleteModal from "./CommonDeleteModal";
import BlueButton from "../../components/common/BlueButton";
import { Link, useNavigate, useLocation } from "react-router-dom";
import dashHome from "../../../src/img/dashHome.svg";

// sort
import sortIcon from "../../../src/pages/img/sort_1.png";
import searchIcn from "../../../src/img/search.svg";
import ConfirmationModal from "./ConfirmationModal";
import action_sa_Edit from "../../../src/img/Edit.svg";
import action_sa_Delete from "../../../src/img/Delete.svg";
import ChapterModel from "./ChapterModel";
import DeleteBlanksModal from "./QuestionBank/CommonComponents/DeleteBlanksModal";
import commonsort from "../js/CommonSort"

const CreateEditConcept = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [modifiedDate, setModifiedDate] = useState({});
  const [createConcept, setConcept] = useState(false);
  const [conceptData, setConceptData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [conceptId, setConceptId] = useState("");
  const [subjectId, setSubjectId] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [deletemodal, setDeleteModal] = useState(false);
  const [id, setId] = useState();
  const [isEdit, setEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [conceptName, setConceptName] = useState();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState(null);
  const [searchtempdata, setSearchtempdata] = useState();
  const [row, setRow] = useState({});
  const [filterValue, setFilterValue] = useState([]);
  const token = sessionStorage.getItem("token");
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });

  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleDetails, setToggleDetails] = useState({});
  const [chapterUpdateOpen, setUpdateChapterModal] = useState(false);

  // sort
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(false);

  //Edit Active / In-Active
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isConceptErrMsg, setIsConceptErrMsg] = useState("");
  const [isSubjectErrMsg, setIsSubjectErrMsg] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    initData(sortOrder);
    return () => { };
  }, [sortOrder, sortBy]);

  useEffect(() => {
    if (subjectId || search) {
      filterAPI();
    }
    return () => { };
  }, [subjectId, search]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const filterAPI = async () => {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/page?pageSize=1000&sortOrder=${sortOrder}&sortBy=${sortBy}${subjectId?.length ? `&subjectId=${subjectId}` : ''}${search ? `&search=${search}` : ''}`;
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // setData(response?.data?.data?.data);
    // setSearchtempdata(response?.data?.data?.data);
    setFilterValue(response?.data?.data?.data);
    // setSubjectData(response?.data.data);
  }
  const initData = async () => {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/page?pageSize=1000&sortOrder=${sortOrder}&sortBy=${sortBy}`;
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  //   response.data.data.data = await commonsort(response?.data?.data?.data || [], sortBy, !sortOrder);
  //  console.log("sortedData", response.data.data.data, sortOrder)
    setData(response?.data?.data?.data);
    setSearchtempdata(response?.data?.data?.data);
    setFilterValue(response?.data?.data?.data);

    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setSubjectData(res.data.data);
  };

  const handleOpen = async (item) => {
    setOpenDeleteModal(true);
    setRow(item);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/confirmation-api?id=${item?.id}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setMessage(response?.data?.data?.message);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };

  // pagination starts

  const [pageDetail, setPageDetail] = useState({});
  const handleChange = (e, value) => {
    setPage(value);
  };
  const handleCreate = () => {

    if (!conceptId) {
      setIsConceptErrMsg('Concept is Required');
      return false
    } else if (!subjectData) {
      setIsSubjectErrMsg('Subject is Required');
      return false
    } else {
      setIsConceptErrMsg('')
      setIsSubjectErrMsg('')
    }

    const y = subjectData
      .filter((value, index) => {
        return value.subject == subjectId.find((v) => v == value.subject);
      })
      .map((v) => v.id);

    const newValue = {
      concept: conceptId,
      subjects: [...y],
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts`,
      data: newValue,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        initData();
        setConcept(false);
        setMessage(response?.data?.message);
        setTimeout(() => {
          setUpdateChapterModal(true);
        }, 700);

      })
      .catch(function (error) {
        setMessage(error?.response?.data?.message);
        setTimeout(() => {
          setUpdateChapterModal(true);
        }, 700);
      });
  };

  const editClickHandler = (row) => {
    setRow(row);
    const subjects = row.subjects.map((v) => v.subject);
    setConcept(true);
    setEdit(true);
    setConceptId(row?.concept);
    setSubjectId([...subjects]);
    setSelectedItem("");
  };

  const clearClickHandler = (row) => {
    setConceptId("");
    setSubjectId([]);
    setConcept(false);
  };

  const updateClickHandler = () => {

    if (!conceptId) {
      setIsConceptErrMsg('Concept is Required');
      return false
    } else if (!subjectData) {
      setIsSubjectErrMsg('Subject is Required');
      return false
    } else {
      setIsConceptErrMsg('')
      setIsSubjectErrMsg('')
    }

    const y = subjectData
      .filter((value, index) => {
        return value.subject == subjectId.find((v) => v == value.subject);
      })
      .map((v) => v.id);
    const newValue = {
      id: row?.id,
      concept: conceptId,
      subjects: [...y],
    };
    axios({
      method: "put",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts`,
      data: newValue,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        initData();
        setConcept(false);
        setTimeout(() => {
          setUpdateChapterModal(true);
        }, 700);
        setMessage(response?.data?.message);

      })
      .catch(function (error) {
        setMessage(error?.response?.data?.message);
        setTimeout(() => {
          setUpdateChapterModal(true);
        }, 700);
      });
  };

  const updateChapterModelClose = () => {
    setUpdateChapterModal(false);
  };
  const showDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/${row.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        initData();
        hideDeleteModal();
      })
      .catch((error) => {
        hideDeleteModal();
      });
  };
  const updateToggle = () => {
    const values = {
      id: toggleDetails?.id,
      active: toggleDetails?.value,
    };
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/toggle-active-status`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          initData();
          setToggleConfirmation(false);
        }
        // const t = data.filter((v) => v.id != row.id);
        // const temp = [...t, { ...row, active: e.target.checked }];
        // setData([...temp]);
      })
      .catch((err) => console.log(err));
  };

  const switchHandler = async ({ id, active }) => {
    setToggleDetails({});
    const value = !active;
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      const values = {
        id,
        active: value,
      };
      axios
        .put(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/toggle-active-status`,
          values,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          if (response?.data) {
            initData();
            setToggleConfirmation(false);
          }
          // const t = data.filter((v) => v.id != row.id);
          // const temp = [...t, { ...row, active: e.target.checked }];
          // setData([...temp]);
        })
        .catch((err) => console.log(err));
    }
  }

  const subjectChange = (event) => {
    const {
      target: { value },
    } = event;
    setSubjectId(typeof value === "string" ? value.split(",") : value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };
  const handleSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = searchtempdata;
    if (!value) {
      if (subjectId) filterAPI()
      else initData()
    }
    if (value) {
      result = searchtempdata.filter((v) => {
        return v?.concept?.toLowerCase().includes(value);
      });
    }
    setData(result);
    setSearch(value);
    setPage(0);
  };

  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  const onHideModal = () => {
    setShowAlert(false);
  }
  const labelStyle = {
    fontSize: "14px",
    " &.MuiInputLabel-shrink": {
      // color: "#ff9900",
      fontWeight: 600
    },
    "&.Mui-focused": {
      // color: "orange",
      fontWeight: 600,
      fontFamily: "Poppins",
      fontSize: "13px",
    },
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="az_home_navigation_section" style={{ top: "67px" }}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <p className="az_navigation_text"><span style={{ position: "relative" }}>
                <img style={{ position: "absolute", top: "1px" }}
                  src={dashHome}
                  alt="no img"
                  width="18px"
                  height="18px"

                /> </span>
                <span onClick={() => {
                  sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                  sessionStorage.removeItem("sideNavSubMenuNames");
                  navigate("/dashboard/template")
                }} className="az_navigation_content" style={{ marginLeft: "20px" }}>Home</span>
              </p>
            </div>
            <p>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
            </p>
            <p onClick={() => {
              navigate("/dashboard/Add Training Category")
              sessionStorage.setItem("sideNavMainMenuNames", "Master");
              sessionStorage.removeItem("sideNavSubMenuNames");
            }} className="az_navigation_text"><span>Master</span>
            </p>
            <p>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
              <span>
                <i
                  className="fa-solid fa-angle-right"
                  style={{ color: "#7A7A7A" }}
                ></i>
              </span>
            </p>
            <p className="az_navigation_text"><span style={{ color: "#FFA500" }}>Concept</span></p>

          </div>
          <p className="az_last_update_time">Last Update: {modifiedDate.data}</p>
        </div>
        {/* <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <span>Master</span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <a>Concept</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {modifiedDate.data}</p>
          </div>
        </article> */}
        <Paper
          style={{
            marginTop: "55px",
            paddingRight: "33px",
            position: "absolute",
            right: "15px",
            left: "15px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 20px",
            }}
          >
            {createConcept && (
              <div>
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    flexDirection: "row",

                    width: "80vw",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <FormControl
                      style={{
                        marginRight: "8px",
                        marginLeft: "8px",
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "48px !important",
                          width: "270px !important",
                          borderRadius: "25px !important",
                          "&:hover": {
                            border: "2px solid #FFA500",
                            color: "#FFA500"
                          }
                        },
                      }}
                    // sx={{ width: 300 }}
                    >
                      <TextField
                        className={chapterStyles.select_input}
                        placeholder="*Concepts"
                        // sx={{
                        //   "& .MuiInputBase-root": {
                        //     color: "#FFA500"
                        //   }
                        // }}
                        value={conceptId}
                        onChange={(event) => {
                          setConceptId(event.target.value);
                        }}
                      ></TextField>
                      <span style={{ color: 'red', padding: "10px" }}>{isConceptErrMsg}</span>
                    </FormControl>

                    <FormControl
                      style={{
                        marginRight: "8px",
                        marginLeft: "8px",
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "48px !important",
                          width: "270px !important",
                          borderRadius: "25px !important",
                          "&:hover": {
                            border: "2px solid #FFA500",
                            color: "#FFA500",
                          }
                        },
                      }}
                    // sx={{ width: 300 }}
                    >
                      <InputLabel
                        className={chapterStyles.select_input}
                        sx={labelStyle} id="subject-select"
                      >
                        *Subject
                      </InputLabel>
                      <Select
                        // MenuProps={{
                        //   anchorOrigin: {
                        //     vertical: "bottom",
                        //     horizontal: "left",
                        //   },
                        //   transformOrigin: {
                        //     vertical: "top",
                        //     horizontal: "left",
                        //   },
                        //   getContentAnchorEl: null,
                        //   style: {
                        //     maxHeight: "300px",
                        //   },
                        // }}
                        style={{
                          borderRadius: 150,
                          background: "#fff",
                          fontSize: "14px !important",
                          // color:"orange",
                          height: "100%",
                        }}
                        input={
                          <OutlinedInput
                            sx={{ fontSize: "10px !important" }}
                            label="Subject-select"
                          />
                        }
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={subjectId}
                        onChange={subjectChange}
                        // input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected}
                        // MenuProps={MenuProps}
                        sx={{
                          "&.Mui-selected": {
                            "&:hover": {
                              border: "2px solid #FFA500 !important",
                              background: "rgb(252, 237, 194)",
                              color: "#FFA500"
                            }, color: "#FFA500"
                          }
                        }}
                      >
                        {subjectData?.map((name) => (
                          <MenuItem
                            key={name.id}
                            value={name?.subject}
                            className={dashboardContentStyles.menu_item}
                            id={`az-content-subjectdropdown-${name.id}`}
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: '#FFA500',
                                color: "#FFFFFF"
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: '#FFA500',
                                color: "#000000"
                              },
                              "&:not(.Mui-selected):hover": {
                                backgroundColor: '#fcedc2',
                                color: '#000000',
                              },
                            }}
                          >
                            <Checkbox
                              {...label}
                              defaultChecked
                              sx={{
                                color: `#FFA500 !important`,
                                "&.Mui-checked": {
                                  color: blue[900],
                                },
                              }}
                              checked={subjectId?.indexOf(name?.subject) > -1}
                            />
                            <ListItemText primary={name.subject} />
                          </MenuItem>
                        ))}
                      </Select>
                      <span style={{ color: 'red', padding: "10px" }}>{isSubjectErrMsg}</span>
                    </FormControl>
                  </div>
                  <div style={{ marginRight: "50px" }}>
                    <button
                      id="az-content-admin-create-concept-clear-btn"
                      style={{ marginRight: "10px" }}
                      className="az_cancel_btn"
                      onClick={clearClickHandler}
                    >
                      Clear
                    </button>
                    {isEdit ? (
                      <button className="az_confirm_btn" id="az-content-admin-create-concept-update-btn" onClick={updateClickHandler}>
                        Update
                      </button>
                    ) : (
                      <button
                        className="az_confirm_btn"
                        id="az-content-admin-create-concept-create-btn"
                        onClick={handleCreate}>Create</button>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className={chapterStyles.chapter_container}>
              <div
                className={chapterStyles.flex}
                style={{ marginTop: "15px", position: "relative", marginLeft: "50px" }}
              >
                {!createConcept && (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      right: "30px",
                      width: "100%",
                      marginTop: "-14px",
                    }}
                  >
                    <Box
                      style={{
                        margin: "auto 8px",
                        left: "10px",
                        position: "absolute",
                        zIndex: 1000,
                      }}
                      sx={{ minWidth: 180 }}
                    >
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{ width: "206px", height: "36px" }}
                      >
                        <InputLabel
                          className={chapterStyles.select_input}
                          // id="demo-select-small"
                          sx={labelStyle} id="subject-selected"
                        >
                          Select subject
                        </InputLabel>
                        <Select
                          // MenuProps={{
                          //   anchorOrigin: {
                          //     vertical: "bottom",
                          //     horizontal: "left",
                          //   },
                          //   transformOrigin: {
                          //     vertical: "top",
                          //     horizontal: "left",
                          //   },
                          //   getContentAnchorEl: null,
                          //   style: {
                          //     maxHeight: "300px",
                          //   },
                          // }}
                          style={{
                            borderRadius: 150,
                            background: "#fff",
                            fontSize: "14px !important",
                            color: "orange",
                            height: "100%",
                          }}
                          input={
                            <OutlinedInput
                              sx={{ fontSize: "10px !important" }}
                              label="Subject-selectedddd"
                            />
                          }
                          labelId="demo-select-small"
                          id="demo-select-small"
                          // value={subjectInput}
                          // label="subject-select"
                          onChange={(event) => {
                            setSubjectId(typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value);
                          }}
                        // style={{ background: "#fff", borderRadius: 150 }}
                        //   MenuProps={MenuProps}
                        >
                          <MenuItem value="" onClick={() => initData()}>All</MenuItem>

                          {subjectData.length > 0 ? (
                            subjectData.map((subject, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  value={subject.id}
                                  className={dashboardContentStyles.menu_item}
                                  id={`az-content-subjectdropdown-${i}`}
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: '#FFA500',
                                      color: "#FFFFFF"
                                    },
                                    "&.Mui-selected:hover": {
                                      backgroundColor: '#FFA500',
                                      color: "#000000"
                                    },
                                    "&:not(.Mui-selected):hover": {
                                      backgroundColor: '#fcedc2',
                                      color: '#000000',
                                    },
                                  }}
                                >
                                  <ListItemText primary={subject.subject} />
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem>Select</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Box>

                    {/* <lable>Subjects</lable>
                    <FormControl
                      style={{
                        marginRight: "600px",
                        marginLeft: "50px",
                        margin: "left",
                      }}
                      sx={{ width: 300 }}
                    >
                      <Select
                        name="boardId"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        placeholder="English"
                        //value={chapterInputs.boardId}
                        onChange={(event) => {
                          setSubjectId(event.target.value);
                        }}
                        input={<OutlinedInput label="Tag" />}

                        // renderValue={(selected) => selected.join(", ")}
                      >
                        {subjectData.length > 0 ? (
                          subjectData.map((board, i) => {
                            return (
                              <MenuItem key={i} value={board.subject}>
                                <ListItemText primary={board.subject} />
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem>Select</MenuItem>
                        )}
                      </Select>
                    </FormControl> */}
                    <div
                      style={{
                        width: "100%",
                        position: "absolute",
                        // right: "24px",
                        display: "flex",
                        marginLeft: "50px",
                        justifyContent: "flex-end"
                      }}
                    >
                      <TextField
                        type={"search"}
                        name="search"
                        // type='search'
                        sx={{
                          width: { sm: 200, md: 300 },
                          "& .MuiInputBase-root": {
                            width: "247px !important",
                            height: "32px !important",
                            borderRadius: "50px !important",
                            background: "#fff !important",
                            lineHeight: "21px !important",
                            font: "normal normal normal 13px/20px Poppins !important",
                            opacity: 1,
                          },
                        }}
                        // style={{width:'14vw' , height : '12vh'}}
                        placeholder={"Search...."}
                        onChange={handleSearch}
                        //Value={search.search}
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <img
                                src={searchIcn}
                                alt="no img"
                                style={{
                                  verticalAlign: "top",
                                  fontSize: "large",
                                }}
                                aria-hidden="true"
                              />
                            </IconButton>
                          ),
                        }}
                      />
                      <div style={{ marginLeft: "18px" }}>
                        <button
                          id={`az-content-addconceptbutton`}
                          style={{ height: "31px", align: "right" }}
                          className={chapterStyles.green_btn}
                          onClick={() => {
                            setConcept(true);
                            setConceptId("");
                            setSubjectId([]);
                          }}
                        >
                          + Add Concept
                        </button>
                        {/* green_btn */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "25px" }}>
            <TableContainer
              sx={{
                overflow: "auto",
              }}
              style={{ marginBottom: "35px" }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow >
                    <TableCell style={{
                      color: "#fff",
                      fontWeight: 600,
                      background: "#403E75",
                    }}>
                      #
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        fontWeight: 600,
                        background: "#403E75",
                      }}
                      align="left"
                    // className={chapterStyles.table_head}
                    >
                      Concept Name
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          marginBottom: "1px",
                        }}
                        className="cursor-pointer"
                        width="12"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("concept");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>

                    <TableCell
                      style={{
                        color: "#fff",
                        fontWeight: 600,
                        background: "#403E75",
                        marginRight: "30px"
                      }}
                      align="left"
                    // className={chapterStyles.table_head}
                    >
                      Subject
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "45px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="12"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("subject");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        fontWeight: 600,
                        background: "#403E75",
                      }}
                      align="left"
                    // className={chapterStyles.table_head}
                    >
                      Active<img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          marginBottom: "1px",
                        }}
                        className="cursor-pointer"
                        width="12"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("active");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        fontWeight: 600,
                        background: "#403E75",
                      }}
                      align="right"
                    // className={chapterStyles.table_head}
                    >
                      Action
                      {/* <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          marginBottom: "1px",
                        }}
                        className="cursor-pointer"
                        width="12"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("concept");
                          // await initData(!sortOrder, "id");
                        }}
                      />  */}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterValue?.length > 0 &&
                    filterValue
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((elem, i) => (
                        <TableRow
                          style={{ backgroundColor: (i % 2 === 0) ? "white" : "#f5f5f8" }}
                          sx={{
                            "&:nth-child(even)": {
                              background: "#F5F5F8"
                            }, "&:hover": {
                              background: "#fff !important",
                              border: "red"
                            }
                          }}>
                          {/* index */}

                          <TableCell
                          //  className={chapterStyles.table_cell}
                          >
                            {page * rowsPerPage + (i + 1)}
                          </TableCell>
                          {/* index */}
                          <TableCell >
                            {elem.concept}
                          </TableCell >
                          {/* index */}
                          <TableCell
                            // className={chapterStyles.table_cell}
                            align="left"
                          >
                            {elem?.subjects &&
                              elem?.subjects.map((v, i) => {
                                return (
                                  <span>
                                    {v.subject}
                                    {i == elem.subjects.length - 1 ? "" : ","}
                                  </span>
                                );
                              })}
                          </TableCell>
                          <TableCell
                            // className={chapterStyles.table_cell}
                            align="left"
                          >
                            <Switch
                              id={`az-content-switch-${i}`}
                              checked={elem?.active}
                              onChange={(e) => {
                                switchHandler(elem, e)
                              }}
                            ></Switch>
                            {/* <div>
                              <Switch
                              sx={{color:"orange"}}
                                checked={elem?.active}
                                // onChange={(event) => switchHandler(elem, event)}
                                defaultChecked={elem.active}
                                onClick={(e) => switchHandler(elem)}
                                // disabled={elem.reviewStatus === "APPROVED"}
                                name="checkedB"
                                color="primary"
                              />
                            </div> */}
                          </TableCell>

                          <TableCell
                            // className={chapterStyles.table_cell}
                            align="right"
                          >
                            <img
                              id={`az-content-editicon-${i}`}
                              onClick={() => {
                                if (!elem.active) {
                                  setShowAlert(true);
                                  setAlertMessage(`You cannot Edit the In-Active chapter, 
                                    For the Edit You have to first Active the Chapter`);
                                }
                                else {
                                  editClickHandler(elem);
                                }
                              }}
                              className={`${
                                dashboardContentStyles.btnHoverEffect
                              } cursorPointer`}
                              src={action_sa_Edit}
                              alt="Lock-btn"
                            />
                            {/* <EditIconButton
                              onClick={() => {
                                if(!elem.active){
                                  setShowAlert(true);
                                  setAlertMessage(`You cannot Edit the In-Active chapter, 
                                  For the Edit You have to first Active the Chapter`);
                                }
                                else{
                                  editClickHandler(elem);
                                } 
                              }}
                            
                            >
                              <Tooltip title="Edit" placement={"top"}>
                                <EditOutlinedIcon
                                  style={{ fontSize: "1.7vw" }}
                                />
                              </Tooltip>
                            </EditIconButton>  */}
                            {/* <DeleteIconButton
                              onClick={() => {
                                handleOpen(elem);
                              }}
                            >
                              <Tooltip title="Remove" placement={"top"}>
                                <DeleteOutlinedIcon
                                  style={{ fontSize: "1.7vw" }}
                                />
                              </Tooltip>
                            </DeleteIconButton> */}

                            <img
                              id={`az-contentadmin-deleteicon-${i}`}
                              onClick={() => {
                                handleOpen(elem);
                              }}
                              className={`${
                                dashboardContentStyles.btnHoverEffect
                              } cursorPointer`}
                              src={action_sa_Delete}
                              alt="Lock-btn"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* pagination container - Start */}
            <div
              className={TrainingCategoryStyles.pagination}
              style={{ width: "100%" }}
            >
              <span>
                <TablePagination
                  style={{
                    bottom: "0",
                    width: "80.5%",
                    position: "fixed",
                    backgroundColor: "white",
                  }}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={filterValue?.length || 0}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true}
                />
              </span>
            </div>
            {/* pagination container - End */}
          </Paper>
        </Paper>
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Concept"}
          deleteHandler={deleteHandler}
          deleteId={selectedItem?.id}
          deleteName={message}
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
        {/* <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={editClickHandler}
        /> */}
        <ChapterModel
          open={chapterUpdateOpen}
          close={updateChapterModelClose}
          deleteName="Concept Created Successfully"
        // message={message}
        />
        <DeleteBlanksModal
          open={showAlert}
          close={onHideModal}
          alert={alertMessage}
        />
      </div>
    </>
  );
};
export default CreateEditConcept;
