import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import searchIcn from "../../../src/img/search.svg";
import sortIcon from "../../../src/pages/img/sort.png";
import commonsort from "../js/CommonSort"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1300,
  height: 660,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  rowGap: "1rem",
};
const setBg = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomColor}`;
};
const ChapterTrackingModal = ({ open, close, data, name }) => {
  const [studentData, setStudentData] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [showClear, setShowClear] = useState(false);
  const [query, setQuery] = useState("");
  const [userName, setUserName] = useState(name);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("userName");
  const [userLocal, setUserLocal] = useState(localStorage.getItem("role"))
  useEffect(() => {
    if (data.length > 0) {
      // console.log("27-6-24",data)
      // data = data?.filter(v => v.skilledSubject === true) || [];
      // console.log(data)
      setFilterValue(data);
      setStudentData(data);
    } else {
      setFilterValue([]);
      setStudentData([]);
    }
    setUserName(name)
  }, [data, name]);
  const searchHandler = (event) => {
    let value = event?.target?.value?.toLowerCase();
    setQuery(value)
    let result = [...studentData];
    if (value) {
      result = studentData?.filter((data) => {
        return (
          data?.chapter?.toLowerCase().includes(value) ||
          data?.grade?.toLowerCase().includes(value) ||
          data?.subject?.toLowerCase().includes(value) ||
          data?.section?.toLowerCase().includes(value)
        );
      });
      setFilterValue(result);
    } else {
      setFilterValue(studentData);
    }
    setShowClear(value !== "")
    setPageNumber(0);
  };
  const handleClear = () => {
    setQuery("");
    setShowClear(false);
    setFilterValue(studentData);

  };
  const handleChangePage = async (event, newPage) => {
    console.log("handleChangePage", newPage, filterValue, sortBy, sortOrder, rowsPerPage)
    setPageNumber(newPage);
    var sorted = await commonsort(filterValue || [], sortBy, !sortOrder);
    console.log(sorted, newPage)
    // setFilterValue(sorted)
  };
  const handleRowsPerPage = (event) => {
    console.log("handleRowsPerPage", event.target.value)
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };
  const lastDateAndLoginHandler = (item) => {
    if (item?.length > 0) {
      const array = item?.split(" ");
      //const date = moment(new Date(array[0])).format("MM/DD/YYYY");
      // const time = moment(array[1]).format("hh:mm");
      return `${array[0]} / ${array[1]}`;
    }
  };
  const submissionDateHandler = (item) => {
    if (item?.length) {
      return moment(item).format("YYYY - MMM - DD");
    }
  };

  const onClose = () => {
    setFilterValue([]);
    setStudentData([]);
    setQuery("")
    setPageNumber(0)
    close()
  }

  const onSort = async (sortOrder, field) => {
    console.log(field, filterValue, userLocal)

    var VVfilterValue = filterValue;
    VVfilterValue.forEach(item => {
      item.subTopicId = item?.subTopicId ? item?.subTopicId : ""
    });
    console.log("VVfilterValue",VVfilterValue)
    if (field === "startDate" || field == "endDate") {
      VVfilterValue?.forEach(student => {
        student.startDateNew = new Date(student?.startDate);
        student.endDateNew = new Date(student?.endDate)
      });
    }
    if (field === "startDate") {
      field = "startDateNew"
    } else if (field === "endDate") {
      field = "endDateNew"
    }

    setSortOrder(sortOrder)
    var sorted = await commonsort(VVfilterValue || [], field, sortOrder);
    console.log("onsort ", sorted)
    setFilterValue(sorted)
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: 20, fontWeight: 600, color: "#29313f" }}>
              Chapters handled by {userName}
            </div>
            <CloseOutlinedIcon
              onClick={onClose}
              style={{ cursor: "pointer", fontSize: 20, color: "#757575" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{
                width: { sm: 200, md: 300 },
                "& .MuiInputBase-root": {
                  width: "247px !important",
                  height: "32px !important",
                  borderRadius: "50px !important",
                  background: "#fff !important",
                  lineHeight: "21px !important",
                  opacity: 1,
                  font: "normal normal normal 13px/20px Poppins !important",
                },
              }}
              type={"text"}
              value={query}
              placeholder={"Search anything...."}
              onChange={searchHandler}
              InputProps={{
                endAdornment: (
                  <>
                    {showClear && (
                      <IconButton onClick={handleClear}>
                        <ClearIcon
                          style={{ color: "#F05262", fontSize: "1vw" }}
                        />
                      </IconButton>
                    )}
                    {!showClear && (
                      <IconButton>
                        <img
                          src={searchIcn}
                          style={{
                            verticalAlign: "top",
                            fontSize: "large",
                          }}
                          aria-hidden="true"
                        />
                      </IconButton>
                    )}
                  </>
                ),
              }}
              size={"small"}
            />

          </div>
        </div>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            boxShadow: "0px 0px 0px 0px",
          }}
        >
          <TableContainer sx={{ maxHeight: 500, overflow: "auto" }}>
            <Table
              sx={{ minWidth: 650, marginBottom: "90px" }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: 600,
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 600,
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    Grade{" "}
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("grade");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "grade")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 600,
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                    align={"left"}
                  >
                    Subject{" "}
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("subject");
                        //await initData(!sortOrder, "grade");
                        await onSort(!sortOrder, "subject")
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 600,
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                    align={"center"}
                  >
                    Subtopic{" "}
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy((userLocal === "COORDINATOR" || userLocal === "PRINCIPAL") ? "subTopic" : "subtopic");
                        await onSort(!sortOrder, (userLocal === "COORDINATOR" || userLocal === "PRINCIPAL") ? "subTopic" : "subtopic")
                        //await initData(!sortOrder, "grade");
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 600,
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    Chapter{" "}
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("chapter");
                        await onSort(!sortOrder, "chapter")
                        //await initData(!sortOrder, "grade");
                      }}
                    />
                  </TableCell>

                  <TableCell
                    align="right"
                    style={{
                      fontWeight: 600,
                      paddingRight: "60px",
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    Section{" "}
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("section");
                        await onSort(!sortOrder, "section")
                        //await initData(!sortOrder, "grade");
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 600,
                      paddingLeft: "47px",
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    Start date{" "}
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy((userLocal === "COORDINATOR" || userLocal === "PRINCIPAL") ? "startDate" : "startdate");
                        await onSort(!sortOrder, (userLocal === "COORDINATOR" || userLocal === "PRINCIPAL") ? "startDate" : "startdate")
                        //await initData(!sortOrder, "grade");
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 600,
                      paddingLeft: "47px",
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    End date{" "}
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy((userLocal === "COORDINATOR" || userLocal === "PRINCIPAL") ? "endDate" : "enddate");
                        await onSort(!sortOrder, (userLocal === "COORDINATOR" || userLocal === "PRINCIPAL") ? "endDate" : "enddate")
                        //await initData(!sortOrder, "grade");
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 600,
                      paddingLeft: "47px",
                      background: "#f4f4f4",
                      color: "#3a3642",
                    }}
                  >
                    Number of Days{" "}
                    <img
                      style={{ position: filterValue.length > 0 ? "absolute" : '', top: "34px" }}
                      className="cursor-pointer"
                      width="12"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy((userLocal === "COORDINATOR" || userLocal === "PRINCIPAL") ? "numberOfDays" : "numberofdats");
                        await onSort(!sortOrder, (userLocal === "COORDINATOR" || userLocal === "PRINCIPAL") ? "numberOfDays" : "numberofdats")
                        //await initData(!sortOrder, "grade");
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterValue?.length > 0 &&
                  filterValue
                    ?.slice(
                      pageNumber * rowsPerPage,
                      pageNumber * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      const firstLetter = row?.firstName;
                      return (
                        <TableRow
                          key={row?.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {pageNumber * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "#575269",
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "10px",
                            }}>
                            {row?.grade}
                          </TableCell>
                          <TableCell align="left" style={{ color: "#575269" }}>
                            {row?.subject}
                          </TableCell>
                          <TableCell align="center" style={{ color: "#575269" }}>
                            {row?.subTopic || "-"}
                          </TableCell>
                          <TableCell align="center" style={{ color: "#575269" }}>
                            {row?.chapter}
                          </TableCell>
                          <TableCell align="center" style={{ color: "#575269" }}>
                            {row?.section}
                          </TableCell>

                          <TableCell align="center" style={{ color: "#575269" }}>
                            {row?.startDate != null && row?.startDate ? moment(row?.startDate).format('DD-MM-YYYY') : ''}
                            {/* {row?.startDate} */}
                          </TableCell>
                          <TableCell align="center" style={{ color: "#575269" }}>
                            {/* {row?.endDate} */}
                            {row?.endDate != null && row?.endDate ? moment(row?.endDate).format('DD-MM-YYYY') : ''}
                          </TableCell>
                          <TableCell align="center" style={{ color: "#575269" }}>
                            {row?.numberOfDays}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              bottom: "0",
              width: "80.5%",
              position: "fixed",
              backgroundColor: "white",
            }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={filterValue?.length || 0}
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          />
        </Paper>
      </Box>
    </Modal>
  );
};
export default ChapterTrackingModal;
