import axios from 'axios'

function concept_last_modified_get (setLastUpdated){
    let token = sessionStorage.getItem("token")

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concepts/last-modified-at`,
    {
        headers: {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setLastUpdated(res.data)
    })
    .catch(err => console.log(err))
}

export default concept_last_modified_get