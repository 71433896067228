import React, { useEffect, useState } from "react";
import "./PrincipalDashboardNew.css";


const PrincipalCardV1 = ({ img, label, count, onLabelClick }) => {
  const styles = {
    cursor: "pointer",
  };

  return (
    <div onClick={onLabelClick} style={{cursor:'pointer'}} className="principal-top-section-card">
      <div>
        <img src={img}></img>
      </div>
      <div>
        <div>
          <p className="principal-grade-count-label">
            <a  style={onLabelClick ? styles : null}>
              {label}
            </a>
          </p>
        </div>
        <div>
          <p className="principal-grade-count">{count}</p>
        </div>
      </div>
    </div>
  );
};

export default PrincipalCardV1;
