import axios from 'axios'

function teacher_upload_chapter_get(setChapterContent) {
  let token = sessionStorage.getItem("token");
   

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/teacher-chapter/page?pageSize=1000&sortBy=id&chapterId=${sessionStorage.getItem("chapterId")}`, 
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res)=>{
      setChapterContent(res.data.data.data)
    })
    .catch(err=>console.log(err))
}

export default teacher_upload_chapter_get;
