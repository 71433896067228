import React from "react";
import { Box, Modal } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DarkYellowButton from "../../components/common/DarkYellowButton";
import OTPInput from "./OTPInput/OTPInput";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "7px",
  display: "flex",
  flexDirection: "column",
};
const OTPVerificationModal = ({ visible, hide, number, setIsOTP }) => {
  const submitHandler = async () => {
    await hide();
    await setIsOTP(true);
  };
  return (
    <Modal
      open={visible}
      onClose={hide}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            background: "rgba(96, 91, 161, 0.81)",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            padding: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
          }}
        >
          <div>Change Phone Number</div>
          <CloseOutlinedIcon onClick={hide} style={{ cursor: "pointer" }} />
        </div>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            rowGap: "2rem",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
          >
            <div>OTP Verification</div>
            <div style={{ paddingRight: "20px" }}>
              We sent you an SMS code On number{" "}
              <span style={{ color: "#0492e2" }}>{number}</span>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <OTPInput
                autoFocus
                length={4}
                className="otpContainer"
                inputClassName="otpInput"
                onChangeOTP={(otp) => console.log("String OTP: ", otp)}
              />
            </div>
          </div>
          <DarkYellowButton onClick={() => submitHandler()}>
            Validate
          </DarkYellowButton>
        </div>
      </Box>
    </Modal>
  );
};
export default OTPVerificationModal;
