import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const Yellow100Button = (props) => {
  const CancelButton = styled(Button)(({ theme }) => ({
    color: "#fff",
    minWidth: 100,
    backgroundColor: "#f79f02",
    // borderRadius: 150,
    border: "1px solid #757575",
    borderColor: "#f79f02",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#f79f02",
    },
  }));
  return (
    <CancelButton onClick={props.onClick} style={props.style}>
      {props.children}
    </CancelButton>
  );
};
export default Yellow100Button;
