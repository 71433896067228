import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './store';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { positions, transitions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import EducationContextProvider from './context/quiz/EducationContext';
import QuestionTypeContextProvider from './context/quiz/QuestionTypeContext';
import * as serviceWorker from '../src/Serviceworker';
import NotificationContextProvider from './context/notification/NotificationContext';
import { PersistGate } from 'redux-persist/integration/react';
import mixpanel from 'mixpanel-browser';


const options = {
  // timeout: 500,
  position: positions.BOTTOM_CENTER,
  transition: transitions.SCALE,
};

const queryClient = new QueryClient();
mixpanel.init('a3c53021ab25f86e0b532e90c36522e6')

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <Sample /> */}
        {/* <TextEditorNew /> */}
        <NotificationContextProvider>
          <EducationContextProvider>
            <QuestionTypeContextProvider>
              <AlertProvider template={AlertTemplate} {...options}>
                <React.StrictMode>
                  <App />
                </React.StrictMode>
              </AlertProvider>
            </QuestionTypeContextProvider>
          </EducationContextProvider>
        </NotificationContextProvider>
      </PersistGate>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);
serviceWorker.unregister();