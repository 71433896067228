// userActions.js
import { UPDATE_USER_INFO } from '../constants/userActionTypes';

export const updateUserInfo = (firstName, lastName, id, userName) => {
    console.log(firstName, lastName)
    console.log(localStorage.getItem("firstName"))
    return {
        type: UPDATE_USER_INFO,
        payload: { firstName, lastName, id, userName },
    };
};
