import React from "react";
import { Box, Modal, Typography} from "@mui/material";
import YellowButton from "../../components/common/yellowButton";
import success from "../../../src/img/successpopup.png";
// import { styled } from "@mui/material/styles";

const AdmininstrationPopup1 = ({
  open,
  // close,
  userType,
  handleOk,
  userName_Modal,
  SelectedSchool,
  SelectedBranch,
  // addedItem,
}) => {
  // const [closeModal , setCloseModal] = useState(false);
  // const CancelButton = styled(Button)(() => ({
  //   background: "#ffffff 0% 0% no-repeat padding-box",
  //   border: "1px solid #7a7a7a",
  //   background: "#FFFFFF 0% 0% no-repeat padding-box",
  //   border: "1px solid #7A7A7A",
  //   borderRadius: "21px",
  //   opacity: 1,
  //   width: "178px",
  //   height: "40px",
  //   marginRight: "15px",
  //   color: "#7A7A7A",
  //   "&:hover": {
  //     boxShadow: "1px 2px 3px 0px #FD8C00",
  //     borderColor: "#FD8C00",
  //     color: "#FD8C00",
  //   },
  // }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 1,
    padding: "15px 30px",
  };
  return (
    <Modal
      open={open}
      // onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ padding: "30px 50px 30px 50px" }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "10px",
            }}
          >
            <img src={success} alt="success" />
          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <p style={{ color: "black" }}>
                You have successfully added {userType}
              </p>
              <br />
              <p style={{ color: "#FD8C00", lineHeight: " 1.8" }}>
                "{userName_Modal}"
              </p>
              <br />
              <div
                style={{
                  justifyContent: "flex-start",
                  left: "10px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    School Name
                    <div style={{color:"orange"}}>{SelectedSchool}</div>{" "}
                  </div>

                  <div  >
                    Branch Name
                  {SelectedBranch?.map((branch)=>  {
                  return( <div style={{color:"orange"}}>
                    {branch}
                    </div>)})}
                    
                  </div>
                </Box>
                <YellowButton onClick={() => handleOk()}>
                  <p style={{ color: "white" }}>OK</p>
                </YellowButton>
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};

export default AdmininstrationPopup1;
