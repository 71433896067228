import axios from 'axios'

function training_category_controller_getall(setTraininglist) {
  let token = sessionStorage.getItem("token");
   

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training-category/all`, 
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      if(json.data.data){
        setTraininglist(json.data.data)
      }
    })
    .catch(err=>console.log(err))
}

export default training_category_controller_getall;
