import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import addbranchtable from "./../css/addbranchlist.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import SchoolControllerIdGet from "../API/school-controller/User_School_Id";
import Pagination1 from "./pagination1";
import BranchControllerGet from "../API/branch-controller/User_Branches";
// import BranchControllerIdGet from "../API/branch-controller/User_Branches_id";
import GetLastModifiedAt from "../API/branch-controller/GetLastModifiedAt";
import MappingAlert from "../../components/common/MappingAlert";
import dashHome from "../../../src/img/dashHome.svg";
import branch_Icn from "../../../src/img/branch-list.svg";

const Addbranchlist = (props) => {
  const navigate = useNavigate();
  const branchlist15 = props.branchlist15;
  const [showAlert, setShowAlert] = useState(false);

  const showAlertHandler = () => {
    // setOpenDeleteModal(true);
    setShowAlert(true);
  };

  const hideAlertHandler = () => {
    setShowAlert(false);
  };

  const handleAddbranch = (e) => {
    e.preventDefault();
    navigate("/dashboard/addbranch");
  };

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };
  // pagination ends

  const [pageDetail, setPageDetail] = useState({});

  const [schoolIdData, setSchoolIdData] = useState({});

  const sectionBoard = schoolIdData?.boardList?.map((res) => res?.board);
  sessionStorage.setItem("sectionBoard", sectionBoard);
  const [branchData, setBranchData] = useState({});
  // const [branchPlanIdData, setBranchPlanIdData] = useState("");
  // last update time starts
  const [modifiedDate, setModifiedDate] = useState({});
  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => {};
  }, []);
  // last update time ends
  useEffect(() => {
    // BranchControllerGet(setBranchData);
    SchoolControllerIdGet(setSchoolIdData);
    // BranchControllerIdGet();

    return () => {};
  }, []);

  useEffect(() => {
    BranchControllerGet(setBranchData, setPageDetail, rowsInput, page);
    return () => {};
  }, [page, rowsInput]);

  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>{" "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <span className={dashboardContentStyles.link_text}>
            Resigtered Schools
          </span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a>School Branch</a>
        </div>
      </article>
      <article className={addbranchtable.add_schools}>
        <div className={addbranchtable.taken_width}>
          <div className={addbranchtable.edit_schools}>
            <div className={addbranchtable.school_logo_jpg}>
              <img
                style={{ width: "10vw" }}
                src={schoolIdData.logoUrl}
                width="200"
                height="150"
                alt="nav logo"
              />
            </div>
            <div className={addbranchtable.schoolName}>
              <label for="School Name" style={{color:"orange"}}>School Name</label>
              <p>{schoolIdData.name}</p>
            </div>
            <div className={addbranchtable.cityName}>
              <label for="City Name" style={{color:"orange"}}>City Name</label>
              <p> {schoolIdData.city}</p>
            </div>
            <div className={addbranchtable.pocEmail}>
              <label for="pocEmail" style={{color:"orange"}}>POC Email</label>
              <p> {schoolIdData.pocEmail}</p>
            </div>
            <div className={addbranchtable.phoneNumber}>
              <label for="phoneNumber" style={{color:"orange"}}>Phone Number</label>
              <p>{schoolIdData.phoneNumber}</p>
            </div>
            <div className={addbranchtable.website}>
              <label for="website" style={{color:"orange"}}>Website</label>
              <p>{schoolIdData.website}</p>
            </div>
            <div className={addbranchtable.SignatoryName}>
              <label for="Signatory Name" style={{color:"orange"}}>Signatory Name</label>
              <p>{schoolIdData.signatoryName}</p>
            </div>
            <div className={addbranchtable.SignatoryRole}>
              <label for="Signatory Role" style={{color:"orange"}}>Signatory Role</label>
              <p>{schoolIdData.signatoryRole}</p>
            </div>
            <div className={addbranchtable.schoolType}>
              {/* <label for="School type">School Type</label>
              <p>production</p> */}
            </div>
            <div className={addbranchtable.schoolType}>
              {/* <label for="School type">School Type</label>
              <p>production</p> */}
            </div>

            <div>
              <button
                className={addbranchtable.edit_button}
                id={addbranchtable.button_id_edit}
                type="button"
                name="edit-button"
                onClick={(e) => {
                  navigate("/dashboard/createschool");
                }}
              >
                <i class="fa fa-pencil"></i> <span> Edit</span>
              </button>
            </div>
          </div>
        </div>

        <div className={addbranchtable.school_list_container}>
          <div className={addbranchtable.school_list_title}>
            <span className={addbranchtable.school_list}>
              <img
                src={branch_Icn}
                alt="no img"
                style={{ verticalAlign: "middle", fontSize: "large" }}
              />
            </span>
            <span style={{ fontSize: "16px" }}> Branch List</span>
          </div>
          <div className={addbranchtable.artical_button_container}>
            <button
              className={addbranchtable.artical_button}
              id={addbranchtable.button_id}
              onClick={handleAddbranch}
            >
              {" "}
              <i class="fa-solid fa-circle-plus"></i> Add Branch
            </button>
          </div>
        </div>

        <div className={addbranchtable.create_branch_list}>
          <table className={addbranchtable.table}>
            <thead>
              <tr>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  # <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th scope="col">
                  <div className={dashboardContentStyles.table_head}>
                    {" "}
                    Branch name <i class="fa-solid fa-arrows-up-down"></i>
                  </div>
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Branch city <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Branch locality <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Boards <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Coordinators <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Teachers <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Principals <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Students <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Active <i class="fa-solid fa-arrows-up-down"></i>
                </th>
                <th scope="col" className={dashboardContentStyles.table_head}>
                  Action <i class="fa-solid fa-arrows-up-down"></i>
                </th>
              </tr>
            </thead>
            <tbody className={addbranchtable.particular_hover}>
              {branchData.length > 0 ? (
                branchData.map((branchData, index) => {
                  return (
                    <tr className={addbranchtable.data_row} key={index}>
                      <td
                        className={dashboardContentStyles.table_cell}
                        // className={addbranchtable.col_size}
                      >
                        {index + 1}
                      </td>
                      <td
                        // className={addbranchtable.col_size}
                        onClick={(e) => {
                          var branchid = branchData.id;
                          sessionStorage.setItem("branchid", branchid);
                          var BoardNamex = branchData.board;
                          sessionStorage.setItem("BoardNamex", BoardNamex);
                          navigate("/dashboard/branchplan");
                        }}
                      >
                        <div className={dashboardContentStyles.table_cell}>
                          {branchData.name}
                        </div>
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {branchData.cityName}
                      </td>

                      <td className={dashboardContentStyles.table_cell}>
                        {branchData.locality}
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {branchData.board}
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {branchData.numberOfCoordinators}
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {branchData.numberOfTeachers}
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {branchData.numberOfPrincipals}
                      </td>
                      <td className={dashboardContentStyles.table_cell}>
                        {branchData.numberOfStudents}
                      </td>

                      {/* <td className={addbranchtable.create_branch}></td> */}
                      <td className={addbranchtable.col_size}>
                        <label className={addbranchtable.active_switch}>
                          <input
                            type="checkbox"
                            id=""
                            name=""
                            value=""
                            defaultchecked={branchData.active}
                          />
                          <span className={addbranchtable.active_slider}></span>
                        </label>
                      </td>

                      <td className={addbranchtable.hover_table_1}>
                        <span
                          className={addbranchtable.circle}
                          onClick={(e) => {
                            e.preventDefault();
                            var branchid = branchData.id;
                            sessionStorage.setItem("branchid", branchid);
                            navigate("/dashboard/addbranch", {
                              state: "edit",
                            });
                          }}
                        >
                          <i class="fa fa-pencil"></i>{" "}
                        </span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan="10"
                    style={{
                      // "white-space": "nowrap",/
                      height: "23rem",
                      // overflow: "scroll",
                      // "text-overflow": "clip",
                      // margin: "0px 10px",
                    }}
                  >
                    NO DATA AVAILABLE
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={addbranchtable.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={addbranchtable.rows}>
              <label for="rows">Rows per page</label>
              <select
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        <MappingAlert
          open={showAlert}
          onClose={hideAlertHandler}
          close={hideAlertHandler}
          alert="This Grade-section has other child links"
        />
      </article>
    </>
  );
};
export default Addbranchlist;
