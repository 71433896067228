import { Modal } from "@mui/material";
import React from "react";
import "./DashboardGradeModal.css";
import { Close } from "@material-ui/icons";
import {
  emberMessageOne,
  emberMessageTwo,
} from "../../constants/dashboardConstants";
import GradesStudents from "../../img/gradeStudents.png";
import GradesHandling from "../../img/gradesHandling.png";
import GradesSection from "../../img/gradesSection.png";
const DashboardGradeModal = ({
  title,
  content,
  showMessageModal,
  onMessageModalClose,
  isEmberCategoryMessage,
  data,
}) => {
  return (
    <Modal
      sx={{ display: "grid", placeContent: "center" }}
      open={showMessageModal}
      onClose={onMessageModalClose}
    >
      <div
        className="msg-modal-body"
        style={{ width: isEmberCategoryMessage ? "550px" : "550px" }}
      >
        <div class="grade-table-wrap">
          <div className="grade-modal-head">
            <div className="grade-modal-head-wrap">
              <div>
                <img src={GradesHandling} />
              </div>
              <div>
                <label style={{ color: "#2D3962" }} className="cursorText">Grades Handling</label>
              </div>
            </div>
            <div className="grade-modal-head-wrap">
              <div>
                <img src={GradesSection} />
              </div>
              <div>
                <label style={{ color: "#2D3962" }} className="cursorText">Section</label>
              </div>
            </div>
            <div className="grade-modal-head-wrap">
              <div>
                <img src={GradesStudents} />
              </div>
              <div>
                <label style={{ color: "#2D3962" }} className="cursorText">Students</label>
              </div>
            </div>
          </div>
          {data?.map((data, index) => {
            return (
              <div className="grade-table" key={index}>
                <span style={{ width: "25%", overflow: "hidden" }}>
                  {index + 1 + "."}
                  <label className="grade-table-content cursorText">{data?.grade}</label>
                </span>
                <span
                  className="grade-table-content"
                  style={{ textAlign: "left", width: "20%" }}
                >
                  {data?.section}
                </span>
                <span style={{ color: "#6A6A6A" }}>
                  {data?.totalStudentsUnderSection}
                </span>
              </div>
            );
          })}
        </div>

        {/* <p className="msg-modal-title" style={{marginBottom:isEmberCategoryMessage ? '30px':'15px'}}>{isEmberCategoryMessage ? 'Attention!': title}</p>
            <p className="msg-modal-content" style={{marginBottom:isEmberCategoryMessage ? '15px':'0px'}}>{isEmberCategoryMessage ? emberMessageOne :content}</p>
           
            {isEmberCategoryMessage ?  <p className="msg-modal-content">{emberMessageTwo}</p> :null} */}
        <div onClick={onMessageModalClose} className="msg-modal-close-btn">
          <Close style={{ fontSize: "16px", color: "#ccc" }} />
        </div>
      </div>
    </Modal>
  );
};

export default DashboardGradeModal;
