import { useState, useEffect } from 'react';
import axios from 'axios';
import { RootStateOrAny, useSelector } from 'react-redux';

const useFetch =(url)=> {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const TOKEN = sessionStorage.getItem('token')

  const headers = {Authorization: `Bearer ${TOKEN}`}


  useEffect(() => {

    setLoading(true)
    setData(null);
    setError(null);
    const source = axios.CancelToken.source();
    axios.get(url, { headers,cancelToken: source.token })
    .then(res => {
        setLoading(false);
        //checking for multiple responses for more flexibility 
        //with the url we send in.
        res.data && setData(res.data);
    })
    .catch(err => {
        setLoading(false)
        setError(err)
    })
    return () => {
        source.cancel();
    }

  }, [url])
  

  return { data, loading, error };
}
export default useFetch;
