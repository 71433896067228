import axios from 'axios'

function assessment_table_get(setAssessTable) {
    let token = sessionStorage.getItem("token")

    // let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/assessment/paper?pageSize=10&sortBy=id`,
    let result = axios.get(`http://3.111.249.182:9005v1/api/content/assessment/paper?pageSize=1000&sortBy=id`,
        {
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        .then((res) => {
            console.log(res.data.data, "777")
            setAssessTable(res.data.data.data)
        })
        .catch(err => console.log(err))
}

export default assessment_table_get