import axios from 'axios'

async function training_pending_table_get(setTrainingtableData , categoryinput ,trainingtype, rowsInput , page , setPageDetail , search,sortBy,sortOrder) {
  let token = sessionStorage.getItem("token");
   

   if(categoryinput !== "" || trainingtype !== ""){
    if(categoryinput !== "" && trainingtype === "") {
        var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training?reviewStatus=PENDING&search=${search.search}&pageNumber=${page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&categoryId=${categoryinput}`
    }
    if(categoryinput === "" && trainingtype !== ""){
        var URL =`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training?reviewStatus=PENDING&search=${search.search}&pageNumber=${page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}&trainingType=${trainingtype}`
    }
   } else {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training?reviewStatus=PENDING&search=${search.search}&pageNumber=${page - 1}&pageSize=${rowsInput.rows}&sortOrder=${sortOrder}&sortBy=${sortBy}`
   }
    let result = axios.get(URL, 
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res)=>{
      if(res.data.data){
        setTrainingtableData(res.data.data.data)
        setPageDetail(res.data.data)
      }
    })
    .catch(err=>console.log(err))
}

export default training_pending_table_get;
