import axios from 'axios'

export function all_questionType_get(setQuetype){
    let token = sessionStorage.getItem("token");

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question-type/all`,
    {
        headers : {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=> {
        setQuetype(res.data.data)
    })
    .catch(err => console.log(err))
}

export default all_questionType_get;