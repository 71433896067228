import axios from 'axios'

function chapter_reason_by_id_get(setChapterInputs){
    let token = sessionStorage.getItem("token")

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/contents/reason?id=${sessionStorage.getItem("chapterId")}&chapterStatus=${sessionStorage.getItem("status")}` ,
    {
        headers : {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
    })
    .catch(err => console.log(err))
}
export default chapter_reason_by_id_get