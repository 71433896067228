import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Tab,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { MenuProps } from "../Data";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "chartjs-plugin-datalabels";
import GetLastModifiedAt from "../../API/token-controller/GetLastModifiedAt";
import axios from "axios";
import DarkYellowButton from "../../../components/common/DarkYellowButton";
import ViewImage from "../../../components/common/ViewImage";
import StudentWisePerformance from "./PerformanceWiseReports/StudentWisePerformance";
import QuestionWisePerformance from "./PerformanceWiseReports/QuestionWisePerformance";
import TopBar from "../../../components/common/TopBar";
import "../../css/switch.css";
import QuizOverview from "./PerformanceWiseReports/QuizOverview";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import {
  studentPerformanceData,
  accordionData,
  graphData,
  studentPerformanceDataNew,
  formalStudentPerformance,
  question_wise_data,
  top5bottom5,
  conceptTreeFormal,
  formalOverview,
  sectionGlobalData,
} from "./PerformanceWiseReports/dummyData";
import { CSVLink } from "react-csv";
import { isNOTNullOrUndefined } from '../../../utils/helper'
import { formal_assessment } from "./PerformanceWiseReports/dummyData";

const FormalAssessment = ({ isFormative }) => {
  const abortController = new AbortController();

  const token = sessionStorage.getItem("token");
  const boardId = sessionStorage.getItem("boardId");
  const schoolId = sessionStorage.getItem("schoolId");
  const branchId = sessionStorage.getItem("branchId");
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const [downloadReport, setDownloadReport] = useState(false);
  const [teacherId, setTeacherId] = useState("");
  const [tabsValue, setTabsValue] = useState("1");
  const [subjectList, setSubjectList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [subTopicList, setSubTopicList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [studentPerformanceReport, setStudentPerformanceReport] = useState([]);
  const [quizOverviewData, setQuizOverviewData] = useState([]);
  const [chaptersList, setChaptersList] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSubTopic, setSelectedSubTopic] = useState("");
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [chapterConcepts, setChapterConcepts] = useState([]);
  const [questionWisePerformanceData, setQuestionWisePerformanceData] =
    useState([]);
  const [top5PerformanceData, setTop5PerformanceData] =
    useState([]);
  const [modifiedDate, setModifiedDate] = useState({});
  const [expanded, setExpanded] = useState("");
  const [intIndex, setIntIndex] = useState(0);
  const [questionExpanded, setQuestionExpanded] = useState("");
  const [questionIndex, setQuestionIndex] = useState(0);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const [csvReport, setCsvReport] = useState();
  const [changedFilter, setChangedFilter] = useState('nochange')
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [gradeSection, setGradeSection] = useState([]);
  const [sectionAvgData, setSectionAvgData] = useState([]);
  const [globalAvgData, setGlobalAvgData] = useState([]);

  const [quizTypeList, setQuizTypeList] = useState([]);
  const [selectedQuizType, setSelectedQuizType] = useState("");
  const [quizList, setQuizList] = useState();
  const [selectedQuiz, setSelectedQuiz] = useState("");
  const [quizName, setQuizName] = useState('');
  const [studentTabData, setStudentTabData] = useState([]);
  const [studentTabDataTemp, setStudentTabDataTemp] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [questionLoader, setQuestionLoader] = useState(false);
  const [absenteestudentTabData, setAbsenteeStudentTabData] = useState();

  const accordionChangeHandler = (e, index) => {
    let value = e;
    if (expanded === value) {
      value = "";
    } else {
      value = e;
    }
    setExpanded(value);
    setIntIndex(index);
  };

  const questionAccordionHandler = (e, index) => {
    let value = e;
    if (questionExpanded === value) {
      value = "";
    } else {
      value = e;
    }
    setQuestionExpanded(value);
    setQuestionIndex(index);
  };

  const tabsChangeHandle = (event, newValue) => {
    setTabsValue(newValue);
  };

  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data?.teacher || [];
  };

  const downloadStudentPerformance = async () => {
    let report = [];
    studentPerformanceData?.map(stud => {
      let obj = {
        id: stud?.id,
        student: stud?.student,
        concept: stud?.concept,
        subConcepts: stud?.subConcepts !== stud?.subConcepts?.toString() ? stud?.subConcepts?.map(x => x?.title)?.toString() : stud?.subConcepts,
        rootConcepts: stud?.rootConcepts !== stud?.rootConcepts?.toString() ? stud?.rootConcepts?.map(x => x?.title)?.toString() : stud?.rootConcepts,
        marks: stud?.marks !== stud?.marks?.toString() ? stud?.marks?.map(x => x?.mark)?.toString() : stud?.marks,
        percentage: stud?.percentage,
        timeToken: stud?.timeToken[0]?.min + " min" + stud?.timeToken[0]?.second + " second",
        timePerQuestion: stud?.timePerQuestion[0]?.min + " min" + stud?.timePerQuestion[0]?.second + " second",
        attempt: stud?.attempt,
        pq1: stud?.pq1,
        pq2: stud?.pq2,
        highestScore: stud?.highestScore
      }
      report.push(obj);
    })

    const headers = [
      { label: "S.No", key: "id" },
      { label: "Student", key: "student" },
      { label: "Concept", key: "concept" },
      { label: "Sub Concept", key: "subConcepts" },
      { label: "Root Concept", key: "rootConcepts" },
      { label: "Marks", key: "marks" },
      { label: "Percentage", key: "percentage" },
      { label: "Time Taken(min)", key: "timeToken" },
      { label: "Avg.Time per Question", key: "timePerQuestion" },
      { label: "Attempt on", key: "attempt" },
      { label: "PQ 1 Attemp", key: "pq1" },
      { label: "PQ 2 Attemp", key: "pq2" },
      { label: "Highest Score(PQ)", key: "highestScore" },
    ];

    const csvReport = {
      data: report,
      headers: headers,
      filename: 'Studentwiseperformance.csv'
    };
    console.log("csvReport", csvReport, report)
    setCsvReport(csvReport);
  }

  const fetchSubjectData = async (params) => {
    const token = sessionStorage.getItem("token");
    const teacherId = sessionStorage.getItem("teacherId");

    const response = await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/user/teachers/subjects?teacherId=${params?.teacherId ? params.teacherId : teacherId
        }&gradeId=${params?.gradeId}&sectionId=${params?.sectionId}&menuName=${params?.menuName}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => console.log(err));
    return response?.data?.data || [];
  };

  const fetchQuizList = async (params) => {
    const token = sessionStorage.getItem("token");
    const url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/getquiz-namelist-diagnostic-and-annual-quiz?subjectId=${selectedSubject}&quizTypeId=${selectedQuizType}&gradeId=${selectedGrade}&sectionId=${selectedSection}`;
    const response = await axios.get(
      // `${
      //   process.env.REACT_APP_SWAGGER_URL
      // }v1/api/content/quiz/getquiz-namelist-diagnostic-and-annual-quiz?subjectId=${selectedSubject}&quizTypeId=${selectedQuizType}`,
      url,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .catch((err) => console.log(err));
    return response?.data?.data || [];
  };

  const fetchQuizType = async () => {
    const token = sessionStorage.getItem("token");
    const response = await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/content/quiz-types/annual-diagnostic`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => console.log(err));
    return response?.data?.data || [];
  };

  const fetchGradeData = async (tId) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${tId}&menuName=TEACH`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchChapterNames = async ({ gradeId, subjectId, subTopicId }) => {
    let subId = subjectId ? subjectId : selectedSubject;
    let gId = gradeId ? gradeId : selectedGrade;
    let subTopId = subTopicId ? subTopicId : selectedSubTopic;
    const res = await axios.get(
      //  `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/all-chapters?subTopicId=${subTopic}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&studentLogin=false&schoolId=${schoolId}&branchId=${branchId}&sectionId=${section}`,



      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/all-chapters?subjectId=${subId}&boardId=${boardId}&studentLogin=false&schoolId=${schoolId}&branchId=${branchId}&sectionId=${selectedSection}&subTopicId=${subTopId}`,
      {
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchAllAcademicYear = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchPerformanceReportChapterConcept = async (
    grade,
    subject,
    section,
    quizId
  ) => {
    // let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/chapters-concept-tree?teacherId=${teacherId.id}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&chapterId=${chapter}&sectionId=${section}&academicYearId=${selectedAcademicYear}`;

    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/formative-overview-chapters-concept-tree?teacherId=${teacherId.id}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&quizId=${quizId}&sectionId=${section}&academicYearId=${selectedAcademicYear}`;

    const res = await axios.get(
      url,
      {
        signal: abortController.signal,
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchPerformanceReportStudentPerformance = async (
    grade,
    subject,
    section,
    quizId,
    academicYear,
  ) => {
    // let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/exam-performance?teacherId=${teacherId.id}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&chapterId=${chapter}&sectionId=${section}&academicYearId=${selectedAcademicYear}`;
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/formative-overview-exam-performance?teacherId=${teacherId.id}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&quizId=${quizId}&sectionId=${section}&academicYearId=${academicYear}`
    const res = await axios.get(
      url
      ,
      {
        signal: abortController.signal,
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchPerformanceReportQuizOverview = async (
    grade,
    subject,
    section,
    quizId
  ) => {
    // let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/quiz-overview-report?teacherId=${teacherId.id}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&chapterId=${chapter}&sectionId=${section}&academicYearId=${selectedAcademicYear}`;

    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/formative-quiz-overview-report?teacherId=${teacherId.id}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&quizId=${quizId}&sectionId=${section}&academicYearId=${selectedAcademicYear}`;

    const res = await axios.get(
      url,
      {
        signal: abortController.signal,
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    //Demo use only
    // let temp = res?.data?.data?.concepts;
    // let temp_obj = {
    //   caseStudy: res?.data?.data?.caseStudy,
    //   classAverage: res?.data?.data?.classAverage,
    //   concepts: [...temp, ...temp]
    // }
    // return temp_obj || [];

    return res?.data?.data || [];
  };

  const fetchQuestionWiseQuizPerformance = async (
    grade,
    subject,
    subTopic,
    quizId
  ) => {
    const res = await axios.get(
      // `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/formative-questionwise-performance?gradeId=${grade}&subjectId=${subject}&subtopicId=${subTopic}&quizId=${quizId}
      // `,
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/formative-questionwise-performance?teacherId=${teacherId?.id}&schoolId=${schoolId}&branchId=${branchId}&boardId=${boardId}&gradeId=${grade}&sectionId=${selectedSection}&subjectId=${subject}&subtopicId=${subTopic}&academicYearId=${selectedAcademicYear}&quizId=${quizId}&quizTypeId=${selectedQuizType}
      `,
      {
        signal: abortController.signal,
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("res?.data?.data questionwise performance", res?.data?.data?.concepts)
    const result = Object.values(
      res?.data?.data?.concepts.reduce((acc, item) => {
        if (!acc[item.concept]) {
          acc[item.concept] = {
            concept: item.concept,
            conceptId: item.conceptId,
            percentageInQuiz: item.percentageInQuiz,
            questions: [...item.questions],
            count: 1
          };
        } else {
          acc[item.concept].percentageInQuiz += item.percentageInQuiz;
          acc[item.concept].questions = [...acc[item.concept].questions, ...item.questions];
          acc[item.concept].count += 1;
        }
        return acc;
      }, {})
    );
    result.forEach(item => {
      item.percentageInQuiz = Math.round(item.percentageInQuiz / item.count);
      delete item.count;
    });
    console.log(result);
    res.data.data.concepts = result;
    return res?.data?.data || [];
  };

  const fetchTop5QuizPerformance = async (
    grade,
    subject,
    subTopic,
    quizId
  ) => {
    const res = await axios.get(
      // `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/formative-questionwise-performance?gradeId=${grade}&subjectId=${subject}&subtopicId=${subTopic}&quizId=${quizId}
      // `,
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/get-top-five-difficult-question-formative?teacherId=${teacherId?.id}&schoolId=${schoolId}&branchId=${branchId}&boardId=${boardId}&gradeId=${grade}&sectionId=${selectedSection}&subjectId=${subject}&subtopicId=${subTopic}&academicYearId=${selectedAcademicYear}&quizId=${quizId}&quizTypeId=${selectedQuizType}
      `,
      {
        signal: abortController.signal,
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = Object.values(
      res?.data?.data?.concepts.reduce((acc, item) => {
        if (!acc[item.concept]) {
          acc[item.concept] = {
            concept: item.concept,
            conceptId: item.conceptId,
            percentageInQuiz: item.percentageInQuiz,
            questions: [...item.questions],
            count: 1
          };
        } else {
          acc[item.concept].percentageInQuiz += item.percentageInQuiz;
          acc[item.concept].questions = [...acc[item.concept].questions, ...item.questions];
          acc[item.concept].count += 1;
        }
        return acc;
      }, {})
    );
    result.forEach(item => {
      item.percentageInQuiz = Math.round(item.percentageInQuiz / item.count);
      delete item.count;
    });
    console.log(result);
    res.data.data.concepts = result;
    return res?.data?.data || [];
  };

  const fetchSectionAvgData = async (
    grade,
    subject,
    section,
    quizId
  ) => {
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/section-average-quiz-overview?teacherId=${teacherId.id}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&quizId=${quizId}&sectionId=${section}&academicYearId=${selectedAcademicYear}`;

    const res = await axios.get(
      url,
      {
        signal: abortController.signal,
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res?.data?.data || [];
  };

  const fetchGlobalAvgData = async (
    grade, subject, section, quizId
  ) => {

    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/global-average-quiz-overview?teacherId=${teacherId.id}&subjectId=${subject}&gradeId=${grade}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&quizId=${quizId}&sectionId=${section}&academicYearId=${selectedAcademicYear}`;

    const res = await axios.get(
      url,
      {
        signal: abortController.signal,
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchFormalStudentWise = async (
    grade,
    subject,
    quizId
  ) => {
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/report/get-formal-studentwise?boardId=${boardId}&gradeId=${grade}&subjectId=${subject}&schoolId=${schoolId}&academicYearId=${selectedAcademicYear}&branchId=${branchId}&sectionId=${selectedSection}&teacherId=${teacherId?.id}&quizId=${quizId}&quizTypeId=${selectedQuizType}`;
    const res = await axios.get(
      url,
      {
        signal: abortController.signal,
        headers: {
          "Content-TYpe": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("res?.data?.data", res?.data?.data)

    if (res && res.data && res.data.data && res.data.data.formalListOfStudentWiseResponse) {
      res.data.data.formalListOfStudentWiseResponse = res.data.data.formalListOfStudentWiseResponse.map(entry => {
        console.log("entry", entry);
        const [numerator, denominator] = entry?.total?.split("/") || [];
        const num = parseFloat(numerator);
        const denom = parseFloat(denominator);
        const Vpercentage = Math.round((num / denom) * 100);
        return {
          ...entry,
          percentage: Vpercentage,
        };
      });
    }
    if (res && res.data && res.data.data && res.data.data.subjectWithSubtopic && res.data.data.subjectWithSubtopic.length > 0) {
      res.data.data.subjectWithSubtopic = res.data.data.subjectWithSubtopic.map(entry => {
        let totalNumerator = 0;
        let totalDenominator = 0;

        // Ensure subDetails exists before mapping
        if (entry && entry.subDetails) {
          entry.subDetails.forEach(item => {
            const [numerator, denominator] = item.marks.replace(/\s+/g, '').split("/").map(Number);
            totalNumerator += numerator;
            totalDenominator += denominator;
          });
        }
        const totalMarks = `${totalNumerator}/${totalDenominator}`;
        const Vpercentage = Math.round((parseFloat(totalNumerator) / parseFloat(totalDenominator)) * 100);
        return {
          ...entry,
          percentage: Vpercentage,
        };
      });
    }


    return res?.data?.data || [];
  };

  function groupAndSumMarks(data) {
    const groupedData = new Map();
    data.forEach(item => {
      const { subConceptName, mark } = item;
      const [numerator, denominator] = mark.split('/').map(Number);
      if (groupedData.has(subConceptName)) {
        let existingItem = groupedData.get(subConceptName);
        existingItem.numerator += numerator;
        existingItem.denominator += denominator;
      } else {
        groupedData.set(subConceptName, { ...item, numerator, denominator });
      }
    });
    const result = Array.from(groupedData, ([subConceptName, item]) => ({
      ...item,
      mark: `${item.numerator}/${item.denominator}`
    }));
    return result;
  }


  useEffect(() => {
    firstRender();
    downloadStudentPerformance();
    return () => {
      cleanupOnUnmount()
    }
  }, [token])

  const cleanupOnUnmount = () => {
    setSelectedGrade('')
    setSelectedSection('')
    setSelectedSubject('')
    sessionStorage.removeItem('sectionId')
    sessionStorage.removeItem('gradeId')
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const firstRender = async () => {
    setIsPageLoading(true)
    const teacherData = await fetchTeacherData(userName, role)

    sessionStorage.setItem('teacherId', teacherData?.id);
    sessionStorage.setItem('boardId', teacherData?.boardId);
    sessionStorage.setItem('schoolId', teacherData?.school);
    sessionStorage.setItem('branchId', teacherData?.branch);
    setTeacherId(teacherData)
    await GetLastModifiedAt(setModifiedDate)
    const gradeSectionList = await fetchGradeData(teacherData?.id);
    setGradeSection(gradeSectionList)
    const academic = await fetchAllAcademicYear()
    setAcademicYearList(academic)
    const storedAcademicYearId = sessionStorage.getItem('academicYearId');
    if (storedAcademicYearId) {
      setSelectedAcademicYear(storedAcademicYearId)
    } else if (academic?.length > 0) {
      setSelectedAcademicYear(academic[0].id);
      sessionStorage.setItem('academicYearId', academic[0].id);
    }
    //auto population
    await autoPopulate(gradeSectionList, teacherData?.id)
  }

  const autoPopulate = async (gradeSectionList, teacherId) => {
    const gradeSectionFirstElement = gradeSectionList[0]
    const sectionfirstElement = gradeSectionFirstElement?.sections[0]
    let subjectParams = {
      gradeId: gradeSectionFirstElement?.id,
      sectionId: sectionfirstElement?.id,
      teacherId: teacherId,
      menuName: "TEACH"
    }
    var subjectListFetched = await fetchSubjectData(subjectParams);
    console.log("4-7-24", subjectListFetched)
    subjectListFetched = subjectListFetched?.filter(v => v.skilledSubject === true) || [];
    console.log("aftr", subjectListFetched)
    if (subjectListFetched?.length === 0) {
      setErrorMsg('NO SUBJECTS ARE AVAILABLE');
      setSelectedSubject('');
    } else {
      setSubjectList(subjectListFetched)
      setSelectedSubject(subjectListFetched[0]?.subjectId)
      setErrorMsg('');
    }

    setSectionList(gradeSectionFirstElement?.sections);
    setGradeList(gradeSectionList);
    setSelectedGrade(gradeSectionFirstElement?.id);
    setSelectedSection(sectionfirstElement?.id);
    // setSubTopicList(subjectListFetched[0]?.subTopics);
    // setSelectedSubTopic(subjectListFetched[0]?.subTopics[0]?.id);

    sessionStorage.setItem('gradeId', gradeSectionFirstElement?.id);
    sessionStorage.setItem('subjectId', subjectListFetched[0]?.subjectId);
    sessionStorage.setItem('sectionId', sectionfirstElement?.id);
    // sessionStorage.setItem('subTopicId',subjectListFetched[0]?.subTopics[0]?.id)
    setIsPageLoading(false);
  }

  useEffect(async () => {
    const quizType = await fetchQuizType();
    setQuizTypeList(quizType);
    setSelectedQuizType(quizType[0]?.id);
  }, []);

  useEffect(async () => {
    if (
      isNOTNullOrUndefined(selectedGrade) &&
      isNOTNullOrUndefined(selectedSection) &&
      isNOTNullOrUndefined(selectedSubject) &&
      isNOTNullOrUndefined(selectedQuizType) &&
      changedFilter != "quiz"
    ) {
      try {
        setQuestionLoader(true);
        const quizfetched = await fetchQuizList();
        if (quizfetched?.length === 0) {
          setQuestionLoader(false);
          setErrorMsg('NO QUIZZES ARE AVAILABLE');
          setQuizList([]);
          setSelectedQuiz('');
          setQuizName('');
          setQuizOverviewData({});
          setStudentPerformanceReport({});
          setChapterName("");
          setChapterConcepts([]);
          setSectionAvgData([]);
          setGlobalAvgData([]);
          setQuestionWisePerformanceData({});
          setTop5PerformanceData({});
          setStudentTabData([]);
        } else {
          setQuestionLoader(false);
          setQuizList(quizfetched);
          setSelectedQuiz(quizfetched[0]?.quizId);
          setQuizName(quizfetched[0]?.quizName);
          setErrorMsg('');
        }
      } catch {
        setQuestionLoader(false);
        setErrorMsg('NO QUIZZES ARE AVAILABLE');
        setQuizList([]);
        setSelectedQuiz('');
        setQuizName('');
      }
    } else {
      setErrorMsg('NO QUIZZES ARE AVAILABLE');
      setQuizList([]);
      setSelectedQuiz('');
      setQuizName('');
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedQuizType]);

  useEffect(async () => {
    if (!isPageLoading) {
      if (selectedGrade && selectedSubject && selectedQuiz &&
        selectedAcademicYear && tabsValue === '1'
      ) {
        // fetch overview data which contains student count in section, no. of questions, no. of case studies etc.
        const fetchReportQuizOverview = async () => {
          try {
            const report = await fetchPerformanceReportQuizOverview(
              selectedGrade,
              selectedSubject,
              selectedSection,
              selectedQuiz,
            );
            setQuizOverviewData(report);
            // setQuizOverviewData(formalOverview.data)
          } catch (err) {
            console.log("not able to fetch quiz overview data");
            setQuizOverviewData({});
          }
        };
        fetchReportQuizOverview();

        // fetch student list for top 5, bottom 5, absentees
        const fetchReportStudentPerformance = async () => {
          try {
            const report = await fetchPerformanceReportStudentPerformance(
              selectedGrade,
              selectedSubject,
              selectedSection,
              selectedQuiz,
              selectedAcademicYear,
            );
            setStudentPerformanceReport(report);
            // setStudentPerformanceReport(top5bottom5.data)
          } catch (err) {
            console.log("not able to fetch student performance data")
          }
        };
        fetchReportStudentPerformance();

        // fetch chapter concepts, sub-concepts, root-concepts to display in slider.
        const fetchReport = async () => {
          try {
            const report = await fetchPerformanceReportChapterConcept(
              selectedGrade,
              selectedSubject,
              selectedSection,
              selectedQuiz
            );
            if (report) {
              setChapterName(
                chaptersList?.find((item) => item?.quizId === selectedChapter)?.chapter ||
                ""
              );
              setChapterConcepts(report);
            } else {
              setChapterName("");
              setChapterConcepts([]);
            }
            // setChapterName(formal_assessment.quizName)
            // setChapterConcepts(report);
            // setChapterConcepts(conceptTreeFormal.data);
          } catch (err) {
            setChapterConcepts([]);
            setChapterName("");
            console.log("not able to fetch performance report data")
          }
        };
        fetchReport();
      }
    }

    return () => {
      abortController.abort();
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedSubTopic, selectedQuiz, isPageLoading, selectedAcademicYear, tabsValue]);

  useEffect(async () => {
    if (!isPageLoading) {
      if (selectedGrade && selectedSubject && selectedQuiz &&
        selectedAcademicYear && tabsValue === '3') {
        // fetch question wise performance report
        const fetchReportQuestionWiseQuizPerformance = async () => {
          try {
            setQuestionLoader(true);
            const report = await fetchQuestionWiseQuizPerformance(
              selectedGrade,
              selectedSubject,
              selectedSubTopic,
              selectedQuiz
            );
            setQuestionWisePerformanceData(report);
            setQuestionLoader(false);
            // setQuestionWisePerformanceData(question_wise_data.data);
          } catch (err) {
            setQuestionLoader(false);
            setQuestionWisePerformanceData({});
            console.log("not able to fetch quiz wise data !")
          }
        };
        fetchReportQuestionWiseQuizPerformance();
        // if(selectedQuiz !== prevSelectedQuiz.current) {
        // }
        // prevSelectedQuiz.current = selectedQuiz;
      } else if (selectedGrade && selectedSubject && selectedQuiz &&
        selectedAcademicYear && tabsValue === '4') {
        // fetch question wise performance report
        const fetchTop5QuestionWiseQuizPerformance = async () => {
          try {
            setQuestionLoader(true);
            const report = await fetchTop5QuizPerformance(
              selectedGrade,
              selectedSubject,
              selectedSubTopic,
              selectedQuiz
            );
            setTop5PerformanceData(report);
            setQuestionLoader(false);
            // setQuestionWisePerformanceData(question_wise_data.data);
          } catch (err) {
            setQuestionLoader(false);
            setTop5PerformanceData({});
            console.log("not able to fetch top 5 quiz data !")
          }
        };
        fetchTop5QuestionWiseQuizPerformance();
      }

      return () => {
        abortController.abort();
      }
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedSubTopic, selectedQuiz, isPageLoading, selectedAcademicYear, tabsValue]);

  useEffect(() => {
    // console.log("=====================================", selectedGrade, selectedSubject, selectedQuiz, tabsValue)
    if (selectedGrade && selectedSubject && selectedQuiz && tabsValue === '2') {
      const fetchFormalStudentWiseData = async () => {
        try {
          const reportTemp = await fetchFormalStudentWise(
            selectedGrade,
            selectedSubject,
            selectedQuiz
          );
          // console.log(report)
          var recrd = reportTemp?.formalListOfStudentWiseResponse?.map((v) => {
            // console.log(v.concepts, "===========1")
            v.concepts?.map((p) => {
              // console.log(p.subConcepts, "===============2")
              var sub = groupAndSumMarks(p.subConcepts);
              // console.log(sub, p.subConcepts, "=======3")
              p.subConcepts = sub;
              return p;
            });
            return v;
          })
          reportTemp.formalListOfStudentWiseResponse = recrd
          // console.log(recrd, "formalListOfStudentWiseResponse", report)
          // setStudentTabDataTemp(reportTemp);

          setIsPageLoading(true);
          const report = await fetchFormalStudentWise(
            selectedGrade,
            selectedSubject,
            selectedQuiz
          );
          report.formalListOfStudentWiseResponseTemp = recrd
          console.log(report)
          setStudentTabData(report);
          setAbsenteeStudentTabData(report?.absentees || []);
        } catch (error) {
          setStudentTabData([]);
          console.log("not able to fetch student wise data !");
        } finally {
          setIsPageLoading(false);
        }
      }
      fetchFormalStudentWiseData();
    } else {
    }

    return () => {
      abortController.abort();
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedQuiz, selectedAcademicYear, tabsValue]);

  useEffect(() => {
    if (selectedGrade && selectedSubject && selectedQuiz && tabsValue === '1') {
      // fetch section global average data
      const fetchGraphData = async () => {
        try {
          setIsGraphLoading(true);
          const report = await fetchSectionAvgData(
            selectedGrade,
            selectedSubject,
            selectedSection,
            selectedQuiz
          );
          setSectionAvgData(report);
        } catch (err) {
          console.log("not able to fetch graph data");
          setSectionAvgData([]);
        } finally {
          setIsGraphLoading(false);
        }
      }
      fetchGraphData();

      const fetchGlobalGraphData = async () => {
        try {
          setIsGraphLoading(true);
          const report = await fetchGlobalAvgData(
            selectedGrade,
            selectedSubject,
            selectedSection,
            selectedQuiz
          );
          setGlobalAvgData(report);
        } catch (err) {
          console.log("not able to fetch graph data");
          setGlobalAvgData([]);
        } finally {
          setIsGraphLoading(false);
        }
      }
      fetchGlobalGraphData();
    }

    return () => {
      abortController.abort();
    }
  }, [selectedGrade, selectedSection, selectedSubject, selectedQuiz, selectedAcademicYear, tabsValue]);

  const handleChangeClear = (filter) => {
    switch (filter) {
      case "grade":
        setSelectedGrade("");
        setSelectedSection("");
        setSelectedSubject("");
        // setSelectedQuizType("");
        setSelectedQuiz("");
        break;

      case "section":
        setSelectedSection("");
        setSelectedSubject("");
        // setSelectedQuizType("");
        setSelectedQuiz("");
        break;

      case "subject":
        setSelectedSubject("");
        // setSelectedQuizType("");
        setSelectedQuiz("");
        break;

      case "quizType":
        // setSelectedQuizType("");
        setSelectedQuiz("");
        break;
    }
  }

  const handleChangeGrade = async (value) => {
    handleChangeClear("grade");
    setChangedFilter("grade");
    const selectedId = value;
    setSelectedGrade(selectedId);
    const sections = gradeSection?.find(i => i?.id === selectedId)?.sections
    sessionStorage.setItem('gradeId', selectedId)
    setSelectedSubject('')
    setSelectedSubTopic('')
    setSectionList(sections)
    setSelectedSection(sections?.length ? sections[0].id : "")
    let subjectParams = {
      gradeId: value,
      sectionId: sections?.length ? sections[0].id : "",
      menuName: "TEACH"
    }
    var subjectsFetchList = await fetchSubjectData(subjectParams);
    console.log("4-7-24 ", subjectsFetchList)
    subjectsFetchList = subjectsFetchList?.filter(v => v.skilledSubject === true) || [];
    console.log("aftr", subjectsFetchList)

    let subject = subjectsFetchList?.length ? subjectsFetchList[0] : [];
    if (subjectsFetchList?.length === 0) {
      setQuizOverviewData({});
      setSelectedSubject('');
      setStudentPerformanceReport({});
      setChapterName("");
      setChapterConcepts([]);
      setSectionAvgData([]);
      setGlobalAvgData([]);
      setQuestionWisePerformanceData({});
      setTop5PerformanceData({});
      setStudentTabData([]);
    } else {
      setSubjectList(subjectsFetchList)
      setSelectedSubject(subject?.subjectId);
      setErrorMsg('');
    }
    sessionStorage.setItem('sectionId', sections?.length ? sections[0].id : "")
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const handleChangeSection = async value => {
    setChangedFilter("section");
    const secId = value
    setSelectedSection(secId)
    setSelectedSubject('')
    setSelectedSubTopic("")
    let subjectParams = {
      gradeId: selectedGrade,
      sectionId: secId,
      menuName: "TEACH"
    }
    var subjectsFetchList = await fetchSubjectData(subjectParams);
    let subject = subjectsFetchList?.length ? subjectsFetchList[0] : [];
    if (subjectsFetchList?.length > 0) {

      console.log("4-7-24", subjectsFetchList)
      subjectsFetchList = subjectsFetchList?.filter(v => v.skilledSubject === true) || [];
      console.log("aftr", subjectsFetchList)

      setSubjectList(subjectsFetchList);
      setSelectedSubject(subject?.subjectId);
      setErrorMsg('');
    } else {
      setQuizOverviewData({});
      setSelectedSubject('');
      setStudentPerformanceReport({});
      setChapterName("");
      setChapterConcepts([]);
      setSectionAvgData([]);
      setGlobalAvgData([]);
      setQuestionWisePerformanceData({});
      setTop5PerformanceData({});
      setStudentTabData([]);
    }
    sessionStorage.setItem('sectionId', value)
    sessionStorage.removeItem('subjectId')
    sessionStorage.removeItem('subTopicId')
  }

  const handleChangeSubject = value => {
    handleChangeClear("subject");
    setChangedFilter("subject");

    setSelectedSubject(value)
    sessionStorage.setItem('subjectId', value)
  }

  const handleChangeQuizType = async (value) => {
    handleChangeClear("quizType");
    setChangedFilter("quizType");
    setSelectedQuizType(value);
  }

  const handleChangeQuiz = value => {
    setSelectedQuiz(value);

    const findQuiz = quizList.find(item => item?.quizId === value);
    setQuizName(findQuiz?.quizName);
  }

  const handleChangeAcademicYear = value => {
    setChangedFilter("year");
    setSelectedAcademicYear(value)
    sessionStorage.setItem('academicYearId', value)
  }


  return (
    <div
      style={{
        width: "100%",
        backgroundColor: `${tabsValue === '2'
          ? '#fff'
          : '#efeef3'}`,
        height: `${tabsValue === '1'
          ? '147vh'
          : '100%'
          }`

      }}
    >
      <TopBar
        title={"Home"}
        role={role}
        nav1={
          role === "TEACHER"
            ? "TeacherDashboard"
            : "principal/dashboard"
        }
        subTitle={"Reports"}
        subSubTitle={"Formal Assessment"}
        modifiedDate={modifiedDate}
      />
      <div
        style={{
          marginTop: "15px",
          paddingRight: "33px",
          position: "absolute",
          left: "23px",
          width: "98%",
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
        }}
      >
        {/* Select component starts */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "1rem",
          }}
        >
          {/* <FormControl
            sx={{
              minWidth: "9.3vw",
              background: "white",
              borderRadius: 150,
              width: '150px',
              height: '32px'
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Select Grade
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                // background: "white",
                height: "32px",
                color: '#FD8C00'
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedGrade}
              onChange={e => handleChangeGrade(e.target.value)}
            >
              {gradeList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.grade}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        },
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: '13px',
                color: '#e7ab3c',
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
                '&.Mui-focused': {
                  color: '#e7ab3c'
                }
              }}
              className='dropDownLabel'
              id="demo-simple-select-label"
            >
              Grade
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput label='Grade' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop: '2px',
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}
              IconComponent={props => (
                <ExpandMoreIcon {...props} sx={{ fontSize: '1.5vw' }} />
              )}
              sx={{
                width: '150px',
                height: '30px',
                padding: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c',
                }
              }}
              className='dropdownSelect'
              value={selectedGrade}
              onChange={e => handleChangeGrade(e.target.value)}
            >
              {gradeList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.grade}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <FormControl
            sx={{
              minWidth: "9.3vw",
              background: "white",
              borderRadius: 150,
              width: '150px',
              height: '32px'
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Select Section
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                // background: "white",
                height: "32px",
                color: '#FD8C00'
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedSection}
              onChange={e => handleChangeSection(e.target.value)}
            >
              {sectionList.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.section}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <FormControl fullWidth >
            <InputLabel
              sx={{
                fontSize: '13px',
                color: '#e7ab3c',
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
                '&.Mui-focused': {
                  color: '#e7ab3c'
                }
              }}
              className='dropDownLabel'
              id="demo-simple-select-label"
            >
              Section
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput label='Section' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop: '2px',
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}
              IconComponent={(props) => (
                <ExpandMoreIcon
                  {...props}
                  sx={{ fontSize: "1.5vw" }}
                />
              )}
              sx={{
                width: '150px',
                height: '30px',
                padding: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c',
                }
              }}
              className='dropdownSelect'
              value={selectedSection}
              onChange={e => handleChangeSection(e.target.value)}
            >
              {sectionList.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.section}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <FormControl
            sx={{
              minWidth: "9.3vw",
              background: "white",
              borderRadius: 150,
              width: '150px',
              height: '32px'
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Select Subject
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                // background: "white",
                height: "32px",
                color: '#FD8C00'
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedSubject}
              onChange={e => handleChangeSubject(e.target.value)}
              disabled={selectedSubject === '' ? true : false}
            >
              {subjectList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.subjectId}
                    key={item?.subjectId}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.subject}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: '13px',
                color: '#e7ab3c',
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
                '&.Mui-focused': {
                  color: '#e7ab3c'
                }
              }}
              className='dropDownLabel'
              id="demo-simple-select-label"
            >
              Subject
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput label='Subject' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop: '2px',
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}
              IconComponent={(props) => (
                <ExpandMoreIcon
                  {...props}
                  sx={{ fontSize: "1.5vw" }}
                />
              )}
              sx={{
                width: '150px',
                height: '30px',
                padding: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c',
                }
              }}
              className='dropdownSelect'
              value={selectedSubject}
              onChange={e => handleChangeSubject(e.target.value)}
              disabled={selectedSubject === '' ? true : false}
            >
              {subjectList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.subjectId}
                    key={item?.subjectId}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.subject}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <FormControl
            sx={{
              borderRadius: 150,
              background: "white",
              borderRadius: 150,
              width: '200px',
              height: '32px'
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Select Quiz Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                // background: "white",
                height: "32px",
                color: '#FD8C00'
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedQuizType}
              onChange={e => handleChangeQuizType(e.target.value)}
              disabled={selectedQuizType === '' ? true : false}
            >
              {quizTypeList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.quizType}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: '13px',
                color: '#e7ab3c',
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
                '&.Mui-focused': {
                  color: '#e7ab3c'
                }
              }}
              className='dropDownLabel'
              id="demo-simple-select-label"
            >
              Quiz Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput label='Quiz Type' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop: '2px',
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}
              IconComponent={(props) => (
                <ExpandMoreIcon
                  {...props}
                  sx={{ fontSize: "1.5vw" }}
                />
              )}
              sx={{
                width: '150px',
                height: '30px',
                padding: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c',
                }
              }}
              className='dropdownSelect'
              value={selectedQuizType}
              onChange={e => handleChangeQuizType(e.target.value)}
              disabled={selectedQuizType === '' ? true : false}
            >
              {quizTypeList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.quizType}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <FormControl
            sx={{
              minWidth: "9.3vw",
              background: "white",
              borderRadius: 150,
              width: '200px',
              height: '32px'
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Quiz Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                // background: "white",
                height: "32px",
                color: '#FD8C00'
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedQuiz}
              onChange={(e) => { handleChangeQuiz(e.target.value); setChangedFilter('quiz') }}
              disabled={selectedQuiz === '' ? true : false}
            >
              {quizList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.quizId}
                    key={item?.quizId}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.quizName}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: '13px',
                color: '#e7ab3c',
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
                '&.Mui-focused': {
                  color: '#e7ab3c'
                }
              }}
              className='dropDownLabel'
              id="demo-simple-select-label"
            >
              Quiz Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput label='Quiz Names' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop: '2px',
                    maxHeight: '190px',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '2px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#888',
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: '#555',
                    },
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px',
                    overflowY: 'auto',
                  },
                },
              }}
              IconComponent={(props) => (
                <ExpandMoreIcon
                  {...props}
                  sx={{ fontSize: "1.5vw" }}
                />
              )}
              sx={{
                width: '150px',
                height: '30px',
                padding: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c',
                }
              }}
              className='dropdownSelect'
              value={selectedQuiz}
              onChange={(e) => { handleChangeQuiz(e.target.value); setChangedFilter('quiz') }}
              disabled={selectedQuiz === '' ? true : false}
            >
              {quizList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.quizId}
                    key={item?.quizId}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.quizName}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <FormControl
            sx={{
              background: "white",
              borderRadius: 150,
              width: '150px',
              height: '32px',
              minWidth: "9.3vw",
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#7A7A7A"
                },
                fontSize: '14px'
              }}
            >
              Academic Year
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={MenuProps}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              sx={{
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000"
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                },
              }}
              style={{
                borderRadius: "150px",
                padding: "4px",
                // background: "white",
                height: "32px",
                color: '#FD8C00'
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedAcademicYear}
              onChange={e => handleChangeAcademicYear(e.target.value)}
            >
              {academicYearList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.academicYear}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}

          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: '13px',
                color: '#e7ab3c',
                top: '-10px',
                '&.MuiInputLabel-shrink': {
                  top: '0',
                },
                '&.Mui-focused': {
                  color: '#e7ab3c'
                }
              }}
              className='dropDownLabel'
              id="demo-simple-select-label"
            >
              Academic Year
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput label='Academic Year' />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '150px',
                    marginTop: '2px',
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: '0px'
                  },
                },
              }}
              IconComponent={(props) => (
                <ExpandMoreIcon
                  {...props}
                  sx={{ fontSize: "1.5vw" }}
                />
              )}
              sx={{
                width: '150px',
                height: '30px',
                padding: '10px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#000',
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e7ab3c',
                }
              }}
              className='dropdownSelect'
              value={selectedAcademicYear}
              onChange={e => handleChangeAcademicYear(e.target.value)}
              disabled
            >
              {academicYearList?.map((item) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={item?.id}
                    className={dashboardContentStyles.menu_item}
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: '#f4d788',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#e7ab3c',
                        '&:hover': {
                          backgroundColor: '#f4d788',
                        },
                      },
                    }}
                  >
                    <ListItemText
                      primary={item?.academicYear}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

        </div>
        {/* Select component ends */}
        {/* Tabpanel starts here to switch between quiz overview, student wise performance etc. */}
        <Box sx={{ width: "100%", typography: "body1", height: "100%" }}>
          <TabContext value={tabsValue}>
            <Box
              sx={{
                borderBottom: 2,
                borderColor: "#403E75",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TabList
                onChange={(e, value) => tabsChangeHandle(e, value)}
                aria-label="lab API tabs example"
                textColor="inherit"
                indicatorColor="secondary"
              >
                <Tab
                  label="Quiz Overview"
                  value="1"
                  sx={{
                    background: tabsValue === "1" ? "#403E75" : "",
                    color: tabsValue === "1" ? "white" : "black",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Student Wise Performance"
                  value="2"
                  sx={{
                    background: tabsValue === "2" ? "#403E75" : "",
                    color: tabsValue === "2" ? "white" : "",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Question Wise Performance"
                  value="3"
                  sx={{
                    background: tabsValue === "3" ? "#403E75" : "",
                    color: tabsValue === "3" ? "white" : "",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Top 5 Difficult Questions"
                  value="4"
                  sx={{
                    background: tabsValue === "4" ? "#403E75" : "",
                    color: tabsValue === "4" ? "white" : "",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                  }}
                />
              </TabList>
              {tabsValue === "2" && (
                <DarkYellowButton
                  style={{ marginBottom: "5px" }}
                  onClick={() => setDownloadReport(true)}
                  disabled={studentTabData?.length === 0 || (studentTabData?.formalListOfStudentWiseResponse?.length === 0 && studentTabData?.subjectWithSubtopic?.length === 0)}
                >
                  Download Report
                </DarkYellowButton>
              )}
            </Box>
            {(tabsValue === '1' || tabsValue === '2') &&
              <Paper
                sx={{
                  width: "100%",
                  minHeight: "650px",
                  backgroundColor: `${tabsValue === '1' ? '#f7f7fc' : '#fff'}`,
                  boxShadow: '0 0 0 0'
                }}
              >
                {/* Quiz Overview panel */}
                <TabPanel value="1">
                  <QuizOverview
                    chapterConcepts={chapterConcepts}
                    chapterName={chapterName}
                    studentPerformanceReport={studentPerformanceReport}
                    quizOverviewData={quizOverviewData}
                    averageGraphData={sectionAvgData}
                    globalAvgData={globalAvgData}
                    loading={isPageLoading}
                    graphLoading={isGraphLoading}
                    quizName={quizName}
                  />
                </TabPanel>
                {/* studentwise performance panel */}

                <TabPanel value="2">
                  {
                    (absenteestudentTabData && studentTabData) ?
                      <StudentWisePerformance
                        data={studentTabData}
                        absenteeData={absenteestudentTabData}
                        downloadReport={downloadReport}
                        setDownloadReport={setDownloadReport}
                        loading={isPageLoading}
                      /> :

                      <div
                        style={{
                          position: 'relative',
                          fontSize: '18px',
                          fontWeight: 500,
                          color: '#9b9fa7',
                          opacity: 0.8,
                        }}
                      >
                        NO QUIZZES ARE AVAILABLE
                      </div>
                  }

                </TabPanel>
              </Paper>}
            {/* question wise performance panel */}
            <TabPanel value="3">
              <QuestionWisePerformance
                accordionChangeHandler={accordionChangeHandler}
                accordionData={questionWisePerformanceData}
                expanded={expanded}
                intIndex={intIndex}
                setSelectedImageUrl={setSelectedImageUrl}
                setOpenImageModal={setOpenImageModal}
                loading={questionLoader}
                tabsValue={tabsValue}
                errorMsg={errorMsg}
              />
            </TabPanel>
            {/* top 5 difficult questions panel */}
            {/* question wise performance and top 5 difficult panel designs are same so we are using same component */}
            <TabPanel value="4">
              {/* <QuestionWisePerformance
                  accordionChangeHandler={questionAccordionHandler}
                  accordionData={accordionData}
                  expanded={questionExpanded}
                  intIndex={questionIndex}
                  setSelectedImageUrl={setSelectedImageUrl}
                  setOpenImageModal={setOpenImageModal}
                /> */}

              <QuestionWisePerformance
                accordionChangeHandler={accordionChangeHandler}
                accordionData={top5PerformanceData}
                expanded={expanded}
                intIndex={intIndex}
                setSelectedImageUrl={setSelectedImageUrl}
                setOpenImageModal={setOpenImageModal}
                loading={questionLoader}
                tabsValue={tabsValue}
                errorMsg={errorMsg}
              />

            </TabPanel>
          </TabContext>
        </Box>
        {/* Tab panel ends here */}
      </div>
      {openImageModal && (
        <ViewImage
          open={openImageModal}
          close={() => {
            setOpenImageModal(false);
            setSelectedImageUrl("");
          }}
          url={selectedImageUrl}
        />
      )}
    </div>
  );
};
export default FormalAssessment;
