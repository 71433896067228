import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import sort from '../../../img/sort_1.png'
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone'
import { Tooltip } from 'react-tooltip'
import { truncate } from 'lodash'

function ViewQuizTable({ quizes, setSelectedQuiz, setPreview, handleSort, startIndexfrom }) {
  const columns = [
    { field: 'id', headerName: '#', width: 40 },
    {
      field: 'board',
      headerName: 'Board',
      width: 140,
      sortable: true
    },
    {
      field: 'grade',
      headerName: 'Grade',
      width: 90,
      sortable: true
    },
    {
      field: 'subject',
      headerName: 'Subject',
      width: 100,
      sortable: true
    },
    {
      field: 'subTopic',
      headerName: 'Sub Topic',
      width: 120,
      sortable: true
    },
    {
      field: 'chapter',
      headerName: 'Chapter',
      width: 130,
      sortable: true
    },
    {
      field: 'name',
      headerName: 'Quiz Name',
      width: 140,
      sortable: true
    },
    {
      field: 'totalQuestions',
      headerName: 'Questions',
      width: 100,
      sortable: true
    },
    {
      field: 'totalMarks',
      headerName: 'Marks',
      width: 80,
      sortable: true
    },
    {
      field: 'duration',
      headerName: 'Time',
      width: 80,
      sortable: true
    }
  ]

  const renderCellData = (data, item, i) => {
    if (data.field === 'chapter') {
      const list = item?.chapterList
      const lastIndex = item?.chapterList.length - 1
      return list?.map((i, index) => list[lastIndex].chapter === i.chapter ? i.chapter : i.chapter + ', ')
    } else if (data.field === 'id') {
      return startIndexfrom + i + 1
    } else if (data.field === 'duration') {
      return `${item['duration']} Min`
    } else {
      return item[data.field]
    }
  }

  const divRef = useRef(null);

  const scrollToTop = () => {
    divRef.current.scroll({
      top: 0,
      behavior: "smooth"
    });
  }

  useEffect(() => {
    scrollToTop()
  }, [quizes])

  return (
    <div style={{ overflow: 'auto', height: '90%', width: '100%', position: 'relative', }} ref={divRef}>
      <div
        style={{
          justifyContent: 'flex-start',
          display: 'flex',
          gap: 10,
          background: ' #403E75',
          color: '#ffffff',
          width:
            columns.reduce((acc, curr, index, arr) => {
              return acc + curr.width
            }, 0) + 220,
          position: 'sticky',
          top: 0,
          height: 40,
          zIndex: 10
        }}
      >
        {columns.map(data => (
          <TableElement style={{ width: data.width, minWidth: data.width, }}>
            {data.headerName}{' '}
            {!data.sortable ? (
              ''
            ) : (
              <SortIcon onClick={() => handleSort(data.field)}>
                <img src={sort} alt='Sort' />
              </SortIcon>
            )}
          </TableElement>
        ))}
        <TableElement style={{ width: 100, minWidth: 100 }}>
          Actions
        </TableElement>
        <div style={{ height: '200px' }} />
      </div>
      <TableBody>
        {quizes?.map((item, i) => (<>
          <TableColumn>
            {columns.map(data => (<>
              <TableItem
                id={item.id + data.field}
                style={{
                  width: data.width,
                  minWidth: data.width
                }}
                className='cursorDefault'
              >
                <span data-tooltip-content={renderCellData(data, item, i)}
                  data-tooltip-id={item.id + data.field}> {renderCellData(data, item, i)}</span>

                <Tooltip
                  id={item.id + data.field}
                  // tittle={item.id + data.field}
                  // anchorSelect={`#${item.id + data.field}`}
                  place='bottom'
                // content={renderCellData(data, item, i)}
                />
              </TableItem></>
            ))}
            <TableItem style={{ width: 100, minWidth: 100 }}>
              <PreviewButton
                id={`az-revisionreviewer-quizpreview-button`}
                onClick={() => {
                  setPreview(true)
                  setSelectedQuiz({
                    id: item.id
                  })
                }}
              >
                View
              </PreviewButton>
            </TableItem>
          </TableColumn>
        </>))}
      </TableBody>
    </div>
  )
}

export default ViewQuizTable

const TableElement = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  font-family: 'poppins' !important;
`
const SortIcon = styled.span`
  display: block;
  width: 12px;
  margin-left: 5px;
  cursor: pointer;
  img {
    display: block;
    width: 100%;
  }
  :hover {
    opacity: 0.8;
  }
`
const TableBody = styled.div`
  height: 100%;
`
const TableColumn = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  position: relative;
  
  &:hover {   
    border: 1px solid #fd8c00;
  }
  // :hover {
  //   // box-shadow: orange;
  //    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // }
  // :after {
  //   position: absolute;
  //   content: '';
  //   left: 0;
  // }
`
const TableItem = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  text-align: flex-start;
  z-index:0;
  font-family: 'poppins' !important;
  
`

const PreviewButton = styled.span`
  display: flex;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  color: #fd8c00;
  border: 1px solid #fd8c00;
  border-radius: 20px;
  transition: all 0.3s;
  cursor: pointer;
  :hover {
    background-color: #fd8c00;
    color: #fff;
  }
`
