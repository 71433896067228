import axios from 'axios'

async function States_Controller_getall(setStatesName, CityInputs) {
  let token = sessionStorage.getItem("token");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/states/all?countryId=${CityInputs.countryId}`,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setStatesName(json.data.data)
    })
    .catch(err=>console.log(err))
}

export default States_Controller_getall;
