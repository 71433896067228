import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Box, Modal, Typography, Grid, TextField, Button, FormControlLabel, Checkbox } from "@mui/material";

// import { Accordion, AccordionSummary, AccordionDetails, Button, IconButton } from '@mui/material';
import Green100Button from '../../components/common/Green100Button';
const AccessPassport = (props) => {
    console.log("gradeList", props);
    const {gradeList,gradeEditOpen,checkSection,checklist,show,CSPForm,message,Access} = props 
    
    return (
        <>
            {/* <div>
                {gradeList?.map((grade, index) => {
                    return (
                        <div key={index}>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    style={{ backgroundColor: "#e4e4f1" }}
                                >
                                    <h3> {grade.grade}</h3>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{textAlign:"end"}}>
                                    <IconButton
                                        sx={{
                                            backgroundColor: 'primary.main',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: 'primary.dark',
                                            },
                                            borderRadius: '50%', // Makes the button round
                                            width: 35, // this can adjust the width and height to make it a perfect circle
                                            height: 35,
                                        }}
                                        onClick={() => gradeEditOpen(grade)}
                                    >
                                        <EditIcon style={{backgroundColor:'#1976d2',border:"1px solid"}}/>
                                    </IconButton>
                                    </div>
                                    {grade.subjects?.map((subject, idx1) => {
                                        return (
                                            <div key={idx1} style={{
                                                padding: "20px",
                                                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                                                marginTop: "10px",
                                                backgroundColor: "#fbfbfb"
                                            }}>

                                                <h3>{subject.subject}</h3>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        columnGap: "1rem",
                                                        marginTop: "10px"
                                                    }}
                                                >
                                                    {subject.sectionsForSubject?.map((section, idx2) => {
                                                        return (
                                                            <div key={idx2}>
                                                                <div>{section.section}</div>

                                                            </div>
                                                        )
                                                    })}
                                                    <div
                                                        style={{
                                                            // width: "100%",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            rowGap: "1rem",
                                                            marginTop: "10px",
                                                            marginLeft: "10px"
                                                        }}
                                                    >

                                                        {subject.subTopics &&
                                                            subject.subTopics?.map((subTobic, idx3) => {
                                                                return (
                                                                    <div key={idx3}>
                                                                        {subTobic.subTopic}
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                columnGap: "1rem",
                                                                                marginTop: "10px",
                                                                                marginLeft: "20px"
                                                                            }}
                                                                        >
                                                                            {subTobic.sectionsForSubTopic?.map((subTobicSection, idx4) => {
                                                                                return (
                                                                                    <div key={idx4}>
                                                                                        <div>{subTobicSection.section}</div>

                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    )
                })}

            </div> */}
            <div>
                      <div style={{display:"flex",justifyContent:"flex-end"}}>
                          <Green100Button onClick={Access}>AddAccess</Green100Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: "490px",
                        }}
                      >
                        <div>
                          {!show && (
                            <div className={CSPForm.wrapper_teacherid}>
                              <div className={CSPForm.icon}>
                                Current Access
                                <span className={CSPForm.i_button}>
                                  <i className="fas fa-info-circle"></i>
                                </span>
                                <div className={CSPForm.tooltip} style={{ bottom: "75%" }}>
                                  Select Add Access option if new Access needs to be Added
                                  Deselect the section for grade and subject and select Update
                                  Access option if the current access need to be updated
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div
                          style={{
                            // padding: "20px 40px",
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ flexGrow: 1 }}>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "350px",
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: "1rem",
                                    marginLeft: "50px",
                                    padding: "10px",
                                    // overflowY: "scroll",
                                  }}
                                >
                                  {gradeList.length > 0 &&
                                    gradeList.map((v, i) => {
                                      return (
                                        <>
                                          <div>
                                            <div
                                              style={{
                                                width: "50%",
                                                fontSize: "20px",
                                                fontWeight: "bolder",
                                              }}
                                            >
                                              {v.grade}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              marginLeft: "30px",
                                            }}
                                          >
                                            {(v.subjects || []).length > 0 &&
                                              v.subjects.map((value, index) => {
                                                return (
                                                  <div>
                                                    <div style={{ fontSize: "18px", fontWeight: "600",  }}>{value?.subject}</div>
                                                    {(value?.subTopics || []).length > 0
                                                      ? value.subTopics.map((val, ind) => {
                                                        return (
                                                          <div>
                                                            <div style={{ fontSize: "16px", fontWeight: "500", marginLeft:"20px" }}>{val.subTopic}</div>
                                                            <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            padding:"10px"
                                                            }}>

                                                            {val.sectionsForSubTopic
                                                              .length > 0 &&
                                                              val.sectionsForSubTopic.map(
                                                                (va, i) => {
                                                                  return (
                                                                    <div
                                                                      style={{
                                                                        alignItems:
                                                                          "center",
                                                                        marginLeft: "25px",
                                                                        columnGap: "1rem",
                                                                        
                                                                      }}
                                                                    >
                                                                      
                                                                      <input
                                                                        type="checkbox"
                                                                        // checked={true}
                                                                        checked={
                                                                          Object.keys(checklist).includes(va.sectionId + val.subTopic + value.subjectId + v.gradeId) ?
                                                                            checklist[va.sectionId + val.subTopic + value.subjectId + v.gradeId] : true
                                                                        }
                                                                        onChange={(
                                                                          event
                                                                        ) => {
                                                                          checkSection(
                                                                            event,
                                                                            va.sectionId +
                                                                            val.subTopic + value.subjectId + v.gradeId,
                                                                            v.gradeId,
                                                                            va.sectionId,
                                                                            value.subjectId,
                                                                            val.subTopicId,
                                                                            `you have unchecked: ${v.grade},${value.subject},${val.subTopic},${va.section}`
                                                                          );
                                                                        }}
                                                                        style={{
                                                                          top: "23px",
                                                                          outlineOffset:
                                                                            "-2px",
                                                                          transform:
                                                                            "scale(1.1)",
                                                                          padding: "10px",
                                                                          accentColor:
                                                                            "orange",
                                                                        }}
                                                                      />
                                                                      <span
                                                                        style={{
                                                                          padding: "10px",
                                                                          fontSize: "16px",
                                                                          fontStyle: "italic",
                                                                        }}
                                                                      >
                                                                        {va.section}
                                                                      </span>
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          </div>
                                                        );
                                                      })
                                                      :  <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        padding:"10px"
                                        }}>
                                                      {value?.sectionsForSubject?.map(
                                                        (item, i) => {
                                                          return (
                                                            <div
                                                              style={{
                                                                alignItems: "center",
                                                                marginLeft: "25px",
                                                                columnGap: "1rem",
                                                              }}
                                                            >
                                                              <input
                                                                type="checkbox"
                                                                // checked={true}

                                                                checked={
                                                                  Object.keys(checklist).includes(item.sectionId + item.section + value.subjectId) ?
                                                                    checklist[item.sectionId + item.section + value.subjectId] : true
                                                                }
                                                                style={{
                                                                  top: "23px",
                                                                  outlineOffset: "-2px",
                                                                  transform: "scale(1.1)",
                                                                  padding: "10px",
                                                                  accentColor: "orange",
                                                                }}
                                                                onChange={(event) => {
                                                                  checkSection(
                                                                    event,
                                                                    item.sectionId + item.section + value.subjectId,
                                                                    v.gradeId,
                                                                    item.sectionId,
                                                                    value.subjectId,
                                                                    "",
                                                                    `you have unchecked:${v.grade},${value.subject},${item.section}`
                                                                  );
                                                                }}
                                                              />

                                                              <span
                                                                style={{
                                                                  padding: "10px",
                                                                  fontSize: "16px",
                                                                  fontStyle: "italic",
                                                                }}
                                                              >
                                                                {item.section}
                                                              </span>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                      </div>
                                                      }
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </>
                                      );
                                    })}
                                </div>
                          </Box>
                        </div>
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                          columnGap: "1rem",
                        }}
                      >
                        <div style={{ color: "blue", fontStyle: "italic", fontSize: "13px" }}>{message}</div>
                     
                      </div>
                    </div>
        </>
    )
}

export default AccessPassport;