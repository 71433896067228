import React, { useRef, useState, useEffect } from 'react'
import './HorizontallyScrollableContainerV2.css'
import { Tooltip } from '@mui/material';
import { isNullOrUndefined, truncateString } from '../../utils/helper';
import useShowScrollButtons from '../../hooks/quiz/useShowScrollButtons';

const HorizontallyScrollableContainerV2 = ({ data,displayEmberCategoryMessage }) => {
  //To do : Mkae it Reusable
  const containerRef = useRef(null)
  const { showPrevButton, showNextButton } = useShowScrollButtons(containerRef);

  const scroll = (scrollOffset) => {
    containerRef.current.scrollLeft += scrollOffset;
  };

  const clickableLabelStyle = {
    color: '#EE5D45',
    textDecoration: 'underline',
    cursor: 'pointer'
  }


  return (
    <div style={{ width: '100%', height: '296px', marginTop: '10px', background: '#fff', padding: '10px' ,borderRadius:'10px'}}>
   <div className="scrl-container-top">
   <p className="scrl-container-sub-heading">Chapterwise Performance</p>
        <div className="scrl-container-legend">
          <div className="scrl-container-indicator">
              <div className="clr-box" style={{background:'#5CA2F2'}}/>
              <p className="indicator-label">% Achieved</p>
          </div>
          <div className="scrl-container-indicator">
              <div className="clr-box" style={{background:'#64C2A6'}}/>
              <p className="indicator-label">Average %</p>
          </div>
        </div>
   </div>
      <div className="bar-chart-container">
        {showPrevButton ? <div className="round-btn" onClick={() => scroll(-150)}>{'<'}</div> : null}

        <div ref={containerRef} className="bar-chart">
          {data?.map((item, index) => <div key={item?.chapterId} className='cs-chart-bar-pair' style={{ background: item?.unitQuizObtainedMarksPercentage < 40 ? '#EE5D4540' : '' }}>
            <div className="cs-chart-bar-pair-inner">

              <div className='bar' style={{ background: "#5CA2F2", marginRight: '7px', height: `${item?.unitQuizObtainedMarksPercentage}%` }} >
                <p className='bar-label'>{item?.unitQuizObtainedMarksPercentage}%</p>
              </div>
              <div className='bar' style={{ background: "#64C2A6", height: `${item?.globalMarkPercentage}%` }} >
                <p className='bar-label'>{item?.globalMarkPercentage}%</p>
              </div>
              <div style={{ background: '#000', height: '1px', width: '90px', position: 'absolute', bottom: '-2px' }} />
              <Tooltip title={item?.chapterName}>
              <span className="cs-chart-pair-label" onClick={()=>{
                if(item?.unitQuizObtainedMarksPercentage < 40){
                  displayEmberCategoryMessage()
                }
              }} style={item?.unitQuizObtainedMarksPercentage < 40 ? clickableLabelStyle : null}>{truncateString(item?.chapterName, 6)}   </span>
              </Tooltip>
            </div>
          </div>)}
          
        </div>
        {showNextButton ? <div className="round-btn" onClick={() => scroll(150)}>{'>'}</div> : null}

      </div>
    </div>
  )
}

export default HorizontallyScrollableContainerV2