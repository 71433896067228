import Thermometer from "react-thermometer-ecotropy";
import React from "react";
const CustomThermometer = ({
  totalValue,
  height,
  steps,
  maxValue,
  color,
  format,
  mode,
}) => {
  const styles = {
    dial: {
      display: "inline-block",
      width: `120px`,
      height: `auto`,
      color: "#000",
      border: "0.5px solid #fff",
      padding: "2px",
      justifyContent: "center",
    },
    title: {
      fontSize: "1em",
      color: color,
      marginBottom: "20px",
      display: "flex",
      justifyContent: "center",
    },
  };
  return (
    <>
      <div style={styles.dial}>
        <div style={styles.title}>{totalValue}%</div>
        <Thermometer
          theme={mode}
          value={totalValue}
          max={maxValue}
          steps={steps}
          format={format}
          height={height}
          color={color}
          background={color}
        />
      </div>
    </>
  );
};
export default CustomThermometer;
