import { useState, useEffect } from 'react'
import { Box, Modal, Typography } from "@mui/material";
import studentFileStyles from './../css/studentFiles.module.css'
import createschool from "./../css/CreateSchool.module.css";
import map_post from '../API_CONTROLLER/map-controller/map_post';
import BlueButton from '../../components/common/BlueButton';
import map_img_post from '../API_CONTROLLER/map-controller/map_image_post';
import whiteAddButton from '../../components/common/WhiteButton';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 483,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 4,
    p: 3,
};

const PracticeQuizModal = ({ open, closeModalAPI,
    close }) => {
    const [selectedImage, setSelectedIMage] = useState('')
    const [mapInputs, setMapInputs] = useState({
        mapType: '',
        mapUrl: '',
        title: ''
    })

    const mapUploadHandler = () => {
        map_post(mapInputs);

    }
    const imageChange = (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("file", e.target.files[0]);
        map_img_post(setSelectedIMage, formData, mapInputs, setMapInputs)
    }
    const removeSelectedImage = (e) => {
        e.preventDefault();
        setMapInputs({
            ...mapInputs,
            mapUrl: null
        })
    }

    const handleMapInputs = (e) => {
        setMapInputs({
            ...mapInputs,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div><p>Practice Quiz - CBSE/Grade 3 /Science</p></div>
                <div style={{display:'flex'}}>
                <div style={{margin:'12px'}} className={studentFileStyles.input_element}>
                    <select
                        className={studentFileStyles.form_input_element}
                        name="mapType"
                        id="cityName"
                        style={{ width: '200px', height: '48px' }}
                        value={mapInputs.mapType}
                        onChange={handleMapInputs}
                        placeholder=" "
                    >
                        <option value="">Select</option>
                        <option
                            value="PHYSICAL"
                            className={studentFileStyles.option}>
                            Map Physical
                        </option>
                        <option
                            value="POLITICAL"
                            className={studentFileStyles.option}>
                            Map Pollitical
                        </option>

                    </select>

                    <label style={{ fontWeight: "300" }} for="cityName">
                        *Quiz Name
                    </label>
                </div>
                <div style={{margin:'12px'}} className={studentFileStyles.input_element}>
                    <select
                        className={studentFileStyles.form_input_element}
                        name="mapType"
                        id="cityName"
                        style={{ width: '200px', height: '48px' }}
                        value={mapInputs.mapType}
                        onChange={handleMapInputs}
                        placeholder=" "
                    >
                        <option value="">Select</option>
                        <option
                            value="PHYSICAL"
                            className={studentFileStyles.option}>
                            Map Physical
                        </option>
                        <option
                            value="POLITICAL"
                            className={studentFileStyles.option}>
                            Map Pollitical
                        </option>

                    </select>

                    <label style={{ fontWeight: "300" }} for="cityName">
                        *Total Marks
                    </label>
                </div>
                </div>

                <div style={{display:'flex',paddingBottom:'25px'}}>
                <div style={{margin:'12px'}} className={studentFileStyles.input_element}>
                    <select
                        className={studentFileStyles.form_input_element}
                        name="mapType"
                        id="cityName"
                        style={{ width: '200px', height: '48px' }}
                        value={mapInputs.mapType}
                        onChange={handleMapInputs}
                        placeholder=" "
                    >
                        <option value="">Select</option>
                        <option
                            value="PHYSICAL"
                            className={studentFileStyles.option}>
                            Map Physical
                        </option>
                        <option
                            value="POLITICAL"
                            className={studentFileStyles.option}>
                            Map Pollitical
                        </option>

                    </select>

                    <label style={{ fontWeight: "300" }} for="cityName">
                        *Duration
                    </label>
                </div>
                <div style={{margin:'12px'}} className={studentFileStyles.input_element}>
                    <select
                        className={studentFileStyles.form_input_element}
                        name="mapType"
                        id="cityName"
                        style={{ width: '200px', height: '48px' }}
                        value={mapInputs.mapType}
                        onChange={handleMapInputs}
                        placeholder=" "
                    >
                        <option value="">Select</option>
                        <option
                            value="PHYSICAL"
                            className={studentFileStyles.option}>
                            Map Physical
                        </option>
                        <option
                            value="POLITICAL"
                            className={studentFileStyles.option}>
                            Map Pollitical
                        </option>

                    </select>

                    <label style={{ fontWeight: "300" }} for="cityName">
                        *Academic Year
                    </label>
                </div>
                </div>
                <div style={{display:'flex',position:'relative'}}>
                    <div style={{position:'absolute',right:'0px',marginTop:'-25px'}}>
                    <whiteAddButton>CANCEL</whiteAddButton>
                    <BlueButton>SUBMIT</BlueButton>
                    </div>
                </div>


            </Box>
        </Modal>

    )
}

export default PracticeQuizModal