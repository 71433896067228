import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";

//Images Used
import dashHome from "../../../../src/img/dashHome.svg";
import bubble_img from "../../../../src/img/BB.svg";
import prev from "../../../../src/img/Prev.svg";
import nxt from "../../../../src/img/Nxt.svg";

//Css Pages
import "../../css/Student/Student_Enrich_Quiz_Attend.css";
import dashboardContentStyles from "../../css/dashboardContent.module.css";

//API Used
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";
import { fetch_Chapter_Quiz_Key_Api } from "./Apis/Chapter_Quiz_Key_Api";

//Mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Quiz Quistion Type and Common Components
import Student_Enrich_Quiz_MCQ from "./Student_Enrich_Quiz_MCQ";
import Student_Enrich_Quiz_MSQ from "./Student_Enrich_Quiz_MSQ";
import Student_Enrich_Quiz_CS from "./Student_Enrich_Quiz_CS";
import Student_Enrich_Quiz_FIB from "./Student_Enrich_Quiz_FIB";
import Student_Enrich_Quiz_MTF from "./Student_Enrich_Quiz_MTF";
import Student_Enrich_Quiz_MTF_DrawLines from "./Student_Enrich_Quiz_MTF_DrawLines";
import Student_Enrich_Quiz_MTF_DD from "./Student_Enrich_Quiz_MTF_DD";
import Student_Enrich_Quiz_Submit_Modal from "./Student_Enrich_Quiz_Submit_Modal";
import Student_Enrich_Quiz_Analysis_Modal from "./Student_Enrich_Quiz_Analysis_Modal";
import ChapterQuizKeyModal from "./ChapterQuizKey/ChapterQuizKeyModal";
import { set } from "react-hook-form";

const modal = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  marginBottom: "-20px"


};

function Student_Enrich_Quiz_Attend({
  selectedSubject,
  quizData,
  academicYearList,
  schoolId,
  branchId,
  selectedsubTopic,
  studentId,
  closeModall,
  quizExaminationId,
  resumeBtnClicked,
  setLoadQuizData,
  withTimeBtnClicked,
  withOutTimeBtnClicked,
  subjectList,
  previousPage
  // fetchViewQuiz,
}) {
  // console.log("student enrich quizattend called", resumeBtnClicked, previousPage)
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "550px",
    height: "550px",
    background: "#FFFFFF",
    border: "none",
  };
  window.history.pushState(null, null, window.location.href);
  const [quizType, setQuizType] = useState(null);
  const [modifiedDate, setModifiedDate] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const token = sessionStorage.getItem("token");
  const [remainingTime, setRemainingTime] = useState("");

  const [questionIds, setQuestionIds] = useState([]);
  const [questions, setQuestions] = useState([]);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [storeData, setStoreData] = useState([]);
  const [MCQ, setMCQ] = useState("");

  const [quizzData, setQuizzData] = useState({});
  const [startButtonClicked, setStartButtonClicked] = useState(false);
  const [totalQuestionss, setTotalQuestionss] = useState("");
  const [quizDetails, setQuizDetails] = useState([]);
  const [resumeData, setResumeData] = useState();
  // console.log("quizExaminationId",quizExaminationId)
  const [attendMsq, setAttendMsq] = useState([]);
  const [attendedMsq, setAttendedMsq] = useState([]);
  const [attendMcq, setAttendMcq] = useState([]);
  const [attendedMcq, setAttendedMcq] = useState([]);
  const [attendFib, setAttendFib] = useState([]);
  const [attendedFib, setAttendedFib] = useState([]);
  const [attendMtfDd, setAttendMtfDd] = useState([]);
  const [attendMtfDl, setAttendMtfDl] = useState([]);
  const [attendMtfDlTemp, setAttendMtfDlTemp] = useState([]);
  const [attendedMtfDd, setAttendedMtfDd] = useState([]);
  const [attendedMtfDl, setAttendedMtfDl] = useState([]);
  const [attendCs, setAttendCs] = useState([]);
  const [attendedCs, setAttendedCs] = useState([]);
  const [examEndModalOpen, setExamEndModalOpen] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const [chapterQuizKey, setChapterQuizKey] = useState(true);

  const [timeFromApi, setTimeFromApi] = useState(quizData.totalDuration);
  const [timeLeft, setTimeLeft] = useState(timeFromApi * 60);
  const [timerRunning, setTimerRunning] = useState(false);
  const [formattedTime, setFormattedTime] = useState("");

  const [elapsedTime, setElapsedTime] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const [quesstartTime, setQuesStartTime] = useState("00:00:00");
  const [quesendTime, setQuesEndTime] = useState("00:00:00");
  const [elapsedTimeArray, setElapsedTimeArray] = useState([]);

  sessionStorage.setItem("chapterQuizKeyBtn", chapterQuizKey);
  const subTopicId = sessionStorage.getItem("subTopicId")
  var Vquiztype = sessionStorage.getItem("quiztype")
  var question_start_time = '';
  var question_end_time = '';

  const handleChange = (event) => {
    const value = event.target.value;
    const item = questions && questions[currentQuestion];
    const id = item?.questionId;
    if (id) {
      const clone = [...storeData];
      const findIndex = clone?.findIndex((data) => data?.questionId === id);
      if (findIndex !== -1) {
        clone[findIndex].answer = value;
        setStoreData(clone);
      } else {
        const data = {
          quizId: item?.id,
          question: item?.question,
          questionId: item?.questionId,
          answer: value,
          subQuestions: [],
        };
        setStoreData((pre) => [...pre, data]);
      }
      setMCQ(value);
    }
  };
  // const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  // const handleQuizType = (type) => {
  //   setQuizType(type);
  // };

  const handleSubmitModalOpen = () => setOpen(true);
  const handleSubmitModalClose = () => setOpen(false);

  const fetchRealTimeQuizGraph = async () => {
    const exIdOne = sessionStorage.getItem("exIdOne");
    const exIdTwo = sessionStorage.getItem("exIdTwo");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const examId = exIdTwo ? exIdTwo : exIdOne;
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/real-time-quiz-graph?examId=${examId}&schoolId=${schoolId}&studentId=${studentId}&branchId=${branchId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${selectedSubject}&reportPage=false`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data || [];
  };

  const handleSubmitBtn = () => {
    Attend_Exam_Post_Api();
    setSubmitted(true);
    const realTimeQuizGraphFetch = async () => {
      const realTimeQuizGraphFetchData = await fetchRealTimeQuizGraph();
      return realTimeQuizGraphFetchData?.data.accuracyAnalysis.some(
        (item) =>
          item.accuracyStatus === "Unattempted" && item.accuracyCount > 0
      );
    };
    realTimeQuizGraphFetch().then((attempted) => {
      if (attempted) {
        setShowModal(true);
        handleSubmitModalOpen();
        setLoadQuizData(false);
      } else {
        const sumbitQuiz = async () => {
          const response = await Submit_Exam_Put_Api();
          return response;
        };
        sumbitQuiz().then((response) => {
          if (response) {
            setLoadQuizData(false);
            setOpenAnalysisModal(true);
          }
        });
      }
    });
    // fetchViewQuiz();
  };

  const handleCloseExamEndModal = () => {
    setExamEndModalOpen(false);
    setLoadQuizData(false);
    setOpenAnalysisModal(true);
  };

  const handleAnalysisModalClose = () => {
    setOpenAnalysisModal(false);
    setLoadQuizData(true);
  };

  useEffect(() => {
    console.log("usseffect")
    if (elapsedTime) {
      setSeconds(0);
      const intervalId = setInterval(() => {
        // Update the state to increment the seconds
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
      // This function will be called when the component unmounts
      return () => {
        // Clear the interval to stop the timer
        clearInterval(intervalId);
      };
    }
    GetLastModifiedAt(setModifiedDate);
    // document.addEventListener('keydown', function (event) {
    //   console.log(event)
    //   if (event.key === "Enter") {
    //     console.log("Enter key pressed");
    //     const isDisabledButton =
    //       event.target === document.getElementsByClassName('ExitBtn') ||
    //       event.target === document.getElementsByClassName('PrevBtn') ||
    //       event.target === document.getElementsByClassName('NxtBtn') ||
    //       event.target === document.getElementsByClassName('SubmitBtn');
    //     console.log("isDisabledButton",isDisabledButton)
    //     // Prevent the default behavior only for the specified buttons
    //     if (isDisabledButton) {
    //       event.preventDefault();
    //     }

    //   } else if (event.key === " ") {
    //     console.log("Spacebar pressed",);
    //     const isDisabledButton =
    //       event.target === document.getElementsByClassName('ExitBtn') ||
    //       event.target === document.getElementsByClassName('PrevBtn') ||
    //       event.target === document.getElementsByClassName('NxtBtn') ||
    //       event.target === document.getElementsByClassName('SubmitBtn');
    //       console.log("isDisabledButton",isDisabledButton)
    //     // Prevent the default behavior only for the specified buttons
    //     if (isDisabledButton) {
    //       event.preventDefault();
    //     }

    //   }
    // });
    //student keydown 
    document.addEventListener('keydown', function (event) {
      console.log(event);
      // alert(event.getModifierState)
      const isModifierKey = event.getModifierState('Alt') ||
        event.getModifierState('Tab') ||
        event.getModifierState('Shift');

      if (event.key === 'F12' || isModifierKey) {
        console.log("Modifier key pressed");
        event.preventDefault();
      } else if (event.key === "Enter" || event.key === " ") {
        console.log(`${event.key} key pressed`);

        // Check if no modifier keys are pressed
        const noModifiers = !event.getModifierState('Alt') &&
          !event.getModifierState('Control') &&
          !event.getModifierState('Meta') &&
          !event.getModifierState('Shift');

        if (noModifiers) {
          console.log("No modifier keys pressed");
          // Prevent the default behavior only for <button> elements
          if (event.target.tagName === 'BUTTON') {
            event.preventDefault();
            console.log("Key event prevented for <button>");
          }
        }
      }
    });

    const handleBeforeUnload = (event) => {
      console.log("close tab", event.type); // For reload and close tab
      const isRefresh = !event.currentTarget.performance.navigation.type;
      console.log("isRefresh", isRefresh);

      if (event.type === 'beforeunload') {
        const message = "Are you sure you want to leave? Your progress may be lost.";
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      } else {
        console.log("else close tab");
        const message = "Are you sure you want to close the tab?";
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    const handleContextMenu = (event) => {
      // Prevent the default right-click behavior
      console.log("right click prevent");
      event.preventDefault();
    };

    const handlePopstate = (event) => {
      console.log("handlepopstate", event.type, window.location.href);
      // Prevent the default behavior of navigating back
      window.history.pushState(null, document.title, window.location.href);
    };

    const handleUnload = (event) => {
      console.log("handleunload", event.type);
      const message = "Are you sure you want to close the tab?";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('copy', handleContextMenu);
    window.addEventListener('beforeunload', handleBeforeUnload);
    // window.addEventListener('popstate', handlePopstate);
    window.addEventListener('unload', handleUnload);

    // Cleanup the event listeners when the component is unmounted
    return () => {
      window.removeEventListener('unload', handleUnload);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      // window.removeEventListener('popstate', handlePopstate);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
    // return () => { };
  }, []);

  useEffect(() => {
    console.log("student enrich quiz attend.js ")
    console.log('-- record satrt time --')
    var currentDate = moment();
    var formattedTime = currentDate.format('HH:mm:ss');
    console.log('1st start time >> ', formattedTime);
    question_start_time = formattedTime;
    setQuesStartTime(formattedTime);
    setOpenModal(true);
    setCurrentQuestion(0);
  }, []);

  // Start The Examination (POST) API -> Integration //
  const StartExam_Post_Api = () => {
    var formattedTime = moment().format('h:mm A');
    console.log("formattedTime", formattedTime, Vquiztype, quizData.quizTypeName)
    if (Vquiztype === 'practice' || quizData.quizTypeName === 'Practice Quiz') {
      sessionStorage.setItem("practicequizStartTime", JSON.stringify(formattedTime));
    } else {
      sessionStorage.setItem("practicequizStartTime", JSON.stringify(""));
    }

    setLoadQuizData(true);
    var item = {
      releasedId: quizData.releasedId,
      quizId: quizData.quizId,
      quizTypeId: quizData.quizTypeId,
      startDate: quizData.startDate,
      startTime: quizData.startTime,
      status: "START",
      academicYearId: academicYearList,
      boardId: sessionStorage.getItem("boardId"),
      schoolId: schoolId,
      branchId: branchId,
      gradeId: sessionStorage.getItem("gradeId"),
      subjectId: selectedSubject,
      // subTopicId: selectedsubTopic ? selectedsubTopic : subTopicId,
      sectionId: sessionStorage.getItem("sectionId"),
      studentId: studentId,
    };
    console.log("start4")
    if (subTopicId) {
      item = { ...item, subTopicId: subTopicId }
    }
    console.log("item", item)

    const res = axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/start`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setQuizzData(res.data.data);
        setStartButtonClicked(true);
        startTimer();
        startQuesTimer();// to record ques time
        handleClose();
        sessionStorage.setItem("exIdOne", res.data.data.id);
      })
      .catch((err) => console.log(err));
  };

  //❕❗ Attend The Examination (POST) API -> Integration ❗❕//
  const Attend_Exam_Post_Api = () => {
    console.log('qeus index >> ', currentQuestion);

    var currentDate = moment();
    var formattedTime = currentDate.format('HH:mm:ss');
    // console.log(formattedTime+'hh')    
    question_end_time = formattedTime;
    setQuesEndTime(formattedTime);
    // setElapsedTime(false);
    console.log('start >> ' + quesstartTime)
    console.log('End time >> ', formattedTime);
    // console.log('end >> '+quesendTime)

    //console.log('start/end time >> ', question_start_time, question_end_time)
    if (quesstartTime !== "00:00:00" && formattedTime !== "00:00:00") {
      const difference = timeDifference(quesstartTime, formattedTime);
      console.log(`The difference is ${difference}`);
      if (currentQuestion === 0) {
        updateTimeDiffernce(currentQuestion, difference)
      } else {
        updateTimeDiffernce(currentQuestion - 1, difference)
      }

      // console.log('time taken array >> ', elapsedTimeArray);
    } else {

    }

    setAttendMtfDd([]);
    setAttendFib([]);
    setAttendCs([]);
    setAttendMcq([]);
    setAttendMsq([]);

    const sortedMtfdd = attendMtfDd.sort((a, b) =>
      a.order.localeCompare(b.order)
    );
    console.log("sortedMtfdd", sortedMtfdd, attendMtfDl, attendedMtfDl, attendMtfDlTemp)
    const sortedFib = attendFib?.filter((value) => value !== undefined);
    console.log(questions[currentQuestion])
    var obj =
      questions[currentQuestion]?.questionTypeCode === "MSQ"
        ? attendMsq
        : questions[currentQuestion]?.questionTypeCode === "MCQ"
          ? attendMcq
          : questions[currentQuestion]?.questionTypeCode === "FILL"
            ? sortedFib
            : questions[currentQuestion]?.questionTypeCode === "MATCH" &&
              questions[currentQuestion]?.matchType === "DRAG_DROP"
              ? sortedMtfdd
              : attendMtfDlTemp; //attendedMtfDl;
    // : questions[currentQuestion]?.questionTypeCode === "CASE" &&
    //   attendCs[{}];
    var currentDate = moment();
    var formattedTime = currentDate.format('HH:mm');
    console.log(formattedTime, typeof formattedTime, quizData, Vquiztype);
    if (Vquiztype === 'practice' || quizData.quizTypeName === 'Practice Quiz') {
      let pracQuizAnsArr = JSON.parse(sessionStorage.getItem("practiceQuizAnswer")) || [];
      console.log("pracQuizAnsArr", pracQuizAnsArr)
      var item = {
        examId: quizzData.id ? quizzData.id : quizExaminationId,
        questionId: resumeBtnClicked ? questions[currentQuestion]?.questionId : quizDetails?.questionIdList[currentQuestion],
        parentQuestionId: null,
        questionTypeCode: questions[currentQuestion]?.questionTypeCode,
        answer: obj,
        endDate: quizData.endDate,
        endTime: quizData.endTime,
        pqPausedAt: formattedTime,
        // startTime:quizData.startTime,
        totalTimeSpend: currentQuestion === 0 ? elapsedTimeArray[currentQuestion].timeTaken : elapsedTimeArray[currentQuestion - 1].timeTaken
      };
      let existingIndex = pracQuizAnsArr.findIndex(
        (value) => value.examId === item.examId && value.questionId === item.questionId
      );
      pracQuizAnsArr.map((value, index) => {
        if (value.examId === item.examId && value.questionId === item.questionId) {
          console.log("Existing object found at index:", index);
          console.log("Existing examId:", value.examId, item.examId);
          console.log("Existing questionId:", value.questionId, item.questionId);
        }
      });
      console.log("existingIndex", existingIndex)
      // If an existing object is found, replace it; otherwise, push the new object
      if (existingIndex !== -1) {
        pracQuizAnsArr[existingIndex] = item;
      } else {
        pracQuizAnsArr.push(item);
      }
      console.log("b4 set into session pracQuizAnsArr", pracQuizAnsArr)
      sessionStorage.setItem("practiceQuizAnswer", JSON.stringify(pracQuizAnsArr));
    } else {
      var item = {
        examId: quizzData.id ? quizzData.id : quizExaminationId,
        questionId: resumeBtnClicked ? questions[currentQuestion]?.questionId : quizDetails?.questionIdList[currentQuestion],
        parentQuestionId: null,
        questionTypeCode: questions[currentQuestion]?.questionTypeCode,
        answer: obj,
        endDate: quizData.endDate,
        endTime: quizData.endTime,
        // totalTimeSpend: currentQuestion === 0 ? elapsedTimeArray[currentQuestion].timeTaken : elapsedTimeArray[currentQuestion - 1].timeTaken
      };
    }
    console.log("swetham", item)
    console.log(">> ", currentQuestion === 0 ? elapsedTimeArray[currentQuestion].timeTaken :
      elapsedTimeArray[currentQuestion - 1].timeTaken)
    if (Vquiztype !== 'practice') {
      const res = axios
        .post(
          questions[currentQuestion]?.questionTypeCode === "CASE"
            ? `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/attend/case-study`
            : `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/attend-answer`,
          questions[currentQuestion]?.questionTypeCode === "CASE"
            ? attendCs
            : item,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data.data);
        })
        .catch((err) => console.log(err));
    }


    // setElapsedTime(true);
    var currentDate = moment();
    var formattedTime = currentDate.format('HH:mm:ss');
    question_start_time = formattedTime;
    setQuesStartTime(formattedTime);
    console.log('start time >> ', formattedTime);

    // return res.data.data;
  };

  const updateTimeDiffernce = (index, value) => {

    const newElapsedTime = [...elapsedTimeArray];
    // console.log('>> ', newElapsedTime)
    // console.log('BEF >> ', newElapsedTime[index].timeTaken)
    if (newElapsedTime[index].timeTaken !== "00:00:00") {

      const momentTime1 = moment(newElapsedTime[index].timeTaken, 'HH:mm:ss');
      const momentTime2 = moment(value, 'HH:mm:ss');

      const totalDuration = moment.duration({
        hours: momentTime1.hours() + momentTime2.hours(),
        minutes: momentTime1.minutes() + momentTime2.minutes(),
        seconds: momentTime1.seconds() + momentTime2.seconds(),
      });

      var res = moment.utc(totalDuration.asMilliseconds()).format('HH:mm:ss');
      //console.log('updated time >> ', res)
      newElapsedTime[index].timeTaken = res;

    } else {
      newElapsedTime[index].timeTaken = value;
    }
    //console.log('AFT >> ', newElapsedTime[index].timeTaken)
    setElapsedTimeArray(newElapsedTime);
    console.log('elapsedTimeArray >> ', elapsedTimeArray);

  };

  function timeStringToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  function secondsToTimeString(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  }

  function timeDifference(time1, time2) {

    console.log('start/end time >> ', time1, time2)

    // console.log('start/end time >> ',time1 , time2)

    const seconds1 = timeStringToSeconds(time1);
    const seconds2 = timeStringToSeconds(time2);
    const difference = Math.abs(seconds1 - seconds2);
    return secondsToTimeString(difference);
  }


  //❕❗ Submit The Examination (PUT) API -> Integration ❗❕//
  const Submit_Exam_Put_Api = async () => {
    console.log("final submit called", quizzData, quizData, "--1--", quizExaminationId, JSON.parse(sessionStorage.getItem("practiceQuizAnswer")));
    console.log("Vquiztype", Vquiztype)
    console.log(sessionStorage.getItem("practicequizStartTime"))
    console.log(JSON.parse(sessionStorage.getItem("practicequizStartTime")))
    console.log("practiceQuizAnswer", JSON.parse(sessionStorage.getItem("practiceQuizAnswer")));
    var item = {};

    if (Vquiztype === 'practice' || quizData.quizTypeName === 'Practice Quiz') {
      item = {
        examId: quizzData.id ? quizzData.id : quizExaminationId,
        status: "END",
        answerSheets: JSON.parse(sessionStorage.getItem("practiceQuizAnswer")) || [],
        startTime: JSON.parse(sessionStorage.getItem("practicequizStartTime"))
      };
    } else {
      item = {
        examId: quizzData.id ? quizzData.id : quizExaminationId,
        status: "END",
        answerSheets: JSON.parse(sessionStorage.getItem("practiceQuizAnswer")) || [],
      };
    }

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/examination/submit`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res) {
        sessionStorage.removeItem("practiceQuizAnswer");
        sessionStorage.removeItem("draw_line_array");
        sessionStorage.removeItem("drag_drop_array_final");
      }
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataUsingQuizId = async () => {
    const quizId = quizData.quizId;
    var randomizeQuestions = false;
    if (localStorage.getItem("role") == "STUDENT") {
      randomizeQuestions = true;
    }
    const response = await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/${quizId}?showCorrectAnswers=false&randomizeQuestions=${randomizeQuestions}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setQuestionIds(response?.data.data.questionIdList);
        setTotalQuestionss(response?.data.data.totalQuestions);
        setQuizDetails(response?.data.data);
      });
  };
  useEffect(() => {
    console.log("student_enrich_quiz_attend.js -----------")
    fetchDataUsingQuizId();
  }, []);

  const response = async () => {
    const promises = questionIds.map((questionId) => {
      return axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${questionId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    });
    const responses = await Promise.all(promises);
    const questionsids = responses.map((v, i) => {
      return { subQuestions: v.data.data.hasSubQuestions, Id: v.data.data.id };
    });
    for (let element of questionsids) {
      if (element.subQuestions == true) {
        element["subQuestions"] = await subConceptsfetch(element?.Id);
      } else {
        element["subQuestions"] = [];
      }
    }

    const questionss = responses?.map((response) => response.data.data);

    let questionandSubquestions = [];
    let timeTakenArray = [];
    questionss.forEach((v, i) => {
      questionsids.forEach((value, id) => {
        if (v?.id == value?.Id) {
          questionandSubquestions.push({ ...v, ...value });
          timeTakenArray.push({ ...v, ...value, timeTaken: "00:00:00" });
        }
      });
    });
    setQuestions(questionandSubquestions);
    setElapsedTime(true);
    console.log('>>', questionandSubquestions, timeTakenArray)
    setElapsedTimeArray(timeTakenArray);
  };

  useEffect(() => {
    if (!resumeBtnClicked) {
      response();
    }
  }, [questionIds]);

  const subConceptsfetch = async (Id) => {
    var URL = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${Id}/sub-questions?showCorrectAnswer=true`;
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("response?.data?.data;", response?.data?.data)
    return response?.data?.data;
  };

  // Total Duration Of Exam Calculation Using StartTime & EndTime
  const startTime = quizData.startTime;
  const endTime = quizData.endTime;

  // Manual Time -> For Testing Purpose
  // const startTime = "9:15 AM";
  // const endTime = "11:55 PM";

  const start = moment(startTime, "hh:mm A");
  const end = moment(endTime, "hh:mm A");

  // Format duration string as "3hrs 45min" or "3hrs"
  let durationString = "";
  const duration = moment.duration(end.diff(start));
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  if (hours > 0) {
    durationString += `${hours}hrs`;
  }
  if (hours > 0 && minutes > 0) {
    durationString += " ";
  }
  if (minutes > 0 || durationString === "") {
    durationString += `${minutes}min`;
  }

  useEffect(() => {
    console.log("resumeBtnClicked", resumeBtnClicked)
    let quizSubmitted = false;

    if (submitted) {
      quizSubmitted = true;
    }

    const handleSubmission = () => {
      if (!quizSubmitted) {
        quizSubmitted = true;
        Attend_Exam_Post_Api();
        Submit_Exam_Put_Api();
        setExamEndModalOpen(true);
        setChapterQuizKey(false);
      }
    }

    if (resumeBtnClicked) {
      const resumeDataFetch = async () => {
        const data = await fetch_Chapter_Quiz_Key_Api();
        console.log(data.data.questionAnswerKeys)
        setQuestions(data.data.questionAnswerKeys);
        setElapsedTime(true);

        let timeTakenArray = [];
        data.data.questionAnswerKeys.forEach((value, id) => {
          timeTakenArray.push({ ...value, timeTaken: "00:00:00" });

        });
        console.log('>>', timeTakenArray)
        setElapsedTimeArray(timeTakenArray);
      };
      resumeDataFetch();
    }

    if (startButtonClicked) {
      const interval = setInterval(() => {
        const currentTime = moment();
        if (currentTime.isBefore(start)) {
          setRemainingTime("Not started yet");
        } else if (currentTime.isAfter(end)) {
          handleSubmission();
          setRemainingTime("Quiz has ended");
        } else {
          const remainingDuration = moment.duration(end.diff(currentTime));
          const remainingMilliseconds = remainingDuration.asMilliseconds();
          if (remainingMilliseconds <= 0) {
            handleSubmission();
            setRemainingTime("Quiz duration exceeded");
            return;
          }
          const hours = Math.floor(remainingDuration.asHours());
          const minutes = Math.floor(remainingDuration.asMinutes()) % 60;
          const seconds = Math.floor(remainingDuration.asSeconds()) % 60;
          let remainingTimeString = "";
          if (hours > 0) {
            remainingTimeString += `${hours}hrs `;
          }
          if (minutes > 0 || hours === 0) {
            remainingTimeString += `${minutes}min`;
          }
          remainingTimeString += ` ${seconds}sec`;
          setRemainingTime(remainingTimeString.trim());
        }
      }, 1000);
      return () => clearInterval(interval);
    } else if (resumeBtnClicked) {
      const interval = setInterval(() => {
        const currentTime = moment();
        if (currentTime.isBefore(start)) {
          setRemainingTime("Not started yet");
        } else if (currentTime.isAfter(end)) {
          handleSubmission();
          setRemainingTime("Quiz has ended");
        } else {
          const remainingDuration = moment.duration(end.diff(currentTime));
          const remainingMilliseconds = remainingDuration.asMilliseconds();
          if (remainingMilliseconds <= 0) {
            handleSubmission();
            setRemainingTime("Quiz duration exceeded");
            return;
          }
          const hours = Math.floor(remainingDuration.asHours());
          const minutes = Math.floor(remainingDuration.asMinutes()) % 60;
          const seconds = Math.floor(remainingDuration.asSeconds()) % 60;
          let remainingTimeString = "";
          if (hours > 0) {
            remainingTimeString += `${hours}hrs `;
          }
          if (minutes > 0 || hours === 0) {
            remainingTimeString += `${minutes}min`;
          }
          remainingTimeString += ` ${seconds}sec`;
          setRemainingTime(remainingTimeString.trim());
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [startButtonClicked, resumeBtnClicked]);

  const nextQuestionHandler = () => {
    if (currentQuestion + 1 < questionIds?.length) {
      setCurrentQuestion(currentQuestion + 1);
    }
    Attend_Exam_Post_Api();
  };

  const preQuestionHandler = () => {
    if (currentQuestion >= 1) {
      setCurrentQuestion(currentQuestion - 1);
    }
    setElapsedTime(true);
    console.log('prevous click >>', elapsedTimeArray, currentQuestion, attendedMtfDl)
  };

  const currentQuestionHandler = (i) => {
    setCurrentQuestion(i);
    setElapsedTime(true);
    Attend_Exam_Post_Api();
  };

  useEffect(() => {
    if (remainingTime === "Quiz has ended") {
      setChapterQuizKey(false);
    }
  }, [remainingTime]);

  // Practice Quiz Time Functionality
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const hoursPart = hours > 0 ? `${hours} hr ` : "";
    const minutesPart = minutes > 0 ? `${minutes} min ` : "";
    const secondsPart = `${remainingSeconds} sec`;

    return `${hoursPart}${minutesPart}${secondsPart}`;
  };

  useEffect(() => {
    if (timerRunning) {
      const timerInterval = setInterval(() => {
        if (timeLeft > 0) {
          const newFormattedTime = formatTime(timeLeft);
          setFormattedTime(newFormattedTime);
          setTimeLeft(timeLeft - 1);
        } else {
          clearInterval(timerInterval);
        }
      }, 1000);

      return () => {
        clearInterval(timerInterval);
      };
    }
  }, [startButtonClicked, timerRunning, timeLeft]);

  const startTimer = () => {
    setTimerRunning(true);
  };

  const startQuesTimer = () => {
    setElapsedTime(true);
  }

  // useEffect(() => {
  //   if (elapsedTime) {
  //     console.log('>> record satrt time >>')
  //     var currentDate = moment();
  //     var formattedTime = currentDate.format('HH:mm:ss');
  //     setQuesStartTime(formattedTime);
  //   }
  // });

  // Time Left:
  const displayTime = withTimeBtnClicked ? formattedTime : remainingTime;

  const handleButtonKeyDown = (event, handlerFunction) => {
    console.log("handle button keydown clicked", event.key)
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();

    }
  };

  // console.log("formattedTime", formattedTime);
  // console.log("timerRunning", timerRunning);

  return (
    <>
      {/*---------------------------| Common Items Across The Project Starts Here |---------------------------*/}

      {/* {
        console.log("==================", isModalOpen)
      } */}

      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          <span
            style={{
              fontSize: "12px",
              marginLeft: "4px",
              color: "#7A7A7A",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/dashboard/template");
            }}
          >
            Home ⨠
          </span>
          <span
            style={{
              fontSize: "12px",
              color: "#001AF3",
              marginLeft: "4px",
            }}
          >
            Quiz
          </span>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>

      {/*---------------------------| Common Items Across The Project Ends Here |---------------------------*/}
      <Modal
        open={isModalOpen}
        onClose={isModalOpen}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className="Quiz_Wrapper" style={{ opacity: resumeBtnClicked ? 1 : !startButtonClicked ? 0 : 1, backgroundColor: "white", height: "100%" }}>
          {/*---------------------------| Quiz Title & Time Division |---------------------------*/}
          <div className="Title_And_Time">
            <div>
              <h6 className="Title">{quizDetails.name}</h6>
            </div>
            <div className="Time_OutOf">
              <div className="OutOf">
                {currentQuestion + 1} Out of {totalQuestionss}
              </div>
              {withOutTimeBtnClicked ? (
                <h6></h6>
              ) : (
                <h6 className="Time">Time Left: {displayTime}</h6>
              )}
            </div>
          </div>
          {/* {
            console.log(questions[currentQuestion], questions)

          } */}

          {questions.length > 0 &&
            questions[currentQuestion]?.questionTypeCode === "CASE" ? (
            <Student_Enrich_Quiz_CS
              questions={questions}
              currentQuestion={currentQuestion}
              resumeBtnClicked={resumeBtnClicked}
              setAttendCs={setAttendCs}
              attendCs={attendCs}
              setAttendedCs={setAttendedCs}
              attendedCs={attendedCs}
              examId={quizzData.id ? quizzData.id : quizExaminationId}
              // parentQuestionId={questions.id}
              endDate={quizData.endDate}
              endTime={quizData.endTime}
            />
          ) : (
            ""
          )}

          {questions.length > 0 &&
            questions[currentQuestion]?.questionTypeCode === "FILL" ? (
            <Student_Enrich_Quiz_FIB
              questions={questions}
              resumeBtnClicked={resumeBtnClicked}
              currentQuestion={currentQuestion}
              Attend_Exam_Post_Api={Attend_Exam_Post_Api}
              setAttendFib={setAttendFib}
              attendFib={attendFib}
              setAttendedFib={setAttendedFib}
              attendedFib={attendedFib}
            />
          ) : (
            ""
          )}

          {questions.length > 0 && questions[currentQuestion]?.questionTypeCode === "MSQ" ? (
            <Student_Enrich_Quiz_MSQ
              questions={questions}
              resumeBtnClicked={resumeBtnClicked}
              currentQuestion={currentQuestion}
              Attend_Exam_Post_Api={Attend_Exam_Post_Api}
              setAttendMsq={setAttendMsq}
              attendMsq={attendMsq}
              setAttendedMsq={setAttendedMsq}
              attendedMsq={attendedMsq}
            />
          ) : (
            ""
          )}

          {questions.length > 0 && questions[currentQuestion]?.questionTypeCode === "MCQ" ? (
            <Student_Enrich_Quiz_MCQ
              questions={questions}
              resumeBtnClicked={resumeBtnClicked}
              currentQuestion={currentQuestion}
              setAttendMcq={setAttendMcq}
              attendMcq={attendMcq}
              setAttendedMcq={setAttendedMcq}
              attendedMcq={attendedMcq}
            />
          ) : (
            ""
          )}

          {questions.length > 0 &&
            questions[currentQuestion]?.questionTypeCode === "MATCH" &&
            questions[currentQuestion]?.matchType === "DRAG_DROP" ? (
            <Student_Enrich_Quiz_MTF_DD
              questions={questions}
              // qstnId={quizDetails.questionIdList[currentQuestion]}
              currentQuestion={currentQuestion}
              setAttendMtfDd={setAttendMtfDd}
              attendMtfDd={attendMtfDd}
              setAttendedMtfDd={setAttendedMtfDd}
              attendedMtfDd={attendedMtfDd}
              resumeBtnClicked={resumeBtnClicked}
            />
          ) : questions.length > 0 &&
            questions[currentQuestion]?.questionTypeCode === "MATCH" &&
            (questions[currentQuestion]?.matchType === "draw-lines" || questions[currentQuestion]?.matchType === "DRAW_LINE") ? (
            <div>
              <Student_Enrich_Quiz_MTF_DrawLines
                questions={questions}
                currentQuestion={currentQuestion}
                setAttendMtfDl={setAttendMtfDl}
                attendMtfDl={attendMtfDl}
                setAttendedMtfDl={setAttendedMtfDl}
                attendedMtfDl={attendedMtfDl}
                resumeBtnClicked={resumeBtnClicked}
                attendMtfDlTemp={attendMtfDlTemp}
                setAttendMtfDlTemp={setAttendMtfDlTemp}
              />
            </div>
          ) : (
            ""
          )}
          {/*---------------------------| Prev, Next & Submit Btn Division|---------------------------*/}
          <div className="BtnsDiv">
            <div className="QstnBtns">
              <button
                className="ExitBtn"
                onClick={() => {
                  sessionStorage.removeItem("itemload");
                  sessionStorage.removeItem("viewQuizData");
                  // setIsModalOpen(false)
                  console.log("exit clicked1", previousPage);
                  sessionStorage.removeItem("practiceQuizAnswer");
                  sessionStorage.removeItem("drag_drop_array");
                  sessionStorage.removeItem("drag_drop_array_final")
                  // navigate('/dashboard/enrich')
                  window.location.href = previousPage;
                  window.location.reload()
                }}
                onKeyDown={(e) => handleButtonKeyDown(e, preQuestionHandler)}
              >
                <h6>Exit</h6>
              </button>

              {currentQuestion !== 0 && (
                <button className="PrevBtn" onClick={preQuestionHandler} onKeyDown={(e) => handleButtonKeyDown(e, preQuestionHandler)}
                  tabIndex="0">
                  <img src={prev} />
                  <h6>Previous Question</h6>
                </button>
              )}

              {currentQuestion !== questions.length - 1 && (
                <button className="NxtBtn" onClick={nextQuestionHandler} onKeyDown={(e) => handleButtonKeyDown(e, nextQuestionHandler)}
                  tabIndex="0">
                  <h6>Next Question </h6>
                  <img src={nxt} />
                </button>
              )}

              {currentQuestion === questions.length - 1 && (
                <button className="SubmitBtn" onClick={handleSubmitBtn}>
                  <h6>Submit</h6>
                </button>
              )}
            </div>
          </div>
          {showModal && (
            <Student_Enrich_Quiz_Submit_Modal
              showModal={open}
              closeModal={handleSubmitModalClose}
              Submit_Exam_Put_Api={Submit_Exam_Put_Api}
              closeModall={closeModall}
              setLoadQuizData={setLoadQuizData}
              openAnalysisModal={openAnalysisModal}
            // fetchViewQuiz={fetchViewQuiz}
            />
          )}

          {openAnalysisModal && (
            <Student_Enrich_Quiz_Analysis_Modal
              showAnalysisModal={openAnalysisModal}
              closeModal={handleAnalysisModalClose}
              closeModall={closeModall}
            // chapterQuizKey={chapterQuizKey}
            />
          )}

          {/*---------------------------| Quiz Question Bubble Division Starts Here |---------------------------*/}
          <div className="Qstn_Bubble_div">
            <div className="Bubble_and_img">
              <div className="Qstn_Bubble_img">
                <div>
                  <img src={bubble_img} />
                </div>
                <div>
                  <h6>
                    Questions <span>Attempted</span>
                  </h6>
                </div>
              </div>
              <div className="Bubbles">
                {/* <div className="Bubble" onClick={() => handleQuizType("CS")}>
                <h6>1</h6>
              </div>
                */}
                {/* {
                  console.log("questions", questions, attendMtfDlTemp, attendedMtfDl)
                } */}
                {questions?.map((item, index) => {

                  let isAttempted = false;
                  if (item.questionTypeCode === "CASE") {
                    isAttempted = attendedCs.hasOwnProperty(
                      resumeBtnClicked ? item.questionId : item.Id
                    );
                  } else if (item.questionTypeCode === "FILL") {
                    isAttempted = attendedFib.hasOwnProperty(
                      resumeBtnClicked ? item.questionId : item.Id
                    );
                    // console.log("isAttempted", isAttempted)
                  } else if (item.questionTypeCode === "MSQ") {
                    isAttempted = attendedMsq.hasOwnProperty(
                      resumeBtnClicked ? item.questionId : item.Id
                    );
                  } else if (item.questionTypeCode === "MCQ") {
                    // console.log(item,"-------------------------------------",attendedMcq,resumeBtnClicked)
                    isAttempted = attendedMcq.hasOwnProperty(
                      resumeBtnClicked ? item.questionId : item.Id
                    );
                  } else if (item.questionTypeCode == "MATCH") {
                    if (item.matchType == "DRAW_LINE") {
                      // console.log(item, attendMtfDlTemp, attendedMtfDl, item.questionId, item.Id)
                      var id = resumeBtnClicked ? item.questionId : item.Id
                      isAttempted = attendedMtfDl.some(item => item.id === id);
                      // console.log(isAttempted,typeof id)
                    } else {
                      // console.log(JSON.parse(sessionStorage.getItem("drag_drop_array_final")), attendedMtfDd, attendMtfDd, currentQuestion);
                      // if (attendedMtfDd.length > 0) {
                      //   isAttempted = attendedMtfDd.some(items => (items.id == item.id || items.id == item.questionId));
                      //   if (isAttempted) {
                      //     // console.log("isAttempted", isAttempted);
                      //   }
                      // } else {
                      //   isAttempted = attendMtfDd.some(items => items.currentQuestion == index);
                      //   if (isAttempted) {
                      //     // console.log("isAttempted", isAttempted);
                      //   }
                      // }
                      var id = resumeBtnClicked ? item.questionId : item.Id
                      var sess = JSON.parse(sessionStorage.getItem("drag_drop_array_final")) || [];
                      console.log("sess", sess, id)
                      isAttempted = sess.some(items => items.id == String(id));
                      if (isAttempted) {
                        // console.log("isAttempted", isAttempted);
                      }
                    }

                  }
                  else {
                    isAttempted = attendedMtfDd.hasOwnProperty(
                      resumeBtnClicked ? item.questionId : item.Id
                    );
                  }
                  // console.log(isAttempted, item.questionTypeCode, resumeBtnClicked)
                  return (
                    <>
                      <div
                        className="Bubble"
                        key={index}
                        style={{
                          backgroundColor: isAttempted ? "" : "#928d8d",
                        }}
                        onClick={() => currentQuestionHandler(index)}
                      >
                        <h6>{index + 1}</h6>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          {/*---------------------------| Quiz Question Bubble Division Ends Here |---------------------------*/}

          {/*---------------------------| Quiz Question Info Bubbles Division Starts Here |---------------------------*/}
          <div className="Info_Bubbles">
            <div className="bubble_1">
              <div className="circle_1"></div>
              <span> - Attempted</span>
            </div>
            <div className="bubble_2">
              <div className="circle_2"></div>
              <span> - Unattempted</span>
            </div>
          </div>
          {/*---------------------------| Quiz Question Info Bubbles Division Ends Here |---------------------------*/}
        </div>
      </Modal >
      {/*---------------------------| General Instructions Modal Starts Here |---------------------------*/}

      < div >
        {
          quizExaminationId ? null : (
            <Modal
              open={openModal}
              // onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                {/*-------------------------------| General Instructions Modal Title & Close-Btn |-------------------------------*/}
                <div className="Modal_Head">
                  <div className="Modal_title">General Instructions</div>
                  <div className="Close_btn">
                    <CloseIcon onClick={() => closeModall()} />
                    {/* <CloseIcon onClick={() => handleClose()} /> */}
                  </div>
                </div>
                {/*-------------------------------| General Instructions Modal Contents |-------------------------------*/}
                <div className="modal_contents">
                  <h6 className="modal_content_1">
                    All questions are compulsory
                  </h6>
                  <h6 className="modal_content_2">
                    The <span>maximum marks is {quizDetails.totalMarks}</span>
                  </h6>
                  <div className="modal_content_3" style={{ marginBottom: '10px' }}>
                    The duration of the paper is: {durationString}. (15 min
                    reading time & {durationString} writing time) Read all the
                    questions carefully before attempting. A neat and legible
                    presentation will be appreciated.
                  </div>
                  <h6 className="modal_content_1">
                    Please take a few minutes to read the instructions carefully before starting the quiz:
                  </h6>
                  <div className="modal_content_3">
                    <ol>
                      <li>This assessment contains Multiple Choice Questions, with 1 correct answer for every question.</li>
                      <li>Other questions – like Multiselect and Match the following, Fill in the blanks & Case study have specific instructions given.</li>
                      <li>Read the questions carefully and choose the appropriate option.</li>
                      <li>It is mandatory to attempt all the questions.</li>
                      <li>No marks are awarded for unattempted questions.</li>
                      <li>Mark the answers for each question. If you want to attempt any questions later, you can move to the next question without marking the answer. Press the back arrow and attempt the questions later before you submit the quiz.</li>
                      <li>If you are taking the quiz through the mobile app, avoid making/receiving calls.</li>
                      <li>In case of any challenges regarding the quiz, please feel free to contact your teacher immediately.</li>
                      <li>Once quiz Time is over student will receive pop up “Time is over” click on “Ok” to submit quiz.</li>
                    </ol>
                  </div>
                </div>
                {/*-------------------------------| General Instructions Modal Footer Btns |-------------------------------*/}
                <div className="Modal_Footer">
                  <hr className="U_line" />
                  <div className="Modal_Footer_Btns">
                    <div className="Cancel_Btn">
                      <button onClick={() => closeModall()}>Cancel</button>
                    </div>
                    <div className="Start_Btn">
                      {/* <button onClick={handleClose}>Start</button> */}
                      <button onClick={StartExam_Post_Api}>Start</button>
                      {/* <button onClick={() => setStartButtonClicked(true)}>
                    Start
                  </button> */}
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          )
        }
      </div >
      {/*---------------------------| General Instructions Modal Ends Here |---------------------------*/}

      {/*-----------------------| Exam Time Over Validation Modal Starts Here |-----------------------*/}
      <div>
        <Modal
          open={examEndModalOpen}
          aria-labelledby="exam-modal-title"
          aria-describedby="exam-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              border: "none",
              borderRadius: "5px",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="exam-modal-title" align="center">
              Exam Time Over
            </Typography>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              <Button
                fullWidth
                style={{
                  width: "20px",
                  color: "white",
                  backgroundColor: "#FD8C00",
                }}
                onClick={handleCloseExamEndModal}
              >
                OK
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
      {/*-----------------------| Exam Time Over Validation Modal Ends Here |-----------------------*/}

    </>
  );
}

export default Student_Enrich_Quiz_Attend;
