export const topBarContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '17px'
}

export const topContainerRight = {
  display: 'flex',
  alignItems: 'center'
}

export const topContainerLeft = {
  display: 'flex',
  alignItems: 'center'
}

export const countCardStyle = {
  borderRadius: '5px',
  height: '100%',
  padding: '15px'
}

export const rowOne = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '174px'
  // height: "19%"
}
export const rowTwo = {
  width: '100%',
  height: '350px',
  background:"#fff",
  padding:"18px"
  // height: "19%"
}

export const filterStyle = {
  '& .css-10hburv-MuiTypography-root': {
    fontSize: '12px !important'
  },
  '&.MuiOutlinedInput-root': {
    '& > fieldset': {
      border: '1px solid #D6D5DF !important'
    }
  },
  '&.MuiOutlinedInput-root.Mui-focused': {
    '& > fieldset': {
      border: '1px solid #272727 !important'
    }
  },
  fontSize: '14px !important',

  font: 'Poppins !important'
}

export const formStyle = { width: '113px', height: '36px', marginRight: '10px' }

export const labelStyle = {
  fontSize: '14px',
  '&.Mui-focused': {
    color: 'orange',
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '13px'
  }
}

export const selectStyle = {
  background: '#fff',
  borderRadius: 150,
  height: '100%'
}

export const cardText = { fontSize: '16px', color: '#2D3962' }
export const cardTop = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%'
}

export const taskheaderContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '22px',
  paddingRight: '12px',
  paddingTop: '5px',
  paddingBottom: '7px'
}
export const lastRowContainer = {
  width: '100%',
  backgroundColor: '#fff',
  borderRadius: '5px',
  height: '289px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
paddingTop:'16px',
paddingBottom:'16px',
}
