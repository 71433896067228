import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useQuestionType = () => {
  const TOKEN = sessionStorage.getItem('token');

  return useQuery(
    ['questionType'],
    () => {
      return axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question-type/pattern?pattern=OBJECTIVE`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        )
        .then((res) => res.data.data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};
