import axios from 'axios'

async function StudentCategoriesControllerGetall(setStudentCategoriesName) {
  let token = sessionStorage.getItem("token");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/student-categories/all`,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      // console.log(json)
      setStudentCategoriesName(json.data.data)
    })
    .catch(err=>console.log(err))
  }



export default StudentCategoriesControllerGetall;
