import React, { useEffect, useState } from 'react'
import { Checkbox } from '@mui/material'
import styled from 'styled-components'
import { columns } from './DataSchema'

const headStyle = {
  background: '#dadcfe',
  width: '100%',
  position: 'sticky',
  top: 0,
  height: '125px',
  zIndex: 10
}
const headUpper = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '40px'
}
const tableHeaditem = {
  width: '8.5%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start'
}
const firstheadItem = {
  width: '15%',
  paddingLeft: '18px'
}

const SubjectTable = ({
  planDetails,
  handlePlanTemplateData,
  gradeId,
  allFieldsSelectedInGrade,
  setAllSelected
}) => {
  // {gradeId:gradeId,fields:[]}
  const allSelected =
    allFieldsSelectedInGrade.find(i => i.gradeId === gradeId)?.fields || []


    console.log(allSelected,'allSelected')

  const renderCellData = (field, item, category) => {
    if (field === 'subject') {
      return item[field]
    } else {
      return (
        <Checkbox
          sx={{
            color: category === 'teacher' ? '#fd8c00' : '#001af3',
            '&.Mui-checked': {
              color: category === 'teacher' ? '#fd8c00' : '#001af3'
            }
          }}
          onClick={e => {
            const isChecked = e.target.checked
            const mapped = planDetails.map(i => {
              if (i.subjectId === item.subjectId) {
                i[field] = isChecked
                return { ...i }
              } else {
                return i
              }
            })
            handlePlanTemplateData(mapped, gradeId)
            if (allSelected?.includes(field)) {
              if (!isChecked) {
                let arr = allSelected.filter(i => i != field)
                setAllSelected(prev => prev.map(item => {
                  if(item.gradeId === gradeId){
                    item.fields = arr
                  }
                  return item
                }))
              }
            }
          }}
          checked={item[field]}
        />
      )
    }
  }

  const handleCheck = (e, data) => {
    const isChecked = e.target.checked
    const mapped = planDetails.map(i => {
      i[data.field] = isChecked
      return { ...i }
    })

    if (allSelected?.includes(data.field)) {
      let arr = allSelected.filter(i => i != data.field)
      setAllSelected(prev => prev.map(item => {
        if(item.gradeId === gradeId){
          item.fields = arr
        }
        return item
      }))
    } else {
      let arr = [...allSelected, data.field]
      setAllSelected(prev => prev.map(item => {
        if(item.gradeId === gradeId){
          item.fields = arr
        }
        return item
      }))
    }
    handlePlanTemplateData(mapped, gradeId)
  }

  const isFieldSelectedForAllSubjects = field => {
    //lessonPlan
    let booeleanValuesForFieldAllSubject = planDetails.map(
      subjectItem => subjectItem[field]
    )

    return booeleanValuesForFieldAllSubject.includes(false) ? false : true
  }


  return (
    <div>
      <div style={headStyle}>
        <div style={headUpper}>
          <TableElement style={{ width: '15%', height: '100%' }}></TableElement>
          <TableElement
            style={{ width: '68%', height: '100%', justifyContent: 'center' }}
          >
            Teacher
          </TableElement>
          <TableElement
            style={{ width: '17%', height: '100%', justifyContent: 'center' }}
          >
            Student
          </TableElement>
        </div>
        <div style={{ justifyContent: 'flex-start', display: 'flex' }}>
          {columns.map(data => (
            <TableElement
              key={data.field}
              style={data.field === 'subject' ? firstheadItem : tableHeaditem}
            >
              {data.field != 'subject' ? (
                <Checkbox
                  onClick={e => handleCheck(e, data)}
                  // checked={allSelected?.includes(data.field) || isFieldSelectedForAllSubjects(data.field)}
                  checked={
                    allSelected?.includes(data.field) ||
                    isFieldSelectedForAllSubjects(data.field)
                  }
                  sx={{
                    color: data.category === 'teacher' ? '#fd8c00' : '#001af3',
                    '&.Mui-checked': {
                      color: data.category === 'teacher' ? '#fd8c00' : '#001af3'
                    }
                  }}
                />
              ) : null}
              {data.headerName}{' '}
            </TableElement>
          ))}
        </div>
      </div>
      <TableBody>
        {planDetails?.map((item, i) => (
          <TableColumn key={i}>
            {columns.map(headerData => (
              <TableItem
                id={item.id + headerData.field}
                style={{
                  width: headerData.width,
                  justifyContent:
                    headerData.field === 'subject' ? 'flex-start' : 'center',
                  paddingLeft: headerData.field === 'subject' ? '18px' : 0
                }}
              >
                {renderCellData(headerData.field, item, headerData.category)}
              </TableItem>
            ))}
          </TableColumn>
        ))}
      </TableBody>
    </div>
  )
}

export default SubjectTable

const TableElement = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'poppins' !important;
  border-right: 1px solid #c8c9e1;
  border-top: 1px solid #c8c9e1;
  text-align: center;
  border-bottom: none;
  color: #001af3;
`
const TableBody = styled.div`
  height: 100%;
`
const TableColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #c8c9e1;
  border-top: 1px solid #c8c9e1;
  border-right: 1px solid #c8c9e1;

  :hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  :after {
    position: absolute;
    content: '';
    left: 0;
  }
`
const TableItem = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  text-align: flex-start;
  z-index: 0;
  font-family: 'poppins' !important;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-right: 1px solid #c8c9e1;
  color: #001af3;
`
