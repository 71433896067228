import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { orange } from "@mui/material/colors";

const DarkOrangeButton = (props) => {
    const AddButton = styled(Button)(() => ({
        color: "#fff",
        backgroundColor: props.disabled ? "#ccc" : "#fd8c00", // Orange color
        borderColor: "#fd8c00", // Orange color
        textTransform: "none",
        display: "flex",
        flexDirection: "row",
        columnGap: "10px",
        fontSize: "0.9vw",
        "&:hover": {
            color: "#fff",
            backgroundColor: props.disabled ? "#ccc" : orange[700], // Orange color
            border: 0,
            boxShadow: props.disabled ? "0px" : "0px 3px 5px #fd8c00", // Orange color
        },
    }));
    return (
        <AddButton id={props.id} disabled={props.disabled} onClick={props.onClick} style={props.style}>
            {props.children}
        </AddButton>
    );
};
export default DarkOrangeButton;
