import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { green } from "@mui/material/colors";

const DarkGreenButton = (props) => {
  const AddButton = styled(Button)(() => ({
    color: "#fff",
    backgroundColor:props.disabled ? "#ccc":  "rgba(6,181,9,0.89)",
    borderColor: green[500],
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    fontSize: "0.9vw",
    "&:hover": {
      color: "#fff",
      backgroundColor:props.disabled ? "#ccc": green[500],
      border: 0,
      boxShadow:props.disabled ? "0px": "0px 3px 5px #78be20",
    },
  }));
  return (
    <AddButton id={props.id} disabled={props.disabled} onClick={props.onClick} style={props.style}>
      {props.children}
    </AddButton>
  );
};
export default DarkGreenButton;
