import sortIcon from "../../../../src/pages/img/sort_1.png";
import add_icon from "../../../../src/img/add-circle-outline.svg";
import dashHome from "../../../../src/img/dashHome.svg";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Modal,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Tabs,
  Tab,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import dashboardContentStyles from "./../../css/dashboardContent.module.css";
import chapterStyles from "./../../css/createChapter.module.css";
import GetLastModifiedAt from "../../API/school-controller/GetLastModifiedAt";
import EditIconButton from "../../../components/common/EditIconButton";
import DeleteIconButton from "../../../components/common/DeleteIconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import action__Edit from "../../../../src/img/password.svg";
import action__Delete from "../../../../src/img/Delete.svg";
import action__Eye from "../../../../src/img/View_N.png";
import { hover } from "@testing-library/user-event/dist/hover";
// import AvidReader from "./AvidReader";
import Student_Image_Open_Modal from "../Student/Student_Image_Open_Modal";
import GreenAddButton from "../../../components/common/GreenAddButton";
import CloseIcon from '@mui/icons-material/Close';
import {
  fetchTeacherData
} from "../../js/Teacher/Apis/TeacherDashboard";

const PrincipalReadingPassport = () => {
  //   const [sortOrder, setSortOrder] = useState(false);
  //   const [sortBy, setSortBy] = useState("id");
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState(true);
  const [passbookList, setPassbokkList] = useState([]);
  const [modifiedDate, setModifiedDate] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [studentModel, setstudentModel] = useState(false);
  const [showtable, setshowtable] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadImageUrl, setUploadImageUrl] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [avidReader, setAvidReader] = useState(false);
  const [filterStatus, setFilterStatus] = useState("pending");
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const navigate = useNavigate();

  const student_grade = sessionStorage.getItem("gradeName");

  const handleSort = (field) => {
    const isAsc = sortBy === field && sortOrder;
    setSortBy(field);
    setSortOrder(!isAsc);
  };

  const fetchReadingBookData = async () => {
    setshowtable(false)
    let schoolId = sessionStorage.getItem('schoolId');

    const userName = localStorage.getItem("userName");
    const role = localStorage.getItem("role");
    const dat = await fetchTeacherData(userName, role);
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/reading_submissions/search_submission_list_access_based?&pageSize=${pageSize}&pageNumber=${page}&schoolId=${schoolId}&teacherViewed=true&branchId=${dat?.branch || sessionStorage.getItem("branchId")}`

    const response = await axios.get(url,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    let data = response?.data?.data.data || [];

    data.sort((a, b) => {
      let valueA = a[sortBy];
      let valueB = b[sortBy];

      // Special handling for dates
      if (sortBy === "dateOfSubmission") {
        valueA = new Date(valueA);
        valueB = new Date(valueB);
      }

      if (valueA < valueB) return sortOrder ? -1 : 1;
      if (valueA > valueB) return sortOrder ? 1 : -1;
      return 0;
    });

    setPassbokkList(data);
    setshowtable(true)
    setTotalItems(response?.data?.data?.totalElements || response?.data?.data?.numberOfElements);
  };

  const keyToLabelMap = {

    characters: 'Characters ',
    favcharacters: 'Favourite Character ',
    rating: 'Star Value ',
    reviewtext: 'Review Text ',
    description: 'Describe the setting of the story ',
    summary: 'Summary of the Plot ',
    protagonist: 'Who is the protagonist? Describe the character ',
    antagonist: 'Who is the antagonist? Describe the character ',
    majorconflicts: 'What are the major conflicts within the story? ',
    minorconflicts: 'What are the minor conflicts within the story? ',
    turningpoint: 'What is the turning point or climax of the story? ',
    quote: 'Write a memorable quote/line/citation from the story and caption it ',
    motive: 'What is the motive that runs throughout the story? ',
    theme: 'What is the theme that runs throughout the story? ',
    storyend: 'How does the story end? ',
    recommendation: 'Would you recommend the book to somebody? Why or why not? ',
    comments: 'List five different words from the book and define them ',
    starRating: 'Rating ',
    storyFeedback: 'What I like/dislike about the story ',
    characterFeedback: 'What I like/dislike about the Main Character ',
    changes: 'What changes I want to make to the story ',
    maincharacters: 'Main Character ',
    otherCharacters: 'Other Characters '

  };

  // useEffect(() => {
  //   fetchReadingBookData();
  //   GetLastModifiedAt(setModifiedDate);
  //   //publishPassportCDN();
  // }, [filterStatus, sortOrder, sortBy]);

  useEffect(() => {
    fetchReadingBookData();
  }, [pageSize, page, filterStatus, sortOrder, sortBy]);

  const showModel = () => {
    setstudentModel(true);
    setOpen(true);
  };

  const handleClose = () => {
    setstudentModel(false);
    setOpen(false);
  };

  const handleViewClick = (studentData) => {
    setSelectedStudent(studentData);
    setOpen(true);
    showModel();
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage, pageSize, passbookList, sortBy, sortOrder)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    console.log(pageSize, passbookList)
    // setPage(0);
  };


  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          <div style={{ position: "relative", margin: "14px" }}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={`${dashboardContentStyles.az_menu_icon} cursorPointer`}
              />
            </span>
            <span
              id={`az-breadscrum-home`}
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: "pointer" }}
              onClick={() => {
                sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
                sessionStorage.removeItem("sideNavSubMenuNames");
                if (localStorage.getItem("role") === "PRINCIPAL") {
                  navigate("/dashboard/principal/dashboard");
                } else {
                  navigate("/cordinator/dashboard");
                }
              }}
            >
              Home
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#828282" }}
              />
            </span>
            <span>
              <i
                className="fa-solid fa-angle-right"
                style={{ color: "#828282" }}
              />
            </span>
            <span className={dashboardContentStyles.az_selected_menu_text}>
              Reading Passport
            </span>
          </div>

          {/* <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {modifiedDate.data}</p>
          </div> */}
        </article>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "20px",
            marginTop: "20px",
            marginRight: "20px",
          }}
        >
        </div>

        <Paper
          style={{
            marginTop: "5px",
            width: "100%",
            padding: "10px",
          }}
        >
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              sx={{
                overflow: "auto",
              }}
              style={{ marginBottom: "50px" }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Student Name
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={() => handleSort("studentName")}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Grade
                      <img
                        style={{
                          verticalAlign: "middle",
                          paddingLeft: "3px",
                          marginLeft: "5px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={() => handleSort("grade")}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Section
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={() => handleSort("section")}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Submitted On
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          paddingLeft: "3px",
                          marginBottom: "2px",
                        }}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={() => handleSort("dateOfSubmission")}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={dashboardContentStyles.table_head}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody sx={{}}>
                  {(passbookList?.length > 0 && showtable) ? (
                    passbookList.map((elem, i) => (
                      <TableRow
                        sx={{
                          backgroundColor: i % 2 === 0 ? '#ffffff' : '#f5f5f8',
                          "&:last-child td, &:last-child th": {
                            borderBottom: "none !important",
                          },

                          "&:hover": {

                            "& td, & th": {
                              borderTop: "1px solid #ff9702",
                              borderBottom: "1px solid #ff9702",
                            },
                            " & th:first-of-type": {
                              borderLeft: "1px solid #ff9702",
                            },

                            "& td:first-of-type": {

                              borderLeft: "1px solid #ff9702",

                            },

                            "&:last-child td, &:last-child th": {
                              borderBottom: "1px solid #ff9702 !important",
                            },

                            "& td:last-of-type": {
                              borderRight: "1px solid #ff9702",

                            },

                            "& th:last-of-type": {
                              borderRight: "0",
                            },
                          },

                          "& td, & th": {
                            border: "1px solid transparent",
                            borderTop: "1px solid transparent",
                            borderBottom: "1px solid transparent",
                            "&:not(:last-child)": {
                              borderRight: "1px solid transparent",
                            },
                          },


                        }}
                      >
                        <TableCell
                          className={dashboardContentStyles.table_cell}
                        >
                          {page * pageSize + i + 1}
                        </TableCell>
                        <TableCell className={dashboardContentStyles.table_cell}>
                          <Tooltip title={elem.studentName} arrow>
                            <span>{elem.studentName}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={dashboardContentStyles.table_cell}
                          align="center"
                        >
                          {elem.grade}
                        </TableCell>
                        <TableCell
                          className={dashboardContentStyles.table_cell}
                          align="center"
                        >
                          {elem.section}
                        </TableCell>
                        <TableCell
                          className={dashboardContentStyles.table_cell}
                          align="center"
                        >
                          {elem.dateOfSubmission}
                        </TableCell>
                        <TableCell
                          className={dashboardContentStyles.table_cell}
                          align="right"
                          style={{ paddingLeft: 50 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "-10px",
                            }}
                          >
                            <EditIconButton
                              onClick={() => handleViewClick(elem)}
                            >
                              <Tooltip title="View" placement={"top"}>
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Eye}
                                  alt="Edit-Btn"
                                />
                              </Tooltip>
                            </EditIconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    showtable &&
                    <td
                      colSpan="10"
                      style={{
                        height: "25rem",
                        textAlign: "center",
                        color: "grey",
                      }}
                    >
                      {" "}
                      NO DATA AVAILABLE{" "}
                    </td>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              style={{
                bottom: "0",
                width: "81vw",
                position: "fixed",
                backgroundColor: "white",
              }}
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={totalItems}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </Paper>
        </Paper>
      </div>

      {studentModel && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "700",
              color: "#403e75",
              backgroundColor: "#f4f4f9",
              borderBottom: "1px solid #ccc",
              fontFamily: "Poppins, sans-serif",
              position: "relative",
            }}
          >
            Reading Passport Review
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{
                color: "#403e75",
                padding: "8px",
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            style={{
              margin: "20px",
              padding: "20px",
              height: "400px",
              overflowY: "auto",
              backgroundColor: "#f4f4f9",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {selectedStudent && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <span
                    style={{
                      margin: "0 10px",
                      backgroundColor: "#ffa500",
                      color: "#fff",
                      padding: "5px 10px",
                      textTransform: "capitalize",
                    }}
                  >
                    {" "}
                    {/* {selectedStudent.studentName} */}
                    {selectedStudent.studentName.replace(/(?<!^)([A-Z])/g, ' $1').trim()}
                  </span>
                  <span
                    style={{
                      margin: "0 10px",
                      backgroundColor: "#ffa500",
                      color: "#fff",
                      padding: "5px 10px",
                    }}
                  >
                    {selectedStudent.grade}
                  </span>
                  <span
                    style={{
                      margin: "0 10px",
                      backgroundColor: "#ffa500",
                      color: "#fff",
                      padding: "5px 10px",
                    }}
                  >
                    {" "}
                    {selectedStudent.section}
                  </span>
                </div>

                <div
                  style={{ borderTop: "3px solid #eee", paddingTop: "20px" }}
                >
                  <form
                    style={{
                      padding: "20px",
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      style={{
                        marginBottom: "10px",
                        color: "#403e75",
                        fontWeight: "bold",
                      }}
                    >
                      {selectedStudent.titleOfBook || "No Title Available"}
                    </Typography>

                    {selectedStudent.author && (
                      <>

                        <Typography
                          variant="subtitle1"
                          component="div"
                          style={{ marginBottom: "20px", color: "#555" }}
                        >
                          Author: {selectedStudent.author}
                        </Typography>
                      </>
                    )}

                    <Typography
                      variant="h6"
                      component="div"
                      style={{ marginBottom: "20px", color: "#403e75" }}
                    >
                      Book Details
                    </Typography>
                    {selectedStudent &&
                      Object.entries({
                        ...selectedStudent.bookDetails.book,
                        ...selectedStudent.reviewRating.bookRating,
                      }).filter(([key, value]) => value).length > 0 ? (
                      Object.entries({
                        ...selectedStudent.bookDetails.book,
                        ...selectedStudent.reviewRating.bookRating,
                      })
                        .filter(([key, value]) => value)
                        .map(([key, value]) => (
                          <div
                            key={key}
                            style={{
                              marginBottom: "20px",
                            }}
                          >
                            <label
                              htmlFor={key}
                              style={{
                                display: "block",
                                marginBottom: "5px",
                                color: "#555",
                                fontWeight: "bold",
                                // textTransform: "capitalize",
                              }}
                              className="cursorText"
                            >
                              {/* {key.replace(/([A-Z])/g, " $1").trim()}: */}
                              {keyToLabelMap[key] || key.replace(/([A-Z])/g, " $1").trim()}:
                            </label>
                            <div
                              style={{
                                width: "100%",
                                minHeight: "50px",
                                padding: "10px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                color: "#666",
                                backgroundColor: "#f9f9f9",
                                overflowY: "auto",
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                              }}
                            >
                              {value}
                            </div>
                          </div>
                        ))
                    ) : (
                      <Typography variant="body2" style={{ color: "#999" }}>
                        No data available
                      </Typography>
                    )}
                  </form>
                </div>

                {selectedStudent.imageUploaded && (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      borderTop: "1px solid #eee",
                      paddingTop: "20px",
                    }}
                  >
                    <img
                      src={selectedStudent.imageUploaded}
                      alt="Uploaded Book"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                        minHeight: '200px',  // Minimum height
                        maxHeight: '500px',  // Maximum height
                      }}
                      onClick={() => {
                        setSelectedImageUrl(selectedStudent.imageUploaded);
                        setModalOpen(true);
                      }}
                      className="cursorPointer"
                    />
                  </div>
                )}
              </div>
            )}
          </DialogContent>
          <Student_Image_Open_Modal
            selectedImageUrl={selectedImageUrl}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            className="cursorPointer"
          />
        </Dialog>

      )}
    </>
  );
};



export default PrincipalReadingPassport;