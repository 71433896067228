import React from 'react'
import TaskItem from './TaskItem'
import { InfoRounded, Refresh } from '@material-ui/icons'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './Principaldashboard.css'
import { dummyTasks } from './dummyData';
import { taskheaderContainer } from './styles';

const TasksColumn = ({lastColumnWidth,onViewAllTasksClick,}) => {
    const onTaskClick =()=>{}
  return (
    <div
    className='tasks'
  >
    <div style={taskheaderContainer}>
      <div style={{ display: "flex",alignItems:"center" }}>
        <h3 className='sub-heading'>Upcoming Tasks</h3>
        <Refresh style={{fontSize:'10px',marginLeft:"10px"}} />
      </div>
      <InfoOutlinedIcon color='#FD8C00' sx={{ color: '#FD8C00' }} />
    </div>
    <div style={{ overflowY: "auto", height: '88%' }}>
      {dummyTasks?.map(item => <TaskItem key={item.id} onClick={onTaskClick} task={item} />)}

    </div>
    <div className="tasks-bottom">
      <p className="tasks-bottom-text" onClick={onViewAllTasksClick}>View all tasks</p>
    </div>
  </div>
  )
}

export default TasksColumn