import React, { useState, useEffect } from "react";
import SubConceptStyles from "./../css/subConceptTree.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import { SearchOutlined } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import conceptlist_get from "../API_CONTROLLER/concept-controller/conceptlist_get";
import subConcept_controller_get from "../API_CONTROLLER/concept-controller/subConcept_controller_get";
import rootConcept_controller_get from "../API_CONTROLLER/root-concept-controller/rootConcept_controller_get";
import concept_last_modified_get from "../API_CONTROLLER/concept-controller/concept_last_modified_get";
import chapters_list_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_getall";
import concept_controller_post from "../API_CONTROLLER/concept-controller/concept_controller_post";
import concept_table_get from "../API_CONTROLLER/concept-controller/concept_table_get";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";

const SubConceptTree = () => {
  const [showModalShare, setShowModalShare] = useState(false);
  const [subject, setSubject] = useState({});
  const [subtopic, setSubtopic] = useState({});
  const [chapters, setChapters] = useState({});
  const [chaptersList, setChaptersList] = useState({});
  const [conceptlist, setConceptlist] = useState({});
  const [conceptTable, setConceptTable] = useState({});
  const [conceptInputs, setConceptInputs] = useState({
    conceptId: "",
    concept: "",
    rootConcept: "",
    subTopicId: "",
    subjectsId: "",
  });
  const [rootConceptList, setRootConceptList] = useState({});

  const [subConceptInputs, setSubConceptInputs] = useState({
    subConceptId: "",
  });
  const [subconceptList, setSubconceptList] = useState({});
  const [subtopiclist, setSubtopiclist] = useState({});
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSubtopic, setSelectedSubtopic] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");

  const handleShareModal = () => {
    setShowModalShare(true);
  };

  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };

  const handleConceptInputs = (e) => {
    e.preventDefault();
    setConceptInputs({
      ...conceptInputs,
      [e.target.name]: e.target.value,
    });
  };

  const createConceptHandler = (e) => {
    e.preventDefault();
    concept_controller_post(conceptInputs);
  };

  const handlesubConceptInputs = (e) => {
    e.preventDefault();
    setSubConceptInputs({
      ...subConceptInputs,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    subtopics_controller_getall(conceptInputs, setSubtopiclist);
  }, [conceptInputs]);

  useEffect(() => {
    subConcept_controller_get(conceptInputs, setSubconceptList);
  }, [conceptInputs]);

  useEffect(() => {
    rootConcept_controller_get(subConceptInputs, setRootConceptList);
  }, [subConceptInputs]);

  useEffect(() => {
    chapters_controller_getall(setChapters);
    subjects_controller_getall(setSubject);
    subtopics_controller_getall(setSubtopic);
    conceptlist_get(setConceptlist);
    concept_last_modified_get(setLastUpdated);

    return () => {};
  }, []);

  useEffect(() => {
    chapters_controller_getall(setChapters);
    chapters_list_getall(setChaptersList);
    concept_table_get(setConceptTable);
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <article>
          <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            {"  "}
            <a>Dashboard</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>Last Update: {lastUpdated.data}</p>
          </div>
        </article>
        <Paper
          style={{
            marginTop: "55px",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 20px",
            }}
          >
            <div className={SubConceptStyles.training_container}>
              <div className={SubConceptStyles.flex}>
                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: "16vw" }}
                >
                  <InputLabel
                    style={{ fontFamily: "poppins" }}
                    id="demo-multiple-checkbox-label"
                  >
                    *Subject
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name="subjectId"
                    value={conceptInputs.subjectId}
                    onChange={handleConceptInputs}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {subject.length > 0 ? (
                      subject.map((subject, i) => {
                        return (
                          <MenuItem key={i} value={subject.id}>
                            <ListItemText primary={subject.subject} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: "16vw" }}
                >
                  <InputLabel
                    style={{ fontFamily: "poppins" }}
                    id="demo-multiple-checkbox-label"
                  >
                    *Sub Topic
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name="subTopicId"
                    value={conceptInputs.subTopicId}
                    onChange={handleConceptInputs}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {subtopic.length > 0 ? (
                      subtopic.map((subtopic, i) => {
                        return (
                          <MenuItem key={i} value={subtopic.id}>
                            <ListItemText primary={subtopic.subTopic} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: "16vw" }}
                >
                  <InputLabel
                    style={{ fontFamily: "poppins" }}
                    id="demo-multiple-checkbox-label"
                  >
                    *Chapter
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name="chapterId"
                    value={conceptInputs.chapterId}
                    onChange={handleConceptInputs}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {chaptersList.length > 0 ? (
                      chaptersList.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}>
                            <ListItemText primary={elem.chapter} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>

              <div
                className={SubConceptStyles.flex}
                style={{
                  marginTop: "40px",
                  // minWidth: '1280px'
                }}
              >
                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: "16vw" }}
                >
                  <InputLabel
                    style={{ fontFamily: "poppins" }}
                    id="demo-multiple-checkbox-label"
                  >
                    *Concept Name
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name="conceptId"
                    value={conceptInputs.conceptId}
                    onChange={handleConceptInputs}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {conceptlist && conceptlist.length > 0 ? (
                      conceptlist.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}>
                            <ListItemText primary={elem.concept} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: "16vw" }}
                >
                  <InputLabel
                    style={{ fontFamily: "poppins" }}
                    id="demo-multiple-checkbox-label"
                  >
                    *Sub Concept
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    // multiple
                    name="subConceptId"
                    value={subConceptInputs.subConceptId}
                    onChange={handlesubConceptInputs}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {subconceptList && subconceptList.length > 0 ? (
                      subconceptList.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}>
                            <ListItemText primary={elem.subConcept} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <FormControl
                  style={{ marginRight: "8px", marginLeft: "8px" }}
                  sx={{ width: "16vw" }}
                >
                  <InputLabel
                    style={{ fontFamily: "poppins" }}
                    id="demo-multiple-checkbox-label"
                  >
                    *Root Concept
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    // multiple
                    name="rootConcept"
                    value={conceptInputs.rootConcept}
                    onChange={handleConceptInputs}
                    input={<OutlinedInput label="Tag" />}
                    // renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                  >
                    {rootConceptList && rootConceptList.length > 0 ? (
                      rootConceptList.map((elem, i) => {
                        return (
                          <MenuItem key={i} value={elem.id}>
                            <ListItemText primary={elem.rootConcept} />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Select</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <div
                  style={{
                    position: "absolute",
                    right: "61px",
                    maxWidth: "120px",
                    minWidth: "95px",
                  }}
                >
                  <BlueButton onClick={createConceptHandler}>SUBMIT</BlueButton>
                </div>
              </div>
              {/* Table */}
            </div>
          </div>
        </Paper>
        {/* search row start  */}
        <div className={SubConceptStyles.flex} style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              right: "15px",
              marginTop: "20px",
              display: "flex",
            }}
          >
            <Box sx={{ minWidth: 186 }} style={{ margin: "auto 7px" }}>
              <FormControl fullWidth size="small">
                <InputLabel
                  style={{ fontFamily: "poppins" }}
                  id="demo-select-small"
                >
                  Select Subject
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  // value={board}
                  label="Grade"
                  //   onChange={selectGradeHandler}
                  style={{ background: "#fff", borderRadius: 150 }}
                  //   MenuProps={MenuProps}
                >
                  {subject.length > 0 ? (
                    subject.map((subject, i) => {
                      return (
                        <MenuItem key={i} value={subject.id}>
                          <ListItemText primary={subject.subject} />
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>Select</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 150 }} style={{ margin: "auto 7px" }}>
              <FormControl fullWidth size="small">
                <InputLabel
                  style={{ fontFamily: "poppins" }}
                  id="demo-select-small"
                >
                  Sub topic
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  label="Subject"
                  style={{ background: "#fff", borderRadius: 150 }}
                  //   onChange={selectSubjectHandler}
                  //   MenuProps={MenuProps}/
                >
                  {subtopic.length > 0 ? (
                    subtopic.map((subtopic, i) => {
                      return (
                        <MenuItem key={i} value={subtopic.id}>
                          <ListItemText primary={subtopic.subTopic} />
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>Select</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
            <TextField
              sx={{
                width: { sm: 200, md: 300 },
                "& .MuiInputBase-root": {
                  width: "247px !important",
                  height: "32px !important",
                  borderRadius: "50px !important",
                  background: "#fff !important",
                  lineHeight: "21px !important",
                  opacity: 1,
                  font: "normal normal normal 13px/20px Poppins !important",
                },
              }}
              style={{ margin: "auto 7px" }}
              type={"text"}
              placeholder={"Search...."}
              //   onChange={onSearch}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <img
                      src={searchIcn}
                      style={{
                        verticalAlign: "top",
                        fontSize: "large",
                      }}
                      aria-hidden="true"
                    />
                  </IconButton>
                ),
              }}
            />
          </div>
        </div>

        {/* search row end  */}

        {/* content start  */}
        <Paper
          sx={{
            paddingRight: "33px",
            width: "100%",
            overflow: "hidden",
            marginTop: " 75px",
            marginLeft: "15px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "667px",
              backgroundColor: "white",
            }}
          >
            <Table
              style={{
                width: "100%",
              }}
            >
              <TableHead style={{ backgroundColor: "#d9dcfe", height: "55px" }}>
                <TableRow>
                  <TableCell
                    align="left"
                    className={SubConceptStyles.table_head}
                  >
                    Sub topic
                  </TableCell>
                  <TableCell
                    align="left"
                    className={SubConceptStyles.table_head}
                  >
                    Concept
                  </TableCell>
                  <TableCell
                    align="left"
                    className={SubConceptStyles.table_head}
                  >
                    Sub Cocept
                  </TableCell>
                  <TableCell
                    align="left"
                    className={SubConceptStyles.table_head}
                  >
                    Roots
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell
                    align="left"
                    className={SubConceptStyles.table_cell}
                  >
                    Physics
                    <i onClick={handleShareModal} class="fas fa-link"></i>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={SubConceptStyles.table_cell}
                  >
                    Pollution
                    <i class="fas fa-link"></i>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={SubConceptStyles.table_cell}
                  >
                    Air
                    <i class="fas fa-link"></i>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={SubConceptStyles.table_cell}
                  >
                    Causes
                    <i class="fas fa-link"></i>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="left"
                    className={SubConceptStyles.table_cell}
                  >
                    Chemistry
                    <i class="fas fa-link"></i>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={SubConceptStyles.table_cell}
                  >
                    Concept Name
                    <i class="fas fa-link"></i>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={SubConceptStyles.table_cell}
                  >
                    Water
                    <i class="fas fa-link"></i>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={SubConceptStyles.table_cell}
                  >
                    Effects
                    <i class="fas fa-link"></i>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Paper>
        {/* content ends */}
        {/* own modal start  */}
        <div
          className={SubConceptStyles.flex_container}
          id={SubConceptStyles.modal_id}
          style={
            showModalShare
              ? { display: "flex", zIndex: "10" }
              : { display: "none" }
          }
        >
          <div
            className={SubConceptStyles.main_container}
            id={SubConceptStyles.modal_content}
          >
            <div style={{ padding: "20px 23px 25px", position: "relative" }}>
              <center>
                <WarningAmberIcon
                  style={{ color: "#FD8C00", fontSize: "100px" }}
                />
                <h4 style={{ fontWeight: "100" }}>
                  You want to unlink with ------ concept ?
                </h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "25px",
                  }}
                >
                  <button
                    style={{
                      color: "grey",
                      padding: "8px",
                      backgroundColor: "white",
                      margin: "auto 8px",
                      border: "1px solid grey",
                      borderRadius: "21px",
                    }}
                    onClick={handleShareModalOk}
                  >
                    NOT NOW
                  </button>
                  <button
                    style={{
                      padding: "10px 32px",
                      borderRadius: "21px",
                      margin: "auto 8px",
                      backgroundColor: "#FD8C00",
                      color: "white",
                      border: "none",
                    }}
                  >
                    UNLINK
                  </button>
                </div>
              </center>
            </div>
          </div>
        </div>
        {/* own modal end  */}
      </div>
    </>
  );
};

export default SubConceptTree;
