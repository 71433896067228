import React, { useState } from 'react'
import ChapterPerformance from '../../ChapterPerformance/ChapterPerformance'
import Navbar from '../../Navbar'
import MainContainer from '../dashboard/MainContainer'
import StudentReport from '../../StudentReport'

const CordinatorReports = () => {
  const [headerTitle, setHeaderTitle] = useState('')
  const handleHeaderTitle = value => {
    setHeaderTitle(value)
  }
  return (
    <>
      <Navbar handleHeaderTitle={value => handleHeaderTitle(value)} />
      <MainContainer
       		children={<div></div>}
           // children={<DashboardContent />}
        headerTitle={headerTitle}
      />
    </>
  )
}

export default CordinatorReports
