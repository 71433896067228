import axios from "axios";

async function CSVfile_upload_controller_post(formData, setUploadCSV,setMessage) {
  let token = sessionStorage.getItem("token");
  // console.log(formData);
    let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/file-upload?fileTypes=STUDENTS`,formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response)=>{
      // console.log("Success:", response)
      // setMessage(response.data.message)
      // setUploadCSV(response)
      setMessage(response.data.data.message)
      setUploadCSV(response)
      console.log('setUploadCSV >> ',response)
    })
    .catch(err=>
      {
        console.log(err)
        setUploadCSV(null)
        setMessage("Failed")
      })
}

export default CSVfile_upload_controller_post;
