import { Box, TextField, Container } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { createHomeWorkAssignment } from "./Apis/HomeWorkApis";
import {
  AssignDateStructure,
  AssignSubmitStructure,
  validationSchema,
} from "./Schema/schema";
import { isNOTNullOrUndefined } from "../../../utils/helper";
import ConfigurationSuccessModal from "../../../components/common/ConfigurationSuccessModal";

export default function AssignHomeWork({
  boardId,
  gradeId,
  sectionId,
  schoolId,
  branchId,
  subjectId,
  subTopicId,
  chapterId,
  academicYearId,
  teacherId,
  setsuccessModalMessage,
  setSuccessCreateModal,
  selectedDataForEdit,
  setSelectedDataForEdit,
  setSelectedForEdit,
  selectedForEdit,
  setIsCreated,
  showSubTopic
}) {
  var date1 = new Date();
  const [assignDate, setAssignDate] = useState(date1);

  var date = new Date();
  date.setDate(date.getDate() + 1);

  const [submissionDate, setSubmissionDate] = useState(date);
  const [initialSubmissionDate, setInitialSubmissionDate] = useState(date);

  let initialData = {
    homeworkName: "",
    description: "",
  };

  const [formData, setFormData] = useState(initialData);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (selectedForEdit) {
      setFormData({
        id: selectedDataForEdit?.id,
        homeworkName: selectedDataForEdit?.homeworkName,
        description: selectedDataForEdit?.description,
      });

      setAssignDate(new Date(selectedDataForEdit?.assignedDate));
      setSubmissionDate(new Date(selectedDataForEdit?.submittingDate));
    }
  }, [selectedForEdit]);

  const handleDateSelect = (date) => {
    setAssignDate(date);
  };

  const handleSubmitDateSelect = (date) => {
    setSubmissionDate(date);
  };

  const handleSubmit = async () => {
    // e.preventDefault();

    try {
      // Validate the form data using Yup
      await validationSchema.validate(formData, { abortEarly: false });
      // setShowAssignModal(true);
      onSubmit(formData);
      // Form data is valid, you can handle the submission here
      // Clear form errors
      setFormErrors({});
    } catch (error) {
      // Handle validation errors
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setFormErrors(errors);
    }
  };

  const resetForm = () => {
    setFormData(initialData);
    setFormErrors({});
    setSelectedForEdit(false);
    setSelectedDataForEdit({});
    let value = new Date();
    // var updatedValue = value.toLocaleDateString("en-GB");
    // const assignDates = updatedValue.split("/");
    // const newAssignDate =
    //   assignDates[2] +
    //   "-" +
    //   assignDates[0] +
    //   "-" +
    //   (parseInt(assignDates[1]) < 10 ? "0" + assignDates[1] : assignDates[1]);
    var value1 = new Date();
    value1.setDate(value1.getDate() + 1);
    // const newValue = value1.toLocaleDateString("en-GB");
    // const submissionDates = newValue.split("/");
    // const newSubmissionDate =
    //   submissionDates[2] +
    //   "-" +
    //   submissionDates[0] +
    //   "-" +
    //   (parseInt(submissionDates[1]) < 10
    //     ? "0" + submissionDates[1]
    //     : submissionDates[1]);

    setAssignDate(value);
    setSubmissionDate(value1);
  };

  const onSubmit = async () => {
    let value = dayjs(assignDate).$d.toLocaleDateString("en-GB");
    const newAssignDate = AssignDateStructure(value);

    let value1 = dayjs(submissionDate).$d.toLocaleDateString("en-GB");
    const newSubmissionDate = AssignSubmitStructure(value1);

    let hideSubTopicApi = !showSubTopic && { subTopicId: subTopicId}

    let objData = {
      id: formData?.id || "",
      schoolId: schoolId,
      branchId: branchId,
      boardId: boardId,
      gradeId: gradeId,
      sectionId: sectionId,
      subjectId: subjectId,
      // subTopicId: subTopicId,
      chapterId: chapterId,
      academicYearId: academicYearId,
      assignedTeacherId: teacherId.id,
      homeworkName: formData?.homeworkName,
      description: formData?.description,
      assignedDate: newAssignDate,
      submittingDate: newSubmissionDate,
      ...hideSubTopicApi
    };

    let objData1 = {
      id: formData?.id || "",
      schoolId: schoolId,
      branchId: branchId,
      boardId: boardId,
      gradeId: gradeId,
      sectionId: sectionId,
      subjectId: subjectId,
      chapterId: chapterId,
      academicYearId: academicYearId,
      assignedTeacherId: teacherId.id,
      homeworkName: formData?.homeworkName,
      description: formData?.description,
      assignedDate: newAssignDate,
      submittingDate: newSubmissionDate,
    };

    const datas = subTopicId == "undefined" ? objData1 : objData;

    // Handle form submission here
    const response = await createHomeWorkAssignment(datas);
    if (response?.success == true) {
      if (!selectedForEdit) {
        setsuccessModalMessage("Created homework assignment successfully");
        setSuccessCreateModal(true);
        setFormData(initialData);
        setSelectedForEdit(false);
        setSelectedDataForEdit({});
        resetForm();
        setIsCreated(true);
      } else {
        setsuccessModalMessage("Updated homework assignment successfully");
        setSuccessCreateModal(true);
        setFormData(initialData);
        setSelectedForEdit(false);
        setSelectedDataForEdit({});
        resetForm();
        setIsCreated(true);
      }
    } else {
      // setModalMessageError(response.message);
      // setErrorCreateModal(true);
      alert(JSON.stringify(response.message));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          {/* <FormControl
            sx={{ width: "30ch", marginTop: "8px", m: 1 }}
            // size="small"
          > */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Assign Date"
              renderInput={(params) => (
                <TextField onKeyDown={onKeyDown} {...params} />
              )}
              disablePast={true}
              onChange={(date) => handleDateSelect(date)}
              inputFormat={"DD-MM-YYYY"}
              value={assignDate}
              disabled={true}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Last Submission Date"
              renderInput={(params) => (
                <TextField onKeyDown={onKeyDown} {...params} />
              )}
              disablePast={true}
              onChange={(date) => handleSubmitDateSelect(date)}
              inputFormat={"DD-MM-YYYY"}
              value={submissionDate}
              minDate={initialSubmissionDate}
            />
          </LocalizationProvider>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              gap: "5px",
              marginTop: "20px",
            }}
          >
            <TextField
              // placeholder="MultiLine with rows: 2 and rowsMax: 4"
              label="Homework Name"
              multiline
              rows={3}
              maxRows={6}
              required
              name="homeworkName"
              value={
                isNOTNullOrUndefined(formData?.homeworkName)
                  ? formData.homeworkName
                  : ""
              }
              onChange={handleChange}
              error={!!formErrors.homeworkName}
              helperText={formErrors.homeworkName}
              inputProps={{
                maxLength: 35 
              }}
            />
            <TextField
              // placeholder="MultiLine with rows: 2 and rowsMax: 4"
              label="Description"
              multiline
              rows={3}
              maxRows={6}
              required
              name="description"
              value={
                isNOTNullOrUndefined(formData?.description)
                  ? formData.description
                  : ""
              }
              onChange={handleChange}
              error={!!formErrors.description}
              helperText={formErrors.description}
              inputProps={{
                maxLength: 200
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <button
                className="cancel-button-home-work"
                type="button"
                onClick={resetForm}
              >
                Clear
              </button>
              <button
                className="submit-button-home-work"
                onClick={handleSubmit}
                type="button"
              >
                {selectedForEdit ? "Update" : "Create"}
              </button>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}