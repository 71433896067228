import dashboardContentStyles from "../css/dashboardContent.module.css";
import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { MenuProps } from "./Data";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TabList from "@mui/lab/TabList";
import { TabContext } from "@mui/lab";
import TabPanel from "@mui/lab/TabPanel";
import { HorizontalBar } from "react-chartjs-3";
import "chartjs-plugin-datalabels";
import DarkYellowButton from "../../components/common/DarkYellowButton";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import PlanModal from "./PlanModal";
import ViewStudentProfile from "./ViewStudentProfile";
import QuizPerformance from "./QuizPerformance";
import CustomThermometer from "../../components/common/CustomThermometer";
import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import axios from "axios";
import TopBar from "../../components/common/TopBar";
import GenericChart from "./GenericChart";
import { Navigate, useNavigate } from "react-router";
const options = {
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        offset: true,
        ticks: {
          display: true,
          fontSize: 18,
          fontColor: "#115d8c",
        },
        datasets: {
          barThickness: 40,
          barPercentage: 0.7,
        },
        // barThickness: 50,  // number (pixels) or 'flex'
        // maxBarThickness: 50 // number (pixels)
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        display: false,
        scaleLabel: {
          display: true,
        },
        offset: false,
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 100,
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        let datasets = ctx.chart.data.datasets;
        if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
          // let sum = datasets[0].data.reduce((a, b) => a + b, 0);
          // let percentage = Math.round((value / sum) * 100) + "%";
          let percentage = Math.round(value) + "%";
          return percentage;
        }
      },
      display: true,
      color: "#115d8c",
      align: "end",
      anchor: "end",
      font: { size: "23" },
    },
  },
  legend: {
    display: false,
  },
};
const planData = [
  {
    id: 1,
    label: "Number System",
    description: "Learning Plan Description",
    planData: [
      {
        id: 1,
        subject: "Physics",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 1",
        date: "12-08-2022",
        time: "11:40 am",
      },
      {
        id: 2,
        subject: "Chemistry",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 2",
        date: "16-04-2022",
        time: "1:40 pm",
      },
      {
        id: 3,
        subject: "Biology",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 3",
        date: "23-07-2022",
        time: "5:40 pm",
      },
    ],
  },
  {
    id: 2,
    label: "Algebra",
    description: "Learning Plan Description",
    planData: [
      {
        id: 1,
        subject: "Physics",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 1",
        date: "12-08-2022",
        time: "11:40 am",
      },
      {
        id: 2,
        subject: "Chemistry",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 2",
        date: "16-04-2022",
        time: "1:40 pm",
      },
      {
        id: 3,
        subject: "Biology",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 3",
        date: "23-07-2022",
        time: "5:40 pm",
      },
    ],
  },
  {
    id: 3,
    label: "Mensuration",
    description: "Learning Plan Description",
    planData: [
      {
        id: 1,
        subject: "Physics",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 1",
        date: "12-08-2022",
        time: "11:40 am",
      },
      {
        id: 2,
        subject: "Chemistry",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 2",
        date: "16-04-2022",
        time: "1:40 pm",
      },
      {
        id: 3,
        subject: "Biology",
        value:
          "Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's",
        plan: "plan 3",
        date: "23-07-2022",
        time: "5:40 pm",
      },
    ],
  },
];
const PracticeQuizData = [
  {
    id: 1,
    subtopic: "Physics",
    chapterName: "Source of Enargy",
    unitQuiz: "35%",
    Pq1Attempt: "1",
    Pq2Attempt: "1",
    PqScore: "50%",
  },
  {
    id: 2,
    subtopic: "Chemistry",
    chapterName: "Metals & Non-metals",
    unitQuiz: "30%",
    Pq1Attempt: "2",
    Pq2Attempt: "-",
    PqScore: "80%",
  },
  {
    id: 3,
    subtopic: "Physics",
    chapterName: "Source of Enargy",
    unitQuiz: "35%",
    Pq1Attempt: "1",
    Pq2Attempt: "1",
    PqScore: "50%",
  },
  {
    id: 4,
    subtopic: "Chemistry",
    chapterName: "Metals & Non-metals",
    unitQuiz: "30%",
    Pq1Attempt: "2",
    Pq2Attempt: "-",
    PqScore: "80%",
  },
  {
    id: 5,
    subtopic: "Physics",
    chapterName: "Source of Enargy",
    unitQuiz: "35%",
    Pq1Attempt: "1",
    Pq2Attempt: "1",
    PqScore: "50%",
  },
  {
    id: 6,
    subtopic: "Chemistry",
    chapterName: "Metals & Non-metals",
    unitQuiz: "30%",
    Pq1Attempt: "2",
    Pq2Attempt: "-",
    PqScore: "80%",
  },
  {
    id: 7,
    subtopic: "Physics",
    chapterName: "Source of Enargy",
    unitQuiz: "35%",
    Pq1Attempt: "1",
    Pq2Attempt: "1",
    PqScore: "50%",
  },
  {
    id: 8,
    subtopic: "Chemistry",
    chapterName: "Metals & Non-metals",
    unitQuiz: "30%",
    Pq1Attempt: "2",
    Pq2Attempt: "-",
    PqScore: "80%",
  },
];
const diagnosticQuizStaticData = {
  "data": {
      "subTopicSubConceptDetails": [
          {
              "id": "40289288836b282f018379ece03f001a",
              "name": "HUMAN ORGANS",
              "score": 80,
          },
          {
              "id": "40289288836b282f018379ece043001b",
              "name": "INSECT STUDY",
              "score": 80,
          }
      ],
    "overAllQuizScore": 80
  },
  "message": "Diagnostic quiz overall performance success",
  "success": true,
  "errorCode": null,
  "path": null
}
const quizStaticData = {
  "data": {
    "subTopicSubConceptDetails": [
        {
            "id": "40289288836b282f018379ece03f001a",
            "name": "HUMAN ORGANS",
            "score": 80,
            "chapterDetails": [
                {
                    "chapterId": "402892888801bbe301880a7e197c0055",
                    "score": "80",
                    "chapterName": "CHAPTER FOR NURSERY"
                }
            ]
        },
        {
            "id": "40289288836b282f018379ece043001b",
            "name": "INSECT STUDY",
            "score": 0,
            "chapterDetails": []
        }
    ]
  },
  "message": "Unit quiz overall performance success",
  "success": true,
  "errorCode": null,
  "path": null
}
const StudentReport_Student = () => {

  const navigate = useNavigate();

  const token = sessionStorage.getItem("token");
  const boardId = sessionStorage.getItem("boardId");
  const schoolId = sessionStorage.getItem("schoolId");
  const branchId = sessionStorage.getItem("branchId");
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const sectionId = sessionStorage.getItem("sectionId");
  const studentId =sessionStorage.getItem("studentId");

  const [tabsValue, setTabsValue] = useState("1");
  const [showPlan, setShowPlan] = useState(false);
  const [showStudentProfile, setShowStudentProfile] = useState(false);
  const [planDetailData, setPlanDetailData] = useState([]);
  const [modifiedDate, setModifiedDate] = useState({});
  const [subjectList, setSubjectList] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedStudentData, setSelectedStudentData] = useState({});
  const [selectedSubjectName, setSelectedSubjectName] = useState("");
  const [selectedQuizName, setSelectedQuizName] = useState("");
  const [quizList, setQuizList] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState("");
  const [unitQuizData, setUnitQuizData] = useState([]);
  const [practiceQuizData, setPracticeQuizData] = useState([]);
  const [diagnosticQuizData, setDiagnosticQuizData] = useState([]);
  const [practiceLoader, setPracticeLoader] = useState(false);
  const [unitLoader, setUnitLoader] = useState(false);
  const [diagnosticLoader, setDiagnosticLoader] = useState(false);

  const gradeId = sessionStorage.getItem("gradeId");
  const planId = sessionStorage.getItem("planId");

  const [isPageLoading, setIsPageLoading] = useState(true);

  const data = {
    labels: diagnosticQuizData.map(item => item.name),
    datasets: [
      {
        label: "# of Votes",
        // data: [84, 70, 60],
        data: diagnosticQuizData.map(item => item.score === null ? 0 : item.score),
        backgroundColor: [
          "rgba(242,68,16,0.89)",
          "rgb(255,204,0)",
          "rgb(116,60,220)",
        ],
        borderColor: [
          "rgba(242,68,16,0.89)",
          "rgb(255,204,0)",
          "rgb(116,60,220)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const genericDiagnosticData = diagnosticQuizData.map(item => ({
    name: item.name,
    score: item.score === null ? 0 : item.score
  }));

  const genericUnitData = unitQuizData?.map(item => {
    return item?.chapterDetails.map(item2 => ({
      name: item2.chapterName,
      score: item2.score === null ? 0 : +item2.score
    }))
  });


  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => {};
  }, []);
  const tabsChangeHandle = (event, newValue) => {
    setTabsValue(newValue);
  };
  const fetchSubjectData = async (params) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/subjects/grade-plan?planId=${planId}&gradeId=${gradeId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data.data || [];
  };
  const fetchQuizList = async (params) => {
    let url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/quiz/diagnostic-quiz-list-for-student?studentId=${studentId}&subjectId=${params.subjectId}&gradeId=${gradeId}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&sectionId=${sectionId}`;
    const response = await axios.get(
      url,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response?.data.data || [];
  }
  const fetchUnitQuizData = async (params) => {
    /* Dynamic Unit Quiz Data */
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/report/get-unit-quiz-details?subjectId=${params.subjectId}&studentId=${studentId}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${gradeId}&sectionId=${sectionId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    );
    return response?.data?.data?.subTopicSubConceptDetailDtos || [];
  }
  const fetchPracticeQuizData = async (params) => {
    const response = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/student/report/get-practice-quiz-details?subjectId=${params.subjectId}&studentId=${studentId}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${gradeId}&sectionId=${sectionId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    );
    const responseData = response?.data?.data?.practiceQuizOverAllResponseList;
    const practiceData = [];
    if(responseData) {
      const hasSubTopic = response?.data?.data?.hasSubTopic;
      practiceData.push(hasSubTopic);
      practiceData.push(responseData);
    }
    return practiceData || [];
  }
  const fetchDiagnosticQuizData = async (params, quizId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/student/report/get-diagnostic-quiz-detail?subjectId=${params.subjectId}&studentId=${studentId}&boardId=${boardId}&schoolId=${schoolId}&branchId=${branchId}&gradeId=${gradeId}&sectionId=${sectionId}&quizId=${quizId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    );
    const overAllQuizScore = response?.data?.data?.overAllQuizScore;
    const hasSubTopic = response?.data?.data?.hasSubTopic;
    const responseData = response?.data?.data?.subTopicSubConceptDetails?.map(item => {
      return {
        id: item.id,
        name: item.name,
        score: item.score
      }
    });
    if(responseData) {
      responseData[0].overAllQuizScore = overAllQuizScore;
      responseData[0].hasSubTopic = hasSubTopic;
    }
    return responseData || [];
  }
  const fetchStudentDataUsingId = async (studentId) => {
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students/${studentId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedStudentData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setSelectedStudentData({});
      });
  };
  const fetchStudentData = async (pageSize) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/students?pageSize=${pageSize}&sortOrder=true
      `,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // if (res.data.data?.totalElements >= pageSize) {
    //   setPageSize(res.data.data?.totalElements);
    // }
    return res?.data?.data?.data || [];
  };

  useEffect(() => {
    firstRender();
    return () => {
      cleanupOnUnmount()
    }
  }, [token])

  const cleanupOnUnmount = () => {
    setSelectedSubject('')
  }

  const firstRender = async () => {
    setIsPageLoading(true)
    await autoPopulate();
  }

  const autoPopulate = async () => {
    const subjectListFetched = await fetchSubjectData();
    setSubjectList(subjectListFetched)
    setSelectedSubject(subjectListFetched?.length ? subjectListFetched[0].subjectId : "");
    setSelectedSubjectName(subjectListFetched[0].subject)

    setIsPageLoading(false)
  }

  useEffect(async () => {
    const quizParams = {
      subjectId: selectedSubject,
    }
    if(selectedSubject) {
      if(tabsValue === '1') {
        const quizListFetched = await fetchQuizList(quizParams);
        const defaultQuizList = quizListFetched.length
          ? quizListFetched
          : ["Quiz Not Available"];
        setQuizList(defaultQuizList);
        setSelectedQuiz(quizListFetched[0]?.quizId);
        setSelectedQuizName(quizListFetched.length ? quizListFetched[0]?.quizName.toUpperCase() : "Quiz Not Available");
      } else if (tabsValue === '2') {
        const fetchUnitReport = async () => {
          try {
            setUnitLoader(true);
            const unitQuizDataFetched = await fetchUnitQuizData(quizParams);
            setUnitQuizData(unitQuizDataFetched);
            setUnitLoader(false);
          } catch (error) {
            setUnitLoader(false);
            setUnitQuizData([]);
            console.log("not able to fetch unit data !")
          }
        }
        fetchUnitReport();
      } else {
        const fetchPracticeReport = async () => {
          try {
            setPracticeLoader(true);
            const practiceQuizDataFetched = await fetchPracticeQuizData(quizParams);
            setPracticeQuizData(practiceQuizDataFetched);
            setPracticeLoader(false);
          } catch (error) {
            setPracticeLoader(false);
            setPracticeQuizData([]);
            console.log("not able to fetch practice data !")
          }
        }
        fetchPracticeReport();
      }
    }
  }, [selectedSubject, tabsValue]);

  useEffect(async () => {
    const quizParams = {
      subjectId: selectedSubject
    }
    if(selectedSubject && selectedQuiz && tabsValue === '1') {
      const fetchDiagnosticReport = async () => {
        try {
          setDiagnosticLoader(true);
          const diagnosticQuizDataFetched = await fetchDiagnosticQuizData(quizParams, selectedQuiz);
          setDiagnosticQuizData(diagnosticQuizDataFetched);
          setDiagnosticLoader(false);
        } catch (error) {
          setDiagnosticLoader(false);
          setDiagnosticQuizData([]);
          console.log("not able to fetch diagnostic data !")
        }
      }
      fetchDiagnosticReport();
    }
  }, [selectedSubject, selectedQuiz, tabsValue]);

  const selectSubjectHandler = (id) => {
    setSelectedQuiz('');
    const findSubject = subjectList.find(item => item.subjectId === id);
    setSelectedSubject(id);
    setSelectedSubjectName(findSubject?.subject);
  };

  const selectQuizHandler = id => {
    const findQuiz = quizList.find(item => item.quizId === id);
    setSelectedQuiz(id);
    setSelectedQuizName(findQuiz?.quizName.toUpperCase());
  }

  useEffect(async () => {
    if (studentId) {
      await fetchStudentDataUsingId(studentId);
    }
  }, [studentId]);

  const colors = [
    "rgba(48,210,214,1)",
    "rgb(20,160,192)",
    "rgb(102,46,155)",
    "rgb(31 209 213)",
    "rgb(16 158 189)",
    "rgb(108 35 151)",
    "rgb(250 106 52)",
    "rgb(247 204 61)",
    "rgb(77 252 174)"
  ];
  return (
    <div style={{ width: "100%"}}>
      <TopBar
        title={"Home"}
        nav1={"template"}
        subTitle={"Reports"}
        subSubTitle={"Overall Performance"}
        modifiedDate={modifiedDate?.data}
      />
      <div
        style={{
          marginTop: "15px",
          paddingRight: "33px",
          position: "absolute",
          left: "23px",
          width: "98%",
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}
        >
          <FormControl
           sx={{
            "& .MuiInputLabel-root": {
              color: "#FD8C00 !important",
              fontSize: "14px !important",
              opacity: 0.9,
            },
            "& .MuiInputBase-root": {
              backgroundColor: "#FFFFFF",
              borderRadius: "50px",
              width: "148px",
              height: "32px",

            },

          }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#FD8C00"
                },
              fontSize: '14px'
              }}
            >
              Select Subject
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className={selectedSubject ? dashboardContentStyles.az_select_menu_selected : dashboardContentStyles.az_select_menu}
              style={{
                borderRadius: "150px",
                padding: "4px",
                color: selectedSubject ? "orange" : "black",
              }}
          sx={{
            "& .css-10hburv-MuiTypography-root": {
              font: "normal normal normal 15px/15px Poppins !important",
            },
            "&.Mui-selected": {
              backgroundColor: 'orange',
              color: "#FFFFFF",
              border: "2px solid orange"
            },
            "&.Mui-selected:hover": {
              backgroundColor: '#fcedc2',
              color: "#000000",
            },
            "&:not(.Mui-selected):hover": {
              backgroundColor: '#fcedc2',
              color: '#000000',
              border: "2px solid orange"
            },
          }}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              MenuProps={MenuProps}
              
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedSubject}
              onChange={(e) => selectSubjectHandler(e.target.value)}
            >
              {subjectList?.map((item) => {
                return (
                  <MenuItem
                    value={item.subjectId}
                    key={item.subjectId}
                   // className={dashboardContentStyles.menu_item}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: 'orange',
                        color: "#FFFFFF"
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: '#fcedc2',
                        color: "#000000"
                      },
                      "&:not(.Mui-selected):hover": {
                        backgroundColor: '#fcedc2',
                        color: '#000000',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item.subject}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
         { tabsValue == "1" &&
          <FormControl
            sx={{
              minWidth: "8.8vw",
              background: "white",
              borderRadius: 150,
              width: '200px',
              height: '32px',
              "& .MuiInputLabel-root": {
                color: "#7A7A7A !important",
                fontSize: "14px !important",
                opacity: 0.9,
              },
              "& .MuiInputBase-root": {
                backgroundColor: "#FFFFFF",
                borderRadius: "50px",
                width: "100%",
                height: "32px",
              },
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{
                top: '-10px',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  top: 0,
                  color: "#FD8C00"
                },
                fontSize: '14px'
              }}
            >
              Select Quiz
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{
                "& .css-10hburv-MuiTypography-root": {
                  font: "normal normal normal 15px/15px Poppins !important",
                },
                "&.Mui-selected": {
                  backgroundColor: 'orange',
                  color: "#FFFFFF",
                  border: "2px solid orange"
                },
                "&.Mui-selected:hover": {
                  backgroundColor: '#fcedc2',
                  color: "#000000",
                },
                "&:not(.Mui-selected):hover": {
                  backgroundColor: '#fcedc2',
                  color: '#000000',
                  border: "2px solid orange"
                },
              }}
              input={
                <OutlinedInput
                  label="Tag"
                  sx={{
                    '& legend': {
                      fontSize: '2.15em'
                    }
                  }}
                />
              }
              MenuProps={MenuProps}
              style={{
                borderRadius: "150px",
                padding: "4px",
                height: "32px",
                color: '#FD8C00'
              }}
              size={"small"}
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
              )}
              value={selectedQuiz ?? quizList[0]}
              onChange={(e) => selectQuizHandler(e.target.value)}
            >
              {quizList?.map((item) => {
                return (
                  <MenuItem
                    value={item.quizId ? item.quizId : quizList[0]}
                    key={item.quizId}
                   // className={dashboardContentStyles.menu_item}
                   sx={{
                    "&.Mui-selected": {
                      backgroundColor: 'orange',
                      color: "#FFFFFF"
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: '#fcedc2',
                      color: "#000000"
                    },
                    "&:not(.Mui-selected):hover": {
                      backgroundColor: '#fcedc2',
                      color: '#000000',
                    },
                  }}
                 >
                    <ListItemText
                      primary={item.quizName ? item.quizName : quizList[0]}
                      sx={{
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis !important',
                        '& span': {
                          fontSize: '14px',
                          textOverflow: 'ellipsis !important',
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>}
        </div>
        <Box sx={{ width: "100%", typography: "body1", height: "100%" }}>
          <TabContext value={tabsValue}>
            <Box
              sx={{
                borderBottom: 2,
                borderColor: "rgb(75,70,137)",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TabList
                onChange={(e, value) => tabsChangeHandle(e, value)}
                aria-label="lab API tabs example"
                textColor="inherit"
                indicatorColor="secondary"
              >
                <Tab
                  label="Diagnostic Quiz"
                  value="1"
                  sx={{
                    background: tabsValue === "1" ? "rgb(75,70,137)" : "",
                    color: tabsValue === "1" ? "white" : "black",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Unit Quiz"
                  value="2"
                  sx={{
                    background: tabsValue === "2" ? "rgb(75,70,137)" : "",
                    color: tabsValue === "2" ? "white" : "",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Practice Quiz"
                  value="3"
                  sx={{
                    background: tabsValue === "3" ? "rgb(75,70,137)" : "",
                    color: tabsValue === "3" ? "white" : "",
                    borderTopLeftRadius: "7px",
                    borderTopRightRadius: "7px",
                    textTransform: "none",
                  }}
                />
              </TabList>
              {/* <DarkYellowButton
                style={{
                  marginBottom: "5px",
                  cursor: !Object.keys(selectedStudentData)?.length
                    ? "no-drop"
                    : "pointer",
                  marginRight: '25px',
                  position: 'relative',
                  top: '-5px'
                }}
                onClick={() =>
                  Object.keys(selectedStudentData)?.length &&
                  setShowStudentProfile(true)
                }
                disabled={!Object.keys(selectedStudentData)?.length}
              >
                Student Profile
              </DarkYellowButton> */}
            </Box>
            <Paper
              sx={{
                width: "100%",
                // minHeight: "650px",
                height: "100%",
              }}
            >
              <TabPanel value="1" sx={{minHeight: '68vh'}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    rowGap: "1rem",
                    // minHeight: "600px",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "10px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 21,
                        color: "rgb(0 75 255)",
                      }}
                    >
                      {selectedQuizName
                        ? selectedQuizName
                        : 'No Quizzes Are Available'
                      } - {selectedSubjectName ? selectedSubjectName : 'No Subjects Are Available'}
                    </div>
                    <Box sx={{ flexGrow: 1 }}>
                      {/* {diagnosticLoader &&
                         <div
                          className={dashboardContentStyles.quizErrorMsg}
                        >
                         FETCHING...
                        </div>
                      } */}
                      {(!diagnosticLoader && diagnosticQuizData.length === 0 ||
                          diagnosticQuizData === null ||
                          selectedQuizName === 'Quiz Not Available') &&
                          <div
                            className={dashboardContentStyles.quizErrorMsg}
                          >
                            NO QUIZZES ARE AVAILABLE
                          </div>
                      }
                      {!diagnosticLoader &&
                        diagnosticQuizData.length > 0 &&
                        selectedQuizName !== 'Quiz Not Available' &&
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={2} sm={6}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  textAlign: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 19,
                                    fontWeight: 600,
                                    color: "rgb(66, 61, 92)",
                                    textAlign: "center",
                                  }}
                                >
                                  Quiz Score
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "310px",
                                    paddingTop: "0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <CustomThermometer
                                    totalValue={diagnosticQuizData?.map(item => Math.round(item?.overAllQuizScore))[0]}
                                    color={"rgb(69,202,144)"}
                                    height="240"
                                    steps={5}
                                    minValue={0}
                                    maxValue={100}
                                    format={"%"}
                                    mode={"light"}
                                  />
                                </div>
                                <div
                                  style={{
                                    color: "rgb(66, 61, 92)",
                                    fontWeight: 600,
                                  }}
                                >
                                  Total Score
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 19,
                                    fontWeight: 600,
                                    color: "rgb(66, 61, 92)",
                                    textAlign: "center",
                                  }}
                                >
                                  {diagnosticQuizData?.map(item => item?.hasSubTopic)[0] ? 'Subtopic' : 'Subconcept'}
                                </div>
                                <GenericChart
                                  data={genericDiagnosticData}
                                  colors={colors}
                                  chapterCount={genericDiagnosticData.length}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  rowGap: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 19,
                                    fontWeight: 600,
                                    color: "rgb(66, 61, 92)",
                                    textAlign: "center",
                                  }}
                                >
                                  Learning Plan
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: "10px",
                                    overflow: "auto",
                                    height: "240px",
                                    padding: "10px",
                                  }}
                                >
                                  {planData.map((data) => {
                                    return (
                                      <TextField
                                        id="outlined-basic"
                                        label={data.label}
                                        variant="outlined"
                                        value={data.description}
                                        key={data.id}
                                        InputProps={{
                                          startAdornment: (
                                            <IconButton
                                              onClick={() => {
                                                setShowPlan(true);
                                                setPlanDetailData(data.planData);
                                              }}
                                            >
                                              <CopyAllOutlinedIcon
                                                style={{ color: "#f79f02" }}
                                              />
                                            </IconButton>
                                          ),
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                      }
                    </Box>
                  </div>
                  {/* <Divider /> */}
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                  }}
                >
                  <div
                    style={{
                      fontSize: 21,
                      color: "rgb(0 75 255)",
                    }}
                  >
                    Unit Quiz Performance - {selectedSubjectName ? selectedSubjectName : 'No Subjects Are Available'}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "10px",
                    }}
                  >
                    {/* {unitLoader &&
                      <div className={dashboardContentStyles.quizErrorMsg}>
                        FETCHING...
                      </div>
                    } */}
                    {!unitLoader && (unitQuizData?.length === 0 ||
                        unitQuizData === null) &&
                        <div className={dashboardContentStyles.quizErrorMsg}>
                          NO QUIZZES ARE AVAILABLE
                        </div>
                    }
                    {!unitLoader && unitQuizData?.length > 0 &&
                      unitQuizData?.map(item => {
                      return (
                        <QuizPerformance
                          title={item?.name}
                          titleColor={"rgba(242,68,16,0.89)"}
                          thValue={Math.round(item?.score)}
                          thColor={"rgba(242,68,16,0.89)"}
                          barColor={["rgba(242,68,16,0.89)", "rgb(83,242,173)"]}
                          // barData={item.chapterDetails.map(item2 => item2.score)}
                          // barLabel={item.chapterDetails.map(item2 => item2.chapterName)}
                          data={genericUnitData}
                          colors={colors}
                        />
                      );
                    })}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: "600px",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1rem",
                  }}
                >
                  <div
                    style={{
                      fontSize: 21,
                      color: "rgb(0 75 255)",
                    }}
                  >
                    Practice Quiz Performance - {selectedSubjectName ? selectedSubjectName : 'No Subjects Are Available'}
                  </div>
                  {/* {practiceLoader &&
                    <div className={dashboardContentStyles.quizErrorMsg}>
                      FETCHING...
                    </div>
                  } */}
                  {(!practiceLoader && (practiceQuizData[1]?.length === 0 ||
                      practiceQuizData[1] === null || practiceQuizData?.length === 0)) &&
                        <div className={dashboardContentStyles.quizErrorMsg}>
                          NO QUIZZES ARE AVAILABLE
                        </div>
                  }
                  {(!practiceLoader && practiceQuizData[1]?.length > 0) &&
                    practiceQuizData[1]?.map((item) => {
                      return (
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
                          }}
                        >
                          <TableContainer sx={{ maxHeight: 550, overflow: "auto" }}>
                            <Table
                              sx={{ minWidth: 650 }}
                              stickyHeader={true}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontWeight: 500,
                                      // background: "rgb(75,70,137)",
                                      background: "#574f7f",
                                      color: "white",
                                    }}
                                  >
                                    {practiceQuizData[0] ? 'Subtopic' : 'Subconcept'}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontWeight: 500,
                                      background: "#574f7f",
                                      color: "white",
                                    }}
                                  >
                                    Chapter Name
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontWeight: 500,
                                      background: "#574f7f",
                                      color: "white",
                                    }}
                                  >
                                    Unit Score less then 40%
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontWeight: 500,
                                      background: "#574f7f",
                                      color: "white",
                                    }}
                                  >
                                    PQ-1 Attempt
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontWeight: 500,
                                      background: "#574f7f",
                                      color: "white",
                                    }}
                                  >
                                    PQ-2 Attempt
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      fontWeight: 500,
                                      // paddingRight: "60px",
                                      background: "#574f7f",
                                      color: "white",
                                    }}
                                  >
                                    Highest PQ Score
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: '0 !important',
                                    },
                                  }}
                                  key={item?.id}
                                  hover={false}
                                >
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      columnGap: "10px",
                                      color: "#4a86a4",
                                      // color: "#115d8c",
                                      border: 0,
                                      fontWeight: 500,
                                      width: '15vw'
                                    }}
                                  >
                                    {item?.name}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      color: "#4a86a4",
                                      border: 0,
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item?.chapterDetails?.map(item2 => {
                                      return (
                                        <div style={{lineHeight: 2}}>
                                          {item2?.chapterName}
                                        </div>
                                      )
                                    })}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ color: "black", fontWeight: 500, border: 0 }}
                                  >
                                    {item?.chapterDetails?.map(item2 => {
                                      return (
                                        <div style={{lineHeight: 2}}>
                                          {item2?.scoreLessThanFortyFive} %
                                        </div>
                                      )
                                    })}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ color: "black", fontWeight: 500, border: 0 }}
                                  >
                                    {item?.chapterDetails.map(item2 => {
                                      return (
                                        <div style={{lineHeight: 2}}>
                                          {item2?.pq1 === null
                                            ? '-'
                                            : item2?.pq1
                                          }
                                        </div>
                                      )
                                    })}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ color: "black", fontWeight: 500, border: 0 }}
                                  >
                                    {item?.chapterDetails?.map(item2 => {
                                      return (
                                        <div style={{lineHeight: 2}}>
                                          {item2?.pq2 === null
                                            ? '-'
                                            : item2?.pq2
                                          }
                                        </div>
                                      )
                                    })}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ color: "black", fontWeight: 500, border: 0 }}
                                  >
                                    {item?.chapterDetails?.map(item2 => {
                                      return (
                                        <div style={{lineHeight: 2}}>
                                          {item2?.highestPQScore} %
                                        </div>
                                      )
                                    })}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      )})
                  }
                </div>
              </TabPanel>
            </Paper>
          </TabContext>
        </Box>
      </div>
      {showPlan && (
        <PlanModal
          open={showPlan}
          close={() => setShowPlan(false)}
          data={planDetailData}
        />
      )}
      {showStudentProfile && (
        <ViewStudentProfile
          open={showStudentProfile}
          close={() => setShowStudentProfile(false)}
          studentDetail={selectedStudentData}
        />
      )}
    </div>
  );
};
export default StudentReport_Student;
