import { Box, Modal, Typography } from "@mui/material";
// import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import YellowBorderHover from "../../components/common/YellowBorderHover";
import YellowButton from "../../components/common/yellowButton";
import info_icon from "../../../src/img/pop-up-indicator1.svg"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 1,
  p: 2,
};
const CommonDeleteModal = ({
  open,
  close,
  deleteTitle,
  deleteHandler,
  deleteId,
  deleteName,
}) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <InfoOutlinedIcon
              style={{ color: "#FFA700", fontSize: 120, }}
            /> */}
            <img src={info_icon} alt="info-icon"/>
          </div>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              
                <p>Are you sure you want to remove  {deleteTitle}</p>
              
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#FFA700",
                  // padding: "4px",
                  marginBottom:"40px",
                  textAlign:"center"
                }}
              >
                {`"${deleteName}"`}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  paddingBottom:"20px",
                  columnGap: "1rem",
                }}
              >
                {deleteName ==
                "No Permission to delete or deactivate, as it has existing mapping in the workflow..!" || deleteName == "File modification is not possible for approved chapters." ? (
                  <YellowButton onClick={close}>OK</YellowButton>
                ) : (
                  <>
                    <YellowBorderHover onClick={deleteHandler}>Yes</YellowBorderHover>
                    <YellowButton onClick={close}>No</YellowButton>
                  </>
                )}
                {/* <YellowButton onClick={() => deleteHandler(deleteId)}>
                  Yes
                </YellowButton> */}
              </div>
            </div>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};
export default CommonDeleteModal;
