import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	LinearProgress,
	Modal,
	Paper,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axios, { Axios } from "axios";
import CaseStudyPreviewModal from "./CaseStudyPreviewModal";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: 1000,
	bgcolor: "transparent",
	borderRadius: "10px",
	padding: 0,
	display: "flex",
	flexDirection: "column",
	rowGap: "1rem",
};
const LinearProgressWithLabel = (props) => {
	return (
		<Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
			<Box sx={{ width: "100%", mr: 1 }}>
				<Box
					sx={{
						minWidth: 35,
						display: "flex",
						justifyContent: "space-between",
						padding: "10",
					}}
				>
					<Typography
						fontSize={18}
						fontWeight={600}
						sx={{ padding: "10px 0px" }}
					>
						{props.name}
					</Typography>
					<Typography
						variant="body2"
						fontWeight={600}
						fontSize={18}
						sx={{ padding: "10px 0px" }}
					>{`${Math.round(props.value)} out of ${
						props.totalQuestions
					}`}</Typography>
				</Box>
				<LinearProgress
					variant="determinate"
					value={Math.round(
						(100 * props.value) / props.totalQuestions
					)}
					sx={{ height: 10, borderRadius: "150px", color: "#FFA700" }}
					color={"inherit"}
				/>
			</Box>
		</Box>
	);
};
const ExitButton = styled(Button)(() => ({
	color: "#fff",
	width: 80,
	backgroundColor: "rgba(5,175,116,0.89)",
	borderRadius: 5,
	textTransform: "none",
	"&:hover": {
		color: "#fff",
		backgroundColor: "rgba(5,175,116,0.89)",
		border: 0,
	},
}));
const PreviewButton = styled(Button)(() => ({
	color: "#fff",
	width: 200,
	backgroundColor: "#8589be",
	borderRadius: 5,
	textTransform: "none",
	display: "flex",
	flexDirection: "row",
	columnGap: "10px",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#8589be",
		border: 0,
	},
}));
const NextButton = styled(Button)(() => ({
	color: "#fff",
	width: 200,
	backgroundColor: "#FFA700",
	borderRadius: 5,
	textTransform: "none",
	display: "flex",
	flexDirection: "row",
	columnGap: "10px",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#FFA700",
		border: 0,
	},
}));
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: "rgba(5,175,116,0.89)",
	...theme.typography.body2,
	padding: "4px",
	textAlign: "center",
	color: "white",
	borderRadius: "150px",
	width: 35,
	height: 35,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));
const CreateQuizPreviewModal = ({ open, close, data, onSubmitClick }) => {
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [storeData, setStoreData] = useState([]);
	const [questions, setQuestions] = useState([]);
	const [answerByOrder, setAnswerByOrder] = useState([]);
	const [matchData, setMatchData] = useState([]);
	const [MSQList, setMSQList] = useState([]);
	const [MCQ, setMCQ] = useState("");
	const [subMCQ, setSubMCQ] = useState([]);
	const [selectedQuestion, setSelectedQuestion] = useState({
		question: "",
		color: "",
	});
	const [selectedData, setSelectedData] = useState([]);
	const [selectedUrl, setSelectedUrl] = useState("");
	const [selectedImgData, setSelectedImgData] = useState([]);
	const [selectedAnswerData, setSelectedAnswerData] = useState([]);
	const [selectedAnswer, setSelectedAnswer] = useState("");
	const [subquestions, setSubquestions] = useState([]);
	const handleChange = (event) => {
		const value = event.target.value;
		const item = questions && questions[currentQuestion];
		const id = item?.questionId;
		if (id) {
			const clone = [...storeData];
			const findIndex = clone?.findIndex(
				(data) => data?.questionId === id
			);
			if (findIndex !== -1) {
				clone[findIndex].answer = value;
				setStoreData(clone);
			} else {
				const data = {
					quizId: item?.id,
					question: item?.question,
					questionId: item?.questionId,
					answer: value,
					subQuestions: [],
				};
				setStoreData((pre) => [...pre, data]);
			}
			setMCQ(value);
		}
	};
	const MSQHandler = (event) => {
		const value = event.target.value;
		const item = questions && questions[currentQuestion];
		const id = item?.questionId;
		if (id) {
			const clone = [...MSQList];
			let filterValue = clone?.find((item) => item === value);
			if (filterValue) {
				const remove = MSQList.filter((item) => item !== value);
				setMSQList(remove);
			} else {
				const storeClone = [...storeData];
				const findIndex = storeClone?.findIndex(
					(data) => data?.questionId === id
				);
				if (findIndex !== -1) {
					storeClone[findIndex].answer = [...MSQList, value];
					setStoreData(storeClone);
				} else {
					const data = {
						quizId: item?.id,
						question: item?.question,
						questionId: item?.questionId,
						answer: [...MSQList, value],
						subQuestions: [],
					};
					setStoreData((pre) => [...pre, data]);
				}
				setMSQList((pre) => [...pre, value]);
			}
		}
	};
	useEffect(() => {
		if (data) {
			setQuestions(data?.quizQuestions);
		}
	}, [data]);
	const nextQuestionHandler = () => {
		if (currentQuestion + 1 < data?.quizQuestions?.length) {
			setCurrentQuestion(currentQuestion + 1);
		}
	};
	const preQuestionHandler = () => {
		if (currentQuestion >= 1) {
			setCurrentQuestion(currentQuestion - 1);
			// setMatchData([]);
		}
	};
	const currentQuestionHandler = (i) => {
		setCurrentQuestion(i);
	};
	const fillHandler = (e) => {
		const value = e.target.value;
		if (value) {
			const clone = [...answerByOrder];
			const filterValue = clone?.find((item) => item === value);
			if (filterValue) {
				const remove = answerByOrder.filter((item) => item !== value);
				setAnswerByOrder(remove);
			} else {
				setAnswerByOrder((pre) => [...pre, value]);
			}
		}
	};
	const matchRadioButtonHandler = (e, question) => {
		const value = e.target.value;
		const questionData = questions && questions[currentQuestion];
		if (value) {
			const clone = [...matchData];
			const storeClone = [...storeData];
			const filterValue = clone?.find(
				(item) => item.question === question
			);
			const storeIndex = storeClone.findIndex(
				(que) => que.questionId === questionData?.questionId
			);
			if (filterValue) {
				filterValue.answer = value;
				setMatchData(clone);
			} else {
				const match = {
					questionId: Math.random(),
					question,
					answer: value,
				};
				if (storeIndex !== -1) {
					storeClone[storeIndex].answer = [...matchData, match];
					setStoreData(storeClone);
					setMatchData((prev) => [...prev, match]);
				} else {
					const data = {
						quizId: questionData?.id,
						question: questionData?.question,
						questionId: questionData?.questionId,
						answer: [...matchData, match],
						subQuestions: [],
					};
					setMatchData((prev) => [...prev, match]);
					setStoreData((pre) => [...pre, data]);
				}
			}
		}
	};
	const subMCQHandler = (event, item) => {
		const value = event.target.value;
		const questionData = questions && questions[currentQuestion];
		if (value) {
			const clone = [...subMCQ];
			const storeClone = [...storeData];
			const findIndex = clone.findIndex(
				(sub) => sub.subQuestionId === item.id
			);
			const storeIndex = storeClone.findIndex(
				(que) => que.questionId === questionData?.questionId
			);
			if (findIndex !== -1) {
				clone[findIndex].subAnswer = value;
				storeClone[storeIndex].subQuestions = clone;
				setStoreData(storeClone);
				setSubMCQ(clone);
			} else {
				const data = {
					subQuestionId: item?.id,
					subQuestion: item?.question,
					subAnswer: value,
				};
				if (storeIndex !== -1) {
					storeClone[storeIndex].subQuestions = [...subMCQ, data];
					setStoreData(storeClone);
				} else {
					const finalData = {
						quizId: questionData?.id,
						question: questionData?.question,
						questionId: questionData?.questionId,
						answer: "",
						subQuestions: [...subMCQ, data],
					};
					setStoreData((pre) => [...pre, finalData]);
				}
				setSubMCQ((pre) => [...pre, data]);
			}
		}
	};
	const selectQuestionHandler = (question) => {
		const color = setBg();
		const clone = [...selectedData];
		const findIndex = clone.findIndex((item) => item.question === question);
		if (findIndex !== -1) {
			const filter = clone.filter((item) => item.question !== question);
			setSelectedData(filter);
		} else {
			setSelectedQuestion({ question: question, color: color });
		}
	};
	const selectedAnswerHandler = (item, answer) => {
		const question = item?.question;
		if (question) {
			const findIndex =
				questions &&
				questions[currentQuestion]?.options.findIndex(
					(item) => item.question === question
				);
			const find =
				questions &&
				questions[currentQuestion]?.options?.find(
					(item) => item.question === question
				);
			if (findIndex !== -1) {
				const clone = [...selectedData];
				const findIndexForData = clone.findIndex(
					(item) => item.question === question
				);
				if (findIndexForData !== -1) {
				} else {
					const data = {
						question: find?.question,
						answer: answer,
						color: item.color,
					};
					setSelectedData((pre) => [...pre, data]);
					setSelectedQuestion({ question: "", color: "" });
				}
			}
		} else if (answer) {
			const findIndex = selectedData.findIndex(
				(item) => item.answer === answer
			);
			if (findIndex !== -1) {
				const remove = selectedData.filter(
					(item) => item.answer !== answer
				);
				setSelectedData(remove);
			}
		}
	};
	const setBg = () => {
		const randomColor = Math.floor(Math.random() * 16777215).toString(16);
		return `#${randomColor}`;
	};
	const setImageSelectHandler = (question, url) => {
		if (question) {
			const find =
				questions &&
				questions[currentQuestion]?.options.find(
					(item) => item.question === question
				);
			if (find) {
				if (url) {
					const clone = [...selectedImgData];
					const findIndex = clone.findIndex(
						(item) => item.question === question
					);
					if (findIndex !== -1) {
						clone[findIndex].img = url;
						setSelectedImgData(clone);
						setSelectedUrl("");
					} else {
						const data = {
							question: question,
							img: selectedUrl,
						};
						setSelectedImgData((pre) => [...pre, data]);
						setSelectedUrl("");
					}
				}
			}
		}
	};
	const imageHandler = (question) => {
		const remove = selectedImgData.filter(
			(item) => item.question !== question
		);
		setSelectedImgData(remove);
	};
	const imageSelectHandler = (url) => {
		setSelectedUrl(url);
	};
	const answerHandler = (question) => {
		const remove = selectedAnswerData.filter(
			(item) => item.question !== question
		);
		setSelectedAnswerData(remove);
	};
	const setAnswerSelectHandler = (question, answer) => {
		if (question) {
			const find =
				questions &&
				questions[currentQuestion]?.options?.find(
					(item) => item.question === question
				);
			if (find) {
				if (answer) {
					const clone = [...selectedAnswerData];
					const findIndex = clone.findIndex(
						(item) => item.question === question
					);
					if (findIndex !== -1) {
						clone[findIndex].answer = answer;
						setSelectedAnswerData(clone);
						setSelectedAnswer("");
					} else {
						const data = {
							question: question,
							answer: selectedAnswer,
						};
						setSelectedAnswerData((pre) => [...pre, data]);
						setSelectedAnswer("");
					}
				}
			}
		}
	};
	const answerSelectHandler = (answer) => {
		setSelectedAnswer(answer);
	};

	const fetchSubTopics = () => {
		const TOKEN = sessionStorage.getItem("token");
		// console.log(TOKEN);

		const BASE_URL = process.env.REACT_APP_SWAGGER_URL;
		const id = questions[currentQuestion]?.id;
		axios
			.get(
				`${BASE_URL}v1/api/content/question/${id}/sub-questions?showCorrectAnswer=true`,
				{
					headers: {
						Authorization: `Bearer ${TOKEN}`,
					},
				}
			)
			.then((response) => {
				setSubquestions(response.data.data);
			});
	};
	useEffect(() => {
		fetchSubTopics();
	}, [questions, questions[currentQuestion]]);

	return (
		<Modal
			open={open}
			onClose={() => {
				close();
				setCurrentQuestion(0);
			}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			key={data?.id}
		>
			{/* ------------------------------CASE STUDYS------------------------------ */}
			<Box sx={style}>
				{questions && questions[currentQuestion]?.hasSubQuestions ? (
					// <Paper style={{ padding: 20 }}>
					// 	<div
					// 		style={{
					// 			display: "flex",
					// 			justifyContent: "space-between",
					// 			alignItems: "center",
					// 		}}
					// 	>
					// 		<div>
					// 			<h2 style={{ fontSize: 20 }}>{data?.name}</h2>
					// 			<h2 style={{ fontSize: 20 }}>Case study </h2>
					// 			<h2 style={{ fontSize: 18 }}>
					// 				{currentQuestion + 1}.{" "}
					// 				{questions[currentQuestion]?.question}
					// 			</h2>
					// 		</div>

					// 		<p style={{ fontSize: 20, color: "#093EB5" }}>
					// 			Total Questions : {currentQuestion + 1} of{" "}
					// 			{questions.length}
					// 		</p>
					// 	</div>
					// 	<div
					// 		style={{
					// 			margin: "20px 0 20px 0",
					// 			maxHeight: "50vh",
					// 			overflow: "scroll",
					// 		}}
					// 	>
					// 		{subquestions?.map((subQuestion, index) => (
					// 			<div key={data.id}>
					// 				{subQuestion?.questionTypeCode !==
					// 				"MATCH" ? (
					// 					<div
					// 						style={{
					// 							width: "100%",
					// 							display: "flex",
					// 							flexDirection: "row",
					// 							padding: "20px",
					// 							borderRadius: "10px",
					// 						}}
					// 					>
					// 						<div
					// 							style={{
					// 								width: "45%",
					// 								display: "flex",
					// 								flexDirection: "column",
					// 								rowGap: "0.7rem",
					// 								overflow: "hidden",
					// 							}}
					// 						>
					// 							<div
					// 								style={{
					// 									display: "flex",
					// 									flexDirection: "column",
					// 									rowGap: "10px",
					// 									fontSize: 18,
					// 									overflow: "auto",
					// 								}}
					// 							>
					// 								<div
					// 									style={{
					// 										fontWeight: 600,
					// 									}}
					// 								>{` ${subQuestion?.questionType}`}</div>
					// 								<div
					// 									style={{
					// 										paddingLeft: "10px",
					// 										fontSize: 17,
					// 									}}
					// 								>
					// 									{`${index + 1} .${
					// 										subQuestion.question
					// 									}`}
					// 								</div>
					// 								{subQuestion?.imageUrl && (
					// 									<div
					// 										style={{
					// 											fontWeight: 600,
					// 											fontSize: 18,
					// 										}}
					// 									>
					// 										<img
					// 											src={
					// 												subQuestion?.imageUrl
					// 											}
					// 											alt={""}
					// 											width={200}
					// 										/>
					// 									</div>
					// 								)}
					// 							</div>
					// 						</div>
					// 						<div
					// 							style={{
					// 								width: "55%",
					// 								padding: "0px 20px",

					// 								display: "flex",
					// 								flexDirection: "column",
					// 								justifyContent:
					// 									"space-between",
					// 							}}
					// 						>
					// 							<div
					// 								style={{
					// 									display: "flex",
					// 									flexDirection: "column",
					// 									rowGap: "1rem",
					// 								}}
					// 							>
					// 								<div
					// 									style={{
					// 										display: "flex",
					// 										flexDirection:
					// 											"column",
					// 										rowGap: "1rem",
					// 										overflow: "auto",
					// 										padding: "10px",
					// 									}}
					// 								>
					// 									<div
					// 										style={{
					// 											display: "flex",
					// 											flexDirection:
					// 												"column",
					// 											rowGap: "1rem",
					// 										}}
					// 									>
					// 										<div
					// 											style={{
					// 												color: "black",
					// 											}}
					// 										>
					// 											Answer
					// 										</div>

					// 										<FormControl
					// 											sx={{
					// 												paddingLeft:
					// 													"10px",
					// 											}}
					// 											key={
					// 												subQuestion?.id
					// 											}
					// 										>
					// 											<RadioGroup
					// 												aria-labelledby="demo-controlled-radio-buttons-group"
					// 												name="controlled-radio-buttons-group"
					// 												sx={{
					// 													display:
					// 														"flex",
					// 													flexDirection:
					// 														"column",
					// 													rowGap: "1rem",
					// 												}}
					// 												// onChange={handleChange}
					// 												defaultValue="female"
					// 											>
					// 												{subQuestion
					// 													? subQuestion?.questionTypeCode ===
					// 													  "MSQ"
					// 														? subQuestion?.options[0]?.map(
					// 																(
					// 																	data,
					// 																	index
					// 																) => {
					// 																	return (
					// 																		<FormControlLabel
					// 																			value={
					// 																				data.option
					// 																			}
					// 																			control={
					// 																				<Checkbox
					// 																					onChange={(
					// 																						e
					// 																					) =>
					// 																						MSQHandler(
					// 																							e
					// 																						)
					// 																					}
					// 																					checked={
					// 																						MSQList.indexOf(
					// 																							data.option
					// 																						) >
					// 																						-1
					// 																					}
					// 																				/>
					// 																			}
					// 																			label={
					// 																				data.option
					// 																			}
					// 																			sx={{
					// 																				border: "1px solid #827d7d",
					// 																				borderRadius:
					// 																					"4px",
					// 																			}}
					// 																			key={
					// 																				index
					// 																			}
					// 																			// onChange={MSQHandler}
					// 																		/>
					// 																	);
					// 																}
					// 														  )
					// 														: subQuestion?.questionTypeCode ===
					// 														  "MCQ"
					// 														? subQuestion?.options[0]?.map(
					// 																(
					// 																	data,
					// 																	index
					// 																) => {
					// 																	return (
					// 																		<RadioGroup
					// 																			aria-labelledby="demo-controlled-radio-buttons-group"
					// 																			name="controlled-radio-buttons-group"
					// 																			sx={{
					// 																				display:
					// 																					"flex",
					// 																				flexDirection:
					// 																					"column",
					// 																				rowGap: "1rem",
					// 																			}}
					// 																			onChange={(
					// 																				e
					// 																			) =>
					// 																				handleChange(
					// 																					e
					// 																				)
					// 																			}
					// 																			value={
					// 																				MCQ
					// 																			}
					// 																			key={
					// 																				index
					// 																			}
					// 																		>
					// 																			<FormControlLabel
					// 																				value={
					// 																					data.option
					// 																				}
					// 																				control={
					// 																					<Radio />
					// 																				}
					// 																				label={
					// 																					data.option
					// 																				}
					// 																				sx={{
					// 																					border: "1px solid #827d7d",
					// 																					borderRadius:
					// 																						"4px",
					// 																				}}
					// 																				key={
					// 																					index
					// 																				}
					// 																			/>
					// 																		</RadioGroup>
					// 																	);
					// 																}
					// 														  )
					// 														: subQuestion?.questionTypeCode ===
					// 														  "FILL"
					// 														? subQuestion?.options?.map(
					// 																(
					// 																	data,
					// 																	index
					// 																) => {
					// 																	const findIndex =
					// 																		answerByOrder &&
					// 																		answerByOrder?.findIndex(
					// 																			(
					// 																				item
					// 																			) =>
					// 																				item ===
					// 																				data?.order
					// 																		);

					// 																	return (
					// 																		<div
					// 																			key={
					// 																				index
					// 																			}
					// 																			style={{
					// 																				display:
					// 																					"flex",
					// 																				flexDirection:
					// 																					"row",
					// 																				columnGap:
					// 																					"2rem",
					// 																				alignItems:
					// 																					"center",
					// 																			}}
					// 																		>
					// 																			{findIndex !==
					// 																				-1 && (
					// 																				<div
					// 																					style={{
					// 																						display:
					// 																							"flex",
					// 																						flexDirection:
					// 																							"row",
					// 																						columnGap:
					// 																							"5px",
					// 																					}}
					// 																				>
					// 																					<div>
					// 																						Answer
					// 																					</div>
					// 																					{findIndex +
					// 																						1}
					// 																				</div>
					// 																			)}
					// 																			<FormControlLabel
					// 																				value={
					// 																					data.order
					// 																				}
					// 																				control={
					// 																					<Checkbox />
					// 																				}
					// 																				label={
					// 																					data.answer
					// 																				}
					// 																				sx={{
					// 																					border: "1px solid #827d7d",
					// 																					borderRadius:
					// 																						"4px",
					// 																					width: "100%",
					// 																				}}
					// 																				key={
					// 																					data.order
					// 																				}
					// 																				onChange={(
					// 																					e
					// 																				) => {
					// 																					fillHandler(
					// 																						e
					// 																					);
					// 																				}}
					// 																			/>
					// 																		</div>
					// 																	);
					// 																}
					// 														  )
					// 														: questions &&
					// 														  questions[
					// 																currentQuestion
					// 														  ]
					// 																?.questionTypeCode ===
					// 																"MATCH"
					// 														? questions &&
					// 														  questions[
					// 																currentQuestion
					// 														  ]?.options?.map(
					// 																(
					// 																	matchItem,
					// 																	index
					// 																) => {
					// 																	const subOptions =
					// 																		questions[
					// 																			currentQuestion
					// 																		]?.options
					// 																			.map(
					// 																				(
					// 																					que
					// 																				) =>
					// 																					que.answer
					// 																			)
					// 																			.sort();
					// 																	return (
					// 																		<div
					// 																			key={
					// 																				index
					// 																			}
					// 																			style={{
					// 																				display:
					// 																					"flex",
					// 																				flexDirection:
					// 																					"row",
					// 																				columnGap:
					// 																					"1rem",
					// 																			}}
					// 																		>
					// 																			<FormControl
					// 																				key={
					// 																					index
					// 																				}
					// 																			>
					// 																				<FormLabel
					// 																					id="demo-customized-radios"
					// 																					sx={{
					// 																						fontWeight: 600,
					// 																						color: "black",
					// 																					}}
					// 																					key={
					// 																						index
					// 																					}
					// 																				>
					// 																					{
					// 																						matchItem.question
					// 																					}
					// 																				</FormLabel>
					// 																				<RadioGroup
					// 																					defaultValue="female"
					// 																					aria-labelledby="demo-customized-radios"
					// 																					name="customized-radios"
					// 																					row
					// 																					onChange={(
					// 																						e
					// 																					) =>
					// 																						matchRadioButtonHandler(
					// 																							e,
					// 																							matchItem.question
					// 																						)
					// 																					}
					// 																				>
					// 																					{subOptions?.map(
					// 																						(
					// 																							subSubItem,
					// 																							index
					// 																						) => {
					// 																							const find =
					// 																								matchData &&
					// 																								matchData?.filter(
					// 																									(
					// 																										item
					// 																									) =>
					// 																										item?.answer ===
					// 																										subSubItem
					// 																								);
					// 																							return (
					// 																								<FormControlLabel
					// 																									value={
					// 																										subSubItem
					// 																									}
					// 																									control={
					// 																										<Radio />
					// 																									}
					// 																									label={
					// 																										subSubItem
					// 																									}
					// 																									key={
					// 																										subSubItem
					// 																									}
					// 																									disabled={
					// 																										!!find?.length
					// 																									}
					// 																								/>
					// 																							);
					// 																						}
					// 																					)}
					// 																				</RadioGroup>
					// 																			</FormControl>
					// 																		</div>
					// 																	);
					// 																}
					// 														  )
					// 														: ""
					// 													: questions &&
					// 													  questions[
					// 															currentQuestion
					// 													  ]?.subQuestions?.map(
					// 															(
					// 																subItem,
					// 																index
					// 															) => {
					// 																return (
					// 																	<div
					// 																		key={
					// 																			index
					// 																		}
					// 																		style={{
					// 																			display:
					// 																				"flex",
					// 																			flexDirection:
					// 																				"column",
					// 																			rowGap: "1rem",
					// 																		}}
					// 																	>
					// 																		<div
					// 																			style={{
					// 																				fontSize: 17,
					// 																				display:
					// 																					"flex",
					// 																				flexDirection:
					// 																					"row",
					// 																				columnGap:
					// 																					"10px",
					// 																				justifyContent:
					// 																					"space-between",
					// 																			}}
					// 																		>
					// 																			{`Q${
					// 																				index +
					// 																				1
					// 																			} ) ${
					// 																				subItem.question
					// 																			}`}
					// 																			{subItem?.imageUrl && (
					// 																				<img
					// 																					src={
					// 																						subItem?.imageUrl
					// 																					}
					// 																					width={
					// 																						110
					// 																					}
					// 																					alt={
					// 																						""
					// 																					}
					// 																				/>
					// 																			)}
					// 																		</div>
					// 																		<div
					// 																			style={{
					// 																				color: "black",
					// 																			}}
					// 																		>
					// 																			Answer
					// 																		</div>
					// 																		<FormControl
					// 																			sx={{
					// 																				paddingLeft:
					// 																					"10px",
					// 																			}}
					// 																			key={
					// 																				index
					// 																			}
					// 																		>
					// 																			<RadioGroup
					// 																				aria-labelledby="demo-controlled-radio-buttons-group"
					// 																				name="controlled-radio-buttons-group"
					// 																				sx={{
					// 																					display:
					// 																						"flex",
					// 																					flexDirection:
					// 																						"column",
					// 																					rowGap: "1rem",
					// 																				}}
					// 																			>
					// 																				{subItem.options.map(
					// 																					(
					// 																						subOption,
					// 																						index
					// 																					) => {
					// 																						const findIndex =
					// 																							subMCQ?.findIndex(
					// 																								(
					// 																									sub
					// 																								) =>
					// 																									sub?.subQuestionId ===
					// 																									subItem?.id
					// 																							);
					// 																						return (
					// 																							<FormControlLabel
					// 																								value={
					// 																									subOption.option
					// 																								}
					// 																								control={
					// 																									<Radio
					// 																										checked={
					// 																											subMCQ[
					// 																												findIndex
					// 																											]
					// 																												?.subAnswer ===
					// 																											subOption.option
					// 																										}
					// 																										onChange={(
					// 																											e
					// 																										) =>
					// 																											subMCQHandler(
					// 																												e,
					// 																												subItem
					// 																											)
					// 																										}
					// 																									/>
					// 																								}
					// 																								label={
					// 																									subOption.option
					// 																								}
					// 																								sx={{
					// 																									border: "1px solid #827d7d",
					// 																									borderRadius:
					// 																										"4px",
					// 																								}}
					// 																								key={
					// 																									index
					// 																								}
					// 																							/>
					// 																						);
					// 																					}
					// 																				)}
					// 																			</RadioGroup>
					// 																		</FormControl>
					// 																	</div>
					// 																);
					// 															}
					// 													  )}
					// 											</RadioGroup>
					// 										</FormControl>
					// 									</div>
					// 								</div>
					// 							</div>
					// 						</div>
					// 					</div>
					// 				) : subQuestion?.questionTypeCode ===
					// 				  "MATCH" ? (
					// 					<div>
					// 						<div
					// 							style={{
					// 								display: "flex",
					// 								flexDirection: "column",
					// 								rowGap: "5px",
					// 							}}
					// 						>
					// 							<div
					// 								style={{
					// 									paddingLeft: "10px",
					// 									fontSize: 17,
					// 								}}
					// 							>
					// 								{`${currentQuestion + 1} .${
					// 									subQuestion?.question
					// 								}`}
					// 							</div>
					// 						</div>

					// 						<div
					// 							style={{
					// 								display: "flex",
					// 								flexDirection: "column",
					// 								justifyContent:
					// 									"space-between",
					// 								height: "100%",
					// 								marginTop: "10px",
					// 							}}
					// 						>
					// 							<div
					// 								style={{
					// 									width: "100%",
					// 									display: "flex",
					// 									flexDirection: "row",
					// 									columnGap: "10px",
					// 								}}
					// 							>
					// 								<div
					// 									style={{
					// 										width: "65%",
					// 										display: "flex",
					// 										flexDirection:
					// 											"column",
					// 										rowGap: "1rem",
					// 										height: "100%",
					// 										overflow: "auto",
					// 									}}
					// 								>
					// 									{subQuestion?.options?.map(
					// 										(item, index) => {
					// 											const find =
					// 												selectedAnswerData?.find(
					// 													(
					// 														data
					// 													) =>
					// 														data.question ===
					// 														item.question
					// 												);
					// 											return (
					// 												<div
					// 													style={{
					// 														display:
					// 															"flex",
					// 														flexDirection:
					// 															"row",
					// 														alignItems:
					// 															"center",
					// 													}}
					// 													key={
					// 														item.question
					// 													}
					// 												>
					// 													<div
					// 														style={{
					// 															width: "100%",
					// 															padding:
					// 																"10px",
					// 															border: "1px solid black",
					// 															borderStyle:
					// 																"solid",
					// 															borderRadius:
					// 																"5px",
					// 															cursor: "pointer",
					// 														}}
					// 													>
					// 														{
					// 															item.question
					// 														}
					// 													</div>
					// 													<div
					// 														style={{
					// 															width: "20%",
					// 															border: "3px dashed #6f6f6f",
					// 															borderStyle:
					// 																"dashed",
					// 															borderBottom: 0,
					// 														}}
					// 													/>
					// 													{find ? (
					// 														<div
					// 															style={{
					// 																width: "100%",
					// 																borderStyle:
					// 																	"dashed",
					// 																border: "3px dashed #6f6f6f",
					// 																padding:
					// 																	"10px",
					// 																borderRadius:
					// 																	"5px",
					// 																cursor: "pointer",
					// 															}}
					// 															onClick={() =>
					// 																answerHandler(
					// 																	item.question
					// 																)
					// 															}
					// 														>
					// 															{
					// 																find?.answer
					// 															}
					// 														</div>
					// 													) : (
					// 														<div
					// 															style={{
					// 																width: "100%",
					// 																borderStyle:
					// 																	"dashed",
					// 																border: "3px dashed #6f6f6f",
					// 																padding:
					// 																	"10px",
					// 																borderRadius:
					// 																	"5px",
					// 																cursor: "pointer",
					// 															}}
					// 															onClick={() =>
					// 																setAnswerSelectHandler(
					// 																	item.question,
					// 																	selectedAnswer
					// 																)
					// 															}
					// 														>
					// 															Drag
					// 															&
					// 															Drop
					// 															Here
					// 														</div>
					// 													)}
					// 												</div>
					// 											);
					// 										}
					// 									)}
					// 								</div>
					// 								<div
					// 									style={{
					// 										width: "35%",
					// 										display: "flex",
					// 										flexDirection:
					// 											"column",
					// 										rowGap: "1rem",
					// 										height: "100%",
					// 										overflow: "auto",
					// 									}}
					// 								>
					// 									{subQuestion?.options?.map(
					// 										(item) => {
					// 											const find =
					// 												selectedAnswerData.find(
					// 													(
					// 														data
					// 													) =>
					// 														data.answer ===
					// 														item.answer
					// 												);
					// 											return (
					// 												<div
					// 													style={{
					// 														width: "100%",
					// 														padding:
					// 															"12px",
					// 														border: "1px solid black",
					// 														borderStyle:
					// 															"solid",
					// 														borderRadius:
					// 															"5px",
					// 														cursor:
					// 															find?.answer ===
					// 															item?.answer
					// 																? "not-allowed"
					// 																: "pointer",
					// 													}}
					// 													key={
					// 														item.answer
					// 													}
					// 													onClick={() =>
					// 														find?.answer !==
					// 															item?.answer &&
					// 														answerSelectHandler(
					// 															item?.answer
					// 														)
					// 													}
					// 												>
					// 													{
					// 														item.answer
					// 													}
					// 												</div>
					// 											);
					// 										}
					// 									)}
					// 								</div>
					// 							</div>
					// 						</div>
					// 					</div>
					// 				) : (
					// 					""
					// 				)}
					// 			</div>
					// 		))}{" "}
					// 	</div>
					// 	<div
					// 		style={{
					// 			display: "flex",
					// 			alignItems: "center",
					// 			justifyContent: "flex-end",
					// 			width: "100%",
					// 			flexDirection: "row",
					// 			columnGap: "1rem",
					// 		}}
					// 	>
					// 		<ExitButton
					// 			onClick={() => {
					// 				close();
					// 				setCurrentQuestion(0);
					// 			}}
					// 		>
					// 			Exit
					// 		</ExitButton>
					// 		<PreviewButton
					// 			onClick={preQuestionHandler}
					// 			disabled={currentQuestion + 1 === 1}
					// 		>
					// 			<KeyboardArrowLeftIcon
					// 				style={{ fontSize: "25px" }}
					// 			/>
					// 			Previous Question
					// 		</PreviewButton>
					// 		{currentQuestion + 1 ===
					// 		data?.quizQuestions?.length ? (
					// 			<NextButton onClick={onSubmitClick}>
					// 				Submit
					// 			</NextButton>
					// 		) : (
					// 			<NextButton
					// 				onClick={nextQuestionHandler}
					// 				disabled={
					// 					currentQuestion + 1 ===
					// 					data?.quizQuestions?.length
					// 				}
					// 			>
					// 				Next Question
					// 				<KeyboardArrowRightIcon
					// 					style={{ fontSize: "25px" }}
					// 				/>
					// 			</NextButton>
					// 		)}
					// 	</div>
					// </Paper>
					<CaseStudyPreviewModal
						currentQuestion={currentQuestion}
						questions={questions}
						data={data}
						subquestions={subquestions}
						MSQHandler={MSQHandler}
						MSQList={MSQList}
						handleChange={handleChange}
						answerByOrder={answerByOrder}
						MCQ={MCQ}
						fillHandler={fillHandler}
						matchRadioButtonHandler={matchRadioButtonHandler}
						matchData={matchData}
						subMCQ={subMCQ}
						subMCQHandler={subMCQHandler}
						selectedAnswerData={selectedAnswerData}
						answerHandler={answerHandler}
						close={close}
						setAnswerSelectHandler={setAnswerSelectHandler}
						selectedAnswer={selectedAnswer}
						answerSelectHandler={answerSelectHandler}
						setCurrentQuestion={setCurrentQuestion}
						preQuestionHandler={preQuestionHandler}
						onSubmitClick={nextQuestionHandler}
						nextQuestionHandler={nextQuestionHandler}
					/>
				) : questions &&
				  questions[currentQuestion]?.questionTypeCode !== "MATCH" ? (
					<Paper
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							padding: "20px",
							borderRadius: "10px",
						}}
					>
						<div
							style={{
								width: "45%",
								height: "600px",
								display: "flex",
								flexDirection: "column",
								rowGap: "0.7rem",
								overflow: "hidden",
							}}
						>
							<div
								style={{
									fontWeight: 600,
									color: "rgba(5,175,116,0.89)",
									fontSize: 21,
								}}
							>
								{data?.name}
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									rowGap: "10px",
									fontSize: 18,
									overflow: "auto",
									minHeight: 400,
								}}
							>
								<div style={{ fontWeight: 600 }}>{` ${
									questions &&
									questions[currentQuestion]?.questionType
								}`}</div>
								<div
									style={{
										paddingLeft: "10px",
										fontSize: 17,
									}}
								>
									{`${currentQuestion + 1} .${
										questions &&
										questions[currentQuestion]?.question
									}`}
								</div>
								{questions &&
									questions[currentQuestion]?.imageUrl && (
										<div
											style={{
												fontWeight: 600,
												fontSize: 18,
											}}
										>
											<img
												src={
													questions &&
													questions[currentQuestion]
														?.imageUrl
												}
												alt={""}
												width={200}
											/>
										</div>
									)}
							</div>
						</div>
						<div
							style={{
								width: "55%",
								padding: "0px 20px",
								minHeight: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									rowGap: "1rem",
								}}
							>
								<LinearProgressWithLabel
									value={currentQuestion + 1}
									name={"Quiz"}
									totalQuestions={data?.quizQuestions?.length}
								/>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										rowGap: "1rem",
										overflow: "auto",
										height: "400px",
										padding: "10px",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: "1rem",
										}}
									>
										{questions &&
											!questions[currentQuestion]
												?.hasSubQuestions && (
												<div style={{ color: "black" }}>
													Answer
												</div>
											)}
										<FormControl
											sx={{ paddingLeft: "10px" }}
											key={
												questions &&
												!questions[currentQuestion]?.id
											}
										>
											<RadioGroup
												aria-labelledby="demo-controlled-radio-buttons-group"
												name="controlled-radio-buttons-group"
												sx={{
													display: "flex",
													flexDirection: "column",
													rowGap: "1rem",
												}}
												// onChange={handleChange}
												defaultValue="female"
											>
												{questions &&
												!questions[currentQuestion]
													?.hasSubQuestions
													? questions[currentQuestion]
															?.questionTypeCode ===
													  "MSQ"
														? questions &&
														  questions[
																currentQuestion
														  ]?.options[0]?.map(
																(
																	data,
																	index
																) => {
																	return (
																		<FormControlLabel
																			value={
																				data.option
																			}
																			control={
																				<Checkbox
																					onChange={(
																						e
																					) =>
																						MSQHandler(
																							e
																						)
																					}
																					checked={
																						MSQList.indexOf(
																							data.option
																						) >
																						-1
																					}
																				/>
																			}
																			label={
																				data.option
																			}
																			sx={{
																				border: "1px solid #827d7d",
																				borderRadius:
																					"4px",
																			}}
																			key={
																				index
																			}
																			// onChange={MSQHandler}
																		/>
																	);
																}
														  )
														: questions &&
														  questions[
																currentQuestion
														  ]
																?.questionTypeCode ===
																"MCQ"
														? questions &&
														  questions[
																currentQuestion
														  ]?.options?.map(
																(
																	data,
																	index
																) => {
																	return (
																		<RadioGroup
																			aria-labelledby="demo-controlled-radio-buttons-group"
																			name="controlled-radio-buttons-group"
																			sx={{
																				display:
																					"flex",
																				flexDirection:
																					"column",
																				rowGap: "1rem",
																			}}
																			onChange={(
																				e
																			) =>
																				handleChange(
																					e
																				)
																			}
																			value={
																				MCQ
																			}
																			key={
																				index
																			}
																		>
																			<FormControlLabel
																				value={
																					data.option
																				}
																				control={
																					<Radio />
																				}
																				label={
																					data.option
																				}
																				sx={{
																					border: "1px solid #827d7d",
																					borderRadius:
																						"4px",
																				}}
																				key={
																					index
																				}
																			/>
																		</RadioGroup>
																	);
																}
														  )
														: questions &&
														  questions[
																currentQuestion
														  ]
																?.questionTypeCode ===
																"FILL"
														? questions &&
														  questions[
																currentQuestion
														  ]?.options[0]?.map(
																(
																	data,
																	index
																) => {
																	const findIndex =
																		answerByOrder &&
																		answerByOrder?.findIndex(
																			(
																				item
																			) =>
																				item ===
																				data?.order
																		);
																	return (
																		<div
																			key={
																				index
																			}
																			style={{
																				display:
																					"flex",
																				flexDirection:
																					"row",
																				columnGap:
																					"2rem",
																				alignItems:
																					"center",
																			}}
																		>
																			{findIndex !==
																				-1 && (
																				<div
																					style={{
																						display:
																							"flex",
																						flexDirection:
																							"row",
																						columnGap:
																							"5px",
																					}}
																				>
																					<div>
																						Answer
																					</div>
																					{findIndex +
																						1}
																				</div>
																			)}
																			<FormControlLabel
																				value={
																					data.order
																				}
																				control={
																					<TextField
																						disabled
																						value={
																							data
																								.answerKey[0]
																						}
																						sx={{
																							border: "none",
																							width: "100%",
																							outline:
																								"none",
																						}}
																					/>
																				}
																				// label={
																				// 	data
																				// 		.answerKey[0]
																				// }
																				sx={{
																					// border: "1px solid #827d7d",
																					borderRadius:
																						"4px",
																					width: "100%",
																				}}
																				key={
																					data.order
																				}
																				onChange={(
																					e
																				) => {
																					fillHandler(
																						e
																					);
																				}}
																			/>
																		</div>
																	);
																}
														  )
														: questions &&
														  questions[
																currentQuestion
														  ]
																?.questionTypeCode ===
																"MATCH"
														? questions &&
														  questions[
																currentQuestion
														  ]?.options?.map(
																(
																	matchItem,
																	index
																) => {
																	const subOptions =
																		questions[
																			currentQuestion
																		]?.options
																			.map(
																				(
																					que
																				) =>
																					que.answer
																			)
																			.sort();
																	return (
																		<div
																			key={
																				index
																			}
																			style={{
																				display:
																					"flex",
																				flexDirection:
																					"row",
																				columnGap:
																					"1rem",
																			}}
																		>
																			<FormControl
																				key={
																					index
																				}
																			>
																				<FormLabel
																					id="demo-customized-radios"
																					sx={{
																						fontWeight: 600,
																						color: "black",
																					}}
																					key={
																						index
																					}
																				>
																					{
																						matchItem.question
																					}
																				</FormLabel>
																				<RadioGroup
																					defaultValue="female"
																					aria-labelledby="demo-customized-radios"
																					name="customized-radios"
																					row
																					onChange={(
																						e
																					) =>
																						matchRadioButtonHandler(
																							e,
																							matchItem.question
																						)
																					}
																				>
																					{subOptions?.map(
																						(
																							subSubItem,
																							index
																						) => {
																							const find =
																								matchData &&
																								matchData?.filter(
																									(
																										item
																									) =>
																										item?.answer ===
																										subSubItem
																								);
																							return (
																								<FormControlLabel
																									value={
																										subSubItem
																									}
																									control={
																										<Radio />
																									}
																									label={
																										subSubItem
																									}
																									key={
																										subSubItem
																									}
																									disabled={
																										!!find?.length
																									}
																								/>
																							);
																						}
																					)}
																				</RadioGroup>
																			</FormControl>
																		</div>
																	);
																}
														  )
														: ""
													: questions &&
													  questions[
															currentQuestion
													  ]?.subQuestions?.map(
															(
																subItem,
																index
															) => {
																return (
																	<div
																		key={
																			index
																		}
																		style={{
																			display:
																				"flex",
																			flexDirection:
																				"column",
																			rowGap: "1rem",
																		}}
																	>
																		<div
																			style={{
																				fontSize: 17,
																				display:
																					"flex",
																				flexDirection:
																					"row",
																				columnGap:
																					"10px",
																				justifyContent:
																					"space-between",
																			}}
																		>
																			{`Q${
																				index +
																				1
																			} ) ${
																				subItem.question
																			}`}
																			{subItem?.imageUrl && (
																				<img
																					src={
																						subItem?.imageUrl
																					}
																					width={
																						110
																					}
																					alt={
																						""
																					}
																				/>
																			)}
																		</div>
																		<div
																			style={{
																				color: "black",
																			}}
																		>
																			Answer
																		</div>
																		<FormControl
																			sx={{
																				paddingLeft:
																					"10px",
																			}}
																			key={
																				index
																			}
																		>
																			<RadioGroup
																				aria-labelledby="demo-controlled-radio-buttons-group"
																				name="controlled-radio-buttons-group"
																				sx={{
																					display:
																						"flex",
																					flexDirection:
																						"column",
																					rowGap: "1rem",
																				}}
																			>
																				{subItem.options.map(
																					(
																						subOption,
																						index
																					) => {
																						const findIndex =
																							subMCQ?.findIndex(
																								(
																									sub
																								) =>
																									sub?.subQuestionId ===
																									subItem?.id
																							);
																						return (
																							<FormControlLabel
																								value={
																									subOption.option
																								}
																								control={
																									<Radio
																										checked={
																											subMCQ[
																												findIndex
																											]
																												?.subAnswer ===
																											subOption.option
																										}
																										onChange={(
																											e
																										) =>
																											subMCQHandler(
																												e,
																												subItem
																											)
																										}
																									/>
																								}
																								label={
																									subOption.option
																								}
																								sx={{
																									border: "1px solid #827d7d",
																									borderRadius:
																										"4px",
																								}}
																								key={
																									index
																								}
																							/>
																						);
																					}
																				)}
																			</RadioGroup>
																		</FormControl>
																	</div>
																);
															}
													  )}
											</RadioGroup>
										</FormControl>
									</div>
								</div>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end",
									width: "100%",
									flexDirection: "row",
									columnGap: "1rem",
								}}
							>
								<ExitButton
									onClick={() => {
										close();
										setCurrentQuestion(0);
									}}
								>
									Exit
								</ExitButton>
								<PreviewButton
									onClick={preQuestionHandler}
									disabled={currentQuestion + 1 === 1}
								>
									<KeyboardArrowLeftIcon
										style={{ fontSize: "25px" }}
									/>
									Previous Question
								</PreviewButton>
								{currentQuestion + 1 ===
								data?.quizQuestions?.length ? (
									<NextButton onClick={onSubmitClick}>
										Submit
									</NextButton>
								) : (
									<NextButton
										onClick={nextQuestionHandler}
										disabled={
											currentQuestion + 1 ===
											data?.quizQuestions?.length
										}
									>
										Next Question
										<KeyboardArrowRightIcon
											style={{ fontSize: "25px" }}
										/>
									</NextButton>
								)}
							</div>
						</div>
					</Paper>
				) : (
					<Paper
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
							padding: "20px",
							borderRadius: "10px",
							rowGap: "10px",
							height: "640px",
						}}
					>
						<div
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
								columnGap: "1rem",
								alignItems: "center",
							}}
						>
							<div
								style={{
									fontWeight: 600,
									color: "rgba(5,175,116,0.89)",
									fontSize: 21,
									width: "100%",
								}}
							>
								{data?.name}
							</div>
							<LinearProgressWithLabel
								value={currentQuestion + 1}
								name={"Quiz"}
								totalQuestions={data?.quizQuestions?.length}
							/>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								rowGap: "5px",
							}}
						>
							<div style={{ fontWeight: 600 }}>{` ${
								questions &&
								questions[currentQuestion]?.questionType
							}`}</div>
							<div style={{ paddingLeft: "10px", fontSize: 17 }}>
								{`${currentQuestion + 1} .${
									questions &&
									questions[currentQuestion]?.question
								}`}
							</div>
						</div>
						{/*<!-- impotent don't delete this --!>*/}

						{/*<div*/}
						{/*  style={{*/}
						{/*    display: "flex",*/}
						{/*    flexDirection: "row",*/}
						{/*    justifyContent: "space-between",*/}
						{/*    columnGap: "1rem",*/}
						{/*  }}*/}
						{/*>*/}
						{/*  <div*/}
						{/*    style={{*/}
						{/*      display: "flex",*/}
						{/*      flexDirection: "row",*/}
						{/*      justifyContent: "space-between",*/}
						{/*      width: "100%",*/}
						{/*      background: "white",*/}
						{/*      padding: "20px",*/}
						{/*      columnGap: "2rem",*/}
						{/*    }}*/}
						{/*  >*/}
						{/*    <div*/}
						{/*      style={{*/}
						{/*        display: "flex",*/}
						{/*        flexDirection: "column",*/}
						{/*        rowGap: "1rem",*/}
						{/*        width: "100%",*/}
						{/*      }}*/}
						{/*    >*/}
						{/*      {questions &&*/}
						{/*        questions[currentQuestion]?.options?.map((item, index) => {*/}
						{/*          const find = selectedData?.find(*/}
						{/*            (data) => data?.question === item?.question*/}
						{/*          );*/}
						{/*          return (*/}
						{/*            <div*/}
						{/*              style={{*/}
						{/*                width: "100%",*/}
						{/*                padding: "10px",*/}
						{/*                border: "1px solid black",*/}
						{/*                borderStyle: "solid",*/}
						{/*                borderRadius: "5px",*/}
						{/*                cursor: "pointer",*/}
						{/*                background:*/}
						{/*                  selectedQuestion.question === item?.question*/}
						{/*                    ? selectedQuestion.color*/}
						{/*                    : find?.color,*/}
						{/*                color: find?.color ? "white" : "black",*/}
						{/*              }}*/}
						{/*              key={index}*/}
						{/*              onClick={() => selectQuestionHandler(item.question)}*/}
						{/*            >*/}
						{/*              {item.question}*/}
						{/*            </div>*/}
						{/*          );*/}
						{/*        })}*/}
						{/*    </div>*/}
						{/*    <div*/}
						{/*      style={{*/}
						{/*        display: "flex",*/}
						{/*        flexDirection: "column",*/}
						{/*        rowGap: "1rem",*/}
						{/*        width: "100%",*/}
						{/*      }}*/}
						{/*    >*/}
						{/*      {questions &&*/}
						{/*        questions[currentQuestion]?.options?.map((item, index) => {*/}
						{/*          const find = selectedData?.find(*/}
						{/*            (data) => data?.answer === item?.answer*/}
						{/*          );*/}
						{/*          return (*/}
						{/*            <div*/}
						{/*              style={{*/}
						{/*                width: "100%",*/}
						{/*                padding: "10px",*/}
						{/*                border: "1px solid black",*/}
						{/*                borderStyle: "solid",*/}
						{/*                borderRadius: "5px",*/}
						{/*                cursor:*/}
						{/*                  find?.answer === item?.answer*/}
						{/*                    ? "not-allowed"*/}
						{/*                    : "pointer",*/}
						{/*                background: find?.color,*/}
						{/*                color: find?.color ? "white" : "black",*/}
						{/*              }}*/}
						{/*              key={index}*/}
						{/*              onClick={() =>*/}
						{/*                find?.answer !== item?.answer &&*/}
						{/*                selectedAnswerHandler(*/}
						{/*                  selectedQuestion,*/}
						{/*                  item?.answer*/}
						{/*                )*/}
						{/*              }*/}
						{/*            >*/}
						{/*              {item.answer}*/}
						{/*            </div>*/}
						{/*          );*/}
						{/*        })}*/}
						{/*    </div>*/}
						{/*  </div>*/}
						{/*  <div*/}
						{/*    style={{*/}
						{/*      width: "100%",*/}
						{/*      display: "flex",*/}
						{/*      flexDirection: "row",*/}
						{/*      columnGap: "10px",*/}
						{/*      // background: "white",*/}
						{/*      padding: "20px",*/}
						{/*    }}*/}
						{/*  >*/}
						{/*    <div*/}
						{/*      style={{*/}
						{/*        width: "65%",*/}
						{/*        display: "flex",*/}
						{/*        flexDirection: "column",*/}
						{/*        rowGap: "1rem",*/}
						{/*        height: "380px",*/}
						{/*        overflow: "auto",*/}
						{/*      }}*/}
						{/*    >*/}
						{/*      {questions &&*/}
						{/*        questions[currentQuestion]?.options?.map((item, index) => {*/}
						{/*          const find = selectedImgData?.find(*/}
						{/*            (data) => data.question === item.question*/}
						{/*          );*/}
						{/*          return (*/}
						{/*            <div*/}
						{/*              style={{*/}
						{/*                display: "flex",*/}
						{/*                flexDirection: "row",*/}
						{/*                alignItems: "center",*/}
						{/*              }}*/}
						{/*              key={index}*/}
						{/*            >*/}
						{/*              <div*/}
						{/*                style={{*/}
						{/*                  width: "100%",*/}
						{/*                  padding: "10px",*/}
						{/*                  border: "3px dashed #6f6f6f",*/}
						{/*                  borderStyle: "dashed",*/}
						{/*                  borderRadius: "5px",*/}
						{/*                  cursor: "pointer",*/}
						{/*                  color: "black",*/}
						{/*                  height: "200px",*/}
						{/*                  display: "flex",*/}
						{/*                  justifyContent: "center",*/}
						{/*                  alignItems: "center",*/}
						{/*                }}*/}
						{/*              >*/}
						{/*                {item.question}*/}
						{/*              </div>*/}
						{/*              <div*/}
						{/*                style={{*/}
						{/*                  width: "20%",*/}
						{/*                  border: "3px dashed #6f6f6f",*/}
						{/*                  borderStyle: "dashed",*/}
						{/*                  borderBottom: 0,*/}
						{/*                }}*/}
						{/*              />*/}
						{/*              {find?.img ? (*/}
						{/*                <div*/}
						{/*                  style={{*/}
						{/*                    width: "100%",*/}
						{/*                    borderStyle: "dashed",*/}
						{/*                    border: "3px dashed #6f6f6f",*/}
						{/*                    padding: "10px",*/}
						{/*                    borderRadius: "5px",*/}
						{/*                    cursor: "pointer",*/}
						{/*                    height: "200px",*/}
						{/*                    display: "flex",*/}
						{/*                    justifyContent: "center",*/}
						{/*                    alignItems: "center",*/}
						{/*                  }}*/}
						{/*                  onClick={() => imageHandler(item.question)}*/}
						{/*                >*/}
						{/*                  <img*/}
						{/*                    src={find?.img}*/}
						{/*                    alt={""}*/}
						{/*                    style={{ maxHeight: "190px" }}*/}
						{/*                  />*/}
						{/*                </div>*/}
						{/*              ) : (*/}
						{/*                <div*/}
						{/*                  style={{*/}
						{/*                    width: "100%",*/}
						{/*                    borderStyle: "dashed",*/}
						{/*                    border: "3px dashed #6f6f6f",*/}
						{/*                    padding: "10px",*/}
						{/*                    borderRadius: "5px",*/}
						{/*                    cursor: "pointer",*/}
						{/*                    height: "200px",*/}
						{/*                    display: "flex",*/}
						{/*                    justifyContent: "center",*/}
						{/*                    alignItems: "center",*/}
						{/*                  }}*/}
						{/*                  onClick={() =>*/}
						{/*                    setImageSelectHandler(*/}
						{/*                      item.question,*/}
						{/*                      selectedUrl*/}
						{/*                    )*/}
						{/*                  }*/}
						{/*                >*/}
						{/*                  Drag & Drop Here*/}
						{/*                </div>*/}
						{/*              )}*/}
						{/*            </div>*/}
						{/*          );*/}
						{/*        })}*/}
						{/*    </div>*/}
						{/*    <div*/}
						{/*      style={{*/}
						{/*        width: "35%",*/}
						{/*        display: "flex",*/}
						{/*        flexDirection: "column",*/}
						{/*        rowGap: "1rem",*/}
						{/*        height: "380px",*/}
						{/*        overflow: "auto",*/}
						{/*      }}*/}
						{/*    >*/}
						{/*      {questions &&*/}
						{/*        questions[currentQuestion]?.options?.map((item, index) => {*/}
						{/*          const find = selectedImgData.find(*/}
						{/*            (data) => data.img === item.img*/}
						{/*          );*/}
						{/*          return (*/}
						{/*            <div*/}
						{/*              style={{*/}
						{/*                width: "100%",*/}
						{/*                padding: "12px",*/}
						{/*                border: "3px dashed #6f6f6f",*/}
						{/*                borderStyle: "dashed",*/}
						{/*                borderRadius: "5px",*/}
						{/*                cursor:*/}
						{/*                  find?.img === item?.img*/}
						{/*                    ? "not-allowed"*/}
						{/*                    : "pointer",*/}
						{/*                height: "200px",*/}
						{/*                display: "flex",*/}
						{/*                justifyContent: "center",*/}
						{/*                alignItems: "center",*/}
						{/*                minHeight: "200px",*/}
						{/*              }}*/}
						{/*              key={index}*/}
						{/*              onClick={() =>*/}
						{/*                find?.img !== item?.img &&*/}
						{/*                imageSelectHandler(item?.img)*/}
						{/*              }*/}
						{/*            >*/}
						{/*              <img*/}
						{/*                src={item?.img}*/}
						{/*                alt={""}*/}
						{/*                style={{ maxHeight: "190px" }}*/}
						{/*              />*/}
						{/*            </div>*/}
						{/*          );*/}
						{/*        })}*/}
						{/*    </div>*/}
						{/*  </div>*/}
						{/*</div>*/}

						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								height: "100%",
							}}
						>
							<div
								style={{
									width: "100%",
									display: "flex",
									flexDirection: "row",
									columnGap: "10px",
								}}
							>
								<div
									style={{
										width: "65%",
										display: "flex",
										flexDirection: "column",
										rowGap: "1rem",
										height: "100%",
										overflow: "auto",
									}}
								>
									{questions &&
										questions[
											currentQuestion
										]?.options[0]?.map((item, index) => {
											const find =
												selectedAnswerData?.find(
													(data) =>
														data.question ===
														item.question
												);
											return (
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
													}}
													key={item.question}
												>
													<div
														style={{
															width: "100%",
															padding: "10px",
															border: "1px solid black",
															borderStyle:
																"solid",
															borderRadius: "5px",
															cursor: "pointer",
														}}
													>
														{item.question}
													</div>
													<div
														style={{
															width: "20%",
															border: "3px dashed #6f6f6f",
															borderStyle:
																"dashed",
															borderBottom: 0,
														}}
													/>
													{find ? (
														<div
															style={{
																width: "100%",
																borderStyle:
																	"dashed",
																border: "3px dashed #6f6f6f",
																padding: "10px",
																borderRadius:
																	"5px",
																cursor: "pointer",
															}}
															onClick={() =>
																answerHandler(
																	item.question
																)
															}
														>
															{find?.answer}
														</div>
													) : (
														<div
															style={{
																width: "100%",
																borderStyle:
																	"dashed",
																border: "3px dashed #6f6f6f",
																padding: "10px",
																borderRadius:
																	"5px",
																cursor: "pointer",
															}}
															onClick={() =>
																setAnswerSelectHandler(
																	item.question,
																	selectedAnswer
																)
															}
														>
															Drag & Drop Here
														</div>
													)}
												</div>
											);
										})}
								</div>
								<div
									style={{
										width: "35%",
										display: "flex",
										flexDirection: "column",
										rowGap: "1rem",
										height: "100%",
										overflow: "auto",
									}}
								>
									{questions &&
										questions[
											currentQuestion
										]?.options[0]?.map((item) => {
											const find =
												selectedAnswerData.find(
													(data) =>
														data.jumbledAnswer ===
														item.jumbledAnswer
												);

											console.log(
												selectedAnswerData,
												"findddddddd"
											);
											return (
												<div
													style={{
														width: "100%",
														padding: "12px",
														border: "1px solid black",
														borderStyle: "solid",
														borderRadius: "5px",
														cursor:
															find?.jumbledAnswer ===
															item?.jumbledAnswer
																? "not-allowed"
																: "pointer",
													}}
													key={item.jumbledAnswer}
													onClick={() =>
														find?.jumbledAnswer !==
															item?.jumbledAnswer &&
														answerSelectHandler(
															item?.jumbledAnswer
														)
													}
												>
													{item.jumbledAnswer}
												</div>
											);
										})}
								</div>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end",
									width: "100%",
									flexDirection: "row",
									columnGap: "1rem",
								}}
							>
								<ExitButton
									onClick={() => {
										close();
										setCurrentQuestion(0);
									}}
								>
									Exit
								</ExitButton>
								<PreviewButton
									onClick={preQuestionHandler}
									disabled={currentQuestion + 1 === 1}
								>
									<KeyboardArrowLeftIcon
										style={{ fontSize: "25px" }}
									/>
									Previous Question
								</PreviewButton>
								{currentQuestion + 1 ===
								data?.quizQuestions?.length ? (
									<NextButton onClick={onSubmitClick}>
										Submit
									</NextButton>
								) : (
									<NextButton
										onClick={nextQuestionHandler}
										disabled={
											currentQuestion + 1 ===
											data?.quizQuestions?.length
										}
									>
										Next Question
										<KeyboardArrowRightIcon
											style={{ fontSize: "25px" }}
										/>
									</NextButton>
								)}
							</div>
						</div>
					</Paper>
				)}
				<Paper
					style={{
						minHeight: "150px",
						borderRadius: "10px",
						padding: "20px",
						display: "flex",
						flexDirection: "row",
						columnGap: "1rem",
						width: "100%",
					}}
				>
					<div
						style={{
							fontSize: 20,
							fontWeight: 600,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							rowGap: "5px",
							width: "10%",
						}}
					>
						<span>Total</span>
						<span>Question</span>
						<span>{data.totalQuestions}</span>
					</div>
					<div
						style={{
							padding: "10px",
							display: "flex",
							flexDirection: "row",
							columnGap: "1rem",
							width: "90%",
						}}
					>
						<Box sx={{ flexGrow: 1 }}>
							<Grid container spacing={2}>
								{questions?.map((item, index) => {
									return (
										<Grid
											item
											xs={0.6}
											md={0.6}
											key={index}
										>
											<Item
												sx={{
													background:
														currentQuestion ===
															index ||
														currentQuestion >= index
															? ""
															: "#928d8d",
													cursor: "pointer",
												}}
												onClick={() =>
													currentQuestionHandler(
														index
													)
												}
											>
												{index + 1}
											</Item>
										</Grid>
									);
								})}
							</Grid>
						</Box>
					</div>
				</Paper>
			</Box>
		</Modal>
	);
};
export default CreateQuizPreviewModal;
