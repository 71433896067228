import React, { useState, useEffect } from "react";
import chapterStyles from '../../../css/createChapter.module.css';
import { blue, orange } from "@mui/material/colors";
import {
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Checkbox,
    styled,
    Grid,
    Typography
} from "@mui/material";
import { color } from "@mui/system";

const CustomInputLabel = styled(InputLabel)`
 & .Mui-focused {
            color: orange,
        }
}`;

const SelectBoardAndGrade = (props) => {

    const label = { inputProps: { "aria-label": "Checkbox demo" } };
    const labelStyle = {
        fontSize: "14px",
        root: {
            color: "orange",
          },
         " &.MuiInputLabel-shrink" :{
            color: "#ff9900" /* Set the desired color */
          },
        "&.Mui-focused": {
          color: "orange !important",
          fontWeight: 600,
          fontFamily: "Poppins",
          fontSize: "16px",
        },
      };
      

    return (
        <>
            <Grid item xs={3} md={3} lg={3}>
                <FormControl sx={{ width: "19vw" }}>
                    <InputLabel className={chapterStyles.select_input} id="demo-multiple-checkbox-label" sx={labelStyle}>
                        *Board
                    </InputLabel>
                    <Select
                        size="med"
                        labelId="demo-multiple-checkbox-label"
                        id="boardSelect"
                        value={props.boardValue}
                        onChange={
                            props.handleBoardChange
                        }
                        input={<OutlinedInput label="board" />}
                        disabled = {props.isDisabled ? true : false}
                    >
                        {props.boardsList ? (
                            props.boardsList.map((board) => (
                                <MenuItem
                                    key={board.id}
                                    value={board.id}
                                    id={`az-revisionadmin-boarddropdown-${board.id}`}
									
									sx={{
										"&.Mui-selected": {
											backgroundColor: 'orange',
											color: "#FFFFFF"
										},
										"&.Mui-selected:hover": {
											backgroundColor: '#fcedc2',
											color: "#000000"
										},
										"&:not(.Mui-selected):hover": {
											backgroundColor: '#fcedc2',
											color: '#000000',
										},
									}}
                                >{board.board}</MenuItem>
                            ))
                        )
                            : (
                                <p>Select Board</p>
                            )}
                    </Select>
                </FormControl>
                {!!props.isError['board'] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{props.isError['board']}</Typography>}
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
                <FormControl sx={{ width: "19vw"}}>
                    <InputLabel className={chapterStyles.select_input} id="demo-multiple-checkbox-label" sx={labelStyle}>
                        *Grade
                    </InputLabel>
                    <Select
                        size="med"
                        labelId="demo-multiple-checkbox-label"
                        id="gradeSelect"
                        multiple
                        value={props.gradeValue}
                        onChange={
                            props.handleGradeChange
                        }
                        input={<OutlinedInput label="grade" />}
                        disabled = {props?.isDisabled ? true : false}
                        renderValue={(selected) => selected
                            .map(
                                (obj) =>
                                    props.gradeList.find(
                                        (item) => item.id === obj
                                    ).grade
                            )
                            .join(",")}
                    >
                        {props.gradeList ? (
                            props.gradeList.map((grade) => {
                                // console.log(props.gradeValue.indexOf(grade.id) > -1)
                                return (
                                    <MenuItem
                                        key={grade.id}
                                        value={grade.id}
                                        id={`az-revisionadmin-gradedropdown-${grade.id}`}
									
									sx={{
										"&.Mui-selected": {
											 backgroundColor: 'white',
											color: "#000000"
										},
										"&.Mui-selected:hover": {
											backgroundColor: '#fcedc2',
											color: "#000000"
										},
										"&:not(.Mui-selected):hover": {
											backgroundColor: '#fcedc2',
											color: '#000000',
										},
									}}
                                    >
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <Checkbox
                                                {...label}
                                                sx={{
                                                    color: `#ffa500 !important`,
                                                    "&.Mui-checked": {
                                                        color: blue[900],
                                                    },
                                                }}
                                                checked={
                                                    // props.gradeValue.includes(grade.id)
                                                    props.gradeValue.indexOf(grade.id) > -1
                                                }
                                            />
                                            {grade.grade}
                                        </div>

                                    </MenuItem>
                                )
                            })
                        ) : (
                            <p>Select Grade</p>
                        )}
                    </Select>
                </FormControl>
                {!!props.isError['grade'] && <Typography sx={{ color: "orange", fontSize: '10px' }}>{props.isError['grade']}</Typography>}
            </Grid>
        </>
    )
}

export default SelectBoardAndGrade