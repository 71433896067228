import axios from 'axios'

function 






































































































































































chapters_controller_put(chapterInputs,subTopicId,chapName){
    let token = sessionStorage.getItem("token") ;
    var item = {
        // boardI : chapterInputs.boardId,
        board : chapterInputs.board,
        boardId : chapterInputs.boardId,

        grade: chapterInputs.grade,
        gradeId: chapterInputs.gradeId,

        subject : chapterInputs.subject,
        subjectId : chapterInputs.subjectId,

        chapter : chapName,
        chapterStatus : chapterInputs.chapterStatus,

        subTopicId : subTopicId ? subTopicId : null,
        docType : chapterInputs.docType,
        thumbnailPath: chapterInputs.thumbnailPath,
    } ;
    
    let result = axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/chapters/${sessionStorage.getItem("chapterId")}` , item ,
    {
        headers : {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
    })
    .catch(err => console.log(err))
}
export default chapters_controller_put;