import axios from "axios";

async function gradeListGet(setGrades) {
  let token = sessionStorage.getItem("token");

    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/all`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response)=>{
      if (response.data.data.length !== 0) {
        setGrades(response.data.data);
      }
    })
    .catch(err=>{
      console.log(err);
      setGrades(["unable to fetch sections"]);
    })
}

export default gradeListGet;
