import axios from 'axios'

function training_subtraining_table_post(trainingInputs){
    let token = sessionStorage.getItem("token");
    var item = {
        categoryId : trainingInputs.categoryId ,
        subCategoryId : trainingInputs.subCategoryId,
        reviewStatus : 'PENDING',
        name: trainingInputs.name,
        trainingType : "SELF"
    };

    const result = axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/training`, item ,
    {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          validateStatus() { return true } 
    })
    .then((res)=>{
    })
}

export default training_subtraining_table_post;