import React, { useState } from "react";
import { Modal, Fade, Box, IconButton, Button, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RefreshIcon from "@mui/icons-material/Refresh";

function Student_Image_Open_Modal({
  selectedImageUrl,
  modalOpen,
  setModalOpen,
}) {
  const [zoomLevel, setZoomLevel] = useState(100);
  const defaultZoomLevel = 100;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    // transform: "translate(-50%, -50%)",
    transform: `translate(-50%, -50%) scale(${zoomLevel / 100})`,
    transition: "transform 0.3s ease-in-out",
    width: "auto",
    height: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 1,
  };

  const handleZoomIn = () => {
    if (zoomLevel < 200) {
      setZoomLevel(zoomLevel + 5);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 30) {
      setZoomLevel(zoomLevel - 5);
    }
  };

  const handleRefresh = () => {
    setZoomLevel(defaultZoomLevel);
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              height: "100%",
            }}
          >
            <IconButton onClick={() => setModalOpen(false)}>
              <CloseIcon style={{ width: "20px", height: "20px", cursor: 'pointer' }} />
            </IconButton>
          </div>
          <div
            in={modalOpen}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={selectedImageUrl}
              alt="Image"
              // style={{ maxWidth: "100%", maxHeight: "100%" }}
              style={{
                maxWidth: "100%",
                height: "auto",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                minHeight: '200px',  // Minimum height
                maxHeight: '500px',  // Maximum height
              }}
              className="cursorPointer"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Tooltip title="Zoom-in" placement="top" arrow>
              <IconButton onClick={handleZoomIn}>
                <ZoomInIcon style={{ fontSize: "22px" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Zoom-out" placement="top" arrow>
              <IconButton variant="outlined" onClick={handleZoomOut}>
                <ZoomOutIcon style={{ fontSize: "22px" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refresh" placement="top" arrow>
              <IconButton variant="outlined" onClick={handleRefresh}>
                <RefreshIcon style={{ fontSize: "21px" }} />
              </IconButton>
            </Tooltip>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Student_Image_Open_Modal;
