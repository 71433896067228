import React from 'react'
import {Menu} from '@mui/material'
import { MenuItem } from '@mui/material'
import { useState } from 'react'
import {Button} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

const ButtonGrade = () => {
    const [anchorElm, setAnchorElm] = useState(null)
    const [open, setOpen] = useState(false)

    const handleClick = (event) => {
        setAnchorElm(event.currentTarget);
        setOpen(true)

    };
    const handleClose = ( ) => {
        setAnchorElm(null);
        setOpen(false)
    }

    return (
        //
    <div>
        <Button variant="outlined" size='small' onClick={handleClick} sx={{borderRadius:"16px"}}  endIcon={<KeyboardArrowDown />}>
           Grade
            </Button>
        <Menu
             anchorEl={anchorElm} open={open} onClose={handleClose}
         >
        
        <MenuItem onClick={handleClose}>One</MenuItem>
        <MenuItem onClick={handleClose}>Two</MenuItem>
        <MenuItem onClick={handleClose}>Three</MenuItem>
        <MenuItem onClick={handleClose}>Four</MenuItem>
        <MenuItem onClick={handleClose}>Five</MenuItem>
        <MenuItem onClick={handleClose}>Six</MenuItem>
        <MenuItem onClick={handleClose}>Seven</MenuItem>
        <MenuItem onClick={handleClose}>Eight</MenuItem>
        <MenuItem onClick={handleClose}>Nine</MenuItem>
        <MenuItem onClick={handleClose}>Ten</MenuItem>
        <MenuItem onClick={handleClose}>Eleven</MenuItem>
        <MenuItem onClick={handleClose}>Twelve</MenuItem>
        </Menu>
    </div>
  )
}
  

export default ButtonGrade