
import { LOGIN_USER_DETAILS } from "../actions/userDetailAction";
  
  
  const initialState = {
    userDetails: {},
   
  }
  
  export const userDetailReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_USER_DETAILS:
        return {
          ...state,
          userDetails: action.payload
        }
      default:
        return state
    }
  }
  