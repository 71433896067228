import React, { useState } from 'react'
import MainContainer from '../dashboard/MainContainer';
import TeachContent from './TeachContent';
import Navbar from '../../Navbar';
import TeachContentOptimised from './TeachContentOptimised';

const CordinatorTeach = () => {
    const [headerTitle,setHeaderTitle] = useState('')
    const handleHeaderTitle = (value) => {
      setHeaderTitle(value)
    }
    return (
      <>
        <Navbar handleHeaderTitle={(value)=>handleHeaderTitle(value)}/>  
        <MainContainer children={<TeachContentOptimised/>} headerTitle={headerTitle} />  
      
      </>
    );
  };

export default CordinatorTeach