import React, { useEffect, useState } from 'react'
import Topbar from './Topbar'
import { Doughnut, Pie } from 'react-chartjs-3'
import './Principaldashboard.css'
import RowOne from './RowOne'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import {
  getCountForDashboard,
  getProfileDetails
} from '../../../API/principal-controller/dashboardApis'
import RowTwo from './RowTwo'
import RowThree from './RowThree'
import TasksModal from './TasksModal'
import GetLastModifiedAt from '../../../API/school-controller/GetLastModifiedAt'
import TasksColumn from './TasksColumn'

const DashboardContent = () => {
  const [selectedSubject, setSelectedSubject] = useState('')
  const [selectedGrade, setSelectedGrade] = useState('')
  const [selectedSubtopic, setSelectedSubtopic] = useState('')
  const [showTasksModal, setShowTaskModal] = useState(false)
  const userName = localStorage.getItem('userName')
  const role = localStorage.getItem('role')
  const token = sessionStorage.getItem('token')

  const columnWidth = '24%'
  const lastColumnWidth = '33%'
  const wideColumWidth = '66%'
  const queryClient = useQueryClient()

  const {
    isLoading: isInitiating,
    isError: isErrorOnProfileFetch,
    error: profileFetchError,
    data: profileData,
    refetch
  } = useQuery(['profileData', userName, role, token], getProfileDetails, {
    enabled: !!token
  })

 

  if (isInitiating) {
    return <div>Intitiating..</div>
  }

  return (
    <div style={{ width: '100%', padding: '20px', height: '100%' }}>
      <Topbar title={'Home'} subSubTitle={'Dashboard'} />
      <RowOne
        profileData={profileData}
        token={token}
        gradeId={selectedGrade}
        subjectId={selectedSubject}
        subTopicId={selectedSubtopic}
        columnWidth={columnWidth}
        lastColumnWidth={lastColumnWidth}
      />
      <div style={{ height: '20px' }} />
      <div className='rows-container'>
        <div className='columns-container'>
          <RowTwo
            profileData={profileData}
            token={token}
            selectedGrade={selectedGrade}
            selectedSubject={selectedSubject}
            selectedSubtopic={selectedSubtopic}
            setSelectedSubject={setSelectedSubject}
            setSelectedGrade={setSelectedGrade}
            setSelectedSubtopic={setSelectedSubtopic}
          />
          <RowThree
            selectedGrade={selectedGrade}
            selectedSubject={selectedSubject}
            selectedSubtopic={selectedSubtopic}

            token={token}
            profileData={profileData}
          />
        </div>
        <TasksColumn
          onViewAllTasksClick={() => setShowTaskModal(true)}
          lastColumnWidth={lastColumnWidth}
        />
      </div>

      {showTasksModal ? (
        <TasksModal
          open={showTasksModal}
          close={() => setShowTaskModal(false)}
        />
      ) : null}
    </div>
  )
}
export default DashboardContent
