import React from "react";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import useLastModifiedAt from "../../../hooks/quiz/useLastModifiedAt";
import styled from "styled-components";

const UpdatedAt = () => {
	const { data } = useLastModifiedAt();
	return (
		<Container>
			<Label>Last Update: {data?.data}</Label>
		</Container>
	);
};

export default UpdatedAt;
const Container = styled.div``;
const Label = styled.p`
  width: 220px;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #1b1b1b;
  opacity: 1;
`;
