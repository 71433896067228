import React from 'react'

const CustomBarV2 = ({width,percent,color,marginRight}) => {
  return (
    <div style={{width:width,height:`${percent}%` ,background:color,marginRight:marginRight? marginRight:0,position:"relative",borderRadius:'15px'}} >
        <p style={{position:'absolute',top:'-15px',left:0,right:0,fontSize:'10px',color:'#060606'}}>{percent}%</p>
    </div>
  )
}

export default CustomBarV2