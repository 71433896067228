import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import fileStatusStyles from "./../css/fileStatus.module.css";
import sharestyle from "../css/shareModal.module.css"; //share modal css
import contentApprovalStyles from "../css/contetnApproval.module.css";
import tickImage from "./../img/tick-inside-a-circle.svg";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
// import fileStatus_pending_get from '../API_CONTROLLER/chapters-controller/chapters_controller_get';
import chapters_last_modified_get from "../API_CONTROLLER/chapters-controller/chapters_last_modified_get";
import fileStatus_pending_get from "../API_CONTROLLER/File-status-controller/fileStatus_pending_get";
import chapter_reason_by_id_get from "../API_CONTROLLER/chapters-controller/chapters_reason_by_id_get";
import Pagination1 from "./pagination1";
import dashHome from "../../../src/img/dashHome.svg";
import commonsort from "../js/CommonSort";

// sort
import sortIcon from "../../../src/pages/img/sort_1.png";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import searchIcn from "../../../src/img/search.svg";

const ContentApprovalPending = () => {
  const [gradesName, setGradesName] = useState({});
  const [board, setBoard] = useState({});
  const [subject, setSubject] = useState({});
  const [subtopic, setSubtopic] = useState({});
  const [chapters, setChapters] = useState({});
  const [lastmodifieddate, setLastModifiedDate] = useState("");

  const [boardInput, setBoardInput] = useState("");
  const [gradeInput, setGradeInput] = useState("");
  const [subjectInput, setSubjectInput] = useState("");
  const [subTopicInput, setSubTopicInput] = useState("");
  const [showSubTopic, setShowSubTopic] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();
  const [showModalShare, setShowModalShare] = useState(false);

  // sort
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState(false);

  const [searchParams] = useSearchParams();
  const chapterId = searchParams.get("chapterId");

  const navigate = useNavigate();

  const handleShareModal = () => {
    setShowModalShare(true);
  };

  const handleShareModalOk = (e) => {
    setShowModalShare(false);
  };
  // searching
  // pagination starts
  const [rowsInput, setRowsInputs] = useState({ rows: 1000 });
  const [page, setPage] = useState(0);
  const [pageDetail, setPageDetail] = useState({});

  const [sessionTrigger, setSessionTrigger] = useState(false);
  const handleChange = (e, value) => {
    setPage(value);
  };

  const handlerRowsInputs = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  // pagination ends

  const [search, setSearch] = useState({
    search: "",
  });
  const handleSearch = (e) => {
    e.preventDefault();
    const searchValue = {
      search: e.target.value,
    };
    setSearch(searchValue);
    fileStatus_pending_get(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      searchValue,
      gradeInput,
      subjectInput,
      subTopicInput,
      boardInput,
      sortBy,
      sortOrder,
      chapterId
    );
    setPage(0);
  };

  useEffect(() => {
    sessionStorage.removeItem("gradeId");
    sessionStorage.removeItem("subjectId");
    sessionStorage.removeItem("subTopicId");
    sessionStorage.removeItem("boardId");
    setSessionTrigger(true);
  }, []);

  useEffect(() => {
    if (
      sessionStorage.getItem("boardId") != "" &&
      sessionStorage.getItem("boardId") &&
      sessionTrigger
    ) {
      setBoardInput(sessionStorage.getItem("boardId"));
    }
    if (
      sessionStorage.getItem("gradeId") != "" &&
      sessionStorage.getItem("gradeId") &&
      sessionTrigger
    ) {
      setGradeInput(sessionStorage.getItem("gradeId"));
    }
    if (
      sessionStorage.getItem("subjectId") != "" &&
      sessionStorage.getItem("subjectId") &&
      sessionTrigger
    ) {
      setSubjectInput(sessionStorage.getItem("subjectId"));
    }
    if (
      sessionStorage.getItem("subTopicId") != "" &&
      sessionStorage.getItem("subTopicId") &&
      sessionTrigger
    ) {
      setSubTopicInput(sessionStorage.getItem("subTopicId"));
    }
    if (
      sessionStorage.getItem("subTopicId") ||
      sessionStorage.getItem("subjectId") ||
      sessionStorage.getItem("gradeId") ||
      (sessionStorage.getItem("boardId") && sessionTrigger)
    ) {
      setTimeout(() => {
        getData();
      }, 500);
    }
  }, [sessionTrigger]);
  const getData = () => {
    fileStatus_pending_get(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      sessionStorage.getItem("gradeId")
        ? sessionStorage.getItem("gradeId")
        : "",
      sessionStorage.getItem("subjectId")
        ? sessionStorage.getItem("subjectId")
        : "",
      sessionStorage.getItem("subTopicId")
        ? sessionStorage.getItem("subTopicId")
        : "",
      sessionStorage.getItem("boardId")
        ? sessionStorage.getItem("boardId")
        : "",
      sortBy,
      sortOrder,
      gradeInput || boardInput || subjectInput || subTopicInput ? "" : chapterId
    );
  };

  useEffect(() => {
    if (search.search === "") {
      fileStatus_pending_get(
        setChapters,
        rowsInput,
        page,
        setPageDetail,
        search,
        gradeInput,
        subjectInput,
        subTopicInput,
        boardInput,
        sortBy,
        sortOrder,
        chapterId
      );
    }
    return () => { };
  }, [rowsInput, search, sortBy, sortOrder]);

  useEffect(() => {
    fileStatus_pending_get(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput,
      boardInput,
      sortBy,
      sortOrder,
      chapterId
    );
  }, [rowsInput, page, sortBy, sortOrder]);

  useEffect(() => {
    fileStatus_pending_get(
      setChapters,
      rowsInput,
      page,
      setPageDetail,
      search,
      gradeInput,
      subjectInput,
      subTopicInput,
      boardInput,
      sortBy,
      sortOrder,
      gradeInput || boardInput || subjectInput || subTopicInput ? "" : chapterId
    );
    return () => { };
  }, [
    search,
    gradeInput,
    subjectInput,
    subTopicInput,
    boardInput,
    sortBy,
    sortOrder,
  ]);

  useEffect(() => {
    GradesControllerGetall(setGradesName);
    boards_controller_getall(setBoard);
    subjects_controller_getall(setSubject);
    subtopics_controller_getall(setSubtopic);
    chapters_last_modified_get(setLastModifiedDate);
    return () => { };
  }, []);

  const handleChangeSubject = (value) => {
    subtopics_controller_getall(setSubtopic, value);
    console.log(value, subject)
    const selectedSubject = subject.find(val => val.id === String(value));
    console.log(selectedSubject)
    console.log(selectedSubject?.hideSubtopics, selectedSubject.id)
    if (selectedSubject && selectedSubject.hideSubtopics) {
      setShowSubTopic(false);
      // console.log("false")
      // alert("pac false");
    } else {
      // console.log("true")
      setShowSubTopic(true);
      // alert("pac true");
    }
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        {/* article start  */}
        <article>
          {/* <div className={dashboardContentStyles.dashboard_link}>
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />{" "}
            </span>
            {"  "}
            <span className={dashboardContentStyles.link_text}>Home</span>
            {"  "}
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>
            <span>
              <i className="fa-solid fa-angle-right"></i>
            </span>

            {"  "}
            <a>Content Approval</a>
          </div> */}

          <div className={dashboardContentStyles.dashboard_link}>

            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                className={dashboardContentStyles.az_menu_icon}
              />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate("/dashboard/template")}
            >
              Home
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span>
              <i className="fa-solid fa-angle-right"
                style={{ color: "#828282" }} />
            </span>
            <span
              className={dashboardContentStyles.az_selected_menu_text}
            >
              Content Approval
            </span>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update: {lastmodifieddate.data}
            </p>
          </div>
        </article>
        {/* article end  */}
        {/* drop down search row start  */}
        <div
          className={contentApprovalStyles.flex}
          style={{ marginTop: "55px", position: "relative" }}
        >
          <div className={contentApprovalStyles.flex}>
            <i
              style={{ color: "grey", fontSize: "30px", margin: "5px" }}
              className="fa-solid fa-user-gear"
            ></i>
            <p
              style={{ color: "grey ", fontSize: "1.1vw", margin: "auto 5px" }}
            >
              Content Pending
            </p>
          </div>

          <Box style={{ margin: "auto 5px" }} sx={{ minWidth: 150 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">Board</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="Grade"
                value={boardInput}
                onChange={(e) => {
                  sessionStorage.setItem("boardId", e.target.value);
                  setBoardInput(e.target.value);
                  setGradeInput("");
                  setSubjectInput("");
                  setSubTopicInput("");
                }}
                style={{ background: "#fff", borderRadius: 150 }}
              //   MenuProps={MenuProps}
              >
                {/* <MenuItem value="">All</MenuItem> */}
                {board.length > 0 ? (
                  board.map((elem, i) => {
                    return (
                      <MenuItem key={i} value={elem.id}
                        id={`az-content-boarddropdown-${elem.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: '#FFA500',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#FFA500',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <ListItemText primary={elem.board} />
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          <Box style={{ margin: "auto 5px" }} sx={{ minWidth: 150 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">Grade</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label="Grade"
                value={gradeInput}
                onChange={(e) => {
                  sessionStorage.setItem("gradeId", e.target.value);
                  setGradeInput(e.target.value);
                  setSubjectInput("");
                  setSubTopicInput("");
                }}
                style={{ background: "#fff", borderRadius: 150 }}
              //   MenuProps={MenuProps}
              >
                {/* <MenuItem value="">All</MenuItem> */}
                {gradesName.length > 0 ? (
                  gradesName.map((elem, i) => {
                    return (
                      <MenuItem key={i} value={elem.id}
                        id={`az-content-gradedropdown-${elem.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: '#FFA500',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#FFA500',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <ListItemText primary={elem.grade} />
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          <Box style={{ margin: "auto 5px" }} sx={{ minWidth: 180 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small">Subject</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={subjectInput}
                label="Subject"
                onChange={(e) => {
                  sessionStorage.setItem("subjectId", e.target.value);
                  handleChangeSubject(e.target.value);
                  setSubjectInput(e.target.value);
                }}
                style={{ background: "#fff", borderRadius: 150 }}
              //   MenuProps={MenuProps}
              >
                {/* <MenuItem value="">All</MenuItem> */}
                {subject.length > 0 ? (
                  subject.map((subject, i) => {
                    return (
                      <MenuItem key={i} value={subject.id}
                        id={`az-content-subjectdropdown-${subject.id}`}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: '#FFA500',
                            color: "#FFFFFF"
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: '#FFA500',
                            color: "#000000"
                          },
                          "&:not(.Mui-selected):hover": {
                            backgroundColor: '#fcedc2',
                            color: '#000000',
                          },
                        }}>
                        <ListItemText primary={subject.subject} />
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>Select</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          {
            showSubTopic &&

            <Box style={{ margin: "auto 5px" }} sx={{ minWidth: 150 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small">SubTopic</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={subTopicInput}
                  label="SubTopic"
                  onChange={(e) => {
                    sessionStorage.setItem("subTopicId", e.target.value, subtopic);
                    setSubTopicInput(e.target.value);


                  }}
                  style={{ background: "#fff", borderRadius: 150 }}
                //   MenuProps={MenuProps}
                >
                  {/* <MenuItem value="">All</MenuItem> */}
                  {subtopic.length > 0 ? (
                    subtopic.map((subtopic, i) => {
                      return (
                        <MenuItem key={i} value={subtopic.id}
                          id={`az-content-subtopicdropdown-${subtopic.id}`}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: '#FFA500',
                              color: "#FFFFFF"
                            },
                            "&.Mui-selected:hover": {
                              backgroundColor: '#FFA500',
                              color: "#000000"
                            },
                            "&:not(.Mui-selected):hover": {
                              backgroundColor: '#fcedc2',
                              color: '#000000',
                            },
                          }}>
                          <ListItemText primary={subtopic.subTopic} />
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>Select</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          }
          <TextField
            sx={{
              width: { sm: 200, md: 300 },
              "& .MuiInputBase-root": {
                width: "247px !important",
                height: "32px !important",
                borderRadius: "50px !important",
                background: "#fff !important",
                lineHeight: "21px !important",
                font: "normal normal normal 13px/20px Poppins !important",
                opacity: 1,
              },
            }}
            style={{
              position: "absolute",
              right: "-30px",
            }}
            type={"search"}
            placeholder={"Search...."}
            name="search"
            onChange={handleSearch}
            Value={search.search}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <img
                    src={searchIcn}
                    style={{
                      verticalAlign: "top",
                      fontSize: "large",
                    }}
                    aria-hidden="true"
                  />
                </IconButton>
              ),
            }}
          />
        </div>
        {/* drop down search row  end  */}

        {/* content start  */}
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            marginTop: " 15px",
            marginLeft: "15px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "49px",
              backgroundColor: "white",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                display: "flex",
                width: "100%",
              }}
            >
              <p
                style={{
                  width: "33%",
                  textAlign: "center",
                  padding: "8px",
                  color: "#FD8C00",
                  borderRight: "1px solid lightgrey",
                  borderBottom: "7px solid #FD8C00",
                  cursor: "pointer"
                }}
              >
                Pending
              </p>
              <p
                style={{
                  width: "33%",
                  textAlign: "center",
                  padding: "8px",
                  borderRight: "1px solid lightgrey",
                  cursor: "pointer"
                }}
                onClick={(e) => {
                  if (
                    chapterId != "" &&
                    boardInput == "" &&
                    gradeInput == "" &&
                    subjectInput == "" &&
                    subTopicInput == "" &&
                    chapterId
                  ) {
                    navigate(
                      `/dashboard/ContentApprovalRejected?chapterId=${chapterId}`
                    );
                  } else {
                    e.preventDefault();
                    navigate("/dashboard/ContentApprovalRejected");
                  }
                }}
              >
                Rejected
              </p>
              <p
                style={{
                  width: "33%",
                  textAlign: "center",
                  padding: "8px",
                  borderRight: "1px solid lightgrey",
                  cursor: "pointer"
                }}
                onClick={(e) => {
                  if (
                    chapterId != "" &&
                    boardInput == "" &&
                    gradeInput == "" &&
                    subjectInput == "" &&
                    subTopicInput == "" &&
                    chapterId
                  ) {
                    navigate(
                      `/dashboard/ContentApprovalRejected?chapterId=${chapterId}`
                    );
                  } else {
                    e.preventDefault();
                    navigate("/dashboard/ContentApprovalApproved");
                  }
                }}
              >
                Approved
              </p>
            </div>
          </div>
        </Paper>

        {/* content start  */}

        <Paper
          sx={{ width: "100%", height: "100%", overflow: "scroll" }}
          style={{ marginLeft: "15px" }}
        >
          <TableContainer sx={{ maxHeight: 500, overflow: "auto" }}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader={true}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "white",
                      fontWeight: 600,
                      background: "#403e75",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color: "white",
                      fontWeight: 600,
                      background: "#403e75",
                    }}
                  >
                    Chapter
                    <img
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("chapter");
                        // await initData(!sortOrder, "id");
                        var sortedData = await commonsort(chapters || [], "chapter", sortOrder ? true : false);
                        setChapters(sortedData);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: 600,
                      background: "#403e75",
                    }}
                  >
                    Board
                    <img
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("board");
                        // await initData(!sortOrder, "id");
                        var sortedData = await commonsort(chapters || [], "board", sortOrder ? true : false);
                        setChapters(sortedData);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: 600,
                      background: "#403e75",
                    }}
                  >
                    Grade
                    <img
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("grade");
                        // await initData(!sortOrder, "id");
                        var sortedData = await commonsort(chapters || [], "grade", sortOrder ? true : false);
                        setChapters(sortedData);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: 600,
                      background: "#403e75",
                    }}
                  >
                    Subject
                    <img
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("subject");
                        // await initData(!sortOrder, "id");
                        var sortedData = await commonsort(chapters || [], "subject", sortOrder ? true : false);
                        setChapters(sortedData);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: 600,
                      background: "#403e75",
                    }}
                  >
                    Subtopic
                    <img
                      style={{
                        verticalAlign: "middle",
                        paddingLeft: "3px",
                        marginBottom: "2px",
                      }}
                      className="cursor-pointer"
                      width="14"
                      height="15"
                      src={sortIcon}
                      onClick={async () => {
                        await setSortOrder(!sortOrder);
                        await setSortBy("subTopic");
                        // await initData(!sortOrder, "id");

                        var sortedData = await commonsort(chapters || [], "subTopic", sortOrder ? true : false);
                        setChapters(sortedData);

                      }}
                    />
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: 600,
                      background: "#403e75",
                      paddingRight: "21px",
                    }}
                  >
                    Uploaded Files
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chapters && chapters.length > 0 ? (
                  chapters
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((elem, i) => (
                      <TableRow
                        style={{ backgroundColor: (i % 2 === 0) ? "white" : "#f5f5f8" }}>
                        {/* index */}
                        <TableCell className={contentApprovalStyles.table_cell}>
                          {/* {i + 1} */}
                          {page * rowsPerPage + (i + 1)}
                        </TableCell>
                        {/* index */}
                        <TableCell className={contentApprovalStyles.table_cell}>
                          <Tooltip title={elem.chapter} arrow>
                            <span>
                              {elem.chapter.length > 28
                                ? `${elem.chapter.substring(0, 28)}...`
                                : elem.chapter}
                            </span>
                          </Tooltip>
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={contentApprovalStyles.table_cell}
                          align="center"
                        >
                          {elem.board}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={contentApprovalStyles.table_cell}
                          align="center"
                        >
                          {elem.grade}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={contentApprovalStyles.table_cell}
                          align="center"
                        >
                          {elem.subject}
                        </TableCell>
                        {/* index */}
                        <TableCell
                          className={contentApprovalStyles.table_cell}
                          align="center"
                        >
                          {elem.subTopic}
                          {/* {elem.subTopics?.length > 0 ? (
                            elem.subTopics.map((item) => (
                              <p style={{ color: "black" }}>{item.subTopic}</p>
                            ))
                          ) : (
                            <p>-</p>
                          )} */}
                        </TableCell>
                        {/* active btn */}

                        <TableCell
                          className={contentApprovalStyles.table_cell}
                          align="center"
                        >
                          <div>
                            <button
                              className={contentApprovalStyles.orange_btn}
                              onClick={(e) => {
                                e.preventDefault();
                                if (searchParams.get("chapterId")) {
                                  navigate(
                                    `/dashboard/StudentFileApproval?chapterId=${searchParams.get(
                                      "chapterId"
                                    )}`
                                  );
                                  sessionStorage.setItem("isStatus", true);
                                  sessionStorage.setItem("status", "PENDING");
                                } else {
                                  // handleShareModal() ;
                                  navigate("/dashboard/StudentFileApproval");
                                  var chapterId = elem.id;
                                  sessionStorage.setItem(
                                    "chapterId",
                                    chapterId
                                  );
                                  sessionStorage.setItem("isStatus", false);
                                  sessionStorage.setItem("status", "PENDING");
                                  chapter_reason_by_id_get();
                                }
                              }}
                            >
                              Student
                            </button>
                            <button
                              className={contentApprovalStyles.orange_btn}
                              id={`az-content-viewfile-${i}`}
                              onClick={(e) => {
                                e.preventDefault();
                                if (searchParams.get("chapterId")) {
                                  navigate(
                                    `/dashboard/TeacherFileApproval?chapterId=${searchParams.get(
                                      "chapterId"
                                    )}`
                                  );
                                  sessionStorage.setItem("isStatus", true);
                                  sessionStorage.setItem("status", "PENDING");
                                } else {
                                  navigate("/dashboard/TeacherFileApproval");
                                  var chapterId = elem.id;
                                  sessionStorage.setItem(
                                    "chapterId",
                                    chapterId
                                  );
                                  sessionStorage.setItem("isStatus", false);
                                  sessionStorage.setItem("status", "PENDING");
                                  chapter_reason_by_id_get();
                                }
                              }}
                            >
                              Teacher
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <td
                    colSpan="10"
                    style={{ height: "25rem", textAlign: "center" }}
                  >
                    {" "}
                    NO DATA AVAILABLE{" "}
                  </td>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* pagination container - Start */}
          <TablePagination
            style={{
              bottom: "0",
              width: "80.5%",
              position: "fixed",
              backgroundColor: "white",
            }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={chapters?.length || 0}
            page={page}
            onPageChange={handleChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handlerRowsInputs}
            showFirstButton={true}
            showLastButton={true}
          />
          {/* pagination container - End */}
        </Paper>

        <div
          className={contentApprovalStyles.flex_container}
          id={contentApprovalStyles.modal_id}
          style={
            showModalShare
              ? { display: "flex", zIndex: "10" }
              : { display: "none" }
          }
        >
          <div
            className={contentApprovalStyles.main_container}
            id={contentApprovalStyles.modal_content}
          >
            <div style={{ padding: "20px 23px 25px", position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <p style={{ fontSize: "16px" }}>Chapter name</p>
                <p
                  style={{
                    marginLeft: "auto",
                    fontSize: "16px",
                    color: "black",
                    marginRight: "36px",
                  }}
                >
                  15 files Rejected
                </p>
                <p
                  onClick={handleShareModalOk}
                  style={{
                    position: "absolute",
                    right: "15px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  X
                </p>
              </div>
              <div style={{ marginTop: "20.5px" }}>
                <p
                  style={{
                    fontSize: "13px",
                    marginRight: "auto",
                    display: "block",
                  }}
                >
                  Reason
                </p>
                <p style={{ fontSize: "14px", color: "black" }}>
                  {" "}
                  The href attribute requires a valid value to be accessible.
                  Provide a valid, navigable address as the href value. If you
                  cannot provide a valid href, but still need the element to
                  resemble a link, use a butto{" "}
                </p>
              </div>
              <div
                style={{ position: "absolute", left: "34%", marginTop: "12px" }}
              >
                <button
                  onClick={handleShareModalOk}
                  style={{
                    padding: "10px 32px",
                    borderRadius: "21px",
                    backgroundColor: "#FD8C00",
                    color: "white",
                    border: "none",
                  }}
                >
                  UPLOAD AGAIN
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* own modal end  */}
      </div>
    </>
  );
};

export default ContentApprovalPending;
