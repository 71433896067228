import axios from 'axios'
import CaseStudy from '../../js/CaseStudy';

function taxonomy_get(setTaxonomyList, categoryId, caseStudy){
    let token = sessionStorage.getItem("token");

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/taxonomy/all?isCaseStudy=${caseStudy}&categoryId=${categoryId}`,
    {
        headers : {
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setTaxonomyList(res.data.data)
    })
    .catch(err => console.log(err))
}

export default taxonomy_get