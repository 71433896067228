import React, { useEffect, useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import GreenAddButton from "../../components/common/GreenAddButton";
import CardMembershipSharpIcon from "@mui/icons-material/CardMembershipSharp";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import HoverGreyCancelButton from "../../components/common/HoverGreyCancelButton";
import BlueButton from "../../components/common/BlueButton";
import Switch from "../../components/common/Switch";
import EditIconButton from "../../components/common/EditIconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIconButton from "../../components/common/DeleteIconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import axios from "axios";
import DeleteGradeModal from "./DeleteGradeModal";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
// import GetLastModifiedAt from "../API/teacher-controller/GetLastModifiedAt";
import ConfirmationModal from "./ConfirmationModal";
import searchIcn from "../../../src/img/search.svg";
import Success_OK_Modal from "../../components/common/Success_OK_Modal";
import UpdatedModal from "./UpdatedModal";
import { Link, useNavigate, useLocation } from "react-router-dom";
import commonsort from "../js/CommonSort"
// sort
import sortIcon from "../../../src/pages/img/sort_1.png";

//Action Icon Images
import action__Edit from "../../../src/img/Edit.svg";
import action__Delete from "../../../src/img/Delete.svg";
import dashHome from "../../../src/img/dashHome.svg";

const Grade = () => {
  const token = sessionStorage.getItem("token");
  const [addGradeVisible, setAddGradeVisible] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [updatedData, setUpdatedData] = useState();
  const [filterValue, setFilterValue] = useState();
  const [grade, setGrade] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [modifiedDate, setModifiedDate] = useState({});
  const [validation, setValidation] = useState(false);
  const [validationMessage, setMessage] = useState("");
  const [id, setId] = useState();
  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const navigate = useNavigate();

  // sort
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState("userName");

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => { };
  }, []);
  const fetchData = async (sortOrder) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/page?pageSize=1000&sortOrder=${sortOrder}&sortBy=grade`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // var sorted = await commonsort(response?.data?.data?.data||[],"grade",sortOrder)
    setUpdatedData(response?.data?.data?.data);
  };
  useEffect(() => {
    setFilterValue(updatedData);
  }, [updatedData]);
  useEffect(() => {
    fetchData(sortOrder);
  }, [sortOrder]);
  const showDeleteModal = async (item) => {
    setId(item?.id);
    const response = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/confirmation-api?id=${item.id}&operationType=DELETE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setSelectedValue({ grade: response?.data?.data?.message });

    setOpenDeleteModal(true);
  };
  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const [gradeDisplayName, setGradeDisplayName] = useState("")
  const addAdminRoleHandler = async () => {
    if (!grade) {
      setValidation(true);
      setMessage("Grade is Required");
      return;
    }

    const newValue = {
      discription: grade,
      grade: grade,
    };
    setGradeDisplayName(grade)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades`,
      data: newValue,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        setSuccessCreateModal(true)
        if (response.data.data) {
          setUpdatedData([...updatedData, response.data.data]);
        }
        setAddGradeVisible(false);
        setGrade("");
      })
      .catch(function (error) {
        setValidation(true);
        setMessage(
          error?.response?.data?.message
            ? error.response.data.message
            : "Failed To Add Grade"
        );
        setGrade("");
      });
  };
  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/toggle-active?id=${toggleDetails?.id}&active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setUpdatedData(clone);
          handleConfirmationClose();
        }
      })
      .catch(function (response) { });
  };
  const [toggleDetails, setToggleDetails] = useState({});
  const switchHandler = async (id, e) => {
    const value = e.target.checked;
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/toggle-active?id=${id}&active=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: value,
          }
        )
        .then(function (response) {
          if (response?.data) {
            const find = updatedData?.find((item) => item.id === id);
            find.active = value;
            const clone = [...updatedData];
            clone.forEach((data) => {
              data = find;
            });
            setUpdatedData(clone);
            handleConfirmationClose();
          }
        })
        .catch(function (response) { });
    }
  };
  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };

  async function GetLastModifiedAt(ModifiedDate) {
    let token = sessionStorage.getItem("token");
    let result = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/last-modified-at`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((json) => {
        ModifiedDate(json.data)
      })
      .catch(err => console.log(err))
  }

  const deleteHandler = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          const find = updatedData?.filter((data) => data.id !== id);
          setUpdatedData(find);
        }
        setOpenDeleteModal(false);
      })
      .catch(function (response) {
        console.log(response);
      });
  };

  const [updatedName, setUpdatedName] = useState("")

  const editHandler = (id) => {
    if (!grade) {
      setValidation(true);
      setMessage("Grade is Required");
      return;
    }
    const updatedValue = {
      discription: grade,
      grade: grade,
    };
    setUpdatedName(grade)
    axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/grades/${id}`,
        updatedValue,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setSuccesUpdatesModal(true)
        if (response?.data?.data) {
          const find = updatedData?.find(
            (item) => item.id === response?.data?.data?.id
          );
          find.grade = grade;
          const clone = [...updatedData];
          clone.forEach((data) => {
            data = find;
          });
          setUpdatedData(clone);
          setAddGradeVisible(false);
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onSearch = async (event) => {
    let value = event?.target?.value?.toLowerCase();
    let result = updatedData;
    if (value) {
      result = updatedData?.filter((data) => {
        return data?.grade?.toLowerCase().includes(value);
      });
    }
    setFilterValue(result);
    setPage(0);
  };
  const editClickHandler = (row) => {
    setAddGradeVisible(true);
    setIsEdit(true);
    setGrade(row?.grade);
    setSelectedValue(row);
  };

  const [succesUpdatesModal, setSuccesUpdatesModal] = useState(false)
  const [successCreateModal, setSuccessCreateModal] = useState(false)
  const closeSuccessModal = () => {
    setSuccessCreateModal(false)
  }

  const closeModal = () => {
    setSuccesUpdatesModal(false)
  }

  const CustomTooltip = ({ title, children, ...props }) => (
    <Tooltip
      title={title}
      arrow
      {...props}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: '[data-popper-arrow]',
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          }
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#ffa500',
            color: 'white',
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
            fontSize: '10px',
            fontWeight: 500,
            padding: '9px 20px',
            borderRadius: '5px',
          },
        },
        arrow: {
          sx: {
            color: '#ffa500',
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span
            className={dashboardContentStyles.link_icon}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            <img
              src={dashHome}
              alt="no img"
              // width="15px"
              // height="20px"
              // style={{ verticalAlign: "middle" }}
              className={dashboardContentStyles.az_menu_icon}
            />
          </span>
          {/* {"  "}
          <span className={dashboardContentStyles.link_text}>Home</span>
          {"  "} */}
          <span
            className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
              sessionStorage.removeItem("sideNavSubMenuNames");
              navigate("/dashboard/template");
            }}
          >
            Home
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "} */}
          <span className={dashboardContentStyles.az_menu_text}
            style={{ cursor: 'default' }}>
            Master
          </span>

          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right" style={{ color: "#828282" }}></i>
          </span>
          {/* {"  "}
          <a>Grade</a> */}
          <span
            className={`${dashboardContentStyles.az_selected_menu_text} cursorDefault`}
          >
            Grade
          </span>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>

      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            marginTop: "60px",
            gap: "16px",
          }}
        >
          {addGradeVisible && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#606060",
                  columnGap: "10px",
                }}
              >
                <CardMembershipSharpIcon style={{ fontSize: "30px" }} />
                <div style={{ fontWeight: 600 }}>
                  {isEdit ? "Edit Grade" : "Add Grade"}
                </div>
              </div>
              <TableContainer component={Paper}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px 20px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#B5B6B6 ",
                          fontSize: "14px/21px !important",
                        },
                        "& .MuiInputBase-root": {
                          height: "48px !important",
                          width: "270px !important",
                          borderRadius: "150px !important",

                        },
                        "& .MuiInputBase-input": {
                          color: "#ffa500",

                        }

                      }}
                      id="demo-helper-text-aligned-no-helper"
                      label="*Grade Name"
                      autoComplete="off"
                      value={grade}
                      onChange={(e) => setGrade(e.target.value)}
                      style={{ fontSize: "15px" }}
                    />
                    {validation && !grade && (
                      <span style={{ color: "red", padding: "5px" }}>
                        {validationMessage}
                      </span>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                    }}
                  >
                    <HoverGreyCancelButton
                      variant="outlined"
                      onClick={() => {
                        setAddGradeVisible(false);
                        setIsEdit(false);
                        setGrade("");
                      }}
                    >
                      Cancel
                    </HoverGreyCancelButton>
                    {isEdit ? (
                      <BlueButton onClick={() => editHandler(selectedValue.id)}>
                        Update
                      </BlueButton>
                    ) : (
                      <BlueButton onClick={() => addAdminRoleHandler()}>
                        Create
                      </BlueButton>
                    )}
                  </div>
                </div>
              </TableContainer>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#606060",
                columnGap: "10px",
              }}
            >
              <CardMembershipSharpIcon style={{ fontSize: "2.2vw" }} />
              <div
                style={{
                  fontSize: "1.1vw",
                  fontWeight: "600",
                }}
              >
                Grade List
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              <TextField
                type={"text"}
                placeholder={"Search..."}
                sx={{
                  width: { sm: 200, md: 300 },
                  "& .MuiInputBase-root": {
                    width: "247px !important",
                    height: "32px !important",
                    borderRadius: "50px !important",
                    background: "#fff !important",
                    lineHeight: "21px !important",
                    font: "normal normal normal 13px/20px Poppins !important",
                    opacity: 1,
                  },
                }}
                onChange={onSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <img
                        src={searchIcn}
                        style={{
                          verticalAlign: "top",
                          fontSize: "large",
                        }}
                        aria-hidden="true"
                      />
                    </IconButton>
                  ),
                }}
              />
              {!addGradeVisible && (
                <GreenAddButton
                  onClick={() => {
                    setGrade("");
                    setAddGradeVisible(true);
                  }}
                >
                  <AddCircleOutlineOutlinedIcon
                    style={{
                      marginRight: "10px",
                      marginBottom: "2px",
                      fontSize: "medium",
                    }}
                  />
                  Add Grade
                </GreenAddButton>
              )}
            </div>
          </div>

          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              style={{ marginBottom: "90px" }}
              sx={{ maxHeight: 640, overflow: "auto" }}
            >
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>
                      #
                      {/* <img
                      style={{verticalAlign:"middle",paddingLeft:"3px"}}
                        className="cursor-pointer"
                        width="14"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          await setSortBy("id");
                          // await initData(!sortOrder, "id");
                        }}
                      /> */}
                    </TableCell>
                    <TableCell
                      align="left"
                      className={dashboardContentStyles.table_head}
                    >
                      Grade
                      <img
                        style={{
                          verticalAlign: "middle",
                          margin: "0 0 1px 5px",
                        }}
                        className="cursor-pointer"
                        width="11"
                        height="15"
                        src={sortIcon}
                        onClick={async () => {
                          await setSortOrder(!sortOrder);
                          // await setSortBy("id");
                          // await initData(!sortOrder, "id");
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="right"
                      className={dashboardContentStyles.table_head}
                    >
                      Active
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        //color: "blue",
                        //fontWeight: 600,
                        paddingRight: "60px",
                        // background: "#d9dcfe",
                        color: "#ffffff",
                        fontFamily: "poppins",
                        fontWeight: 400,
                        background: '#403E75',
                        fontSize: "1vw",
                        //textAlign: "center",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filterValue
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      return (
                        <TableRow
                          key={row?.id}
                          sx={{
                            // "&:last-child td, &:last-child th": { border: 0 },

                            backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f8',
                            "&:last-child td, &:last-child th": {
                              borderBottom: "none !important",
                            },

                            "&:hover": {

                              "& td, & th": {
                                borderTop: "1px solid #ff9702",
                                borderBottom: "1px solid #ff9702",
                              },
                              " & th:first-of-type": {
                                borderLeft: "1px solid #ff9702",
                              },

                              "& td:first-of-type": {
                                borderLeft: "0",

                              },

                              "&:last-child td, &:last-child th": {
                                borderBottom: "1px solid #ff9702 !important",
                              },

                              "& td:last-of-type": {
                                borderRight: "1px solid #ff9702",

                              },

                              "& th:last-of-type": {
                                borderRight: "0",
                              },
                            },

                            "& td, & th": {
                              border: "1px solid transparent",
                              borderTop: "1px solid transparent",
                              borderBottom: "1px solid transparent",
                              "&:not(:last-child)": {
                                borderRight: "1px solid transparent",
                              },
                            },
                          }}

                          // style={{
                          //   background: "#f9f8f8",
                          // }}
                          className={dashboardContentStyles.table_rw}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className={dashboardContentStyles.table_cell}
                          >
                            {page * rowsPerPage + (index + 1)}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={dashboardContentStyles.table_cellll}
                          >
                            {row?.grade}
                          </TableCell>
                          <TableCell align="right">
                            <div>
                              <Switch
                                checked={row?.active}
                                onChange={(e) => switchHandler(row?.id, e)}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <EditIconButton
                              onClick={() => {
                                editClickHandler(row);
                              }}
                            >
                              <CustomTooltip title="Edit" placement={"top"}>
                                {/* <EditOutlinedIcon
                                  style={{ fontSize: "1.7vw" }}
                                /> */}
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Edit}
                                  alt="Edit-Btn"
                                />
                              </CustomTooltip>
                            </EditIconButton>

                            <DeleteIconButton
                              onClick={() => showDeleteModal(row)}
                            >
                              <CustomTooltip title="Remove" placement={"top"}>
                                {/* <DeleteOutlinedIcon
                                  style={{ fontSize: "1.7vw" }}
                                /> */}
                                <img
                                  className={
                                    dashboardContentStyles.aRoleActionBtn
                                  }
                                  src={action__Delete}
                                  alt="Dlt-Btn"
                                />
                              </CustomTooltip>
                            </DeleteIconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              style={{
                bottom: "0",
                width: "80.5%",
                position: "fixed",
                backgroundColor: "white",
              }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={updatedData?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </Paper>
        </div>
        <DeleteGradeModal
          open={openDeleteModal}
          close={hideDeleteModal}
          title={"Grade"}
          deleteHandler={deleteHandler}
          selectedValue={selectedValue}
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
        <Success_OK_Modal
          userType={"Grade"}
          userName_Modal={
            gradeDisplayName
          }
          open={successCreateModal}
          handleOk={closeSuccessModal}
        />
        <UpdatedModal
          userType={"Grade"}
          userName_Modal={
            updatedName
          }
          open={succesUpdatesModal}
          handleOk={closeModal}
          close={closeModal}
        />
      </div>
    </>
  );
};
export default Grade;
