import axios from 'axios'

async function User_Token_get(
  setTokenDta,
  setPageDetail,
  page,
  rowsInput,
  search
) {
  let token = sessionStorage.getItem("token");
  let schoolid = sessionStorage.getItem("schoolid");
  let branchid = sessionStorage.getItem("branchid");
  console.log(page,"page")
  if (rowsInput.roleId !== "") {
    var url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/tokens?pageNumber=${page - 1}&pageSize=${
      rowsInput.rows
    }&sortBy=id&roleId=${
      rowsInput.roleId
    }&branchId=${branchid}&schoolId=${schoolid}&search=${search.search}`;
  } else {
    var url = `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/tokens?pageNumber=${page - 1}&pageSize=${
      rowsInput.rows
    }&sortBy=id&branchId=${branchid}&schoolId=${schoolid}&search=${
      search.search
    }`;
  }
    let result = await axios.get(`${url}`,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setTokenDta(json.data.data.data)
      console.log(json.data.data,"details")
       setPageDetail(json.data.data)
    })
    .catch(err=>console.log(err))
}

export default User_Token_get;
