import React, { useState } from 'react'
import MainContainer from '../dashboard/MainContainer'
import Navbar from '../../Navbar'
import AvidReader from '../../Teacher/AvidReader';

const CoordinatorAvidReader = () => {
  const [headerTitle, setHeaderTitle] = useState('')
  const handleHeaderTitle = value => {
    setHeaderTitle(value)
  }
  return (
    <>
      <Navbar handleHeaderTitle={value => handleHeaderTitle(value)} />
      <MainContainer
        // children={<div></div>} 
        children={<AvidReader />}
        headerTitle={headerTitle}
      />
    </>
  )
}

export default CoordinatorAvidReader
