import React from "react";
import sortIcon from "../../../../src/pages/img/sort.png";

const HomeWorkListHead = ({
  tableHeadData,
  setSortOrder,
  setSortBy,
  sortOrder,
}) => {
  const sortSetting = (item) => {
    const sortValue =
      item == "#"
        ? "id"
        : item == "Homework Name"
        ? "homeworkName"
        : item == "Description"
        ? "description"
        : item == "Assign Date"
        ? "assignedDate"
        : item == "Last Submission Date"
        ? "submittingDate"
        : "createdAt";
    setSortBy(sortValue);
    setSortOrder(!sortOrder);
  };

  return (
    <thead>
      <tr className="home-work-table-data-head">
        {tableHeadData?.map((item) => {
          return (
            <>
              <th
                style={{
                  textAlign: item?.title == "Action" ? "center" : "",
                  borderRight: "1px Solid #d4d9ff",
                  // paddingLeft: item?.title == "Action" ? "10px" : "",
                }}
              >
                {item?.title}
                {item?.title == "#" ||
                item?.title == "Homework Name" ||
                item?.title == "Description" ||
                item?.title == "Assign Date" ||
                item?.title == "Last Submission Date" ? (
                  <img
                    style={{
                      verticalAlign: "middle",
                      margin: "1px 0 2px 2px",
                    }}
                    className="cursor-pointer"
                    width="10"
                    height="15"
                    src={sortIcon}
                    onClick={() => {
                      sortSetting(item?.title);
                    }}
                    // await initData(!sortOrder, "id");
                  />
                ) : null}
              </th>
            </>
          );
        })}
      </tr>
    </thead>
  );
};

export default HomeWorkListHead;
