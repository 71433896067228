import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Css Pages
import navStyles from "./../css/navbar.module.css";
import "../../../src/pages/css/Student/Student_SideNavbar.css";

// Images & Icons Used
import dashHome from "../../../src/img/dashHome.svg";
import quizAprvlIcn from "../../../src/img/add_training.svg";
import viewQuizIcn from "../../../src/img/view-quizzes.svg";
import ViewConsptIcn from "../../../src/img/view-concept-tree.svg";
import admin from "../../../src/img/Adminuser.svg";

const RevisionReviewerMenuItem = ({
  elem,
  i,
  schoolSublinkVisible,
  navBarVis,
  handleLinkToggle,
}) => {
  let menuName = sessionStorage.getItem("sideNavMainMenuNames");

  const handleHighlight = (clicked) => {
    sessionStorage.setItem("sideNavMainMenuNames", clicked);
  };

  // Rendering the Menu Icons
  const menuIcons = {
    Dashboard: dashHome,
    QuizApproval: quizAprvlIcn,
    ViewQuizzes: viewQuizIcn, // Import and Change the Icon Here
    ViewConceptTree: ViewConsptIcn,
  };
  const defaultIcon = admin;
  const selectedIcon = menuIcons[elem.menu.replace(/ /g, "")] || defaultIcon;

  return (
    <>
      <div>
        {/*-------| Side Navbar Menu Names & Navigation Starts |-------*/}
        <Link
          style={{ textDecoration: "none" }}
          to={`/revision-reviewer/${elem.menu
            .toLowerCase()
            .replace(/ /g, "-")}`}
        >
          <div
            key={elem.menuId}
            className="sidenav_parent"
            onClick={elem.subMenus && handleLinkToggle}
          >
            <div
              className={`icon_and_text ${
                menuName === elem.menu ? "" : "icon_and_text_active"
              }`}
              onClick={() => handleHighlight(elem.menu)}
            >
              {/*-------| Side Navbar Icons|-------*/}
              <div
                className={`sidenav_icons ${
                  menuName === elem.menu ? "sidenav_icons_active" : ""
                }`}
              >
                <img src={selectedIcon} alt="no img" />
              </div>
              {menuName === elem.menu ? (
                <div className="sidenav_text_selected">{elem.menu}</div>
              ) : (
                <div>
                  <div className="sidenav_text">{elem.menu}</div>
                </div>
              )}
            </div>
          </div>
        </Link>
        {/*-------| Side Navbar Menu Names & Navigation Ends |-------*/}
      </div>
    </>
  );
};

export default RevisionReviewerMenuItem;
