import React from 'react'
import dashboardContentStyles from '../../../css/dashboardContent.module.css'
import dashHome from '../../../../img/dashHome.svg'

const BreadCrumbBar = ({ modifiedDate, pageName, closeContentDisplay }) => {
  return (
    <article style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ fontSize: '1vw', margin: '3px 10px' }}>
        {/* <span className={dashboardContentStyles.link_icon}>
          <img
            src={dashHome}
            alt='no img'
            width='15px'
            height='20px'
            style={{ verticalAlign: 'middle' }}
          />
        </span> */}
        <span className="az_menu_text cursorDefault">Home</span>
        <span>
          <i className="fa-solid fa-angle-right"
            style={{ color: "#828282" }} />
        </span>
        <span>
          <i className="fa-solid fa-angle-right"
            style={{ color: "#828282" }} />
        </span>
        <span className="az_menu_text"

          onClick={closeContentDisplay} style={{ cursor: 'pointer' }}>
          {pageName}
        </span>
        <span>
          <i className="fa-solid fa-angle-right"
            style={{ color: "#828282" }} />
        </span>
        <span>
          <i className="fa-solid fa-angle-right"
            style={{ color: "#828282" }} />
        </span>
        <a className="az_selected_menu_text">View Document</a>
      </div>
      {/* <div >
        <p className={dashboardContentStyles.lastUpdated_text}>
          Last Update:
          {modifiedDate?.length && modifiedDate}
        </p>
      </div> */}
    </article>
  )
}

export default BreadCrumbBar