import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";

const CancelButton = (props) => {
  const CancelButton = styled(Button)(({ theme }) => ({
    // color: theme.palette.getContrastText(grey[500]),
    color: "#333333",
    width: 150,
    fontSize: "14px",
    backgroundColor: grey[500],
    borderRadius: 150,
    borderColor: grey[500],
    border: 0,
    "&:hover": {
      color: "#333333",
      backgroundColor: grey[500],
      border: 0,
    },
  }));
  return <CancelButton onClick={props.onClick}>{props.children}</CancelButton>;
};
export default CancelButton;
