import axios from 'axios'

async function TeacherIDControllerPut(teacherIDData, setSuccess) {
  let token = sessionStorage.getItem("token");
  let teacherid = sessionStorage.getItem("teacherid");
  // console.log(sessionStorage.getItem("branchid"));
  // console.log(sessionStorage.getItem("schoolid"));

  if (teacherIDData.academicStaffProfile === "COORDINATOR") {
  var item = {
    lmsEnv: process.env.REACT_APP_LMS_ENV,
    address: teacherIDData.address,
    branch: sessionStorage.getItem("branchid"),
    academicStaffProfile: teacherIDData.academicStaffProfile,
    coordinatorTypeId: teacherIDData.coordinatorTypeId,
    designation: teacherIDData.designation,
    dob: teacherIDData.dob,
    email: teacherIDData.email.toLowerCase(),
    firstName: teacherIDData.firstName,
    gender: teacherIDData.gender,
    joinDate: teacherIDData.joinDate,
    lastName: teacherIDData.lastName,
    mobile: teacherIDData.mobile,
    previousWorkExp: teacherIDData.previousWorkExp,
    roleId: "ff8081818041dfd1018041e36f980000",
    school: sessionStorage.getItem("schoolid"),
    token: "770-1798-MMARS",
  }
}else{
  var item = {
    lmsEnv: process.env.REACT_APP_LMS_ENV,
    address: teacherIDData.address,
    branch: sessionStorage.getItem("branchid"),
    academicStaffProfile: teacherIDData.academicStaffProfile,
    coordinatorTypeId: null,
    designation: teacherIDData.designation,
    dob: teacherIDData.dob,
    email: teacherIDData.email.toLowerCase(),
    firstName: teacherIDData.firstName,
    gender: teacherIDData.gender,
    joinDate: teacherIDData.joinDate,
    lastName: teacherIDData.lastName,
    mobile: teacherIDData.mobile,
    previousWorkExp: teacherIDData.previousWorkExp,
    roleId: "ff8081818041dfd1018041e36f980000",
    school: sessionStorage.getItem("schoolid"),
    token: "770-1798-MMARS",
  }
}
 

    let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/${sessionStorage.getItem("teacherid")}`,item,
    {
     headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      setSuccess(json.data)
    })
    .catch(err=>console.log(err))
}
export default TeacherIDControllerPut;
