import axios from 'axios'

function academicYear_list_get(setYearlist){
    let token = sessionStorage.getItem('token');

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
    {
        headers:{
            "Content-Type" : "application/json",
            Authorization : `Bearer ${token}`
        }
    })
    .then((res)=>{
        setYearlist(res.data.data)
    })
    .catch(err => console.log(err))
}

export default academicYear_list_get