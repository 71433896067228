import React from "react";


const ProgressTable = ({ subTopicHideOrNot, progressTableData, onSubTopicRenderArray, selectedSubject }) => {

    return <div style={{ overflowX: "auto", scrollbarWidth: "thin" }}>

        {selectedSubject?.subject && <>
            {(subTopicHideOrNot?.hideSubtopics || onSubTopicRenderArray?.length <= 0) ? <table class="dashboard-table">
                <thead>
                    <th scope="row" style={{ textAlign: "start" }}>{selectedSubject?.subject}</th>
                    {progressTableData && progressTableData?.map(o => <th>{o?.section}</th>)}
                </thead>
                <tbody>
                    <tr>
                        <td scope="row" style={{ textAlign: "start" }}>Total Chapters</td>
                        {/* {progressTableData?.length > 0 && <td colSpan={`${progressTableData?.length}`}>{progressTableData[0]?.totalChapters}</td>} */}
                         <td colSpan={progressTableData?.length}>{progressTableData[0]?.totalChapters}</td>
                    </tr>
                    <tr>
                        <td scope="row" style={{ textAlign: "start" }}>Chapters Completed</td>
                        {progressTableData && progressTableData?.map(o => <td>{o?.chaptersCompleted}</td>)}
                    </tr>
                    <tr>
                        <td scope="row" style={{ textAlign: "start" }}>Tests Released</td>
                        {progressTableData && progressTableData?.map(o => <td>{o?.testReleased}</td>)}
                    </tr>
                </tbody>
            </table> : <> {onSubTopicRenderArray && onSubTopicRenderArray?.map((obj, i) => {
                return (<table class="dashboard-table">
                    <thead>
                        <th scope="row" style={{ textAlign: "start" }}>{obj?.subTopic}</th>
                        {obj?.section && obj?.section?.map((o) => {
                            return <th>{o?.section}</th>
                        })}
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="row" style={{ textAlign: "start" }}>Total Chapters</td>
                            <td colSpan={obj?.section?.length}>{obj?.section[0]?.totalChapters}</td>
                        </tr>
                        <tr>
                            <td scope="row" style={{ textAlign: "start" }}>Chapters Completed</td>
                            {obj?.section && obj?.section?.map((o) => {
                                return <td>{o?.chaptersCompleted}</td>
                            })}
                        </tr>
                        <tr>
                            <td scope="row" style={{ textAlign: "start" }}>Tests Released</td>
                            {obj?.section && obj?.section?.map((o) => {
                                return <td>{o?.testReleased}</td>
                            })}
                        </tr>
                    </tbody>
                </table>)
            })}</>}
        </>}
        {!selectedSubject && <span>No Progress Data</span>}
    </div>
}

export default ProgressTable