import {
    SELECT_QUESTION,
    SET_QUIZ_TYPE,
    UNSELECT_QUESTION,
    QUIZ_CREATION_STEP,
    QUIZ_CREATION_RESET,
    RESET_SELECTED_QUESTIONS,
    EDIT_UNSELECT_QUESTION,
    ORDER_SELECTED_QUESTION
  } from "../constants/quizConstants";
  

  
  export const select_question = item => dispatch => {
    dispatch({
        type:SELECT_QUESTION,
        payload:item
    })
}

export const order_selected_question = item => dispatch => {
  dispatch({
      type:ORDER_SELECTED_QUESTION,
      payload:item
  })
}
 
export const unselect_question = item => dispatch => {
  dispatch({
    type: UNSELECT_QUESTION,
    payload:item ,
  })
}
    
  
export const set_quiz_type = item => dispatch => {
  dispatch({
    type: SET_QUIZ_TYPE,
    payload:item ,
  })
}

export const set_quiz_creation_step = step => dispatch => {
  dispatch({
    type: QUIZ_CREATION_STEP,
    payload:step ,
  })
}


export const reset_quiz_creation = () => (dispatch) => {
  dispatch({
    type: QUIZ_CREATION_RESET,
  })
}


export const reset_selected_questions = () => (dispatch) => {
  dispatch({
    type: RESET_SELECTED_QUESTIONS,
  })
}

export const edit_selected_questions = (question) => (dispatch) => {
  dispatch({
    type: EDIT_UNSELECT_QUESTION,
    payload: question
  })
}
