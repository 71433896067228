import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import mainStyles from "./../css/main.module.css";
import mainImages from "./../css/mainImages.module.css";
import ProtectedRoute from "../../components/ProtectedRoute";

// import { useHistory } from "react-router-dom";

import Loader from "../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login } from "../../actions/userAction";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";
import { updateUserInfo } from '../../actions/userActions';
import { getLoginUserDetails } from "../../actions/userDetailAction";
import mixpanel from "mixpanel-browser";

const Auth = ({
  token,
  setToken,
  sucess,
  setSucess,
  sucessText,
  setSucessText,
  resultTokenData,
  setResultTokenData,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const [loginName, setLoginName] = useState("");
  const [loginNameValid, setLoginNameValid] = useState(true);
  const [uservalid, setUserValid] = useState(true);
  const [loginPassword, setLoginPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [isErr, setIsErr] = useState(true);
  const [loginPasswordValid, setLoginPasswordValid] = useState(true);
  const [showErr, setShowErr] = useState("");
  const [validate, setValidate] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [userName, setUserName] = useState("");
  const [schoolid, setSchoolId] = useState("");
  // const [sucess, setSucess] = useState(true);
  // const [token, setToken] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  // const [loginPassword, setLoginPassword] = useState("");
  // const [passwordShown, setPasswordShown] = useState(false);
  const [readingPassportSuccess, setReadingPassportSuccess] = useState()



  const navigate = useNavigate();

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  // Fetching The School Menus Api //
  const fetchSchoolMenus = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/misc/school-menus`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res?.data?.data || [];
    } catch (error) {
      console.error("Error fetching school menus:", error);
      return [];
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (loginName === "") {
      setLoginNameValid(false);
      return;
    }
    setLoginNameValid(true);

    if (loginPassword === "") {
      setLoginPasswordValid(false);
      return;
    }
    setLoginPasswordValid(true);
    login();

    fetchSchoolMenus()
    // adminGet();
  };

  async function login() {
    let item = { username: loginName, password: loginPassword };

    axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/authenticate`,
        item,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        console.log(result.data.data);
        dispatch(getLoginUserDetails(result?.data?.data))
        dispatch(updateUserInfo(result.data.data.firstName, result.data.data.lastName, result.data.data.id, result.data.data.userName));
        if (result.data.data.roles[0].role === "CONTENT_ADMIN") {
          let menu = result.data.data.menus
          menu.push({
            application
              :
              null,
            iconClass
              :
              null,
            menu
              :
              "Create Content",
            menuId
              :
              "ff808181854f743801854fa408c600asdas2",
            route
              :
              null,
            subMenus
              : null
          })
          localStorage.setItem("menus", JSON.stringify(menu));
        } else {
          localStorage.setItem("menus", JSON.stringify(result.data.data.menus));
        }
        localStorage.setItem("purchasedFeatures", JSON.stringify(result.data.data.purchasedFeatures || []));
        localStorage.setItem("userName", result.data.data.userName);
        localStorage.setItem("firstName", result.data.data.firstName);
        localStorage.setItem("id", result.data.data.id);

        localStorage.setItem("lastName", result.data.data.lastName);
        //
        var purchasedFeatures = result.data.data.purchasedFeatures;
        console.log("purchasedFeatures >>> ", purchasedFeatures)

        localStorage.setItem("role", result.data.data.roles[0].role);

        setToken(result.data.data.token);
        setValidate(result.data.data.token);
        setResultTokenData(result.data);
        setUserName(result.data.data.userName)
        const token = result.data.data.token;
        const userId = result.data.data.id;

        localStorage.setItem("token", result?.data?.data?.token);
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("userId", userId);


        var tokenItem = {
          token: token,
        };
        axios
          .post(
            `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/authenticate/validatetoken`,
            tokenItem,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((json) => { })
          .catch((err) => console.log(err));
        // console.log("token", result.data.data.roles[0].role)
        // setTimeout(() => {
        if (result.data.data.roles[0].role === "TEACHER") {
          var isTeacher = result.data.data.menus?.some(item => item.menu === "Teach");
          // SS for Highlighthing the Sidenav Menu Name
          console.log(isTeacher, "isTeacher")
          if (!result.data.data.purchasedFeatures) {
            sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
            navigate("/dashboard/TeacherDashboard", {
              state: { id: 7, color: "green" },
            });
          } else if (!isTeacher) {
            sessionStorage.setItem("sideNavMainMenuNames", "Reading Passport");
            sessionStorage.setItem("sideNavSubMenuNames", "Reading Passport List")
            // navigate("/dashboard/PrincipalDashboard", {
            navigate("/dashboard/reading%20passport", {
              state: { id: 7, color: "green" },
            });
          }
          else {
            sessionStorage.setItem("sideNavMainMenuNames", "Teach");
            navigate("/dashboard/teach", {
              state: { id: 7, color: "green" },
            });
          }
        } else if (result.data.data.roles[0].role === "COORDINATOR") {
          // SS for Highlighthing the Sidenav Menu Name
          sessionStorage.setItem("sideNavMainMenuNames", "Teach");
          // navigate("/dashboard/PrincipalDashboard", {
          navigate("/cordinator/teach", {
            state: { id: 7, color: "green" },
          });


        } else if (result.data.data.roles[0].role === "PRINCIPAL") {
          // SS for Highlighthing the Sidenav Menu Name
          sessionStorage.setItem("sideNavMainMenuNames", "Reports");
          sessionStorage.setItem("sideNavSubMenuNames", "Chapter Performance")
          // navigate("/dashboard/PrincipalDashboard", {
          navigate("/dashboard/Chapter%20Performance", {
            state: { id: 7, color: "green" },
          });

          // dashboard/Chapter%20Performance


        } else if (result.data.data.roles[0].role === "REVISION_REVIEWER") {
          // SS for Highlighthing the Sidenav Menu Name
          sessionStorage.setItem("sideNavMainMenuNames", "Quiz Approval");
          navigate("revision-reviewer/quiz-approval", {
            state: { id: 7, color: "green" },
          });


        }
        else if (result.data.data.roles[0].role === "REVISION_ADMIN") {
          // SS for Highlighthing the Sidenav Menu Name
          sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
          navigate("dashboard/RevisionAdminDash", {
            state: { id: 7, color: "green" },
          });


        } else if (result.data.data.roles[0].role === "STUDENT") {
          sessionStorage.setItem("sideNavMainMenuNames", "Study");

          // mix panel logic goes here --------------------------------------

          // const userRole = result?.data?.data?.roles[0]?.role;
          // const user_Id = result?.data?.data?.id;
          // const user_Data = result?.data?.data;
          // if(userRole ==="STUDENT"){
          //   mixpanel.identify(user_Id)

          //   mixpanel.people.set({
          //     "$first_name": user_Data?.firstName,
          //     "$last_name": user_Data?.lastName,
          //     "$email":user_Data?.email,
          //     "username": user_Data?.userName,
          //   })

          //   mixpanel.track('Student_Logged_In',{
          //     "user_id" : user_Id,
          //     "username": user_Data?.userName,
          //   })
          // }else{
          //   mixpanel.identify(user_Id)

          //   mixpanel.people.set({
          //     "$first_name": user_Data?.firstName,
          //     "$last_name": user_Data?.lastName,
          //     "$email":user_Data?.email,
          //     "username": user_Data?.userName,
          //     "role":userRole
          //   })

          //   mixpanel.track(`${userRole}_Logged_In`,{
          //     "user_id" : user_Id,
          //     "username": user_Data?.userName,
          //   })

          // }
          navigate("/dashboard/study", {
            state: { id: 7, color: "green" },
          });
        }
        else if (result.data.data.roles[0].role === "CONTENT_ADMIN") {
          sessionStorage.setItem("sideNavMainMenuNames", "Upload Content");
          navigate("/dashboard/upload%20content", {
            state: { id: 7, color: "green" },
          });
        }
        else if (result.data.data.roles[0].role === "CONTENT_REVIEWER") {
          sessionStorage.setItem("sideNavMainMenuNames", "Content Approval");
          navigate("/dashboard/content%20approval", {
            state: { id: 7, color: "green" },
          });
        }
        else {
          // SS for Highlighthing the Sidenav Menu Name
          sessionStorage.setItem("sideNavMainMenuNames", "Dashboard");
          navigate("/dashboard/template", {
            state: { id: 7, color: "green" },
          });

        }

        const userRole = result?.data?.data?.roles[0]?.role;
        const user_Id = result?.data?.data?.id;
        const user_Data = result?.data?.data;
        if (userRole === "STUDENT") {
          mixpanel.identify(user_Id)

          mixpanel.people.set({
            "$first_name": user_Data?.firstName,
            "$last_name": user_Data?.lastName,
            "$email": user_Data?.email,
            "username": user_Data?.userName,
            "role": userRole
          })

          mixpanel.track('Student_Logged_In', {
            "user_id": user_Id,
            "username": user_Data?.userName,
            "role": userRole
          })
        } else {
          mixpanel.identify(user_Id)

          mixpanel.people.set({
            "$first_name": user_Data?.firstName,
            "$last_name": user_Data?.lastName,
            "$email": user_Data?.email,
            "username": user_Data?.userName,
            "role": userRole
          })

          mixpanel.track(`${userRole}_Logged_In`, {
            "user_id": user_Id,
            "username": user_Data?.userName,
            "role": userRole
          })

        }
        // }, 2000);
        // axios
        // .get(
        //   `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/readingPassportAccess/getReadingPassport/${userId}`,
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // )
        // .then(function (response) {

        // console.log("responece from login user",response.data.data,result.data.data.menus)
        // let accessCheck = response.data.data.access
        // let menuList = result.data.data.menus
        // let updatedMenu = []
        // if(accessCheck || 
        //         result.data.data.roles[0].role === "PRINCIPAL" || 
        //         result.data.data.roles[0].role === "COORDINATOR" ||
        //         result.data.data.roles[0].role === "STUDENT"  ){
        //   localStorage.setItem("access", JSON.stringify("true"));
        //   //  updatedMenu = menuList?.filter((menuData,idx)=> !(menuData.menuId == "402892888e0e01bb018e50431e4800e0" ))
        //   //  localStorage.setItem("menus", JSON.stringify(updatedMenu));
        // }else{
        //   localStorage.setItem("access", JSON.stringify("false"));
        // }
        // console.log("updatad menu", updatedMenu);

        //   setReadingPassportSuccess(true)
        // })
        // .catch((err) => {
        //   console.log(err)
        //   setReadingPassportSuccess(false)
        // });


      })
      .catch((err) => {
        setIsErr(err.response?.data?.success);
      });
  }

  async function adminGet() {
    await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/administrations/${userName}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        setUserDetails(resp.data.data.data)
        setSchoolId(resp.data.data.institutions[0].schoolId)
        const schoolid = resp.data.data.institutions[0].schoolId
        sessionStorage.setItem("schoolid", schoolid);
        sessionStorage.setItem("userName", userName);
      })
      .catch((err) => {
        console.log(err)
      })
  }
  if (userName !== "") { adminGet() }

  return (
    <>
      {/* {loading ? (
        <Loader />
      ) : ( */}
      <>
        <div className={mainStyles.main_container}>
          <div className={`${mainStyles.main} ${mainImages.main}`}>
            <div className={`${mainStyles.navbar} ${mainImages.navbar}`}>
              <div className={mainStyles.icon}>
                <h2 className={mainStyles.logo}>
                  <img src="./img/Azvasa_logo.png" />
                </h2>
              </div>
            </div>
            <div className={mainStyles.content}>
              <img
                style={{ width: "50%" }}
                src="./img/Login.png"
                className="login-img"
              />
              <form
                className={`${mainStyles.login_form} ${mainImages.login_form}`}
              >
                <div className={mainStyles.login_form_title}>
                  <h2>Welcome to Azvasa!</h2>
                  <h3>Login into your account</h3>
                </div>
                <div className={mainStyles.username_container}>
                  <fieldset>
                    <input
                      id={`az-username`}
                      type="text"
                      placeholder=" "
                      className={mainStyles.input_element}
                      required
                      value={loginName}
                      onChange={(e) => {
                        setLoginNameValid(true);
                        setLoginName(e.target.value);
                      }}
                    />
                    <span className={mainStyles.input_legend}>User Name</span>
                    <span
                      className={mainStyles.error_legend}
                      style={
                        loginNameValid
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      Please enter valid User Name
                    </span>
                  </fieldset>
                </div>
                <div className={mainStyles.password_container}>
                  <fieldset>
                    <input
                      id={`az-password`}
                      type={passwordShown ? "text" : "password"}
                      placeholder=" "
                      className={mainStyles.input_element}
                      required
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                    />
                    <span className={mainStyles.input_legend}>Password</span>
                    <i
                      onClick={togglePasswordVisiblity}
                      className={`bi ${passwordShown ? "bi-eye" : "bi-eye-slash"
                        } ${mainStyles.bi_eye_slash}`}
                      id="togglePassword"
                    />
                    <span
                      className={mainStyles.error_legend}
                      style={
                        loginPasswordValid
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      Please enter valid password
                    </span>
                    <span
                      className={mainStyles.error_legend}
                      style={
                        isErr ? { display: "none" } : { display: "block" }
                      }
                    >
                      Please Enter valid Data
                    </span>
                  </fieldset>
                </div>
                <div className={mainStyles.login_container}>
                  <span className={mainStyles.remember_checkbox}>
                    <input
                      style={{ accentColor: "#fb9d2a" }}
                      type="checkbox"
                      className="cursorPointer"
                      name="rememberMe"
                      id="rememberMe"
                    />
                    <label htmlFor="rememberMe">&nbsp;Remember Me</label>
                  </span>
                  <button
                    id='az-login-button'
                    type="submit"
                    className={mainStyles.login_btn}
                    onClick={handleClick}
                  >
                    Login
                  </button>
                </div>
                <div className={mainStyles.forgot_password}>
                  <a
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("forgetpassmob");
                    }}
                  >
                    Forgot Password?
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
      {/* )} */}
    </>
  );
};

export default Auth;