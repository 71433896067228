import axios from 'axios'

async function SchoolControllerAll(setschoolUserData) {
  let role = localStorage.getItem("role")
  let userId = sessionStorage.getItem("userId");
  let token = sessionStorage.getItem("token");
  let schoolname = "Silverleaf School";
  if (role==='SCHOOL_ADMIN'){
     await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/all?userId=${userId}`,
    {
     headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      if(json.data.data){
        setschoolUserData(json.data.data)
      }
    })
    .catch(err=>console.log(err))
}

else{
  await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/schools/all`,
    {
     headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((json)=>{
      if(json.data.data){
        setschoolUserData(json.data.data)
      }
    })
    .catch(err=>console.log(err))
}
}
export default SchoolControllerAll;
