import axios from 'axios'

const association_last_update_get = (setLastUpdate) =>{
    const token = sessionStorage.getItem('token')

    axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/concept-association/last-modified-at`,{
        headers:{
            "Content-type":"application/json",
            Authorization:`Bearer ${token}`
        }
    })
    .then((res)=>{
        setLastUpdate(res.data)
    })
    .catch(err => console.log(err))
}

export default association_last_update_get