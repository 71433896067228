import { Modal } from '@mui/material'
import React from 'react'
import './DashboardMessageModal.css'
import { Close } from '@material-ui/icons'
import { emberMessageOne, emberMessageTwo } from '../../constants/dashboardConstants'
import { getMessageColorCode,getMessageColorCodeV2 } from '../../utils/helper'
const DashboardMessageModal = ({title,content,showMessageModal,onMessageModalClose,isEmberCategoryMessage}) => {
  return (
   <Modal sx={{display:'grid',placeContent:'center'}} open={showMessageModal} onClose={onMessageModalClose} >
        <div className='msg-modal-body' style={{width:isEmberCategoryMessage ? '450px':'350px'}}>
            <p className="msg-modal-title" style={{marginBottom:isEmberCategoryMessage ? '30px':'15px',color:getMessageColorCodeV2(title)}}>{isEmberCategoryMessage ? 'Attention!': title}</p>
            <p className="msg-modal-content" style={{marginBottom:isEmberCategoryMessage ? '15px':'0px'}}>{isEmberCategoryMessage ? emberMessageOne :content}</p>
           
            {isEmberCategoryMessage ?  <p className="msg-modal-content">{emberMessageTwo}</p> :null}
            <div onClick={onMessageModalClose} className="msg-modal-close-btn">
                <Close style={{fontSize:'16px',color:'#ccc'}} />
            </div>
        </div>
   </Modal>
  )
}

export default DashboardMessageModal