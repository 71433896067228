import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import addcityliststyles from "./../css/AddCityList.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import Pagination1 from "./pagination1";
import GetLastModifiedAt from "../API/school-controller/GetLastModifiedAt";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";

import City_Controller_get from "../API_CONTROLLER/City_Controller/City_Controller_get";
const AddCityList = (props) => {
  const [modifiedDate, setModifiedDate] = useState({});
  const [rowsInput, setRowsInputs] = useState({ rows: 10 });

  useEffect(() => {
    GetLastModifiedAt(setModifiedDate);
    return () => {};
  }, []);

  let CityList = props.CityList;
  const navigate = useNavigate();

  const handleAddCity = (e) => {
    e.preventDefault();
    navigate("/dashboard/addcity");
  };
  const handleEdit = (e) => {
    navigate("/dashboard/addcity", {
      state: "edit",
    });
  };
  // search starts
  const [search, setSearch] = useState({
    searchName: "",
  });
  const handleSearch = (e) => {
    e.preventDefault();
    const search = {
      searchName: e.target.value,
    };
    City_Controller_get(setCityData, rowsInput, page, setPageDetail, search);
  };
  const handleSearchCity = (e) => {
    e.preventDefault();
    City_Controller_get(setCityData, rowsInput, page, setPageDetail, search);
  };

  useEffect(() => {
    if (search.search === "") {
      City_Controller_get(setCityData, rowsInput, page, setPageDetail, search);
    }

    return () => {};
  }, [rowsInput, search]);

  // search ends
  // pagination starts

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handlerRowsInputs = (e) => {
    setRowsInputs({
      ...rowsInput,
      [e.target.name]: e.target.value,
    });
  };
  // pagination ends
  const [toggleActive, setToggleActive] = useState("");
  const handletoggleChange = (e) => {
    setToggleActive(e.target.checked);
  };

  const [pageDetail, setPageDetail] = useState({});
  const [cityData, setCityData] = useState({});

  useEffect(() => {
    City_Controller_get(setCityData, rowsInput, page, setPageDetail, search);

    return () => {};
  }, [rowsInput, page]);
  return (
    <>
      {" "}
      <article>
        <div className={dashboardContentStyles.dashboard_link}>
          <span className={dashboardContentStyles.link_icon}>
            <img
              src={dashHome}
              alt="no img"
              width="15px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </span>
          {"  "}
          {/* <span className={dashboardContentStyles.link_text}>Home</span>{" "} */}

          {"  "}
          <span className={dashboardContentStyles.link_text}>City List</span>
          {"  "}
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          <span>
            <i className="fa-solid fa-angle-right"></i>
          </span>
          {"  "}
          <a>Edit City</a>
        </div>
        <div className={dashboardContentStyles.dashboard_last_updated}>
          <p>Last Update: {modifiedDate.data}</p>
        </div>
      </article>
      <article className={addcityliststyles.article}>
        <div className={addcityliststyles.city_head}>
          <div>
            <i className="fa-solid fa-city"></i> City List
          </div>
          <div className={addcityliststyles.school_list_search_container_32}>
            <div className={addcityliststyles.school_list_search_container}>
              <input
                type="search"
                name="search"
                id="search"
                placeholder="Name"
                Value={search.searchName}
                onChange={handleSearch}
              />
              <span>
                <img
                  src={searchIcn}
                  style={{
                    verticalAlign: "top",
                    fontSize: "large",
                  }}
                  onClick={handleSearchCity}
                />
              </span>
            </div>
            <div>
              <input
                className={addcityliststyles.add_school_button_32}
                type="submit"
                value="Add City"
                onClick={handleAddCity}
              />
            </div>
          </div>
        </div>
        <div className={addcityliststyles.section_list_table}>
          <table className={addcityliststyles.table_section}>
            <thead className={addcityliststyles.thead_35}>
              <tr className={addcityliststyles.table_head_section}>
                <th className={addcityliststyles.cbse_table_head}>
                  #<i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th className={addcityliststyles.city_name}>
                  City
                  <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th className={addcityliststyles.cbse_table_head}>
                  {" "}
                  PinCode
                  <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th className={addcityliststyles.cbse_table_head1}>
                  State
                  <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th className={addcityliststyles.cbse_table_head2}>
                  Country
                  <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th className={addcityliststyles.cbse_table_head3}>
                  Active
                  <i className="fa-solid fa-arrows-up-down"></i>
                </th>
                <th className={addcityliststyles.cbse_table_head3}>
                  Action
                  <i className="fa-solid fa-arrows-up-down"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              {cityData && cityData.length > 0 ? (
                cityData.map((cityData, index) => {
                  return (
                    <tr className={addcityliststyles.data_row} key={index}>
                      <td>{index + 1}</td>
                      <td className={addcityliststyles.city_name}>
                        {cityData.city}
                      </td>
                      <td>{cityData.pincode}</td>
                      <td>{cityData.state}</td>
                      <td>{cityData.country}</td>
                      <td>
                        <label className={addcityliststyles.active_switch}>
                          <input
                            type="checkbox"
                            id={cityData.id}
                            name={cityData.id}
                            Value={cityData.active}
                            // checked="true"
                            defaultChecked={cityData.active}
                            onChange={handletoggleChange}
                          />
                          <span
                            className={addcityliststyles.active_slider}
                          ></span>
                        </label>
                      </td>
                      <td>
                        <div className={addcityliststyles.wrapper}>
                          <div className={addcityliststyles.icon}>
                            <div className={addcityliststyles.tooltip}>
                              Edit
                            </div>
                            <span>
                              <i
                                onClick={(e) => {
                                  e.preventDefault();
                                  var cityid = cityData.id;
                                  sessionStorage.setItem("cityid", cityid);
                                  navigate("/dashboard/addcity", {
                                    state: "edit",
                                  });
                                }}
                                className="fa-solid fa-pencil"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5">NO DATA AVAILABLE</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* pagination container - Start */}
        <div className={addcityliststyles.pagination}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <span className={addcityliststyles.rows}>
              <label htmlFor="rows">Rows per page</label>
              <select
                name="rows"
                id="rows"
                value={rowsInput.rows}
                onChange={handlerRowsInputs}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </span>
          </form>
          <span>
            <Pagination1 pageDetail={pageDetail} handleChange={handleChange} />
          </span>
        </div>
        {/* pagination container - End */}
      </article>
    </>
  );
};

export default AddCityList;
