import axios from "axios";

async function CSVfile_upload_controller_post(formData, setUploadCSV,fileType,setMessage) {
  let token = sessionStorage.getItem("token");
  // console.log(formData);

    let result = await axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/file-upload?fileTypes=${fileType}`,formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response)=>{
        setUploadCSV(response)
        setMessage(response.data.data.message)
        sessionStorage.setItem('csv_message',response.data.data.message);
       
    
    })
    .catch(err=>{             
        setUploadCSV(null)
        setMessage("Failed")
        sessionStorage.setItem('csv_message',"");
        console.log(err)
      }
      )
}

export default CSVfile_upload_controller_post;
